import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete } from "antd";
import { useAttendeesQuery } from "api/queries/attendees";
import { Input } from "components/styleguide";
import { useDebounce } from "hooks/useDebounce";
import { toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./styles.module.less";

const AttendeeSearch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const forumId = toNumber(useParams().id);

  const [attendeeSearchValue, setAttendeeSearchValue] = useState<string>("");
  const [attendeeDebouncedSearchValue, setAttendeeDebouncedSearchValue] = useState<string>("");

  const debouncedSearchTerm = useDebounce(attendeeSearchValue, 300, 2);

  useEffect(() => {
    setAttendeeDebouncedSearchValue(attendeeSearchValue);
  }, [debouncedSearchTerm]);

  const { data: { data: { items: attendees = [] } = {} } = {} } = useAttendeesQuery({
    forumId,
    globalSearch: attendeeDebouncedSearchValue,
  });

  const attendeesOptions = attendees.map(({ attendId, fullName, email, forumCompany }) => ({
    label: `${fullName} ${email} ${forumCompany}`,
    value: attendId,
  }));

  const onAttendeeSelect = (selectedAttendeeId: React.SetStateAction<string>) =>
    navigate(`../attendees/${selectedAttendeeId}`);

  return (
    <AutoComplete
      className={styles.search}
      allowClear
      options={attendeesOptions}
      onChange={setAttendeeSearchValue}
      onSelect={onAttendeeSelect}
    >
      <Input
        className={styles.searchInput}
        prefix={<SearchOutlined />}
        placeholder={t("Search by Attendee, Company or Email")}
      />
    </AutoComplete>
  );
};

export default AttendeeSearch;
