import apiClient from "api-client";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useTimeslotSessionQuery = getWrappedUseQuery(KEYS.GET_TIMESLOT_SESSION, apiClient.TimeSlotSession.get);
export const useTimeslotSessions = getWrappedUseQuery(KEYS.GET_TIMESLOT_SESSIONS, apiClient.TimeSlotSession.getAll);

export const useAllTimeslotsSessionsQuery = getWrappedUseQuery(
  KEYS.GET_ALL_TIMESLOTS_SESSIONS,
  apiClient.TimeSlotSession.sessions.getAll,
);
