/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FacilityModel } from '../apiModels';
// @ts-ignore
import { FacilityRequestModel } from '../apiModels';
// @ts-ignore
import { FacilityResponseModel } from '../apiModels';
// @ts-ignore
import { FacilityType } from '../apiModels';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ReferencedObjectCannotBeDeletedExceptionResponse } from '../apiModels';
// @ts-ignore
import { ValueOutOfRangeExceptionResponse } from '../apiModels';
/**
 * ForumFacilityApi - axios parameter creator
 * @export
 */
export const ForumFacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {string} [facilityCode] 
         * @param {string} [name] 
         * @param {string} [roomGrade] 
         * @param {string} [description] 
         * @param {string} [attendee] 
         * @param {string} [billingNote] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet: async (forumId: number, forumBuildingId: number, facilityCode?: string, name?: string, roomGrade?: string, description?: string, attendee?: string, billingNote?: string, orderBy?: string, orderDir?: string, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/exportbedrooms`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facilityCode !== undefined) {
                localVarQueryParameter['FacilityCode'] = facilityCode;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (roomGrade !== undefined) {
                localVarQueryParameter['RoomGrade'] = roomGrade;
            }

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (attendee !== undefined) {
                localVarQueryParameter['Attendee'] = attendee;
            }

            if (billingNote !== undefined) {
                localVarQueryParameter['BillingNote'] = billingNote;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get forum venue facility by id
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet: async (forumId: number, forumBuildingId: number, facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet', 'facilityId', facilityId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{facilityId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a forum venue facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete: async (forumId: number, forumBuildingId: number, forumFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete', 'forumFacilityId', forumFacilityId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a forum venue facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut: async (forumId: number, forumBuildingId: number, forumFacilityId: number, facilityRequestModel?: FacilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut', 'forumFacilityId', forumFacilityId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facilities of a forum venue building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {string} [facilityCode] 
         * @param {string} [name] 
         * @param {string} [roomGrade] 
         * @param {string} [description] 
         * @param {string} [attendee] 
         * @param {string} [billingNote] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FacilityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet: async (forumId: number, forumBuildingId: number, facilityCode?: string, name?: string, roomGrade?: string, description?: string, attendee?: string, billingNote?: string, orderBy?: string, orderDir?: string, type?: FacilityType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facilityCode !== undefined) {
                localVarQueryParameter['FacilityCode'] = facilityCode;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (roomGrade !== undefined) {
                localVarQueryParameter['RoomGrade'] = roomGrade;
            }

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (attendee !== undefined) {
                localVarQueryParameter['Attendee'] = attendee;
            }

            if (billingNote !== undefined) {
                localVarQueryParameter['BillingNote'] = billingNote;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a forum venue facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost: async (forumId: number, forumBuildingId: number, facilityRequestModel?: FacilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumFacilityApi - functional programming interface
 * @export
 */
export const ForumFacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumFacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {string} [facilityCode] 
         * @param {string} [name] 
         * @param {string} [roomGrade] 
         * @param {string} [description] 
         * @param {string} [attendee] 
         * @param {string} [billingNote] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet(forumId: number, forumBuildingId: number, facilityCode?: string, name?: string, roomGrade?: string, description?: string, attendee?: string, billingNote?: string, orderBy?: string, orderDir?: string, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet(forumId, forumBuildingId, facilityCode, name, roomGrade, description, attendee, billingNote, orderBy, orderDir, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get forum venue facility by id
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet(forumId: number, forumBuildingId: number, facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet(forumId, forumBuildingId, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a forum venue facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete(forumId: number, forumBuildingId: number, forumFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete(forumId, forumBuildingId, forumFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a forum venue facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut(forumId: number, forumBuildingId: number, forumFacilityId: number, facilityRequestModel?: FacilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut(forumId, forumBuildingId, forumFacilityId, facilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get facilities of a forum venue building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {string} [facilityCode] 
         * @param {string} [name] 
         * @param {string} [roomGrade] 
         * @param {string} [description] 
         * @param {string} [attendee] 
         * @param {string} [billingNote] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FacilityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet(forumId: number, forumBuildingId: number, facilityCode?: string, name?: string, roomGrade?: string, description?: string, attendee?: string, billingNote?: string, orderBy?: string, orderDir?: string, type?: FacilityType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet(forumId, forumBuildingId, facilityCode, name, roomGrade, description, attendee, billingNote, orderBy, orderDir, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a forum venue facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost(forumId: number, forumBuildingId: number, facilityRequestModel?: FacilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost(forumId, forumBuildingId, facilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumFacilityApi - factory interface
 * @export
 */
export const ForumFacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumFacilityApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityCode, requestParameters.name, requestParameters.roomGrade, requestParameters.description, requestParameters.attendee, requestParameters.billingNote, requestParameters.orderBy, requestParameters.orderDir, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get forum venue facility by id
         * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FacilityResponseModel> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a forum venue facility
         * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a forum venue facility
         * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.facilityRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facilities of a forum venue building
         * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FacilityResponseModel>> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityCode, requestParameters.name, requestParameters.roomGrade, requestParameters.description, requestParameters.attendee, requestParameters.billingNote, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a forum venue facility
         * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FacilityModel> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet operation in ForumFacilityApi.
 * @export
 * @interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGetRequest
 */
export interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly facilityCode?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly roomGrade?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly attendee?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly billingNote?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {FileExportType}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet operation in ForumFacilityApi.
 * @export
 * @interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGetRequest
 */
export interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet
     */
    readonly facilityId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete operation in ForumFacilityApi.
 * @export
 * @interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDeleteRequest
 */
export interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete
     */
    readonly forumFacilityId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut operation in ForumFacilityApi.
 * @export
 * @interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPutRequest
 */
export interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {FacilityRequestModel}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut
     */
    readonly facilityRequestModel?: FacilityRequestModel
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet operation in ForumFacilityApi.
 * @export
 * @interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest
 */
export interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly facilityCode?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly roomGrade?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly attendee?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly billingNote?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {FacilityType}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet
     */
    readonly type?: FacilityType
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost operation in ForumFacilityApi.
 * @export
 * @interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPostRequest
 */
export interface ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {FacilityRequestModel}
     * @memberof ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost
     */
    readonly facilityRequestModel?: FacilityRequestModel
}

/**
 * ForumFacilityApi - object-oriented interface
 * @export
 * @class ForumFacilityApi
 * @extends {BaseAPI}
 */
export class ForumFacilityApi extends BaseAPI {
    /**
     * 
     * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumFacilityApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGetRequest, options?: AxiosRequestConfig) {
        return ForumFacilityApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityCode, requestParameters.name, requestParameters.roomGrade, requestParameters.description, requestParameters.attendee, requestParameters.billingNote, requestParameters.orderBy, requestParameters.orderDir, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get forum venue facility by id
     * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumFacilityApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGetRequest, options?: AxiosRequestConfig) {
        return ForumFacilityApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a forum venue facility
     * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumFacilityApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumFacilityApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a forum venue facility
     * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumFacilityApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPutRequest, options?: AxiosRequestConfig) {
        return ForumFacilityApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.facilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facilities of a forum venue building
     * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumFacilityApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest, options?: AxiosRequestConfig) {
        return ForumFacilityApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityCode, requestParameters.name, requestParameters.roomGrade, requestParameters.description, requestParameters.attendee, requestParameters.billingNote, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a forum venue facility
     * @param {ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumFacilityApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost(requestParameters: ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesPostRequest, options?: AxiosRequestConfig) {
        return ForumFacilityApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.facilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

