import { Input, Select } from "components/styleguide";
import React from "react";
import { normFile } from "utils/documentUtils";

import VenueMapUpload from "./VenueMapUpload";

const { TextArea } = Input;

const getFields = (map, countries = []) => {
  return [
    { title: "Name", dataIndex: "name" },
    { title: "Code", dataIndex: "code" },
    { title: "Address 1", dataIndex: "address" },
    { title: "Email", dataIndex: "email" },
    { title: "Address 2", dataIndex: "addressLine2" },
    { title: "Website Address", dataIndex: "websiteAddress" },
    { title: "City", dataIndex: "city" },
    { title: "Phone", dataIndex: "phone" },
    { title: "County", dataIndex: "county" },
    { title: "Venue Directions", dataIndex: "directions" },
    { title: "Postcode", dataIndex: "postCode" },
    {
      dataIndex: "space_1",
    },
    {
      title: "Country",
      dataIndex: "countryCode",
      displayValue: "country",
      component: (
        <Select
          options={countries.map(({ isoCode, name }) => ({
            value: isoCode,
            label: name,
          }))}
          allowClear={true}
          showSearch={true}
          optionFilterProp={"label"}
        />
      ),
    },
    {
      dataIndex: "space_2",
    },
    { title: "Venue Description", dataIndex: "description", component: <TextArea rows={10} /> },
    {
      title: "Venue Map",
      dataIndex: "map",
      type: "image",
      getValueFromEvent: normFile,
      valuePropName: "fileList",
      component: map && <VenueMapUpload current={map?.current} fileList={map.fileList} onChange={map.onChange} />,
    },
  ];
};

export default getFields;
