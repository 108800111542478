import { useGetCountriesQuery } from "api/queries/countries";
import { Button, Col, Divider, Form, Input, Row, Space } from "components/styleguide";
import { VirtualForumCode } from "enums/VirtualForumCode";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import getFields from "../helpers";

import styles from "../styles.module.less";

const VenueDetailsForm = ({ initialValues, setIsEditing, onSubmit, isUpdateLoading }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [mapFileList, setMapFileList] = useState([]);

  const getSchema = () => yup.object().shape(venueFormSchema);

  const getFileMaxSizeTest = (maxSize) => (value) => {
    const file = Array.isArray(value) ? value[0] : value;

    if (!file || file.size < maxSize) {
      return true;
    }

    return false;
  };

  const getFileTypeTest = (availableFileExtensions) => (value) => {
    const file = Array.isArray(value) ? value[0] : value;

    if (!file) {
      return true;
    }

    const currentFileExtension = file.name.split(".").reverse()[0];

    return availableFileExtensions.includes(currentFileExtension);
  };

  const venueFormSchema = {
    name: yup
      .string()
      .required(t("errors.required", { prop: "$t(venueDetailsLabels.Name)" }))
      .max(50, t("The character limit is {{count}}", { count: 50 })),
    code: yup
      .string()
      .required(t("errors.required", { prop: "$t(venueDetailsLabels.Code)" }))
      .min(5, t("The character limit is {{count}}", { count: 5 }))
      .max(5, t("The character limit is {{count}}", { count: 5 })),
    address: yup
      .string()
      .required(t("errors.required", { prop: "$t(venueDetailsLabels.Address 1)" }))
      .max(200, t("The character limit is {{count}}", { count: 200 })),
    email: yup
      .string()
      .nullable()
      .email(t("errors.emailValid"))
      .max(100, t("The character limit is {{count}}", { count: 100 })),
    addressLine2: yup
      .string()
      .nullable()
      .max(200, t("The character limit is {{count}}", { count: 200 })),
    websiteAddress: yup
      .string()
      .nullable()
      .max(200, t("The character limit is {{count}}", { count: 200 })),
    city: yup
      .string()
      .required(t("errors.required", { prop: "$t(venueDetailsLabels.City)" }))
      .max(50, t("The character limit is {{count}}", { count: 50 })),
    phone: yup
      .string()
      .nullable()
      .max(50, t("The character limit is {{count}}", { count: 50 })),
    county: yup
      .string()
      .nullable()
      .max(50, t("The character limit is {{count}}", { count: 50 })),
    description: yup
      .string()
      .nullable()
      .max(2500, t("The character limit is {{count}}", { count: 2500 })),
    postCode: yup
      .string()
      .required(t("errors.required", { prop: "$t(venueDetailsLabels.Postcode)" }))
      .max(50, t("The character limit is {{count}}", { count: 50 })),
    directions: yup
      .string()
      .nullable()
      .max(2500, t("The character limit is {{count}}", { count: 2500 })),
    countryCode:
      initialValues?.code === VirtualForumCode.VirtualVenueCode ? yup.string().nullable() : yup.string().required(),
    map: yup
      .array()
      .of(yup.object())
      .test(
        "File type",
        t("Please upload only JPEG, PNG, JPG, TIF images"),
        getFileTypeTest(["png", "jpg", "jpeg", "tif", "tiff"]),
      )
      .test(
        "File size",
        t("The maximum file size should be {{size}}", { size: "10MB" }),
        getFileMaxSizeTest(10 * 1024 * 1024),
      ),
  };

  const validation = Form.useYupValidator(form, getSchema);

  const { data: { data: countries = [] } = {} } = useGetCountriesQuery({});

  const getInitialValue = (dataIndex, type) => {
    if (type !== "image") {
      return initialValues[dataIndex];
    }
  };

  const submit = (values) => onSubmit({ ...initialValues, ...values, oldMap: initialValues?.map, map: mapFileList });

  return (
    <Form
      onFinish={submit}
      id="contactInfoForm"
      name="contact-info"
      form={form}
      labelAlign="left"
      labelCol={{ justify: "left", span: 13 }}
      wrapperCol={{ justify: "right", span: 11 }}
      labelWrap
      colon={false}
    >
      <Row gutter={80}>
        {getFields(
          {
            current: initialValues?.map,
            fileList: mapFileList,
            onChange: setMapFileList,
          },
          countries,
        ).map((f) => (
          <Col key={f.dataIndex} className={styles.col} md={24} lg={12}>
            {f.title ? (
              <>
                <Form.Item
                  name={f.dataIndex}
                  key={f.dataIndex}
                  label={t(f.title)}
                  valuePropName={f.valuePropName}
                  initialValue={getInitialValue(f.dataIndex, f.type)}
                  getValueFromEvent={f.getValueFromEvent}
                  rules={validation.rules}
                  className={styles.item}
                >
                  {f.component ?? <Input />}
                </Form.Item>
                <Divider />
              </>
            ) : (
              <Space />
            )}
          </Col>
        ))}
      </Row>
      <Row gutter={80}>
        <Col span={1}>
          <Button loading={isUpdateLoading} htmlType="submit" type="primary">
            {t("Save")}
          </Button>
        </Col>
        <Col span={1}>
          <Button onClick={() => setIsEditing(false)}>{t("Cancel")}</Button>
        </Col>
      </Row>
    </Form>
  );
};

VenueDetailsForm.propTypes = {
  initialValues: PropTypes.any,
  setIsEditing: PropTypes.func,
  onSubmit: PropTypes.func,
  isUpdateLoading: PropTypes.bool,
};

export default VenueDetailsForm;
