import { ReportsApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ReportsApi);

const Reports = {
  clientId: {
    post: api.reportsClientsPost,
  },
  parameters: {
    get: api.reportsReportIdParametersGet,
    post: api.reportsClientsClientIDParametersPost,
  },
};

export default Reports;
