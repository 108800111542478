/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BuildingResponseModel } from '../apiModels';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { ForumBuildingRequestModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ReferencedObjectCannotBeDeletedExceptionResponse } from '../apiModels';
// @ts-ignore
import { UnknownObjectExceptionResponse } from '../apiModels';
/**
 * ForumBuildingApi - axios parameter creator
 * @export
 */
export const ForumBuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsFacilitiesExportGet: async (forumId: number, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsFacilitiesExportGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/buildings/facilities/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdDelete: async (forumId: number, forumBuildingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdDelete', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdDelete', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a forum venue\'s building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdGet: async (forumId: number, forumBuildingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdGet', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a building in a forum\'s venue
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {ForumBuildingRequestModel} [forumBuildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdPut: async (forumId: number, forumBuildingId: number, forumBuildingRequestModel?: ForumBuildingRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdPut', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdPut', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumBuildingRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of buildings in a forum\'s venue
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/buildings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a forum building
         * @param {number} forumId 
         * @param {ForumBuildingRequestModel} [forumBuildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsPost: async (forumId: number, forumBuildingRequestModel?: ForumBuildingRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/buildings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumBuildingRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumBuildingApi - functional programming interface
 * @export
 */
export const ForumBuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumBuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsFacilitiesExportGet(forumId: number, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsFacilitiesExportGet(forumId, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdDelete(forumId: number, forumBuildingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdDelete(forumId, forumBuildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a forum venue\'s building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdGet(forumId: number, forumBuildingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdGet(forumId, forumBuildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a building in a forum\'s venue
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {ForumBuildingRequestModel} [forumBuildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdPut(forumId: number, forumBuildingId: number, forumBuildingRequestModel?: ForumBuildingRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdPut(forumId, forumBuildingId, forumBuildingRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of buildings in a forum\'s venue
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a forum building
         * @param {number} forumId 
         * @param {ForumBuildingRequestModel} [forumBuildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsPost(forumId: number, forumBuildingRequestModel?: ForumBuildingRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsPost(forumId, forumBuildingRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumBuildingApi - factory interface
 * @export
 */
export const ForumBuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumBuildingApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsFacilitiesExportGet(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsForumIdVenueBuildingsFacilitiesExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a forum building
         * @param {ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdDelete(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a forum venue\'s building
         * @param {ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdGet(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<BuildingResponseModel> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdGet(requestParameters.forumId, requestParameters.forumBuildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a building in a forum\'s venue
         * @param {ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdPut(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumBuildingRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of buildings in a forum\'s venue
         * @param {ForumBuildingApiForumsForumIdVenueBuildingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsGet(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BuildingResponseModel>> {
            return localVarFp.forumsForumIdVenueBuildingsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a forum building
         * @param {ForumBuildingApiForumsForumIdVenueBuildingsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsPost(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsPostRequest, options?: AxiosRequestConfig): AxiosPromise<BuildingResponseModel> {
            return localVarFp.forumsForumIdVenueBuildingsPost(requestParameters.forumId, requestParameters.forumBuildingRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdVenueBuildingsFacilitiesExportGet operation in ForumBuildingApi.
 * @export
 * @interface ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGetRequest
 */
export interface ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {FileExportType}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdDelete operation in ForumBuildingApi.
 * @export
 * @interface ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDeleteRequest
 */
export interface ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDelete
     */
    readonly forumBuildingId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdGet operation in ForumBuildingApi.
 * @export
 * @interface ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGetRequest
 */
export interface ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGet
     */
    readonly forumBuildingId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdPut operation in ForumBuildingApi.
 * @export
 * @interface ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPutRequest
 */
export interface ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPut
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {ForumBuildingRequestModel}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPut
     */
    readonly forumBuildingRequestModel?: ForumBuildingRequestModel
}

/**
 * Request parameters for forumsForumIdVenueBuildingsGet operation in ForumBuildingApi.
 * @export
 * @interface ForumBuildingApiForumsForumIdVenueBuildingsGetRequest
 */
export interface ForumBuildingApiForumsForumIdVenueBuildingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsPost operation in ForumBuildingApi.
 * @export
 * @interface ForumBuildingApiForumsForumIdVenueBuildingsPostRequest
 */
export interface ForumBuildingApiForumsForumIdVenueBuildingsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumBuildingRequestModel}
     * @memberof ForumBuildingApiForumsForumIdVenueBuildingsPost
     */
    readonly forumBuildingRequestModel?: ForumBuildingRequestModel
}

/**
 * ForumBuildingApi - object-oriented interface
 * @export
 * @class ForumBuildingApi
 * @extends {BaseAPI}
 */
export class ForumBuildingApi extends BaseAPI {
    /**
     * 
     * @param {ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumBuildingApi
     */
    public forumsForumIdVenueBuildingsFacilitiesExportGet(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsFacilitiesExportGetRequest, options?: AxiosRequestConfig) {
        return ForumBuildingApiFp(this.configuration).forumsForumIdVenueBuildingsFacilitiesExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a forum building
     * @param {ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumBuildingApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdDelete(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumBuildingApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a forum venue\'s building
     * @param {ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumBuildingApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdGet(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdGetRequest, options?: AxiosRequestConfig) {
        return ForumBuildingApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdGet(requestParameters.forumId, requestParameters.forumBuildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a building in a forum\'s venue
     * @param {ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumBuildingApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdPut(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsForumBuildingIdPutRequest, options?: AxiosRequestConfig) {
        return ForumBuildingApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumBuildingRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of buildings in a forum\'s venue
     * @param {ForumBuildingApiForumsForumIdVenueBuildingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumBuildingApi
     */
    public forumsForumIdVenueBuildingsGet(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsGetRequest, options?: AxiosRequestConfig) {
        return ForumBuildingApiFp(this.configuration).forumsForumIdVenueBuildingsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a forum building
     * @param {ForumBuildingApiForumsForumIdVenueBuildingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumBuildingApi
     */
    public forumsForumIdVenueBuildingsPost(requestParameters: ForumBuildingApiForumsForumIdVenueBuildingsPostRequest, options?: AxiosRequestConfig) {
        return ForumBuildingApiFp(this.configuration).forumsForumIdVenueBuildingsPost(requestParameters.forumId, requestParameters.forumBuildingRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

