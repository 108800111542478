export const DefaultSortOptions = { Company: "Company", Surname: "Surname" };

export const CancellationsSortOptions = {
  Company: "Company",
  Surname: "Surname",
  "Date-Ascending": "Date - Ascending",
  "Date-Descending": "Date - Descending",
};

export const AllMealtimes = "AllMealtimes";
