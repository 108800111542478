import { useQueryClient } from "@tanstack/react-query";
import { Select } from "antd";
import { useUpdateGdprRequestMutation } from "api/mutations/gdprRequests";
import { useGdprRequestsQuery } from "api/queries/gdprRequests";
import KEYS from "api/queries/keys";
import { Table, Typography } from "components/styleguide";
import { GdprRequestModel, GdprRequestStatus } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";

import styles from "../styles.module.less";

const ParticipationCodesTable = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate, isLoading: isMutationLoading } = useUpdateGdprRequestMutation();

  const { response: { data: { data: { items = [] } = {} } = {}, isLoading } = {}, pagination } =
    useModernQueryWithPaginationAndOrder(
      useGdprRequestsQuery,
      {},
      {},
      { defaultPageSize: 30, pageSizeOptions: [30, 50, 100] },
    );

  const updateGdprStatus = useCallback(
    (gdprRequest: GdprRequestModel) => {
      if (gdprRequest.id) {
        mutate(
          {
            gdprRequestId: gdprRequest.id,
            gdprRequestStatusModel: { status: gdprRequest.status },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_GDPR_REQUESTS]);
              toast.success(t("Successfully updated"));
            },
            onError: () => {
              toast.error("Something went wrong");
            },
          },
        );
      }
    },
    [queryClient, mutate],
  );

  const handleStatusChange = useCallback(
    (status: GdprRequestStatus, record: GdprRequestModel) => {
      if (status === record?.status) return;

      updateGdprStatus({ ...record, status });
    },
    [updateGdprStatus],
  );

  const columns = useMemo(
    () => [
      buildColumn(t("Name"), "name"),
      buildColumn(t("Company"), "company"),
      buildColumn(t("Email"), "email"),
      buildColumn(t("Reason for Request"), "reason", {
        render: (reason: string) => <Typography.Text>{reason}</Typography.Text>,
      }),
      buildColumn(t("Status"), "status", {
        render: (status: GdprRequestStatus, record: GdprRequestModel) => {
          return (
            <Select
              className={styles.fullWidth}
              value={status}
              onSelect={(selectedStatus: GdprRequestStatus) => handleStatusChange(selectedStatus, record)}
            >
              {Object.entries(GdprRequestStatus).map(([key, label]) => (
                <Select.Option key={key} value={key}>
                  {t(label as string)}
                </Select.Option>
              ))}
            </Select>
          );
        },
      }),
    ],
    [t, handleStatusChange],
  );

  return (
    <Table
      id="gdpr-requests-table"
      dataSource={items}
      columns={columns}
      bordered
      loading={isLoading || isMutationLoading}
      rowKey="id"
      pagination={pagination}
    />
  );
};

export default ParticipationCodesTable;
