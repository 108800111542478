import { UseQueryResult } from "@tanstack/react-query/src/types";
import { SelectProps } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { AxiosResponse } from "axios";
import React, { useMemo } from "react";

import Select from "../Select";
import Spin from "../Spin";

type GetAxiosData<T> = T extends UseQueryResult<infer Data>
  ? Data extends AxiosResponse<infer Response>
    ? Response
    : never
  : never;

const isAxiosResponse = (response: unknown): response is AxiosResponse => {
  if (!response || typeof response !== "object") {
    return false;
  }

  return Object.hasOwn(response, "data");
};

type Option = { value?: string | number; label?: string };
type Options = Array<Option>;

interface AsyncSelectProps<
  TData,
  TError,
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
  Query extends UseQueryResult<TData, TError> = UseQueryResult<TData, TError>,
> extends SelectProps<ValueType, OptionType> {
  query: Query;
  optionsMapper?: (response: GetAxiosData<Query>) => Options;
}

const AsyncSelect = <
  ValueType,
  OptionType extends BaseOptionType | DefaultOptionType,
  TData,
  TError,
  Query extends UseQueryResult<TData, TError>,
>({
  query,
  optionsMapper,
  ...props
}: AsyncSelectProps<TData, TError, ValueType, OptionType, Query>) => {
  const { isLoading, data } = query;

  const options = useMemo<Options>(() => {
    if (!data || !isAxiosResponse(data) || !optionsMapper) {
      return (data ?? []) as Options;
    }

    return optionsMapper(data.data);
  }, [data, optionsMapper]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Select {...props}>
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AsyncSelect;
