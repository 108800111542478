import {
  QuestionnaireContentQuestion,
  QuestionnaireProfileDetails,
  QuestionnaireProfileDetailsQuestionProfileDetails,
  QuestionQuestionType,
} from "../../../../../../../../../../generated/api";
import { filterQuestionsByType } from "../../../../../../../../../../utils/questionnaireContentUtils";
import { ListingTableProps } from "../components/Table";
import fillMappingWithDefaultValue from "./fillMappingWithDefaultValue";

type Questions = Array<QuestionnaireContentQuestion>;

const SUPPORTED_TYPES: Array<QuestionQuestionType> = ["FreeText", "Checkbox", "RadioButton", "DropdownList"];

const mapQuestionsWithMappings = (
  questions: Questions,
  mappings: QuestionnaireProfileDetails,
): ListingTableProps["value"] => {
  const map = new Map<
    QuestionnaireProfileDetailsQuestionProfileDetails["id"],
    QuestionnaireProfileDetailsQuestionProfileDetails
  >();

  mappings.questions?.forEach((question) => {
    map.set(question.id, question);
  });

  const availableQuestions = filterQuestionsByType(questions, SUPPORTED_TYPES);
  return availableQuestions.map((question) => {
    const mapping = map.get(question.id);

    if (!mapping) {
      return fillMappingWithDefaultValue(question);
    }

    return mapping;
  });
};

export default mapQuestionsWithMappings;
