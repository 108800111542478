import { JointItem } from "../../../../generated/api";
import ContentItem from "../content-item";
import References from "../references";

const collectForJoint = (refs: References, item: ContentItem<JointItem>): void => {
  const itemId = item.value.associatedSelectionItem;
  if (!itemId) {
    return;
  }

  refs.items.add(itemId);
};

export default collectForJoint;
