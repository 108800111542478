import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteTeamMember, useReorderTeamMembersMutation } from "api/mutations/itineraryBookletsForumTeamMembers";
import { useGetItineraryBookletTeamMembers } from "api/queries/itineraryBookletsForumTeamMembers";
import KEYS from "api/queries/keys";
import { DraggableTable } from "components/DraggableTable";
import { Button, Modal, Space } from "components/styleguide";
import { ItineraryBookletsForumTeamMemberResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";

import EditTeamMember from "./EditTeamMember";
import NewTeamMemberDrawer from "./NewTeamMemberDrawer";

import styles from "./styles.module.less";

const TeamMembers: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const forumId = toNumber(useParams().id);

  const [teamMembers, setTeamMembers] = useState<ItineraryBookletsForumTeamMemberResponseModel[]>();
  const [isAddTeamMemberDrawerVisible, setIsAddTeamMemberDrawerVisible] = useState(false);
  const [isEditTeamMemberDrawerVisible, setIsEditTeamMemberDrawerVisible] = useState<boolean>(false);
  const [activeTeamMember, setActiveTeamMember] = useState<ItineraryBookletsForumTeamMemberResponseModel | null>(null);

  const deleteTeamMemberQuery = useDeleteTeamMember();
  const reorderMutation = useReorderTeamMembersMutation();

  const itineraryTeamMembersQuery = useGetItineraryBookletTeamMembers(
    { forumId },
    {
      onSuccess: async ({ data }) => {
        setTeamMembers(data);
      },
    },
  );

  const onEditTeamMember = (teamMember: ItineraryBookletsForumTeamMemberResponseModel) => {
    setActiveTeamMember(teamMember);
    setIsEditTeamMemberDrawerVisible(true);
  };

  const onEditTeamMemberDrawerClose = () => {
    setIsEditTeamMemberDrawerVisible(false);
    setActiveTeamMember(null);
  };

  const onAddTeamMember = () => setIsAddTeamMemberDrawerVisible(true);

  const onAddTeamMemberDrawerClose = () => setIsAddTeamMemberDrawerVisible(false);

  const handleTeamMemberDelete = (memberId: number) => {
    Modal.confirm({
      title: t("confirmation"),
      content: t("personDeletionConfirm"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        await deleteTeamMemberQuery.mutateAsync(
          { forumId, memberId },
          {
            onSuccess: () => {
              toast.success(t("Team member deleted"));
              queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_TEAM_MEMBERS]);
              queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_UNASSIGNED_TEAM_MEMBERS]);
            },
          },
        );
      },
    });
  };

  const columns = [
    buildColumn(t("name"), "name"),
    buildColumn(t("Job Title"), "jobTitle"),
    buildColumn(t("Email address"), "email"),
    buildColumn(t("Mobile phone"), "mobilePhone"),
    buildColumn(t("Phone Number"), "businessPhone"),
    buildColumn("", "id", {
      align: "center",
      render: (_: number, teamMember: ItineraryBookletsForumTeamMemberResponseModel) => (
        <div className={styles.actionButtons}>
          <Button onClick={() => onEditTeamMember(teamMember)}>{t("Edit")}</Button>
          <DeleteFilled
            className="delete-icon"
            onClick={() => teamMember.id && handleTeamMemberDelete(teamMember.id)}
          />
        </div>
      ),
    }),
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (teamMembers && over) {
      const orderedTeamMembers = teamMembers.sort((a, b) => (a.order && b.order ? a.order - b.order : 0));

      const oldIndex = orderedTeamMembers.findIndex((item) => item.order === active.id);
      const newIndex = orderedTeamMembers.findIndex((item) => item.order === over.id);

      const updatedTeamMembers = arrayMove(orderedTeamMembers, oldIndex, newIndex);
      const updatedTeamMember = updatedTeamMembers[newIndex];

      setTeamMembers(updatedTeamMembers);

      if (updatedTeamMember.id) {
        reorderMutation.mutate(
          {
            forumId,
            memberId: updatedTeamMember.id,
            priorityPreferenceRequestModel: { priority: newIndex + 1 },
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_TEAM_MEMBERS]);
            },
          },
        );
      }
    }
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Button onClick={onAddTeamMember}>{t("Add team member")}</Button>
      <DraggableTable
        id="teamMembersTable"
        data-testid="teamMembersTab"
        dataSource={teamMembers}
        columns={columns}
        pagination={false}
        bordered={true}
        rowKey="order"
        onDragEnd={onDragEnd}
        loading={itineraryTeamMembersQuery.isLoading}
        disableOrdering={false}
      ></DraggableTable>
      <NewTeamMemberDrawer
        forumId={forumId}
        isVisible={isAddTeamMemberDrawerVisible}
        onClose={onAddTeamMemberDrawerClose}
      />
      {activeTeamMember && (
        <EditTeamMember
          forumId={forumId}
          teamMember={activeTeamMember}
          isVisible={isEditTeamMemberDrawerVisible}
          onClose={onEditTeamMemberDrawerClose}
        />
      )}
    </Space>
  );
};

export default TeamMembers;
