import EnumBuilder from "../utils/enumBuilderUtils";

const builder = new EnumBuilder();
const EnumClass = builder.add("Open", "Open").add("Draft", "Draft").add("Closed", "Closed").build();

class Status extends EnumClass {
  get isOpen() {
    return this.value === "Open";
  }

  get isDraft() {
    return this.value === "Draft";
  }

  get isClosed() {
    return this.value === "Closed";
  }
}

export default Status;
