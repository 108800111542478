/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssignAttendeeModel } from '../apiModels';
// @ts-ignore
import { AttendeeActionModel } from '../apiModels';
// @ts-ignore
import { AttendeeAddressInfoRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeAddressInformationModel } from '../apiModels';
// @ts-ignore
import { AttendeeAdvancedFilterTypes } from '../apiModels';
// @ts-ignore
import { AttendeeAttendanceDetailsModel } from '../apiModels';
// @ts-ignore
import { AttendeeBFRDateRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeContactDetailsInfoRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeContactDetailsModel } from '../apiModels';
// @ts-ignore
import { AttendeeDescriptionModel } from '../apiModels';
// @ts-ignore
import { AttendeeDescriptionRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeDietaryRequirementsModel } from '../apiModels';
// @ts-ignore
import { AttendeeFeedBackStatus } from '../apiModels';
// @ts-ignore
import { AttendeeInformationModel } from '../apiModels';
// @ts-ignore
import { AttendeeModel } from '../apiModels';
// @ts-ignore
import { AttendeeMoveToCompanyRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeePartnerModel } from '../apiModels';
// @ts-ignore
import { AttendeePartnerResponseModel } from '../apiModels';
// @ts-ignore
import { AttendeeReContactResponse } from '../apiModels';
// @ts-ignore
import { AttendeeReplacementRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeSocialLinksModel } from '../apiModels';
// @ts-ignore
import { AttendeeSocialLinksRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeStatus } from '../apiModels';
// @ts-ignore
import { CompanyAddressRequestModel } from '../apiModels';
// @ts-ignore
import { CompanyAddressResponseModel } from '../apiModels';
// @ts-ignore
import { DuplicateItemExceptionResponse } from '../apiModels';
// @ts-ignore
import { FieldsCannotBeEqualExceptionResponse } from '../apiModels';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { ForumAttendeeHotelRoomAllocationRequestModel } from '../apiModels';
// @ts-ignore
import { ForumAttendeeHotelRoomAllocationResponseModel } from '../apiModels';
// @ts-ignore
import { ForumAttendeeProfilingQuestionnaireModel } from '../apiModels';
// @ts-ignore
import { ForumAttendeeResponseModel } from '../apiModels';
// @ts-ignore
import { ForumAttendeeSelectionSiteStatus } from '../apiModels';
// @ts-ignore
import { ForumAttendeesInformation } from '../apiModels';
// @ts-ignore
import { GroupWithParticipationCodeModel } from '../apiModels';
// @ts-ignore
import { InvalidModelStateExceptionResponse } from '../apiModels';
// @ts-ignore
import { InvalidTimePeriodExceptionResponse } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundException } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeHistoryModel } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeManageDescriptionModel } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeModel } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeePotentialReplacementResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeesByParticipationTypeModel } from '../apiModels';
// @ts-ignore
import { ParticipationCodeType } from '../apiModels';
// @ts-ignore
import { ProblemDetails } from '../apiModels';
// @ts-ignore
import { QuestionnaireSubmissionStatus } from '../apiModels';
// @ts-ignore
import { ReasonForMeeting } from '../apiModels';
// @ts-ignore
import { SpeakerTrackingRequestModel } from '../apiModels';
// @ts-ignore
import { SpeakerTrackingResponseModel } from '../apiModels';
/**
 * ForumAttendeeApi - axios parameter creator
 * @export
 */
export const ForumAttendeeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets attendees\'s address info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAddressInfoGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAddressInfoGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAddressInfoGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/address-info`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a forum attendee\'s address information
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeAddressInfoRequestModel} [attendeeAddressInfoRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAddressInfoPut: async (forumId: number, attendeeId: number, attendeeAddressInfoRequestModel?: AttendeeAddressInfoRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAddressInfoPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAddressInfoPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/address-info`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeAddressInfoRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees\'s attendance info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAttendanceDetailsGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAttendanceDetailsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAttendanceDetailsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/attendance-details`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeBFRDateRequestModel} [attendeeBFRDateRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdBfrDatePut: async (forumId: number, attendeeId: number, attendeeBFRDateRequestModel?: AttendeeBFRDateRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdBfrDatePut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdBfrDatePut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/bfr-date`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeBFRDateRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdCompanyAddressGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdCompanyAddressGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdCompanyAddressGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/company-address`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {CompanyAddressRequestModel} [companyAddressRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdCompanyAddressPut: async (forumId: number, attendeeId: number, companyAddressRequestModel?: CompanyAddressRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdCompanyAddressPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdCompanyAddressPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/company-address`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyAddressRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Moves an Attendee to a different ForumCompany from the same forum, \\  with the possibility of changing his job title and his contact information
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeMoveToCompanyRequestModel} [attendeeMoveToCompanyRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdCompanyPut: async (forumId: number, attendeeId: number, attendeeMoveToCompanyRequestModel?: AttendeeMoveToCompanyRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdCompanyPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdCompanyPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/company`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeMoveToCompanyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees\'s contact info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdContactDetailsGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdContactDetailsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdContactDetailsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/contact-details`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeContactDetailsInfoRequestModel} [attendeeContactDetailsInfoRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdContactDetailsPut: async (forumId: number, attendeeId: number, attendeeContactDetailsInfoRequestModel?: AttendeeContactDetailsInfoRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdContactDetailsPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdContactDetailsPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/contact-details`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeContactDetailsInfoRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees\'s description info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDescriptionGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDescriptionGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDescriptionGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/description`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeDescriptionRequestModel} [attendeeDescriptionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDescriptionPut: async (forumId: number, attendeeId: number, attendeeDescriptionRequestModel?: AttendeeDescriptionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDescriptionPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDescriptionPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/description`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeDescriptionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees\'s main info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDetailsGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDetailsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDetailsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/details`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attendee\'s dietary requirements
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDietaryRequirementsGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDietaryRequirementsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDietaryRequirementsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/dietary-requirements`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update attendee\'s dietary requirements
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeDietaryRequirementsModel} [attendeeDietaryRequirementsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDietaryRequirementsPut: async (forumId: number, attendeeId: number, attendeeDietaryRequirementsModel?: AttendeeDietaryRequirementsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDietaryRequirementsPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdDietaryRequirementsPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/dietary-requirements`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeDietaryRequirementsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a forum attendee\'s details
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} [userTimeZoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdGet: async (forumId: number, attendeeId: number, userTimeZoneOffset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userTimeZoneOffset !== undefined) {
                localVarQueryParameter['userTimeZoneOffset'] = userTimeZoneOffset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [name] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdGroupsGet: async (forumId: number, attendeeId: number, name?: string, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdGroupsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdGroupsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/groups`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [forumCode] 
         * @param {string} [forumName] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdHistoryGet: async (forumId: number, attendeeId: number, forumCode?: string, forumName?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdHistoryGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdHistoryGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/history`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forumCode !== undefined) {
                localVarQueryParameter['ForumCode'] = forumCode;
            }

            if (forumName !== undefined) {
                localVarQueryParameter['ForumName'] = forumName;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a ForumAttendee\'s hotel room allocation data
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/hotel-room-allocation`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets a ForumAttendee\'s hotel room allocation data
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {ForumAttendeeHotelRoomAllocationRequestModel} [forumAttendeeHotelRoomAllocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut: async (forumId: number, attendeeId: number, forumAttendeeHotelRoomAllocationRequestModel?: ForumAttendeeHotelRoomAllocationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/hotel-room-allocation`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumAttendeeHotelRoomAllocationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Make attendee attending (form Cancelled, Replace or CancelledWithFee)
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMakeAttendingPut: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMakeAttendingPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMakeAttendingPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/make-attending`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees\'s partner info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPartnerGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPartnerGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPartnerGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/partner`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a forum attendee\'s spouse/partner
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeePartnerModel} [attendeePartnerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPartnerPost: async (forumId: number, attendeeId: number, attendeePartnerModel?: AttendeePartnerModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPartnerPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPartnerPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/partner`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeePartnerModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeActionModel} [attendeeActionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPatch: async (forumId: number, attendeeId: number, attendeeActionModel?: AttendeeActionModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPatch', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPatch', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeActionModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPotentialReplacementsGet: async (forumId: number, attendeeId: number, orderBy?: string, firstName?: string, lastName?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPotentialReplacementsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPotentialReplacementsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/potential-replacements`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/profiling-questionnaire-answers`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the reason for meeting options configured for the ReasonForMeeting (V1/V2/V3) item in the attendee\'s selection site
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdReasonsForMeetingGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdReasonsForMeetingGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdReasonsForMeetingGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/reasons-for-meeting`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendee list of possbile ReContacts based on the ForumGroups that his ParticipationCodes makes him be part of
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdRecontactsGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdRecontactsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdRecontactsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/recontacts`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Replace a forum attendee with a person from the same company
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeReplacementRequestModel} [attendeeReplacementRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdReplacementPost: async (forumId: number, attendeeId: number, attendeeReplacementRequestModel?: AttendeeReplacementRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdReplacementPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdReplacementPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/replacement`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeReplacementRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees\'s social links info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSocialLinksGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSocialLinksGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSocialLinksGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/social-links`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a forum attendee\'s social links
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeSocialLinksRequestModel} [attendeeSocialLinksRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSocialLinksPut: async (forumId: number, attendeeId: number, attendeeSocialLinksRequestModel?: AttendeeSocialLinksRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSocialLinksPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSocialLinksPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/social-links`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeSocialLinksRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSpeakerTrackingGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSpeakerTrackingGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSpeakerTrackingGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/speaker-tracking`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {SpeakerTrackingRequestModel} [speakerTrackingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSpeakerTrackingPut: async (forumId: number, attendeeId: number, speakerTrackingRequestModel?: SpeakerTrackingRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSpeakerTrackingPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSpeakerTrackingPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/speaker-tracking`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(speakerTrackingRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [fullName] 
         * @param {string} [companyName] 
         * @param {Array<AttendeeFeedBackStatus>} [status] 
         * @param {ParticipationCodeType} [attendeeParticipationType] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesByParticipationTypeGet: async (forumId: number, fullName?: string, companyName?: string, status?: Array<AttendeeFeedBackStatus>, attendeeParticipationType?: ParticipationCodeType, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesByParticipationTypeGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees/by-participation-type`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fullName !== undefined) {
                localVarQueryParameter['FullName'] = fullName;
            }

            if (companyName !== undefined) {
                localVarQueryParameter['CompanyName'] = companyName;
            }

            if (status) {
                localVarQueryParameter['Status'] = status;
            }

            if (attendeeParticipationType !== undefined) {
                localVarQueryParameter['AttendeeParticipationType'] = attendeeParticipationType;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets attendees manage description info
         * @param {number} forumId 
         * @param {string} [description] 
         * @param {string} [attendeeName] 
         * @param {string} [attendeeCompanyName] 
         * @param {string} [peopleType] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesDescriptionsGet: async (forumId: number, description?: string, attendeeName?: string, attendeeCompanyName?: string, peopleType?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesDescriptionsGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees/descriptions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (attendeeName !== undefined) {
                localVarQueryParameter['AttendeeName'] = attendeeName;
            }

            if (attendeeCompanyName !== undefined) {
                localVarQueryParameter['AttendeeCompanyName'] = attendeeCompanyName;
            }

            if (peopleType !== undefined) {
                localVarQueryParameter['PeopleType'] = peopleType;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {string} [email] 
         * @param {string} [group] Searches by: any of the ForumGroups that the ForumAttendee belongs to       has the name in EM2.Models.Attendees.AttendeesPageRequest.Group
         * @param {Array<number>} [forumGroupIds] 
         * @param {Array<AttendeeStatus>} [attendeeStatus] 
         * @param {string} [jobTitle] 
         * @param {Array<string>} [participationCodes] 
         * @param {Array<string>} [peopleTypes] 
         * @param {AttendeeAdvancedFilterTypes} [advancedFilterType] 
         * @param {string} [globalSearch] Search by FullName, Email or CompanyName
         * @param {boolean} [onlySpeakers] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesExportGet: async (forumId: number, firstName?: string, lastName?: string, fullName?: string, company?: string, email?: string, group?: string, forumGroupIds?: Array<number>, attendeeStatus?: Array<AttendeeStatus>, jobTitle?: string, participationCodes?: Array<string>, peopleTypes?: Array<string>, advancedFilterType?: AttendeeAdvancedFilterTypes, globalSearch?: string, onlySpeakers?: boolean, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesExportGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['FullName'] = fullName;
            }

            if (company !== undefined) {
                localVarQueryParameter['Company'] = company;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (group !== undefined) {
                localVarQueryParameter['Group'] = group;
            }

            if (forumGroupIds) {
                localVarQueryParameter['ForumGroupIds'] = forumGroupIds;
            }

            if (attendeeStatus) {
                localVarQueryParameter['AttendeeStatus'] = attendeeStatus;
            }

            if (jobTitle !== undefined) {
                localVarQueryParameter['JobTitle'] = jobTitle;
            }

            if (participationCodes) {
                localVarQueryParameter['ParticipationCodes'] = participationCodes;
            }

            if (peopleTypes) {
                localVarQueryParameter['PeopleTypes'] = peopleTypes;
            }

            if (advancedFilterType !== undefined) {
                localVarQueryParameter['AdvancedFilterType'] = advancedFilterType;
            }

            if (globalSearch !== undefined) {
                localVarQueryParameter['GlobalSearch'] = globalSearch;
            }

            if (onlySpeakers !== undefined) {
                localVarQueryParameter['OnlySpeakers'] = onlySpeakers;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} personId 
         * @param {AssignAttendeeModel} [assignAttendeeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesFrompersonPersonIdPut: async (forumId: number, personId: number, assignAttendeeModel?: AssignAttendeeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesFrompersonPersonIdPut', 'forumId', forumId)
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('forumForumIdAttendeesFrompersonPersonIdPut', 'personId', personId)
            const localVarPath = `/forum/{forumId}/attendees/fromperson/{personId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(assignAttendeeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates multiple attendees by list of people ids
         * @param {number} forumId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesFrompersonPut: async (forumId: number, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesFrompersonPut', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees/fromperson`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {string} [email] 
         * @param {string} [group] Searches by: any of the ForumGroups that the ForumAttendee belongs to       has the name in EM2.Models.Attendees.AttendeesPageRequest.Group
         * @param {Array<number>} [forumGroupIds] 
         * @param {Array<AttendeeStatus>} [attendeeStatus] 
         * @param {string} [jobTitle] 
         * @param {Array<string>} [participationCodes] 
         * @param {Array<string>} [peopleTypes] 
         * @param {AttendeeAdvancedFilterTypes} [advancedFilterType] 
         * @param {string} [globalSearch] Search by FullName, Email or CompanyName
         * @param {boolean} [onlySpeakers] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {AttendeeAdvancedFilterTypes} [type] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {AttendeeAdvancedFilterTypes} [type2] 
         * @param {string} [startDate2] 
         * @param {string} [endDate2] 
         * @param {AttendeeAdvancedFilterTypes} [type3] 
         * @param {string} [startDate3] 
         * @param {string} [endDate3] 
         * @param {AttendeeAdvancedFilterTypes} [type4] 
         * @param {number} [questionnaireId] 
         * @param {Array<QuestionnaireSubmissionStatus>} [statuses] 
         * @param {AttendeeAdvancedFilterTypes} [type5] 
         * @param {number} [selectionSiteId] 
         * @param {ForumAttendeeSelectionSiteStatus} [status] 
         * @param {AttendeeAdvancedFilterTypes} [type6] 
         * @param {Array<string>} [groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesGet: async (forumId: number, firstName?: string, lastName?: string, fullName?: string, company?: string, email?: string, group?: string, forumGroupIds?: Array<number>, attendeeStatus?: Array<AttendeeStatus>, jobTitle?: string, participationCodes?: Array<string>, peopleTypes?: Array<string>, advancedFilterType?: AttendeeAdvancedFilterTypes, globalSearch?: string, onlySpeakers?: boolean, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, type?: AttendeeAdvancedFilterTypes, startDate?: string, endDate?: string, type2?: AttendeeAdvancedFilterTypes, startDate2?: string, endDate2?: string, type3?: AttendeeAdvancedFilterTypes, startDate3?: string, endDate3?: string, type4?: AttendeeAdvancedFilterTypes, questionnaireId?: number, statuses?: Array<QuestionnaireSubmissionStatus>, type5?: AttendeeAdvancedFilterTypes, selectionSiteId?: number, status?: ForumAttendeeSelectionSiteStatus, type6?: AttendeeAdvancedFilterTypes, groups?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (fullName !== undefined) {
                localVarQueryParameter['FullName'] = fullName;
            }

            if (company !== undefined) {
                localVarQueryParameter['Company'] = company;
            }

            if (email !== undefined) {
                localVarQueryParameter['Email'] = email;
            }

            if (group !== undefined) {
                localVarQueryParameter['Group'] = group;
            }

            if (forumGroupIds) {
                localVarQueryParameter['ForumGroupIds'] = forumGroupIds;
            }

            if (attendeeStatus) {
                localVarQueryParameter['AttendeeStatus'] = attendeeStatus;
            }

            if (jobTitle !== undefined) {
                localVarQueryParameter['JobTitle'] = jobTitle;
            }

            if (participationCodes) {
                localVarQueryParameter['ParticipationCodes'] = participationCodes;
            }

            if (peopleTypes) {
                localVarQueryParameter['PeopleTypes'] = peopleTypes;
            }

            if (advancedFilterType !== undefined) {
                localVarQueryParameter['AdvancedFilterType'] = advancedFilterType;
            }

            if (globalSearch !== undefined) {
                localVarQueryParameter['GlobalSearch'] = globalSearch;
            }

            if (onlySpeakers !== undefined) {
                localVarQueryParameter['OnlySpeakers'] = onlySpeakers;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (type2 !== undefined) {
                localVarQueryParameter['Type'] = type2;
            }

            if (startDate2 !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate2 as any instanceof Date) ?
                    (startDate2 as any).toISOString() :
                    startDate2;
            }

            if (endDate2 !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate2 as any instanceof Date) ?
                    (endDate2 as any).toISOString() :
                    endDate2;
            }

            if (type3 !== undefined) {
                localVarQueryParameter['Type'] = type3;
            }

            if (startDate3 !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate3 as any instanceof Date) ?
                    (startDate3 as any).toISOString() :
                    startDate3;
            }

            if (endDate3 !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate3 as any instanceof Date) ?
                    (endDate3 as any).toISOString() :
                    endDate3;
            }

            if (type4 !== undefined) {
                localVarQueryParameter['Type'] = type4;
            }

            if (questionnaireId !== undefined) {
                localVarQueryParameter['QuestionnaireId'] = questionnaireId;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (type5 !== undefined) {
                localVarQueryParameter['Type'] = type5;
            }

            if (selectionSiteId !== undefined) {
                localVarQueryParameter['SelectionSiteId'] = selectionSiteId;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (type6 !== undefined) {
                localVarQueryParameter['Type'] = type6;
            }

            if (groups) {
                localVarQueryParameter['Groups'] = groups;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get forum attendees summury
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesSummaryGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesSummaryGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees/summary`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of attendees unassigned to bedrooms
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesUnassignedBedroomsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesUnassignedBedroomsGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/attendees/unassigned-bedrooms`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeApi - functional programming interface
 * @export
 */
export const ForumAttendeeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets attendees\'s address info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAddressInfoGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeAddressInformationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAddressInfoGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a forum attendee\'s address information
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeAddressInfoRequestModel} [attendeeAddressInfoRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAddressInfoPut(forumId: number, attendeeId: number, attendeeAddressInfoRequestModel?: AttendeeAddressInfoRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAddressInfoPut(forumId, attendeeId, attendeeAddressInfoRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees\'s attendance info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAttendanceDetailsGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeAttendanceDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAttendanceDetailsGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeBFRDateRequestModel} [attendeeBFRDateRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdBfrDatePut(forumId: number, attendeeId: number, attendeeBFRDateRequestModel?: AttendeeBFRDateRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdBfrDatePut(forumId, attendeeId, attendeeBFRDateRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdCompanyAddressGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAddressResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdCompanyAddressGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {CompanyAddressRequestModel} [companyAddressRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdCompanyAddressPut(forumId: number, attendeeId: number, companyAddressRequestModel?: CompanyAddressRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyAddressResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdCompanyAddressPut(forumId, attendeeId, companyAddressRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Moves an Attendee to a different ForumCompany from the same forum, \\  with the possibility of changing his job title and his contact information
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeMoveToCompanyRequestModel} [attendeeMoveToCompanyRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdCompanyPut(forumId: number, attendeeId: number, attendeeMoveToCompanyRequestModel?: AttendeeMoveToCompanyRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdCompanyPut(forumId, attendeeId, attendeeMoveToCompanyRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees\'s contact info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdContactDetailsGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeContactDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdContactDetailsGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeContactDetailsInfoRequestModel} [attendeeContactDetailsInfoRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdContactDetailsPut(forumId: number, attendeeId: number, attendeeContactDetailsInfoRequestModel?: AttendeeContactDetailsInfoRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdContactDetailsPut(forumId, attendeeId, attendeeContactDetailsInfoRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees\'s description info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdDescriptionGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeDescriptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdDescriptionGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeDescriptionRequestModel} [attendeeDescriptionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdDescriptionPut(forumId: number, attendeeId: number, attendeeDescriptionRequestModel?: AttendeeDescriptionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdDescriptionPut(forumId, attendeeId, attendeeDescriptionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees\'s main info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdDetailsGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeInformationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdDetailsGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get attendee\'s dietary requirements
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdDietaryRequirementsGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeDietaryRequirementsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdDietaryRequirementsGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update attendee\'s dietary requirements
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeDietaryRequirementsModel} [attendeeDietaryRequirementsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdDietaryRequirementsPut(forumId: number, attendeeId: number, attendeeDietaryRequirementsModel?: AttendeeDietaryRequirementsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeDietaryRequirementsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdDietaryRequirementsPut(forumId, attendeeId, attendeeDietaryRequirementsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a forum attendee\'s details
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} [userTimeZoneOffset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdGet(forumId: number, attendeeId: number, userTimeZoneOffset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdGet(forumId, attendeeId, userTimeZoneOffset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [name] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdGroupsGet(forumId: number, attendeeId: number, name?: string, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GroupWithParticipationCodeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdGroupsGet(forumId, attendeeId, name, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [forumCode] 
         * @param {string} [forumName] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdHistoryGet(forumId: number, attendeeId: number, forumCode?: string, forumName?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeHistoryModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdHistoryGet(forumId, attendeeId, forumCode, forumName, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a ForumAttendee\'s hotel room allocation data
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeeHotelRoomAllocationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets a ForumAttendee\'s hotel room allocation data
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {ForumAttendeeHotelRoomAllocationRequestModel} [forumAttendeeHotelRoomAllocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut(forumId: number, attendeeId: number, forumAttendeeHotelRoomAllocationRequestModel?: ForumAttendeeHotelRoomAllocationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut(forumId, attendeeId, forumAttendeeHotelRoomAllocationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Make attendee attending (form Cancelled, Replace or CancelledWithFee)
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdMakeAttendingPut(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdMakeAttendingPut(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees\'s partner info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPartnerGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeePartnerResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPartnerGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a forum attendee\'s spouse/partner
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeePartnerModel} [attendeePartnerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPartnerPost(forumId: number, attendeeId: number, attendeePartnerModel?: AttendeePartnerModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPartnerPost(forumId, attendeeId, attendeePartnerModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cancel an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeActionModel} [attendeeActionModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPatch(forumId: number, attendeeId: number, attendeeActionModel?: AttendeeActionModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPatch(forumId, attendeeId, attendeeActionModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPotentialReplacementsGet(forumId: number, attendeeId: number, orderBy?: string, firstName?: string, lastName?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeePotentialReplacementResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPotentialReplacementsGet(forumId, attendeeId, orderBy, firstName, lastName, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumAttendeeProfilingQuestionnaireModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the reason for meeting options configured for the ReasonForMeeting (V1/V2/V3) item in the attendee\'s selection site
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdReasonsForMeetingGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReasonForMeeting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdReasonsForMeetingGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendee list of possbile ReContacts based on the ForumGroups that his ParticipationCodes makes him be part of
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdRecontactsGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeReContactResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdRecontactsGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Replace a forum attendee with a person from the same company
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeReplacementRequestModel} [attendeeReplacementRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdReplacementPost(forumId: number, attendeeId: number, attendeeReplacementRequestModel?: AttendeeReplacementRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdReplacementPost(forumId, attendeeId, attendeeReplacementRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees\'s social links info
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSocialLinksGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeSocialLinksModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSocialLinksGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a forum attendee\'s social links
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeSocialLinksRequestModel} [attendeeSocialLinksRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSocialLinksPut(forumId: number, attendeeId: number, attendeeSocialLinksRequestModel?: AttendeeSocialLinksRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSocialLinksPut(forumId, attendeeId, attendeeSocialLinksRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSpeakerTrackingGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpeakerTrackingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSpeakerTrackingGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {SpeakerTrackingRequestModel} [speakerTrackingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSpeakerTrackingPut(forumId: number, attendeeId: number, speakerTrackingRequestModel?: SpeakerTrackingRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSpeakerTrackingPut(forumId, attendeeId, speakerTrackingRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [fullName] 
         * @param {string} [companyName] 
         * @param {Array<AttendeeFeedBackStatus>} [status] 
         * @param {ParticipationCodeType} [attendeeParticipationType] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesByParticipationTypeGet(forumId: number, fullName?: string, companyName?: string, status?: Array<AttendeeFeedBackStatus>, attendeeParticipationType?: ParticipationCodeType, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeesByParticipationTypeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesByParticipationTypeGet(forumId, fullName, companyName, status, attendeeParticipationType, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets attendees manage description info
         * @param {number} forumId 
         * @param {string} [description] 
         * @param {string} [attendeeName] 
         * @param {string} [attendeeCompanyName] 
         * @param {string} [peopleType] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesDescriptionsGet(forumId: number, description?: string, attendeeName?: string, attendeeCompanyName?: string, peopleType?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeManageDescriptionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesDescriptionsGet(forumId, description, attendeeName, attendeeCompanyName, peopleType, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {string} [email] 
         * @param {string} [group] Searches by: any of the ForumGroups that the ForumAttendee belongs to       has the name in EM2.Models.Attendees.AttendeesPageRequest.Group
         * @param {Array<number>} [forumGroupIds] 
         * @param {Array<AttendeeStatus>} [attendeeStatus] 
         * @param {string} [jobTitle] 
         * @param {Array<string>} [participationCodes] 
         * @param {Array<string>} [peopleTypes] 
         * @param {AttendeeAdvancedFilterTypes} [advancedFilterType] 
         * @param {string} [globalSearch] Search by FullName, Email or CompanyName
         * @param {boolean} [onlySpeakers] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesExportGet(forumId: number, firstName?: string, lastName?: string, fullName?: string, company?: string, email?: string, group?: string, forumGroupIds?: Array<number>, attendeeStatus?: Array<AttendeeStatus>, jobTitle?: string, participationCodes?: Array<string>, peopleTypes?: Array<string>, advancedFilterType?: AttendeeAdvancedFilterTypes, globalSearch?: string, onlySpeakers?: boolean, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesExportGet(forumId, firstName, lastName, fullName, company, email, group, forumGroupIds, attendeeStatus, jobTitle, participationCodes, peopleTypes, advancedFilterType, globalSearch, onlySpeakers, pageSize, pageNumber, orderBy, orderDir, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} personId 
         * @param {AssignAttendeeModel} [assignAttendeeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesFrompersonPersonIdPut(forumId: number, personId: number, assignAttendeeModel?: AssignAttendeeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesFrompersonPersonIdPut(forumId, personId, assignAttendeeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates multiple attendees by list of people ids
         * @param {number} forumId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesFrompersonPut(forumId: number, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesFrompersonPut(forumId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [fullName] 
         * @param {string} [company] 
         * @param {string} [email] 
         * @param {string} [group] Searches by: any of the ForumGroups that the ForumAttendee belongs to       has the name in EM2.Models.Attendees.AttendeesPageRequest.Group
         * @param {Array<number>} [forumGroupIds] 
         * @param {Array<AttendeeStatus>} [attendeeStatus] 
         * @param {string} [jobTitle] 
         * @param {Array<string>} [participationCodes] 
         * @param {Array<string>} [peopleTypes] 
         * @param {AttendeeAdvancedFilterTypes} [advancedFilterType] 
         * @param {string} [globalSearch] Search by FullName, Email or CompanyName
         * @param {boolean} [onlySpeakers] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {AttendeeAdvancedFilterTypes} [type] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {AttendeeAdvancedFilterTypes} [type2] 
         * @param {string} [startDate2] 
         * @param {string} [endDate2] 
         * @param {AttendeeAdvancedFilterTypes} [type3] 
         * @param {string} [startDate3] 
         * @param {string} [endDate3] 
         * @param {AttendeeAdvancedFilterTypes} [type4] 
         * @param {number} [questionnaireId] 
         * @param {Array<QuestionnaireSubmissionStatus>} [statuses] 
         * @param {AttendeeAdvancedFilterTypes} [type5] 
         * @param {number} [selectionSiteId] 
         * @param {ForumAttendeeSelectionSiteStatus} [status] 
         * @param {AttendeeAdvancedFilterTypes} [type6] 
         * @param {Array<string>} [groups] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesGet(forumId: number, firstName?: string, lastName?: string, fullName?: string, company?: string, email?: string, group?: string, forumGroupIds?: Array<number>, attendeeStatus?: Array<AttendeeStatus>, jobTitle?: string, participationCodes?: Array<string>, peopleTypes?: Array<string>, advancedFilterType?: AttendeeAdvancedFilterTypes, globalSearch?: string, onlySpeakers?: boolean, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, type?: AttendeeAdvancedFilterTypes, startDate?: string, endDate?: string, type2?: AttendeeAdvancedFilterTypes, startDate2?: string, endDate2?: string, type3?: AttendeeAdvancedFilterTypes, startDate3?: string, endDate3?: string, type4?: AttendeeAdvancedFilterTypes, questionnaireId?: number, statuses?: Array<QuestionnaireSubmissionStatus>, type5?: AttendeeAdvancedFilterTypes, selectionSiteId?: number, status?: ForumAttendeeSelectionSiteStatus, type6?: AttendeeAdvancedFilterTypes, groups?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesGet(forumId, firstName, lastName, fullName, company, email, group, forumGroupIds, attendeeStatus, jobTitle, participationCodes, peopleTypes, advancedFilterType, globalSearch, onlySpeakers, pageSize, pageNumber, orderBy, orderDir, type, startDate, endDate, type2, startDate2, endDate2, type3, startDate3, endDate3, type4, questionnaireId, statuses, type5, selectionSiteId, status, type6, groups, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get forum attendees summury
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesSummaryGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeesInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesSummaryGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of attendees unassigned to bedrooms
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesUnassignedBedroomsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesUnassignedBedroomsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeApi - factory interface
 * @export
 */
export const ForumAttendeeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets attendees\'s address info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAddressInfoGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeAddressInformationModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAddressInfoGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a forum attendee\'s address information
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAddressInfoPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAddressInfoPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeAddressInfoRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees\'s attendance info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAttendanceDetailsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeAttendanceDetailsModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAttendanceDetailsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdBfrDatePut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdBfrDatePut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeBFRDateRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdCompanyAddressGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGetRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyAddressResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdCompanyAddressGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdCompanyAddressPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPutRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyAddressResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdCompanyAddressPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.companyAddressRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Moves an Attendee to a different ForumCompany from the same forum, \\  with the possibility of changing his job title and his contact information
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdCompanyPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdCompanyPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeMoveToCompanyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees\'s contact info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdContactDetailsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeContactDetailsModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdContactDetailsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdContactDetailsPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeeResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdContactDetailsPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeContactDetailsInfoRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees\'s description info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDescriptionGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeDescriptionModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdDescriptionGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDescriptionPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeeResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdDescriptionPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeDescriptionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees\'s main info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDetailsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeInformationModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdDetailsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attendee\'s dietary requirements
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDietaryRequirementsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeDietaryRequirementsModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdDietaryRequirementsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update attendee\'s dietary requirements
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdDietaryRequirementsPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPutRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeDietaryRequirementsModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdDietaryRequirementsPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeDietaryRequirementsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a forum attendee\'s details
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeeResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.userTimeZoneOffset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdGroupsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<GroupWithParticipationCodeModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdGroupsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.name, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdHistoryGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeHistoryModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdHistoryGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumCode, requestParameters.forumName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a ForumAttendee\'s hotel room allocation data
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeeHotelRoomAllocationResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets a ForumAttendee\'s hotel room allocation data
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumAttendeeHotelRoomAllocationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Make attendee attending (form Cancelled, Replace or CancelledWithFee)
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMakeAttendingPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdMakeAttendingPut(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees\'s partner info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPartnerGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeePartnerResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPartnerGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a forum attendee\'s spouse/partner
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPartnerPost(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPartnerPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeePartnerModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel an attendee
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPatch(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPatch(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeActionModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPotentialReplacementsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeePotentialReplacementResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPotentialReplacementsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.firstName, requestParameters.lastName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumAttendeeProfilingQuestionnaireModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the reason for meeting options configured for the ReasonForMeeting (V1/V2/V3) item in the attendee\'s selection site
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdReasonsForMeetingGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ReasonForMeeting>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdReasonsForMeetingGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendee list of possbile ReContacts based on the ForumGroups that his ParticipationCodes makes him be part of
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdRecontactsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeReContactResponse>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdRecontactsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Replace a forum attendee with a person from the same company
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdReplacementPost(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdReplacementPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeReplacementRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees\'s social links info
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSocialLinksGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGetRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeSocialLinksModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSocialLinksGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a forum attendee\'s social links
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSocialLinksPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSocialLinksPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeSocialLinksRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSpeakerTrackingGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGetRequest, options?: AxiosRequestConfig): AxiosPromise<SpeakerTrackingResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSpeakerTrackingGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSpeakerTrackingPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSpeakerTrackingPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.speakerTrackingRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesByParticipationTypeGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeesByParticipationTypeModel> {
            return localVarFp.forumForumIdAttendeesByParticipationTypeGet(requestParameters.forumId, requestParameters.fullName, requestParameters.companyName, requestParameters.status, requestParameters.attendeeParticipationType, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets attendees manage description info
         * @param {ForumAttendeeApiForumForumIdAttendeesDescriptionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesDescriptionsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesDescriptionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeManageDescriptionModel> {
            return localVarFp.forumForumIdAttendeesDescriptionsGet(requestParameters.forumId, requestParameters.description, requestParameters.attendeeName, requestParameters.attendeeCompanyName, requestParameters.peopleType, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesExportGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdAttendeesExportGet(requestParameters.forumId, requestParameters.firstName, requestParameters.lastName, requestParameters.fullName, requestParameters.company, requestParameters.email, requestParameters.group, requestParameters.forumGroupIds, requestParameters.attendeeStatus, requestParameters.jobTitle, requestParameters.participationCodes, requestParameters.peopleTypes, requestParameters.advancedFilterType, requestParameters.globalSearch, requestParameters.onlySpeakers, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesFrompersonPersonIdPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<number> {
            return localVarFp.forumForumIdAttendeesFrompersonPersonIdPut(requestParameters.forumId, requestParameters.personId, requestParameters.assignAttendeeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates multiple attendees by list of people ids
         * @param {ForumAttendeeApiForumForumIdAttendeesFrompersonPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesFrompersonPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesFrompersonPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesFrompersonPut(requestParameters.forumId, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeApiForumForumIdAttendeesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeModel> {
            return localVarFp.forumForumIdAttendeesGet(requestParameters.forumId, requestParameters.firstName, requestParameters.lastName, requestParameters.fullName, requestParameters.company, requestParameters.email, requestParameters.group, requestParameters.forumGroupIds, requestParameters.attendeeStatus, requestParameters.jobTitle, requestParameters.participationCodes, requestParameters.peopleTypes, requestParameters.advancedFilterType, requestParameters.globalSearch, requestParameters.onlySpeakers, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, requestParameters.startDate, requestParameters.endDate, requestParameters.type2, requestParameters.startDate2, requestParameters.endDate2, requestParameters.type3, requestParameters.startDate3, requestParameters.endDate3, requestParameters.type4, requestParameters.questionnaireId, requestParameters.statuses, requestParameters.type5, requestParameters.selectionSiteId, requestParameters.status, requestParameters.type6, requestParameters.groups, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get forum attendees summury
         * @param {ForumAttendeeApiForumForumIdAttendeesSummaryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesSummaryGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesSummaryGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeesInformation> {
            return localVarFp.forumForumIdAttendeesSummaryGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of attendees unassigned to bedrooms
         * @param {ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesUnassignedBedroomsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeModel>> {
            return localVarFp.forumForumIdAttendeesUnassignedBedroomsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAddressInfoGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAddressInfoPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeAddressInfoRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPut
     */
    readonly attendeeAddressInfoRequestModel?: AttendeeAddressInfoRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAttendanceDetailsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdBfrDatePut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeBFRDateRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePut
     */
    readonly attendeeBFRDateRequestModel?: AttendeeBFRDateRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdCompanyAddressGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdCompanyAddressPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {CompanyAddressRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPut
     */
    readonly companyAddressRequestModel?: CompanyAddressRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdCompanyPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeMoveToCompanyRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPut
     */
    readonly attendeeMoveToCompanyRequestModel?: AttendeeMoveToCompanyRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdContactDetailsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdContactDetailsPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeContactDetailsInfoRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPut
     */
    readonly attendeeContactDetailsInfoRequestModel?: AttendeeContactDetailsInfoRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdDescriptionGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdDescriptionPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeDescriptionRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPut
     */
    readonly attendeeDescriptionRequestModel?: AttendeeDescriptionRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdDetailsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdDietaryRequirementsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdDietaryRequirementsPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeDietaryRequirementsModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPut
     */
    readonly attendeeDietaryRequirementsModel?: AttendeeDietaryRequirementsModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGet
     */
    readonly userTimeZoneOffset?: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdGroupsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdHistoryGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly forumCode?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly forumName?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {ForumAttendeeHotelRoomAllocationRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPut
     */
    readonly forumAttendeeHotelRoomAllocationRequestModel?: ForumAttendeeHotelRoomAllocationRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdMakeAttendingPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPut
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPartnerGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPartnerPost operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPostRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeePartnerModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPost
     */
    readonly attendeePartnerModel?: AttendeePartnerModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPatch operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatchRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatch
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatch
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeActionModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatch
     */
    readonly attendeeActionModel?: AttendeeActionModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPotentialReplacementsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly lastName?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdReasonsForMeetingGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdRecontactsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdReplacementPost operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPostRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeReplacementRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPost
     */
    readonly attendeeReplacementRequestModel?: AttendeeReplacementRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSocialLinksGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSocialLinksPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeSocialLinksRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPut
     */
    readonly attendeeSocialLinksRequestModel?: AttendeeSocialLinksRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSpeakerTrackingGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSpeakerTrackingPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {SpeakerTrackingRequestModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPut
     */
    readonly speakerTrackingRequestModel?: SpeakerTrackingRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesByParticipationTypeGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly companyName?: string

    /**
     * 
     * @type {Array<AttendeeFeedBackStatus>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly status?: Array<AttendeeFeedBackStatus>

    /**
     * 
     * @type {ParticipationCodeType}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly attendeeParticipationType?: ParticipationCodeType

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesDescriptionsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesDescriptionsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesDescriptionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly attendeeName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly attendeeCompanyName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly peopleType?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesDescriptionsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesExportGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesExportGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly company?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly email?: string

    /**
     * Searches by: any of the ForumGroups that the ForumAttendee belongs to       has the name in EM2.Models.Attendees.AttendeesPageRequest.Group
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly group?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * 
     * @type {Array<AttendeeStatus>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly attendeeStatus?: Array<AttendeeStatus>

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly jobTitle?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly participationCodes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly peopleTypes?: Array<string>

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly advancedFilterType?: AttendeeAdvancedFilterTypes

    /**
     * Search by FullName, Email or CompanyName
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly globalSearch?: string

    /**
     * 
     * @type {boolean}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly onlySpeakers?: boolean

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {FileExportType}
     * @memberof ForumAttendeeApiForumForumIdAttendeesExportGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumForumIdAttendeesFrompersonPersonIdPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPut
     */
    readonly personId: number

    /**
     * 
     * @type {AssignAttendeeModel}
     * @memberof ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPut
     */
    readonly assignAttendeeModel?: AssignAttendeeModel
}

/**
 * Request parameters for forumForumIdAttendeesFrompersonPut operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesFrompersonPutRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesFrompersonPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesFrompersonPut
     */
    readonly forumId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesFrompersonPut
     */
    readonly requestBody?: Array<number>
}

/**
 * Request parameters for forumForumIdAttendeesGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly company?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly email?: string

    /**
     * Searches by: any of the ForumGroups that the ForumAttendee belongs to       has the name in EM2.Models.Attendees.AttendeesPageRequest.Group
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly group?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * 
     * @type {Array<AttendeeStatus>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly attendeeStatus?: Array<AttendeeStatus>

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly jobTitle?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly participationCodes?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly peopleTypes?: Array<string>

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly advancedFilterType?: AttendeeAdvancedFilterTypes

    /**
     * Search by FullName, Email or CompanyName
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly globalSearch?: string

    /**
     * 
     * @type {boolean}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly onlySpeakers?: boolean

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly type?: AttendeeAdvancedFilterTypes

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly endDate?: string

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly type2?: AttendeeAdvancedFilterTypes

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly startDate2?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly endDate2?: string

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly type3?: AttendeeAdvancedFilterTypes

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly startDate3?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly endDate3?: string

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly type4?: AttendeeAdvancedFilterTypes

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly questionnaireId?: number

    /**
     * 
     * @type {Array<QuestionnaireSubmissionStatus>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly statuses?: Array<QuestionnaireSubmissionStatus>

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly type5?: AttendeeAdvancedFilterTypes

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly selectionSiteId?: number

    /**
     * 
     * @type {ForumAttendeeSelectionSiteStatus}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly status?: ForumAttendeeSelectionSiteStatus

    /**
     * 
     * @type {AttendeeAdvancedFilterTypes}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly type6?: AttendeeAdvancedFilterTypes

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeApiForumForumIdAttendeesGet
     */
    readonly groups?: Array<string>
}

/**
 * Request parameters for forumForumIdAttendeesSummaryGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesSummaryGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesSummaryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesSummaryGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumForumIdAttendeesUnassignedBedroomsGet operation in ForumAttendeeApi.
 * @export
 * @interface ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGetRequest
 */
export interface ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGet
     */
    readonly forumId: number
}

/**
 * ForumAttendeeApi - object-oriented interface
 * @export
 * @class ForumAttendeeApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeApi extends BaseAPI {
    /**
     * 
     * @summary Gets attendees\'s address info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdAddressInfoGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAddressInfoGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a forum attendee\'s address information
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdAddressInfoPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdAddressInfoPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAddressInfoPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeAddressInfoRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees\'s attendance info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdAttendanceDetailsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdAttendanceDetailsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAttendanceDetailsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdBfrDatePut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdBfrDatePutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdBfrDatePut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeBFRDateRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdCompanyAddressGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdCompanyAddressGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdCompanyAddressPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyAddressPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdCompanyAddressPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.companyAddressRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Moves an Attendee to a different ForumCompany from the same forum, \\  with the possibility of changing his job title and his contact information
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdCompanyPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdCompanyPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdCompanyPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeMoveToCompanyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees\'s contact info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdContactDetailsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdContactDetailsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdContactDetailsPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdContactDetailsPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdContactDetailsPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeContactDetailsInfoRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees\'s description info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdDescriptionGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdDescriptionGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdDescriptionPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDescriptionPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdDescriptionPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeDescriptionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees\'s main info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdDetailsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDetailsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdDetailsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attendee\'s dietary requirements
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdDietaryRequirementsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdDietaryRequirementsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update attendee\'s dietary requirements
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdDietaryRequirementsPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdDietaryRequirementsPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdDietaryRequirementsPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeDietaryRequirementsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a forum attendee\'s details
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.userTimeZoneOffset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdGroupsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdGroupsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdGroupsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.name, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdHistoryGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdHistoryGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdHistoryGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumCode, requestParameters.forumName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a ForumAttendee\'s hotel room allocation data
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets a ForumAttendee\'s hotel room allocation data
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdHotelRoomAllocationPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumAttendeeHotelRoomAllocationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Make attendee attending (form Cancelled, Replace or CancelledWithFee)
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdMakeAttendingPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdMakeAttendingPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdMakeAttendingPut(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees\'s partner info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdPartnerGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPartnerGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a forum attendee\'s spouse/partner
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdPartnerPost(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPartnerPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPartnerPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeePartnerModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel an attendee
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdPatch(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPatchRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPatch(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeActionModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdPotentialReplacementsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdPotentialReplacementsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPotentialReplacementsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.firstName, requestParameters.lastName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdProfilingQuestionnaireAnswersGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the reason for meeting options configured for the ReasonForMeeting (V1/V2/V3) item in the attendee\'s selection site
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdReasonsForMeetingGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdReasonsForMeetingGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdReasonsForMeetingGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendee list of possbile ReContacts based on the ForumGroups that his ParticipationCodes makes him be part of
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdRecontactsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdRecontactsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdRecontactsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Replace a forum attendee with a person from the same company
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdReplacementPost(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdReplacementPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdReplacementPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeReplacementRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees\'s social links info
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdSocialLinksGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSocialLinksGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a forum attendee\'s social links
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdSocialLinksPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSocialLinksPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSocialLinksPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeSocialLinksRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdSpeakerTrackingGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSpeakerTrackingGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesAttendeeIdSpeakerTrackingPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesAttendeeIdSpeakerTrackingPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSpeakerTrackingPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.speakerTrackingRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesByParticipationTypeGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesByParticipationTypeGet(requestParameters.forumId, requestParameters.fullName, requestParameters.companyName, requestParameters.status, requestParameters.attendeeParticipationType, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets attendees manage description info
     * @param {ForumAttendeeApiForumForumIdAttendeesDescriptionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesDescriptionsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesDescriptionsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesDescriptionsGet(requestParameters.forumId, requestParameters.description, requestParameters.attendeeName, requestParameters.attendeeCompanyName, requestParameters.peopleType, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesExportGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesExportGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesExportGet(requestParameters.forumId, requestParameters.firstName, requestParameters.lastName, requestParameters.fullName, requestParameters.company, requestParameters.email, requestParameters.group, requestParameters.forumGroupIds, requestParameters.attendeeStatus, requestParameters.jobTitle, requestParameters.participationCodes, requestParameters.peopleTypes, requestParameters.advancedFilterType, requestParameters.globalSearch, requestParameters.onlySpeakers, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesFrompersonPersonIdPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesFrompersonPersonIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesFrompersonPersonIdPut(requestParameters.forumId, requestParameters.personId, requestParameters.assignAttendeeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates multiple attendees by list of people ids
     * @param {ForumAttendeeApiForumForumIdAttendeesFrompersonPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesFrompersonPut(requestParameters: ForumAttendeeApiForumForumIdAttendeesFrompersonPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesFrompersonPut(requestParameters.forumId, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeApiForumForumIdAttendeesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesGet(requestParameters.forumId, requestParameters.firstName, requestParameters.lastName, requestParameters.fullName, requestParameters.company, requestParameters.email, requestParameters.group, requestParameters.forumGroupIds, requestParameters.attendeeStatus, requestParameters.jobTitle, requestParameters.participationCodes, requestParameters.peopleTypes, requestParameters.advancedFilterType, requestParameters.globalSearch, requestParameters.onlySpeakers, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, requestParameters.startDate, requestParameters.endDate, requestParameters.type2, requestParameters.startDate2, requestParameters.endDate2, requestParameters.type3, requestParameters.startDate3, requestParameters.endDate3, requestParameters.type4, requestParameters.questionnaireId, requestParameters.statuses, requestParameters.type5, requestParameters.selectionSiteId, requestParameters.status, requestParameters.type6, requestParameters.groups, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get forum attendees summury
     * @param {ForumAttendeeApiForumForumIdAttendeesSummaryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesSummaryGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesSummaryGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesSummaryGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of attendees unassigned to bedrooms
     * @param {ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeApi
     */
    public forumForumIdAttendeesUnassignedBedroomsGet(requestParameters: ForumAttendeeApiForumForumIdAttendeesUnassignedBedroomsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeApiFp(this.configuration).forumForumIdAttendeesUnassignedBedroomsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }
}

