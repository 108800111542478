import "./styles.css";

import React from "react";
import { Quill } from "react-quill";

import MergeTags from "./MergeTags";

function insertCustomTags(value: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editor = this as any;

  const cursorPosition = editor.quill.getSelection().index;
  editor.quill.insertText(cursorPosition, value);
  editor.quill.setSelection(cursorPosition + value.length);
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "blockquote",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "list",
  "code-block",
];

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertCustomTags: insertCustomTags,
    },
  },
};

// Quill Toolbar component
export const QuillToolbar = ({
  mergeTags,
  hasImageHandler = false,
  showListFormats = false,
}: {
  mergeTags?: string[];
  hasImageHandler: boolean;
  showListFormats: boolean;
}) => (
  <div id="toolbar" className="ql-toolbar ql-snow">
    <span className="ql-formats">
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    {showListFormats && (
      <span className="ql-formats">
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </span>
    )}
    <span className="ql-formats">
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      {hasImageHandler && <button className="ql-image" />}
    </span>
    {mergeTags && (
      <span className="ql-formats">
        <MergeTags mergeTags={mergeTags} />
      </span>
    )}
  </div>
);

export default QuillToolbar;
