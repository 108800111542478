import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumAttendeeMeetingFeedbackMutation } from "api/mutations/forumAttendeeMeetingFeedback";
import KEYS from "api/queries/keys";
import { DatePicker, Drawer, Input } from "components/styleguide";
import { FeedBackQuestionType, FeedbackType, ForumAttendeeMeetingFeedbackModel } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment/moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import EditFeedbackForm from "../EditFeedbackForm";
import { getQuestionsFromValues } from "../helpers";
import { FeedbackField, MeetingFormValues } from "../types";

interface EditMeetingFeedbackDrawerProps {
  feedback: ForumAttendeeMeetingFeedbackModel;
  attendeeId: number;
  isVisible: boolean;
  onClose: () => void;
}

const EditMeetingFeedbackDrawer: FC<EditMeetingFeedbackDrawerProps> = ({
  feedback,
  attendeeId,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { mutate: updateMeetingFeedback, isLoading: isUpdating } = useUpdateForumAttendeeMeetingFeedbackMutation();

  const fields: Array<FeedbackField> = [
    {
      label: "Start Time",
      name: "startTime",
      initialValue: moment.utc(feedback.startTime).utcOffset(moment().utcOffset()),
      component: <DatePicker style={{ width: "100%" }} format="L LT" showTime disabled />,
    },
    {
      label: "Attendee",
      name: "attendee",
      initialValue: `${feedback.attendeeFirstName} ${feedback.attendeeLastName}, ${feedback.attendeeJobTitle}`,
      component: <Input disabled />,
    },
    {
      label: "Follow-up Requested",
      name: FeedBackQuestionType.FollowUp,
      initialValue: feedback.followUpRequest,
      isRequired: true,
    },
    {
      label: "Supplier Meeting Rating",
      name: FeedBackQuestionType.Supplier,
      initialValue: feedback.supplierMeetingRating,
      isRequired: true,
    },
    {
      label: "Correct Person",
      name: FeedBackQuestionType.CorrectPerson,
      initialValue: feedback.correctPerson,
      isRequired: true,
    },
  ];

  const onSubmit = (values: MeetingFormValues) => {
    updateMeetingFeedback(
      {
        forumId,
        attendeeId,
        feedbackId: toNumber(feedback.id),
        forumMeetingFeedBackRequestModel: getQuestionsFromValues(values),
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_FEEDBACK]),
        onError: () => toast.error(t("Something went wrong")),
        onSettled: onClose,
      },
    );
  };

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title={t("Edit Meeting Feedback")}
      placement="right"
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <EditFeedbackForm {...{ fields, isUpdating, onSubmit, onClose }} feedbackType={FeedbackType.Meeting} />
    </Drawer>
  );
};

export default EditMeetingFeedbackDrawer;
