import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useCreateEmailTemplateMutation = getWrappedUseMutation(apiClient.Email.templates.post);

export const useUpdateEmailTemplateMutation = getWrappedUseMutation(apiClient.Email.templates.put);

export const useUploadImageMutation = getWrappedUseMutation(apiClient.Email.image.post);

export const useDeleteEmailTemplateById = getWrappedUseMutation(apiClient.Email.templates.delete);
