import { useGetTimeTypeLabelsQuery } from "api/queries/itineraryBookletsForumTimeTypeLabels";
import { Space, Table } from "components/styleguide";
import { ItineraryBookletsForumTimeTypeLabelResponseModel } from "generated/api";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import TimeTypeLabelInput from "./Components/TimeTypeLabelInput";

export default function TimeTypeLabels() {
  const { t } = useTranslation();
  const { id } = useParams();

  const [timeTypeLabels, setTimeTypeLabels] = useState<ItineraryBookletsForumTimeTypeLabelResponseModel[]>([]);
  const [timeTypeSearch, setTimeTypeSearch] = useState<string | undefined>(undefined);
  const [meetingLabelSearch, setMeetingLabelSearch] = useState<string | undefined>(undefined);

  const query = useGetTimeTypeLabelsQuery(
    {
      forumId: toNumber(id),
      timeType: timeTypeSearch,
      meetingLabel: meetingLabelSearch,
    },
    { onSuccess: ({ data }) => setTimeTypeLabels(data) },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, _: string, searchSet: string) => {
    switch (searchSet) {
      case "timeType": {
        setTimeTypeSearch(selectedKeys[0]);
        break;
      }
      case "meetingLabel": {
        setMeetingLabelSearch(selectedKeys[0]);
        break;
      }
    }

    confirm?.();
  };
  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "timeType": {
        setTimeTypeSearch(undefined);
        break;
      }
      case "meetingLabel": {
        setMeetingLabelSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const handleTypeLabelUpdate = (
    newLabel: ItineraryBookletsForumTimeTypeLabelResponseModel["meetingLabel"],
    labelId: ItineraryBookletsForumTimeTypeLabelResponseModel["id"],
  ) => {
    setTimeTypeLabels(
      timeTypeLabels.map((timeTypeLabel) => {
        if (timeTypeLabel.id === labelId) {
          timeTypeLabel.meetingLabel = newLabel;
        }
        return timeTypeLabel;
      }),
    );
  };

  const columns = [
    {
      key: "timeType",
      title: t("Time Type"),
      dataIndex: "timeType",
      ...(getColumnSearchProps("timeType", handleSearch, handleReset, "timeType") as Partial<unknown>),
    },
    { key: "meetingType", title: t("Meeting Type"), dataIndex: "meetingType" },
    {
      key: "meetingLabel",
      title: t("Meeting Label"),
      dataIndex: "meetingLabel",
      ...(getColumnSearchProps("meetingLabel", handleSearch, handleReset, "meetingLabel") as Partial<unknown>),
      render: (meetingLabel: string, row: ItineraryBookletsForumTimeTypeLabelResponseModel) => {
        return (
          <TimeTypeLabelInput
            onTypeLabelUpdate={handleTypeLabelUpdate}
            labelId={toNumber(row.id)}
            forumId={toNumber(id)}
            label={meetingLabel}
          />
        );
      },
    },
  ];

  return (
    <Space direction="vertical" fullWidth={true}>
      <Table rowKey={"id"} columns={columns} dataSource={timeTypeLabels} loading={query.isLoading} />
    </Space>
  );
}
