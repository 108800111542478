import { toNumber } from "lodash";

import { ChangedValuesKeys } from "./types";

export const getChangedValues = (values: ChangedValuesKeys) => {
  const changedValues: ChangedValuesKeys = {};
  for (const key in values) {
    changedValues[key] = values[key];
  }
  return Object.entries(changedValues).map(([id, value]) => ({ id: toNumber(id), value }));
};
