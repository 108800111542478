import { Space, Typography, YesNo } from "components/styleguide";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface WithReasonSelectOptionProps {
  isWithReason: boolean;
  setIsWithReason: Dispatch<SetStateAction<boolean>>;
}

const WithReasonSelectOption = ({ isWithReason, setIsWithReason }: WithReasonSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("With Reason")}</Typography.Text>
      <YesNo style={{ width: "200px" }} value={isWithReason} onSelect={setIsWithReason} />
    </Space>
  );
};

export default WithReasonSelectOption;
