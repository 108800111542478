import { Input } from "components/styleguide";
import React from "react";
import { TFunction } from "react-i18next";

const getFormFields = ({
  t,
  onEmailChange,
  onEmail2Change,
}: {
  t: TFunction;
  onEmailChange: (value: string) => void;
  onEmail2Change: (value: string) => void;
}) => [
  { title: "Address", dataIndex: "address", maxLength: 50 },
  { title: "Buisness Phone", dataIndex: "businessPhone", maxLength: 30 },
  { title: "Address line 2", dataIndex: "addressLine2", maxLength: 50 },
  {
    title: "Email",
    dataIndex: "email",
    component: <Input maxLength={100} onChange={(event) => onEmailChange(event.target.value)} />,
    rules: [{ required: true, message: t("errors.required", { prop: t("Email") }) }],
  },
  { title: "County", dataIndex: "county", maxLength: 30 },
  {
    title: "Email 2",
    dataIndex: "email2",
    component: <Input maxLength={100} onChange={(event) => onEmail2Change(event.target.value)} />,
  },
  { title: "City", dataIndex: "city", maxLength: 30 },
];

export { getFormFields };
