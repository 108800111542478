import api from "api";
import { useQuery } from "@tanstack/react-query";

const getLanguagesQueryKey = "get_languages";

const languagesQueryKeys = {
  getLanguages: () => [getLanguagesQueryKey],
};

const useLanguagesQuery = (options = {}) =>
  useQuery(languagesQueryKeys.getLanguages(), () => api.get(`/languages`), options);

export { useLanguagesQuery, getLanguagesQueryKey };
