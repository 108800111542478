import { FormProps } from "antd";
import { PositiveSelectionV2Item } from "generated/api";
import { mergeSets } from "utils/setUtils";

type FieldData = NonNullable<FormProps["fields"]>[number];

export const FIELDS_TO_CLEAR_ON_QMS_CHANGE = new Set<keyof PositiveSelectionV2Item>(["questionnaireMatching"]);
export const FIELDS_TO_CLEAR_ON_QBS_CHANGE = new Set<keyof PositiveSelectionV2Item>([
  "questionnaireMatching",
  "questionnaireSearchIndex",
  "profileDetailsOnline",
  "profileDetailsPdf",
]);
const FIELDS_TO_CLEAR_ON_GROUP_SELECTING_FROM_CHANGE = mergeSets([
  FIELDS_TO_CLEAR_ON_QMS_CHANGE,
  FIELDS_TO_CLEAR_ON_QBS_CHANGE,
  new Set<keyof PositiveSelectionV2Item>([
    "questionnaireOfGroupMakingSelections",
    "questionnaireOfGroupBeingSelected",
    "questionnaireOfGroupBeingSelectedVersion",
  ]),
]);

const getFieldName = (field: FieldData): string | number | undefined => {
  if (Array.isArray(field.name)) {
    return field.name[0];
  }

  return field.name;
};

class ChangeDetector {
  constructor(
    private oldValues: PositiveSelectionV2Item,
    private newValues: Map<string | number | undefined, unknown>,
  ) {}

  wasChanged(key: keyof PositiveSelectionV2Item): boolean {
    const old = this.newValues.get(key);
    const newValue = this.oldValues[key];

    return old !== newValue;
  }
}

const handleFieldsChange = (previousValues: PositiveSelectionV2Item, fields: Array<FieldData>): Array<FieldData> => {
  const fieldsMap = new Map<string | number | undefined, unknown>();
  fields.forEach((field) => fieldsMap.set(getFieldName(field), field.value));
  const changeDetector = new ChangeDetector(previousValues, fieldsMap);

  const fieldsToClear = new Set<keyof PositiveSelectionV2Item>();

  const wasGroupSelectingFromChanged = changeDetector.wasChanged("groupSelectingFrom");
  const wasQMSChanged = changeDetector.wasChanged("questionnaireOfGroupMakingSelections");
  const wasQBSChanged = changeDetector.wasChanged("questionnaireOfGroupBeingSelected");

  if (wasGroupSelectingFromChanged) {
    FIELDS_TO_CLEAR_ON_GROUP_SELECTING_FROM_CHANGE.forEach((field) => fieldsToClear.add(field));
  }

  if (wasQMSChanged) {
    FIELDS_TO_CLEAR_ON_QMS_CHANGE.forEach((field) => fieldsToClear.add(field));
  }

  if (wasQBSChanged) {
    FIELDS_TO_CLEAR_ON_QBS_CHANGE.forEach((field) => fieldsToClear.add(field));
  }

  if (fieldsToClear.size === 0) {
    return fields;
  }

  return fields.map((field) => {
    const fieldName = getFieldName(field);

    // TypeScript thinks that `fieldName` can be only PositiveSelectionV2Item key
    // but in fact it can be undefined | string | number, and anyway it would be a valid code
    // if fieldName would be non-existent key of PositiveSelectionV2Item then it simply won't get into
    // the if statement and will return the field as it is.
    // That's why we use type assertion here
    if (fieldsToClear.has(fieldName as keyof PositiveSelectionV2Item)) {
      return {
        ...field,
        value: undefined,
      };
    }

    return field;
  });
};

export default handleFieldsChange;
