import { useQueryClient } from "@tanstack/react-query";
import { useUploadForumVisualSpeakerDocumentMutation } from "api/mutations/visualSpeakerDocuments";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DocumentForm from "../DocumentForm";
import { DocumentUploadModel } from "../types";

interface AddDocumentDrawerProps {
  isVisible: boolean;
  onClose: () => void;
}

const AddDocumentDrawer: FC<AddDocumentDrawerProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);
  const seminarId = toNumber(params.seminarId);

  const { mutate: uploadDocument, isLoading: isDocumentUploading } = useUploadForumVisualSpeakerDocumentMutation();

  const onSubmit = ({ location, file, description, permissions, publish }: DocumentUploadModel) => {
    const data = {
      forumId,
      seminarId,
      title: file[0].originFileObj.name,
      description,
      publishedFromDate: publish?.[0]?.format("YYYY-MM-DD"),
      publishedUntilDate: publish?.[1]?.format("YYYY-MM-DD"),
      permissionGroupIds: permissions,
      seminarIds: location,
      formFile: file[0].originFileObj,
    };

    uploadDocument(data, {
      onSuccess: () => {
        queryClient.invalidateQueries([KEYS.GET_FORUM_VISUAL_SPEAKER_DOCUMENTS]);
        toast.success(t("File successfully uploaded"));
        onClose();
      },
      onError: () => toast.error(t("Something went wrong")),
    });
  };

  return (
    <Drawer
      title={t("Upload document")}
      open={isVisible}
      size="large"
      placement="right"
      destroyOnClose
      onClose={onClose}
    >
      <DocumentForm isLoading={isDocumentUploading} onSubmit={onSubmit} onClose={onClose} />
    </Drawer>
  );
};

export default AddDocumentDrawer;
