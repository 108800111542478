import { useQueryClient } from "@tanstack/react-query";
import { useDeleteForumAttendeeSessionFeedbackMutation } from "api/mutations/forumAttendeeSessionFeedback";
import KEYS from "api/queries/keys";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import DeleteFeedbackButton from "../DeleteFeedbackButton";

interface DeleteSessionFeedbackButtonProps {
  forumId: number;
  attendeeId: number;
  feedbackId: number;
}

const DeleteSessionFeedbackButton: FC<DeleteSessionFeedbackButtonProps> = ({ forumId, attendeeId, feedbackId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: deleteSessionFeedbackMutate } = useDeleteForumAttendeeSessionFeedbackMutation();

  const onDelete = () => {
    deleteSessionFeedbackMutate(
      { forumId, attendeeId, feedbackId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_CONFERENCE_SESSION_FEEDBACK]);
          queryClient.invalidateQueries([KEYS.GET_FEEDBACK_BY_PARTICIPATION_TYPE]);
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return <DeleteFeedbackButton {...{ onDelete }} />;
};

export default DeleteSessionFeedbackButton;
