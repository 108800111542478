import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useUpdateAttendeeDescriptionMutation } from "api/mutations/attendees";
import { useAttendeeDescriptionQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Col, Row, Spin } from "components/styleguide";
import TextEditor from "components/TextEditor";
import { useEditorHooks } from "components/TextEditor/hooks";
import { AttendeeDescriptionModel } from "generated/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.less";

type AttendeeDescriptionProps = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

type AttendeeDescriptionFormValues = {
  description: string;
};
const AttendeeDescription = ({ isEditing, setIsEditing }: AttendeeDescriptionProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const forumId = Number(useParams().id);
  const attendeeId = Number(useParams().attendeeId);
  const { isEditorEmpty } = useEditorHooks();

  const [description, setDescription] = useState<AttendeeDescriptionModel>();

  const query = useAttendeeDescriptionQuery(
    { forumId, attendeeId },
    {
      onSuccess: ({ data }: { data: AttendeeDescriptionModel }) => {
        setDescription(data);
      },
    },
  );

  const descriptionUpdateMutation = useUpdateAttendeeDescriptionMutation();
  const handleDescriptionUpdate = (values: AttendeeDescriptionFormValues) => {
    descriptionUpdateMutation.mutate(
      {
        forumId,
        attendeeId,
        attendeeDescriptionRequestModel: { description: values.description },
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          queryClient.resetQueries([KEYS.GET_ATTENDEE_DESCRIPTION]);
        },
      },
    );
  };
  const handleEditCancel = () => {
    queryClient.invalidateQueries([KEYS.GET_ATTENDEE_DESCRIPTION]);
    setIsEditing(false);
    form.resetFields();
  };

  if (query.isLoading || !description) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      {description && !query.isLoading ? (
        <Form form={form} onFinish={handleDescriptionUpdate} id={"attendee-description-form"} name={"description-form"}>
          <Form.Item initialValue={description.description} name={"description"}>
            {isEditing ? (
              <TextEditor maxLength={2000} placeholder={t("Please, enter Attendee description")} showListFormats />
            ) : (
              <span
                className={styles.description}
                dangerouslySetInnerHTML={{
                  __html:
                    !description.description || isEditorEmpty(description.description) ? "-" : description.description,
                }}
              />
            )}
          </Form.Item>
          {isEditing && (
            <Row gutter={80}>
              <Col span={1}>
                <Button loading={descriptionUpdateMutation.isLoading} htmlType="submit" type="primary">
                  {t("Save")}
                </Button>
              </Col>
              <Col span={1}>
                <Button onClick={handleEditCancel}>{t("Cancel")}</Button>
              </Col>
            </Row>
          )}
        </Form>
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default AttendeeDescription;
