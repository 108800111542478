import { useQueryClient } from "@tanstack/react-query";
import TextArea from "antd/lib/input/TextArea";
import { useUpdateForumAttendeeSessionFeedbackMutation } from "api/mutations/forumAttendeeSessionFeedback";
import KEYS from "api/queries/keys";
import { Drawer, Input } from "components/styleguide";
import { FeedBackQuestionType, FeedbackType, ForumAttendeeSessionFeedbackModel } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment/moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import EditFeedbackForm from "../EditFeedbackForm";
import { getQuestionsFromValues } from "../helpers";
import { FeedbackField, SessionFormValues } from "../types";

interface EditSessionFeedbackDrawerProps {
  feedback: ForumAttendeeSessionFeedbackModel;
  attendeeId: number;
  isVisible: boolean;
  onClose: () => void;
}

const EditSessionFeedbackDrawer: FC<EditSessionFeedbackDrawerProps> = ({
  feedback,
  attendeeId,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { mutate: updateSessionFeedback, isLoading: isUpdating } = useUpdateForumAttendeeSessionFeedbackMutation();

  const getFormattedDate = (date: string, format: string) => moment.utc(date).format(format);

  const getFormattedDateRange = (startDate: string, endDate: string, format: string) => {
    return `${getFormattedDate(startDate, format)} - ${getFormattedDate(endDate, format)}`;
  };

  const fields: Array<FeedbackField> = [
    {
      label: "Date and Time",
      name: "sessionDateAndTime",
      initialValue:
        feedback.sessionStartDate &&
        feedback.sessionEndDate &&
        getFormattedDateRange(feedback.sessionStartDate, feedback.sessionEndDate, "L LT"),
      component: <Input disabled />,
    },
    {
      label: "Name",
      name: "seminarName",
      initialValue: feedback.seminarName,
      component: <Input disabled />,
    },
    {
      label: "Please rate the content quality of this session",
      name: FeedBackQuestionType.ContentQuality,
      initialValue: feedback.contentQuality,
      isRequired: true,
    },
    {
      label: "How well did the content match the programme description",
      name: FeedBackQuestionType.ContentMatch,
      initialValue: feedback.contentMatch,
      isRequired: true,
    },
    {
      label: "How well did the session meet your needs",
      name: FeedBackQuestionType.SessionMeetYourNeeds,
      initialValue: feedback.sessionMeetYourNeeds,
      isRequired: true,
    },
    {
      label: "Please provide feedback on the speakers",
      name: FeedBackQuestionType.FeedbackOnSpeakers,
      initialValue: feedback.feedbackOnSpeakers,
      isRequired: true,
    },
    {
      label: "Feedback",
      name: "comment",
      initialValue: feedback.comment,
      component: <TextArea rows={6} cols={6} />,
    },
  ];

  const onSubmit = (values: SessionFormValues) => {
    updateSessionFeedback(
      {
        forumId,
        attendeeId,
        feedbackId: toNumber(feedback.id),
        forumSessionFeedBackRequestModel: {
          forumSessionFeedBackAnswers: getQuestionsFromValues(values),
          comment: values.comment,
        },
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_ATTENDEE_CONFERENCE_SESSION_FEEDBACK]),
        onError: () => toast.error(t("Something went wrong")),
        onSettled: onClose,
      },
    );
  };

  return (
    <Drawer
      open={isVisible}
      onClose={onClose}
      title={t("Edit Conference Session Feedback")}
      placement="right"
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <EditFeedbackForm {...{ fields, isUpdating, onSubmit, onClose }} feedbackType={FeedbackType.Seminar} />
    </Drawer>
  );
};

export default EditSessionFeedbackDrawer;
