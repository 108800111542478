/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeCategoryPreferenceModel } from '../apiModels';
// @ts-ignore
import { AttendeeCategoryPreferencesRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeChoice } from '../apiModels';
// @ts-ignore
import { AttendeeSelectionPreferenceRequest } from '../apiModels';
// @ts-ignore
import { AttendeeSeminarPreferenceResponseModel } from '../apiModels';
// @ts-ignore
import { BlockedMeetingPreferenceRequestModel } from '../apiModels';
// @ts-ignore
import { DuplicateItemExceptionResponse } from '../apiModels';
// @ts-ignore
import { FieldsCannotBeEqualExceptionResponse } from '../apiModels';
// @ts-ignore
import { MeetingPreferenceRequestModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseBlockedMeetingPreferenceResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponseForumAttendeeSelectionChoiceResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponseMeetingPreferenceResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponseSeminarPreferenceResponseModel } from '../apiModels';
// @ts-ignore
import { ProblemDetails } from '../apiModels';
// @ts-ignore
import { SelectionChoiceModel } from '../apiModels';
// @ts-ignore
import { SeminarPreferenceRequestModel } from '../apiModels';
// @ts-ignore
import { UnknownObjectExceptionResponse } from '../apiModels';
// @ts-ignore
import { ValueOutOfRangeExceptionResponse } from '../apiModels';
/**
 * ForumAttendeePreferencesApi - axios parameter creator
 * @export
 */
export const ForumAttendeePreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all categories applicable for the specified attendee.
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/available-categories`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get event options choices list definition (without already selected)
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} [selectionChoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet: async (forumId: number, attendeeId: number, selectionChoiceId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/available-selection-choices-definitions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (selectionChoiceId !== undefined) {
                localVarQueryParameter['selectionChoiceId'] = selectionChoiceId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [attendeeNotToMeetWith] 
         * @param {string} [orderDir] Desc or asc(default)
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet: async (forumId: number, attendeeId: number, attendeeNotToMeetWith?: string, orderDir?: string, pageSize?: number, pageNumber?: number, orderBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/blocked-meetings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (attendeeNotToMeetWith !== undefined) {
                localVarQueryParameter['AttendeeNotToMeetWith'] = attendeeNotToMeetWith;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete blocked meeting preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete: async (forumId: number, attendeeId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete', 'id', id)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/blocked-meetings/{id}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {BlockedMeetingPreferenceRequestModel} [blockedMeetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut: async (forumId: number, attendeeId: number, id: number, blockedMeetingPreferenceRequestModel?: BlockedMeetingPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut', 'id', id)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/blocked-meetings/{id}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockedMeetingPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {BlockedMeetingPreferenceRequestModel} [blockedMeetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost: async (forumId: number, attendeeId: number, blockedMeetingPreferenceRequestModel?: BlockedMeetingPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/blocked-meetings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(blockedMeetingPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of category preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesCategoryGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesCategoryGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesCategoryGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/category`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeCategoryPreferencesRequestModel} [attendeeCategoryPreferencesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesCategoryPut: async (forumId: number, attendeeId: number, attendeeCategoryPreferencesRequestModel?: AttendeeCategoryPreferencesRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesCategoryPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesCategoryPut', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/category`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeCategoryPreferencesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [attendeeToMeetWith] 
         * @param {string} [attendeeToMakeJointWith] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] Desc or asc(default)
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet: async (forumId: number, attendeeId: number, attendeeToMeetWith?: string, attendeeToMakeJointWith?: string, orderBy?: string, orderDir?: string, pageSize?: number, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/meetings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (attendeeToMeetWith !== undefined) {
                localVarQueryParameter['AttendeeToMeetWith'] = attendeeToMeetWith;
            }

            if (attendeeToMakeJointWith !== undefined) {
                localVarQueryParameter['AttendeeToMakeJointWith'] = attendeeToMakeJointWith;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete meeting preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete: async (forumId: number, attendeeId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete', 'id', id)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/meetings/{id}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {MeetingPreferenceRequestModel} [meetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut: async (forumId: number, attendeeId: number, id: number, meetingPreferenceRequestModel?: MeetingPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut', 'id', id)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/meetings/{id}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {MeetingPreferenceRequestModel} [meetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost: async (forumId: number, attendeeId: number, meetingPreferenceRequestModel?: MeetingPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/meetings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selection option preference of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeChoice} [attendeeChoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost: async (forumId: number, attendeeId: number, attendeeChoice?: AttendeeChoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/selection-choice`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeChoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the list of selection choices preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet: async (forumId: number, attendeeId: number, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/selection-choices`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set selection option preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeSelectionPreferenceRequest} [attendeeSelectionPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost: async (forumId: number, attendeeId: number, attendeeSelectionPreferenceRequest?: AttendeeSelectionPreferenceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/selection-choices`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeSelectionPreferenceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete selection choice preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} selectionChoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete: async (forumId: number, attendeeId: number, selectionChoiceId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'selectionChoiceId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete', 'selectionChoiceId', selectionChoiceId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/selection-choices/{selectionChoiceId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"selectionChoiceId"}}`, encodeURIComponent(String(selectionChoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update selection option preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} selectionChoiceId 
         * @param {AttendeeChoice} [attendeeChoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut: async (forumId: number, attendeeId: number, selectionChoiceId: number, attendeeChoice?: AttendeeChoice, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'selectionChoiceId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut', 'selectionChoiceId', selectionChoiceId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/selection-choices/{selectionChoiceId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"selectionChoiceId"}}`, encodeURIComponent(String(selectionChoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeChoice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] Desc or asc(default)
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet: async (forumId: number, attendeeId: number, orderBy?: string, orderDir?: string, pageSize?: number, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/seminars`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete conference preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete: async (forumId: number, attendeeId: number, id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete', 'id', id)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/seminars/{id}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates conference preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {SeminarPreferenceRequestModel} [seminarPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut: async (forumId: number, attendeeId: number, id: number, seminarPreferenceRequestModel?: SeminarPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut', 'id', id)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/seminars/{id}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seminarPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {SeminarPreferenceRequestModel} [seminarPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost: async (forumId: number, attendeeId: number, seminarPreferenceRequestModel?: SeminarPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/preferences/seminars`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(seminarPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all attendee conference preferences associated to the seminar
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesPreferencesSeminarIdGet: async (forumId: number, seminarId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesPreferencesSeminarIdGet', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumForumIdAttendeesPreferencesSeminarIdGet', 'seminarId', seminarId)
            const localVarPath = `/forum/{forumId}/attendees/preferences/{seminarId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeePreferencesApi - functional programming interface
 * @export
 */
export const ForumAttendeePreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeePreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all categories applicable for the specified attendee.
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeCategoryPreferenceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get event options choices list definition (without already selected)
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} [selectionChoiceId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet(forumId: number, attendeeId: number, selectionChoiceId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectionChoiceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet(forumId, attendeeId, selectionChoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [attendeeNotToMeetWith] 
         * @param {string} [orderDir] Desc or asc(default)
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet(forumId: number, attendeeId: number, attendeeNotToMeetWith?: string, orderDir?: string, pageSize?: number, pageNumber?: number, orderBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseBlockedMeetingPreferenceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet(forumId, attendeeId, attendeeNotToMeetWith, orderDir, pageSize, pageNumber, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete blocked meeting preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete(forumId: number, attendeeId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete(forumId, attendeeId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {BlockedMeetingPreferenceRequestModel} [blockedMeetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut(forumId: number, attendeeId: number, id: number, blockedMeetingPreferenceRequestModel?: BlockedMeetingPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut(forumId, attendeeId, id, blockedMeetingPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {BlockedMeetingPreferenceRequestModel} [blockedMeetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost(forumId: number, attendeeId: number, blockedMeetingPreferenceRequestModel?: BlockedMeetingPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost(forumId, attendeeId, blockedMeetingPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of category preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesCategoryGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeCategoryPreferenceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesCategoryGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeCategoryPreferencesRequestModel} [attendeeCategoryPreferencesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesCategoryPut(forumId: number, attendeeId: number, attendeeCategoryPreferencesRequestModel?: AttendeeCategoryPreferencesRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesCategoryPut(forumId, attendeeId, attendeeCategoryPreferencesRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [attendeeToMeetWith] 
         * @param {string} [attendeeToMakeJointWith] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] Desc or asc(default)
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet(forumId: number, attendeeId: number, attendeeToMeetWith?: string, attendeeToMakeJointWith?: string, orderBy?: string, orderDir?: string, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseMeetingPreferenceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet(forumId, attendeeId, attendeeToMeetWith, attendeeToMakeJointWith, orderBy, orderDir, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete meeting preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete(forumId: number, attendeeId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete(forumId, attendeeId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {MeetingPreferenceRequestModel} [meetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut(forumId: number, attendeeId: number, id: number, meetingPreferenceRequestModel?: MeetingPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut(forumId, attendeeId, id, meetingPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {MeetingPreferenceRequestModel} [meetingPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost(forumId: number, attendeeId: number, meetingPreferenceRequestModel?: MeetingPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost(forumId, attendeeId, meetingPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selection option preference of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeChoice} [attendeeChoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost(forumId: number, attendeeId: number, attendeeChoice?: AttendeeChoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost(forumId, attendeeId, attendeeChoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the list of selection choices preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} [pageSize] 
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet(forumId: number, attendeeId: number, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseForumAttendeeSelectionChoiceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet(forumId, attendeeId, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set selection option preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeSelectionPreferenceRequest} [attendeeSelectionPreferenceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost(forumId: number, attendeeId: number, attendeeSelectionPreferenceRequest?: AttendeeSelectionPreferenceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost(forumId, attendeeId, attendeeSelectionPreferenceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete selection choice preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} selectionChoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete(forumId: number, attendeeId: number, selectionChoiceId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete(forumId, attendeeId, selectionChoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update selection option preferences of an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} selectionChoiceId 
         * @param {AttendeeChoice} [attendeeChoice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut(forumId: number, attendeeId: number, selectionChoiceId: number, attendeeChoice?: AttendeeChoice, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut(forumId, attendeeId, selectionChoiceId, attendeeChoice, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] Desc or asc(default)
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet(forumId: number, attendeeId: number, orderBy?: string, orderDir?: string, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseSeminarPreferenceResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet(forumId, attendeeId, orderBy, orderDir, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete conference preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete(forumId: number, attendeeId: number, id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete(forumId, attendeeId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates conference preference of attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} id 
         * @param {SeminarPreferenceRequestModel} [seminarPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut(forumId: number, attendeeId: number, id: number, seminarPreferenceRequestModel?: SeminarPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut(forumId, attendeeId, id, seminarPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {SeminarPreferenceRequestModel} [seminarPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost(forumId: number, attendeeId: number, seminarPreferenceRequestModel?: SeminarPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost(forumId, attendeeId, seminarPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all attendee conference preferences associated to the seminar
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesPreferencesSeminarIdGet(forumId: number, seminarId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeSeminarPreferenceResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesPreferencesSeminarIdGet(forumId, seminarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeePreferencesApi - factory interface
 * @export
 */
export const ForumAttendeePreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeePreferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all categories applicable for the specified attendee.
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeCategoryPreferenceModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get event options choices list definition (without already selected)
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SelectionChoiceModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.selectionChoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseBlockedMeetingPreferenceResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeNotToMeetWith, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete blocked meeting preference of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, requestParameters.blockedMeetingPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.blockedMeetingPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of category preferences of an attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesCategoryGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeCategoryPreferenceModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesCategoryGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesCategoryPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesCategoryPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeCategoryPreferencesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseMeetingPreferenceResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeToMeetWith, requestParameters.attendeeToMakeJointWith, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete meeting preference of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a meeting preference priority of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, requestParameters.meetingPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.meetingPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selection option preference of an attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeChoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the list of selection choices preferences of an attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseForumAttendeeSelectionChoiceResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set selection option preferences of an attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeSelectionPreferenceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete selection choice preferences of an attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.selectionChoiceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update selection option preferences of an attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.selectionChoiceId, requestParameters.attendeeChoice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseSeminarPreferenceResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete conference preference of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates conference preference of attendee
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, requestParameters.seminarPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.seminarPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all attendee conference preferences associated to the seminar
         * @param {ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesPreferencesSeminarIdGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeSeminarPreferenceResponseModel>> {
            return localVarFp.forumForumIdAttendeesPreferencesSeminarIdGet(requestParameters.forumId, requestParameters.seminarId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet
     */
    readonly selectionChoiceId?: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly attendeeNotToMeetWith?: string

    /**
     * Desc or asc(default)
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly orderDir?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet
     */
    readonly orderBy?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDeleteRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPutRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {BlockedMeetingPreferenceRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut
     */
    readonly blockedMeetingPreferenceRequestModel?: BlockedMeetingPreferenceRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPostRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {BlockedMeetingPreferenceRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost
     */
    readonly blockedMeetingPreferenceRequestModel?: BlockedMeetingPreferenceRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesCategoryGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesCategoryPut operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPutRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeCategoryPreferencesRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPut
     */
    readonly attendeeCategoryPreferencesRequestModel?: AttendeeCategoryPreferencesRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly attendeeToMeetWith?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly attendeeToMakeJointWith?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly orderBy?: string

    /**
     * Desc or asc(default)
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly orderDir?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGet
     */
    readonly pageNumber?: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDeleteRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPutRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {MeetingPreferenceRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut
     */
    readonly meetingPreferenceRequestModel?: MeetingPreferenceRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPostRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {MeetingPreferenceRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPost
     */
    readonly meetingPreferenceRequestModel?: MeetingPreferenceRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePostRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeChoice}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost
     */
    readonly attendeeChoice?: AttendeeChoice
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPostRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeSelectionPreferenceRequest}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost
     */
    readonly attendeeSelectionPreferenceRequest?: AttendeeSelectionPreferenceRequest
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDeleteRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete
     */
    readonly selectionChoiceId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPutRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut
     */
    readonly selectionChoiceId: number

    /**
     * 
     * @type {AttendeeChoice}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut
     */
    readonly attendeeChoice?: AttendeeChoice
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGet
     */
    readonly orderBy?: string

    /**
     * Desc or asc(default)
     * @type {string}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGet
     */
    readonly orderDir?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGet
     */
    readonly pageNumber?: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDeleteRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPutRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {SeminarPreferenceRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut
     */
    readonly seminarPreferenceRequestModel?: SeminarPreferenceRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPostRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {SeminarPreferenceRequestModel}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPost
     */
    readonly seminarPreferenceRequestModel?: SeminarPreferenceRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesPreferencesSeminarIdGet operation in ForumAttendeePreferencesApi.
 * @export
 * @interface ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGetRequest
 */
export interface ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGet
     */
    readonly seminarId: number
}

/**
 * ForumAttendeePreferencesApi - object-oriented interface
 * @export
 * @class ForumAttendeePreferencesApi
 * @extends {BaseAPI}
 */
export class ForumAttendeePreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Get all categories applicable for the specified attendee.
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get event options choices list definition (without already selected)
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.selectionChoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeNotToMeetWith, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete blocked meeting preference of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a meeting preference priority of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, requestParameters.blockedMeetingPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.blockedMeetingPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of category preferences of an attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesCategoryGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesCategoryGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a meeting preference priority of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesCategoryPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesCategoryPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesCategoryPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeCategoryPreferencesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeToMeetWith, requestParameters.attendeeToMakeJointWith, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete meeting preference of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a meeting preference priority of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, requestParameters.meetingPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.meetingPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selection option preference of an attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeChoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the list of selection choices preferences of an attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set selection option preferences of an attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeSelectionPreferenceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete selection choice preferences of an attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.selectionChoiceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update selection option preferences of an attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.selectionChoiceId, requestParameters.attendeeChoice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete conference preference of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates conference preference of attendee
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.id, requestParameters.seminarPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesSeminarsPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.seminarPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all attendee conference preferences associated to the seminar
     * @param {ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeePreferencesApi
     */
    public forumForumIdAttendeesPreferencesSeminarIdGet(requestParameters: ForumAttendeePreferencesApiForumForumIdAttendeesPreferencesSeminarIdGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeePreferencesApiFp(this.configuration).forumForumIdAttendeesPreferencesSeminarIdGet(requestParameters.forumId, requestParameters.seminarId, options).then((request) => request(this.axios, this.basePath));
    }
}

