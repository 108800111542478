import "./styles.less";

import { Drawer as OriginalDrawer, DrawerProps } from "antd";
import { FC } from "react";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const Drawer: FC<DrawerProps> = ({ getContainer = () => document.getElementById("theme-container")!, ...props }) => {
  return <OriginalDrawer getContainer={getContainer} {...props} />;
};
export default Drawer;
