import { useQueryClient } from "@tanstack/react-query";
import { useUpdateSelectionSiteMutation } from "api/mutations/selectionSites";
import { useGetForumGroupQuery, useGetForumGroupsQuery } from "api/queries/groups";
import { useSelectionSiteQuery } from "api/queries/selectionSites";
import { Spin } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";

import Form from "../Form";
import SelectionSiteInfo from "./SelectionSiteInfo";

const SelectionSiteDetails = ({ isReadOnly, setIsReadOnly }) => {
  const { id: forumId } = useOutletContext();
  const params = useParams();
  const selectionSiteId = parseInt(params.selectionSiteId);

  const { t } = useTranslation();

  const [selectionSite, setSelectionSite] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { mutate, isLoading: isMutationLoading } = useUpdateSelectionSiteMutation();

  const { isLoading: isSelectionSiteLoading } = useSelectionSiteQuery(
    {
      forumId,
      selectionSiteId,
    },
    {
      onSuccess: ({ data }) => {
        setSelectionSite({ ...selectionSite, ...data });

        if (!location.state || !location.state.selectionSiteName) {
          navigate(location.pathname, {
            replace: true,
            hash: location.hash || "",
            state: { selectionSiteName: data?.name },
          });
        }
      },
    },
  );

  const { refetch: refetchGroup, isLoading: isGroupLoading } = useGetForumGroupQuery(
    { forumId, groupId: selectionSite?.groupId },
    {
      onSuccess: ({ data }) => setSelectionSite({ ...selectionSite, group: data }),
      enabled: false,
    },
  );

  useEffect(() => {
    if (selectionSite?.groupId) {
      refetchGroup();
    }
  }, [selectionSite?.groupId]);

  const onSubmit = ({ available, ...values }) => {
    const [availableFrom, availableTo] = available;
    const formData = {
      ...values,
      availableFrom: availableFrom.format(t("dateFormat")),
      availableTo: availableTo.format(t("dateFormat")),
    };

    mutate(
      {
        forumId,
        selectionSiteId,
        ...formData,
      },
      {
        onSuccess: () => {
          setIsReadOnly(true);
          queryClient.invalidateQueries(useSelectionSiteQuery.getQueryKeys({ forumId, selectionSiteId }));
          navigate(location.pathname, {
            state: { ...location.state, selectionSiteName: formData?.name },
          });
          setErrorMsg(null);
        },
        onError: ({ response }) => {
          setErrorMsg(response.data?.Message);
        },
      },
    );
  };

  if (isSelectionSiteLoading || isGroupLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  const onEditClose = () => {
    if (isMutationLoading) {
      return;
    }

    setIsReadOnly(true);
  };

  if (isReadOnly) {
    return <SelectionSiteInfo selectionSite={selectionSite} />;
  }

  return (
    <Form
      selectionSite={selectionSite}
      onEditClose={onEditClose}
      onSubmit={onSubmit}
      isMutationLoading={isMutationLoading}
      isEdit
      errorMsg={errorMsg}
    />
  );
};

SelectionSiteDetails.propTypes = {
  isReadOnly: PropTypes.bool,
  setIsReadOnly: PropTypes.func,
};

SelectionSiteDetails.defaultProps = {
  isReadOnly: true,
  setIsReadOnly: () => undefined,
};

export default SelectionSiteDetails;
