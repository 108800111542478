import { Collapse } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import BillingNotes from "./BillingNotes";
import HotelAllocations from "./HotelAllocations";

import styles from "./styles.module.less";

const { Panel } = Collapse;

const HotelAdmin: FC = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const activeKeys = ["billing-notes", "hotel-allocations"];

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      element?.scrollIntoView();
    }
  }, []);

  return (
    <div id="hotel-admin" className={styles.hotelAdminPage}>
      <Collapse className="collapse-parent" defaultActiveKey={activeKeys}>
        <UserGuide.Panel
          id="billing-notes"
          title="Billing Notes"
          articleId="16441883351837-hotel-admin"
          key="billing-notes"
        >
          <BillingNotes />
        </UserGuide.Panel>
        <Panel
          id="hotel-allocations"
          key="hotel-allocations"
          className="collapse-panel"
          header={t("Hotel Allocations")}
        >
          <HotelAllocations />
        </Panel>
      </Collapse>
    </div>
  );
};

export default HotelAdmin;
