import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useAssignSpeakerMutation, useRemoveSpeakerMutation } from "api/mutations/seminars";
import KEYS from "api/queries/keys";
import {
  GET_ASSIGNED_SPEAKERS_QUERY,
  GET_POSSIBLE_SPEAKERS_QUERY,
  useAssignedSpeakersQuery,
  usePossibleSpeakersQuery,
} from "api/queries/seminars";
import { Col, Row, Table } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { getColumnSearchProps } from "../../../../utils/tableColumnUtils";

import styles from "../../../Groups/EditGroupDrawer/styles.module.less";

const SpeakerDetails = ({ forumId, seminarId }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { isLoading: isPossibleSpeakersLoading, data: { data: possibleSpeakers = [] } = {} } = usePossibleSpeakersQuery(
    { forumId, forumSeminarId: seminarId },
  );

  const { isLoading: isAssignedSpeakersLoading, data: { data: assignedSpeakers = [] } = {} } = useAssignedSpeakersQuery(
    { forumId, forumSeminarId: seminarId },
  );

  const assignSpeakerMutation = useAssignSpeakerMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
      await queryClient.invalidateQueries([GET_ASSIGNED_SPEAKERS_QUERY]);
      await queryClient.invalidateQueries([GET_POSSIBLE_SPEAKERS_QUERY]);
    },
  });

  const removeSpeakerMutation = useRemoveSpeakerMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
      await queryClient.invalidateQueries([GET_ASSIGNED_SPEAKERS_QUERY]);
      await queryClient.invalidateQueries([GET_POSSIBLE_SPEAKERS_QUERY]);
    },
  });

  const handleSpeakerAdd = (speaker) => {
    assignSpeakerMutation.mutateAsync({
      forumId,
      forumSeminarId: seminarId,
      speaker: { forumAttendeeId: speaker.id },
    });
  };

  const handleSpeakerRemove = (speaker) => {
    removeSpeakerMutation.mutate({
      forumId,
      forumSeminarId: seminarId,
      speakerId: speaker.forumSeminarSpeakerId,
    });
  };

  const addFullName = (speakers) => {
    return speakers.map((speaker) => {
      return {
        fullName: `${speaker.firstName} ${speaker.surname}`,
        ...speaker,
      };
    });
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const handleReset = (clearFilters, _, __, confirm) => {
    clearFilters();
    confirm();
  };

  const onFilter = (value, record, dataIndex) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());

  const possibleSpeakersColumns = [
    {
      title: t("People Name"),
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: t("Company"),
      dataIndex: "companyName",
      ...getColumnSearchProps("companyName", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <span className={styles.codeIcon} title={t("Assign Speaker")}>
            <PlusCircleOutlined onClick={() => handleSpeakerAdd(record)} />
          </span>
        );
      },
    },
  ];

  const assignedSpeakersColumns = [
    {
      title: t("People Name"),
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: t("Company"),
      dataIndex: "companyName",
      ...getColumnSearchProps("companyName", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <span className={styles.codeIcon} title={t("Remove Speaker")}>
            <MinusCircleOutlined onClick={() => handleSpeakerRemove(record)} />
          </span>
        );
      },
    },
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col span={12}>
          <h4>
            <strong>{t("Not Assigned Speakers")}</strong>
          </h4>
          <Table
            bordered={true}
            columns={possibleSpeakersColumns}
            dataSource={addFullName(possibleSpeakers)}
            loading={isPossibleSpeakersLoading || assignSpeakerMutation.isLoading}
            pagination={false}
            rowKey="id"
          />
        </Col>
        <Col span={12}>
          <h4>
            <strong>{t("Assigned Speakers")}</strong>
          </h4>
          <Table
            columns={assignedSpeakersColumns}
            dataSource={addFullName(assignedSpeakers)}
            bordered={true}
            loading={isAssignedSpeakersLoading || removeSpeakerMutation.isLoading}
            pagination={false}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
};

SpeakerDetails.propTypes = {
  forumId: PropTypes.string.isRequired,
  seminarId: PropTypes.number.isRequired,
};

export default SpeakerDetails;
