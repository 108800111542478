import { SelectionSiteItemTypeMap } from "../../selectionSiteContentItemTypeUtils";
import Content from "./content";
import ContentItem from "./content-item";
import getContentItemsIds from "./get-content-items-ids";
import getDependencies from "./get-dependencies";

const createDependenciesCollector = (typesToPick: Array<keyof SelectionSiteItemTypeMap>) => {
  return (content: Content, item: ContentItem) => {
    // we do not collect dependencies across all items for performance reasons
    const dependencies = content.getItemsByTypes(typesToPick);

    return getContentItemsIds(getDependencies(dependencies, item));
  };
};

export default createDependenciesCollector;
