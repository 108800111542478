import { Input, Radio, Select } from "components/styleguide";
import AttendeeGrade from "enums/AttendeeGrade";
import AttendeeStatus from "enums/AttendeeStatus";
import Gender from "enums/Gender";
import { CompanyModel, CountryModel, ParticipationCodeModel } from "generated/api";
import { toNumber } from "lodash";
import { TFunction } from "react-i18next";

const { Option } = Select;
const batches = Array.from(Array(301).keys());

interface Restriction {
  isRequired?: boolean;
  maxLength?: number;
}

interface FormFieldsType {
  title: string;
  dataIndex: string;
  component?: JSX.Element;
  initialValue?: number | string | null | undefined;
  restrictions?: Restriction;
}

export const getFormFields = ({
  t,
  company,
  participationCodes,
  email,
  email2,
  setEmail,
  setEmail2,
  countries,
}: {
  t: TFunction;
  company: CompanyModel | undefined;
  participationCodes: ParticipationCodeModel[];
  email: string | null;
  email2: string | null;
  setEmail: (value: string | null) => void;
  setEmail2: (value: string | null) => void;
  countries: CountryModel[] | undefined;
}): FormFieldsType[] => [
  {
    title: "First Name",
    dataIndex: "firstName",
    restrictions: {
      isRequired: true,
      maxLength: 50,
    },
  },
  {
    title: "Last Name",
    dataIndex: "surname",
    restrictions: {
      isRequired: true,
      maxLength: 50,
    },
  },
  {
    title: "People Type",
    dataIndex: "type",
    restrictions: {
      isRequired: true,
    },
    component: (
      <Select>
        {participationCodes.map((c) => (
          <Option key={c.code} value={c.code}>
            {c.code}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Company",
    dataIndex: "companyId",
    initialValue: company?.id,
    component: (
      <Select disabled={true}>
        <Option value={company?.id}>{company?.name}</Option>
      </Select>
    ),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    restrictions: {
      maxLength: 30,
    },
  },
  {
    title: "Mobile Phone",
    dataIndex: "mobilePhone",
    restrictions: {
      maxLength: 50,
    },
  },
  {
    title: "Attend Status",
    dataIndex: "attendStatus",
    restrictions: {
      isRequired: true,
    },
    component: (
      <Select>
        {Object.keys(AttendeeStatus)
          .filter((k) => k !== "toDisplayName")
          .map((k) => (
            <Option
              value={AttendeeStatus[k as keyof typeof AttendeeStatus]}
              key={toNumber(AttendeeStatus[k as keyof typeof AttendeeStatus])}
            >
              {AttendeeStatus.toDisplayName(toNumber(AttendeeStatus[k as keyof typeof AttendeeStatus]))}
            </Option>
          ))}
      </Select>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    restrictions: {
      isRequired: true,
      maxLength: 100,
    },
    component: <Input onChange={(event) => setEmail(event.target.value)} />,
  },
  {
    title: "Email 2",
    dataIndex: "secEmail",
    restrictions: {
      maxLength: 100,
    },
    component: <Input onChange={(event) => setEmail2(event.target.value)} />,
  },
  {
    title: "Mail To",
    dataIndex: "mailToAddress",
    component:
      email || email2 ? (
        <Radio.Group>
          {email && <Radio value={0}>{email}</Radio>}
          {email2 && <Radio value={1}>{email2}</Radio>}
        </Radio.Group>
      ) : (
        <span style={{ color: "var(--text-secondary)" }}>{"This will be populated once Email or Email2 is added"}</span>
      ),
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    restrictions: {
      isRequired: true,
      maxLength: 50,
    },
  },
  {
    title: "Grade",
    dataIndex: "grade",
    component: (
      <Select>
        {Object.keys(AttendeeGrade)
          .filter((k) => k !== "toDisplayName")
          .map((k) => (
            <Option
              value={AttendeeGrade[k as keyof typeof AttendeeGrade]}
              key={toNumber(AttendeeGrade[k as keyof typeof AttendeeGrade])}
            >
              {AttendeeGrade.toDisplayName(toNumber(AttendeeGrade[k as keyof typeof AttendeeGrade]))}
            </Option>
          ))}
      </Select>
    ),
  },
  {
    title: "Batch",
    dataIndex: "batch",
    component: (
      <Select showSearch>
        {batches.map((b) => (
          <Option key={b} value={b < 10 ? `0${b}` : `${b}`}>
            {b < 10 ? `0${b}` : `${b}`}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Gender",
    dataIndex: "gender",
    component: (
      <Select>
        {Object.keys(Gender).map((key) => (
          <Option key={key}>{t(Gender[key as keyof typeof Gender])}</Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Address",
    dataIndex: "address1Line1",
    initialValue: company?.addressLine1,
    restrictions: {
      maxLength: 50,
    },
  },
  {
    title: "Address line 2",
    dataIndex: "address1Line2",
    initialValue: company?.addressLine2,
    restrictions: {
      maxLength: 50,
    },
  },
  {
    title: "County",
    dataIndex: "county",
    initialValue: company?.county,
    restrictions: {
      maxLength: 30,
    },
  },
  {
    title: "City",
    dataIndex: "city",
    initialValue: company?.city,
    restrictions: {
      maxLength: 30,
    },
  },
  {
    title: "Postcode",
    dataIndex: "postcode",
    initialValue: company?.postcode,
    restrictions: {
      maxLength: 10,
    },
  },
  {
    title: "Country",
    dataIndex: "country",
    initialValue: company?.country,
    component: (
      <Select>
        {countries?.map((c) => (
          <Option value={c.name} key={c.isoCode}>
            {c.name}
          </Option>
        ))}
      </Select>
    ),
  },
];

export const getFormFieldRules = (t: TFunction, propName: string, restrictions: Restriction) => {
  const rules = [];

  const { isRequired, maxLength } = restrictions;

  if (isRequired && propName) {
    rules.push({
      required: true,
      message: t("errors.required", {
        prop: t(propName),
      }),
    });
  }

  if (maxLength) {
    rules.push({
      max: maxLength,
      message: t("The character limit is {{count}}", {
        count: maxLength,
      }),
    });
  }

  return rules;
};
