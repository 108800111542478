import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { chunk, toNumber } from "lodash";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ForumResponseModel, ItineraryBookletsDisplaySettingModel, TranslationLanguage } from "generated/api";
import { Divider, Row, Spin, Typography } from "components/styleguide";
import { useLanguagesQuery } from "api/queries/languages";
import { useGetLanguageLabelsTimeFormatsQuery } from "api/queries/languageLabelsTimeFormats";
import { useUpdateLanguageLabelsTimeFormatsMutation } from "api/mutations/languageLabelsTimeFormats";
import KEYS from "api/queries/keys";

import { useForumsQuery, useGetForumByIdQuery } from "../../../../api/queries/forums";
import FormItemBox from "./components/FormItemBox";
import { getSections } from "./helpers";
import { ItinerarySettingValue } from "./types";

const LanguageLabelsTimeFormats = () => {
  const { id } = useParams();
  const forumId = toNumber(id);

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [forum, setForum] = useState<ForumResponseModel>();

  const { isLoading, data: { data: essentialsData = {} as ItineraryBookletsDisplaySettingModel } = {} } =
    useGetLanguageLabelsTimeFormatsQuery({
      forumId: toNumber(forumId),
    });

  const {
    isLoading: isLoadingForums,
    refetch,
    data: { data: { items: forums = [] as Array<ForumResponseModel> } = {} } = {},
  } = useForumsQuery(
    {
      venueId: forum?.venueId as number,
      minEndDate: forum?.endDate,
    },
    {
      enabled: !!forum,
    },
  );

  const filteredForums = forums.filter((f) => f.id !== forumId);

  const { isLoading: isLoadingForum } = useGetForumByIdQuery(
    { forumId: toNumber(forumId) },
    {
      onSuccess: ({ data = {} as ForumResponseModel }) => {
        setForum(data);
        refetch();
      },
    },
  );

  const { isLoading: isLanguagesLoading, data: { data: languages = [] as Array<TranslationLanguage> } = {} } =
    useLanguagesQuery();

  const { mutate, isLoading: isUpdateLoading } = useUpdateLanguageLabelsTimeFormatsMutation();

  const onSubmit = useCallback(
    (value: ItinerarySettingValue, dataIndex: string) => {
      if (value == essentialsData[dataIndex as keyof ItineraryBookletsDisplaySettingModel]) {
        return;
      }

      const itineraryBookletsDisplaySettingModel: ItineraryBookletsDisplaySettingModel = {
        ...essentialsData,
        [dataIndex]: value,
      };

      mutate(
        { forumId: toNumber(forumId), itineraryBookletsDisplaySettingModel },
        {
          onSuccess: () => toast.success(t("Saved successfully")),
          onError: () => toast.error(t("Something went wrong")),
          onSettled: () => queryClient.invalidateQueries([KEYS.GET_LANGUAGE_LABELS_TIME_FORMATS_ESSENTIALS]),
        },
      );
    },
    [essentialsData, forumId, mutate, queryClient, t],
  );

  const sections = useMemo(
    () =>
      getSections(onSubmit, languages, filteredForums).map(({ title, description, formItems }) => {
        const pairedFormItems = chunk(formItems, 2);

        const formItemsElements = pairedFormItems.map((pair, index) => {
          const isLastPair = index === pairedFormItems.length - 1;
          const leftElement = pair[0];
          const rightElement = pair[1];
          const leftElementData = essentialsData[leftElement.dataIndex as keyof ItineraryBookletsDisplaySettingModel];
          const rightElementData =
            rightElement && essentialsData[rightElement.dataIndex as keyof ItineraryBookletsDisplaySettingModel];

          return (
            <Row gutter={80} key={index}>
              <FormItemBox data={leftElementData} {...leftElement} isLastPair={isLastPair} onSubmit={onSubmit} />

              {rightElement && (
                <FormItemBox data={rightElementData} {...rightElement} isLastPair={isLastPair} onSubmit={onSubmit} />
              )}
            </Row>
          );
        });

        return (
          <div key={title}>
            <Typography.Title style={{ paddingBottom: !description ? "20px" : "0px" }} level={5}>
              {t(title)}
            </Typography.Title>
            {description && (
              <Typography.Paragraph style={{ paddingBottom: "20px" }}>{t(description)}</Typography.Paragraph>
            )}
            {formItemsElements}
            <Divider />
          </div>
        );
      }),
    [t, essentialsData, onSubmit, languages, forums],
  );

  if (isLoading || isLoadingForum || isLoadingForums || isLanguagesLoading || isUpdateLoading) {
    return (
      <div className={"loader"}>
        <Spin />
      </div>
    );
  }
  return <div>{sections}</div>;
};

export default LanguageLabelsTimeFormats;
