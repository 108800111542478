import { AttendeeDateFilterDisplay } from "enums/AttendeeDateFilterBy";
import { useTranslation } from "react-i18next";

import FullHeightForm from "../../../../../components/FullHeightForm";
import { Button, Form, Select } from "../../../../../components/styleguide";
import { AttendeeAdvancedFilterTypes } from "../../../../../generated/api";
import { AvailableFilters, AvailableFiltersType } from "../../types";
import FilterByType from "../FilterByType";

const filterTypes: Record<AvailableFiltersType, string> = {
  questionnaire: "Questionnaire",
  selectionSites: "Selection sites",
  supplierContractDate: AttendeeDateFilterDisplay.get(AttendeeAdvancedFilterTypes.SupplierContractDate) ?? "",
  delegateSpeakerBFR: AttendeeDateFilterDisplay.get(AttendeeAdvancedFilterTypes.SpeakerBfr) ?? "",
  cancellationDate: AttendeeDateFilterDisplay.get(AttendeeAdvancedFilterTypes.CancellationDate) ?? "",
};

interface FormProps {
  value?: AvailableFilters;
  onCancel?: () => void;
  onChange?: (value: AvailableFilters | undefined) => void;
}

const FiltersForm = ({ value, onCancel, onChange }: FormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<AvailableFilters>();

  const handleReset = () => {
    onChange?.(undefined);
  };

  const handleFormSubmit = (values: AvailableFilters) => {
    onChange?.(values);
  };

  // eslint-disable-next-line
  const handleFieldValueChange = (changedFields: any) => {
    if ("type" in changedFields) {
      form.resetFields(["id", "statuses", "dateRange"]);
    }
  };

  return (
    <FullHeightForm<AvailableFilters>
      form={form}
      initialValues={value}
      labelCol={{ span: 24 }}
      onValuesChange={handleFieldValueChange}
      wrapperCol={{ span: 24 }}
      actionsPrepend={<Button onClick={onCancel}>{t("Cancel")}</Button>}
      actionsAppend={
        <>
          <Button type="text" onClick={handleReset}>
            {t("Reset")}
          </Button>
          <Button type="primary" htmlType="submit">
            {t("Apply filter")}
          </Button>
        </>
      }
      onFinish={handleFormSubmit}
    >
      <Form.Item name="type" label={t("Type")}>
        <Select placeholder={t("Select filter type")}>
          {Object.entries(filterTypes).map(([key, label]) => (
            <Select.Option key={key} value={key}>
              {t(label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item noStyle dependencies={["type"]}>
        {() => <FilterByType type={form.getFieldValue(["type"])} />}
      </Form.Item>
    </FullHeightForm>
  );
};

export default FiltersForm;
