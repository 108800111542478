import { ForumSeminarTypeApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumSeminarTypeApi);

const ForumSeminarType = {
  getAll: api.forumsForumIdSeminarTypesGet,
  delete: api.forumsForumIdSeminarTypesForumSeminarTypeIdDelete,
};

export default ForumSeminarType;
