import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const forumContactInfoKey = "forum_contact_info";

export const useForumsYearsQuery = getWrappedUseQuery(KEYS.GET_FORUM_YEARS, apiClient.Forums.years.get);

export const useForumsQuery = getWrappedUseQuery(KEYS.GET_FORUMS, apiClient.Forums.getAll);

export const useGetForumByIdQuery = getWrappedUseQuery(KEYS.GET_FORUM_BY_ID, apiClient.Forums.get);

export const useForumContactInfoQuery = getWrappedUseQuery(KEYS.GET_FORUM_CONTACT_INFO, apiClient.Forums.contact.get);

export const useGetForumSocialLinksQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_SOCIAL_LINKS,
  apiClient.Forums.socialLinks.get,
);

export const useForumSisterEventsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_SISTER_EVENTS,
  apiClient.Forums.sisterEvents.get,
);
