import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useItineraryBookletDocumentGenerationRunMutation = getWrappedUseMutation(
  apiClient.ItineraryBooklets.documentGenerationRuns.put,
);

export const useAddDocumentToQueueMutation = getWrappedUseMutation(apiClient.ItineraryBooklets.queuedDocuments.post);

export const useCancelDocumentGenerationMutation = getWrappedUseMutation(
  apiClient.ItineraryBooklets.cancelDocument.post,
);
