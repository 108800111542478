/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeMeetingLocationModel } from '../apiModels';
// @ts-ignore
import { AttendeeMeetingLocationTimeSlotTypeModel } from '../apiModels';
// @ts-ignore
import { AttendeeMeetingLocationsRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeStatus } from '../apiModels';
// @ts-ignore
import { Em2ExceptionResponseObject } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseMeetingLocationResponseModel } from '../apiModels';
// @ts-ignore
import { TimeSlotType } from '../apiModels';
/**
 * ForumAttendeeMeetingLocationsApi - axios parameter creator
 * @export
 */
export const ForumAttendeeMeetingLocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets Meeting Locations configured for an Attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdMeetingLocationsGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forums/{forumId}/attendees/{attendeeId}/meeting-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Configure Meeting Locations for an Attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeMeetingLocationsRequestModel} [attendeeMeetingLocationsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdMeetingLocationsPut: async (forumId: number, attendeeId: number, attendeeMeetingLocationsRequestModel?: AttendeeMeetingLocationsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsPut', 'attendeeId', attendeeId)
            const localVarPath = `/forums/{forumId}/attendees/{attendeeId}/meeting-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeMeetingLocationsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a Meeting Location for an Attendee and a Time Slot Type
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {TimeSlotType} timeSlotType 
         * @param {AttendeeMeetingLocationModel} [attendeeMeetingLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut: async (forumId: number, attendeeId: number, timeSlotType: TimeSlotType, attendeeMeetingLocationModel?: AttendeeMeetingLocationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut', 'attendeeId', attendeeId)
            // verify required parameter 'timeSlotType' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut', 'timeSlotType', timeSlotType)
            const localVarPath = `/forums/{forumId}/attendees/{attendeeId}/meeting-locations/{timeSlotType}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"timeSlotType"}}`, encodeURIComponent(String(timeSlotType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeMeetingLocationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {Array<number>} [forumGroupIds] Gets or sets the array of group identifiers within the forum for which meeting location information is requested.
         * @param {number} [timeSlotId] Gets or sets the array of timeslot identifiers for which meeting location information is requested.
         * @param {Array<string>} [forumAttendeeParticipationCodes] 
         * @param {Array<number>} [forumFacilityIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesMeetingLocationsExportGet: async (forumId: number, forumGroupIds?: Array<number>, timeSlotId?: number, forumAttendeeParticipationCodes?: Array<string>, forumFacilityIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesMeetingLocationsExportGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/attendees/meeting-locations/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forumGroupIds) {
                localVarQueryParameter['ForumGroupIds'] = forumGroupIds;
            }

            if (timeSlotId !== undefined) {
                localVarQueryParameter['TimeSlotId'] = timeSlotId;
            }

            if (forumAttendeeParticipationCodes) {
                localVarQueryParameter['ForumAttendeeParticipationCodes'] = forumAttendeeParticipationCodes;
            }

            if (forumFacilityIds) {
                localVarQueryParameter['ForumFacilityIds'] = forumFacilityIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Meeting Locations for attendees, filtered by group and time type
         * @param {number} forumId 
         * @param {TimeSlotType} timeType 
         * @param {string} [orderBy] 
         * @param {Array<number>} [forumGroupIds] Optional; provide null value in order to get the Attendees from all ForumGroups
         * @param {Array<number>} [forumFacilityIds] 
         * @param {string} [forumAttendeeName] 
         * @param {Array<string>} [forumAttendeeParticipationCodes] 
         * @param {string} [forumCompanyName] 
         * @param {Array<AttendeeStatus>} [attendeeStatuses] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesMeetingLocationsGet: async (forumId: number, timeType: TimeSlotType, orderBy?: string, forumGroupIds?: Array<number>, forumFacilityIds?: Array<number>, forumAttendeeName?: string, forumAttendeeParticipationCodes?: Array<string>, forumCompanyName?: string, attendeeStatuses?: Array<AttendeeStatus>, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesMeetingLocationsGet', 'forumId', forumId)
            // verify required parameter 'timeType' is not null or undefined
            assertParamExists('forumsForumIdAttendeesMeetingLocationsGet', 'timeType', timeType)
            const localVarPath = `/forums/{forumId}/attendees/meeting-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (timeType !== undefined) {
                localVarQueryParameter['TimeType'] = timeType;
            }

            if (forumGroupIds) {
                localVarQueryParameter['ForumGroupIds'] = forumGroupIds;
            }

            if (forumFacilityIds) {
                localVarQueryParameter['ForumFacilityIds'] = forumFacilityIds;
            }

            if (forumAttendeeName !== undefined) {
                localVarQueryParameter['ForumAttendeeName'] = forumAttendeeName;
            }

            if (forumAttendeeParticipationCodes) {
                localVarQueryParameter['ForumAttendeeParticipationCodes'] = forumAttendeeParticipationCodes;
            }

            if (forumCompanyName !== undefined) {
                localVarQueryParameter['ForumCompanyName'] = forumCompanyName;
            }

            if (attendeeStatuses) {
                localVarQueryParameter['AttendeeStatuses'] = attendeeStatuses;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeMeetingLocationsApi - functional programming interface
 * @export
 */
export const ForumAttendeeMeetingLocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeMeetingLocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets Meeting Locations configured for an Attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesAttendeeIdMeetingLocationsGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeMeetingLocationTimeSlotTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesAttendeeIdMeetingLocationsGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Configure Meeting Locations for an Attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {AttendeeMeetingLocationsRequestModel} [attendeeMeetingLocationsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesAttendeeIdMeetingLocationsPut(forumId: number, attendeeId: number, attendeeMeetingLocationsRequestModel?: AttendeeMeetingLocationsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeMeetingLocationTimeSlotTypeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesAttendeeIdMeetingLocationsPut(forumId, attendeeId, attendeeMeetingLocationsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a Meeting Location for an Attendee and a Time Slot Type
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {TimeSlotType} timeSlotType 
         * @param {AttendeeMeetingLocationModel} [attendeeMeetingLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut(forumId: number, attendeeId: number, timeSlotType: TimeSlotType, attendeeMeetingLocationModel?: AttendeeMeetingLocationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut(forumId, attendeeId, timeSlotType, attendeeMeetingLocationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {Array<number>} [forumGroupIds] Gets or sets the array of group identifiers within the forum for which meeting location information is requested.
         * @param {number} [timeSlotId] Gets or sets the array of timeslot identifiers for which meeting location information is requested.
         * @param {Array<string>} [forumAttendeeParticipationCodes] 
         * @param {Array<number>} [forumFacilityIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesMeetingLocationsExportGet(forumId: number, forumGroupIds?: Array<number>, timeSlotId?: number, forumAttendeeParticipationCodes?: Array<string>, forumFacilityIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesMeetingLocationsExportGet(forumId, forumGroupIds, timeSlotId, forumAttendeeParticipationCodes, forumFacilityIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets Meeting Locations for attendees, filtered by group and time type
         * @param {number} forumId 
         * @param {TimeSlotType} timeType 
         * @param {string} [orderBy] 
         * @param {Array<number>} [forumGroupIds] Optional; provide null value in order to get the Attendees from all ForumGroups
         * @param {Array<number>} [forumFacilityIds] 
         * @param {string} [forumAttendeeName] 
         * @param {Array<string>} [forumAttendeeParticipationCodes] 
         * @param {string} [forumCompanyName] 
         * @param {Array<AttendeeStatus>} [attendeeStatuses] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesMeetingLocationsGet(forumId: number, timeType: TimeSlotType, orderBy?: string, forumGroupIds?: Array<number>, forumFacilityIds?: Array<number>, forumAttendeeName?: string, forumAttendeeParticipationCodes?: Array<string>, forumCompanyName?: string, attendeeStatuses?: Array<AttendeeStatus>, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseMeetingLocationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesMeetingLocationsGet(forumId, timeType, orderBy, forumGroupIds, forumFacilityIds, forumAttendeeName, forumAttendeeParticipationCodes, forumCompanyName, attendeeStatuses, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeMeetingLocationsApi - factory interface
 * @export
 */
export const ForumAttendeeMeetingLocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeMeetingLocationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets Meeting Locations configured for an Attendee
         * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdMeetingLocationsGet(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeMeetingLocationTimeSlotTypeModel>> {
            return localVarFp.forumsForumIdAttendeesAttendeeIdMeetingLocationsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Configure Meeting Locations for an Attendee
         * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdMeetingLocationsPut(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPutRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeMeetingLocationTimeSlotTypeModel>> {
            return localVarFp.forumsForumIdAttendeesAttendeeIdMeetingLocationsPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeMeetingLocationsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a Meeting Location for an Attendee and a Time Slot Type
         * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.timeSlotType, requestParameters.attendeeMeetingLocationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesMeetingLocationsExportGet(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsForumIdAttendeesMeetingLocationsExportGet(requestParameters.forumId, requestParameters.forumGroupIds, requestParameters.timeSlotId, requestParameters.forumAttendeeParticipationCodes, requestParameters.forumFacilityIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Meeting Locations for attendees, filtered by group and time type
         * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesMeetingLocationsGet(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseMeetingLocationResponseModel> {
            return localVarFp.forumsForumIdAttendeesMeetingLocationsGet(requestParameters.forumId, requestParameters.timeType, requestParameters.orderBy, requestParameters.forumGroupIds, requestParameters.forumFacilityIds, requestParameters.forumAttendeeName, requestParameters.forumAttendeeParticipationCodes, requestParameters.forumCompanyName, requestParameters.attendeeStatuses, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdAttendeesAttendeeIdMeetingLocationsGet operation in ForumAttendeeMeetingLocationsApi.
 * @export
 * @interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGetRequest
 */
export interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumsForumIdAttendeesAttendeeIdMeetingLocationsPut operation in ForumAttendeeMeetingLocationsApi.
 * @export
 * @interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPutRequest
 */
export interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {AttendeeMeetingLocationsRequestModel}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPut
     */
    readonly attendeeMeetingLocationsRequestModel?: AttendeeMeetingLocationsRequestModel
}

/**
 * Request parameters for forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut operation in ForumAttendeeMeetingLocationsApi.
 * @export
 * @interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePutRequest
 */
export interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {TimeSlotType}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut
     */
    readonly timeSlotType: TimeSlotType

    /**
     * 
     * @type {AttendeeMeetingLocationModel}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut
     */
    readonly attendeeMeetingLocationModel?: AttendeeMeetingLocationModel
}

/**
 * Request parameters for forumsForumIdAttendeesMeetingLocationsExportGet operation in ForumAttendeeMeetingLocationsApi.
 * @export
 * @interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGetRequest
 */
export interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGet
     */
    readonly forumId: number

    /**
     * Gets or sets the array of group identifiers within the forum for which meeting location information is requested.
     * @type {Array<number>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * Gets or sets the array of timeslot identifiers for which meeting location information is requested.
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGet
     */
    readonly timeSlotId?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGet
     */
    readonly forumAttendeeParticipationCodes?: Array<string>

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGet
     */
    readonly forumFacilityIds?: Array<number>
}

/**
 * Request parameters for forumsForumIdAttendeesMeetingLocationsGet operation in ForumAttendeeMeetingLocationsApi.
 * @export
 * @interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGetRequest
 */
export interface ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {TimeSlotType}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly timeType: TimeSlotType

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly orderBy?: string

    /**
     * Optional; provide null value in order to get the Attendees from all ForumGroups
     * @type {Array<number>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly forumFacilityIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly forumAttendeeName?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly forumAttendeeParticipationCodes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly forumCompanyName?: string

    /**
     * 
     * @type {Array<AttendeeStatus>}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly attendeeStatuses?: Array<AttendeeStatus>

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGet
     */
    readonly orderDir?: string
}

/**
 * ForumAttendeeMeetingLocationsApi - object-oriented interface
 * @export
 * @class ForumAttendeeMeetingLocationsApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeMeetingLocationsApi extends BaseAPI {
    /**
     * 
     * @summary Gets Meeting Locations configured for an Attendee
     * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingLocationsApi
     */
    public forumsForumIdAttendeesAttendeeIdMeetingLocationsGet(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingLocationsApiFp(this.configuration).forumsForumIdAttendeesAttendeeIdMeetingLocationsGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Configure Meeting Locations for an Attendee
     * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingLocationsApi
     */
    public forumsForumIdAttendeesAttendeeIdMeetingLocationsPut(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingLocationsApiFp(this.configuration).forumsForumIdAttendeesAttendeeIdMeetingLocationsPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.attendeeMeetingLocationsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a Meeting Location for an Attendee and a Time Slot Type
     * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingLocationsApi
     */
    public forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingLocationsApiFp(this.configuration).forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.timeSlotType, requestParameters.attendeeMeetingLocationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingLocationsApi
     */
    public forumsForumIdAttendeesMeetingLocationsExportGet(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsExportGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingLocationsApiFp(this.configuration).forumsForumIdAttendeesMeetingLocationsExportGet(requestParameters.forumId, requestParameters.forumGroupIds, requestParameters.timeSlotId, requestParameters.forumAttendeeParticipationCodes, requestParameters.forumFacilityIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Meeting Locations for attendees, filtered by group and time type
     * @param {ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingLocationsApi
     */
    public forumsForumIdAttendeesMeetingLocationsGet(requestParameters: ForumAttendeeMeetingLocationsApiForumsForumIdAttendeesMeetingLocationsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingLocationsApiFp(this.configuration).forumsForumIdAttendeesMeetingLocationsGet(requestParameters.forumId, requestParameters.timeType, requestParameters.orderBy, requestParameters.forumGroupIds, requestParameters.forumFacilityIds, requestParameters.forumAttendeeName, requestParameters.forumAttendeeParticipationCodes, requestParameters.forumCompanyName, requestParameters.attendeeStatuses, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }
}

