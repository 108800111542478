import { Space } from "components/styleguide";
import { AttendeeModel } from "generated/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AttendeesTableOption from "../OptionTypes/AttendeesTableOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = { SeminarType: "Seminar Type", Priority: "Priority" };

const PreferencesByAnIndividualOptions = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<string>("SeminarType");

  const onAttendeeSelect = (attendee: AttendeeModel) => {
    navigate("report", {
      state: {
        parameters: {
          ForumAttendeeId: attendee.attendId,
          OrderBy: orderBy,
        },
      },
    });
  };

  return (
    <Space direction={"vertical"} size={"large"} fullWidth>
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />

      <AttendeesTableOption onAttendeeSelect={onAttendeeSelect} />
    </Space>
  );
};

export default PreferencesByAnIndividualOptions;
