import { useForumSisterEventsQuery } from "api/queries/forums";
import { Select, Space, Typography } from "components/styleguide";
import { toNumber } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const { Text } = Typography;

interface SisterEventsSelectOptionProps {
  sisterEvents: number[];
  setSisterEvents: Dispatch<SetStateAction<number[]>>;
}

type EventsOption = { value: number; label: string };

const SisterEventsSelectOption = ({ sisterEvents, setSisterEvents }: SisterEventsSelectOptionProps) => {
  const { t } = useTranslation();
  const { id: forumId } = useParams();

  const [sisterEventsOptions, setSisterEventsOptions] = useState<EventsOption[]>([]);

  const { isLoading: isEventsLoading } = useForumSisterEventsQuery(
    {
      forumId: toNumber(forumId),
    },
    {
      onSuccess: ({ data: events = [] }) => {
        const options = events.map(({ id, title }) => ({ value: id, label: title }));

        setSisterEventsOptions(options as EventsOption[]);
      },
    },
  );

  return (
    <Space direction={"vertical"}>
      <Text>{t("Sister Events")}</Text>

      <Select
        allowClear
        showSearch
        style={{ minWidth: "200px" }}
        mode={"multiple"}
        optionFilterProp={"label"}
        options={sisterEventsOptions}
        placeholder={t("Select Sister Events")}
        value={sisterEvents}
        onChange={setSisterEvents}
        loading={isEventsLoading}
      />
    </Space>
  );
};

export default SisterEventsSelectOption;
