import { useQueryClient } from "@tanstack/react-query";
import { FormInstance } from "antd";
import { toNumber } from "lodash";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useUpdateGroupFlagsMutation } from "../../../api/mutations/groups";
import { getForumGroupsQueryKey } from "../../../api/queries/groups";
import { Avatar, Button, Col, Form, Input, Row, Select } from "../../../components/styleguide";
import ForumGroupColor from "../../../enums/ForumGroupColor";
import { ForumGroupFlagsRequestModel, ForumGroupResponseModel } from "../../../generated/api";

type ForumGroupColorType = Exclude<keyof typeof ForumGroupColor, "toDisplayName">;

const forumGroupColorTypes = Object.keys(ForumGroupColor).filter(
  (k) => k !== "toDisplayName",
) as Array<ForumGroupColorType>;

const colors = forumGroupColorTypes.map((c) => (
  <Select.Option key={ForumGroupColor[c]} value={ForumGroupColor[c]}>
    <Row gutter={18} justify="start" align="middle">
      <Col>
        <Avatar size={20} style={{ backgroundColor: c }} />
      </Col>
      <Col>{ForumGroupColor.toDisplayName(ForumGroupColor[c])}</Col>
    </Row>
  </Select.Option>
));

interface GroupFlagsLabelsProps {
  group: ForumGroupResponseModel;
  onSubmit: () => void;
  form: FormInstance;
}

const GroupFlagsLabels: FC<GroupFlagsLabelsProps> = ({ group, onSubmit, form }) => {
  const { t } = useTranslation();
  const forumId = useParams().id;
  const queryClient = useQueryClient();

  const mutation = useUpdateGroupFlagsMutation();

  const submit = (flags: ForumGroupFlagsRequestModel) => {
    mutation.mutate(
      { forumId: toNumber(forumId), groupId: group.id, forumGroupFlagsRequestModel: flags },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([getForumGroupsQueryKey]);
          onSubmit();
        },
      },
    );
  };

  return (
    <Form id="groupLabels" name="labels" layout="vertical" wrapperCol={{ span: 24 }} form={form} onFinish={submit}>
      <Form.Item label={t("Colour")} name="color" wrapperCol={{ span: 6 }}>
        <Select>{colors}</Select>
      </Form.Item>
      <Form.Item label={t("Image Text")} name="imageText">
        <Input />
      </Form.Item>
      <Form.Item label={t("Long Text")} name="longText">
        <Input />
      </Form.Item>
      <Button type={"primary"} htmlType={"submit"} loading={mutation.isLoading}>
        {t("Save")}
      </Button>
    </Form>
  );
};

export default GroupFlagsLabels;
