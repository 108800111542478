import "react-quill/dist/quill.snow.css";
import "./styles.css";

import React, { useEffect, useMemo, useRef } from "react";
import ReactQuill from "react-quill";

// eslint-disable-next-line import/no-named-as-default
import QuillToolbar, { formats, modules } from "./QuillToolbar";

interface TextEditorProps {
  value?: string;
  options?: string[];
  mergeTags?: string[];
  placeholder?: string;
  onQuillAvailable?: (ref: React.RefObject<ReactQuill>) => void;
  maxLength?: number;
  imageHandler?: () => void;
  editorRef?: React.RefObject<ReactQuill>;
  onChange?: (value: string) => void;
  showListFormats?: boolean;
}

const MAX_LENGTH = 3000;

const TextEditor: React.FC<TextEditorProps> = ({
  value,
  onQuillAvailable,
  onChange,
  mergeTags,
  imageHandler,
  placeholder,
  editorRef,
  maxLength = MAX_LENGTH,
  showListFormats = false,
}) => {
  const defaultRef = useRef<ReactQuill>(null);
  const quillRef = editorRef ?? defaultRef;

  const hasCustomHandlers = !!imageHandler;
  const editorModules = useMemo(
    () =>
      hasCustomHandlers
        ? {
            toolbar: {
              container: modules.toolbar.container,
              handlers: {
                image: imageHandler ?? null,
                ...modules.toolbar.handlers,
              },
            },
          }
        : modules,
    [],
  );

  useEffect(() => {
    if (onQuillAvailable) {
      onQuillAvailable(quillRef);
    }
    const quillEditor = quillRef?.current?.getEditor();
    const handler = () => {
      const text = quillEditor?.getText().trim()?.length;
      if (text && text > maxLength) {
        quillEditor?.deleteText(maxLength, text);
      }
    };
    quillEditor?.on("text-change", handler);
    return () => {
      quillEditor?.off("text-change", handler);
    };
  }, [value]);

  const memoValue = useMemo(() => value, [value]);

  return (
    <>
      <QuillToolbar mergeTags={mergeTags} hasImageHandler={!!imageHandler} showListFormats={showListFormats} />
      <ReactQuill
        ref={quillRef}
        value={memoValue}
        onChange={onChange}
        theme="snow"
        modules={editorModules}
        formats={formats}
        placeholder={placeholder}
      />
    </>
  );
};

export default TextEditor;
