import { AxiosRequestConfig } from "axios";

import apiClient from "../../../api-client";
import {
  FacilityType,
  ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest,
} from "../../../generated/api";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

type ForumFacilitiesGetAllRequestParams = Omit<
  ForumFacilityApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesGetRequest,
  "type"
>;

export const useForumFacilityQuery = getWrappedUseQuery(KEYS.GET_FORUM_FACILITY, apiClient.ForumFacilities.get);

export const useForumFacilitiesQuery = getWrappedUseQuery(KEYS.GET_FORUM_FACILITIES, apiClient.ForumFacilities.getAll);

export const useForumMeetingRoomsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_MEETING_ROOMS,
  (params: ForumFacilitiesGetAllRequestParams, options: AxiosRequestConfig = {}) =>
    apiClient.ForumFacilities.getAll({ ...params, type: FacilityType.MeetingRoom }, options),
);

export const useForumConferenceRoomsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_CONFERENCE_ROOMS,
  (params: ForumFacilitiesGetAllRequestParams, options: AxiosRequestConfig = {}) =>
    apiClient.ForumFacilities.getAll({ ...params, type: FacilityType.ConferenceRoom }, options),
);

export const useForumSpeedMeetingsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_SPEED_MEETINGS,
  (params: ForumFacilitiesGetAllRequestParams, options: AxiosRequestConfig = {}) =>
    apiClient.ForumFacilities.getAll({ ...params, type: FacilityType.SpeedMeetings }, options),
);

export const useForumRestaurantsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_RESTAURANTS,
  (params: ForumFacilitiesGetAllRequestParams, options: AxiosRequestConfig = {}) =>
    apiClient.ForumFacilities.getAll({ ...params, type: FacilityType.Restaurant }, options),
);

export const useForumBedroomsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_BEDROOMS,
  (params: ForumFacilitiesGetAllRequestParams, options: AxiosRequestConfig = {}) =>
    apiClient.ForumFacilities.getAll({ ...params, type: FacilityType.Bedroom }, options),
);

export const useExportForumBedroomsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_BEDROOMS_EXPORT,
  apiClient.ForumFacilities.exportBedrooms.get,
);
