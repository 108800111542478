import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";

import { Button, Menu, Modal, Typography } from "../../../../../../../components/styleguide";

const PageLabel = ({
  title,
  isAddDisabled,
  isDeleteDisabled,
  hasQuestions,
  isSelected,
  onRemoveConfirm,
  onClickAdd,
}) => {
  const handleButtonClick = (e) => {
    if (!isAddDisabled) {
      e.stopPropagation();
      onClickAdd();
    }
  };

  const handleRemoveButtonClick = (e) => {
    if (isDeleteDisabled) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const text = hasQuestions
      ? "Do you want to proceed with deletion of this page, it has questions?"
      : "Do you want to proceed with deletion of this page?";

    Modal.confirm({
      title: "Confirmation",
      content: <Typography.Text>{text}</Typography.Text>,
      closable: true,
      onOk: () => onRemoveConfirm(),
    });
  };

  return (
    <Menu.ItemLabel
      isSelected={isSelected}
      append={
        <Button
          onClick={handleRemoveButtonClick}
          type="text"
          icon={<DeleteOutlined />}
          size="small"
          disabled={isDeleteDisabled}
        />
      }
    >
      <span>{title}</span>
      <Button onClick={handleButtonClick} icon={<PlusOutlined />} size="small" disabled={isAddDisabled} />
    </Menu.ItemLabel>
  );
};

PageLabel.propTypes = {
  title: PropTypes.string.isRequired,
  isAddDisabled: PropTypes.bool,
  isDeleteDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  hasQuestions: PropTypes.bool,
  onRemoveConfirm: PropTypes.func,
  onClickAdd: PropTypes.func,
};

PageLabel.defaultProps = {
  isAddDisabled: false,
  isDeleteDisabled: false,
  hasQuestions: false,
  isSelected: false,
  onRemoveConfirm: () => undefined,
  onClickAdd: () => undefined,
};

export default PageLabel;
