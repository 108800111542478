import { Space, Typography, YesNo } from "components/styleguide";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface FullReasonSelectOptionProps {
  isWithFullReason: boolean;
  setIsWithFullReason: Dispatch<SetStateAction<boolean>>;
}

const WithFullReasonSelectOption = ({ isWithFullReason, setIsWithFullReason }: FullReasonSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("Full Reason")}</Typography.Text>
      <YesNo style={{ width: "200px" }} value={isWithFullReason} onSelect={setIsWithFullReason} />
    </Space>
  );
};

export default WithFullReasonSelectOption;
