import { useQueryClient } from "@tanstack/react-query";
import { useGetForumVenueQuery } from "api/queries/forumVenues";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

import {
  useCreateForumFacilityMutation,
  useDeleteForumFacilityMutation,
  useUpdateForumFacilityMutation,
} from "../../../api/mutations/facilities";
import { useCreateForumFloorMutation, useDeleteForumFloorMutation } from "../../../api/mutations/floors";
import {
  useCreateForumRoomGradeMutation,
  useDeleteForumRoomGradeMutation,
} from "../../../api/mutations/forumRoomGrades";
import { useDeleteForumTableMutation } from "../../../api/mutations/forumTables";
import { useAttendeesQuery } from "../../../api/queries/attendees";
import { useGetForumBuildingQuery } from "../../../api/queries/buildings";
import { getForumBuildingFloorsQueryKey, useForumBuildingFloorsQuery } from "../../../api/queries/floors";
import {
  useExportForumBedroomsQuery,
  useForumBedroomsQuery,
  useForumConferenceRoomsQuery,
  useForumFacilityQuery,
  useForumMeetingRoomsQuery,
  useForumRestaurantsQuery,
  useForumSpeedMeetingsQuery,
} from "../../../api/queries/forumFacilities";
import { useForumRoomGradesQuery } from "../../../api/queries/forumRoomGrades";
import { useForumTablesQuery } from "../../../api/queries/forumTables";
import KEYS from "../../../api/queries/keys";
import Building from "../../../components/VenueDetails/Building";
import { AdminTypes } from "../../../enums/common";
import FacilityType from "../../../enums/FacilityType";
import ErrorTypes from "../../../error-handling/errorTypes";
import { useModernQueryWithPaginationAndOrder } from "../../../hooks";
import VenueContext from "../../../pages/venueContext";

const facilityTypesQueryKeys = {
  [FacilityType.MeetingRoom]: KEYS.GET_FORUM_MEETING_ROOMS,
  [FacilityType.ConferenceRoom]: KEYS.GET_FORUM_CONFERENCE_ROOMS,
  [FacilityType.SpeedMeetings]: KEYS.GET_FORUM_SPEED_MEETINGS,
  [FacilityType.Restaurant]: KEYS.GET_FORUM_RESTAURANTS,
  [FacilityType.Bedroom]: KEYS.GET_FORUM_BEDROOMS,
};

const ForumBuilding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id: forumId, buildingId } = useParams();

  const [codeSearch, setCodeSearch] = useState(undefined);
  const [nameSearch, setNameSearch] = useState(undefined);
  const [descriptionSearch, setDescriptionSearch] = useState(undefined);
  const [roomGradeSearch, setRoomGradeSearch] = useState(undefined);
  const [attendeeSearch, setAttendeeSearch] = useState(undefined);
  const [billingNoteSearch, setBillingNoteSearch] = useState(undefined);
  const [bedroomsOrderCol, setBedroomsOrderCol] = useState(undefined);
  const [bedroomsOrderDir, setBedroomsOrderDir] = useState(undefined);
  const [exportBedroomsDocumentType, setExportBedroomsDocumentType] = useState(null);

  const createForumFacilityMutation = useCreateForumFacilityMutation();
  const updateForumFacilityMutation = useUpdateForumFacilityMutation();
  const deleteForumFacilityMutation = useDeleteForumFacilityMutation();
  const createForumFloorMutation = useCreateForumFloorMutation();
  const deleteForumFloorMutation = useDeleteForumFloorMutation();
  const createForumRoomGradeMutation = useCreateForumRoomGradeMutation();
  const deleteForumRoomGradeMutation = useDeleteForumRoomGradeMutation();

  const queryClient = useQueryClient();

  const { data: { data: floors = [] } = {} } = useForumBuildingFloorsQuery({
    forumId,
    buildingId,
  });

  const { data: { data: roomGrades = [] } = {} } = useForumRoomGradesQuery({
    forumId,
    forumBuildingId: buildingId,
  });

  const { data: { data: venue = {} } = {} } = useGetForumVenueQuery({
    forumId,
  });

  const { data: { data: building = {} } = {} } = useGetForumBuildingQuery(
    { forumId, buildingId },
    {
      onSuccess: ({ data }) => {
        if (!location.state || !location.state.venueName || !location.state.buildingName) {
          const hash = location.hash ?? location.pathname.split("#")[1];

          navigate(`${location.pathname}${hash}`, {
            replace: true,
            hash: hash,
            state: {
              ...location.state,
              venueName: venue.name,
              buildingName: data.name,
            },
          });
        }
      },
    },
  );

  const { data: { data: { items: attendees = [] } = {} } = {} } = useAttendeesQuery({
    forumId,
  });

  const handleFacilityCreateSubmit = (facility) => {
    return createForumFacilityMutation.mutateAsync(
      {
        forumId,
        buildingId,
        facility,
      },
      {
        onSuccess: async () => {
          if (facilityTypesQueryKeys[facility.facilityType]) {
            await queryClient.invalidateQueries([facilityTypesQueryKeys[facility.facilityType]]);
          }
        },
        onError: async (err) => {
          const { response } = err;

          if (
            ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem) &&
            response.data?.meta?.ItemType === "ForumAttendee"
          ) {
            toast.error(t("The selected attendee is already assigned to a bedroom"));
          } else if (
            ErrorTypes.isOfType(response, ErrorTypes.ObjectNotFound) &&
            response.data?.meta?.ObjectType === "BillingNote"
          ) {
            toast.error(t("Billing note not found"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const handleFacilityEditSubmit = (
    facility,
    facilityId,
    originalFacilityType,
    additionalOnSuccess,
    additionalOnError,
  ) => {
    return updateForumFacilityMutation.mutateAsync(
      { forumId, buildingId, facility, facilityId },
      {
        onSuccess: async () => {
          const facilityTypeQueryKey =
            facilityTypesQueryKeys[FacilityType[facility.facilityType]] ||
            facilityTypesQueryKeys[facility.facilityType];
          const originalFacilityTypeQueryKey =
            facilityTypesQueryKeys[FacilityType[originalFacilityType]] || facilityTypesQueryKeys[originalFacilityType];

          if (facilityTypeQueryKey) {
            await queryClient.invalidateQueries([facilityTypeQueryKey]);
            await queryClient.invalidateQueries([originalFacilityTypeQueryKey]);
          }

          additionalOnSuccess?.();
        },
        onError: async (err) => {
          const { response } = err;

          if (
            ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem) &&
            response.data?.meta?.ItemType === "ForumAttendee"
          ) {
            toast.error(t("The selected attendee is already assigned to a bedroom"));
          } else if (
            ErrorTypes.isOfType(response, ErrorTypes.ObjectNotFound) &&
            response.data?.meta?.ObjectType === "BillingNote"
          ) {
            toast.error(t("Billing note not found"));
          } else {
            toast.error(t("Something went wrong"));
          }

          additionalOnError?.(err);
        },
      },
    );
  };

  const handleFacilityDeleteSubmit = (facility) => {
    deleteForumFacilityMutation.mutate(
      { forumId, forumBuildingId: buildingId, forumFacilityId: facility.id },
      {
        onSuccess: async () => {
          const facilityTypeQueryKey =
            facilityTypesQueryKeys[FacilityType[facility.facilityType]] ||
            facilityTypesQueryKeys[facility.facilityType];

          if (facilityTypeQueryKey) {
            await queryClient.invalidateQueries([facilityTypeQueryKey]);
          }

          await queryClient.resetQueries([KEYS.GET_FORUM_FACILITIES]);
          await queryClient.resetQueries([KEYS.GET_FACILITIES]);
        },
        onError: async (err) => {
          const { response } = err;

          if (ErrorTypes.isOfType(response, ErrorTypes.ReferencedObjectCannotBeDeleted)) {
            const referencingObjects = response.data?.meta?.ReferencingObjects;

            const seminarSessions = referencingObjects.filter((x) => x.ObjectType === "ForumSeminarSession");
            const hasSeminarSessions = seminarSessions.length > 0;

            const primaryLocations = referencingObjects.filter((x) => x.ObjectType === "ForumCompanyPrimaryLocation");
            const isPrimaryLocationForCompanies = primaryLocations.length > 0;
            const primaryLocationCompaniesNames = primaryLocations.map((x) => x.ObjectFields.CompanyName).join(", ");

            const tables = referencingObjects.filter((x) => x.ObjectType === "ForumTable");
            const hasTables = tables.length > 0;

            if (hasSeminarSessions && isPrimaryLocationForCompanies) {
              toast.error(
                t(
                  "The facility couldn't be deleted. It has assigned sessions and was selected as a primary location for {{companies}}. Delete sessions and primary location firstly to proceed",
                  { companies: primaryLocationCompaniesNames },
                ),
              );
            } else if (hasSeminarSessions) {
              toast.error(
                t("The facility couldn't be deleted. It has assigned sessions. Delete sessions firstly to proceed"),
              );
            } else if (isPrimaryLocationForCompanies) {
              toast.error(
                t(
                  "The facility couldn't be deleted. It was selected as a primary location for {{companies}}. Delete primary location firstly to proceed",
                  { companies: primaryLocationCompaniesNames },
                ),
              );
            } else if (hasTables) {
              toast.error(t("The facility has assigned tables, delete tables firstly to proceed with deletion"));
            }
          }
        },
      },
    );
  };

  const handleFloorAdd = (name) => {
    createForumFloorMutation.mutate(
      { forumId, buildingId, floor: { name } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([getForumBuildingFloorsQueryKey]);
        },
      },
    );
  };

  const handleFloorDelete = (floorId) => {
    deleteForumFloorMutation.mutate(
      { forumId, buildingId, floorId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([getForumBuildingFloorsQueryKey]);
          Object.values(facilityTypesQueryKeys).forEach((value) => queryClient.invalidateQueries(value));
        },
      },
    );
  };

  const handleRoomGradeAdd = (name) => {
    createForumRoomGradeMutation.mutate(
      { forumId, forumBuildingId: buildingId, roomGradeRequestModel: { name } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_ROOM_GRADES]);
        },
      },
    );
  };

  const handleRoomGradeDelete = (forumGradeId) => {
    deleteForumRoomGradeMutation.mutate(
      { forumId, forumBuildingId: buildingId, forumGradeId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_ROOM_GRADES, KEYS.GET_FORUM_BEDROOMS]);
        },
      },
    );
  };

  const useInjectedFacilityQuery = ({ buildingId, facilityId }, options) =>
    useForumFacilityQuery({ forumId, forumBuildingId: buildingId, facilityId }, options);

  const meetingRoomsQuery = useModernQueryWithPaginationAndOrder(
    useForumMeetingRoomsQuery,
    {},
    { forumId, forumBuildingId: buildingId },
  );

  const conferenceRoomsQuery = useModernQueryWithPaginationAndOrder(
    useForumConferenceRoomsQuery,
    {},
    { forumId, forumBuildingId: buildingId },
  );

  const speedMeetingsQuery = useModernQueryWithPaginationAndOrder(
    useForumSpeedMeetingsQuery,
    {},
    { forumId, forumBuildingId: buildingId },
  );
  const restaurantsQuery = useModernQueryWithPaginationAndOrder(
    useForumRestaurantsQuery,
    {},
    { forumId, forumBuildingId: buildingId },
  );

  const bedroomsQuery = useModernQueryWithPaginationAndOrder(
    useForumBedroomsQuery,
    {},
    {
      forumId,
      forumBuildingId: buildingId,
      facilityCode: codeSearch,
      name: nameSearch,
      description: descriptionSearch,
      roomGrade: roomGradeSearch,
      attendee: attendeeSearch,
      billingNote: billingNoteSearch,
    },
  );

  const exportBedroomsQuery = useExportForumBedroomsQuery(
    {
      forumId,
      forumBuildingId: buildingId,
      facilityCode: codeSearch,
      name: nameSearch,
      description: descriptionSearch,
      roomGrade: roomGradeSearch,
      attendee: attendeeSearch,
      billingNote: billingNoteSearch,
      orderBy: bedroomsOrderCol,
      orderDir: bedroomsOrderDir,
      exportType: exportBedroomsDocumentType,
    },
    {
      enabled: !!exportBedroomsDocumentType,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
      onSettled: () => setExportBedroomsDocumentType(null),
    },
  );

  const useInjectedTablesQuery = ({ facilityId, type }, options) =>
    useForumTablesQuery(
      {
        forumId,
        forumBuildingId: buildingId,
        forumFacilityId: facilityId,
        type,
      },
      options,
    );

  const deleteForumTableMutation = useDeleteForumTableMutation();

  const injectedDeleteTableMutate = ({ buildingId, facilityId, tableId }, options) => {
    deleteForumTableMutation.mutate(
      {
        forumId: toNumber(forumId),
        forumBuildingId: toNumber(buildingId),
        forumFacilityId: toNumber(facilityId),
        forumTableId: toNumber(tableId),
      },
      options,
    );
  };

  const handleBedroomTableChange = (_, __, sorter) => {
    bedroomsQuery.handleSort(_, __, sorter);
    setBedroomsOrderCol(sorter.field ?? undefined);
    setBedroomsOrderDir(sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : undefined);
  };

  const venueActions = {
    useFacilityQuery: useInjectedFacilityQuery,
    setCodeSearch: setCodeSearch,
    setNameSearch: setNameSearch,
    setDescriptionSearch: setDescriptionSearch,
    setRoomGradeSearch: setRoomGradeSearch,
    onFacilityCreateSubmit: handleFacilityCreateSubmit,
    isFacilityCreateLoading: createForumFacilityMutation.isLoading,
    onFacilityEditSubmit: handleFacilityEditSubmit,
    isFacilityEditLoading: updateForumFacilityMutation.isLoading,
    onFacilityDeleteSubmit: handleFacilityDeleteSubmit,
    floors: floors,
    onFloorAdd: handleFloorAdd,
    onFloorDelete: handleFloorDelete,
    meetingRoomsQuery: meetingRoomsQuery,
    conferenceRoomsQuery: conferenceRoomsQuery,
    speedMeetingsQuery: speedMeetingsQuery,
    restaurantsQuery: restaurantsQuery,
    useTablesQuery: useInjectedTablesQuery,
    deleteTableMutate: injectedDeleteTableMutate,
    bedroomsQuery: bedroomsQuery,
    roomGrades: roomGrades,
    onRoomGradeAdd: handleRoomGradeAdd,
    onRoomGradeDelete: handleRoomGradeDelete,
    attendees: attendees,
    setAttendeeSearch: setAttendeeSearch,
    exportBedroomsQuery,
    setExportBedroomsDocumentType,
    onBedroomTableChange: handleBedroomTableChange,
    setBillingNoteSearch: setBillingNoteSearch,
  };

  return (
    <VenueContext.Provider value={venueActions}>
      <Building ui={AdminTypes.ForumAdmin} building={building} />
    </VenueContext.Provider>
  );
};

export default ForumBuilding;
