import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import styles from "./styles.module.less";

interface LabelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isSelected?: boolean;
  append?: React.ReactNode;
}

const Label = ({ className, append, isSelected, children, ...props }: PropsWithChildren<LabelProps>) => {
  const hasAppend = append !== undefined && append !== null;
  const classes = clsx(styles.container, className);

  return (
    <div className={classes} {...props}>
      {isSelected && <span className={styles.selectedIcon} />}
      {children}
      {hasAppend && (
        <>
          <div className={styles.spacer} />
          {append}
        </>
      )}
    </div>
  );
};

export default Label;
