import {
  SelectionSiteContentSelectionSiteContentPage,
  SelectionSiteContentSelectionSiteContentPageItemsInner,
} from "../../../generated/api";
import { SelectionSiteItemTypeMap } from "../../selectionSiteContentItemTypeUtils";

type PageType = SelectionSiteContentSelectionSiteContentPage;
type Item = SelectionSiteContentSelectionSiteContentPageItemsInner;

class ContentItem<T extends Item = Item> {
  constructor(public readonly pageId: PageType["id"], public readonly value: T) {}

  is<Type extends keyof SelectionSiteItemTypeMap>(type: Type): this is ContentItem<SelectionSiteItemTypeMap[Type]> {
    return this.value.type === type;
  }
}

export default ContentItem;
