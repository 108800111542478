import { Button } from "components/styleguide";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface LinkToReportProps {
  parameters: Record<string, unknown>;
  disabled?: boolean;
}

const LinkToReport = ({ parameters, disabled = false }: LinkToReportProps) => {
  const { t } = useTranslation();

  return (
    <Link
      to={"report"}
      state={{
        parameters,
      }}
    >
      <Button disabled={disabled}>{t("Select")}</Button>
    </Link>
  );
};

export default LinkToReport;
