import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useSelectionSitesQuery } from "../../../../../../../api/queries/selectionSites";
import { AsyncSelect, Form, Select } from "../../../../../../../components/styleguide";
import { ForumAttendeeSelectionSiteStatus } from "../../../../../../../generated/api";

const SubmissionStatusMap: Record<ForumAttendeeSelectionSiteStatus, string> = {
  [ForumAttendeeSelectionSiteStatus.NotStarted]: "Not started",
  [ForumAttendeeSelectionSiteStatus.InProgress]: "In progress",
  [ForumAttendeeSelectionSiteStatus.Completed]: "Completed",
};

const SelectionSitesFilterForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const forumId = parseInt(params.id ?? "");

  const questionnairesQuery = useSelectionSitesQuery({ forumId });

  const optionsMapper = (response: NonNullable<typeof questionnairesQuery.data>["data"]) => {
    return response.map((selectionSite) => ({ value: selectionSite.id, label: selectionSite.name }));
  };

  return (
    <>
      <Form.Item name="id" label={t("Selection site")} rules={[{ required: true }]}>
        <AsyncSelect
          query={questionnairesQuery}
          optionsMapper={optionsMapper}
          placeholder={t("Select selection site")}
        />
      </Form.Item>
      <Form.Item name="statuses" label={t("Status")} rules={[{ required: true }]}>
        <Select placeholder={t("Select status")}>
          {Object.entries(SubmissionStatusMap).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {t(label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default SelectionSitesFilterForm;
