import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteForumVisualSpeakerDocumentMutation } from "api/mutations/visualSpeakerDocuments";
import KEYS from "api/queries/keys";
import { Modal, Typography } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface DeleteDocumentButtonProps {
  seminarId: string;
  documentId: number;
}

const DeleteDocumentButton: FC<DeleteDocumentButtonProps> = ({ seminarId, documentId }) => {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);

  const { mutate: deleteDocumentMutate } = useDeleteForumVisualSpeakerDocumentMutation();

  const deleteDocument = () => {
    deleteDocumentMutate(
      {
        forumId,
        seminarId,
        documentId,
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_FORUM_VISUAL_SPEAKER_DOCUMENTS]),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onDelete = () => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with the deletion of this document?")}</Typography.Text>,
      closable: true,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: () => deleteDocument(),
    });
  };

  return <DeleteFilled style={{ fontSize: "large" }} onClick={onDelete} />;
};

export default DeleteDocumentButton;
