import EnumBuilder from "./enumBuilderUtils";

const builder = new EnumBuilder();
const EnumClass = builder
  .add("RadioButtonOrCheckbox", "Radio button or Checkbox")
  .add("SingleLineTextbox", "Single line textbox")
  .add("LargePlainTextbox", "Large plain textbox")
  .add("LargeFormattedTextbox", "Large formatted textbox")
  .add("RadioButtonOrCheckboxWithOther", "Radio button or Checkbox with other")
  .build();

class QuestionOptionType extends EnumClass {
  get isRadioButtonOrCheckbox() {
    return this.value === "RadioButtonOrCheckbox";
  }

  get isSingleLineTextbox() {
    return this.value === "SingleLineTextbox";
  }

  get isLargePlainTextbox() {
    return this.value === "LargePlainTextbox";
  }

  get isLargeFormattedTextbox() {
    return this.value === "LargeFormattedTextbox";
  }

  get isRadioButtonOrCheckboxWithOther() {
    return this.value === "RadioButtonOrCheckboxWithOther";
  }
}

export default QuestionOptionType;
