import { useQuery } from "@tanstack/react-query";
import api from "api";

const tablesKey = "tables";

//Keeping as JS for now, for some reason the TS query doesn't have a type prop
const queryKeys = {
  tables: (venueId, buildingId, facilityId, type) => [tablesKey, venueId, buildingId, facilityId, type],
};
const useTablesQuery = ({ venueId, buildingId, facilityId, type }, options = {}) => {
  return useQuery(
    queryKeys.tables(venueId, buildingId, facilityId, type),
    () => api.get(`/venues/${venueId}/buildings/${buildingId}/facilities/${facilityId}/tables`, { params: { type } }),
    options,
  );
};

export { tablesKey, useTablesQuery };
