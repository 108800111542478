import React, { FC } from "react";

import { Col, Row } from "../../styleguide";
import Title from "../Title";

interface TableTitleProps {
  articleId: string;
  title: string;
  children: JSX.Element;
  className?: string;
}

const TableTitle: FC<TableTitleProps> = ({ articleId, title, children, className }) => {
  return (
    <Row {...{ className }}>
      <Col span={24}>
        <Title {...{ articleId, title }} />
      </Col>
      <Col span={24}>{children}</Col>
    </Row>
  );
};

export default TableTitle;
