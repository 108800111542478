/* eslint-disable @typescript-eslint/no-explicit-any */
import type { DatePickerProps } from "antd";
import { DatePicker as AntdDatePicker } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

export type CustomDatePickerProps = DatePickerProps & {
  onChange?: (value: any) => void;
};

const DatePicker = ({ value, format, style, onChange, ...props }: CustomDatePickerProps) => {
  const { t } = useTranslation();

  const handleChange = (newValue: any) => {
    onChange?.(newValue ? newValue.format() : newValue);
  };

  return (
    <AntdDatePicker
      value={value ? moment(value) : value}
      format={format ?? t("dateFormat")}
      onChange={handleChange}
      style={style}
      {...props}
    />
  );
};

const CustomRangePicker = ({ value, format, style, ...props }: RangePickerProps) => {
  const { t } = useTranslation();

  return (
    <AntdDatePicker.RangePicker
      value={value}
      format={format ?? t("dateFormat")}
      style={style}
      popupClassName={styles.picker}
      {...props}
    />
  );
};

DatePicker.RangePicker = CustomRangePicker;

export default DatePicker;
