import { TemplateApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(TemplateApi);

const Template = {
  get: api.templatesGet,
};

export default Template;
