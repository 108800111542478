import { useRef, useState } from "react";

import CustomSet from "./CustomSet";

const useSet = <T>(defaultValue?: Array<T>) => {
  const setRef = useRef(new CustomSet(defaultValue));
  const [list, setList] = useState(defaultValue);

  const set = (array: Array<T>) => {
    setRef.current.reset(array);
    setList(array);
  };

  const remove = (key: T) => {
    setRef.current.delete(key);
    setList(setRef.current.values);
  };

  const add = (key: T) => {
    setRef.current.add(key);
    setList(setRef.current.values);
  };

  const has = (key: T): boolean => {
    return setRef.current.has(key);
  };

  return {
    value: list,
    set,
    remove,
    add,
    has,
  };
};

export default useSet;
