import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteDocumentMutation, useUploadDocumentMutation } from "api/mutations/groupsDocuments";
import { useGroupDocumentsQuery } from "api/queries/groupsDocuments";
import KEYS from "api/queries/keys";
import DateRenderer from "components/DateRenderer";
import { Modal, Space, Table, Typography } from "components/styleguide";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { EventLayoutContext } from "layouts/EventLayout/types";
import { toNumber } from "lodash";
import { Moment } from "moment";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { TableColumn } from "utils/tableColumnUtils";

import DocumentUpload from "./components/Upload";

import styles from "./styles.module.less";

const { confirm } = Modal;

const getDateRenderer = () => {
  return {
    render: (value: string) => {
      if (!value) {
        return "No date was provided";
      }

      return <DateRenderer date={value} />;
    },
  };
};

const GroupDocuments = ({ forumId, groupId }: { forumId: number; groupId: number }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { forum } = useOutletContext<EventLayoutContext>();

  const [isUploadDrawerShown, setIsUploadDrawerShown] = useState(false);

  const { response: query, handleSort } = useModernQueryWithPaginationAndOrder(
    useGroupDocumentsQuery,
    {},
    {
      forumId,
      groupId,
    },
  );

  const mutation = useUploadDocumentMutation();
  const deleteMutation = useDeleteDocumentMutation();

  const handleFinishUploadForm = (values: {
    file: File;
    title: string;
    text: string;
    publishFrom: Moment;
    publishUntil: Moment;
  }) => {
    mutation.mutate(
      {
        forumId: toNumber(forumId),
        groupId: toNumber(groupId),
        documentName: values.title,
        text: values.text,
        publishFrom: values.publishFrom.toISOString(),
        publishUntil: values.publishUntil.toISOString(),
        formFile: values.file,
      },
      {
        onSuccess: () => {
          setIsUploadDrawerShown(false);
          query.refetch();
        },
      },
    );
  };

  const handleDelete = useCallback(
    (documentId: number) => {
      const popupMessage = t("Do you want to proceed with the deletion of this document?");

      confirm({
        title: t("confirmation"),
        content: <Typography.Text>{popupMessage}</Typography.Text>,
        closable: true,
        okText: t("yes"),
        okType: "danger",
        cancelText: t("no"),
        onOk: () => {
          return deleteMutation.mutate(
            { forumId: Number(forumId), groupId, documentId },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([KEYS.GET_DOCUMENTS_KEY]);
                toast.success(t("Document has been deleted successfully"));
              },
              onError: () => {
                toast.error(t("Something went wrong"));
              },
            },
          );
        },
      });
    },
    [forumId, t, queryClient, deleteMutation, groupId],
  );

  const columns = [
    new TableColumn(t("Title"), "fileName", { sorter: true }),
    new TableColumn(t("Text"), "text", { sorter: true }),
    new TableColumn(t("Publish from"), "publishFrom", { ...getDateRenderer(), sorter: true }),
    new TableColumn(t("Publish until"), "publishUntil", { ...getDateRenderer(), sorter: true }),
    new TableColumn("", "id", {
      width: "20px",
      render: (id: number) => <DeleteFilled className={styles.deleteIcon} onClick={() => handleDelete(id)} />,
    }),
  ];

  return (
    <div>
      <Space direction="vertical" size="middle" fullWidth>
        <DocumentUpload
          className="section-button"
          visible={isUploadDrawerShown}
          isLoading={mutation.isLoading}
          onVisibleChange={setIsUploadDrawerShown}
          onSuccess={handleFinishUploadForm}
          forum={forum}
        />
        <Table
          dataSource={query.data?.data ?? []}
          columns={columns}
          rowKey="id"
          pagination={false}
          onChange={handleSort}
          loading={query.isLoading}
        />
      </Space>
    </div>
  );
};

GroupDocuments.propTypes = {
  forumId: PropTypes.number.isRequired,
  groupId: PropTypes.number.isRequired,
};

export default GroupDocuments;
