import { RoomGradeApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(RoomGradeApi);

const RoomGrade = {
  get: api.venuesVenueIdBuildingsBuildingIdRoomgradesGet,
  post: api.venuesVenueIdBuildingsBuildingIdRoomgradesPost,
  put: api.venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut,
  delete: api.venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete,
};

export default RoomGrade;
