import clsx from "clsx";
import React, { FC, PropsWithChildren } from "react";

import typesafeStyles from "../../../../utils/typesafeStyles";

import styles from "./styles.module.less";

type HTMLDivElementProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type CSSVariables = {
  "--gap": number;
};

type HorizontalAlign = "left" | "center" | "right";
type VerticalAlign = "top" | "center" | "bottom";

const getStyles = typesafeStyles<CSSVariables>;

const getHorizontalClass = (horizontalAlign: HorizontalAlign): string | undefined => {
  switch (horizontalAlign) {
    case "center":
      return styles.horizontalMiddle;
    case "right":
      return styles.horizontalRight;
    default:
      return;
  }
};

const getVerticalClass = (verticalAlign: VerticalAlign): string | undefined => {
  switch (verticalAlign) {
    case "center":
      return styles.verticalMiddle;
    case "bottom":
      return styles.verticalBottom;
    default:
      return;
  }
};

interface ActionsProps {
  className?: string;
  gap?: number;
  horizontalAlign?: HorizontalAlign;
  verticalAlign?: VerticalAlign;
}

/*
 * Basically that component can be used to wrap buttons and other actions
 * into one component. It's useful to not write same styles over and over again
 * */
const Actions: FC<PropsWithChildren<ActionsProps & HTMLDivElementProps>> = ({
  className,
  children,
  gap = 10,
  horizontalAlign = "left",
  verticalAlign = "top",
  style,
  ...props
}) => {
  const horizontal = getHorizontalClass(horizontalAlign);
  const vertical = getVerticalClass(verticalAlign);

  const classes = clsx(styles.container, horizontal, vertical, className);

  return (
    <div className={classes} style={getStyles({ "--gap": gap, ...style })} {...props}>
      {children}
    </div>
  );
};

export default Actions;
