import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useCreateStreamMutation = getWrappedUseMutation(apiClient.ForumStreams.post);

export const useUpdateStreamMutation = getWrappedUseMutation(apiClient.ForumStreams.put);

export const useDeleteStreamMutation = getWrappedUseMutation(apiClient.ForumStreams.delete);

export const useReorderStreamsMutation = getWrappedUseMutation(apiClient.ForumStreams.reoder.put);
