import { t } from "i18next";
import { FC, useState } from "react";

import { Typography } from "../styleguide";

export interface EllipsisTextProps {
  text: string;
  maxLength: number;
  suffix?: string;
  expandText?: string;
  collapseText?: string;
}

const EllipsisText: FC<EllipsisTextProps> = ({
  text,
  maxLength,
  suffix = "...",
  expandText = `${suffix}${t("(expand)")}`,
  collapseText = t("(collapse)"),
}) => {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  if (text.length <= maxLength) {
    return <>{text}</>;
  }

  if (isExpanded) {
    return (
      <>
        <span>{text}</span>
        <Typography.Text onClick={() => setExpanded(false)} style={{ wordBreak: "keep-all" }}>
          <Typography.Link> {collapseText}</Typography.Link>
        </Typography.Text>
      </>
    );
  }

  return (
    <>
      <span>{text.substring(0, maxLength)}</span>
      <Typography.Text onClick={() => setExpanded(true)}>
        <Typography.Link style={{ wordBreak: "keep-all" }}>{expandText}</Typography.Link>
      </Typography.Text>
    </>
  );
};

export default EllipsisText;
