import { useMutation } from "@tanstack/react-query";
import api from "api";
import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

const useCreateForumCompaniesMutation = getWrappedUseMutation(apiClient.ForumCompanies.post);

const useCreateCompanyMutation = getWrappedUseMutation(apiClient.Companies.post);

const useUpdateCompanyMutation = (options = {}) => {
  const mutation = useMutation(async ({ companyId, company }) => api.put(`companies/${companyId}`, company), options);
  return mutation;
};

const useUpdateCompanySocialLinksMutation = (options = {}) => {
  const mutation = useMutation(
    async ({ companyId, company }) => api.put(`companies/${companyId}/social-links`, company),
    options,
  );
  return mutation;
};

const useUpdateCompanyPackageMutation = getWrappedUseMutation(apiClient.ForumCompanies.package.put);
const useUpdateCompanyDescriptionMutation = getWrappedUseMutation(apiClient.ForumCompanies.description.put);

const useUpdateCompanyPrimaryLocationMutation = getWrappedUseMutation(apiClient.ForumCompanies.primaryLocations.put);

export {
  useCreateCompanyMutation,
  useCreateForumCompaniesMutation,
  useUpdateCompanyDescriptionMutation,
  useUpdateCompanyMutation,
  useUpdateCompanyPackageMutation,
  useUpdateCompanyPrimaryLocationMutation,
  useUpdateCompanySocialLinksMutation,
};
