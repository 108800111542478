import { SettingFilled } from "@ant-design/icons";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Drawer } from "../../../../../../../../../components/styleguide";
import { EventOptionsChoice } from "../../../../../../../../../generated/api";
import ChoiceSettingsForm from "../ChoiceSettingsForm";

interface EditChoiceProps {
  value?: EventOptionsChoice;
  onChange?: (value: EventOptionsChoice) => void;
}

const EditChoice: FC<EditChoiceProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const showDrawer = () => setIsVisible(true);
  const hideDrawer = () => setIsVisible(false);

  const handleFormSubmit = (choice: EventOptionsChoice) => {
    onChange?.(choice);
  };

  return (
    <>
      <Button icon={<SettingFilled />} type="text" onClick={showDrawer} />
      <Drawer
        open={isVisible}
        placement="right"
        title={t("Choice Details")}
        width="100%"
        destroyOnClose
        onClose={hideDrawer}
      >
        <ChoiceSettingsForm initialValues={value} submitFormOnChangeEvent onFinish={handleFormSubmit} />
      </Drawer>
    </>
  );
};

export default EditChoice;
