import { useMutation, UseMutationOptions } from "@tanstack/react-query";

/**
 * # Description
 *
 * Basically this wrapper wraps our function to have correct types in `options`
 * and to not copy-paste code over and over again
 *
 * # Example of usage
 *
 * ## mutations folder
 * ```ts
 * export const useSomeMutation = getWrappedUseMutation(apiClient.someMutation.post);
 * ```
 *
 * ## in your react component
 * ```tsx
 * const mutation = useSomeMutation(options);
 * mutation.mutate({ ... });
 * ```
 */
const getWrappedUseMutation = <Error, Data, Params, Context>(queryFn: (params: Params) => Promise<Data>) => {
  return (options?: UseMutationOptions<Data, Error, Params, Context>) => {
    return useMutation(queryFn, options);
  };
};

export default getWrappedUseMutation;
