import { Space } from "antd";
import { useCreateSelectionSiteMutation } from "api/mutations/selectionSites";
import { useSelectionSitesQuery } from "api/queries/selectionSites";
import { Button } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import SelectionSitesTable from "./List/SelectionSitesTable";
import SelectionSitesDrawer from "./SelectionSitesDrawer";

const SelectionSitesPage = () => {
  const { t } = useTranslation();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const { id: forumId } = useOutletContext();

  const createSelectionSiteMutation = useCreateSelectionSiteMutation();

  const {
    response: { data, refetch: refetchAllSites, isLoading },
    handleSort,
  } = useModernQueryWithPaginationAndOrder(useSelectionSitesQuery, {}, { forumId });

  const onDrawerClose = () => {
    if (createSelectionSiteMutation.isLoading) {
      return;
    }

    setIsDrawerVisible(false);
    setErrorMsg(null);
  };

  const onMigrationFinish = () => {
    refetchAllSites();
    setIsDrawerVisible(false);
    setErrorMsg(null);
  };

  const onSubmit = ({ available, ...values }) => {
    const [availableFrom, availableTo] = available;
    const formData = {
      ...values,
      availableFrom: availableFrom.format("YYYY-MM-DD"), // to use the date only, without the local time, which may send a wrong date
      availableTo: availableTo.format("YYYY-MM-DD"),
    };

    createSelectionSiteMutation.mutate(
      { forumId, ...formData },
      {
        onSuccess: () => {
          onMigrationFinish();
        },
        onError: ({ response }) => {
          setErrorMsg(response.data?.Message);
        },
      },
    );
  };

  return (
    <div>
      <UserGuide.Title title="Selection Sites" articleId="16439337552029-selection-sites" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button onClick={() => setIsDrawerVisible(true)}>{t("addSite")}</Button>
        <SelectionSitesTable
          items={data?.data ?? []}
          handleSort={handleSort}
          isLoading={isLoading}
          setIsDrawerVisible={setIsDrawerVisible}
          forumId={forumId}
        />
        <SelectionSitesDrawer
          onDrawerClose={onDrawerClose}
          isDrawerVisible={isDrawerVisible}
          onSubmit={onSubmit}
          isMutationLoading={createSelectionSiteMutation?.isLoading}
          errorMsg={errorMsg}
        />
      </Space>
    </div>
  );
};

export default SelectionSitesPage;
