import { DeleteFilled } from "@ant-design/icons";
import { Modal, Typography } from "components/styleguide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface DeleteFeedbackButtonProps {
  onDelete: () => void;
}

const DeleteFeedbackButton: FC<DeleteFeedbackButtonProps> = ({ onDelete }) => {
  const { t } = useTranslation();

  const showDeletionConfirmation = () => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with deletion of this feedback?")}</Typography.Text>,
      closable: true,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: onDelete,
    });
  };

  return <DeleteFilled style={{ fontSize: "large" }} onClick={showDeletionConfirmation} />;
};

export default DeleteFeedbackButton;
