import { Select } from "components/styleguide";
import { CompanyModel, CountryModel } from "generated/api";
import React, { ReactNode } from "react";

const { Option } = Select;

interface FormFieldType {
  title: string;
  dataIndex: string;
  maxLength?: number;
  component?: ReactNode;
  initialValue?: string;
}

const getFormFields = (company: CompanyModel, countries: CountryModel[]): FormFieldType[] => [
  { title: "Address", dataIndex: "addressLine1", maxLength: 50 },
  { title: "Company Phone", dataIndex: "phone", maxLength: 30 },
  { title: "Address line 2", dataIndex: "addressLine2", maxLength: 50 },
  { title: "City", dataIndex: "city", maxLength: 30 },
  { title: "Email", dataIndex: "email", maxLength: 100 },
  { title: "County", dataIndex: "county", maxLength: 30 },
  { title: "Company Sector", dataIndex: "sector", maxLength: 60 },
  { title: "Postcode", dataIndex: "postcode", maxLength: 10 },
  { title: "Company Turnover", dataIndex: "turnover", maxLength: 15 },
  {
    title: "Country",
    dataIndex: "country",
    component: (
      <Select>
        {countries.map((c: CountryModel) => (
          <Option value={c.name} key={c.isoCode}>
            {c.name}
          </Option>
        ))}
      </Select>
    ),
  },
  { title: "Company Budget", dataIndex: "budget", maxLength: 15 },
];

export { getFormFields };
