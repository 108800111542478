import { useQueryClient } from "@tanstack/react-query";
import { ColProps } from "antd";
import { useUpdateZoomMappingsSessionMappingsMutation } from "api/mutations/zoomMappings";
import KEYS from "api/queries/keys";
import { useSeminarsWithSessionsQuery } from "api/queries/seminars";
import { useGetZoomMappingsWebinarsQuery } from "api/queries/zoomMappings";
import { Button, Col, Form, Row, Select } from "components/styleguide";
import { ForumSeminarSessionModel } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface AddMappingModel {
  forumSeminarSessionId: number;
  zoomWebinarId: string;
}

const AddMapping: FC = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const { mutate: addMapping, isLoading: isMappingAdding } = useUpdateZoomMappingsSessionMappingsMutation();

  const { data: { data: webinars = [] } = {}, isLoading: isWebinarsLoading } = useGetZoomMappingsWebinarsQuery({
    forumId,
  });

  const { data: { data: seminars = [] } = {}, isLoading: isSeminarsLoading } = useSeminarsWithSessionsQuery({
    forumId,
    excludeVirtualAndNotMappedSessions: true,
  });

  const getFormattedDate = (date: string | undefined) => moment(date).format("L LT");

  const getWebinarsOptions = () => {
    return webinars.map(({ id, topic, startTime }) => ({
      value: id,
      label: `${topic} - ${getFormattedDate(startTime)}`,
    }));
  };

  const getSeminarSessionsOptions = () => {
    return seminars
      .filter(({ seminarSessions }) => !!seminarSessions)
      .map(({ seminarCode, seminarName, seminarSessions }) => {
        return (seminarSessions as Array<ForumSeminarSessionModel>).map(({ sessionId, startTime }) => ({
          value: sessionId,
          label: `${seminarCode} - ${seminarName} - ${getFormattedDate(startTime)}`,
        }));
      })
      .flat(1);
  };

  const onAddMapping = ({ forumSeminarSessionId, zoomWebinarId }: AddMappingModel) => {
    addMapping(
      {
        forumId,
        forumSeminarSessionId,
        forumSeminarSessionZoomWebinarRequest: { zoomWebinarId },
      },
      {
        onSuccess: () => {
          form.resetFields();
          queryClient.invalidateQueries([KEYS.GET_ZOOM_MAPPINGS_SESSION_MAPPINGS]);
          queryClient.invalidateQueries([KEYS.GET_FORUM_SEMINARS_WITH_SESSIONS]);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: ({ response }: any) => {
          if (response?.data?.type === "TimeConflict") {
            toast.error(
              "The chosen seminar session overlaps with the session already allocated to the selected webinar.",
            );
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  return (
    <Form
      form={form}
      labelAlign="left"
      labelCol={{ justify: "left", span: 6 } as ColProps}
      wrapperCol={{ justify: "right", span: 18 } as ColProps}
      onFinish={onAddMapping}
    >
      <Form.Item
        label={t("Available Webinars")}
        name={"zoomWebinarId"}
        rules={[{ required: true, message: t("errors.required", { prop: "$t(Available Webinar)" }) }]}
      >
        <Select
          allowClear
          placeholder={t("Please select a webinar")}
          options={getWebinarsOptions()}
          loading={isWebinarsLoading}
        />
      </Form.Item>
      <Form.Item
        label={t("Available Sessions")}
        name={"forumSeminarSessionId"}
        rules={[{ required: true, message: t("errors.required", { prop: "$t(Available Session)" }) }]}
      >
        <Select
          allowClear
          placeholder={t("Please select a session")}
          options={getSeminarSessionsOptions()}
          loading={isSeminarsLoading}
        />
      </Form.Item>
      <Row justify="end">
        <Col>
          <Button
            style={{ width: "150px" }}
            htmlType="submit"
            type="primary"
            loading={isMappingAdding}
            disabled={isMappingAdding}
          >
            {t("Add")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddMapping;
