import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useForumStreamsQuery } from "../../../../../../../../../../api/queries/forumStreams";
import { Button, Form, Select, Space, Typography } from "../../../../../../../../../../components/styleguide";

import styles from "./styles.module.less";

type FormFields = { streamId?: number };

interface AddProps {
  forumId: number;
  exclude?: Array<number>;
  onAdd?: (id: number, type: string) => void;
}

const Add: FC<AddProps> = ({ forumId, exclude, onAdd }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormFields>();

  const query = useForumStreamsQuery({ forumId });

  const options = useMemo(() => {
    if (!query.data) {
      return [];
    }

    const excludeSet = new Set(exclude);

    return query.data.data.reduce<Array<{ value: number; label: string }>>((accumulator, stream) => {
      if (!stream.id || !stream.type) {
        return accumulator;
      }

      if (excludeSet.has(stream.id)) {
        return accumulator;
      }

      const option = {
        value: stream.id,
        label: stream.type,
      };
      return [...accumulator, option];
    }, []);
  }, [exclude, query.data]);

  const handleFormSubmit = ({ streamId }: FormFields) => {
    form.resetFields();

    if (!streamId) {
      return;
    }

    const stream = query.data?.data.find((s) => s.id === streamId);
    if (!stream || !stream.id || !stream.type) {
      return;
    }

    onAdd?.(stream.id, stream.type);
  };

  return (
    <Form form={form} onFinish={handleFormSubmit}>
      <Space align="start">
        <Typography.Text className={styles.label}>{t("Select stream")}</Typography.Text>

        <Form.Item className={styles.select} name="streamId">
          <Select loading={query.isLoading} placeholder={t("Select stream from list")}>
            {options.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Button htmlType="submit" type="primary">
          {t("Add")}
        </Button>
      </Space>
    </Form>
  );
};

export default Add;
