import React from 'react';

import './colors.less';

const MainTheme = (props) => {

  return props.children;
};

export default MainTheme;
