/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeItineraryItemResponseModel } from '../apiModels';
// @ts-ignore
import { AttendeeItineraryModel } from '../apiModels';
// @ts-ignore
import { MeetingFacilityModel } from '../apiModels';
// @ts-ignore
import { TimeSlotSessionListItemModel } from '../apiModels';
// @ts-ignore
import { TimeSlotType } from '../apiModels';
/**
 * ForumItineraryApi - axios parameter creator
 * @export
 */
export const ForumItineraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet: async (forumId: number, forumMeetingFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet', 'forumId', forumId)
            // verify required parameter 'forumMeetingFacilityId' is not null or undefined
            assertParamExists('forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet', 'forumMeetingFacilityId', forumMeetingFacilityId)
            const localVarPath = `/forum/{forumId}/itinerary/conferences/facilities/{forumMeetingFacilityId}/timeslot-sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumMeetingFacilityId"}}`, encodeURIComponent(String(forumMeetingFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {number} timeSlotSessionId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet: async (forumId: number, forumMeetingFacilityId: number, timeSlotSessionId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet', 'forumId', forumId)
            // verify required parameter 'forumMeetingFacilityId' is not null or undefined
            assertParamExists('forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet', 'forumMeetingFacilityId', forumMeetingFacilityId)
            // verify required parameter 'timeSlotSessionId' is not null or undefined
            assertParamExists('forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet', 'timeSlotSessionId', timeSlotSessionId)
            const localVarPath = `/forum/{forumId}/itinerary/conferences/facilities/{forumMeetingFacilityId}/timeslot-sessions/{timeSlotSessionId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumMeetingFacilityId"}}`, encodeURIComponent(String(forumMeetingFacilityId)))
                .replace(`{${"timeSlotSessionId"}}`, encodeURIComponent(String(timeSlotSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryConferencesFacilitiesGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryConferencesFacilitiesGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/itinerary/conferences/facilities`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {boolean} [manualSched] 
         * @param {Array<TimeSlotType>} [timeSlotTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryGet: async (forumId: number, manualSched?: boolean, timeSlotTypes?: Array<TimeSlotType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/itinerary`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (manualSched !== undefined) {
                localVarQueryParameter['ManualSched'] = manualSched;
            }

            if (timeSlotTypes) {
                localVarQueryParameter['TimeSlotTypes'] = timeSlotTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {Array<number>} itineraryItemIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsExportGet: async (forumId: number, itineraryItemIds: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsExportGet', 'forumId', forumId)
            // verify required parameter 'itineraryItemIds' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsExportGet', 'itineraryItemIds', itineraryItemIds)
            const localVarPath = `/forum/{forumId}/itinerary/meetings/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (itineraryItemIds) {
                localVarQueryParameter['ItineraryItemIds'] = itineraryItemIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet: async (forumId: number, forumMeetingFacilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet', 'forumId', forumId)
            // verify required parameter 'forumMeetingFacilityId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet', 'forumMeetingFacilityId', forumMeetingFacilityId)
            const localVarPath = `/forum/{forumId}/itinerary/meetings/facilities/{forumMeetingFacilityId}/timeslot-sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumMeetingFacilityId"}}`, encodeURIComponent(String(forumMeetingFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {number} timeSlotSessionId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet: async (forumId: number, forumMeetingFacilityId: number, timeSlotSessionId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet', 'forumId', forumId)
            // verify required parameter 'forumMeetingFacilityId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet', 'forumMeetingFacilityId', forumMeetingFacilityId)
            // verify required parameter 'timeSlotSessionId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet', 'timeSlotSessionId', timeSlotSessionId)
            const localVarPath = `/forum/{forumId}/itinerary/meetings/facilities/{forumMeetingFacilityId}/timeslot-sessions/{timeSlotSessionId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumMeetingFacilityId"}}`, encodeURIComponent(String(forumMeetingFacilityId)))
                .replace(`{${"timeSlotSessionId"}}`, encodeURIComponent(String(timeSlotSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsFacilitiesGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdItineraryMeetingsFacilitiesGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/itinerary/meetings/facilities`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumItineraryApi - functional programming interface
 * @export
 */
export const ForumItineraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumItineraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(forumId: number, forumMeetingFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotSessionListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(forumId, forumMeetingFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {number} timeSlotSessionId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(forumId: number, forumMeetingFacilityId: number, timeSlotSessionId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeItineraryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(forumId, forumMeetingFacilityId, timeSlotSessionId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryConferencesFacilitiesGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingFacilityModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryConferencesFacilitiesGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {boolean} [manualSched] 
         * @param {Array<TimeSlotType>} [timeSlotTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryGet(forumId: number, manualSched?: boolean, timeSlotTypes?: Array<TimeSlotType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeItineraryItemResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryGet(forumId, manualSched, timeSlotTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {Array<number>} itineraryItemIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryMeetingsExportGet(forumId: number, itineraryItemIds: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryMeetingsExportGet(forumId, itineraryItemIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(forumId: number, forumMeetingFacilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotSessionListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(forumId, forumMeetingFacilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumMeetingFacilityId 
         * @param {number} timeSlotSessionId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(forumId: number, forumMeetingFacilityId: number, timeSlotSessionId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeItineraryModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(forumId, forumMeetingFacilityId, timeSlotSessionId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdItineraryMeetingsFacilitiesGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingFacilityModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdItineraryMeetingsFacilitiesGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumItineraryApi - factory interface
 * @export
 */
export const ForumItineraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumItineraryApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters: ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TimeSlotSessionListItemModel>> {
            return localVarFp.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters: ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeItineraryModel>> {
            return localVarFp.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, requestParameters.timeSlotSessionId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryConferencesFacilitiesGet(requestParameters: ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MeetingFacilityModel>> {
            return localVarFp.forumForumIdItineraryConferencesFacilitiesGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryGet(requestParameters: ForumItineraryApiForumForumIdItineraryGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeItineraryItemResponseModel>> {
            return localVarFp.forumForumIdItineraryGet(requestParameters.forumId, requestParameters.manualSched, requestParameters.timeSlotTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryMeetingsExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsExportGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdItineraryMeetingsExportGet(requestParameters.forumId, requestParameters.itineraryItemIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TimeSlotSessionListItemModel>> {
            return localVarFp.forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeItineraryModel>> {
            return localVarFp.forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, requestParameters.timeSlotSessionId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdItineraryMeetingsFacilitiesGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<MeetingFacilityModel>> {
            return localVarFp.forumForumIdItineraryMeetingsFacilitiesGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet
     */
    readonly forumMeetingFacilityId: number
}

/**
 * Request parameters for forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly forumMeetingFacilityId: number

    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly timeSlotSessionId: number

    /**
     * 
     * @type {string}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdItineraryConferencesFacilitiesGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumForumIdItineraryGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryGet
     */
    readonly forumId: number

    /**
     * 
     * @type {boolean}
     * @memberof ForumItineraryApiForumForumIdItineraryGet
     */
    readonly manualSched?: boolean

    /**
     * 
     * @type {Array<TimeSlotType>}
     * @memberof ForumItineraryApiForumForumIdItineraryGet
     */
    readonly timeSlotTypes?: Array<TimeSlotType>
}

/**
 * Request parameters for forumForumIdItineraryMeetingsExportGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryMeetingsExportGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryMeetingsExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsExportGet
     */
    readonly itineraryItemIds: Array<number>
}

/**
 * Request parameters for forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet
     */
    readonly forumMeetingFacilityId: number
}

/**
 * Request parameters for forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly forumMeetingFacilityId: number

    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly timeSlotSessionId: number

    /**
     * 
     * @type {string}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdItineraryMeetingsFacilitiesGet operation in ForumItineraryApi.
 * @export
 * @interface ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGetRequest
 */
export interface ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGet
     */
    readonly forumId: number
}

/**
 * ForumItineraryApi - object-oriented interface
 * @export
 * @class ForumItineraryApi
 * @extends {BaseAPI}
 */
export class ForumItineraryApi extends BaseAPI {
    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters: ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters: ForumItineraryApiForumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, requestParameters.timeSlotSessionId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryConferencesFacilitiesGet(requestParameters: ForumItineraryApiForumForumIdItineraryConferencesFacilitiesGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryConferencesFacilitiesGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryGet(requestParameters: ForumItineraryApiForumForumIdItineraryGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryGet(requestParameters.forumId, requestParameters.manualSched, requestParameters.timeSlotTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryMeetingsExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryMeetingsExportGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsExportGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryMeetingsExportGet(requestParameters.forumId, requestParameters.itineraryItemIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet(requestParameters.forumId, requestParameters.forumMeetingFacilityId, requestParameters.timeSlotSessionId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumItineraryApi
     */
    public forumForumIdItineraryMeetingsFacilitiesGet(requestParameters: ForumItineraryApiForumForumIdItineraryMeetingsFacilitiesGetRequest, options?: AxiosRequestConfig) {
        return ForumItineraryApiFp(this.configuration).forumForumIdItineraryMeetingsFacilitiesGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }
}

