import collectReferences from "./collect-references";
import ContentItem from "./content-item";

const getDependencies = (dependenciesToCheck: Set<ContentItem>, item: ContentItem): Set<ContentItem> => {
  const references = collectReferences(item);

  const result = new Set<ContentItem>();

  dependenciesToCheck.forEach((entry) => {
    if (references.pages.has(entry.pageId) || references.items.has(entry.value.id)) {
      result.add(entry);
    }
  });

  return result;
};

export default getDependencies;
