import React from "react";
import { Outlet } from "react-router-dom";

import { Layout } from "../../../components/styleguide";

import styles from "./styles.module.less";

export default function ItineraryLayout() {
  return (
    <div className={styles.container}>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </div>
  );
}
