import { Button, Collapse } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { VenueDetailsModel } from "generated/api";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import VenueDetails from "./VenueDetails";
import VenueDetailsForm from "./VenueDetailsForm";

const activeKeys = ["venue"];

interface VenueDetailsPanelProps {
  venue: VenueDetailsModel;
  isVenueDetailsEditing: boolean;
  setIsVenueDetailsEditing: (value: boolean) => void;
  onVenueEditSubmit: (venue: VenueDetailsModel) => void;
  isUpdateLoading: boolean;
  isForumVenue?: boolean;
}

const VenueDetailsPanel: FC<VenueDetailsPanelProps> = ({
  venue,
  isVenueDetailsEditing,
  setIsVenueDetailsEditing,
  onVenueEditSubmit,
  isUpdateLoading,
  isForumVenue,
}) => {
  const { t } = useTranslation();

  const getEditButton = useCallback(
    (setEditState: (value: boolean) => void) => {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setEditState(true);
          }}
        >
          {t("Edit")}
        </Button>
      );
    },
    [t],
  );

  return (
    <div>
      <Collapse className="collapse-parent" bordered={false} defaultActiveKey={activeKeys}>
        <UserGuide.Panel
          id="venueDetailsContent"
          title="venueDetails"
          articleId={isForumVenue ? "16423936135709-venue" : "16563820628893-venue-details"}
          key="venue"
          extra={getEditButton(setIsVenueDetailsEditing)}
        >
          {isVenueDetailsEditing ? (
            <VenueDetailsForm
              initialValues={venue}
              setIsEditing={setIsVenueDetailsEditing}
              onSubmit={onVenueEditSubmit}
              isUpdateLoading={isUpdateLoading}
            />
          ) : (
            <VenueDetails venue={venue} />
          )}
        </UserGuide.Panel>
      </Collapse>
    </div>
  );
};

export default VenueDetailsPanel;
