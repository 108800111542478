import { useQuestionnaireQuery } from "api/queries/questionnaires";
import { Col, Layout, Row, Spin } from "components/styleguide";
import { QuestionnaireStatus } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fillQuestionsWithIds } from "utils/questionnaireContentUtils";

import QuestionnaireContentRenderer from "./components/Content";
import QuestionnaireActions from "./components/QuestionnaireActions";
import QuestionnaireEditSider from "./components/Sider";
import useQuestionnaire from "./hooks/useQuestionnaire";

import styles from "./styles.module.less";

const SIDER_WIDTH = 300;

const QuestionnaireEditPage = () => {
  const { t } = useTranslation();

  const { id: forumId } = useOutletContext();
  const { questionnaireId: rawQuestionnaireId } = useParams();
  const questionnaireId = parseInt(rawQuestionnaireId);
  const location = useLocation();
  const navigate = useNavigate();

  const { pages, isLoading, mutate } = useQuestionnaire(forumId, questionnaireId);

  const { data: { data: questionnaire = {} } = {} } = useQuestionnaireQuery(
    { forumId, questionnaireId },
    {
      onSuccess: ({ data }) => {
        if (!location.state || !location.state.questionnaireName) {
          navigate(".", {
            replace: true,
            hash: location.hash || "",
            state: { questionnaireName: data.name },
          });
        }
      },
    },
  );

  const handleChange = (newPages) => {
    mutate(fillQuestionsWithIds(newPages), {
      onError: () => {
        toast.error(t("An error occurred on updating questionnaire"));
      },
    });
  };

  const handleImportClick = () => {
    if (questionnaire.status !== QuestionnaireStatus.Open) {
      navigate("import", {
        state: { ...location.state, questionnaireName: location.state?.questionnaireName },
      });
    }
  };

  if (isLoading) {
    return (
      <Layout className={styles.loadingContainer}>
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <Layout hasSider>
      <QuestionnaireEditSider
        className={styles.sider}
        isActionsDisabled={questionnaire.status === QuestionnaireStatus.Open}
        pages={pages}
        width={SIDER_WIDTH}
        onChange={handleChange}
      />
      <Layout.Content className={styles.content} style={{ "--sider-width": `${SIDER_WIDTH}px` }}>
        <Row gutter={16}>
          <Col
            xs={{ span: 24, order: 0 }}
            sm={{ span: 24, order: 0 }}
            xl={{ span: 10, order: 1 }}
            xxl={{ span: 6, order: 1 }}
          >
            <QuestionnaireActions
              isActive={questionnaire.status !== QuestionnaireStatus.Open}
              onImportClick={handleImportClick}
            />
          </Col>
          <Col xs={24} sm={24} md={24} xl={14} xxl={18}>
            <QuestionnaireContentRenderer
              forumId={forumId}
              questionnaireId={questionnaireId}
              isEditable={questionnaire.status !== QuestionnaireStatus.Open}
              pages={pages}
              onChange={handleChange}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default QuestionnaireEditPage;
