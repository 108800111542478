import { useGetZoomMappingsSessionMappingsQuery } from "api/queries/zoomMappings";
import { Table } from "components/styleguide";
import { toNumber } from "lodash";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import DeleteMappingButton from "./DeleteMappingButton";

const MappingsTable: FC = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const { data: { data: mappings = [] } = {}, isLoading: isMappingsLoading } = useGetZoomMappingsSessionMappingsQuery({
    forumId,
  });

  const columns = [
    {
      title: t("Webinar ID"),
      dataIndex: "zoomWebinarId",
    },
    {
      title: t("Session Code"),
      dataIndex: "forumSeminarSessionId",
    },
    {
      title: t("Seminar Display Code"),
      dataIndex: "seminarDisplayCode",
    },
    {
      title: t("Seminar Title"),
      dataIndex: "seminarTitle",
    },
    {
      title: t("Start Time"),
      dataIndex: "startTime",
      render: (startTime: string) => moment(startTime).format("L LTS"),
    },
    {
      title: "",
      dataIndex: "forumSeminarSessionId",
      render: (sessionId: number) => <DeleteMappingButton {...{ sessionId }} />,
    },
  ];

  return <Table bordered columns={columns} dataSource={mappings} loading={isMappingsLoading} />;
};

export default MappingsTable;
