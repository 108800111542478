import { BaseOptionType } from "antd/lib/select";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { Layout, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ManageAttendeeDesciptions from "./Attendees";
import ManageCompanyDescriptions from "./Companies";

import styles from "./styles.module.less";

const { Content } = Layout;
export default function ManageDescriptions() {
  const { t } = useTranslation();
  const [participationCodes, setParticipationCodes] = useState<BaseOptionType[]>();

  const { isLoading: isLoadingCodes } = useParticipationCodesQuery(
    {
      ignorePagination: true,
    },
    {
      onSuccess: ({ data: { items = [] } = {} }) => {
        const codes = items.map(({ code }) => ({ value: code, label: code }));
        setParticipationCodes(codes);
      },
    },
  );

  const tabs = [
    {
      key: "attendees",
      label: t("Attendees"),
      children: <ManageAttendeeDesciptions participationCodes={participationCodes} isLoadingCodes={isLoadingCodes} />,
    },
    {
      key: "companies",
      label: t("Companies"),
      children: <ManageCompanyDescriptions />,
    },
  ];

  return (
    <Layout className={styles.layout}>
      <UserGuide.Title title="Manage Descriptions" articleId="16441615697821-manage-descriptions" />
      <Content className={styles.content}>
        <Tabs items={tabs} />
      </Content>
    </Layout>
  );
}
