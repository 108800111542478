import { useQueryClient } from "@tanstack/react-query";
import { useCreateBillingNotesMutation, useDeleteBillingNotesMutation } from "api/mutations/billingNotes";
import { useGetBillingNotesQuery } from "api/queries/billingNotes";
import KEYS from "api/queries/keys";
import { Select, Spin } from "components/styleguide";
import TagRender from "components/TagRender";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const BillingNotes: FC = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { isLoading: isCreateBillingNotesLoading, mutate: createBillingNote } = useCreateBillingNotesMutation();
  const { isLoading: isDeleteBillingNotesLoading, mutate: deleteBillingNote } = useDeleteBillingNotesMutation();

  const { isLoading: isGetBillingNotesLoading, data: { data: billingNotes = [] } = {} } = useGetBillingNotesQuery({
    forumId,
  });

  const billingNotesOptions = billingNotes.map(({ id, type }) => ({
    value: id,
    label: type,
  }));

  const billingNotesMutateOptions = {
    onSuccess: () => queryClient.invalidateQueries([KEYS.GET_FORUM_BILLING_NOTES]),
    onError: () => toast.error(t("Something went wrong")),
  };

  const onSelect = (type: string) => {
    createBillingNote(
      {
        forumId,
        billingNoteRequestModel: {
          type,
        },
      },
      billingNotesMutateOptions,
    );
  };

  const onDeselect = (id: number) => {
    deleteBillingNote(
      {
        forumId,
        billingNoteId: id,
      },
      billingNotesMutateOptions,
    );
  };

  if (isGetBillingNotesLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <Select
      style={{ width: "100%" }}
      mode="tags"
      open={false}
      placeholder={t("Billing Options")}
      tagRender={(props) => (
        <TagRender isLoading={isDeleteBillingNotesLoading || isCreateBillingNotesLoading} {...props} />
      )}
      value={billingNotesOptions}
      onSelect={onSelect}
      onDeselect={onDeselect}
    />
  );
};

export default BillingNotes;
