import apiClient from "api-client";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useForumAttendeeMeetingLocationsByAttendeeIdQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID,
  apiClient.ForumAttendeeMeetingLocations.getByAttendeeId,
);

export const useForumAttendeeMeetingLocationsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_MEETING_LOCATIONS,
  apiClient.ForumAttendeeMeetingLocations.getAll,
);

export const useExportForumAttendeeMeetingLocationsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_MEETING_LOCATIONS_EXPORT,
  apiClient.ForumAttendeeMeetingLocations.export.get,
);
