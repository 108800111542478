import { Button, Col, Row } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import AddStreamDrawer from "./components/AddStreamDrawer";
import StreamsTable from "./components/StreamsTable";

const Stream: FC = () => {
  const { t } = useTranslation();

  const [isAddStreamDrawerVisible, setIsAddStreamDrawerVisible] = useState<boolean>(false);

  const onAddStream = () => setIsAddStreamDrawerVisible(true);

  const onCloseAddStreamDrawer = () => setIsAddStreamDrawerVisible(false);

  return (
    <div className="table-wrapper">
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserGuide.Title title="Stream" articleId="16426000704157-streams" />
        </Col>
        <Col span={24}>
          <Button onClick={onAddStream}>{t("Add New Stream")}</Button>
          <AddStreamDrawer isVisible={isAddStreamDrawerVisible} onClose={onCloseAddStreamDrawer} />
        </Col>
        <Col span={24}>
          <StreamsTable />
        </Col>
      </Row>
    </div>
  );
};

export default Stream;
