import { useAttendeePreferencesCategoriesQuery } from "api/queries/attendees";
import { Button, Col, Row, Spin } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AttendeePreferencesCategoriesList from "./AttendeePreferencesCategoriesList";
import EditAttendeePreferencesCategory from "./EditAttendeePreferencesCategory";

const AttendeePreferencesCategory: FC = () => {
  const { t } = useTranslation();
  const params = useParams();

  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: { data: categories = [] } = {}, isLoading: isCategoriesLoading } =
    useAttendeePreferencesCategoriesQuery({
      forumId,
      attendeeId,
    });

  const getCategoryIds = () => categories.map(({ categoryId }) => toNumber(categoryId));

  if (isCategoriesLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (isEditing) {
    return <EditAttendeePreferencesCategory categoryIds={getCategoryIds()} onClose={() => setIsEditing(false)} />;
  }

  return (
    <>
      <Row>
        <Col>
          <Button onClick={() => setIsEditing(true)}>{t(categories.length > 0 ? "Edit" : "Add")}</Button>
        </Col>
      </Row>
      {categories.length > 0 && <AttendeePreferencesCategoriesList {...{ categories }} />}
    </>
  );
};

export default AttendeePreferencesCategory;
