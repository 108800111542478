import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import styles from "./styles.module.less";

// The difference between `AutoGrid` and `Grid` is that `AutoGrid` won't relly on
// ant's grid/columns system at all and may have any number of columns you want
const AutoGrid = ({ className, columns, gap, children }) => {
  const classes = clsx(styles.container, className);

  return <div className={classes} style={{ "--columns": columns, "--gap": `${gap}px` }}>
    {children}
  </div>;
};

AutoGrid.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.number,
  gap: PropTypes.number,
  children: PropTypes.node,
};

AutoGrid.defaultProps = {
  className: "",
  columns: 1,
  gap: 0,
};

export default AutoGrid;
