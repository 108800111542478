import { Col, Row } from "components/styleguide";
import { TestDocumentDefinitionTypes as ReportTypes } from "generated/api";
import React, { FC } from "react";

import Description from "./Description";
import DownloadOptions, { DropdownData } from "./DownloadOptions";

export interface TestItemProps {
  title: string;
  description: JSX.Element;
  reportType: ReportTypes;
  groups?: DropdownData;
  isQuestionnairesVisible?: boolean;
}

const TestItem: FC<TestItemProps> = ({ title, description, reportType, groups, isQuestionnairesVisible }) => {
  return (
    <Row>
      <Col span={12}>
        <DownloadOptions {...{ title, reportType, groups, isQuestionnairesVisible }} />
      </Col>
      <Col span={12}>
        <Description>{description}</Description>
      </Col>
    </Row>
  );
};

export default TestItem;
