import React from "react";
import Select from "../Select";

const YesNo = (props) => {
  return (
    <Select {...props}>
      <Select.Option value={true}>Yes</Select.Option>
      <Select.Option value={false}>No</Select.Option>
    </Select>
  );
};

YesNo.propTypes = Select.propTypes;
YesNo.defaultProps = Select.defaultProps;

export default YesNo;
