import { DeleteFilled } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteStreamMutation, useReorderStreamsMutation } from "api/mutations/streams";
import { useForumStreamsQuery } from "api/queries/forumStreams";
import KEYS from "api/queries/keys";
import { DraggableTable } from "components/DraggableTable";
import { Button, Modal, Typography } from "components/styleguide";
import { StreamResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { stringSorter } from "utils/sorterUtils";

import EditStreamDrawer from "../EditStreamDrawer";

import styles from "./styles.module.less";

const StreamsTable: FC = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [isEditStreamDrawerVisible, setIsEditStreamDrawerVisible] = useState<boolean>(false);
  const [streamToEdit, setStreamToEdit] = useState<StreamResponseModel | null>(null);

  const deleteStreamMutation = useDeleteStreamMutation();

  const { mutate: reorderStreamsMutate } = useReorderStreamsMutation();

  const { data: { data: streams = [] } = {}, isLoading } = useForumStreamsQuery({ forumId });

  const onEditStream = (stream: StreamResponseModel) => {
    setStreamToEdit(stream);
    setIsEditStreamDrawerVisible(true);
  };

  const onCloseEditStreamDrawer = () => {
    setIsEditStreamDrawerVisible(false);
    setStreamToEdit(null);
  };

  const deleteStream = (streamId: number) => {
    deleteStreamMutation.mutate(
      { forumId, streamId: streamId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_STREAMS]);
          toast.success(t("Stream has been deleted successfully"));
        },
        onError: ({ response }: any) => {
          if (response.status === 409) {
            toast.error(t("The stream cannot be deleted because of the assigned seminar. Delete the seminar firstly"));
          } else if (response?.data?.Message) {
            toast.error(response?.data?.message);
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const onDelete = (stream: StreamResponseModel) => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with deletion of this stream?")}</Typography.Text>,
      closable: true,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: () => deleteStream(toNumber(stream.id)),
    });
  };

  const getStreamIndexById = (id: number) => streams.findIndex((item) => item.id === id);

  const reorderStreams = (streamId: number, order: number) => {
    reorderStreamsMutate(
      {
        forumId,
        streamId,
        reorderRequestModel: {
          order,
        },
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_STREAMS]),
      },
    );
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    const oldIndex = getStreamIndexById(active.id as number);
    const newIndex = getStreamIndexById(over?.id as number);
    const updatedStreams = arrayMove(streams as Array<StreamResponseModel>, oldIndex, newIndex);

    reorderStreams(updatedStreams[newIndex].id, newIndex + 1);
  };

  const columns = [
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
      sorter: (a: StreamResponseModel, b: StreamResponseModel) => stringSorter(a.type as string, b.type as string),
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      sorter: (a: StreamResponseModel, b: StreamResponseModel) =>
        stringSorter(a.description as string, b.description as string),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (_: number, row: StreamResponseModel) => {
        return (
          <div className={styles.actions}>
            <Button onClick={() => onEditStream(row)}>{t("Edit")}</Button>
            <DeleteFilled className={styles.deleteIcon} onClick={() => onDelete(row)} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DraggableTable
        columns={columns}
        dataSource={streams}
        pagination={false}
        loading={isLoading}
        rowKey="id"
        onDragEnd={onDragEnd}
      />
      {streamToEdit && (
        <EditStreamDrawer
          stream={streamToEdit}
          isVisible={isEditStreamDrawerVisible}
          onClose={onCloseEditStreamDrawer}
        />
      )}
    </>
  );
};

export default StreamsTable;
