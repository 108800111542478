import { useQueryClient } from "@tanstack/react-query";
import { useCreateTeamMember } from "api/mutations/itineraryBookletsForumTeamMembers";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { ItineraryBookletsForumTeamMemberRequestModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import TeamMemeberForm from "../TeamMemeberForm";

interface NewTeamMemberDrawerProps {
  forumId: number;
  isVisible: boolean;
  onClose: () => void;
}

const NewTeamMemberDrawer: React.FC<NewTeamMemberDrawerProps> = ({ forumId, isVisible, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { isLoading: isCreateTeamMemberLoading, mutate: createTeamMember } = useCreateTeamMember();

  const submit = (values: ItineraryBookletsForumTeamMemberRequestModel) => {
    createTeamMember(
      {
        forumId,
        itineraryBookletsForumTeamMemberRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_TEAM_MEMBERS]);
          onClose();
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onError: ({ response }: any) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
            const { ItemType } = response?.data?.meta ?? null;

            if (ItemType === "ItineraryBookletsForumTeamMember") {
              toast.error(t("Team member already exists"));
            }
          }
        },
      },
    );
  };

  return (
    <Drawer
      title={t("addTeamMember")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <TeamMemeberForm {...{ forumId, onClose }} isSubmitting={isCreateTeamMemberLoading} onSubmit={submit} />
    </Drawer>
  );
};
export default NewTeamMemberDrawer;
