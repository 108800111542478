import {
  SelectionSiteContent,
  SelectionSiteContentSelectionSiteContentPage,
  SelectionSiteContentSelectionSiteContentPageItemsInner,
  SelectionSiteItemType,
} from "../../../generated/api";
import { SelectionSiteItemTypeMap } from "../../selectionSiteContentItemTypeUtils";
import ContentItem from "./content-item";

type Page = SelectionSiteContentSelectionSiteContentPage;
type Item = SelectionSiteContentSelectionSiteContentPageItemsInner;

class Content {
  private readonly _pages: Map<Page["id"], Set<ContentItem>> = new Map();

  private readonly _items: Map<Item["id"], ContentItem> = new Map();

  private readonly _itemsByType: Map<SelectionSiteItemType | undefined, Array<ContentItem>> = new Map();

  constructor(private readonly content: SelectionSiteContent) {
    this._fillItems();
  }

  private _addItemByType(item: ContentItem) {
    const items = this._itemsByType.get(item.value.type) ?? [];
    this._itemsByType.set(item.value.type, [...items, item]);
  }

  private _addItemToPage(pageId: Page["id"], item: ContentItem) {
    const set = this._pages.get(pageId);

    if (!set) {
      this._pages.set(pageId, new Set());
      return;
    }

    set.add(item);
  }

  private _fillItems() {
    const pages = this.content.pages ?? [];

    pages.forEach((page) => {
      const pageItems = page.items ?? [];

      pageItems.forEach((item) => {
        const contentItem = new ContentItem(page.id, item);

        this._items.set(item.id, contentItem);
        this._addItemByType(contentItem);
        this._addItemToPage(page.id, contentItem);
      });
    });
  }

  get items() {
    return [...this._items.values()];
  }

  getPageById(id: Page["id"]) {
    return this._pages.get(id);
  }

  getItemById(id: Item["id"]) {
    return this._items.get(id);
  }

  getItemsByTypes<
    Type extends keyof SelectionSiteItemTypeMap,
    T extends ContentItem = ContentItem<SelectionSiteItemTypeMap[Type]>,
  >(types: Array<Type>): Set<T> {
    const result = new Set<T>();

    types.forEach((type) => {
      this._itemsByType.get(type)?.forEach((item) => {
        // We ignore the type received from typescript
        // here because we are sure that the type is correct
        result.add(item as T);
      });
    });

    return result;
  }
}

export default Content;
