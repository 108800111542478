/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumTableRequestModel } from '../apiModels';
// @ts-ignore
import { ForumTableResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { TableResponseModel } from '../apiModels';
// @ts-ignore
import { TableType } from '../apiModels';
// @ts-ignore
import { TimePeriod } from '../apiModels';
/**
 * ForumTableApi - axios parameter creator
 * @export
 */
export const ForumTableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get table bookings
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet: async (forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet', 'forumFacilityId', forumFacilityId)
            // verify required parameter 'forumTableId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet', 'forumTableId', forumTableId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}/tables/{forumTableId}/bookings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)))
                .replace(`{${"forumTableId"}}`, encodeURIComponent(String(forumTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete: async (forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete', 'forumFacilityId', forumFacilityId)
            // verify required parameter 'forumTableId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete', 'forumTableId', forumTableId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}/tables/{forumTableId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)))
                .replace(`{${"forumTableId"}}`, encodeURIComponent(String(forumTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet: async (forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet', 'forumFacilityId', forumFacilityId)
            // verify required parameter 'forumTableId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet', 'forumTableId', forumTableId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}/tables/{forumTableId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)))
                .replace(`{${"forumTableId"}}`, encodeURIComponent(String(forumTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {ForumTableRequestModel} [forumTableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut: async (forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, forumTableRequestModel?: ForumTableRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut', 'forumFacilityId', forumFacilityId)
            // verify required parameter 'forumTableId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut', 'forumTableId', forumTableId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}/tables/{forumTableId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)))
                .replace(`{${"forumTableId"}}`, encodeURIComponent(String(forumTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumTableRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the tables of a forum meeting facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {TableType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet: async (forumId: number, forumBuildingId: number, forumFacilityId: number, type?: TableType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet', 'forumFacilityId', forumFacilityId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}/tables`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {ForumTableRequestModel} [forumTableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost: async (forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableRequestModel?: ForumTableRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumFacilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost', 'forumFacilityId', forumFacilityId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/facilities/{forumFacilityId}/tables`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumFacilityId"}}`, encodeURIComponent(String(forumFacilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumTableRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumTableApi - functional programming interface
 * @export
 */
export const ForumTableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumTableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get table bookings
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet(forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimePeriod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet(forumId, forumBuildingId, forumFacilityId, forumTableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete(forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete(forumId, forumBuildingId, forumFacilityId, forumTableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet(forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumTableResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet(forumId, forumBuildingId, forumFacilityId, forumTableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {number} forumTableId 
         * @param {ForumTableRequestModel} [forumTableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut(forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableId: number, forumTableRequestModel?: ForumTableRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut(forumId, forumBuildingId, forumFacilityId, forumTableId, forumTableRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the tables of a forum meeting facility
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {TableType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet(forumId: number, forumBuildingId: number, forumFacilityId: number, type?: TableType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TableResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet(forumId, forumBuildingId, forumFacilityId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates forum table
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumFacilityId 
         * @param {ForumTableRequestModel} [forumTableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost(forumId: number, forumBuildingId: number, forumFacilityId: number, forumTableRequestModel?: ForumTableRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumTableResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost(forumId, forumBuildingId, forumFacilityId, forumTableRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumTableApi - factory interface
 * @export
 */
export const ForumTableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumTableApiFp(configuration)
    return {
        /**
         * 
         * @summary Get table bookings
         * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TimePeriod>> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a forum table
         * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get forum table
         * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumTableResponseModel> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a forum table
         * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, requestParameters.forumTableRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the tables of a forum meeting facility
         * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TableResponseModel>> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates forum table
         * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumTableResponseModel> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet operation in ForumTableApi.
 * @export
 * @interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGetRequest
 */
export interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet
     */
    readonly forumTableId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete operation in ForumTableApi.
 * @export
 * @interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDeleteRequest
 */
export interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete
     */
    readonly forumTableId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet operation in ForumTableApi.
 * @export
 * @interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGetRequest
 */
export interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet
     */
    readonly forumTableId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut operation in ForumTableApi.
 * @export
 * @interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPutRequest
 */
export interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut
     */
    readonly forumTableId: number

    /**
     * 
     * @type {ForumTableRequestModel}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut
     */
    readonly forumTableRequestModel?: ForumTableRequestModel
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet operation in ForumTableApi.
 * @export
 * @interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGetRequest
 */
export interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {TableType}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet
     */
    readonly type?: TableType
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost operation in ForumTableApi.
 * @export
 * @interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPostRequest
 */
export interface ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost
     */
    readonly forumFacilityId: number

    /**
     * 
     * @type {ForumTableRequestModel}
     * @memberof ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost
     */
    readonly forumTableRequestModel?: ForumTableRequestModel
}

/**
 * ForumTableApi - object-oriented interface
 * @export
 * @class ForumTableApi
 * @extends {BaseAPI}
 */
export class ForumTableApi extends BaseAPI {
    /**
     * 
     * @summary Get table bookings
     * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTableApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGetRequest, options?: AxiosRequestConfig) {
        return ForumTableApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdBookingsGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a forum table
     * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTableApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumTableApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get forum table
     * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTableApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGetRequest, options?: AxiosRequestConfig) {
        return ForumTableApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a forum table
     * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTableApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPutRequest, options?: AxiosRequestConfig) {
        return ForumTableApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableId, requestParameters.forumTableRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the tables of a forum meeting facility
     * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTableApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGetRequest, options?: AxiosRequestConfig) {
        return ForumTableApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates forum table
     * @param {ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTableApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost(requestParameters: ForumTableApiForumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPostRequest, options?: AxiosRequestConfig) {
        return ForumTableApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumFacilityId, requestParameters.forumTableRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

