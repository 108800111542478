import { useState } from "react";

const DEFAULT_PAGE_SIZE = 50;
const sortDirections = {
  ascend: "asc",
  descend: "desc",
};

const getLegacyPaginationParams = (pageSize, currentPage, sortOptions, queryParams) => {
  return {
    PageSize: pageSize,
    PageNumber: currentPage,
    OrderBy: sortOptions?.sortKey || queryParams?.OrderBy,
    OrderDir: sortOptions?.sortDirection || queryParams?.OrderDir,
  };
};

const getModernPaginationParams = (pageSize, currentPage, sortOptions, queryParams) => {
  return {
    pageSize,
    pageNumber: currentPage,
    orderBy: sortOptions?.sortKey || queryParams?.OrderBy,
    orderDir: sortOptions?.sortDirection || queryParams?.OrderDir,
  };
};

/**
 * Please avoid using of that hook for new queries that was written using `api-client`.
 * Use `useModernQueryWithPaginationAndOrder` instead.
 * @deprecated
 */
const useQueryWithPaginationAndOrder = (
  query,
  queryOptions,
  queryParams = {},
  paginationOptions = {},
  paginationParamsGetter = getLegacyPaginationParams,
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(paginationOptions.defaultPageSize || DEFAULT_PAGE_SIZE);
  const [sortOptions, setSortOptions] = useState(null);

  const handleSort = (_, __, sort) => {
    if (Object.hasOwn(sort, "column")) {
      if (sort.column === undefined) {
        setSortOptions(null);
      } else {
        setSortOptions({
          sortKey: sort.field,
          sortDirection: sortDirections[sort.order],
        });
      }
    }
  };

  const defaultPaginationOptions = {
    pageSizeOptions: [50, 100, 150],
    showSizeChanger: true,
    onChange: (page) => {
      setCurrentPage(page);
    },
    onShowSizeChange: (_, pageSize) => {
      setPageSize(pageSize);
    },
  };

  const paginationParams = paginationParamsGetter(pageSize, currentPage, sortOptions, queryParams);
  const requestParams = {
    ...queryParams,
    ...paginationParams,
  };

  const response = query(requestParams, {
    ...queryOptions,
    onSettled: ({ data, error }) => {
      if (!error) {
        setTotal(data.total);
      }
    },
  });

  const pagination = {
    ...defaultPaginationOptions,
    ...paginationOptions,
    pageSize,
  };

  pagination.total = total;

  return { response, pagination, handleSort };
};

export const useModernQueryWithPaginationAndOrder = (query, queryOptions, queryParams = {}, paginationOptions = {}) => {
  return useQueryWithPaginationAndOrder(query, queryOptions, queryParams, paginationOptions, getModernPaginationParams);
};

export default useQueryWithPaginationAndOrder;
