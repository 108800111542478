import React, { FC } from "react";

import { FreeTextItem } from "../../../../../../../generated/api";
import DefaultForm from "../DefaultForm";

type Item = Pick<FreeTextItem, "id" | "description">;

interface NoOptionsProps {
  item: Item;
  onChange: (item: Item) => void;
}

const NoOptions: FC<NoOptionsProps> = ({ item, onChange }) => {
  const handleChange = (values: Partial<Item>) => {
    const newValues = { ...item, ...values };

    return onChange(newValues);
  };

  return <DefaultForm itemId={item.id} initialValues={item} onChange={handleChange} />;
};

export default NoOptions;
