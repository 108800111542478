import { TabsProps } from "antd";
import { useGetAttendeeConferenceSessionFeedback, useGetAttendeeMeetingFeedback } from "api/queries/attendees";
import { Drawer, Table, Tabs } from "components/styleguide";
import {
  ForumAttendeeMeetingFeedbackModel,
  ForumAttendeeSessionFeedbackModel,
  PageResponseForumAttendeeMeetingFeedbackModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EditMeetingFeedbackDrawer from "./Edit/EditMeetingFeedbackDrawer";
import EditSessionFeedbackDrawer from "./Edit/EditSessionFeedbackDrawer";
import { ConferenceSessionDataIndexes, DataIndexes, getConferenceSessionColumns, getMeetingColumns } from "./helpers";

export default function FeedbackDrawer({
  visible,
  attendeeId,
  onClose,
}: {
  visible: boolean;
  attendeeId?: number;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const locale = {
    emptyText: t("No feedback found"),
  };

  const [startTimeStartFilter, setStartTimeStartFilter] = useState<string | undefined>(undefined);
  const [startTimeEndFilter, setStartTimeEndFilter] = useState<string | undefined>(undefined);

  const [dateTimeStartFilter, setDateTimeStartFilter] = useState<string | undefined>(undefined);
  const [dateTimeEndFilter, setDateTimeEndFilter] = useState<string | undefined>(undefined);
  const [contentQualityFilter, setContentQualityFilter] = useState<string[]>();
  const [contentMatchFilter, setContentMatchFilter] = useState<string[]>();
  const [sessionMeetYourNeedsFilter, setSessionMeetYourNeedsFilter] = useState<string[]>();
  const [feedbackOnSpeakersFilter, setFeedbackOnSpeakersFilter] = useState<string[]>();
  const [feedback, setFeedback] = useState<ForumAttendeeMeetingFeedbackModel[] | undefined>(undefined);
  const [isEditMeetingFeedbackDrawerVisible, setIsEditMeetingFeedbackDrawerVisible] = useState<boolean>(false);
  const [isEditSessionFeedbackDrawerVisible, setIsEditSessionFeedbackDrawerVisible] = useState<boolean>(false);
  const [selectedFeedback, setSelectedFeedback] = useState<
    ForumAttendeeMeetingFeedbackModel | ForumAttendeeSessionFeedbackModel | null
  >(null);

  const showEditMeetingFeedbackDrawer = (currentFeedback: ForumAttendeeMeetingFeedbackModel) => {
    setSelectedFeedback(currentFeedback);
    setIsEditMeetingFeedbackDrawerVisible(true);
  };

  const onCloseEditMeetingFeedbackDrawer = () => {
    setIsEditMeetingFeedbackDrawerVisible(false);
    setSelectedFeedback(null);
  };

  const showEditSessionFeedbackDrawer = (currentFeedback: ForumAttendeeSessionFeedbackModel) => {
    setSelectedFeedback(currentFeedback);
    setIsEditSessionFeedbackDrawerVisible(true);
  };

  const onCloseEditSessionFeedbackDrawer = () => {
    setIsEditSessionFeedbackDrawerVisible(false);
    setSelectedFeedback(null);
  };

  const {
    response: query,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetAttendeeMeetingFeedback,
    {
      enabled: visible && attendeeId !== undefined,
      onSuccess: ({ data }: { data: PageResponseForumAttendeeMeetingFeedbackModel }) => {
        setFeedback(data.items);
      },
    },
    { forumId, attendeeId, startRange: startTimeStartFilter, endRange: startTimeEndFilter },
  );

  const {
    response: { isLoading: isSessionFeedbackLoading, data: { data: { items: sessionFeedback = [] } = {} } = {} } = {},
    pagination: sessionPagination,
    handleSort: sessionSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetAttendeeConferenceSessionFeedback,
    {
      enabled: visible && !!attendeeId,
    },
    {
      forumId,
      attendeeId,
      startRange: dateTimeStartFilter,
      endRange: dateTimeEndFilter,
      contentQuality: contentQualityFilter,
      contentMatch: contentMatchFilter,
      sessionMeetYourNeeds: sessionMeetYourNeedsFilter,
      feedbackOnSpeakers: feedbackOnSpeakersFilter,
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearch = (selectedKeys: any, confirm: () => void, _: string, searchSet: string) => {
    switch (searchSet) {
      case DataIndexes.StartTime: {
        setStartTimeStartFilter(selectedKeys[0]);
        setStartTimeEndFilter(selectedKeys[1]);
        break;
      }
      case ConferenceSessionDataIndexes.SessionStartDate: {
        setDateTimeStartFilter(selectedKeys[0]);
        setDateTimeEndFilter(selectedKeys[1]);
        break;
      }
      case ConferenceSessionDataIndexes.ContentQuality: {
        setContentQualityFilter(selectedKeys.map((option: { value: string }) => option.value));
        break;
      }
      case ConferenceSessionDataIndexes.ContentMatch: {
        setContentMatchFilter(selectedKeys.map((option: { value: string }) => option.value));
        break;
      }
      case ConferenceSessionDataIndexes.SessionMeetYourNeeds: {
        setSessionMeetYourNeedsFilter(selectedKeys.map((option: { value: string }) => option.value));
        break;
      }
      case ConferenceSessionDataIndexes.FeedbackOnSpeakers: {
        setFeedbackOnSpeakersFilter(selectedKeys.map((option: { value: string }) => option.value));
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case DataIndexes.StartTime: {
        setStartTimeStartFilter(undefined);
        setStartTimeEndFilter(undefined);
        break;
      }
      case ConferenceSessionDataIndexes.SessionStartDate: {
        setDateTimeStartFilter(undefined);
        setDateTimeEndFilter(undefined);
        break;
      }
      case ConferenceSessionDataIndexes.ContentQuality: {
        setContentQualityFilter(undefined);
        break;
      }
      case ConferenceSessionDataIndexes.ContentMatch: {
        setContentMatchFilter(undefined);
        break;
      }
      case ConferenceSessionDataIndexes.SessionMeetYourNeeds: {
        setSessionMeetYourNeedsFilter(undefined);
        break;
      }
      case ConferenceSessionDataIndexes.FeedbackOnSpeakers: {
        setFeedbackOnSpeakersFilter(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const meetingColumns = getMeetingColumns({
    forumId,
    attendeeId: toNumber(attendeeId),
    handleReset,
    handleSearch,
    onEditFeedback: showEditMeetingFeedbackDrawer,
  });
  const conferenceSessionColumns = getConferenceSessionColumns({
    forumId,
    attendeeId: toNumber(attendeeId),
    handleReset,
    handleSearch,
    onEditFeedback: showEditSessionFeedbackDrawer,
  });

  const tabs: TabsProps["items"] = [
    {
      key: "meetingsFeedback",
      label: t("Meetings Feedback"),
      children: (
        <Table
          locale={locale}
          columns={meetingColumns}
          dataSource={feedback}
          loading={query.isLoading}
          pagination={pagination}
          onChange={handleSort}
          rowKey="id"
        />
      ),
    },
    {
      key: "conferenceSessionFeedback",
      label: t("Conference Session Feedback"),
      children: (
        <Table
          locale={locale}
          columns={conferenceSessionColumns}
          dataSource={sessionFeedback}
          loading={isSessionFeedbackLoading}
          pagination={sessionPagination}
          onChange={sessionSort}
          rowKey="id"
        />
      ),
    },
  ];

  return (
    <>
      <Drawer open={visible} onClose={onClose} title={t("View Feedback")} placement="right" width="100%" destroyOnClose>
        <Tabs items={tabs} />
      </Drawer>
      {selectedFeedback && attendeeId && (
        <>
          <EditMeetingFeedbackDrawer
            feedback={selectedFeedback}
            attendeeId={attendeeId}
            isVisible={isEditMeetingFeedbackDrawerVisible}
            onClose={onCloseEditMeetingFeedbackDrawer}
          />
          <EditSessionFeedbackDrawer
            feedback={selectedFeedback}
            attendeeId={attendeeId}
            isVisible={isEditSessionFeedbackDrawerVisible}
            onClose={onCloseEditSessionFeedbackDrawer}
          />
        </>
      )}
    </>
  );
}
