import FullHeightForm, { FullHeightFormProps } from "components/FullHeightForm";
import { Form, Input as InputWithoutOnChange } from "components/styleguide";
import { EventOptionsChoice } from "generated/api";
import { useTranslation } from "react-i18next";

import { Input as InputWithOnChange } from "../../../inputs";
import EditOptionsTable from "./components/EditOptionsTable";

interface ChoiceSettingsFormProps<Value> extends Pick<FullHeightFormProps<Value>, "actionsPrepend" | "actionsAppend"> {
  initialValues?: EventOptionsChoice;
  submitFormOnChangeEvent?: boolean;
  onFinish?: (values: EventOptionsChoice) => void;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const ChoiceSettingsForm = <Value extends EventOptionsChoice>({
  initialValues,
  submitFormOnChangeEvent,
  actionsPrepend,
  actionsAppend,
  onFinish,
}: ChoiceSettingsFormProps<Value>) => {
  const { t } = useTranslation();

  const Input = submitFormOnChangeEvent ? InputWithOnChange : InputWithoutOnChange;

  return (
    <FullHeightForm<Value>
      initialValues={initialValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      actionsPrepend={actionsPrepend}
      actionsAppend={actionsAppend}
      onFinish={onFinish}
    >
      <Form.Item name="name" label={t("Choice Name")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="title" label={t("Choice Title Text")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="tardisLink" label={t("Tardis Link")} rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="body" label={t("Body Text")} rules={[{ required: true }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.List
        name="options"
        rules={[
          {
            validator: async (_, v) => {
              const choices: NonNullable<EventOptionsChoice["options"]> = v ?? [];

              const textSet = new Set();
              const valueSet = new Set();

              choices.forEach((choice) => {
                if (textSet.has(choice.text)) {
                  // eslint-disable-next-line @typescript-eslint/quotes
                  throw new Error(t(`Choice text "{{text}}" is not unique`, { text: choice.text }));
                }
                textSet.add(choice.text);

                if (valueSet.has(choice.value)) {
                  // eslint-disable-next-line @typescript-eslint/quotes
                  throw new Error(t(`Choice value "{{value}}" is not unique`, { value: choice.value }));
                }
                valueSet.add(choice.value);
              });
            },
          },
        ]}
      >
        {(_, { add, remove }, { errors }) => (
          <>
            <Form.ErrorList errors={errors} />
            <EditOptionsTable submitFormOnChangeEvent={submitFormOnChangeEvent} add={add} remove={remove} />
          </>
        )}
      </Form.List>
    </FullHeightForm>
  );
};

export default ChoiceSettingsForm;
