import apiClient from "../../../api-client";
import getUniqueKeys from "../../helpers/getUniqueKeys";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const keys = {
  getQuestionnaireContent: (params: Record<string, unknown>) => [
    KEYS.GET_QUESTIONNAIRE_CONTENT,
    ...getUniqueKeys(params),
  ],
  getAutoCreatedMatches: (params: Record<string, unknown>) => [
    KEYS.GET_QUESTIONNAIRE_MATCHES,
    ...getUniqueKeys(params),
  ],
};

export const useQuestionnairesQuery = getWrappedUseQuery(KEYS.GET_QUESTIONNAIRES, apiClient.ForumQuestionnaires.getAll);

export const useSystemQuestionnaires = getWrappedUseQuery(
  KEYS.GET_SYSTEM_QUESTIONNAIRES,
  apiClient.Questionnaires.getAll,
);

export const useQuestionnaireQuery = getWrappedUseQuery(KEYS.GET_QUESTIONNAIRE, apiClient.ForumQuestionnaires.get);

export const useQuestionnaireContentQuery = getWrappedUseQuery(
  KEYS.GET_QUESTIONNAIRE_CONTENT,
  apiClient.ForumQuestionnaires.content.get,
);

export const useQuestionnaireMatchesQuery = getWrappedUseQuery(
  KEYS.GET_QUESTIONNAIRE_MATCHES,
  apiClient.ForumQuestionnaires.match.get,
);

export const useQuestionnairesExportQuery = getWrappedUseQuery(
  KEYS.GET_QUESTIONNAIRES_EXPORT,
  apiClient.ForumQuestionnaires.export.getAll,
);

export const useQuestionnaireExportQuery = getWrappedUseQuery(
  KEYS.GET_QUESTIONNAIRE_EXPORT,
  apiClient.ForumQuestionnaires.export.get,
);

export const useQuestionnairesSubmissionQuery = getWrappedUseQuery(
  KEYS.GET_QUESTIONNAIRES_SUBMISSION,
  apiClient.ForumQuestionnaires.submission.get,
);
