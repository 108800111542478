import { Drawer } from "antd";
import { Tabs } from "components/styleguide";
import { TimeSlotType } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { filterTabs } from "../../helpers";
import MeetingsMoveJoint from "./MeetingsMoveJoint";
import MeetingsMoveLocation from "./MeetingsMoveLocation";
import MeetingsMoveTiming from "./MeetingsMoveTiming";

interface MeetingsMoveDrawerProps {
  itineraryId: number;
  timeSlotType: TimeSlotType;
  tableSupportsJoint: boolean;
  isVisible: boolean;
  onClose: () => void;
}

const MeetingsMoveDrawer: FC<MeetingsMoveDrawerProps> = ({
  itineraryId,
  timeSlotType,
  tableSupportsJoint,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("Timing"),
      key: "timing",
      children: <MeetingsMoveTiming {...{ itineraryId, timeSlotType, onClose }} />,
    },
    {
      label: t("Location"),
      key: "location",
      children: <MeetingsMoveLocation {...{ itineraryId, timeSlotType, onClose }} />,
    },
    {
      label: t("Joint"),
      key: "joint",
      children: <MeetingsMoveJoint {...{ itineraryId, tableSupportsJoint, onClose }} />,
    },
  ];

  const checkIsJointTabVisible = () => {
    const availableTimeSlotTypesForJoint: Array<TimeSlotType> = [
      TimeSlotType.MorningBusinessHours,
      TimeSlotType.AfternoonBusinessHours,
    ];

    return availableTimeSlotTypesForJoint.includes(timeSlotType);
  };

  const getTabs = () => {
    if (!checkIsJointTabVisible()) {
      return filterTabs(tabs, ["timing", "location"]);
    }

    return tabs;
  };

  return (
    <Drawer
      title={t("Moving bookings")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Tabs items={getTabs()} />
    </Drawer>
  );
};

export default MeetingsMoveDrawer;
