import { Select as AntdSelect } from "../../../../../../../../components/styleguide";
import withOnChange from "../../utils/withOnChange";

const Select = withOnChange(AntdSelect);
Select.propTypes = AntdSelect.propTypes;
Select.defaultProps = AntdSelect.defaultProps;

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;
