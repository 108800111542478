import { BaseOptionType } from "antd/lib/select";
import { ColumnType } from "antd/lib/table";
import useCustomHandleSort from "hooks/useCustomHandleSort";
import { toNumber } from "lodash";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useGetItineraryBookletDocumentGenerationRunsQuery } from "../../../../api/queries/itineraryBooklet";
import ContextualDateRenderer from "../../../../components/ContextualDateRenderer";
import { Button, Table } from "../../../../components/styleguide";
import {
  DocumentGenerationRunListItemModel,
  DocumentGenerationRunStatus,
  PageResponseDocumentGenerationRunListItemModel,
} from "../../../../generated/api";
import { useModernQueryWithPaginationAndOrder } from "../../../../hooks";
import { getColumnSearchProps } from "../../../../utils/tableColumnUtils";
import DocumentResults from "./DocumentResults";

import styles from "./styles.module.less";

const CompletedDocumentRuns: FC = () => {
  const navigate = useNavigate();
  const { id: forumId, documentId } = useParams();
  const { t } = useTranslation();

  const { handleSort, getSortOrder, sort, setSort } = useCustomHandleSort("createdAt", "desc");

  let rowKey = 0;

  const [completedDocumentRuns, setCompletedDocumentRuns] = useState<
    Array<DocumentGenerationRunListItemModel> | undefined
  >();
  const [completedDocumentRunsAll, setCompletedDocumentRunsAll] = useState<
    Array<DocumentGenerationRunListItemModel> | undefined
  >();
  const [forumGroupNameSearch, setForumGroupNameSearch] = useState<string | undefined>(undefined);
  const [documentDefinitionSearch, setDocumentDefinitionSearch] = useState<string | undefined>(undefined);
  const [createdBySearch, setCreatedBySearch] = useState<string | undefined>(undefined);
  const [approvalStatusUpdatedBySearch, setApprovalStatusUpdatedBySearch] = useState<string | undefined>(undefined);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [isDocumentResultsDrawerVisible, setIsDocumentResultsDrawerVisible] = useState<boolean>(false);

  const showDocumentResultsDrawer = (id: number) => {
    setSelectedDocumentId(id);
    setIsDocumentResultsDrawerVisible(true);
  };

  const hideDocumentResultsDrawer = () => {
    setSelectedDocumentId(null);
    setIsDocumentResultsDrawerVisible(false);
  };

  const onCloseDocumentResultsDrawer = () => {
    hideDocumentResultsDrawer();
    navigate("../completed");
  };

  const { response: completedDocumentRunsQuery, pagination } = useModernQueryWithPaginationAndOrder(
    useGetItineraryBookletDocumentGenerationRunsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseDocumentGenerationRunListItemModel }) => {
        setCompletedDocumentRuns(data.items);
      },
    },
    {
      forumId,
      status: DocumentGenerationRunStatus.Completed,
      forumGroupName: forumGroupNameSearch,
      documentDefinition: documentDefinitionSearch,
      createdBy: createdBySearch,
      approvalStatusUpdatedBy: approvalStatusUpdatedBySearch,
      OrderBy: sort.orderBy,
      OrderDir: sort.orderDir,
    },
    {
      defaultPageSize: 10,
      pageSizeOptions: [10, 20, 30],
      showSizeChange: false,
    },
  );

  const { isFetching: isFetchingCompletedDocumentRunsAll, refetch: fetchCompletedDocumentRunsAll } =
    useGetItineraryBookletDocumentGenerationRunsQuery(
      {
        forumId: toNumber(forumId),
      },
      {
        onSuccess: ({ data }: { data: PageResponseDocumentGenerationRunListItemModel }) => {
          setCompletedDocumentRunsAll(data.items);
        },
        onError: () => {
          toast.error(t("Something went wrong"));
          navigate("../completed");
        },
        enabled: false,
      },
    );

  useEffect(() => {
    if (documentId) {
      fetchCompletedDocumentRunsAll();
    }
  }, [documentId]);

  useEffect(() => {
    if (!documentId || isFetchingCompletedDocumentRunsAll) {
      return;
    }

    if (!completedDocumentRunsAll) {
      return;
    }
    if (completedDocumentRunsAll.some(({ id }) => id === toNumber(documentId))) {
      showDocumentResultsDrawer(toNumber(documentId));
    } else {
      navigate("../completed");
    }
  }, [documentId, completedDocumentRunsAll]);

  const handleSearch = (
    selectedKeys: Array<string> | Array<BaseOptionType>,
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case "forumGroupName": {
        setForumGroupNameSearch(selectedKeys[0] as string);
        break;
      }
      case "documentDefinition": {
        setDocumentDefinitionSearch(selectedKeys[0] as string);
        break;
      }
      case "createdBy": {
        setCreatedBySearch(selectedKeys[0] as string);
        break;
      }
      case "approvalStatusUpdatedBy": {
        setApprovalStatusUpdatedBySearch(selectedKeys[0] as string);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "forumGroupName": {
        setForumGroupNameSearch(undefined);
        break;
      }
      case "documentDefinition": {
        setDocumentDefinitionSearch(undefined);
        break;
      }
      case "createdBy": {
        setCreatedBySearch(undefined);
        break;
      }
      case "approvalStatusUpdatedBy": {
        setApprovalStatusUpdatedBySearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns: Array<ColumnType<DocumentGenerationRunListItemModel>> = [
    {
      title: t("Event"),
      dataIndex: "forumCode",
      sorter: true,
      ...getSortOrder("forumCode"),
    },
    {
      title: forumGroupNameSearch ?? t("Group"),
      dataIndex: "forumGroupName",
      sorter: true,
      ...getSortOrder("forumGroupName"),
      ...(getColumnSearchProps(
        "forumGroupName",
        handleSearch,
        handleReset,
        "forumGroupName",
      ) as Partial<DocumentGenerationRunListItemModel>),
    },
    {
      title: documentDefinitionSearch ?? t("Document"),
      dataIndex: "documentDefinition",
      render: (document: string) => t(`queueDocumentRunDocuments.${document}`),
      sorter: true,
      ...getSortOrder("documentDefinition"),
      ...(getColumnSearchProps(
        "documentDefinition",
        handleSearch,
        handleReset,
        "documentDefinition",
      ) as Partial<DocumentGenerationRunListItemModel>),
    },
    {
      title: t("Total"),
      dataIndex: "numberOfDocuments",
      sorter: true,
      ...getSortOrder("numberOfDocuments"),
    },
    {
      title: createdBySearch ?? t("Queued by"),
      dataIndex: "createdBy",
      sorter: true,
      ...getSortOrder("createdBy"),
      ...(getColumnSearchProps(
        "createdBy",
        handleSearch,
        handleReset,
        "createdBy",
      ) as Partial<DocumentGenerationRunListItemModel>),
    },
    {
      title: t("Queued date / time"),
      dataIndex: "createdAt",
      width: 150,
      sorter: true,
      ...getSortOrder("createdAt"),
      render: (_, record) => (
        <p className="session-time" key={record.id}>
          <ContextualDateRenderer date={_} showTime />
        </p>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "approvalStatus",
      sorter: true,
      ...getSortOrder("approvalStatus"),
    },
    {
      title: approvalStatusUpdatedBySearch ?? t("Approved / Rejected by"),
      dataIndex: "approvalStatusUpdatedBy",
      sorter: true,
      ...getSortOrder("approvalStatusUpdatedBy"),
      ...(getColumnSearchProps(
        "approvalStatusUpdatedBy",
        handleSearch,
        handleReset,
        "approvalStatusUpdatedBy",
      ) as Partial<DocumentGenerationRunListItemModel>),
    },
    {
      dataIndex: "id",
      render: (_) => <Button onClick={() => navigate(`${_}`)}>{t("View")}</Button>,
    },
  ];

  const getRowKey = () => `${++rowKey}`;

  return (
    <>
      <DocumentResults
        forumId={toNumber(forumId)}
        documentId={selectedDocumentId}
        isVisible={isDocumentResultsDrawerVisible}
        setSort={setSort}
        onClose={onCloseDocumentResultsDrawer}
      />
      <Table
        bordered={true}
        className={styles.completedDocuments}
        columns={columns}
        dataSource={completedDocumentRuns}
        loading={completedDocumentRunsQuery.isLoading}
        locale={{
          emptyText: t("No completed document runs found"),
        }}
        onChange={handleSort}
        pagination={pagination}
        rowKey={getRowKey}
      />
    </>
  );
};

export default CompletedDocumentRuns;
