import { Form } from "components/styleguide";
import TextEditor from "components/TextEditor";
import React from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";

export type ReactQuillType = {
  id: number;
  component?: ReactQuill | null;
};

interface TextEditorOptionProps {
  id: number;
  name: string;
  value: string;
  addQuill: (newQuill: ReactQuillType) => void;
}

const TextEditorOption: React.FC<TextEditorOptionProps> = ({ name, value, addQuill, id }) => {
  const { t } = useTranslation();

  const handleQuillAvailable = (q: React.RefObject<ReactQuill>) => {
    if (q?.current) {
      addQuill({
        id,
        component: q.current,
      });
    }
  };

  return (
    <Form.Item label={t(name)}>
      <TextEditor value={value} onQuillAvailable={handleQuillAvailable} />
    </Form.Item>
  );
};

export default TextEditorOption;
