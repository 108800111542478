import { Space } from "antd";
import { ReportsValues } from "pages/Reporting/helpers";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import LinkToReport from "../components/LinkToReport";
import PeopleTypeSelectOption from "../OptionTypes/PeopleTypeSelectOption";
import SortOrderOption from "../OptionTypes/SortByOption";
import WithFullReasonSelectOption from "../OptionTypes/WithFullReasonSelectOption";
import WithReasonSelectOption from "../OptionTypes/WithReasonSelectOption";

const MeetingPreferencesBySortOptions = { Company: "Company", Surname: "Surname" };
const MeetingPreferencesForSortOptions = {
  ...MeetingPreferencesBySortOptions,
  Priority: "Priority",
};

const PeopleTypePreferencesPostCrunchOptions = () => {
  const { reportKey } = useParams();

  const [isWithReason, setIsWithReason] = useState<boolean>(false);
  const [isWithFullReason, setIsWithFullReason] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string>(MeetingPreferencesBySortOptions.Company);
  const [peopleTypes, setPeopleTypes] = useState<string[]>([]);

  return (
    <Space align="end">
      <SortOrderOption
        options={
          reportKey === ReportsValues.PeopleTypeMeetingPreferencesByPostCrunch
            ? MeetingPreferencesBySortOptions
            : MeetingPreferencesForSortOptions
        }
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <WithReasonSelectOption isWithReason={isWithReason} setIsWithReason={setIsWithReason} />
      <WithFullReasonSelectOption isWithFullReason={isWithFullReason} setIsWithFullReason={setIsWithFullReason} />
      <PeopleTypeSelectOption peopleTypes={peopleTypes} setPeopleTypes={setPeopleTypes} />

      <LinkToReport
        disabled={!peopleTypes.length}
        parameters={{
          ParticipationCode: peopleTypes.join(","),
          WithReason: isWithReason,
          WithFullReason: isWithFullReason,
          OrderBy: orderBy,
        }}
      />
    </Space>
  );
};

export default PeopleTypePreferencesPostCrunchOptions;
