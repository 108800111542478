import { ForumSeminarApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumSeminarApi);

const ForumSeminars = {
  getAll: api.forumsForumIdSeminarsGet,
  post: api.forumsForumIdSeminarsPost,
  put: api.forumsForumIdSeminarsSeminarIdPut,
  withSessions: {
    get: api.forumsForumIdSeminarsSessionsGet,
  },
  details: {
    get: api.forumsForumIdSeminarsForumSeminarIdGet,
  },
  sessions: {
    getAll: api.forumsForumIdSeminarsAllSessionsGet,
  },
  reoder: {
    get: api.forumsForumIdSeminarsSeminarIdReorderPut,
  },
};

export default ForumSeminars;
