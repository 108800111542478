import { DeleteFilled } from "@ant-design/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../styleguide";

import styles from "./styles.module.less";

interface TableRowActionButtonsProps {
  onEdit: () => void;
  onDelete: () => void;
}

const TableRowActionButtons: FC<TableRowActionButtonsProps> = ({ onEdit, onDelete }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Button onClick={onEdit}>{t("Edit")}</Button>
      <DeleteFilled className={styles.deleteIcon} alt={t("Delete")} onClick={onDelete} />
    </div>
  );
};

export default TableRowActionButtons;
