/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Em2ExceptionTypes = {
    DuplicateItem: 'DuplicateItem',
    ObjectNotFound: 'ObjectNotFound',
    ValueOutOfRange: 'ValueOutOfRange',
    InvalidTimePeriod: 'InvalidTimePeriod',
    AssociatedObjectNotFound: 'AssociatedObjectNotFound',
    InvalidEntityState: 'InvalidEntityState',
    UnknownObject: 'UnknownObject',
    FieldsCannotBeEqual: 'FieldsCannotBeEqual',
    ReferencedObjectCannotBeDeleted: 'ReferencedObjectCannotBeDeleted',
    NotAllValuesProvided: 'NotAllValuesProvided',
    InvalidModelState: 'InvalidModelState',
    ServiceCallException: 'ServiceCallException',
    UnsupportedFormat: 'UnsupportedFormat',
    DomainSpecificBadRequestException: 'DomainSpecificBadRequestException',
    DomainSpecificConflictException: 'DomainSpecificConflictException',
    InvalidHtmlContentMergeTags: 'InvalidHtmlContentMergeTags',
    AnswerFormatException: 'AnswerFormatException',
    ObjectHasAssociatedItemsException: 'ObjectHasAssociatedItemsException',
    SelectionSiteContentException: 'SelectionSiteContentException',
    ChildrenNotFound: 'ChildrenNotFound'
} as const;

export type Em2ExceptionTypes = typeof Em2ExceptionTypes[keyof typeof Em2ExceptionTypes];



