import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumRoomGradeMutation } from "api/mutations/forumRoomGrades";
import { useUpdateRoomGradeMutation } from "api/mutations/roomGrade";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { RoomGradeRequestModel, RoomGradeResponseModel } from "generated/api";
import { toNumber } from "lodash-es";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import GradeForm from "../GradeForm";

interface EditGradeProps {
  grade: RoomGradeResponseModel;
  isVisible: boolean;
  onClose: () => void;
}

const EditGrade: FC<EditGradeProps> = ({ grade, isVisible, onClose }) => {
  const { t } = useTranslation();
  const { id: forumId, venueId, buildingId } = useParams();
  const queryClient = useQueryClient();

  const { isLoading: isUpdateForumRoomGradeLoading, mutate: updateForumRoomGradeMutate } =
    useUpdateForumRoomGradeMutation();
  const { isLoading: isUpdateSystemAdminRoomGradeLoading, mutate: updateSystemAdminRoomGradeMutate } =
    useUpdateRoomGradeMutation();

  const isUpdateRoomGradeLoading = forumId ? isUpdateForumRoomGradeLoading : isUpdateSystemAdminRoomGradeLoading;

  const updateForumRoomGrade = (roomGradeRequestModel: RoomGradeRequestModel) => {
    updateForumRoomGradeMutate(
      {
        forumId: toNumber(forumId),
        forumBuildingId: toNumber(buildingId),
        forumGradeId: grade.id,
        roomGradeRequestModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_ROOM_GRADES]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const updateSystemAdminRoomGrade = (roomGradeRequestModel: RoomGradeRequestModel) => {
    updateSystemAdminRoomGradeMutate(
      { venueId: toNumber(venueId), buildingId: toNumber(buildingId), gradeId: grade.id, roomGradeRequestModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ROOM_GRADES]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onSubmit = (roomGradeRequestModel: RoomGradeRequestModel) => {
    if (forumId) {
      updateForumRoomGrade(roomGradeRequestModel);
    } else {
      updateSystemAdminRoomGrade(roomGradeRequestModel);
    }
  };

  return (
    <Drawer title={t("Edit Grade")} open={isVisible} placement="right" size="large" destroyOnClose onClose={onClose}>
      <GradeForm {...{ grade, onSubmit, onClose }} isSubmitting={isUpdateRoomGradeLoading} />
    </Drawer>
  );
};

export default EditGrade;
