import { Col, Divider, Form, Input, Row, Space, Spin } from "components/styleguide";
import { SpeakerTrackingResponseModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";

import { getFormFields } from "./helpers";

interface FeesAndExpensesProps {
  isEditing: boolean;
  speakerTracking: SpeakerTrackingResponseModel | null;
  isLoading: boolean;
}

const FeesAndExpenses = ({ isEditing, isLoading, speakerTracking }: FeesAndExpensesProps) => {
  const { t } = useTranslation();

  if (!speakerTracking || isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <Row gutter={80}>
      {getFormFields(speakerTracking).map((f) => (
        <Col key={f.dataIndex} md={24} lg={12}>
          {f.title ? (
            <Form.Item initialValue={f.initialValue ?? ""} label={t(f.title)} name={f.dataIndex}>
              {isEditing ? f.component ?? <Input /> : <label>{f.initialValue ? f.initialValue : "-"}</label>}
            </Form.Item>
          ) : (
            <Space fullWidth={false} />
          )}
          {f.title && <Divider />}
        </Col>
      ))}
    </Row>
  );
};

export default FeesAndExpenses;
