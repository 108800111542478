import { useQueryClient } from "@tanstack/react-query";
import { useDeleteForumAttendeeMeetingFeedbackMutation } from "api/mutations/forumAttendeeMeetingFeedback";
import KEYS from "api/queries/keys";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import DeleteFeedbackButton from "../DeleteFeedbackButton";

interface DeleteMeetingFeedbackButtonProps {
  forumId: number;
  attendeeId: number;
  feedbackId: number;
}

const DeleteMeetingFeedbackButton: FC<DeleteMeetingFeedbackButtonProps> = ({ forumId, attendeeId, feedbackId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: deleteMeetingFeedbackMutate } = useDeleteForumAttendeeMeetingFeedbackMutation();

  const onDelete = () => {
    deleteMeetingFeedbackMutate(
      { forumId, attendeeId, feedbackId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_FEEDBACK]);
          queryClient.invalidateQueries([KEYS.GET_FEEDBACK_BY_PARTICIPATION_TYPE]);
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return <DeleteFeedbackButton {...{ onDelete }} />;
};

export default DeleteMeetingFeedbackButton;
