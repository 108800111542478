import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from "antd";
import clsx from "clsx";
import React from "react";

import styles from "./styles.module.less";

interface TabsProps extends AntdTabsProps {
  className?: string;
  hideNav?: boolean;
}

const Tabs: React.FC<TabsProps> & { TabPane: typeof AntdTabs.TabPane } = ({
  className = "",
  hideNav = false,
  ...props
}) => {
  const classes = clsx(hideNav && styles.hideNav, className);

  return <AntdTabs className={classes} {...props} />;
};

Tabs.TabPane = AntdTabs.TabPane;

export default Tabs;
