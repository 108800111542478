import { useForumStreamsQuery } from "api/queries/forumStreams";
import { Space } from "components/styleguide";
import { ConferenceSelectionData, TabbedConferenceStream } from "generated/api";
import { toNumber } from "lodash";
import { FC, useMemo } from "react";

import withOnChange from "../../utils/withOnChange";
import Add from "./components/Add";
import ListingTable from "./components/ListingTable";

type Type = TabbedConferenceStream | ConferenceSelectionData;

interface ConferenceSessionsShownProps {
  forumId: number;
  readonlyCheckboxKey: keyof Type;
  value?: Array<Type>;
  onChange?: (value: Array<Type>) => void;
}

const ConferenceSessionsShown: FC<ConferenceSessionsShownProps> = ({
  forumId,
  readonlyCheckboxKey,
  value,
  onChange,
}) => {
  const query = useForumStreamsQuery({ forumId });

  const existingSessions = useMemo(() => {
    return (value || []).reduce<Array<number>>((accumulator, session) => {
      if (!session.forumStreamId) {
        return accumulator;
      }

      return [...accumulator, session.forumStreamId];
    }, []);
  }, [value]);

  const filteredValue = useMemo(() => {
    const streamsSet = new Set(query.data?.data.map((stream) => stream.id));

    return (value ?? []).filter((session) => streamsSet.has(toNumber(session.forumStreamId)));
  }, [query.data?.data, value]);

  const handleAdd = (id: number, type: string) => {
    onChange?.([...(filteredValue || []), { forumStreamId: id, forumStreamName: type }]);
  };

  return (
    <Space direction="vertical" fullWidth>
      <Add forumId={forumId} exclude={existingSessions} onAdd={handleAdd} />
      <ListingTable
        value={filteredValue}
        readonlyCheckboxKey={readonlyCheckboxKey}
        loading={query.isLoading}
        onChange={onChange}
      />
    </Space>
  );
};

export default withOnChange(ConferenceSessionsShown);
