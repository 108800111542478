import { ForumQuestionnaireApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumQuestionnaireApi);

const ForumQuestionnaire = {
  getAll: api.forumForumIdQuestionnairesGet,
  post: api.forumForumIdQuestionnairesPost,
  get: api.forumForumIdQuestionnairesQuestionnaireIdGet,
  put: api.forumForumIdQuestionnairesQuestionnaireIdPut,
  content: {
    get: api.forumForumIdQuestionnairesQuestionnaireIdContentGet,
    put: api.forumForumIdQuestionnairesQuestionnaireIdContentPut,
  },
  match: {
    get: api.forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet,
  },
  export: {
    getAll: api.forumForumIdQuestionnairesExportGet,
    get: api.forumForumIdQuestionnairesQuestionnaireIdExportGet,
  },
  submission: {
    get: api.forumForumIdQuestionnairesSubmissionGet,
  },
};

export default ForumQuestionnaire;
