import { Space } from "components/styleguide";
import { ForumSeminarSessionsModel } from "generated/api/apiModels/forum-seminar-sessions-model";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DefaultSortOptions } from "../constants";
import SessionsTableOption from "../OptionTypes/SessionsTableOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const ConferenceAttendeesSessionOptions = () => {
  const navigate = useNavigate();
  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Surname);

  const onSessionSelect = (session: ForumSeminarSessionsModel) => {
    navigate("report", {
      state: {
        parameters: {
          OrderBy: orderBy,
          ForumSeminarSessionId: session.id,
        },
      },
    });
  };

  return (
    <Space direction={"vertical"} size={"large"} fullWidth>
      <SortOrderOption options={DefaultSortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
      <SessionsTableOption onSessionSelect={onSessionSelect} />
    </Space>
  );
};

export default ConferenceAttendeesSessionOptions;
