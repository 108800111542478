import { TabsProps } from "antd";
import { Layout, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useTranslation } from "react-i18next";

import BookedMeetings from "./BookedMeetings";
import FindMeetings from "./FindMeetings";

import styles from "./styles.module.less";

const { Content } = Layout;

export default function OnSiteFunctions() {
  const { t } = useTranslation();

  const tabs: TabsProps["items"] = [
    {
      key: "findMeetings",
      label: t("Find Meetings"),
      children: <FindMeetings />,
    },
    {
      key: "bookedMeetings",
      label: t("Booked Meetings"),
      children: <BookedMeetings />,
    },
  ];

  return (
    <Layout className={styles.layout}>
      <UserGuide.Title title="On-site Functions" articleId="16441838819869-on-site-functions" />
      <Content className={styles.content}>
        <Tabs items={tabs} />
      </Content>
    </Layout>
  );
}
