import { ForumAttendeeMeetingLocationsApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeMeetingLocationsApi);

const ForumAttendeeMeetingLocations = {
  getByAttendeeId: api.forumsForumIdAttendeesAttendeeIdMeetingLocationsGet,
  put: api.forumsForumIdAttendeesAttendeeIdMeetingLocationsPut,
  putByTimeslotType: api.forumsForumIdAttendeesAttendeeIdMeetingLocationsTimeSlotTypePut,
  getAll: api.forumsForumIdAttendeesMeetingLocationsGet,
  export: {
    get: api.forumsForumIdAttendeesMeetingLocationsExportGet,
  },
};

export default ForumAttendeeMeetingLocations;
