import { UserApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(UserApi);

const Users = {
  get: api.usersMeGet,
  getAll: api.usersGet,
  getById: api.usersIdGet,
};

export default Users;
