import axios from "axios";

import {
  requestFormDataInterceptorResolve,
  requestInterceptorReject,
  requestInterceptorResolve,
  responseInterceptorReject,
  responseInterceptorResolve,
} from "./interceptors";

/**
 * That api client is deprecated, and we should avoid using it.
 * Please use `api-client` instead.
 *
 * In the future, for requests will be used only `api-client` and this client will be used
 * only as axios-client for `api-client`.
 *
 * @deprecated
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

api.defaults.withCredentials = true;

api.interceptors.request.use(requestInterceptorResolve, requestInterceptorReject);
api.interceptors.response.use(responseInterceptorResolve, responseInterceptorReject);

/**
 * Please, don't use this client for requests either.
 * Use `api-client` instead.
 *
 * @deprecated
 */
const formDataApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

formDataApi.defaults.withCredentials = true;

formDataApi.interceptors.request.use(requestFormDataInterceptorResolve, requestInterceptorReject);
formDataApi.interceptors.response.use(responseInterceptorResolve, responseInterceptorReject);

export default api;
export { formDataApi };
