import { useMutation } from "@tanstack/react-query";
import ForumAttendee from "api-client/controllers/forumAttendee";

import api from "../../../api";
import getWrappedUseMutation from "../../../api/helpers/getWrappedUseMutation";
import apiClient from "../../../api-client";

const useUpdateAttendeeCompanyAddressMutation = getWrappedUseMutation(apiClient.ForumAttendees.addressInfo.put);

const useUpdateAttendeeMutation = (options = {}) => {
  const mutation = useMutation(
    async ({ forumId, attendeeId, attendee }) => api.put(`forum/${forumId}/attendees/${attendeeId}`, attendee),
    options,
  );
  return mutation;
};

const useUpdateAttendeeBfrDateMutation = getWrappedUseMutation(apiClient.ForumAttendees.bfrDate.put);

const useUpdateAttendeeSocialLinksMutation = getWrappedUseMutation(apiClient.ForumAttendees.socialLinks.put);

const useUpdateAttendeeContactDetailsMutation = getWrappedUseMutation(apiClient.ForumAttendees.contactDetails.put);

const useUpdateAttendeePreferencesCategoriesMutation = getWrappedUseMutation(
  apiClient.ForumAttendees.categoryPreference.put,
);

const useUpdateAttendeePreferencesMeetingPriorityMutation = getWrappedUseMutation(
  apiClient.ForumAttendees.meetingPreference.put,
);

const useUpdateAttendeePreferencesMeetingMutation = getWrappedUseMutation(
  apiClient.ForumAttendees.meetingPreference.put,
);

const useCreateAttendeePreferencesMeetingMutation = getWrappedUseMutation(
  apiClient.ForumAttendees.meetingPreference.post,
);

const useDeleteAttendeePreferencesMeetingMutation = getWrappedUseMutation(
  apiClient.ForumAttendees.meetingPreference.delete,
);

const useUpdateAttendeeDescriptionMutation = getWrappedUseMutation(apiClient.ForumAttendees.description.put);

const useUpdateAttendeePartnerDetailsMutation = getWrappedUseMutation(apiClient.ForumAttendees.partner.post);

const useAddPersonsAsAttnedeesMutation = getWrappedUseMutation(ForumAttendee.fromPersons.putMany);

const useCreateSeminarPreferenceMutation = getWrappedUseMutation(ForumAttendee.seminarPreference.post);

const useUpdateSeminarPreferenceMutation = getWrappedUseMutation(ForumAttendee.seminarPreference.put);
const useAttendeeReplaceOrCancelMutation = getWrappedUseMutation(ForumAttendee.patch);
const useDeleteSeminarPreferenceMutation = getWrappedUseMutation(ForumAttendee.seminarPreference.delete);
const useDeleteBlockedMeetingPreference = getWrappedUseMutation(ForumAttendee.blockedMeetingPreference.delete);
const useUpdateBlockedMeetingPreference = getWrappedUseMutation(ForumAttendee.blockedMeetingPreference.put);

const useUpdateAttendeeSpeakerTrackingMutation = getWrappedUseMutation(apiClient.ForumAttendees.speakerTracking.put);

const usePatchAttendeeQuery = getWrappedUseMutation(ForumAttendee.patchById);

const useUpdateAttendeeHotelAndRoomAllocation = getWrappedUseMutation(
  apiClient.ForumAttendees.hotelAndRoomAllocation.put,
);

const useMoveAttendeeToCompanyMutation = getWrappedUseMutation(apiClient.ForumAttendees.company.put);

const useUpdateDietaryRequirementsMutation = getWrappedUseMutation(apiClient.ForumAttendees.dietaryRequirements.put);

const useDeleteAttendeeItinerary = getWrappedUseMutation(apiClient.ForumAttendees.itinerary.delete);

const useMoveAttendeeItineraryMutation = getWrappedUseMutation(apiClient.ForumAttendees.itinerary.move.patch);

const useMoveTestAttendeeItineraryMutation = getWrappedUseMutation(apiClient.ForumAttendees.itinerary.move.patchTest);

const useAttendeeReplaceMutation = getWrappedUseMutation(ForumAttendee.replacement.post);

export const useMakeAttendeeAttendingMutation = getWrappedUseMutation(ForumAttendee.makeAttending.put);

export {
  useAddPersonsAsAttnedeesMutation,
  useAttendeeReplaceMutation,
  useAttendeeReplaceOrCancelMutation,
  useCreateAttendeePreferencesMeetingMutation,
  useCreateSeminarPreferenceMutation,
  useDeleteAttendeeItinerary,
  useDeleteAttendeePreferencesMeetingMutation,
  useDeleteBlockedMeetingPreference,
  useDeleteSeminarPreferenceMutation,
  useMoveAttendeeItineraryMutation,
  useMoveAttendeeToCompanyMutation,
  useMoveTestAttendeeItineraryMutation,
  usePatchAttendeeQuery,
  useUpdateAttendeeBfrDateMutation,
  useUpdateAttendeeCompanyAddressMutation,
  useUpdateAttendeeContactDetailsMutation,
  useUpdateAttendeeDescriptionMutation,
  useUpdateAttendeeHotelAndRoomAllocation,
  useUpdateAttendeeMutation,
  useUpdateAttendeePartnerDetailsMutation,
  useUpdateAttendeePreferencesCategoriesMutation,
  useUpdateAttendeePreferencesMeetingMutation,
  useUpdateAttendeePreferencesMeetingPriorityMutation,
  useUpdateAttendeeSocialLinksMutation,
  useUpdateAttendeeSpeakerTrackingMutation,
  useUpdateBlockedMeetingPreference,
  useUpdateDietaryRequirementsMutation,
  useUpdateSeminarPreferenceMutation,
};
