import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";

import getUniqueKeys from "./getUniqueKeys";

type Options<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryKey" | "queryFn" | "initialData"> & {
  initialData?: () => undefined;
};

/**
 * # Description
 * This is a wrapper around useQuery that adds a unique key to the query
 *
 * # Example of usage
 * ## queries folder
 * ```ts
 * export const useSomeQuery = getWrappedUseQuery(KEYS.SOME_UNIQUE_KEY, apiClient.someQuery.get);
 * ```
 *
 * ## in your react component
 * ```tsx
 * const query = useSomeQuery({ ... }, options);
 * query.data;
 * ```
 */
const getWrappedUseQuery = <Error, Data, Params>(key: string, queryFn: (params: Params) => Promise<Data>) => {
  const useFunction = (params: Params, options?: Options<Promise<Data>, Error, Data>) => {
    return useQuery(
      [key, ...getUniqueKeys(params as Record<string, unknown>)],
      () => queryFn(params),
      // `any` is here only because I'm tired of fighting with TypeScript
      // to have support of data on using `{ onSuccess: (data) => ... }`
      // we don't really care about the type of data here
      // the thing is that on using wrapper query - it works correctly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options as any,
    );
  };

  useFunction.getQueryKeys = (params: Params) => [key, ...getUniqueKeys(params as Record<string, unknown>)];
  return useFunction;
};

export default getWrappedUseQuery;
