import { useUpdateAttendeeCompanyAddressMutation } from "api/mutations/attendees";
import { useAttendeeAddresInfoQuery } from "api/queries/attendees";
import { useGetCountriesQuery } from "api/queries/countries";
import { Button, Col, Divider, Form, Input, Radio, Row, Select, Spin } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { AttendeeAddressInformationModel, CountryModel } from "generated/api";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { getFormFields } from "./helpers";

import styles from "./styles.module.less";

const { Option } = Select;

export default function AttendeeAddressInfo({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: forumId, attendeeId } = useParams();
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [email, setEmail] = useState<string | null | undefined>(null);
  const [email2, setEmail2] = useState<string | null | undefined>(null);
  const [addressInfo, setAddressInfo] = useState<AttendeeAddressInformationModel>();

  const query = useAttendeeAddresInfoQuery(
    { forumId: toNumber(forumId), attendeeId: toNumber(attendeeId) },
    {
      onSuccess: ({ data }) => {
        setAddressInfo(data);
      },
    },
  );
  const mutation = useUpdateAttendeeCompanyAddressMutation();

  const resetEmails = useCallback(
    (data: AttendeeAddressInformationModel) => {
      if (data) {
        setEmail(data.email);
        setEmail2(data.email2);

        if (data.mailToAddress === data.email) {
          form.setFieldValue("mailToAddress", 0);
        } else if (data.mailToAddress === data.email2) {
          form.setFieldValue("mailToAddress", 1);
        }
      }
    },
    [form],
  );

  const onEmailChange = (value: string) => {
    setEmail(value);
  };

  const onEmail2Change = (value: string) => {
    setEmail2(value);
    if (!value) {
      form.setFieldValue("mailToAddress", 0);
    }
  };

  useEffect(() => {
    if (addressInfo) {
      resetEmails(addressInfo);
    }
  }, [addressInfo, resetEmails]);

  useGetCountriesQuery(
    {},
    {
      onSuccess: ({ data }) => {
        setCountries(data);
      },
    },
  );

  if (query.isLoading || !addressInfo) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  const cancelEdit = () => {
    form.resetFields();
    resetEmails(addressInfo);
    setIsEditing(false);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (values: any) => {
    const updatedAddressInfo = values;

    if (email || email2) {
      const mailToValue = form.getFieldValue("mailToAddress");

      if (mailToValue === 0 && email) {
        updatedAddressInfo.mailTo = email;
        updatedAddressInfo.mailToAddress = email;
      } else if (mailToValue === 1 && email2) {
        updatedAddressInfo.mailTo = email2;
        updatedAddressInfo.mailToAddress = email2;
      }
    } else {
      updatedAddressInfo.mailTo = null;
      updatedAddressInfo.mailToAddress = null;
    }

    values.email = email && email.length > 0 ? email : null;
    values.email2 = email2 && email2.length > 0 ? email2 : null;

    mutation.mutate(
      {
        forumId: toNumber(forumId),
        attendeeId: toNumber(attendeeId),
        attendeeAddressInfoRequestModel: updatedAddressInfo,
      },
      {
        onSuccess: () => {
          setAddressInfo(updatedAddressInfo);
          setIsEditing(false);
        },

        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
            const duplicateField = Object.keys(response.data.meta.ItemFields)[0];

            return duplicateField === "Email" || duplicateField === "Email2"
              ? toast.error(t("errors.alreadyExists", { prop1: t("An Attendee"), prop2: t(duplicateField) }) as string)
              : toast.error(t("Something went wrong") as string);
          } else {
            toast.error(t("Something went wrong") as string);
          }
        },
      },
    );
  };

  return (
    <div>
      <Form
        onFinish={submit}
        id="contactInfoForm"
        className={styles.addressInfo}
        name="contact-info"
        form={form}
        labelAlign="left"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 11 }}
        labelWrap
        colon={false}
      >
        <Row gutter={80}>
          {getFormFields({ t, onEmailChange, onEmail2Change }).map((f) => (
            <Col key={f.dataIndex} className={styles.formItemCol} md={24} lg={12}>
              <Form.Item
                initialValue={addressInfo[f.dataIndex as keyof AttendeeAddressInformationModel]}
                label={t(f.title)}
                name={f.dataIndex}
                className={styles.formItem}
                rules={isEditing ? f.rules : undefined}
              >
                {isEditing ? (
                  f.component ?? <Input maxLength={f.maxLength} />
                ) : (
                  <label>{addressInfo[f.dataIndex as keyof AttendeeAddressInformationModel] ?? "-"}</label>
                )}
              </Form.Item>
              <Divider />
            </Col>
          ))}
        </Row>
        <Row gutter={80}>
          <Col key="postcode" className={styles.formItemCol} md={24} lg={12}>
            <Form.Item
              initialValue={addressInfo.postcode}
              label={t("Postcode")}
              name="postcode"
              className={styles.formItem}
            >
              {isEditing ? <Input maxLength={10} /> : <label>{addressInfo.postcode ?? "-"}</label>}
            </Form.Item>
            <Divider />
          </Col>
        </Row>
        <Row gutter={80}>
          <Col key="country" md={24} lg={12} className={styles.formItemCol}>
            <Form.Item
              initialValue={addressInfo.country}
              label={t("Country")}
              name="country"
              className={styles.formItem}
            >
              {isEditing ? (
                <Select>
                  {countries.map((c: CountryModel) => (
                    <Option value={c.name} key={c.isoCode}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              ) : (
                <label>{addressInfo.country ?? "-"}</label>
              )}
            </Form.Item>
            <Divider />
          </Col>
        </Row>
        <Row gutter={80}>
          <Col key="mailToAddress" className={styles.formItemCol} md={24} lg={12}>
            <Form.Item label={t("Mail To")} name="mailToAddress" className={styles.formItem}>
              {isEditing ? (
                <Radio.Group>
                  {email && <Radio value={0}>{email}</Radio>}
                  {email2 && <Radio value={1}>{email2}</Radio>}
                </Radio.Group>
              ) : (
                <label>
                  {addressInfo.mailToAddress && addressInfo.mailToAddress !== "3" ? addressInfo.mailToAddress : "-"}
                </label>
              )}
            </Form.Item>
            <Divider />
          </Col>
        </Row>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={mutation.isLoading} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
}

AttendeeAddressInfo.propTypes = {
  attendee: PropTypes.object,
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func,
  setAttendee: PropTypes.func,
  isLoading: PropTypes.bool,
};
