import { LockFilled, UnlockOutlined } from "@ant-design/icons";
import { BaseOptionType, SelectValue } from "antd/es/select";
import { useTimeslotSessions } from "api/queries/timeslotSession";
import { Select } from "components/styleguide";
import {
  AttendeeLocationTimeSlotSession,
  AttendeeSessionMeetingLocationModel,
  FacilityResponseModel,
} from "generated/api";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import TableSelect from "./TableSelect";

import styles from "./styles.module.scss";

interface AttendeeMeetingLocationsAdvancedSetupRowProps {
  forumId: number;
  meetingLocation: AttendeeSessionMeetingLocationModel;
  setMeetingLocation: (index: number, record: AttendeeSessionMeetingLocationModel) => void;
  index: number;
  forumFacilities: FacilityResponseModel[];
  loading: boolean;
}
const MeetingLocationsAdvancedSetupRow = ({
  forumId,
  meetingLocation,
  index,
  forumFacilities,
  setMeetingLocation,
  loading,
}: AttendeeMeetingLocationsAdvancedSetupRowProps) => {
  const { t } = useTranslation();
  const [key, setKey] = useState(0);

  const { data: { data: sessions = [] } = {}, isLoading: isSessionsLoading } = useTimeslotSessions(
    { forumId, timeslotId: meetingLocation.timeSlot.id as number },
    {
      enabled: !!meetingLocation.timeSlot.id,
    },
  );

  const TIMESLOT_DATE_FORMAT = `${t("dateFormat")} HH:mm`;

  const handleFacilitySelect = (value: SelectValue, option: BaseOptionType) => {
    const { facility } = option as { facility: FacilityResponseModel };
    setMeetingLocation(index, {
      ...meetingLocation,
      facility,
      table: null,
    });
    setKey(key + 1);
  };

  const handleSessionChange = (value: SelectValue) => {
    setMeetingLocation(index, {
      ...meetingLocation,
      timeSlotSession: sessions.find((session) => session.id === value) as AttendeeLocationTimeSlotSession,
    });
  };

  const handleSessionLockToggle = () => {
    setMeetingLocation(index, {
      ...meetingLocation,
      isLocked: !meetingLocation.isLocked,
    });
  };

  return (
    <tr>
      <td style={{ width: "15%" }}>
        <b>{meetingLocation.timeSlot.description}</b> <br />
        {moment(meetingLocation.timeSlot.startTime).format("HH:mm A")}
      </td>
      <td style={{ width: "25%" }}>
        <Select
          disabled={meetingLocation.isLocked}
          style={{ width: "100%" }}
          options={[
            { value: null, label: t("None") },
            ...forumFacilities.map((facility) => ({
              value: facility.id,
              label: facility.name,
              facility: facility,
            })),
          ]}
          value={meetingLocation?.facility?.id || null}
          onChange={handleFacilitySelect}
          loading={loading}
        />
      </td>
      <td style={{ width: "10%" }}>
        <TableSelect
          forumId={forumId}
          meetingLocation={meetingLocation}
          setMeetingLocation={setMeetingLocation}
          index={index}
          loading={loading}
          key={key}
        />
      </td>
      <td style={{ width: "10%" }}>
        <Select
          disabled={meetingLocation.isLocked}
          style={{ width: "100%" }}
          value={meetingLocation.ratio}
          onChange={(value) => setMeetingLocation(index, { ...meetingLocation, ratio: value })}
          loading={loading}
        >
          {[...Array(13).keys()].map((ratio) => (
            <Select.Option key={ratio} value={ratio}>
              {ratio}
            </Select.Option>
          ))}
        </Select>
      </td>
      <td style={{ width: "30%" }}>
        <Select
          disabled={meetingLocation.isLocked}
          style={{ width: "100%" }}
          options={sessions.map((session) => ({
            value: session.id,
            label: `
              ${moment(session.startTime).format(TIMESLOT_DATE_FORMAT)}
               - 
               ${moment(session.endTime).format(TIMESLOT_DATE_FORMAT)}
             `,
          }))}
          value={meetingLocation.timeSlotSession ? meetingLocation.timeSlotSession.id : sessions[0]?.id}
          onChange={handleSessionChange}
          loading={isSessionsLoading || loading}
        />
      </td>
      <td style={{ width: "10%" }}>
        <button onClick={handleSessionLockToggle} className={styles.lockIcon}>
          {meetingLocation.isLocked ? <LockFilled /> : <UnlockOutlined />}
        </button>
      </td>
    </tr>
  );
};

export default MeetingLocationsAdvancedSetupRow;
