import { useQueryClient } from "@tanstack/react-query";
import { useCreateStreamMutation } from "api/mutations/streams";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { StreamRequestModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import StreamForm from "../StreamForm";

interface AddStreamDrawerProps {
  isVisible: boolean;
  onClose: () => void;
}

const AddStreamDrawer: FC<AddStreamDrawerProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { isLoading: isAddStreamLoading, mutate: addStream } = useCreateStreamMutation();

  const onSubmit = async (values: StreamRequestModel) => {
    await addStream(
      {
        forumId,
        streamRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_STREAMS]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer
      title={t("Add New Stream")}
      open={isVisible}
      size="large"
      placement="right"
      destroyOnClose
      onClose={onClose}
    >
      <StreamForm submitButtonTitle={"Create"} isLoading={isAddStreamLoading} onSubmit={onSubmit} onClose={onClose} />
    </Drawer>
  );
};

export default AddStreamDrawer;
