import DropdownButton from "components/DropdownButton";
import { FileExportType } from "generated/api";
import VenueContext from "pages/venueContext";
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";

const ExportBedroomsButton: FC = () => {
  const { t } = useTranslation();
  const { exportBedroomsQuery, setExportBedroomsDocumentType } = useContext(VenueContext);

  const items = [
    {
      label: t("Excel"),
      key: "excel",
      onClick: () => setExportBedroomsDocumentType(FileExportType.Xls),
    },
    {
      label: t("CSV"),
      key: "csv",
      onClick: () => setExportBedroomsDocumentType(FileExportType.Csv),
    },
  ];

  return <DropdownButton title="Export" items={items} isLoading={exportBedroomsQuery?.isFetching} />;
};

export default ExportBedroomsButton;
