import { Input, Select } from "components/styleguide";
import React from "react";

const { Option } = Select;
const { TextArea } = Input;

const getFormFields = ({
  t,
  streams,
  seminarTypes,
  validateCodeFormat,
  validateUniqueCode,
  languages,
  onSeminarTypeSelect,
  seminar
}) => [
    {
      title: "Display Code",
      dataIndex: "code",
      rules: [
        { required: true, message: t("errors.required", { prop: "$t(Code)" }) },
        {
          max: 6,
          message: t("errors.max.seminar_code", { count: 6 }),
        },
        {
          validator: validateCodeFormat,
          message: t("Code can only have alphanumeric characters"),
        },
        {
          validator: validateUniqueCode,
          message: t("This Code already exists"),
        },
      ],
      component: <Input />,
    },
    {
      title: "Title",
      dataIndex: "title",
      rules: [{ required: true, message: t("errors.required", { prop: "$t(Title)" }) }],
      component: <Input maxLength={200} />,
    },
    {
      title: "Stream Name",
      dataIndex: "forumStreamId",
      rules: [
        {
          required: true,
          message: t("errors.required", { prop: "$t(Stream Name)" }),
        },
      ],
      component: (
        <Select>
          {streams?.map((s) => (
            <Option key={s?.id} value={s?.id}>
              {s?.type}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Seminar Type Name",
      dataIndex: "forumSeminarTypeId",
      rules: [
        {
          required: true,
          message: t("errors.required", { prop: "$t(Stream Type Name)" }),
        },
      ],
      component: (
        <Select onSelect={onSeminarTypeSelect}>
          {seminarTypes?.map((s) => (
            <Option key={s?.id} value={s?.id}>
              {s?.type}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Languages",
      dataIndex: "languages",
      initialValue: (seminar || []).languages?.map(({ languageId }) => languageId),
      component: (
        <Select
          mode={"multiple"}
          options={
            languages.map(({ id, name }) => ({
              value: id,
              label: name,
            }))
          }
        />
      ),
    },
    {
      title: "Short Description",
      dataIndex: "shortDescription",
      component: <TextArea maxLength={200} autoSize={{ minRows: 1, maxRows: 5 }} />,
    },
    {
      title: "Description",
      dataIndex: "description",
      component: <TextArea maxLength={1000} autoSize={{ minRows: 2, maxRows: 5 }} />,
    },
  ];

export { getFormFields };
