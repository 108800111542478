/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumSeminarSessionZoomWebinarModel } from '../apiModels';
// @ts-ignore
import { ForumSeminarSessionZoomWebinarRequest } from '../apiModels';
// @ts-ignore
import { ForumTableZoomAccountModel } from '../apiModels';
// @ts-ignore
import { ForumTableZoomAccountRequest } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ZoomAccountModel } from '../apiModels';
// @ts-ignore
import { ZoomWebinarModel } from '../apiModels';
/**
 * ZoomMappingsApi - axios parameter creator
 * @export
 */
export const ZoomMappingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns all zoom accounts for a forum sorted by account name
         * @param {number} forumId 
         * @param {boolean} [notMapped] true: return only not mapped accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomAccountsGet: async (forumId: number, notMapped?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomAccountsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/zoom/accounts`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (notMapped !== undefined) {
                localVarQueryParameter['notMapped'] = notMapped;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumSeminarSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete: async (forumId: number, forumSeminarSessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete', 'forumId', forumId)
            // verify required parameter 'forumSeminarSessionId' is not null or undefined
            assertParamExists('forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete', 'forumSeminarSessionId', forumSeminarSessionId)
            const localVarPath = `/forums/{forumId}/zoom/session-mapping/{forumSeminarSessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarSessionId"}}`, encodeURIComponent(String(forumSeminarSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets mapping for Seminar session.  Validates if the webinar isn\'t already mapped to another seminar session with conflicted time.
         * @param {number} forumId 
         * @param {number} forumSeminarSessionId 
         * @param {ForumSeminarSessionZoomWebinarRequest} [forumSeminarSessionZoomWebinarRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomSessionMappingForumSeminarSessionIdPut: async (forumId: number, forumSeminarSessionId: number, forumSeminarSessionZoomWebinarRequest?: ForumSeminarSessionZoomWebinarRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomSessionMappingForumSeminarSessionIdPut', 'forumId', forumId)
            // verify required parameter 'forumSeminarSessionId' is not null or undefined
            assertParamExists('forumsForumIdZoomSessionMappingForumSeminarSessionIdPut', 'forumSeminarSessionId', forumSeminarSessionId)
            const localVarPath = `/forums/{forumId}/zoom/session-mapping/{forumSeminarSessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarSessionId"}}`, encodeURIComponent(String(forumSeminarSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarSessionZoomWebinarRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of mappings between seminar sessions and zoom webinars
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomSessionMappingsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomSessionMappingsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/zoom/session-mappings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Map Zoom account for tables.  Validates if the account isn\'t already mapped to another table.
         * @param {number} forumId 
         * @param {number} forumTableId 
         * @param {ForumTableZoomAccountRequest} [forumTableZoomAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomTableMappingForumTableIdPut: async (forumId: number, forumTableId: number, forumTableZoomAccountRequest?: ForumTableZoomAccountRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomTableMappingForumTableIdPut', 'forumId', forumId)
            // verify required parameter 'forumTableId' is not null or undefined
            assertParamExists('forumsForumIdZoomTableMappingForumTableIdPut', 'forumTableId', forumTableId)
            const localVarPath = `/forums/{forumId}/zoom/table-mapping/{forumTableId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumTableId"}}`, encodeURIComponent(String(forumTableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumTableZoomAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns all tables for a forum with their zoom account mappings  Ordered by facility code and table number
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomTableMappingsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomTableMappingsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/zoom/table-mappings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of webinars sorted by start time
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomWebinarsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdZoomWebinarsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/zoom/webinars`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZoomMappingsApi - functional programming interface
 * @export
 */
export const ZoomMappingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZoomMappingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns all zoom accounts for a forum sorted by account name
         * @param {number} forumId 
         * @param {boolean} [notMapped] true: return only not mapped accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomAccountsGet(forumId: number, notMapped?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoomAccountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomAccountsGet(forumId, notMapped, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumSeminarSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete(forumId: number, forumSeminarSessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete(forumId, forumSeminarSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets mapping for Seminar session.  Validates if the webinar isn\'t already mapped to another seminar session with conflicted time.
         * @param {number} forumId 
         * @param {number} forumSeminarSessionId 
         * @param {ForumSeminarSessionZoomWebinarRequest} [forumSeminarSessionZoomWebinarRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomSessionMappingForumSeminarSessionIdPut(forumId: number, forumSeminarSessionId: number, forumSeminarSessionZoomWebinarRequest?: ForumSeminarSessionZoomWebinarRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomSessionMappingForumSeminarSessionIdPut(forumId, forumSeminarSessionId, forumSeminarSessionZoomWebinarRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of mappings between seminar sessions and zoom webinars
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomSessionMappingsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumSeminarSessionZoomWebinarModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomSessionMappingsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Map Zoom account for tables.  Validates if the account isn\'t already mapped to another table.
         * @param {number} forumId 
         * @param {number} forumTableId 
         * @param {ForumTableZoomAccountRequest} [forumTableZoomAccountRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomTableMappingForumTableIdPut(forumId: number, forumTableId: number, forumTableZoomAccountRequest?: ForumTableZoomAccountRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomTableMappingForumTableIdPut(forumId, forumTableId, forumTableZoomAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns all tables for a forum with their zoom account mappings  Ordered by facility code and table number
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomTableMappingsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumTableZoomAccountModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomTableMappingsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of webinars sorted by start time
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdZoomWebinarsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ZoomWebinarModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdZoomWebinarsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZoomMappingsApi - factory interface
 * @export
 */
export const ZoomMappingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZoomMappingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns all zoom accounts for a forum sorted by account name
         * @param {ZoomMappingsApiForumsForumIdZoomAccountsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomAccountsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomAccountsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ZoomAccountModel>> {
            return localVarFp.forumsForumIdZoomAccountsGet(requestParameters.forumId, requestParameters.notMapped, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete(requestParameters: ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete(requestParameters.forumId, requestParameters.forumSeminarSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets mapping for Seminar session.  Validates if the webinar isn\'t already mapped to another seminar session with conflicted time.
         * @param {ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomSessionMappingForumSeminarSessionIdPut(requestParameters: ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdZoomSessionMappingForumSeminarSessionIdPut(requestParameters.forumId, requestParameters.forumSeminarSessionId, requestParameters.forumSeminarSessionZoomWebinarRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of mappings between seminar sessions and zoom webinars
         * @param {ZoomMappingsApiForumsForumIdZoomSessionMappingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomSessionMappingsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomSessionMappingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumSeminarSessionZoomWebinarModel>> {
            return localVarFp.forumsForumIdZoomSessionMappingsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Map Zoom account for tables.  Validates if the account isn\'t already mapped to another table.
         * @param {ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomTableMappingForumTableIdPut(requestParameters: ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdZoomTableMappingForumTableIdPut(requestParameters.forumId, requestParameters.forumTableId, requestParameters.forumTableZoomAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns all tables for a forum with their zoom account mappings  Ordered by facility code and table number
         * @param {ZoomMappingsApiForumsForumIdZoomTableMappingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomTableMappingsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomTableMappingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumTableZoomAccountModel>> {
            return localVarFp.forumsForumIdZoomTableMappingsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of webinars sorted by start time
         * @param {ZoomMappingsApiForumsForumIdZoomWebinarsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdZoomWebinarsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomWebinarsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ZoomWebinarModel>> {
            return localVarFp.forumsForumIdZoomWebinarsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdZoomAccountsGet operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomAccountsGetRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomAccountsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomAccountsGet
     */
    readonly forumId: number

    /**
     * true: return only not mapped accounts
     * @type {boolean}
     * @memberof ZoomMappingsApiForumsForumIdZoomAccountsGet
     */
    readonly notMapped?: boolean
}

/**
 * Request parameters for forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDeleteRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDelete
     */
    readonly forumSeminarSessionId: number
}

/**
 * Request parameters for forumsForumIdZoomSessionMappingForumSeminarSessionIdPut operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPutRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPut
     */
    readonly forumSeminarSessionId: number

    /**
     * 
     * @type {ForumSeminarSessionZoomWebinarRequest}
     * @memberof ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPut
     */
    readonly forumSeminarSessionZoomWebinarRequest?: ForumSeminarSessionZoomWebinarRequest
}

/**
 * Request parameters for forumsForumIdZoomSessionMappingsGet operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomSessionMappingsGetRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomSessionMappingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomSessionMappingsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdZoomTableMappingForumTableIdPut operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPutRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPut
     */
    readonly forumTableId: number

    /**
     * 
     * @type {ForumTableZoomAccountRequest}
     * @memberof ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPut
     */
    readonly forumTableZoomAccountRequest?: ForumTableZoomAccountRequest
}

/**
 * Request parameters for forumsForumIdZoomTableMappingsGet operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomTableMappingsGetRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomTableMappingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomTableMappingsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdZoomWebinarsGet operation in ZoomMappingsApi.
 * @export
 * @interface ZoomMappingsApiForumsForumIdZoomWebinarsGetRequest
 */
export interface ZoomMappingsApiForumsForumIdZoomWebinarsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ZoomMappingsApiForumsForumIdZoomWebinarsGet
     */
    readonly forumId: number
}

/**
 * ZoomMappingsApi - object-oriented interface
 * @export
 * @class ZoomMappingsApi
 * @extends {BaseAPI}
 */
export class ZoomMappingsApi extends BaseAPI {
    /**
     * 
     * @summary Returns all zoom accounts for a forum sorted by account name
     * @param {ZoomMappingsApiForumsForumIdZoomAccountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomAccountsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomAccountsGetRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomAccountsGet(requestParameters.forumId, requestParameters.notMapped, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete(requestParameters: ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdDeleteRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete(requestParameters.forumId, requestParameters.forumSeminarSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets mapping for Seminar session.  Validates if the webinar isn\'t already mapped to another seminar session with conflicted time.
     * @param {ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomSessionMappingForumSeminarSessionIdPut(requestParameters: ZoomMappingsApiForumsForumIdZoomSessionMappingForumSeminarSessionIdPutRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomSessionMappingForumSeminarSessionIdPut(requestParameters.forumId, requestParameters.forumSeminarSessionId, requestParameters.forumSeminarSessionZoomWebinarRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of mappings between seminar sessions and zoom webinars
     * @param {ZoomMappingsApiForumsForumIdZoomSessionMappingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomSessionMappingsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomSessionMappingsGetRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomSessionMappingsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Map Zoom account for tables.  Validates if the account isn\'t already mapped to another table.
     * @param {ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomTableMappingForumTableIdPut(requestParameters: ZoomMappingsApiForumsForumIdZoomTableMappingForumTableIdPutRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomTableMappingForumTableIdPut(requestParameters.forumId, requestParameters.forumTableId, requestParameters.forumTableZoomAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns all tables for a forum with their zoom account mappings  Ordered by facility code and table number
     * @param {ZoomMappingsApiForumsForumIdZoomTableMappingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomTableMappingsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomTableMappingsGetRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomTableMappingsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of webinars sorted by start time
     * @param {ZoomMappingsApiForumsForumIdZoomWebinarsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomMappingsApi
     */
    public forumsForumIdZoomWebinarsGet(requestParameters: ZoomMappingsApiForumsForumIdZoomWebinarsGetRequest, options?: AxiosRequestConfig) {
        return ZoomMappingsApiFp(this.configuration).forumsForumIdZoomWebinarsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }
}

