import { useUsersQuery } from "api/queries/user";
import { Button, DatePicker, Drawer, Form, Select, Spin } from "components/styleguide";
import { ForumITContractItemRequestModel, ForumResponseModel } from "generated/api";
import moment, { Moment } from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDisabledDates } from "utils/dateUtils";

import FullHeightForm from "../../../components/FullHeightForm";

const { Option } = Select;

interface EditDrawerProps {
  isOpen: boolean;
  isUpdateLoading: boolean;
  onClose: () => void;
  onFinish: (values: ForumITContractItemRequestModel) => void;
  contractToEdit: ForumITContractItemRequestModel;
  forum: ForumResponseModel;
}
const EditDrawer = ({ isOpen, isUpdateLoading, onClose, onFinish, contractToEdit, forum }: EditDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { data: { data: users = [] } = {}, isLoading: isUsersLoading } = useUsersQuery({});

  return (
    <Drawer
      className="it-contract-edit-drawer"
      title={"Edit IT Contract"}
      placement="right"
      onClose={onClose}
      open={isOpen}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      {isUsersLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <FullHeightForm
          preserve={false}
          id="itContractsForm"
          name="it-contracts-form"
          labelCol={{ span: 4 }}
          form={form}
          actionsPrepend={
            <Button
              onClick={() => {
                onClose();
              }}
            >
              {t("cancel")}
            </Button>
          }
          actionsAppend={
            <Button type="primary" htmlType="submit" loading={isUpdateLoading}>
              {t("Update")}
            </Button>
          }
          onFinish={onFinish}
        >
          <Form.Item label={t("Responsible")} name="responsibleUserId" initialValue={contractToEdit?.responsibleUserId}>
            <Select showSearch optionFilterProp="children">
              {users?.map(({ name, id }) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t("Due Date")}
            name="dueDate"
            initialValue={moment(contractToEdit?.dueDate ?? forum?.startDate)}
          >
            <DatePicker
              style={{ width: "100%" }}
              format={"L"}
              disabledDate={(value: Moment) => getDisabledDates(value, forum)}
            />
          </Form.Item>
        </FullHeightForm>
      )}
    </Drawer>
  );
};

export default EditDrawer;
