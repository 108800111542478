import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import { Button, Table } from "components/styleguide";
import { FacilityResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, TableColumn } from "utils/tableColumnUtils";

import LinkToReport from "../../components/LinkToReport";

interface FacilitiesTableOptionProps {
  onFacilitySelect?: (facility: FacilityResponseModel) => void;
}

const FacilitiesTableOption = ({ onFacilitySelect }: FacilitiesTableOptionProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [codeSearch, setCodeSearch] = useState<string>();
  const [nameSearch, setNameSearch] = useState<string>();

  const { response: { isLoading, data: { data: facilities = [] } = {} } = {}, pagination } =
    useModernQueryWithPaginationAndOrder(
      useForumVenueFacilitiesQuery,
      {},
      {
        forumId: toNumber(id),
        code: codeSearch,
        name: nameSearch,
      },
      { defaultPageSize: 10, pageSizeOptions: [10, 50, 100], showSizeChange: false },
    );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "code": {
        setCodeSearch(selectedKeys[0]);
        break;
      }
      case "name": {
        setNameSearch(selectedKeys[0]);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "code": {
        setCodeSearch(undefined);
        break;
      }
      case "name": {
        setNameSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = [
    new TableColumn(t("Facility Code"), "code", {
      width: "30%",
      ...getColumnSearchProps("code", handleSearch, handleReset, "code", null),
    }),
    new TableColumn(t("Facility Description"), "name", {
      ...getColumnSearchProps("name", handleSearch, handleReset, "name", null),
    }),
    new TableColumn("", "id", {
      width: "10%",
      render: (facilityId: number, facility: FacilityResponseModel) =>
        onFacilitySelect ? (
          <Button onClick={() => onFacilitySelect(facility)}>{t("Select").toString()}</Button>
        ) : (
          <LinkToReport parameters={{ ForumFacilityId: facilityId }} />
        ),
    }),
  ];

  return <Table columns={columns} dataSource={facilities} loading={isLoading} rowKey="id" pagination={pagination} />;
};

export default FacilitiesTableOption;
