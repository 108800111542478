import { ForumStreamsApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumStreamsApi);

const ForumStreams = {
  getAll: api.forumForumIdStreamsGet,
  post: api.forumForumIdStreamsPost,
  put: api.forumForumIdStreamsStreamIdPut,
  delete: api.forumForumIdStreamsStreamIdDelete,
  reoder: {
    put: api.forumForumIdStreamsStreamIdReorderPut,
  },
};

export default ForumStreams;
