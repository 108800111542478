import PropTypes from "prop-types";
import React, { useState } from "react";

import { useUpdateQuestionnaireMutation } from "../../../../../../../api/mutations/questionnaries";
import { useQuestionnaireQuery } from "../../../../../../../api/queries/questionnaires";
import QuestionnaireForm from "../../../../../components/QuestionnaireForm";
import QuestionnaireDetails from "./QuestionnaireDetails";

const DetailsPanelContent = ({ forumId, questionnaireId, isReadonly, onSuccessUpdate, onCancel }) => {
  const {
    data: { data: questionnaire = {} } = {},
    isLoading,
    refetch,
  } = useQuestionnaireQuery({
    forumId,
    questionnaireId,
  });
  const [error, setError] = useState(null);

  const updateMutation = useUpdateQuestionnaireMutation({
    onSuccess: () => {
      setError(null);
      refetch();
      onSuccessUpdate();
    },
    onError: ({ response }) => {
      if (response.status === 409) {
        setError("Cannot update questionnaire because status is open");
      }
    },
  });

  const handleFormSubmit = ({ name, availableFrom, availableTo, type, forumGroupIds, status }) => {
    updateMutation.mutate({
      forumId,
      questionnaireId,
      name,
      availableFrom,
      availableTo,
      type,
      forumGroupIds,
      status,
    });
  };

  if (isReadonly) {
    return <QuestionnaireDetails questionnaire={questionnaire} />;
  }

  return (
    <QuestionnaireForm
      forumId={forumId}
      initialValues={questionnaire}
      labelCol={{ span: 24, lg: 24, xl: 13, xxl: 6 }}
      error={error}
      isLoading={isLoading}
      showCancel
      onCancel={onCancel}
      onFinish={handleFormSubmit}
    />
  );
};

DetailsPanelContent.propTypes = {
  forumId: PropTypes.number.isRequired,
  questionnaireId: PropTypes.number.isRequired,
  isReadonly: PropTypes.bool,
  onSuccessUpdate: PropTypes.func,
  onCancel: PropTypes.func,
};

DetailsPanelContent.defaultProps = {
  isReadonly: false,
  onSuccessUpdate: () => undefined,
  onCancel: () => undefined,
};

export default DetailsPanelContent;
