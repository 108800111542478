import { useMutation } from "@tanstack/react-query";
import api from "api";
import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useCreateSelectionSiteMutation = (options = {}) => {
  return useMutation(({ forumId, name, description, availableFrom, availableTo, groupId, colorScheme, status }) => {
    return api.post(`/forum/${forumId}/selection-sites`, {
      name,
      description,
      availableFrom,
      availableTo,
      groupId,
      colorScheme,
      status,
    });
  }, options);
};

export const useUpdateSelectionSiteMutation = (options = {}) => {
  return useMutation(
    ({ forumId, selectionSiteId, name, description, availableFrom, availableTo, groupId, colorScheme, status }) => {
      return api.put(`/forum/${forumId}/selection-sites/${selectionSiteId}`, {
        name,
        description,
        availableFrom,
        availableTo,
        groupId,
        colorScheme,
        status,
      });
    },
    options,
  );
};

export const useAddSelectionSitePageMutation = (options = {}) => {
  return useMutation(({ forumId, selectionSiteId }) => {
    return api.post(`/forum/${forumId}/selection-sites/${selectionSiteId}/content/pages`);
  }, options);
};

export const useAddItemToSelectionSitePageMutation = (options = {}) => {
  return useMutation(({ forumId, selectionSiteId, pageId, item }) => {
    return api.post(`/forum/${forumId}/selection-sites/${selectionSiteId}/content/pages/${pageId}/items`, { item });
  }, options);
};

export const useDeleteSelectionSitePageMutation = (options = {}) => {
  return useMutation(({ forumId, selectionSiteId, pageId }) => {
    return api.delete(`/forum/${forumId}/selection-sites/${selectionSiteId}/content/pages/${pageId}`);
  }, options);
};

export const useDeleteSelectionSiteItemMutation = getWrappedUseMutation(
  apiClient.ForumSelectionSite.content.pages.items.delete,
);

export const useUpdateSelectionSiteItemMutation = (options = {}) => {
  return useMutation(({ forumId, selectionSiteId, pageId, itemId, item }) => {
    return api.put(`/forum/${forumId}/selection-sites/${selectionSiteId}/content/pages/${pageId}/items/${itemId}`, {
      item,
    });
  }, options);
};

const useGetSelectionSiteItemMutation = getWrappedUseMutation(apiClient.ForumSelectionSite.get);

const useUpdateSelectionSitePageOrderMutation = getWrappedUseMutation(
  apiClient.ForumSelectionSite.content.pages.order.put,
);

const useUpdateSelectionSitePageItemOrderMutation = getWrappedUseMutation(
  apiClient.ForumSelectionSite.content.pages.items.order.put,
);

const useUpdateSelectionSiteStatus = getWrappedUseMutation(apiClient.ForumSelectionSite.status.put);

const useDeleteSelectionSite = getWrappedUseMutation(apiClient.ForumSelectionSite.delete);

export {
  useDeleteSelectionSite,
  useGetSelectionSiteItemMutation,
  useUpdateSelectionSitePageItemOrderMutation,
  useUpdateSelectionSitePageOrderMutation,
  useUpdateSelectionSiteStatus,
};
