import { useRef, useState } from "react";
import OpenKeysSet from "../utils/openKeysSet";

const useOpenKeys = (defaultValue) => {
  const setRef = useRef(new OpenKeysSet(defaultValue));
  const [list, setList] = useState(defaultValue);

  const set = (array) => {
    setRef.current.reset(array);
    setList(array);
  };

  const remove = (key) => {
    setRef.current.delete(key);
    setList(setRef.current.values);
  };

  const add = (key) => {
    setRef.current.add(key);
    setList(setRef.current.values);
  };

  const replace = (from, to) => {
    setRef.current.replace(from, to);
    setList(setRef.current.values);
  };

  return {
    value: list,
    set,
    remove,
    add,
    replace,
  };
};

export default useOpenKeys;
