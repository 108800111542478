import { useQueryClient } from "@tanstack/react-query";
import { ColProps } from "antd";
import { useUpdateDietaryRequirementsMutation } from "api/mutations/attendees";
import { useAttendeeDietaryRequirementsQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Col, Form, Row, Select, Spin } from "components/styleguide";
import TagRender from "components/TagRender";
import { toNumber } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.less";

interface AttendeeDietaryRequirementsProps {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  isLoading: boolean;
}

const AttendeeDietaryRequirements = ({ isEditing, setIsEditing, isLoading }: AttendeeDietaryRequirementsProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: forumId, attendeeId } = useParams();
  const queryClient = useQueryClient();

  const { isLoading: isDietaryRequirementsLoading, data: { data: { dietaryRequirements = "" } = {} } = {} } =
    useAttendeeDietaryRequirementsQuery({ forumId: toNumber(forumId), attendeeId: toNumber(attendeeId) });

  const updateDietaryRequirementsMutation = useUpdateDietaryRequirementsMutation();

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const submit = (values: { dietaryRequirements: string[] }) => {
    updateDietaryRequirementsMutation.mutate(
      {
        forumId: toNumber(forumId),
        attendeeId: toNumber(attendeeId),
        attendeeDietaryRequirementsModel: { dietaryRequirements: values.dietaryRequirements?.join(", ") },
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_ATTENDEE_DIETARY_REQUIREMENTS]);
          setIsEditing(false);
        },
      },
    );
  };

  if (isLoading || isDietaryRequirementsLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Form
        form={form}
        onFinish={submit}
        id="dietaryRequirementsForm"
        name="dietary-requirements"
        labelAlign="left"
        labelCol={{ justify: "left", span: 5 } as ColProps}
        colon={false}
      >
        <Form.Item
          initialValue={dietaryRequirements?.split(", ").map((item) => ({
            value: item,
            label: item,
          }))}
          label={t("Dietary Requirements")}
          name="dietaryRequirements"
          className={styles.formItem}
        >
          {isEditing ? (
            <Select
              mode="tags"
              open={false}
              tagRender={(props) => <TagRender {...props} />}
              style={{ width: "100%" }}
            />
          ) : (
            <label>{dietaryRequirements || "-"}</label>
          )}
        </Form.Item>

        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={false} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}> {t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default AttendeeDietaryRequirements;
