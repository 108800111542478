import { useForumDocumentDefinitionSectionQuery } from "api/queries/forumDocumentDefinition";
import { Drawer, Form, Spin, Tabs } from "components/styleguide";
import { ForumDocumentDefinitionSectionResponseModel } from "generated/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ContentEntries from "./ContentEntries";
import CustomElements from "./CustomElements";
import SectionOptions from "./SelectionOptions";
import { ReactQuillType } from "./SelectionOptions/TextEditorOption";
import Watermark from "./Watermark";

interface EditDocumentDefinitionDrawerProps {
  forumId: number;
  documentDefinitionSectionId: number;
  isVisible: boolean;
  onClose: () => void;
}

const EditDocumentDefinitionDrawer = ({
  forumId,
  documentDefinitionSectionId,
  isVisible,
  onClose,
}: EditDocumentDefinitionDrawerProps) => {
  const { t } = useTranslation();

  const [sectionForm] = Form.useForm(); // Same form instance is needed in two tabs to share the form values
  const [sectionOptionsForm] = Form.useForm(); // Used for Section Options form and retrieving form values
  const [quills, setQuills] = useState<Array<ReactQuillType>>([]);
  const [section, setSection] = useState<ForumDocumentDefinitionSectionResponseModel | undefined>();

  const { isLoading } = useForumDocumentDefinitionSectionQuery(
    {
      forumId,
      documentDefinitionSectionId,
    },
    {
      onSuccess: ({ data }: { data: ForumDocumentDefinitionSectionResponseModel }) => {
        const responseModel = data;
        responseModel.elements.sort((a, b) => a.order - b.order);
        setSection(responseModel);
      },
    },
  );

  const commonItemProps = {
    documentDefinitionSection: section,
    documentDefinitionSectionId,
    forumId,
    onClose,
    sectionForm,
    sectionOptionsForm,
    quills,
    setQuills,
  };

  const items = section
    ? [
        {
          label: t("Content Entries"),
          key: "contentEntries",
          children: <ContentEntries {...commonItemProps} />,
        },
        {
          label: t("Custom Elements"),
          key: "customElements",
          children: <CustomElements {...commonItemProps} setSection={setSection} />,
        },
        {
          label: t("Section Options"),
          key: "sectionOptions",
          children: <SectionOptions {...commonItemProps} />,
        },

        {
          label: t("Watermark"),
          key: "watermark",
          children: <Watermark {...commonItemProps} />,
        },
      ].filter((x) =>
        section.sectionType === "StandardSection" ? x.key !== "customElements" : x.key !== "sectionOptions",
      )
    : [];

  return (
    <Drawer
      title={section?.contentsEntryTitle || section?.sectionName}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose
    >
      {!isLoading ? (
        <Tabs defaultActiveKey="contentEntries" items={items} />
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )}
    </Drawer>
  );
};

export default EditDocumentDefinitionDrawer;
