import { Button, Space, Tabs } from "components/styleguide";
import {
  BlockedMeetingPreferenceResponseModel,
  MeetingPreferenceResponseModel,
  SeminarPreferenceResponseModel,
} from "generated/api";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import AddOrEditConferenceDrawer from "./AddOrEditConferenceDrawer";
import AddOrEditMeetingDrawer from "./AddOrEditMeetingDrawer";
import AttendeePreferencesBlockedMeetingsTable from "./AttendeePreferencesBlockedMeetingsTable";
import AttendeePreferencesCategory from "./AttendeePreferencesCategory";
import AttendeePreferencesConferenceTable from "./AttendeePreferencesConferenceTable";
import AttendeePreferencesMeetingsTable from "./AttendeePreferencesMeetingsTable";
import AttendeePreferencesSelectionOptions from "./AttendeePreferencesSelectionOptions";
import EditBlockedMeetingDrawer from "./EditBlockedMeetingDrawer";

interface AttendeePreferencesPanelProps {
  forumId: number;
  attendeeId: number;
}

const AttendeePreferencesPanel: FC<AttendeePreferencesPanelProps> = ({ forumId, attendeeId }) => {
  const { t } = useTranslation();

  const [isAddOrEditSeminarDrawerVisible, setIsAddOrEditSeminarDrawerVisible] = useState<boolean>(false);
  const [isAddOrEditMeetingDrawerVisible, setIsAddOrEditMeetingDrawerVisible] = useState<boolean>(false);
  const [isEditBlockedMeetingDrawerVisible, setIsEditBlockedMeetingDrawerVisible] = useState<boolean>(false);
  const [activeSeminar, setActiveSeminar] = useState<SeminarPreferenceResponseModel | null>(null);
  const [activeMeeting, setActiveMeeting] = useState<MeetingPreferenceResponseModel | null>(null);
  const [activeBlockedMeeting, setActiveBlockedMeeting] = useState<BlockedMeetingPreferenceResponseModel | null>(null);

  const onCloseAddOrEditSeminarDrawer = () => {
    setActiveSeminar(null);
    setIsAddOrEditSeminarDrawerVisible(false);
  };

  const onCloseAddOrEditMeetingDrawer = () => {
    setActiveMeeting(null);
    setIsAddOrEditMeetingDrawerVisible(false);
  };

  const onCloseEditBlockedMeetingDrawer = () => {
    setActiveBlockedMeeting(null);
    setIsEditBlockedMeetingDrawerVisible(false);
  };

  const onEditSeminar = (seminar: SeminarPreferenceResponseModel) => {
    setActiveSeminar(seminar);
    setIsAddOrEditSeminarDrawerVisible(true);
  };

  const onEditMeetingDrawer = (meeting: MeetingPreferenceResponseModel) => {
    setActiveMeeting(meeting);
    setIsAddOrEditMeetingDrawerVisible(true);
  };

  const onEditBlockedMeeting = (blockedMeeting: BlockedMeetingPreferenceResponseModel) => {
    setActiveBlockedMeeting(blockedMeeting);
    setIsEditBlockedMeetingDrawerVisible(true);
  };

  return (
    <>
      <Tabs defaultActiveKey="conference" className="" hideNav={false}>
        <Tabs.TabPane key="conference" tab={t("Conference")} forceRender>
          <Space direction={"vertical"} size={"middle"} fullWidth>
            <Button onClick={() => setIsAddOrEditSeminarDrawerVisible(true)}>{t("Add")}</Button>
            <AttendeePreferencesConferenceTable forumId={forumId} attendeeId={attendeeId} onEdit={onEditSeminar} />
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane key="meeting" tab={t("Meetings")} forceRender>
          <Space direction={"vertical"} size={"middle"} fullWidth>
            <Button onClick={() => setIsAddOrEditMeetingDrawerVisible(true)}>{t("Add")}</Button>
            <AttendeePreferencesMeetingsTable forumId={forumId} attendeeId={attendeeId} onEdit={onEditMeetingDrawer} />
          </Space>
        </Tabs.TabPane>
        <Tabs.TabPane key="category" tab={t("Category")} forceRender>
          <AttendeePreferencesCategory />
        </Tabs.TabPane>
        <Tabs.TabPane key="blockedMeetings" tab={t("Blocked Meetings")} forceRender>
          <AttendeePreferencesBlockedMeetingsTable
            forumId={forumId}
            attendeeId={attendeeId}
            onEdit={onEditBlockedMeeting}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="selectionOptions" tab={t("Selection Options")} forceRender>
          <AttendeePreferencesSelectionOptions />
        </Tabs.TabPane>
      </Tabs>
      <AddOrEditConferenceDrawer
        forumId={forumId}
        attendeeId={attendeeId}
        activeSeminar={activeSeminar}
        isVisible={isAddOrEditSeminarDrawerVisible}
        onClose={onCloseAddOrEditSeminarDrawer}
      />
      <AddOrEditMeetingDrawer
        forumId={forumId}
        attendeeId={attendeeId}
        activeMeeting={activeMeeting}
        isVisible={isAddOrEditMeetingDrawerVisible}
        onClose={onCloseAddOrEditMeetingDrawer}
      />
      <EditBlockedMeetingDrawer
        forumId={forumId}
        attendeeId={attendeeId}
        activeBlockedMeeting={activeBlockedMeeting}
        isVisible={isEditBlockedMeetingDrawerVisible}
        onClose={onCloseEditBlockedMeetingDrawer}
      />
    </>
  );
};

export default AttendeePreferencesPanel;
