import { useQueryClient } from "@tanstack/react-query";
import { Input } from "antd";
import { useUpdateForumCompanyAddressInformationMutation } from "api/mutations/forumCompanies";
import KEYS from "api/queries/keys";
import EditableField from "components/EditableField";
import { CompanyModel } from "generated/api";
import { toNumber } from "lodash";
import React, { CSSProperties, FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface CompanyNameProps {
  forumCompany: CompanyModel;
  onChange: (newName: string) => void;
  style?: CSSProperties;
}

const CompanyName: FC<CompanyNameProps> = ({ forumCompany, onChange, style }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { id, name, forumId } = forumCompany;

  const { mutate: updateAddressInfo, isLoading: isAddressInfoUpdating } =
    useUpdateForumCompanyAddressInformationMutation();

  const updateCompanyName = ({ companyName }: { companyName: string }, onCloseEditing: () => void) => {
    if (companyName === name) {
      onCloseEditing();
      return;
    }

    updateAddressInfo(
      {
        forumId: toNumber(forumId),
        forumCompanyId: id,
        companyAddressRequestModel: { ...forumCompany, name: companyName },
      },
      {
        onSuccess: () => {
          onChange(companyName);
          queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY]);
        },
        onError: () => toast.error(t("Something went wrong")),
        onSettled: onCloseEditing,
      },
    );
  };

  return (
    <EditableField
      name="companyName"
      initialValue={name}
      style={style}
      valueStyle={{ fontSize: "18px", fontWeight: "bold" }}
      isUpdating={isAddressInfoUpdating}
      onUpdate={updateCompanyName}
    >
      <Input maxLength={100} />
    </EditableField>
  );
};

export default CompanyName;
