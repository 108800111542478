import { ForumResponseModel } from "generated/api";
import { toNumber } from "lodash";
import moment, { Moment } from "moment";

export const hasTimezone = (dateString: string) => {
  if (dateString.at(-1) === "Z") {
    return true;
  }

  const temp = dateString.at(-6);
  return temp === "+" || temp === "-";
};

// Because backend returns us time without a timezone we have to add it explicitly to the code
export const addTimezoneIfNotExists = (dateString: string, timezone = "+00:00") => {
  if (moment.isMoment(dateString)) {
    return moment(dateString).toISOString();
  }

  if (typeof dateString !== "string") {
    return null;
  }

  // Finds if + or - exists in date string "2022-11-17T13:12:10+00:00"
  if (hasTimezone(dateString)) {
    return dateString;
  }

  return `${dateString}${timezone}`;
};

export const stringOffsetToNumber = (str: string) => {
  const isFirstPlusOrMinus = str[0] === "+" || str[0] === "-";

  const normalizedString = isFirstPlusOrMinus ? str : `+${str}`;
  const isMinusFirst = normalizedString[0] === "-";

  const [hours, minutes] = normalizedString
    .slice(1)
    .split(":")
    .map((n) => toNumber(n));

  const number = 60 * hours + minutes;
  if (number === 0) {
    return 0;
  }

  return number * (isMinusFirst ? -1 : 1);
};

export const numberOffsetToString = (number: number) => {
  const isMinus = number < 0;
  const absNumber = Math.abs(number);

  const hours = Math.floor(absNumber / 60);
  const minutes = absNumber % 60;

  const hoursString = hours < 10 ? `0${hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;

  return `${isMinus ? "-" : "+"}${hoursString}:${minutesString}`;
};

export const getUserUtcOffset = () => {
  return moment().utcOffset();
};

// Basically it fakes time offset from the forum's one to user locale one to show
// in date picker the same time as was set in forum
//
// ex: '10:00+01:00' to '10:00+02:00'
export const fakeTimeOffset = (dateString: string, forumOffset: number) => {
  const m = moment(addTimezoneIfNotExists(dateString, numberOffsetToString(forumOffset)));
  return m.utcOffset(forumOffset).utcOffset(getUserUtcOffset(), true).format("YYYY-MM-DDTHH:mm:ss");
};

export const getForumDisplayDateByStartAndEnd = (startDate: Moment, endDate: Moment) => {
  if (!startDate.isValid() || !endDate.isValid()) {
    return null;
  }

  if (!startDate.isSame(endDate, "year")) {
    return `${startDate.format("DD.MM.YY")}-${endDate.format("DD.MM.YY")}`;
  } else if (!startDate.isSame(endDate, "month")) {
    return `${startDate.format("DD.MM")}-${endDate.format("DD.MM.YY")}`;
  } else {
    return `${startDate.format("DD")}-${endDate.format("DD.MM.YY")}`;
  }
};

export const getForumDisplayDateByDuration = (duration: Moment[]) => {
  if (duration && duration.length > 1) {
    const start = duration[0];
    const end = duration[1];

    if (!start.isSame(end, "year")) {
      return `${start.format("DD.MM.YY")}-${end.format("DD.MM.YY")}`;
    } else if (!start.isSame(end, "month")) {
      return `${start.format("DD.MM")}-${end.format("DD.MM.YY")}`;
    } else {
      return `${start.format("DD")}-${end.format("DD.MM.YY")}`;
    }
  }

  return null;
};

export const getDisabledDates = (value: Moment, forum: ForumResponseModel) => {
  return moment(value).utc() < moment(forum?.startDate).utc() || moment(value).utc() > moment(forum?.endDate).utc();
};
