import Page403 from "pages/403";
import React, { useEffect, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { useRole } from "utils/userRole";
import { UserRoles } from "utils/userRole/types";

const RoleGuard = ({
  allowIf,
  children,
  redirect = false,
}: {
  allowIf: (roles: UserRoles) => boolean;
  redirect?: boolean;
  children: JSX.Element;
}) => {
  const { is } = useRole();
  const { lang } = useParams();

  const [showPage403, setShow] = React.useState(false);

  const haveAccess = useMemo(() => {
    return is(allowIf);
  }, [is, allowIf]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1500);

    return () => clearTimeout(timeout);
  }, [showPage403]);

  if (haveAccess) {
    return children;
  }

  if (redirect) {
    const link = lang ? `/${lang}/403` : "/403";
    return <Navigate to={link} />;
  }

  if (!showPage403) {
    return <div />;
  }

  return <Page403 />;
};

export default RoleGuard;
