import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumAttendeeMeetingLocationsMutation } from "api/mutations/attendeeMeetingLocations";
import KEYS from "api/queries/keys";
import { Tabs } from "components/styleguide";
import { AttendeeMeetingLocationTimeSlotTypeModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import AdvancedSetup from "./AdvancedSetup";
import AttendeeMeetingLocationsQuickSetup from "./QuickSetup";

interface AttendeeMeetingLocationsPanelProps {
  forumId: number;
  attendeeId: number;
}

const AttendeeMeetingLocationsPanel: FC<AttendeeMeetingLocationsPanelProps> = ({ forumId, attendeeId }) => {
  const { t } = useTranslation();

  const updateForumAttendeeMeetingLocationsMutation = useUpdateForumAttendeeMeetingLocationsMutation();
  const queryClient = useQueryClient();

  const handleQuickSetupFormSubmit = (newLocations: Array<AttendeeMeetingLocationTimeSlotTypeModel>) => {
    updateForumAttendeeMeetingLocationsMutation.mutate(
      { forumId, attendeeId, attendeeMeetingLocationsRequestModel: { meetingLocations: newLocations } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS]);
        },
      },
    );
  };

  return (
    <Tabs defaultActiveKey="quickSetup">
      <Tabs.TabPane tab={t("Quick Setup")} key="quickSetup">
        <AttendeeMeetingLocationsQuickSetup
          forumId={forumId}
          attendeeId={attendeeId}
          onSubmit={handleQuickSetupFormSubmit}
          isSubmitting={updateForumAttendeeMeetingLocationsMutation.isLoading}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={t("Advanced Setup")} key="advancedSetup">
        <AdvancedSetup
          forumId={forumId}
          attendeeId={attendeeId}
          isQuickSetupLoading={updateForumAttendeeMeetingLocationsMutation.isLoading}
        />
      </Tabs.TabPane>
    </Tabs>
  );
};

export default AttendeeMeetingLocationsPanel;
