import { Avatar, Button, Form, Select, Space } from "components/styleguide";
import { ForumGroupResponseModel } from "generated/api";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getMapBy } from "utils/mapUtils";

import styles from "./styles.module.less";

type Group = Pick<ForumGroupResponseModel, "id" | "name" | "color" | "imageText" | "longText">;

interface AddProps {
  groups: Array<Group>;
  exclude?: Array<Group["id"]>;
  onAdd: (value: Group["id"]) => void;
}

const Add: FC<AddProps> = ({ groups, exclude, onAdd }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [lastGroupId, setLastGroupId] = useState<Group["id"]>();

  const groupsMap = useMemo(() => getMapBy(groups, "id"), [groups]);
  const filteredGroups = useMemo<Array<Group>>(() => {
    const map = new Map<Group["id"], Group>(groupsMap);

    exclude?.forEach((id) => {
      map.delete(id);
    });

    return [...map.values()];
  }, [exclude, groupsMap]);

  const lastGroup = useMemo(() => {
    if (!lastGroupId) {
      return undefined;
    }

    return groupsMap.get(lastGroupId);
  }, [groupsMap, lastGroupId]);

  const handleFormSubmit = (values: { groupId: Group["id"] }) => {
    onAdd(values.groupId);
    form.resetFields();
    setLastGroupId(undefined);
  };

  return (
    <Form className={styles.table} form={form} onFinish={handleFormSubmit}>
      <table className={styles.table}>
        <thead>
          <tr>
            <td style={{ width: 300 }}>{t("Group Name")}</td>
            <td style={{ width: 300 }}>{t("Colour")}</td>
            <td style={{ width: 300 }}>{t("Image text")}</td>
            <td style={{ width: "100%" }}>{t("Long text")}</td>
            <td style={{ width: 200 }}></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Item name="groupId" rules={[{ required: true, message: t("Please, select a group") }]}>
                <Select
                  onChange={(groupId) => {
                    setLastGroupId(groupId);
                  }}
                >
                  {filteredGroups.map((group) => (
                    <Select.Option key={group.id} value={group.id}>
                      {group.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </td>
            {lastGroup ? (
              <>
                <td>
                  {lastGroup.color ? (
                    <Space>
                      <Avatar size={20} style={{ backgroundColor: lastGroup.color }} />
                      {lastGroup.color}
                    </Space>
                  ) : (
                    "-"
                  )}
                </td>
                <td>{lastGroup.imageText ?? "-"}</td>
                <td>{lastGroup.longText ?? "-"}</td>
              </>
            ) : (
              <>
                <td></td>
                <td></td>
                <td></td>
              </>
            )}
            <td>
              <Button type="primary" htmlType="submit" block>
                {t("Add")}
              </Button>
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

export default Add;
