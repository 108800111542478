import { useAttendeesQuery } from "api/queries/attendees";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { Table } from "components/styleguide";
import {
  AttendeeModel,
  AttendeeStatus as ApiAttendeeStatus,
  ForumAttendeeApiForumForumIdAttendeesGetRequest,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { TableColumn } from "utils/tableColumnUtils";

import { getColumns } from "./helpers";

interface AttendeesTableOptionProps {
  onAttendeeSelect?: (attendee: AttendeeModel) => void;
  additionalFilters?: Partial<
    Record<
      keyof ForumAttendeeApiForumForumIdAttendeesGetRequest,
      ForumAttendeeApiForumForumIdAttendeesGetRequest[keyof ForumAttendeeApiForumForumIdAttendeesGetRequest]
    >
  >;
  additionalColumns?: TableColumn[];
}

const AttendeesTableOption = ({
  onAttendeeSelect,
  additionalFilters,
  additionalColumns = [],
}: AttendeesTableOptionProps) => {
  const { id } = useParams();

  const [nameSearch, setNameSearch] = useState<string>();
  const [companySearch, setCompanySearch] = useState<string>();
  const [attendStatusSearch, setAttendStatusSearch] = useState<ApiAttendeeStatus[] | undefined>(
    additionalFilters?.attendeeStatus as ApiAttendeeStatus[],
  );
  const [peopleTypeSearch, setPeopleTypeSearch] = useState<string>();
  const [peopleTypesOptions, setPeopleTypesOptions] = useState<{ value: string; label: string }[]>([]);

  const {
    response: { isLoading, data: { data: { items: attendees = [] } = {} } = {} } = {},
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeesQuery,
    {},
    {
      forumId: toNumber(id),
      company: companySearch,
      fullName: nameSearch,
      attendeeStatus: attendStatusSearch,
      peopleTypes: peopleTypeSearch,
      group: additionalFilters?.group,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 50, 100], showSizeChange: false },
  );

  const { isLoading: isCodesLoading } = useParticipationCodesQuery(
    {
      ignorePagination: true,
    },
    {
      onSuccess: ({ data: { items = [] } = {} }) => {
        const codes = items.map(({ code }) => ({ value: code, label: code }));
        setPeopleTypesOptions(codes);
      },
    },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearch = (selectedKeys: any, confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "forumCompany": {
        setCompanySearch(selectedKeys[0] as string);
        break;
      }
      case "fullName": {
        setNameSearch(selectedKeys[0] as string);
        break;
      }
      case "attendStatus": {
        setAttendStatusSearch([selectedKeys[0]?.value]);
        break;
      }
      case "peopleType": {
        setPeopleTypeSearch(selectedKeys.map((selected: { value: string }) => selected.value));
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "forumCompany": {
        setCompanySearch(undefined);
        break;
      }
      case "fullName": {
        setNameSearch(undefined);
        break;
      }
      case "attendStatus": {
        setAttendStatusSearch(
          additionalFilters?.attendeeStatus ? ([additionalFilters.attendeeStatus] as ApiAttendeeStatus[]) : undefined,
        );
        break;
      }
      case "peopleType": {
        setPeopleTypeSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = useMemo(
    () =>
      getColumns({
        handleSearch,
        handleReset,
        peopleTypesOptions,
        onAttendeeSelect,
        additionalColumns,
        additionalFilters,
      }),
    [onAttendeeSelect, peopleTypesOptions, additionalColumns, additionalFilters],
  );

  return (
    <Table
      columns={columns}
      dataSource={attendees}
      loading={isLoading || isCodesLoading}
      rowKey="attendId"
      pagination={pagination}
      onChange={handleSort}
    />
  );
};

export default AttendeesTableOption;
