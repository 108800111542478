import { ForumAttendeeResponseModel } from "generated/api";
import React from "react";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";
import { generateAnchorLink } from "utils/anchorUtils";

import { EventLayoutLocation } from "./types";

const getProfileMenuItems = (t: TFunction<"translation", undefined>, attendee: ForumAttendeeResponseModel | null) => {
  const shouldBeAnchor = location.pathname.split("/")[4] === "attendees";
  const attendeeId = location.pathname.split("/")[5];

  const baseLink = `attendees/${attendeeId}`;
  const menuItems = [
    {
      label: t("Contact Details"),
      id: "#details",
      display: true,
    },
    {
      label: t("Description"),
      id: "#description",
      display: true,
    },
    {
      label: t("Address Information"),
      id: "#address",
      display: true,
    },
    {
      label: t("Social Links"),
      id: "#social",
      display: true,
    },
    {
      label: t("Attendance Details"),
      id: "#attendance",
      display: true,
    },
    {
      label: t("History Information"),
      id: "#history",
      display: true,
    },
    {
      label: t("Dietary Requirements"),
      id: "#dietary",
      display: true,
    },
    {
      label: t("Availability"),
      id: "#availability",
      display: true,
    },
    {
      label: t("Meeting Locations"),
      id: "#meeting-locations",
      display: true,
    },
    {
      label: t("Group Memberships"),
      id: "#group-memberships",
      display: true,
    },
    {
      label: t("Preferences"),
      id: "#preferences",
      display: true,
    },
    {
      label: t("Questionnaire Answers"),
      id: "#answers",
      display: true,
    },
    {
      label: t("Spouse/Partner"),
      id: "#partner",
      display: true,
    },
    {
      label: t("Speaker Tracking/Details"),
      id: "#speaker",
      display: attendee?.isSpeaker,
    },
    {
      label: t("Hotel and/or Room Allocation"),
      id: "#hotel-and-room-allocation",
      display: true,
    },
    {
      label: t("Itinerary"),
      id: "#itinerary",
      display: true,
    },
  ];

  return menuItems
    .filter((i) => i.display)
    .map((item) => {
      return generateAnchorLink({ id: item.id, label: item.label, link: baseLink, shouldBeAnchor });
    });
};

const getBuildingMenuItems = (
  t: TFunction<"translation", undefined>,
  location: EventLayoutLocation,
  venueId: number,
  buildingId?: number,
) => {
  const isInBuilding =
    location.pathname.split("/")[6] === "buildings" && location.pathname.split("/")[7] === buildingId?.toString();
  const baseLink = `venues/${venueId}/buildings/${buildingId}`;

  const items = [
    {
      id: `${buildingId}_#details`,
      label: t("Details"),
      anchorLink: "#details",
    },
    {
      id: `${buildingId}_#meeting-rooms`,
      label: t("Meeting Rooms"),
      anchorLink: "#meeting-rooms",
    },
    {
      id: `${buildingId}_#conference-rooms`,
      label: t("Conference Rooms"),
      anchorLink: "#conference-rooms",
    },
    {
      id: `${buildingId}_#restaurants`,
      label: t("Restaurants"),
      anchorLink: "#restaurants",
    },
    {
      id: `${buildingId}_#speed-meetings`,
      label: t("Speed Meetings"),
      anchorLink: "#speed-meetings",
    },
    {
      id: `${buildingId}_#bedrooms`,
      label: t("Bedrooms"),
      anchorLink: "#bedrooms",
    },
  ];

  return items.map((i) => {
    return generateAnchorLink({
      id: i.id,
      label: i.label,
      anchorLink: i.anchorLink,
      link: baseLink,
      shouldBeAnchor: isInBuilding,
    });
  });
};

export const getCompanyMenuItems = (t: TFunction<"translation", undefined>, companyId: string) => {
  const shouldBeAnchor = location.pathname.split("/")[5] === "company";
  const baseLink = `attendees/company/${companyId}`;
  const items = [
    {
      id: "#people",
      label: t("People"),
      anchorLink: "#people",
    },
    {
      id: "#description",
      label: t("Description"),
      anchorLink: "#description",
    },
    {
      id: "#address",
      label: t("Address Information"),
      anchorLink: "#address",
    },
    {
      id: "#social",
      label: t("Social Links"),
      anchorLink: "#social",
    },
    {
      id: "#package-info",
      label: t("Package Information"),
      anchorLink: "#package-info",
      children: [
        {
          id: "#package",
          label: t("Package"),
          anchorLink: "#package",
        },
        {
          id: "#primary-locations",
          label: t("Primary Locations"),
          anchorLink: "#primary-locations",
        },
      ],
    },
    {
      id: "#history",
      label: t("History Information"),
      anchorLink: "#history",
    },
  ];

  return items.map((item) => {
    return generateAnchorLink({
      id: item.id,
      label: item.label,
      anchorLink: item.anchorLink,
      link: baseLink,
      shouldBeAnchor,
      children: item.children?.map((c) => {
        return { id: c.id, label: c.label, anchorLink: c.anchorLink, link: baseLink, shouldBeAnchor };
      }),
    });
  });
};

export const getConferenceMenuItems = (t: TFunction<"translation", undefined>) => {
  return [
    {
      key: "stream",
      label: <Link to={"conference/stream"}>{t("Stream")}</Link>,
      id: "stream",
    },
    {
      key: "seminar-types",
      label: <Link to={"conference/seminar-types"}>{t("Seminar Types")}</Link>,
      id: "seminarTypes",
    },
    {
      key: "seminars",
      label: <Link to={"conference/seminars"}>{t("Seminars")}</Link>,
      id: "seminars",
    },
    {
      key: "speaker-visuals",
      label: <Link to={"conference/speaker-visuals"}>{t("Speaker Visuals")}</Link>,
      id: "speakerVisuals",
    },
    {
      key: "languages",
      label: <Link to={"conference/languages"}>{t("Languages")}</Link>,
      id: "languages",
    },
  ];
};

export const getForumAdminMenuItems = (t: TFunction<"translation", undefined>, location: EventLayoutLocation) => {
  const shouldBeAnchor = location.pathname.split("/").at(-1) === "general";
  const baseLink = "forum-admin/general";
  return [
    {
      label: t("General"),
      key: "general",
      children: [
        generateAnchorLink({
          label: t("Details"),
          id: "#details",
          link: baseLink,
          shouldBeAnchor,
        }),
        generateAnchorLink({
          label: t("Contact Info"),
          id: "#contact",
          link: baseLink,
          shouldBeAnchor,
        }),
        generateAnchorLink({
          label: t("Social Links"),
          id: "#social-links",
          link: baseLink,
          shouldBeAnchor,
        }),
        generateAnchorLink({
          label: t("Team Members And Roles"),
          id: "#team-members-roles",
          link: baseLink,
          shouldBeAnchor,
        }),
      ],
    },
    {
      key: "groups",
      label: <Link to={"forum-admin/groups"}>{t("Groups")}</Link>,
      id: "groups",
    },
    {
      key: "it-contract",
      label: <Link to={"forum-admin/it-contract"}>{t("IT Contract")}</Link>,
      id: "it-contract",
    },
    {
      key: "timeslots",
      label: <Link to={"forum-admin/timeslots"}>{t("Timeslots")}</Link>,
      id: "timeslots",
    },
    {
      key: "questionnaires",
      label: <Link to={"forum-admin/questionnaires"}>{t("Questionnaires")}</Link>,
      id: "questionnaires",
    },
    {
      key: "selectionSites",
      label: <Link to={"forum-admin/selection-sites"}>{t("Selection Sites")}</Link>,
      id: "selectionSites",
    },
    {
      key: "manageDescriptions",
      label: <Link to={"forum-admin/descriptions"}>{t("Manage Descriptions")}</Link>,
      id: "manageDescriptions",
    },
    {
      key: "manageVirtualForum",
      label: <Link to={"forum-admin/manage-virtual-forum"}>{t("Manage Virtual Forum")}</Link>,
      id: "manageVirtualForum",
    },
    {
      label: t("Itinerary Booklets"),
      key: "itinerary",
      children: [
        {
          key: "essentials",
          label: <Link to={"forum-admin/itinerary/essentials/"}>{t("Essentials")}</Link>,
          id: "essentials",
        },
        {
          key: "test",
          label: <Link to={"forum-admin/itinerary/test/"}>{t("Test")}</Link>,
          id: "test",
        },
        {
          key: "advanced",
          label: <Link to={"forum-admin/itinerary/advanced/"}>{t("Advanced")}</Link>,
          id: "advanced",
        },
        {
          key: "create",
          label: <Link to={"forum-admin/itinerary/create/"}>{t("Create")}</Link>,
          id: "create",
        },
        {
          key: "bookletProfileSetup",
          label: <Link to={"forum-admin/itinerary/booklet-profile-setup/"}>{t("Booklet Profile Setup")}</Link>,
          id: "bookletProfileSetup",
        },
      ],
    },
    {
      key: "feedback",
      label: <Link to={"forum-admin/feedback"}>{t("Feedback")}</Link>,
      id: "feedback",
    },
    {
      key: "onSiteFunctions",
      label: <Link to={"forum-admin/on-site-functions"}>{t("On-site Functions")}</Link>,
      id: "onSiteFunctions",
    },
    {
      key: "emailTemplates",
      label: <Link to={"forum-admin/email-templates"}>{t("Email Templates")}</Link>,
      id: "emailTemplateEdit",
    },
    {
      label: t("Hotel Admin"),
      key: "hotel-admin",
      children: [
        generateAnchorLink({
          label: t("Billing Notes"),
          id: "#billing-notes",
          link: "forum-admin/hotel-admin",
          shouldBeAnchor: location.pathname.split("/")[5] === "hotel-admin",
        }),
        generateAnchorLink({
          label: t("Hotel Allocations"),
          id: "#hotel-allocations",
          link: "forum-admin/hotel-admin",
          shouldBeAnchor: location.pathname.split("/")[5] === "hotel-admin",
        }),
      ],
    },
  ];
};

const getDefaultSelectedKeys = (location: EventLayoutLocation) => {
  const mainItem = location.pathname.split("/")[4];
  const subItem = location.pathname.split("/")[5];

  if (mainItem === "attendees" && subItem) {
    return ["profile"];
  }

  if (mainItem === "venues") {
    return ["venues", "venue"];
  }

  if (mainItem === "company") {
    return ["company"];
  }

  return [mainItem];
};

const getDefaultOpenKeys = (location: EventLayoutLocation) => {
  const mainItem = location.pathname.split("/")[4];
  const subItem = location.pathname.split("/")[5];

  //These are put here so that the menu doesn't overflow to a point in doesn't render it's children
  if (mainItem === "attendees" && subItem) {
    return ["attendees", "profile"];
  }

  if (mainItem === "company") {
    return ["company"];
  }

  if (mainItem === "venues") {
    return ["venues"];
  }

  if (mainItem === "forum-admin") {
    const items = ["forum-admin"];
    const subMenus = ["general", "itinerary", "hotel-admin"];

    subMenus.map((menu) => {
      if (menu === subItem) {
        items.push(menu);
      }
    });

    return items;
  }

  return [];
};

export { getBuildingMenuItems, getDefaultOpenKeys, getDefaultSelectedKeys, getProfileMenuItems };
