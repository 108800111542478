import { useQueryClient } from "@tanstack/react-query";
import VenueDetails from "components/VenueDetails";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useDeleteVenueMapMutation,
  useUpdateVenueMutation,
  useUploadVenueMapMutation,
} from "../../../../api/mutations/venues";
import KEYS from "../../../../api/queries/keys";
import { useGetVenueQuery } from "../../../../api/queries/venues";

const VenueDetailsPanel = () => {
  const { t } = useTranslation();
  const venueId = toNumber(useParams().venueId);

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateVenueMutation = useUpdateVenueMutation();
  const uploadVenueMapMutation = useUploadVenueMapMutation();
  const deleteVenueMapMutation = useDeleteVenueMapMutation();

  const [isVenueDetailsEditing, setIsVenueDetailsEditing] = useState(false);
  const [venue, setVenue] = useState({});

  useGetVenueQuery(
    { venueId },
    {
      onSuccess: ({ data }) => {
        setVenue(data);
        if (!location.state || !location.state.venueName) {
          navigate(".", {
            replace: true,
            state: { ...location.state, venueName: data.name },
          });
        }
      },
    },
  );

  const uploadVenueMap = (venueId, mapFile, onSuccess) => {
    uploadVenueMapMutation.mutate(
      {
        venueId,
        formFile: mapFile,
      },
      {
        onSuccess,
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const deleteVenueMap = (venueId, onSuccess) => {
    deleteVenueMapMutation.mutate(
      {
        venueId,
      },
      {
        onSuccess,
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onUpdateVenueSuccess = () => {
    queryClient.invalidateQueries([KEYS.GET_VENUE]);
    setIsVenueDetailsEditing(false);
    toast.success(t("Venue successfully updated"));
  };

  const handleVenueEditSubmit = (venueData) => {
    const { map, oldMap } = venueData;

    updateVenueMutation.mutate(
      { venueId, venueRequestModel: venueData },
      {
        onSuccess: ({ data: updatedVenue }) => {
          setVenue(updatedVenue);

          if (Array.isArray(map) && map.length) {
            uploadVenueMap(updatedVenue.id, map[0]?.originFileObj, onUpdateVenueSuccess);
          } else if (!map && oldMap) {
            deleteVenueMap(updatedVenue.id, onUpdateVenueSuccess);
          } else {
            onUpdateVenueSuccess();
          }
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <VenueDetails
      venue={venue}
      setVenue={setVenue}
      isVenueDetailsEditing={isVenueDetailsEditing}
      setIsVenueDetailsEditing={setIsVenueDetailsEditing}
      onVenueEditSubmit={handleVenueEditSubmit}
      isUpdateLoading={updateVenueMutation.isLoading}
    />
  );
};

export default VenueDetailsPanel;
