import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "../../../../../../../components/styleguide";
import { JointItem } from "../../../../../../../generated/api";
import DefaultForm from "../DefaultForm";
import { GroupSelectingFrom, SelectionSiteItemSelect, YesNo } from "../inputs";

interface JointProps {
  forumId: number;
  selectionSiteId: number;
  item: JointItem;
  onChange: (item: JointItem) => void;
}

const Joint: FC<JointProps> = ({ forumId, selectionSiteId, item, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (values: Partial<JointItem>) => {
    const newValues = { ...item, ...values };

    return onChange(newValues);
  };

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      onChange={handleChange}
      options={() => (
        <>
          <Form.Item name="associatedSelectionItem" label={t("Associated selection steps")}>
            <SelectionSiteItemSelect
              types={["PositiveSelectionV2"]}
              forumId={forumId}
              selectionSiteId={selectionSiteId}
            />
          </Form.Item>
          <Form.Item name="groupThatCanBeSelected" label={t("The group that can be selected for joint meetings")}>
            <GroupSelectingFrom forumId={forumId} primary />
          </Form.Item>
          <Form.Item name="showProfileDetails" label={t("Show profile details")}>
            <YesNo />
          </Form.Item>
          <Form.Item name="displayLocalisedLabels" label={t("Show localised labels")}>
            <YesNo />
          </Form.Item>
        </>
      )}
    />
  );
};

export default Joint;
