import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useSelectionSitesQuery = getWrappedUseQuery(KEYS.GET_SELECTION_SITES, apiClient.ForumSelectionSite.getAll);

export const useSelectionSiteQuery = getWrappedUseQuery(KEYS.GET_SELECTION_SITE, apiClient.ForumSelectionSite.get);

export const useSelectionSiteContentQuery = getWrappedUseQuery(
  KEYS.GET_SELECTION_SITE_CONTENT,
  apiClient.ForumSelectionSite.content.get,
);

export const useSelectionSitePredefinedEventChoicesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_SELECTION_SITE_PREDEFINED_EVENT_CHOICES,
  apiClient.ForumSelectionSite.predefined.eventChoices.getAll,
);

export const useGetExportSelectionSiteQuery = getWrappedUseQuery(
  KEYS.GET_SELECTION_SITE_EXPORT,
  apiClient.ForumSelectionSite.export.get,
);
