import { Space, Typography, YesNo } from "components/styleguide";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface ShowNameSelectOptionProps {
  showName: boolean;
  setShowName: Dispatch<SetStateAction<boolean>>;
}

const ShowNameSelectOption = ({ showName, setShowName }: ShowNameSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("Show Name")}</Typography.Text>
      <YesNo style={{ width: "200px" }} value={showName} onSelect={setShowName} />
    </Space>
  );
};

export default ShowNameSelectOption;
