import api from "api";
import { useQuery } from "@tanstack/react-query";

const FAVORITE_FORUMS_KEY = "favorite_forums";

const keys = {
  favoriteForums: () => [FAVORITE_FORUMS_KEY],
};

const useFavoriteForumsQuery = (options = {}) => {
  return useQuery(
    keys.favoriteForums(),
    async () => await api.get("/favorite-forums"),
    options
  );
};

export { useFavoriteForumsQuery };
