import { Typography } from "antd";
import { useGetItineraryBookletsDocumentTest } from "api/queries/itineraryBookletsDocumentTest";
import { useQuestionnairesQuery } from "api/queries/questionnaires";
import { Button, Col, Row, Select } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { TestDocumentDefinitionTypes as ReportTypes } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

import styles from "./styles.module.less";

export interface DropdownOption {
  label: string;
  value: number;
}

export interface DropdownData {
  options: Array<DropdownOption>;
  isLoading: boolean;
}

interface DownloadOptionsProps {
  title: string;
  reportType: ReportTypes;
  groups?: DropdownData;
  isQuestionnairesVisible?: boolean;
}

const DownloadOptions: FC<DownloadOptionsProps> = ({ title, reportType, groups, isQuestionnairesVisible }) => {
  const forumId = toNumber(useParams().id);
  const { t } = useTranslation();
  const { Title } = Typography;

  const [selectedGroupId, setSelectedGroupId] = useState<number | undefined>();
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<number | undefined>();

  const { isFetching: isQuestionnairesLoading, data: { data: { items: questionnaires = [] } = {} } = {} } =
    useQuestionnairesQuery(
      {
        forumId,
        groupId: selectedGroupId,
      },
      {
        enabled: !!(isQuestionnairesVisible && selectedGroupId),
        retry: false,
        refetchOnWindowFocus: false,
        onSettled: () => setSelectedQuestionnaireId(undefined),
      },
    );

  const { isFetching: isReportDownloading, refetch: downloadReport } = useGetItineraryBookletsDocumentTest(
    {
      forumId,
      reportType,
      forumGroupId: selectedGroupId,
      questionnaireId: selectedQuestionnaireId,
    },
    {
      enabled: false,
      retry: false,
      onSuccess: ({ data: reportLink }) => downloadFileByURL(reportLink),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: ({ response }: any) => {
        if (ErrorTypes.isOfType(response, ErrorTypes.ChildrenNotFound)) {
          toast.error(t("The downloaded report is empty"));
        } else if (ErrorTypes.isOfType(response, ErrorTypes.AssociatedObjectNotFound)) {
          toast.error(t("The document cannot be generated, there are no attendees associated with this group"));
        } else {
          toast.error(t("Something went wrong"));
        }
      },
    },
  );

  const questionnairesOptions = questionnaires.map(({ id, name }) => ({
    label: name,
    value: id,
  }));

  const isDisabledDownloadButton =
    (groups && !selectedGroupId) || (isQuestionnairesVisible && !selectedQuestionnaireId) || isReportDownloading;

  return (
    <>
      <Title className={styles.title} level={5}>
        {t(title)}
      </Title>
      {groups && (
        <Row>
          <Col>
            <Select
              className={styles.dropdown}
              placeholder={t("Select Group")}
              loading={groups?.isLoading}
              options={groups?.options}
              value={selectedGroupId}
              onChange={setSelectedGroupId}
            />
          </Col>
        </Row>
      )}
      {isQuestionnairesVisible && (
        <Row>
          <Col>
            <Select
              className={styles.dropdown}
              placeholder={t("Select Questionnaire")}
              loading={isQuestionnairesLoading}
              disabled={!selectedGroupId}
              options={questionnairesOptions}
              value={selectedQuestionnaireId}
              onChange={setSelectedQuestionnaireId}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <Button
            type="primary"
            className={styles.button}
            loading={isReportDownloading}
            disabled={isDisabledDownloadButton}
            onClick={() => downloadReport()}
          >
            {t("Download")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DownloadOptions;
