import { ForumVisualSpeakerDocumentApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumVisualSpeakerDocumentApi);

const ForumVisualSpeakerDocument = {
  get: api.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet,
  post: api.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost,
  put: api.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut,
  delete: api.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete,
};

export default ForumVisualSpeakerDocument;
