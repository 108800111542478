import React from 'react';
import {languages} from "../../i18n";
import { useTranslation } from "react-i18next";
import {Select} from "components/styleguide";
import { useLocation, useNavigate } from "react-router-dom"
import styles from './styles.module.less';

const {Option} = Select;

const LanguageSwitch = () => {
  const {i18n} = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language).then(() => {
      let newLocation = location.pathname.split("/");
      newLocation[1] = language;
      navigate(newLocation.join("/"))
    })
  };

  return (
    <Select
      className={styles.languageSwitch}
      value={i18n.resolvedLanguage}
      onChange={handleLanguageChange}
    >
      {Object.values(languages).map(language => (
        <Option value={language.key} key={language.key}>
          {language.name}
        </Option>
      ))}
    </Select>
  )
};

export default LanguageSwitch;