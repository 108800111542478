import { ItineraryBookletsForumWelcomeLetterApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ItineraryBookletsForumWelcomeLetterApi);

const ItineraryBookletsForumWelcomeLetter = {
  get: api.forumForumIdWelcomeLetterGet,
  put: api.forumForumIdWelcomeLetterPut,
  image: {
    post: api.forumForumIdWelcomeLetterImagePost,
  },
};

export default ItineraryBookletsForumWelcomeLetter;
