import { Skeleton, Tag } from "components/styleguide";
import React, { MouseEvent, ReactNode } from "react";

interface TagRenderProps {
  label?: ReactNode;
  closable?: boolean;
  onClose?: () => void;
  isLoading?: boolean;
}

const TagRender = ({ label, closable, onClose, isLoading, ...props }: TagRenderProps) => {
  const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      {isLoading ? (
        <Skeleton.Button
          size={"small"}
          active={isLoading}
          style={{ width: "50px", marginRight: "3px", borderRadius: "10px" }}
        />
      ) : (
        <Tag
          style={{ borderRadius: "10px" }}
          {...props}
          onMouseDown={onPreventMouseDown}
          closable={closable}
          onClose={onClose}
        >
          {label}
        </Tag>
      )}
    </>
  );
};

export default TagRender;
