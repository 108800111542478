import { TabsProps } from "antd";
import { Drawer, Space, Tabs } from "components/styleguide";
import { toNumber } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { DocumentDefinitionTypes } from "../../../../../generated/api";
import CustomPageTable from "./CustomPageTable";
import CustomPDFTable from "./CustomPDFTable";
import StandardSectionsTypesTable from "./StandardSectionsTypesTable";

interface AddNewSectionDrawerProps {
  isVisible: boolean;
  onClose: () => void;
  selectedDocumentDefinitionType: DocumentDefinitionTypes;
}

export interface TabsTableProps {
  forumId: number;
  documentDefinitionType: DocumentDefinitionTypes;
}

const AddNewSectionDrawer: React.FC<AddNewSectionDrawerProps> = ({
  isVisible,
  onClose,
  selectedDocumentDefinitionType,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const forumId = toNumber(id);

  const tabs: TabsProps["items"] = [
    {
      key: "standardSectionsTypes",
      label: t("Standard Sections Types"),
      children: (
        <StandardSectionsTypesTable forumId={forumId} documentDefinitionType={selectedDocumentDefinitionType} />
      ),
    },
    {
      key: "customPage",
      label: t("Custom Page"),
      children: <CustomPageTable forumId={forumId} documentDefinitionType={selectedDocumentDefinitionType} />,
    },
    {
      key: "customPDF",
      label: t("Custom PDF"),
      children: <CustomPDFTable forumId={forumId} documentDefinitionType={selectedDocumentDefinitionType} />,
    },
  ];

  return (
    <Drawer
      title={t("Add New Section")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Tabs items={tabs} />
      </Space>
    </Drawer>
  );
};

export default AddNewSectionDrawer;
