import { useQueryClient } from "@tanstack/react-query";
import { useCreateParticipationCodeMutation } from "api/mutations/participationCodes";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { ParticipationCodeModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import FormComponent from "./FormComponent";

interface ParticipationCodesDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const ParticipationCodesDrawer = ({ visible, onClose }: ParticipationCodesDrawerProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const createCodeMutation = useCreateParticipationCodeMutation();

  const handleFormSubmit = (participationCodeModel: ParticipationCodeModel) => {
    createCodeMutation.mutate(
      {
        participationCodeModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_PARTICIPATION_CODES]);
          onClose();
        },
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem) && response?.data?.meta?.ItemFields?.Code) {
            toast.error(t("A Participation Code with this code already exists"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const isMutationLoading = createCodeMutation.isLoading;

  return (
    <Drawer
      open={isMutationLoading || visible}
      placement="right"
      title={t("Add New Code")}
      size="large"
      destroyOnClose
      onClose={isMutationLoading ? undefined : onClose}
    >
      <FormComponent isLoading={isMutationLoading} onCancel={onClose} onSubmit={handleFormSubmit} />
    </Drawer>
  );
};

export default ParticipationCodesDrawer;
