import apiClient from "api-client";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useGetItContractItemsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_IT_CONTRACT_ITEMS,
  apiClient.ItContracts.get,
);

export const useGetItContractQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_IT_CONTRACT_APPROVAL,
  apiClient.ItContracts.approval.get,
);

export const useGetItContractExport = getWrappedUseQuery(
  KEYS.GET_FORUM_IT_CONTRACT_EXPORT,
  apiClient.ItContracts.export.get,
);
