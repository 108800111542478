import { Button } from "components/styleguide";
import { ITContractApprovalStatus } from "enums/ITContractApprovalStatus";
import { ForumITContractItemResponseModel, ForumITContractResponseModel } from "generated/api";
import { t } from "i18next";
import moment from "moment";
import { buildColumn } from "utils/columnUtils";

import ItContractApprovalStatus from "./ItApprovalStatus";

export const getContractColumns = (
  editContract: (record: ForumITContractItemResponseModel) => void,
  contractApproval: ForumITContractResponseModel,
) => [
  {
    title: t("Due date"),
    dataIndex: "dueDate",
    render: (date: string) => date && moment(date).format("L"),
  },
  {
    title: t("item"),
    dataIndex: "item",
  },
  {
    title: t("responsible"),
    dataIndex: "responsible",
  },
  {
    dataIndex: "id",
    width: "5%",
    render: (_: number, record: ForumITContractItemResponseModel) => (
      <Button
        disabled={contractApproval.status === ITContractApprovalStatus.Approved}
        onClick={() => editContract(record)}
      >
        {t("Edit") as string}
      </Button>
    ),
  },
];

export const getApprovalColumns = () => [
  buildColumn(t("Current status"), "status", {
    render: (status: string) => <ItContractApprovalStatus status={status} />,
  }),
  buildColumn(t("Decision made by"), "decisionMadeByName"),
  buildColumn(t("Decision made on"), "decisionMadeOnDate", {
    render: (decisionMadeOnDate: string) => decisionMadeOnDate && moment(decisionMadeOnDate).format("Do MMMM YYYY"),
  }),
];
