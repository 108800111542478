import React from "react";
import { Tree as AntdTree } from "antd";
import PropTypes from "prop-types";
import clsx from "clsx";

import styles from "./styles.module.less";

const Tree = ({ transparent, className, ...props }) => {
  const classes = clsx(className, {
    [styles.transparentBackground]: transparent,
  });

  return <div className={classes}>
    <AntdTree {...props} />
  </div>;
};

Tree.propTypes = {
  transparent: PropTypes.bool,
};

Tree.defaultProps = {
  transparent: false,
};

export default Tree;
