import EnumBuilder from "../utils/enumBuilderUtils";

const builder = new EnumBuilder();
const EnumClass = builder
  .add("PersonalProfile", "Personal profile")
  .add("CompanyProfile", "Company profile")
  .add("DietaryRequirements", "Dietary requirements")
  .add("ConferenceRequirements", "Conference requirements")
  .add("DelegateFeedback", "Delegate feedback")
  .add("SupplierFeedback", "Supplier feedback")
  .add("AccommodationRequirements", "Accommodation requirements")
  .build();

class QuestionnaireType extends EnumClass {
  isPersonalProfile() {
    return this.value === "PersonalProfile";
  }

  isCompanyProfile() {
    return this.value === "CompanyProfile";
  }

  isDietaryRequirements() {
    return this.value === "DietaryRequirements";
  }

  isConferenceRequirements() {
    return this.value === "ConferenceRequirements";
  }

  isDelegateFeedback() {
    return this.value === "DelegateFeedback";
  }

  isSupplierFeedback() {
    return this.value === "SupplierFeedback";
  }

  isAccommodationRequirements() {
    return this.value === "AccommodationRequirements";
  }
}

export default QuestionnaireType;
