/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQueryClient } from "@tanstack/react-query";
import { ColProps, InputNumber } from "antd";
import { useUpdateCompanyPackageMutation } from "api/mutations/companies";
import KEYS from "api/queries/keys";
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from "components/styleguide";
import { CompanyPackageModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";

const { Option } = Select;

interface ForumCompanyPackageFormProps {
  forumId: number;
  forumCompanyId: number;
  companyName: string;
  initialValues: CompanyPackageModel;
  setIsEditing: (value: boolean) => void;
}

const ForumCompanyPackageForm: FC<ForumCompanyPackageFormProps> = ({
  forumId,
  forumCompanyId,
  companyName,
  initialValues,
  setIsEditing,
}) => {
  const { t } = useTranslation();

  const getSchema = () => {
    return yup.object().shape({
      companyPackagePrice: yup
        .number()
        .nullable()
        .positive()
        .typeError("Must be a positive number")
        .label("Company Package Price"),
      numberOfItineraries: yup
        .number()
        .nullable()
        .positive()
        .typeError("Must be a positive number")
        .label("Number of itineraries"),
      numberOfExecutives: yup
        .number()
        .nullable()
        .positive()
        .typeError("Must be a positive number")
        .label("Number of executives"),
      numberOfRooms: yup.number().nullable().positive().typeError("Must be a positive number").label("Number of rooms"),
      contractSigned: yup.date().nullable().typeError("Must be a valid date").label("Contract Date"),
      currency: yup.string().nullable().typeError("Must be a valid currency").label("Currency"),
      nameSign: yup.string().nullable().label("Name Sign"),
    });
  };

  const [form] = Form.useForm();
  const validation = Form.useYupValidator(form, getSchema);

  const queryClient = useQueryClient();

  const updateMutation = useUpdateCompanyPackageMutation();

  const submit = ({
    numberOfRooms,
    nameSign,
    companyPackagePrice,
    numberOfItineraries,
    numberOfExecutives,
    currency,
    contractSigned,
  }: CompanyPackageModel) => {
    const updatedCompanyPackage = {
      ...initialValues,
      numberOfRooms,
      companyPackagePrice,
      numberOfItineraries,
      numberOfExecutives,
      contractSigned,
      currency,
      nameSign,
    } as CompanyPackageModel;

    updateMutation.mutate(
      {
        forumId,
        forumCompanyId,
        companyPackageModel: updatedCompanyPackage,
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY_PACKAGE]);
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const selectAfter = (
    <Form.Item name="currency" noStyle initialValue={initialValues.currency || null} rules={validation.rules as any}>
      <Select style={{ width: 80 }}>
        <Option value={null}>None</Option>
        <Option value="USD">$</Option>
        <Option value="EUR">€</Option>
        <Option value="GBP">£</Option>
        <Option value="CNY">¥</Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form
      onFinish={submit}
      id="packageForm"
      name="package"
      form={form}
      labelAlign="left"
      labelCol={{ justify: "left", span: 13 } as ColProps}
      wrapperCol={{ justify: "right", span: 11 } as ColProps}
      labelWrap
      colon={false}
    >
      <Row gutter={80}>
        <Col md={24} lg={12}>
          <Form.Item
            name="companyPackagePrice"
            label={t("Company Package Price")}
            rules={validation.rules as any}
            initialValue={initialValues.companyPackagePrice}
          >
            <InputNumber style={{ width: "100%" }} addonAfter={selectAfter} />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="numberOfItineraries"
            label={t("Number of itineraries")}
            rules={validation.rules as any}
            initialValue={initialValues.numberOfItineraries}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="numberOfExecutives"
            label={t("Number of executives")}
            rules={validation.rules as any}
            initialValue={initialValues.numberOfExecutives}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="contractSigned"
            label={t("Contract Date")}
            rules={validation.rules as any}
            initialValue={initialValues.contractSigned}
          >
            <DatePicker />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="numberOfRooms"
            label={t("Number of rooms")}
            initialValue={initialValues.numberOfRooms}
            // useYupValidator props need to be updated
            rules={validation.rules as any}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="nameSign"
            label={t("Name Sign")}
            initialValue={initialValues.nameSign ?? companyName}
            // useYupValidator props need to be updated
            rules={validation.rules as any}
          >
            <Input />
          </Form.Item>
          <Divider />
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={1}>
          <Button loading={updateMutation.isLoading} htmlType="submit" type="primary">
            {t("Save")}
          </Button>
        </Col>
        <Col span={1}>
          <Button onClick={() => setIsEditing(false)}>{t("Cancel")}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ForumCompanyPackageForm;
