import DropdownButton from "components/DropdownButton";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface OptionsButtonProps {
  onSendEmail: () => void;
  onMoveToCompany: () => void;
}

const OptionsButton: FC<OptionsButtonProps> = ({ onSendEmail, onMoveToCompany }) => {
  const { t } = useTranslation();

  const items = [
    {
      label: t("Send Email"),
      key: "send-email",
      onClick: onSendEmail,
    },
    {
      label: t("Move to another company"),
      key: "move-to-company",
      onClick: onMoveToCompany,
    },
  ];

  return <DropdownButton title="More Options" items={items} />;
};

export default OptionsButton;
