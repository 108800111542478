import "./styles.less";

import Icon, { BellOutlined, SettingFilled } from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { UseQueryResult } from "@tanstack/react-query";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { AxiosResponse } from "axios";
import LanguageSwitch from "components/LanguageSwitch";
import { Layout } from "components/styleguide";
import UserMenuAvatar from "components/UserMenuAvatar";
import { UserInfoModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";

import { Role } from "../../utils/userRole";

const { Content, Header } = Layout;

const BaseLayout = ({ userInfo }: { userInfo: UseQueryResult<AxiosResponse<UserInfoModel, unknown>, unknown> }) => {
  const { t } = useTranslation();

  return (
    <Layout style={{ height: "auto", minHeight: "100%" }}>
      <Header className={"base-layout-header"}>
        <Logo width={140} />
        <div className={"icons"}>
          <Role renderIf={({ SYSTEM_ADMIN }) => SYSTEM_ADMIN}>
            <Link to="system-admin/forums" className="icon system-admin-nav">
              <Icon component={SettingFilled as React.ForwardRefExoticComponent<CustomIconComponentProps>} />
              <span>{t("System Admin")}</span>
            </Link>
          </Role>
          <Icon
            className={"icon"}
            component={BellOutlined as React.ForwardRefExoticComponent<CustomIconComponentProps>}
          />
          <LanguageSwitch />
          <UserMenuAvatar userInfo={userInfo} />
        </div>
      </Header>
      <Content>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default BaseLayout;
