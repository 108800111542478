/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { TableRequestModel } from '../apiModels';
// @ts-ignore
import { TableResponseModel } from '../apiModels';
// @ts-ignore
import { TableType } from '../apiModels';
/**
 * TableApi - axios parameter creator
 * @export
 */
export const TableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all Tables
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {TableType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet: async (venueId: number, buildingId: number, facilityId: number, type?: TableType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet', 'facilityId', facilityId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}/tables`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {TableRequestModel} [tableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost: async (venueId: number, buildingId: number, facilityId: number, tableRequestModel?: TableRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost', 'facilityId', facilityId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}/tables`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete: async (venueId: number, buildingId: number, facilityId: number, tableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete', 'facilityId', facilityId)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete', 'tableId', tableId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}/tables/{tableId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet: async (venueId: number, buildingId: number, facilityId: number, tableId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet', 'facilityId', facilityId)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet', 'tableId', tableId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}/tables/{tableId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {number} tableId 
         * @param {TableRequestModel} [tableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut: async (venueId: number, buildingId: number, facilityId: number, tableId: number, tableRequestModel?: TableRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut', 'facilityId', facilityId)
            // verify required parameter 'tableId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut', 'tableId', tableId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}/tables/{tableId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)))
                .replace(`{${"tableId"}}`, encodeURIComponent(String(tableId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tableRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TableApi - functional programming interface
 * @export
 */
export const TableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all Tables
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {TableType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet(venueId: number, buildingId: number, facilityId: number, type?: TableType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TableResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet(venueId, buildingId, facilityId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {TableRequestModel} [tableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost(venueId: number, buildingId: number, facilityId: number, tableRequestModel?: TableRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost(venueId, buildingId, facilityId, tableRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete(venueId: number, buildingId: number, facilityId: number, tableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete(venueId, buildingId, facilityId, tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {number} tableId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet(venueId: number, buildingId: number, facilityId: number, tableId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TableResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet(venueId, buildingId, facilityId, tableId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a table
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {number} tableId 
         * @param {TableRequestModel} [tableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut(venueId: number, buildingId: number, facilityId: number, tableId: number, tableRequestModel?: TableRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut(venueId, buildingId, facilityId, tableId, tableRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TableApi - factory interface
 * @export
 */
export const TableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TableApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all Tables
         * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TableResponseModel>> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates table
         * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPostRequest, options?: AxiosRequestConfig): AxiosPromise<TableResponseModel> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a table
         * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get table
         * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<TableResponseModel> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a table
         * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableId, requestParameters.tableRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet operation in TableApi.
 * @export
 * @interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGetRequest
 */
export interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet
     */
    readonly facilityId: number

    /**
     * 
     * @type {TableType}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet
     */
    readonly type?: TableType
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost operation in TableApi.
 * @export
 * @interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPostRequest
 */
export interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost
     */
    readonly facilityId: number

    /**
     * 
     * @type {TableRequestModel}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost
     */
    readonly tableRequestModel?: TableRequestModel
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete operation in TableApi.
 * @export
 * @interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDeleteRequest
 */
export interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete
     */
    readonly facilityId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete
     */
    readonly tableId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet operation in TableApi.
 * @export
 * @interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGetRequest
 */
export interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet
     */
    readonly facilityId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet
     */
    readonly tableId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut operation in TableApi.
 * @export
 * @interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPutRequest
 */
export interface TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut
     */
    readonly facilityId: number

    /**
     * 
     * @type {number}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut
     */
    readonly tableId: number

    /**
     * 
     * @type {TableRequestModel}
     * @memberof TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut
     */
    readonly tableRequestModel?: TableRequestModel
}

/**
 * TableApi - object-oriented interface
 * @export
 * @class TableApi
 * @extends {BaseAPI}
 */
export class TableApi extends BaseAPI {
    /**
     * 
     * @summary Get all Tables
     * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGetRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates table
     * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPostRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesPost(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a table
     * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDeleteRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get table
     * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGetRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a table
     * @param {TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TableApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut(requestParameters: TableApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPutRequest, options?: AxiosRequestConfig) {
        return TableApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.tableId, requestParameters.tableRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

