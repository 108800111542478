/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DuplicateItemExceptionResponse } from '../apiModels';
// @ts-ignore
import { ItineraryBookletsForumTeamMemberRequestModel } from '../apiModels';
// @ts-ignore
import { ItineraryBookletsForumTeamMemberResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PriorityPreferenceRequestModel } from '../apiModels';
// @ts-ignore
import { UserResponseModel } from '../apiModels';
// @ts-ignore
import { ValueOutOfRangeExceptionResponse } from '../apiModels';
/**
 * ItineraryBookletsForumTeamMembersApi - axios parameter creator
 * @export
 */
export const ItineraryBookletsForumTeamMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets list of team members by forum
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-team-members`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of team members by forum
         * @param {number} forumId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersMemberIdDelete: async (forumId: number, memberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersMemberIdDelete', 'forumId', forumId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersMemberIdDelete', 'memberId', memberId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-team-members/{memberId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an essentials forum team member\'s details
         * @param {number} forumId 
         * @param {number} memberId 
         * @param {ItineraryBookletsForumTeamMemberRequestModel} [itineraryBookletsForumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersMemberIdPut: async (forumId: number, memberId: number, itineraryBookletsForumTeamMemberRequestModel?: ItineraryBookletsForumTeamMemberRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersMemberIdPut', 'forumId', forumId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersMemberIdPut', 'memberId', memberId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-team-members/{memberId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itineraryBookletsForumTeamMemberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the order of a team members by forum
         * @param {number} forumId 
         * @param {number} memberId 
         * @param {PriorityPreferenceRequestModel} [priorityPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut: async (forumId: number, memberId: number, priorityPreferenceRequestModel?: PriorityPreferenceRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut', 'forumId', forumId)
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut', 'memberId', memberId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-team-members/{memberId}/reorder`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(priorityPreferenceRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates an essentials forum team member
         * @param {number} forumId 
         * @param {ItineraryBookletsForumTeamMemberRequestModel} [itineraryBookletsForumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersPost: async (forumId: number, itineraryBookletsForumTeamMemberRequestModel?: ItineraryBookletsForumTeamMemberRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-team-members`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itineraryBookletsForumTeamMemberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of unassigned users
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersUnassignedGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTeamMembersUnassignedGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-team-members/unassigned`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItineraryBookletsForumTeamMembersApi - functional programming interface
 * @export
 */
export const ItineraryBookletsForumTeamMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItineraryBookletsForumTeamMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets list of team members by forum
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTeamMembersGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItineraryBookletsForumTeamMemberResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTeamMembersGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of team members by forum
         * @param {number} forumId 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTeamMembersMemberIdDelete(forumId: number, memberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTeamMembersMemberIdDelete(forumId, memberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an essentials forum team member\'s details
         * @param {number} forumId 
         * @param {number} memberId 
         * @param {ItineraryBookletsForumTeamMemberRequestModel} [itineraryBookletsForumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTeamMembersMemberIdPut(forumId: number, memberId: number, itineraryBookletsForumTeamMemberRequestModel?: ItineraryBookletsForumTeamMemberRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTeamMembersMemberIdPut(forumId, memberId, itineraryBookletsForumTeamMemberRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the order of a team members by forum
         * @param {number} forumId 
         * @param {number} memberId 
         * @param {PriorityPreferenceRequestModel} [priorityPreferenceRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut(forumId: number, memberId: number, priorityPreferenceRequestModel?: PriorityPreferenceRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut(forumId, memberId, priorityPreferenceRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates an essentials forum team member
         * @param {number} forumId 
         * @param {ItineraryBookletsForumTeamMemberRequestModel} [itineraryBookletsForumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTeamMembersPost(forumId: number, itineraryBookletsForumTeamMemberRequestModel?: ItineraryBookletsForumTeamMemberRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTeamMembersPost(forumId, itineraryBookletsForumTeamMemberRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of unassigned users
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTeamMembersUnassignedGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTeamMembersUnassignedGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItineraryBookletsForumTeamMembersApi - factory interface
 * @export
 */
export const ItineraryBookletsForumTeamMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItineraryBookletsForumTeamMembersApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets list of team members by forum
         * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersGet(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ItineraryBookletsForumTeamMemberResponseModel>> {
            return localVarFp.forumsForumIdItinerarybookletsTeamMembersGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of team members by forum
         * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersMemberIdDelete(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItinerarybookletsTeamMembersMemberIdDelete(requestParameters.forumId, requestParameters.memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an essentials forum team member\'s details
         * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersMemberIdPut(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItinerarybookletsTeamMembersMemberIdPut(requestParameters.forumId, requestParameters.memberId, requestParameters.itineraryBookletsForumTeamMemberRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the order of a team members by forum
         * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut(requestParameters.forumId, requestParameters.memberId, requestParameters.priorityPreferenceRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates an essentials forum team member
         * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersPost(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItinerarybookletsTeamMembersPost(requestParameters.forumId, requestParameters.itineraryBookletsForumTeamMemberRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of unassigned users
         * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTeamMembersUnassignedGet(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserResponseModel>> {
            return localVarFp.forumsForumIdItinerarybookletsTeamMembersUnassignedGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdItinerarybookletsTeamMembersGet operation in ItineraryBookletsForumTeamMembersApi.
 * @export
 * @interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGetRequest
 */
export interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdItinerarybookletsTeamMembersMemberIdDelete operation in ItineraryBookletsForumTeamMembersApi.
 * @export
 * @interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDeleteRequest
 */
export interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDelete
     */
    readonly memberId: number
}

/**
 * Request parameters for forumsForumIdItinerarybookletsTeamMembersMemberIdPut operation in ItineraryBookletsForumTeamMembersApi.
 * @export
 * @interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPutRequest
 */
export interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPut
     */
    readonly memberId: number

    /**
     * 
     * @type {ItineraryBookletsForumTeamMemberRequestModel}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPut
     */
    readonly itineraryBookletsForumTeamMemberRequestModel?: ItineraryBookletsForumTeamMemberRequestModel
}

/**
 * Request parameters for forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut operation in ItineraryBookletsForumTeamMembersApi.
 * @export
 * @interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPutRequest
 */
export interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut
     */
    readonly memberId: number

    /**
     * 
     * @type {PriorityPreferenceRequestModel}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut
     */
    readonly priorityPreferenceRequestModel?: PriorityPreferenceRequestModel
}

/**
 * Request parameters for forumsForumIdItinerarybookletsTeamMembersPost operation in ItineraryBookletsForumTeamMembersApi.
 * @export
 * @interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPostRequest
 */
export interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ItineraryBookletsForumTeamMemberRequestModel}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPost
     */
    readonly itineraryBookletsForumTeamMemberRequestModel?: ItineraryBookletsForumTeamMemberRequestModel
}

/**
 * Request parameters for forumsForumIdItinerarybookletsTeamMembersUnassignedGet operation in ItineraryBookletsForumTeamMembersApi.
 * @export
 * @interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGetRequest
 */
export interface ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGet
     */
    readonly forumId: number
}

/**
 * ItineraryBookletsForumTeamMembersApi - object-oriented interface
 * @export
 * @class ItineraryBookletsForumTeamMembersApi
 * @extends {BaseAPI}
 */
export class ItineraryBookletsForumTeamMembersApi extends BaseAPI {
    /**
     * 
     * @summary Gets list of team members by forum
     * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTeamMembersApi
     */
    public forumsForumIdItinerarybookletsTeamMembersGet(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTeamMembersApiFp(this.configuration).forumsForumIdItinerarybookletsTeamMembersGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of team members by forum
     * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTeamMembersApi
     */
    public forumsForumIdItinerarybookletsTeamMembersMemberIdDelete(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdDeleteRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTeamMembersApiFp(this.configuration).forumsForumIdItinerarybookletsTeamMembersMemberIdDelete(requestParameters.forumId, requestParameters.memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an essentials forum team member\'s details
     * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTeamMembersApi
     */
    public forumsForumIdItinerarybookletsTeamMembersMemberIdPut(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTeamMembersApiFp(this.configuration).forumsForumIdItinerarybookletsTeamMembersMemberIdPut(requestParameters.forumId, requestParameters.memberId, requestParameters.itineraryBookletsForumTeamMemberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the order of a team members by forum
     * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTeamMembersApi
     */
    public forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersMemberIdReorderPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTeamMembersApiFp(this.configuration).forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut(requestParameters.forumId, requestParameters.memberId, requestParameters.priorityPreferenceRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates an essentials forum team member
     * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTeamMembersApi
     */
    public forumsForumIdItinerarybookletsTeamMembersPost(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersPostRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTeamMembersApiFp(this.configuration).forumsForumIdItinerarybookletsTeamMembersPost(requestParameters.forumId, requestParameters.itineraryBookletsForumTeamMemberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of unassigned users
     * @param {ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTeamMembersApi
     */
    public forumsForumIdItinerarybookletsTeamMembersUnassignedGet(requestParameters: ItineraryBookletsForumTeamMembersApiForumsForumIdItinerarybookletsTeamMembersUnassignedGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTeamMembersApiFp(this.configuration).forumsForumIdItinerarybookletsTeamMembersUnassignedGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }
}

