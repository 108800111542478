import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnsType } from "antd/lib/table";
import {
  useCreateForumDocumentCustomPageElementMutation,
  useDeleteForumDocumentCustomPageElementMutation,
  useReorderForumDocumentCustomPageElementMutation,
} from "api/mutations/forumDocumentDefinition";
import {
  useForumDocumentCustomPageMergeTagsQuery,
  useForumDocumentDefinitionCustomPageIdQuery,
} from "api/queries/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import { DraggableTable } from "components/DraggableTable";
import { Button, Drawer, Modal, Space } from "components/styleguide";
import {
  CustomPageElementAlignments,
  CustomPageElementTypes,
  ForumDocumentCustomPageElementResponseModel,
  ForumDocumentCustomPageListItemModel,
} from "generated/api";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TableColumn } from "utils/tableColumnUtils";

import EditElementDrawer from "./EditElementDrawer";

import editorStyles from "components/TextEditor/plainTextStyles.module.less";

interface CustomPageElementsDrawerProps {
  isDrawerVisible: boolean;
  onClose: () => void;
  forumId: number;
  customPage?: ForumDocumentCustomPageListItemModel;
}

export type CustomPageElement = Pick<
  ForumDocumentCustomPageElementResponseModel,
  "id" | "type" | "value" | "order" | "alignment"
>;

const CustomPageElementsDrawer: React.FC<CustomPageElementsDrawerProps> = ({
  onClose,
  isDrawerVisible,
  forumId,
  customPage,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [elements, setElements] = useState<CustomPageElement[] | undefined>();
  const [isEditElementDrawerVisible, setIsEditElementDrawerVisible] = useState(false);
  const [element, setElement] = useState<CustomPageElement | undefined>();
  const [mergeTags, setMergeTags] = useState<string[] | undefined>();

  const mergeTagsQuery = useForumDocumentCustomPageMergeTagsQuery(
    {},
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onSuccess: ({ data }: { data: string[] }) => {
        setMergeTags(data.map((tag) => `[${tag}]`));
      },
    },
  );

  const createForumDocumentCustomPageElementMutation = useCreateForumDocumentCustomPageElementMutation();
  const deleteForumDocumentCustomPageElementMutation = useDeleteForumDocumentCustomPageElementMutation();
  const reorderForumDocumentCustomPageElementMutation = useReorderForumDocumentCustomPageElementMutation();

  const { isLoading } = useForumDocumentDefinitionCustomPageIdQuery(
    {
      forumId,
      customPageId: toNumber(customPage?.id),
    },
    {
      onSuccess: ({ data }) => {
        setElements(
          data.elements
            .sort((a, b) => a.order - b.order)
            .map(({ id, type, value, order, alignment }) => ({ id, type, value, order, alignment })),
        );
      },
      enabled: !!customPage?.id,
    },
  );

  const handleAddElement = () => {
    const forumDocumentCustomPageElementCreateModel = {
      type: CustomPageElementTypes.HtmlText,
      value: "Change this element",
      alignment: CustomPageElementAlignments.Left,
    };

    createForumDocumentCustomPageElementMutation.mutate(
      {
        forumId,
        customPageId: toNumber(customPage?.id),
        forumDocumentCustomPageElementCreateModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_ID]);
        },
      },
    );
  };

  const onDelete = (record: CustomPageElement) => {
    Modal.confirm({
      title: t("Delete custom page element"),
      content: t("Do you want to proceed with deletion of this element?"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("no"),
      okText: t("Yes"),
      onOk: async () => {
        deleteForumDocumentCustomPageElementMutation.mutate(
          {
            forumId,
            customPageId: toNumber(customPage?.id),
            elementId: record.id,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_ID]);
            },
          },
        );
      },
    });
  };

  const onEditElement = (record: CustomPageElement) => {
    setIsEditElementDrawerVisible(true);
    setElement(record);
  };

  const customElementsColumns: ColumnsType<CustomPageElement> = [
    new TableColumn(t("Element"), "type", { width: "14%" }),
    new TableColumn(t("Description"), "value", {
      render: (description: string) => {
        return (
          <span
            className={editorStyles.editorText}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        );
      },
    }),
    new TableColumn("", "id", {
      align: "center",
      width: "12%",
      render: (id: number, record: CustomPageElement) => (
        <Button
          loading={mergeTagsQuery.isLoading}
          disabled={mergeTagsQuery.isLoading}
          onClick={() => onEditElement(record)}
        >
          {t("Edit")}
        </Button>
      ),
    }),
    new TableColumn("", "id", {
      width: "8%",
      render: (_: unknown, record: CustomPageElement) => {
        return <DeleteFilled className="delete-icon" onClick={() => onDelete(record)} />;
      },
    }),
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id && over) {
      const current = elements?.find((i) => i.order === active.id);

      setElements((previous) => {
        if (!previous) return;
        const activeIndex = previous.findIndex((i) => i.order === active.id);
        const overIndex = previous.findIndex((i) => i.order === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });

      reorderForumDocumentCustomPageElementMutation.mutate({
        forumId,
        customPageId: toNumber(customPage?.id),
        elementId: toNumber(current?.id),
        reorderRequestModel: {
          order: over.id as number,
        },
      });
    }
  };

  return (
    <Drawer
      title={t("Custom Page Elements")}
      placement="right"
      onClose={onClose}
      open={isDrawerVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button onClick={handleAddElement} type="primary">
          {t("Add New Element")}
        </Button>
        <DraggableTable
          dataSource={elements}
          columns={customElementsColumns}
          loading={isLoading}
          id="custom-elements-table"
          data-testId="custom-elements-table"
          bordered
          pagination={false}
          rowKey={"order"}
          onDragEnd={onDragEnd}
          disableOrdering={false}
        />
      </Space>
      {element && (
        <EditElementDrawer
          pageName={customPage?.name}
          forumId={forumId}
          customPageId={toNumber(customPage?.id)}
          element={element}
          isDrawerVisible={isEditElementDrawerVisible}
          mergeTags={mergeTags}
          onClose={() => setIsEditElementDrawerVisible(false)}
        />
      )}
    </Drawer>
  );
};

export default CustomPageElementsDrawer;
