import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useForumItinerariesQuery = getWrappedUseQuery(
  KEYS.GET_BOOKED_MEETINGS,
  apiClient.ForumItinerary.forumItineraries.getAll,
);

export const useExportBookedMeetingsQuery = getWrappedUseQuery(
  KEYS.GET_BOOKED_MEETINGS_EXPORT,
  apiClient.ForumItinerary.meetings.export,
);

export const useForumItineraryConferenceFacilitiesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_ITINERARY_CONFERENCE_FACILITIES,
  apiClient.ForumItinerary.conferences.facilities.get,
);

export const useForumItineraryConferenceFacilityTimeslotSessionsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSIONS,
  apiClient.ForumItinerary.conferences.timeslotSessions.get,
);

export const useForumItineraryConferenceFacilityTimeslotSessionAttendeesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSION_ATTENDEES,
  apiClient.ForumItinerary.conferences.attendees.get,
);

export const useForumItineraryMeetingFacilitiesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_ITINERARY_MEETINGS_FACILITIES,
  apiClient.ForumItinerary.meetings.facilities.get,
);

export const useForumItineraryMeetingFacilityTimeslotSessionsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSIONS,
  apiClient.ForumItinerary.meetings.timeslotSessions.get,
);

export const useForumItineraryMeetingFacilityTimeslotSessionAttendeesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSION_ATTENDEES,
  apiClient.ForumItinerary.meetings.attendees.get,
);
