import { UploadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { FormInstance, UploadFile } from "antd";
import { useUploadCustomForumDocumentPdf } from "api/mutations/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Upload } from "components/styleguide";
import { useTranslation } from "react-i18next";
import { handleFileFakeRequest } from "utils/documentUtils";

const validatePdfSize = (_: unknown, input: { file: File; fileList: FileList }) => {
  if (input.file.size > 1024 * 1024 * 25) {
    return Promise.reject();
  }

  return Promise.resolve();
};

const validateFileType = (_: unknown, input: { file: File; fileList: FileList }) => {
  const fileExtension = input.file.name.split(".").reverse()[0];

  if (fileExtension !== "pdf") {
    return Promise.reject();
  }

  return Promise.resolve();
};

const validateFileExists = (_: unknown, input: { file: File; fileList: FileList }) => {
  if (input.fileList.length === 0) {
    return Promise.reject();
  }

  return Promise.resolve();
};

export default function UploadPDFDrawer({
  visible,
  form,
  onClose,
}: {
  visible: boolean;
  form: FormInstance;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const uploadMutation = useUploadCustomForumDocumentPdf();

  const submit = ({
    description,
    fileUpload,
  }: {
    description: string;
    fileUpload: { file: UploadFile; fileList: FileList };
  }) => {
    uploadMutation.mutate(
      { fileName: fileUpload.file.name, formFile: fileUpload.file.originFileObj, description },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PDF]);
          onClose();
        },
      },
    );
  };

  return (
    <Drawer
      title={t("Upload Document")}
      placement="right"
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose={true}
    >
      <FullHeightForm
        layout="vertical"
        form={form}
        onFinish={submit}
        actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
        actionsAppend={
          <Button
            type="primary"
            htmlType="submit"
            loading={uploadMutation.isLoading}
            disabled={uploadMutation.isLoading}
          >
            {t("Save")}
          </Button>
        }
      >
        <Form.Item
          name="fileUpload"
          label={t("File")}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(File)" }),
              validator: validateFileExists,
            },
            {
              validator: validatePdfSize,
              message: t("File cannot be bigger than 25 MB"),
            },
            {
              validator: validateFileType,
              message: t("File has to be a .pdf"),
            },
          ]}
        >
          <Upload.Dragger maxCount={1} accept=".pdf" customRequest={handleFileFakeRequest}>
            <p>
              <UploadOutlined />
              {t("Drag file here or Browse")}
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item name="description" label={t("Description")}>
          <Input />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
}
