import { Space } from "antd";
import { useQuestionnairesQuery } from "api/queries/questionnaires";
import { Button } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";

import QuestionnaireExportButton from "./components/QuestionnaireExportButton";
import QuestionnairesTable from "./components/QuestionnairesTable";

import styles from "./styles.module.less";

const QuestionnairesListPage = () => {
  const { t } = useTranslation();
  const { id: forumId } = useOutletContext<{ id: number }>();

  const { data: questionnairesExportData, isLoading } = useQuestionnairesQuery({
    forumId,
    orderBy: "createdAt",
    orderDir: "desc",
  });

  return (
    <div>
      <UserGuide.Title title="Questionnaires" articleId="16439280004637-questionnaires" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div className={styles.actions}>
          <Link to="new">
            <Button>{t("addQuestionnaire")}</Button>
          </Link>
          <QuestionnaireExportButton />
        </div>
        <QuestionnairesTable items={questionnairesExportData?.data?.items ?? []} loading={isLoading} />
      </Space>
    </div>
  );
};

export default QuestionnairesListPage;
