import apiClient from "../../../api-client";
import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useUpdateForumCompanyAddressInformationMutation = getWrappedUseMutation(
  apiClient.ForumCompanies.addressInformation.put,
);

export const useUpdateForumCompanySocialLinksMutation = getWrappedUseMutation(apiClient.ForumCompanies.socialLinks.put);

export const useCreateForumCompanyPrimaryLocationMutation = getWrappedUseMutation(
  apiClient.ForumCompanies.primaryLocations.post,
);

export const useUpdateForumCompanyPrimaryLocationMutation = getWrappedUseMutation(
  apiClient.ForumCompanies.primaryLocations.put,
);

export const useDeleteForumCompanyPrimaryLocationMutation = getWrappedUseMutation(
  apiClient.ForumCompanies.primaryLocations.delete,
);

export const useUpdateForumCompanyExhibitingStatusMutation = getWrappedUseMutation(
  apiClient.ForumCompanies.exhibitingStatus.put,
);
