// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
const tenant = process.env.REACT_APP_MSAL_TENANT;
const signInPolicy = process.env.REACT_APP_SIGN_IN_POLICY;
const applicationID = process.env.REACT_APP_APPLICATION_ID;
const tenantSubdomain = tenant.split(".")[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/`;
const signInAuthority = `${instance}${tenant}/${signInPolicy}`;

export const msalConfig = {
  auth: {
    clientId: applicationID,
    authority: signInAuthority,
    knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITY],
    validateAuthority: false,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
    postLogoutRedirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  // system: {
  //   loggerOptions: {
  //     loggerCallback: (level, message, containsPii) => {
  //       if (containsPii) {
  //         return;
  //       }
  //       switch (level) {
  //         case LogLevel.Error:
  //           console.error(message);
  //           return;
  //         case LogLevel.Info:
  //           console.info(message);
  //           return;
  //         case LogLevel.Verbose:
  //           console.debug(message);
  //           return;
  //         case LogLevel.Warning:
  //           console.warn(message);
  //           return;
  //         default:
  //           return;
  //       }
  //     }
  //   }
  // }
};

export const loginRequest = {
  authority: signInAuthority,
  scopes: [`https://${tenant}/${applicationID}/${tenantSubdomain}`],
  prompt: "login",
};
