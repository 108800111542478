/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeItineraryChangeModel } from '../apiModels';
// @ts-ignore
import { AttendeeItineraryCheckInRequest } from '../apiModels';
// @ts-ignore
import { AttendeeItineraryRemovalReasonType } from '../apiModels';
// @ts-ignore
import { AttendeeItinerarySpeakerDeleteOptions } from '../apiModels';
// @ts-ignore
import { AttendeeItineraryValidationResult } from '../apiModels';
// @ts-ignore
import { AvailableTableRequestModel } from '../apiModels';
// @ts-ignore
import { AvailableTimeSlotSessionModel } from '../apiModels';
// @ts-ignore
import { JointCandidate } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeItineraryItemResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeItineraryRemovedItemResponseModel } from '../apiModels';
// @ts-ignore
import { ServiceCallExceptionResponse } from '../apiModels';
/**
 * ForumAttendeeItineraryApi - axios parameter creator
 * @export
 */
export const ForumAttendeeItineraryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export attendee itinerary
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryExportGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryExportGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryExportGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {boolean} [allPages] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryGet: async (forumId: number, attendeeId: number, orderBy?: string, allPages?: boolean, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (allPages !== undefined) {
                localVarQueryParameter['AllPages'] = allPages;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available timeslot sessions available for moving meeting to, based on attendees availability
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet: async (forumId: number, attendeeId: number, itineraryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet', 'attendeeId', attendeeId)
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet', 'itineraryId', itineraryId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/{itineraryId}/available-timeslot-sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItineraryCheckInRequest} [attendeeItineraryCheckInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut: async (forumId: number, attendeeId: number, itineraryId: number, attendeeItineraryCheckInRequest?: AttendeeItineraryCheckInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut', 'attendeeId', attendeeId)
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut', 'itineraryId', itineraryId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/{itineraryId}/check-in`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeItineraryCheckInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes itinerary
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItinerarySpeakerDeleteOptions} [attendeeItinerarySpeakerDeleteOptions] 
         * @param {AttendeeItineraryRemovalReasonType} [attendeeItineraryRemovalReasonType] 
         * @param {string} [otherText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete: async (forumId: number, attendeeId: number, itineraryId: number, attendeeItinerarySpeakerDeleteOptions?: AttendeeItinerarySpeakerDeleteOptions, attendeeItineraryRemovalReasonType?: AttendeeItineraryRemovalReasonType, otherText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete', 'itineraryId', itineraryId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/{itineraryId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (attendeeItinerarySpeakerDeleteOptions !== undefined) {
                localVarQueryParameter['AttendeeItinerarySpeakerDeleteOptions'] = attendeeItinerarySpeakerDeleteOptions;
            }

            if (attendeeItineraryRemovalReasonType !== undefined) {
                localVarQueryParameter['AttendeeItineraryRemovalReasonType'] = attendeeItineraryRemovalReasonType;
            }

            if (otherText !== undefined) {
                localVarQueryParameter['OtherText'] = otherText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get joint candidates based on availability and preferences
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet: async (forumId: number, attendeeId: number, itineraryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet', 'attendeeId', attendeeId)
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet', 'itineraryId', itineraryId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/{itineraryId}/joint-candidates`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates Itinerary item
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItineraryChangeModel} [attendeeItineraryChangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch: async (forumId: number, attendeeId: number, itineraryId: number, attendeeItineraryChangeModel?: AttendeeItineraryChangeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch', 'attendeeId', attendeeId)
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch', 'itineraryId', itineraryId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/{itineraryId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeItineraryChangeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates change of itinerary item
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItineraryChangeModel} [attendeeItineraryChangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch: async (forumId: number, attendeeId: number, itineraryId: number, attendeeItineraryChangeModel?: AttendeeItineraryChangeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch', 'attendeeId', attendeeId)
            // verify required parameter 'itineraryId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch', 'itineraryId', itineraryId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/{itineraryId}/test`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"itineraryId"}}`, encodeURIComponent(String(itineraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeItineraryChangeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {Array<number>} [removedItineraryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet: async (forumId: number, attendeeId: number, removedItineraryIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/removed-items-document`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (removedItineraryIds) {
                localVarQueryParameter['RemovedItineraryIds'] = removedItineraryIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {boolean} [allPages] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet: async (forumId: number, attendeeId: number, orderBy?: string, allPages?: boolean, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/removed-items`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (allPages !== undefined) {
                localVarQueryParameter['AllPages'] = allPages;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} removedItemId 
         * @param {AvailableTableRequestModel} [availableTableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut: async (forumId: number, attendeeId: number, removedItemId: number, availableTableRequestModel?: AvailableTableRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'removedItemId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut', 'removedItemId', removedItemId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/itinerary/removed-items/{removedItemId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"removedItemId"}}`, encodeURIComponent(String(removedItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(availableTableRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeItineraryApi - functional programming interface
 * @export
 */
export const ForumAttendeeItineraryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeItineraryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export attendee itinerary
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryExportGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryExportGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {boolean} [allPages] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryGet(forumId: number, attendeeId: number, orderBy?: string, allPages?: boolean, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeItineraryItemResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryGet(forumId, attendeeId, orderBy, allPages, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available timeslot sessions available for moving meeting to, based on attendees availability
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet(forumId: number, attendeeId: number, itineraryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableTimeSlotSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet(forumId, attendeeId, itineraryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItineraryCheckInRequest} [attendeeItineraryCheckInRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut(forumId: number, attendeeId: number, itineraryId: number, attendeeItineraryCheckInRequest?: AttendeeItineraryCheckInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut(forumId, attendeeId, itineraryId, attendeeItineraryCheckInRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes itinerary
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItinerarySpeakerDeleteOptions} [attendeeItinerarySpeakerDeleteOptions] 
         * @param {AttendeeItineraryRemovalReasonType} [attendeeItineraryRemovalReasonType] 
         * @param {string} [otherText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete(forumId: number, attendeeId: number, itineraryId: number, attendeeItinerarySpeakerDeleteOptions?: AttendeeItinerarySpeakerDeleteOptions, attendeeItineraryRemovalReasonType?: AttendeeItineraryRemovalReasonType, otherText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete(forumId, attendeeId, itineraryId, attendeeItinerarySpeakerDeleteOptions, attendeeItineraryRemovalReasonType, otherText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get joint candidates based on availability and preferences
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet(forumId: number, attendeeId: number, itineraryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JointCandidate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet(forumId, attendeeId, itineraryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates Itinerary item
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItineraryChangeModel} [attendeeItineraryChangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch(forumId: number, attendeeId: number, itineraryId: number, attendeeItineraryChangeModel?: AttendeeItineraryChangeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch(forumId, attendeeId, itineraryId, attendeeItineraryChangeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates change of itinerary item
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} itineraryId 
         * @param {AttendeeItineraryChangeModel} [attendeeItineraryChangeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch(forumId: number, attendeeId: number, itineraryId: number, attendeeItineraryChangeModel?: AttendeeItineraryChangeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeItineraryValidationResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch(forumId, attendeeId, itineraryId, attendeeItineraryChangeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {Array<number>} [removedItineraryIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet(forumId: number, attendeeId: number, removedItineraryIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet(forumId, attendeeId, removedItineraryIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {boolean} [allPages] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet(forumId: number, attendeeId: number, orderBy?: string, allPages?: boolean, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeItineraryRemovedItemResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet(forumId, attendeeId, orderBy, allPages, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} removedItemId 
         * @param {AvailableTableRequestModel} [availableTableRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut(forumId: number, attendeeId: number, removedItemId: number, availableTableRequestModel?: AvailableTableRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut(forumId, attendeeId, removedItemId, availableTableRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeItineraryApi - factory interface
 * @export
 */
export const ForumAttendeeItineraryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeItineraryApiFp(configuration)
    return {
        /**
         * 
         * @summary Export attendee itinerary
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryExportGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryExportGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeItineraryItemResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.allPages, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available timeslot sessions available for moving meeting to, based on attendees availability
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AvailableTimeSlotSessionModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItineraryCheckInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes itinerary
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItinerarySpeakerDeleteOptions, requestParameters.attendeeItineraryRemovalReasonType, requestParameters.otherText, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get joint candidates based on availability and preferences
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<JointCandidate>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates Itinerary item
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatchRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItineraryChangeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates change of itinerary item
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatchRequest, options?: AxiosRequestConfig): AxiosPromise<AttendeeItineraryValidationResult> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItineraryChangeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.removedItineraryIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeItineraryRemovedItemResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.allPages, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.removedItemId, requestParameters.availableTableRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryExportGet operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGetRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryGet operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGetRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly allPages?: boolean

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGetRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet
     */
    readonly itineraryId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPutRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut
     */
    readonly itineraryId: number

    /**
     * 
     * @type {AttendeeItineraryCheckInRequest}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut
     */
    readonly attendeeItineraryCheckInRequest?: AttendeeItineraryCheckInRequest
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDeleteRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete
     */
    readonly itineraryId: number

    /**
     * 
     * @type {AttendeeItinerarySpeakerDeleteOptions}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete
     */
    readonly attendeeItinerarySpeakerDeleteOptions?: AttendeeItinerarySpeakerDeleteOptions

    /**
     * 
     * @type {AttendeeItineraryRemovalReasonType}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete
     */
    readonly attendeeItineraryRemovalReasonType?: AttendeeItineraryRemovalReasonType

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete
     */
    readonly otherText?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGetRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet
     */
    readonly itineraryId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatchRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch
     */
    readonly itineraryId: number

    /**
     * 
     * @type {AttendeeItineraryChangeModel}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch
     */
    readonly attendeeItineraryChangeModel?: AttendeeItineraryChangeModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatchRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatchRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch
     */
    readonly itineraryId: number

    /**
     * 
     * @type {AttendeeItineraryChangeModel}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch
     */
    readonly attendeeItineraryChangeModel?: AttendeeItineraryChangeModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGetRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet
     */
    readonly removedItineraryIds?: Array<number>
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGetRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly allPages?: boolean

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut operation in ForumAttendeeItineraryApi.
 * @export
 * @interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPutRequest
 */
export interface ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut
     */
    readonly removedItemId: number

    /**
     * 
     * @type {AvailableTableRequestModel}
     * @memberof ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut
     */
    readonly availableTableRequestModel?: AvailableTableRequestModel
}

/**
 * ForumAttendeeItineraryApi - object-oriented interface
 * @export
 * @class ForumAttendeeItineraryApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeItineraryApi extends BaseAPI {
    /**
     * 
     * @summary Export attendee itinerary
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryExportGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryExportGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryExportGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.allPages, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available timeslot sessions available for moving meeting to, based on attendees availability
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryItineraryIdAvailableTimeslotSessionsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItineraryCheckInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes itinerary
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItinerarySpeakerDeleteOptions, requestParameters.attendeeItineraryRemovalReasonType, requestParameters.otherText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get joint candidates based on availability and preferences
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates Itinerary item
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdPatchRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItineraryChangeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates change of itinerary item
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatchRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch(requestParameters.forumId, requestParameters.attendeeId, requestParameters.itineraryId, requestParameters.attendeeItineraryChangeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.removedItineraryIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.allPages, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeItineraryApi
     */
    public forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut(requestParameters: ForumAttendeeItineraryApiForumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeItineraryApiFp(this.configuration).forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.removedItemId, requestParameters.availableTableRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

