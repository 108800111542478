import { Col, Layout, Row, Typography } from "components/styleguide";
import React, { FC } from "react";

import AttendeeSearch from "./AttendeeSearch";
import DashboardMenu from "./Menu";

import styles from "./styles.module.less";

interface HeaderProps {
  title?: string;
  displayDates?: string | null;
}

const Header: FC<HeaderProps> = ({ title, displayDates }) => {
  const { Title } = Typography;

  return (
    <Layout.Header className={styles.header}>
      <Row style={{ rowGap: "10px" }} gutter={20} align="top" justify="space-between" wrap>
        <Col flex={"auto"}>
          <Title level={3}>{title ?? "-"}</Title>
          {displayDates && <Title level={5}>{displayDates}</Title>}
        </Col>
        <Col className={styles.searchContainer} lg={12} md={24} sm={24}>
          <AttendeeSearch />
        </Col>
      </Row>
      <Row gutter={20} className={styles.menu} justify="space-around">
        <DashboardMenu />
      </Row>
    </Layout.Header>
  );
};

export default Header;
