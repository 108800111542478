/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BuildingRequestModel } from '../apiModels';
// @ts-ignore
import { BuildingResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * BuildingApi - axios parameter creator
 * @export
 */
export const BuildingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdDelete: async (venueId: number, buildingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdDelete', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdDelete', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a building by id
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdGet: async (venueId: number, buildingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdGet', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdGet', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a building for a venue
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {BuildingRequestModel} [buildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdPut: async (venueId: number, buildingId: number, buildingRequestModel?: BuildingRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdPut', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdPut', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of buildings for a venue
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsGet: async (venueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsGet', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/buildings`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a building for a venue
         * @param {number} venueId 
         * @param {BuildingRequestModel} [buildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsPost: async (venueId: number, buildingRequestModel?: BuildingRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsPost', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/buildings`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buildingRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuildingApi - functional programming interface
 * @export
 */
export const BuildingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuildingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdDelete(venueId: number, buildingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdDelete(venueId, buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a building by id
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdGet(venueId: number, buildingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdGet(venueId, buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a building for a venue
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {BuildingRequestModel} [buildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdPut(venueId: number, buildingId: number, buildingRequestModel?: BuildingRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdPut(venueId, buildingId, buildingRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of buildings for a venue
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsGet(venueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BuildingResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a building for a venue
         * @param {number} venueId 
         * @param {BuildingRequestModel} [buildingRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsPost(venueId: number, buildingRequestModel?: BuildingRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuildingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsPost(venueId, buildingRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuildingApi - factory interface
 * @export
 */
export const BuildingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuildingApiFp(configuration)
    return {
        /**
         * 
         * @param {BuildingApiVenuesVenueIdBuildingsBuildingIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdDelete(requestParameters: BuildingApiVenuesVenueIdBuildingsBuildingIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdDelete(requestParameters.venueId, requestParameters.buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a building by id
         * @param {BuildingApiVenuesVenueIdBuildingsBuildingIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdGet(requestParameters: BuildingApiVenuesVenueIdBuildingsBuildingIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdGet(requestParameters.venueId, requestParameters.buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a building for a venue
         * @param {BuildingApiVenuesVenueIdBuildingsBuildingIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdPut(requestParameters: BuildingApiVenuesVenueIdBuildingsBuildingIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.buildingRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of buildings for a venue
         * @param {BuildingApiVenuesVenueIdBuildingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsGet(requestParameters: BuildingApiVenuesVenueIdBuildingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BuildingResponseModel>> {
            return localVarFp.venuesVenueIdBuildingsGet(requestParameters.venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a building for a venue
         * @param {BuildingApiVenuesVenueIdBuildingsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsPost(requestParameters: BuildingApiVenuesVenueIdBuildingsPostRequest, options?: AxiosRequestConfig): AxiosPromise<BuildingResponseModel> {
            return localVarFp.venuesVenueIdBuildingsPost(requestParameters.venueId, requestParameters.buildingRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdDelete operation in BuildingApi.
 * @export
 * @interface BuildingApiVenuesVenueIdBuildingsBuildingIdDeleteRequest
 */
export interface BuildingApiVenuesVenueIdBuildingsBuildingIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdDelete
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdDelete
     */
    readonly buildingId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdGet operation in BuildingApi.
 * @export
 * @interface BuildingApiVenuesVenueIdBuildingsBuildingIdGetRequest
 */
export interface BuildingApiVenuesVenueIdBuildingsBuildingIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdGet
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdGet
     */
    readonly buildingId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdPut operation in BuildingApi.
 * @export
 * @interface BuildingApiVenuesVenueIdBuildingsBuildingIdPutRequest
 */
export interface BuildingApiVenuesVenueIdBuildingsBuildingIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdPut
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdPut
     */
    readonly buildingId: number

    /**
     * 
     * @type {BuildingRequestModel}
     * @memberof BuildingApiVenuesVenueIdBuildingsBuildingIdPut
     */
    readonly buildingRequestModel?: BuildingRequestModel
}

/**
 * Request parameters for venuesVenueIdBuildingsGet operation in BuildingApi.
 * @export
 * @interface BuildingApiVenuesVenueIdBuildingsGetRequest
 */
export interface BuildingApiVenuesVenueIdBuildingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsGet
     */
    readonly venueId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsPost operation in BuildingApi.
 * @export
 * @interface BuildingApiVenuesVenueIdBuildingsPostRequest
 */
export interface BuildingApiVenuesVenueIdBuildingsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof BuildingApiVenuesVenueIdBuildingsPost
     */
    readonly venueId: number

    /**
     * 
     * @type {BuildingRequestModel}
     * @memberof BuildingApiVenuesVenueIdBuildingsPost
     */
    readonly buildingRequestModel?: BuildingRequestModel
}

/**
 * BuildingApi - object-oriented interface
 * @export
 * @class BuildingApi
 * @extends {BaseAPI}
 */
export class BuildingApi extends BaseAPI {
    /**
     * 
     * @param {BuildingApiVenuesVenueIdBuildingsBuildingIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public venuesVenueIdBuildingsBuildingIdDelete(requestParameters: BuildingApiVenuesVenueIdBuildingsBuildingIdDeleteRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdDelete(requestParameters.venueId, requestParameters.buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a building by id
     * @param {BuildingApiVenuesVenueIdBuildingsBuildingIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public venuesVenueIdBuildingsBuildingIdGet(requestParameters: BuildingApiVenuesVenueIdBuildingsBuildingIdGetRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdGet(requestParameters.venueId, requestParameters.buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a building for a venue
     * @param {BuildingApiVenuesVenueIdBuildingsBuildingIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public venuesVenueIdBuildingsBuildingIdPut(requestParameters: BuildingApiVenuesVenueIdBuildingsBuildingIdPutRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.buildingRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of buildings for a venue
     * @param {BuildingApiVenuesVenueIdBuildingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public venuesVenueIdBuildingsGet(requestParameters: BuildingApiVenuesVenueIdBuildingsGetRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).venuesVenueIdBuildingsGet(requestParameters.venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a building for a venue
     * @param {BuildingApiVenuesVenueIdBuildingsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuildingApi
     */
    public venuesVenueIdBuildingsPost(requestParameters: BuildingApiVenuesVenueIdBuildingsPostRequest, options?: AxiosRequestConfig) {
        return BuildingApiFp(this.configuration).venuesVenueIdBuildingsPost(requestParameters.venueId, requestParameters.buildingRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

