/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentDefinitionTypes } from '../apiModels';
// @ts-ignore
import { DocumentGenerationRunRequestModel } from '../apiModels';
// @ts-ignore
import { DocumentGenerationRunResultModel } from '../apiModels';
// @ts-ignore
import { DocumentGenerationRunStatus } from '../apiModels';
// @ts-ignore
import { DocumentGenerationRunUpdateStatusModel } from '../apiModels';
// @ts-ignore
import { FieldsCannotBeEqualExceptionResponse } from '../apiModels';
// @ts-ignore
import { InvalidEntityStateExceptionResponse } from '../apiModels';
// @ts-ignore
import { ItineraryBookletsDisplaySettingModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseDocumentGenerationRunListItemModel } from '../apiModels';
/**
 * ItineraryBookletApi - axios parameter creator
 * @export
 */
export const ItineraryBookletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The process of generating the individual documents for attendees is stopped
         * @summary Cancel a document generation run
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost: async (forumId: number, documentGenerationRunId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost', 'forumId', forumId)
            // verify required parameter 'documentGenerationRunId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost', 'documentGenerationRunId', documentGenerationRunId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/document-generation-runs/{documentGenerationRunId}/cancel`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentGenerationRunId"}}`, encodeURIComponent(String(documentGenerationRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download document(s) for run
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {Array<number>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet: async (forumId: number, documentGenerationRunId: number, documentIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet', 'forumId', forumId)
            // verify required parameter 'documentGenerationRunId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet', 'documentGenerationRunId', documentGenerationRunId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/document-generation-runs/{documentGenerationRunId}/documents`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentGenerationRunId"}}`, encodeURIComponent(String(documentGenerationRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentIds) {
                localVarQueryParameter['DocumentIds'] = documentIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a document generation run\'s details and generated documents
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet: async (forumId: number, documentGenerationRunId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet', 'forumId', forumId)
            // verify required parameter 'documentGenerationRunId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet', 'documentGenerationRunId', documentGenerationRunId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/document-generation-runs/{documentGenerationRunId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentGenerationRunId"}}`, encodeURIComponent(String(documentGenerationRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve or reject document generation run
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {DocumentGenerationRunUpdateStatusModel} [documentGenerationRunUpdateStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut: async (forumId: number, documentGenerationRunId: number, documentGenerationRunUpdateStatusModel?: DocumentGenerationRunUpdateStatusModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut', 'forumId', forumId)
            // verify required parameter 'documentGenerationRunId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut', 'documentGenerationRunId', documentGenerationRunId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/document-generation-runs/{documentGenerationRunId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentGenerationRunId"}}`, encodeURIComponent(String(documentGenerationRunId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentGenerationRunUpdateStatusModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get document generation runs by forum
         * @param {number} forumId ForumId
         * @param {DocumentGenerationRunStatus} [status] 
         * @param {string} [forumGroupName] 
         * @param {string} [documentDefinition] 
         * @param {string} [createdBy] 
         * @param {string} [approvalStatusUpdatedBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsGet: async (forumId: number, status?: DocumentGenerationRunStatus, forumGroupName?: string, documentDefinition?: string, createdBy?: string, approvalStatusUpdatedBy?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/document-generation-runs`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }

            if (forumGroupName !== undefined) {
                localVarQueryParameter['ForumGroupName'] = forumGroupName;
            }

            if (documentDefinition !== undefined) {
                localVarQueryParameter['DocumentDefinition'] = documentDefinition;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['CreatedBy'] = createdBy;
            }

            if (approvalStatusUpdatedBy !== undefined) {
                localVarQueryParameter['ApprovalStatusUpdatedBy'] = approvalStatusUpdatedBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Queue generation of documents for a group\'s attendees
         * @param {number} forumId 
         * @param {DocumentGenerationRunRequestModel} [documentGenerationRunRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsPost: async (forumId: number, documentGenerationRunRequestModel?: DocumentGenerationRunRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletDocumentGenerationRunsPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/document-generation-runs`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentGenerationRunRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {number} forumId 
         * @param {number} existingForumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletExistingForumIdPut: async (forumId: number, existingForumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletExistingForumIdPut', 'forumId', forumId)
            // verify required parameter 'existingForumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletExistingForumIdPut', 'existingForumId', existingForumId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/{existingForumId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"existingForumId"}}`, encodeURIComponent(String(existingForumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {DocumentDefinitionTypes} documentDefinitionType 
         * @param {number} forumAttendeeId 
         * @param {number} [principalAttendeeId] 
         * @param {number} [forumGroupId] 
         * @param {Array<number>} [forumGroupIds] 
         * @param {number} [sequenceNumber] 
         * @param {number} [piggyBackerExecutiveSequenceNumber] 
         * @param {number} [forumQuestionnaireId] 
         * @param {Array<number>} [removedItineraryIds] 
         * @param {Array<number>} [itineraryIds] 
         * @param {Array<number>} [forumAttendeeIds] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletGenerateDocumentGet: async (forumId: number, documentDefinitionType: DocumentDefinitionTypes, forumAttendeeId: number, principalAttendeeId?: number, forumGroupId?: number, forumGroupIds?: Array<number>, sequenceNumber?: number, piggyBackerExecutiveSequenceNumber?: number, forumQuestionnaireId?: number, removedItineraryIds?: Array<number>, itineraryIds?: Array<number>, forumAttendeeIds?: Array<number>, fileName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletGenerateDocumentGet', 'forumId', forumId)
            // verify required parameter 'documentDefinitionType' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletGenerateDocumentGet', 'documentDefinitionType', documentDefinitionType)
            // verify required parameter 'forumAttendeeId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletGenerateDocumentGet', 'forumAttendeeId', forumAttendeeId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/generate-document`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (documentDefinitionType !== undefined) {
                localVarQueryParameter['DocumentDefinitionType'] = documentDefinitionType;
            }

            if (forumAttendeeId !== undefined) {
                localVarQueryParameter['ForumAttendeeId'] = forumAttendeeId;
            }

            if (principalAttendeeId !== undefined) {
                localVarQueryParameter['PrincipalAttendeeId'] = principalAttendeeId;
            }

            if (forumGroupId !== undefined) {
                localVarQueryParameter['ForumGroupId'] = forumGroupId;
            }

            if (forumGroupIds) {
                localVarQueryParameter['ForumGroupIds'] = forumGroupIds;
            }

            if (sequenceNumber !== undefined) {
                localVarQueryParameter['SequenceNumber'] = sequenceNumber;
            }

            if (piggyBackerExecutiveSequenceNumber !== undefined) {
                localVarQueryParameter['PiggyBackerExecutiveSequenceNumber'] = piggyBackerExecutiveSequenceNumber;
            }

            if (forumQuestionnaireId !== undefined) {
                localVarQueryParameter['ForumQuestionnaireId'] = forumQuestionnaireId;
            }

            if (removedItineraryIds) {
                localVarQueryParameter['RemovedItineraryIds'] = removedItineraryIds;
            }

            if (itineraryIds) {
                localVarQueryParameter['ItineraryIds'] = itineraryIds;
            }

            if (forumAttendeeIds) {
                localVarQueryParameter['ForumAttendeeIds'] = forumAttendeeIds;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletSettingsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletSettingsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/settings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {ItineraryBookletsDisplaySettingModel} [itineraryBookletsDisplaySettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletSettingsPut: async (forumId: number, itineraryBookletsDisplaySettingModel?: ItineraryBookletsDisplaySettingModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItineraryBookletSettingsPut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerary-booklet/settings`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itineraryBookletsDisplaySettingModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItineraryBookletApi - functional programming interface
 * @export
 */
export const ItineraryBookletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItineraryBookletApiAxiosParamCreator(configuration)
    return {
        /**
         * The process of generating the individual documents for attendees is stopped
         * @summary Cancel a document generation run
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost(forumId: number, documentGenerationRunId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost(forumId, documentGenerationRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download document(s) for run
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {Array<number>} [documentIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet(forumId: number, documentGenerationRunId: number, documentIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet(forumId, documentGenerationRunId, documentIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a document generation run\'s details and generated documents
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet(forumId: number, documentGenerationRunId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentGenerationRunResultModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet(forumId, documentGenerationRunId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve or reject document generation run
         * @param {number} forumId 
         * @param {number} documentGenerationRunId 
         * @param {DocumentGenerationRunUpdateStatusModel} [documentGenerationRunUpdateStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut(forumId: number, documentGenerationRunId: number, documentGenerationRunUpdateStatusModel?: DocumentGenerationRunUpdateStatusModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut(forumId, documentGenerationRunId, documentGenerationRunUpdateStatusModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get document generation runs by forum
         * @param {number} forumId ForumId
         * @param {DocumentGenerationRunStatus} [status] 
         * @param {string} [forumGroupName] 
         * @param {string} [documentDefinition] 
         * @param {string} [createdBy] 
         * @param {string} [approvalStatusUpdatedBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletDocumentGenerationRunsGet(forumId: number, status?: DocumentGenerationRunStatus, forumGroupName?: string, documentDefinition?: string, createdBy?: string, approvalStatusUpdatedBy?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseDocumentGenerationRunListItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletDocumentGenerationRunsGet(forumId, status, forumGroupName, documentDefinition, createdBy, approvalStatusUpdatedBy, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Queue generation of documents for a group\'s attendees
         * @param {number} forumId 
         * @param {DocumentGenerationRunRequestModel} [documentGenerationRunRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletDocumentGenerationRunsPost(forumId: number, documentGenerationRunRequestModel?: DocumentGenerationRunRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletDocumentGenerationRunsPost(forumId, documentGenerationRunRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {number} forumId 
         * @param {number} existingForumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletExistingForumIdPut(forumId: number, existingForumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletExistingForumIdPut(forumId, existingForumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {DocumentDefinitionTypes} documentDefinitionType 
         * @param {number} forumAttendeeId 
         * @param {number} [principalAttendeeId] 
         * @param {number} [forumGroupId] 
         * @param {Array<number>} [forumGroupIds] 
         * @param {number} [sequenceNumber] 
         * @param {number} [piggyBackerExecutiveSequenceNumber] 
         * @param {number} [forumQuestionnaireId] 
         * @param {Array<number>} [removedItineraryIds] 
         * @param {Array<number>} [itineraryIds] 
         * @param {Array<number>} [forumAttendeeIds] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletGenerateDocumentGet(forumId: number, documentDefinitionType: DocumentDefinitionTypes, forumAttendeeId: number, principalAttendeeId?: number, forumGroupId?: number, forumGroupIds?: Array<number>, sequenceNumber?: number, piggyBackerExecutiveSequenceNumber?: number, forumQuestionnaireId?: number, removedItineraryIds?: Array<number>, itineraryIds?: Array<number>, forumAttendeeIds?: Array<number>, fileName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletGenerateDocumentGet(forumId, documentDefinitionType, forumAttendeeId, principalAttendeeId, forumGroupId, forumGroupIds, sequenceNumber, piggyBackerExecutiveSequenceNumber, forumQuestionnaireId, removedItineraryIds, itineraryIds, forumAttendeeIds, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletSettingsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItineraryBookletsDisplaySettingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletSettingsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {ItineraryBookletsDisplaySettingModel} [itineraryBookletsDisplaySettingModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItineraryBookletSettingsPut(forumId: number, itineraryBookletsDisplaySettingModel?: ItineraryBookletsDisplaySettingModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItineraryBookletsDisplaySettingModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItineraryBookletSettingsPut(forumId, itineraryBookletsDisplaySettingModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItineraryBookletApi - factory interface
 * @export
 */
export const ItineraryBookletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItineraryBookletApiFp(configuration)
    return {
        /**
         * The process of generating the individual documents for attendees is stopped
         * @summary Cancel a document generation run
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost(requestParameters.forumId, requestParameters.documentGenerationRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download document(s) for run
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet(requestParameters.forumId, requestParameters.documentGenerationRunId, requestParameters.documentIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a document generation run\'s details and generated documents
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<DocumentGenerationRunResultModel> {
            return localVarFp.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet(requestParameters.forumId, requestParameters.documentGenerationRunId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve or reject document generation run
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut(requestParameters.forumId, requestParameters.documentGenerationRunId, requestParameters.documentGenerationRunUpdateStatusModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get document generation runs by forum
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseDocumentGenerationRunListItemModel> {
            return localVarFp.forumsForumIdItineraryBookletDocumentGenerationRunsGet(requestParameters.forumId, requestParameters.status, requestParameters.forumGroupName, requestParameters.documentDefinition, requestParameters.createdBy, requestParameters.approvalStatusUpdatedBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Queue generation of documents for a group\'s attendees
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletDocumentGenerationRunsPost(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItineraryBookletDocumentGenerationRunsPost(requestParameters.forumId, requestParameters.documentGenerationRunRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletExistingForumIdPut(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItineraryBookletExistingForumIdPut(requestParameters.forumId, requestParameters.existingForumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletGenerateDocumentGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsForumIdItineraryBookletGenerateDocumentGet(requestParameters.forumId, requestParameters.documentDefinitionType, requestParameters.forumAttendeeId, requestParameters.principalAttendeeId, requestParameters.forumGroupId, requestParameters.forumGroupIds, requestParameters.sequenceNumber, requestParameters.piggyBackerExecutiveSequenceNumber, requestParameters.forumQuestionnaireId, requestParameters.removedItineraryIds, requestParameters.itineraryIds, requestParameters.forumAttendeeIds, requestParameters.fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletSettingsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletSettingsGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletSettingsGetRequest, options?: AxiosRequestConfig): AxiosPromise<ItineraryBookletsDisplaySettingModel> {
            return localVarFp.forumsForumIdItineraryBookletSettingsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItineraryBookletApiForumsForumIdItineraryBookletSettingsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItineraryBookletSettingsPut(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletSettingsPutRequest, options?: AxiosRequestConfig): AxiosPromise<ItineraryBookletsDisplaySettingModel> {
            return localVarFp.forumsForumIdItineraryBookletSettingsPut(requestParameters.forumId, requestParameters.itineraryBookletsDisplaySettingModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPostRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost
     */
    readonly documentGenerationRunId: number
}

/**
 * Request parameters for forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGetRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet
     */
    readonly documentGenerationRunId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet
     */
    readonly documentIds?: Array<number>
}

/**
 * Request parameters for forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGetRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet
     */
    readonly documentGenerationRunId: number
}

/**
 * Request parameters for forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPutRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut
     */
    readonly documentGenerationRunId: number

    /**
     * 
     * @type {DocumentGenerationRunUpdateStatusModel}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut
     */
    readonly documentGenerationRunUpdateStatusModel?: DocumentGenerationRunUpdateStatusModel
}

/**
 * Request parameters for forumsForumIdItineraryBookletDocumentGenerationRunsGet operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGetRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGetRequest {
    /**
     * ForumId
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {DocumentGenerationRunStatus}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly status?: DocumentGenerationRunStatus

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly forumGroupName?: string

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly documentDefinition?: string

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly createdBy?: string

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly approvalStatusUpdatedBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsForumIdItineraryBookletDocumentGenerationRunsPost operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPostRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {DocumentGenerationRunRequestModel}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPost
     */
    readonly documentGenerationRunRequestModel?: DocumentGenerationRunRequestModel
}

/**
 * Request parameters for forumsForumIdItineraryBookletExistingForumIdPut operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPutRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPut
     */
    readonly existingForumId: number
}

/**
 * Request parameters for forumsForumIdItineraryBookletGenerateDocumentGet operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGetRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly forumId: number

    /**
     * 
     * @type {DocumentDefinitionTypes}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly documentDefinitionType: DocumentDefinitionTypes

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly forumAttendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly principalAttendeeId?: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly forumGroupId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly sequenceNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly piggyBackerExecutiveSequenceNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly forumQuestionnaireId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly removedItineraryIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly itineraryIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly forumAttendeeIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGet
     */
    readonly fileName?: string
}

/**
 * Request parameters for forumsForumIdItineraryBookletSettingsGet operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletSettingsGetRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletSettingsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletSettingsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdItineraryBookletSettingsPut operation in ItineraryBookletApi.
 * @export
 * @interface ItineraryBookletApiForumsForumIdItineraryBookletSettingsPutRequest
 */
export interface ItineraryBookletApiForumsForumIdItineraryBookletSettingsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletSettingsPut
     */
    readonly forumId: number

    /**
     * 
     * @type {ItineraryBookletsDisplaySettingModel}
     * @memberof ItineraryBookletApiForumsForumIdItineraryBookletSettingsPut
     */
    readonly itineraryBookletsDisplaySettingModel?: ItineraryBookletsDisplaySettingModel
}

/**
 * ItineraryBookletApi - object-oriented interface
 * @export
 * @class ItineraryBookletApi
 * @extends {BaseAPI}
 */
export class ItineraryBookletApi extends BaseAPI {
    /**
     * The process of generating the individual documents for attendees is stopped
     * @summary Cancel a document generation run
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPostRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost(requestParameters.forumId, requestParameters.documentGenerationRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download document(s) for run
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet(requestParameters.forumId, requestParameters.documentGenerationRunId, requestParameters.documentIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a document generation run\'s details and generated documents
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet(requestParameters.forumId, requestParameters.documentGenerationRunId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve or reject document generation run
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut(requestParameters.forumId, requestParameters.documentGenerationRunId, requestParameters.documentGenerationRunUpdateStatusModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get document generation runs by forum
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletDocumentGenerationRunsGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletDocumentGenerationRunsGet(requestParameters.forumId, requestParameters.status, requestParameters.forumGroupName, requestParameters.documentDefinition, requestParameters.createdBy, requestParameters.approvalStatusUpdatedBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Queue generation of documents for a group\'s attendees
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletDocumentGenerationRunsPost(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletDocumentGenerationRunsPostRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletDocumentGenerationRunsPost(requestParameters.forumId, requestParameters.documentGenerationRunRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletExistingForumIdPut(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletExistingForumIdPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletExistingForumIdPut(requestParameters.forumId, requestParameters.existingForumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletGenerateDocumentGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletGenerateDocumentGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletGenerateDocumentGet(requestParameters.forumId, requestParameters.documentDefinitionType, requestParameters.forumAttendeeId, requestParameters.principalAttendeeId, requestParameters.forumGroupId, requestParameters.forumGroupIds, requestParameters.sequenceNumber, requestParameters.piggyBackerExecutiveSequenceNumber, requestParameters.forumQuestionnaireId, requestParameters.removedItineraryIds, requestParameters.itineraryIds, requestParameters.forumAttendeeIds, requestParameters.fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletSettingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletSettingsGet(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletSettingsGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletSettingsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItineraryBookletApiForumsForumIdItineraryBookletSettingsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletApi
     */
    public forumsForumIdItineraryBookletSettingsPut(requestParameters: ItineraryBookletApiForumsForumIdItineraryBookletSettingsPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletApiFp(this.configuration).forumsForumIdItineraryBookletSettingsPut(requestParameters.forumId, requestParameters.itineraryBookletsDisplaySettingModel, options).then((request) => request(this.axios, this.basePath));
    }
}

