import { ForumSelectionSiteApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumSelectionSiteApi);

const ForumSelectionSite = {
  getAll: api.forumForumIdSelectionSitesGet,
  get: api.forumForumIdSelectionSitesSelectionSiteIdGet,
  post: api.forumForumIdSelectionSitesPost,
  put: api.forumForumIdSelectionSitesSelectionSiteIdPut,
  delete: api.forumForumIdSelectionSitesSelectionSiteIdDelete,
  content: {
    get: api.forumForumIdSelectionSitesSelectionSiteIdContentGet,
    put: api.forumForumIdSelectionSitesSelectionSiteIdContentPut,
    pages: {
      post: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPost,
      delete: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete,
      order: {
        put: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut,
      },
      items: {
        post: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost,
        put: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut,
        delete: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete,
        order: {
          put: api.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut,
        },
      },
    },
  },
  export: {
    get: api.forumForumIdSelectionSitesSelectionSiteIdExportGet,
  },
  status: {
    put: api.forumForumIdSelectionSitesSelectionSiteIdStatusPut,
  },
  predefined: {
    eventChoices: {
      getAll: api.forumSelectionSitesPredefinedEventchoicesGet,
    },
  },
};

export default ForumSelectionSite;
