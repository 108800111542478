import { useGetForumGroupsQuery } from "api/queries/groups";
import { Select } from "components/styleguide";
import React from "react";

interface GroupSelectProps {
  forumId: number;
}

const GroupSelect: React.FC<GroupSelectProps> = ({ forumId, ...props }) => {
  const { isLoading, data: { data: groups = [] } = {} } = useGetForumGroupsQuery({ forumId });

  return (
    <Select
      {...props}
      loading={isLoading}
      showSearch
      optionFilterProp={"label"}
      options={groups.map(({ id, name }) => ({ value: id, label: name }))}
    />
  );
};

export default GroupSelect;
