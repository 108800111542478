import { FormInstance } from "antd";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Select, YesNo } from "components/styleguide";
import { CompanyModel, CountryModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const NewCompanyDrawer = ({
  visible,
  form,
  isLoading,
  countries,
  onClose,
  onFinish,
}: {
  visible: boolean;
  form: FormInstance;
  isLoading: boolean;
  countries: CountryModel[] | undefined;
  onClose: () => void;
  onFinish: (companyModel: CompanyModel) => void;
}) => {
  const { t } = useTranslation();

  const countryOptions = countries?.map((c) => {
    return (
      <Option value={c.name} key={c.isoCode}>
        {c.name}
      </Option>
    );
  });

  return (
    <Drawer
      title={t("companyDrawer.addNewCompany")}
      placement="right"
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
    >
      <FullHeightForm
        id="addCompanyForm"
        name="add-new-company"
        labelCol={{ span: 8 }}
        form={form}
        actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("companyDrawer.submit")}
          </Button>
        }
        onFinish={onFinish}
      >
        <Form.Item
          label={t("companyName")}
          name="name"
          rules={[
            {
              required: true,
              message: t("companyNameRequired"),
            },
            {
              max: 100,
              message: t("The character limit is {{count}}", { count: 100 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("address1")}
          name="addressLine1"
          rules={[
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("address2")}
          name="addressLine2"
          rules={[
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("city")}
          name="city"
          rules={[
            {
              max: 30,
              message: t("The character limit is {{count}}", { count: 30 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("county")}
          name="county"
          rules={[
            {
              max: 30,
              message: t("The character limit is {{count}}", { count: 30 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("postcode")}
          name="postcode"
          rules={[
            {
              max: 10,
              message: t("The character limit is {{count}}", { count: 10 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("country")} name="country">
          <Select showSearch allowClear>
            {countryOptions}
          </Select>
        </Form.Item>
        <Form.Item label={t("grade")} name="grade">
          <Select>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {t("companyDrawer.newCompanyDrawerGrades", { returnObjects: true }).map(
              (grade: { value: string; label: string; key: string }) => (
                <Option value={grade.value} key={grade.key}>
                  {grade.label}
                </Option>
              ),
            )}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("phone")}
          name="phone"
          rules={[
            {
              max: 30,
              message: t("The character limit is {{count}}", { count: 30 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("website")}
          name="website"
          rules={[
            {
              max: 100,
              message: t("The character limit is {{count}}", { count: 100 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("email")}
          name="email"
          rules={[
            {
              max: 100,
              message: t("The character limit is {{count}}", { count: 100 }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("exhibitingStatus")} name="exhibitingStatus">
          <YesNo />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default NewCompanyDrawer;
