import { ForumGroupTasksApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumGroupTasksApi);

const ForumGroupTasks = {
  getAll: api.forumForumIdGroupsGroupIdTasksGet,
  post: api.forumForumIdGroupsGroupIdTasksPost,
  task: {
    put: api.forumForumIdGroupsGroupIdTasksTaskIdPut,
  },
  attendees: {
    get: api.forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet,
  },
  statuses: {
    export: {
      get: api.forumForumIdGroupsGroupIdTasksAttendeesExportGet,
    },
  },
};

export default ForumGroupTasks;
