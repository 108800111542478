import { useQueryClient } from "@tanstack/react-query";
import { ColProps } from "antd";
import { useUpdateAttendeeContactDetailsMutation } from "api/mutations/attendees";
import { useAttendeeContactDetailsQuery, useAttendeeReContactsQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { Button, Col, Divider, Form, Input, Row, Space, Spin } from "components/styleguide";
import AttendeeGrade from "enums/AttendeeGrade";
import {
  AttendeeContactDetailsInfoRequestModel,
  AttendeeContactDetailsModel,
  AttendeeReContactResponse,
  AttendeeStatus,
  GradeEnum,
} from "generated/api";
import { toNumber } from "lodash";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getFormFields } from "./helpers";

import styles from "./styles.module.less";

interface AttendeeContactDetailsProps {
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
}

type FormValues = {
  firstName: string;
  lastName: string;
  type: string;
  jobTitle?: string | null;
  title?: string | null;
  phone?: string | null;
  mobilePhone?: string | null;
  salutation?: string | null;
  grade?: GradeEnum;
  batch?: number;
  gender?: string | null;
  reContactId?: number | null;
  attendStatus?: AttendeeStatus;
  secretaryFirstName?: string | null;
  secretaryLastName?: string | null;
  secretaryEmail?: string | null;
};

const AttendeeContactDetails: FC<AttendeeContactDetailsProps> = ({ isEditing, setIsEditing }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const forumId = toNumber(useParams().id);
  const attendeeId = toNumber(useParams().attendeeId);
  const queryClient = useQueryClient();

  const [contactDetails, setContactDetails] = useState<AttendeeContactDetailsModel>();
  const [reContacts, setReContacts] = useState<AttendeeReContactResponse[]>([]);

  const mutation = useUpdateAttendeeContactDetailsMutation();

  const query = useAttendeeContactDetailsQuery(
    { forumId: forumId, attendeeId: attendeeId },
    {
      onSuccess: ({ data }: { data: AttendeeContactDetailsModel }) => {
        setContactDetails(data);
      },
    },
  );

  const reContactFirstName = reContacts.filter((c) => c.id === contactDetails?.reContactId)[0]?.firstName;
  const reContactLastName = reContacts.filter((c) => c.id === contactDetails?.reContactId)[0]?.lastName;

  useAttendeeReContactsQuery(
    { forumId, attendeeId },
    {
      onSuccess: ({ data }) => {
        setReContacts(data);
      },
      enabled: !!contactDetails,
    },
  );

  const { data: { data: { items: participationCodes = [] } = {} } = {} } = useParticipationCodesQuery({
    ignorePagination: true,
  });

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const submit = async (values: FormValues) => {
    const model = {
      participationCode: values.type,
      ...values,
    } as AttendeeContactDetailsInfoRequestModel;

    if (typeof model.grade === "string") {
      model.grade = AttendeeGrade[model.grade];
    }

    mutation.mutate(
      {
        forumId,
        attendeeId,
        attendeeContactDetailsInfoRequestModel: model,
      },
      {
        onSuccess: () => {
          setIsEditing(false);

          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_CONTACT_DETAILS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_HISTORY_INFORMATION]);
        },
      },
    );
  };

  return (
    <div>
      {contactDetails && !query.isLoading ? (
        <Form
          form={form}
          onFinish={submit}
          id="contactInfoForm"
          name="contact-info"
          className={styles.contactDetails}
          labelAlign="left"
          labelCol={{ justify: "left", span: 13 } as ColProps}
          wrapperCol={{ justify: "right", span: 11 } as ColProps}
          labelWrap
          colon={false}
        >
          <Row gutter={80}>
            {getFormFields(
              t,
              reContacts,
              participationCodes,
              reContactFirstName,
              reContactLastName,
              contactDetails.batch,
            ).map((f) => (
              <Col key={f.dataIndex} className={styles.formItemCol} md={24} lg={12}>
                {f.title ? (
                  <Form.Item
                    initialValue={
                      "initalValue" in f
                        ? f.initalValue
                        : contactDetails[f.dataIndex as keyof AttendeeContactDetailsModel]
                    }
                    label={t(f.title)}
                    name={f.dataIndex}
                    className={styles.formItem}
                  >
                    {!f.readonly && isEditing ? (
                      f.component ?? <Input maxLength={f.maxLength} />
                    ) : f.render ? (
                      f.render(contactDetails[f.dataIndex as keyof AttendeeContactDetailsModel])
                    ) : (
                      <label>
                        {f.label
                          ? f.label
                          : (contactDetails[f.dataIndex as keyof AttendeeContactDetailsModel] as string) ?? "-"}
                      </label>
                    )}
                  </Form.Item>
                ) : (
                  <Space />
                )}
                {f.title && <Divider />}
              </Col>
            ))}
          </Row>
          {isEditing && (
            <Row gutter={80}>
              <Col span={1}>
                <Button loading={mutation.isLoading} htmlType="submit" type="primary">
                  {t("Save")}
                </Button>
              </Col>
              <Col span={1}>
                <Button onClick={cancelEdit}> {t("Cancel")}</Button>
              </Col>
            </Row>
          )}
        </Form>
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default AttendeeContactDetails;
