/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeAvailableToBookModel } from '../apiModels';
// @ts-ignore
import { AttendeeUnderRatioModel } from '../apiModels';
// @ts-ignore
import { BookedMeetingResponseModel } from '../apiModels';
// @ts-ignore
import { MeetingAttendeeRequestModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * ForumAttendeeMeetingsApi - axios parameter creator
 * @export
 */
export const ForumAttendeeMeetingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Creates a meeting
         * @param {number} forumId 
         * @param {number} timeSlotSessionId 
         * @param {number} executiveAttendeeId 
         * @param {MeetingAttendeeRequestModel} [meetingAttendeeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost: async (forumId: number, timeSlotSessionId: number, executiveAttendeeId: number, meetingAttendeeRequestModel?: MeetingAttendeeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost', 'forumId', forumId)
            // verify required parameter 'timeSlotSessionId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost', 'timeSlotSessionId', timeSlotSessionId)
            // verify required parameter 'executiveAttendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost', 'executiveAttendeeId', executiveAttendeeId)
            const localVarPath = `/forum/{forumId}/attendee-meetings/{timeSlotSessionId}/executives-under-ratio/{executiveAttendeeId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeSlotSessionId"}}`, encodeURIComponent(String(timeSlotSessionId)))
                .replace(`{${"executiveAttendeeId"}}`, encodeURIComponent(String(executiveAttendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingAttendeeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} timeSlotSessionId 
         * @param {number} executiveAttendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet: async (forumId: number, timeSlotSessionId: number, executiveAttendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet', 'forumId', forumId)
            // verify required parameter 'timeSlotSessionId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet', 'timeSlotSessionId', timeSlotSessionId)
            // verify required parameter 'executiveAttendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet', 'executiveAttendeeId', executiveAttendeeId)
            const localVarPath = `/forum/{forumId}/attendee-meetings/{timeSlotSessionId}/executives-under-ratio/{executiveAttendeeId}/available-attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeSlotSessionId"}}`, encodeURIComponent(String(timeSlotSessionId)))
                .replace(`{${"executiveAttendeeId"}}`, encodeURIComponent(String(executiveAttendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of executives under ratio
         * @param {number} forumId 
         * @param {number} timeSlotSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet: async (forumId: number, timeSlotSessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet', 'forumId', forumId)
            // verify required parameter 'timeSlotSessionId' is not null or undefined
            assertParamExists('forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet', 'timeSlotSessionId', timeSlotSessionId)
            const localVarPath = `/forum/{forumId}/attendee-meetings/{timeSlotSessionId}/executives-under-ratio`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeSlotSessionId"}}`, encodeURIComponent(String(timeSlotSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeMeetingsApi - functional programming interface
 * @export
 */
export const ForumAttendeeMeetingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeMeetingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Creates a meeting
         * @param {number} forumId 
         * @param {number} timeSlotSessionId 
         * @param {number} executiveAttendeeId 
         * @param {MeetingAttendeeRequestModel} [meetingAttendeeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost(forumId: number, timeSlotSessionId: number, executiveAttendeeId: number, meetingAttendeeRequestModel?: MeetingAttendeeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookedMeetingResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost(forumId, timeSlotSessionId, executiveAttendeeId, meetingAttendeeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} timeSlotSessionId 
         * @param {number} executiveAttendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet(forumId: number, timeSlotSessionId: number, executiveAttendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeAvailableToBookModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet(forumId, timeSlotSessionId, executiveAttendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of executives under ratio
         * @param {number} forumId 
         * @param {number} timeSlotSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet(forumId: number, timeSlotSessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeUnderRatioModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet(forumId, timeSlotSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeMeetingsApi - factory interface
 * @export
 */
export const ForumAttendeeMeetingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeMeetingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Creates a meeting
         * @param {ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost(requestParameters: ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPostRequest, options?: AxiosRequestConfig): AxiosPromise<BookedMeetingResponseModel> {
            return localVarFp.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost(requestParameters.forumId, requestParameters.timeSlotSessionId, requestParameters.executiveAttendeeId, requestParameters.meetingAttendeeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet(requestParameters: ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeAvailableToBookModel>> {
            return localVarFp.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet(requestParameters.forumId, requestParameters.timeSlotSessionId, requestParameters.executiveAttendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of executives under ratio
         * @param {ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet(requestParameters: ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeUnderRatioModel>> {
            return localVarFp.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet(requestParameters.forumId, requestParameters.timeSlotSessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost operation in ForumAttendeeMeetingsApi.
 * @export
 * @interface ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPostRequest
 */
export interface ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost
     */
    readonly timeSlotSessionId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost
     */
    readonly executiveAttendeeId: number

    /**
     * 
     * @type {MeetingAttendeeRequestModel}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost
     */
    readonly meetingAttendeeRequestModel?: MeetingAttendeeRequestModel
}

/**
 * Request parameters for forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet operation in ForumAttendeeMeetingsApi.
 * @export
 * @interface ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGetRequest
 */
export interface ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet
     */
    readonly timeSlotSessionId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet
     */
    readonly executiveAttendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet operation in ForumAttendeeMeetingsApi.
 * @export
 * @interface ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGetRequest
 */
export interface ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet
     */
    readonly timeSlotSessionId: number
}

/**
 * ForumAttendeeMeetingsApi - object-oriented interface
 * @export
 * @class ForumAttendeeMeetingsApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeMeetingsApi extends BaseAPI {
    /**
     * 
     * @summary Creates a meeting
     * @param {ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingsApi
     */
    public forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost(requestParameters: ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingsApiFp(this.configuration).forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost(requestParameters.forumId, requestParameters.timeSlotSessionId, requestParameters.executiveAttendeeId, requestParameters.meetingAttendeeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingsApi
     */
    public forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet(requestParameters: ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingsApiFp(this.configuration).forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet(requestParameters.forumId, requestParameters.timeSlotSessionId, requestParameters.executiveAttendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of executives under ratio
     * @param {ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingsApi
     */
    public forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet(requestParameters: ForumAttendeeMeetingsApiForumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingsApiFp(this.configuration).forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet(requestParameters.forumId, requestParameters.timeSlotSessionId, options).then((request) => request(this.axios, this.basePath));
    }
}

