const ErrorTypes = {
  DuplicateItem: 1,
  ObjectNotFound: 2,
  ValueOutOfRange: 3,
  InvalidTimePeriod: 4,
  AssociatedObjectNotFound: 5,
  InvalidEntityState: 6,
  UnknownObject: 7,
  FieldsCannotBeEqual: 8,
  ReferencedObjectCannotBeDeleted: 9,
  VisualSpeakerDocumentAssociatedToBothSeminarAndItsSessions: 10,
  NotAllValuesProvided: 11,
  InvalidModelState: 12,
  ServiceCallException: 13,
  ObjectModificationNotAllowed: 15,
  ObjectHasAssociatedItemsException: 19,
  ChildrenNotFound: 21,

  isOfType(response, errorType) {
    return response?.data?.code === errorType;
  },
};

export default ErrorTypes;
