import { useCreateForumBuildingMutation, useUpdateForumBuildingMutation } from "api/mutations/buildings";
import { useForumBuildingsQuery } from "api/queries/forumBuilding";
import { useGetForumVenueQuery } from "api/queries/forumVenues";
import VenueLayout from "layouts/VenueLayout";
import { toNumber } from "lodash";
import { Location, useLocation, useNavigate, useParams } from "react-router-dom";

interface ForumVenueLocation extends Location {
  state: {
    venueName?: string;
  };
}

const ForumVenueLayout = () => {
  const navigate = useNavigate();
  const location = useLocation() as ForumVenueLocation;
  const forumId = toNumber(useParams().id);

  const { data: { data: venue = {} } = {} } = useGetForumVenueQuery(
    { forumId },
    {
      onSuccess: ({ data }) => {
        if (!location.state || !location.state.venueName) {
          navigate(location.pathname, {
            replace: true,
            state: { ...location.state, venueName: data.name },
          });
        }
      },
    },
  );

  const createBuildingMutation = useCreateForumBuildingMutation();
  const updateBuildingMutation = useUpdateForumBuildingMutation();

  const { data: { data: buildingsData = {} } = {}, isLoading } = useForumBuildingsQuery({
    forumId: toNumber(forumId),
  });

  return (
    <VenueLayout
      venue={venue}
      buildingsData={buildingsData}
      createBuildingMutation={createBuildingMutation}
      updateBuildingMutation={updateBuildingMutation}
      isBuildingsLoading={isLoading}
      isForumBuilding
    />
  );
};

export default ForumVenueLayout;
