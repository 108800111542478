import { useGetForumGroupsQuery } from "api/queries/groups";
import { Select, Space, Typography } from "components/styleguide";
import { ForumGroupResponseModel } from "generated/api";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import LinkToReport from "../components/LinkToReport";
import { DefaultSortOptions } from "../constants";
import SortOrderOption from "../OptionTypes/SortByOption";

const PreCrunchSummaryOfAllPreferencesOptions = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [groups, setGroups] = useState<ForumGroupResponseModel[]>();
  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Company);
  const [groupId, setGroupId] = useState<number>();

  const { isLoading: isGroupsLoading } = useGetForumGroupsQuery(
    {
      forumId,
    },
    {
      onSuccess: ({ data }) =>
        setGroups(data.filter(({ name }) => ["Delegates", "Executives", "Speakers"].includes(name))),
    },
  );

  return (
    <Space align="end">
      <SortOrderOption options={DefaultSortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />

      <Space direction={"vertical"}>
        <Typography.Text>{t("Group")}</Typography.Text>
        <Select
          style={{ minWidth: "200px" }}
          placeholder={t("Select Group")}
          loading={isGroupsLoading}
          value={groupId}
          onSelect={(id: number) => setGroupId(id)}
          options={groups?.map(({ id: value, name: label }) => ({ value, label }))}
        />
      </Space>

      <LinkToReport parameters={{ OrderBy: orderBy, ForumGroupId: groupId }} disabled={!groupId} />
    </Space>
  );
};

export default PreCrunchSummaryOfAllPreferencesOptions;
