import { UploadOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { UploadFile } from "antd";
import { useUploadForumDocumentDefinitionWatermark } from "api/mutations/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Upload } from "components/styleguide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { handleFileFakeRequest } from "utils/documentUtils";

const validateFileSize = (_: unknown, input: { file: File; fileList: FileList }) => {
  return input.file.size > 2 * 1024 * 1024 ? Promise.reject() : Promise.resolve();
};

const validateFileType = (_: unknown, input: { file: File; fileList: FileList }) => {
  const fileExtension = input.file.name.split(".").reverse()[0];

  return fileExtension === "png" ? Promise.resolve() : Promise.reject();
};

const validateFileExists = (_: unknown, input: { file: File; fileList: FileList }) => {
  return input.fileList.length === 0 ? Promise.reject() : Promise.resolve();
};

interface UploadWatermarkDrawerProps {
  forumId: number;
  isVisible: boolean;
  setIsUploadDrawerVisible: (bool: boolean) => void;
}

const UploadWatermarkDrawer: FC<UploadWatermarkDrawerProps> = ({ forumId, isVisible, setIsUploadDrawerVisible }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const uploadMutation = useUploadForumDocumentDefinitionWatermark();

  const onClose = () => {
    if (uploadMutation.isLoading) {
      return;
    }

    form.resetFields();
    setIsUploadDrawerVisible(false);
  };

  const submit = ({
    description,
    fileUpload,
  }: {
    description: string;
    fileUpload: { file: UploadFile; fileList: FileList };
  }) => {
    uploadMutation.mutate(
      { forumId, formFile: fileUpload.file.originFileObj, description },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_WATERMARKS]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer
      title={t("Upload Watermark")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose={true}
    >
      <FullHeightForm
        layout="vertical"
        form={form}
        onFinish={submit}
        actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
        actionsAppend={
          <Button
            type="primary"
            htmlType="submit"
            loading={uploadMutation.isLoading}
            disabled={uploadMutation.isLoading}
          >
            {t("Save")}
          </Button>
        }
      >
        <Form.Item
          name="fileUpload"
          label={t("Upload File")}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(File)" }),
              validator: validateFileExists,
            },
            {
              validator: validateFileSize,
              message: t("File cannot be bigger than 2 MB"),
            },
            {
              validator: validateFileType,
              message: t("File has to be a .png"),
            },
          ]}
        >
          <Upload.Dragger maxCount={1} accept=".png" customRequest={handleFileFakeRequest}>
            <p>
              <UploadOutlined />
              {t("Drag file here or Browse")}
            </p>
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          name="description"
          label={t("Description")}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Name)" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default UploadWatermarkDrawer;
