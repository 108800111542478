import { ParticipationCodeApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ParticipationCodeApi);

const ParticipationCodes = {
  get: api.participationcodesGet,
  post: api.participationcodesPost,
  put: api.participationcodesPut,
};

export default ParticipationCodes;
