import { Col, Divider, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useMemo } from "react";

import QuestionnaireStatus from "../../../../../../../backend-models/questionnaireStatus";
import QuestionnaireType from "../../../../../../../backend-models/questionnaireType";

const formatType = "L";

const QuestionnaireDetails = ({ questionnaire }) => {
  const { forumGroups } = questionnaire;

  const groups = useMemo(() => {
    return forumGroups?.map(({ name }) => name).join(", ");
  }, [forumGroups]);

  return (
    <Row gutter={24}>
      <Col className="gutter-row" span={12} lg={24} md={24}>
        <Row gutter={6}>
          <Col className="gutter-row" span={12}>
            <label htmlFor="questionnaireName">Questionnaire name</label>
          </Col>
          <Col className="gutter-row" span={12}>
            <span id="questionnaireName">{questionnaire.name || "-"}</span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={6}>
          <Col className="gutter-row" span={12}>
            <label htmlFor="availableFrom">Available from</label>
          </Col>
          <Col className="gutter-row" span={12}>
            <span id="availableFrom">{moment(questionnaire.availableFrom).format(formatType) || "-"}</span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={6}>
          <Col className="gutter-row" span={12}>
            <label htmlFor="availableTo">Available to</label>
          </Col>
          <Col className="gutter-row" span={12}>
            <span id="availableTo">{moment(questionnaire.availableTo).format(formatType) || "-"}</span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={6}>
          <Col className="gutter-row" span={12}>
            <label htmlFor="questionnaireType">Questionnaire type</label>
          </Col>
          <Col className="gutter-row" span={12}>
            <span id="questionnaireType">{new QuestionnaireType(questionnaire.type).toString() || "-"}</span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={6}>
          <Col className="gutter-row" span={12}>
            <label htmlFor="questionnaireGroup">Questionnaire group</label>
          </Col>
          <Col className="gutter-row" span={12}>
            <span id="questionnaireGroup">{groups || "-"}</span>
          </Col>
        </Row>
        <Divider />
        <Row gutter={6}>
          <Col className="gutter-row" span={12}>
            <label htmlFor="questionnaireStatus">Questionnaire status</label>
          </Col>
          <Col className="gutter-row" span={12}>
            <span id="questionnaireStatus">{new QuestionnaireStatus(questionnaire.status).toString() || "-"}</span>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

QuestionnaireDetails.propTypes = {
  questionnaire: PropTypes.shape({
    name: PropTypes.string,
    availableFrom: PropTypes.string,
    availableTo: PropTypes.string,
    type: PropTypes.oneOf(QuestionnaireType.LIST),
    status: PropTypes.oneOf(QuestionnaireStatus.LIST),
    forumGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
};

export default QuestionnaireDetails;
