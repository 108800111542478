import { ExclamationCircleOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnsType } from "antd/lib/table";
import {
  useDeleteForumDocumentDefinitionSectionMutation,
  useForumDocumentDefinitionSectionReorderMutation,
} from "api/mutations/forumDocumentDefinition";
import { useForumDocumentDefinitionSectionsQuery } from "api/queries/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import { DraggableTable } from "components/DraggableTable";
import { Modal, Space } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import {
  DocumentDefinitionTypes,
  ForumDocumentDefinitionSectionListItemModel,
  ForumDocumentDefinitionSectionResponseModel,
} from "generated/api";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { buildColumn } from "utils/columnUtils";

interface DocumentDefinitionsTableProps {
  documentDefinitionType: DocumentDefinitionTypes | null;
  onEdit: (documentDefinitionSectionId: number) => void;
}

const DocumentDefinitionsTable: React.FC<DocumentDefinitionsTableProps> = ({
  documentDefinitionType: documentDefinitiontype,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const forumId = toNumber(id);
  const queryClient = useQueryClient();

  const [documentDefinitionSections, setDocumentDefinitionSections] =
    useState<ForumDocumentDefinitionSectionListItemModel[]>();

  const deleteMutation = useDeleteForumDocumentDefinitionSectionMutation();

  const { isLoading } = useForumDocumentDefinitionSectionsQuery(
    { forumId, type: documentDefinitiontype as DocumentDefinitionTypes },
    {
      onSuccess: ({ data }) => {
        setDocumentDefinitionSections(data.sort((a, b) => a.order - b.order));
      },
      enabled: !!documentDefinitiontype,
    },
  );

  const reorderMutation = useForumDocumentDefinitionSectionReorderMutation();

  const YesNoColumn = (showInContents: boolean) => (showInContents ? t("yes") : t("no"));

  const confirmDelete = (documentDefinitionSectionId: number) => {
    Modal.confirm({
      title: t("Delete Document Definition"),
      content: t("Do you want to proceed with deletion of this section?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        deleteMutation.mutate(
          {
            forumId,
            documentDefinitionSectionId,
          },
          {
            onSuccess: () => {
              queryClient.resetQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS]);
            },
          },
        );
      },
    });
  };

  const columns: ColumnsType<ForumDocumentDefinitionSectionResponseModel> = [
    buildColumn(t("Contents Entry Title"), "contentsEntryTitle"),
    buildColumn(t("Section Name"), "sectionName"),
    buildColumn(t("Show in Contents"), "showInContents", {
      render: (showInContents: boolean) => YesNoColumn(showInContents),
    }),
    buildColumn(t("Watermark"), "watermarkDescription", {
      render: (watermarkDescription: string | null) => watermarkDescription || "None",
    }),
    buildColumn("", "id", {
      render: (sectionId: number) => {
        return <TableRowActionButtons onEdit={() => onEdit(sectionId)} onDelete={() => confirmDelete(sectionId)} />;
      },
    }),
  ];

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (!(active.id !== over?.id && active && over)) {
      return;
    }

    const draggedEntry = documentDefinitionSections?.find((i) => i.id === active.id);
    const overEntry = documentDefinitionSections?.find((i) => i.id === over.id);

    if (!draggedEntry || !overEntry) {
      return;
    }

    setDocumentDefinitionSections((previous) => {
      if (!previous) return;
      const activeIndex = previous.findIndex((i) => i.id === active.id);
      const overIndex = previous.findIndex((i) => i.id === over?.id);
      return arrayMove(previous, activeIndex, overIndex);
    });

    reorderMutation.mutate({
      forumId,
      documentDefinitionSectionId: draggedEntry.id,
      reorderRequestModel: {
        order: overEntry.order,
      },
    });
  };

  const dataSource = (documentDefinitiontype && documentDefinitionSections) || [];

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <DraggableTable
        id="document-definitions-table"
        data-testid="document-definitions-table"
        dataSource={dataSource}
        columns={columns}
        rowKey="id"
        onDragEnd={onDragEnd}
        loading={!!documentDefinitiontype && isLoading}
        disableOrdering={false}
        pagination={false}
      />
    </Space>
  );
};

export default DocumentDefinitionsTable;
