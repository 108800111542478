import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useForumAttendeeAvailibilitiesQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_AVAILABILITIES,
  apiClient.ForumAttendeeAvailability.getAll,
);

export const useForumAttendeeAvailibilityByIdQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_AVAILABILITY,
  apiClient.ForumAttendeeAvailability.get,
);
