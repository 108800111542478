import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useForumVenueFacilitiesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_VENUE_FACILITIES,
  apiClient.ForumVenues.facilities.get,
);

export const useForumVenueTimeslotSessionsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_VENUE_AVAILABLE_TIMESLOTS,
  apiClient.ForumVenues.availableTimeslots.get,
);

export const useGetForumVenueQuery = getWrappedUseQuery(KEYS.GET_FORUM_VENUE, apiClient.ForumVenues.get);

export const useForumVenueTablesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_VENUE_TABLES,
  apiClient.ForumVenues.tables.get,
);
