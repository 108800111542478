import { TimeSlotType } from "generated/api";

export const mealTime: Array<TimeSlotType> = [TimeSlotType.Breakfast, TimeSlotType.Dinner, TimeSlotType.Lunch];
export const businessTime: Array<TimeSlotType> = [
  TimeSlotType.MorningBusinessHours,
  TimeSlotType.AfternoonBusinessHours,
  TimeSlotType.AllMeetAllOutsideBh,
];

export const checkIsTimeSlotTypeMealTime = (timeSlotType: TimeSlotType) => mealTime.includes(timeSlotType);

export const checkIsTimeSlotTypeBusinessTime = (timeSlotType: TimeSlotType) => businessTime.includes(timeSlotType);
