import { EmailApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(EmailApi);

const Email = {
  templates: {
    post: api.emailTemplatesPost,
    getAll: api.emailTemplatesGet,
    put: api.emailTemplatesTemplateIdPut,
    get: api.emailTemplatesTemplateIdGet,
    delete: api.emailTemplatesTemplateIdDelete,
  },
  attendees: {
    put: api.emailForumForumIdAttendeesPut,
  },
  attendee: {
    put: api.emailForumForumIdAttendeeAttendeeIdPut,
  },
  byUserId: {
    get: api.emailForumForumIdUserGet,
  },
  image: {
    post: api.emailImagePost,
  },
};

export default Email;
