import { useQueryClient } from "@tanstack/react-query";
import { BaseOptionType } from "antd/lib/select";
import { useUpdateForumAttendeeMeetingLocationsByTimeslotTypeMutation } from "api/mutations/attendeeMeetingLocations";
import { useForumAttendeeMeetingLocationsQuery } from "api/queries/attendeeMeetingLocations";
import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import { useGetForumGroupsQuery } from "api/queries/groups";
import KEYS from "api/queries/keys";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { Select, Space } from "components/styleguide";
import Info from "components/UserGuide/Info";
import AttendeeStatus from "enums/AttendeeStatus";
import TimeSlotTypes, { TimeslotType } from "enums/TimeSlotType";
import {
  ForumGroupResponseModel,
  MeetingLocationResponseModel,
  PageResponseMeetingLocationResponseModel,
  TimeSlotType as ApiTimeslotType,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import MeetingLocationsTable from "../Table";

import styles from "../styles.module.less";

export default function MeetingLocationsByTimeType() {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [meetingLocations, setMeetingLocations] = useState<MeetingLocationResponseModel[]>([]);
  const [attendeeNameSearch, setAttendeeNameSearch] = useState<string>();
  const [peopleTypeFilter, setPeopleTypeFilter] = useState<string[]>();
  const [facilityFilter, setFacilityFilter] = useState<number[]>();
  const [companyNameSearch, setCompanyNameSearch] = useState<string>();
  const [timeslotTypeFilter, setTimeslotTypeFilter] = useState<string>(ApiTimeslotType.MorningBusinessHours);
  const [groupFilter, setGroupFilter] = useState<number[] | null | undefined>(null);
  const [peopleTypeFilterOptions, setPeopleTypeFilterOptions] = useState<BaseOptionType[]>([]);
  const [facilityFilterOptions, setFacilityFilterOptions] = useState<BaseOptionType[]>([]);
  const [groupFilterOptions, setGroupFilterOptions] = useState<BaseOptionType[]>([]);

  const timeslotTypesFilterOptions = useMemo(() => {
    return Object.keys(TimeSlotTypes)
      .filter((key) => key !== ApiTimeslotType.Conference)
      .map((key) => ({
        value: key,
        label: t(`timeTypes.${TimeSlotTypes[key as keyof TimeslotType]}`),
      }));
  }, []);

  const updateMutation = useUpdateForumAttendeeMeetingLocationsByTimeslotTypeMutation();

  const {
    response: { refetch: fetchMeetingLocations, isLoading: isMeetingLocationsLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useForumAttendeeMeetingLocationsQuery,
    {
      enabled: groupFilter !== null,
      onSuccess: ({ data }: { data: PageResponseMeetingLocationResponseModel }) => {
        setMeetingLocations(data.items ?? []);
      },
    },
    {
      forumId,
      forumAttendeeName: attendeeNameSearch,
      forumAttendeeParticipationCodes: peopleTypeFilter,
      forumCompanyName: companyNameSearch,
      timeType: timeslotTypeFilter,
      forumGroupIds: groupFilter,
      forumFacilityIds: facilityFilter,
      attendeeStatuses: [AttendeeStatus.Attending, AttendeeStatus.AttendingReplacement],
    },
  );

  const { isLoading: isLoadingForumGroups } = useGetForumGroupsQuery(
    {
      forumId,
    },
    {
      onSuccess: ({ data = [] }: { data: ForumGroupResponseModel[] }) => {
        const codes = data.map(({ id, name }) => ({ value: id, label: name }));
        const executivesId = data.filter((g) => g.name === "Executives")[0].id;
        setGroupFilterOptions(codes);
        if (groupFilter === null) {
          setGroupFilter([executivesId]);
        }
      },
    },
  );

  const { isLoading: isCodesLoading } = useParticipationCodesQuery(
    {
      ignorePagination: true,
    },
    {
      onSuccess: ({ data: { items = [] } = {} }) => {
        const codes = items.map(({ code }) => ({ value: code, label: code }));
        setPeopleTypeFilterOptions(codes);
      },
    },
  );

  const { data: { data: facilities = [] } = {}, isLoading: isFacilitiesLoading } = useForumVenueFacilitiesQuery(
    {
      forumId,
    },
    {
      onSuccess: ({ data }) => {
        const facilitiesOptions = data?.map(({ id, name }) => ({ value: id, label: name }));
        setFacilityFilterOptions(facilitiesOptions);
      },
    },
  );

  const isTableLoading = isCodesLoading || isFacilitiesLoading || isMeetingLocationsLoading || updateMutation.isLoading;

  const handleTimeslotTypeChange = (value: string) => {
    setTimeslotTypeFilter(value);
  };
  const handleGroupChange = (value: number[]) => {
    setGroupFilter(value);
  };

  const handleMeetingLocationUpdate = (
    attendeeId: number,
    attendeeMeetingLocationModel: MeetingLocationResponseModel,
  ) => {
    updateMutation.mutate(
      {
        forumId,
        attendeeId,
        timeSlotType: attendeeMeetingLocationModel.timeSlotType,
        attendeeMeetingLocationModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS]);
          fetchMeetingLocations();
        },
      },
    );
  };

  const handleFacilityChange = (facilityId: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.forumFacilityId = facilityId;
    meetingLocation.forumTableId = null;
    queryClient.resetQueries([KEYS.GET_FORUM_TABLES]);

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleTableChange = (tableId: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.forumTableId = tableId;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleRatioChange = (ratio: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.ratio = ratio;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleLockedClicked = (isLocked: boolean, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.isLocked = isLocked;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  return (
    <div id="meeting-locations-time-type-tab">
      <Space direction="vertical" fullWidth={true}>
        <div className={styles.header}>
          <Space fullWidth={true} direction="horizontal">
            <Select
              placeholder={t("Filter by Timeslot Type")}
              className={styles.filter}
              options={timeslotTypesFilterOptions}
              value={timeslotTypeFilter}
              onChange={handleTimeslotTypeChange}
            ></Select>
            <Select
              placeholder={t("Filter by Group")}
              className={styles.filter}
              options={groupFilterOptions}
              value={groupFilter}
              onChange={handleGroupChange}
              allowClear
              optionFilterProp="label"
              showSearch={true}
              loading={isLoadingForumGroups}
              disabled={isLoadingForumGroups}
              mode="multiple"
            ></Select>
          </Space>
          <Info articleId="16423895049373-meeting-locations" />
        </div>
        <MeetingLocationsTable
          meetingLocations={meetingLocations}
          facilities={facilities}
          isLoading={isTableLoading}
          peopleTypeFilterOptions={peopleTypeFilterOptions}
          facilityFilterOptions={facilityFilterOptions}
          pagination={pagination}
          setAttendeeNameSearch={setAttendeeNameSearch}
          setPeopleTypeFilter={setPeopleTypeFilter}
          setFacilityFilter={setFacilityFilter}
          setCompanyNameSearch={setCompanyNameSearch}
          onFacilityChange={handleFacilityChange}
          onTableChange={handleTableChange}
          onRatioChange={handleRatioChange}
          onLockedClicked={handleLockedClicked}
          onSort={handleSort}
        />
      </Space>
    </div>
  );
}
