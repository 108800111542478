/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { TimeSlotRequestModel } from '../apiModels';
// @ts-ignore
import { TimeSlotResponseModel } from '../apiModels';
// @ts-ignore
import { TimeSlotType } from '../apiModels';
/**
 * TimeSlotApi - axios parameter creator
 * @export
 */
export const TimeSlotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotExportGet: async (forumId: number, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTimeslotExportGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/timeslot/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all timeslots
         * @param {number} forumId 
         * @param {number} [firstSession] 
         * @param {TimeSlotType} [timeSlotType] 
         * @param {string} [description] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {number} [sessionsAvailableForMeeting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotGet: async (forumId: number, firstSession?: number, timeSlotType?: TimeSlotType, description?: string, orderBy?: string, orderDir?: string, sessionsAvailableForMeeting?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTimeslotGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/timeslot`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstSession !== undefined) {
                localVarQueryParameter['FirstSession'] = firstSession;
            }

            if (timeSlotType !== undefined) {
                localVarQueryParameter['TimeSlotType'] = timeSlotType;
            }

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (sessionsAvailableForMeeting !== undefined) {
                localVarQueryParameter['SessionsAvailableForMeeting'] = sessionsAvailableForMeeting;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a TimeSlot
         * @param {number} forumId 
         * @param {TimeSlotRequestModel} [timeSlotRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotPost: async (forumId: number, timeSlotRequestModel?: TimeSlotRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTimeslotPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/timeslot`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a timeslot by id
         * @param {number} forumId 
         * @param {number} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotTimeSlotIdDelete: async (forumId: number, timeSlotId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTimeslotTimeSlotIdDelete', 'forumId', forumId)
            // verify required parameter 'timeSlotId' is not null or undefined
            assertParamExists('forumForumIdTimeslotTimeSlotIdDelete', 'timeSlotId', timeSlotId)
            const localVarPath = `/forum/{forumId}/timeslot/{timeSlotId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetTimeSlot
         * @param {number} forumId 
         * @param {number} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotTimeSlotIdGet: async (forumId: number, timeSlotId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTimeslotTimeSlotIdGet', 'forumId', forumId)
            // verify required parameter 'timeSlotId' is not null or undefined
            assertParamExists('forumForumIdTimeslotTimeSlotIdGet', 'timeSlotId', timeSlotId)
            const localVarPath = `/forum/{forumId}/timeslot/{timeSlotId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Timeslot
         * @param {number} forumId 
         * @param {number} timeSlotId 
         * @param {TimeSlotRequestModel} [timeSlotRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotTimeSlotIdPut: async (forumId: number, timeSlotId: number, timeSlotRequestModel?: TimeSlotRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTimeslotTimeSlotIdPut', 'forumId', forumId)
            // verify required parameter 'timeSlotId' is not null or undefined
            assertParamExists('forumForumIdTimeslotTimeSlotIdPut', 'timeSlotId', timeSlotId)
            const localVarPath = `/forum/{forumId}/timeslot/{timeSlotId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeSlotApi - functional programming interface
 * @export
 */
export const TimeSlotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeSlotApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTimeslotExportGet(forumId: number, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTimeslotExportGet(forumId, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all timeslots
         * @param {number} forumId 
         * @param {number} [firstSession] 
         * @param {TimeSlotType} [timeSlotType] 
         * @param {string} [description] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {number} [sessionsAvailableForMeeting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTimeslotGet(forumId: number, firstSession?: number, timeSlotType?: TimeSlotType, description?: string, orderBy?: string, orderDir?: string, sessionsAvailableForMeeting?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTimeslotGet(forumId, firstSession, timeSlotType, description, orderBy, orderDir, sessionsAvailableForMeeting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a TimeSlot
         * @param {number} forumId 
         * @param {TimeSlotRequestModel} [timeSlotRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTimeslotPost(forumId: number, timeSlotRequestModel?: TimeSlotRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTimeslotPost(forumId, timeSlotRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a timeslot by id
         * @param {number} forumId 
         * @param {number} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTimeslotTimeSlotIdDelete(forumId: number, timeSlotId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTimeslotTimeSlotIdDelete(forumId, timeSlotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetTimeSlot
         * @param {number} forumId 
         * @param {number} timeSlotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTimeslotTimeSlotIdGet(forumId: number, timeSlotId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTimeslotTimeSlotIdGet(forumId, timeSlotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a Timeslot
         * @param {number} forumId 
         * @param {number} timeSlotId 
         * @param {TimeSlotRequestModel} [timeSlotRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTimeslotTimeSlotIdPut(forumId: number, timeSlotId: number, timeSlotRequestModel?: TimeSlotRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTimeslotTimeSlotIdPut(forumId, timeSlotId, timeSlotRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeSlotApi - factory interface
 * @export
 */
export const TimeSlotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeSlotApiFp(configuration)
    return {
        /**
         * 
         * @param {TimeSlotApiForumForumIdTimeslotExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotExportGet(requestParameters: TimeSlotApiForumForumIdTimeslotExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdTimeslotExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all timeslots
         * @param {TimeSlotApiForumForumIdTimeslotGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotGet(requestParameters: TimeSlotApiForumForumIdTimeslotGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TimeSlotResponseModel>> {
            return localVarFp.forumForumIdTimeslotGet(requestParameters.forumId, requestParameters.firstSession, requestParameters.timeSlotType, requestParameters.description, requestParameters.orderBy, requestParameters.orderDir, requestParameters.sessionsAvailableForMeeting, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a TimeSlot
         * @param {TimeSlotApiForumForumIdTimeslotPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotPost(requestParameters: TimeSlotApiForumForumIdTimeslotPostRequest, options?: AxiosRequestConfig): AxiosPromise<TimeSlotResponseModel> {
            return localVarFp.forumForumIdTimeslotPost(requestParameters.forumId, requestParameters.timeSlotRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a timeslot by id
         * @param {TimeSlotApiForumForumIdTimeslotTimeSlotIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotTimeSlotIdDelete(requestParameters: TimeSlotApiForumForumIdTimeslotTimeSlotIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdTimeslotTimeSlotIdDelete(requestParameters.forumId, requestParameters.timeSlotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetTimeSlot
         * @param {TimeSlotApiForumForumIdTimeslotTimeSlotIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotTimeSlotIdGet(requestParameters: TimeSlotApiForumForumIdTimeslotTimeSlotIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<TimeSlotResponseModel> {
            return localVarFp.forumForumIdTimeslotTimeSlotIdGet(requestParameters.forumId, requestParameters.timeSlotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Timeslot
         * @param {TimeSlotApiForumForumIdTimeslotTimeSlotIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTimeslotTimeSlotIdPut(requestParameters: TimeSlotApiForumForumIdTimeslotTimeSlotIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdTimeslotTimeSlotIdPut(requestParameters.forumId, requestParameters.timeSlotId, requestParameters.timeSlotRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdTimeslotExportGet operation in TimeSlotApi.
 * @export
 * @interface TimeSlotApiForumForumIdTimeslotExportGetRequest
 */
export interface TimeSlotApiForumForumIdTimeslotExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {FileExportType}
     * @memberof TimeSlotApiForumForumIdTimeslotExportGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumForumIdTimeslotGet operation in TimeSlotApi.
 * @export
 * @interface TimeSlotApiForumForumIdTimeslotGetRequest
 */
export interface TimeSlotApiForumForumIdTimeslotGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly firstSession?: number

    /**
     * 
     * @type {TimeSlotType}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly timeSlotType?: TimeSlotType

    /**
     * 
     * @type {string}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotGet
     */
    readonly sessionsAvailableForMeeting?: number
}

/**
 * Request parameters for forumForumIdTimeslotPost operation in TimeSlotApi.
 * @export
 * @interface TimeSlotApiForumForumIdTimeslotPostRequest
 */
export interface TimeSlotApiForumForumIdTimeslotPostRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotPost
     */
    readonly forumId: number

    /**
     * 
     * @type {TimeSlotRequestModel}
     * @memberof TimeSlotApiForumForumIdTimeslotPost
     */
    readonly timeSlotRequestModel?: TimeSlotRequestModel
}

/**
 * Request parameters for forumForumIdTimeslotTimeSlotIdDelete operation in TimeSlotApi.
 * @export
 * @interface TimeSlotApiForumForumIdTimeslotTimeSlotIdDeleteRequest
 */
export interface TimeSlotApiForumForumIdTimeslotTimeSlotIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdDelete
     */
    readonly timeSlotId: number
}

/**
 * Request parameters for forumForumIdTimeslotTimeSlotIdGet operation in TimeSlotApi.
 * @export
 * @interface TimeSlotApiForumForumIdTimeslotTimeSlotIdGetRequest
 */
export interface TimeSlotApiForumForumIdTimeslotTimeSlotIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdGet
     */
    readonly timeSlotId: number
}

/**
 * Request parameters for forumForumIdTimeslotTimeSlotIdPut operation in TimeSlotApi.
 * @export
 * @interface TimeSlotApiForumForumIdTimeslotTimeSlotIdPutRequest
 */
export interface TimeSlotApiForumForumIdTimeslotTimeSlotIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdPut
     */
    readonly timeSlotId: number

    /**
     * 
     * @type {TimeSlotRequestModel}
     * @memberof TimeSlotApiForumForumIdTimeslotTimeSlotIdPut
     */
    readonly timeSlotRequestModel?: TimeSlotRequestModel
}

/**
 * TimeSlotApi - object-oriented interface
 * @export
 * @class TimeSlotApi
 * @extends {BaseAPI}
 */
export class TimeSlotApi extends BaseAPI {
    /**
     * 
     * @param {TimeSlotApiForumForumIdTimeslotExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public forumForumIdTimeslotExportGet(requestParameters: TimeSlotApiForumForumIdTimeslotExportGetRequest, options?: AxiosRequestConfig) {
        return TimeSlotApiFp(this.configuration).forumForumIdTimeslotExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all timeslots
     * @param {TimeSlotApiForumForumIdTimeslotGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public forumForumIdTimeslotGet(requestParameters: TimeSlotApiForumForumIdTimeslotGetRequest, options?: AxiosRequestConfig) {
        return TimeSlotApiFp(this.configuration).forumForumIdTimeslotGet(requestParameters.forumId, requestParameters.firstSession, requestParameters.timeSlotType, requestParameters.description, requestParameters.orderBy, requestParameters.orderDir, requestParameters.sessionsAvailableForMeeting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a TimeSlot
     * @param {TimeSlotApiForumForumIdTimeslotPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public forumForumIdTimeslotPost(requestParameters: TimeSlotApiForumForumIdTimeslotPostRequest, options?: AxiosRequestConfig) {
        return TimeSlotApiFp(this.configuration).forumForumIdTimeslotPost(requestParameters.forumId, requestParameters.timeSlotRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a timeslot by id
     * @param {TimeSlotApiForumForumIdTimeslotTimeSlotIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public forumForumIdTimeslotTimeSlotIdDelete(requestParameters: TimeSlotApiForumForumIdTimeslotTimeSlotIdDeleteRequest, options?: AxiosRequestConfig) {
        return TimeSlotApiFp(this.configuration).forumForumIdTimeslotTimeSlotIdDelete(requestParameters.forumId, requestParameters.timeSlotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetTimeSlot
     * @param {TimeSlotApiForumForumIdTimeslotTimeSlotIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public forumForumIdTimeslotTimeSlotIdGet(requestParameters: TimeSlotApiForumForumIdTimeslotTimeSlotIdGetRequest, options?: AxiosRequestConfig) {
        return TimeSlotApiFp(this.configuration).forumForumIdTimeslotTimeSlotIdGet(requestParameters.forumId, requestParameters.timeSlotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Timeslot
     * @param {TimeSlotApiForumForumIdTimeslotTimeSlotIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotApi
     */
    public forumForumIdTimeslotTimeSlotIdPut(requestParameters: TimeSlotApiForumForumIdTimeslotTimeSlotIdPutRequest, options?: AxiosRequestConfig) {
        return TimeSlotApiFp(this.configuration).forumForumIdTimeslotTimeSlotIdPut(requestParameters.forumId, requestParameters.timeSlotId, requestParameters.timeSlotRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

