import { useQueryClient } from "@tanstack/react-query";
import { useUpdateAttendeeBfrDateMutation } from "api/mutations/attendees";
import KEYS from "api/queries/keys";
import { DatePicker } from "components/styleguide";
import { toNumber } from "lodash";
import { Moment } from "moment";
import moment from "moment/moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./styles.module.less";

interface BFRDatePickerProps {
  value?: string | null;
}

const BFRDatePicker: FC<BFRDatePickerProps> = ({ value }) => {
  const { t } = useTranslation();
  const { id: forumId, attendeeId } = useParams();
  const queryClient = useQueryClient();

  const { mutate: updateBfrDateMutate } = useUpdateAttendeeBfrDateMutation();

  const updateBfrDate = (newDate: Moment | null) => {
    updateBfrDateMutate(
      {
        forumId: toNumber(forumId),
        attendeeId: toNumber(attendeeId),
        attendeeBFRDateRequestModel: { bfrDate: moment(newDate).format("YYYY-MM-DD") },
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_ATTENDEE]),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <div className={styles.datePickerContainer}>
      <DatePicker
        value={value ? moment(value) : undefined}
        format={"L"}
        allowClear={false}
        bordered={false}
        disabledDate={(current) => current.isAfter(moment())}
        onChange={updateBfrDate}
      />
    </div>
  );
};

export default BFRDatePicker;
