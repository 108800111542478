import { DatePicker, Space, Typography } from "components/styleguide";
import { CustomDatePickerProps } from "components/styleguide/components/DatePicker";
import { Moment } from "moment";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

interface DatePickerOptionProps {
  date: Moment | undefined;
  setDate: Dispatch<SetStateAction<Moment>> | Dispatch<SetStateAction<Moment | undefined>>;
  format?: string;
}

const defaultFormat = "DD/MM/YYYY";

const DatePickerOption = ({
  date,
  setDate,
  format = defaultFormat,
  ...props
}: DatePickerOptionProps & CustomDatePickerProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Text>{t("Enter Date")}</Text>
      <DatePicker
        style={{ minWidth: "200px" }}
        value={date}
        onChange={(value) => setDate(value)}
        format={format}
        {...props}
      />
    </Space>
  );
};

export default DatePickerOption;
