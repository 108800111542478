import { useEffect, useState } from "react";

interface ScreenProps {
  screenWidth?: number;
}

export const useCheckMobileScreen = ({ screenWidth = 800 }: Partial<ScreenProps> = {}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width <= screenWidth;
};
