import { useQuestionnaireContentQuery, useQuestionnaireQuery } from "api/queries/questionnaires";
import { Layout, Spin, Typography } from "components/styleguide";
import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fillQuestionsWithIds } from "utils/questionnaireContentUtils";

import useQuestionnaire from "../Edit/hooks/useQuestionnaire";
import QuestionsSelect from "./components/QuestionsSelect";

import styles from "./styles.module.less";

const QuestionnaireImportQuestionsPage = () => {
  const navigate = useNavigate();
  const { id: forumId } = useOutletContext();
  const { questionnaireId, importForumId, importQuestionnaireId, lang } = useParams();

  const questionnaire = useQuestionnaire(forumId, questionnaireId);
  const importQuery = useQuestionnaireContentQuery({ forumId: importForumId, questionnaireId: importQuestionnaireId });
  const importQuestionnaire = useQuestionnaireQuery({ forumId: importForumId, questionnaireId: importQuestionnaireId });
  const isLoading = questionnaire.isLoading || importQuery.isLoading || importQuestionnaire.isLoading;

  const importTitle = importQuestionnaire.data?.data?.name ?? "";

  const addPagesToQuestionnaire = (pages) => {
    const newPages = fillQuestionsWithIds([...questionnaire.pages, ...pages]);
    questionnaire.mutate(newPages, {
      onSuccess: () => {
        navigate(`/${lang}/forum/${forumId}/forum-admin/questionnaires/${questionnaireId}`);
        toast.success("Multiple Questions Imported");
      },
      onError: () => {
        toast.error("An error occurred on updating questionnaire");
      },
    });
  };

  if (isLoading) {
    return (
      <Layout className={styles.loadingContainer}>
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <div className={styles.container}>
      <Typography.Title level={4}>Import from library</Typography.Title>
      <QuestionsSelect
        title={importTitle}
        pages={importQuery.data?.data?.pages ?? []}
        isLoading={questionnaire.isLoading}
        onFinish={addPagesToQuestionnaire}
      />
    </div>
  );
};

export default QuestionnaireImportQuestionsPage;
