import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, Row } from "antd";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { useTeamMembersQuery } from "api/queries/teamMembers";
import { AxiosResponse } from "axios";
import { Button, Divider, Drawer, Form, Input, Select, Spin, Table } from "components/styleguide";
import ForumGroupType from "enums/ForumGroupType";
import {
  ForumGroupRequestModel,
  ForumTeamMemberResponseModel,
  PageResponseParticipationCodeModel,
  ParticipationCodeModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { stringSorter } from "utils/sorterUtils";
import { FilterFunction, getColumnSearchProps } from "utils/tableColumnUtils";
import { validateMaxStringLength } from "utils/validatorUtils";

import styles from "./styles.module.less";

const { Option } = Select;

const AddGroupDrawer = ({
  visible,
  isLoading,
  onClose,
  onFinish,
}: {
  visible: boolean;
  isLoading: boolean;
  onClose: () => void;
  onFinish: (values: {
    group: ForumGroupRequestModel;
    selectedCodes: ParticipationCodeModel[] | undefined;
  }) => Promise<AxiosResponse<ForumGroupRequestModel>>;
}) => {
  const { id: forumId } = useParams();
  const [availableCodes, setAvailableCodes] = useState<ParticipationCodeModel[] | undefined>([]);
  const [selectedCodes, setSelectedCodes] = useState<ParticipationCodeModel[] | undefined>([]);

  const { t } = useTranslation();

  const pagination = { defaultPageSize: 10, pageSizeOptions: [10, 20] };

  const handleSearch = (_: unknown, confirm: () => void) => {
    confirm();
  };
  const handleReset = (clearFilters: () => void, _: unknown, __: unknown, confirm: () => void) => {
    clearFilters();
    confirm();
  };
  const onFilter: FilterFunction<ParticipationCodeModel> = (
    value: string | undefined | number | boolean,
    record: ParticipationCodeModel | undefined,
    dataIndex: string | undefined,
  ) => {
    return !!(record as ParticipationCodeModel)[dataIndex as keyof ParticipationCodeModel]
      ?.toString()
      .toLowerCase()
      .includes((value as string).toLowerCase());
  };

  const { response: { isLoading: isCodesLoading } = {}, pagination: availablePagination } =
    useModernQueryWithPaginationAndOrder(
      useParticipationCodesQuery,
      {
        onSuccess: ({ data }: { data: PageResponseParticipationCodeModel }) => {
          const filteredCodes = data?.items?.filter((a) => !selectedCodes?.some((s) => s.code === a.code));
          setAvailableCodes(filteredCodes);
        },
        enabled: visible,
      },
      {},
      { defaultPageSize: 10, pageSizeOptions: [10, 50, 100] },
    );

  const addCode = (code: ParticipationCodeModel) => {
    if (selectedCodes && selectedCodes.findIndex((c) => c.code === code.code) > 0) {
      toast.error(t("This Code was already added"));
    }

    if (selectedCodes) {
      setSelectedCodes([...selectedCodes, code]);
    }

    setAvailableCodes(availableCodes?.filter((c) => c.code !== code.code));
  };
  const removeCode = (code: ParticipationCodeModel) => {
    if (availableCodes) {
      setAvailableCodes([code, ...availableCodes].sort((a, b) => stringSorter(a.code, b.code)));
    }

    setSelectedCodes(selectedCodes?.filter((c) => c.code !== code.code));
  };

  const closeDrawer = () => {
    setAvailableCodes([]);
    setSelectedCodes([]);
    onClose();
  };

  const handleSubmit = (values: ForumGroupRequestModel) => {
    onFinish({ group: { ...values }, selectedCodes }).then(() => {
      closeDrawer();
      toast.success(t("Group successfully added"));
    });
  };

  const availableCodeColumns = [
    {
      title: t("peopleType"),
      dataIndex: "code",
      ...getColumnSearchProps("code", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: t("Description"),
      dataIndex: "description",
      ...getColumnSearchProps("description", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      render: (_: unknown, record: ParticipationCodeModel) => {
        return (
          <span className={styles.codeIcon} title={t("Include participation code")}>
            <PlusCircleOutlined onClick={() => addCode(record)} />
          </span>
        );
      },
    },
  ];

  const { data: { data: forumTeamMembers = [] } = {}, isLoading: isTeamMembersLoading } = useTeamMembersQuery(
    { forumId: toNumber(forumId) },
    { enabled: !!forumId },
  );

  const selectedCodeColumns = [
    {
      title: t("peopleType"),
      dataIndex: "code",
      ...getColumnSearchProps("code", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: t("Description"),
      dataIndex: "description",
      ...getColumnSearchProps("description", handleSearch, handleReset, "", null, styles.filteredSearch, onFilter),
    },
    {
      title: "",
      render: (_: unknown, record: ParticipationCodeModel) => {
        return (
          <span className={styles.codeIcon} title={t("Exclude participation code")}>
            <MinusCircleOutlined onClick={() => removeCode(record)} />
          </span>
        );
      },
    },
  ];

  return (
    <Drawer
      className="group-drawer"
      title={t("addGroup")}
      placement="right"
      onClose={closeDrawer}
      open={visible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Form id="groupForm" name="group-form" labelCol={{ span: 4 }} onFinish={handleSubmit}>
        <div className="form-layout">
          <div>
            <Form.Item
              label={t("Name")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("forumGroups.errors.Group name is required!"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("Description")}
              name="description"
              rules={[
                {
                  required: true,
                  message: t("forumGroups.errors.Group description is required!"),
                },
                {
                  validator: validateMaxStringLength(200),
                  message: t("'Description' field has a maximum of 200 characters"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("Contact")}
              name="contactUserId"
              rules={[
                {
                  required: true,
                  message: t("This field is mandatory!"),
                },
              ]}
            >
              <Select allowClear>
                {isTeamMembersLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  forumTeamMembers?.map((record: ForumTeamMemberResponseModel, index: number) => (
                    <Option value={record.userId} key={index}>
                      {record.name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Group Type"
              name="groupType"
              rules={[
                {
                  required: true,
                  message: t("forumGroups.errors.Group type is required!"),
                },
              ]}
            >
              <Select>
                <Option value={1} key={ForumGroupType.DisplayType}>
                  {t(`forumGroupTypes.${ForumGroupType.toDisplayName(ForumGroupType.DisplayType)}`)}
                </Option>
                <Option value={2} key={ForumGroupType.General}>
                  {t(`forumGroupTypes.${ForumGroupType.toDisplayName(ForumGroupType.General)}`)}
                </Option>
                <Option value={3} key={ForumGroupType.Selection}>
                  {t(`forumGroupTypes.${ForumGroupType.toDisplayName(ForumGroupType.Selection)}`)}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label="Primary" name="primary" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </div>
          <div>
            <Row gutter={100}>
              <Col span={12}>
                <h4>
                  <strong>{t("Available Codes")}</strong>
                </h4>
                <Table
                  bordered={true}
                  pagination={availablePagination}
                  columns={availableCodeColumns}
                  dataSource={availableCodes}
                  loading={isCodesLoading}
                ></Table>
              </Col>
              <Col span={12}>
                <h4>
                  <strong>{t("Selected Codes")}</strong>
                </h4>
                <Table
                  bordered={true}
                  pagination={pagination}
                  columns={selectedCodeColumns}
                  dataSource={selectedCodes}
                ></Table>
              </Col>
            </Row>
          </div>
          <div>
            <Divider />
            <Row>
              <Col span={3}>
                <Button onClick={closeDrawer}>{t("Cancel")}</Button>
              </Col>
              <Col span={18} />
              <Col span={3}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {t("addGroup")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

AddGroupDrawer.propTypes = {
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
};

export default AddGroupDrawer;
