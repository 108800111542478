import EnumBuilder from "./enumBuilderUtils";

const builder = new EnumBuilder();
const EnumClass = builder
  .add("Page", "Page")
  .add("FreeText", "Free text")
  .add("Availability", "Availability")
  .add("ConferenceSelectionV2", "Conference selection V2")
  .add("ConferenceRankV2", "Conference rank V2")
  .add("EventOptions", "Event options")
  .add("Joint", "Joint Meeting")
  .add("NegativeSelection", "Negative selection")
  .add("PositiveSelectionV2", "Positive selection V2")
  .add("ReasonForMeeting", "Reason for meeting")
  .add("ReasonForMeetingV2", "Reason for meeting V2")
  .add("ReasonForMeetingV3", "Reason for meeting V3")
  .add("SubmittingSelections", "Submitting selections")
  .add("TabbedConferenceSelection", "Tabbed conference selection")
  .build();

class SelectionSiteItemType extends EnumClass {
  get isPage() {
    return this.value === "Page";
  }

  get isFreeText() {
    return this.value === "FreeText";
  }

  get isAvailability() {
    return this.value === "Availability";
  }

  get isConferenceSelectionV2() {
    return this.value === "ConferenceSelectionV2";
  }

  get isConferenceRankV2() {
    return this.value === "ConferenceRankV2";
  }

  get isEventOptions() {
    return this.value === "EventOptions";
  }

  get isJoint() {
    return this.value === "Joint";
  }

  get isNegativeSelection() {
    return this.value === "NegativeSelection";
  }

  get isPositiveSelectionV2() {
    return this.value === "PositiveSelectionV2";
  }

  get isReasonForMeeting() {
    return this.value === "ReasonForMeeting";
  }

  get isReasonForMeetingV2() {
    return this.value === "ReasonForMeetingV2";
  }

  get isReasonForMeetingV3() {
    return this.value === "ReasonForMeetingV3";
  }

  get isSubmittingSelections() {
    return this.value === "SubmittingSelections";
  }

  get isTabbedConferenceSelection() {
    return this.value === "TabbedConferenceSelection";
  }
}

export default SelectionSiteItemType;
