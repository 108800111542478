import { SpeakerTrackingResponseModel } from "generated/api";
import React from "react";

interface FormFieldsProp {
  dataIndex: string;
  title: string;
  initialValue?: string | null;
  render?: boolean;
  component?: React.ReactNode;
}

const getFormFields = (speakerTracking: SpeakerTrackingResponseModel): FormFieldsProp[] => {
  return [
    {
      dataIndex: "agreedFee",
      title: "Aggreed Fee",
      initialValue: speakerTracking.agreedFee,
    },
    {
      dataIndex: "agreedTravelCosts",
      title: "Agreed Travel Costs",
      initialValue: speakerTracking.agreedTravelCosts,
    },
    {
      dataIndex: "otherAgreedExpenses",
      title: "Other Agreed Expenses",
      initialValue: speakerTracking.otherAgreedExpenses,
    },
    {
      dataIndex: "paymentTerms",
      title: "Payment Terms & Other Notes",
      initialValue: speakerTracking.paymentTerms,
    },
  ];
};

export { getFormFields };
