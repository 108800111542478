import { SpeakerTrackingResponseModel } from "generated/api";
import React from "react";

interface FormFieldsProp {
  dataIndex: string;
  title: string;
  initialValue?: string | null;
  render?: boolean;
  component?: React.ReactNode;
}

const getFormFields = (speakerTracking: SpeakerTrackingResponseModel): FormFieldsProp[] => {
  return [
    {
      dataIndex: "pa",
      title: "PA",
      initialValue: speakerTracking.pa,
    },
    {
      dataIndex: "paPhone",
      title: "PA Phone",
      initialValue: speakerTracking.paPhone,
    },
    {
      dataIndex: "paEmail",
      title: "PA Email",
      initialValue: speakerTracking.paEmail,
    },
    {
      dataIndex: "agent",
      title: "Agent",
      initialValue: speakerTracking.agent,
    },
    {
      dataIndex: "agentPhone",
      title: "Agent Phone",
      initialValue: speakerTracking.agentPhone,
    },
    {
      dataIndex: "agentEmail",
      title: "Agent Email",
      initialValue: speakerTracking.agentEmail,
    },
  ];
};

export { getFormFields };
