import { Form } from "components/styleguide";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ConferenceSelectionV2Item } from "../../../../../../../generated/api";
import DefaultForm from "../DefaultForm";
import { ConferenceSessionsShown, Setup, YesNo } from "../inputs";

interface ConferenceSelectionV2Props {
  forumId: number;
  item: ConferenceSelectionV2Item;
  onChange: (item: ConferenceSelectionV2Item) => void;
}

const ConferenceSelectionV2: FC<ConferenceSelectionV2Props> = ({ forumId, item, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (values: Partial<ConferenceSelectionV2Item>) => {
    const newValues = { ...item, ...values };

    return onChange(newValues);
  };

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      onChange={handleChange}
      options={() => (
        <>
          <Form.Item name="items" label={t("Set up the conference sessions")}>
            <Setup
              formTitle={t("Set up the conference sessions")}
              form={{
                element: ConferenceSessionsShown,
                props: {
                  forumId,
                  readonlyCheckboxKey: "readOnly",
                },
              }}
            />
          </Form.Item>
          <Form.Item name="displayLocalisedLabels" label={t("Display localized labels")}>
            <YesNo />
          </Form.Item>
          <Form.Item name="showLanguagesOfConferenceSession" label={t("Show Languages of Conference Session")}>
            <YesNo />
          </Form.Item>
        </>
      )}
    />
  );
};

export default ConferenceSelectionV2;
