import { Select, Space, Typography } from "components/styleguide";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import LinkToReport from "../components/LinkToReport";
import { AllMealtimes } from "../constants";
import MealtimeOption from "../OptionTypes/MealtimeOption";

const { Text } = Typography;

const SharedOptions = {
  Shared: "Shared Only",
  All: "All Tables",
};

const PlaceCardsOptions = () => {
  const { t } = useTranslation();

  const [session, setSession] = useState<number | typeof AllMealtimes>();
  const [shareType, setShareType] = useState<string>("Shared");

  return (
    <Space align={"end"} wrap>
      <Space direction={"vertical"}>
        <Text>{t("Shared")}</Text>
        <Select
          style={{ minWidth: "200px" }}
          placeholder={t("Shared")}
          options={Object.entries(SharedOptions).map(([value, label]) => ({
            value,
            label: t(label),
          }))}
          value={shareType}
          onSelect={setShareType}
        />
      </Space>

      <MealtimeOption session={session} setSession={setSession} showAllMealtimesOption />

      <LinkToReport
        parameters={{
          ShareType: shareType,
          TimeSlotSessionId: session === AllMealtimes ? null : session,
          AllMealtimes: session === AllMealtimes,
        }}
      />
    </Space>
  );
};

export default PlaceCardsOptions;
