import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";

import apiClient from "../../../api-client";

const useUpdateWelcomeLetterMutation = getWrappedUseMutation(apiClient.ItineraryBookletsForumWelcomeLetter.put);

const useUploadWelcomeLetterImageMutation = getWrappedUseMutation(
  apiClient.ItineraryBookletsForumWelcomeLetter.image.post,
);

export { useUpdateWelcomeLetterMutation, useUploadWelcomeLetterImageMutation };
