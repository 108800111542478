import apiClient from "../../../api-client";
import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useUploadForumVisualSpeakerDocumentMutation = getWrappedUseMutation(
  apiClient.ForumVisualSpeakerDocument.post,
);

export const useUpdateForumVisualSpeakerDocumentMutation = getWrappedUseMutation(
  apiClient.ForumVisualSpeakerDocument.put,
);

export const useDeleteForumVisualSpeakerDocumentMutation = getWrappedUseMutation(
  apiClient.ForumVisualSpeakerDocument.delete,
);
