import "./styles.less";

import { useGetForumCompanyQuery } from "api/queries/forumCompanies";
import { Button, Collapse, Layout, Spin } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { CompanyModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ForumCompanyAddressInfo from "./ForumCompanyAddressInfo";
import ForumCompanyAttendees from "./ForumCompanyAttendees";
import ForumCompanyDescription from "./ForumCompanyDescription";
import ForumCompanyDetails from "./ForumCompanyDetails";
import ForumCompanyHistoryInformationPanel from "./ForumCompanyHistoryInformation";
import ForumCompanyPackagePanel from "./ForumCompanyPackage";
import ForumCompanyPrimaryLocationsPanel from "./ForumCompanyPrimaryLocations";
import ForumCompanySocialLinksPanel from "./ForumCompanySocialLinks";

import styles from "./styles.module.less";

const { Content } = Layout;

const ForumCompany: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: paramForumId, companyId: paramCompanyId } = useParams();

  const forumId = toNumber(paramForumId);
  const companyId = toNumber(paramCompanyId);

  const [company, setCompany] = useState<CompanyModel | null>(null);
  const [isEditingAddressInfo, setIsEditingAddressInfo] = useState<boolean>(false);
  const [isEditingSocialLinks, setIsEditingSocialLinks] = useState<boolean>(false);
  const [isEditingPackage, setIsEditingPackage] = useState<boolean>(false);
  const [isEditingDescription, setIsEditingDescription] = useState<boolean>(false);

  const { isLoading } = useGetForumCompanyQuery(
    {
      forumId,
      forumCompanyId: companyId,
    },
    {
      onSuccess: ({ data }: { data: CompanyModel }) => {
        setCompany(data);
        // location.state is of type unknown
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const locationState: any = location.state;
        if (!locationState || !locationState.companyName) {
          navigate(location.pathname, {
            state: { ...locationState, companyName: data.name },
          });
        }
      },
    },
  );

  const onChangeCompanyName = (newCompanyName: string) => {
    navigate(location.pathname, {
      state: { ...location, companyName: newCompanyName },
    });
  };

  const getEditButton = useCallback(
    (setEditState: (newState: boolean) => void) => {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setEditState(true);
          }}
        >
          {t("Edit")}
        </Button>
      );
    },
    [t],
  );

  if (!company) {
    return <Spin />;
  }

  return (
    <Layout>
      <Content>
        <ForumCompanyDetails isLoading={isLoading} forumCompany={company} onChangeCompanyName={onChangeCompanyName} />
        <div className={styles.container}>
          <Collapse
            className={"collapse-parent"}
            defaultActiveKey={[
              "people",
              "addressInfo",
              "socialLinks",
              "packageInformation",
              "package",
              "primaryLocations",
              "description",
              "historyInformation",
            ]}
            ghost
          >
            <UserGuide.Panel id="people" title={t("People")} articleId="16423608581789-people" key="people">
              <ForumCompanyAttendees forumId={forumId} forumCompanyId={companyId} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="description"
              title={t("Description")}
              articleId="16423644756893-description"
              key="description"
              extra={getEditButton(setIsEditingDescription)}
            >
              <ForumCompanyDescription
                forumId={forumId}
                forumCompany={company}
                isLoading={isLoading}
                isEditing={isEditingDescription}
                setIsEditing={setIsEditingDescription}
              />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="address"
              title={t("Address Information")}
              articleId="16423656031901-address-information"
              key="addressInfo"
              extra={getEditButton(setIsEditingAddressInfo)}
            >
              <ForumCompanyAddressInfo
                forumId={forumId}
                forumCompany={company}
                isLoading={isLoading}
                isEditing={isEditingAddressInfo}
                setForumCompany={setCompany}
                setIsEditing={setIsEditingAddressInfo}
              />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="social"
              title={t("Social Links")}
              articleId="16423657343773-social-links"
              key="socialLinks"
              extra={getEditButton(setIsEditingSocialLinks)}
            >
              <ForumCompanySocialLinksPanel
                forumId={forumId}
                forumCompanyId={companyId}
                forumCompany={company}
                isLoading={isLoading}
                isEditing={isEditingSocialLinks}
                setIsEditing={setIsEditingSocialLinks}
              />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="package"
              title={t("Package")}
              articleId="16423651328285-package"
              key="package"
              extra={getEditButton(setIsEditingPackage)}
            >
              <ForumCompanyPackagePanel
                forumId={toNumber(forumId)}
                forumCompanyId={toNumber(companyId)}
                companyName={company.name}
                isEditing={isEditingPackage}
                setIsEditing={setIsEditingPackage}
              />
            </UserGuide.Panel>

            <UserGuide.Panel
              id="primary-locations"
              title={t("Primary Locations")}
              articleId="16423636113693-primary-locations"
              key="primaryLocations"
            >
              <ForumCompanyPrimaryLocationsPanel forumId={forumId} forumCompanyId={companyId} />
            </UserGuide.Panel>

            <UserGuide.Panel
              id="history"
              title={t("History Information")}
              articleId="16423673979037-history-information"
              key="historyInformation"
            >
              <ForumCompanyHistoryInformationPanel forumId={forumId} forumCompanyId={companyId} />
            </UserGuide.Panel>
          </Collapse>
        </div>
      </Content>
    </Layout>
  );
};

export default ForumCompany;
