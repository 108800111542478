import PropTypes from "prop-types";
import ItemType from "../itemType";

const Base = {
  id: PropTypes.number,
  type: ItemType,
  description: PropTypes.string,
};

export default Base;
