import { VenueApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(VenueApi);

const Venues = {
  get: api.venuesGet,
  getById: api.venuesVenueIdGet,
  post: api.venuesPost,
  put: api.venuesVenueIdPut,
  facilities: { get: api.venuesVenueIdFacilitiesGet },
  tables: { get: api.venuesVenueIdTablesGet },
  map: {
    put: api.venuesVenueIdMapPut,
    delete: api.venuesVenueIdMapDelete,
  },
};

export default Venues;
