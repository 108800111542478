import { useMemo } from "react";

const useYupValidator = (form, getSchema) => {
  const rule = useMemo(
    () => ({
      validator: async ({ field }, value) => {
        await getSchema(form).validateSyncAt(field, { [field]: value });
      },
    }),
    [form, getSchema],
  );

  const rules = useMemo(() => [rule], [rule]);

  return {
    rule,
    rules,
  };
};

export default useYupValidator;
