import { ZoomMappingsApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ZoomMappingsApi);

const ZoomMappings = {
  accounts: {
    get: api.forumsForumIdZoomAccountsGet,
  },
  sessionMappings: {
    get: api.forumsForumIdZoomSessionMappingsGet,
    put: api.forumsForumIdZoomSessionMappingForumSeminarSessionIdPut,
    delete: api.forumsForumIdZoomSessionMappingForumSeminarSessionIdDelete,
  },
  tableMappings: {
    get: api.forumsForumIdZoomTableMappingsGet,
    put: api.forumsForumIdZoomTableMappingForumTableIdPut,
  },
  webinars: {
    get: api.forumsForumIdZoomWebinarsGet,
  },
};

export default ZoomMappings;
