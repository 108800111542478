import { FilterOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Drawer } from "../../../components/styleguide";
import FiltersForm from "./components/FiltersForm";
import { AvailableFilters } from "./types";

interface FiltersProps {
  value?: AvailableFilters;
  onChange?: (value: AvailableFilters | undefined) => void;
}

const Filters = ({ value, onChange }: FiltersProps) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const areFiltersApplied = value !== undefined;

  const showDrawer = () => setIsDrawerOpen(true);
  const hideDrawer = () => setIsDrawerOpen(false);

  const handleFiltersChange = (newValue: AvailableFilters | undefined) => {
    onChange?.(newValue);
    hideDrawer();
  };

  return (
    <>
      <Button type={areFiltersApplied ? "primary" : undefined} icon={<FilterOutlined />} onClick={showDrawer}>
        {t("Filters")}
      </Button>
      <Drawer
        open={isDrawerOpen}
        title={t("Filters")}
        placement="left"
        size="large"
        destroyOnClose
        onClose={hideDrawer}
      >
        <FiltersForm value={value} onCancel={hideDrawer} onChange={handleFiltersChange} />
      </Drawer>
    </>
  );
};

export default Filters;
