import QuestionnaireType from "backend-models/questionnaireType";
import { Button } from "components/styleguide";
import { ForumQuestionnaireResponseModel } from "generated/api";
import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

import GenericQuestionnairesTable from "../../../components/GenericQuestionnairesTable";
import QuestionnaireStatus from "../QuestionnaireStatus";
import QuestionnaireAnswersExportButton from "./QuestionnaireAnswersExportButton";

import styles from "./styles.module.less";

interface QuestionnairesTableProps {
  items: ForumQuestionnaireResponseModel[];
  loading: boolean;
  className?: string;
}

const buildColumn = (title: string, key: string, options?: Record<string, unknown>) => ({
  title,
  key,
  dataIndex: key,
  ...options,
});

const getTypeRenderer = () => {
  return {
    render: (type: string) => {
      const questionnaireType = new QuestionnaireType(type);
      return questionnaireType.toString() ?? t("Unknown type");
    },
  };
};

const getStatusRenderer = () => {
  return {
    render: (status: string) => <QuestionnaireStatus value={status} />,
  };
};

const QuestionnairesTable = ({ className, items, loading }: QuestionnairesTableProps) => {
  const columns = [
    buildColumn(t("code"), "eventCode", { width: "10%" }),
    buildColumn(t("questionnaire.name"), "name"),
    buildColumn(t("questionnaire.type"), "type", getTypeRenderer()),
    buildColumn(t("status"), "status", getStatusRenderer()),
    buildColumn("", "id", {
      responsive: ["sm"],
      render: (id: number, row: ForumQuestionnaireResponseModel) => {
        return (
          <div className={styles.actions}>
            <QuestionnaireAnswersExportButton questionnaireId={id} groups={row.forumGroups} />
            <Link to={`${id}`} state={{ questionnaireName: row.name }}>
              <Button>{t("Edit") as string}</Button>
            </Link>
          </div>
        );
      },
    }),
  ];

  return (
    <GenericQuestionnairesTable className={className} items={items} columns={columns} loading={loading} rowKey="id" />
  );
};

export default QuestionnairesTable;
