import { useMutation } from "@tanstack/react-query";

import api from "../../index";

export const useCreateQuestionnaireMutation = (options = {}) => {
  return useMutation(({ forumId, name, availableFrom, availableTo, type, forumGroupIds }) => {
    return api.post(`/forum/${forumId}/questionnaires`, {
      name,
      availableFrom,
      availableTo,
      type,
      forumGroupIds,
    });
  }, options);
};

export const useUpdateQuestionnaireContentMutation = (options = {}) => {
  return useMutation(({ forumId, questionnaireId, pages }) => {
    return api.put(`/forum/${forumId}/questionnaires/${questionnaireId}/content`, { pages });
  }, options);
};

export const useUpdateQuestionnaireMutation = (options = {}) => {
  return useMutation(({ forumId, questionnaireId, name, availableFrom, availableTo, type, forumGroupIds, status }) => {
    return api.put(`/forum/${forumId}/questionnaires/${questionnaireId}`, {
      name,
      availableFrom,
      availableTo,
      type,
      forumGroupIds,
      status,
    });
  }, options);
};
