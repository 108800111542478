import { SelectProps } from "antd";
import { Select } from "components/styleguide";
import React from "react";
import { useTranslation } from "react-i18next";

const YesNo = (props: SelectProps) => {
  const { t } = useTranslation();

  return (
    <Select {...props}>
      <Select.Option value={true}>{t("Yes")}</Select.Option>
      <Select.Option value={false}>{t("No")}</Select.Option>
    </Select>
  );
};

export default YesNo;
