import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useMoveAttendeeItineraryMutation } from "api/mutations/attendees";
import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import { Button, Select, Spin } from "components/styleguide";
import { FacilityResponseModel, FacilityType } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface SeminarSessionsMoveLocationProps {
  itineraryId: number;
  facilityId: number;
  timeSlotSessionId: number;
  onClose: () => void;
}

const SeminarSessionsMoveLocation: FC<SeminarSessionsMoveLocationProps> = ({
  itineraryId,
  timeSlotSessionId,
  facilityId,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const forumId = toNumber(useParams().id);
  const attendeeId = toNumber(useParams().attendeeId);

  const { mutate: moveMutate, isLoading: isMoveLoading } = useMoveAttendeeItineraryMutation();

  const { data: { data: facilities = [] as Array<FacilityResponseModel> } = {}, isLoading: isFacilitiesLoading } =
    useForumVenueFacilitiesQuery(
      {
        forumId: forumId,
        types: [FacilityType.ConferenceRoom],
        availableForTimeslotSessionId: timeSlotSessionId,
        minCapacityFacilityId: facilityId,
        includeEmpty: true,
      },
      { onError: () => toast.error(t("Something went wrong")) },
    );

  const facilitiesOptions = useMemo(() => {
    return facilities.map(({ id, name, tables, places }) => ({
      value: id,
      label: t("{{name}}, {{tables}} tables, {{seats}} seats", { name, tables, seats: places }),
    }));
  }, [facilities]);

  const move = (selectedFacilityId: number) => {
    moveMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: {
          forumFacilityId: selectedFacilityId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          onClose();
          toast.success(t("The session was moved to a new facility"));
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onSubmit = ({ id }: { id: number }) => move(id);

  if (isFacilitiesLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (!facilities.length) {
    return <>{t("There are no available facilities at this time")}</>;
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        label={t("Choose a Facility")}
        name="id"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: t("Facility") }),
          },
        ]}
      >
        <Select style={{ width: "350px" }} options={facilitiesOptions} disabled={isMoveLoading} />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={isMoveLoading} disabled={isMoveLoading}>
        {t("Move")}
      </Button>
    </Form>
  );
};

export default SeminarSessionsMoveLocation;
