import { GdprRequestApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(GdprRequestApi);

const GdprRequests = {
  getAll: api.gdprGet,
  put: api.gdprGdprRequestIdPut,
};

export default GdprRequests;
