import MailLink from "components/MailLink";
import { Button, Layout, Space, Typography } from "components/styleguide";
import useLogout from "hooks/useLogout";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.less";

const Page403: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logout = useLogout();

  const handleGoToHomepageClick = () => {
    navigate("/");
  };

  return (
    <Layout>
      <Layout.Content className={styles.container}>
        <div className={styles.content}>
          <Typography.Title className={styles.title} level={1}>
            {t("You don't have permissions to access this page.")}
          </Typography.Title>
          <Typography.Title className={styles.title} level={4}>
            <Trans i18nKey="contactITTeam">
              <MailLink
                to={process.env.REACT_APP_IT_TEAM_EMAIL as string}
                subject="Permissions required"
                body="Please specify which forum you would like to access (including the forum code)."
              >
                contact your IT team
              </MailLink>
            </Trans>
          </Typography.Title>
          <Space className={styles.buttons}>
            {
              <Button type="primary" onClick={handleGoToHomepageClick}>
                {t("Go to homepage")}
              </Button>
            }
            <Button type="primary" onClick={logout}>
              {t("Logout")}
            </Button>
          </Space>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default Page403;
