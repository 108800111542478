import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { Layout } from "../../../components/styleguide";

import styles from "./styles.module.less";

const SelectionSitesLayout = () => {
  const { id } = useOutletContext();

  return (
    <div className={styles.container}>
      <Layout.Content>
        <Outlet context={{ id }} />
      </Layout.Content>
    </div>
  );
};

export default SelectionSitesLayout;
