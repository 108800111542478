import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useCreateForumDocumentDefinitionSectionMutation = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionSection.post,
);

export const useUpdateForumDocumentDefinitionSectionMutation = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionSection.put,
);

export const useUpdateForumDocumentDefinitionSectionOptionsMutation = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionSection.optionsPut,
);

export const useCreateForumDocumentCustomPageMutation = getWrappedUseMutation(
  apiClient.ForumDocument.forumDocumentCustomPage.post,
);

export const useUpdateForumDocumentCustomPageElementMutation = getWrappedUseMutation(
  apiClient.ForumDocument.forumDocumentCustomPage.elements.put,
);

export const useCreateForumDocumentCustomPageElementMutation = getWrappedUseMutation(
  apiClient.ForumDocument.forumDocumentCustomPage.elements.post,
);

export const useDeleteForumDocumentCustomPageElementMutation = getWrappedUseMutation(
  apiClient.ForumDocument.forumDocumentCustomPage.elements.delete,
);

export const useReorderForumDocumentCustomPageElementMutation = getWrappedUseMutation(
  apiClient.ForumDocument.forumDocumentCustomPage.elements.reorder,
);

export const useDeleteForumDocumentDefinitionSectionMutation = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionSection.delete,
);

export const useUploadCustomForumDocumentPdf = getWrappedUseMutation(apiClient.ForumDocument.documentCustomPdf.post);

export const useUploadForumDocumentDefinitionWatermark = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionWatermark.post,
);

export const useDeleteForumDocumentWatermark = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionWatermark.delete,
);

export const useForumDocumentDefinitionSectionReorderMutation = getWrappedUseMutation(
  apiClient.ForumDocument.documentDefinitionSection.reorderPut,
);

export const useForumDocumentDefinitionElementCreateMutation = getWrappedUseMutation(
  apiClient.ForumDocumentDefinitionSection.elements.post,
);

export const useForumDocumentDefinitionElementUpdateMutation = getWrappedUseMutation(
  apiClient.ForumDocumentDefinitionSection.elements.put,
);

export const useForumDocumentDefinitionElementDeleteMutation = getWrappedUseMutation(
  apiClient.ForumDocumentDefinitionSection.elements.delete,
);

export const useForumDocumentDefinitionElementReorderMutation = getWrappedUseMutation(
  apiClient.ForumDocumentDefinitionSection.elements.order.put,
);
