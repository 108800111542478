import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useMoveAttendeeItineraryMutation, useMoveTestAttendeeItineraryMutation } from "api/mutations/attendees";
import KEYS from "api/queries/keys";
import { useTimeslotsQuery } from "api/queries/timeslots";
import DateRenderer from "components/DateRenderer";
import { Button, Select, Spin } from "components/styleguide";
import { AttendeeItineraryValidationResult, TimeSlotResponseModel, TimeSlotType } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  businessTime,
  checkIsTimeSlotTypeBusinessTime,
  checkIsTimeSlotTypeMealTime,
  mealTime,
} from "../helpers/timeSlotType";

interface MeetingsMoveTimingProps {
  itineraryId: number;
  timeSlotType: TimeSlotType;
  onClose: () => void;
}

const MeetingsMoveTiming: FC<MeetingsMoveTimingProps> = ({ itineraryId, timeSlotType, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const forumId = toNumber(useParams().id);
  const attendeeId = toNumber(useParams().attendeeId);

  const [timeslots, setTimeslots] = useState<TimeSlotResponseModel[]>([]);

  const { mutate: moveMutate, isLoading: isMoveLoading } = useMoveAttendeeItineraryMutation();
  const { mutate: validateMutate, isLoading: isValidateLoading } = useMoveTestAttendeeItineraryMutation();

  const getAvailableTimeSlotTypes = (): Array<TimeSlotType> | undefined => {
    if (checkIsTimeSlotTypeMealTime(timeSlotType)) {
      return mealTime;
    } else if (checkIsTimeSlotTypeBusinessTime(timeSlotType)) {
      return businessTime;
    }
  };

  const { isLoading: isTimeslotsLoading } = useTimeslotsQuery(
    {
      forumId,
      sessionsAvailableForMeeting: itineraryId,
    },
    {
      onSuccess: ({ data }) => {
        console.warn({ data });
        const availableTimeSlotTypes = getAvailableTimeSlotTypes();

        if (availableTimeSlotTypes) {
          const filteredTimeslots = data.filter(
            (timeslot) => timeslot.timeSlotType && availableTimeSlotTypes.includes(timeslot.timeSlotType),
          );
          setTimeslots(filteredTimeslots);
        }
      },
      onError: () => toast.error(t("Something went wrong")),
    },
  );

  const timeslotSessionsOptions = useMemo(() => {
    return timeslots
      .filter(({ sessions }) => sessions)
      .map(({ description, sessions }) => ({
        label: description,
        options: sessions?.map(({ id, startTime, endTime }) => ({
          value: id,
          label: (
            <>
              <DateRenderer date={startTime as string} showTime />
              {" - "}
              <DateRenderer date={endTime as string} showTime />
            </>
          ),
        })),
      }));
  }, [timeslots]);

  const getTimeslotId = (timeslotSessionId: number) => {
    return timeslots.find(({ sessions }) => {
      return sessions?.find(({ id }) => id === timeslotSessionId);
    })?.id;
  };

  const move = (timeslotSessionId: number) => {
    moveMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: {
          timeSlotId: getTimeslotId(timeslotSessionId),
          timeSlotSessionId: timeslotSessionId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          onClose();
          toast.success(t("The meeting was moved to a new time"));
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const getNotAvailableAttendeesErrorMessage = (notAvailableAttendees: Array<string>) => {
    if (notAvailableAttendees.length > 1) {
      return t("{{attendees}} are not available at the new time", {
        attendees: notAvailableAttendees.join(", "),
      });
    }

    return t("{{attendee}} is not available at the new time", { attendee: notAvailableAttendees[0] });
  };

  const validate = (timeslotSessionId: number) => {
    const timeslotId = getTimeslotId(timeslotSessionId);

    validateMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: {
          timeSlotId: timeslotId,
          timeSlotSessionId: timeslotSessionId,
        },
      },
      {
        onSuccess: ({ data }: { data: AttendeeItineraryValidationResult }) => {
          if (data.isValid) {
            move(timeslotSessionId);
          } else {
            if (data?.notAvailableAttendees && data?.notAvailableAttendees.length) {
              toast.error(getNotAvailableAttendeesErrorMessage(data.notAvailableAttendees));
            } else {
              toast.error(t(data?.message ?? "Something went wrong"));
            }
          }
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onSubmit = ({ id }: { id: number }) => validate(id);

  if (isTimeslotsLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (!timeslots.length) {
    return <>{t("There are no available timeslot sessions at this time")}</>;
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        label={t("Choose a Meeting session")}
        name="id"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: t("Conference Timeslot session") }),
          },
        ]}
      >
        <Select
          style={{ width: "350px" }}
          options={timeslotSessionsOptions}
          disabled={isValidateLoading || isMoveLoading}
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={isValidateLoading || isMoveLoading}
        disabled={isValidateLoading || isMoveLoading}
      >
        {t("Move")}
      </Button>
    </Form>
  );
};

export default MeetingsMoveTiming;
