import { BaseOptionType } from "antd/lib/select";
import { useGetSentEmailsQuery } from "api/queries/emails";
import { Layout, Table, Typography } from "components/styleguide";
import Info from "components/UserGuide/Info";
import { EmailTemplateCategory, PageResponseSentEmailResponseModel, SentEmailResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, getColumnSelectFilterProps } from "utils/tableColumnUtils";

import styles from "./styles.module.less";

const { Content } = Layout;
const { Text } = Typography;

const SearchSets = {
  Sender: "sender",
  Recipient: "recipient",
  Subject: "subject",
  Template: "template",
  IsSent: "isSent",
};

export default function EmailTracking() {
  const { t } = useTranslation();
  const { id: forumId } = useParams();
  let rowKey = 0;

  const [senderSearch, setSenderSearch] = useState<string | undefined>(undefined);
  const [recipientSearch, setRecipientSearch] = useState<string | undefined>(undefined);
  const [subjectSearch, setSubjectSearch] = useState<string | undefined>(undefined);
  const [templateFilter, setTemplateFilter] = useState<string | undefined>(undefined);

  const [isSentFilter, setIsSentFilter] = useState<string | undefined>();

  const [statusFilterOptions] = useState([
    { label: t("Sent"), value: true },
    { label: t("Failed"), value: false },
  ]);

  const [templateFilterOptions] = useState([
    { label: t("General"), value: EmailTemplateCategory.General },
    { label: t("Questionnaire"), value: EmailTemplateCategory.Questionnaire },
    { label: t("Selection Site"), value: EmailTemplateCategory.SelectionSite },
  ]);

  const [emails, setEmails] = useState<SentEmailResponseModel[] | undefined>(undefined);

  const {
    response: query,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetSentEmailsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseSentEmailResponseModel }) => {
        setEmails(data.items);
      },
    },
    {
      forumId: toNumber(forumId),
      sender: senderSearch,
      recipient: recipientSearch,
      subject: subjectSearch,
      template: templateFilter,
      isSent: isSentFilter,
    },
  );

  const handleSearch = (
    selectedKeys: string[] | BaseOptionType[],
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case SearchSets.Sender: {
        setSenderSearch(selectedKeys[0] as string);
        break;
      }
      case SearchSets.Recipient: {
        setRecipientSearch(selectedKeys[0] as string);
        break;
      }
      case SearchSets.Subject: {
        setSubjectSearch(selectedKeys[0] as string);
        break;
      }
      case SearchSets.Template: {
        const selected = selectedKeys as BaseOptionType[];
        setTemplateFilter(selected[0].value);
        break;
      }
      case SearchSets.IsSent: {
        const selected = selectedKeys as BaseOptionType[];
        setIsSentFilter(selected[0].value);
        break;
      }
    }
    confirm?.();
  };
  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case SearchSets.Sender: {
        setSenderSearch(undefined);
        break;
      }
      case SearchSets.Recipient: {
        setRecipientSearch(undefined);
        break;
      }
      case SearchSets.Subject: {
        setSubjectSearch(undefined);
        break;
      }
      case SearchSets.Template: {
        setTemplateFilter(undefined);
        break;
      }
      case SearchSets.IsSent: {
        setIsSentFilter(undefined);
        break;
      }
    }
    clearFilters?.();
    confirm?.();
  };

  const columns = [
    {
      title: senderSearch ?? t("Sender"),
      dataIndex: "sender",
      sorter: true,
      render: (_: string, record: SentEmailResponseModel) => {
        return (
          <div>
            <Text>{record.sender?.split(", ")[0]}</Text>
            <br />
            <Text type="secondary">{record.sender?.split(", ")[1]}</Text>
          </div>
        );
      },
      ...(getColumnSearchProps("sender", handleSearch, handleReset, "sender") as Partial<SentEmailResponseModel>),
    },
    {
      title: recipientSearch ?? t("Recipient"),
      dataIndex: "recipient",
      sorter: true,
      render: (_: string, record: SentEmailResponseModel) => {
        return (
          <div>
            <Text>{record.recipient?.split(", ")[0]}</Text>
            <br />
            <Text type="secondary">{record.recipient?.split(", ")[1]}</Text>
          </div>
        );
      },
      ...(getColumnSearchProps("recipient", handleSearch, handleReset, "recipient") as Partial<SentEmailResponseModel>),
    },
    {
      title: subjectSearch ?? t("Subject"),
      dataIndex: "subject",
      sorter: true,
      ...(getColumnSearchProps("subject", handleSearch, handleReset, "subject") as Partial<SentEmailResponseModel>),
    },
    {
      title: templateFilter ?? t("Template"),
      dataIndex: "template",
      ...(getColumnSelectFilterProps(
        "template",
        handleSearch,
        handleReset,
        "template",
        templateFilterOptions,
      ) as Partial<SentEmailResponseModel>),
    },
    {
      title: t("Time"),
      dataIndex: "time",
      render: (time: string) => {
        return <span>{moment.utc(time).utcOffset(moment().utcOffset()).format("L LT")}</span>;
      },
    },
    {
      title: t("Status"),
      dataIndex: "isSent",
      render: (value: boolean) => {
        return <span>{value ? t("Sent") : t("Failed")}</span>;
      },
      ...(getColumnSelectFilterProps(
        "isSent",
        handleSearch,
        handleReset,
        "isSent",
        statusFilterOptions,
      ) as Partial<SentEmailResponseModel>),
    },
  ];

  const getRowKey = () => {
    return `${rowKey++}`;
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <Info className={styles.info} articleId="16426144525981-email-tracking" />
        <Table
          columns={columns}
          dataSource={emails}
          loading={query.isLoading}
          rowKey={getRowKey}
          onChange={handleSort}
          pagination={pagination}
        />
      </Content>
    </Layout>
  );
}
