import { Cascader, MenuProps } from "antd";
import type { DefaultOptionType } from "antd/es/select";
import { Layout, Menu, Space, Typography } from "components/styleguide";
import Info, { InfoSize } from "components/UserGuide/Info";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { flattenReports, getReports, rootSubmenuKeys } from "./helpers";
import { OnSingleChange } from "./types";

import styles from "./styles.module.less";

const { Content } = Layout;
const { Title } = Typography;

const Reporting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = useParams();

  const [openKeys, setOpenKeys] = useState<string[]>([(location.state as { openKeys: string })?.openKeys]);

  useEffect(() => {
    if (category) {
      // get the menu keys from path
      const pathKeys = location.pathname.split("/").reverse();
      const breadcrumbOpenKeys = pathKeys.splice(0, pathKeys.indexOf("reporting")).reverse();

      navigate("../", { state: { openKeys: breadcrumbOpenKeys }, replace: true });
      setOpenKeys(breadcrumbOpenKeys);
    }
  }, [category, navigate, location.pathname]);

  const navigateToReport = (key: string, path: string) => {
    const selectedReport = flattenReports().find(({ key: reportKey }) => reportKey === key);

    if (selectedReport?.hasOptions) {
      navigate(`${path}/options/${key}`);
    } else {
      navigate(`${path}/report/${key}`);
    }
  };

  const onMenuSelect: MenuProps["onSelect"] = ({ key, keyPath: menuKeyPath }) => {
    // create path from categories keys
    const keyPath = menuKeyPath.reverse().slice(0, -1);
    const path = keyPath.join("/");

    navigateToReport(key, path);
  };

  const onSearchSelect: OnSingleChange<DefaultOptionType> = (value) => {
    if (!value) return;

    const path = value.slice(0, -1).join("/");
    navigateToReport(value[value.length - 1] as string, path);
  };

  const onOpenChange = (keys: string[]) => {
    // close other expanded first level submenus
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (!rootSubmenuKeys.includes(latestOpenKey as string)) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <div className={styles.reportsHeader}>
          <Space size={"middle"} wrap>
            <Title level={5} style={{ margin: 0 }}>
              {t("Select report")}
            </Title>
            <Cascader
              autoFocus
              allowClear
              showSearch
              className={styles.cascader}
              dropdownClassName={styles.cascaderDropdown}
              options={getReports()}
              onChange={onSearchSelect}
              placeholder={t("Search report")}
            />
          </Space>
          <Info className={styles.info} articleId="16426106552221-reporting" size={InfoSize.BIG} />
        </div>

        <Menu
          mode="inline"
          defaultOpenKeys={openKeys}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onSelect={onMenuSelect}
          className={styles.reportsMenu}
          items={getReports()}
        />
      </Content>
    </Layout>
  );
};

export default Reporting;
