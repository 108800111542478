import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useCreateSessionMutation = getWrappedUseMutation(apiClient.TimeSlotSession.post);

export const useUpdateSessionMutation = getWrappedUseMutation(apiClient.TimeSlotSession.put);

export const useDeleteSessionMutation = getWrappedUseMutation(apiClient.TimeSlotSession.delete);

export const useReorderSessionsMutation = getWrappedUseMutation(apiClient.TimeSlotSession.reoder.put);
