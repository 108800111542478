import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateForumBasedOnMutation,
  useCreateForumMutation,
  useUpdateForumMutation,
  useUploadLogoMutation,
} from "api/mutations/forums";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import FormComponent from "./components/FormComponent";

const duplicateCodeErrorMessage = "A forum with the same event code already exists. Change the code!";

const ForumDrawer = ({ existingForum = null, isEdit = false, visible, onClose }) => {
  const { t } = useTranslation();
  const [venueAssignmentType, setVenueAssignmentType] = useState();

  const queryClient = useQueryClient();

  const createForumMutation = useCreateForumMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.GET_FORUMS);
      onClose();
      toast.success(t("Forum Created Successfully"));
    },
    onError: ({ response }) => {
      if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
        toast.error(t(duplicateCodeErrorMessage));
      } else {
        toast.error(response.data.Message);
      }
    },
  });

  const createForumBasedOnMutation = useCreateForumBasedOnMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(KEYS.GET_FORUMS);
      onClose();
      toast.success(t("Forum Created Successfully"));
    },
    onError: ({ response }) => {
      if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
        toast.error(t(duplicateCodeErrorMessage));
      } else {
        toast.error(response.data.Message);
      }
    },
  });

  const updateForumMutation = useUpdateForumMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([KEYS.GET_FORUMS]);
      onClose();
      toast.success(t("Forum Updated Successfully"));
    },
    onError: ({ response }) => {
      if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
        toast.error(t(duplicateCodeErrorMessage));
      } else {
        toast.error(response.data.Message);
      }
    },
  });

  const uploadLogoMutation = useUploadLogoMutation();

  const updateForum = useCallback(
    (forumData) => {
      if (!existingForum) {
        createForumMutation.mutate({ forumRequestModel: forumData });
      } else if (isEdit) {
        updateForumMutation.mutate({
          id: toNumber(existingForum.id),
          venueAssignmentType,
          forumRequestModel: { venueAssignmentType, ...forumData },
        });
      } else {
        createForumBasedOnMutation.mutate({
          existingForumId: existingForum.id,
          forumRequestModel: forumData,
        });
      }
    },
    [existingForum, venueAssignmentType, createForumMutation, updateForumMutation, createForumBasedOnMutation, isEdit],
  );

  const handleFormSubmit = ({ duration, logo, ...values }) => {
    const forumData = {
      startDate: duration[0].format("YYYY-MM-DD"), // to use the date only, without the local time, which may send a wrong date
      endDate: duration[1].format("YYYY-MM-DD"),
      ...values,
    };

    if (!logo) {
      updateForum(forumData);
    } else {
      uploadLogoMutation.mutate(
        {
          formFile: logo?.[0].originFileObj,
        },
        {
          onSuccess: ({ data }) => {
            updateForum({ ...forumData, logoFileBlobId: data });
            toast.success(t("Forum Created Successfully"));
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    }
  };

  const isMutationsLoading =
    createForumMutation.isLoading ||
    createForumBasedOnMutation.isLoading ||
    updateForumMutation.isLoading ||
    uploadLogoMutation.isLoading;

  return (
    <Drawer
      open={isMutationsLoading || visible}
      placement="right"
      title={isEdit ? t("Edit Forum") : t("addNewForum")}
      size="large"
      destroyOnClose={true}
      onClose={isMutationsLoading ? false : onClose}
    >
      <FormComponent
        existingForum={existingForum}
        venueAssignmentType={venueAssignmentType}
        isEdit={isEdit}
        isLoading={isMutationsLoading}
        onCancel={isMutationsLoading ? () => false : onClose}
        onVenueAssignmentTypeChange={setVenueAssignmentType}
        onFinish={handleFormSubmit}
      />
    </Drawer>
  );
};

ForumDrawer.propTypes = {
  existingForum: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isEdit: PropTypes.bool,
  isDrawerOpen: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  form: PropTypes.object,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ForumDrawer;
