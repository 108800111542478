import { useQueryClient } from "@tanstack/react-query";
import { TableProps } from "antd";
import { useUpdateForumAttendeeSessionMeetingLocationsMutation } from "api/mutations/forumAttendeeSessionMeetingLocation";
import { useForumAttendeeSessionMeetingLocationsQuery } from "api/queries/forumAttendeeSessionMeetingLocations";
import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import { Table } from "components/styleguide";
import { AttendeeSessionMeetingLocationModel, PageResponseAttendeeSessionMeetingLocationModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { isEmpty } from "lodash";
import React, { FC, HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";

import MeetingLocationsAdvancedSetupRow from "./MeetingLocationsAdvancedSetupRow";
interface AttendeeMeetingLocationsAdvancedSetupProps {
  forumId: number;
  attendeeId: number;
  isQuickSetupLoading: boolean;
}

const AdvancedSetup: FC<AttendeeMeetingLocationsAdvancedSetupProps> = ({
  forumId,
  attendeeId,
  isQuickSetupLoading,
}) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [meetingLocations, setMeetingLocations] = useState<AttendeeSessionMeetingLocationModel[]>([]);

  const forumFacilitiesQuery = useForumVenueFacilitiesQuery({ forumId });
  const forumFacilities = forumFacilitiesQuery.data?.data ?? [];

  const sessionMeetingLocationUpdate = useUpdateForumAttendeeSessionMeetingLocationsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS]);
    },
  });

  const columns: TableProps<AttendeeSessionMeetingLocationModel>["columns"] = [
    {
      title: t("Timeslot"),
      dataIndex: "timeSlot",
      key: "timeSlot",
      sorter: true,
    },
    {
      title: t("Facility"),
      dataIndex: "facility",
      key: "facility",
    },
    {
      title: t("Table"),
      dataIndex: "table",
      key: "table",
    },
    {
      title: t("Ratio"),
      dataIndex: "ratio",
      key: "ratio",
    },
    {
      title: t("Session"),
      dataIndex: "timeSlotSession",
      key: "timeSlotSession",
    },
    {
      title: t("Is Locked"),
      dataIndex: "isLocked",
      key: "isLocked",
    },
  ];

  const {
    response: { isLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useForumAttendeeSessionMeetingLocationsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseAttendeeSessionMeetingLocationModel }) => {
        if (data.items) {
          setMeetingLocations(data.items);
        }
      },
    },
    {
      forumId,
      attendeeId,
    },
  );

  const handleFormSubmit = async (data: AttendeeSessionMeetingLocationModel) => {
    const dataToSubmit = {
      forumId,
      attendeeId,
      timeSlotId: data.timeSlot.id as number,
      attendeeSessionMeetingLocationRequestModel: {
        timeSlotSessionId: data.timeSlotSession?.id || null,
        facilityId: data?.facility?.id || null,
        tableId: data?.table?.id || null,
        ratio: data.ratio || 0,
        isLocked: data.isLocked || false,
      },
    };

    sessionMeetingLocationUpdate.mutate(dataToSubmit, {
      onSuccess: () => {
        queryClient.invalidateQueries([KEYS.GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS]);
        queryClient.invalidateQueries([KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS]);
        queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID]);
        queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS]);
      },
    });
  };

  const isAdvancedSetupLoading =
    isLoading || forumFacilitiesQuery.isLoading || isQuickSetupLoading || sessionMeetingLocationUpdate.isLoading;

  return (
    <Table
      columns={columns}
      dataSource={meetingLocations}
      components={{
        body: {
          row: isEmpty(meetingLocations) ? () => <></> : MeetingLocationsAdvancedSetupRow,
        },
      }}
      onRow={(record, index) => {
        return {
          forumId,
          meetingLocation: record,
          setMeetingLocation: (idx: number, value: AttendeeSessionMeetingLocationModel) => {
            const newValues = [...meetingLocations.slice(0, idx), value, ...meetingLocations.slice(idx + 1)];
            setMeetingLocations(newValues);
            handleFormSubmit(value);
          },
          index,
          forumFacilities,
          loading: isAdvancedSetupLoading,
        } as HTMLAttributes<HTMLElement>;
      }}
      loading={isAdvancedSetupLoading}
      onChange={handleSort}
      pagination={pagination}
      rowKey={(record) => record.timeSlot.id || 0}
      id="meeting-locations-advanced-table"
    />
  );
};

export default AdvancedSetup;
