import { TabsProps } from "antd";
import { Layout, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useTranslation } from "react-i18next";

import CopySetup from "./CopySetup";
import LanguageLabelsTimeFormats from "./LanguageLabelsTimeFormats";
import TeamDetails from "./TeamDetails";
import WelcomeLetter from "./WelcomeLetter";

import styles from "./styles.module.less";

const { Content } = Layout;

export default function Essentials() {
  const { t } = useTranslation();

  const tabs: TabsProps["items"] = [
    {
      key: "welcomeLetter",
      label: t("Welcome Letter"),
      children: <WelcomeLetter />,
    },
    {
      key: "teamDetails",
      label: t("Team Details"),
      children: <TeamDetails />,
    },
    {
      key: "language",
      label: t("Language, Labels & Time Formats"),
      children: <LanguageLabelsTimeFormats />,
    },
    {
      key: "copySetup",
      label: t("Copy Setup"),
      children: <CopySetup />,
    },
  ];

  return (
    <Layout>
      <UserGuide.Title title="Essentials" articleId="16441706974493-essentials" />
      <Content className={styles.essentials}>
        <Tabs items={tabs} />
      </Content>
    </Layout>
  );
}
