import { useForumFacilityQuery } from "api/queries/forumFacilities";
import { useForumItineraryConferenceFacilityTimeslotSessionsQuery } from "api/queries/forumItinerary";
import MobileLinkList, { LinkListProp } from "components/MobileLinkList";
import { Grid, SpinLoading } from "components/styleguide/mobile";
import { FacilityResponseModel, TimeSlotSessionListItemModel } from "generated/api";
import { toNumber } from "lodash-es";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";

import { PlaybookOutletContext } from "../../types";

export default function PlaybookFacility() {
  const { id, buildingId, facilityId } = useParams();
  const { t } = useTranslation();
  const { setTitle } = useOutletContext<PlaybookOutletContext>();

  const { isLoading: isLoadingFacility } = useForumFacilityQuery(
    {
      forumId: toNumber(id),
      forumBuildingId: toNumber(buildingId),
      facilityId: toNumber(facilityId),
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess: ({ data }: { data: FacilityResponseModel }) => {
        setTitle(data.name);
      },
    },
  );

  const { data: { data: sessions = [] } = {}, isLoading: isLoadingSessions } =
    useForumItineraryConferenceFacilityTimeslotSessionsQuery(
      {
        forumId: toNumber(id),
        forumMeetingFacilityId: toNumber(facilityId),
      },
      { cacheTime: Infinity, staleTime: Infinity },
    );

  if (!sessions || isLoadingSessions || isLoadingFacility) {
    if (isLoadingFacility) {
      setTitle(t("Loading..."));
    }

    return (
      <div className="loader">
        <SpinLoading style={{ "--size": "48px" }} />
      </div>
    );
  }

  return (
    <Grid columns={2} gap={8}>
      <MobileLinkList
        items={sessions?.map((s: TimeSlotSessionListItemModel) => {
          return {
            id: s.id?.toString(),
            title: moment(s.startTime).format("LT"),
            link: `sessions/${s.timeSlotId}/${s.id}`,
            footerTextLeft: moment(s.startTime).format("L"),
          } as LinkListProp;
        })}
      />
    </Grid>
  );
}
