import { ForumTableApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumTableApi);

const ForumTables = {
  getAll: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesGet,
  get: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdGet,
  post: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesPost,
  put: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdPut,
  delete: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdTablesForumTableIdDelete,
};

export default ForumTables;
