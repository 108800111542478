import React from 'react';
import MainTheme from "./Themes/MainTheme";
import { SUPPORTED_THEMES } from "./constants";

const themes = {
  [SUPPORTED_THEMES.main]: MainTheme,
};

const Theme = ({type, children}) => {

  const SelectedTheme = themes[type];

  return (
    <div id="theme-container" style={{height: "100%"}} data-theme={type}>
      <SelectedTheme>
        {children}
      </SelectedTheme>
    </div>
  )
};

export {
  SUPPORTED_THEMES,
};

export default Theme;
