import AuthService from "services/auth";
import {msalInstance} from "../index";
import {loginRequest} from "../authConfig";

export const requestInterceptorResolve = async (config) => {
  //** TODO HANDLE EXPIRED TOKEN
  const accessToken = await msalInstance
    .acquireTokenSilent({...loginRequest})
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => msalInstance.loginRedirect(loginRequest));

  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  } else {
    msalInstance.loginRedirect(loginRequest);
  }
  config.headers["Content-Type"] = "application/json";
  return config;
};

export const requestFormDataInterceptorResolve = async (config) => {
  //** TODO HANDLE EXPIRED TOKEN
  const accessToken = await msalInstance
    .acquireTokenSilent({...loginRequest})
    .then((response) => {
      return response.accessToken;
    })
    .catch(() => msalInstance.loginRedirect(loginRequest));

  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  } else {
    msalInstance.loginRedirect(loginRequest);
  }
  config.headers["Content-Type"] = "multipart/form-data";
  return config;
};

export const requestInterceptorReject = (config) => config;

export const responseInterceptorResolve = (config) => config;

export const responseInterceptorReject = (response) => {
  return Promise.reject(response);
};
