import React, { memo, useMemo } from "react";
import PropTypes from "prop-types";
import QuestionnaireStatusClass from "../../../../../backend-models/questionnaireStatus";
import { Tag } from "components/styleguide";
import {useTranslation} from "react-i18next";

const QuestionnaireStatus = ({ value }) => {
  const { t } = useTranslation();
  const status = useMemo(() => new QuestionnaireStatusClass(value), [value]);
  const text = status.toString() ?? t("unknownStatus");

  /**
   * @type {'green'|'red'|undefined}
   */
  const color = useMemo(() => {
    if (status.isOpen) {
      return "green";
    }

    if (status.isDraft) {
      return "red";
    }

    return undefined;
  }, [status]);

  return <Tag color={color}>{text}</Tag>;
};

QuestionnaireStatus.propTypes = {
  value: PropTypes.oneOf(QuestionnaireStatusClass.VALUES),
};

export default memo(QuestionnaireStatus);
