import { EventLayoutContext } from "layouts/EventLayout/types";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import { Layout } from "../../components/styleguide";

import styles from "./styles.module.less";

const { Content } = Layout;

const ConferenceLayout = () => {
  const { id, forum } = useOutletContext<EventLayoutContext>();

  return (
    <Layout className={styles.layout}>
      <Layout.Content>
        <div className={styles.page}>
          <Content>
            <Outlet context={{ id, forum }} />
          </Content>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ConferenceLayout;
