import PropTypes from "prop-types";
import Page from "./types/page";
import Item from "./types/item";
import ItemType from "./types/itemType";

const Pages = PropTypes.arrayOf(Page);
const Items = PropTypes.arrayOf(Item.ANY);

const SelectionSitesPropTypes = {
  Pages,
  Page,
  Items,
  Item,
  ItemType,
};

export default SelectionSitesPropTypes;
