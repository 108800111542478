import { AttendeeItineraryRemovalReasonType } from "generated/api/apiModels/attendee-itinerary-removal-reason-type";
import { TFunction } from "react-i18next";

interface Tab {
  label: string;
  key: string;
  children: 0 | JSX.Element | null;
}

export const filterTabs = (tabs: Array<Tab>, keys: Array<string>) => tabs.filter(({ key }) => keys.includes(key));

export type RemovalReasonsType = {
  [AttendeeItineraryRemovalReasonType.NoneSpecified]: string;
  [AttendeeItineraryRemovalReasonType.AttendeeArrivingLate]: string;
  [AttendeeItineraryRemovalReasonType.AttendeeLeavingEarly]: string;
  [AttendeeItineraryRemovalReasonType.AttendeeRequestedMeetingRemoved]: string;
  [AttendeeItineraryRemovalReasonType.AttendeeHasPreviousEngagement]: string;
  [AttendeeItineraryRemovalReasonType.Other]: string;
};

export const RemovalReasons: RemovalReasonsType = {
  [AttendeeItineraryRemovalReasonType.NoneSpecified]: "Not Specified",
  [AttendeeItineraryRemovalReasonType.AttendeeArrivingLate]: "Arriving Late",
  [AttendeeItineraryRemovalReasonType.AttendeeLeavingEarly]: "Leaving Early",
  [AttendeeItineraryRemovalReasonType.AttendeeRequestedMeetingRemoved]: "Meeting Removed",
  [AttendeeItineraryRemovalReasonType.AttendeeHasPreviousEngagement]: "Has Previous Engagement",
  [AttendeeItineraryRemovalReasonType.Other]: "Other",
};

export const getRemovalReasonDisplayText = (t: TFunction, value: string) => {
  const enumValues = Object.keys(RemovalReasons);

  if (enumValues.includes(value)) {
    return t(RemovalReasons[value as keyof RemovalReasonsType]);
  }
  return value;
};
