import { Skeleton } from "components/styleguide";

interface MenuPlaceholder {
  label: JSX.Element;
  key: string;
  id: string;
  children?:
    | {
        key: string;
        label: JSX.Element;
        id: string;
        link: string;
      }[]
    | null;
}

const getMenuPlaceholders = (repeat = 1) => {
  const placeholders: MenuPlaceholder[] = [];
  for (let index = 0; index < repeat; index++) {
    placeholders.push({
      label: <Skeleton.Button size="small" active={true} shape="round" block={true} />,
      key: `skeleton_${index}`,
      id: `skeleton_${index}`,
      children: null,
    });
  }

  return placeholders;
};

export { getMenuPlaceholders };
