import "./styles.less";

import { useCreateVenueMutation, useUploadVenueMapMutation } from "api/mutations/venues";
import { useGetCountriesQuery } from "api/queries/countries";
import { useGetVenuesQuery } from "api/queries/venues";
import { Button, Col, Form, Layout, Row, Table } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import NewVenueDrawer from "../NewVenue";

const { Header, Content } = Layout;

export default function AllVenues() {
  const { t } = useTranslation();
  const [newVenueForm] = Form.useForm();

  const createVenueMutation = useCreateVenueMutation();
  const uploadVenueMapMutation = useUploadVenueMapMutation();

  const [venues, setVenues] = useState([]);
  const [countries, setCountries] = useState([]);
  const [newVenueDrawerVisible, setNewVenueDrawerVisible] = useState(false);
  const [codeSearch, setCodeSearch] = useState(undefined);
  const [nameSearch, setNameSearch] = useState(undefined);

  const SEARCHABLE_COLUMN_TITLES = {
    code: "code",
    title: "name",
  };

  const handleSearch = (selectedKeys, confirm, searchSet) => {
    if (searchSet === SEARCHABLE_COLUMN_TITLES.code) {
      setCodeSearch(selectedKeys[0]);
    }
    if (searchSet === SEARCHABLE_COLUMN_TITLES.title) {
      setNameSearch(selectedKeys[0]);
    }

    confirm?.();
  };

  const handleReset = (clearFilters, dataIndex, searchSet, confirm) => {
    if (searchSet === SEARCHABLE_COLUMN_TITLES.code) {
      setCodeSearch(undefined);
    }
    if (searchSet === SEARCHABLE_COLUMN_TITLES.title) {
      setNameSearch(undefined);
    }

    clearFilters?.();
    confirm?.();
  };

  const columnTitle = (value) => <span className="filtered-title">{value}</span>;

  const columns = [
    {
      title: codeSearch ? columnTitle(codeSearch) : t("venueCode"),
      dataIndex: "code",
      sorter: true,
      render: (_, record) => {
        return (
          <Link className="venue-link" to={`${record.id}`} state={{ venueName: record.name }}>
            {record.code}
          </Link>
        );
      },
      ...getColumnSearchProps("code", handleSearch, handleReset, "code"),
    },
    {
      title: nameSearch ? columnTitle(nameSearch) : t("venueName"),
      dataIndex: "name",
      sorter: true,
      render: (_, record) => {
        return (
          <Link className="venue-link" to={`${record.id}`} state={{ venueName: record.name }}>
            {record.name}
          </Link>
        );
      },
      ...getColumnSearchProps("name", handleSearch, handleReset, "name"),
    },
    {
      title: t("venueAddress"),
      dataIndex: "address",
      sorter: true,
    },
    {
      title: t("venueCity"),
      dataIndex: "city",
      sorter: true,
    },
    { title: t("venueCountry"), dataIndex: "country", sorter: true },
    { title: t("venueEmail"), dataIndex: "email", sorter: true },
    { title: t("venuePhone"), dataIndex: "phone", sorter: true },
    { title: t("venueWebsite"), dataIndex: "websiteAddress", sorter: true },
  ];

  const {
    response: query,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetVenuesQuery,
    {
      onSuccess: ({ data }) => {
        setVenues(data.items);
      },
    },
    { code: codeSearch, name: nameSearch },
    { defaultPageSize: 50, pageSizeOptions: [20, 50, 100] },
  );

  useGetCountriesQuery(
    {},
    {
      onSuccess: ({ data }) => {
        setCountries(data);
      },
    },
  );

  const hideNewVenueDrawer = () => {
    newVenueForm.resetFields();
    setNewVenueDrawerVisible(false);
  };

  const showNewVenueDrawer = () => {
    setNewVenueDrawerVisible(true);
  };

  const uploadVenueMap = (venueId, mapFile, onSuccess) => {
    uploadVenueMapMutation.mutate(
      {
        venueId,
        formFile: mapFile,
      },
      {
        onSuccess,
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onCreateVenueSuccess = () => {
    hideNewVenueDrawer();
    toast.success(t("Venue successfully created"));
  };

  const submitNewVenueForm = (values) => {
    const { map } = values;

    createVenueMutation.mutate(
      {
        venueRequestModel: values,
      },
      {
        onSuccess: ({ data: createdVenue }) => {
          query.refetch();

          if (Array.isArray(map) && map.length) {
            uploadVenueMap(createdVenue.id, map[0]?.originFileObj, onCreateVenueSuccess);
          } else {
            onCreateVenueSuccess();
          }
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <div className="venues-page">
      <Layout>
        <Header>
          <Row id="venueControls" gutter={16}>
            <Col>
              <Button onClick={showNewVenueDrawer}>{t("addVenue")}</Button>
            </Col>
          </Row>
        </Header>
        <Content>
          <NewVenueDrawer
            form={newVenueForm}
            visible={newVenueDrawerVisible}
            onClose={hideNewVenueDrawer}
            isLoading={createVenueMutation.isLoading}
            countries={countries}
            onFinish={submitNewVenueForm}
          />
          <UserGuide.TableTitle className="container" articleId="16426524184733-venues" title="Venues">
            <Table
              pagination={pagination}
              loading={query.isLoading}
              id="venuesTable"
              dataSource={venues}
              columns={columns}
              onChange={handleSort}
              rowKey="id"
            />
          </UserGuide.TableTitle>
        </Content>
      </Layout>
    </div>
  );
}
