import { t } from "i18next";
import React from "react";

import ErrorTypes from "../../../../../error-handling/errorTypes";

// create column string join the 4 properties (exclude properties that are null)
export const buildAttendeeColumn = (
  name: string | null,
  participationCode: string | null,
  jobTitle: string | null | undefined,
  company: string | null | undefined,
) => [name, participationCode, jobTitle, company].filter((value) => value).join(", ");

/**
 * Checks if input value is not between 0 and 1000 and adds error to the passed state
 * @returns `true` if value is out of range, otherwise `false`
 */
export const validatePriorityRange = (
  preferenceErrors: { [meetingId: number]: string[] },
  setPreferenceErrors: React.Dispatch<React.SetStateAction<{ [meetingId: number]: string[] }>>,
  rowId: number,
  value: number,
): boolean => {
  if (value <= 0 || value > 1000) {
    setPreferenceErrors({
      ...preferenceErrors,
      [rowId]: [
        ...(preferenceErrors[rowId] ? preferenceErrors[rowId] : []),
        t("errors.Must be between {{start}} and {{end}}", { start: 1, end: 1000 }),
      ],
    });
    return true;
  }
  return false;
};

/** Checks if response error is DuplicateItem and adds error to the passed state */
export const handleDuplicatePriorityError = (
  preferenceErrors: { [meetingId: number]: string[] },
  setPreferenceErrors: React.Dispatch<React.SetStateAction<{ [meetingId: number]: string[] }>>,
  rowId: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
) => {
  if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
    setPreferenceErrors({
      ...preferenceErrors,
      [rowId]: [
        ...(preferenceErrors[rowId] ? preferenceErrors[rowId] : []),
        t("This priority is already set for a meeting"),
      ],
    });
  }
};
