/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AssociatedObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { CustomRole } from '../apiModels';
// @ts-ignore
import { FileResponseModel } from '../apiModels';
// @ts-ignore
import { ForumAddedResult } from '../apiModels';
// @ts-ignore
import { ForumContactInfoModel } from '../apiModels';
// @ts-ignore
import { ForumRequestModel } from '../apiModels';
// @ts-ignore
import { ForumResponseModel } from '../apiModels';
// @ts-ignore
import { ForumSocialLinksModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseForumResponseModel } from '../apiModels';
// @ts-ignore
import { SisterForumResponseModel } from '../apiModels';
// @ts-ignore
import { UpdateForumTestRequestModel } from '../apiModels';
// @ts-ignore
import { UpdateForumTestResponseModel } from '../apiModels';
/**
 * ForumsApi - axios parameter creator
 * @export
 */
export const ForumsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a forum based on a previous occurence, copying questionnaires, selection sites, groups and contacts
         * @param {number} existingForumId 
         * @param {ForumRequestModel} [forumRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsExistingForumIdPost: async (existingForumId: number, forumRequestModel?: ForumRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'existingForumId' is not null or undefined
            assertParamExists('forumsExistingForumIdPost', 'existingForumId', existingForumId)
            const localVarPath = `/forums/{existingForumId}`
                .replace(`{${"existingForumId"}}`, encodeURIComponent(String(existingForumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} forumCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumCodeGet: async (forumCode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumCode' is not null or undefined
            assertParamExists('forumsForumCodeGet', 'forumCode', forumCode)
            const localVarPath = `/forums/{forumCode}`
                .replace(`{${"forumCode"}}`, encodeURIComponent(String(forumCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets forum contact details by id
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdContactGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdContactGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/contact`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Forum
         * @param {number} forumId 
         * @param {ForumContactInfoModel} [forumContactInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdContactPut: async (forumId: number, forumContactInfoModel?: ForumContactInfoModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdContactPut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/contact`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumContactInfoModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets forum by id
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an URL with Read/Write access (available for 1 hour) to the specified forum\'s logo in Azure Storage
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdLogoAccessUrlGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdLogoAccessUrlGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/logo-access-url`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all sister-forum names by forumId
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSisterForumsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSisterForumsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/sister-forums`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets forum social links by forumId
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSocialLinksGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSocialLinksGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/social-links`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates forum social links by forumId
         * @param {number} forumId 
         * @param {ForumSocialLinksModel} [forumSocialLinksModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSocialLinksPut: async (forumId: number, forumSocialLinksModel?: ForumSocialLinksModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSocialLinksPut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/social-links`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSocialLinksModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets forums by search filter
         * @param {number} [year] If year is specified - only forums of specifeid years are returned  Otherwise forums of all years are displayed
         * @param {string} [code] Forum.Code contains passed \&#39;Code\&#39; string
         * @param {string} [title] Forum.Title contains passed \&#39;Title\&#39; string
         * @param {boolean} [fav] Pass fav&#x3D;true to get favorite forums only
         * @param {string} [countryCode] 
         * @param {number} [venueId] 
         * @param {string} [maxStartDate] 
         * @param {string} [minEndDate] 
         * @param {string} [shortName] Forum.ShortName contains passed \&#39;ShortName\&#39; string
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsGet: async (year?: number, code?: string, title?: string, fav?: boolean, countryCode?: string, venueId?: number, maxStartDate?: string, minEndDate?: string, shortName?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (fav !== undefined) {
                localVarQueryParameter['Fav'] = fav;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['CountryCode'] = countryCode;
            }

            if (venueId !== undefined) {
                localVarQueryParameter['VenueId'] = venueId;
            }

            if (maxStartDate !== undefined) {
                localVarQueryParameter['MaxStartDate'] = (maxStartDate as any instanceof Date) ?
                    (maxStartDate as any).toISOString() :
                    maxStartDate;
            }

            if (minEndDate !== undefined) {
                localVarQueryParameter['MinEndDate'] = (minEndDate as any instanceof Date) ?
                    (minEndDate as any).toISOString() :
                    minEndDate;
            }

            if (shortName !== undefined) {
                localVarQueryParameter['ShortName'] = shortName;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a forum by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumsIdDelete', 'id', id)
            const localVarPath = `/forums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Forum
         * @param {number} id 
         * @param {ForumRequestModel} [forumRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsIdPut: async (id: number, forumRequestModel?: ForumRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumsIdPut', 'id', id)
            const localVarPath = `/forums/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tests what objects will be changed/deleted after Forum update
         * @param {number} id 
         * @param {UpdateForumTestRequestModel} [updateForumTestRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsIdTestPut: async (id: number, updateForumTestRequestModel?: UpdateForumTestRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('forumsIdTestPut', 'id', id)
            const localVarPath = `/forums/{id}/test`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateForumTestRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload forum logo
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsLogoPost: async (formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forums/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Title should be unique
         * @summary Creates a Forum
         * @param {ForumRequestModel} [forumRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsPost: async (forumRequestModel?: ForumRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get roles available for forum groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsRolesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forums/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of years with forums sorted desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsYearsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forums/years`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumsApi - functional programming interface
 * @export
 */
export const ForumsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a forum based on a previous occurence, copying questionnaires, selection sites, groups and contacts
         * @param {number} existingForumId 
         * @param {ForumRequestModel} [forumRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsExistingForumIdPost(existingForumId: number, forumRequestModel?: ForumRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsExistingForumIdPost(existingForumId, forumRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} forumCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumCodeGet(forumCode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumCodeGet(forumCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets forum contact details by id
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdContactGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumContactInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdContactGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a Forum
         * @param {number} forumId 
         * @param {ForumContactInfoModel} [forumContactInfoModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdContactPut(forumId: number, forumContactInfoModel?: ForumContactInfoModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumContactInfoModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdContactPut(forumId, forumContactInfoModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets forum by id
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets an URL with Read/Write access (available for 1 hour) to the specified forum\'s logo in Azure Storage
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdLogoAccessUrlGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdLogoAccessUrlGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all sister-forum names by forumId
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSisterForumsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SisterForumResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSisterForumsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets forum social links by forumId
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSocialLinksGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumSocialLinksModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSocialLinksGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates forum social links by forumId
         * @param {number} forumId 
         * @param {ForumSocialLinksModel} [forumSocialLinksModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSocialLinksPut(forumId: number, forumSocialLinksModel?: ForumSocialLinksModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumSocialLinksModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSocialLinksPut(forumId, forumSocialLinksModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets forums by search filter
         * @param {number} [year] If year is specified - only forums of specifeid years are returned  Otherwise forums of all years are displayed
         * @param {string} [code] Forum.Code contains passed \&#39;Code\&#39; string
         * @param {string} [title] Forum.Title contains passed \&#39;Title\&#39; string
         * @param {boolean} [fav] Pass fav&#x3D;true to get favorite forums only
         * @param {string} [countryCode] 
         * @param {number} [venueId] 
         * @param {string} [maxStartDate] 
         * @param {string} [minEndDate] 
         * @param {string} [shortName] Forum.ShortName contains passed \&#39;ShortName\&#39; string
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsGet(year?: number, code?: string, title?: string, fav?: boolean, countryCode?: string, venueId?: number, maxStartDate?: string, minEndDate?: string, shortName?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseForumResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsGet(year, code, title, fav, countryCode, venueId, maxStartDate, minEndDate, shortName, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a forum by id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a Forum
         * @param {number} id 
         * @param {ForumRequestModel} [forumRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsIdPut(id: number, forumRequestModel?: ForumRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsIdPut(id, forumRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Tests what objects will be changed/deleted after Forum update
         * @param {number} id 
         * @param {UpdateForumTestRequestModel} [updateForumTestRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsIdTestPut(id: number, updateForumTestRequestModel?: UpdateForumTestRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateForumTestResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsIdTestPut(id, updateForumTestRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload forum logo
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsLogoPost(formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsLogoPost(formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Title should be unique
         * @summary Creates a Forum
         * @param {ForumRequestModel} [forumRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsPost(forumRequestModel?: ForumRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAddedResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsPost(forumRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get roles available for forum groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsRolesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomRole>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsRolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get list of years with forums sorted desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsYearsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsYearsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumsApi - factory interface
 * @export
 */
export const ForumsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a forum based on a previous occurence, copying questionnaires, selection sites, groups and contacts
         * @param {ForumsApiForumsExistingForumIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsExistingForumIdPost(requestParameters: ForumsApiForumsExistingForumIdPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumResponseModel> {
            return localVarFp.forumsExistingForumIdPost(requestParameters.existingForumId, requestParameters.forumRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumsApiForumsForumCodeGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumCodeGet(requestParameters: ForumsApiForumsForumCodeGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumResponseModel> {
            return localVarFp.forumsForumCodeGet(requestParameters.forumCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets forum contact details by id
         * @param {ForumsApiForumsForumIdContactGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdContactGet(requestParameters: ForumsApiForumsForumIdContactGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumContactInfoModel> {
            return localVarFp.forumsForumIdContactGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Forum
         * @param {ForumsApiForumsForumIdContactPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdContactPut(requestParameters: ForumsApiForumsForumIdContactPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumContactInfoModel> {
            return localVarFp.forumsForumIdContactPut(requestParameters.forumId, requestParameters.forumContactInfoModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets forum by id
         * @param {ForumsApiForumsForumIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdGet(requestParameters: ForumsApiForumsForumIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumResponseModel> {
            return localVarFp.forumsForumIdGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets an URL with Read/Write access (available for 1 hour) to the specified forum\'s logo in Azure Storage
         * @param {ForumsApiForumsForumIdLogoAccessUrlGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdLogoAccessUrlGet(requestParameters: ForumsApiForumsForumIdLogoAccessUrlGetRequest, options?: AxiosRequestConfig): AxiosPromise<FileResponseModel> {
            return localVarFp.forumsForumIdLogoAccessUrlGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all sister-forum names by forumId
         * @param {ForumsApiForumsForumIdSisterForumsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSisterForumsGet(requestParameters: ForumsApiForumsForumIdSisterForumsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SisterForumResponseModel>> {
            return localVarFp.forumsForumIdSisterForumsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets forum social links by forumId
         * @param {ForumsApiForumsForumIdSocialLinksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSocialLinksGet(requestParameters: ForumsApiForumsForumIdSocialLinksGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumSocialLinksModel> {
            return localVarFp.forumsForumIdSocialLinksGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates forum social links by forumId
         * @param {ForumsApiForumsForumIdSocialLinksPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSocialLinksPut(requestParameters: ForumsApiForumsForumIdSocialLinksPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumSocialLinksModel> {
            return localVarFp.forumsForumIdSocialLinksPut(requestParameters.forumId, requestParameters.forumSocialLinksModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets forums by search filter
         * @param {ForumsApiForumsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsGet(requestParameters: ForumsApiForumsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResponseForumResponseModel> {
            return localVarFp.forumsGet(requestParameters.year, requestParameters.code, requestParameters.title, requestParameters.fav, requestParameters.countryCode, requestParameters.venueId, requestParameters.maxStartDate, requestParameters.minEndDate, requestParameters.shortName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a forum by id
         * @param {ForumsApiForumsIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsIdDelete(requestParameters: ForumsApiForumsIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsIdDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Forum
         * @param {ForumsApiForumsIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsIdPut(requestParameters: ForumsApiForumsIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumResponseModel> {
            return localVarFp.forumsIdPut(requestParameters.id, requestParameters.forumRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Tests what objects will be changed/deleted after Forum update
         * @param {ForumsApiForumsIdTestPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsIdTestPut(requestParameters: ForumsApiForumsIdTestPutRequest, options?: AxiosRequestConfig): AxiosPromise<UpdateForumTestResponseModel> {
            return localVarFp.forumsIdTestPut(requestParameters.id, requestParameters.updateForumTestRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload forum logo
         * @param {ForumsApiForumsLogoPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsLogoPost(requestParameters: ForumsApiForumsLogoPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsLogoPost(requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Title should be unique
         * @summary Creates a Forum
         * @param {ForumsApiForumsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsPost(requestParameters: ForumsApiForumsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ForumAddedResult> {
            return localVarFp.forumsPost(requestParameters.forumRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get roles available for forum groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsRolesGet(options?: AxiosRequestConfig): AxiosPromise<Array<CustomRole>> {
            return localVarFp.forumsRolesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of years with forums sorted desc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsYearsGet(options?: AxiosRequestConfig): AxiosPromise<Array<number>> {
            return localVarFp.forumsYearsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsExistingForumIdPost operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsExistingForumIdPostRequest
 */
export interface ForumsApiForumsExistingForumIdPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsExistingForumIdPost
     */
    readonly existingForumId: number

    /**
     * 
     * @type {ForumRequestModel}
     * @memberof ForumsApiForumsExistingForumIdPost
     */
    readonly forumRequestModel?: ForumRequestModel
}

/**
 * Request parameters for forumsForumCodeGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumCodeGetRequest
 */
export interface ForumsApiForumsForumCodeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ForumsApiForumsForumCodeGet
     */
    readonly forumCode: string
}

/**
 * Request parameters for forumsForumIdContactGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdContactGetRequest
 */
export interface ForumsApiForumsForumIdContactGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdContactGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdContactPut operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdContactPutRequest
 */
export interface ForumsApiForumsForumIdContactPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdContactPut
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumContactInfoModel}
     * @memberof ForumsApiForumsForumIdContactPut
     */
    readonly forumContactInfoModel?: ForumContactInfoModel
}

/**
 * Request parameters for forumsForumIdGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdGetRequest
 */
export interface ForumsApiForumsForumIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdLogoAccessUrlGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdLogoAccessUrlGetRequest
 */
export interface ForumsApiForumsForumIdLogoAccessUrlGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdLogoAccessUrlGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdSisterForumsGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdSisterForumsGetRequest
 */
export interface ForumsApiForumsForumIdSisterForumsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdSisterForumsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdSocialLinksGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdSocialLinksGetRequest
 */
export interface ForumsApiForumsForumIdSocialLinksGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdSocialLinksGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdSocialLinksPut operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsForumIdSocialLinksPutRequest
 */
export interface ForumsApiForumsForumIdSocialLinksPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsForumIdSocialLinksPut
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumSocialLinksModel}
     * @memberof ForumsApiForumsForumIdSocialLinksPut
     */
    readonly forumSocialLinksModel?: ForumSocialLinksModel
}

/**
 * Request parameters for forumsGet operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsGetRequest
 */
export interface ForumsApiForumsGetRequest {
    /**
     * If year is specified - only forums of specifeid years are returned  Otherwise forums of all years are displayed
     * @type {number}
     * @memberof ForumsApiForumsGet
     */
    readonly year?: number

    /**
     * Forum.Code contains passed \&#39;Code\&#39; string
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly code?: string

    /**
     * Forum.Title contains passed \&#39;Title\&#39; string
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly title?: string

    /**
     * Pass fav&#x3D;true to get favorite forums only
     * @type {boolean}
     * @memberof ForumsApiForumsGet
     */
    readonly fav?: boolean

    /**
     * 
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly countryCode?: string

    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsGet
     */
    readonly venueId?: number

    /**
     * 
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly maxStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly minEndDate?: string

    /**
     * Forum.ShortName contains passed \&#39;ShortName\&#39; string
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly shortName?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumsApiForumsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumsApiForumsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumsApiForumsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsIdDelete operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsIdDeleteRequest
 */
export interface ForumsApiForumsIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsIdDelete
     */
    readonly id: number
}

/**
 * Request parameters for forumsIdPut operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsIdPutRequest
 */
export interface ForumsApiForumsIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsIdPut
     */
    readonly id: number

    /**
     * 
     * @type {ForumRequestModel}
     * @memberof ForumsApiForumsIdPut
     */
    readonly forumRequestModel?: ForumRequestModel
}

/**
 * Request parameters for forumsIdTestPut operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsIdTestPutRequest
 */
export interface ForumsApiForumsIdTestPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumsApiForumsIdTestPut
     */
    readonly id: number

    /**
     * 
     * @type {UpdateForumTestRequestModel}
     * @memberof ForumsApiForumsIdTestPut
     */
    readonly updateForumTestRequestModel?: UpdateForumTestRequestModel
}

/**
 * Request parameters for forumsLogoPost operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsLogoPostRequest
 */
export interface ForumsApiForumsLogoPostRequest {
    /**
     * 
     * @type {File}
     * @memberof ForumsApiForumsLogoPost
     */
    readonly formFile?: File
}

/**
 * Request parameters for forumsPost operation in ForumsApi.
 * @export
 * @interface ForumsApiForumsPostRequest
 */
export interface ForumsApiForumsPostRequest {
    /**
     * 
     * @type {ForumRequestModel}
     * @memberof ForumsApiForumsPost
     */
    readonly forumRequestModel?: ForumRequestModel
}

/**
 * ForumsApi - object-oriented interface
 * @export
 * @class ForumsApi
 * @extends {BaseAPI}
 */
export class ForumsApi extends BaseAPI {
    /**
     * 
     * @summary Create a forum based on a previous occurence, copying questionnaires, selection sites, groups and contacts
     * @param {ForumsApiForumsExistingForumIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsExistingForumIdPost(requestParameters: ForumsApiForumsExistingForumIdPostRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsExistingForumIdPost(requestParameters.existingForumId, requestParameters.forumRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumsApiForumsForumCodeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumCodeGet(requestParameters: ForumsApiForumsForumCodeGetRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumCodeGet(requestParameters.forumCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets forum contact details by id
     * @param {ForumsApiForumsForumIdContactGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdContactGet(requestParameters: ForumsApiForumsForumIdContactGetRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdContactGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Forum
     * @param {ForumsApiForumsForumIdContactPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdContactPut(requestParameters: ForumsApiForumsForumIdContactPutRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdContactPut(requestParameters.forumId, requestParameters.forumContactInfoModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets forum by id
     * @param {ForumsApiForumsForumIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdGet(requestParameters: ForumsApiForumsForumIdGetRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets an URL with Read/Write access (available for 1 hour) to the specified forum\'s logo in Azure Storage
     * @param {ForumsApiForumsForumIdLogoAccessUrlGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdLogoAccessUrlGet(requestParameters: ForumsApiForumsForumIdLogoAccessUrlGetRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdLogoAccessUrlGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all sister-forum names by forumId
     * @param {ForumsApiForumsForumIdSisterForumsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdSisterForumsGet(requestParameters: ForumsApiForumsForumIdSisterForumsGetRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdSisterForumsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets forum social links by forumId
     * @param {ForumsApiForumsForumIdSocialLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdSocialLinksGet(requestParameters: ForumsApiForumsForumIdSocialLinksGetRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdSocialLinksGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates forum social links by forumId
     * @param {ForumsApiForumsForumIdSocialLinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsForumIdSocialLinksPut(requestParameters: ForumsApiForumsForumIdSocialLinksPutRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsForumIdSocialLinksPut(requestParameters.forumId, requestParameters.forumSocialLinksModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets forums by search filter
     * @param {ForumsApiForumsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsGet(requestParameters: ForumsApiForumsGetRequest = {}, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsGet(requestParameters.year, requestParameters.code, requestParameters.title, requestParameters.fav, requestParameters.countryCode, requestParameters.venueId, requestParameters.maxStartDate, requestParameters.minEndDate, requestParameters.shortName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a forum by id
     * @param {ForumsApiForumsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsIdDelete(requestParameters: ForumsApiForumsIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsIdDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Forum
     * @param {ForumsApiForumsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsIdPut(requestParameters: ForumsApiForumsIdPutRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsIdPut(requestParameters.id, requestParameters.forumRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Tests what objects will be changed/deleted after Forum update
     * @param {ForumsApiForumsIdTestPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsIdTestPut(requestParameters: ForumsApiForumsIdTestPutRequest, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsIdTestPut(requestParameters.id, requestParameters.updateForumTestRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload forum logo
     * @param {ForumsApiForumsLogoPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsLogoPost(requestParameters: ForumsApiForumsLogoPostRequest = {}, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsLogoPost(requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Title should be unique
     * @summary Creates a Forum
     * @param {ForumsApiForumsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsPost(requestParameters: ForumsApiForumsPostRequest = {}, options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsPost(requestParameters.forumRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get roles available for forum groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsRolesGet(options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsRolesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of years with forums sorted desc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumsApi
     */
    public forumsYearsGet(options?: AxiosRequestConfig) {
        return ForumsApiFp(this.configuration).forumsYearsGet(options).then((request) => request(this.axios, this.basePath));
    }
}

