import { useGetForumGroupsQuery } from "api/queries/groups";
import SelectionSiteStatus from "backend-models/selectionSiteStatus";
import { Button, DatePicker, Form, Input, Select } from "components/styleguide";
import { toNumber } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useOutletContext } from "react-router-dom";
import * as yup from "yup";

import { useGetForumByIdQuery } from "../../../api/queries/forums";
import FullHeightForm from "../../../components/FullHeightForm";
const { Option } = Select;
const { RangePicker } = DatePicker;

const SelectionSiteForm = ({
  onSubmit,
  selectionSite,
  errorMsg,
  onDrawerClose,
  onEditClose,
  isMutationLoading,
  isEdit,
}) => {
  const { id: forumId } = useOutletContext();
  const dateValidation = yup.array().test("isSameDate", (value) => !value[0].isSame(value[1]));

  const { data: { data: forum = {} } = {} } = useGetForumByIdQuery({ forumId: toNumber(forumId) });

  const { isLoading: isGroupsLoading, data: { data: groups = [] } = {} } = useGetForumGroupsQuery({ forumId });

  return (
    <FullHeightForm
      id="newSelectionSiteForm"
      name="new-selection-site"
      labelCol={{ justify: "left", span: 6 }}
      wrapperCol={{ justify: "right" }}
      labelWrap
      key={JSON.stringify(selectionSite)}
      destroyOnClose
      actionsPrepend={<Button onClick={isEdit ? onEditClose : onDrawerClose}>Cancel</Button>}
      actionsAppend={
        <Button type="primary" htmlType="submit" loading={isMutationLoading}>
          {isEdit ? "Save" : "Create"}
        </Button>
      }
      onFinish={onSubmit}
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={selectionSite?.name}
        rules={[
          {
            required: true,
            message: "Name is required!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Description" name="description" initialValue={selectionSite?.description}>
        <Input />
      </Form.Item>
      <Form.Item
        label="Available"
        name="available"
        initialValue={[
          selectionSite?.availableFrom
            ? moment(selectionSite?.availableFrom)
            : moment(forum.startDate).subtract(1, "days"),
          selectionSite?.availableTo ? moment(selectionSite?.availableTo) : moment(forum.startDate).subtract(1, "days"),
        ]}
        rules={[
          {
            required: true,
            message: "Availability is required!",
          },
          {
            async validator(_, value) {
              await dateValidation.validateSync(value);
            },
            message: "End date should be greater then start date",
          },
        ]}
      >
        <RangePicker
          placeholder={["From", "To"]}
          disabledDate={(current) => {
            return moment(forum.startDate) <= current;
          }}
        />
      </Form.Item>
      <Form.Item
        label="Group"
        name="groupId"
        initialValue={selectionSite?.groupId}
        rules={[
          {
            required: true,
            message: "Group is required!",
          },
        ]}
      >
        <Select
          loading={isGroupsLoading}
          showSearch
          optionFilterProp={"label"}
          options={groups.map(({ id, name }) => ({ value: id, label: name }))}
        />
      </Form.Item>
      <Form.Item label="Color scheme" name="colorScheme" initialValue={selectionSite?.colorScheme || "Default"}>
        <Select>
          <Option value="default" key="default">
            Default
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Status"
        name="status"
        initialValue={selectionSite?.status || SelectionSiteStatus.VALUES[1]}
        rules={[
          {
            required: true,
            message: "Status is required!",
          },
        ]}
      >
        <Select>
          {SelectionSiteStatus.LIST.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {errorMsg && <p style={{ color: "red", float: "right" }}>{errorMsg}</p>}
    </FullHeightForm>
  );
};

SelectionSiteForm.propTypes = {
  onSubmit: PropTypes.func,
  selectionSite: PropTypes.object,
  groups: PropTypes.array,
  errorMsg: PropTypes.string,
  onDrawerClose: PropTypes.func,
  onEditClose: PropTypes.func,
  isMutationLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
};

export default SelectionSiteForm;
