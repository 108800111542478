import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useGetItineraryBookletTeamMembers = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_TEAM_MEMBERS,
  apiClient.ItineraryBookletsForumTeamMembers.get,
);

export const useGetItineraryBookletUnassignedTeamMembers = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_UNASSIGNED_TEAM_MEMBERS,
  apiClient.ItineraryBookletsForumTeamMembers.getUnassigned,
);
