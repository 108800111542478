import { Rule } from "antd/lib/form";
import { useUpdateAttendeeSocialLinksMutation } from "api/mutations/attendees";
import { useAttendeeSocialLinksQuery } from "api/queries/attendees";
import { Button, Col, Divider, Form, Input, Row, Spin } from "components/styleguide";
import { AttendeeSocialLinksModel, AttendeeSocialLinksRequestModel } from "generated/api";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { urlToAnchor } from "utils/anchorUtils";
import * as yup from "yup";

import { getFormFields } from "./helpers";

import styles from "./styles.module.less";

export default function AttendeeSocialLinks({
  isEditing,
  setIsEditing,
}: {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}) {
  const [form] = Form.useForm();
  const { id: forumId, attendeeId } = useParams();
  const { t } = useTranslation();
  const { ref, inView } = useInView();

  const [socialLinks, setSocialLinks] = useState<AttendeeSocialLinksModel>();

  const query = useAttendeeSocialLinksQuery(
    { forumId: toNumber(forumId), attendeeId: toNumber(attendeeId) },
    { enabled: inView, onSuccess: ({ data }) => setSocialLinks(data) },
  );

  const mutation = useUpdateAttendeeSocialLinksMutation();

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };
  const urlYupTest = () =>
    yup
      .string()
      .nullable()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      .test((value) => {
        if (!value) {
          return true;
        }
        try {
          return yup.string().nullable().url(t("errors.Must be a valid web address")).validateSync(value);
        } catch (_) {
          return yup.string().nullable().url(t("errors.Must be a valid web address")).validateSync(`https://${value}`);
        }
      });

  const getSchema = () => {
    return yup.object().shape({
      facebook: urlYupTest(),
      twitter: urlYupTest(),
      linkedIn: urlYupTest(),
    });
  };

  const validation = Form.useYupValidator(form, getSchema);

  const submit = (values: AttendeeSocialLinksRequestModel) => {
    mutation.mutate(
      { forumId: toNumber(forumId), attendeeId: toNumber(attendeeId), attendeeSocialLinksRequestModel: values },
      {
        onSuccess: () => {
          setSocialLinks({ ...socialLinks, ...values });
          setIsEditing(false);
        },
      },
    );
  };

  if (query.isLoading || !socialLinks) {
    return (
      <div className="loader" ref={ref}>
        <Spin />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <Form
        id="contactInfoForm"
        name="contact-info"
        form={form}
        labelAlign="left"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 11 }}
        labelWrap
        colon={false}
        onFinish={submit}
      >
        <Row gutter={80}>
          {getFormFields(t).map((f) => (
            <Col key={f.dataIndex} md={24} lg={12} className={styles.formItemCol}>
              <Form.Item
                initialValue={socialLinks[f.dataIndex as keyof AttendeeSocialLinksModel]}
                label={f.title}
                name={f.dataIndex}
                className={styles.formItem}
                rules={validation.rules as unknown as Rule[]}
              >
                {isEditing ? (
                  <Input maxLength={100} />
                ) : socialLinks[f.dataIndex as keyof AttendeeSocialLinksModel] ? (
                  (urlToAnchor(socialLinks[f.dataIndex as keyof AttendeeSocialLinksModel] as string) as never)
                ) : (
                  <label>{"-"}</label>
                )}
              </Form.Item>
              <Divider />
            </Col>
          ))}
        </Row>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={mutation.isLoading} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
}
