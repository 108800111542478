import { BaseOptionType } from "antd/lib/select";
import { ColumnType } from "antd/lib/table";
import { useGetFeedbackByParticipationTypeQuery } from "api/queries/attendees";
import { Button, Col, Row, Table } from "components/styleguide";
import {
  AttendeeFeedBackStatus,
  AttendeeInfoByPatricipationTypeModel,
  AttendeesByParticipationTypeModel,
  CompanyInfoByPatricipationTypeModel,
  ForumFeedBackGroupType,
  PageResponseAttendeesByParticipationTypeModel,
  ParticipationCodeType,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, getColumnSelectFilterProps } from "utils/tableColumnUtils";

import ExportFeedback from "../ExportFeedback";
import FeedbackDrawer from "../FeedbackDrawer";

import styles from "../styles.module.less";

export default function DelegateFeedback() {
  const { t } = useTranslation();
  const { id: forumId } = useParams();

  const [attendeeSearch, setAttendeeSearch] = useState<string | undefined>(undefined);
  const [companySearch, setCompanySearch] = useState<string | undefined>(undefined);
  const [feedbackStatusSearch, setFeedbackStatusSearch] = useState<string[] | undefined>(undefined);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedAttendeeId, setSelectedAttendeeId] = useState<number | undefined>(undefined);

  const showDrawer = (id: number | undefined) => {
    setSelectedAttendeeId(id);
    if (id) {
      setIsDrawerVisible(true);
    }
  };
  const hideDrawer = () => setIsDrawerVisible(false);

  const [delegatesfeedback, setDelegatesFeedback] = useState<AttendeesByParticipationTypeModel[] | undefined>(
    undefined,
  );

  let rowKey = 0;

  const [feedbackStatusSearchOptions] = useState([
    { label: t("Completed"), value: AttendeeFeedBackStatus.Completed },
    { label: t("In Progress"), value: AttendeeFeedBackStatus.InProgress },
    { label: t("Not Started"), value: AttendeeFeedBackStatus.NotStarted },
  ]);

  const {
    response: delegatesQuery,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetFeedbackByParticipationTypeQuery,
    {
      onSuccess: ({ data }: { data: PageResponseAttendeesByParticipationTypeModel }) => {
        setDelegatesFeedback(data.items);
      },
    },
    {
      forumId,
      attendeeParticipationType: ParticipationCodeType.Delegate,
      fullName: attendeeSearch,
      companyName: companySearch,
      status: feedbackStatusSearch,
    },
  );

  const handleSearch = (
    selectedKeys: string[] | BaseOptionType[],
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case "attendee": {
        setAttendeeSearch(selectedKeys[0] as string);
        break;
      }
      case "company": {
        setCompanySearch(selectedKeys[0] as string);
        break;
      }
      case "status": {
        const selected = selectedKeys as BaseOptionType[];
        setFeedbackStatusSearch(selected.map((k) => k.value as string));
        break;
      }
    }

    confirm?.();
  };
  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "attendee": {
        setAttendeeSearch(undefined);
        break;
      }
      case "company": {
        setCompanySearch(undefined);
        break;
      }
      case "status": {
        setFeedbackStatusSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns: ColumnType<AttendeesByParticipationTypeModel>[] = [
    {
      title: attendeeSearch ?? t("Attendee"),
      dataIndex: "attendeeInfo",
      render: (attendeeInfo: AttendeeInfoByPatricipationTypeModel) => {
        return (
          <div>
            {attendeeInfo.firstName} {attendeeInfo.lastName}
            {(attendeeInfo.firstName || attendeeInfo.lastName) && attendeeInfo.jobTitle ? ", " : ""}
            {attendeeInfo.jobTitle}
          </div>
        );
      },
      ...(getColumnSearchProps(
        "attendee",
        handleSearch,
        handleReset,
        "attendee",
      ) as Partial<AttendeesByParticipationTypeModel>),
    },
    {
      title: companySearch ?? t("Company"),
      dataIndex: "companyInfo",
      sorter: true,
      render: (company: CompanyInfoByPatricipationTypeModel) => {
        return (
          <div>
            {company.name}
            {company.name && company.city ? ", " : ""}
            {company.city}
          </div>
        );
      },
      ...(getColumnSearchProps(
        "company",
        handleSearch,
        handleReset,
        "company",
      ) as Partial<AttendeesByParticipationTypeModel>),
    },
    {
      title: t("Feedback Received"),
      dataIndex: "feedbackReceived",
    },
    {
      title: t("Feedback Status"),
      dataIndex: "status",
      render: (status: AttendeeFeedBackStatus) => {
        let statusString = "";
        if (status === AttendeeFeedBackStatus.Completed) {
          statusString = t("Completed");
        }
        if (status === AttendeeFeedBackStatus.InProgress) {
          statusString = t("In Progress");
        }
        if (status === AttendeeFeedBackStatus.NotStarted) {
          statusString = t("Not Started");
        }
        return (
          <div>
            <span>{statusString}</span>
          </div>
        );
      },
      ...(getColumnSelectFilterProps(
        "status",
        handleSearch,
        handleReset,
        "status",
        feedbackStatusSearchOptions,
      ) as Partial<AttendeesByParticipationTypeModel>),
    },
    {
      dataIndex: "attendeeInfo",
      render: (_: unknown, record: AttendeesByParticipationTypeModel) => (
        <Button onClick={() => showDrawer(record.attendeeInfo?.id)}>{t("View Feedback")}</Button>
      ),
    },
  ];

  const getRowKey = () => {
    rowKey++;
    return `${rowKey}`;
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <ExportFeedback group={ForumFeedBackGroupType.Delegates} />
        </Col>
      </Row>
      <Row className={styles.tableContainerRow}>
        <Col className={styles.tableContainerCol}>
          <Table
            bordered={true}
            columns={columns}
            rowKey={getRowKey}
            dataSource={delegatesfeedback}
            loading={delegatesQuery.isLoading}
            pagination={pagination}
            onChange={handleSort}
          />
        </Col>
      </Row>
      <FeedbackDrawer visible={isDrawerVisible} onClose={hideDrawer} attendeeId={selectedAttendeeId} />
    </>
  );
}
