import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import KEYS from "api/queries/keys";
import { Button, Col, Modal, Row, Typography } from "components/styleguide";
import Table from "components/styleguide/components/Table";
import {
  AttendeeItineraryItemResponseModel,
  AttendeeItineraryRemovedItemResponseModel,
  AvailableTableRequestModel,
  TimeSlotType,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const { confirm, error } = Modal;
import { useUpdateAttendeeRemovedItineraryMutation } from "api/mutations/forumAttendeeItinerary";
import {
  useAttendeeRemovedItinerariesDocumentQuery,
  useAttendeeRemovedItinerariesQuery,
} from "api/queries/forumAttendeeItinerary";
import ItineraryRestoreErrorTypes from "error-handling/itineraryRestoreErrorTypes";
import { toNumber } from "lodash";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";
import { TableColumn } from "utils/tableColumnUtils";

import { getRemovalReasonDisplayText } from "../helpers";

import styles from "../styles.module.less";

const timeFormat = "HH:mm";

interface AttendeeItineraryProps {
  forumId: number;
  attendeeId: number;
}

export default function RemovedBookingsTab({ forumId, attendeeId }: AttendeeItineraryProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedItineraryIds, setSelectedItineraryIds] = useState<number[]>([]);

  const rowSelection = {
    onChange: (selectedRowKeys: Array<React.Key>) => {
      setSelectedItineraryIds(selectedRowKeys.map((key) => toNumber(key)));
    },
    getCheckboxProps: (record: AttendeeItineraryRemovedItemResponseModel) => ({
      disabled: record.timeSlotType === TimeSlotType.Conference,
    }),
  };

  const {
    response: { isLoading, data: { data: { items: removedItineraries = [] } = {} } = {} },
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeeRemovedItinerariesQuery,
    {},
    {
      forumId,
      attendeeId,
    },
  );

  const { isFetching: isDocumentDownloading, refetch: downloadDocument } = useAttendeeRemovedItinerariesDocumentQuery(
    {
      forumId,
      attendeeId,
      removedItineraryIds: selectedItineraryIds,
    },
    {
      enabled: false,
      retry: false,
      onSuccess: ({ data: document }) => downloadFileByURL(document),
      onError: () => toast.error(t("Something went wrong")),
    },
  );

  const restoreItinerary = useUpdateAttendeeRemovedItineraryMutation();

  const handleRestoreItinerary = useCallback(
    async (
      removedItem: AttendeeItineraryRemovedItemResponseModel,
      availableTableRequestModel?: AvailableTableRequestModel,
    ) => {
      const isSession = !!removedItem.seminarSessionId;
      const isJointMeeting = !!removedItem.jointAttendeeId;

      await restoreItinerary.mutateAsync(
        {
          forumId,
          attendeeId,
          removedItemId: removedItem.id as number,
          availableTableRequestModel,
        },
        {
          onSuccess: async () => {
            if (isSession) {
              return toast.success(t("The session was restored"));
            } else if (isJointMeeting) {
              if (availableTableRequestModel?.attendeeId) {
                return toast.success(t("The meeting was restored"));
              }

              return toast.success(t("The joint meeting was restored"));
            }

            toast.success(t("The meeting was restored"));
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: (ex: any) => {
            const errorType = ex.response.data.type;

            switch (errorType) {
              case ItineraryRestoreErrorTypes.InsufficientCapacity: {
                const objectType = ex.response.data.meta.ObjectType;
                const facilityName = ex.response?.data?.meta?.ObjectFields?.FacilityName;

                if ((objectType == "JointMeetingFacility" || objectType == "MeetingFacility") && facilityName) {
                  const errorConfirm = confirm({
                    title: t("Facility Unavailable"),
                    content: t(
                      "There are no available places in this facility. The meeting can be moved to a new facility {{facilityInfo}}. Do you want to proceed?",
                      {
                        facilityInfo: `${ex.response.data.meta.ObjectFields.FacilityName}, ${ex.response.data.meta.ObjectFields.TableNumber}, ${ex.response.data.meta.ObjectFields.NumberOfSeats}`,
                      },
                    ),
                    icon: <ExclamationCircleOutlined />,
                    okText: t("Proceed"),
                    cancelText: t("Cancel"),
                    okType: "primary",
                    zIndex: 2001,
                    maskStyle: { zIndex: 2000 },
                    onOk: async () => {
                      const response = {
                        facilityId: ex.response.data.meta.ObjectFields.FacilityId as number,
                        tableId: ex.response.data.meta.ObjectFields.TableId as number,
                      };

                      await handleRestoreItinerary(removedItem, response).finally(() => {
                        errorConfirm.destroy();
                      });
                      await queryClient.resetQueries([KEYS.GET_ATTENDEE_ITINERARY]);
                      await queryClient.resetQueries([KEYS.GET_ATTENDEE_REMOVED_ITINERARIES]);
                    },
                  });

                  return errorConfirm;
                } else {
                  return error({
                    title: t("No Places"),
                    content: t("There are no available places in this facility"),
                    icon: <ExclamationCircleOutlined />,
                    okText: t("Ok"),
                    zIndex: 2001,
                    maskStyle: { zIndex: 2000 },
                  });
                }
              }
              case ItineraryRestoreErrorTypes.JointMeetingNoAttendeeAvailable: {
                const attendeeJoinWithName = `${ex.response.data.meta.ObjectFields["attendeeJointWith FirstName"]} ${ex.response.data.meta.ObjectFields["attendeeJointWith Surname"]}`;
                const attendeeMeetWithName = `${ex.response.data.meta.ObjectFields["attendeeMeetWith FirstName"]} ${ex.response.data.meta.ObjectFields["attendeeMeetWith Surname"]}`;

                return error({
                  title: t("No Attendees Available"),
                  content: t(
                    "The meeting cannot be restored, {{attendeeNames}} are not available for the selected time",
                    {
                      attendeeNames: `${attendeeJoinWithName}, ${attendeeMeetWithName}`,
                    },
                  ),
                  icon: <ExclamationCircleOutlined />,
                  okText: t("Ok"),
                  zIndex: 2001,
                  maskStyle: { zIndex: 2000 },
                });
              }
              case ItineraryRestoreErrorTypes.SessionAttendeeNotAvailable: {
                const attendeeName = `${ex.response.data.meta.ObjectFields.AttendeeFirstName} ${ex.response.data.meta.ObjectFields.AttendeeSurname}`;

                return error({
                  title: t("Attendee Unavailable"),
                  content: t(
                    "The session cannot be restored, {{attendeeName}} is not available for the selected time",
                    {
                      attendeeName,
                    },
                  ),
                  icon: <ExclamationCircleOutlined />,
                  okText: t("Ok"),
                  zIndex: 2001,
                  maskStyle: { zIndex: 2000 },
                });
              }
              case ItineraryRestoreErrorTypes.MeetingAttendeeUnavailable: {
                const attendeeName = `${ex.response.data.meta.ObjectFields.AttendeeFirstName} ${ex.response.data.meta.ObjectFields.AttendeeSurname}`;

                return error({
                  title: t("Attendee Unavailable"),
                  content: t(
                    "The meeting cannot be restored, {{attendeeName}} is not available for the selected time",
                    {
                      attendeeName,
                    },
                  ),
                  icon: <ExclamationCircleOutlined />,
                  okText: t("Ok"),
                  zIndex: 2001,
                  maskStyle: { zIndex: 2000 },
                });
              }
              case ItineraryRestoreErrorTypes.SessionNoSpacesAvailable: {
                return error({
                  title: t("No Places"),
                  content: t("There are no available places in this facility"),
                  icon: <ExclamationCircleOutlined />,
                  okText: t("Ok"),
                  zIndex: 2001,
                  maskStyle: { zIndex: 2000 },
                });
              }
              case ItineraryRestoreErrorTypes.MeetingFacilityUnavailable:
              case ItineraryRestoreErrorTypes.JointMeetingFacilityUnavailable: {
                const errorConfirm = confirm({
                  title: t("Facility Unavailable"),
                  content: t(
                    "There are no available places in this facility. The meeting can be moved to a new facility {{facilityInfo}}. Do you want to proceed?",
                    {
                      facilityInfo: `${ex.response.data.meta.ObjectFields.FacilityName}, ${ex.response.data.meta.ObjectFields.TableNumber}, ${ex.response.data.meta.ObjectFields.NumberOfSeats}`,
                    },
                  ),
                  icon: <ExclamationCircleOutlined />,
                  okText: t("Proceed"),
                  cancelText: t("Cancel"),
                  okType: "primary",
                  zIndex: 2001,
                  maskStyle: { zIndex: 2000 },
                  onOk: async () => {
                    const response = {
                      facilityId: ex.response.data.meta.ObjectFields.FacilityId as number,
                      tableId: ex.response.data.meta.ObjectFields.TableId as number,
                    };

                    await handleRestoreItinerary(removedItem, response).finally(() => {
                      errorConfirm.destroy();
                    });
                    await queryClient.resetQueries([KEYS.GET_ATTENDEE_ITINERARY]);
                    await queryClient.resetQueries([KEYS.GET_ATTENDEE_REMOVED_ITINERARIES]);
                  },
                });

                return errorConfirm;
              }
              case ItineraryRestoreErrorTypes.JointMeetingAttendeeNotAvailable: {
                const attendeeName = `${ex.response.data.meta.ObjectFields.AttendeeFirstName} ${ex.response.data.meta.ObjectFields.AttendeeSurname}`;

                const availableAttendeeId = ex.response.data.meta.ObjectFields.Id as number;

                const errorConfirm = confirm({
                  title: t("Participant Unavailable"),
                  content: t("Only {{attendeeName}} is  available. Do you want to restore this meeting?", {
                    attendeeName: attendeeName,
                  }),
                  icon: <ExclamationCircleOutlined />,
                  okText: t("Proceed"),
                  cancelText: t("Cancel"),
                  okType: "primary",
                  zIndex: 2001,
                  maskStyle: { zIndex: 2000 },
                  onOk: async () => {
                    const response = {
                      facilityId: ex.response.data.meta.ObjectFields.FacilityId as number,
                      tableId: ex.response.data.meta.ObjectFields.TableId as number,
                      attendeeId: availableAttendeeId,
                    };

                    await handleRestoreItinerary(removedItem, response).finally(() => {
                      errorConfirm.destroy();
                    });
                    await queryClient.resetQueries([KEYS.GET_ATTENDEE_ITINERARY]);
                    await queryClient.resetQueries([KEYS.GET_ATTENDEE_REMOVED_ITINERARIES]);
                  },
                });

                return errorConfirm;
              }

              default:
                return toast.error("Something went wrong");
            }
          },
        },
      );
    },
    [attendeeId, restoreItinerary, forumId, queryClient],
  );

  const onRestoreClick = useCallback(
    (record: AttendeeItineraryRemovedItemResponseModel) => {
      const confirmation = confirm({
        title: t("confirmation"),
        content: t("Do you want to restore this booking?"),
        icon: <ExclamationCircleOutlined />,
        okText: t("yes"),
        okType: "primary",
        cancelText: t("no"),
        zIndex: 2001,
        maskStyle: { zIndex: 2000 },
        onOk: async () => {
          await handleRestoreItinerary(record).finally(() => {
            confirmation.destroy();
          });
          await queryClient.refetchQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          await queryClient.refetchQueries([KEYS.GET_ATTENDEE_REMOVED_ITINERARIES]);
        },
      });
    },
    [t, handleRestoreItinerary],
  );

  const columns = useMemo(
    () => [
      new TableColumn(t("Date"), "date", {
        render: (date: string) => date && moment(date).format("D MMMM YYYY"),
      }),
      new TableColumn(t("Timeslot"), "timeSlotName", {
        render: (_: string, { timeSlotName, startTime, endTime }: AttendeeItineraryItemResponseModel) => (
          <>
            <Typography.Title className={styles.title} level={5}>
              {timeSlotName || "-"}
            </Typography.Title>
            <Typography.Text>
              {moment(startTime).format(timeFormat)} - {moment(endTime).format(timeFormat)}
            </Typography.Text>
          </>
        ),
      }),
      new TableColumn(t("Description"), "description"),
      new TableColumn(t("Removal Reason"), "removalReason", {
        render: (reason: string) => {
          return <span>{getRemovalReasonDisplayText(t, reason)}</span>;
        },
      }),
      new TableColumn(t("Removal Date"), "removedDate", {
        render: (date: string) => date && moment(date).format("D MMMM YYYY"),
      }),
      new TableColumn("", "id", {
        render: (_: number, record: AttendeeItineraryItemResponseModel) => (
          <Button onClick={() => onRestoreClick(record)}>{t("Restore")}</Button>
        ),
      }),
    ],
    [t, onRestoreClick],
  );

  return (
    <Row justify="end" gutter={[0, 20]}>
      <Col span={24}>
        <Table
          id="removed-bookings-table"
          dataSource={removedItineraries}
          columns={columns}
          pagination={pagination}
          bordered={true}
          loading={isLoading}
          rowSelection={rowSelection}
          rowKey="id"
        />
      </Col>
      <Col>
        <Button
          type="primary"
          loading={isDocumentDownloading}
          disabled={!selectedItineraryIds.length || isDocumentDownloading}
          onClick={() => downloadDocument()}
        >
          {t("Print selected")}
        </Button>
      </Col>
    </Row>
  );
}
