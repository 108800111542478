import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Spin } from "../../../../../../../../components/styleguide";
import Select from "../Select";

const AsyncSelect = ({ query, optionsMapper, ...props }) => {
  const { loading, data } = query;

  // It is expected to get Array<{ value: string, label: string }> from the `optionsMapper`.
  const options = useMemo(() => {
    const response = data?.data?.items ?? data?.data;
    if (!optionsMapper || !response) {
      return response ?? [];
    }

    return optionsMapper(response);
  }, [data, optionsMapper]);

  if (loading) {
    return <Spin />;
  }

  return (
    <Select {...props}>
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

AsyncSelect.propTypes = {
  query: PropTypes.object.isRequired,
  optionsMapper: PropTypes.func,
};

AsyncSelect.defaultProps = {
  optionsMapper: undefined,
};

export default AsyncSelect;
