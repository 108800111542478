import apiClient from "api-client";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useForumAttendeeSessionMeetingLocationsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS,
  apiClient.ForumAttendeeSessionMeetingLocation.get,
);

export const useForumAttendeesSessionMeetingLocationsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS,
  apiClient.ForumAttendeeSessionMeetingLocation.getAll,
);
