import { TableProps } from "antd";
import { Table } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import FacilityType from "enums/FacilityType";
import { ForumCompanyPrimaryLocationModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ForumCompanyPrimaryLocationsTableProps {
  forumCompanyPrimaryLocations: Array<ForumCompanyPrimaryLocationModel>;
  onClickEdit: (locationId: ForumCompanyPrimaryLocationModel) => void;
  onClickDelete: (locationId: ForumCompanyPrimaryLocationModel) => void;
  isLoading: boolean;
}

const ForumCompanyPrimaryLocationsTable: FC<ForumCompanyPrimaryLocationsTableProps> = ({
  forumCompanyPrimaryLocations,
  onClickEdit,
  onClickDelete,
  isLoading,
}) => {
  const { t } = useTranslation();

  const primaryLocationsColumns: TableProps<ForumCompanyPrimaryLocationModel>["columns"] = [
    {
      title: t("Location"),
      dataIndex: "forumFacilityName",
      key: "forumFacilityName",
      sorter: false,
    },
    {
      title: t("facilityType"),
      dataIndex: "forumFacilityType",
      key: "forumFacilityType",
      render: (forumFacilityType: ForumCompanyPrimaryLocationModel["forumFacilityType"]) =>
        FacilityType.toDisplayName(FacilityType.fromApiFacilityType(forumFacilityType)),
      sorter: false,
    },
    {
      title: t("Default Table"),
      dataIndex: "forumTableNumber",
      key: "forumTableNumber",
      sorter: false,
    },
    {
      title: t("Sitting"),
      dataIndex: "sitting",
      key: "sitting",
      sorter: false,
    },
    {
      render: (primaryLocationId: number, primaryLocation: ForumCompanyPrimaryLocationModel) => (
        <TableRowActionButtons
          onEdit={() => onClickEdit(primaryLocation)}
          onDelete={() => onClickDelete(primaryLocation)}
        />
      ),
    },
  ];

  return (
    <Table
      dataSource={forumCompanyPrimaryLocations}
      columns={primaryLocationsColumns}
      rowKey="id"
      loading={isLoading}
    />
  );
};

export default ForumCompanyPrimaryLocationsTable;
