import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import { Button, Card, Space, Tree, Typography } from "../../../../../components/styleguide";
import {
  collectQuestionsByIndexes,
  generatePageId,
  generateQuestionId,
  getQuestionIndexFromId,
  isQuestionId,
  removeQuestionsIds,
} from "../../../../../utils/questionnaireContentUtils";

const ALL_CHECKBOX_KEY = "all";

const QuestionsSelect = ({ title, pages, onFinish }) => {
  const [selected, setSelected] = useState([]);
  const [isLoadingImport, setIsLoadingImport] = useState(false);

  const tree = useMemo(() => {
    return [
      {
        title,
        key: ALL_CHECKBOX_KEY,
        children: pages.map((page, pageIndex) => {
          const questions = page.questions ?? [];
          return {
            title: `Page ${pageIndex + 1}`,
            key: generatePageId(pageIndex),
            disabled: questions.length === 0,
            children: questions.map((question, questionIndex) => {
              return {
                key: generateQuestionId(pageIndex, questionIndex),
                title: question.text,
              };
            }),
          };
        }),
      },
    ];
  }, [title, pages]);

  const handleSelect = (items) => {
    setSelected(items);
  };

  const handleImportClick = () => {
    setIsLoadingImport(true);

    if (selected.includes(ALL_CHECKBOX_KEY)) {
      onFinish(removeQuestionsIds(pages));
      return;
    }

    const selectedQuestions = selected
      .filter((key) => isQuestionId(key))
      .map((questionId) => getQuestionIndexFromId(questionId));

    const collectedQuestions = collectQuestionsByIndexes(pages, selectedQuestions);
    const newQuestions = removeQuestionsIds(collectedQuestions);

    onFinish(newQuestions);
  };

  if (pages.length === 0) {
    return <Typography.Text>There is no any pages in the questionnaire</Typography.Text>;
  }

  return (
    <Card>
      <Space direction="vertical">
        <Tree
          selectedKeys={selected}
          transparent
          checkable
          selectable={false}
          defaultExpandAll
          treeData={tree}
          onCheck={handleSelect}
        />

        <Button
          key="submit"
          type="primary"
          loading={isLoadingImport}
          disabled={selected.length === 0}
          onClick={handleImportClick}
        >
          Import & Continue
        </Button>
      </Space>
    </Card>
  );
};

QuestionsSelect.propTypes = {
  title: PropTypes.string,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  onFinish: PropTypes.func,
};

QuestionsSelect.defaultProps = {
  title: "",
  pages: [],
  onFinish: () => undefined,
};

export default QuestionsSelect;
