import { useGetForumFeedbackQuestionsQuery } from "api/queries/forumFeedbackQuestions";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Select } from "components/styleguide";
import { FeedBackQuestionType, FeedbackType, ForumFeedBackQuestionModel } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { getQuestionItemsOptions } from "../helpers";
import { FeedbackField, MeetingFormValues, SessionFormValues } from "../types";

interface EditFeedbackFormProps {
  feedbackType: FeedbackType;
  fields: Array<FeedbackField>;
  isUpdating: boolean;
  onSubmit: <T extends MeetingFormValues & SessionFormValues>(values: T) => void;
  onClose: () => void;
}

const EditFeedbackForm: FC<EditFeedbackFormProps> = ({ fields, feedbackType, isUpdating, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const { data: { data: questions = [] as Array<ForumFeedBackQuestionModel> } = {}, isLoading: isQuestionsLoading } =
    useGetForumFeedbackQuestionsQuery({ feedBackType: feedbackType });

  const getFieldRules = (isRequired?: boolean) => {
    if (isRequired) {
      return [
        {
          required: true,
          message: t("errors.required", { prop: "$t(type)" }),
        },
      ];
    }
  };

  return (
    <FullHeightForm
      form={form}
      labelCol={{ span: 24, offset: 0 }}
      actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
      actionsAppend={
        <Button type="primary" htmlType="submit" loading={isUpdating}>
          {t("Save")}
        </Button>
      }
      onFinish={onSubmit}
    >
      {fields.map(({ label, name, initialValue, component, isRequired }, key) => (
        <Form.Item key={key} initialValue={initialValue} label={t(label)} name={name} rules={getFieldRules(isRequired)}>
          {component ?? (
            <Select
              options={getQuestionItemsOptions(questions, name as FeedBackQuestionType)}
              loading={isQuestionsLoading}
            />
          )}
        </Form.Item>
      ))}
    </FullHeightForm>
  );
};

export default EditFeedbackForm;
