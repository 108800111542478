import { DownOutlined } from "@ant-design/icons";
import { useGetTemplatesQuery } from "api/queries/templates";
import { TemplateType } from "generated/api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

import { Button, Dropdown } from "../styleguide";

const ExportTemplates = () => {
  const { t } = useTranslation();

  const [type, setType] = useState<TemplateType | null>(null);

  const { isFetching: isTemplatesLoading } = useGetTemplatesQuery(
    { type: type as TemplateType },
    {
      enabled: !!type,
      refetchOnWindowFocus: false,
      onSuccess: ({ data: link }) => downloadFileByURL(link),
      onError: () => toast.error(t("Something went wrong")),
      onSettled: () => setType(null),
    },
  );

  const menu = {
    items: [
      {
        label: t("All"),
        key: "all",
        onClick: () => setType(TemplateType.VenueAllTemplates),
      },
      {
        label: t("Facilities"),
        key: "facilities",
        onClick: () => setType(TemplateType.VenueFacilities),
      },
      {
        label: t("Tables"),
        key: "tables",
        onClick: () => setType(TemplateType.VenueTables),
      },
      {
        label: t("Bedrooms"),
        key: "bedrooms",
        onClick: () => setType(TemplateType.VenueBedrooms),
      },
    ],
  };

  return (
    <Dropdown {...{ menu }}>
      <Button loading={isTemplatesLoading}>
        {t("Export Templates")}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ExportTemplates;
