import Icon, { FieldTimeOutlined, MailOutlined, SolutionOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "components/styleguide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

const links = [
  {
    title: "Email Templates",
    link: "forum-admin/email-templates",
    icon: <MailOutlined />,
  },
  {
    title: "Itinerary Booklets",
    link: "forum-admin/itinerary/create/queue",
    icon: <SolutionOutlined />,
  },
  {
    title: "Timeslots",
    link: "forum-admin/timeslots",
    icon: <FieldTimeOutlined />,
  },
];

const FrequentlyUsedPages: FC = () => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <div className={styles.frequentlyUsedPages}>
      <Title level={4}>{t("Frequently used pages")}</Title>
      <Row gutter={30} className={styles.linkContainer} justify="start">
        {links.map(({ title, link, icon }) => (
          <Col key={title} lg={8} sm={12}>
            <Button
              href={link}
              className={styles.link}
              type="link"
              icon={<Icon component={() => icon as any} className={styles.linkIcon} />}
            >
              {t(title)}
            </Button>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FrequentlyUsedPages;
