import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { RadioChangeEvent } from "antd";
import { useDeleteForumDocumentWatermark } from "api/mutations/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import { Col, Modal, Radio, Row } from "components/styleguide";
import { DocumentDefinitionWatermarkResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import styles from "./styles.module.less";

interface WatermarksProps {
  value?: number | null;
  forumId: number;
  watermarks: Array<DocumentDefinitionWatermarkResponseModel>;
  onChange: (watermarkId: number) => void;
  deselect: () => void;
}

const Watermarks: FC<WatermarksProps> = ({ value, forumId, watermarks, onChange, deselect }) => {
  const { t } = useTranslation();
  const { confirm } = Modal;
  const queryClient = useQueryClient();

  const deleteWatermark = useDeleteForumDocumentWatermark();

  const showDeleteConfirm = (watermarkId: number | undefined) => {
    confirm({
      title: t("confirmation"),
      content: t("Do you want to proceed with deletion of this watermark?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: () => {
        deleteWatermark.mutate(
          { forumId, watermarkId },
          {
            onSuccess: () => {
              queryClient.resetQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_WATERMARKS]);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onError: ({ response: { data } }: any) => {
              if (data?.meta?.ReferencingObjects?.length) {
                toast.error(t("This watermark cannot be deleted as it is used in other documents"));
              } else {
                toast.error(t("Something went wrong"));
              }
            },
          },
        );
      },
    });
  };

  const onChangeWatermark = (event: RadioChangeEvent) => onChange(event.target.value);

  const deselectWatermark = (event: MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;

    if (toNumber(target?.value) === value) {
      deselect();
    }
  };

  return (
    <Radio.Group value={value} className={styles.watermarks} onChange={onChangeWatermark}>
      <Row gutter={[30, 20]}>
        {watermarks.map(({ id, description, link }) => (
          <Col key={id}>
            <Radio key={id} value={id} onClick={deselectWatermark}>
              <figure>
                <Row>
                  <Col className={styles.imageContainer}>
                    <img alt={description ?? "Watermark"} src={link} />
                  </Col>
                </Row>
                <Row className={styles.titleContainer} justify="space-between" align="middle" gutter={[10, 0]}>
                  <Col span={20}>
                    <figcaption>{description}</figcaption>
                  </Col>
                  <Col>
                    <DeleteFilled
                      className="delete-icon"
                      onClick={(event) => {
                        event.preventDefault();
                        showDeleteConfirm(id);
                      }}
                    />
                  </Col>
                </Row>
              </figure>
            </Radio>
          </Col>
        ))}
      </Row>
    </Radio.Group>
  );
};

export default Watermarks;
