/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { SessionReorderRequestModel } from '../apiModels';
// @ts-ignore
import { TimeSlotSessionListItemModel } from '../apiModels';
// @ts-ignore
import { TimeSlotSessionRequestModel } from '../apiModels';
// @ts-ignore
import { TimeSlotSessionResponseModel } from '../apiModels';
// @ts-ignore
import { TimeSlotType } from '../apiModels';
/**
 * TimeSlotSessionApi - axios parameter creator
 * @export
 */
export const TimeSlotSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all time slot sessions from all timeslots
         * @param {number} forumId 
         * @param {Array<TimeSlotType>} [timeSlotTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsSessionsGet: async (forumId: number, timeSlotTypes?: Array<TimeSlotType>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsSessionsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/timeslots/sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSlotTypes) {
                localVarQueryParameter['TimeSlotTypes'] = timeSlotTypes;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all sessions
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsGet: async (forumId: number, timeslotId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsGet', 'forumId', forumId)
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsGet', 'timeslotId', timeslotId)
            const localVarPath = `/forums/{forumId}/timeslots/{timeslotId}/sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeslotId"}}`, encodeURIComponent(String(timeslotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a TimeSlot session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {TimeSlotSessionRequestModel} [timeSlotSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsPost: async (forumId: number, timeslotId: number, timeSlotSessionRequestModel?: TimeSlotSessionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsPost', 'forumId', forumId)
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsPost', 'timeslotId', timeslotId)
            const localVarPath = `/forums/{forumId}/timeslots/{timeslotId}/sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeslotId"}}`, encodeURIComponent(String(timeslotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotSessionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a timeslot session by id
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete: async (forumId: number, timeslotId: number, sessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete', 'forumId', forumId)
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete', 'timeslotId', timeslotId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete', 'sessionId', sessionId)
            const localVarPath = `/forums/{forumId}/timeslots/{timeslotId}/sessions/{sessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeslotId"}}`, encodeURIComponent(String(timeslotId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet: async (forumId: number, timeslotId: number, sessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet', 'forumId', forumId)
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet', 'timeslotId', timeslotId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/forums/{forumId}/timeslots/{timeslotId}/sessions/{sessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeslotId"}}`, encodeURIComponent(String(timeslotId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a TimeSlot session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {TimeSlotSessionRequestModel} [timeSlotSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut: async (forumId: number, timeslotId: number, sessionId: number, timeSlotSessionRequestModel?: TimeSlotSessionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut', 'forumId', forumId)
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut', 'timeslotId', timeslotId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut', 'sessionId', sessionId)
            const localVarPath = `/forums/{forumId}/timeslots/{timeslotId}/sessions/{sessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeslotId"}}`, encodeURIComponent(String(timeslotId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(timeSlotSessionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder a TimeSlot session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {SessionReorderRequestModel} [sessionReorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut: async (forumId: number, timeslotId: number, sessionId: number, sessionReorderRequestModel?: SessionReorderRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut', 'forumId', forumId)
            // verify required parameter 'timeslotId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut', 'timeslotId', timeslotId)
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut', 'sessionId', sessionId)
            const localVarPath = `/forums/{forumId}/timeslots/{timeslotId}/sessions/{sessionId}/reorder`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeslotId"}}`, encodeURIComponent(String(timeslotId)))
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionReorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeSlotSessionApi - functional programming interface
 * @export
 */
export const TimeSlotSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeSlotSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all time slot sessions from all timeslots
         * @param {number} forumId 
         * @param {Array<TimeSlotType>} [timeSlotTypes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsSessionsGet(forumId: number, timeSlotTypes?: Array<TimeSlotType>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotSessionResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsSessionsGet(forumId, timeSlotTypes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all sessions
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsTimeslotIdSessionsGet(forumId: number, timeslotId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSlotSessionListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsTimeslotIdSessionsGet(forumId, timeslotId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a TimeSlot session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {TimeSlotSessionRequestModel} [timeSlotSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsTimeslotIdSessionsPost(forumId: number, timeslotId: number, timeSlotSessionRequestModel?: TimeSlotSessionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotSessionListItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsTimeslotIdSessionsPost(forumId, timeslotId, timeSlotSessionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a timeslot session by id
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete(forumId: number, timeslotId: number, sessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete(forumId, timeslotId, sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet(forumId: number, timeslotId: number, sessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotSessionListItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet(forumId, timeslotId, sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a TimeSlot session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {TimeSlotSessionRequestModel} [timeSlotSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut(forumId: number, timeslotId: number, sessionId: number, timeSlotSessionRequestModel?: TimeSlotSessionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut(forumId, timeslotId, sessionId, timeSlotSessionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder a TimeSlot session
         * @param {number} forumId 
         * @param {number} timeslotId 
         * @param {number} sessionId 
         * @param {SessionReorderRequestModel} [sessionReorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut(forumId: number, timeslotId: number, sessionId: number, sessionReorderRequestModel?: SessionReorderRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut(forumId, timeslotId, sessionId, sessionReorderRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeSlotSessionApi - factory interface
 * @export
 */
export const TimeSlotSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeSlotSessionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all time slot sessions from all timeslots
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsSessionsGet(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TimeSlotSessionResponseModel>> {
            return localVarFp.forumsForumIdTimeslotsSessionsGet(requestParameters.forumId, requestParameters.timeSlotTypes, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all sessions
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsGet(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TimeSlotSessionListItemModel>> {
            return localVarFp.forumsForumIdTimeslotsTimeslotIdSessionsGet(requestParameters.forumId, requestParameters.timeslotId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a TimeSlot session
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsPost(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<TimeSlotSessionListItemModel> {
            return localVarFp.forumsForumIdTimeslotsTimeslotIdSessionsPost(requestParameters.forumId, requestParameters.timeslotId, requestParameters.timeSlotSessionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a timeslot session by id
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get session
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<TimeSlotSessionListItemModel> {
            return localVarFp.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a TimeSlot session
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, requestParameters.timeSlotSessionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder a TimeSlot session
         * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, requestParameters.sessionReorderRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdTimeslotsSessionsGet operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsSessionsGetRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {Array<TimeSlotType>}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsSessionsGet
     */
    readonly timeSlotTypes?: Array<TimeSlotType>
}

/**
 * Request parameters for forumsForumIdTimeslotsTimeslotIdSessionsGet operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGetRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGet
     */
    readonly timeslotId: number
}

/**
 * Request parameters for forumsForumIdTimeslotsTimeslotIdSessionsPost operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPostRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPost
     */
    readonly timeslotId: number

    /**
     * 
     * @type {TimeSlotSessionRequestModel}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPost
     */
    readonly timeSlotSessionRequestModel?: TimeSlotSessionRequestModel
}

/**
 * Request parameters for forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDeleteRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete
     */
    readonly timeslotId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete
     */
    readonly sessionId: number
}

/**
 * Request parameters for forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGetRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet
     */
    readonly timeslotId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet
     */
    readonly sessionId: number
}

/**
 * Request parameters for forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPutRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut
     */
    readonly timeslotId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut
     */
    readonly sessionId: number

    /**
     * 
     * @type {TimeSlotSessionRequestModel}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut
     */
    readonly timeSlotSessionRequestModel?: TimeSlotSessionRequestModel
}

/**
 * Request parameters for forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut operation in TimeSlotSessionApi.
 * @export
 * @interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPutRequest
 */
export interface TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut
     */
    readonly timeslotId: number

    /**
     * 
     * @type {number}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut
     */
    readonly sessionId: number

    /**
     * 
     * @type {SessionReorderRequestModel}
     * @memberof TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut
     */
    readonly sessionReorderRequestModel?: SessionReorderRequestModel
}

/**
 * TimeSlotSessionApi - object-oriented interface
 * @export
 * @class TimeSlotSessionApi
 * @extends {BaseAPI}
 */
export class TimeSlotSessionApi extends BaseAPI {
    /**
     * 
     * @summary Get all time slot sessions from all timeslots
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsSessionsGet(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsSessionsGetRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsSessionsGet(requestParameters.forumId, requestParameters.timeSlotTypes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all sessions
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsTimeslotIdSessionsGet(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsGetRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsTimeslotIdSessionsGet(requestParameters.forumId, requestParameters.timeslotId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a TimeSlot session
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsTimeslotIdSessionsPost(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsPostRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsTimeslotIdSessionsPost(requestParameters.forumId, requestParameters.timeslotId, requestParameters.timeSlotSessionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a timeslot session by id
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdDeleteRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get session
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdGetRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a TimeSlot session
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdPutRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, requestParameters.timeSlotSessionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder a TimeSlot session
     * @param {TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotSessionApi
     */
    public forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut(requestParameters: TimeSlotSessionApiForumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPutRequest, options?: AxiosRequestConfig) {
        return TimeSlotSessionApiFp(this.configuration).forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut(requestParameters.forumId, requestParameters.timeslotId, requestParameters.sessionId, requestParameters.sessionReorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

