import { Typography } from "components/styleguide";
import {
  ForumDelegatesInformation,
  ForumOtherInformation,
  ForumSpeakersInformation,
  ForumSuppliersInformation,
} from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

interface SummaryListItemProps {
  title: string;
  stats?: ForumDelegatesInformation | ForumSpeakersInformation | ForumSuppliersInformation | ForumOtherInformation;
}

const SummaryListItem: FC<SummaryListItemProps> = ({ title, stats }) => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <>
      <Title level={5}>{t(title)}</Title>
      {stats ? (
        <ul className={styles.stats}>
          {Object.entries(stats)?.map(([key, value]) => (
            <li key={key}>{`${value} ${t(`summaryStatistics.${key}`)}`}</li>
          ))}
        </ul>
      ) : (
        "-"
      )}
    </>
  );
};

export default SummaryListItem;
