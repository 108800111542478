import { Button, Card } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionOptionType from "utils/questionOptionTypeUtils";
import QuestionType from "utils/questionTypeUtils";

import QuestionEditDrawer from "../QuestionEditDrawer";
import QuestionPreviewRenderer from "../QuestionPreviewRenderer";

const QuestionRenderer = ({ questionId, questionIndex, question, isEditable, onChange }) => {
  const { t } = useTranslation();

  const [showEditPopup, setShowEditPopup] = useState(false);

  const handleQuestionChange = (newQuestion) => {
    onChange(newQuestion);
    setShowEditPopup(false);
  };

  const onEdit = (e) => {
    if (isEditable) {
      e.stopPropagation();
      setShowEditPopup(true);
    }
  };

  return (
    <>
      <Card
        title={t("Question {{n}}", { n: questionIndex + 1 })}
        id={questionId}
        extra={
          <Button onClick={onEdit} disabled={!isEditable}>
            {t("Edit")}
          </Button>
        }
      >
        <QuestionPreviewRenderer question={question} />
      </Card>
      <QuestionEditDrawer
        questionIndex={questionIndex}
        question={question}
        visible={showEditPopup}
        onVisibleChange={setShowEditPopup}
        onChange={handleQuestionChange}
      />
    </>
  );
};

QuestionRenderer.propTypes = {
  questionId: PropTypes.string.isRequired,
  questionIndex: PropTypes.number.isRequired,
  question: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(QuestionType.VALUES).isRequired,
    description: PropTypes.string,
    numberOfColumns: PropTypes.number,
    secondaryText: PropTypes.string,
    showSecondaryText: PropTypes.bool,
    layoutHorizontal: PropTypes.bool,
    optionsColumns: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
        type: PropTypes.oneOf(QuestionOptionType.VALUES),
      }),
    ),
  }),
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
};

QuestionRenderer.defaultProps = {
  isEditable: true,
  onChange: () => undefined,
};

export default QuestionRenderer;
