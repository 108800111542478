import { Space } from "antd";
import { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import { DefaultSortOptions } from "../constants";
import EventsSelectOption, { EventTypes } from "../OptionTypes/EventsSelectOption";
import PeopleTypeSelectOption from "../OptionTypes/PeopleTypeSelectOption";
import SortOrderOption from "../OptionTypes/SortByOption";

export const BedroomsSortOptions = {
  Bedroom: "Bedroom",
  Grade: "Grade",
};

export const BedroomsSortOptionsWithDefault = {
  ...BedroomsSortOptions,
  ...DefaultSortOptions,
};

interface BedroomsOptionsProps {
  options?: Record<string, string>;
  defaultOption?: string;
  showPeopleTypes?: boolean;
}

const BedroomsOptions = ({
  options = BedroomsSortOptionsWithDefault,
  defaultOption = BedroomsSortOptions.Bedroom,
  showPeopleTypes = false,
}: BedroomsOptionsProps) => {
  const [orderBy, setOrderBy] = useState<string>(defaultOption);
  const [eventType, setEventType] = useState<EventTypes>(EventTypes.ThisEvent);
  const [peopleTypes, setPeopleTypes] = useState<string[]>([]);

  return (
    <Space align={"end"}>
      <SortOrderOption options={options} orderBy={orderBy} setOrderBy={setOrderBy} />
      <EventsSelectOption eventType={eventType} setEventType={setEventType} />

      {showPeopleTypes && <PeopleTypeSelectOption peopleTypes={peopleTypes} setPeopleTypes={setPeopleTypes} />}

      <LinkToReport
        disabled={showPeopleTypes && !peopleTypes.length}
        parameters={{
          OrderBy: orderBy,
          Event: eventType,
          ParticipationCode: showPeopleTypes ? peopleTypes : undefined,
        }}
      />
    </Space>
  );
};

export default BedroomsOptions;
