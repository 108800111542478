import React, { FC } from "react";

import { useGetForumGroupsQuery } from "../../../../../../../../api/queries/groups";
import AsyncSelect from "../AsyncSelect";

interface GroupSelectingFromProps {
  forumId: number;
  primary?: boolean;
  value?: number;
  onChange?: (value: number) => void;
}

const GroupSelectingFrom: FC<GroupSelectingFromProps> = ({ forumId, primary, value, onChange }) => {
  const query = useGetForumGroupsQuery({ forumId, primary });

  const optionsMapper = (response: NonNullable<typeof query.data>["data"]) =>
    response.map((group) => ({
      value: group.id,
      label: group.name,
    }));

  return <AsyncSelect query={query} optionsMapper={optionsMapper} value={value} onChange={onChange} />;
};

export default GroupSelectingFrom;
