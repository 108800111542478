export const EDITOR_OPTIONS = {
  mergeTags: {
    forumName: {
      name: "Forum Name",
      value: "#[ForumName]#",
    },
    firstName: {
      name: "First Name",
      value: "#[FirstName]#",
    },
    forumDisplayDates: {
      name: "Forum Display Dates",
      value: "#[ForumDisplayDates]#",
    },
    questionnaireTitle: {
      name: "Questionnaire Title",
      value: "#[QuestionnaireTitle]#",
    },
    questionnaireCloseDate: {
      name: "Questionnaire Close Date",
      value: "#[QuestionnaireCloseDate]#",
    },
    reContactName: {
      name: "RE Contact Name",
      value: "#[REContactName]#",
    },
    reContactJobTitle: {
      name: "RE Contact Job Title",
      value: "#[REContactJobTitle]#",
    },
    reContactPhone: {
      name: "RE Contact Phone",
      value: "#[REContactPhone]#",
    },
    personEmail: {
      name: "Person Email",
      value: "#[PersonEmail]#",
    },
    siteCloseDate: {
      name: "Site Close Date",
      value: "#[SiteCloseDate]#",
    },
    fromDate: {
      name: "From Date",
      value: "#[FromDate]#",
    },
    toDate: {
      name: "To Date",
      value: "#[ToDate]#",
    },
  },
};
