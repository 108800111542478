import ContentItem from "../content-item";
import References from "../references";
import collectForConferenceRankV2 from "./collect-for-conference-rank-v2";
import collectForJoint from "./collect-for-joint";
import collectForReasonForMeeting from "./collect-for-reason-for-meeting";

const collectReferences = (item: ContentItem): References => {
  const refs = new References();

  if (item.is("ReasonForMeeting") || item.is("ReasonForMeetingV2") || item.is("ReasonForMeetingV3")) {
    collectForReasonForMeeting(refs, item);
    return refs;
  }

  if (item.is("Joint")) {
    collectForJoint(refs, item);
    return refs;
  }

  if (item.is("ConferenceRankV2")) {
    collectForConferenceRankV2(refs, item);
    return refs;
  }

  return refs;
};

export default collectReferences;
