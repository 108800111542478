import { useQuestionnaireContentQuery, useQuestionnaireQuery } from "api/queries/questionnaires";
import { Form, Spin } from "components/styleguide";
import { PositiveSelectionV2Item, QuestionaireSearchIndex, QuestionnaireContentQuestion } from "generated/api";
import { FC, useMemo } from "react";
import { getQuestionsFromPages } from "utils/questionnaireContentUtils";

import withOnChange from "../../../utils/withOnChange";
import ListingTable, { ListingTableProps } from "./components/ListingTable";
import mapQuestionsWithMappings from "./utils/mapQuestionsWithMappings";

interface FormValues extends PositiveSelectionV2Item {
  questionnaireOfGroupBeingSelected: number;
}

interface QuestionnaireSearchIndexProps {
  forumId: number;
  value?: QuestionaireSearchIndex;
  onChange?: (value: QuestionaireSearchIndex) => void;
}

const QuestionnaireSearchIndex: FC<QuestionnaireSearchIndexProps> = ({ forumId, value, onChange }) => {
  const form = Form.useFormInstance();

  const {
    questionnaireOfGroupBeingSelected: qbs,
    questionnaireOfGroupBeingSelectedVersion: qbsContentVersion,
  }: FormValues = form.getFieldsValue([
    "questionnaireOfGroupBeingSelected",
    "questionnaireOfGroupBeingSelectedVersion",
  ]);

  const questionnaireQuery = useQuestionnaireQuery({ questionnaireId: qbs, forumId });
  const questionnaireContentQuery = useQuestionnaireContentQuery({ forumId, questionnaireId: qbs });
  const isLoading = questionnaireQuery.isLoading || questionnaireContentQuery.isLoading;

  const questions = useMemo<Array<QuestionnaireContentQuestion>>(() => {
    return getQuestionsFromPages(questionnaireContentQuery.data?.data.pages ?? []);
  }, [questionnaireContentQuery.data?.data.pages]);

  const mappedValue = useMemo<ListingTableProps["value"]>(() => {
    const isVersionDiffersFromLatest = qbsContentVersion !== questionnaireQuery.data?.data.contentVersion;

    if (isVersionDiffersFromLatest || !value) {
      return mapQuestionsWithMappings(questions, { questions: [] });
    }

    return mapQuestionsWithMappings(questions, value);
  }, [qbsContentVersion, questionnaireQuery.data?.data.contentVersion, questions, value]);

  const handleOnChange: ListingTableProps["onChange"] = (newQuestions) => {
    onChange?.({ ...value, questions: newQuestions });
  };

  if (isLoading) {
    return <Spin />;
  }

  return <ListingTable questions={questions} value={mappedValue} onChange={handleOnChange} />;
};

export default withOnChange(QuestionnaireSearchIndex);
