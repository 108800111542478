import { DownOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useImportTimeslotsMutation } from "api/mutations/fileImport";
import KEYS from "api/queries/keys";
import { Button, Dropdown, Form, Space } from "components/styleguide";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateFileImportKey } from "utils/localStorage/fileImport";

import ImportDrawer from "./ImportDrawer";
import { FileImportSubmit } from "./types";

export default function TimeslotImport({ forumId }: { forumId: number }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [isTimeslotDrawerOpen, setIsTimeslotDrawerOpen] = useState(false);

  const closeAllDrawers = () => {
    setIsTimeslotDrawerOpen(false);
    queryClient.removeQueries([KEYS.GET_TIMESLOT_IMPORT]);
  };

  const importMutation = useImportTimeslotsMutation();

  const onTimeslotSubmit = ({ formFile, importMode, importKey }: FileImportSubmit) => {
    importMutation.mutate(
      {
        forumId,
        formFile: formFile.file.originFileObj,
        importMode,
        importKey,
      },
      {
        onSuccess: () => {
          updateFileImportKey(importKey);
          closeAllDrawers();
        },
        onError: () => {
          toast.error(t("Something went wrong"));
        },
      },
    );
  };

  const menuProps = {
    items: [
      {
        label: t("Import Timeslots"),
        key: "timeslots",
        onClick: () => setIsTimeslotDrawerOpen(true),
      },
    ],
  };

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            {t("Import")}
            <DownOutlined style={{ fontSize: "12px" }} />
          </Space>
        </Button>
      </Dropdown>
      <ImportDrawer
        onSubmit={onTimeslotSubmit}
        title={t("Import Timeslots")}
        visible={isTimeslotDrawerOpen}
        onClose={() => {
          setIsTimeslotDrawerOpen(false);
          queryClient.removeQueries([KEYS.GET_TIMESLOT_IMPORT]);
        }}
        isLoading={importMutation.isLoading}
        form={form}
      />
    </>
  );
}
