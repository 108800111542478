import "./styles.less";

import { Table as AntdTable, TableProps } from "antd";
import React from "react";

const Table = <RecordType extends object>(props: TableProps<RecordType>) => {
  return (
    <div className={"table-container"}>
      <div className={"table-content"}>
        <AntdTable {...props} />
      </div>
    </div>
  );
};

export default Table;
