import { TabsProps } from "antd";
import { Layout, Space, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CompletedDocumentRuns from "./CompletedDocumentRuns";
import QueueDocumentRun from "./QueueDocumentRun";

import styles from "./styles.module.less";

interface CreateProps {
  tab: string;
}

const Create: FC<CreateProps> = ({ tab }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs: TabsProps["items"] = [
    {
      key: "queue",
      label: t("Queue Document Run"),
      children: <QueueDocumentRun />,
    },
    {
      key: "completed",
      label: t("Completed Document Runs"),
      children: <CompletedDocumentRuns />,
    },
  ];

  return (
    <div>
      <UserGuide.Title title="Create" articleId="16441694952733-create" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Layout className={styles.createSection}>
          <Tabs items={tabs} defaultActiveKey={tab} onChange={(path) => navigate(`../${path}`)} />
        </Layout>
      </Space>
    </div>
  );
};

export default Create;
