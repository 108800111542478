import { useGetForumAttendeeSummaryQuery } from "api/queries/attendees";
import { useGetForumByIdQuery } from "api/queries/forums";
import { Divider, Spin } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

import Footer from "./Footer";
import FrequentlyUsedPages from "./FrequentlyUsedPages";
import Header from "./Header";
import Statistics from "./Statistics";

import styles from "./styles.module.less";

const Dashboard: FC = () => {
  const forumId = toNumber(useParams().id);

  const { data: { data: summary } = {}, isLoading: isSummaryLoading } = useGetForumAttendeeSummaryQuery({ forumId });

  const { data: { data: forumInfo } = {}, isLoading: isForumInfoLoading } = useGetForumByIdQuery({ forumId });

  if (isForumInfoLoading || isSummaryLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.dashboard}>
      <div>
        <Header {...forumInfo} />
        <Divider />
        {summary && (
          <>
            <Statistics {...{ summary }} />
            <Divider />
          </>
        )}
        <FrequentlyUsedPages />
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
