import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { TableProps } from "antd";
import {
  useDeleteAttendeePreferencesMeetingMutation,
  useUpdateAttendeePreferencesMeetingPriorityMutation,
} from "api/mutations/attendees";
import { useAttendeePreferencesMeetingsQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import EllipsisText from "components/EllipsisText";
import { Modal } from "components/styleguide";
import Table from "components/styleguide/components/Table";
import TableRowActionButtons from "components/TableRowActionButtons";
import {
  ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest,
  MeetingPreferenceResponseModel,
  PageResponseMeetingPreferenceResponseModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import PriorityInputBox, { PreferenceResponseModels } from "../common/PriorityInputBox";
import { buildAttendeeColumn, handleDuplicatePriorityError, validatePriorityRange } from "../common/utils";

interface AttendeePreferencesMeetingsTableProps {
  forumId: number;
  attendeeId: number;
  onEdit: (record: MeetingPreferenceResponseModel) => void;
}

const AttendeePreferencesMeetingsTable: FC<AttendeePreferencesMeetingsTableProps> = ({
  forumId,
  attendeeId,
  onEdit,
}) => {
  const { t } = useTranslation();

  const [meetings, setMeetings] = useState<PageResponseMeetingPreferenceResponseModel | null>(null);

  const [attendeeToMeetWithSearch, setAttendeeToMeetWithSearch] = useState<string | undefined>(undefined);
  const [attendeeToMakeJointWithSearch, setAttendeeToMakeJointWithSearch] = useState<string | undefined>(undefined);

  const {
    response: { isLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeePreferencesMeetingsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseMeetingPreferenceResponseModel }) => {
        setMeetings(data);
      },
    },
    {
      forumId: forumId,
      attendeeId: attendeeId,
      attendeeToMeetWith: attendeeToMeetWithSearch,
      attendeeToMakeJointWith: attendeeToMakeJointWithSearch,
    } as ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesMeetingsGetRequest,
    { pageSizeOptions: [10, 20, 50] },
  );

  const queryClient = useQueryClient();
  const updatePriorityMutation = useUpdateAttendeePreferencesMeetingPriorityMutation();
  const deletePreferenceMeetingMutation = useDeleteAttendeePreferencesMeetingMutation();

  // this state looks like this (where 3 is the meetingId of the table row):
  // {
  //   3: [
  //     "Must be between 1 and 1000",
  //     "This priority is already set for a meeting",
  //   ],
  // }
  const [preferenceErrors, setPreferenceErrors] = useState<{ [id: number]: string[] }>({});

  const updatePriority = useCallback(
    (id: number, oldValue: number, newValue: number, meeting?: PreferenceResponseModels) => {
      // add error to display if value is not in range

      const { attendeeToMeetWithId, attendeeToMakeJointWithId, reasonForMeeting, reasonDetails } = {
        ...(meeting as MeetingPreferenceResponseModel),
      };

      const outOfRange = validatePriorityRange(preferenceErrors, setPreferenceErrors, id, newValue);
      if (outOfRange) {
        return;
      }

      // skip mutation if value didn't change
      if (oldValue === newValue) {
        return;
      }
      updatePriorityMutation.mutate(
        {
          forumId,
          attendeeId,
          id,
          meetingPreferenceRequestModel: {
            priority: newValue,
            attendeeToMeetWithId,
            attendeeToMakeJointWithId,
            reasonForMeeting,
            reasonDetails,
          },
        },
        {
          onSuccess: () => {
            queryClient.resetQueries([KEYS.GET_ATTENDEE_PREFERENCES_MEETINGS]);
          },
          onError: ({ response }) => {
            // add error to display when DuplicateItemException is returned
            handleDuplicatePriorityError(preferenceErrors, setPreferenceErrors, id, response);
          },
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const onPreferenceMeetingDeleteSubmit = (meeting: MeetingPreferenceResponseModel) => {
    deletePreferenceMeetingMutation.mutate(
      { forumId, attendeeId, id: meeting.id },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_ATTENDEE_PREFERENCES_MEETINGS]);
          queryClient.resetQueries([KEYS.GET_ATTENDEE_HISTORY_INFORMATION]);
        },
      },
    );
  };

  const onPreferenceMeetingDelete = (meeting: MeetingPreferenceResponseModel) => {
    Modal.confirm({
      title: t("Delete meeting preference"),
      content: t("Do you want to proceed with deletion of this meeting?"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("no"),
      okText: t("Yes"),
      onOk: async () => {
        onPreferenceMeetingDeleteSubmit(meeting);
      },
    });
  };

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "attendeeToMeetWith": {
        setAttendeeToMeetWithSearch(selectedKeys[0]);
        break;
      }
      case "attendeeToMakeJointWith": {
        setAttendeeToMakeJointWithSearch(selectedKeys[0]);
        break;
      }
    }
    queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_MEETINGS]);
    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "attendeeToMeetWith": {
        setAttendeeToMeetWithSearch(undefined);
        break;
      }
      case "attendeeToMakeJointWith": {
        setAttendeeToMakeJointWithSearch(undefined);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  const columns: TableProps<MeetingPreferenceResponseModel>["columns"] = [
    {
      title: t("Priority"),
      dataIndex: "priority",
      key: "preference",
      render: (priority: number, meeting: PreferenceResponseModels) => (
        <PriorityInputBox
          priority={priority}
          preferenceErrors={preferenceErrors}
          setPreferenceErrors={setPreferenceErrors}
          rowId={meeting.id}
          updatePriority={updatePriority}
          record={meeting}
        />
      ),
      sorter: false,
    },
    {
      title: t("Attendee to meet with"),
      dataIndex: "attendeeToMeetWithName",
      key: "attendeeToMeetWith",
      render: (_: string, b: MeetingPreferenceResponseModel) =>
        buildAttendeeColumn(
          b.attendeeToMeetWithName,
          b.attendeeToMeetWithParticipationCode,
          b.attendeeToMeetWithJobTitle,
          b.attendeeToMeetWithCompany,
        ),
      sorter: false,
      ...getColumnSearchProps("attendeeToMeetWith", handleSearch, handleReset, "attendeeToMeetWith"),
    },
    {
      title: t("Attendee to make joint with"),
      dataIndex: "attendeeToMakeJointWithName",
      key: "attendeeToMakeJointWith",
      render: (_: string, b: MeetingPreferenceResponseModel) => {
        if (b.attendeeToMakeJointWithName && b.attendeeToMakeJointWithParticipationCode) {
          return buildAttendeeColumn(
            b.attendeeToMakeJointWithName,
            b.attendeeToMakeJointWithParticipationCode,
            b.attendeeToMakeJointWithJobTitle,
            b.attendeeToMakeJointWithCompany,
          );
        } else {
          return "";
        }
      },
      sorter: false,
      ...getColumnSearchProps("attendeeToMakeJointWith", handleSearch, handleReset, "attendeeToMakeJointWith"),
    },
    {
      title: t("Reason for meeting"),
      dataIndex: "reasonForMeeting",
      width: "25%",
      key: "reasonForMeeting",
      render: (val: string) => val,
      sorter: false,
    },
    {
      title: t("Reason Details"),
      dataIndex: "reasonDetails",
      width: "25%",
      key: "reasonDetails",
      render: (val: string | null) => (val ? <EllipsisText text={val} maxLength={50} /> : val),
      sorter: false,
    },
    {
      title: "",
      render: (_: unknown, record) => {
        return (
          <TableRowActionButtons onEdit={() => onEdit(record)} onDelete={() => onPreferenceMeetingDelete(record)} />
        );
      },
    },
  ];

  return (
    <Table
      id="attendee-group-memberships-table"
      dataSource={meetings?.items ?? []}
      columns={columns as TableProps<MeetingPreferenceResponseModel>["columns"]}
      pagination={pagination}
      bordered
      loading={isLoading || updatePriorityMutation.isLoading}
      rowKey="id"
      onChange={handleSort}
    />
  );
};

export default AttendeePreferencesMeetingsTable;
