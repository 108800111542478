import { CSSProperties } from "react";

type Variables<T = unknown> = {
  [K in keyof T]: T[K];
};

/**
 * This function is used to have a better typechecking, to omit the situation like ->
 * style={{ ['--item-nb' as any]: index }}
 * should be written in the next way
 *
 * ```
 * const getStyles = typesafeStyles<{ '--item-nb': number }>
 * <div style={getStyles({ '--item-nb': index })} />`
 * ```
 */
const typesafeStyles = <T extends Variables>(styles: CSSProperties & T): CSSProperties => {
  return styles;
};

export default typesafeStyles;
