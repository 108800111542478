import { Typography } from "antd";
import { useGetForumGroupsQuery } from "api/queries/groups";
import { Divider, Layout, Space } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { TestDocumentDefinitionTypes as ReportTypes } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import TestItem, { TestItemProps } from "./TestItem";

import styles from "./styles.module.less";

const Test: FC = () => {
  const forumId = toNumber(useParams().id);
  const { t } = useTranslation();
  const { Title, Paragraph } = Typography;

  const { isLoading: isGroupsLoading, data: { data: forumGroups = [] } = {} } = useGetForumGroupsQuery({ forumId });

  const groups = useMemo(
    () => ({
      options: forumGroups.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
      isLoading: isGroupsLoading,
    }),
    [forumGroups, isGroupsLoading],
  );

  const items = useMemo<TestItemProps[]>(
    () => [
      {
        title: "Check Profile Information",
        description: (
          <>
            <Paragraph>
              {t(
                "Each company profile with the additional information should fit on to one page. If the information overruns for any reason then one of the following must be done:",
              )}
            </Paragraph>
            <ul>
              <li>{t("Shorten the company description within EM2.")}</li>
              <li>
                {t(
                  "Switch off the display of a profile element from the questionnaire within EM2. This will affect all entries within the itinerary booklets.",
                )}
              </li>
              <li>
                {t(
                  "Switch on the option to automatically truncate long profiles. This will omit some details for some companies (dependent on which questions they have answered within the questionnaire).",
                )}
              </li>
            </ul>
            <Paragraph>
              {t(
                "Changes to descriptions and profiles require the event to be uploaded before they will be reflected in the entries. An automatic upload happens every hour on the hour. Alternately email the IT Team and we will run one for you.",
              )}
            </Paragraph>
            <Paragraph>{t("This report can be run at any time.")}</Paragraph>
          </>
        ),
        reportType: ReportTypes.TestProfile,
        groups,
      },
      {
        title: "Check Conference Information",
        description: (
          <>
            <Paragraph>
              {t(
                "This report will show the details and times for all general sessions and conference entries. You should check the spelling of entries. You should also check the speaker biographies of conference sessions.",
              )}
            </Paragraph>
            <Paragraph>
              {t(
                "This report cannot be run until sessions have been created and speakers have been scheduled in to them.",
              )}
            </Paragraph>
          </>
        ),
        reportType: ReportTypes.TestConferenceProfile,
      },
      {
        title: "Check Reasons for Meeting",
        description: (
          <>
            <Paragraph>
              {t("This report will show the reasons for meetings supplied by attendees against their preferences.")}
            </Paragraph>
            <Paragraph>
              {t(
                "To change / amend the spelling on these you must masquerade as the attendee and change it on the reason for meeting step.",
              )}
            </Paragraph>
            <Paragraph>
              {t("This report can be run at any time but will be most useful when the selection site is open.")}
            </Paragraph>
          </>
        ),
        reportType: ReportTypes.TestReason,
        groups,
      },
      {
        title: "All Profiles for Event",
        description: (
          <>
            <Paragraph>{t("This report will show the full profiles for all attendees within that group.")}</Paragraph>
            <Paragraph>{t("This report can be run at any time.")}</Paragraph>
          </>
        ),
        reportType: ReportTypes.AllProfiles,
        groups,
      },
      {
        title: "Attendee List from Co-located Forums",
        description: (
          <>
            <Paragraph>
              {t(
                "This report will show the name, job title and companies of events that are co-located with this one.",
              )}
            </Paragraph>
            <Paragraph>
              {t("To set the events to be listed change the Sister Events value in the Sister Events section.")}
            </Paragraph>
            <Paragraph>{t("This report can be run at any time.")}</Paragraph>
          </>
        ),
        reportType: ReportTypes.AllSisterEventAttendeesExpanded,
      },
      {
        title: "All Sister Events List",
        description: t("This report will show you the list of Sister Events."),
        reportType: ReportTypes.AllSisterEventsList,
      },
      {
        title: "Questionnaire Definition",
        description: t("This report will show you the questionnaire definition."),
        reportType: ReportTypes.QuestDefinition,
        groups,
        isQuestionnairesVisible: true,
      },
      {
        title: "Attendee List",
        description: t("This report will show you the attendees list."),
        reportType: ReportTypes.AttendeeList,
      },
      {
        title: "Profile List",
        description: t(
          "This report will show you the list of all attendees participating in the forum and their profiles.",
        ),
        reportType: ReportTypes.ProfileList,
      },
      {
        title: "Profile List No Profiles",
        description: t("This report will show you the list of forum's attendees."),
        reportType: ReportTypes.ProfileListNoProfiles,
      },
    ],
    [groups],
  );

  return (
    <>
      <UserGuide.Title title="Test" articleId="16441700690205-test" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Layout className={styles.testSection}>
          <Title className={styles.description} level={5}>
            {t("You can test parts of your documents here:")}
          </Title>
          {items.map((item, key) => (
            <div key={key}>
              <Divider />
              <TestItem key={item.title} {...{ ...item }} />
            </div>
          ))}
        </Layout>
      </Space>
    </>
  );
};

export default Test;
