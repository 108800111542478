import { useQueryClient } from "@tanstack/react-query";
import { FormInstance } from "antd";
import { useUpdateForumSocialLinksMutation } from "api/mutations/forums";
import KEYS from "api/queries/keys";
import { Button, Col, Divider, Form, Input, Row, Space, Spin } from "components/styleguide";
import { ForumSocialLinksModel } from "generated/api";
import { toNumber } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { getFormFields } from "./helpers";

export default function SocialLinks({
  isEditing,
  form,
  socialLinks,
  isLoading,
  setSocialLinks,
  setIsEditing,
}: {
  isEditing: boolean;
  isLoading: boolean;
  form: FormInstance;
  socialLinks: ForumSocialLinksModel | undefined;
  setIsEditing: (state: boolean) => void;
  setSocialLinks: (state: ForumSocialLinksModel) => void;
}) {
  const { t } = useTranslation();
  const { id: forumId } = useParams();
  const queryClient = useQueryClient();

  const mutation = useUpdateForumSocialLinksMutation();

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const submit = (values: ForumSocialLinksModel) => {
    mutation.mutate(
      { forumId: toNumber(forumId), forumSocialLinksModel: values },
      {
        onSuccess: () => {
          setSocialLinks(values);
          queryClient.invalidateQueries([KEYS.GET_FORUM_SOCIAL_LINKS]);
          setIsEditing(false);
        },
      },
    );
  };

  if (isLoading || !socialLinks || !form) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Form
        id="socialLinksForm"
        name="socialLinksForm"
        form={form}
        labelAlign="left"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 11 }}
        labelWrap
        colon={false}
        onFinish={submit}
      >
        <Row gutter={80}>
          {getFormFields({ t, socialLinks }).map((f) => (
            <Col key={f.dataIndex} md={24} lg={12}>
              {f.title ? (
                <Form.Item initialValue={f.initialValue} label={f.title} name={f.dataIndex}>
                  {isEditing ? <Input /> : <label> {f.initialValue ?? "-"}</label>}
                </Form.Item>
              ) : (
                <Space fullWidth={false} />
              )}
              {f.title && <Divider />}
            </Col>
          ))}
        </Row>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button htmlType="submit" type="primary" loading={mutation.isLoading} disabled={mutation.isLoading}>
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </>
  );
}
