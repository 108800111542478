import { SettingOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Drawer } from "../../../../../../../../../../../components/styleguide";
import OptionsMatching from "../OptionsMatching";

const OptionsMatchingButton = ({ forumId, from, to, options, onOptionsChange }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);
  const close = () => setVisible(false);

  return (
    <>
      <Button icon={<SettingOutlined />} type="text" onClick={show} />
      <Drawer
        open={visible}
        placement="right"
        title={t("Matching Question Items")}
        size="large"
        destroyOnClose
        onClose={close}
      >
        <OptionsMatching from={from} to={to} forumId={forumId} options={options} onOptionsChange={onOptionsChange} />
      </Drawer>
    </>
  );
};

OptionsMatchingButton.propTypes = {
  forumId: PropTypes.number.isRequired,
  from: PropTypes.shape({
    questionnaire: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    questionnaire: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
  onOptionsChange: PropTypes.func,
};

OptionsMatchingButton.defaultProps = {
  options: undefined,
  onOptionsChange: () => undefined,
};

export default OptionsMatchingButton;
