import { useSendEmailToAttendeeMutation, useSendEmailToAttendeesMutation } from "api/mutations/email";
import { useUploadImageMutation } from "api/mutations/emailTemplates";
import {
  useGetDefaultEmailTemplatesQuery,
  useGetEmailTemplateDetails,
  useGetEmailTemplatesQuery,
} from "api/queries/emails";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Select } from "components/styleguide";
import { uniq, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as blank from "utils/emailTemplateUtils/blankTemplate.json";

import { EMAIL_CATEGORIES, EMAIL_CATEGORIES_OPTIONS } from "../../../utils/emailTemplates";
import { EDITOR_OPTIONS } from "./helpers";

import styles from "./styles.module.less";

const { Option } = Select;

const SendEmailDrawer = ({
  onClose,
  isOpen,
  attendees,
  userInfo,
  forumId,
  emailCategory,
  selectedAll,
  isFiltered,
  hideTargetGroup,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const emailEditorRef = useRef(null);

  const [toFieldValues, setToFieldValues] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(emailCategory ?? EMAIL_CATEGORIES.general);
  const [emailTemplate, setEmailTemplate] = useState(undefined);

  const sendEmailToAttendeesMutation = useSendEmailToAttendeesMutation();
  const sendEmailToAttendeeMutation = useSendEmailToAttendeeMutation();
  const uploadImageMutation = useUploadImageMutation();

  const { isLoading: isLoadingEmailTemplates, data: { data: emailTemplates = [] } = {} } = useGetEmailTemplatesQuery();
  const { isLoading: isLoadingDefaultEmailTemplates, data: { data: defaultEmailTemplates = [] } = {} } =
    useGetDefaultEmailTemplatesQuery();

  useGetEmailTemplateDetails(selectedTemplateId, {
    enabled: Boolean(selectedTemplateId),
    onSuccess: ({ data }) => {
      emailEditorRef.current.editor.loadDesign(JSON.parse(data?.body?.jsonBody));
      setEmailTemplate(data);
    },
  });

  const onReady = () => {
    emailEditorRef.current.editor.registerCallback("image", (file, done) => {
      const formData = new FormData();
      formData.append("FormFile", file.attachments[0]);

      uploadImageMutation.mutate(formData, {
        onSuccess: async ({ data }) => {
          done({ url: data });
        },
      });
    });

    emailEditorRef.current.editor.loadDesign(emailTemplate ? JSON.parse(emailTemplate?.body?.jsonBody) : blank);
  };

  const handleClose = () => {
    onClose();
    form.resetFields(["subject", "template", "emailTemplateCategory, groupMembership"]);
    setEmailTemplate(undefined);
    setSelectedTemplateId(undefined);
  };

  const handleSubmit = () => {
    const { subject, template } = form.getFieldsValue();
    let to = form.getFieldValue("to").map((element) => element.value);

    if (selectedAll) {
      to = attendees.map(({ attendId, attendeeId }) => attendId || attendeeId);
    }

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { html } = data;

      if (to.length > 1) {
        sendEmailToAttendeesMutation.mutate(
          {
            forumId,
            sendEmailToMultipleUsersRequest: { subject, attendeeIds: to, templateId: template, body: html },
          },
          {
            onSuccess: () => {
              toast.success(t("Emails successfully sent"));
              handleClose();
            },
          },
        );
      } else {
        sendEmailToAttendeeMutation.mutate(
          {
            forumId,
            attendeeId: to[0],
            sendEmailRequestBase: { subject, templateId: template, body: html },
          },
          {
            onSuccess: () => {
              toast.success(t("Email successfully sent"));
              handleClose();
            },
          },
        );
      }
    });
  };

  useEffect(() => {
    if (isOpen || emailCategory) {
      setSelectedCategory(emailCategory ?? EMAIL_CATEGORIES.general);
      form.resetFields(["emailTemplateCategory"]);
    }
  }, [isOpen, emailCategory]);

  useEffect(() => {
    if (userInfo !== null && attendees.length) {
      const to = attendees.map((attendee) => ({
        value: attendee.attendId || attendee.attendeeId,
        label: attendee.email,
        ...attendee,
      }));

      const targetGroup = attendees?.flatMap((attendee) =>
        attendee?.groupMembership?.map((groupMember) => ({
          value: groupMember,
          label: groupMember,
          id: attendee.attendId || attendee.attendeeId,
          ...attendee,
        })),
      );
      const uniqueTargetGroup = uniqBy(targetGroup, "value");

      setToFieldValues(to);

      const fields = [
        {
          name: "to",
          value: selectedAll ? (isFiltered ? t("All Filtered Attendees") : t("All Attendees")) : to,
        },
        {
          name: "from",
          value: [{ value: userInfo.email, label: userInfo.email }],
        },
        { name: "groupMembership", value: uniqueTargetGroup },
      ];
      form.setFields(fields);
    }
  }, [attendees, userInfo, isOpen, form]);

  useEffect(() => {
    if (emailTemplate) {
      form.setFieldValue("subject", emailTemplate.subject);
    }
  }, [emailTemplate, form]);

  const filteredTemplatesSelectOptions = useMemo(() => {
    if (!(emailTemplates && defaultEmailTemplates)) return;

    return [...defaultEmailTemplates, ...emailTemplates]
      .filter((template) => template.emailTemplateCategory === selectedCategory)
      .map((template) => (
        <Option value={template.id} label={template.name} key={template.id}>
          {template.name}
        </Option>
      ));
  }, [defaultEmailTemplates, emailTemplates, selectedCategory]);

  const handleGroupDeselect = (option) => {
    const filteredToFieldValues = form.getFieldValue("to")?.filter((attendee) => {
      return attendee.groupMembership.length === 0 || !attendee.groupMembership.includes(option.value);
    });
    const groups = form.getFieldValue("groupMembership");

    const filteredGroups = groups.filter((group) => {
      return filteredToFieldValues.some((to) => {
        return to.groupMembership.includes(group.value);
      });
    });

    setToFieldValues(filteredToFieldValues);
    form.setFields([
      {
        name: "to",
        value: filteredToFieldValues,
      },
      { name: "groupMembership", value: filteredGroups },
    ]);
  };

  const handleToDeselect = () => {
    const to = form.getFieldValue("to");
    const groups = form.getFieldValue("groupMembership");
    const groupNames = uniq(groups?.flatMap((group) => group.groupMembership));
    const mappedToFields = attendees?.filter((attendee) => {
      return to.some((toField) => attendee.attendId === toField.value);
    });

    const filteredGroups = groups
      .filter(
        (group) =>
          groupNames.includes(group.value) &&
          mappedToFields.some((participant) => participant.groupMembership.includes(group.value)),
      )
      .flatMap((attendee) =>
        attendee.groupMembership.map((groupMember) => ({
          value: groupMember,
          label: groupMember,
          ...attendee,
        })),
      );
    const uniqGroups = uniqBy(filteredGroups, "value");

    form.setFields([{ name: "groupMembership", value: uniqGroups }]);
  };

  return (
    <Drawer
      className={styles.sendEmailDrawer}
      title={t("Bulk Email")}
      placement="right"
      onClose={handleClose}
      open={isOpen}
      contentWrapperStyle={{ minWidth: "60%" }}
      destroyOnClose
    >
      <FullHeightForm
        form={form}
        actionsPrepend={
          <Button onClick={handleClose} disabled={sendEmailToAttendeesMutation.isLoading}>
            {t("cancel")}
          </Button>
        }
        actionsAppend={
          <Button
            type="primary"
            disabled={sendEmailToAttendeesMutation.isLoading || sendEmailToAttendeesMutation.isLoading}
            loading={sendEmailToAttendeesMutation.isLoading || sendEmailToAttendeesMutation.isLoading}
            htmlType="submit"
          >
            {t("Send")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("Category")}
          name="emailTemplateCategory"
          labelCol={{ span: 24, offset: 0 }}
          initialValue={selectedCategory}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Category)" }),
            },
          ]}
        >
          <Select
            onChange={(category) => {
              setSelectedCategory(category);
            }}
            placeholder={t("placeholders.select", { prop: "$t(Category)" })}
            options={EMAIL_CATEGORIES_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          label={t("Template")}
          name="template"
          labelCol={{ span: 24, offset: 0 }}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Template)" }),
            },
          ]}
        >
          <Select
            onChange={(id) => setSelectedTemplateId(id)}
            dependecies={["emailTemplateCategory"]}
            showSearch
            placeholder={t("placeholders.select", { prop: "$t(Template)" })}
            loading={isLoadingEmailTemplates || isLoadingDefaultEmailTemplates}
            optionFilterProp={"label"}
          >
            {filteredTemplatesSelectOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("To")}
          name="to"
          getValueFromEvent={(values) => {
            setToFieldValues(values); // This is just to make useEffect track this value and update remove icon
            return values;
          }}
          labelCol={{ span: 24, offset: 0 }}
        >
          <Select
            labelInValue
            mode="multiple"
            onDeselect={handleToDeselect}
            notFoundContent={null}
            showSearch={false}
            removeIcon={toFieldValues.length <= 1 || selectedAll ? null : undefined}
          />
        </Form.Item>
        <Form.Item
          hidden={hideTargetGroup}
          label={t("Target Group")}
          name="groupMembership"
          labelCol={{ span: 24, offset: 0 }}
        >
          <Select
            labelInValue
            mode="multiple"
            notFoundContent={null}
            showSearch={false}
            onDeselect={handleGroupDeselect}
            removeIcon={toFieldValues.length <= 1 || selectedAll ? null : undefined}
          />
        </Form.Item>
        <Form.Item label={t("From")} name="from" labelCol={{ span: 24, offset: 0 }}>
          <Select mode="multiple" notFoundContent={null} showSearch={false} removeIcon={null} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          label={t("Subject")}
          name={"subject"}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Subject)" }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(Subject)" })} />
        </Form.Item>
        <EmailEditor
          key={!!emailTemplate}
          className={styles.editor} ref={emailEditorRef} onReady={onReady} options={EDITOR_OPTIONS} />
      </FullHeightForm>
    </Drawer>
  );
};

SendEmailDrawer.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  attendees: PropTypes.array,
  userInfo: PropTypes.object,
  forumId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  emailCategory: PropTypes.string,
  selectedAll: PropTypes.bool,
  isFiltered: PropTypes.bool,
  hideTargetGroup: PropTypes.bool,
};

export default SendEmailDrawer;
