const AttendeeGrade = {
  None: 1,
  A: 2,
  B: 3,
  C: 4,
  D: 5,

  toDisplayName(type: number) {
    switch (type) {
      case 1:
        return "None";
      case 2:
        return "A";
      case 3:
        return "B";
      case 4:
        return "C";
      case 5:
        return "D";
      default:
        return "";
    }
  },
};

export default AttendeeGrade;
