import { useGetForumByIdQuery } from "api/queries/forums";
import { toNumber } from "lodash";
import React from "react";

const DEFAULT_OFFSET = "+00:00";
const ForumUtcOffsetContext = React.createContext(DEFAULT_OFFSET);

export const ForumUtcOffsetProvider = ({ forumId, children }: { forumId: number; children: JSX.Element }) => {
  const query = useGetForumByIdQuery({ forumId: toNumber(forumId) });

  const utcOffset = query.data?.data?.timezoneId;

  return (
    <ForumUtcOffsetContext.Provider value={utcOffset ?? DEFAULT_OFFSET}>{children}</ForumUtcOffsetContext.Provider>
  );
};

export default ForumUtcOffsetContext;
