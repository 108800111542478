import { useQueryClient } from "@tanstack/react-query";
import { useDeleteForumRoomGradeMutation } from "api/mutations/forumRoomGrades";
import { useDeleteRoomGradeMutation } from "api/mutations/roomGrade";
import { useForumRoomGradesQuery } from "api/queries/forumRoomGrades";
import KEYS from "api/queries/keys";
import { useRoomGradesQuery } from "api/queries/roomGrade";
import { Button, Layout, Table } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import { RoomGradeResponseModel } from "generated/api";
import { toNumber } from "lodash-es";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import AddGrade from "./AddGrade";
import EditGrade from "./EditGrade";

import styles from "./styles.module.less";

const { Header, Content } = Layout;

interface GradesProps {
  drawerVisible: boolean;
}

export default function Grades({ drawerVisible }: GradesProps) {
  const { id: forumId, venueId, buildingId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const isSysAdmin = forumId === undefined;

  const [grades, setGrades] = useState<RoomGradeResponseModel[]>([]);
  const [activeGrade, setActiveGrade] = useState<RoomGradeResponseModel | null>(null);
  const [isAddGradeDrawerVisible, setIsAddGradeDrawerVisible] = useState<boolean>(false);
  const [isEditGradeDrawerVisible, setIsEditGradeDrawerVisible] = useState<boolean>(false);

  const onEditGrade = (grade: RoomGradeResponseModel) => {
    setActiveGrade(grade);
    setIsEditGradeDrawerVisible(true);
  };

  const onEditGradeDrawerClose = () => {
    setIsEditGradeDrawerVisible(false);
    setActiveGrade(null);
  };

  const onAddGrade = () => setIsAddGradeDrawerVisible(true);

  const onAddGradeDrawerClose = () => setIsAddGradeDrawerVisible(false);

  const roomGradesQuery = useRoomGradesQuery(
    {
      venueId: toNumber(venueId),
      buildingId: toNumber(buildingId),
    },
    {
      onSuccess: ({ data }) => {
        setGrades(data);
      },
      enabled: isSysAdmin && drawerVisible,
    },
  );

  const forumRoomGradesQuery = useForumRoomGradesQuery(
    {
      forumId: toNumber(forumId),
      forumBuildingId: toNumber(buildingId),
    },
    {
      onSuccess: ({ data }) => {
        setGrades(data);
      },
      enabled: !isSysAdmin && drawerVisible,
    },
  );

  const deleteRoomGradeMutation = useDeleteRoomGradeMutation();
  const deleteForumRoomGradeMutation = useDeleteForumRoomGradeMutation();

  const isLoading = isSysAdmin
    ? roomGradesQuery.isLoading || deleteRoomGradeMutation.isLoading
    : forumRoomGradesQuery.isLoading || deleteForumRoomGradeMutation.isLoading;

  const deleteGrade = (gradeId: number) => {
    if (isSysAdmin) {
      deleteRoomGradeMutation.mutate(
        {
          venueId: toNumber(venueId),
          buildingId: toNumber(buildingId),
          gradeId,
        },
        {
          onSuccess: () => queryClient.invalidateQueries([KEYS.GET_ROOM_GRADES]),
          onError: ({ response }) => {
            if (response.data.Type === "ItemHasRelationException") {
              toast.error("Unable to delete the Room Grade as it's associated with other Rooms");
            }
          },
        },
      );
    } else {
      deleteForumRoomGradeMutation.mutate(
        {
          forumId: toNumber(forumId),
          forumBuildingId: toNumber(buildingId),
          forumGradeId: gradeId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_FORUM_ROOM_GRADES]);
          },
          onError: ({ response }) => {
            if (response.data.Type === "ItemHasRelationException") {
              toast.error("Unable to delete the Room Grade as it's associated with other Rooms");
            }
          },
        },
      );
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    { title: "Description", dataIndex: "description" },
    {
      dataIndex: "id",
      render: (_: number, grade: RoomGradeResponseModel) => {
        return <TableRowActionButtons onEdit={() => onEditGrade(grade)} onDelete={() => deleteGrade(grade.id)} />;
      },
    },
  ];

  return (
    <div>
      <Layout>
        <Header className={styles.header}>
          <Button onClick={onAddGrade}>{t("Add Grade")}</Button>
        </Header>
        <Content id="tablesContent">
          <AddGrade isVisible={isAddGradeDrawerVisible} onClose={onAddGradeDrawerClose} />
          {activeGrade && (
            <EditGrade grade={activeGrade} isVisible={isEditGradeDrawerVisible} onClose={onEditGradeDrawerClose} />
          )}
          <Table pagination={false} columns={columns} dataSource={grades} loading={isLoading} rowKey="id" />
        </Content>
      </Layout>
    </div>
  );
}
