/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ItineraryBookletsForumTimeTypeLabelRequestModel } from '../apiModels';
// @ts-ignore
import { ItineraryBookletsForumTimeTypeLabelResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * ItineraryBookletsForumTimeTypeLabelApi - axios parameter creator
 * @export
 */
export const ItineraryBookletsForumTimeTypeLabelApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Time Type Labels by forum
         * @param {number} forumId 
         * @param {string} [timeType] 
         * @param {string} [meetingLabel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTimeTypeLabelsGet: async (forumId: number, timeType?: string, meetingLabel?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTimeTypeLabelsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-time-type-labels`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeType !== undefined) {
                localVarQueryParameter['TimeType'] = timeType;
            }

            if (meetingLabel !== undefined) {
                localVarQueryParameter['MeetingLabel'] = meetingLabel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Time Type Labels by forum
         * @param {number} forumId 
         * @param {number} timeTypeLabelId 
         * @param {ItineraryBookletsForumTimeTypeLabelRequestModel} [itineraryBookletsForumTimeTypeLabelRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut: async (forumId: number, timeTypeLabelId: number, itineraryBookletsForumTimeTypeLabelRequestModel?: ItineraryBookletsForumTimeTypeLabelRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut', 'forumId', forumId)
            // verify required parameter 'timeTypeLabelId' is not null or undefined
            assertParamExists('forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut', 'timeTypeLabelId', timeTypeLabelId)
            const localVarPath = `/forums/{forumId}/itinerarybooklets-time-type-labels/{timeTypeLabelId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"timeTypeLabelId"}}`, encodeURIComponent(String(timeTypeLabelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itineraryBookletsForumTimeTypeLabelRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItineraryBookletsForumTimeTypeLabelApi - functional programming interface
 * @export
 */
export const ItineraryBookletsForumTimeTypeLabelApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItineraryBookletsForumTimeTypeLabelApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Time Type Labels by forum
         * @param {number} forumId 
         * @param {string} [timeType] 
         * @param {string} [meetingLabel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTimeTypeLabelsGet(forumId: number, timeType?: string, meetingLabel?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ItineraryBookletsForumTimeTypeLabelResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTimeTypeLabelsGet(forumId, timeType, meetingLabel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Time Type Labels by forum
         * @param {number} forumId 
         * @param {number} timeTypeLabelId 
         * @param {ItineraryBookletsForumTimeTypeLabelRequestModel} [itineraryBookletsForumTimeTypeLabelRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut(forumId: number, timeTypeLabelId: number, itineraryBookletsForumTimeTypeLabelRequestModel?: ItineraryBookletsForumTimeTypeLabelRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut(forumId, timeTypeLabelId, itineraryBookletsForumTimeTypeLabelRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItineraryBookletsForumTimeTypeLabelApi - factory interface
 * @export
 */
export const ItineraryBookletsForumTimeTypeLabelApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItineraryBookletsForumTimeTypeLabelApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Time Type Labels by forum
         * @param {ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTimeTypeLabelsGet(requestParameters: ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ItineraryBookletsForumTimeTypeLabelResponseModel>> {
            return localVarFp.forumsForumIdItinerarybookletsTimeTypeLabelsGet(requestParameters.forumId, requestParameters.timeType, requestParameters.meetingLabel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Time Type Labels by forum
         * @param {ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut(requestParameters: ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut(requestParameters.forumId, requestParameters.timeTypeLabelId, requestParameters.itineraryBookletsForumTimeTypeLabelRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdItinerarybookletsTimeTypeLabelsGet operation in ItineraryBookletsForumTimeTypeLabelApi.
 * @export
 * @interface ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGetRequest
 */
export interface ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGet
     */
    readonly timeType?: string

    /**
     * 
     * @type {string}
     * @memberof ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGet
     */
    readonly meetingLabel?: string
}

/**
 * Request parameters for forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut operation in ItineraryBookletsForumTimeTypeLabelApi.
 * @export
 * @interface ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPutRequest
 */
export interface ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut
     */
    readonly timeTypeLabelId: number

    /**
     * 
     * @type {ItineraryBookletsForumTimeTypeLabelRequestModel}
     * @memberof ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut
     */
    readonly itineraryBookletsForumTimeTypeLabelRequestModel?: ItineraryBookletsForumTimeTypeLabelRequestModel
}

/**
 * ItineraryBookletsForumTimeTypeLabelApi - object-oriented interface
 * @export
 * @class ItineraryBookletsForumTimeTypeLabelApi
 * @extends {BaseAPI}
 */
export class ItineraryBookletsForumTimeTypeLabelApi extends BaseAPI {
    /**
     * 
     * @summary Get Time Type Labels by forum
     * @param {ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTimeTypeLabelApi
     */
    public forumsForumIdItinerarybookletsTimeTypeLabelsGet(requestParameters: ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTimeTypeLabelApiFp(this.configuration).forumsForumIdItinerarybookletsTimeTypeLabelsGet(requestParameters.forumId, requestParameters.timeType, requestParameters.meetingLabel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Time Type Labels by forum
     * @param {ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumTimeTypeLabelApi
     */
    public forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut(requestParameters: ItineraryBookletsForumTimeTypeLabelApiForumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumTimeTypeLabelApiFp(this.configuration).forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut(requestParameters.forumId, requestParameters.timeTypeLabelId, requestParameters.itineraryBookletsForumTimeTypeLabelRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

