import { useQueryClient } from "@tanstack/react-query";
import { Form, Select } from "antd";
import { useUpdateAttendeePreferencesCategoriesMutation } from "api/mutations/attendees";
import { useAttendeePreferencesAvailableCategoriesQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Col, Row } from "components/styleguide";
import { AttendeeCategoryPreferencesRequestModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface EditAttendeePreferencesCategoryProps {
  categoryIds: Array<number>;
  onClose: () => void;
}

const EditAttendeePreferencesCategory: FC<EditAttendeePreferencesCategoryProps> = ({ categoryIds, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const { mutate: updateCategories, isLoading: isCategoriesUpdating } =
    useUpdateAttendeePreferencesCategoriesMutation();

  const { data: { data: availableCategories = [] } = {}, isLoading: isAvailableCategoriesLoading } =
    useAttendeePreferencesAvailableCategoriesQuery({
      forumId,
      attendeeId,
    });

  const getAvailableCategoriesOptions = () => {
    return availableCategories.map(({ categoryId, categoryName }) => ({
      label: categoryName,
      value: categoryId,
    }));
  };

  const onCancel = () => {
    form.resetFields();
    onClose();
  };

  const onFinish = (values: AttendeeCategoryPreferencesRequestModel) => {
    updateCategories(
      {
        forumId,
        attendeeId,
        attendeeCategoryPreferencesRequestModel: values,
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_CATEGORIES]),
        onError: () => toast.error(t("Something went wrong")),
        onSettled: () => onClose(),
      },
    );
  };

  return (
    <Form form={form} layout="horizontal" onFinish={onFinish}>
      <Row gutter={80}>
        <Col md={24} lg={12}>
          <Form.Item initialValue={categoryIds} name="categories">
            <Select
              mode="multiple"
              allowClear
              options={getAvailableCategoriesOptions()}
              loading={isAvailableCategoriesLoading}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={2}>
          <Button htmlType="submit" type="primary" loading={isCategoriesUpdating} disabled={isCategoriesUpdating}>
            {t("Save")}
          </Button>
        </Col>
        <Col span={2}>
          <Button onClick={onCancel}> {t("Cancel")}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default EditAttendeePreferencesCategory;
