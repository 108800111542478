import { Space } from "components/styleguide";
import { ForumSeminarResponseModel, ForumSeminarTypeResponseModel } from "generated/api";
import { ReportsValues } from "pages/Reporting/helpers";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DefaultSortOptions } from "../constants";
import SeminarsTableOption from "../OptionTypes/SeminarsTableOption";
import SeminarTypesTableOption from "../OptionTypes/SeminarTypesTableOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const ConferenceAttendeesSeminarsOptions = () => {
  const { reportKey } = useParams();
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Surname);

  const onSeminarSelect = (seminar: ForumSeminarResponseModel) => {
    navigate("report", {
      state: {
        parameters: {
          OrderBy: orderBy,
          ForumSeminarId: seminar.id,
        },
      },
    });
  };

  const onSeminarTypeSelect = (seminarType: ForumSeminarTypeResponseModel) => {
    navigate("report", {
      state: {
        parameters: {
          OrderBy: orderBy,
          ForumSeminarTypeId: seminarType.id,
        },
      },
    });
  };

  return (
    <Space direction={"vertical"} size={"large"} fullWidth>
      <SortOrderOption options={DefaultSortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />

      {reportKey === ReportsValues.ConferenceAttendeesSelectedSeminarCode ? (
        <SeminarsTableOption onSeminarSelect={onSeminarSelect} />
      ) : (
        <SeminarTypesTableOption onSeminarTypeSelect={onSeminarTypeSelect} />
      )}
    </Space>
  );
};

export default ConferenceAttendeesSeminarsOptions;
