import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumVisualSpeakerDocumentMutation } from "api/mutations/visualSpeakerDocuments";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { ForumVisualSpeakerDocumentResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import DocumentForm from "../DocumentForm";
import { DocumentUpdateModel } from "../types";

interface EditDocumentDrawerProps {
  document: ForumVisualSpeakerDocumentResponseModel;
  isVisible: boolean;
  onClose: () => void;
}

const EditDocumentDrawer: FC<EditDocumentDrawerProps> = ({ document, isVisible, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);
  const seminarId = toNumber(params.seminarId);

  const { mutate: updateDocument, isLoading: isDocumentUpdating } = useUpdateForumVisualSpeakerDocumentMutation();

  const onSubmit = ({ location, file, description, permissions, publish }: DocumentUpdateModel) => {
    const data = {
      forumId,
      seminarId,
      documentId: toNumber(document?.id),
      title: file?.[0]?.originFileObj.name ?? String(document?.title),
      description,
      publishedFromDate: publish?.[0]?.format("YYYY-MM-DD"),
      publishedUntilDate: publish?.[1]?.format("YYYY-MM-DD"),
      permissionGroupIds: permissions,
      seminarIds: location,
      formFile: file?.[0]?.originFileObj,
    };

    updateDocument(data, {
      onSuccess: () => {
        queryClient.invalidateQueries([KEYS.GET_FORUM_VISUAL_SPEAKER_DOCUMENTS]);
        toast.success(t("File successfully updated"));
        onClose();
      },
      onError: () => toast.error(t("Something went wrong")),
    });
  };

  return (
    <Drawer
      title={t("Update document")}
      open={isVisible}
      size="large"
      placement="right"
      destroyOnClose
      onClose={onClose}
    >
      <DocumentForm initialValue={document} isLoading={isDocumentUpdating} onSubmit={onSubmit} onClose={onClose} />
    </Drawer>
  );
};

export default EditDocumentDrawer;
