/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumMeetingFeedBackRequestModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseForumAttendeeMeetingFeedbackModel } from '../apiModels';
/**
 * ForumAttendeeMeetingFeedbackApi - axios parameter creator
 * @export
 */
export const ForumAttendeeMeetingFeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete meeting feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete: async (forumId: number, attendeeId: number, feedbackId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete', 'feedbackId', feedbackId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/meeting-feedback/{feedbackId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the meeting feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {Array<ForumMeetingFeedBackRequestModel>} [forumMeetingFeedBackRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut: async (forumId: number, attendeeId: number, feedbackId: number, forumMeetingFeedBackRequestModel?: Array<ForumMeetingFeedBackRequestModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut', 'feedbackId', feedbackId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/meeting-feedback/{feedbackId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumMeetingFeedBackRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the meeting feedback given by an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [startRange] 
         * @param {string} [endRange] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMeetingFeedbackGet: async (forumId: number, attendeeId: number, startRange?: string, endRange?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdMeetingFeedbackGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/meeting-feedback`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startRange !== undefined) {
                localVarQueryParameter['StartRange'] = (startRange as any instanceof Date) ?
                    (startRange as any).toISOString() :
                    startRange;
            }

            if (endRange !== undefined) {
                localVarQueryParameter['EndRange'] = (endRange as any instanceof Date) ?
                    (endRange as any).toISOString() :
                    endRange;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeMeetingFeedbackApi - functional programming interface
 * @export
 */
export const ForumAttendeeMeetingFeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeMeetingFeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete meeting feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete(forumId: number, attendeeId: number, feedbackId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete(forumId, attendeeId, feedbackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the meeting feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {Array<ForumMeetingFeedBackRequestModel>} [forumMeetingFeedBackRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut(forumId: number, attendeeId: number, feedbackId: number, forumMeetingFeedBackRequestModel?: Array<ForumMeetingFeedBackRequestModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut(forumId, attendeeId, feedbackId, forumMeetingFeedBackRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the meeting feedback given by an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [startRange] 
         * @param {string} [endRange] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdMeetingFeedbackGet(forumId: number, attendeeId: number, startRange?: string, endRange?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseForumAttendeeMeetingFeedbackModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdMeetingFeedbackGet(forumId, attendeeId, startRange, endRange, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeMeetingFeedbackApi - factory interface
 * @export
 */
export const ForumAttendeeMeetingFeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeMeetingFeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete meeting feedback
         * @param {ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete(requestParameters: ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the meeting feedback
         * @param {ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut(requestParameters: ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, requestParameters.forumMeetingFeedBackRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the meeting feedback given by an attendee
         * @param {ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdMeetingFeedbackGet(requestParameters: ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseForumAttendeeMeetingFeedbackModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdMeetingFeedbackGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.startRange, requestParameters.endRange, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete operation in ForumAttendeeMeetingFeedbackApi.
 * @export
 * @interface ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDeleteRequest
 */
export interface ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete
     */
    readonly feedbackId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut operation in ForumAttendeeMeetingFeedbackApi.
 * @export
 * @interface ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPutRequest
 */
export interface ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut
     */
    readonly feedbackId: number

    /**
     * 
     * @type {Array<ForumMeetingFeedBackRequestModel>}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut
     */
    readonly forumMeetingFeedBackRequestModel?: Array<ForumMeetingFeedBackRequestModel>
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdMeetingFeedbackGet operation in ForumAttendeeMeetingFeedbackApi.
 * @export
 * @interface ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGetRequest
 */
export interface ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly startRange?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly endRange?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGet
     */
    readonly orderDir?: string
}

/**
 * ForumAttendeeMeetingFeedbackApi - object-oriented interface
 * @export
 * @class ForumAttendeeMeetingFeedbackApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeMeetingFeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Delete meeting feedback
     * @param {ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingFeedbackApi
     */
    public forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete(requestParameters: ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingFeedbackApiFp(this.configuration).forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the meeting feedback
     * @param {ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingFeedbackApi
     */
    public forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut(requestParameters: ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingFeedbackApiFp(this.configuration).forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, requestParameters.forumMeetingFeedBackRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the meeting feedback given by an attendee
     * @param {ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeMeetingFeedbackApi
     */
    public forumForumIdAttendeesAttendeeIdMeetingFeedbackGet(requestParameters: ForumAttendeeMeetingFeedbackApiForumForumIdAttendeesAttendeeIdMeetingFeedbackGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeMeetingFeedbackApiFp(this.configuration).forumForumIdAttendeesAttendeeIdMeetingFeedbackGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.startRange, requestParameters.endRange, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }
}

