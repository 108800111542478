import { ForumRoomGradeApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumRoomGradeApi);

const ForumRoomGrade = {
  get: api.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet,
  post: api.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost,
  put: api.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut,
  delete: api.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete,
};

export default ForumRoomGrade;
