import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useGetCountriesQuery } from "api/queries/countries";
import { useGetForumVenueQuery } from "api/queries/forumVenues";
import { useTimezonesQuery } from "api/queries/timezone";
import { useGetVenuesQuery } from "api/queries/venues";
import VenueAssignmentType from "backend-models/venueAssignmentType";
import FullHeightForm from "components/FullHeightForm";
import { Button, DatePicker, Form, Input, Modal, Radio, Select, Space } from "components/styleguide";
import { VirtualForumCode } from "enums/VirtualForumCode";
import { Office } from "generated/api";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getForumDisplayDateByDuration } from "utils/dateUtils";
import { validateFileSize } from "utils/validatorUtils";

import ForumLogoDragger from "../../../ForumLogoDragger";
import VenuesSelect from "./components/VenuesSelect";
import { getForumDisplayDates, getForumDuration, getRecurForumDisplayDates, getRecurForumDuration } from "./dateUtils";

import styles from "./styles.module.less";

const { confirm } = Modal;

const { RangePicker } = DatePicker;
const eventCodeFormat = new RegExp("^[A-Z]{3}[0-9]{2}$");
const validateCodeFormat = async (_, value) => {
  return value.length === 0 || eventCodeFormat.test(value) ? Promise.resolve() : Promise.reject();
};

const FormComponent = ({
  existingForum,
  isEdit,
  isLoading,
  venueAssignmentType,
  onCancel,
  onVenueAssignmentTypeChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const defaultType = VenueAssignmentType.LIST[0].value;

  const [isCountryRequired, setIsCountryRequired] = useState(!existingForum?.isVirtual);

  const countriesRequest = useGetCountriesQuery({});
  const timezonesQuery = useTimezonesQuery();

  const { isLoading: isVenuesLoading, data: { data: { items: venues = [] } = {} } = {} } = useGetVenuesQuery({});

  const virtualVenue = venues.find(({ code }) => code === VirtualForumCode.VirtualVenueCode);

  const { data: { data: venue = {} } = {} } = useGetForumVenueQuery(
    { forumId: existingForum?.id },
    {
      onSuccess: ({ data }) => {
        onVenueAssignmentTypeChange(data?.assignType);
      },
      enabled: !!existingForum?.id,
    },
  );

  const onDurationChange = (duration) => {
    form.setFieldValue("displayDates", getForumDisplayDateByDuration(duration));
  };

  const onCountryChange = () => {
    if (!form.getFieldValue("isVirtual")) {
      form.setFieldValue("venueId", null);
    }
  };

  const handleRadioClick = ({ target: { value: type } }) => {
    const warningType = type === VenueAssignmentType.LIST[1].value ? t("buildings and facilities") : t("facilities");

    // show confirmation only if it's the same venue as before editing
    if (type !== defaultType && form.getFieldValue("venueId") === existingForum?.venueId) {
      confirm({
        title: t("confirmation"),
        content: t(
          "The existing {{warningType}} with associated tables and sessions will be removed from the forum. The primary locations set for companies will also be deleted. Do you want to proceed?",
          {
            warningType,
          },
        ),
        icon: <ExclamationCircleOutlined />,
        okText: t("yes"),
        okType: "danger",
        cancelText: t("no"),
        zIndex: 2001,
        maskStyle: { zIndex: 2000 },
        onOk: () => {
          onVenueAssignmentTypeChange(type);
        },
      });
    } else {
      onVenueAssignmentTypeChange(type);
    }
  };

  const onVenueChange = () => {
    // manage radio buttons value when venue is changed
    if (form.getFieldValue("venueId") !== existingForum?.venueId) {
      onVenueAssignmentTypeChange(defaultType);
    } else {
      onVenueAssignmentTypeChange(venue.assignType);
    }
  };

  const iterateForumCode = (forumCode) => {
    if (forumCode) {
      const letters = forumCode.substring(0, 3);
      const number = toNumber(forumCode.substring(3));

      const incrementedNumber = String((number + 1) % 100).padStart(2, "0");

      // pads left with 0 if the string length is less than 2, until it has length of 2
      // %100 makes sure that 99 + 1 is transformed to "00" instead of "100"

      return `${letters}${incrementedNumber}`;
    }
  };

  const getForumDurationInitial = () => {
    const { startDate, endDate } = existingForum || {};

    if (!startDate || !endDate) {
      return null;
    }

    const getDuration = isEdit ? getForumDuration : getRecurForumDuration;

    return getDuration(startDate, endDate);
  };

  const getForumDisplayDatesInitial = () => {
    const { startDate, endDate, displayDates } = existingForum || {};

    if (isEdit && displayDates) {
      return displayDates;
    }

    if (!startDate || !endDate) {
      return null;
    }

    const getDisplayDates = isEdit ? getForumDisplayDates : getRecurForumDisplayDates;

    return getDisplayDates(startDate, endDate);
  };

  return (
    <FullHeightForm
      id="forumForm"
      name="forumForm"
      form={form}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      actionsPrepend={<Button onClick={onCancel}>{t("Cancel")}</Button>}
      actionsAppend={
        <Button type="primary" disabled={isLoading} loading={isLoading} htmlType="submit">
          {isEdit ? t("Save") : t("create")}
        </Button>
      }
      footerClassName={styles.footer}
      {...props}
    >
      <Form.Item name="logoFileBlobId" initialValue={existingForum?.logoFileBlobId} hidden={true} />
      <Form.Item
        name="logo"
        label={t("Event Logo")}
        rules={[
          {
            validator: validateFileSize(100 * 1024),
            message: t("File is bigger than {{size}}", { size: "100KB" }),
          },
        ]}
      >
        <ForumLogoDragger existingForum={existingForum} form={form} />
      </Form.Item>
      <Form.Item
        label={t("eventCode")}
        name="code"
        initialValue={isEdit && existingForum?.code ? existingForum.code : iterateForumCode(existingForum?.code)}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(eventCode)" }),
          },
          {
            validator: validateCodeFormat,
            message: t("The Event Code should consist of 3 capital letters followed by 2 digits"),
          },
        ]}
      >
        <Input maxLength={5} placeholder={t("placeholders.add", { prop: "$t(eventCode)" })} />
      </Form.Item>
      <Form.Item
        label={t("name")}
        name="title"
        initialValue={existingForum?.title}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(name)" }),
          },
          {
            max: 200,
            message: t("The character limit is {{count}}", { count: 200 }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(name)" })} />
      </Form.Item>
      <Form.Item
        label={t("shortName")}
        name="shortName"
        initialValue={existingForum?.shortName}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(shortName)" }),
          },
          {
            max: 200,
            message: t("The character limit is {{count}}", { count: 200 }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(shortName)" })} />
      </Form.Item>
      <Form.Item
        label={t("Long Name")}
        name="longName"
        initialValue={existingForum?.longName}
        rules={[
          {
            max: 200,
            message: t("The character limit is {{count}}", { count: 200 }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(Long Name)" })} />
      </Form.Item>
      <Form.Item
        label={t("Formatted Name")}
        name="formattedName"
        initialValue={existingForum?.formattedName}
        rules={[
          {
            max: 200,
            message: t("The character limit is {{count}}", { count: 200 }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(Formatted Name)" })} />
      </Form.Item>
      <Form.Item
        label={t("duration")}
        name="duration"
        initialValue={getForumDurationInitial()}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(duration)" }),
          },
        ]}
      >
        <RangePicker onChange={onDurationChange} />
      </Form.Item>
      <Form.Item
        label={t("Display Dates")}
        name="displayDates"
        initialValue={getForumDisplayDatesInitial()}
        rules={[
          {
            max: 100,
            message: t("The character limit is {{count}}", { count: 100 }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Website Address")}
        name="websiteAddress"
        initialValue={existingForum?.websiteAddress}
        rules={[
          {
            max: 250,
            message: t("The character limit is {{count}}", { count: 250 }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(Website Address)" })} />
      </Form.Item>
      <Form.Item
        label={t("Virtual")}
        name="isVirtual"
        initialValue={existingForum?.isVirtual ?? false}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(Virtual)" }),
          },
        ]}
      >
        <Radio.Group
          disabled={existingForum}
          onChange={({ target: { value } }) => {
            if (value) {
              form.setFieldsValue({ countryCode: null, venueId: virtualVenue?.id });
              setIsCountryRequired(false);
            } else {
              form.setFieldsValue({ venueId: null });
              setIsCountryRequired(true);
            }

            form.validateFields(["countryCode"]);
          }}
        >
          <Radio value={true}>{t("Yes")}</Radio>
          <Radio value={false}>{t("No")}</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label={t("Country")}
        name="countryCode"
        initialValue={existingForum?.countryCode}
        rules={[
          {
            required: isCountryRequired,
            message: t("errors.required", { prop: "$t(Country)" }),
          },
        ]}
      >
        <Select
          showSearch
          onChange={onCountryChange}
          placeholder={t("placeholders.select", { prop: "$t(country)" })}
          loading={countriesRequest.isLoading}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {(countriesRequest.data?.data ?? []).map((item) => (
            <Select.Option key={item.isoCode} value={item.isoCode}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* Better ask antd why you need to declare dependent fields THAT WEIRD to make them re-render as expected
       /* https://4x.ant.design/components/form/#shouldUpdate
       */}
      <Form.Item noStyle dependencies={["countryCode"]}>
        {() => {
          return (
            <Form.Item
              name="venueId"
              label={t("venue")}
              initialValue={existingForum?.venueId}
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: "$t(venue)" }),
                },
              ]}
            >
              <VenuesSelect
                onChange={onVenueChange}
                showSearch={true}
                isLoading={isVenuesLoading}
                venues={venues}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
      {isEdit && (
        <Form.Item initialValue={venueAssignmentType}>
          <Radio.Group value={venueAssignmentType} onChange={handleRadioClick}>
            <Space direction="vertical">
              {VenueAssignmentType.LIST.map(({ value, label }) => (
                <Radio key={value} value={value}>
                  {t(label)}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
      )}
      <Form.Item
        label={t("Office")}
        name="office"
        initialValue={existingForum?.office}
        labelCol={{ span: 24, offset: 0 }}
      >
        <Select
          placeholder={t("Select Office")}
          options={Object.entries(Office).map(([value, label]) => ({ value, label: t(label) }))}
        />
      </Form.Item>
      <Form.Item
        label={t("Time Zone")}
        name="timezoneId"
        initialValue={existingForum?.timezoneId ?? "+00:00"}
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(Time Zone)" }),
          },
        ]}
      >
        <Select placeholder="Select Time Zone" loading={timezonesQuery.isLoading}>
          {!timezonesQuery.isLoading &&
            timezonesQuery.data.data.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.description}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </FullHeightForm>
  );
};

FormComponent.propTypes = {
  // isEdit, isLoading, onCancel
  ...FullHeightForm.propTypes,
  venueAssignmentType: PropTypes.oneOf(VenueAssignmentType.VALUES),
  existingForum: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onVenueAssignmentTypeChange: PropTypes.func,
};

FormComponent.defaultProps = {
  isEdit: false,
  isLoading: false,
  onCancel: () => undefined,
  onVenueAssignmentTypeChange: () => undefined,
};

export default FormComponent;
