export const BuildingLabels = [
  { title: "Building Name(Alias)", dataindex: "name" },
  { title: "Building Code", dataindex: "code" },
  { title: "Address", dataindex: "address" },
  { title: "Address Line 2", dataindex: "addressLine2" },
  { title: "City", dataindex: "city" },
  { title: "County", dataindex: "county" },
  { title: "Postcode", dataindex: "postcode" },
  { title: "Country", dataindex: "country" },
];
