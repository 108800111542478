const ItineraryRestoreErrorTypes = {
  SessionAttendeeNotAvailable: "SessionAttendeeNotAvailable",
  SessionNoSpacesAvailable: "SessionNoSpacesAvailable",
  InsufficientCapacity: "InsufficientCapacity",
  MeetingAttendeeUnavailable: "MeetingAttendeeUnavailable",
  MeetingFacilityUnavailable: "MeetingFacilityUnavailable",
  JointMeetingNoAttendeeAvailable: "JointMeetingNoAttendeeAvailable",
  JointMeetingAttendeeNotAvailable: "JointMeetingAttendeeNotAvailable",
  JointMeetingFacilityUnavailable: "JointMeetingFacilityUnavailable",
};
export default ItineraryRestoreErrorTypes;
