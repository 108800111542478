class CustomSet {
  constructor(array = []) {
    this._set = new Set();
    this._values = array;

    this.reset(array);
  }

  get size() {
    return this._set.size;
  }

  get values() {
    return this._values;
  }

  _updateValues() {
    // we do update values array manually to prevent creating new array everytime
    // in case when we pass in setter from `useState` same array once again - it won't cause re-render
    this._values = Array.from(this._set.values());
  }

  reset(array) {
    this._set.clear();
    array.forEach(entry => {
      this._set.add(entry);
    });
    this._updateValues();
  }

  add(key) {
    const sizeBefore = this._set.size;
    this._set.add(key);
    const sizeAfter = this._set.size;

    if (sizeBefore === sizeAfter) {
      return;
    }

    this._updateValues();
  }

  delete(key) {
    const sizeBefore = this._set.size;
    this._set.delete(key);
    const sizeAfter = this._set.size;

    if (sizeBefore === sizeAfter) {
      return;
    }

    this._updateValues();
  }

  has(key) {
    return this._set.has(key);
  }
}

export default CustomSet;
