import { useTimeTypeLabelMutation } from "api/mutations/itineraryBookletsForumTimeTypeLabels";
import { Input } from "components/styleguide";
import { ItineraryBookletsForumTimeTypeLabelResponseModel } from "generated/api";
import { debounce } from "lodash";
import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

interface TimeTypeLabelInputProps {
  label: string;
  forumId: number;
  labelId: number;
  onTypeLabelUpdate: (
    newLabel: ItineraryBookletsForumTimeTypeLabelResponseModel["meetingLabel"],
    labelId: ItineraryBookletsForumTimeTypeLabelResponseModel["id"],
  ) => void;
}

const TimeTypeLabelInput: FC<TimeTypeLabelInputProps> = ({ label, forumId, labelId, onTypeLabelUpdate }) => {
  const [value, setValue] = useState(label);

  const updateTimeTypeLabel = useTimeTypeLabelMutation();

  const handleTypeLabelUpdate = useCallback(
    (newValue: string) => {
      updateTimeTypeLabel.mutate(
        {
          forumId: forumId,
          timeTypeLabelId: labelId,
          itineraryBookletsForumTimeTypeLabelRequestModel: {
            meetingLabel: newValue,
          },
        },
        {
          onSuccess: () => {
            onTypeLabelUpdate(newValue, labelId);
          },
        },
      );
    },
    [forumId, labelId, updateTimeTypeLabel],
  );

  const debouncedHandleTypeLabelUpdate = useCallback(
    debounce(handleTypeLabelUpdate, 300, { leading: false, trailing: true }),
    [],
  );
  const handleTypeLabelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (value !== label) {
      debouncedHandleTypeLabelUpdate(value);
    }
  }, [value]);

  useEffect(() => {
    if (label !== value) {
      setValue(label);
    }
  }, [label]);

  return <Input maxLength={30} value={value} onChange={handleTypeLabelChange} />;
};

export default TimeTypeLabelInput;
