import { Space } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import { AttendeeModel } from "generated/api";
import moment, { Moment } from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TableColumn } from "utils/tableColumnUtils";

import { CancellationsSortOptions, DefaultSortOptions } from "../constants";
import AttendeesTableOption from "../OptionTypes/AttendeesTableOption";
import DatePickerOption from "../OptionTypes/DatePickerOption";
import SortOrderOption from "../OptionTypes/SortByOption";

interface IndividualCancellationsProps {
  sortOptions?: typeof CancellationsSortOptions | typeof DefaultSortOptions;
  showDate?: boolean;
}

const IndividualCancellationsOptions = ({
  sortOptions = CancellationsSortOptions,
  showDate = false,
}: IndividualCancellationsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Company);
  const [date, setDate] = useState<Moment>();

  const onAttendeeSelect = (attendee: AttendeeModel) => {
    navigate("report", {
      state: {
        parameters: {
          OrderBy: orderBy,
          Date: date && moment(date).format("YYYY-MM-DD"),
          ForumAttendeeId: attendee.attendId,
        },
      },
    });
  };

  return (
    <Space direction={"vertical"} size={"middle"} fullWidth>
      <Space align="end">
        <SortOrderOption options={sortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
        {showDate && <DatePickerOption date={date} setDate={setDate} />}
      </Space>

      <AttendeesTableOption
        onAttendeeSelect={onAttendeeSelect}
        additionalFilters={{ attendeeStatus: [AttendeeStatus.Cancelled, AttendeeStatus.CancelledWithFee] }}
        additionalColumns={[
          new TableColumn(t("Cancellation Date"), "cancelDate", {
            render: (cancelDate: string) => cancelDate && moment(cancelDate).format("L"),
          }),
        ]}
      />
    </Space>
  );
};

export default IndividualCancellationsOptions;
