import { Button } from "antd";
import { Collapse } from "components/styleguide";
import SelectionSiteStatusContext from "pages/SelectionSites/EditPage/context/selection-site-status";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePageId } from "utils/selectionSiteContentUtils";

import SelectionSiteDetails from "../../../Details";
import ItemsRenderer from "../ItemsRenderer";

import styles from "./styles.module.less";

const SelectionSiteContent = ({ pages, onChange }) => {
  const { t } = useTranslation();
  const [isReadOnly, setIsReadOnly] = useState(true);
  const status = useContext(SelectionSiteStatusContext);
  const isEditDisabled = status === "Open";

  const defaultOpenedItems = useMemo(() => {
    const pageIds = pages.map((page) => generatePageId(page.id));
    return ["details", ...pageIds];
  }, [pages]);

  const handleSelectionSiteEdit = (e) => {
    e.stopPropagation();
    setIsReadOnly(false);
  };

  return (
    <div data-testid="selection-site-content" className={styles.container}>
      <Collapse bordered={false} defaultActiveKey={defaultOpenedItems}>
        <Collapse.Panel
          id="details"
          header="Details"
          key="details"
          extra={isEditDisabled ? "" : <Button onClick={handleSelectionSiteEdit}>{t("Edit")}</Button>}
        >
          <SelectionSiteDetails isReadOnly={isReadOnly} setIsReadOnly={setIsReadOnly} />
        </Collapse.Panel>

        {pages.map((page, pageIndex) => {
          const pageId = generatePageId(page.id);
          const header = `${t("page")} ${pageIndex + 1}`;

          return (
            <Collapse.Panel
              className={isEditDisabled ? styles.panelDisabled : ""}
              id={pageId}
              header={header}
              key={pageId}
            >
              <ItemsRenderer
                pageId={page.id}
                items={page.items}
                onChange={(itemId, item) => onChange(page.id, itemId, item)}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

SelectionSiteContent.propTypes = {
  pages: SelectionSitesPropTypes.Pages,
  onChange: PropTypes.func,
};

SelectionSiteContent.defaultProps = {
  pages: [],
  onChange: () => undefined,
};

export default SelectionSiteContent;
