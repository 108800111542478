import { Col, Divider, Form, Input, Row, Space, Spin } from "components/styleguide";
import { SpeakerTrackingResponseModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";

import { getFormFields } from "./helpers";

const { TextArea } = Input;

interface DetailsProps {
  isEditing: boolean;
  isLoading: boolean;
  speakerTracking: SpeakerTrackingResponseModel | null;
}

const Details = ({ isEditing, isLoading, speakerTracking }: DetailsProps) => {
  const { t } = useTranslation();

  if (!speakerTracking || isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Row gutter={106}>
        {getFormFields(t, speakerTracking).map((f) => (
          <Col key={f.dataIndex} md={24} lg={12}>
            {f.title ? (
              <Form.Item initialValue={f.initialValue ?? ""} label={t(f.title)} name={f.dataIndex}>
                {isEditing ? (
                  f.component ?? <Input />
                ) : f.dataIndex === "prefferedLanguage" || f.dataIndex === "briefingCallStatus" ? (
                  <label>{f.initialValue ?? "-"}</label>
                ) : (
                  <label>{f.initialValue !== null ? (f.initialValue ? t("Yes") : t("No")) : "-"}</label>
                )}
              </Form.Item>
            ) : (
              <Space fullWidth={false} />
            )}
            {f.title && f.hasDivider !== false && <Divider />}
          </Col>
        ))}
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Form.Item initialValue={speakerTracking.notes} label={t("NOTES")} name={"notes"} labelCol={{ span: 6 }}>
            {isEditing ? (
              <TextArea rows={3} cols={6} style={{ width: "100%" }} />
            ) : (
              <label>{speakerTracking.notes ?? "-"}</label>
            )}
          </Form.Item>
          <Divider />
        </Col>
      </Row>
    </>
  );
};

export default Details;
