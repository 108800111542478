import Icon, { StarFilled } from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { TablePaginationConfig } from "antd/es/table/Table";
import { FilterValue, SorterResult, TableCurrentDataSource } from "antd/lib/table/interface";
import { Table } from "components/styleguide";
import { ForumResponseModel } from "generated/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import { useForumTableSearch } from "../helpers";

const SEARCHABLE_COLUMN_TITLES = {
  forumCode: "Forum Code",
  forumName: "Forum Name",
  location: "Location",
};

const formatDate = (date?: string) => moment(date).format("L");

export default function ForumsTable({
  count,
  isLoading,
  forums,
  pagination,
  nameSearch,
  codeSearch,
  countryCodeSearch,
  onTableChange,
  onFavoriteChange,
  setNameSearch,
  setCodeSearch,
  setCountryCodeSearch,
}: {
  count?: number;
  isLoading: boolean;
  forums?: ForumResponseModel[];
  pagination: TablePaginationConfig | undefined;
  nameSearch?: string;
  codeSearch?: string;
  countryCodeSearch?: string;
  onFavoriteChange: (value: ForumResponseModel) => void;
  onTableChange: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ForumResponseModel> | SorterResult<ForumResponseModel>[],
    extra: TableCurrentDataSource<ForumResponseModel>,
  ) => void;
  setNameSearch: (value: React.SetStateAction<string | undefined>) => void;
  setCodeSearch: (value: React.SetStateAction<string | undefined>) => void;
  setCountryCodeSearch: (value: React.SetStateAction<string | undefined>) => void;
}) {
  const { t } = useTranslation();
  const { searchFunc, resetFunc } = useForumTableSearch(setNameSearch, setCodeSearch, setCountryCodeSearch);

  const columns = [
    {
      title: "",
      dataIndex: "favorite",
      key: "favorite",
      render: (favorite: boolean, record: ForumResponseModel, index: number) => {
        const localOrange = " event-star-color-orange";
        const localBlack = " event-star-color-black";
        return (
          <span title={favorite ? "Remove from favourites" : "Add to favourites"}>
            <Icon
              key={index}
              component={StarFilled as React.ForwardRefExoticComponent<CustomIconComponentProps>}
              className={`favorite-icon ${favorite ? localOrange : localBlack} `}
              onClick={() => onFavoriteChange(record)}
            />
          </span>
        );
      },
      width: "40px",
    },
    {
      title: countryCodeSearch ? (
        <span className="filteredTitle">{countryCodeSearch}</span>
      ) : (
        SEARCHABLE_COLUMN_TITLES.location
      ),
      dataIndex: "countryCode",
      key: "countryCode",
      sorter: true,
      ...getColumnSearchProps("countryCode", searchFunc, resetFunc, "countryCode"),
      render: (text: string, record: ForumResponseModel, index: number) => (
        <div key={index}>{text || (record.isVirtual && t("Virtual"))}</div>
      ),
      width: "120px",
    },
    {
      title: codeSearch ? <span className="filteredTitle">{codeSearch}</span> : SEARCHABLE_COLUMN_TITLES.forumCode,
      dataIndex: "code",
      key: "eventCode",
      sorter: true,
      ...getColumnSearchProps("code", searchFunc, resetFunc, "code"),
      render: (code: string, record: ForumResponseModel) => (
        <Link className="forum-link" to={`forum/${record.id}/dashboard`}>
          {code}
        </Link>
      ),
    },
    {
      title: nameSearch ? <span className="filteredTitle">{nameSearch}</span> : SEARCHABLE_COLUMN_TITLES.forumName,
      dataIndex: "title",
      key: "eventName",
      sorter: true,
      ...getColumnSearchProps("name", searchFunc, resetFunc, "name"),
      render: (name: string, record: ForumResponseModel) => (
        <Link className="forum-link" to={`forum/${record.id}/dashboard`}>
          {name}
        </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "startDate",
      key: "startDate",
      sorter: true,
      render: (_: unknown, record: ForumResponseModel) => (
        <div>
          {formatDate(record.startDate)} - {formatDate(record.endDate)}
        </div>
      ),
    },
  ];

  return (
    <div className={"event-table"}>
      <div className={"event-table-results"}>{t("event.result", { count })}</div>
      <div className={"event-table-table"}>
        <Table
          rowKey="id"
          loading={isLoading}
          dataSource={forums}
          columns={columns}
          pagination={pagination}
          onChange={onTableChange}
        />
      </div>
    </div>
  );
}
