import { ItineraryBookletsForumTeamMembersApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ItineraryBookletsForumTeamMembersApi);

const ItineraryBookletsForumTeamMembers = {
  get: api.forumsForumIdItinerarybookletsTeamMembersGet,
  getUnassigned: api.forumsForumIdItinerarybookletsTeamMembersUnassignedGet,
  post: api.forumsForumIdItinerarybookletsTeamMembersPost,
  put: api.forumsForumIdItinerarybookletsTeamMembersMemberIdPut,
  delete: api.forumsForumIdItinerarybookletsTeamMembersMemberIdDelete,
  reorder: {
    put: api.forumsForumIdItinerarybookletsTeamMembersMemberIdReorderPut,
  },
};

export default ItineraryBookletsForumTeamMembers;
