import { useGetForumCompanyPackageQuery } from "api/queries/forumCompanies";
import { Spin } from "components/styleguide";
import { CompanyPackageModel } from "generated/api";
import { FC } from "react";

import ForumCompanyPackage from "./ForumCompanyPackage";
import ForumCompanyPackageForm from "./ForumCompanyPackageForm";

interface ForumCompanyPackagePanelProps {
  forumId: number;
  forumCompanyId: number;
  companyName: string;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

const ForumCompanyPackagePanel: FC<ForumCompanyPackagePanelProps> = ({
  forumId,
  forumCompanyId,
  companyName,
  isEditing,
  setIsEditing,
}) => {
  const { isLoading, data: { data: companyPackage = {} as CompanyPackageModel } = {} } = useGetForumCompanyPackageQuery(
    {
      forumId,
      forumCompanyId,
    },
  );

  if (isLoading || !companyPackage) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return isEditing ? (
    <ForumCompanyPackageForm
      forumId={forumId}
      forumCompanyId={forumCompanyId}
      companyName={companyName}
      initialValues={companyPackage}
      setIsEditing={setIsEditing}
    />
  ) : (
    <ForumCompanyPackage companyPackage={companyPackage} companyName={companyName} />
  );
};

export default ForumCompanyPackagePanel;
