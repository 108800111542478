import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "../../../../../../../components/styleguide";
import { ConferenceRankV2Item } from "../../../../../../../generated/api";
import DefaultForm from "../DefaultForm";
import { SelectionSiteItemSelect, YesNo } from "../inputs";

interface ConferenceRankV2Props {
  forumId: number;
  selectionSiteId: number;
  item: ConferenceRankV2Item;
  onChange: (item: ConferenceRankV2Item) => void;
}

const ConferenceRankV2: FC<ConferenceRankV2Props> = ({ forumId, selectionSiteId, item, onChange }) => {
  const { t } = useTranslation();
  const handleChange = (values: Partial<ConferenceRankV2Item>) => {
    const newValues = { ...item, ...values };

    return onChange(newValues);
  };

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      onChange={handleChange}
      options={() => (
        <>
          <Form.Item name="rankedItemId" label={t("Set the conference step for ranking")}>
            <SelectionSiteItemSelect
              forumId={forumId}
              selectionSiteId={selectionSiteId}
              types={["ConferenceSelectionV2", "TabbedConferenceSelection"]}
            />
          </Form.Item>
          <Form.Item name="displayLocalisedLabels" label={t("Display localized labels")}>
            <YesNo />
          </Form.Item>
        </>
      )}
    />
  );
};

export default ConferenceRankV2;
