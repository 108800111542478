import AttendeeGroupMembershipsTable from "./AttendeeGroupMembershipsTable";

interface AttendeeGroupMembershipsPanelProps {
  forumId: number;
  attendeeId: number;
}

export default function AttendeeGroupMembershipsPanel({ forumId, attendeeId }: AttendeeGroupMembershipsPanelProps) {
  return (
    <div>
      <AttendeeGroupMembershipsTable forumId={forumId} attendeeId={attendeeId} />
    </div>
  );
}
