import { UserInfoModel } from "generated/api";
import { useMemo } from "react";

import { useUserInfoQuery } from "../../../api/queries/user";
import UserRole from "../class";

const useRole = () => {
  const userInfoRequest = useUserInfoQuery({});

  return useMemo(() => {
    return new UserRole(userInfoRequest.data?.data as UserInfoModel);
  }, [userInfoRequest.data]);
};

export default useRole;
