import PropTypes from "prop-types";
import React, { createContext, createElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Drawer } from "../../../../../../../../components/styleguide";

const Context = createContext({
  isShown: false,
  show: () => undefined,
  hide: () => undefined,
});

const Setup = ({ formTitle, value, form, onChange }) => {
  const { t } = useTranslation();

  const [isDrawerShown, setIsDrawerShown] = useState(false);

  const showDrawer = () => setIsDrawerShown(true);
  const hideDrawer = () => setIsDrawerShown(false);

  return (
    <>
      <Button type="primary" onClick={showDrawer}>
        {t("Set up this item")}
      </Button>
      <Drawer open={isDrawerShown} placement="right" title={formTitle} destroyOnClose width="100%" onClose={hideDrawer}>
        <Context.Provider
          value={{
            isShown: isDrawerShown,
            show: showDrawer,
            hide: hideDrawer,
          }}
        >
          {createElement(form?.element, {
            value,
            onChange,
            ...form?.props,
          })}
        </Context.Provider>
      </Drawer>
    </>
  );
};

Setup.propTypes = {
  formTitle: PropTypes.string.isRequired,
  form: PropTypes.shape({
    element: PropTypes.elementType,
    props: PropTypes.object,
  }),
  value: PropTypes.any,
  onChange: PropTypes.func,
};

Setup.defaultProps = {
  value: undefined,
  form: undefined,
  onChange: () => undefined,
};

Setup.DrawerContext = Context;

export default Setup;
