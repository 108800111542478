import { ForumAttendeeSessionMeetingLocationApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeSessionMeetingLocationApi);

const ForumAttendeeSessionMeetingLocations = {
  get: api.forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet,
  put: api.forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut,
  getAll: api.forumsForumIdAttendeesSessionMeetingLocationsGet,
};

export default ForumAttendeeSessionMeetingLocations;
