import { useQueryClient } from "@tanstack/react-query";
import { useDeleteForumSeminarTypeMutation } from "api/mutations/seminarTypes";
import KEYS from "api/queries/keys";
import { useSeminarsQuery } from "api/queries/seminars";
import { useSeminarTypesQuery } from "api/queries/seminarTypes";
import { Button, Col, Modal, Row, Table } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import UserGuide from "components/UserGuide";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import useCustomHandleSort from "hooks/useCustomHandleSort";
import { toNumber } from "lodash-es";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AddSeminarType from "../Edit";

const disabledCodes = ["F", "G", "K", "Z"];

export default function SeminarTypes() {
  const { t } = useTranslation();

  const forumId = useParams().id;

  const [seminarTypes, setSeminarTypes] = useState([]);
  const [seminars, setSeminars] = useState([]);
  const [isAddSeminarVisible, setIsAddSeminarVisible] = useState(false);

  const [activeSeminarType, setActiveSeminarType] = useState(null);

  const showAddSeminarType = () => setIsAddSeminarVisible(true);

  const deleteMutation = useDeleteForumSeminarTypeMutation();
  const queryClient = useQueryClient();

  const { handleSort, getSortOrder, sort, setSort } = useCustomHandleSort("code", "asc");

  const { response: query } = useModernQueryWithPaginationAndOrder(
    useSeminarTypesQuery,
    {
      onSuccess: ({ data }) => {
        setSeminarTypes(data);
      },
    },
    { forumId, OrderBy: sort.orderBy, OrderDir: sort.orderDir },
  );

  useSeminarsQuery(
    { forumId },
    {
      onSuccess: ({ data }) => {
        setSeminars(data);
      },
    },
  );

  const hideAddSeminarType = () => {
    setActiveSeminarType(null);
    setIsAddSeminarVisible(false);
  };

  const editSeminar = useCallback((seminarType) => {
    setActiveSeminarType(seminarType);
    showAddSeminarType(true);
  }, []);

  const deleteSeminar = useCallback((seminarType) => {
    if (disabledCodes.includes(seminarType.code)) {
      Modal.error({
        title: t("Cannot delete Seminar Type"),
        content: t("Cannot delete Seminar Types:  F, G, K ,or Z"),
        okText: t("OK"),
      });
    } else if (seminars.findIndex((s) => s.forumSeminarTypeId === seminarType.id) > 0) {
      Modal.error({
        title: t("Cannot delete Seminar Type"),
        content: t(
          "Seminar Type is attached to a Seminar and cannot be deleted. Remove any Seminars before you can continue",
        ),
        okText: t("OK"),
      });
    } else {
      Modal.confirm({
        title: t("Confirm Seminar Delete"),
        content: t("Are you sure you want to delete this Seminar Type?"),
        okText: t("Delete"),
        okButtonProps: { loading: deleteMutation.isLoading, disabled: deleteMutation.isLoading },
        okType: "danger",
        cancelText: t("Cancel"),
        onOk: () => {
          deleteMutation.mutate(
            { forumId: toNumber(forumId), forumSeminarTypeId: seminarType.id },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([KEYS.GET_SEMINAR_TYPES]);
              },
            },
          );
        },
      });
    }
  });

  const columns = useMemo(
    () => [
      {
        title: t("Code"),
        dataIndex: "code",
        sorter: true,
        ...getSortOrder("code"),
      },
      {
        title: t("Type"),
        dataIndex: "type",
        sorter: true,
        ...getSortOrder("type"),
      },
      {
        title: t("Description"),
        dataIndex: "description",
        sorter: true,
        ...getSortOrder("description"),
      },
      {
        dataIndex: "id",
        render: (_, record) => (
          <TableRowActionButtons onEdit={() => editSeminar(record)} onDelete={() => deleteSeminar(record)} />
        ),
      },
    ],
    [editSeminar, t, deleteSeminar, getSortOrder],
  );

  return (
    <div className="table-wrapper">
      <AddSeminarType
        existingSeminarTypes={seminarTypes}
        visible={isAddSeminarVisible}
        onClose={hideAddSeminarType}
        seminarType={activeSeminarType}
        setSort={setSort}
      />
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserGuide.Title title="Seminar Types" articleId="16426038186013-seminar-types" />
        </Col>
        <Col span={24}>
          <Button onClick={showAddSeminarType}>{t("Add New Seminar Type")}</Button>
        </Col>
        <Col span={24}>
          <Table
            id="seminarTypesTable"
            dataSource={seminarTypes}
            columns={columns}
            loading={query.isLoading}
            pagination={false}
            onChange={handleSort}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
}
