import { ForumFeedbackApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumFeedbackApi);

const ForumFeedback = {
  export: {
    get: api.forumForumIdFeedbackExportGet,
  },
};

export default ForumFeedback;
