import { Menu as AntdMenu } from "antd";

import Label from "./components/Label";
import useOpenKeys from "./hooks/useOpenKeys";

type AntdMenuType = typeof AntdMenu;
interface MenuType extends AntdMenuType {
  ItemLabel: typeof Label;

  useOpenKeys: typeof useOpenKeys;
}

// We will add the missing properties to the Menu component just at the bottom of this file.
//
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Menu: MenuType = AntdMenu;

Menu.ItemLabel = Label;
Menu.useOpenKeys = useOpenKeys;

export default Menu;
