import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useAttendeeRemovedItinerariesQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_REMOVED_ITINERARIES,
  apiClient.ForumAttendeeItinerary.removedItineraries.get,
);

export const useAttendeeRemovedItinerariesDocumentQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_REMOVED_ITINERARIES_DOCUMENT,
  apiClient.ForumAttendeeItinerary.removedItineraries.document.get,
);
