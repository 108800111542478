import nextTick from "./nextTickUtils";

type NormFileEvent = {
  fileList: FileList;
};

export const MAX_FILE_SIZE = 1024 * 1024 * 100; // 100MB
export const normFile = (event: NormFileEvent) => {
  if (Array.isArray(event)) {
    return event;
  }
  return event?.fileList;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleFileFakeRequest = async (options: any) => {
  await nextTick();
  options.onSuccess(true);
};
