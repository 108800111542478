import { Typography } from "components/styleguide";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import SelectionSiteItemType from "utils/selectionSiteItemTypeUtils";

import ConferenceRankV2 from "./components/ConferenceRankV2";
import ConferenceSelectionV2 from "./components/ConferenceSelectionV2";
import EventOptions from "./components/EventOptions";
import FreeText from "./components/FreeText";
import Joint from "./components/Joint";
import NoOptions from "./components/NoOptions";
import PositiveSelectionV2 from "./components/PositiveSelectionV2";
import ReasonForMeeting from "./components/ReasonForMeeting";
import TabbedConferenceSelection from "./components/TabbedConferenceSelection";

const ItemEditRenderer = ({ item, onChange }) => {
  const params = useParams();
  const { id: forumId } = useOutletContext();
  const selectionSiteId = parseInt(params.selectionSiteId);

  const { t } = useTranslation();
  const itemType = useMemo(() => new SelectionSiteItemType(item.type), [item.type]);

  const handleItemChange = (newItem) => {
    return onChange({ ...item, ...newItem });
  };

  if (itemType.isFreeText) {
    return <FreeText item={item} onChange={handleItemChange} />;
  }

  // Because NegativeSelection is a full copy from business logic point of view
  // we can use PositiveSelectionV2 component for both PositiveV2 and Negative item types
  if (itemType.isPositiveSelectionV2 || itemType.isNegativeSelection) {
    return <PositiveSelectionV2 forumId={forumId} item={item} onChange={handleItemChange} />;
  }

  if (itemType.isReasonForMeeting || itemType.isReasonForMeetingV2 || itemType.isReasonForMeetingV3) {
    return (
      <ReasonForMeeting forumId={forumId} selectionSiteId={selectionSiteId} item={item} onChange={handleItemChange} />
    );
  }

  if (itemType.isConferenceSelectionV2) {
    return <ConferenceSelectionV2 forumId={forumId} item={item} onChange={handleItemChange} />;
  }

  if (itemType.isTabbedConferenceSelection) {
    return <TabbedConferenceSelection forumId={forumId} item={item} onChange={handleItemChange} />;
  }

  if (itemType.isConferenceRankV2) {
    return (
      <ConferenceRankV2 forumId={forumId} selectionSiteId={selectionSiteId} item={item} onChange={handleItemChange} />
    );
  }

  if (itemType.isJoint) {
    return <Joint forumId={forumId} selectionSiteId={selectionSiteId} item={item} onChange={handleItemChange} />;
  }

  if (itemType.isEventOptions) {
    return <EventOptions forumId={forumId} item={item} onChange={handleItemChange} />;
  }

  if (itemType.isAvailability || itemType.isSubmittingSelections) {
    return <NoOptions item={item} onChange={handleItemChange} />;
  }

  return <Typography.Text>{t("Sorry, this item type can't be displayed yet.")}</Typography.Text>;
};

ItemEditRenderer.propTypes = {
  item: SelectionSitesPropTypes.Item.ANY.isRequired,
  onChange: PropTypes.func,
};

ItemEditRenderer.defaultProps = {
  onChange: () => undefined,
};

export default ItemEditRenderer;
