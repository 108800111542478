import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateAttendeePreferencesMeetingMutation,
  useUpdateAttendeePreferencesMeetingMutation,
} from "api/mutations/attendees";
import KEYS from "api/queries/keys";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, InputNumber, Select } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  useAttendeePreferencesBlockedMeetingsQuery,
  useAttendeesQuery,
  useGetAttendeeReasonsForMeetingQuery,
} from "../../../../../api/queries/attendees";
import { AttendeeModel, MeetingPreferenceResponseModel } from "../../../../../generated/api";
import { buildAttendeeColumn } from "../common/utils";

interface EditMeetingDrawerProps {
  forumId: number;
  attendeeId: number;
  activeMeeting: MeetingPreferenceResponseModel | null;
  isVisible: boolean;
  onClose: () => void;
}

const AddOrEditMeetingDrawer: React.FC<EditMeetingDrawerProps> = ({
  forumId,
  attendeeId,
  activeMeeting,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { TextArea } = Input;

  const [selectedAttendeeToMeetWith, setSelectedAttendeeToMeetWith] = useState<number>();
  const [selectedAttendeeToMakeJointWith, setSelectedAttendeeToMakeJointWith] = useState<number>();

  const updateAttendeePreferencesMutation = useUpdateAttendeePreferencesMeetingMutation();

  const createAttendeePreferenceMutation = useCreateAttendeePreferencesMeetingMutation();

  const { data: { data: reasonsForMeeting = [] } = {}, isLoading: isLoadingReasonsForMeeting } =
    useGetAttendeeReasonsForMeetingQuery({
      forumId,
      attendeeId,
    });

  const { data: { data: { items: attendees = [] } = {} } = {}, isLoading: isLoadingAttendees } = useAttendeesQuery({
    forumId,
  });

  const { data: { data: { items: blockedMeetings = [] } = {} } = {}, isLoading: isLoadingBlockedMeetings } =
    useAttendeePreferencesBlockedMeetingsQuery({
      forumId,
      attendeeId,
    });

  const reasonsForMeetingOptions = reasonsForMeeting.map(({ reason }) => ({ label: reason, value: reason }));

  // filter attendees that are in blocked meetings
  const filteredAttendees = attendees?.filter((a: AttendeeModel) => {
    return a.attendId !== toNumber(attendeeId) && !blockedMeetings?.some((b) => a.attendId === b.attendeeIdNotToMeet);
  });

  const getAttendeesOptions = () => {
    return filteredAttendees
      ?.map((a: AttendeeModel) => {
        return {
          label: buildAttendeeColumn(
            [a.firstName, a.lastName].join(" "),
            a.participationCode,
            a.jobTitle,
            a.forumCompany,
          ),
          value: a.attendId,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const filteredOptions = (alreadySelectedAttendee?: number) =>
    getAttendeesOptions()?.filter((option) => option.value !== alreadySelectedAttendee);

  const handleSelectAttendeeToMeetWith = (value: number) => {
    setSelectedAttendeeToMeetWith(value);
  };

  const handleSelectAttendeeToMakeJointWith = (value: number) => {
    setSelectedAttendeeToMakeJointWith(value);
  };

  const isLoading = isLoadingAttendees || isLoadingBlockedMeetings;

  const filterOptions = (input: string, option?: { label: string; value: number }) => {
    const name = option?.label.split(",")[0].toLowerCase();
    const searchValueText = input.toLowerCase();
    if (name) {
      return name?.indexOf(searchValueText) >= 0;
    }
    return false;
  };

  const preferenceMutationOptions = {
    onSuccess: () => {
      queryClient.resetQueries([KEYS.GET_ATTENDEE_PREFERENCES_MEETINGS]);
      queryClient.resetQueries([KEYS.GET_ATTENDEE_HISTORY_INFORMATION]);

      onClose();
    },
    onError: ({ response }: any) => {
      if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
        const {
          RelatedItemType,
          ItemFields: { Priority, AttendeeToMeetWithId },
        } = response.data.meta;

        if (Priority) {
          toast.error(t("Priority must be unique for this meeting"));
        } else if (RelatedItemType === "ForumAttendeeBlockedMeetingPreference") {
          toast.error(
            t("Cannot have a positive meeting preference and a blocked meeting preference for the same person"),
          );
        } else if (RelatedItemType === "ForumAttendee" && AttendeeToMeetWithId) {
          toast.error(t("Cannot have multiple preferences for the same attendee"));
        } else {
          toast.error(t("Duplicate field found for this meeting"));
        }
      }

      if (ErrorTypes.isOfType(response, ErrorTypes.FieldsCannotBeEqual)) {
        toast.error(t("Attendee to meet with cannot be the same as attendee to make joint with"));
      }
    },
  };

  const submit = (values: MeetingPreferenceResponseModel) => {
    if (activeMeeting) {
      updateAttendeePreferencesMutation.mutate(
        {
          forumId,
          attendeeId,
          id: activeMeeting.id,
          meetingPreferenceRequestModel: values,
        },
        preferenceMutationOptions,
      );
    } else {
      createAttendeePreferenceMutation.mutate(
        {
          forumId,
          attendeeId,
          meetingPreferenceRequestModel: values,
        },
        preferenceMutationOptions,
      );
    }
  };

  return (
    <Drawer
      title={t(`${activeMeeting ? "Edit" : "Add"} Meeting Preference`)}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose
    >
      <FullHeightForm
        onFinish={submit}
        layout="vertical"
        className="edit-meeting-preference-form"
        actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
        actionsAppend={
          <Button
            htmlType="submit"
            type="primary"
            loading={createAttendeePreferenceMutation.isLoading || updateAttendeePreferencesMutation.isLoading}
          >
            {activeMeeting ? t("Update") : t("Add")}
          </Button>
        }
      >
        <Form.Item
          label={t("Priority")}
          name="priority"
          initialValue={activeMeeting?.priority}
          wrapperCol={{ style: { width: "100%" } }}
          extra={t("Priority must be unique and between 1 and 1000")}
          rules={[{ required: true, message: t("errors.required", { prop: "$t(Priority)" }) }]}
        >
          <InputNumber min={1} max={1000} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label={t("Attendee to meet with")}
          initialValue={activeMeeting?.attendeeToMeetWithId}
          name="attendeeToMeetWithId"
          rules={[{ required: true, message: t("errors.required", { prop: "$t(Attendee to meet with)" }) }]}
        >
          <Select
            showSearch
            filterOption={filterOptions}
            loading={isLoading}
            disabled={isLoading}
            onChange={handleSelectAttendeeToMeetWith}
            options={filteredOptions(selectedAttendeeToMakeJointWith)}
          />
        </Form.Item>

        <Form.Item
          label={t("Attendee to make joint with")}
          initialValue={activeMeeting?.attendeeToMakeJointWithId}
          name="attendeeToMakeJointWithId"
        >
          <Select
            showSearch
            filterOption={filterOptions}
            loading={isLoading}
            disabled={isLoading}
            onChange={handleSelectAttendeeToMakeJointWith}
            options={filteredOptions(selectedAttendeeToMeetWith)}
            allowClear
          />
        </Form.Item>

        <Form.Item
          label={t("Reason for meeting")}
          initialValue={activeMeeting?.reasonForMeeting}
          name="reasonForMeeting"
        >
          <Select
            showSearch
            allowClear
            options={reasonsForMeetingOptions}
            loading={isLoadingReasonsForMeeting}
            disabled={isLoadingReasonsForMeeting}
          />
        </Form.Item>

        <Form.Item label={t("Reason Details")} initialValue={activeMeeting?.reasonDetails} name="reasonDetails">
          <TextArea rows={6} cols={6} />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default AddOrEditMeetingDrawer;
