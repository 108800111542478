import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import useFormInstance from "antd/lib/form/hooks/useFormInstance";
import { isEmpty, uniq } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Input } from "../../../../../../../components/styleguide";

const OptionsFormItem = ({ name, getOptionsPlaceholder }) => {
  const { t } = useTranslation();

  const [duplicateValues, setDuplicateValues] = useState([]);

  const form = useFormInstance();

  const validateDuplicateOptions = useCallback(() => {
    const options = form.getFieldValue("options");
    const optionsValues = options.filter((option) => !!option).map((x) => x.text);

    const duplicates = uniq(optionsValues.filter((item, index) => optionsValues.indexOf(item) !== index));
    setDuplicateValues(duplicates);
  }, [form]);

  return (
    <Form.List
      name={name}
      rules={[
        // actual validation of no duplicate options:
        {
          validator: async () => {
            validateDuplicateOptions();
            return isEmpty(duplicateValues) ? Promise.resolve() : Promise.reject();
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <Form.Item>
            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
              {t("Add new item")}
            </Button>
          </Form.Item>
          <Form.ErrorList
            errors={[...errors, ...duplicateValues.map((x) => t("Duplicate value: {{value}}", { value: x }))]}
          />

          {fields.map(({ name, ...restField }, index) => (
            <Form.Item required={false} key={restField.key}>
              <Form.Item
                {...restField}
                name={[name, "text"]}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    required: true,
                    message: t("Item's text can't be empty"),
                  },
                  // this rule exists so that every onChange and onBlur action on the Form.Items
                  // will trigger the validation from Form.List and display the error messages:
                  {
                    validator: async () => {
                      validateDuplicateOptions();
                      return Promise.resolve();
                    },
                  },
                ]}
                noStyle
              >
                <Input
                  placeholder={getOptionsPlaceholder(index)}
                  addonAfter={fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)} />}
                />
              </Form.Item>
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>
  );
};

OptionsFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  getOptionsPlaceholder: PropTypes.func.isRequired,
};

export default OptionsFormItem;
