import { HomeOutlined } from "@ant-design/icons";
import { NavBar, Space } from "components/styleguide/mobile";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";

import styles from "./styles.module.less";

export default function PlaybookLayout() {
  const { lang, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [title, setTitle] = useState<string>(t("Playbook"));
  const [title2, setTitle2] = useState<string | null>();

  const forumId = toNumber(id);

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <NavBar
        onBack={onBack}
        className={styles.navbar}
        right={
          <Link to={`/${lang}/forum/${forumId}/dashboard`}>
            <HomeOutlined style={{ fontSize: "x-large", color: "white" }} />
          </Link>
        }
      >
        <Space direction="vertical">
          <span>{title}</span>
          {title2 && <span>{title2}</span>}
        </Space>
      </NavBar>
      <div className={styles.page}>
        <Outlet context={{ setTitle, setTitle2 }} />
      </div>
    </Space>
  );
}
