import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { HolderOutlined } from "@ant-design/icons";

export default function DraggableRow({
  style,
  dragIcon = <HolderOutlined />,
  ...props
}) {
  const rowKey = props["data-row-key"];
  const { attributes, listeners, setNodeRef, isDragging, overIndex, index } =
    useSortable({
      id: rowKey,
    });
  const isOver = overIndex === index;
  const { children, ...restProps } = props;
  const isData = children instanceof Array;
  return (
    <tr key={rowKey} ref={setNodeRef} {...attributes} style={style}>
      {isData ? (
        children.map((child) => {
          const { children, key, ...restProps } = child;
          return key === "dragHandle" ? (
            <td key={key} {...listeners}>
              {dragIcon}
            </td>
          ) : (
            child
          );
        })
      ) : (
        <></>
      )}
    </tr>
  );
}
