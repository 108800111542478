import { Col, Row } from "components/styleguide";
import React, { FC } from "react";

import AddMapping from "./AddMapping";
import MappingsTable from "./MappingsTable";

const MappingWebinarsToSessions: FC = () => {
  return (
    <Row gutter={[0, 25]}>
      <Col span={24}>
        <AddMapping />
      </Col>
      <Col span={24}>
        <MappingsTable />
      </Col>
    </Row>
  );
};

export default MappingWebinarsToSessions;
