/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EventOptionsChoiceModel } from '../apiModels';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ReferencedObjectCannotBeDeletedExceptionResponse } from '../apiModels';
// @ts-ignore
import { SelectionSiteContent } from '../apiModels';
// @ts-ignore
import { SelectionSiteContentItem } from '../apiModels';
// @ts-ignore
import { SelectionSiteListItemModel } from '../apiModels';
// @ts-ignore
import { SelectionSiteModel } from '../apiModels';
// @ts-ignore
import { SelectionSiteReorderModel } from '../apiModels';
// @ts-ignore
import { SelectionSiteRequestModel } from '../apiModels';
// @ts-ignore
import { SelectionSiteStatusRequestModel } from '../apiModels';
// @ts-ignore
import { ValueOutOfRangeExceptionResponse } from '../apiModels';
/**
 * ForumSelectionSiteApi - axios parameter creator
 * @export
 */
export const ForumSelectionSiteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesGet: async (forumId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/selection-sites`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a selection site
         * @param {number} forumId 
         * @param {SelectionSiteRequestModel} [selectionSiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesPost: async (forumId: number, selectionSiteRequestModel?: SelectionSiteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/selection-sites`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentGet: async (forumId: number, selectionSiteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentGet', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentGet', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete: async (forumId: number, selectionSiteId: number, pageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete', 'selectionSiteId', selectionSiteId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete', 'pageId', pageId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages/{pageId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an item inside a selection site and unlinks the dependencies associated to it
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete: async (forumId: number, selectionSiteId: number, pageId: number, itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete', 'selectionSiteId', selectionSiteId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete', 'pageId', pageId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete', 'itemId', itemId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages/{pageId}/items/{itemId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move (reorder) an item inside a selection site page
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {number} itemId 
         * @param {SelectionSiteReorderModel} [selectionSiteReorderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut: async (forumId: number, selectionSiteId: number, pageId: number, itemId: number, selectionSiteReorderModel?: SelectionSiteReorderModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut', 'selectionSiteId', selectionSiteId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut', 'pageId', pageId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut', 'itemId', itemId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages/{pageId}/items/{itemId}/order`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteReorderModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {number} itemId 
         * @param {SelectionSiteContentItem} [selectionSiteContentItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut: async (forumId: number, selectionSiteId: number, pageId: number, itemId: number, selectionSiteContentItem?: SelectionSiteContentItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut', 'selectionSiteId', selectionSiteId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut', 'pageId', pageId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut', 'itemId', itemId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages/{pageId}/items/{itemId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteContentItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {SelectionSiteContentItem} [selectionSiteContentItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost: async (forumId: number, selectionSiteId: number, pageId: number, selectionSiteContentItem?: SelectionSiteContentItem, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost', 'selectionSiteId', selectionSiteId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost', 'pageId', pageId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages/{pageId}/items`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteContentItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move (reorder) a page inside a selection site
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {SelectionSiteReorderModel} [selectionSiteReorderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut: async (forumId: number, selectionSiteId: number, pageId: number, selectionSiteReorderModel?: SelectionSiteReorderModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut', 'selectionSiteId', selectionSiteId)
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut', 'pageId', pageId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages/{pageId}/order`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)))
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteReorderModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPost: async (forumId: number, selectionSiteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPost', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPagesPost', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content/pages`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {SelectionSiteContent} [selectionSiteContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPut: async (forumId: number, selectionSiteId: number, selectionSiteContent?: SelectionSiteContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPut', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdContentPut', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/content`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a given selection site
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdDelete: async (forumId: number, selectionSiteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdDelete', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdDelete', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdExportGet: async (forumId: number, selectionSiteId: number, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdExportGet', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdExportGet', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a selection site\'s details
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdGet: async (forumId: number, selectionSiteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdGet', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdGet', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a Selection Site\'s details
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {SelectionSiteRequestModel} [selectionSiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdPut: async (forumId: number, selectionSiteId: number, selectionSiteRequestModel?: SelectionSiteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdPut', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdPut', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the status of a given selection site
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {SelectionSiteStatusRequestModel} [selectionSiteStatusRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdStatusPut: async (forumId: number, selectionSiteId: number, selectionSiteStatusRequestModel?: SelectionSiteStatusRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdStatusPut', 'forumId', forumId)
            // verify required parameter 'selectionSiteId' is not null or undefined
            assertParamExists('forumForumIdSelectionSitesSelectionSiteIdStatusPut', 'selectionSiteId', selectionSiteId)
            const localVarPath = `/forum/{forumId}/selection-sites/{selectionSiteId}/status`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"selectionSiteId"}}`, encodeURIComponent(String(selectionSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(selectionSiteStatusRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get predefined event choices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumSelectionSitesPredefinedEventchoicesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forum/selection-sites/predefined/eventchoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumSelectionSiteApi - functional programming interface
 * @export
 */
export const ForumSelectionSiteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumSelectionSiteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesGet(forumId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SelectionSiteListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesGet(forumId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a selection site
         * @param {number} forumId 
         * @param {SelectionSiteRequestModel} [selectionSiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesPost(forumId: number, selectionSiteRequestModel?: SelectionSiteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesPost(forumId, selectionSiteRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentGet(forumId: number, selectionSiteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentGet(forumId, selectionSiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete(forumId: number, selectionSiteId: number, pageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete(forumId, selectionSiteId, pageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an item inside a selection site and unlinks the dependencies associated to it
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete(forumId: number, selectionSiteId: number, pageId: number, itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete(forumId, selectionSiteId, pageId, itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move (reorder) an item inside a selection site page
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {number} itemId 
         * @param {SelectionSiteReorderModel} [selectionSiteReorderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut(forumId: number, selectionSiteId: number, pageId: number, itemId: number, selectionSiteReorderModel?: SelectionSiteReorderModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut(forumId, selectionSiteId, pageId, itemId, selectionSiteReorderModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {number} itemId 
         * @param {SelectionSiteContentItem} [selectionSiteContentItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut(forumId: number, selectionSiteId: number, pageId: number, itemId: number, selectionSiteContentItem?: SelectionSiteContentItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut(forumId, selectionSiteId, pageId, itemId, selectionSiteContentItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {SelectionSiteContentItem} [selectionSiteContentItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost(forumId: number, selectionSiteId: number, pageId: number, selectionSiteContentItem?: SelectionSiteContentItem, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost(forumId, selectionSiteId, pageId, selectionSiteContentItem, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Move (reorder) a page inside a selection site
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {number} pageId 
         * @param {SelectionSiteReorderModel} [selectionSiteReorderModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut(forumId: number, selectionSiteId: number, pageId: number, selectionSiteReorderModel?: SelectionSiteReorderModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut(forumId, selectionSiteId, pageId, selectionSiteReorderModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPagesPost(forumId: number, selectionSiteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPagesPost(forumId, selectionSiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {SelectionSiteContent} [selectionSiteContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdContentPut(forumId: number, selectionSiteId: number, selectionSiteContent?: SelectionSiteContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdContentPut(forumId, selectionSiteId, selectionSiteContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a given selection site
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdDelete(forumId: number, selectionSiteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdDelete(forumId, selectionSiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdExportGet(forumId: number, selectionSiteId: number, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdExportGet(forumId, selectionSiteId, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a selection site\'s details
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdGet(forumId: number, selectionSiteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdGet(forumId, selectionSiteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a Selection Site\'s details
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {SelectionSiteRequestModel} [selectionSiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdPut(forumId: number, selectionSiteId: number, selectionSiteRequestModel?: SelectionSiteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectionSiteModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdPut(forumId, selectionSiteId, selectionSiteRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the status of a given selection site
         * @param {number} forumId 
         * @param {number} selectionSiteId 
         * @param {SelectionSiteStatusRequestModel} [selectionSiteStatusRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdSelectionSitesSelectionSiteIdStatusPut(forumId: number, selectionSiteId: number, selectionSiteStatusRequestModel?: SelectionSiteStatusRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdSelectionSitesSelectionSiteIdStatusPut(forumId, selectionSiteId, selectionSiteStatusRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get predefined event choices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumSelectionSitesPredefinedEventchoicesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EventOptionsChoiceModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumSelectionSitesPredefinedEventchoicesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumSelectionSiteApi - factory interface
 * @export
 */
export const ForumSelectionSiteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumSelectionSiteApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SelectionSiteListItemModel>> {
            return localVarFp.forumForumIdSelectionSitesGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a selection site
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesPost(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesPostRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteModel> {
            return localVarFp.forumForumIdSelectionSitesPost(requestParameters.forumId, requestParameters.selectionSiteRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGetRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentGet(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an item inside a selection site and unlinks the dependencies associated to it
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move (reorder) an item inside a selection site page
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPutRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.itemId, requestParameters.selectionSiteReorderModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.itemId, requestParameters.selectionSiteContentItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPostRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.selectionSiteContentItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Move (reorder) a page inside a selection site
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPutRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.selectionSiteReorderModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPagesPost(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPostRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPagesPost(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdContentPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPutRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteContent> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdContentPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.selectionSiteContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a given selection site
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdDelete(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdDelete(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdExportGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdExportGet(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a selection site\'s details
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteModel> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdGet(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a Selection Site\'s details
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<SelectionSiteModel> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.selectionSiteRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the status of a given selection site
         * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdSelectionSitesSelectionSiteIdStatusPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdSelectionSitesSelectionSiteIdStatusPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.selectionSiteStatusRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get predefined event choices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumSelectionSitesPredefinedEventchoicesGet(options?: AxiosRequestConfig): AxiosPromise<Array<EventOptionsChoiceModel>> {
            return localVarFp.forumSelectionSitesPredefinedEventchoicesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdSelectionSitesGet operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesGetRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdSelectionSitesPost operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesPostRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {SelectionSiteRequestModel}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesPost
     */
    readonly selectionSiteRequestModel?: SelectionSiteRequestModel
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentGet operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGetRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGet
     */
    readonly selectionSiteId: number
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDeleteRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete
     */
    readonly pageId: number
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDeleteRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete
     */
    readonly pageId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete
     */
    readonly itemId: number
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPutRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut
     */
    readonly pageId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut
     */
    readonly itemId: number

    /**
     * 
     * @type {SelectionSiteReorderModel}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut
     */
    readonly selectionSiteReorderModel?: SelectionSiteReorderModel
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPutRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut
     */
    readonly pageId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut
     */
    readonly itemId: number

    /**
     * 
     * @type {SelectionSiteContentItem}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut
     */
    readonly selectionSiteContentItem?: SelectionSiteContentItem
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPostRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost
     */
    readonly pageId: number

    /**
     * 
     * @type {SelectionSiteContentItem}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost
     */
    readonly selectionSiteContentItem?: SelectionSiteContentItem
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPutRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut
     */
    readonly pageId: number

    /**
     * 
     * @type {SelectionSiteReorderModel}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut
     */
    readonly selectionSiteReorderModel?: SelectionSiteReorderModel
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPagesPost operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPostRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPost
     */
    readonly selectionSiteId: number
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdContentPut operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPutRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPut
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {SelectionSiteContent}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPut
     */
    readonly selectionSiteContent?: SelectionSiteContent
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdDelete operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDeleteRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDelete
     */
    readonly selectionSiteId: number
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdExportGet operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGetRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGet
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {FileExportType}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdGet operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGetRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGet
     */
    readonly selectionSiteId: number
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdPut operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPutRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPut
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {SelectionSiteRequestModel}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPut
     */
    readonly selectionSiteRequestModel?: SelectionSiteRequestModel
}

/**
 * Request parameters for forumForumIdSelectionSitesSelectionSiteIdStatusPut operation in ForumSelectionSiteApi.
 * @export
 * @interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPutRequest
 */
export interface ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPut
     */
    readonly selectionSiteId: number

    /**
     * 
     * @type {SelectionSiteStatusRequestModel}
     * @memberof ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPut
     */
    readonly selectionSiteStatusRequestModel?: SelectionSiteStatusRequestModel
}

/**
 * ForumSelectionSiteApi - object-oriented interface
 * @export
 * @class ForumSelectionSiteApi
 * @extends {BaseAPI}
 */
export class ForumSelectionSiteApi extends BaseAPI {
    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesGetRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a selection site
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesPost(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesPostRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesPost(requestParameters.forumId, requestParameters.selectionSiteRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentGetRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentGet(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdDelete(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an item inside a selection site and unlinks the dependencies associated to it
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdDelete(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move (reorder) an item inside a selection site page
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPutRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdOrderPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.itemId, requestParameters.selectionSiteReorderModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPutRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsItemIdPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.itemId, requestParameters.selectionSiteContentItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPostRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdItemsPost(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.selectionSiteContentItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Move (reorder) a page inside a selection site
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPutRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPageIdOrderPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.pageId, requestParameters.selectionSiteReorderModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPagesPost(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPagesPostRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPagesPost(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdContentPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdContentPutRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdContentPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.selectionSiteContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a given selection site
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdDelete(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdDelete(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdExportGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdExportGetRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdExportGet(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a selection site\'s details
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdGet(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdGetRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdGet(requestParameters.forumId, requestParameters.selectionSiteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a Selection Site\'s details
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdPutRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.selectionSiteRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the status of a given selection site
     * @param {ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumForumIdSelectionSitesSelectionSiteIdStatusPut(requestParameters: ForumSelectionSiteApiForumForumIdSelectionSitesSelectionSiteIdStatusPutRequest, options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumForumIdSelectionSitesSelectionSiteIdStatusPut(requestParameters.forumId, requestParameters.selectionSiteId, requestParameters.selectionSiteStatusRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get predefined event choices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSelectionSiteApi
     */
    public forumSelectionSitesPredefinedEventchoicesGet(options?: AxiosRequestConfig) {
        return ForumSelectionSiteApiFp(this.configuration).forumSelectionSitesPredefinedEventchoicesGet(options).then((request) => request(this.axios, this.basePath));
    }
}

