import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateForumCompanyPrimaryLocationMutation,
  useUpdateForumCompanyPrimaryLocationMutation,
} from "api/mutations/forumCompanies";
import { useForumTablesQuery } from "api/queries/forumTables";
import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input, InputNumber, Select, Spin } from "components/styleguide";
import FacilityType from "enums/FacilityType";
import ErrorTypes from "error-handling/errorTypes";
import {
  Em2ExceptionResponseObject,
  FacilityType as APIFacilityType,
  ForumCompanyPrimaryLocationModel,
  ForumCompanyPrimaryLocationRequestModel,
} from "generated/api";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface ForumCompanyPrimaryLocationFormProps {
  forumId: number;
  forumCompanyId: number;
  initialValues: ForumCompanyPrimaryLocationModel | null;
  onDrawerClose: () => void;
}

const ForumCompanyPrimaryLocationForm: FC<ForumCompanyPrimaryLocationFormProps> = ({
  forumId,
  forumCompanyId,
  initialValues,
  onDrawerClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [selectedForumFacilityId, setSelectedForumFacilityId] = useState<number | undefined>();
  const [selectedForumBuildingId, setSelectedForumBuildingId] = useState<number | undefined>();

  const { data: { data: forumFacilities = [] } = {}, isLoading } = useForumVenueFacilitiesQuery({
    forumId: forumId,
  });

  const { data: { data: forumTables = [] } = {}, isLoading: isTablesLoading } = useForumTablesQuery(
    {
      forumId,
      forumBuildingId: Number(selectedForumBuildingId),
      forumFacilityId: Number(selectedForumFacilityId),
    },
    {
      enabled: !!selectedForumFacilityId && !!selectedForumBuildingId,
    },
  );

  const createForumCompanyPrimaryLocationMutation = useCreateForumCompanyPrimaryLocationMutation();
  const updateForumCompanyPrimaryLocationMutation = useUpdateForumCompanyPrimaryLocationMutation();

  const isMutationLoading =
    createForumCompanyPrimaryLocationMutation.isLoading || updateForumCompanyPrimaryLocationMutation.isLoading;

  const forumMeetingFacilities = useMemo(
    () => forumFacilities.filter((f) => f.facilityType !== APIFacilityType.Bedroom),
    [forumFacilities],
  );

  const setForumTableParams = useCallback(
    (forumFacilityId: number) => {
      const forumBuildingId = forumMeetingFacilities.find((f) => f.id === forumFacilityId)?.buildingId;

      setSelectedForumBuildingId(forumBuildingId);
      setSelectedForumFacilityId(forumFacilityId);
    },
    [forumMeetingFacilities],
  );

  useEffect(() => {
    if (initialValues?.forumFacilityId) {
      setForumTableParams(initialValues.forumFacilityId);
    }
  }, [initialValues?.forumFacilityId, setForumTableParams]);

  const setForumFacilityTypeDisplay = useCallback(
    (forumFacilityId: number) => {
      const facilityType = forumMeetingFacilities.find((f) => f.id === forumFacilityId)?.facilityType;
      const enumFacilityType = FacilityType.toDisplayName(FacilityType.fromApiFacilityType(facilityType));

      form.setFieldValue("facilityType", enumFacilityType);
    },
    [form, forumMeetingFacilities],
  );

  const onForumFacilityChange = (forumFacilityId: number) => {
    setForumFacilityTypeDisplay(forumFacilityId);
    setForumTableParams(forumFacilityId);

    form.setFieldValue("forumTableId", null);
  };

  const handleDuplicateError = (response: { data: Em2ExceptionResponseObject }) => {
    if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
      form.setFields([
        {
          name: "facilityType",
          errors: [
            t("A location of type {{facilityType}} already exists", {
              facilityType: t(`facilityTypeNames.${response.data.meta.ItemFields.FacilityType}`),
            }),
          ],
        },
      ]);
    }
  };

  const mutationOptions = {
    onSuccess: () => {
      queryClient.resetQueries([KEYS.GET_FORUM_COMPANY_PRIMARY_LOCATIONS]);
      queryClient.resetQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS]);
      queryClient.resetQueries([KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS]);

      onDrawerClose();
    },
    onError: (({ response }: { response: { data: Em2ExceptionResponseObject } }) => {
      handleDuplicateError(response);
      // onError signature expects responst to be of type "unknown"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }) as any,
  };

  const handleCreatePrimaryLocation = (values: ForumCompanyPrimaryLocationRequestModel) => {
    createForumCompanyPrimaryLocationMutation.mutate(
      {
        forumId,
        forumCompanyId,
        forumCompanyPrimaryLocationRequestModel: values,
      },
      mutationOptions,
    );
  };

  const handleEditPrimaryLocation = (primaryLocationId: number, values: ForumCompanyPrimaryLocationRequestModel) => {
    updateForumCompanyPrimaryLocationMutation.mutate(
      {
        forumId,
        forumCompanyId,
        locationId: primaryLocationId,
        forumCompanyPrimaryLocationRequestModel: values,
      },
      mutationOptions,
    );
  };

  const onFormSubmit = (values: ForumCompanyPrimaryLocationRequestModel) => {
    if (!initialValues) {
      handleCreatePrimaryLocation(values);
      return;
    }

    handleEditPrimaryLocation(initialValues.id, values);
  };

  if (isLoading) {
    return <Spin className={"loader"} />;
  }

  return (
    <FullHeightForm
      layout="vertical"
      id="forumCompanyPrimaryLocationForm"
      name="forum-company-primary-location-form"
      form={form}
      actionsPrepend={<Button onClick={onDrawerClose}>{t("cancel")}</Button>}
      actionsAppend={
        <Button type="primary" htmlType="submit" loading={isMutationLoading}>
          {t("save")}
        </Button>
      }
      onFinish={onFormSubmit}
    >
      <Form.Item
        name="forumFacilityId"
        label={t("Location")}
        initialValue={initialValues?.forumFacilityId}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: t("Location") }),
          },
        ]}
      >
        <Select
          onChange={onForumFacilityChange}
          options={forumMeetingFacilities.map(({ id, name }) => ({ value: id, label: name }))}
        />
      </Form.Item>
      <Form.Item
        name="facilityType"
        initialValue={
          initialValues?.forumFacilityType &&
          FacilityType.toDisplayName(FacilityType.fromApiFacilityType(initialValues?.forumFacilityType))
        }
        label={t("facilityType")}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item name="forumTableId" label={t("Table")} initialValue={initialValues?.forumTableId}>
        <Select
          loading={isTablesLoading}
          options={forumTables.map(({ id, tableNumber }) => ({ value: id, label: tableNumber }))}
        />
      </Form.Item>
      <Form.Item name="sitting" initialValue={initialValues?.sitting} label={t("Sitting")}>
        <InputNumber style={{ width: "100%" }} />
      </Form.Item>
    </FullHeightForm>
  );
};

export default ForumCompanyPrimaryLocationForm;
