import { Space } from "components/styleguide";
import React, { useState } from "react";

import LinkToReport from "../../components/LinkToReport";
import { DefaultSortOptions } from "../../constants";
import SortOrderOption from "../SortByOption";

interface BaseSortOptionsProps {
  options?: Record<string, string>;
  defaultOption?: string;
}

// most reports only have sorting options so we can reuse that
// instead of creating options for each report
const BaseSortOptions = ({
  options = DefaultSortOptions,
  defaultOption = DefaultSortOptions.Surname,
}: BaseSortOptionsProps) => {
  const [orderBy, setOrderBy] = useState<string>(defaultOption);

  return (
    <Space align="end">
      <SortOrderOption options={options} orderBy={orderBy} setOrderBy={setOrderBy} />
      <LinkToReport parameters={{ OrderBy: orderBy }} />
    </Space>
  );
};

export default BaseSortOptions;
