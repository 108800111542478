import { Space } from "components/styleguide";
import { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import { DefaultSortOptions } from "../constants";
import ShowBedroomSelectOption from "../OptionTypes/ShowBedroomSelectOption";
import ShowNameSelectOption from "../OptionTypes/ShowNameSelectOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = {
  ...DefaultSortOptions,
  Bedroom: "Bedroom",
};

const EventAttendeesOptions = () => {
  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Company);
  const [showBedroom, setShowBedroom] = useState<boolean>(true);
  const [showName, setShowName] = useState<boolean>(true);

  return (
    <Space align={"end"} wrap>
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
      <ShowNameSelectOption showName={showName} setShowName={setShowName} />
      <ShowBedroomSelectOption showBedroom={showBedroom} setShowBedroom={setShowBedroom} />

      <LinkToReport parameters={{ OrderBy: orderBy, ShowBedroom: showBedroom, ShowName: showName }} />
    </Space>
  );
};

export default EventAttendeesOptions;
