import { FormInstance } from "antd";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input, Select } from "components/styleguide";
import { ForumDocumentDefinitionSectionResponseModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";

import { useUpdateSectionAndSectionOptions } from "../../hooks/useUpdateSectionAndSectionOptions";
import { ReactQuillType } from "../SelectionOptions/TextEditorOption";

interface ContentEntriesProps {
  documentDefinitionSection?: ForumDocumentDefinitionSectionResponseModel;
  forumId: number;
  documentDefinitionSectionId: number;
  onClose: () => void;
  sectionForm: FormInstance;
  sectionOptionsForm: FormInstance;
  quills: ReactQuillType[];
}

const ContentEntries: React.FC<ContentEntriesProps> = ({
  documentDefinitionSection,
  forumId,
  documentDefinitionSectionId,
  onClose,
  sectionForm,
  sectionOptionsForm,
  quills,
}) => {
  const { t } = useTranslation();

  const { contentsEntryTitle, contentsDescription, showInContents } = documentDefinitionSection || {};

  const { submit, isMutationLoading } = useUpdateSectionAndSectionOptions({
    forumId,
    documentDefinitionSectionId,
    onClose,
    quills,
  });

  return (
    <FullHeightForm
      form={sectionForm}
      onFinish={(values) => {
        submit({ ...documentDefinitionSection, ...values }, sectionOptionsForm.getFieldsValue());
      }}
      layout="vertical"
      actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
      actionsAppend={
        <Button htmlType="submit" type="primary" loading={isMutationLoading}>
          {t("Update")}
        </Button>
      }
    >
      <Form.Item label={t("Contents Title")} initialValue={contentsEntryTitle} name="contentsTitle">
        <Input disabled />
      </Form.Item>
      <Form.Item label={t("Contents Description")} initialValue={contentsDescription} name="contentsDescription">
        <Input.TextArea rows={6} cols={12} />
      </Form.Item>
      <Form.Item label={t("Show in Contents")} initialValue={showInContents} name="showInContents">
        <Select>
          <Select.Option value={true}>{t("Yes")}</Select.Option>
          <Select.Option value={false}>{t("No")}</Select.Option>
        </Select>
      </Form.Item>
    </FullHeightForm>
  );
};

export default ContentEntries;
