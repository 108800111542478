import { useGetCountriesQuery } from "api/queries/countries";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Select } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { validateBuildingCode } from "utils/validatorUtils";

const { Option } = Select;

const ForumBuildingDrawer = ({
  hideBuildingDrawer,
  buildingDrawerVisible,
  submitBuilding,
  isLoading,
  isEdit,
  building,
  venue,
}) => {
  const { t } = useTranslation();
  const { data: { data: countries = [] } = {} } = useGetCountriesQuery({});

  return (
    <Drawer
      className="new-building-drawer"
      title={isEdit ? t("Edit Building") : t("createNewBuilding")}
      placement="right"
      onClose={hideBuildingDrawer}
      open={buildingDrawerVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose
    >
      <FullHeightForm
        id="newBuildingForm"
        name="new-building"
        labelCol={{ justify: "left", span: 6 }}
        wrapperCol={{ justify: "right" }}
        labelWrap
        actionsPrepend={<Button onClick={hideBuildingDrawer}>{t("cancel")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEdit ? t("save") : t("create")}
          </Button>
        }
        onFinish={submitBuilding}
      >
        <Form.Item
          label={t("buildingName")}
          name="name"
          initialValue={isEdit ? building.name : undefined}
          rules={[
            {
              required: true,
              message: t("buildingNameRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("buildingCode")}
          name="code"
          initialValue={isEdit ? building.code : undefined}
          rules={[
            {
              required: true,
              message: t("buildingCodeRequired"),
            },
            {
              validator: validateBuildingCode,
              message: t("Building Code should be up to 5 characters!"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("address")} name="address" initialValue={building.address || venue?.address}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("address2")}
          name="addressLine2"
          initialValue={building.addressLine2 || venue?.addressLine2}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("city")} name="city" initialValue={building.city || venue?.city}>
          <Input />
        </Form.Item>
        <Form.Item label={t("county")} name="county" initialValue={building.county || venue?.county}>
          <Input />
        </Form.Item>
        <Form.Item label={t("postcode")} name="postcode" initialValue={building.postcode || venue?.postCode}>
          <Input />
        </Form.Item>
        <Form.Item label={t("country")} name="countryCode" initialValue={building.countryCode || venue?.countryCode}>
          <Select>
            {countries.map((c) => (
              <Option value={c.isoCode} key={c.isoCode}>
                {c.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

ForumBuildingDrawer.propTypes = {
  building: PropTypes.object,
  venue: PropTypes.object,
  hideBuildingDrawer: PropTypes.func,
  buildingDrawerVisible: PropTypes.bool,
  submitBuilding: PropTypes.func,
  createBuildingMutation: PropTypes.object,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ForumBuildingDrawer;
