import { Space, Typography } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { replaceAt } from "utils/arrayUtils";
import { generateQuestionId } from "utils/questionnaireContentUtils";
import QuestionOptionType from "utils/questionOptionTypeUtils";
import QuestionType from "utils/questionTypeUtils";

import QuestionRenderer from "../QuestionRenderer";

const QuestionsRenderer = ({ questions, pageIndex, isEditable, onChange }) => {
  const { t } = useTranslation();
  if (!questions || questions.length === 0) {
    return <Typography.Text>{t("noQuestions")}</Typography.Text>;
  }

  const handleQuestionChange = (questionIndex, newQuestion) => {
    onChange(replaceAt(questions, questionIndex, newQuestion));
  };

  return (
    <Space direction="vertical" size="large" fullWidth>
      {questions.map((question, questionIndex) => {
        const id = generateQuestionId(pageIndex, questionIndex);

        return (
          <QuestionRenderer
            key={question.id}
            questionIndex={questionIndex}
            questionId={id}
            question={question}
            isEditable={isEditable}
            onChange={(newQuestion) => handleQuestionChange(questionIndex, newQuestion)}
          />
        );
      })}
    </Space>
  );
};

QuestionsRenderer.propTypes = {
  pageIndex: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.oneOf(QuestionType.VALUES).isRequired,
      description: PropTypes.string,
      numberOfColumns: PropTypes.number,
      secondaryText: PropTypes.string,
      showSecondaryText: PropTypes.bool,
      layoutHorizontal: PropTypes.bool,
      optionsColumns: PropTypes.arrayOf(PropTypes.string),
      options: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          text: PropTypes.string,
          type: PropTypes.oneOf(QuestionOptionType.VALUES),
        }),
      ),
    }),
  ),
  isEditable: PropTypes.bool,
  onChange: PropTypes.func,
};

QuestionsRenderer.defaultProps = {
  isEditable: true,
  onChange: () => undefined,
};

export default QuestionsRenderer;
