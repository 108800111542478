const TableType = {
  None: 1,
  Supplier: 2,
  Networking: 3,
  Conference: 4,
  Lounge: 5,
  All: 6,

  toDisplayName(type: number) {
    switch (type) {
      case 1: {
        return "None";
      }
      case 2: {
        return "Supplier";
      }
      case 3: {
        return "Networking";
      }
      case 4: {
        return "Conference";
      }
      case 5: {
        return "Lounge";
      }
      case 6: {
        return "All Meet All";
      }
      default:
        return type;
    }
  },
};

export default TableType;
