import { DownOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  useImportBedroomsMutation,
  useImportFacilitiesMutation,
  useImportForumBedroomsMutation,
  useImportForumFacilitiesMutation,
  useImportForumTablesMutation,
  useImportTablesMutation,
} from "api/mutations/fileImport";
import KEYS from "api/queries/keys";
import { useGetVenueFacilitiesQuery, useVenueTablesQuery } from "api/queries/venues";
import { Button, Dropdown, Form, Space } from "components/styleguide";
import { FacilityType } from "generated/api";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateFileImportKey } from "utils/localStorage/fileImport";

import { useForumVenueFacilitiesQuery, useForumVenueTablesQuery } from "../../api/queries/forumVenues";
import ImportDrawer from "./ImportDrawer";
import { FileImportSubmit } from "./types";

export default function FileImport({ venueId, forumId }: { venueId: number; forumId?: number }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [isFacilitiesDrawerOpen, setIsFacilitiesDrawerOpen] = useState(false);
  const [isTablesDrawerOpen, setIsTablesDrawerOpen] = useState(false);
  const [isBedroomsDrawerOpen, setIsBedroomsDrawerOpen] = useState(false);

  const closeAllDrawers = () => {
    setIsFacilitiesDrawerOpen(false);
    setIsTablesDrawerOpen(false);
    setIsBedroomsDrawerOpen(false);
    queryClient.removeQueries([KEYS.GET_FILE_IMPORT]);
  };

  const importSystemAdminBedroomsMutation = useImportBedroomsMutation();
  const importForumAdminBedroomsMutation = useImportForumBedroomsMutation();
  const importFacilities = useImportFacilitiesMutation();
  const importForumFacilities = useImportForumFacilitiesMutation();
  const importTables = useImportTablesMutation();
  const importForumTables = useImportForumTablesMutation();

  const { data: { data: systemAdminBedrooms = [] } = {} } = useGetVenueFacilitiesQuery(
    {
      venueId: toNumber(venueId),
      type: FacilityType.Bedroom,
    },
    { enabled: !forumId },
  );
  const { data: { data: forumAdminBedrooms = [] } = {} } = useForumVenueFacilitiesQuery(
    {
      forumId: toNumber(forumId),
      types: [FacilityType.Bedroom],
    },
    { enabled: !!forumId },
  );

  const { data: { data: systemAdminFacilities = [] } = {} } = useGetVenueFacilitiesQuery(
    { venueId },
    { enabled: !forumId },
  );

  const { data: { data: forumAdminFacilities = [] } = {} } = useForumVenueFacilitiesQuery(
    { forumId: toNumber(forumId) },
    { enabled: !!forumId },
  );

  const { data: { data: forumAdminTables = [] } = {} } = useForumVenueTablesQuery(
    { forumId: toNumber(forumId) },
    { enabled: !!forumId },
  );

  const { data: { data: systemAdminTables = [] } = {} } = useVenueTablesQuery(
    {
      venueId,
    },
    { enabled: !forumId },
  );

  const onFacilitiesSubmit = ({ formFile, importMode, importKey }: FileImportSubmit) => {
    if (forumId) {
      importForumFacilities.mutate(
        {
          forumId,
          formFile: formFile.file.originFileObj,
          importMode,
          importKey,
        },
        {
          onSuccess: () => {
            updateFileImportKey(importKey);
            closeAllDrawers();
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    } else {
      importFacilities.mutate(
        {
          venueId,
          formFile: formFile.file.originFileObj,
          importMode,
          importKey,
        },
        {
          onSuccess: () => {
            updateFileImportKey(importKey);
            closeAllDrawers();
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    }
  };

  const onBedroomsSubmit = ({ formFile, importMode, importKey }: FileImportSubmit) => {
    if (forumId) {
      importForumAdminBedroomsMutation.mutate(
        {
          forumId: toNumber(forumId),
          formFile: formFile.file.originFileObj,
          importMode,
          importKey,
        },
        {
          onSuccess: () => {
            updateFileImportKey(importKey);
            closeAllDrawers();
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    } else {
      importSystemAdminBedroomsMutation.mutate(
        {
          venueId: toNumber(venueId),
          formFile: formFile.file.originFileObj,
          importMode,
          importKey,
        },
        {
          onSuccess: () => {
            updateFileImportKey(importKey);
            closeAllDrawers();
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    }
  };

  const onTablesSubmit = ({ formFile, importMode, importKey }: FileImportSubmit) => {
    if (forumId) {
      importForumTables.mutate(
        {
          forumId: toNumber(forumId),
          formFile: formFile.file.originFileObj,
          importMode,
          importKey,
        },
        {
          onSuccess: () => {
            updateFileImportKey(importKey);
            closeAllDrawers();
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    } else {
      importTables.mutate(
        {
          venueId: toNumber(venueId),
          formFile: formFile.file.originFileObj,
          importMode,
          importKey,
        },
        {
          onSuccess: () => {
            updateFileImportKey(importKey);
            closeAllDrawers();
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    }
  };

  const menuProps = {
    items: [
      {
        label: t("Import Facilities"),
        key: "facilities",
        onClick: () => setIsFacilitiesDrawerOpen(true),
      },
      {
        label: t("Import Tables"),
        key: "tables",
        onClick: () => setIsTablesDrawerOpen(true),
      },
      {
        label: t("Import Bedrooms"),
        key: "bedrooms",
        onClick: () => setIsBedroomsDrawerOpen(true),
      },
    ],
  };

  return (
    <>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            {t("Import File")}
            <DownOutlined style={{ fontSize: "12px" }} />
          </Space>
        </Button>
      </Dropdown>
      <ImportDrawer
        onSubmit={onFacilitiesSubmit}
        title="Import Facilities"
        visible={isFacilitiesDrawerOpen}
        onClose={() => {
          setIsFacilitiesDrawerOpen(false);
          queryClient.removeQueries([KEYS.GET_FILE_IMPORT]);
        }}
        isLoading={forumId ? importForumFacilities.isLoading : importFacilities.isLoading}
        form={form}
        existingEntities={forumId ? forumAdminFacilities : systemAdminFacilities}
      />
      <ImportDrawer
        onSubmit={onTablesSubmit}
        title="Import Tables"
        visible={isTablesDrawerOpen}
        onClose={() => {
          setIsTablesDrawerOpen(false);
          queryClient.removeQueries([KEYS.GET_FILE_IMPORT]);
        }}
        isLoading={forumId ? importForumTables.isLoading : importTables.isLoading}
        form={form}
        existingEntities={forumId ? forumAdminTables : systemAdminTables}
      />
      <ImportDrawer
        onSubmit={onBedroomsSubmit}
        title="Import Bedrooms"
        visible={isBedroomsDrawerOpen}
        onClose={() => {
          setIsBedroomsDrawerOpen(false);
          queryClient.removeQueries([KEYS.GET_FILE_IMPORT]);
        }}
        isLoading={forumId ? importForumAdminBedroomsMutation.isLoading : importSystemAdminBedroomsMutation.isLoading}
        form={form}
        existingEntities={forumId ? forumAdminBedrooms : systemAdminBedrooms}
      />
    </>
  );
}
