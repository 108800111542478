import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input } from "components/styleguide";
import { RoomGradeRequestModel, RoomGradeResponseModel } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface GradeFormProps {
  grade?: RoomGradeResponseModel;
  isSubmitting: boolean;
  onSubmit: (values: RoomGradeRequestModel) => void;
  onClose: () => void;
}

const GradeForm: FC<GradeFormProps> = ({ grade, isSubmitting, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <FullHeightForm
      form={form}
      initialValues={grade}
      onFinish={onSubmit}
      style={{ height: "100%" }}
      labelWrap
      layout="vertical"
      actionsAppend={
        <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={isSubmitting}>
          {t("Save")}
        </Button>
      }
      actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
    >
      <Form.Item
        label={t("name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(Name)" }),
          },
          {
            max: 50,
            message: t("The character limit is {{count}}", { count: 50 }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Description")}
        name="description"
        rules={[
          {
            max: 250,
            message: t("The character limit is {{count}}", { count: 250 }),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </FullHeightForm>
  );
};

export default GradeForm;
