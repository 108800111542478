import { useQueryClient } from "@tanstack/react-query";
import { BaseOptionType } from "antd/lib/select";
import { useUpdateAttendeeDescriptionMutation } from "api/mutations/attendees";
import { useAttendeeDescriptionsQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Form, Table } from "components/styleguide";
import {
  AttendeeDescriptionRequestModel,
  AttendeeManageDescriptionModel,
  PageResponseAttendeeManageDescriptionModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, getColumnSelectFilterProps } from "utils/tableColumnUtils";

import EditDescriptionDrawer from "../EditDrawer";
import { ManageDesriptionsTab } from "../types";

import styles from "./styles.module.less";
import editorStyles from "components/TextEditor/plainTextStyles.module.less";

const DataIndexes = {
  AttendeeName: "attendeeName",
  PeopleType: "peopleType",
  CompanyName: "attendeeCompanyName",
  Description: "description",
};

export default function ManageAttendeeDesciptions({ participationCodes, isLoadingCodes }: ManageDesriptionsTab) {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [descriptions, setDescriptions] = useState<AttendeeManageDescriptionModel[]>();
  const [attendeeNameSearch, setAttendeeNameSearch] = useState<string | undefined>(undefined);
  const [participationCodeFilter, setParticipationFilter] = useState<string | undefined>(undefined);
  const [companyNameSearch, setCompanyNameSearch] = useState<string | undefined>(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<AttendeeManageDescriptionModel | null>();
  const [form] = Form.useForm();

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    response: query,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeeDescriptionsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseAttendeeManageDescriptionModel }) => {
        setDescriptions(data.items);
      },
    },
    {
      forumId,
      attendeeName: attendeeNameSearch,
      attendeeCompanyName: companyNameSearch,
      peopleType: participationCodeFilter,
    },
  );

  const updateMutation = useUpdateAttendeeDescriptionMutation();

  const handleSearch = (
    selectedKeys: Array<string> | Array<BaseOptionType>,
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case DataIndexes.AttendeeName: {
        setAttendeeNameSearch(selectedKeys[0] as string);
        break;
      }
      case DataIndexes.PeopleType: {
        const selected = selectedKeys as BaseOptionType[];
        setParticipationFilter(selected[0].value);
        break;
      }
      case DataIndexes.CompanyName: {
        setCompanyNameSearch(selectedKeys[0] as string);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case DataIndexes.AttendeeName: {
        setAttendeeNameSearch(undefined);
        break;
      }
      case DataIndexes.PeopleType: {
        setParticipationFilter(undefined);
        break;
      }
      case DataIndexes.CompanyName: {
        setCompanyNameSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const handleEditClick = (record: AttendeeManageDescriptionModel) => {
    setActiveItem(record);
    form.setFieldValue("description", record.description);
    setDrawerOpen(true);
  };

  const handleEditSubmit = (values: AttendeeDescriptionRequestModel) => {
    updateMutation.mutate(
      {
        forumId,
        attendeeId: activeItem?.id as number,
        attendeeDescriptionRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_ATTENDEES_DESCRIPTIONS]);

          setDrawerOpen(false);
          form.resetFields();
        },
      },
    );
  };

  const columns = [
    {
      title: t("Attendee Name"),
      dataIndex: DataIndexes.AttendeeName,
      sorter: true,
      ...getColumnSearchProps(DataIndexes.AttendeeName, handleSearch, handleReset, DataIndexes.AttendeeName),
    },
    {
      title: t("People Type"),
      dataIndex: DataIndexes.PeopleType,
      ...getColumnSelectFilterProps(
        DataIndexes.PeopleType,
        handleSearch,
        handleReset,
        DataIndexes.PeopleType,
        participationCodes,
      ),
    },
    {
      title: t("Company Name"),
      dataIndex: DataIndexes.CompanyName,
      sorter: true,
      ...getColumnSearchProps(DataIndexes.CompanyName, handleSearch, handleReset, DataIndexes.CompanyName),
    },
    {
      title: t("Attendee Description"),
      dataIndex: DataIndexes.Description,
      render: (description: string) => {
        return (
          <span
            className={editorStyles.editorText}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        );
      },
    },
    {
      dataIndex: "id",
      width: "5%",
      render: (_: unknown, record: AttendeeManageDescriptionModel) => (
        <div className={styles.buttonWrapper}>
          <Button onClick={() => handleEditClick(record)}>{t("Edit")}</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <EditDescriptionDrawer
        title={t("Edit Attendee Description")}
        editorPlaceholder={t("Please, enter Attendee description")}
        form={form}
        open={drawerOpen}
        isLoading={updateMutation.isLoading}
        onClose={handleDrawerClose}
        onSubmit={handleEditSubmit}
      />
      <Table
        columns={columns}
        rowKey="id"
        loading={isLoadingCodes || query.isLoading}
        dataSource={descriptions}
        pagination={pagination}
        onChange={handleSort}
      />
    </>
  );
}
