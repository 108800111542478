import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useUpdateTeamMemberMutation,
} from "api/mutations/teamMembers";
import KEYS from "api/queries/keys";
import { useTeamMembersQuery, useUnassignedTeamMembersQuery } from "api/queries/teamMembers";
import { Button, Modal, Table, Typography } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import { ForumTeamMemberRequestModel, ForumTeamMemberResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AddTeamMemberDrawer from "./AddTeamMemberDrawer";
import EditTeamMemberDrawer from "./EditTeamMemberDrawer";
import { EditTeamMemberSubmitProps } from "./types";

const TeamMembers = () => {
  const { id: forumId } = useParams();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [isAddTeamMemberDrawerVisible, setIsAddTeamMemberDrawerVisible] = useState(false);
  const [isEditTeamMemberDrawerVisible, setIsEditTeamMemberDrawerVisible] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState<ForumTeamMemberResponseModel | null>(null);

  const { isLoading: isCreateLoading, mutate: mutateCreate } = useCreateTeamMemberMutation();
  const { isLoading: isUpdateLoading, mutate: mutateUpdate } = useUpdateTeamMemberMutation();

  const deleteTeamMember = useDeleteTeamMemberMutation();

  const {
    response: { isLoading, data: { data: teamMembers = [] } = {} } = {},
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(useTeamMembersQuery, {}, { forumId });

  const { data: { data: users } = {}, isLoading: isUsersLoading } = useUnassignedTeamMembersQuery(
    { forumId: toNumber(forumId) },
    {
      enabled: isEditTeamMemberDrawerVisible || isAddTeamMemberDrawerVisible,
    },
  );

  const handleCreateTeamMember = (forumTeamMemberRequestModel: ForumTeamMemberRequestModel) => {
    mutateCreate(
      {
        forumId: toNumber(forumId),
        forumTeamMemberRequestModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_TEAM_MEMEBERS]);
          setIsAddTeamMemberDrawerVisible(false);
        },
      },
    );
  };

  const handleUpdateTeamMember = ({
    teamMemberId,
    role,
    jobTitle,
    mobilePhone,
    businessPhone,
    userId,
  }: EditTeamMemberSubmitProps) => {
    mutateUpdate(
      {
        forumId: toNumber(forumId),
        teamMemberId,
        forumTeamMemberRequestModel: {
          role,
          jobTitle,
          userId,
          mobilePhone,
          businessPhone,
        } as ForumTeamMemberRequestModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_TEAM_MEMEBERS]);
          setIsEditTeamMemberDrawerVisible(false);
        },
      },
    );
  };

  const onDrawerClose = () => {
    if (isCreateLoading || isUpdateLoading) {
      return;
    }

    setIsAddTeamMemberDrawerVisible(false);
    setIsEditTeamMemberDrawerVisible(false);
    setSelectedTeamMember(null);
  };

  const editTeamMember = useCallback((member: ForumTeamMemberResponseModel) => {
    setSelectedTeamMember(member);
    setIsEditTeamMemberDrawerVisible(true);
  }, []);

  const handleTeamMemberDelete = useCallback(
    (id: number | undefined) => {
      Modal.confirm({
        title: t("confirmation"),
        content: <Typography.Text>{t("Do you want to delete the selected team member?")}</Typography.Text>,
        closable: true,
        okText: t("yes"),
        okType: "danger",
        cancelText: t("no"),
        onOk: () =>
          deleteTeamMember.mutateAsync(
            { forumId: toNumber(forumId), teamMemberId: toNumber(id) },
            {
              onSuccess: () => {
                queryClient.invalidateQueries([KEYS.GET_FORUM_TEAM_MEMEBERS]);
              },
            },
          ),
      });
    },
    [deleteTeamMember, forumId, queryClient, t],
  );

  const teamMembersColumns = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "name",
        sorter: true,
      },
      {
        title: t("jobTitle"),
        dataIndex: "jobTitle",
        sorter: true,
      },
      {
        title: t("role"),
        dataIndex: "role",
        sorter: true,
      },
      {
        title: t("Mobile phone"),
        dataIndex: "mobilePhone",
      },
      {
        title: t("Phone Number"),
        dataIndex: "businessPhone",
      },
      {
        dataIndex: "id",
        render: (id: number | undefined, record: ForumTeamMemberResponseModel) => (
          <TableRowActionButtons onEdit={() => editTeamMember(record)} onDelete={() => handleTeamMemberDelete(id)} />
        ),
        width: "100px",
      },
    ],
    [editTeamMember, handleTeamMemberDelete, t],
  );

  return (
    <div>
      <AddTeamMemberDrawer
        isVisible={isAddTeamMemberDrawerVisible}
        isLoading={isCreateLoading || isLoading}
        onClose={onDrawerClose}
        onFinish={handleCreateTeamMember}
        users={users}
        isUsersLoading={isUsersLoading}
      />

      <EditTeamMemberDrawer
        key={selectedTeamMember?.id}
        isVisible={isEditTeamMemberDrawerVisible}
        isLoading={isUpdateLoading || isLoading}
        onClose={onDrawerClose}
        onFinish={handleUpdateTeamMember}
        teamMember={selectedTeamMember}
        users={users}
        isUsersLoading={isUsersLoading}
      />
      <div className="section-button">
        <Button onClick={() => setIsAddTeamMemberDrawerVisible(true)}>{t("addTeamMember")}</Button>
      </div>
      <Table
        id="teamMembersTable"
        dataSource={teamMembers}
        columns={teamMembersColumns}
        bordered={true}
        rowKey={"id"}
        pagination={pagination}
        onChange={handleSort}
        loading={isLoading}
      />
    </div>
  );
};

export default TeamMembers;
