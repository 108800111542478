import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Modal, TableProps } from "antd";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useDeleteBlockedMeetingPreference } from "../../../../../api/mutations/attendees";
import { useAttendeePreferencesBlockedMeetingsQuery } from "../../../../../api/queries/attendees";
import KEYS from "../../../../../api/queries/keys";
import { Table, Typography } from "../../../../../components/styleguide";
import { ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest } from "../../../../../generated/api";
import { BlockedMeetingPreferenceResponseModel } from "../../../../../generated/api/apiModels/blocked-meeting-preference-response-model";
import { PageResponseBlockedMeetingPreferenceResponseModel } from "../../../../../generated/api/apiModels/page-response-blocked-meeting-preference-response-model";
import { useModernQueryWithPaginationAndOrder } from "../../../../../hooks";
import { getColumnSearchProps } from "../../../../../utils/tableColumnUtils";
import { buildAttendeeColumn } from "../common/utils";

import styles from "./styles.module.less";

interface AttendeePreferencesBlockedMeetingsTableProps {
  forumId: number;
  attendeeId: number;
  onEdit: (blockedMeetingPreference: BlockedMeetingPreferenceResponseModel) => void;
}

const AttendeePreferencesBlockedMeetingsTable: FC<AttendeePreferencesBlockedMeetingsTableProps> = ({
  forumId,
  attendeeId,
  onEdit,
}) => {
  const { t } = useTranslation();

  const [blockedMeetings, setBlockedMeetings] = useState<PageResponseBlockedMeetingPreferenceResponseModel | null>(
    null,
  );

  const [attendeeNotToMeetWithSearch, setAttendeeNotToMeetWithSearch] = useState<string | undefined>(undefined);

  const {
    response: { isLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeePreferencesBlockedMeetingsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseBlockedMeetingPreferenceResponseModel }) => {
        setBlockedMeetings(data);
      },
    },
    {
      forumId: forumId,
      attendeeId: attendeeId,
      attendeeNotToMeetWith: attendeeNotToMeetWithSearch,
    } as ForumAttendeePreferencesApiForumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGetRequest,
    { pageSizeOptions: [10, 20, 50] },
  );

  const queryClient = useQueryClient();
  const deleteMutation = useDeleteBlockedMeetingPreference();

  // this state looks like this (where 3 is the meetingId of the table row):
  // {
  //   3: [
  //     "Must be between 1 and 1000",
  //     "This priority is already set for a meeting",
  //   ],
  // }

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "attendeeNotToMeetWith": {
        setAttendeeNotToMeetWithSearch(selectedKeys[0]);
        break;
      }
    }
    queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS]);
    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "attendeeNotToMeetWith": {
        setAttendeeNotToMeetWithSearch(undefined);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  const deleteBlockedMeeting = (id: number) => {
    deleteMutation.mutate(
      { forumId, attendeeId, id },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS]);
          toast.success(t("Preference has been deleted successfully"));
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onDelete = (id: number) => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with deletion of this entry?")}</Typography.Text>,
      closable: true,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: () => deleteBlockedMeeting(id),
    });
  };

  const columns: TableProps<BlockedMeetingPreferenceResponseModel>["columns"] = [
    {
      title: t("Attendee not to meet with"),
      dataIndex: "attendeeNotToMeetWithName",
      key: "attendeeNotToMeetWith",
      render: (_: string, b: BlockedMeetingPreferenceResponseModel) =>
        buildAttendeeColumn(
          b.attendeeNotToMeetWithName,
          b.attendeeNotToMeetWithParticipationCode,
          b.attendeeNotToMeetWithJobTitle,
          b.attendeeNotToMeetWithCompany,
        ),
      sorter: false,
      ...getColumnSearchProps("attendeeNotToMeetWith", handleSearch, handleReset, "attendeeNotToMeetWith"),
    },
    {
      title: t("Negative"),
      dataIndex: "isNegative",
      width: "25%",
      key: "isNegative",
      render: (val: boolean) => (val ? t("Yes") : t("No")),
      sorter: false,
    },
    {
      title: "",
      render: (_: unknown, record: BlockedMeetingPreferenceResponseModel) => {
        return (
          <div className={styles.optionWrapper}>
            <Button onClick={() => onEdit(record)}>{t("Edit")}</Button>
            <span className={styles.iconWrapper}>
              <DeleteFilled className="delete-icon" onClick={() => onDelete(record.id)} />
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <Table
      id="attendee-group-blocked-memberships-table"
      dataSource={blockedMeetings?.items ?? []}
      columns={columns}
      pagination={pagination}
      bordered={true}
      loading={isLoading}
      rowKey="id"
      onChange={handleSort}
    />
  );
};

export default AttendeePreferencesBlockedMeetingsTable;
