/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumSeminarTypeRequestModel } from '../apiModels';
// @ts-ignore
import { ForumSeminarTypeResponseModel } from '../apiModels';
// @ts-ignore
import { ReferencedObjectCannotBeDeletedExceptionResponse } from '../apiModels';
/**
 * ForumSeminarTypeApi - axios parameter creator
 * @export
 */
export const ForumSeminarTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumSeminarTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesForumSeminarTypeIdDelete: async (forumId: number, forumSeminarTypeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarTypesForumSeminarTypeIdDelete', 'forumId', forumId)
            // verify required parameter 'forumSeminarTypeId' is not null or undefined
            assertParamExists('forumsForumIdSeminarTypesForumSeminarTypeIdDelete', 'forumSeminarTypeId', forumSeminarTypeId)
            const localVarPath = `/forums/{forumId}/seminar-types/{forumSeminarTypeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarTypeId"}}`, encodeURIComponent(String(forumSeminarTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The seminar type code should be unique in the forum\\  Can\'t update the code for a seminar type with the code F, K, G, Z
         * @summary Updates a forum seminar type
         * @param {number} forumId 
         * @param {number} forumSeminarTypeId 
         * @param {ForumSeminarTypeRequestModel} [forumSeminarTypeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesForumSeminarTypeIdPut: async (forumId: number, forumSeminarTypeId: number, forumSeminarTypeRequestModel?: ForumSeminarTypeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarTypesForumSeminarTypeIdPut', 'forumId', forumId)
            // verify required parameter 'forumSeminarTypeId' is not null or undefined
            assertParamExists('forumsForumIdSeminarTypesForumSeminarTypeIdPut', 'forumSeminarTypeId', forumSeminarTypeId)
            const localVarPath = `/forums/{forumId}/seminar-types/{forumSeminarTypeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarTypeId"}}`, encodeURIComponent(String(forumSeminarTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarTypeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a forum\'s seminar types
         * @param {number} forumId 
         * @param {string} [code] 
         * @param {string} [type] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesGet: async (forumId: number, code?: string, type?: string, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarTypesGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/seminar-types`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * The seminar type code should be unique in the forum and not in the default seminar types list
         * @summary Create a new forum seminar type
         * @param {number} forumId 
         * @param {ForumSeminarTypeRequestModel} [forumSeminarTypeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesPost: async (forumId: number, forumSeminarTypeRequestModel?: ForumSeminarTypeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarTypesPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/seminar-types`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarTypeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumSeminarTypeApi - functional programming interface
 * @export
 */
export const ForumSeminarTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumSeminarTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumSeminarTypeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarTypesForumSeminarTypeIdDelete(forumId: number, forumSeminarTypeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarTypesForumSeminarTypeIdDelete(forumId, forumSeminarTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The seminar type code should be unique in the forum\\  Can\'t update the code for a seminar type with the code F, K, G, Z
         * @summary Updates a forum seminar type
         * @param {number} forumId 
         * @param {number} forumSeminarTypeId 
         * @param {ForumSeminarTypeRequestModel} [forumSeminarTypeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarTypesForumSeminarTypeIdPut(forumId: number, forumSeminarTypeId: number, forumSeminarTypeRequestModel?: ForumSeminarTypeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarTypesForumSeminarTypeIdPut(forumId, forumSeminarTypeId, forumSeminarTypeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a forum\'s seminar types
         * @param {number} forumId 
         * @param {string} [code] 
         * @param {string} [type] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarTypesGet(forumId: number, code?: string, type?: string, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumSeminarTypeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarTypesGet(forumId, code, type, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * The seminar type code should be unique in the forum and not in the default seminar types list
         * @summary Create a new forum seminar type
         * @param {number} forumId 
         * @param {ForumSeminarTypeRequestModel} [forumSeminarTypeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarTypesPost(forumId: number, forumSeminarTypeRequestModel?: ForumSeminarTypeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumSeminarTypeResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarTypesPost(forumId, forumSeminarTypeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumSeminarTypeApi - factory interface
 * @export
 */
export const ForumSeminarTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumSeminarTypeApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesForumSeminarTypeIdDelete(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarTypesForumSeminarTypeIdDelete(requestParameters.forumId, requestParameters.forumSeminarTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * The seminar type code should be unique in the forum\\  Can\'t update the code for a seminar type with the code F, K, G, Z
         * @summary Updates a forum seminar type
         * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesForumSeminarTypeIdPut(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarTypesForumSeminarTypeIdPut(requestParameters.forumId, requestParameters.forumSeminarTypeId, requestParameters.forumSeminarTypeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a forum\'s seminar types
         * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesGet(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumSeminarTypeResponseModel>> {
            return localVarFp.forumsForumIdSeminarTypesGet(requestParameters.forumId, requestParameters.code, requestParameters.type, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * The seminar type code should be unique in the forum and not in the default seminar types list
         * @summary Create a new forum seminar type
         * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarTypesPost(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumSeminarTypeResponseModel> {
            return localVarFp.forumsForumIdSeminarTypesPost(requestParameters.forumId, requestParameters.forumSeminarTypeRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdSeminarTypesForumSeminarTypeIdDelete operation in ForumSeminarTypeApi.
 * @export
 * @interface ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDeleteRequest
 */
export interface ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDelete
     */
    readonly forumSeminarTypeId: number
}

/**
 * Request parameters for forumsForumIdSeminarTypesForumSeminarTypeIdPut operation in ForumSeminarTypeApi.
 * @export
 * @interface ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPutRequest
 */
export interface ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPut
     */
    readonly forumSeminarTypeId: number

    /**
     * 
     * @type {ForumSeminarTypeRequestModel}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPut
     */
    readonly forumSeminarTypeRequestModel?: ForumSeminarTypeRequestModel
}

/**
 * Request parameters for forumsForumIdSeminarTypesGet operation in ForumSeminarTypeApi.
 * @export
 * @interface ForumSeminarTypeApiForumsForumIdSeminarTypesGetRequest
 */
export interface ForumSeminarTypeApiForumsForumIdSeminarTypesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesGet
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesGet
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsForumIdSeminarTypesPost operation in ForumSeminarTypeApi.
 * @export
 * @interface ForumSeminarTypeApiForumsForumIdSeminarTypesPostRequest
 */
export interface ForumSeminarTypeApiForumsForumIdSeminarTypesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumSeminarTypeRequestModel}
     * @memberof ForumSeminarTypeApiForumsForumIdSeminarTypesPost
     */
    readonly forumSeminarTypeRequestModel?: ForumSeminarTypeRequestModel
}

/**
 * ForumSeminarTypeApi - object-oriented interface
 * @export
 * @class ForumSeminarTypeApi
 * @extends {BaseAPI}
 */
export class ForumSeminarTypeApi extends BaseAPI {
    /**
     * 
     * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarTypeApi
     */
    public forumsForumIdSeminarTypesForumSeminarTypeIdDelete(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumSeminarTypeApiFp(this.configuration).forumsForumIdSeminarTypesForumSeminarTypeIdDelete(requestParameters.forumId, requestParameters.forumSeminarTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The seminar type code should be unique in the forum\\  Can\'t update the code for a seminar type with the code F, K, G, Z
     * @summary Updates a forum seminar type
     * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarTypeApi
     */
    public forumsForumIdSeminarTypesForumSeminarTypeIdPut(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesForumSeminarTypeIdPutRequest, options?: AxiosRequestConfig) {
        return ForumSeminarTypeApiFp(this.configuration).forumsForumIdSeminarTypesForumSeminarTypeIdPut(requestParameters.forumId, requestParameters.forumSeminarTypeId, requestParameters.forumSeminarTypeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a forum\'s seminar types
     * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarTypeApi
     */
    public forumsForumIdSeminarTypesGet(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarTypeApiFp(this.configuration).forumsForumIdSeminarTypesGet(requestParameters.forumId, requestParameters.code, requestParameters.type, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * The seminar type code should be unique in the forum and not in the default seminar types list
     * @summary Create a new forum seminar type
     * @param {ForumSeminarTypeApiForumsForumIdSeminarTypesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarTypeApi
     */
    public forumsForumIdSeminarTypesPost(requestParameters: ForumSeminarTypeApiForumsForumIdSeminarTypesPostRequest, options?: AxiosRequestConfig) {
        return ForumSeminarTypeApiFp(this.configuration).forumsForumIdSeminarTypesPost(requestParameters.forumId, requestParameters.forumSeminarTypeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

