import { useMutation } from "@tanstack/react-query";
import api from "api";

import apiClient from "../../../api-client";
import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

const useCreateFacilityMutation = (options = {}) =>
  useMutation(
    ({ venueId, buildingId, facility }) => api.post(`/venues/${venueId}/buildings/${buildingId}/facilities`, facility),
    options,
  );

const useUpdateFacilityMutation = (options = {}) =>
  useMutation(
    ({ venueId, buildingId, facilityId, facility }) =>
      api.put(`/venues/${venueId}/buildings/${buildingId}/facilities/${facilityId}`, facility),
    options,
  );

const useCreateForumFacilityMutation = (options = {}) =>
  useMutation(
    ({ forumId, buildingId, facility }) =>
      api.post(`/forums/${forumId}/venue/buildings/${buildingId}/facilities`, facility),
    options,
  );

const useUpdateForumFacilityMutation = (options = {}) =>
  useMutation(
    ({ forumId, buildingId, facilityId, facility }) =>
      api.put(`/forums/${forumId}/venue/buildings/${buildingId}/facilities/${facilityId}`, facility),
    options,
  );

const useDeleteForumFacilityMutation = getWrappedUseMutation(apiClient.ForumFacilities.delete);
const useDeleteFacilityMutation = getWrappedUseMutation(apiClient.Facilities.delete);

export {
  useCreateFacilityMutation,
  useCreateForumFacilityMutation,
  useDeleteFacilityMutation,
  useDeleteForumFacilityMutation,
  useUpdateFacilityMutation,
  useUpdateForumFacilityMutation,
};
