import { TableProps } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Space, Table } from "../../../../../../../../../../components/styleguide";
import { ConferenceSelectionData, TabbedConferenceStream } from "../../../../../../../../../../generated/api";
import { removeAt, replaceAt } from "../../../../../../../../../../utils/arrayUtils";

import styles from "../../../../PositiveSelectionV2/components/QuestionnaireMatching/components/Table/styles.module.less";

type Value = TabbedConferenceStream | ConferenceSelectionData;

interface ListingTableProps {
  readonlyCheckboxKey: keyof Value;
  value?: Array<Value>;
  loading?: boolean;
  onChange?: (value: Array<Value>) => void;
}

const ListingTable: FC<ListingTableProps> = ({ readonlyCheckboxKey, value, loading, onChange }) => {
  const { t } = useTranslation();

  const updateByIndex = (index: number, patch: Partial<Value>) => {
    if (!value || !onChange) {
      return;
    }

    const newValue = {
      ...value[index],
      ...patch,
    };

    const newArray = replaceAt(value, index, newValue);
    onChange(newArray);
  };

  const removeByIndex = (index: number) => {
    if (!value || !onChange) {
      return;
    }

    onChange(removeAt(value, index));
  };

  const columns: TableProps<Value>["columns"] = [
    {
      dataIndex: "forumStreamName",
    },
    {
      dataIndex: "showExpanded",
      title: t("Show expanded"),
      render: (showExpanded, record, index) => (
        <Checkbox checked={showExpanded} onChange={(e) => updateByIndex(index, { showExpanded: e.target.checked })} />
      ),
      width: 150,
    },
    {
      dataIndex: "preselectAll",
      title: t("Preselect all"),
      render: (preselectAll, record, index) => (
        <Checkbox checked={preselectAll} onChange={(e) => updateByIndex(index, { preselectAll: e.target.checked })} />
      ),
      width: 150,
    },
    {
      dataIndex: readonlyCheckboxKey,
      title: t("Read only"),
      render: (checked, record, index) => {
        return (
          <Checkbox
            checked={checked}
            onChange={(e) => updateByIndex(index, { [readonlyCheckboxKey]: e.target.checked })}
          />
        );
      },
      width: 150,
    },
    {
      dataIndex: "forumStreamId",
      render: (forumStreamId, record, index) => (
        <Space className={styles.actions}>
          <Button type="primary" onClick={() => removeByIndex(index)}>
            {t("Delete")}
          </Button>
        </Space>
      ),
      width: 100,
    },
  ];

  return <Table columns={columns} dataSource={value} rowKey="forumStreamId" pagination={false} loading={loading} />;
};

export default ListingTable;
