import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useUpdateForumAttendeeMeetingLocationsMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeMeetingLocations.put,
);

export const useUpdateForumAttendeeMeetingLocationsByTimeslotTypeMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeMeetingLocations.putByTimeslotType,
);
