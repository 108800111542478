import "./styles.less";

import Icon, {
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  CalendarOutlined,
  ContactsOutlined,
  HomeOutlined,
  MailOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { UseQueryResult } from "@tanstack/react-query";
import { Anchor } from "antd";
import { useAttendeeQuery } from "api/queries/attendees";
import { useGetForumBuildingQuery } from "api/queries/buildings";
import { useForumBuildingsQuery } from "api/queries/forumBuilding";
import { useGetForumCompanyQuery } from "api/queries/forumCompanies";
import { useGetForumByIdQuery } from "api/queries/forums";
import { useFavoriteForumsQuery } from "api/queries/userPreferences";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { AxiosError, AxiosResponse } from "axios";
import clsx from "clsx";
import { Layout, Menu, Space } from "components/styleguide";
import Breadcrumb from "components/styleguide/components/Breadcrumb";
import UserMenuAvatar from "components/UserMenuAvatar";
import {
  BuildingResponseModel,
  CompanyModel,
  ForumAttendeeResponseModel,
  ForumResponseModel,
  UserInfoModel,
} from "generated/api";
import { toNumber } from "lodash";
import { flattenReports } from "pages/Reporting/helpers";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AnchorGeneratorReturnProps } from "utils/anchorUtils";
import { useCheckMobileScreen } from "utils/screenUtils";

import { ForumUtcOffsetProvider } from "../../contexts/forumUtcOffset";
import { getBreadcrumbsItems } from "./breadcrumbs";
import EventSelect from "./componentns/EventSelect";
import {
  getBuildingMenuItems,
  getCompanyMenuItems,
  getConferenceMenuItems,
  getDefaultOpenKeys,
  getDefaultSelectedKeys,
  getForumAdminMenuItems,
  getProfileMenuItems,
} from "./helpers";
import { getMenuPlaceholders } from "./placeholders";
import { EventLayoutLocation } from "./types";

import styles from "./styles.module.less";

const { Header, Sider, Content } = Layout;

const EventLayout = ({ userInfo }: { userInfo: UseQueryResult<AxiosResponse<UserInfoModel, unknown>, unknown> }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation() as unknown as EventLayoutLocation;
  const {
    id: forumId,
    lang,
    attendeeId,
    venueId,
    buildingId,
    companyId,
    reportKey,
    category,
    subcategory,
    subcategory2,
    subcategory3,
    subcategory4,
  } = useParams();

  const contentRef = useRef<HTMLElement>(null);
  const isMobile = useCheckMobileScreen({ screenWidth: 1024 });

  const [id, setId] = useState<number | undefined>(toNumber(forumId));
  const [selectedForum, setSelectedForum] = useState<ForumResponseModel | null>(null);
  const [favoriteForums, setFavoriteForums] = useState<ForumResponseModel[]>([]);
  const [isMainMenuCollapsed, setIsMainMenuCollapsed] = useState(isMobile);
  const [attendee, setAttendee] = useState<ForumAttendeeResponseModel | null>(null);
  const [attendeeName, setAttendeeName] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [buildings, setBuildings] = useState<BuildingResponseModel[] | null>(null);
  const [openKeys, setOpenKeys] = useState<string[]>(getDefaultOpenKeys(location));
  const [selectedKeys, setSelectedKeys] = useState<string[]>(getDefaultSelectedKeys(location));
  const [buildingName, setBuildingName] = useState<string>();

  const flatReports = useMemo(() => flattenReports(), []);

  useEffect(() => {
    contentRef.current?.scrollTo(0, 0);
  }, [location.pathname]);

  const attendeeQuery = useAttendeeQuery(
    {
      forumId: toNumber(forumId),
      attendeeId: toNumber(attendeeId),
    },
    {
      enabled: !!forumId && !!attendeeId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: ({ data }: { data: ForumAttendeeResponseModel }) => {
        setAttendee(data);
        setAttendeeName(`${data.firstName} ${data.lastName}`);
        if (attendeeId) {
          setOpenKeys(["attendees", "profile"]);
          setSelectedKeys(["#details"]);
        }
      },
    },
  );

  const companyQuery = useGetForumCompanyQuery(
    {
      forumId: toNumber(forumId),
      forumCompanyId: toNumber(companyId),
    },
    {
      enabled: !!forumId && !!companyId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: ({ data }: { data: CompanyModel }) => {
        setCompanyName(data.name);
      },
    },
  );

  useGetForumBuildingQuery(
    {
      forumId: toNumber(forumId),
      buildingId: toNumber(buildingId),
    },
    {
      enabled: !!forumId && !!buildingId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: ({ data }: { data: BuildingResponseModel }) => {
        setBuildingName(data.name);
        setOpenKeys(["venues", data.id?.toString() ?? "venue"]);
        setSelectedKeys(["venues", `${data.id?.toString()}_#details` ?? "venue"]);
      },
    },
  );

  const buildingsQuery = useForumBuildingsQuery(
    {
      forumId: toNumber(forumId),
    },
    {
      enabled: !!forumId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: ({ data }: { data: BuildingResponseModel[] }) => {
        setBuildings(data);
      },
    },
  );

  const getAttendeeMenuItems = () => {
    if (attendeeId) {
      if (!attendee || attendeeQuery.isLoading) {
        return [
          {
            label: t("Profile"),
            key: "profile",
            id: "profile",
            children: getMenuPlaceholders(13),
          },
        ];
      } else {
        return [
          {
            label: t("Profile"),
            key: "profile",
            id: "profile",
            children: getProfileMenuItems(t, attendee),
          },
        ];
      }
    }
    return null;
  };

  const getVenueMenuItems = () => {
    const venueItem = {
      key: "venue" as string | undefined,
      id: "venue" as string | undefined,
      label: <Link to={`venues/${selectedForum?.forumVenueId}`}>{t("Venue")}</Link>,
      children: null as AnchorGeneratorReturnProps[] | null,
    };

    let resultItems = [venueItem, ...(getMenuPlaceholders(5) as AnchorGeneratorReturnProps[])];

    if (selectedForum?.forumVenueId && !buildingsQuery.isLoading) {
      const loadedItems = buildings?.map((building) => {
        return {
          label: <Link to={`venues/${selectedForum?.forumVenueId}/buildings/${building.id}`}>{building.name}</Link>,
          key: building.id?.toString(),
          id: building.id?.toString(),
          children: getBuildingMenuItems(t, location, toNumber(venueId), building.id),
        };
      });
      if (loadedItems) {
        resultItems = [venueItem, ...loadedItems];
      }
    }
    return resultItems;
  };

  const forumQuery = useGetForumByIdQuery(
    { forumId: toNumber(forumId) },
    {
      onSuccess: ({ data }: { data: ForumResponseModel }) => {
        setSelectedForum(data);
      },
      onError: (err) => {
        const error = err as AxiosError;
        if (error.response?.status == 404) {
          navigate("404");
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const favoriteForumsQuery = useFavoriteForumsQuery({
    onSuccess: ({ data }: { data: ForumResponseModel[] }) => {
      setFavoriteForums(data);
    },
    StaleTime: Infinity,
    CacheTime: Infinity,
  });

  const companyItem = {
    key: "company",
    title: t("Company"),
    icon: <BankOutlined />,
    label: t("Company"),
    id: "company",
    children: companyId ? getCompanyMenuItems(t, companyId) : null,
  };

  const menuItems = [
    {
      key: "dashboard",
      icon: <HomeOutlined />,
      title: "Dashboard",
      label: <Link to={"dashboard"}>{t("Dashboard")}</Link>,
      id: "dashboard",
    },
    {
      key: "attendees",
      icon: <UserOutlined />,
      title: "Attendees",
      label: <Link to={"attendees"}>{t("Attendees")}</Link>,
      id: "attendees",
      children: getAttendeeMenuItems(),
    },
    companyId ? companyItem : null,
    {
      key: "locations",
      icon: <ContactsOutlined />,
      title: t("Meeting Locations"),
      label: <Link to={"meeting-locations"}>{t("Meeting Locations")}</Link>,
      id: "locations",
    },
    {
      key: "venues",
      icon: <ScheduleOutlined />,
      title: "Facilities",
      label: <Link to={`venues/${selectedForum?.forumVenueId}`}>{t("Facilities")}</Link>,
      id: "facilities",
      disabled: !Number.isInteger(Number(selectedForum?.forumVenueId)),
      children: getVenueMenuItems(),
    },
    {
      key: "conference",
      icon: <CalendarOutlined />,
      title: "Conference",
      label: <Link to={"conference/stream"}>{t("Conference")}</Link>,
      id: "conference",
      children: getConferenceMenuItems(t),
    },
    {
      key: "forum-admin",
      title: "Forum Admin",
      icon: <SettingOutlined />,
      label: <Link to={"forum-admin/timeslots"}>{t("Forum Admin")}</Link>,
      id: "forum-admin",
      children: getForumAdminMenuItems(t, location),
    },
    {
      key: "reporting",
      title: t("Reporting"),
      icon: <AuditOutlined />,
      label: <Link to={"reporting"}>{t("Reporting")}</Link>,
      id: "reporting",
    },
    {
      key: "email-tracking",
      title: t("Email Tracking"),
      icon: <MailOutlined />,
      label: <Link to={"email-tracking"}>{t("Email Tracking")}</Link>,
      id: "emailTracking",
    },
  ];

  const handleEventChange = (value: string) => {
    setId(toNumber(value));
    navigate(location.pathname.replace(/([0-9]+)/, value));
  };

  const handleMainMenuCollapse = () => {
    setIsMainMenuCollapsed(!isMainMenuCollapsed);
  };

  const openChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (latestOpenKey == "conference") {
      return setOpenKeys(["conference"]);
    }

    if (latestOpenKey == "forum-admin") {
      return setOpenKeys(["forum-admin"]);
    }

    if (latestOpenKey == "company") {
      return setOpenKeys(["company"]);
    }

    if (latestOpenKey == "venues") {
      return setOpenKeys(["venues"]);
    }

    if (openKeys.indexOf("attendees") > -1 && latestOpenKey) {
      return setOpenKeys(["attendees", latestOpenKey]);
    }

    if (openKeys.indexOf("venues") > -1 && latestOpenKey) {
      return setOpenKeys(["venues", latestOpenKey]);
    }

    if (openKeys.indexOf("forum-admin") > -1 && latestOpenKey) {
      return setOpenKeys(["forum-admin", latestOpenKey]);
    }

    setOpenKeys(keys);
  };

  const menuClick = ({ key }: { key: string }) => {
    const rootKeys = ["dashboard", "attendees", "reporting", "email-tracking", "conference"];
    if (rootKeys.includes(key)) {
      setOpenKeys([]);
    }
    setSelectedKeys([key]);
  };

  const anchorChange = (currentActiveLink: string) => {
    const activeLink = currentActiveLink.length > 0 ? currentActiveLink : location.hash;

    setSelectedKeys([buildingId ? `${buildingId}_${activeLink}` : currentActiveLink]);
  };

  // added this effect because "id" state wouldn't update from params.id
  // when clicking browser Back button
  useEffect(() => {
    if (forumId) {
      setId(parseInt(forumId));
    }
  }, [forumId]);

  useEffect(() => {
    if (venueId && !location.hash) {
      setSelectedKeys(["venues", "venue"]);
      setOpenKeys(["venues"]);
    }
  }, [venueId, location.hash]);

  useEffect(() => {
    if (!attendeeId) {
      setAttendeeName(null);
    }
  }, [attendeeId]);

  useEffect(() => {
    if (companyId) {
      setOpenKeys(["company"]);
    }
  }, [companyId]);

  useEffect(() => {
    if (buildingId) {
      setBuildingName(buildings?.find((b) => b.id?.toString() === buildingId)?.name);
    }
  }, [buildingId]);

  return (
    <ForumUtcOffsetProvider forumId={toNumber(id)}>
      <Layout hasSider={true}>
        <Sider
          collapsed={isMainMenuCollapsed}
          id="menu"
          className={`menu ${styles.sider}`}
          width={"400px"}
          style={{
            overflow: "auto",
            height: "100%",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            paddingBottom: "20px",
            zIndex: 1000,
          }}
        >
          <Icon
            id="menu-fold-icon"
            className="menu-fold-icon"
            component={
              (isMainMenuCollapsed
                ? MenuUnfoldOutlined
                : MenuFoldOutlined) as React.ForwardRefExoticComponent<CustomIconComponentProps>
            }
            onClick={handleMainMenuCollapse}
          />
          <div>
            <EventSelect
              favoriteForums={favoriteForums}
              selectedForum={selectedForum}
              loading={favoriteForumsQuery.isLoading || forumQuery.isLoading}
              isCollapsed={isMainMenuCollapsed}
              onChange={handleEventChange}
            />
          </div>
          <div className="delimiter" />
          <div>
            <Anchor
              style={{ overflow: "hidden", maxHeight: "initial" }}
              affix={false}
              getContainer={() => document.getElementsByTagName("main")[0]}
              onChange={anchorChange}
              bounds={200}
            >
              <Menu
                items={menuItems}
                selectedKeys={selectedKeys}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={openChange}
                onClick={menuClick}
              />
            </Anchor>
          </div>
        </Sider>
        <Layout style={{ marginLeft: isMainMenuCollapsed || isMobile ? "80px" : "400px" }}>
          <Header className={clsx("event-layout-header", styles.header)}>
            <div className={styles.breadcrumbsWrapper}>
              <Link to={`/${lang}`} className={styles.logoLink}>
                <Logo width={140} />
              </Link>
              <div className={"page-name"}>
                <Breadcrumb
                  breadcrumbs={getBreadcrumbsItems({
                    t,
                    location,
                    attendeeName: !attendeeQuery.isLoading && attendeeName ? attendeeName : t("loading"),
                    companyName: !companyQuery.isLoading && companyName ? companyName : t("loading"),
                    venueName: selectedForum?.forumVenueName ?? t("loading"),
                    buildingName: buildingName ?? t("loading"),
                    reportKey,
                    category,
                    subcategory,
                    subcategory2,
                    subcategory3,
                    subcategory4,
                    flatReports,
                  })}
                  excludePaths={["/", "/:lang/forum", "/:lang/forum/:id", "/:lang"]}
                />
              </div>
            </div>
            <div>
              <Space direction="horizontal" fullWidth={true} size={"large"}>
                <Link to="playbook" className="icon system-admin-nav">
                  <Icon component={AppstoreOutlined as React.ForwardRefExoticComponent<CustomIconComponentProps>} />
                  <span>{t("Playbook")}</span>
                </Link>
                <UserMenuAvatar userInfo={userInfo} />
              </Space>
            </div>
          </Header>
          <Layout>
            <Content ref={contentRef} className="event-layout-content">
              <Outlet context={{ id, setId, forum: selectedForum }} />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </ForumUtcOffsetProvider>
  );
};

EventLayout.propTypes = {
  userInfo: PropTypes.any,
};

export default EventLayout;
