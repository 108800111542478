import { Button } from "components/styleguide";
import { AttendeeAvailableToBookModel } from "generated/api";
import { t } from "i18next";
import { buildAttendeeColumn } from "pages/Attendee/components/AttendeePreferences/common/utils";
import { Dispatch, SetStateAction } from "react";
import { buildColumn } from "utils/columnUtils";

export const getAvailableAttendeesColumns = (
  executiveAttendeeId: number | undefined,
  setExecutiveAttendeeId: Dispatch<SetStateAction<number | undefined>>,
  setAttendeeToMeetWithId: Dispatch<SetStateAction<number | undefined>>,
) => [
  buildColumn(t("Attendee"), "firstName", {
    render: (_: string, { firstName, lastName, jobTitle, companyName }: AttendeeAvailableToBookModel) =>
      buildAttendeeColumn(`${firstName} ${lastName}`, null, jobTitle, companyName),
  }),
  buildColumn(t("Ratio"), "ratio"),
  buildColumn(t("Booked"), "booked"),
  buildColumn(t("Available"), "available"),
  buildColumn("", "id", {
    width: "5%",
    render: (id: number) => (
      <Button
        type={id === executiveAttendeeId ? "primary" : "default"}
        onClick={() => {
          setAttendeeToMeetWithId(undefined);
          setExecutiveAttendeeId(id === executiveAttendeeId ? undefined : id);
        }}
      >
        {t("Select").toString()}
      </Button>
    ),
  }),
];

export const getAvailablePreferencesColumns = (
  attendeeToMeetWithId: number | undefined,
  setAttendeeToMeetWithId: Dispatch<SetStateAction<number | undefined>>,
) => [
  buildColumn(t("Attendee"), "firstName", {
    render: (_: string, { firstName, lastName, jobTitle, companyName }: AttendeeAvailableToBookModel) =>
      buildAttendeeColumn(`${firstName} ${lastName}`, null, jobTitle, companyName),
  }),
  buildColumn(t("Table"), "tableNumber"),
  buildColumn(t("Booked"), "booked"),
  buildColumn(t("Priority for"), "priorityFor"),
  buildColumn(t("Priority by"), "priorityBy"),
  buildColumn("", "id", {
    width: "5%",
    render: (id: number) => (
      <Button
        type={id === attendeeToMeetWithId ? "primary" : "default"}
        onClick={() => setAttendeeToMeetWithId(id === attendeeToMeetWithId ? undefined : id)}
      >
        {t("Select").toString()}
      </Button>
    ),
  }),
];
