import { FC } from "react";

import { Space } from "../../../../../../../../../components/styleguide";
import { EventOptionsChoice } from "../../../../../../../../../generated/api";
import withOnChange from "../../../utils/withOnChange";
import AddChoice from "../AddChoice";
import ListingTable from "../ListingTable";

interface ChoicesProps {
  value?: Array<EventOptionsChoice>;
  onChange?: (value: Array<EventOptionsChoice>) => void;
}

const Choices: FC<ChoicesProps> = ({ value, onChange }) => {
  const handleAdd = (choice: EventOptionsChoice) => {
    onChange?.([...(value ?? []), choice]);
  };

  return (
    <Space direction="vertical" fullWidth>
      <AddChoice onAdd={handleAdd} />
      <ListingTable choices={value ?? []} onChange={onChange} />
    </Space>
  );
};

export default withOnChange(Choices);
