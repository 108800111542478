import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { useQuestionnairesQuery } from "../../../../../../../../../api/queries/questionnaires";
import { AsyncSelect } from "../../../inputs";

const convertToString = (id, contentVersion) => {
  return `${id}/${contentVersion}`;
};

const convertFromString = (value) => {
  const [id, contentVersion] = value.split("/").map((v) => parseInt(v, 10));
  return { id, contentVersion };
};

const QuestionnaireSelectWithVersion = ({ forumId, contentVersion, value, onContentVersionChange, onChange }) => {
  const { t } = useTranslation();

  const stringValue = value ? convertToString(value, contentVersion) : value;

  const query = useQuestionnairesQuery({ forumId });

  const optionsMapper = (response) => {
    const isCurrentValueInResponse = response.some(
      (questionnaire) => questionnaire.id === value && questionnaire.contentVersion === contentVersion,
    );

    const options = response.map((questionnaire) => ({
      value: convertToString(questionnaire.id, questionnaire.contentVersion),
      label: questionnaire.name,
    }));

    if (isCurrentValueInResponse || !value) {
      return options;
    }

    return [
      {
        value: stringValue,
        label: t("Select new questionnaire"),
      },
      ...options,
    ];
  };

  const handleChange = (newValue) => {
    if (!onChange) {
      return;
    }

    const selected = convertFromString(newValue);
    onContentVersionChange(selected.contentVersion);
    onChange(selected.id);
  };

  return <AsyncSelect query={query} optionsMapper={optionsMapper} value={stringValue} onChange={handleChange} />;
};

QuestionnaireSelectWithVersion.propTypes = {
  forumId: PropTypes.number.isRequired,
  contentVersion: PropTypes.number,
  value: PropTypes.number,
  onContentVersionChange: PropTypes.func,
  onChange: PropTypes.func,
};

QuestionnaireSelectWithVersion.defaultProps = {
  value: undefined,
  onContentVersionChange: () => undefined,
  onChange: () => undefined,
};

export default QuestionnaireSelectWithVersion;
