/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumSeminarSessionRequestModel } from '../apiModels';
// @ts-ignore
import { ForumSeminarSessionResponseModel } from '../apiModels';
// @ts-ignore
import { ForumSeminarSessionWithTimeslotSession } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * ForumSeminarSessionApi - axios parameter creator
 * @export
 */
export const ForumSeminarSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a seminar session
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {number} forumSeminarSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete: async (forumId: number, forumSeminarId: number, forumSeminarSessionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete', 'forumId', forumId)
            // verify required parameter 'forumSeminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete', 'forumSeminarId', forumSeminarId)
            // verify required parameter 'forumSeminarSessionId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete', 'forumSeminarSessionId', forumSeminarSessionId)
            const localVarPath = `/forums/{forumId}/seminars/{forumSeminarId}/sessions/{forumSeminarSessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarId"}}`, encodeURIComponent(String(forumSeminarId)))
                .replace(`{${"forumSeminarSessionId"}}`, encodeURIComponent(String(forumSeminarSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a seminar session
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {number} forumSeminarSessionId 
         * @param {ForumSeminarSessionRequestModel} [forumSeminarSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut: async (forumId: number, forumSeminarId: number, forumSeminarSessionId: number, forumSeminarSessionRequestModel?: ForumSeminarSessionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut', 'forumId', forumId)
            // verify required parameter 'forumSeminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut', 'forumSeminarId', forumSeminarId)
            // verify required parameter 'forumSeminarSessionId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut', 'forumSeminarSessionId', forumSeminarSessionId)
            const localVarPath = `/forums/{forumId}/seminars/{forumSeminarId}/sessions/{forumSeminarSessionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarId"}}`, encodeURIComponent(String(forumSeminarId)))
                .replace(`{${"forumSeminarSessionId"}}`, encodeURIComponent(String(forumSeminarSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarSessionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the sessions within a forum seminar
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsGet: async (forumId: number, forumSeminarId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsGet', 'forumId', forumId)
            // verify required parameter 'forumSeminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsGet', 'forumSeminarId', forumSeminarId)
            const localVarPath = `/forums/{forumId}/seminars/{forumSeminarId}/sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarId"}}`, encodeURIComponent(String(forumSeminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * **409 ValueOutOfRangeException** error response example:  ```      {          \"code\": 3,          \"type\": \"ValueOutOfRange\",          \"meta\": {              \"propertyName\": \"Places\",              \"propertyValue\": 1200,              \"referenceEntityName\": \"ForumMeetingFacility\",              \"rangeStartPropertyName\": \"1\",              \"rangeStartValue\": 1,              \"rangeEndPropertyName\": \"Places\",              \"rangeEnd\": 1000,          }      }  ```  **409 DuplicateItemException** error response example:  ```  {      {          \"code\": 1,          \"type\": \"DuplicateItem\",          \"meta\": {              \"itemType\": \"ForumSeminarSession\",              \"itemFields\": {                  \"ForumMeetingFacilityId\": 4,                  \"TimeSlotSessionId\": 72              },              \"relatedItemType\": null,              \"relatedItemFields\": null          }      }  }  ```
         * @summary Create a seminar session within a forum seminar
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {ForumSeminarSessionRequestModel} [forumSeminarSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsPost: async (forumId: number, forumSeminarId: number, forumSeminarSessionRequestModel?: ForumSeminarSessionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsPost', 'forumId', forumId)
            // verify required parameter 'forumSeminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdSessionsPost', 'forumSeminarId', forumSeminarId)
            const localVarPath = `/forums/{forumId}/seminars/{forumSeminarId}/sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarId"}}`, encodeURIComponent(String(forumSeminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarSessionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumSeminarSessionApi - functional programming interface
 * @export
 */
export const ForumSeminarSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumSeminarSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a seminar session
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {number} forumSeminarSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete(forumId: number, forumSeminarId: number, forumSeminarSessionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete(forumId, forumSeminarId, forumSeminarSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a seminar session
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {number} forumSeminarSessionId 
         * @param {ForumSeminarSessionRequestModel} [forumSeminarSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut(forumId: number, forumSeminarId: number, forumSeminarSessionId: number, forumSeminarSessionRequestModel?: ForumSeminarSessionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut(forumId, forumSeminarId, forumSeminarSessionId, forumSeminarSessionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the sessions within a forum seminar
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsForumSeminarIdSessionsGet(forumId: number, forumSeminarId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumSeminarSessionWithTimeslotSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsForumSeminarIdSessionsGet(forumId, forumSeminarId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * **409 ValueOutOfRangeException** error response example:  ```      {          \"code\": 3,          \"type\": \"ValueOutOfRange\",          \"meta\": {              \"propertyName\": \"Places\",              \"propertyValue\": 1200,              \"referenceEntityName\": \"ForumMeetingFacility\",              \"rangeStartPropertyName\": \"1\",              \"rangeStartValue\": 1,              \"rangeEndPropertyName\": \"Places\",              \"rangeEnd\": 1000,          }      }  ```  **409 DuplicateItemException** error response example:  ```  {      {          \"code\": 1,          \"type\": \"DuplicateItem\",          \"meta\": {              \"itemType\": \"ForumSeminarSession\",              \"itemFields\": {                  \"ForumMeetingFacilityId\": 4,                  \"TimeSlotSessionId\": 72              },              \"relatedItemType\": null,              \"relatedItemFields\": null          }      }  }  ```
         * @summary Create a seminar session within a forum seminar
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {ForumSeminarSessionRequestModel} [forumSeminarSessionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsForumSeminarIdSessionsPost(forumId: number, forumSeminarId: number, forumSeminarSessionRequestModel?: ForumSeminarSessionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumSeminarSessionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsForumSeminarIdSessionsPost(forumId, forumSeminarId, forumSeminarSessionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumSeminarSessionApi - factory interface
 * @export
 */
export const ForumSeminarSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumSeminarSessionApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a seminar session
         * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.forumSeminarSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a seminar session
         * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.forumSeminarSessionId, requestParameters.forumSeminarSessionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the sessions within a forum seminar
         * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsGet(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumSeminarSessionWithTimeslotSession>> {
            return localVarFp.forumsForumIdSeminarsForumSeminarIdSessionsGet(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * **409 ValueOutOfRangeException** error response example:  ```      {          \"code\": 3,          \"type\": \"ValueOutOfRange\",          \"meta\": {              \"propertyName\": \"Places\",              \"propertyValue\": 1200,              \"referenceEntityName\": \"ForumMeetingFacility\",              \"rangeStartPropertyName\": \"1\",              \"rangeStartValue\": 1,              \"rangeEndPropertyName\": \"Places\",              \"rangeEnd\": 1000,          }      }  ```  **409 DuplicateItemException** error response example:  ```  {      {          \"code\": 1,          \"type\": \"DuplicateItem\",          \"meta\": {              \"itemType\": \"ForumSeminarSession\",              \"itemFields\": {                  \"ForumMeetingFacilityId\": 4,                  \"TimeSlotSessionId\": 72              },              \"relatedItemType\": null,              \"relatedItemFields\": null          }      }  }  ```
         * @summary Create a seminar session within a forum seminar
         * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdSessionsPost(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumSeminarSessionResponseModel> {
            return localVarFp.forumsForumIdSeminarsForumSeminarIdSessionsPost(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.forumSeminarSessionRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete operation in ForumSeminarSessionApi.
 * @export
 * @interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDeleteRequest
 */
export interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete
     */
    readonly forumSeminarId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete
     */
    readonly forumSeminarSessionId: number
}

/**
 * Request parameters for forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut operation in ForumSeminarSessionApi.
 * @export
 * @interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPutRequest
 */
export interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut
     */
    readonly forumSeminarId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut
     */
    readonly forumSeminarSessionId: number

    /**
     * 
     * @type {ForumSeminarSessionRequestModel}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut
     */
    readonly forumSeminarSessionRequestModel?: ForumSeminarSessionRequestModel
}

/**
 * Request parameters for forumsForumIdSeminarsForumSeminarIdSessionsGet operation in ForumSeminarSessionApi.
 * @export
 * @interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGetRequest
 */
export interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGet
     */
    readonly forumSeminarId: number

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsForumIdSeminarsForumSeminarIdSessionsPost operation in ForumSeminarSessionApi.
 * @export
 * @interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPostRequest
 */
export interface ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPost
     */
    readonly forumSeminarId: number

    /**
     * 
     * @type {ForumSeminarSessionRequestModel}
     * @memberof ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPost
     */
    readonly forumSeminarSessionRequestModel?: ForumSeminarSessionRequestModel
}

/**
 * ForumSeminarSessionApi - object-oriented interface
 * @export
 * @class ForumSeminarSessionApi
 * @extends {BaseAPI}
 */
export class ForumSeminarSessionApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a seminar session
     * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarSessionApi
     */
    public forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumSeminarSessionApiFp(this.configuration).forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.forumSeminarSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a seminar session
     * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarSessionApi
     */
    public forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPutRequest, options?: AxiosRequestConfig) {
        return ForumSeminarSessionApiFp(this.configuration).forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.forumSeminarSessionId, requestParameters.forumSeminarSessionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the sessions within a forum seminar
     * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarSessionApi
     */
    public forumsForumIdSeminarsForumSeminarIdSessionsGet(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarSessionApiFp(this.configuration).forumsForumIdSeminarsForumSeminarIdSessionsGet(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * **409 ValueOutOfRangeException** error response example:  ```      {          \"code\": 3,          \"type\": \"ValueOutOfRange\",          \"meta\": {              \"propertyName\": \"Places\",              \"propertyValue\": 1200,              \"referenceEntityName\": \"ForumMeetingFacility\",              \"rangeStartPropertyName\": \"1\",              \"rangeStartValue\": 1,              \"rangeEndPropertyName\": \"Places\",              \"rangeEnd\": 1000,          }      }  ```  **409 DuplicateItemException** error response example:  ```  {      {          \"code\": 1,          \"type\": \"DuplicateItem\",          \"meta\": {              \"itemType\": \"ForumSeminarSession\",              \"itemFields\": {                  \"ForumMeetingFacilityId\": 4,                  \"TimeSlotSessionId\": 72              },              \"relatedItemType\": null,              \"relatedItemFields\": null          }      }  }  ```
     * @summary Create a seminar session within a forum seminar
     * @param {ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarSessionApi
     */
    public forumsForumIdSeminarsForumSeminarIdSessionsPost(requestParameters: ForumSeminarSessionApiForumsForumIdSeminarsForumSeminarIdSessionsPostRequest, options?: AxiosRequestConfig) {
        return ForumSeminarSessionApiFp(this.configuration).forumsForumIdSeminarsForumSeminarIdSessionsPost(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.forumSeminarSessionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

