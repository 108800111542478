import { SelectionSiteContent, SelectionSiteItemType } from "../generated/api";
import { stringKeyToString } from "./stringUtils";

type Page = NonNullable<SelectionSiteContent["pages"]>[0];
type Item = NonNullable<Page["items"]>[0];

const PREFIX = "selection-site";

const NAV_PREFIX = `${PREFIX}_nav_`;
const PAGE_PREFIX = `${PREFIX}_page_`;
const ITEM_PREFIX = `${PREFIX}_item_`;

const KNOWN_ITEM_TYPES = new Set(Object.values(SelectionSiteItemType));

export const generateNavId = (id: string) => {
  return `${NAV_PREFIX}${id}`;
};

export const getIdFromNavId = (navId: string) => {
  return navId.slice(NAV_PREFIX.length);
};

export const generatePageId = (pageId: number) => {
  return `${PAGE_PREFIX}${pageId}`;
};

export const isPageId = (id: string): boolean => {
  return id.startsWith(PAGE_PREFIX);
};

export const getPageIdFromId = (id: string) => {
  if (!isPageId(id)) {
    const idString = id.slice(ITEM_PREFIX.length);
    return parseInt(idString);
  }

  const idString = id.slice(PAGE_PREFIX.length);
  return parseInt(idString);
};

export const generateItemId = (pageId: number, itemId: number) => {
  return `${ITEM_PREFIX}${pageId}_${itemId}`;
};

export const isItemId = (id: string) => {
  return id.startsWith(ITEM_PREFIX);
};

export const getItemIdFromId = (id: string) => {
  if (!isItemId(id)) {
    return null;
  }

  const pageAndItemIds = id.slice(ITEM_PREFIX.length);
  const [page, item] = pageAndItemIds.split("_").map((str) => parseInt(str));
  return [page, item];
};

export const getItemsFromPages = (pages: Array<Page>): Array<Item> => {
  return pages.reduce<Array<Page>>((accumulator, page) => {
    return [...accumulator, ...(page.items ?? [])];
  }, []);
};

export const filterItemsByType = (items: Array<Item>, types: Array<SelectionSiteItemType>) => {
  if (types.length === 0) {
    return [];
  }

  const typesSet = new Set(types);
  return items.filter((item) => item.type && typesSet.has(item.type));
};

const splitStringWithVersion = (str: string): [string] | [string, string] => {
  const index = str.search(/V[0-9]$/);
  if (index === -1) {
    return [str];
  }

  return [str.slice(0, index), str.slice(index)];
};

export const itemTypeToString = (type: SelectionSiteItemType) => {
  if (!KNOWN_ITEM_TYPES.has(type)) {
    return "";
  }

  const [name, version] = splitStringWithVersion(type);
  const n = stringKeyToString(name);

  return `${n} ${version ?? ""}`.trim();
};

export const checkSetOrderUp = (index: number) => index <= 1 || !index || false;

export const checkSetOrderDown = (index: number, itemLength: number) => index == itemLength || !index || false;
