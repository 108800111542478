import { Drawer } from "components/styleguide";
import { ForumCompanyPrimaryLocationModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import ForumCompanyPrimaryLocationForm from "./ForumCompanyPrimaryLocationForm";

interface ForumCompanyPrimaryLocationDrawerProps {
  forumId: number;
  forumCompanyId: number;
  isVisible: boolean;
  initialValues: ForumCompanyPrimaryLocationModel | null;
  onClose: () => void;
}

const ForumCompanyPrimaryLocationDrawer: FC<ForumCompanyPrimaryLocationDrawerProps> = ({
  forumId,
  forumCompanyId,
  isVisible,
  initialValues,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={isVisible}
      placement="right"
      title={initialValues ? t("Edit Primary Location") : t("Add Primary Location")}
      onClose={onClose}
      destroyOnClose
      contentWrapperStyle={{ minWidth: "40%" }}
    >
      <ForumCompanyPrimaryLocationForm
        forumId={forumId}
        forumCompanyId={forumCompanyId}
        initialValues={initialValues}
        onDrawerClose={onClose}
      />
    </Drawer>
  );
};

export default ForumCompanyPrimaryLocationDrawer;
