import React from "react";
import { Input } from "components/styleguide";

const { TextArea } = Input;

const getFormFields = ({ t, validateCodeFormat, validateUniqueCode }) => [
  {
    title: "Code",
    dataIndex: "code",
    rules: [
      { required: true, message: t("errors.required", { prop: "$t(Code)" }) },
      {
        validator: validateCodeFormat,
        message: t("Code can only be a single capital letter"),
      },
      {
        validator: validateUniqueCode,
        message: t("This Code already exists"),
      },
    ],
  },
  {
    title: "Type",
    dataIndex: "type",
    rules: [
      { required: true, message: t("errors.required", { prop: "$t(Type)" }) },
    ],
  },
  {
    title: "Description",
    dataIndex: "description",
    component: <TextArea rows={6} />,
  },
];

export { getFormFields };
