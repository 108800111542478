/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DuplicateItemExceptionResponse } from '../apiModels';
// @ts-ignore
import { FacilityModel } from '../apiModels';
// @ts-ignore
import { FacilityRequestModel } from '../apiModels';
// @ts-ignore
import { FacilityResponseModel } from '../apiModels';
// @ts-ignore
import { FacilityType } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundException } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * FacilityApi - axios parameter creator
 * @export
 */
export const FacilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Also deletes all tables associated to the facility
         * @summary Delete a facility from a venue
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete: async (venueId: number, buildingId: number, facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete', 'facilityId', facilityId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get facility
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet: async (venueId: number, buildingId: number, facilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet', 'facilityId', facilityId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a facility
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut: async (venueId: number, buildingId: number, facilityId: number, facilityRequestModel?: FacilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut', 'buildingId', buildingId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut', 'facilityId', facilityId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities/{facilityId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all Facilities
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FacilityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesGet: async (venueId: number, buildingId: number, orderBy?: string, orderDir?: string, type?: FacilityType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesGet', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesGet', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Facility in the specified Venue and Building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesPost: async (venueId: number, buildingId: number, facilityRequestModel?: FacilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesPost', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdFacilitiesPost', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/facilities`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacilityApi - functional programming interface
 * @export
 */
export const FacilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacilityApiAxiosParamCreator(configuration)
    return {
        /**
         * Also deletes all tables associated to the facility
         * @summary Delete a facility from a venue
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete(venueId: number, buildingId: number, facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete(venueId, buildingId, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get facility
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet(venueId: number, buildingId: number, facilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet(venueId, buildingId, facilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a facility
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} facilityId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut(venueId: number, buildingId: number, facilityId: number, facilityRequestModel?: FacilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut(venueId, buildingId, facilityId, facilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all Facilities
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FacilityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesGet(venueId: number, buildingId: number, orderBy?: string, orderDir?: string, type?: FacilityType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesGet(venueId, buildingId, orderBy, orderDir, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Facility in the specified Venue and Building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {FacilityRequestModel} [facilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdFacilitiesPost(venueId: number, buildingId: number, facilityRequestModel?: FacilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacilityResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdFacilitiesPost(venueId, buildingId, facilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacilityApi - factory interface
 * @export
 */
export const FacilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacilityApiFp(configuration)
    return {
        /**
         * Also deletes all tables associated to the facility
         * @summary Delete a facility from a venue
         * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get facility
         * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FacilityResponseModel> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a facility
         * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.facilityRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all Facilities
         * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesGet(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FacilityResponseModel>> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Facility in the specified Venue and Building
         * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdFacilitiesPost(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPostRequest, options?: AxiosRequestConfig): AxiosPromise<FacilityResponseModel> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdFacilitiesPost(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete operation in FacilityApi.
 * @export
 * @interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDeleteRequest
 */
export interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete
     */
    readonly facilityId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet operation in FacilityApi.
 * @export
 * @interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGetRequest
 */
export interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet
     */
    readonly facilityId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut operation in FacilityApi.
 * @export
 * @interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPutRequest
 */
export interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut
     */
    readonly facilityId: number

    /**
     * 
     * @type {FacilityRequestModel}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut
     */
    readonly facilityRequestModel?: FacilityRequestModel
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesGet operation in FacilityApi.
 * @export
 * @interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest
 */
export interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGet
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGet
     */
    readonly buildingId: number

    /**
     * 
     * @type {string}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {FacilityType}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGet
     */
    readonly type?: FacilityType
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdFacilitiesPost operation in FacilityApi.
 * @export
 * @interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPostRequest
 */
export interface FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPost
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPost
     */
    readonly buildingId: number

    /**
     * 
     * @type {FacilityRequestModel}
     * @memberof FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPost
     */
    readonly facilityRequestModel?: FacilityRequestModel
}

/**
 * FacilityApi - object-oriented interface
 * @export
 * @class FacilityApi
 * @extends {BaseAPI}
 */
export class FacilityApi extends BaseAPI {
    /**
     * Also deletes all tables associated to the facility
     * @summary Delete a facility from a venue
     * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDeleteRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get facility
     * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGetRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a facility
     * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPutRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityId, requestParameters.facilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all Facilities
     * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesGet(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesGet(requestParameters.venueId, requestParameters.buildingId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Facility in the specified Venue and Building
     * @param {FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacilityApi
     */
    public venuesVenueIdBuildingsBuildingIdFacilitiesPost(requestParameters: FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesPostRequest, options?: AxiosRequestConfig) {
        return FacilityApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdFacilitiesPost(requestParameters.venueId, requestParameters.buildingId, requestParameters.facilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

