import { Form } from "components/styleguide";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ConferenceSelectionV2Item, TabbedConferenceSelectionItem } from "../../../../../../../generated/api";
import DefaultForm from "../DefaultForm";
import { ConferenceSessionsShown, Setup, YesNo } from "../inputs";

interface TabbedConferenceSelectionProps {
  forumId: number;
  item: TabbedConferenceSelectionItem;
  onChange: (item: TabbedConferenceSelectionItem) => void;
}

const TabbedConferenceSelection: FC<TabbedConferenceSelectionProps> = ({ forumId, item, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (values: Partial<ConferenceSelectionV2Item>) => {
    const newValues = { ...item, ...values };

    return onChange(newValues);
  };

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      onChange={handleChange}
      options={() => (
        <>
          <Form.Item name="conferenceStreams" label={t("Set up the conference sessions shown")}>
            <Setup
              formTitle={t("Set up the conference sessions shown")}
              form={{
                element: ConferenceSessionsShown,
                props: {
                  forumId,
                  readonlyCheckboxKey: "readonly",
                },
              }}
            />
          </Form.Item>
          <Form.Item name="displayLocalisedLabels" label={t("Display localized labels")}>
            <YesNo />
          </Form.Item>
        </>
      )}
    />
  );
};

export default TabbedConferenceSelection;
