import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Select } from "../../../../../../../../../../../components/styleguide";

import styles from "./styles.module.less";

const getSelect = (name, entity) => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: true,
          message: entity.errorMessage,
        },
      ]}
    >
      <Select placeholder={entity.placeholder}>
        {entity.options.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

const Mapper = ({ first, second, onSubmit, onMatchesAutoCreate, isAutoMatchesLoading }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const handleFormSubmit = (values) => {
    const firstQuestion = values.first;
    const secondQuestion = values.second;

    onSubmit(firstQuestion, secondQuestion);
    form.resetFields();
  };

  return (
    <Form className={styles.table} form={form} onFinish={handleFormSubmit}>
      <table className={styles.table}>
        <thead>
          <tr>
            <td style={{ width: "50%" }}>{first.title}</td>
            <td style={{ width: "50%" }}>{second.title}</td>
            <td style={{ width: 160 }}>
              {onMatchesAutoCreate && (
                <div className={styles.actions}>
                  <Button block type="primary" onClick={onMatchesAutoCreate} loading={isAutoMatchesLoading}>
                    {t("Create Matches")}
                  </Button>
                </div>
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getSelect("first", first)}</td>
            <td>{getSelect("second", second)}</td>
            <td>
              <div className={styles.actions}>
                <Button block type="primary" htmlType="submit">
                  {t("Add")}
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Form>
  );
};

Mapper.propTypes = {
  first: PropTypes.shape({
    title: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
  second: PropTypes.shape({
    title: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    placeholder: PropTypes.string,
    errorMessage: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onMatchesAutoCreate: PropTypes.func,
  isAutoMatchesLoading: PropTypes.bool,
};

Mapper.defaultProps = {
  first: {
    title: "",
    options: [],
    placeholder: "",
    errorMessage: undefined,
  },
  second: {
    title: "",
    options: [],
    placeholder: "",
    errorMessage: undefined,
  },
  onSubmit: () => undefined,
};

export default Mapper;
