import { useExportAttendeesQuery } from "api/queries/attendees";
import DropdownButton from "components/DropdownButton";
import { AttendeeStatus, FileExportType } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

interface ExportAttendeesButtonProps {
  firstName?: string;
  lastName?: string;
  company?: string;
  jobTitle?: string;
  email?: string;
  attendeeStatus?: Array<AttendeeStatus>;
  group?: string;
  peopleTypes?: Array<string>;
  orderBy?: string;
  orderDir?: string;
}

const ExportAttendeesButton: FC<ExportAttendeesButtonProps> = ({
  firstName,
  lastName,
  company,
  jobTitle,
  email,
  attendeeStatus,
  group,
  peopleTypes,
  orderBy,
  orderDir,
}) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [documentType, setDocumentType] = useState<FileExportType | null>(null);

  const { isFetching: isAttendeesExportLoading } = useExportAttendeesQuery(
    {
      forumId,
      firstName,
      lastName,
      company,
      jobTitle,
      email,
      attendeeStatus,
      group,
      peopleTypes,
      orderBy,
      orderDir,
      exportType: documentType as FileExportType,
    },
    {
      enabled: !!documentType,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
      onSettled: () => setDocumentType(null),
    },
  );

  const items = [
    {
      label: t("Excel"),
      key: "excel",
      onClick: () => setDocumentType(FileExportType.Xls),
    },
    {
      label: t("CSV"),
      key: "csv",
      onClick: () => setDocumentType(FileExportType.Csv),
    },
  ];

  return <DropdownButton title="Export" items={items} isLoading={isAttendeesExportLoading} />;
};

export default ExportAttendeesButton;
