import { Form } from "components/styleguide";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { validateIsNotEqual } from "utils/validatorUtils";

import DefaultForm from "../DefaultForm";
import { DependentFormItem, Input, Setup, YesNo } from "../inputs";
import GroupSelectingFrom from "../inputs/GroupSelectingFrom";
import AttendeeImageDisplayForumGroups from "./components/AttendeeImageDisplayForumGroups";
import PageSizesAvailableForTheSelectionGrid from "./components/PageSizesAvailableForTheSelectionGrid";
import PrefilledListOrder from "./components/PrefilledListOrder";
import QuestionnaireMatching from "./components/QuestionnaireMatching";
import QuestionnaireProfileDetailsItem from "./components/QuestionnaireProfileDetailsItem";
import QuestionnaireSearchIndex from "./components/QuestionnaireSearchIndex";
import QuestionnaireSelectWithVersion from "./components/QuestionnaireSelectWithVersion";
import useQuestionnaireVersionValidator from "./hooks/useQuestionnaireVersionValidator";
import handleFieldsChange, {
  FIELDS_TO_CLEAR_ON_QBS_CHANGE,
  FIELDS_TO_CLEAR_ON_QMS_CHANGE,
} from "./utils/handleFieldsChange";

const PositiveSelectionV2 = ({ forumId, item, onChange }) => {
  const { t } = useTranslation();

  // Ant.d god decided that input in Form.Item should ignore that we pass a new value to it,
  // after cleaning questionnaires of group making selections and questionnaires of group being selected
  // when group selecting from is changed. So we're making kinda dirty hack here to force update
  // those inputs and start sending events back to the form again when their values were changed.
  const [groupId, setGroupId] = useState(item.groupSelectingFrom);

  const questionnaireVersionValidator = useQuestionnaireVersionValidator(forumId);

  const dataNormalizer = (changedFields, allFields) => {
    return handleFieldsChange(item, allFields);
  };

  const handleChange = (values) => {
    const newValues = {
      ...item,
      ...values,
    };

    setGroupId(newValues.groupSelectingFrom);

    return onChange(newValues);
  };

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      dataNormalizer={dataNormalizer}
      onChange={handleChange}
      options={(form) => (
        <>
          <Form.Item name="groupSelectingFrom" label={t("Group selecting from")}>
            <GroupSelectingFrom forumId={forumId} primary />
          </Form.Item>

          <DependentFormItem
            name="questionnaireOfGroupBeingSelected"
            label={t("Questionnaire of group being selected")}
            dependencies={["questionnaireOfGroupMakingSelectionsContentVersion"]}
            dependency={{
              name: "groupSelectingFrom",
              label: t("Group selecting from"),
            }}
            rules={[
              {
                validator: validateIsNotEqual(form, "questionnaireOfGroupMakingSelections"),
                message: t(
                  "Questionnaire of group being selected must be different from the questionnaire of group making selections",
                ),
              },
              {
                ...questionnaireVersionValidator(form, "questionnaireOfGroupBeingSelectedVersion"),
                message: t("The questionnaire was updated. Please select a new one."),
              },
            ]}
          >
            <QuestionnaireSelectWithVersion
              key={groupId}
              forumId={forumId}
              contentVersion={form.getFieldValue("questionnaireOfGroupBeingSelectedVersion")}
              onContentVersionChange={(contentVersion) => {
                form.setFieldValue("questionnaireOfGroupBeingSelectedVersion", contentVersion);

                // because in `handleFieldsChange` FOR SOME UNKNOWN REASONS `questionnaireOfGroupMakingSelectionsContentVersion`
                // in previous values is always undefined we need to clear fields depending on it manually
                FIELDS_TO_CLEAR_ON_QBS_CHANGE.forEach((fieldName) => {
                  form.setFieldValue(fieldName, undefined);
                });
              }}
            />
          </DependentFormItem>

          <DependentFormItem
            name="questionnaireOfGroupMakingSelections"
            label={t("Questionnaire of group making selections")}
            dependencies={["groupSelectingFrom", "questionnaireOfGroupMakingSelectionsContentVersion"]}
            dependency={{
              name: "questionnaireOfGroupBeingSelected",
              label: t("Questionnaire of group being selected"),
            }}
            rules={[
              {
                validator: validateIsNotEqual(form, "questionnaireOfGroupBeingSelected"),
                message: t(
                  "Questionnaire of group making selections must be different from the questionnaire of group being selected",
                ),
              },
              {
                ...questionnaireVersionValidator(form, "questionnaireOfGroupMakingSelectionsVersion"),
                message: t("The questionnaire was updated. Please select a new one."),
              },
            ]}
          >
            <QuestionnaireSelectWithVersion
              key={groupId}
              forumId={forumId}
              contentVersion={form.getFieldValue("questionnaireOfGroupMakingSelectionsVersion")}
              onContentVersionChange={(contentVersion) => {
                form.setFieldValue("questionnaireOfGroupMakingSelectionsVersion", contentVersion);

                FIELDS_TO_CLEAR_ON_QMS_CHANGE.forEach((fieldName) => {
                  form.setFieldValue(fieldName, undefined);
                });
              }}
            />
          </DependentFormItem>

          <DependentFormItem
            name="questionnaireMatching"
            label={t("Questionnaire matching")}
            dependencies={["groupSelectingFrom", "questionnaireOfGroupBeingSelected"]}
            dependency={{
              name: "questionnaireOfGroupMakingSelections",
              label: t("Questionnaire of group making selections"),
            }}
          >
            <Setup
              formTitle={t("Matching Questions")}
              form={{
                element: QuestionnaireMatching,
                props: {
                  forumId,
                },
              }}
            />
          </DependentFormItem>

          <DependentFormItem
            name="questionnaireSearchIndex"
            label={t("Configure the search index used for this step")}
            dependencies={["questionnaireOfGroupBeingSelected", "questionnaireOfGroupBeingSelectedVersion"]}
            dependency={{
              name: "questionnaireOfGroupMakingSelections",
              label: t("Questionnaire of group making selections"),
            }}
          >
            <Setup
              formTitle={t("Setup Search Index")}
              form={{
                element: QuestionnaireSearchIndex,
                props: {
                  forumId,
                },
              }}
            />
          </DependentFormItem>

          <DependentFormItem
            name="profileDetailsOnline"
            label={t("Profile details shown online for this step")}
            dependencies={["questionnaireOfGroupBeingSelectedVersion"]}
            dependency={{
              name: "questionnaireOfGroupBeingSelected",
              label: t("Questionnaire of group being selected"),
            }}
          >
            <Setup
              formTitle={t("Profile details shown online for this step")}
              form={{
                element: QuestionnaireProfileDetailsItem,
                props: {
                  forumId,
                },
              }}
            />
          </DependentFormItem>

          <DependentFormItem
            name="profileDetailsPdf"
            label={t("Profile details shown in PDF for this step")}
            dependencies={["questionnaireOfGroupBeingSelectedVersion"]}
            dependency={{
              name: "questionnaireOfGroupBeingSelected",
              label: t("Questionnaire of group being selected"),
            }}
          >
            <Setup
              formTitle={t("Profile details shown in PDF for this step")}
              form={{
                element: QuestionnaireProfileDetailsItem,
                props: {
                  forumId,
                },
              }}
            />
          </DependentFormItem>

          <Form.Item name="showReasonForMeeting" label={t("Show reason for meeting")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showFreeTextSearch" label={t("Show free text search")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showAdvancedSearch" label={t("Show advanced search")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="prefilteredListOrder" label={t("Prefiltered list order")}>
            <PrefilledListOrder />
          </Form.Item>

          <Form.Item name="showPersonLocationOnSite" label={t("Show person location on site")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showCompanyLocationOnSite" label={t("Show company location on site")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="colourForPositiveSelections" label={t("Colour for positive selections")}>
            <Input />
          </Form.Item>

          <Form.Item name="colourForNegativeSelections" label={t("Colour for negative selections")}>
            <Input />
          </Form.Item>

          <Form.Item name="includeCompanyProfileInSearch" label={t("Include company profile in search")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="includePersonalProfileInSearch" label={t("Include personal profile in search")}>
            <YesNo />
          </Form.Item>

          <Form.Item
            name="attendeeImageDisplayForumGroups"
            label={t("Configure the attendee image display for this step")}
            dependencies={["groupSelectingFrom"]}
          >
            <Setup
              formTitle={t("Setup Attendee Image Display")}
              form={{
                element: AttendeeImageDisplayForumGroups,
                props: {
                  forumId,
                },
              }}
            />
          </Form.Item>

          <Form.Item name="setTheMinimumOfSelectionsRequired" label={t("Set the minimum of selections required")}>
            <Input type="number" />
          </Form.Item>

          <Form.Item name="showContactDetailsOnTheProfile" label={t("Show contact details on the profile")}>
            <YesNo />
          </Form.Item>

          <Form.Item
            name="showAddressInformationOnTheProfileDisplay"
            label={t("Show address information on the profile display")}
          >
            <YesNo />
          </Form.Item>

          <Form.Item name="showCompanyOverviewOn" label={t("Show company overview on")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showPersonalBiographyInProfile" label={t("Show personal biography in profile")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showAdditionalInformation" label={t("Show additional information")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showActionButtons" label={t("Show action buttons")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="displayLocalisedLabels" label={t("Display localised labels")}>
            <YesNo />
          </Form.Item>

          <Form.Item
            name="showTheMutualColumnInTheSelectionGrid"
            label={t("Show the mutual column in the selection grid")}
          >
            <YesNo />
          </Form.Item>

          <Form.Item name="useTheLegacyBookletProfile" label={t("Use the legacy booklet profile")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="allowPagingOnTheSelectionGrid" label={t("Allow paging on the selection grid")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="defaultSelectedPageSize" label={t("Default selected page size")}>
            <Input type="number" />
          </Form.Item>

          <Form.Item
            name="pageSizesAvailableForTheSelectionGrid"
            label={t("Page sizes available for the selection grid")}
            normalize={PageSizesAvailableForTheSelectionGrid.normalize}
          >
            <PageSizesAvailableForTheSelectionGrid />
          </Form.Item>

          <Form.Item name="filterRequestsToMeetToTopOfResults" label={t("Filter requests to meet to top of results")}>
            <YesNo />
          </Form.Item>
        </>
      )}
    />
  );
};

PositiveSelectionV2.propTypes = {
  forumId: PropTypes.number.isRequired,
  item: SelectionSitesPropTypes.Item.PositiveSelectionV2.isRequired,
  onChange: PropTypes.func,
};

PositiveSelectionV2.defaultProps = {
  onChange: () => undefined,
};

export default PositiveSelectionV2;
