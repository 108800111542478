import { Drawer } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import SelectionSiteForm from "../Form";

const SelectionSitesDrawer = ({ onDrawerClose, isDrawerVisible, onSubmit, isMutationLoading, errorMsg }) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={t("Add Selection Site")}
      placement="right"
      onClose={onDrawerClose}
      open={isDrawerVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose
    >
      <SelectionSiteForm
        isMutationLoading={isMutationLoading}
        onSubmit={onSubmit}
        errorMsg={errorMsg}
        onDrawerClose={onDrawerClose}
      />
    </Drawer>
  );
};

SelectionSitesDrawer.propTypes = {
  onDrawerClose: PropTypes.func,
  isDrawerVisible: PropTypes.bool,
  onSubmit: PropTypes.func,
  isMutationLoading: PropTypes.bool,
  errorMsg: PropTypes.string,
};

export default SelectionSitesDrawer;
