import React, { ComponentProps, ComponentType, useState } from "react";

import { Form } from "../../../../../../../components/styleguide";

interface WithOnChangeProps<Value> {
  value?: Value;
  onChange?: (value: Value) => void;
}

const withOnChange =
  <Component extends ComponentType, Props extends ComponentProps<Component>, Value>(
    WrappedComponent: ComponentType<Props>,
  ) =>
  // eslint-disable-next-line react/display-name
  ({ value, onChange, ...props }: Props & WithOnChangeProps<Value>) => {
    const [prevValue, setPrevValue] = useState(value);
    const form = Form.useFormInstance();

    const handleChange = (newValue: Value) => {
      if (prevValue === newValue) {
        return;
      }

      setPrevValue(newValue);
      onChange?.(newValue);
      form.submit();
    };

    // It's hard to fix these errors, so we just ignore them.
    // The thing is that it understands types correctly from generics and it is
    // much usable not then before
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent value={value} onChange={handleChange} {...props} />;
  };

export default withOnChange;
