import { t } from "i18next";
import moment from "moment";
import React from "react";

import styles from "./styles.module.less";

export const ForumDetailLabels = [
  {
    title: "Event Logo",
    dataindex: "logoReadUrl",
    render: (value: string | undefined | null) => {
      return (
        <div className={styles.sizedContainer}>
          {value ? <img className={styles.logo} alt={t("Event Logo")} src={value} /> : "-"}
        </div>
      );
    },
  },
  {
    title: "Short Name",
    dataindex: "shortName",
    render: (value: string | undefined | null) => <div className={styles.sizedContainer}>{value || "-"}</div>,
  },
  { title: "Event Code", dataindex: "code" },
  { title: "Long Name", dataindex: "longName" },
  { title: "Formatted Name", dataindex: "formattedName" },
  { title: "Virtual", dataindex: "isVirtual", render: (value: boolean | undefined) => (value ? t("Yes") : t("No")) },
  {
    title: "Start Date",
    dataindex: "startDate",
    render: (value: string | undefined) => (value ? moment(value).format("DD/MM/YYYY HH:mm") : ""),
  },
  { title: "Country", dataindex: "country" },
  {
    title: "End Date",
    dataindex: "endDate",
    render: (value: string | undefined) => (value ? moment(value).format("DD/MM/YYYY HH:mm") : ""),
  },
  { title: "Venue Name", dataindex: "forumVenueName" },
  { title: "Website Address", dataindex: "websiteAddress" },
  { title: "Venue City", dataindex: "venueCity" },
  { title: "Display Dates", dataindex: "displayDates" },
  { title: "Venue Country", dataindex: "venueCountry" },
];

export const activeKeys = ["details", "contact", "social-links", "team-members-roles"];
