import {
  PositiveSelectionV2ItemProfileStyle,
  QuestionnaireContentQuestion,
  QuestionnaireProfileDetailsOptionProfileDetails,
  QuestionnaireProfileDetailsQuestionProfileDetails,
  QuestionQuestionType,
} from "../../../../../../../../../../generated/api";

const getDefaultProfileStyle = (type: QuestionQuestionType): PositiveSelectionV2ItemProfileStyle => {
  switch (type) {
    case "Checkbox":
    case "RadioButton":
    case "DropdownList":
      return PositiveSelectionV2ItemProfileStyle.ClassifiedIndex;
    default:
      return PositiveSelectionV2ItemProfileStyle.None;
  }
};

const fillMappingWithDefaultValue = (
  question: QuestionnaireContentQuestion,
): QuestionnaireProfileDetailsQuestionProfileDetails => {
  const options = (question.options ?? []).map<QuestionnaireProfileDetailsOptionProfileDetails>((option) => ({
    id: option.id,
    profileTitle: option.text ?? undefined,
    isActive: true,
  }));

  return {
    id: question.id,
    profileTitle: question.text,
    profileStyle: getDefaultProfileStyle(question.type),
    isActive: true,
    options,
  };
};

export default fillMappingWithDefaultValue;
