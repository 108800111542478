import TaskStatus from "backend-models/TaskStatus";
import FullHeightForm from "components/FullHeightForm";
import { Button, Checkbox, DatePicker, Drawer, Form, Input, Select } from "components/styleguide";
import { ForumGroupTaskModel, TaskType } from "generated/api";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EditTaskDrawer = ({
  task,
  onEditCancel,
  onEditSubmit,
  isLoading,
}: {
  task: ForumGroupTaskModel | null | undefined;
  isLoading: boolean;
  onEditCancel: () => void;
  onEditSubmit: (values: ForumGroupTaskModel) => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = () => {
    onEditSubmit(form.getFieldsValue());
  };

  const handleClose = () => {
    onEditCancel();
    form.resetFields();
  };

  useEffect(() => {
    if (task !== null) {
      form.setFields([
        {
          name: "displayName",
          value: task?.displayName,
        },
        {
          name: "dueDate",
          value: moment(task?.dueDate),
        },
        {
          name: "status",
          value: task?.status,
        },
        {
          name: "isActive",
          value: task?.isActive,
        },
        {
          name: "link",
          value: task?.link,
        },
      ]);
    }
  }, [task]);

  return (
    <Drawer
      title={t("Edit Task")}
      placement="right"
      onClose={handleClose}
      open={Boolean(task)}
      contentWrapperStyle={{ minWidth: "40%" }}
      destroyOnClose
    >
      <FullHeightForm
        form={form}
        actionsPrepend={
          <Button onClick={handleClose} disabled={isLoading}>
            {t("Cancel")}
          </Button>
        }
        actionsAppend={
          <Button type="primary" disabled={isLoading} loading={isLoading} htmlType="submit">
            {t("Save")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          label={t("Task Display Name")}
          name={"displayName"}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Task Display Name)" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item labelCol={{ span: 24, offset: 0 }} label={t("Due Date")} name={"dueDate"}>
          <DatePicker style={{ minWidth: 180 }} format={"L HH:mm"} showTime={true} />
        </Form.Item>
        {task?.type === TaskType.Task && (
          <Form.Item labelCol={{ span: 24, offset: 0 }} label={t("Status")} name="status">
            <Select placeholder={t("Select status")}>
              {Object.entries(TaskStatus.MAP).map(([key, label]) => (
                <Select.Option key={key} value={key}>
                  {t(label as string)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name={"isActive"} valuePropName="checked">
          <Checkbox>{t("Active")}</Checkbox>
        </Form.Item>
        <Form.Item name={"link"} valuePropName="checked" labelAlign="right">
          <Checkbox>{t("Link")}</Checkbox>
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

EditTaskDrawer.propTypes = {
  task: PropTypes.object,
  onEditCancel: PropTypes.func,
  onEditSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default EditTaskDrawer;
