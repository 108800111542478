import { memo, ReactElement, ReactNode, useMemo } from "react";

import useRole from "../hook";
import { UserRoles } from "../types";

const Role = ({
  renderIf,
  children,
}: {
  renderIf: (value: UserRoles) => boolean;
  children: ReactElement | ReactNode;
}) => {
  const { is } = useRole();

  const shouldRender = useMemo(() => {
    return is(renderIf);
  }, [renderIf, is]);

  if (!shouldRender) {
    return null;
  }

  return <>{children}</>;
};

export default memo(Role);
