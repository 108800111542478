import { useQueryClient } from "@tanstack/react-query";
import { useCreatePersonMutation } from "api/mutations/persons";
import { useGetCompanyByIdQuery } from "api/queries/companies";
import { useGetCountriesQuery } from "api/queries/countries";
import KEYS from "api/queries/keys";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { CompanyModel, CountryModel, PersonModel } from "generated/api";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { getFormFieldRules, getFormFields } from "./helpers";

const EditAttendeeForm = ({
  visible,
  onClose,
  companyId,
}: {
  visible: boolean;
  onClose: () => void;
  companyId: number | null;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [company, setCompany] = useState<CompanyModel | undefined>();
  const [countries, setCountries] = useState<CountryModel[] | undefined>([]);
  const [email, setEmail] = useState<string | null>(null);
  const [email2, setEmail2] = useState<string | null>(null);

  const createMutation = useCreatePersonMutation();

  const { data: { data: { items: participationCodes = [] } = {} } = {} } = useParticipationCodesQuery({
    ignorePagination: true,
  });

  const handleClose = () => {
    form.resetFields();
    setEmail(null);
    setEmail2(null);

    onClose();
  };

  useGetCompanyByIdQuery(
    { companyId: toNumber(companyId) },
    {
      enabled: !!companyId && companyId > 0,
      onSuccess: ({ data }) => {
        setCompany(data);
      },
    },
  );

  useGetCountriesQuery(
    {},
    {
      onSuccess: ({ data }) => {
        setCountries(data);
      },
    },
  );

  const submit = (values: PersonModel) => {
    const personModel = values;

    if (email || email2) {
      const mailToValue = form.getFieldValue("mailToAddress");

      if (mailToValue === 0 && email) {
        personModel.mailToAddress = email;
      } else if (mailToValue === 1 && email2) {
        personModel.mailToAddress = email2;
      }
    } else {
      personModel.mailToAddress = null;
    }

    createMutation.mutate(
      { personModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_COMPANY_PERSONS]);
          handleClose();
        },
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
            const duplicateField = Object.keys(response.data.meta.ItemFields)[0];

            return duplicateField === "Email" || duplicateField === "SecEmail"
              ? toast.error(
                t("errors.alreadyExists", {
                  prop1: t("An Attendee"),
                  prop2: t(duplicateField === "Email" ? "Email" : "Email2"),
                }),
              )
              : toast.error(t("Something went wrong"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  return (
    <Drawer
      open={visible}
      onClose={handleClose}
      placement="right"
      title={t("Create New Attendee")}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <div>
        <FullHeightForm
          form={form}
          className={"edit-attendee-form"}
          layout="vertical"
          id="edit-attendeeForm"
          name="edit-attendee-form"
          onFinish={submit}
          labelWrap
          actionsPrepend={<Button onClick={handleClose}>{t("Cancel")}</Button>}
          actionsAppend={
            <Button htmlType="submit" type="primary" loading={createMutation.isLoading}>
              {t("Create Attendee")}
            </Button>
          }
        >
          {getFormFields({
            t,
            company,
            participationCodes,
            email,
            email2,
            setEmail,
            setEmail2,
            countries,
          }).map((f) => (
            <Form.Item
              key={f.dataIndex}
              label={t(f.title)}
              name={f.dataIndex}
              initialValue={f.initialValue ?? null}
              rules={f.restrictions ? getFormFieldRules(t, f.title, f.restrictions) : undefined}
            >
              {f.component ?? <Input />}
            </Form.Item>
          ))}
        </FullHeightForm>
      </div>
    </Drawer>
  );
};

EditAttendeeForm.propTypes = { visible: PropTypes.bool, onClose: PropTypes.func, companyId: PropTypes.number };

export default EditAttendeeForm;
