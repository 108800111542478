import { Space, Typography, YesNo } from "components/styleguide";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface WithNegativesSelectOptionProps {
  isWithNegatives: boolean;
  setIsWithNegatives: Dispatch<SetStateAction<boolean>>;
}

const WithNegativesSelectOption = ({ isWithNegatives, setIsWithNegatives }: WithNegativesSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("With Negatives")}</Typography.Text>
      <YesNo style={{ width: "200px" }} value={isWithNegatives} onSelect={setIsWithNegatives} />
    </Space>
  );
};

export default WithNegativesSelectOption;
