const downloadFileByURL = (url: string) => {
  const fileName = url.split("/").reverse()[0];

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  document.body.appendChild(link);

  link.click();

  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }
};

export { downloadFileByURL };
