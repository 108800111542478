import { Layout } from "components/styleguide";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";

const CompanyLayout: FC = () => {
  return (
    <div>
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </div>
  );
};

export default CompanyLayout;
