import { MeetingPreferenceResponseModel, SeminarPreferenceResponseModel } from "generated/api/apiModels";
import { omit, toNumber } from "lodash-es";
import React, { FC, useEffect, useState } from "react";

import { InputNumber, Typography } from "../../../../../components/styleguide";

interface PriorityErrorsProps {
  rowId: number;
  preferenceErrors: { [rowId: number]: string[] };
}
export const PriorityErrors: FC<PriorityErrorsProps> = ({ rowId, preferenceErrors }) =>
  rowId in preferenceErrors ? (
    <div>
      <Typography.Text type="danger">{preferenceErrors[rowId].join("\n")}</Typography.Text>
    </div>
  ) : null;

export type PreferenceResponseModels = SeminarPreferenceResponseModel | MeetingPreferenceResponseModel;

interface PriorityInputBoxProps {
  priority: number;
  preferenceErrors: { [rowId: number]: string[] };
  setPreferenceErrors: React.Dispatch<React.SetStateAction<{ [meetingId: number]: string[] }>>;
  rowId: number;
  record?: PreferenceResponseModels;
  updatePriority: (id: number, oldValue: number, newValue: number, conference?: PreferenceResponseModels) => void;
}

const PriorityInputBox: FC<PriorityInputBoxProps> = ({
  priority,
  preferenceErrors,
  setPreferenceErrors,
  rowId,
  updatePriority,
  record,
}) => {
  const [newPriority, setNewPriority] = useState<number>(priority);

  useEffect(() => {
    setNewPriority(priority);
  }, [priority]);

  return (
    <>
      <div>
        <InputNumber
          style={{ width: "60px" }}
          value={newPriority}
          disabled={Object.keys(preferenceErrors).some((k) => k != rowId.toString())}
          status={rowId in preferenceErrors ? "error" : ""}
          onBlur={(event) => updatePriority(rowId, priority, toNumber(event.target.value), record)}
          onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            updatePriority(rowId, priority, toNumber(target.value), record);
          }}
          onChange={(value) => {
            setPreferenceErrors(omit(preferenceErrors, [rowId]));
            setNewPriority(toNumber(value));
          }}
        />
      </div>
      {/* for the table row, display the error messages when they exist */}
      <PriorityErrors rowId={rowId} preferenceErrors={preferenceErrors} />
    </>
  );
};

export default PriorityInputBox;
