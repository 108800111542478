import { BaseOptionType } from "antd/lib/select";
import { useForumRoomGradesQuery } from "api/queries/forumRoomGrades";
import { Divider, Table, Typography } from "components/styleguide";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, TableColumn } from "utils/tableColumnUtils";

interface BuildingProps {
  id: number;
  name: string;
}

const Building: FC<BuildingProps> = ({ id, name }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [roomGradeNameSearch, setRoomGradeNameSearch] = useState<string | undefined>();

  const {
    response: { data: { data: roomGrades = [] } = {}, isLoading: isRoomGradesLoading },
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useForumRoomGradesQuery,
    {},
    {
      forumId,
      forumBuildingId: id,
      name: roomGradeNameSearch,
    },
  );

  const handleSearch = (
    selectedKeys: Array<string> | Array<BaseOptionType>,
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case "name": {
        setRoomGradeNameSearch(selectedKeys[0] as string);
        break;
      }
    }
    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "name": {
        setRoomGradeNameSearch(undefined);
        break;
      }
    }
    clearFilters?.();
    confirm?.();
  };

  const columns = [
    new TableColumn(t("Room Grade"), "name", {
      sorter: true,
      ...getColumnSearchProps("name", handleSearch, handleReset, "name"),
    }),
    new TableColumn(t("Number of Rooms"), "numberOfRooms", {
      sorter: true,
    }),
  ];

  return (
    <>
      <Typography.Title level={5}>{name}</Typography.Title>
      <Table bordered columns={columns} dataSource={roomGrades} loading={isRoomGradesLoading} onChange={handleSort} />
      <Divider />
    </>
  );
};

export default Building;
