import { FileImportApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(FileImportApi);

const FileImport = {
  get: api.requestImportKeyGet,
  forums: {
    bedrooms: { post: api.forumsForumIdImportBedroomsPost },
    facilities: { post: api.forumsForumIdImportFacilitiesPost },
    tables: { post: api.forumsForumIdImportTablesPost },
  },
  venues: {
    bedrooms: { post: api.venuesVenueIdImportBedroomsPost },
    facilities: { post: api.venuesVenueIdImportFacilitiesPost },
    tables: { post: api.venuesVenueIdImportTablesPost },
  },
  timeslots: { post: api.forumsForumIdImportTimeslotsPost },
};

export default FileImport;
