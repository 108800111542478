/* eslint sort-keys: ["error", "asc", {"natural": true}] */

// All queries keys must be stored in one file to avoid duplication
// Also, please, sort them alphabetically to ease the search

const KEYS = {
  ATTENDEE_SPEAKER_TRACKING_QUERY: "ATTENDEE_SPEAKER_TRACKING_QUERY",
  GET_ALL_SESSIONS: "GET_ALL_SESSIONS",
  GET_ALL_TIMESLOTS_SESSIONS: "GET_ALL_TIMESLOTS_SESSIONS",
  GET_ATTENDEE: "GET_ATTENDEE",
  GET_ATTENDEE_ADDRESS_INFO: "GET_ATTENDEE_ADDRESS_INFO",
  GET_ATTENDEE_ATTENDANCE_DETAILS: "GET_ATTENDEE_ATTENDANCE_DETAILS",
  GET_ATTENDEE_AVAILABILITIES: "GET_ATTENDEE_AVAILABILITIES",
  GET_ATTENDEE_AVAILABILITY: "GET_ATTENDEE_AVAILABILITY",
  GET_ATTENDEE_COMPANY_ADDRESS: "GET_ATTENDEE_COMPANY_ADDRESS",
  GET_ATTENDEE_CONFERENCE_SESSION_FEEDBACK: "GET_ATTENDEE_CONFERENCE_SESSION_FEEDBACK",
  GET_ATTENDEE_CONTACT_DETAILS: "GET_ATTENDEE_CONTACT_DETAILS",
  GET_ATTENDEE_DESCRIPTION: "GET_ATTENDEE_DESCRIPTION",
  GET_ATTENDEE_DIETARY_REQUIREMENTS: "GET_ATTENDEE_DIETARY_REQUIREMENTS",
  GET_ATTENDEE_EXPORT: "GET_ATTENDEE_EXPORT",
  GET_ATTENDEE_GROUP_MEMBERSHIP: "GET_ATTENDEE_GROUP_MEMBERSHIP",
  GET_ATTENDEE_HISTORY_INFORMATION: "GET_ATTENDEE_HISTORY_INFORMATION",
  GET_ATTENDEE_HOTEL_AND_ROOM_ALLOCATION: "GET_ATTENDEE_HOTEL_AND_ROOM_ALLOCATION",
  GET_ATTENDEE_ITINERARY: "GET_ATTENDEE_ITINERARY",
  GET_ATTENDEE_ITINERARY_JOINT_CANDIDATES: "GET_ATTENDEE_ITINERARY_JOINT_CANDIDATES",
  GET_ATTENDEE_MEETING_FEEDBACK: "GET_ATTENDEE_MEETING_FEEDBACK",
  GET_ATTENDEE_MEETING_LOCATIONS: "GET_ATTENDEE_MEETING_LOCATIONS",
  GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID: "GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID",
  GET_ATTENDEE_MEETING_LOCATIONS_EXPORT: "GET_ATTENDEE_MEETING_LOCATIONS_EXPORT",
  GET_ATTENDEE_PARTNER: "GET_ATTENDEE_PARTNER",
  GET_ATTENDEE_PREFERENCES_AVAILABLE_CATEGORIES: "GET_ATTENDEE_PREFERENCES_AVAILABLE_CATEGORIES",
  GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS: "GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS",
  GET_ATTENDEE_PREFERENCES_CATEGORIES: "GET_ATTENDEE_PREFERENCES_CATEGORIES",
  GET_ATTENDEE_PREFERENCES_CONFERENCES: "GET_ATTENDEE_PREFERENCE_CONFERENCES",
  GET_ATTENDEE_PREFERENCES_MEETINGS: "GET_ATTENDEE_PREFERENCES_MEETINGS",
  GET_ATTENDEE_PREFERENCES_SELECTION_OPTION_DEFINITION: "GET_ATTENDEE_PREFERENCES_SELECTION_OPTION_DEFINITION",
  GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS: "GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS",
  GET_ATTENDEE_PREFERENCES_SEMINARS: "GET_ATTENDEE_PREFERENCES_SEMINARS",
  GET_ATTENDEE_REASONS_FOR_MEETING: "GET_ATTENDEE_REASONS_FOR_MEETING",
  GET_ATTENDEE_RECONTACTS: "GET_ATTENDEE_RECONTACTS",
  GET_ATTENDEE_REMOVED_ITINERARIES: "GET_ATTENDEE_REMOVED_ITINERARIES",
  GET_ATTENDEE_REMOVED_ITINERARIES_DOCUMENT: "GET_ATTENDEE_REMOVED_ITINERARIES_DOCUMENT",
  GET_ATTENDEE_SESSION_MEETING_LOCATIONS: "GET_ATTENDEE_SESSION_MEETING_LOCATIONS",
  GET_ATTENDEE_SOCIAL_LINKS: "GET_ATTENDEE_SOCIAL_LINKS",
  GET_ATTENDEE_SUMMARY: "GET_ATTENDEE_SUMMARY",
  GET_ATTENDEES: "GET_ATTENDEES",
  GET_ATTENDEES_DESCRIPTIONS: "GET_ATTENDEES_DESCRIPTIONS",
  GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS: "GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS",
  GET_AVAILABLE_CODES: "GET_AVAILABLE_CODES",
  GET_BEDROOMS: "GET_BEDROOMS",
  GET_BOOKED_MEETINGS: "GET_BOOKED_MEETINGS",
  GET_BOOKED_MEETINGS_EXPORT: "GET_BOOKED_MEETINGS_EXPORT",
  GET_BUILDING: "GET_BUILDING",
  GET_BUILDINGS: "GET_BUILDINGS",
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_DESCRIPTIONS: "GET_COMPANIES_DECRIPTIONS",
  GET_COMPANY_BY_ID: "GET_COMPANY_BY_ID",
  GET_COMPANY_PERSONS: "GET_COMPANY_PERSONS",
  GET_CONFERENCE_ROOMS: "GET_CONFERENCE_ROOMS",
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_DOCUMENT_DEFINITION_STANDARD_SECTIONS: "GET_DOCUMENT_DEFINITION_STANDARD_SECTIONS",
  GET_DOCUMENTS_KEY: "GET_DOCUMENTS_KEY",
  GET_EMAIL_TEMPLATE_BY_ID: "GET_EMAIL_TEMPLATE_BY_ID",
  GET_EMAIL_TEMPLATES: "GET_EMAIL_TEMPLATES",
  GET_FACILITIES: "GET_FACILITIES",
  GET_FACILITY: "GET_FACILITY",
  GET_FEEDBACK_BY_PARTICIPATION_TYPE: "GET_FEEDBACK_BY_PARTICIPATION_TYPE",
  GET_FILE_IMPORT: "GET_FILE_IMPORT",
  GET_FORUM_BEDROOMS: "GET_FORUM_BEDROOMS",
  GET_FORUM_BEDROOMS_EXPORT: "GET_FORUM_BEDROOMS_EXPORT",
  GET_FORUM_BILLING_NOTES: "GET_FORUM_BILLING_NOTES",
  GET_FORUM_BUILDING_FACILITIES_EXPORT: "GET_FORUM_BUILDING_FACILITIES_EXPORT",
  GET_FORUM_BUILDINGS: "GET_FORUM_BUILDINGS",
  GET_FORUM_BY_ID: "GET_FORUM_BY_ID",
  GET_FORUM_COMPANIES: "GET_FORUM_COMPANIES",
  GET_FORUM_COMPANIES_DESCRIPTIONS: "GET_FORUM_COMPANIES_DESCRIPTIONS",
  GET_FORUM_COMPANY: "GET_FORUM_COMPANY",
  GET_FORUM_COMPANY_ATTENDEES: "GET_FORUM_COMPANY_ATTENDEES",
  GET_FORUM_COMPANY_HISTORY_INFORMATION: "GET_FORUM_COMPANY_HISTORY_INFORMATION",
  GET_FORUM_COMPANY_PACKAGE: "GET_FORUM_COMPANY_PACKAGE",
  GET_FORUM_COMPANY_PACKAGE_EXPORT: "GET_FORUM_COMPANY_PACKAGE_EXPORT",
  GET_FORUM_COMPANY_PEOPLE: "GET_FORUM_COMPANY_PEOPLE",
  GET_FORUM_COMPANY_PRIMARY_LOCATIONS: "GET_FORUM_COMPANY_PRIMARY_LOCATIONS",
  GET_FORUM_CONFERENCE_ROOMS: "GET_FORUM_CONFERENCE_ROOMS",
  GET_FORUM_CONTACT_INFO: "GET_FORUM_CONTACT_INFO",
  GET_FORUM_COUNTRIES: "GET_FORUM_COUNTRIES",
  GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE: "GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE",
  GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_ID: "GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_ID",
  GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_MERGE_TAGS: "GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_MERGE_TAGS",
  GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PDF: "GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PDF",
  GET_FORUM_DOCUMENT_DEFINITION_SECTION: "GET_FORUM_DOCUMENT_DEFINITION_SECTION",
  GET_FORUM_DOCUMENT_DEFINITION_SECTIONS: "GET_FORUM_DOCUMENT_DEFINITION_SECTIONS",
  GET_FORUM_DOCUMENT_DEFINITION_WATERMARKS: "GET_FORUM_DOCUMENT_DEFINITION_WATERMARKS",
  GET_FORUM_FACILITIES: "GET_FORUM_FACILITIES",
  GET_FORUM_FACILITIES_EXPORT: "GET_FORUM_FACILITIES_EXPORT",
  GET_FORUM_FACILITY: "GET_FORUM_FACILITY",
  GET_FORUM_FEEDBACK_EXPORT: "GET_FORUM_FEEDBACK_EXPORT",
  GET_FORUM_FEEDBACK_QUESTIONS: "GET_FORUM_FEEDBACK_QUESTIONS",
  GET_FORUM_GROUP: "GET_FORUM_GROUP",
  GET_FORUM_GROUPS: "GET_FORUM_GROUPS",
  GET_FORUM_GROUPS_TASKS_ATTENDEES: "GET_FORUM_GROUPS_TASKS_ATTENDEES",
  GET_FORUM_IT_CONTRACT_APPROVAL: "GET_FORUM_IT_CONTRACT_APPROVAL",
  GET_FORUM_IT_CONTRACT_EXPORT: "GET_FORUM_IT_CONTRACT_EXPORT",
  GET_FORUM_IT_CONTRACT_ITEMS: "GET_FORUM_IT_CONTRACT_ITEMS",
  GET_FORUM_ITINERARY_CONFERENCE_FACILITIES: "GET_FORUM_ITINERARY_CONFERENCE_FACILITIES",
  GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSION_ATTENDEES: "GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSION_ATTENDEES",
  GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSIONS: "GET_FORUM_ITINERARY_FACILITY_TIMESLOT_SESSIONS",
  GET_FORUM_ITINERARY_MEETINGS_FACILITIES: "GET_FORUM_ITINERARY_FACILITIES",
  GET_FORUM_LOGO: "GET_FORUM_LOGO",
  GET_FORUM_MEETING_ROOMS: "GET_FORUM_MEETING_ROOMS",
  GET_FORUM_RESTAURANTS: "GET_FORUM_RESTAURANTS",
  GET_FORUM_ROOM_GRADES: "GET_FORUM_ROOM_GRADES",
  GET_FORUM_SELECTION_SITE_PREDEFINED_EVENT_CHOICES: "GET_FORUM_SELECTION_SITE_PREDEFINED_EVENT_CHOICES",
  GET_FORUM_SEMINAR_BY_ID: "GET_FORUM_SEMINAR_BY_ID",
  GET_FORUM_SEMINARS: "GET_FORUM_SEMINARS",
  GET_FORUM_SEMINARS_WITH_SESSIONS: "GET_FORUM_SEMINARS_WITH_SESSIONS",
  GET_FORUM_SISTER_EVENTS: "GET_FORUM_SISTER_EVENTS",
  GET_FORUM_SOCIAL_LINKS: "GET_FORUM_SOCIAL_LINKS",
  GET_FORUM_SPEED_MEETINGS: "GET_FORUM_SPEED_MEETINGS",
  GET_FORUM_TABLES: "GET_FORUM_TABLES",
  GET_FORUM_TEAM_MEMEBER_BY_ID: "GET_FORUM_TEAM_MEMEBER_BY_ID",
  GET_FORUM_TEAM_MEMEBERS: "GET_FORUM_TEAM_MEMEBERS",
  GET_FORUM_VENUE: "GET_FORUM_VENUE",
  GET_FORUM_VENUE_AVAILABLE_TIMESLOTS: "GET_FORUM_VENUE_AVAILABLE_TIMESLOTS",
  GET_FORUM_VENUE_FACILITIES: "GET_FORUM_VENUE_FACILITIES",
  GET_FORUM_VENUE_TABLES: "GET_FORUM_VENUE_TABLES",
  GET_FORUM_VISUAL_SPEAKER_DOCUMENTS: "GET_FORUM_VISUAL_SPEAKER_DOCUMENTS",
  GET_FORUM_YEARS: "GET_FORUM_YEARS",
  GET_FORUMS: "GET_FORUMS",
  GET_GDPR_REQUESTS: "GET_GDPR_REQUESTS",
  GET_GROUP_TASKS: "GET_GROUP_TASKS",
  GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUN: "GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUN",
  GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUN_EXPORT: "GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUN_EXPORT",
  GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUNS: "GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUNS",
  GET_ITINERARY_BOOKLET_FORUM_WELCOME_LETTER: "GET_ITINERARY_BOOKLET_FORUM_WELCOME_LETTER",
  GET_ITINERARY_BOOKLET_GENERATE_DOCUMENT: "GET_ITINERARY_BOOKLET_GENERATE_DOCUMENT",
  GET_ITINERARY_BOOKLET_PROFILE_SETUP: "GET_ITINERARY_BOOKLET_PROFILE_SETUP",
  GET_ITINERARY_BOOKLET_QUEUED_DOCUMENTS: "GET_ITINERARY_BOOKLET_QUEUED_DOCUMENTS",
  GET_ITINERARY_BOOKLET_TEAM_MEMBERS: "GET_ITINERARY_BOOKLET_FORUM_TEAM_MEMBERS",
  GET_ITINERARY_BOOKLET_TIME_TYPE_LABELS: "GET_ITINERARY_BOOKLET_TIME_TYPE_LABELS",
  GET_ITINERARY_BOOKLET_UNASSIGNED_TEAM_MEMBERS: "GET_ITINERARY_BOOKLET_UNASSIGNED_TEAM_MEMBERS",
  GET_ITINERARY_BOOKLETS_DOCUMENT_TEST: "GET_ITINERARY_BOOKLETS_DOCUMENT_TEST",
  GET_LANGUAGE_LABELS_TIME_FORMATS_ESSENTIALS: "GET_LANGUAGE_LABELS_TIME_FORMATS_ESSENTIALS",
  GET_MEETING_ROOMS: "GET_MEETING_ROOMS",
  GET_ON_SITE_AVAILABLE_ATTENDEES: "GET_ON_SITE_AVAILABLE_ATTENDEES",
  GET_ON_SITE_AVAILABLE_PREFERENCES: "GET_ON_SITE_AVAILABLE_PREFERENCES",
  GET_PARTICIPATION_CODES: "GET_PARTICIPATION_CODES",
  GET_POTENTIAL_REPLACEMENTS: "GET_POTENTIAL_REPLACEMENTS",
  GET_QUESTIONNAIRE: "GET_QUESTIONNAIRE",
  GET_QUESTIONNAIRE_CONTENT: "GET_QUESTIONNAIRE_CONTENT",
  GET_QUESTIONNAIRE_EXPORT: "GET_QUESTIONNAIRE_EXPORT",
  GET_QUESTIONNAIRE_MATCHES: "GET_QUESTIONNAIRE_MATCHES",
  GET_QUESTIONNAIRES: "GET_QUESTIONNAIRES",
  GET_QUESTIONNAIRES_EXPORT: "GET_QUESTIONNAIRES_EXPORT",
  GET_QUESTIONNAIRES_SUBMISSION: "GET_QUESTIONNAIRES_SUBMISSION",
  GET_REPORT_PARAMETERS: "GET_REPORT_PARAMETERS",
  GET_RESTAURANTS: "GET_RESTAURANTS",
  GET_ROOM_GRADES: "GET_ROOM_GRADES",
  GET_SELECTED_CODES: "GET_SELECTED_CODES",
  GET_SELECTION_SITE: "GET_SELECTION_SITE",
  GET_SELECTION_SITE_CONTENT: "GET_SELECTION_SITE_CONTENT",
  GET_SELECTION_SITE_EXPORT: "GET_SELECTION_SITE_EXPORT",
  GET_SELECTION_SITES: "GET_SELECTION_SITES",
  GET_SEMINAR_SESSIONS: "GET_SEMINAR_SESSIONS",
  GET_SEMINAR_TYPES: "GET_SEMINAR_TYPES",
  GET_SEMINARS: "GET_SEMINARS",
  GET_SENT_EMAILS: "GET_SENT_EMAILS",
  GET_SPEED_MEETINGS: "GET_SPEED_MEETINGS",
  GET_STREAMS: "GET_STREAMS",
  GET_SYSTEM_QUESTIONNAIRES: "GET_SYSTEM_QUESTIONNAIRES",
  GET_TASK_STATUSES_EXPORT: "GET_TASK_STATUSES_EXPORT",
  GET_TEMPLATES: "GET_TEMPLATES",
  GET_TIMESLOT: "GET_TIMESLOT",
  GET_TIMESLOT_IMPORT: "GET_TIMESLOT_IMPORT",
  GET_TIMESLOT_SESSION: "GET_TIMESLOT_SESSION",
  GET_TIMESLOT_SESSIONS: "GET_TIMESLOT_SESSIONS",
  GET_TIMESLOTS: "GET_TIMESLOTS",
  GET_UNASSIGNED_FORUM_TEAM_MEMBER_BY_ID: "GET_UNASSIGNED_FORUM_TEAM_MEMBER_BY_ID",
  GET_USER_INFO: "GET_USER_INFO",
  GET_USERS: "GET_USERS",
  GET_VENUE: "GET_VENUE",
  GET_VENUE_FACILITIES: "GET_VENUE_FACILITIES",
  GET_VENUE_TABLES: "GET_VENUE_TABLES",
  GET_VENUES: "GET_VENUES",
  GET_ZOOM_MAPPINGS_ACCOUNTS: "GET_ZOOM_MAPPINGS_ACCOUNTS",
  GET_ZOOM_MAPPINGS_SESSION_MAPPINGS: "GET_ZOOM_MAPPINGS_SESSION_MAPPINGS",
  GET_ZOOM_MAPPINGS_TABLE_MAPPINGS: "GET_ZOOM_MAPPINGS_TABLE_MAPPINGS",
  GET_ZOOM_MAPPINGS_WEBINARS: "GET_ZOOM_MAPPINGS_WEBINARS",
  SEMINARS_QUERY: "SEMINARS_QUERY",
  TIMESLOTS_EXPORT: "TIMESLOTS_EXPORT",
} as const;

export default KEYS;
