import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";

import { useUpdateQuestionnaireContentMutation } from "../../../../api/mutations/questionnaries";
import { keys, useQuestionnaireContentQuery } from "../../../../api/queries/questionnaires";

// Basically that hook stands for easier mutation and data getting for questionnaire,
// and it has optimistic updates builtin in there
const useQuestionnaire = (forumId, questionnaireId) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => keys.getQuestionnaireContent({ forumId, questionnaireId }),
    [forumId, questionnaireId],
  );

  const { data, isLoading } = useQuestionnaireContentQuery({ forumId, questionnaireId });

  const { mutate: originalMutate } = useUpdateQuestionnaireContentMutation({
    onMutate: async ({ pages }) => {
      await queryClient.cancelQueries(queryKey);

      const previousResponse = queryClient.getQueryData(queryKey);
      queryClient.setQueriesData(queryKey, (data) => ({ ...data, data: { ...data?.pages, pages } }));

      return { previousResponse };
    },
    onError: (_, __, context) => {
      queryClient.setQueriesData(queryKey, context.previousResponse);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const pages = useMemo(() => {
    return data?.data?.pages ?? [];
  }, [data]);

  const mutate = useCallback(
    (pages, options = {}) => {
      originalMutate({ forumId, questionnaireId, pages }, options);
    },
    [originalMutate],
  );

  return {
    pages,
    isLoading,
    mutate,
  };
};

export default useQuestionnaire;
