import { useForumBuildingsQuery } from "api/queries/forumBuilding";
import { Spin } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

import Building from "./Building";
import { Empty } from "antd";

const HotelAllocations: FC = () => {
  const forumId = toNumber(useParams().id);

  const { data: { data: buildings = [] } = {}, isLoading: isBuildingsLoading } = useForumBuildingsQuery({ forumId });

  if (isBuildingsLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <>
      {buildings.length ? buildings.map(({ id, name }, index) => (
        <Building key={index} id={toNumber(id)} name={String(name)} />
      )) : (
        <Empty />
      )}
    </>
  );
};

export default HotelAllocations;
