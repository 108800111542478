import { ArrowDownOutlined, ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  useAddItemToSelectionSitePageMutation,
  useAddSelectionSitePageMutation,
  useDeleteSelectionSiteItemMutation,
  useDeleteSelectionSitePageMutation,
  useUpdateSelectionSitePageItemOrderMutation,
  useUpdateSelectionSitePageOrderMutation,
} from "api/mutations/selectionSites";
import clsx from "clsx";
import { Button, Col, Layout, Menu, Row } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import SelectionSiteStatusContext from "pages/SelectionSites/EditPage/context/selection-site-status";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { scrollToAnchor } from "utils/anchorUtils";
import nextTick from "utils/nextTickUtils";
import {
  checkSetOrderDown,
  checkSetOrderUp,
  generateItemId,
  generateNavId,
  generatePageId,
  getIdFromNavId,
  getItemIdFromId,
  getPageIdFromId,
  isItemId,
} from "utils/selectionSiteContentUtils";
import SelectionSiteItemType from "utils/selectionSiteItemTypeUtils";

import ItemErrorsContext from "../../context/item-errors";
import ItemLabel from "./components/ItemLabel";
import PageLabel from "./components/PageLabel";

import styles from "./styles.module.less";

const navDetailsId = generateNavId("details");

const SelectionSiteEditSider = ({
  className,
  forumId,
  selectionSiteId,
  pages,
  width,
  onPageAdd,
  onPageDelete,
  onItemAdd,
  onItemDelete,
}) => {
  const { t } = useTranslation();

  const [selectedMenuKey, setSelectedMenuKey] = useState(navDetailsId);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState();
  const [selectedMenuItems, setSelectedMenuItems] = useState();
  const openKeys = Menu.useOpenKeys([]);
  const classes = clsx(styles.subMenu, className);

  const itemErrors = useContext(ItemErrorsContext);

  const addItemToPageMutation = useAddItemToSelectionSitePageMutation();
  const queryClient = useQueryClient();

  const status = useContext(SelectionSiteStatusContext);
  const isEditDisabled = status === "Open";

  const addItemToPage = (pageId, itemType) => {
    return addItemToPageMutation.mutateAsync(
      {
        forumId,
        selectionSiteId,
        pageId,
        item: {
          type: itemType,
        },
      },
      {
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
            toast.error(t("Cannot create more than one item of this type per page"));
          }
        },
      },
    );
  };

  const addPageMutation = useAddSelectionSitePageMutation({
    onSuccess: (response) => {
      const newPageId = response?.data?.pages.at(-1)?.id;
      addItemToPage(newPageId, "FreeText").then(() => {
        onPageAdd();
      });
    },
  });

  const handleSuccess = () => {
    queryClient.invalidateQueries(addPageMutation);
  };

  const handleError = (error) => {
    const errorMessage = error?.response?.data?.Message || error.message;
    const genericError = t("An error occurred while updating the item.");
    const message = `${genericError} ${errorMessage}`;
    toast.error(message);
  };

  const updateSelectionPageOrder = useUpdateSelectionSitePageOrderMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const updateSelectionPageItemOrder = useUpdateSelectionSitePageItemOrderMutation({
    onSuccess: handleSuccess,
    onError: handleError,
  });

  const isLoading =
    addPageMutation.isLoading ||
    addItemToPageMutation.isLoading ||
    updateSelectionPageOrder.isLoading ||
    updateSelectionPageItemOrder.isLoading;

  const deletePageMutation = useDeleteSelectionSitePageMutation({
    onSuccess: () => {
      onPageDelete();
    },
  });

  const removeItemFromPageMutation = useDeleteSelectionSiteItemMutation({
    onSuccess: () => {
      onItemDelete();
    },
  });

  const handleAddPageClick = () => {
    addPageMutation.mutate({ forumId, selectionSiteId });
  };

  const handleRemovePage = async (pageId) => {
    await deletePageMutation.mutateAsync({ forumId, selectionSiteId, pageId });
    const navId = generateNavId(generatePageId(pageId));
    setSelectedMenuKey((prevKey) => (prevKey === navId ? "" : prevKey));
    openKeys.remove(navId);
  };

  const handleRemoveItem = async (pageId, itemId) => {
    await removeItemFromPageMutation.mutateAsync({ forumId, selectionSiteId, pageId, itemId });
    const navId = generateNavId(generateItemId(pageId, itemId));
    setSelectedMenuKey((prevKey) => (prevKey === navId ? "" : prevKey));
  };

  const handleItemAdd = (pageId, itemType) => {
    addItemToPage(pageId, itemType).then(() => {
      onItemAdd();
    });
  };

  const setSelectedKey = (key) => {
    if (key) {
      setSelectedMenuKey(key);
    }
  };

  const setSelectedKeyToNavAndScroll = async (id) => {
    const navId = generateNavId(id);
    setSelectedKey(navId);

    await nextTick();
    scrollToAnchor(`#${id}`);
  };

  const handleMenuItemsOrder = (position, menuItems) => {
    const pageId = getPageIdFromId(getIdFromNavId(selectedMenuKey));

    if (isItemId(getIdFromNavId(selectedMenuKey))) {
      const page = menuItems.find(
        (item) => getPageIdFromId(getIdFromNavId(item.id)) === getPageIdFromId(getIdFromNavId(selectedMenuKey)),
      );
      const index = page.children.findIndex((item) => item.key === selectedMenuKey);
      const itemId = getItemIdFromId(getIdFromNavId(selectedMenuKey))[1];
      const selectionSiteReorderModel = {
        moveToPosition: (position === "up" ? -1 : +1) + index + 1,
      };
      setSelectedMenuIndex((position === "up" ? -1 : +1) + index + 1);
      updateSelectionPageItemOrder.mutate(
        {
          forumId,
          selectionSiteId,
          pageId,
          itemId,
          selectionSiteReorderModel,
        },
        {
          onSuccess: () => {
            setTimeout(() => setSelectedKeyToNavAndScroll(generateItemId(pageId, itemId)), 2000);
          },
        },
      );
    } else {
      const index = menuItems.findIndex((item) => item.id === selectedMenuKey);
      const selectionSiteReorderModel = {
        moveToPosition: (position === "up" ? -1 : +1) + index,
      };
      setSelectedMenuIndex((position === "up" ? -1 : +1) + index);
      updateSelectionPageOrder.mutate(
        {
          forumId,
          selectionSiteId,
          pageId,
          selectionSiteReorderModel,
        },
        {
          onSuccess: () => {
            setTimeout(() => setSelectedKeyToNavAndScroll(generatePageId(pageId)), 2000);
          },
        },
      );
    }
  };

  const handleClickItem = (index, items) => {
    setSelectedMenuIndex(index);
    setSelectedMenuItems(items);
  };

  const menuItems = useMemo(() => {
    const detailsSection = {
      id: navDetailsId,
      key: navDetailsId,
      anchorLink: "#details",
      label: <Menu.ItemLabel>Details</Menu.ItemLabel>,
    };

    const sections = pages.map((page, pageIndex) => {
      const id = generatePageId(page.id);
      const navId = generateNavId(id);

      const hasItems = page.items && page.items.length !== 0;

      const items = hasItems ? page.items : undefined;
      const hasErrors = items?.some((item) => itemErrors.has(item.id));

      const common = {
        id: navId,
        key: navId,
        anchorLink: `#${id}`,
        label: (
          <PageLabel
            title={`${t("page")} ${pageIndex + 1}`}
            pageId={page.id}
            pages={pages}
            isSelected={navId === selectedMenuKey}
            hasErrors={hasErrors}
            onRemoveConfirm={() => handleRemovePage(page.id)}
            onAddItemClick={(key) => handleItemAdd(page.id, key)}
          />
        ),
      };

      if (!hasItems) {
        return common;
      }

      return {
        ...common,
        // `onChange` or `onClick` from ant is called only on selecting specific element
        // to track when page with items (dropdown title) was clicked we have to use that hack
        onTitleClick: ({ key }) => {
          setSelectedKeyToNavAndScroll(getIdFromNavId(key));
          const index = menuItems.findIndex((item) => item.id === key);
          setSelectedMenuIndex(index);
        },
        children: items.map((item, index) => {
          const id = generateItemId(page.id, item.id);
          const navId = generateNavId(id);
          const itemType = new SelectionSiteItemType(item.type);

          const hasErrors = itemErrors.has(item.id);

          return {
            id: item.id,
            key: navId,
            anchorLink: `#${id}`,
            label: (
              <ItemLabel
                title={t(itemType.toString())}
                itemId={item.id}
                pages={pages}
                isSelected={navId === selectedMenuKey}
                hasErrors={hasErrors}
                onRemoveConfirm={() => handleRemoveItem(page.id, item.id)}
                onClick={() => handleClickItem(index + 1, items)}
              />
            ),
          };
        }),
      };
    });

    return [detailsSection, ...sections];
  }, [pages, selectedMenuKey, itemErrors]);

  const handleMenuItemSelect = ({ key, item }) => {
    setSelectedKey(key);

    const anchorLink = item.props.anchorLink;
    if (!anchorLink) {
      return;
    }

    scrollToAnchor(anchorLink);
  };

  return (
    <Layout.Sider data-testid="sider" className={classes} width={width}>
      <Row className={styles.actions} gutter={8}>
        <Col>
          <Button
            icon={<PlusOutlined />}
            loading={isLoading}
            disabled={isLoading || isEditDisabled}
            onClick={handleAddPageClick}
          >
            {t("Add page")}
          </Button>
        </Col>
        <Col flex={"1"} />
        <Col>
          <Button
            disabled={checkSetOrderUp(selectedMenuIndex) || isEditDisabled}
            onClick={() => handleMenuItemsOrder("up", menuItems)}
            icon={<ArrowUpOutlined />}
          />
        </Col>
        <Col>
          <Button
            disabled={
              checkSetOrderDown(
                selectedMenuIndex,
                selectedMenuItems ? selectedMenuItems.length : menuItems.length - 1,
              ) || isEditDisabled
            }
            onClick={() => handleMenuItemsOrder("down", menuItems)}
            icon={<ArrowDownOutlined />}
          />
        </Col>
      </Row>
      <Menu
        items={menuItems}
        mode="inline"
        selectedKeys={[]}
        openKeys={openKeys.value}
        onSelect={handleMenuItemSelect}
        onOpenChange={openKeys.set}
      />
    </Layout.Sider>
  );
};

SelectionSiteEditSider.propTypes = {
  className: PropTypes.string,
  forumId: PropTypes.number.isRequired,
  selectionSiteId: PropTypes.number.isRequired,
  pages: SelectionSitesPropTypes.Pages,
  width: PropTypes.number,
  onPageAdd: PropTypes.func,
  onPageDelete: PropTypes.func,
  onItemAdd: PropTypes.func,
  onItemDelete: PropTypes.func,
};

SelectionSiteEditSider.defaultProps = {
  pages: [],
  onPageAdd: () => undefined,
  onPageDelete: () => undefined,
  onItemAdd: () => undefined,
  onItemDelete: () => undefined,
};

export default SelectionSiteEditSider;
