import React from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { useCreateQuestionnaireMutation } from "../../../api/mutations/questionnaries";
import QuestionnaireType from "../../../backend-models/questionnaireType";
import { Collapse } from "../../../components/styleguide";
import QuestionnaireForm from "../components/QuestionnaireForm";

const NewQuestionnairePage = () => {
  const navigate = useNavigate();
  const { id: forumId } = useOutletContext();
  const { lang } = useParams();

  const createMutation = useCreateQuestionnaireMutation({
    onSuccess: () => {
      navigate(`/${lang}/forum/${forumId}/forum-admin/questionnaires`);
    },
  });

  const handleFormSubmit = ({ name, availableFrom, availableTo, type, forumGroupIds }) => {
    createMutation.mutate({
      forumId,
      name,
      availableFrom,
      availableTo,
      type,
      forumGroupIds,
    });
  };

  return (
    <Collapse defaultActiveKey={["general"]}>
      <Collapse.Panel key="general" header="General">
        <QuestionnaireForm
          forumId={forumId}
          initialValues={{
            type: QuestionnaireType.LIST[0].value,
          }}
          labelCol={{ span: 24, lg: 12, xl: 5, xxl: 4 }}
          hideFields={["status"]}
          isLoading={createMutation.isLoading}
          onFinish={handleFormSubmit}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default NewQuestionnairePage;
