import { CollapsePanelProps } from "antd/lib/collapse/CollapsePanel";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Col, Collapse, Row } from "../../styleguide";
import Info from "../Info";

interface PanelProps extends Omit<CollapsePanelProps, "header"> {
  articleId: string;
  title: string;
  className?: string;
}

const Panel: FC<PanelProps> = ({ articleId, title, className, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Collapse.Panel
      className={className ?? "collapse-panel"}
      collapsible="icon"
      header={
        <Row align="middle" gutter={[10, 0]}>
          <Col>{t(title)}</Col>
          <Col>
            <Info {...{ articleId }} />
          </Col>
        </Row>
      }
      {...props}
    >
      {children}
    </Collapse.Panel>
  );
};

export default Panel;
