import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { FormLayout } from "antd/lib/form/Form";
import {
  useCreateSessionMutation,
  useDeleteSessionMutation,
  useReorderSessionsMutation,
  useUpdateSessionMutation,
} from "api/mutations/sessions";
import { useUpdateTimeslotMutation } from "api/mutations/timeslots";
import { useGetForumByIdQuery } from "api/queries/forums";
import KEYS from "api/queries/keys";
import { useGetTimeslotQuery } from "api/queries/timeslots";
import { Button, Divider, Form, Input, Modal, Select, Spin } from "components/styleguide";
import ForumUtcOffsetContext from "contexts/forumUtcOffset";
import TimeSlotTypes, { TimeslotType } from "enums/TimeSlotType";
import ErrorTypes from "error-handling/errorTypes";
import {
  ForumResponseModel,
  TimeSlotRequestModel,
  TimeSlotResponseModel,
  TimeSlotSessionResponseModel,
} from "generated/api";
import { toNumber } from "lodash";
import moment from "moment/moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { fakeTimeOffset, stringOffsetToNumber } from "utils/dateUtils";

import SessionTable from "./SessionsTable";

const { confirm } = Modal;

const EditTimeslot = () => {
  const { t } = useTranslation();
  const forumUtcOffset = useContext(ForumUtcOffsetContext);

  const timeslotId = toNumber(useParams().timeslotId);
  const forumId = toNumber(useParams().id);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [timeslotForm] = Form.useForm();
  const queryClient = useQueryClient();

  const [formLayout] = useState<FormLayout>("inline");
  const [timeslot, setTimeslot] = useState<TimeSlotResponseModel>();
  const [sessions, setSessions] = useState<TimeSlotSessionResponseModel[]>([]);
  const [editingKey, setEditingKey] = useState<number | undefined>();
  const [forum, setForum] = useState<ForumResponseModel>();
  const [disableOrdering, setDisableOrdering] = useState<boolean>(false);

  const isEditing = (record: TimeSlotSessionResponseModel) => record.order === editingKey;

  const { mutate: updateTimeslot, isLoading: isUpdateMutationLoading } = useUpdateTimeslotMutation();
  const createSessionMutation = useCreateSessionMutation();
  const updateSessionMutation = useUpdateSessionMutation();
  const deleteSessionMutation = useDeleteSessionMutation();
  const reorderSessionsMutation = useReorderSessionsMutation();

  const { isLoading: isTimeslotLoading } = useGetTimeslotQuery(
    { forumId, timeSlotId: timeslotId },
    {
      onSuccess: ({ data }) => {
        setTimeslot(data);
        setSessions(data.sessions ?? []);
      },
      enabled: !editingKey,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );

  const handleSessionEdit = (record: TimeSlotSessionResponseModel) => {
    const startTime = record.startTime ? fakeTimeOffset(record.startTime, stringOffsetToNumber(forumUtcOffset)) : "";
    const endTime = record.endTime ? fakeTimeOffset(record.endTime, stringOffsetToNumber(forumUtcOffset)) : "";

    form.setFieldsValue({
      ...record,
      startTime,
      endTime,
    });

    setEditingKey(record.order);
  };

  const { isLoading: isForumLoading } = useGetForumByIdQuery(
    { forumId },
    {
      onSuccess: ({ data }) => {
        if (!sessions || sessions.length === 0) {
          const defaultSession = { id: 0, order: 1, startTime: data.startDate };
          setSessions([defaultSession]);
          handleSessionEdit(defaultSession);
        }

        setForum(data);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const handleSubmit = (values: TimeSlotRequestModel) => {
    const request = values;
    request.sessions = sessions;

    updateTimeslot(
      {
        forumId,
        timeSlotId: timeslotId,
        timeSlotRequestModel: request,
      },
      {
        onSuccess: () => navigate("../"),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const handleSessionSave = async (record: TimeSlotSessionResponseModel) => {
    try {
      const row = await form.validateFields();

      const values = {
        startTime: moment(row.duration[0]).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: moment(row.duration[1]).format("YYYY-MM-DDTHH:mm:ss"),
      };

      const newData = [...sessions];
      const index = newData?.findIndex((item) => record.order === item.order);

      if ((sessions[index]?.id as number) <= 0) {
        await createSessionMutation.mutateAsync(
          { forumId, timeslotId, timeSlotSessionRequestModel: values },
          {
            onSuccess: (response) => {
              const item = newData[index];
              item.id = response.data.id;
              newData.splice(index, 1, { ...item, ...values });
              setSessions(newData);
              setEditingKey(undefined);
            },
          },
        );
      } else {
        await updateSessionMutation.mutateAsync(
          {
            forumId,
            timeslotId: timeslotId,
            sessionId: newData[index].id as number,
            timeSlotSessionRequestModel: values,
          },
          {
            onSuccess: () => {
              const item = newData[index];
              newData.splice(index, 1, { ...item, ...values });
              setSessions(newData);
              setEditingKey(undefined);
            },
          },
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSessionAdd = () => {
    const newSession = {
      id: 0,
      order: sessions.length + 1,
      startTime: forum?.startDate,
    };

    setSessions([...sessions, newSession]);
    setEditingKey(newSession.order);
  };

  const handleSessionDelete = (session: TimeSlotSessionResponseModel) => {
    deleteSessionMutation.mutate(
      { forumId, timeslotId, sessionId: session.id as number },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_TIMESLOT]);
          setEditingKey(undefined);
        },
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.ObjectHasAssociatedItemsException)) {
            return toast.error(t("The session cannot be deleted, it is associated with a seminar session."));
          }

          toast.error(t("Something went wrong"));
        },
      },
    );
  };

  const showDeleteConfirm = (session: TimeSlotSessionResponseModel) => {
    confirm({
      title: t("confirmation"),
      content:
        timeslotForm.getFieldValue("timeSlotType") === TimeSlotTypes.Conference
          ? t("Are you sure you want to delete this session?")
          : t("The session will be deleted for the associated meeting locations. Do you want to proceed?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: () => handleSessionDelete(session),
    });
  };

  const handleSessionCancel = (record: TimeSlotSessionResponseModel) => {
    if (!record.id) {
      const newData = sessions.filter((item) => item.order !== record.order);
      setSessions(newData);
    }
    setEditingKey(undefined);
  };

  const timeTypesOptions = Object.keys(TimeSlotTypes).map((k) => ({
    value: k,
    label: t(`timeTypes.${TimeSlotTypes[k as keyof TimeslotType]}`),
  }));

  if (isForumLoading || !timeslot || isTimeslotLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  const handleSessionReorder = (event: DragEndEvent) => {
    const orderedSessions = sessions.sort((a, b) => (a.order as number) - (b.order as number));
    const oldIndex = orderedSessions.findIndex((item) => item.order === event.active.id);
    const newIndex = orderedSessions.findIndex((item) => item.order === event.over?.id);
    const updatedSessions = arrayMove(orderedSessions, oldIndex, newIndex);

    const updatedSession = updatedSessions[newIndex];

    setSessions(updatedSessions);

    const order: Record<number, number> = {};
    updatedSessions.map((item, index) => (order[item.id as number] = index + 1));

    reorderSessionsMutation.mutate(
      {
        forumId,
        timeslotId,
        sessionId: updatedSession.id as number,
        sessionReorderRequestModel: {
          newOrderNumber: newIndex + 1,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_TIMESLOT]);
        },
      },
    );
  };

  return (
    <div id="edit-timeslot-page">
      <div className="section">
        <Form
          onFinish={handleSubmit}
          id="edit-timeslot-form"
          layout={formLayout}
          form={timeslotForm}
          fields={[
            {
              name: "description",
              value: timeslot.description,
            },
            {
              name: "timeSlotType",
              value: timeslot.timeSlotType,
            },
          ]}
        >
          <Form.Item
            label={t("description")}
            name="description"
            rules={[
              {
                required: true,
                message: t("errors.required", {
                  prop: "$t(timeslotDescription)",
                }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("timeType")}
            name="timeSlotType"
            rules={[
              {
                required: true,
                message: t("errors.required", { prop: "$t(timeType)" }),
              },
            ]}
          >
            <Select className="time-type-select" style={{ minWidth: 200 }} options={timeTypesOptions} />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              loading={isUpdateMutationLoading}
              disabled={isUpdateMutationLoading}
            >
              {t("save")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Divider />
      <div className="section">
        <SessionTable
          form={form}
          data={sessions}
          defaultTimezone={forumUtcOffset}
          isEditing={isEditing}
          editingKey={editingKey}
          onAdd={handleSessionAdd}
          onSave={handleSessionSave}
          onCancel={handleSessionCancel}
          onDelete={showDeleteConfirm}
          onEdit={handleSessionEdit}
          onDragEnd={handleSessionReorder}
          disableOrdering={disableOrdering}
          setDisableOrdering={setDisableOrdering}
          rowKey="order"
          isSaveLoading={createSessionMutation.isLoading || updateSessionMutation.isLoading}
          loading={isTimeslotLoading || isForumLoading}
        />
      </div>
    </div>
  );
};

export default EditTimeslot;
