import Icon, { StarFilled } from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useAttendeeGroupMembershipsQuery } from "api/queries/attendees";
import { Table } from "components/styleguide";
import { GroupWithParticipationCodeModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps } from "utils/tableColumnUtils";

const AttendeeGroupMembershipsTable = ({ forumId, attendeeId }: { forumId: number; attendeeId: number }) => {
  const { t } = useTranslation();

  const [groupMemberships, setGroupMemberships] = useState<GroupWithParticipationCodeModel[]>([]);

  const [nameSearch, setNameSearch] = useState<string | undefined>();

  const {
    response: { isLoading },
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeeGroupMembershipsQuery,
    {
      onSuccess: ({ data }: { data: GroupWithParticipationCodeModel[] }) => {
        setGroupMemberships(data);
      },
    },
    {
      forumId: forumId,
      attendeeId: attendeeId,
      Name: nameSearch,
    },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "name": {
        setNameSearch(selectedKeys[0]);
        break;
      }
    }
    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "name": {
        setNameSearch(undefined);
        break;
      }
    }
    clearFilters();
    confirm();
  };

  const columns = [
    {
      title: "",
      dataIndex: "favorite",
      key: "favorite",
      render: (favorite: boolean, _: unknown, index: number) => {
        // Favourite Logic yet to be implemented
        const localOrange = " event-star-color-orange";
        const localBlack = " event-star-color-black";
        return (
          <span
            title={favorite ? "Remove from favourites" : "Add to favourites"}
            className={`favorite-icon ${favorite ? localOrange : localBlack} `}
          >
            <Icon key={index} component={StarFilled as React.ForwardRefExoticComponent<CustomIconComponentProps>} />
          </span>
        );
      },
      width: "40px",
    },
    {
      title: t("group"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      ...getColumnSearchProps("name", handleSearch, handleReset, "name"),
    },
    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
      sorter: true,
    },
  ];

  return (
    <Table
      id="attendee-group-memberships-table"
      dataSource={groupMemberships}
      columns={columns}
      pagination={false}
      bordered={true}
      loading={isLoading}
      rowKey="id"
      onChange={handleSort}
    />
  );
};

export default AttendeeGroupMembershipsTable;
