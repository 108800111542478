import { useQuestionnaireContentQuery, useQuestionnaireMatchesQuery } from "api/queries/questionnaires";
import { Space, Spin } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { removeAt } from "utils/arrayUtils";
import { getQuestionById, getQuestionOptionsById } from "utils/questionnaireContentUtils";

import Mapper from "../Mapper";
import ListingTable from "../Table";

const convertQuestionOptionsToOptions = (options) => {
  return options.map((option) => ({
    value: option.id,
    label: option.text,
  }));
};

const OptionsMatching = ({ forumId, from, to, options, onOptionsChange }) => {
  const { t } = useTranslation();

  const fromQuestionnaireQuery = useQuestionnaireContentQuery({ forumId, questionnaireId: from.questionnaire });
  const toQuestionnaireQuery = useQuestionnaireContentQuery({ forumId, questionnaireId: to.questionnaire });

  const isLoading = fromQuestionnaireQuery.isLoading || toQuestionnaireQuery.isLoading;

  const fromQuestionnairePages = fromQuestionnaireQuery.data?.data?.pages;
  const toQuestionnairePages = toQuestionnaireQuery.data?.data?.pages;

  const fromQuestionTitle = getQuestionById(fromQuestionnairePages, from.question)?.text;
  const toQuestionTitle = getQuestionById(toQuestionnairePages, to.question)?.text;

  const fromOptions = useMemo(() => {
    const optionsInQuestion = getQuestionOptionsById(fromQuestionnairePages, from.question);
    return convertQuestionOptionsToOptions(optionsInQuestion);
  }, [from.question, fromQuestionnairePages]);

  const toOptions = useMemo(() => {
    const optionsInQuestion = getQuestionOptionsById(toQuestionnairePages, to.question);
    return convertQuestionOptionsToOptions(optionsInQuestion);
  }, [to.question, toQuestionnairePages]);

  const createMatchesQuery = useQuestionnaireMatchesQuery({
    forumId,
    sourceQuestionnaireId: from.questionnaire,
    targetQuestionnaireId: to.questionnaire,
    questionMap: [from.question, to.question],
  });

  const handleOptionsAutoMatch = () => {
    const {
      data: { data },
    } = createMatchesQuery;

    // get question pair with auto matched options
    const matchedQuestionsPair = data.questions.find((question) => {
      return question.beingSelected === from.question && question.makingSelections;
    });

    if (matchedQuestionsPair) {
      // convert to options format and remove all null options
      const newOptions = matchedQuestionsPair.options
        .filter(({ beingSelected, makingSelections }) => {
          return beingSelected !== null && makingSelections !== null;
        })
        .map(({ beingSelected: from, makingSelections: to }) => {
          return { from, to };
        });

      // exclude auto matched options that was already added
      const uniqueMatchedOptions = newOptions.filter((option) => {
        return (
          options.findIndex((existedOption) => existedOption.from === option.from && existedOption.to === option.to) ===
          -1
        );
      });

      return onOptionsChange([...options, ...uniqueMatchedOptions]);
    }
  };

  const handleAddOption = (from, to) => {
    const newOptions = [...options, { from, to }];
    return onOptionsChange(newOptions);
  };

  const handleDeleteMatching = (index) => {
    const newOptions = removeAt(options, index);
    return onOptionsChange(newOptions);
  };

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" size="middle" fullWidth>
      <Mapper
        first={{
          title: fromQuestionTitle,
          options: fromOptions,
          placeholder: t("Select question item from list"),
          errorMessage: t("Please select a question item"),
        }}
        second={{
          title: toQuestionTitle,
          options: toOptions,
          placeholder: t("Select question item from list"),
          errorMessage: t("Please select a question item"),
        }}
        onSubmit={handleAddOption}
        onMatchesAutoCreate={handleOptionsAutoMatch}
        isAutoMatchesLoading={createMatchesQuery.isLoading}
      />
      <ListingTable
        options={{
          from: fromOptions,
          to: toOptions,
        }}
        mappings={options ?? []}
        onDelete={handleDeleteMatching}
      />
    </Space>
  );
};

OptionsMatching.propTypes = {
  forumId: PropTypes.number.isRequired,
  from: PropTypes.shape({
    questionnaire: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  to: PropTypes.shape({
    questionnaire: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
  onOptionsChange: PropTypes.func,
};

OptionsMatching.defaultProps = {
  options: undefined,
  onOptionsChange: () => undefined,
};

export default OptionsMatching;
