import "./styles.less";

import Icon, {
  CalendarOutlined,
  CheckSquareOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ScheduleOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useQueryClient } from "@tanstack/react-query";
import { useGetBuildingQuery, useGetBuildingsQuery } from "api/queries/buildings";
import { useGetForumByIdQuery } from "api/queries/forums";
import KEYS from "api/queries/keys";
import { useGetVenueQuery } from "api/queries/venues";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { ReactComponent as Logo } from "assets/img/logo.svg";
import { Anchor, Layout, Menu } from "components/styleguide";
import Breadcrumb from "components/styleguide/components/Breadcrumb";
import Button from "components/styleguide/components/Button";
import { BuildingResponseModel, ForumResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useParams } from "react-router-dom";

import { getBuildingMenuItems, getDefaultOpenKeys, getDefaultSelectedKeys, getVenueMenuItems } from "./helpers";

import styles from "./styles.module.less";

const { Header, Sider, Content } = Layout;

const SystemAdminLayout = () => {
  const { t } = useTranslation();
  const { lang, venueId, forumId, buildingId } = useParams();
  const queryClient = useQueryClient();

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [venueName, setVenueName] = useState<string>();
  const [forumName, setForumName] = useState<string>();
  const [buildingName, setBuildingName] = useState<string | null>(null);
  const [buildings, setBuildings] = useState<BuildingResponseModel[]>();
  const [openKeys, setOpenKeys] = useState<string[]>(getDefaultOpenKeys(location));
  const [selectedKeys, setSelectedKeys] = useState<string[]>(getDefaultSelectedKeys(location));

  const handleSidebarCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  useGetVenueQuery(
    { venueId: toNumber(venueId) },
    {
      enabled: !!venueId,
      staleTime: Infinity,
      cacheTime: Infinity,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: ({ data }: { data: any }) => {
        setOpenKeys(["venues"]);
        setVenueName(data.name);
        if (!buildingId) {
          setSelectedKeys(["venues", "venue"]);
        }
      },
    },
  );

  useGetForumByIdQuery(
    { forumId: toNumber(forumId) },
    {
      enabled: !!forumId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: ({ data }: { data: ForumResponseModel }) => {
        setForumName(data.title);
      },
    },
  );

  const buildingQuery = useGetBuildingQuery(
    { venueId: toNumber(venueId), buildingId: toNumber(buildingId) },
    {
      enabled: !!venueId && !!buildingId,
      staleTime: Infinity,
      cacheTime: Infinity,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onSuccess: ({ data }: { data: any }) => {
        setBuildingName(data.name);
        setOpenKeys(["venues", data.id?.toString() ?? "venue"]);
        setSelectedKeys(["venues", `${data.id?.toString()}_#details` ?? "venue"]);
      },
    },
  );

  useGetBuildingsQuery(
    { venueId: toNumber(venueId) },
    {
      enabled: !!venueId,
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess: ({ data }: { data: BuildingResponseModel[] }) => {
        setBuildings(data);
      },
    },
  );

  const getVenuesItems = () => {
    if (venueId && buildings) {
      const resultItems = [
        {
          key: "venue" as string | undefined,
          id: "venue" as string | undefined,
          label: <Link to={`venues/${venueId}`}>{t("Venue")}</Link>,
          children: null as
            | {
                key: string;
                label: JSX.Element;
                id: string;
                link: string;
              }[]
            | null,
        },
      ];
      getVenueMenuItems(t, buildings, venueId).forEach((item) => {
        resultItems.push({
          key: item.key?.toString(),
          id: item.id?.toString(),
          label: item.label,
          children: getBuildingMenuItems(t, location, venueId, item.id?.toString()),
        });
      });
      return resultItems;
    }
    return null;
  };

  const sideBarItems = [
    {
      key: "forums",
      icon: <CalendarOutlined />,
      label: (
        <Link to="forums" title={t("Forums")}>
          {t("Forums")}
        </Link>
      ),
      id: "forums",
    },
    {
      key: "participation-codes",
      icon: <CheckSquareOutlined />,
      label: (
        <Link to="participation-codes" title={t("Participation Codes")}>
          {t("Participation Codes")}
        </Link>
      ),
      id: "participation-codes",
    },
    {
      key: "venues",
      icon: <ScheduleOutlined />,
      label: (
        <Link to="venues" title={t("Venues")}>
          {t("Venues")}
        </Link>
      ),
      children: getVenuesItems(),
    },
    {
      key: "gdpr-requests",
      icon: <SolutionOutlined />,
      label: (
        <Link to="gdpr-requests" title={t("GDPR Requests")}>
          {t("GDPR Requests")}
        </Link>
      ),
    },
  ];

  const breadcrumbs = [
    {
      path: "/:lang/system-admin",
      breadcrumb: null,
      children: [
        {
          path: "venues",
          breadcrumb: t("breadcrumbs.venues"),
          children: [
            {
              path: ":venueId",
              breadcrumb: venueName ?? t("loading"),
              children: [
                {
                  path: "buildings",
                  breadcrumb: null,
                  children: [
                    {
                      path: ":buildingId",
                      breadcrumb: !buildingQuery.isLoading ? buildingName : t("loading"),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "forums",
          breadcrumb: t("Forums"),
          children: [
            {
              path: ":forumId",
              breadcrumb: forumName ?? "Loading...",
            },
          ],
        },
        {
          path: "gdpr-requests",
          breadcrumb: t("GDPR Requests"),
        },
      ],
    },
  ];

  const openChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (openKeys.indexOf("venues") > -1 && latestOpenKey) {
      return setOpenKeys(["venues", latestOpenKey]);
    }

    setOpenKeys(keys);
  };

  const menuClick = ({ key }: { key: string }) => {
    setSelectedKeys([key]);
  };

  const anchorChange = (currentActiveLink: string) => {
    const activeLink = currentActiveLink.length > 0 ? currentActiveLink : location.hash;

    setSelectedKeys([buildingId ? `${buildingId}_${activeLink}` : currentActiveLink]);
  };

  useEffect(() => {
    queryClient.resetQueries([KEYS.GET_BUILDING]);
  }, [buildingId]);

  return (
    <Layout>
      <Sider
        collapsed={sidebarCollapsed}
        id="systemAdminSidebar"
        className={`menu ${styles.sider}`}
        width={"400px"}
        style={{
          overflow: "auto",
          height: "100%",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          paddingBottom: "20px",
        }}
      >
        <Icon
          id="menu-fold-icon"
          className="menu-fold-icon"
          component={
            (sidebarCollapsed
              ? MenuUnfoldOutlined
              : MenuFoldOutlined) as React.ForwardRefExoticComponent<CustomIconComponentProps>
          }
          onClick={handleSidebarCollapse}
        />
        <Anchor
          style={{ overflow: "hidden", maxHeight: "innitial" }}
          affix={false}
          getContainer={() => document.getElementsByTagName("main")[0]}
          onChange={anchorChange}
          bounds={200}
        >
          <Menu
            items={sideBarItems}
            mode="inline"
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            onOpenChange={openChange}
            onClick={menuClick}
          />
        </Anchor>
      </Sider>
      <Layout style={{ marginLeft: sidebarCollapsed ? "80px" : "400px" }}>
        <Header id="systemAdminContentHeader" className={styles.header}>
          <div className={styles.breadcrumbsWrapper}>
            <Link to={`/${lang}`} className={styles.logoLink}>
              <Logo width={140} />
            </Link>
            <div className="page-name">
              <Breadcrumb breadcrumbs={breadcrumbs} excludePaths={["/", "system-admin", "/:lang"]} />
            </div>
          </div>
          <div>
            <Link to="/">
              <Button type="primary">{t("View Forums")}</Button>
            </Link>
          </div>
        </Header>
        <Layout>
          <Content id="systemAdminContentLayout">
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SystemAdminLayout;
