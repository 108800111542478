import PropTypes from "prop-types";
import React from "react";

import { Button, Card } from "../../../../../components/styleguide";

import styles from "./styles.module.less";

const QuestionnaireActions = ({ isActive, onImportClick }) => {
  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Button block onClick={onImportClick} disabled={!isActive}>
          Upload Multiple Questions
        </Button>
      </Card>
    </div>
  );
};

QuestionnaireActions.propTypes = {
  isActive: PropTypes.bool,
  onImportClick: PropTypes.func,
};

QuestionnaireActions.defaultProps = {
  isActive: true,
  onImportClick: () => undefined,
};

export default QuestionnaireActions;
