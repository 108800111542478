import { useQuery } from "@tanstack/react-query";
import api from "api";

import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

const getBuildingsKey = "get_buildings";
const getBuildingKey = "get_building";
const getForumBuildingsKey = "get_forum_buildings";
const getForumBuildingKey = "get_forum_building";

const buildingKeys = {
  getBuildings: (venueId) => [getBuildingsKey, venueId],
  getBuilding: (venueId, buildingId) => [getBuildingKey, venueId, buildingId],
  getForumBuildings: (forumId) => [getForumBuildingsKey, forumId],
  getForumBuilding: (buildingId, forumId) => [getForumBuildingKey, buildingId, forumId],
};

const useGetBuildingsQuery = getWrappedUseQuery(KEYS.GET_BUILDINGS, apiClient.Building.get);

const useGetBuildingQuery = getWrappedUseQuery(KEYS.GET_BUILDING, apiClient.Building.getById);

const useGetForumBuildingQuery = ({ buildingId, forumId }, options = {}) => {
  return useQuery(
    buildingKeys.getForumBuilding(buildingId, forumId),
    () => api.get(`/forums/${forumId}/venue/buildings/${buildingId}`),
    options,
  );
};

const useGetBuildingsFacilitiesExport = getWrappedUseQuery(
  KEYS.GET_FORUM_BUILDING_FACILITIES_EXPORT,
  apiClient.ForumBuilding.export.get,
);

export {
  getForumBuildingKey,
  useGetBuildingQuery,
  useGetBuildingsFacilitiesExport,
  useGetBuildingsQuery,
  useGetForumBuildingQuery,
};
