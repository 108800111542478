import { TableProps } from "antd";
import { Checkbox, Input, Table } from "components/styleguide";
import { QuestionnaireProfileDetailsOptionProfileDetails, QuestionOption } from "generated/api";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceAt } from "utils/arrayUtils";

interface OptionsTableProps {
  options: Array<QuestionOption>;
  value: Array<QuestionnaireProfileDetailsOptionProfileDetails>;
  onChange?: (options: Array<QuestionnaireProfileDetailsOptionProfileDetails>) => void;
}

const OptionsTable: FC<OptionsTableProps> = ({ options, value, onChange }) => {
  const { t } = useTranslation();

  const optionsMap = useMemo(() => {
    return options.reduce((map, option) => {
      map.set(option.id, option);
      return map;
    }, new Map<QuestionOption["id"], QuestionOption>());
  }, [options]);

  const updateByIndex = (index: number, patch: Partial<QuestionnaireProfileDetailsOptionProfileDetails>) => {
    if (!onChange) {
      return;
    }

    const newValue = {
      ...value[index],
      ...patch,
    };

    const newArray = replaceAt(value, index, newValue);
    onChange(newArray);
  };

  const columns: TableProps<QuestionnaireProfileDetailsOptionProfileDetails>["columns"] = [
    {
      dataIndex: "id",
      title: t("Question title"),
      render: (id) => optionsMap.get(id)?.text,
    },
    {
      dataIndex: "profileTitle",
      title: t("Profile title"),
      render: (profileTitle, record, index) => (
        <Input value={profileTitle} lazy onChange={(e) => updateByIndex(index, { profileTitle: e.target.value })} />
      ),
    },
    {
      dataIndex: "isActive",
      title: t("Active"),
      render: (active, record, index) => (
        <Checkbox checked={active} onChange={(e) => updateByIndex(index, { isActive: e.target.checked })} />
      ),
    },
  ];

  return <Table columns={columns} dataSource={value} rowKey="id" pagination={false} />;
};

export default OptionsTable;
