/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumSessionFeedBackRequestModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseForumAttendeeSessionFeedbackModel } from '../apiModels';
/**
 * ForumAttendeeSessionFeedbackApi - axios parameter creator
 * @export
 */
export const ForumAttendeeSessionFeedbackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete session feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete: async (forumId: number, attendeeId: number, feedbackId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete', 'feedbackId', feedbackId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/session-feedback/{feedbackId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit the session feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {ForumSessionFeedBackRequestModel} [forumSessionFeedBackRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut: async (forumId: number, attendeeId: number, feedbackId: number, forumSessionFeedBackRequestModel?: ForumSessionFeedBackRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut', 'feedbackId', feedbackId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/session-feedback/{feedbackId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSessionFeedBackRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the session feedback given by an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [startRange] 
         * @param {string} [endRange] 
         * @param {Array<string>} [contentQuality] 
         * @param {Array<string>} [contentMatch] 
         * @param {Array<string>} [feedbackOnSpeakers] 
         * @param {Array<string>} [sessionMeetYourNeeds] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSessionFeedbackGet: async (forumId: number, attendeeId: number, startRange?: string, endRange?: string, contentQuality?: Array<string>, contentMatch?: Array<string>, feedbackOnSpeakers?: Array<string>, sessionMeetYourNeeds?: Array<string>, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdSessionFeedbackGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/session-feedback`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startRange !== undefined) {
                localVarQueryParameter['StartRange'] = (startRange as any instanceof Date) ?
                    (startRange as any).toISOString() :
                    startRange;
            }

            if (endRange !== undefined) {
                localVarQueryParameter['EndRange'] = (endRange as any instanceof Date) ?
                    (endRange as any).toISOString() :
                    endRange;
            }

            if (contentQuality) {
                localVarQueryParameter['ContentQuality'] = contentQuality;
            }

            if (contentMatch) {
                localVarQueryParameter['ContentMatch'] = contentMatch;
            }

            if (feedbackOnSpeakers) {
                localVarQueryParameter['FeedbackOnSpeakers'] = feedbackOnSpeakers;
            }

            if (sessionMeetYourNeeds) {
                localVarQueryParameter['SessionMeetYourNeeds'] = sessionMeetYourNeeds;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeSessionFeedbackApi - functional programming interface
 * @export
 */
export const ForumAttendeeSessionFeedbackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeSessionFeedbackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete session feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete(forumId: number, attendeeId: number, feedbackId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete(forumId, attendeeId, feedbackId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit the session feedback
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} feedbackId 
         * @param {ForumSessionFeedBackRequestModel} [forumSessionFeedBackRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut(forumId: number, attendeeId: number, feedbackId: number, forumSessionFeedBackRequestModel?: ForumSessionFeedBackRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut(forumId, attendeeId, feedbackId, forumSessionFeedBackRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the session feedback given by an attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [startRange] 
         * @param {string} [endRange] 
         * @param {Array<string>} [contentQuality] 
         * @param {Array<string>} [contentMatch] 
         * @param {Array<string>} [feedbackOnSpeakers] 
         * @param {Array<string>} [sessionMeetYourNeeds] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdSessionFeedbackGet(forumId: number, attendeeId: number, startRange?: string, endRange?: string, contentQuality?: Array<string>, contentMatch?: Array<string>, feedbackOnSpeakers?: Array<string>, sessionMeetYourNeeds?: Array<string>, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseForumAttendeeSessionFeedbackModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdSessionFeedbackGet(forumId, attendeeId, startRange, endRange, contentQuality, contentMatch, feedbackOnSpeakers, sessionMeetYourNeeds, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeSessionFeedbackApi - factory interface
 * @export
 */
export const ForumAttendeeSessionFeedbackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeSessionFeedbackApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete session feedback
         * @param {ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete(requestParameters: ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit the session feedback
         * @param {ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut(requestParameters: ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, requestParameters.forumSessionFeedBackRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the session feedback given by an attendee
         * @param {ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdSessionFeedbackGet(requestParameters: ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseForumAttendeeSessionFeedbackModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdSessionFeedbackGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.startRange, requestParameters.endRange, requestParameters.contentQuality, requestParameters.contentMatch, requestParameters.feedbackOnSpeakers, requestParameters.sessionMeetYourNeeds, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete operation in ForumAttendeeSessionFeedbackApi.
 * @export
 * @interface ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDeleteRequest
 */
export interface ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete
     */
    readonly feedbackId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut operation in ForumAttendeeSessionFeedbackApi.
 * @export
 * @interface ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPutRequest
 */
export interface ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut
     */
    readonly feedbackId: number

    /**
     * 
     * @type {ForumSessionFeedBackRequestModel}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut
     */
    readonly forumSessionFeedBackRequestModel?: ForumSessionFeedBackRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdSessionFeedbackGet operation in ForumAttendeeSessionFeedbackApi.
 * @export
 * @interface ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGetRequest
 */
export interface ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly startRange?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly endRange?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly contentQuality?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly contentMatch?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly feedbackOnSpeakers?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly sessionMeetYourNeeds?: Array<string>

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGet
     */
    readonly orderDir?: string
}

/**
 * ForumAttendeeSessionFeedbackApi - object-oriented interface
 * @export
 * @class ForumAttendeeSessionFeedbackApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeSessionFeedbackApi extends BaseAPI {
    /**
     * 
     * @summary Delete session feedback
     * @param {ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeSessionFeedbackApi
     */
    public forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete(requestParameters: ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeSessionFeedbackApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit the session feedback
     * @param {ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeSessionFeedbackApi
     */
    public forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut(requestParameters: ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeSessionFeedbackApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.feedbackId, requestParameters.forumSessionFeedBackRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the session feedback given by an attendee
     * @param {ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeSessionFeedbackApi
     */
    public forumForumIdAttendeesAttendeeIdSessionFeedbackGet(requestParameters: ForumAttendeeSessionFeedbackApiForumForumIdAttendeesAttendeeIdSessionFeedbackGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeSessionFeedbackApiFp(this.configuration).forumForumIdAttendeesAttendeeIdSessionFeedbackGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.startRange, requestParameters.endRange, requestParameters.contentQuality, requestParameters.contentMatch, requestParameters.feedbackOnSpeakers, requestParameters.sessionMeetYourNeeds, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }
}

