import { PersonsApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(PersonsApi);

const Persons = {
  post: api.personsPost,
  getByCompanyId: api.personsForumForumIdCompanyCompanyIdGet,
};

export default Persons;
