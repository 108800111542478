import { CompanyApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(CompanyApi);

const Companies = {
  post: api.companiesPost,
  get: api.companiesGet,
  getById: api.companiesCompanyIdGet,
};

export default Companies;
