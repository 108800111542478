import { UploadOutlined } from "@ant-design/icons";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Select, Upload } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { handleFileFakeRequest, normFile } from "utils/documentUtils";
import { validateFileSize, validateFileType, validateVenueCode } from "utils/validatorUtils";

const { Option } = Select;

export default function NewVenueDrawer({ onClose, visible, countries, form, isLoading, onFinish }) {
  const { t } = useTranslation();

  const countryOptions = countries.map((c) => {
    return (
      <Option value={c.isoCode} key={c.isoCode}>
        {c.name}
      </Option>
    );
  });

  const venueMapRules = [
    {
      validator: validateFileType(["png", "jpg", "jpeg", "tif", "tiff"]),
      message: t("Please upload only JPEG, PNG, JPG, TIF images"),
    },
    {
      validator: validateFileSize(10 * 1024 * 1024),
      message: t("The maximum file size should be {{size}}", { size: "10MB" }),
    },
  ];

  return (
    <Drawer
      className="new-venue-drawer"
      title={t("newVenue")}
      placement="right"
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
    >
      <FullHeightForm
        id="newVenueForm"
        name="new-venue"
        labelCol={{ justify: "left", span: 6 }}
        wrapperCol={{ justify: "right" }}
        labelWrap
        form={form}
        actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t("create")}
          </Button>
        }
        onFinish={onFinish}
      >
        <Form.Item
          label={t("venueName")}
          name="name"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(venueName)" }),
            },
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(venueName)" })} />
        </Form.Item>
        <Form.Item
          label={t("Venue Code")}
          name="code"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Venue Code)" }),
            },
            {
              validator: validateVenueCode(),
              message: t("The character limit is {{count}}", { count: 5 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(Venue Code)" })} />
        </Form.Item>
        <Form.Item
          label={t("venueAddress")}
          name="address"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(venueAddress)" }),
            },
            {
              max: 200,
              message: t("The character limit is {{count}}", { count: 200 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(venueAddress)" })} />
        </Form.Item>
        <Form.Item
          label={t("venueAddress2")}
          name="addressLine2"
          rules={[
            {
              max: 200,
              message: t("The character limit is {{count}}", { count: 200 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(venueAddress2)" })} />
        </Form.Item>
        <Form.Item
          label={t("city")}
          name="city"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(city)" }),
            },
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(city)" })} />
        </Form.Item>
        <Form.Item
          label={t("county")}
          name="county"
          rules={[
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(county)" })} />
        </Form.Item>
        <Form.Item
          label={t("postcode")}
          name="postCode"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(postcode)" }),
            },
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(postcode)" })} />
        </Form.Item>
        <Form.Item
          label={t("country")}
          name="countryCode"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(country)" }),
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {countryOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("email")}
          name="email"
          rules={[
            {
              type: "email",
              message: t("errors.emailValid"),
            },
            {
              max: 100,
              message: t("The character limit is {{count}}", { count: 100 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(email)" })} />
        </Form.Item>
        <Form.Item
          label={t("phone")}
          name="phone"
          rules={[
            {
              max: 50,
              message: t("The character limit is {{count}}", { count: 50 }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(phone)" })} />
        </Form.Item>
        <Form.Item
          label={t("websiteAddress")}
          name="websiteAddress"
          rules={[
            {
              max: 200,
              message: t("The character limit is {{count}}", { count: 200 }),
            },
          ]}
        >
          <Input
            placeholder={t("placeholders.add", {
              prop: "$t(websiteAddress)",
            })}
          />
        </Form.Item>
        <Form.Item
          label={t("venueDescription")}
          name="description"
          rules={[
            {
              max: 2500,
              message: t("The character limit is {{count}}", { count: 2500 }),
            },
          ]}
        >
          <Input
            placeholder={t("placeholders.add", {
              prop: "$t(venueDescription)",
            })}
          />
        </Form.Item>
        <Form.Item
          label={t("venueDirections")}
          name="directions"
          rules={[
            {
              max: 2500,
              message: t("The character limit is {{count}}", { count: 2500 }),
            },
          ]}
        >
          <Input
            placeholder={t("placeholders.add", {
              prop: "$t(venueDirections)",
            })}
          />
        </Form.Item>
        <Form.Item
          name="map"
          label={t("Venue Map")}
          getValueFromEvent={normFile}
          valuePropName="fileList"
          rules={venueMapRules}
        >
          <Upload customRequest={handleFileFakeRequest} maxCount={1} accept=".png,.jpg,.jpeg,.tif,.tiff">
            <Button icon={<UploadOutlined />}>{t("Click to Upload")}</Button>
          </Upload>
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
}

NewVenueDrawer.propTypes = {
  onClose: PropTypes.func,
  visible: PropTypes.bool,
  countries: PropTypes.array,
  form: PropTypes.any,
  isLoading: PropTypes.bool,
  onFinish: PropTypes.func,
};
