import { Empty, TableProps } from "antd";
import { useForumAttendeeMeetingLocationsByAttendeeIdQuery } from "api/queries/attendeeMeetingLocations";
import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import { Table } from "components/styleguide";
import TimeSlotTypes from "enums/TimeSlotType";
import { AttendeeMeetingLocationTimeSlotTypeModel, TimeSlotType } from "generated/api";
import { isEmpty } from "lodash";
import { FC, HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";

import AttendeeMeetingLocationsQuickSetupRow from "./TableRow";

interface AttendeeMeetingLocationsQuickSetupProps {
  forumId: number;
  attendeeId: number;
  onSubmit: (newLocations: Array<AttendeeMeetingLocationTimeSlotTypeModel>) => void;
  isSubmitting: boolean;
}

const AttendeeMeetingLocationsQuickSetup: FC<AttendeeMeetingLocationsQuickSetupProps> = ({
  forumId,
  attendeeId,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation();

  const forumFacilitiesQuery = useForumVenueFacilitiesQuery({ forumId });
  const forumFacilities = forumFacilitiesQuery.data?.data ?? [];

  const [meetingLocations, setMeetingLocations] = useState<Array<AttendeeMeetingLocationTimeSlotTypeModel>>([]);

  const forumAttendeeMeetingLocationsQuery = useForumAttendeeMeetingLocationsByAttendeeIdQuery(
    {
      forumId,
      attendeeId,
    },
    {
      onSuccess: ({ data }: { data: Array<AttendeeMeetingLocationTimeSlotTypeModel> }) => {
        setMeetingLocations(data);
      },
    },
  );

  const columns: TableProps<AttendeeMeetingLocationTimeSlotTypeModel>["columns"] = [
    {
      title: t("timeType"),
      dataIndex: "timeSlotType",
      key: "timeSlotType",
      render: (timeSlotType: TimeSlotType) => t(`timeTypes.${TimeSlotTypes[timeSlotType]}`),
      sorter: false,
    },
    {
      title: t("Facility"),
      dataIndex: "facilityId",
      key: "facilityId",
      sorter: false,
    },
    {
      title: t("Table"),
      dataIndex: "tableId",
      key: "tableId",
      sorter: false,
    },
    {
      title: t("Ratio"),
      dataIndex: "ratio",
      key: "ratio",
      sorter: false,
    },
  ];

  const submitForm = (newValues: Array<AttendeeMeetingLocationTimeSlotTypeModel>) => {
    onSubmit(newValues);
  };

  if (isEmpty(meetingLocations)) {
    return <Empty />;
  }

  return (
    <Table
      columns={columns}
      dataSource={meetingLocations}
      components={{
        body: {
          row: isEmpty(meetingLocations) ? () => <></> : AttendeeMeetingLocationsQuickSetupRow,
        },
      }}
      onRow={(record, index) => {
        return {
          forumId,
          meetingLocation: record,
          setMeetingLocation: (idx: number, value: AttendeeMeetingLocationTimeSlotTypeModel) => {
            const newValues = [...meetingLocations.slice(0, idx), value, ...meetingLocations.slice(idx + 1)];
            setMeetingLocations(newValues);
            submitForm(newValues);
          },
          forumFacilities,
          index,
          loading: forumAttendeeMeetingLocationsQuery.isLoading || isSubmitting,
        } as HTMLAttributes<HTMLElement>;
      }}
      loading={forumAttendeeMeetingLocationsQuery.isLoading || isSubmitting}
      rowKey="timeSlotType"
      id="meeting-locations-table"
    />
  );
};

export default AttendeeMeetingLocationsQuickSetup;
