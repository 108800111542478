import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useImportBedroomsMutation = getWrappedUseMutation(apiClient.FileImport.venues.bedrooms.post);

export const useImportForumBedroomsMutation = getWrappedUseMutation(apiClient.FileImport.forums.bedrooms.post);

export const useImportForumFacilitiesMutation = getWrappedUseMutation(apiClient.FileImport.forums.facilities.post);

export const useImportFacilitiesMutation = getWrappedUseMutation(apiClient.FileImport.venues.facilities.post);

export const useImportTablesMutation = getWrappedUseMutation(apiClient.FileImport.venues.tables.post);

export const useImportForumTablesMutation = getWrappedUseMutation(apiClient.FileImport.forums.tables.post);

export const useImportTimeslotsMutation = getWrappedUseMutation(apiClient.FileImport.timeslots.post);
