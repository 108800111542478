import { Form as OriginalForm } from "antd";

import useYupValidator from "./hooks/useYupValidator";

type OriginalFormInterface = typeof OriginalForm;

export interface FormInterface extends OriginalFormInterface {
  useYupValidator: typeof useYupValidator;
}

const Form: FormInterface = Object.assign(OriginalForm, { useYupValidator });

export default Form;
