import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

const useCreateForumMutation = getWrappedUseMutation(apiClient.Forums.post);

const useCreateForumBasedOnMutation = getWrappedUseMutation(apiClient.Forums.postFromExisting);

const useUpdateForumContactInfoMutation = getWrappedUseMutation(apiClient.Forums.contact.put);

const useUpdateForumMutation = getWrappedUseMutation(apiClient.Forums.put);
const useUpdateForumSocialLinksMutation = getWrappedUseMutation(apiClient.Forums.socialLinks.put);

const useUploadLogoMutation = getWrappedUseMutation(apiClient.Forums.logo.post);

export {
  useCreateForumBasedOnMutation,
  useCreateForumMutation,
  useUpdateForumContactInfoMutation,
  useUpdateForumMutation,
  useUpdateForumSocialLinksMutation,
  useUploadLogoMutation,
};
