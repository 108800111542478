import { DownOutlined } from "@ant-design/icons";
import { MenuProps } from "antd";
import { useExportForumAttendeeMeetingLocationsQuery } from "api/queries/attendeeMeetingLocations";
import { Dropdown } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

interface MeetingLocationsExportButtonProps {
  timeslotId: number | undefined;
  groupIds: number[] | undefined;
  peopleTypes: string[] | undefined;
  facilities: number[] | undefined;
}

enum TimeslotsExportType {
  Export,
  ExportAll,
}

enum MenuItems {
  ExportAllTimeslots,
}

const MeetingLocationsExportButton: FC<MeetingLocationsExportButtonProps> = ({
  timeslotId,
  groupIds,
  peopleTypes,
  facilities,
}) => {
  const forumId = toNumber(useParams().id);
  const { t } = useTranslation();

  const [timeslotsExportType, setTimeslotsExportType] = useState<TimeslotsExportType | null>(null);

  const { isFetching: isExportMeetingLocationsLoading } = useExportForumAttendeeMeetingLocationsQuery(
    {
      forumId,
      forumGroupIds: groupIds,
      timeSlotId: timeslotsExportType === TimeslotsExportType.Export ? timeslotId : undefined,
      forumAttendeeParticipationCodes: peopleTypes,
      forumFacilityIds: facilities,
    },
    {
      enabled: timeslotsExportType !== null,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
      onSettled: () => setTimeslotsExportType(null),
    },
  );

  const onExport = () => {
    if (!timeslotId || !groupIds || !groupIds.length) {
      toast.error(t("The timeslot and group must be selected"));
      return;
    }

    setTimeslotsExportType(TimeslotsExportType.Export);
  };

  const onMenuItemClick: MenuProps["onClick"] = (e) => {
    const menuItemKey = toNumber(e.key);

    switch (menuItemKey) {
      case MenuItems.ExportAllTimeslots:
        setTimeslotsExportType(TimeslotsExportType.ExportAll);
        break;
    }
  };

  const items = [
    {
      label: t("Export All Timeslots"),
      key: MenuItems.ExportAllTimeslots,
    },
  ];

  const menu = {
    items,
    onClick: onMenuItemClick,
  };

  return (
    <Dropdown.Button menu={menu} icon={<DownOutlined />} loading={isExportMeetingLocationsLoading} onClick={onExport}>
      {t("Export")}
    </Dropdown.Button>
  );
};

export default MeetingLocationsExportButton;
