import { SelectionSiteContentSelectionSiteContentPageItemsInner } from "../../../generated/api";
import { SelectionSiteItemTypeMap } from "../../selectionSiteContentItemTypeUtils";
import Content from "./content";
import ContentItem from "./content-item";
import getContentItemsIds from "./get-content-items-ids";
import getDependentItems from "./get-dependent-items";

type Item = SelectionSiteContentSelectionSiteContentPageItemsInner;
type Id = Item["id"];
const createDependentCollector = (typesToPick: Array<keyof SelectionSiteItemTypeMap>) => {
  return (content: Content, item: ContentItem): Array<Id> => {
    // we do not collect dependencies across all items for performance reasons
    const dependentItems = content.getItemsByTypes(typesToPick);

    return getContentItemsIds(getDependentItems(dependentItems, item));
  };
};

export default createDependentCollector;
