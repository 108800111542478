import { ForumAttendeeItineraryApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeItineraryApi);

const ForumAttendeeItinerary = {
  removedItineraries: {
    get: api.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsGet,
    put: api.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsRemovedItemIdPut,
    document: {
      get: api.forumForumIdAttendeesAttendeeIdItineraryRemovedItemsDocumentGet,
    },
  },
  checkIn: {
    put: api.forumForumIdAttendeesAttendeeIdItineraryItineraryIdCheckInPut,
  },
};

export default ForumAttendeeItinerary;
