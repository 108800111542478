import { ForumSeminarSessionApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumSeminarSessionApi);

const ForumSeminarSessions = {
  getAll: api.forumsForumIdSeminarsForumSeminarIdSessionsGet,
  post: api.forumsForumIdSeminarsForumSeminarIdSessionsPost,
  put: api.forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdPut,
  delete: api.forumsForumIdSeminarsForumSeminarIdSessionsForumSeminarSessionIdDelete,
};

export default ForumSeminarSessions;
