import { Button, Modal, Space } from "antd";
import { AttendeeItineraryItemResponseModel, AttendeeItinerarySpeakerDeleteOptions } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

interface AttendeeItineraryDeleteOptionsProps {
  record: AttendeeItineraryItemResponseModel;
  onDelete: (id: number, option: AttendeeItinerarySpeakerDeleteOptions) => void;
}

const AttendeeItineraryDeleteOptions: FC<AttendeeItineraryDeleteOptionsProps> = ({ record, onDelete }) => {
  const { t } = useTranslation();

  const deleteItinerary = (option: AttendeeItinerarySpeakerDeleteOptions) => {
    onDelete(record.id, option);
    Modal.destroyAll();
  };

  return (
    <Space className={styles.modalButtonWrapper}>
      <Button key="session" onClick={() => deleteItinerary(AttendeeItinerarySpeakerDeleteOptions.DeleteSession)}>
        {t("This session")}
      </Button>
      <Button key="speaker" onClick={() => deleteItinerary(AttendeeItinerarySpeakerDeleteOptions.OnlySpeaker)}>
        {t("Only speaker")}
      </Button>
    </Space>
  );
};

export default AttendeeItineraryDeleteOptions;
