/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CompanyType = {
    Suppliers: 'Suppliers',
    Delegates: 'Delegates',
    Speakers: 'Speakers'
} as const;

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];



