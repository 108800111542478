import Icon, { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Select, Space } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import styles from "./styles.module.less";

const { Option } = Select;

export default function ComboBox({
  options,
  fieldNames = {},
  placeholder,
  addButtonText = "Add Item",
  intputPlaceholder,
  onAdd,
  onDelete,
  value,
  onChange,
  maxLength,
  stayOpen = false,
}) {
  const [textInput, setTextInput] = useState("");
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onNameChange = (event) => {
    setTextInput(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (textInput.length > 0) {
      onAdd(textInput);
      setTextInput("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  const removeItem = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete(item);
    setIsOpen(true);
  };

  const onItemChange = (changedValue) => {
    onChange?.(changedValue);
  };

  const onBlur = (e) => {
    if (e.relatedTarget?.id !== "addInput" && e.relatedTarget?.id !== "addButton") {
      return setIsOpen(stayOpen);
    }
  };

  return (
    <Select
      value={value}
      open={isOpen}
      onClick={() => setIsOpen(true)}
      onBlur={onBlur}
      placeholder={placeholder}
      onChange={onItemChange}
      dropdownRender={(menu) => (
        <div>
          {menu}
          <Divider
            style={{
              margin: "8px 0",
            }}
          />
          <Space
            style={{
              padding: "0 8px 4px",
            }}
          >
            <Input
              id="addInput"
              placeholder={intputPlaceholder}
              ref={inputRef}
              value={textInput}
              onChange={onNameChange}
              maxLength={maxLength}
              style={{
                width: "100%",
              }}
            />
            <Button id="addButton" type="text" icon={<PlusOutlined />} onClick={addItem}>
              {addButtonText}
            </Button>
          </Space>
        </div>
      )}
    >
      {options?.map((o) => (
        <Option key={o[fieldNames.key]} value={o[fieldNames.value]}>
          <span className={styles.optionWrapper}>
            <span> {o[fieldNames.label]}</span>
            <span className={styles.iconWrapper}>
              <Icon className="delete-icon" component={DeleteFilled} onClick={(event) => removeItem(event, o)} />
            </span>
          </span>
        </Option>
      ))}
    </Select>
  );
}

ComboBox.propTypes = {
  options: PropTypes.array,
  fieldNames: PropTypes.object,
  placeholder: PropTypes.string,
  addButtonText: PropTypes.string,
  intputPlaceholder: PropTypes.string,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  stayOpen: PropTypes.bool,
};
