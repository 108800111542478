/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentDefinitionTypes } from '../apiModels';
// @ts-ignore
import { ForumDocumentCustomPageElementCreateModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentCustomPageElementUpdateModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentDefinitionSectionCreateModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentDefinitionSectionListItemModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentDefinitionSectionResponseModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentDefinitionSectionUpdateModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentDefinitionStandardSectionOptionValueRequestModel } from '../apiModels';
// @ts-ignore
import { InvalidEntityStateExceptionResponse } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ReorderRequestModel } from '../apiModels';
// @ts-ignore
import { ValueOutOfRangeExceptionResponse } from '../apiModels';
/**
 * ForumDocumentDefinitionSectionApi - axios parameter creator
 * @export
 */
export const ForumDocumentDefinitionSectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a section of a forum document definition
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete: async (forumId: number, documentDefinitionSectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete', 'documentDefinitionSectionId', documentDefinitionSectionId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete: async (forumId: number, documentDefinitionSectionId: number, elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete', 'documentDefinitionSectionId', documentDefinitionSectionId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete', 'elementId', elementId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}/elements/{elementId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {number} elementId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut: async (forumId: number, documentDefinitionSectionId: number, elementId: number, reorderRequestModel?: ReorderRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut', 'documentDefinitionSectionId', documentDefinitionSectionId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut', 'elementId', elementId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}/elements/{elementId}/order`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {number} elementId 
         * @param {ForumDocumentCustomPageElementUpdateModel} [forumDocumentCustomPageElementUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut: async (forumId: number, documentDefinitionSectionId: number, elementId: number, forumDocumentCustomPageElementUpdateModel?: ForumDocumentCustomPageElementUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut', 'documentDefinitionSectionId', documentDefinitionSectionId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut', 'elementId', elementId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}/elements/{elementId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentCustomPageElementUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {ForumDocumentCustomPageElementCreateModel} [forumDocumentCustomPageElementCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost: async (forumId: number, documentDefinitionSectionId: number, forumDocumentCustomPageElementCreateModel?: ForumDocumentCustomPageElementCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost', 'documentDefinitionSectionId', documentDefinitionSectionId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}/elements`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentCustomPageElementCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of a forum document definition section
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet: async (forumId: number, documentDefinitionSectionId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet', 'documentDefinitionSectionId', documentDefinitionSectionId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update options in a document definition section
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {Array<ForumDocumentDefinitionStandardSectionOptionValueRequestModel>} [forumDocumentDefinitionStandardSectionOptionValueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut: async (forumId: number, documentDefinitionSectionId: number, forumDocumentDefinitionStandardSectionOptionValueRequestModel?: Array<ForumDocumentDefinitionStandardSectionOptionValueRequestModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut', 'documentDefinitionSectionId', documentDefinitionSectionId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}/options`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentDefinitionStandardSectionOptionValueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a section of a forum document definition
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {ForumDocumentDefinitionSectionUpdateModel} [forumDocumentDefinitionSectionUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut: async (forumId: number, documentDefinitionSectionId: number, forumDocumentDefinitionSectionUpdateModel?: ForumDocumentDefinitionSectionUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut', 'documentDefinitionSectionId', documentDefinitionSectionId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentDefinitionSectionUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Changes the priority of a section in a document definition
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut: async (forumId: number, documentDefinitionSectionId: number, reorderRequestModel?: ReorderRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut', 'forumId', forumId)
            // verify required parameter 'documentDefinitionSectionId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut', 'documentDefinitionSectionId', documentDefinitionSectionId)
            const localVarPath = `/forums/{forumId}/document-definition-sections/{documentDefinitionSectionId}/reorder`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentDefinitionSectionId"}}`, encodeURIComponent(String(documentDefinitionSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sections of a forum document definition
         * @param {number} forumId 
         * @param {DocumentDefinitionTypes} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsGet: async (forumId: number, type?: DocumentDefinitionTypes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-definition-sections`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a section for a forum document definition
         * @param {number} forumId 
         * @param {ForumDocumentDefinitionSectionCreateModel} [forumDocumentDefinitionSectionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsPost: async (forumId: number, forumDocumentDefinitionSectionCreateModel?: ForumDocumentDefinitionSectionCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionSectionsPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-definition-sections`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentDefinitionSectionCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumDocumentDefinitionSectionApi - functional programming interface
 * @export
 */
export const ForumDocumentDefinitionSectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumDocumentDefinitionSectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a section of a forum document definition
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete(forumId: number, documentDefinitionSectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete(forumId, documentDefinitionSectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete(forumId: number, documentDefinitionSectionId: number, elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete(forumId, documentDefinitionSectionId, elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {number} elementId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut(forumId: number, documentDefinitionSectionId: number, elementId: number, reorderRequestModel?: ReorderRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut(forumId, documentDefinitionSectionId, elementId, reorderRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {number} elementId 
         * @param {ForumDocumentCustomPageElementUpdateModel} [forumDocumentCustomPageElementUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut(forumId: number, documentDefinitionSectionId: number, elementId: number, forumDocumentCustomPageElementUpdateModel?: ForumDocumentCustomPageElementUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut(forumId, documentDefinitionSectionId, elementId, forumDocumentCustomPageElementUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {ForumDocumentCustomPageElementCreateModel} [forumDocumentCustomPageElementCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost(forumId: number, documentDefinitionSectionId: number, forumDocumentCustomPageElementCreateModel?: ForumDocumentCustomPageElementCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost(forumId, documentDefinitionSectionId, forumDocumentCustomPageElementCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details of a forum document definition section
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet(forumId: number, documentDefinitionSectionId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumDocumentDefinitionSectionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet(forumId, documentDefinitionSectionId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update options in a document definition section
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {Array<ForumDocumentDefinitionStandardSectionOptionValueRequestModel>} [forumDocumentDefinitionStandardSectionOptionValueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut(forumId: number, documentDefinitionSectionId: number, forumDocumentDefinitionStandardSectionOptionValueRequestModel?: Array<ForumDocumentDefinitionStandardSectionOptionValueRequestModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut(forumId, documentDefinitionSectionId, forumDocumentDefinitionStandardSectionOptionValueRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a section of a forum document definition
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {ForumDocumentDefinitionSectionUpdateModel} [forumDocumentDefinitionSectionUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut(forumId: number, documentDefinitionSectionId: number, forumDocumentDefinitionSectionUpdateModel?: ForumDocumentDefinitionSectionUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut(forumId, documentDefinitionSectionId, forumDocumentDefinitionSectionUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Changes the priority of a section in a document definition
         * @param {number} forumId 
         * @param {number} documentDefinitionSectionId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut(forumId: number, documentDefinitionSectionId: number, reorderRequestModel?: ReorderRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut(forumId, documentDefinitionSectionId, reorderRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get sections of a forum document definition
         * @param {number} forumId 
         * @param {DocumentDefinitionTypes} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsGet(forumId: number, type?: DocumentDefinitionTypes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumDocumentDefinitionSectionListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsGet(forumId, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a section for a forum document definition
         * @param {number} forumId 
         * @param {ForumDocumentDefinitionSectionCreateModel} [forumDocumentDefinitionSectionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionSectionsPost(forumId: number, forumDocumentDefinitionSectionCreateModel?: ForumDocumentDefinitionSectionCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionSectionsPost(forumId, forumDocumentDefinitionSectionCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumDocumentDefinitionSectionApi - factory interface
 * @export
 */
export const ForumDocumentDefinitionSectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumDocumentDefinitionSectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a section of a forum document definition
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete(requestParameters.forumId, requestParameters.documentDefinitionSectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.elementId, requestParameters.reorderRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.elementId, requestParameters.forumDocumentCustomPageElementUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.forumDocumentCustomPageElementCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of a forum document definition section
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumDocumentDefinitionSectionResponseModel> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update options in a document definition section
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.forumDocumentDefinitionStandardSectionOptionValueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a section of a forum document definition
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.forumDocumentDefinitionSectionUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Changes the priority of a section in a document definition
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.reorderRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sections of a forum document definition
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsGet(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumDocumentDefinitionSectionListItemModel>> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsGet(requestParameters.forumId, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a section for a forum document definition
         * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionSectionsPost(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionSectionsPost(requestParameters.forumId, requestParameters.forumDocumentDefinitionSectionCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDeleteRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete
     */
    readonly documentDefinitionSectionId: number
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDeleteRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete
     */
    readonly elementId: number
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPutRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut
     */
    readonly elementId: number

    /**
     * 
     * @type {ReorderRequestModel}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut
     */
    readonly reorderRequestModel?: ReorderRequestModel
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPutRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut
     */
    readonly elementId: number

    /**
     * 
     * @type {ForumDocumentCustomPageElementUpdateModel}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut
     */
    readonly forumDocumentCustomPageElementUpdateModel?: ForumDocumentCustomPageElementUpdateModel
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPostRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {ForumDocumentCustomPageElementCreateModel}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost
     */
    readonly forumDocumentCustomPageElementCreateModel?: ForumDocumentCustomPageElementCreateModel
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGetRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {string}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPutRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {Array<ForumDocumentDefinitionStandardSectionOptionValueRequestModel>}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut
     */
    readonly forumDocumentDefinitionStandardSectionOptionValueRequestModel?: Array<ForumDocumentDefinitionStandardSectionOptionValueRequestModel>
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPutRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {ForumDocumentDefinitionSectionUpdateModel}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut
     */
    readonly forumDocumentDefinitionSectionUpdateModel?: ForumDocumentDefinitionSectionUpdateModel
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPutRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut
     */
    readonly documentDefinitionSectionId: number

    /**
     * 
     * @type {ReorderRequestModel}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut
     */
    readonly reorderRequestModel?: ReorderRequestModel
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsGet operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGetRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {DocumentDefinitionTypes}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGet
     */
    readonly type?: DocumentDefinitionTypes
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionSectionsPost operation in ForumDocumentDefinitionSectionApi.
 * @export
 * @interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPostRequest
 */
export interface ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumDocumentDefinitionSectionCreateModel}
     * @memberof ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPost
     */
    readonly forumDocumentDefinitionSectionCreateModel?: ForumDocumentDefinitionSectionCreateModel
}

/**
 * ForumDocumentDefinitionSectionApi - object-oriented interface
 * @export
 * @class ForumDocumentDefinitionSectionApi
 * @extends {BaseAPI}
 */
export class ForumDocumentDefinitionSectionApi extends BaseAPI {
    /**
     * 
     * @summary Delete a section of a forum document definition
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete(requestParameters.forumId, requestParameters.documentDefinitionSectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.elementId, requestParameters.reorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.elementId, requestParameters.forumDocumentCustomPageElementUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPostRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.forumDocumentCustomPageElementCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of a forum document definition section
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGetRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update options in a document definition section
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.forumDocumentDefinitionStandardSectionOptionValueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a section of a forum document definition
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.forumDocumentDefinitionSectionUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Changes the priority of a section in a document definition
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut(requestParameters.forumId, requestParameters.documentDefinitionSectionId, requestParameters.reorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sections of a forum document definition
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsGet(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsGetRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsGet(requestParameters.forumId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a section for a forum document definition
     * @param {ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionSectionApi
     */
    public forumsForumIdDocumentDefinitionSectionsPost(requestParameters: ForumDocumentDefinitionSectionApiForumsForumIdDocumentDefinitionSectionsPostRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionSectionApiFp(this.configuration).forumsForumIdDocumentDefinitionSectionsPost(requestParameters.forumId, requestParameters.forumDocumentDefinitionSectionCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}

