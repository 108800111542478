import EnumBuilder from "./enumBuilderUtils";

const builder = new EnumBuilder();

const QuestionCategory = builder
  .add("None", "None")
  .add("CompanyDescription", "Company Description")
  .add("ProfessionalBiography", "Professional Biography")
  .build();

export default QuestionCategory;
