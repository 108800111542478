import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Empty } from "antd";
import { Table } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";

import DraggableRow from "./DraggableRow";
import DraggableWrapper from "./DraggableWrapper";

export default function DraggableTable({
  disableOrdering = false,
  dataSource,
  columns,
  onDragEnd,
  components = undefined,
  rowKey = "id",
  ...props
}) {
  const sortableColumns = useMemo(() => {
    if (columns.findIndex((c) => c.dataIndex === "dragHandle") > -1) {
      return columns;
    } else {
      return [
        {
          dataIndex: "dragHandle",
          title: "",
        },
        ...columns,
      ];
    }
  }, [columns]);

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragStart(event) {
    const { active } = event;

    setActiveId(active?.id);
  }

  function handleDragEnd(event) {
    if (onDragEnd && !disableOrdering) {
      onDragEnd(event);
    }

    // Stop overlay.
    setActiveId(null);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <Table
        rowKey={rowKey}
        dataSource={dataSource}
        columns={sortableColumns}
        disableOrdering={disableOrdering}
        {...props}
        components={{
          body: dataSource?.length ? (
            {
              wrapper: DraggableWrapper,
              row: DraggableRow,
              cell: components?.body?.cell,
            }
          ) : (
            <Empty />
          ),
        }}
      />
      <DragOverlay>
        <Table
          rowKey={rowKey}
          columns={sortableColumns}
          showHeader={false}
          {...props}
          dataSource={activeId ? [dataSource[dataSource.findIndex((item) => item[rowKey] === activeId)]] : []}
          pagination={false}
          components={{
            body: {
              cell: components?.body?.cell,
            },
          }}
        />
      </DragOverlay>
    </DndContext>
  );
}

DraggableTable.propTypes = {
  disableOrdering: PropTypes.bool,
  dataSource: PropTypes.any,
  columns: PropTypes.any,
  onDragEnd: PropTypes.func,
  onChange: PropTypes.func,
  components: PropTypes.object,
  rowKey: PropTypes.string,
};
