import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";

import apiClient from "../../../api-client";
import KEYS from "../keys";

const useGetWelcomeLetterQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_FORUM_WELCOME_LETTER,
  apiClient.ItineraryBookletsForumWelcomeLetter.get,
);

export { useGetWelcomeLetterQuery };
