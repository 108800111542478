import { ForumTeamMemberApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumTeamMemberApi);

const ForumTeamMember = {
  get: api.forumForumIdTeamMembersTeamMemberIdGet,
  put: api.forumForumIdTeamMembersTeamMemberIdPut,
  delete: api.forumForumIdTeamMembersTeamMemberIdDelete,
  post: api.forumForumIdTeamMembersPost,
  getAll: api.forumForumIdTeamMembersGet,
  unassignedUsers: {
    get: api.forumForumIdTeamMembersUnassignedUsersGet,
  },
};

export default ForumTeamMember;
