import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useForumDocumentDefinitionSectionsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS,
  apiClient.ForumDocument.documentDefinitionSection.getAll,
);

export const useForumDocumentDefinitionSectionQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTION,
  apiClient.ForumDocument.documentDefinitionSection.get,
);

export const useDocumentDefinitionStandardSectionsQuery = getWrappedUseQuery(
  KEYS.GET_DOCUMENT_DEFINITION_STANDARD_SECTIONS,
  apiClient.ForumDocument.documentStandardSections.get,
);

export const useDocumentDefinitionCustomPdfQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PDF,
  apiClient.ForumDocument.documentCustomPdf.get,
);

export const useForumDocumentDefinitionCustomPageQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE,
  apiClient.ForumDocument.forumDocumentCustomPage.get,
);

export const useForumDocumentDefinitionCustomPageIdQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_ID,
  apiClient.ForumDocument.forumDocumentCustomPage.elements.get,
);

export const useForumDocumentDefinitionWatermarksQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_WATERMARKS,
  apiClient.ForumDocument.documentDefinitionWatermark.getAll,
);

export const useForumDocumentCustomPageMergeTagsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_MERGE_TAGS,
  apiClient.ForumDocument.forumDocumentCustomPage.mergeTags.get,
);
