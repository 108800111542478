import { Layout } from "antd";
import { EventLayoutContext } from "layouts/EventLayout/types";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

const { Content } = Layout;

const ForumAdminLayout = () => {
  const { id, forum } = useOutletContext<EventLayoutContext>();

  return (
    <Layout>
      <Content>
        <Outlet context={{ id, forum }} />
      </Content>
    </Layout>
  );
};

export default ForumAdminLayout;
