import { useAttendeePreferencesSelectionOptionDefinitionsQuery } from "api/queries/attendees";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input, Select, Spin } from "components/styleguide";
import { AttendeeChoice, SelectionChoiceModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

interface SelectionOptionFormProps {
  isLoading: boolean;
  onSubmit: (values: AttendeeChoice) => void;
  onClose: () => void;
  initialValue?: AttendeeChoice;
}

const SelectionOptionForm: FC<SelectionOptionFormProps> = ({ isLoading, onSubmit, onClose, initialValue }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm();

  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const [choiceId, setChoiceId] = useState<number | undefined>(initialValue?.choiceId);
  const [optionId, setOptionId] = useState<number | undefined>(initialValue?.optionId);

  const {
    data: { data: selectionChoices = [] as Array<SelectionChoiceModel> } = {},
    isLoading: isSelectionChoicesLoading,
  } = useAttendeePreferencesSelectionOptionDefinitionsQuery({
    forumId,
    attendeeId,
    selectionChoiceId: initialValue?.choiceId,
  });

  const choiceOptions = useMemo(() => {
    return selectionChoices.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [selectionChoices]);

  const choiceDescription = useMemo(() => {
    if (choiceId) {
      const currentSelectionChoice = selectionChoices.find(({ id }) => id === choiceId);
      return currentSelectionChoice?.description;
    }
  }, [selectionChoices, choiceId]);

  const choiceOptionOptions = useMemo(() => {
    if (choiceId) {
      const currentSelectionChoiceOptions = selectionChoices.find(({ id }) => id === choiceId)?.options;
      return currentSelectionChoiceOptions?.map(({ id, value }) => ({
        label: value,
        value: id,
      }));
    }
  }, [selectionChoices, choiceId]);

  const onChoiceChange = (id: number) => {
    setChoiceId(id);
    setOptionId(undefined);
  };

  useEffect(() => {
    if (choiceId) {
      form.resetFields(["description"]);
    }
  }, [choiceId]);

  useEffect(() => {
    if (!optionId) {
      form.resetFields(["optionId"]);
    }
  }, [optionId]);

  if (isSelectionChoicesLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <FullHeightForm
      form={form}
      actionsPrepend={
        <Button onClick={onClose} disabled={isLoading}>
          {t("Cancel")}
        </Button>
      }
      actionsAppend={
        <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
          {t("Save")}
        </Button>
      }
      onFinish={onSubmit}
    >
      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label={t("Choice Name")}
        name="choiceId"
        initialValue={choiceId}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(Choice Name)" }),
          },
        ]}
      >
        <Select options={choiceOptions} onChange={onChoiceChange} />
      </Form.Item>
      <Form.Item
        initialValue={choiceDescription}
        labelCol={{ span: 24, offset: 0 }}
        label={t("Choice Description")}
        name="description"
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24, offset: 0 }}
        label={t("Choice Value")}
        name="optionId"
        initialValue={optionId}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(Choice Value)" }),
          },
        ]}
      >
        <Select options={choiceOptionOptions} onChange={(id) => setOptionId(id)} disabled={!choiceId} />
      </Form.Item>
    </FullHeightForm>
  );
};

export default SelectionOptionForm;
