import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Select, TableProps } from "antd";
import { useUpdateParticipationCodeMutation } from "api/mutations/participationCodes";
import KEYS from "api/queries/keys";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import ParticipationCodeTypes from "backend-models/participationCodeTypes";
import { Input } from "components/styleguide";
import { ParticipationCodeModel, ParticipationCodeType } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import Table from "../../../../../components/styleguide/components/Table";

import styles from "../../styles.module.less";

interface ParticipationCodesTableProps {
  className: string;
}

const ParticipationCodesTable = ({ className }: ParticipationCodesTableProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [codeSearch, setCodeSearch] = useState<string | undefined>();
  const [descriptionSearch, setDescriptionSearch] = useState<string | undefined>();
  const [typeFilter, setTypeFilter] = useState<string | undefined>();

  const updateMutation = useUpdateParticipationCodeMutation();

  const {
    response: { data: { data: { items = [] } = {} } = {}, isLoading } = {},
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useParticipationCodesQuery,
    {},
    {
      code: codeSearch || undefined,
      description: descriptionSearch || undefined,
      type: typeFilter || undefined,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 50, 100] },
  );

  const updateParticipationCode = useCallback(
    (participationCode: ParticipationCodeModel) => {
      if (!updateMutation.isLoading) {
        updateMutation.mutate(
          {
            participationCodeModel: participationCode,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_PARTICIPATION_CODES]);
            },
            onError: () => {
              toast.error("Something went wrong");
            },
          },
        );
      }
    },
    [queryClient, updateMutation],
  );

  const handleSearch = useCallback(
    (selectedKeys: string[], confirm: () => void, searchSet: string) => {
      switch (searchSet) {
        case "code": {
          setCodeSearch(selectedKeys[0]);
          break;
        }
        case "description": {
          setDescriptionSearch(selectedKeys[0]);
          break;
        }
      }

      confirm();
    },
    [setCodeSearch, setDescriptionSearch],
  );

  const handleReset = useCallback(
    (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
      switch (searchSet) {
        case "code": {
          setCodeSearch(undefined);
          break;
        }
        case "description": {
          setDescriptionSearch(undefined);
          break;
        }
      }

      clearFilters();
      confirm();
    },
    [setCodeSearch, setDescriptionSearch],
  );

  const getCheckboxRenderer = useCallback(
    (dataIndex: string) => {
      return {
        sorter: true,
        width: "auto",
        render: (bool: boolean, record: ParticipationCodeModel) => (
          <Checkbox checked={bool} onClick={() => updateParticipationCode({ ...record, [dataIndex]: !bool })} />
        ),
      };
    },
    [updateParticipationCode],
  );

  const handleSubmit = useCallback(
    (participationCode: ParticipationCodeModel) => {
      updateParticipationCode(participationCode);
    },
    [updateParticipationCode],
  );

  const handleDescriptionSave = useCallback(
    (description: string, record: ParticipationCodeModel) => {
      if (description === record?.description) return;

      handleSubmit({ ...record, description });
    },
    [handleSubmit],
  );

  const columns = useMemo(
    () => [
      buildColumn(t("Code"), "code", {
        key: "code",
        width: "auto",
        sorter: true,
        ...getColumnSearchProps("code", handleSearch, handleReset, "code"),
      }),
      buildColumn(t("participationDescription"), "description", {
        key: "description",
        width: "30%",
        sorter: true,
        render: (oldDescription: string, record: ParticipationCodeModel) => {
          return (
            <Input
              maxLength={40}
              onBlur={({ target: { value: description } }) => handleDescriptionSave(description, record)}
              onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
                const target = event.target as HTMLInputElement;
                handleDescriptionSave(target?.value, record);
              }}
              defaultValue={oldDescription}
            />
          );
        },
        ...getColumnSearchProps("description", handleSearch, handleReset, "description"),
      }),
      buildColumn(t("Participation Code Configuration"), "type", {
        key: "type",
        width: "20%",
        filters: Object.entries(ParticipationCodeTypes.MAP).map(([key, label]) => ({
          text: label,
          value: key,
        })),
        filterMultiple: false,
        onFilter: (value: string) => {
          setTypeFilter(value);
          return true;
        },
        render: (type: ParticipationCodeType, record: ParticipationCodeModel) => {
          return (
            <Select
              className={styles.fullWidth}
              value={type}
              onSelect={(selectedType: ParticipationCodeType) => handleSubmit({ ...record, type: selectedType })}
              allowClear
              onClear={() => handleSubmit({ ...record, type: null }) }
            >
              {Object.entries(ParticipationCodeTypes.MAP).map(([key, label]) => (
                <Select.Option key={key} value={key}>
                  {t(label as string)}
                </Select.Option>
              ))}
            </Select>
          );
        },
      }),
      buildColumn(t("mealMeet"), "mealMeet", getCheckboxRenderer("mealMeet")),
      buildColumn(t("busMeet"), "busMeet", getCheckboxRenderer("busMeet")),
      buildColumn(t("includingSelToDel"), "selToDel", getCheckboxRenderer("selToDel")),
      buildColumn(t("includingSelToExec"), "includingSelToExec", getCheckboxRenderer("includingSelToExec")),
      buildColumn(t("includingAttRep"), "includingAttRep", getCheckboxRenderer("includingAttRep")),
      buildColumn(t("includingCrunch"), "includingCrunch", getCheckboxRenderer("includingCrunch")),
    ],
    [getCheckboxRenderer, handleDescriptionSave, handleSubmit, t, handleReset, handleSearch],
  );

  const handleChange: TableProps<ParticipationCodeModel>["onChange"] = (_, filters, sorter) => {
    setTypeFilter(filters.type?.[0] as string);
    handleSort(_, filters, sorter);
  };

  return (
    <Table
      id="participationCodesTable"
      className={className}
      dataSource={items}
      columns={columns}
      bordered
      loading={isLoading || updateMutation.isLoading}
      rowKey="code"
      pagination={pagination}
      onChange={handleChange}
    />
  );
};

export default ParticipationCodesTable;
