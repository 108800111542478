import { useQueryClient } from "@tanstack/react-query";
import { ColProps } from "antd";
import { useUpdateForumCompanyAddressInformationMutation } from "api/mutations/forumCompanies";
import { useGetCountriesQuery } from "api/queries/countries";
import KEYS from "api/queries/keys";
import { Button, Col, Divider, Form, Input, Row, Space, Spin } from "components/styleguide";
import { CompanyModel, CountryModel } from "generated/api";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { getFormFields } from "./helpers";

import styles from "./styles.module.less";

interface ForumCompanyAddressInfoProps {
  forumId: number;
  forumCompany: CompanyModel;
  isEditing: boolean;
  isLoading: boolean;
  setForumCompany: (value: CompanyModel) => void;
  setIsEditing: (value: boolean) => void;
}

const ForumCompanyAddressInfo: FC<ForumCompanyAddressInfoProps> = ({
  forumId,
  forumCompany,
  isEditing,
  isLoading,
  setForumCompany,
  setIsEditing,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [countries, setCountries] = useState<CountryModel[]>([]);

  useGetCountriesQuery(
    {},
    {
      onSuccess: ({ data }: { data: CountryModel[] }) => setCountries(data),
    },
  );

  const queryClient = useQueryClient();

  const updateAddressInfoMutation = useUpdateForumCompanyAddressInformationMutation();

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (values: any) => {
    const companyAddressRequestModel = { ...forumCompany, ...values };
    companyAddressRequestModel.countryCode = countries.find((country) => country.name === values.country)?.isoCode;

    updateAddressInfoMutation.mutate(
      { forumId, forumCompanyId: forumCompany.id, companyAddressRequestModel },
      {
        onSuccess: () => {
          setIsEditing(false);
          setForumCompany({ ...forumCompany, ...values });
          queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY]);
        },
      },
    );
  };

  if (!forumCompany || isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Form
        onFinish={submit}
        id="addressInfoForm"
        name="address-info"
        form={form}
        labelAlign="left"
        labelCol={{ justify: "left", span: 12 } as ColProps}
        wrapperCol={{ justify: "right", span: 12 } as ColProps}
        labelWrap
        colon={false}
      >
        <Row gutter={80}>
          {getFormFields(forumCompany, countries).map((f) => (
            <Col key={f.dataIndex} md={24} lg={12} className={styles.col}>
              {f.title ? (
                <Form.Item
                  initialValue={f.initialValue ?? forumCompany[f.dataIndex as keyof CompanyModel]}
                  label={t(`companies.addressInformationLabels.${f.title}`)}
                  name={f.dataIndex}
                  className={styles.formItem}
                >
                  {isEditing ? (
                    f.component ?? <Input maxLength={f.maxLength} />
                  ) : (
                    <label className={styles.label}>{forumCompany[f.dataIndex as keyof CompanyModel] || "-"}</label>
                  )}
                </Form.Item>
              ) : (
                <Space fullWidth />
              )}
              {f.title && <Divider />}
            </Col>
          ))}
        </Row>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button
                htmlType="submit"
                type="primary"
                loading={updateAddressInfoMutation.isLoading}
                disabled={updateAddressInfoMutation.isLoading}
              >
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default ForumCompanyAddressInfo;
