import Icon, { DeleteFilled, PlusCircleOutlined } from "@ant-design/icons";
import { FormInstance, Upload, UploadFile } from "antd";
import { ForumResponseModel } from "generated/api";
import React, { MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleFileFakeRequest } from "utils/documentUtils";

import styles from "./styles.module.less";

const { Dragger } = Upload;

interface ForumLogoProps {
  existingForum: ForumResponseModel;
  form: FormInstance;
}

const ForumLogoDragger = ({ existingForum, form, ...props }: ForumLogoProps) => {
  const { t } = useTranslation();

  const [previewImage, setPreviewImage] = useState<string | ArrayBuffer | null | undefined>(existingForum?.logoReadUrl);

  const onChange = ({ file }: { file: UploadFile }) => {
    form.setFieldValue("logo", [file]);

    // transform the file to a base64 image to use as preview image src
    if (!["uploading", "removed"].includes(file.status as string)) {
      const reader = new FileReader();

      reader.onload = ({ target }) => {
        if (target) {
          setPreviewImage(target.result);
        }
      };

      reader.readAsDataURL(file.originFileObj as Blob);
    }
  };

  const handleDeleteIcon = (e: MouseEvent) => {
    e.stopPropagation();

    form.setFieldsValue({ logoFileBlobId: null, logo: null });
    setPreviewImage(null);
  };

  return (
    <div className={styles.sizedContainer}>
      <Dragger
        {...props}
        style={!previewImage ? { width: "130px" } : {}}
        className={styles.logoUploadInput}
        accept=".jpg, .png"
        onChange={onChange}
        customRequest={handleFileFakeRequest}
        maxCount={1}
        showUploadList={false}
      >
        {previewImage ? (
          <>
            <img className={styles.previewImage} alt={t("Event Logo")} src={previewImage as string} />
            <Icon className={styles.deleteLogoIcon} onClick={handleDeleteIcon} component={() => <DeleteFilled />} />
          </>
        ) : (
          <div className={styles.uploadWrapper}>
            <>
              <PlusCircleOutlined className={styles.plusCircleIcon} />
              <p>{t("Click or drop image here")}</p>
            </>
          </div>
        )}
      </Dragger>
    </div>
  );
};

export default ForumLogoDragger;
