import { FormInstance } from "antd";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form } from "components/styleguide";
import TextEditor from "components/TextEditor";
import { AttendeeDescriptionRequestModel } from "generated/api";
import { useTranslation } from "react-i18next";

export default function EditDescriptionDrawer({
  title,
  editorPlaceholder,
  open,
  isLoading,
  form,
  onClose,
  onSubmit,
}: {
  title: string;
  editorPlaceholder: string;
  open: boolean;
  isLoading: boolean;
  form: FormInstance;

  onClose: () => void;
  onSubmit: (values: AttendeeDescriptionRequestModel) => void;
}) {
  const { t } = useTranslation();

  return (
    <Drawer
      title={title}
      placement="right"
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose={true}
      open={open}
      onClose={onClose}
    >
      <FullHeightForm
        form={form}
        onFinish={onSubmit}
        actionsPrepend={<Button onClick={onClose}>{t("Close")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
            {t("Save")}
          </Button>
        }
      >
        <Form.Item name={"description"}>
          <TextEditor maxLength={2000} placeholder={editorPlaceholder} showListFormats />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
}
