import { ForumFacilityApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumFacilityApi);

const ForumFacilities = {
  getAll: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesGet,
  get: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesFacilityIdGet,
  post: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesPost,
  put: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdPut,
  delete: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesForumFacilityIdDelete,
  exportBedrooms: {
    get: api.forumsForumIdVenueBuildingsForumBuildingIdFacilitiesExportbedroomsGet,
  },
};

export default ForumFacilities;
