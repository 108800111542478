import { Col, Divider, Row } from "components/styleguide";
import { CompanyPackageModel } from "generated/api";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface ForumCompanyPackageProps {
  companyPackage: CompanyPackageModel;
  companyName: string;
}

const ForumCompanyPackage: FC<ForumCompanyPackageProps> = ({ companyPackage, companyName }) => {
  const { t } = useTranslation();

  const contractSigned = moment(companyPackage.contractSigned);

  return (
    <>
      <Row gutter={24}>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Company Package Price")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {(companyPackage.companyPackagePrice || "-") + " " + (companyPackage.currency || "")}
            </Col>
          </Row>
          <Divider />
        </Col>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Number of itineraries")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {companyPackage.numberOfItineraries || "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Number of executives")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {companyPackage.numberOfExecutives || "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Contract Date")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {contractSigned.isValid() ? contractSigned.format(t("dateFormat")) : "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Number of rooms")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {companyPackage.numberOfRooms || "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Name Sign")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {companyPackage.nameSign ?? companyName}
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
    </>
  );
};

export default ForumCompanyPackage;
