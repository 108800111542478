import { Col, Row, Tag } from "components/styleguide";
import { AttendeeCategoryPreferenceModel } from "generated/api";
import React, { FC } from "react";

import styles from "./styles.module.less";

interface AttendeePreferencesCategoriesListProps {
  categories: Array<AttendeeCategoryPreferenceModel>;
}

const AttendeePreferencesCategoriesList: FC<AttendeePreferencesCategoriesListProps> = ({ categories }) => {
  return (
    <Row className={styles.categories}>
      <Col>
        {categories.map(({ categoryId, categoryName }) => (
          <Tag key={categoryId} className={styles.category}>
            {categoryName}
          </Tag>
        ))}
      </Col>
    </Row>
  );
};

export default AttendeePreferencesCategoriesList;
