import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useUpdateCompanyDescriptionMutation } from "api/mutations/companies";
import KEYS from "api/queries/keys";
import { Button, Col, Row, Spin } from "components/styleguide";
import TextEditor from "components/TextEditor";
import { useEditorHooks } from "components/TextEditor/hooks";
import { CompanyModel } from "generated/api";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

type CompanyDescriptionProps = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  isLoading: boolean;
  forumId: number;
  forumCompany: CompanyModel | null;
};

type CompanyDescriptionFormValues = {
  description: string;
};
const CompanyDescription: FC<CompanyDescriptionProps> = ({
  isEditing,
  setIsEditing,
  forumCompany,
  isLoading,
  forumId,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { isEditorEmpty } = useEditorHooks();

  const descriptionUpdateMutation = useUpdateCompanyDescriptionMutation();

  const handleDescriptionUpdate = (values: CompanyDescriptionFormValues) => {
    if (!forumCompany || forumCompany.description === values.description) {
      setIsEditing(false);
      return;
    }

    descriptionUpdateMutation.mutate(
      {
        forumId,
        forumCompanyId: forumCompany.id,
        companyDescriptionRequestModel: { description: values.description },
      },
      {
        onSuccess: () => {
          setIsEditing(false);
          queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY]);
        },
      },
    );
  };
  const handleEditCancel = () => {
    setIsEditing(false);
    form.resetFields();
  };

  useEffect(() => {
    if (forumCompany) {
      form.setFieldValue("description", forumCompany.description);
    }
  }, [forumCompany?.description]);

  if (isLoading || !forumCompany) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Form form={form} onFinish={handleDescriptionUpdate} id={"company-description-form"}>
        <Form.Item initialValue={forumCompany.description} name={"description"}>
          {isEditing ? (
            <TextEditor maxLength={2000} placeholder={t("Please, enter company description")} showListFormats />
          ) : (
            <span
              className={styles.description}
              dangerouslySetInnerHTML={{
                __html:
                  !forumCompany.description || isEditorEmpty(forumCompany.description) ? "-" : forumCompany.description,
              }}
            />
          )}
        </Form.Item>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={descriptionUpdateMutation.isLoading} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={handleEditCancel}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default CompanyDescription;
