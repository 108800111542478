import { useQueryClient } from "@tanstack/react-query";
import { Button } from "antd";
import { Drawer, Form, Select } from "components/styleguide";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useUpdateBlockedMeetingPreference } from "../../../../../api/mutations/attendees";
import {
  useAttendeePreferencesBlockedMeetingsQuery,
  useAttendeePreferencesMeetingsQuery,
  useAttendeesQuery,
} from "../../../../../api/queries/attendees";
import KEYS from "../../../../../api/queries/keys";
import FullHeightForm from "../../../../../components/FullHeightForm";
import {
  BlockedMeetingPreferenceRequestModel,
  BlockedMeetingPreferenceResponseModel,
} from "../../../../../generated/api";
import { buildAttendeeColumn } from "../common/utils";

interface EditBlockedMeetingDrawerProps {
  forumId: number;
  attendeeId: number;
  activeBlockedMeeting: BlockedMeetingPreferenceResponseModel | null;
  isVisible: boolean;
  onClose: () => void;
}

const EditBlockedMeetingDrawer: FC<EditBlockedMeetingDrawerProps> = ({
  forumId,
  attendeeId,
  activeBlockedMeeting,
  isVisible,
  onClose,
}) => {
  const [meetingsAttendeesIds, setMeetingsAttendeesIds] = useState<Array<number | null>>([]);
  const [blockedMeetingsAttendeesIds, setBlockedMeetingsAttendeesIds] = useState<Array<number>>([]);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const updateMutation = useUpdateBlockedMeetingPreference();

  const { isLoading, data: { data: { items: attendees = [] } = {} } = {} } = useAttendeesQuery({ forumId });

  const blockedMeetingsQuery = useAttendeePreferencesBlockedMeetingsQuery(
    { forumId, attendeeId },
    {
      onSuccess: ({ data }) => {
        if (data.items) {
          setBlockedMeetingsAttendeesIds(data.items.map((blockedMeeting) => blockedMeeting.attendeeIdNotToMeet));
        }
      },
    },
  );

  const meetingsQuery = useAttendeePreferencesMeetingsQuery(
    { forumId, attendeeId },
    {
      onSuccess: ({ data }) => {
        if (data.items) {
          setMeetingsAttendeesIds([
            ...new Set([
              ...data.items.map((meeting) => meeting.attendeeToMeetWithId),
              ...data.items
                .filter((meeting) => meeting.attendeeToMakeJointWithId)
                .map((meeting) => meeting.attendeeToMakeJointWithId as number),
            ]),
          ]);
        }
      },
    },
  );

  const getBlockedMeetingsIdsWithoutActive = () =>
    blockedMeetingsAttendeesIds.filter(
      (blockedMeetingsAttendeeId) => blockedMeetingsAttendeeId != activeBlockedMeeting?.attendeeIdNotToMeet,
    );

  const getAttendeesOptions = () => {
    return attendees
      .filter(
        ({ attendId }) =>
          !meetingsAttendeesIds.includes(attendId) &&
          !getBlockedMeetingsIdsWithoutActive().includes(attendId) &&
          Number(attendeeId) !== attendId,
      )
      .map(({ firstName, lastName, participationCode, jobTitle, forumCompany, attendId }) => ({
        label: buildAttendeeColumn(`${firstName} ${lastName}`, participationCode, jobTitle, forumCompany),
        value: attendId,
      }))
      .sort((option1, option2) => option1.label.localeCompare(option2.label));
  };

  const submit = (values: BlockedMeetingPreferenceRequestModel) => {
    if (!activeBlockedMeeting) return;

    updateMutation.mutate(
      {
        forumId,
        attendeeId,
        id: activeBlockedMeeting.id,
        blockedMeetingPreferenceRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS]);
          onClose();
          toast.success(t("Blocked Meeting successfully updated"));
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer
      title={t("Edit Blocked Meeting Preference")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose={true}
    >
      <FullHeightForm
        onFinish={submit}
        layout="vertical"
        actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
        actionsAppend={
          <Button htmlType="submit" type="primary" loading={updateMutation.isLoading}>
            {t("Update")}
          </Button>
        }
      >
        <Form.Item
          label={t("Attendee not to meet with")}
          name="attendeeNotToMeetWithId"
          initialValue={activeBlockedMeeting?.attendeeIdNotToMeet}
        >
          <Select
            loading={isLoading || meetingsQuery.isLoading || blockedMeetingsQuery.isLoading}
            showSearch
            allowClear
            options={getAttendeesOptions()}
            filterOption={(input, option) => (option?.label as string)?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          />
        </Form.Item>
        <Form.Item label={t("Negative")} name="isNegative" initialValue={activeBlockedMeeting?.isNegative}>
          <Select>
            <Select.Option value={true}>{t("Yes")}</Select.Option>
            <Select.Option value={false}>{t("No")}</Select.Option>
          </Select>
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default EditBlockedMeetingDrawer;
