import { Divider, Form, Select, Spin, Table } from "components/styleguide";
import { SessionDetailsRequestModel, SessionDetailsResponseModel, SpeakerTrackingResponseModel } from "generated/api";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

interface AttendanceProps {
  isEditing: boolean;
  speakerTracking: SpeakerTrackingResponseModel | null;
  isLoading?: boolean;
  isLoadingTable: boolean;
}

const dateTimeFormat = "L LT";

const SessionDetails = ({ isEditing, speakerTracking, isLoading, isLoadingTable }: AttendanceProps) => {
  const { t } = useTranslation();

  if (!speakerTracking || isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  const columns = [
    {
      title: t("Name"),
      dataIndex: "seminar",
      render: (seminar: string, record: SessionDetailsResponseModel) => (
        <label>
          <Form.Item
            style={{ display: "none" }}
            initialValue={record.seminar}
            name={[record.id.toString(), "seminar"]}
          ></Form.Item>
          {seminar}
        </label>
      ),
    },
    {
      title: t("Session"),
      dataIndex: "id",
      render: (_: unknown, record: SessionDetailsResponseModel) => (
        <label>
          <Form.Item
            style={{ display: "none" }}
            initialValue={record.startTime}
            name={[record.id.toString(), "startTime"]}
          />
          <Form.Item
            initialValue={record.endTime}
            style={{ display: "none" }}
            name={[record.id.toString(), "endTime"]}
          />
          {`${moment.utc(record.startTime).format(dateTimeFormat)} - ${moment
            .utc(record.endTime)
            .format(dateTimeFormat)}`}
        </label>
      ),
    },
    {
      title: t("Synopsis Confirmed"),
      dataIndex: "synopsisConfrimed",
      render: (_: boolean, record: SessionDetailsRequestModel) => (
        <Form.Item initialValue={record.synopsisConfrimed} name={[record.id.toString(), "synopsisConfrimed"]}>
          {isEditing ? (
            <Select>
              <Select.Option value={true}>{t("Yes")}</Select.Option>
              <Select.Option value={false}>{t("No")}</Select.Option>
            </Select>
          ) : (
            <label>{record.synopsisConfrimed !== null ? (record.synopsisConfrimed ? "Yes" : "No") : "-"}</label>
          )}
        </Form.Item>
      ),
    },
    {
      title: t("Presentation Received"),
      dataIndex: "presentationReveived",
      render: (_: boolean, record: SessionDetailsRequestModel) => (
        <Form.Item initialValue={record.presentationReceived} name={[record.id.toString(), "presentationReceived"]}>
          {isEditing ? (
            <Select>
              <Select.Option value={true}>{t("Yes")}</Select.Option>
              <Select.Option value={false}>{t("No")}</Select.Option>
            </Select>
          ) : (
            <label>{record.presentationReceived !== null ? (record.presentationReceived ? "Yes" : "No") : "-"}</label>
          )}
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <Form.List name="sessionsDetails">
        {() => (
          <Table
            columns={columns}
            dataSource={speakerTracking.sessionsDetails}
            loading={isLoadingTable}
            rowKey="id"
            pagination={false}
          />
        )}
      </Form.List>
      <Divider />
    </>
  );
};

export default SessionDetails;
