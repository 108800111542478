import { Spin } from "components/styleguide";
import { CompanyModel } from "generated/api";
import { FC } from "react";

import ForumCompanySocialLinks from "./ForumCompanySocialLinks";
import ForumCompanySocialLinksForm from "./ForumCompanySocialLinksForm";

interface ForumCompanySocialLinksPanelProps {
  forumId: number;
  forumCompanyId: number;
  forumCompany: CompanyModel;
  isLoading: boolean;
  isEditing: boolean;
  setIsEditing: (val: boolean) => void;
}

const ForumCompanySocialLinksPanel: FC<ForumCompanySocialLinksPanelProps> = ({
  forumId,
  forumCompanyId,
  forumCompany,
  isLoading,
  isEditing,
  setIsEditing,
}) => {
  if (isLoading) {
    return <Spin />;
  }

  return isEditing ? (
    <ForumCompanySocialLinksForm
      forumId={forumId}
      forumCompanyId={forumCompanyId}
      initialValues={forumCompany}
      setIsEditing={setIsEditing}
    />
  ) : (
    <ForumCompanySocialLinks company={forumCompany} />
  );
};

export default ForumCompanySocialLinksPanel;
