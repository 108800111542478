import { Select } from "antd";
import { Button, Col, Form, Row, Spin } from "components/styleguide";
import {
  AttendeeModel,
  AttendeePartnerModel,
  AttendeePartnerResponseModel,
  PageResponseAttendeeModel,
} from "generated/api";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useUpdateAttendeePartnerDetailsMutation } from "../../../../api/mutations/attendees";
import { useAttendeePartnerQuery, useAttendeesQuery } from "../../../../api/queries/attendees";

import styles from "./styles.module.less";

type AttendeeOption = {
  label: string;
  options:
    | {
        label: string;
        value: number;
      }[]
    | undefined;
};

type CompanyEntry = {
  name: string;
  attendees:
    | {
        id: number;
        name: string;
      }[]
    | undefined;
};

export default function AttendeePartnerDetails({
  forumId,
  attendeeId,
  isEditing,
  setIsEditing,
}: {
  forumId: number;
  attendeeId: number;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { ref, inView } = useInView();

  const [partner, setPartner] = useState<AttendeePartnerResponseModel | undefined>();
  const [attendees, setAttendees] = useState<AttendeeModel[] | undefined>([]);
  const [attendeesOptions, setAttendeesOptions] = useState<AttendeeOption[]>([]);

  const { isLoading: isLoadingPartner } = useAttendeePartnerQuery(
    {
      forumId,
      attendeeId,
    },
    {
      enabled: inView,
      onSuccess: ({ data }) => {
        setPartner(data);
      },
    },
  );

  const { isLoading: isLoadingAttendees } = useAttendeesQuery(
    {
      forumId,
    },
    {
      enabled: inView,
      onSuccess: ({ data }: { data: PageResponseAttendeeModel }) => {
        setAttendees(data.items);
      },
    },
  );

  const getAttendeesCompanies = useCallback(
    (attendeeEntries: AttendeeModel[], currentAttendeeId: number | undefined) => {
      const forumCompanies = [
        ...new Set(
          attendeeEntries
            .filter((a: AttendeeModel) => a.attendId !== currentAttendeeId)
            .map((a: AttendeeModel) => a.forumCompany),
        ),
      ];

      return forumCompanies.map(
        (company) =>
          ({
            name: company,
            attendees: attendees
              ?.filter((a: AttendeeModel) => a.forumCompany === company && a.attendId !== currentAttendeeId)
              .map((a: AttendeeModel) => ({
                id: a.attendId,
                name: `${a.firstName} ${a.lastName}`,
              }))
              .sort((a: { id: number; name: string }, b: { id: number; name: string }) => a.name.localeCompare(b.name)),
          } as CompanyEntry),
      );
    },
    [attendees],
  );

  const getAttendeesOptions = (
    companyEntries: {
      name: string | undefined;
      attendees:
        | {
            id: number;
            name: string;
          }[]
        | undefined;
    }[],
  ) => {
    return companyEntries.map(
      (c) =>
        ({
          label: c.name,
          options: c.attendees?.map((a) => ({
            label: a.name,
            value: a.id,
          })),
        } as AttendeeOption),
    );
  };

  const getPartnerName = (id: number | null) => {
    const partnerEntry = attendees?.find((attendee) => attendee.attendId === id);
    return partnerEntry ? `${partnerEntry.firstName} ${partnerEntry.lastName}` : null;
  };

  useEffect(() => {
    if (attendees && partner) {
      const companies = getAttendeesCompanies(attendees, partner.id);
      setAttendeesOptions(getAttendeesOptions(companies));
    }
  }, [attendees, partner, getAttendeesCompanies]);

  const mutation = useUpdateAttendeePartnerDetailsMutation();

  if (!partner || !attendees || isLoadingPartner || isLoadingAttendees) {
    return (
      <div className="loader" ref={ref}>
        <Spin />
      </div>
    );
  }

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const submit = async (values: AttendeePartnerModel) => {
    mutation.mutate(
      {
        forumId,
        attendeeId,
        attendeePartnerModel: values,
      },
      {
        onSuccess: () => {
          setPartner({ ...partner, ...values, partner: getPartnerName(values.partnerForumAttendeeId) });
          setIsEditing(false);
        },
      },
    );
  };

  return (
    <div ref={ref}>
      <Form
        form={form}
        onFinish={submit}
        id="contactInfoForm"
        name="contact-info"
        labelAlign="left"
        labelCol={{ span: 13 }}
        wrapperCol={{ span: 11 }}
        labelWrap
        colon={false}
      >
        <Row gutter={80}>
          <Col md={24} lg={12}>
            <Form.Item
              initialValue={{
                label: partner.partner,
                value: partner.partnerForumAttendeeId,
              }}
              label={t("Spouse/Partner")}
              name="partnerForumAttendeeId"
              className={styles.formItem}
            >
              {isEditing ? (
                <Select
                  showSearch
                  options={attendeesOptions}
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  filterOption={(input, option: any) =>
                    /* eslint-disable-next-line no-unsafe-optional-chaining */
                    (option?.children ?? option?.label)?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              ) : (
                <label>{partner.partner ?? "-"}</label>
              )}
            </Form.Item>
          </Col>
        </Row>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={mutation.isLoading} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}> {t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
}
