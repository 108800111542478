import { Col, Divider, Input, Row } from "antd";
import Label from "components/styleguide/components/Menu/components/Label";
import { t } from "i18next";
import React, { ReactElement } from "react";

import { ItinerarySettingValue } from "../../types";

interface FormItemBoxProps {
  label: string;
  data: ItinerarySettingValue;
  dataIndex: string;
  render?: (data: ItinerarySettingValue) => ReactElement;
  isLastPair: boolean;
  onSubmit: (value: ItinerarySettingValue, dataIndex: string) => void;
}

const FormItemBox = ({ label, data, dataIndex, render, isLastPair, onSubmit }: FormItemBoxProps) => (
  <Col key={dataIndex} md={24} lg={12}>
    <Row>
      <Col md={24} lg={12}>
        <Label>{t(label) as string}</Label>
      </Col>
      <Col md={24} lg={12}>
        {render ? (
          render(data)
        ) : (
          <Input
            key={data}
            defaultValue={data as string}
            maxLength={50}
            onBlur={({ target: { value } }) => onSubmit(value || null, dataIndex)}
            onPressEnter={(event: React.KeyboardEvent<HTMLInputElement>) => {
              const target = event.target as HTMLInputElement;
              onSubmit(target?.value || null, dataIndex);
            }}
          />
        )}
      </Col>
    </Row>
    {!isLastPair && <Divider />}
  </Col>
);

export default FormItemBox;
