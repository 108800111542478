import { TabsProps } from "antd";
import { Layout, Space, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useTranslation } from "react-i18next";

import DelegateFeedback from "./DelegateFeedback";
import SupplierFeedback from "./SupplierFeedback";

import styles from "./styles.module.less";

export default function Feedback() {
  const { t } = useTranslation();

  const tabs: TabsProps["items"] = [
    {
      key: "delegateFeedback",
      label: t("Delegate Feedback"),
      children: <DelegateFeedback />,
    },
    {
      key: "supplierFeedback",
      label: t("Supplier Feedback"),
      children: <SupplierFeedback />,
    },
  ];

  return (
    <div className={styles.container}>
      <UserGuide.Title title="Feedback" articleId="16441786009757-feedback" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Layout className={styles.section}>
          <Tabs items={tabs} />
        </Layout>
      </Space>
    </div>
  );
}
