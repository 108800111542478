import { Select } from "components/styleguide";
import AttendeeGrade from "enums/AttendeeGrade";
import AttendeeStatus from "enums/AttendeeStatus";
import Gender from "enums/Gender";
import { AttendeeReContactResponse, ParticipationCodeModel } from "generated/api";
import { toNumber } from "lodash-es";
import { TFunction } from "react-i18next";

const { Option } = Select;

const batches = Array.from(Array(301).keys());

const getFormFields = (
  t: TFunction,
  reContacts: AttendeeReContactResponse[],
  participationCodes: ParticipationCodeModel[],
  reContactFirstName: string | null | undefined,
  reContactLastName: string | null | undefined,
  batch: string | null,
) => [
  {
    title: "Attend Status",
    dataIndex: "attendStatus",
    render: (attendStatus: string | number | null) =>
      t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendStatus as number)}`),
    readonly: true,
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    maxLength: 50,
  },
  {
    title: "Salutation",
    dataIndex: "salutation",
    maxLength: 50,
  },
  {
    title: "RE Contact",
    dataIndex: "reContactId",
    component: (
      <Select>
        {reContacts?.map((c) => (
          <Option key={c.id} value={c.id}>
            {`${c.firstName} ${c.lastName}`}
          </Option>
        ))}
      </Select>
    ),
    label: reContactFirstName && reContactLastName ? `${reContactFirstName} ${reContactLastName}` : "-",
  },
  {
    title: "Title",
    dataIndex: "title",
    maxLength: 12,
  },
  {
    title: "Grade",
    dataIndex: "grade",
    component: (
      <Select>
        {Object.keys(AttendeeGrade)
          .filter((k) => k !== "toDisplayName")
          .map((k) => (
            <Option
              value={AttendeeGrade[k as keyof typeof AttendeeGrade]}
              key={toNumber(AttendeeGrade[k as keyof typeof AttendeeGrade])}
            >
              {AttendeeGrade.toDisplayName(toNumber(AttendeeGrade[k as keyof typeof AttendeeGrade]))}
            </Option>
          ))}
      </Select>
    ),
  },
  {
    title: "First Name",
    dataIndex: "firstName",
    maxLength: 50,
  },
  {
    title: "Type",
    dataIndex: "type",
    component: (
      <Select>
        {participationCodes?.map((c) => (
          <Option key={c.code} value={c.code}>
            {c.code}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Last Name",
    dataIndex: "lastName",
    maxLength: 50,
  },
  {
    title: "Batch",
    dataIndex: "batch",
    component: (
      <Select showSearch={true}>
        {batches.map((b) => (
          <Option key={b} value={b < 10 ? `0${b}` : `${b}`}>
            {b < 10 ? `0${b}` : `${b}`}
          </Option>
        ))}
      </Select>
    ),
    label: batch ? (toNumber(batch) < 10 ? `0${batch}` : `${batch}`) : "-",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    maxLength: 30,
  },
  {
    title: "Gender",
    dataIndex: "gender",
    render: (genderKey: string | number | null) => t(Gender[genderKey as keyof typeof Gender]),
    component: (
      <Select>
        {Object.keys(Gender).map((key) => (
          <Option key={key}>{t(Gender[key as keyof typeof Gender])}</Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Mobile Phone",
    dataIndex: "mobilePhone",
    maxLength: 50,
  },
  {
    title: "Secretary First Name",
    dataIndex: "secretaryFirstName",
    maxLength: 50,
  },
  {
    title: "Secretary Email",
    dataIndex: "secretaryEmail",
    maxLength: 100,
  },
  {
    title: "Secretary Last Name",
    dataIndex: "secretaryLastName",
    maxLength: 50,
  },
];

export { getFormFields };
