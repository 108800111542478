import { Anchor } from "components/styleguide";
import React from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

const { Link: AnchorLink } = Anchor;

export interface AnchorGeneratorProps {
  id: string;
  key?: string;
  label: string;
  link: string;
  anchorLink?: string;
  children?: AnchorGeneratorProps[];
  shouldBeAnchor: boolean;
}

export interface AnchorGeneratorReturnProps {
  key: string;
  label: JSX.Element;
  id: string;
  link: string;
  children?: AnchorGeneratorReturnProps[] | null;
}

export const generateAnchorLink = ({
  id,
  key,
  label,
  anchorLink,
  link,
  children,
  shouldBeAnchor = true,
}: AnchorGeneratorProps): AnchorGeneratorReturnProps => ({
  key: key ?? id,
  label: shouldBeAnchor ? (
    <AnchorLink href={anchorLink ?? id} title={label} />
  ) : (
    <Link to={link + (anchorLink ?? id)}>{label}</Link>
  ),
  id: id,
  link: anchorLink ?? id,
  children: children
    ? children?.map((i) => {
        return generateAnchorLink({
          id: i.id,
          label: i.label,
          anchorLink: i.anchorLink,
          link: i.link,
          shouldBeAnchor: i.shouldBeAnchor,
        });
      })
    : null,
});

export const isUrlValid = (url: string) => {
  try {
    yup.string().url().validateSync(url);
    return true;
  } catch (_) {
    return false;
  }
};

/**
 *
 * @param {string} url
 * @return {React.ReactNode} \<a\> element with `url` as text that redirects to `url`
 * with protocol prepended if missing
 */
export const urlToAnchor = (url: string): React.ReactNode => {
  if (!isUrlValid(url)) {
    if (!isUrlValid(`https://${url}`)) {
      return <span>{url}</span>;
    }
    return <a href={`https://${url}`}>{url}</a>;
  }
  return <a href={url}>{url}</a>;
};

// Transforms "#some-link" to "someLink"
export const anchorToId = (anchorLink: string) => {
  if (anchorLink.indexOf("-") > 0) {
    return anchorLink;
  }
  return anchorLink
    .substring(1)
    .split("-")
    .map((element, index) => {
      return index === 0 ? element : element.charAt(0).toUpperCase() + element.slice(1);
    })
    .join("");
};

export const scrollToAnchor = (anchorLink: string) => {
  const element = document.getElementById(anchorLink.substring(1));
  element?.scrollIntoView({ behavior: "smooth" });
};
