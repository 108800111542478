/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseParticipationCodeModel } from '../apiModels';
// @ts-ignore
import { ParticipationCodeModel } from '../apiModels';
// @ts-ignore
import { ParticipationCodeType } from '../apiModels';
/**
 * ParticipationCodeApi - axios parameter creator
 * @export
 */
export const ParticipationCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets list of participation codes
         * @param {string} [code] If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
         * @param {string} [description] If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
         * @param {ParticipationCodeType} [type] 
         * @param {boolean} [ignorePagination] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participationcodesGet: async (code?: string, description?: string, type?: ParticipationCodeType, ignorePagination?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/participationcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (ignorePagination !== undefined) {
                localVarQueryParameter['IgnorePagination'] = ignorePagination;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a participation code
         * @param {ParticipationCodeModel} [participationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participationcodesPost: async (participationCodeModel?: ParticipationCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/participationcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a participation code
         * @param {ParticipationCodeModel} [participationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participationcodesPut: async (participationCodeModel?: ParticipationCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/participationcodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(participationCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParticipationCodeApi - functional programming interface
 * @export
 */
export const ParticipationCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParticipationCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets list of participation codes
         * @param {string} [code] If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
         * @param {string} [description] If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
         * @param {ParticipationCodeType} [type] 
         * @param {boolean} [ignorePagination] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participationcodesGet(code?: string, description?: string, type?: ParticipationCodeType, ignorePagination?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseParticipationCodeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participationcodesGet(code, description, type, ignorePagination, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a participation code
         * @param {ParticipationCodeModel} [participationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participationcodesPost(participationCodeModel?: ParticipationCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParticipationCodeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participationcodesPost(participationCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a participation code
         * @param {ParticipationCodeModel} [participationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async participationcodesPut(participationCodeModel?: ParticipationCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.participationcodesPut(participationCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParticipationCodeApi - factory interface
 * @export
 */
export const ParticipationCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParticipationCodeApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets list of participation codes
         * @param {ParticipationCodeApiParticipationcodesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participationcodesGet(requestParameters: ParticipationCodeApiParticipationcodesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResponseParticipationCodeModel> {
            return localVarFp.participationcodesGet(requestParameters.code, requestParameters.description, requestParameters.type, requestParameters.ignorePagination, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a participation code
         * @param {ParticipationCodeApiParticipationcodesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participationcodesPost(requestParameters: ParticipationCodeApiParticipationcodesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ParticipationCodeModel> {
            return localVarFp.participationcodesPost(requestParameters.participationCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a participation code
         * @param {ParticipationCodeApiParticipationcodesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        participationcodesPut(requestParameters: ParticipationCodeApiParticipationcodesPutRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.participationcodesPut(requestParameters.participationCodeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for participationcodesGet operation in ParticipationCodeApi.
 * @export
 * @interface ParticipationCodeApiParticipationcodesGetRequest
 */
export interface ParticipationCodeApiParticipationcodesGetRequest {
    /**
     * If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
     * @type {string}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly code?: string

    /**
     * If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
     * @type {string}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly description?: string

    /**
     * 
     * @type {ParticipationCodeType}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly type?: ParticipationCodeType

    /**
     * 
     * @type {boolean}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly ignorePagination?: boolean

    /**
     * 
     * @type {string}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ParticipationCodeApiParticipationcodesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for participationcodesPost operation in ParticipationCodeApi.
 * @export
 * @interface ParticipationCodeApiParticipationcodesPostRequest
 */
export interface ParticipationCodeApiParticipationcodesPostRequest {
    /**
     * 
     * @type {ParticipationCodeModel}
     * @memberof ParticipationCodeApiParticipationcodesPost
     */
    readonly participationCodeModel?: ParticipationCodeModel
}

/**
 * Request parameters for participationcodesPut operation in ParticipationCodeApi.
 * @export
 * @interface ParticipationCodeApiParticipationcodesPutRequest
 */
export interface ParticipationCodeApiParticipationcodesPutRequest {
    /**
     * 
     * @type {ParticipationCodeModel}
     * @memberof ParticipationCodeApiParticipationcodesPut
     */
    readonly participationCodeModel?: ParticipationCodeModel
}

/**
 * ParticipationCodeApi - object-oriented interface
 * @export
 * @class ParticipationCodeApi
 * @extends {BaseAPI}
 */
export class ParticipationCodeApi extends BaseAPI {
    /**
     * 
     * @summary Gets list of participation codes
     * @param {ParticipationCodeApiParticipationcodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipationCodeApi
     */
    public participationcodesGet(requestParameters: ParticipationCodeApiParticipationcodesGetRequest = {}, options?: AxiosRequestConfig) {
        return ParticipationCodeApiFp(this.configuration).participationcodesGet(requestParameters.code, requestParameters.description, requestParameters.type, requestParameters.ignorePagination, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a participation code
     * @param {ParticipationCodeApiParticipationcodesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipationCodeApi
     */
    public participationcodesPost(requestParameters: ParticipationCodeApiParticipationcodesPostRequest = {}, options?: AxiosRequestConfig) {
        return ParticipationCodeApiFp(this.configuration).participationcodesPost(requestParameters.participationCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a participation code
     * @param {ParticipationCodeApiParticipationcodesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParticipationCodeApi
     */
    public participationcodesPut(requestParameters: ParticipationCodeApiParticipationcodesPutRequest = {}, options?: AxiosRequestConfig) {
        return ParticipationCodeApiFp(this.configuration).participationcodesPut(requestParameters.participationCodeModel, options).then((request) => request(this.axios, this.basePath));
    }
}

