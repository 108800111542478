import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

const useCreateForumRoomGradeMutation = getWrappedUseMutation(apiClient.ForumRoomGrade.post);

const useUpdateForumRoomGradeMutation = getWrappedUseMutation(apiClient.ForumRoomGrade.put);

const useDeleteForumRoomGradeMutation = getWrappedUseMutation(apiClient.ForumRoomGrade.delete);

export { useCreateForumRoomGradeMutation, useDeleteForumRoomGradeMutation, useUpdateForumRoomGradeMutation };
