import { TableProps } from "antd";
import { Checkbox, Input, Select, Table } from "components/styleguide";
import {
  PositiveSelectionV2ItemProfileStyle,
  QuestionnaireContentQuestion,
  QuestionnaireProfileDetailsOptionProfileDetails,
  QuestionnaireProfileDetailsQuestionProfileDetails,
} from "generated/api";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceAt } from "utils/arrayUtils";

import OptionsButton from "../OptionsButton";

import styles from "./styles.module.less";

const ProfileStyle: Record<keyof typeof PositiveSelectionV2ItemProfileStyle, string> = {
  None: "None",
  FreeText: "Free text",
  ClassifiedIndex: "Classified index",
  BlobField: "Blob Field",
};

export interface ListingTableProps {
  questions: Array<QuestionnaireContentQuestion>;
  value?: Array<QuestionnaireProfileDetailsQuestionProfileDetails>;
  onChange?: (value: Array<QuestionnaireProfileDetailsQuestionProfileDetails> | null) => void;
}

const ListingTable: FC<ListingTableProps> = ({ value, questions, onChange }) => {
  const { t } = useTranslation();

  const questionsMap = useMemo(() => {
    return questions.reduce((map, question) => {
      map.set(question.id, question);
      return map;
    }, new Map<QuestionnaireContentQuestion["id"], QuestionnaireContentQuestion>());
  }, [questions]);

  const updateByIndex = (index: number, patch: Partial<QuestionnaireProfileDetailsQuestionProfileDetails>) => {
    if (!value) {
      return;
    }

    const newValue = {
      ...value[index],
      ...patch,
    };

    const newArray = replaceAt(value, index, newValue);
    onChange?.(newArray);
  };

  const columns: TableProps<QuestionnaireProfileDetailsQuestionProfileDetails>["columns"] = [
    // gear icon
    {
      dataIndex: "options",
      width: 50,
      render: (options: Array<QuestionnaireProfileDetailsOptionProfileDetails>, record, index) => {
        const question = record.id ? questionsMap.get(record.id) : null;

        return (
          <OptionsButton
            title={question?.text ?? ""}
            options={question?.options ?? []}
            value={record.options ?? []}
            onChange={(newOptions) => updateByIndex(index, { options: newOptions })}
          />
        );
      },
    },
    {
      dataIndex: "id",
      title: t("Question title"),
      width: "30%",
      render: (id) => questionsMap.get(id)?.text,
    },
    {
      dataIndex: "profileTitle",
      title: t("Profile title"),
      width: "70%",
      render: (profileTitle, record, index) => {
        return (
          <Input value={profileTitle} lazy onChange={(e) => updateByIndex(index, { profileTitle: e.target.value })} />
        );
      },
    },
    {
      dataIndex: "profileStyle",
      title: t("Profile style"),
      width: 200,
      render: (profileStyle, record, index) => {
        return (
          <Select
            className={styles.fullWidth}
            value={profileStyle}
            onChange={(v) => updateByIndex(index, { profileStyle: v })}
          >
            {Object.entries(ProfileStyle).map(([key, label]) => (
              <Select.Option key={key} value={key}>
                {t(label)}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      dataIndex: "isActive",
      title: t("Active"),
      width: 100,
      // render a checkbox from ant.d 4 and update the value by index
      render: (isActive, record, index) => {
        return <Checkbox checked={isActive} onChange={(e) => updateByIndex(index, { isActive: e.target.checked })} />;
      },
    },
  ];

  return <Table columns={columns} dataSource={value} rowKey="id" pagination={false} />;
};

export default ListingTable;
