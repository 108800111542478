/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Advanced section
 * @export
 * @enum {string}
 */

export const DocumentDefinitionTypes = {
    AllProfiles: 'AllProfiles',
    AllSisterEventAttendeesExpanded: 'AllSisterEventAttendeesExpanded',
    AttendeeList: 'AttendeeList',
    AttendeeSpeakerItineraryWithCover: 'AttendeeSpeakerItineraryWithCover',
    AttendeeSpeakerItineraryWithCoverSupplier: 'AttendeeSpeakerItineraryWithCoverSupplier',
    CpdCertificate: 'CPDCertificate',
    EnhancedCpd: 'EnhancedCPD',
    ItineraryCover2: 'ItineraryCover2',
    ItineraryWithCover: 'ItineraryWithCover',
    NewBooking: 'NewBooking',
    PiggyProvItinerary: 'PiggyProvItinerary',
    ProfileList: 'ProfileList',
    ProfileListNoProfiles: 'ProfileListNoProfiles',
    ProvItinerary: 'ProvItinerary',
    QuestDefinition: 'QuestDefinition',
    Questionnaire: 'Questionnaire',
    RemovedBooking: 'RemovedBooking',
    SelectionSite: 'SelectionSite',
    SpeakerPackWithCover: 'SpeakerPackWithCover',
    TestConferenceProfile: 'TestConferenceProfile',
    TestProfile: 'TestProfile',
    TestReason: 'TestReason',
    TransportVoucher: 'TransportVoucher',
    AllSisterEventsList: 'AllSisterEventsList'
} as const;

export type DocumentDefinitionTypes = typeof DocumentDefinitionTypes[keyof typeof DocumentDefinitionTypes];



