import { useQueryClient } from "@tanstack/react-query";
import { useUpdateTeamMember } from "api/mutations/itineraryBookletsForumTeamMembers";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import {
  ItineraryBookletsForumTeamMemberRequestModel,
  ItineraryBookletsForumTeamMemberResponseModel,
} from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import TeamMemeberForm from "../TeamMemeberForm";

interface EditTeamMemberProps {
  forumId: number;
  teamMember: ItineraryBookletsForumTeamMemberResponseModel;
  isVisible: boolean;
  onClose: () => void;
}

const EditTeamMember: FC<EditTeamMemberProps> = ({ forumId, teamMember, isVisible, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { isLoading: isUpdateTeamMemberLoading, mutate: updateTeamMember } = useUpdateTeamMember();

  const submit = (values: ItineraryBookletsForumTeamMemberRequestModel) => {
    updateTeamMember(
      {
        forumId,
        memberId: teamMember.id,
        itineraryBookletsForumTeamMemberRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_TEAM_MEMBERS]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer
      title={t("Edit Team Member")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <TeamMemeberForm
        {...{ forumId, teamMember, onClose }}
        isSubmitting={isUpdateTeamMemberLoading}
        onSubmit={submit}
      />
    </Drawer>
  );
};

export default EditTeamMember;
