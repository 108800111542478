import "moment/locale/en-gb";
import "moment/locale/it";
import "moment/locale/de";
import "moment/locale/fr";

import { ConfigProvider } from "antd";
import de from "antd/es/locale/de_DE";
import enGB from "antd/es/locale/en_GB";
import enUS from "antd/es/locale/en_US";
import fr from "antd/es/locale/fr_FR";
import it from "antd/es/locale/it_IT";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { FALLBACK_LANGUAGE, languages } from "../../i18n";

const antLanguages = {
  [languages.de.key]: de,
  [languages.fr.key]: fr,
  [languages["en-GB"].key]: enGB,
  [languages["en-US"].key]: enUS,
  [languages.it.key]: it,
};

// set manually set translated formats into ant components
// use LL formats for moment

const LocaleHandler = () => {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const newLanguage = Object.keys(languages).includes(lang) ? lang : FALLBACK_LANGUAGE;

    moment.locale(newLanguage);

    i18n.changeLanguage(newLanguage).then(() => {
      let newLocation = location.pathname.split("/");
      newLocation[1] = newLanguage;
      navigate(newLocation.join("/"));
    });
  }, [lang]);

  return (
    <ConfigProvider locale={antLanguages[Object.keys(languages).includes(lang) ? lang : FALLBACK_LANGUAGE]}>
      <Outlet />
    </ConfigProvider>
  );
};

export default LocaleHandler;
