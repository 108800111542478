import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useUpdateSelectionSiteItemMutation } from "../../../api/mutations/selectionSites";
import { useSelectionSiteContentQuery, useSelectionSiteQuery } from "../../../api/queries/selectionSites";
import { Layout, Spin } from "../../../components/styleguide";
import useSet from "../../../hooks/useSet";
import ContentRenderer from "./components/Content";
import SelectionSiteSider from "./components/Sider";
import ItemErrorsContext from "./context/item-errors";
import SelectionSiteStatusContext from "./context/selection-site-status";

import styles from "./styles.module.less";

const SIDER_WIDTH = 300;

const EditPage = () => {
  const { id: forumId } = useOutletContext();
  const params = useParams();
  const selectionSiteId = parseInt(params.selectionSiteId);
  const queryClient = useQueryClient();

  const itemErrorsSet = useSet();

  const { t } = useTranslation();

  const invalidateQuery = () => {
    queryClient.invalidateQueries(useSelectionSiteContentQuery.getQueryKeys({ forumId, selectionSiteId }));
  };

  const query = useSelectionSiteQuery({ forumId, selectionSiteId });
  const contentQuery = useSelectionSiteContentQuery({ forumId, selectionSiteId });

  const itemMutation = useUpdateSelectionSiteItemMutation({
    onSettled: () => {
      invalidateQuery();
    },
    onError: (error) => {
      const errorMessage = error?.response?.data?.meta?.Message || error.message;
      const genericError = t("An error occurred while updating the item.");
      const message = `${genericError} ${errorMessage}`;
      toast.error(message);
    },
  });

  const pages = contentQuery.data?.data?.pages ?? [];

  const handleItemChange = (pageId, itemId, item) => {
    return itemMutation.mutateAsync({
      forumId,
      selectionSiteId,
      pageId,
      itemId,
      item,
    });
  };

  if (contentQuery.isLoading || query.isLoading) {
    return (
      <Layout className={styles.loadingContainer}>
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <SelectionSiteStatusContext.Provider value={query.data.data.status}>
      <ItemErrorsContext.Provider
        value={{
          errors: itemErrorsSet.value,
          add: itemErrorsSet.add,
          remove: itemErrorsSet.remove,
          has: itemErrorsSet.has,
        }}
      >
        <Layout hasSider>
          <SelectionSiteSider
            className={styles.sider}
            forumId={forumId}
            selectionSiteId={selectionSiteId}
            pages={pages}
            width={SIDER_WIDTH}
            onPageAdd={invalidateQuery}
            onPageDelete={invalidateQuery}
            onItemAdd={invalidateQuery}
            onItemDelete={invalidateQuery}
          />
          <Layout.Content className={styles.content} style={{ "--sider-width": `${SIDER_WIDTH}px` }}>
            <ContentRenderer pages={pages} onChange={handleItemChange} />
          </Layout.Content>
        </Layout>
      </ItemErrorsContext.Provider>
    </SelectionSiteStatusContext.Provider>
  );
};

export default EditPage;
