import { useMutation } from "@tanstack/react-query";
import api from "api";
import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import * as apiClient from "api-client";

export const useCreateSeminarTypeMutation = (options = {}) => {
  return useMutation(({ forumId, seminarType }) => {
    return api.post(`/forums/${forumId}/seminar-types`, seminarType);
  }, options);
};

export const useUpdateSeminarTypeMutation = (options = {}) =>
  useMutation(
    ({ forumId, seminarTypeId, seminarType }) =>
      api.put(`/forums/${forumId}/seminar-types/${seminarTypeId}`, seminarType),
    options,
  );

export const useDeleteForumSeminarTypeMutation = getWrappedUseMutation(apiClient.ForumSeminarType.delete);
