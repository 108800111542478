import { useMutation } from "@tanstack/react-query";

import api from "../../../api";
import apiClient from "../../../api-client";
import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useCreateSeminarMutation = getWrappedUseMutation(apiClient.ForumSeminars.post);

export const useReorderSeminarsMutation = getWrappedUseMutation(apiClient.ForumSeminars.reoder.get);

export const useDeleteSeminarMutation = (options = {}) => {
  return useMutation(({ forumId, forumSeminarId }) => {
    return api.delete(`/forums/${forumId}/seminars/${forumSeminarId}`);
  }, options);
};

export const useAssignSpeakerMutation = (options = {}) => {
  return useMutation(({ forumId, forumSeminarId, speaker }) => {
    return api.post(`/forums/${forumId}/seminars/${forumSeminarId}/assigned-speakers`, speaker);
  }, options);
};

export const useRemoveSpeakerMutation = (options = {}) => {
  return useMutation(({ forumId, forumSeminarId, speakerId }) => {
    return api.delete(`/forums/${forumId}/seminars/${forumSeminarId}/assigned-speakers/${speakerId}`);
  }, options);
};

export const useUpdateSeminarMutation = getWrappedUseMutation(apiClient.ForumSeminars.put);
