import { ArrowDownOutlined, ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Button, Col, Layout, Menu, Row } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { scrollToAnchor } from "utils/anchorUtils";
import nextTick from "utils/nextTickUtils";
import {
  appendPage,
  appendQuestion,
  generateNavId,
  generatePageId,
  generateQuestionId,
  getIdFromNavId,
  getNextPageIndex,
  getNextQuestionIndex,
  getPageIndexFromId,
  getPrevPageIndex,
  getPrevQuestionIndex,
  getQuestionIndexFromId,
  isPageId,
  isQuestionId,
  movePage,
  moveQuestion,
  removePage as removePageAt,
  removeQuestion as removeQuestionAt,
} from "utils/questionnaireContentUtils";

import PageLabel from "./components/PageLabel";
import QuestionLabel from "./components/QuestionLabel";

import styles from "./styles.module.less";

const QuestionnaireEditSider = ({ className, isActionsDisabled, pages, width, onChange }) => {
  const [selectedMenuKey, setSelectedMenuKey] = useState(() => generateNavId("details"));
  const openKeys = Menu.useOpenKeys([]);
  const { t } = useTranslation();

  const classes = clsx(styles.subMenu, className);

  const setSelectedKey = (key) => {
    if (key) {
      setSelectedMenuKey(key);
    }
  };

  const addPage = async () => {
    if (isActionsDisabled) {
      return;
    }

    const newPages = appendPage(pages);
    onChange(newPages);

    await nextTick();
    const id = generatePageId(newPages.length - 1);
    scrollToAnchor(`#${id}`);
  };

  const setSelectedKeyToNavAndScroll = async (id) => {
    const navId = generateNavId(id);
    setSelectedKey(navId);

    await nextTick();
    scrollToAnchor(`#${id}`);
  };

  const moveEntityUp = () => {
    if (isActionsDisabled) {
      return;
    }

    const id = getIdFromNavId(selectedMenuKey);

    if (isPageId(id)) {
      const pageIndex = getPageIndexFromId(id);
      const newIndex = getPrevPageIndex(pages, pageIndex);

      const oldNavId = generateNavId(id);
      const newNavId = generateNavId(generatePageId(newIndex));
      openKeys.replace(oldNavId, newNavId);

      onChange(movePage(pages, pageIndex, newIndex));
      setSelectedKeyToNavAndScroll(generatePageId(newIndex));
      return;
    }

    if (isQuestionId(id)) {
      const [pageIndex, questionIndex] = getQuestionIndexFromId(id);
      const [newPageIndex, newQuestionIndex] = getPrevQuestionIndex(pages, pageIndex, questionIndex);

      onChange(moveQuestion(pages, [pageIndex, questionIndex], [newPageIndex, newQuestionIndex]));
      setSelectedKeyToNavAndScroll(generateQuestionId(newPageIndex, newQuestionIndex));
    }
  };

  const moveEntityDown = () => {
    if (isActionsDisabled) {
      return;
    }

    const id = getIdFromNavId(selectedMenuKey);

    if (isPageId(id)) {
      const pageIndex = getPageIndexFromId(id);
      const newIndex = getNextPageIndex(pages, pageIndex);

      const oldNavId = generateNavId(id);
      const newNavId = generateNavId(generatePageId(newIndex));
      openKeys.replace(oldNavId, newNavId);

      onChange(movePage(pages, pageIndex, newIndex));
      setSelectedKeyToNavAndScroll(generatePageId(newIndex));
      return;
    }

    if (isQuestionId(id)) {
      const [pageIndex, questionIndex] = getQuestionIndexFromId(id);
      const [newPageIndex, newQuestionIndex] = getNextQuestionIndex(pages, pageIndex, questionIndex);

      onChange(moveQuestion(pages, [pageIndex, questionIndex], [newPageIndex, newQuestionIndex]));
      setSelectedKeyToNavAndScroll(generateQuestionId(newPageIndex, newQuestionIndex));
    }
  };

  const removePage = (pageIndex) => {
    if (isActionsDisabled) {
      return;
    }

    const navId = generateNavId(generatePageId(pageIndex));
    setSelectedMenuKey((prevKey) => (prevKey === navId ? "" : prevKey));

    openKeys.remove(navId);
    onChange(removePageAt(pages, pageIndex));
  };

  const removeQuestion = (pageIndex, questionIndex) => {
    if (isActionsDisabled) {
      return;
    }

    const navId = generateNavId(generateQuestionId(pageIndex, questionIndex));
    setSelectedMenuKey((prevKey) => (prevKey === navId ? "" : prevKey));

    onChange(removeQuestionAt(pages, pageIndex, questionIndex));
  };

  const addQuestionToPage = (pageIndex) => {
    if (!isActionsDisabled) {
      onChange(appendQuestion(pages, pageIndex, t("Please, enter the answer")));
    }
  };

  const menuItems = useMemo(() => {
    const navDetailsId = generateNavId("details");

    const detailsSection = {
      id: navDetailsId,
      key: navDetailsId,
      anchorLink: "#details",
      label: <Menu.ItemLabel>Details</Menu.ItemLabel>,
    };

    const sections = pages.map((page, pageIndex) => {
      const id = generatePageId(pageIndex);
      const navId = generateNavId(id);

      const hasQuestions = page.questions && page.questions.length !== 0;

      const questions = hasQuestions ? page.questions : undefined;

      const common = {
        id: navId,
        key: navId,
        anchorLink: `#${id}`,
        label: (
          <PageLabel
            title={`${t("page")} ${pageIndex + 1}`}
            isAddDisabled={isActionsDisabled}
            isDeleteDisabled={isActionsDisabled}
            hasQuestions={hasQuestions}
            isSelected={navId === selectedMenuKey}
            onClickAdd={() => addQuestionToPage(pageIndex)}
            onRemoveConfirm={() => removePage(pageIndex)}
          />
        ),
      };

      if (!hasQuestions) {
        return common;
      }

      return {
        ...common,
        // `onChange` or `onClick` from ant is called only on selecting specific element
        // to track when page with questions (dropdown title) was clicked we have to use that hack
        onTitleClick: ({ key }) => {
          setSelectedKeyToNavAndScroll(getIdFromNavId(key));
        },
        children: questions.map((question, questionIndex) => {
          console.log(question);
          const id = generateQuestionId(pageIndex, questionIndex);
          const navId = generateNavId(id);
          return {
            id: question.id,
            key: navId,
            anchorLink: `#${id}`,
            label: (
              <QuestionLabel
                title={question.text}
                isDeleteDisabled={isActionsDisabled}
                isSelected={navId === selectedMenuKey}
                onRemoveConfirm={() => removeQuestion(pageIndex, questionIndex)}
              />
            ),
          };
        }),
      };
    });

    return [detailsSection, ...sections];
  }, [pages, selectedMenuKey, isActionsDisabled]);

  const handleMenuItemSelect = ({ key, item }) => {
    setSelectedKey(key);

    const anchorLink = item.props.anchorLink;
    if (!anchorLink) {
      return;
    }

    scrollToAnchor(anchorLink);
  };

  return (
    <Layout.Sider data-testid="sider" className={classes} width={width}>
      <Row className={styles.actions} gutter={8}>
        <Col>
          <Button onClick={addPage} icon={<PlusOutlined />} disabled={isActionsDisabled}>
            {t("Add Page")}
          </Button>
        </Col>
        <Col flex={"1"} />
        <Col>
          <Button onClick={moveEntityUp} icon={<ArrowUpOutlined />} disabled={isActionsDisabled} />
        </Col>
        <Col>
          <Button onClick={moveEntityDown} icon={<ArrowDownOutlined />} disabled={isActionsDisabled} />
        </Col>
      </Row>
      <Menu
        items={menuItems}
        mode="inline"
        selectedKeys={[]}
        openKeys={openKeys.value}
        onSelect={handleMenuItemSelect}
        onOpenChange={openKeys.set}
      />
    </Layout.Sider>
  );
};

QuestionnaireEditSider.propTypes = {
  className: PropTypes.string,
  isActionsDisabled: PropTypes.bool,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        }),
      ),
    }),
  ),
  width: PropTypes.number,
  onChange: PropTypes.func,
};

QuestionnaireEditSider.defaultProps = {
  isActionsDisabled: false,
  pages: [],
  onChange: () => undefined,
};

export default QuestionnaireEditSider;
