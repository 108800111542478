import TeamMemberRoles from "backend-models/memberRoles";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Select, Spin } from "components/styleguide";
import { ForumTeamMemberResponseModel, UserResponseModel } from "generated/api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { EditTeamMemberSubmitProps } from "../types";

const { Option } = Select;

export default function EditTeamMemberDrawer({
  isVisible,
  isLoading,
  onClose,
  onFinish,
  teamMember,
  users,
  isUsersLoading,
}: {
  isVisible: boolean;
  isLoading: boolean;
  onClose: () => void;
  onFinish: (values: EditTeamMemberSubmitProps) => void;
  teamMember: ForumTeamMemberResponseModel | null;
  users: UserResponseModel[] | undefined;
  isUsersLoading: boolean;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [userId, setUserId] = useState<number | null | undefined>(teamMember?.userId);

  const handleSelect = (record: string) => {
    if (record) {
      const { jobTitle, name, id, mobilePhone, businessPhone } = JSON.parse(record);

      form.setFieldsValue({ jobTitle, name, mobilePhone, businessPhone });

      setUserId(id);
    }
  };

  return (
    <Drawer
      className="team-members-drawer"
      title={"Edit Team Member"}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      {isUsersLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <FullHeightForm
          preserve={false}
          id="teamMembersForm"
          name="team-members-form"
          labelCol={{ span: 4 }}
          form={form}
          actionsPrepend={
            <Button
              onClick={() => {
                setUserId(null);
                onClose();
              }}
            >
              {t("cancel")}
            </Button>
          }
          actionsAppend={
            <Button type="primary" htmlType="submit" loading={isLoading}>
              {t("Update")}
            </Button>
          }
          onFinish={(values) => onFinish({ ...values, userId, teamMemberId: teamMember?.id })}
        >
          <Form.Item
            label={t("name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("errors.required", { prop: "$t(name)" }),
              },
            ]}
            initialValue={teamMember?.name}
          >
            <Select showSearch optionFilterProp="children" onSelect={handleSelect}>
              {users?.map(({ name, jobTitle, id, mobilePhone, businessPhone }, index) => (
                <Option key={index} value={JSON.stringify({ name, jobTitle, id, mobilePhone, businessPhone })}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("jobTitle")}
            name="jobTitle"
            initialValue={teamMember?.jobTitle}
            rules={[
              {
                max: 200,
                message: t("'Job Title' field has a maximum of 200 characters"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("role")}
            name="role"
            rules={[
              {
                required: true,
                message: t("errors.required", { prop: "$t(role)" }),
              },
            ]}
            initialValue={teamMember?.role}
          >
            <Select>
              {TeamMemberRoles.LIST.map(({ value, label }) => (
                <Option key={value} value={label}>
                  {t(`jobRoles.${label}`)}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Mobile phone")}
            name="mobilePhone"
            initialValue={teamMember?.mobilePhone}
            rules={[
              {
                max: 32,
                message: t("The character limit is {{count}}", { count: 32 }),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("Phone Number")}
            name="businessPhone"
            initialValue={teamMember?.businessPhone}
            rules={[
              {
                max: 32,
                message: t("The character limit is {{count}}", { count: 32 }),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </FullHeightForm>
      )}
    </Drawer>
  );
}
