import "./styles.less";

import { Select, Spin } from "components/styleguide";
import { ForumResponseModel } from "generated/api";
import React from "react";

import styles from "./styles.module.less";

const { Option } = Select;

interface EventSelectProps {
  favoriteForums: ForumResponseModel[];
  selectedForum: ForumResponseModel | null;
  isCollapsed: boolean;
  onChange: (value: string) => void;
  loading: boolean;
}

const EventSelect = ({ favoriteForums, selectedForum, isCollapsed, onChange, loading }: EventSelectProps) => {
  const options =
    favoriteForums && selectedForum && !favoriteForums.find((f) => f.id === selectedForum.id)
      ? [...favoriteForums, selectedForum]
      : favoriteForums;

  const optionsMap = options.map(({ code, id }) => (
    <Option key={id} value={id} label={code}>
      <span className="event-name">{code}</span>
    </Option>
  ));

  if (loading) {
    return (
      <span className={styles.loader}>
        <Spin />
      </span>
    );
  }

  return (
    <Select
      value={selectedForum?.code}
      className={`event-select-select ${isCollapsed ? "cropped" : ""}`}
      style={{ width: "100%" }}
      bordered={false}
      dropdownMatchSelectWidth={false}
      id="menu-event-select"
      onChange={onChange}
    >
      {optionsMap}
    </Select>
  );
};

export default EventSelect;
