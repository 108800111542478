import { ForumVenueApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumVenueApi);

const ForumVenues = {
  get: api.forumsForumIdVenueGet,
  put: api.forumsForumIdVenuePut,
  availableTimeslots: { get: api.forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet },
  facilities: { get: api.forumsForumIdVenueFacilitiesGet },
  tables: { get: api.forumsForumIdVenueTablesGet },
  map: {
    put: api.forumsForumIdVenueMapPut,
    delete: api.forumsForumIdVenueMapDelete,
  },
};

export default ForumVenues;
