import { useQueryClient } from "@tanstack/react-query";
import KEYS from "api/queries/keys";
import { Button, Space, Table, Typography } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import FacilityType from "enums/FacilityType";
import VenueContext from "pages/venueContext";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import BedroomDrawer from "./components/BedroomDrawer";
import ExportBedroomsButton from "./ExportBedroomsButton";

import styles from "./styles.module.less";

const Bedrooms = ({ data, loading, onSort, onDelete, isLoading }) => {
  const { t } = useTranslation();
  const { id: forumId } = useParams();
  const queryClient = useQueryClient();

  const venueActions = useContext(VenueContext);

  const {
    setCodeSearch,
    setNameSearch,
    setDescriptionSearch,
    onFacilityCreateSubmit,
    onFacilityEditSubmit,
    floors,
    onFloorAdd,
    onFloorDelete,
    roomGrades,
    setRoomGradeSearch,
    attendees,
    setAttendeeSearch,
    setBillingNoteSearch,
  } = venueActions;

  const [bedroomForEdit, setBedroomForEdit] = useState(null);
  const [isBedroomDrawerVisible, setIsBedroomDrawerVisible] = useState(false);

  const getBedroomsWithAttendeesNames = (bedrooms) => {
    if (!bedrooms?.length) {
      return bedrooms;
    }

    return bedrooms.map((bedroom) => {
      if (!bedroom.attendees?.length) {
        return bedroom;
      }

      return {
        ...bedroom,
        Attendee: bedroom.attendees.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", "),
      };
    });
  };

  const handleNewBedroomCreate = (bedroomData) => {
    onFacilityCreateSubmit({
      ...bedroomData,
      facilityType: FacilityType.Bedroom,
    }).then(() => {
      setIsBedroomDrawerVisible(false);
      queryClient.resetQueries([KEYS.GET_FORUM_FACILITIES]);
      queryClient.resetQueries([KEYS.GET_FACILITIES]);
      toast.success(t("Bedroom Created Successfully"));
    });
  };

  const handleBedroomEdit = useCallback((bedroom) => {
    setBedroomForEdit(bedroom);
    showBedroomDrawer();
  }, []);

  const handleBedroomEditCancel = () => {
    setIsBedroomDrawerVisible(false);
    setBedroomForEdit(null);
  };

  const handleBedroomEditSubmit = (bedroomData) => {
    onFacilityEditSubmit(
      {
        ...bedroomData,
        facilityType: FacilityType.Bedroom,
      },
      bedroomForEdit.id,
    ).then(() => {
      setIsBedroomDrawerVisible(false);
      setBedroomForEdit(null);
      toast.success(t("Bedroom Updated Successfully"));
    });
  };

  const showBedroomDrawer = () => {
    setIsBedroomDrawerVisible(true);
  };

  const handleSearch = useCallback(
    (selectedKeys, confirm, searchSet) => {
      switch (searchSet) {
        case "name": {
          setNameSearch(selectedKeys[0]);
          break;
        }
        case "code": {
          setCodeSearch(selectedKeys[0]);
          break;
        }
        case "description": {
          setDescriptionSearch(selectedKeys[0]);
          break;
        }
        case "roomGrade": {
          setRoomGradeSearch(selectedKeys[0]);
          break;
        }
        case "Attendee": {
          setAttendeeSearch(selectedKeys[0]);
          break;
        }
        case "billingNote": {
          setBillingNoteSearch(selectedKeys[0]);
          break;
        }
      }

      confirm();
    },
    [setAttendeeSearch, setCodeSearch, setDescriptionSearch, setNameSearch, setRoomGradeSearch, setBillingNoteSearch],
  );

  const handleReset = useCallback(
    (clearFilters, dataIndex, searchSet, confirm) => {
      switch (searchSet) {
        case "name": {
          setNameSearch(undefined);
          break;
        }
        case "code": {
          setCodeSearch(undefined);
          break;
        }
        case "description": {
          setDescriptionSearch(undefined);
          break;
        }
        case "roomGrade": {
          setRoomGradeSearch(undefined);
          break;
        }
        case "Attendee": {
          setAttendeeSearch(undefined);
          break;
        }
        case "billingNote": {
          setBillingNoteSearch(undefined);
          break;
        }
      }

      clearFilters();
      confirm();
    },
    [setAttendeeSearch, setCodeSearch, setDescriptionSearch, setNameSearch, setRoomGradeSearch, setBillingNoteSearch],
  );

  const getColumns = useCallback(() => {
    const defaultColumns = [
      {
        title: t("Facility Code"),
        dataIndex: "code",
        width: "15%",
        sorter: true,
        ...getColumnSearchProps("code", handleSearch, handleReset, "code"),
      },
      {
        title: t("Name"),
        dataIndex: "name",
        sorter: true,
        render: (name) => <Typography.Text>{name}</Typography.Text>,
        ...getColumnSearchProps("name", handleSearch, handleReset, "name"),
      },
      {
        title: t("Room Grade"),
        dataIndex: "roomGrade",
        width: "15%",
        sorter: true,
        render: (grade) => <Typography.Text>{grade}</Typography.Text>,
        ...getColumnSearchProps("roomGrade", handleSearch, handleReset, "roomGrade"),
      },
      {
        title: t("Grade Description"),
        dataIndex: "description",
        sorter: true,
        render: (description) => <Typography.Text>{description}</Typography.Text>,
      },
      {
        title: t("Floor"),
        width: "15%",
        dataIndex: "floor",
        sorter: true,
      },
    ];

    const forumBedroomColumns = [
      ...defaultColumns,
      {
        title: t("Attendee"),
        width: "10%",
        dataIndex: "Attendee",
        sorter: true,
        render: (attendee) => <Typography.Text>{attendee}</Typography.Text>,
        ...getColumnSearchProps("Attendee", handleSearch, handleReset, "Attendee"),
      },
      {
        title: t("Billing Notes"),
        dataIndex: "billingNote",
        sorter: true,
        render: (billingNote) => <Typography.Text>{billingNote}</Typography.Text>,
        ...getColumnSearchProps("billingNote", handleSearch, handleReset, "billingNote"),
      },
      {
        width: "10%",
        render: (bedroom) => {
          return <TableRowActionButtons onEdit={() => handleBedroomEdit(bedroom)} onDelete={() => onDelete(bedroom)} />;
        },
      },
    ];

    const bedroomColumns = [
      ...defaultColumns,
      {
        width: "10%",
        render: (bedroom) => {
          return <TableRowActionButtons onEdit={() => handleBedroomEdit(bedroom)} onDelete={() => onDelete(bedroom)} />;
        },
      },
    ];

    return forumId ? forumBedroomColumns : bedroomColumns;
  }, [forumId, handleBedroomEdit, handleReset, t, handleSearch, onDelete]);

  return (
    <div>
      <Space direction="horizontal">
        <Button onClick={showBedroomDrawer} className={styles.button}>
          {t("Add New Bedroom")}
        </Button>
        <div className={styles.button}>
          <ExportBedroomsButton />
        </div>
      </Space>

      <Table
        columns={getColumns()}
        onChange={onSort}
        dataSource={getBedroomsWithAttendeesNames(data)}
        pagination={false}
        loading={loading}
        rowKey="id"
      />
      <BedroomDrawer
        onBedroomCreate={handleNewBedroomCreate}
        onBedroomEditSubmit={handleBedroomEditSubmit}
        bedroom={bedroomForEdit}
        isCreate={!bedroomForEdit}
        visible={isBedroomDrawerVisible}
        isLoading={isLoading}
        onCancel={bedroomForEdit ? handleBedroomEditCancel : () => setIsBedroomDrawerVisible(false)}
        onFloorAdd={onFloorAdd}
        onFloorDelete={onFloorDelete}
        floors={floors}
        attendees={attendees}
        roomGrades={roomGrades}
      />
    </div>
  );
};

Bedrooms.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  onFloorAdd: PropTypes.func,
  onFloorDelete: PropTypes.func,
  onRoomGradeAdd: PropTypes.func,
  onRoomGradeDelete: PropTypes.func,
  floors: PropTypes.array,
  attendees: PropTypes.array,
  roomGrades: PropTypes.array,
  onFacilityEditSubmit: PropTypes.func,
  onFacilityCreateSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  setCodeSearch: PropTypes.func,
  setNameSearch: PropTypes.func,
  setDescriptionSearch: PropTypes.func,
  setRoomGradeSearch: PropTypes.func,
  setAttendeeSearch: PropTypes.func,
  setBillingNoteSearch: PropTypes.func,
};

export default Bedrooms;
