import { SearchOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import { t } from "i18next";
import { useEffect } from "react";
import { ResetFunction, SearchFunctionType } from "utils/tableColumnUtils";

interface ColumnSearchActionButtonsProps {
  handleSearch: SearchFunctionType;
  selectedKeys: string[];
  dataIndex: string;
  searchSet: string;
  handleReset: ResetFunction;
  clearFilters: (() => void) | undefined;
  confirm: FilterDropdownProps["confirm"];
  triggerReset?: string | null;
}

const ColumnSearchActionButtons = ({
  handleSearch,
  selectedKeys,
  dataIndex,
  searchSet,
  handleReset,
  clearFilters,
  confirm,
  triggerReset,
}: ColumnSearchActionButtonsProps) => {
  const handleResetFilter = () => (clearFilters ? handleReset(clearFilters, dataIndex, searchSet, confirm) : "");

  useEffect(() => {
    if (typeof triggerReset === "string") {
      handleResetFilter();
    }
  }, [triggerReset]);

  return (
    <Space style={{ width: "100%" }}>
      <Button
        type="primary"
        onClick={() => handleSearch(selectedKeys, confirm, dataIndex, searchSet)}
        icon={<SearchOutlined />}
        size="small"
        style={{
          width: 90,
        }}
      >
        {t("Search").toString()}
      </Button>
      <Button
        onClick={handleResetFilter}
        size="small"
        style={{
          width: 90,
        }}
      >
        {t("Reset").toString()}
      </Button>
    </Space>
  );
};

export default ColumnSearchActionButtons;
