import { useGetItineraryBookletUnassignedTeamMembers } from "api/queries/itineraryBookletsForumTeamMembers";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input, Select } from "components/styleguide";
import {
  ItineraryBookletsForumTeamMemberRequestModel,
  ItineraryBookletsForumTeamMemberResponseModel,
} from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Option {
  label: string;
  value: number;
}

interface TeamMemberFormProps {
  forumId: number;
  teamMember?: ItineraryBookletsForumTeamMemberResponseModel;
  isSubmitting: boolean;
  onSubmit: (values: ItineraryBookletsForumTeamMemberRequestModel) => void;
  onClose: () => void;
}

const TeamMemeberForm: FC<TeamMemberFormProps> = ({ forumId, teamMember, isSubmitting, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { data: { data: unassignedTeamMembers = [] } = {}, isLoading: isUnassignedTeamMembersLoading } =
    useGetItineraryBookletUnassignedTeamMembers({ forumId });

  const getUnassignedTeamMembersOptions = () => {
    return unassignedTeamMembers?.map(({ id, name }) => {
      return {
        label: name as string,
        value: id as number,
      };
    });
  };

  const getTeamMembersOptions = () => {
    if (teamMember) {
      return [
        {
          label: teamMember.name,
          value: teamMember.userId,
        },
        ...getUnassignedTeamMembersOptions(),
      ];
    }

    return getUnassignedTeamMembersOptions();
  };

  const onSelectedTeamMemberChange = (selectedTeamMemberId: number) => {
    const selectedTeamMember = unassignedTeamMembers?.find(
      (unassignedTeamMember) => unassignedTeamMember.id === selectedTeamMemberId,
    );

    form.setFieldValue("jobTitle", selectedTeamMember?.jobTitle);
    form.setFieldValue("emailAddress", selectedTeamMember?.email);
    form.setFieldValue("mobilePhone", selectedTeamMember?.mobilePhone);
    form.setFieldValue("businessPhone", selectedTeamMember?.businessPhone);
  };

  const filterOptions = (input: string, option?: Option) => {
    const optionText = option?.label.toLowerCase();
    const searchValueText = input.toLowerCase();
    return optionText ? optionText.indexOf(searchValueText) >= 0 : false;
  };

  return (
    <FullHeightForm
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      initialValues={teamMember}
      actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
      actionsAppend={
        <Button htmlType="submit" type="primary" loading={isSubmitting} disabled={isSubmitting}>
          {t("Save")}
        </Button>
      }
    >
      <Form.Item
        label={t("Name")}
        name="userId"
        rules={[{ required: true, message: t("errors.required", { prop: "$t(Name)" }) }]}
      >
        <Select
          showSearch
          options={getTeamMembersOptions()}
          filterOption={filterOptions}
          loading={isUnassignedTeamMembersLoading}
          onChange={onSelectedTeamMemberChange}
        />
      </Form.Item>
      <Form.Item label={t("Job Title")} name="jobTitle">
        <Input />
      </Form.Item>
      <Form.Item label={t("Email address")} name="emailAddress" initialValue={teamMember?.email}>
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Mobile phone")}
        name="mobilePhone"
        initialValue={teamMember?.mobilePhone}
        rules={[
          {
            max: 32,
            message: t("The character limit is {{count}}", { count: 32 }),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label={t("Phone Number")}
        name="businessPhone"
        initialValue={teamMember?.businessPhone}
        rules={[
          {
            max: 32,
            message: t("The character limit is {{count}}", { count: 32 }),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </FullHeightForm>
  );
};

export default TeamMemeberForm;
