import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const getForumGroupsQueryKey = KEYS.GET_FORUM_GROUPS;
export const getForumGroupQueryKey = KEYS.GET_FORUM_GROUP;
export const getGroupTasksQueryKey = KEYS.GET_GROUP_TASKS;

export const useGetForumGroupsQuery = getWrappedUseQuery(getForumGroupsQueryKey, apiClient.ForumGroups.getAll);

export const useAvailableCodesQuery = getWrappedUseQuery(
  KEYS.GET_AVAILABLE_CODES,
  apiClient.ForumGroups.availableCodes.getAll,
);

export const useSelectedCodesQuery = getWrappedUseQuery(
  KEYS.GET_SELECTED_CODES,
  apiClient.ForumGroups.selectedCodes.getAll,
);

export const useGroupTasksQuery = getWrappedUseQuery(getGroupTasksQueryKey, apiClient.ForumGroupTasks.getAll);

export const useGetExportTaskStatusesQuery = getWrappedUseQuery(
  KEYS.GET_TASK_STATUSES_EXPORT,
  apiClient.ForumGroupTasks.statuses.export.get,
);

export const useGetForumGroupQuery = getWrappedUseQuery(getForumGroupQueryKey, apiClient.ForumGroups.get);
