import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";
import { AxiosRequestConfig } from "axios";
import { ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest } from "generated/api";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useAttendeesQuery = getWrappedUseQuery(KEYS.GET_ATTENDEES, apiClient.ForumAttendees.get);

export const useAttendeeQuery = getWrappedUseQuery(KEYS.GET_ATTENDEE, apiClient.ForumAttendees.getById);

export const useAttendeeCompanyAddressQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_COMPANY_ADDRESS,
  apiClient.ForumAttendees.companyAddress.get,
);

export const useAttendeeGroupMembershipsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_GROUP_MEMBERSHIP,
  apiClient.ForumAttendees.groups.get,
);

export const useAttendeePreferencesConferencesQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_CONFERENCES,
  apiClient.ForumAttendees.seminarPreference.get,
);

export const useAttendeePreferencesMeetingsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_MEETINGS,
  apiClient.ForumAttendees.meetingPreference.get,
);

export const useAttendeePreferencesCategoriesQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_CATEGORIES,
  apiClient.ForumAttendees.categoryPreference.get,
);

export const useAttendeePreferencesAvailableCategoriesQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_AVAILABLE_CATEGORIES,
  apiClient.ForumAttendees.categoryPreference.available.get,
);

export const useAttendeePreferencesBlockedMeetingsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS,
  apiClient.ForumAttendees.blockedMeetingPreference.get,
);

export const useAttendeeReContactsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_RECONTACTS,
  apiClient.ForumAttendees.reContacts.get,
);

export const useAttendeeHistoryInformationQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_HISTORY_INFORMATION,
  apiClient.ForumAttendees.history.get,
);

export const useExportAttendeesQuery = getWrappedUseQuery(KEYS.GET_ATTENDEE_EXPORT, apiClient.ForumAttendees.getExport);

export const useAttendeePreferencesSeminarsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_SEMINARS,
  apiClient.ForumAttendees.seminarPreference.getById,
);

export const useAttendeeSpeakerTrackingQuery = getWrappedUseQuery(
  KEYS.ATTENDEE_SPEAKER_TRACKING_QUERY,
  apiClient.ForumAttendees.speakerTracking.get,
);

export const useGetHotelAndRoomAllocationQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_HOTEL_AND_ROOM_ALLOCATION,
  apiClient.ForumAttendees.hotelAndRoomAllocation.get,
);

export const useAttendeePreferencesSelectionOptionsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS,
  apiClient.ForumAttendees.selectionChoice.get,
);

export const useAttendeePreferencesSelectionOptionDefinitionsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PREFERENCES_SELECTION_OPTION_DEFINITION,
  apiClient.ForumAttendees.selectionChoice.definition.get,
);

export const useAttendeeItineraryQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_ITINERARY,
  apiClient.ForumAttendees.itinerary.get,
);

export const useAttendeeItineraryJointCandidatesQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_ITINERARY_JOINT_CANDIDATES,
  apiClient.ForumAttendees.itinerary.jointCandidates.get,
);

export const useAttendeeDietaryRequirementsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_DIETARY_REQUIREMENTS,
  apiClient.ForumAttendees.dietaryRequirements.get,
);

export const useExportItinerary = getWrappedUseMutation(apiClient.ForumAttendees.itinerary.export.get);

export const useGetFeedbackByParticipationTypeQuery = getWrappedUseQuery(
  KEYS.GET_FEEDBACK_BY_PARTICIPATION_TYPE,
  (params: ForumAttendeeApiForumForumIdAttendeesByParticipationTypeGetRequest, options: AxiosRequestConfig = {}) =>
    apiClient.ForumAttendees.byParticipationType.get({ ...params, orderBy: undefined }, options),
);

export const useAttendeeContactDetailsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_CONTACT_DETAILS,
  apiClient.ForumAttendees.contactDetails.get,
);

export const useAttendeeDescriptionQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_DESCRIPTION,
  apiClient.ForumAttendees.description.get,
);

export const useAttendeeDescriptionsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEES_DESCRIPTIONS,
  apiClient.ForumAttendees.description.getAll,
);

export const useAttendeeAddresInfoQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_ADDRESS_INFO,
  apiClient.ForumAttendees.addressInfo.get,
);

export const useAttendeeSocialLinksQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_SOCIAL_LINKS,
  apiClient.ForumAttendees.socialLinks.get,
);

export const useAttendeePartnerQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_PARTNER,
  apiClient.ForumAttendees.partner.get,
);

export const useAttendeeAttendaceDetailsQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_ATTENDANCE_DETAILS,
  apiClient.ForumAttendees.attendanceDetails.get,
);

export const useForumGroupTasksAttendeesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_GROUPS_TASKS_ATTENDEES,
  apiClient.ForumGroupTasks.attendees.get,
);

export const useGetAttendeeMeetingFeedback = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_MEETING_FEEDBACK,
  apiClient.ForumAttendeeMeetingFeedback.get,
);

export const useGetAttendeeConferenceSessionFeedback = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_CONFERENCE_SESSION_FEEDBACK,
  apiClient.ForumAttendeeSessionFeedback.get,
);

export const useGetAttendeeReasonsForMeetingQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_REASONS_FOR_MEETING,
  apiClient.ForumAttendees.reasonsForMeeting.get,
);

export const useGetForumAttendeeSummaryQuery = getWrappedUseQuery(
  KEYS.GET_ATTENDEE_SUMMARY,
  apiClient.ForumAttendees.summary.get,
);

export const useGetAttendeePotentialReplacementsQuery = getWrappedUseQuery(
  KEYS.GET_POTENTIAL_REPLACEMENTS,
  apiClient.ForumAttendees.replacement.get,
);
