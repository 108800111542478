import { Space } from "components/styleguide";
import React, { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = {
  Surname: "Surname",
  Facility: "Facility",
  Start: "Start",
};

const SpeakerBookingsOptions = () => {
  const [orderBy, setOrderBy] = useState<string>(SortOptions.Surname);

  return (
    <Space align="end">
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />

      <LinkToReport parameters={{ OrderBy: orderBy }} />
    </Space>
  );
};

export default SpeakerBookingsOptions;
