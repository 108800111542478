import { useSeminarsQuery } from "api/queries/seminars";
import { Col, Row, Table } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { ForumSeminarWithTypeAndStreamAndSpeakers } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";

import styles from "./styles.module.less";

const SpeakerVisuals: FC = () => {
  const { t } = useTranslation();
  const forumId = useParams().id;
  const location = useLocation();

  const { response: query, handleSort } = useModernQueryWithPaginationAndOrder(useSeminarsQuery, {}, { forumId });

  const renderSeminarDocumentLink = useCallback(
    (data: string, record: ForumSeminarWithTypeAndStreamAndSpeakers) => (
      <Link
        className={styles.link}
        to={`${location.pathname}/${record.id}`}
        state={{ ...(location.state || {}), seminarCode: record.code }}
      >
        {data}
      </Link>
    ),
    [location.pathname, location.state],
  );

  const columns = useMemo(
    () => [
      {
        title: t("Seminar Code"),
        dataIndex: "code",
        sorter: true,
        render: renderSeminarDocumentLink,
      },
      {
        title: t("Seminar Type"),
        dataIndex: "forumSeminarTypeName",
        sorter: true,
        render: renderSeminarDocumentLink,
      },
      {
        title: t("Seminar Title"),
        dataIndex: "title",
        sorter: true,
        render: renderSeminarDocumentLink,
      },
    ],
    [t, renderSeminarDocumentLink],
  );

  return (
    <div className="table-wrapper">
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserGuide.Title title="Seminars" articleId="16426055824925-speaker-visuals" />
        </Col>
        <Col span={24}>
          <Table
            id="seminarsTable"
            dataSource={query?.data?.data || []}
            columns={columns}
            loading={query.isLoading}
            pagination={false}
            onChange={handleSort}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
};

export default SpeakerVisuals;
