import { useQueryClient } from "@tanstack/react-query";
import { TableProps } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { useGetForumCompanyHistoryInformationQuery } from "api/queries/forumCompanies";
import KEYS from "api/queries/keys";
import { Table } from "components/styleguide";
import { CompanyForumParticipationModel } from "generated/api";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps } from "utils/tableColumnUtils";

interface ForumCompanyHistoryInformationProps {
  forumId: number;
  forumCompanyId: number;
}

const ForumCompanyHistoryInformationPanel: FC<ForumCompanyHistoryInformationProps> = ({ forumId, forumCompanyId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [sort, setSort] = useState<{ orderBy: string | undefined; orderDir: string | undefined }>({
    orderBy: undefined,
    orderDir: undefined,
  });

  const [forumCodeSearch, setForumCodeSearch] = useState<string | undefined>(undefined);
  const [forumNameSearch, setForumNameSearch] = useState<string | undefined>(undefined);

  const getForumCompanyHistoryInformationQuery = useGetForumCompanyHistoryInformationQuery({
    forumId,
    forumCompanyId,
    forumName: forumNameSearch,
    forumCode: forumCodeSearch,
    orderBy: sort.orderBy,
    orderDir: sort.orderDir,
  });

  const companyAttendedForums = getForumCompanyHistoryInformationQuery.data
    ?.data as Array<CompanyForumParticipationModel>;

  const handleSort: TableProps<CompanyForumParticipationModel>["onChange"] = (_, __, sorter) => {
    const sortOptions = sorter as SorterResult<CompanyForumParticipationModel>;

    const orderBy = sortOptions.column?.dataIndex?.toString();
    const orderDir = sortOptions.order === "ascend" ? "asc" : sortOptions.order === "descend" ? "desc" : undefined;

    setSort({ orderBy, orderDir });
  };

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "forumCode": {
        setForumCodeSearch(selectedKeys[0]);
        break;
      }
      case "forumName": {
        setForumNameSearch(selectedKeys[0]);
        break;
      }
    }
    queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY_HISTORY_INFORMATION]);
    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "forumCode": {
        setForumCodeSearch(undefined);
        break;
      }
      case "forumName": {
        setForumNameSearch(undefined);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  const columns: TableProps<CompanyForumParticipationModel>["columns"] = useMemo(
    () => [
      {
        title: t("Forum Code"),
        dataIndex: "forumCode",
        key: "forumCode",
        sorter: true,
        ...getColumnSearchProps("forumCode", handleSearch, handleReset, "forumCode"),
      },
      {
        title: t("Forum Name"),
        dataIndex: "forumName",
        key: "forumName",
        sorter: true,
        ...getColumnSearchProps("forumName", handleSearch, handleReset, "forumName"),
      },
      {
        title: t("Year"),
        dataIndex: "year",
        key: "year",
        sorter: true,
      },
      {
        title: t("Number of executives"),
        dataIndex: "numberOfExecutives",
        key: "numberOfExecutives",
        sorter: true,
      },
      {
        title: t("Price"),
        dataIndex: "companyPackagePrice",
        key: "companyPackagePrice",
        render: (_: number, record: CompanyForumParticipationModel) =>
          `${record.companyPackagePrice || ""} ${record.companyPackagePrice && record.currency ? record.currency : ""}`,
        sorter: true,
      },
      {
        title: t("Number of itineraries"),
        dataIndex: "numberOfItineraries",
        key: "numberOfItineraries",
        sorter: true,
      },
    ],
    [],
  );

  return (
    <Table
      rowKey="id"
      data-testid="peopleTable"
      dataSource={companyAttendedForums}
      columns={columns}
      bordered
      pagination={false}
      loading={getForumCompanyHistoryInformationQuery.isLoading}
      onChange={handleSort}
    />
  );
};

export default ForumCompanyHistoryInformationPanel;
