import PropTypes from "prop-types";
import FreeText from "./free-text";
import PositiveSelectionV2 from "./positive-selection-v2";

const Types = {
  FreeText,
  PositiveSelectionV2,
};

export default {
  ANY: PropTypes.oneOfType(Object.values(Types)),
  ...Types,
};
