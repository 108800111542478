import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useMoveAttendeeItineraryMutation } from "api/mutations/attendees";
import { useForumVenueTablesQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import { Button, Checkbox, Select, Spin } from "components/styleguide";
import { FacilityType, ForumFacilityTableModel, TimeSlotType } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { checkIsTimeSlotTypeBusinessTime, checkIsTimeSlotTypeMealTime } from "../helpers/timeSlotType";

interface MeetingsMoveLocationProps {
  itineraryId: number;
  timeSlotType: TimeSlotType;
  onClose: () => void;
}

const MeetingsMoveLocation: FC<MeetingsMoveLocationProps> = ({ itineraryId, timeSlotType, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const forumId = toNumber(useParams().id);
  const attendeeId = toNumber(useParams().attendeeId);

  const [allMeetings, setAllMeetings] = useState<boolean>(false);

  const { mutate: moveMutate, isLoading: isMoveLoading } = useMoveAttendeeItineraryMutation();

  const getFacilityTypes = (): Array<FacilityType> | undefined => {
    if (checkIsTimeSlotTypeMealTime(timeSlotType)) {
      return [FacilityType.Restaurant];
    } else if (checkIsTimeSlotTypeBusinessTime(timeSlotType)) {
      return [FacilityType.MeetingRoom, FacilityType.SpeedMeetings];
    }
  };

  const {
    data: { data: facilityTables = [] as Array<ForumFacilityTableModel> } = {},
    refetch: fetchFacilityTables,
    isLoading: isFacilityTablesLoading,
    isFetching: isFacilityTablesRefetching,
  } = useForumVenueTablesQuery(
    {
      forumId,
      facilityTypes: getFacilityTypes(),
      availableForMeeting: itineraryId,
      allAttendeeMeetings: allMeetings,
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      onError: () => toast.error(t("Something went wrong")),
    },
  );

  const facilityTablesOptions = useMemo(() => {
    return facilityTables
      .filter(({ tables }) => tables)
      .map(({ facilityCode, tables }) => ({
        label: t("Facility {{code}}", { code: facilityCode }),
        options: tables?.map(({ id, tableNumber, seats }) => ({
          value: id,
          label: t("table {{number}}, {{seats}} available seats", { number: tableNumber, seats }),
        })),
      }));
  }, [facilityTables]);

  const getFacilityId = (facilityTableId: number) => {
    return facilityTables.find(({ tables }) => {
      return tables?.find(({ id }) => id === facilityTableId);
    })?.facilityId;
  };

  const move = (facilityTableId: number, areAllMeetings: boolean) => {
    moveMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: {
          forumFacilityId: getFacilityId(facilityTableId),
          forumTableId: facilityTableId,
          applyLocationForAllMeetings: areAllMeetings,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          onClose();
          toast.success(t("The meeting location was moved to a new facility"));
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const allMeetingsOnChange = (event: CheckboxChangeEvent) => {
    setAllMeetings(event.target.checked);
    fetchFacilityTables();
  };

  const onSubmit = ({ id, areAllMeetings }: { id: number; areAllMeetings: boolean }) => move(id, areAllMeetings);

  if (isFacilityTablesLoading || isFacilityTablesRefetching) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (!facilityTables.length) {
    return (
      <>
        {t(
          allMeetings
            ? "There are no available facilities at these times"
            : "There are no available facilities at this time",
        )}
      </>
    );
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        label={t("Choose a Table")}
        name="id"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: t("Facility") }),
          },
        ]}
      >
        <Select style={{ width: "350px" }} options={facilityTablesOptions} disabled={isMoveLoading} />
      </Form.Item>
      <Form.Item
        name="areAllMeetings"
        valuePropName="checked"
        labelCol={{ span: 24, offset: 0 }}
        initialValue={allMeetings}
      >
        <Checkbox onChange={allMeetingsOnChange} disabled={isMoveLoading}>
          {t("Change facility for all meetings of a given timeslot type")}
        </Checkbox>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={isMoveLoading} disabled={isMoveLoading}>
        {t("Move")}
      </Button>
    </Form>
  );
};

export default MeetingsMoveLocation;
