import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

import QuestionnaireType from "../../../../backend-models/questionnaireType";
import StatefulLink from "../../../../components/StatefulLink";
import { Button } from "../../../../components/styleguide";
import { getColumnSearchProps } from "../../../../utils/tableColumnUtils";
import GenericQuestionnairesTable from "../../components/GenericQuestionnairesTable";

import styles from "./styles.module.less";

const buildColumn = (title, key, options) => ({
  title,
  key,
  dataIndex: key,
  ...options,
});

const getTypeRenderer = () => {
  return {
    render: (type) => {
      const questionnaireType = new QuestionnaireType(type);
      return questionnaireType.toString() ?? "Unknown type";
    },
  };
};

const getDateRenderer = () => {
  return {
    render: (value) => {
      if (!value) {
        return "No date was provided";
      }

      return moment(value).format("LL");
    },
  };
};

const getActionsRenderer = () => {
  return {
    render: (questionnaireId, row) => {
      return (
        <div className={styles.actions}>
          <StatefulLink
            to={`${row.forumId}/${questionnaireId}`}
            state={{
              importQuestionnaireName: row.title,
            }}
          >
            <Button type="primary">Import</Button>
          </StatefulLink>
        </div>
      );
    },
  };
};

const SystemQuestionnairesTable = ({ className, items, loading, handleSearch, handleReset, ...props }) => {
  const columns = [
    buildColumn("Event Code", "forumCode", {
      width: "10%",
      sorter: true,
      ...getColumnSearchProps("forumCode", handleSearch, handleReset, "forumCode"),
    }),
    buildColumn("Questionnaire Name", "title", {
      sorter: true,
      ...getColumnSearchProps("title", handleSearch, handleReset, "title"),
    }),
    buildColumn("Questionnaire Type", "type", { ...getTypeRenderer(), sorter: true }),
    buildColumn("Date of Creation", "createdAt", { ...getDateRenderer(), sorter: true }),
    buildColumn("", "questionnaireId", { responsive: ["sm"], ...getActionsRenderer() }),
  ];

  return (
    <GenericQuestionnairesTable
      className={className}
      items={items}
      columns={columns}
      loading={loading}
      rowKey="questionnaireId"
      {...props}
    />
  );
};

SystemQuestionnairesTable.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      eventCode: PropTypes.string,
      name: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ),
};

SystemQuestionnairesTable.defaultProps = {
  className: undefined,
  loading: false,
  items: [],
};

export default SystemQuestionnairesTable;
