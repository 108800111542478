import { ForumDocumentDefinitionSectionApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumDocumentDefinitionSectionApi);

const ForumDocumentDefinitionSection = {
  elements: {
    post: api.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsPost,
    put: api.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdPut,
    delete: api.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdDelete,
    order: {
      put: api.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdElementsElementIdOrderPut,
    },
  },
};

export default ForumDocumentDefinitionSection;
