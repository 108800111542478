import Icon, { DeleteFilled, UploadOutlined } from "@ant-design/icons";
import { Image, UploadFile } from "antd";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleFileFakeRequest } from "utils/documentUtils";

import { Button, Col, Row, Upload } from "../../styleguide";

interface VenueMapUploadProps {
  current?: string;
  fileList: Array<UploadFile>;
  onChange: (fileList: Array<UploadFile> | null) => void;
}

const VenueMapUpload: FC<VenueMapUploadProps> = ({ current, fileList, onChange }) => {
  const { t } = useTranslation();

  const [isCurrentMapVisible, setIsCurrentMapVisible] = useState<boolean>(true);

  const resetCurrentImage = () => {
    setIsCurrentMapVisible(false);
    onChange(null);
  };

  return isCurrentMapVisible && current ? (
    <Row align="middle">
      <Col span={21}>
        <Image src={current} width={200} />
      </Col>
      <Col span={3}>
        <Icon onClick={resetCurrentImage} component={() => <DeleteFilled />} />
      </Col>
    </Row>
  ) : (
    <Upload
      fileList={fileList}
      beforeUpload={() => false}
      onChange={(info) => onChange(info.fileList)}
      customRequest={handleFileFakeRequest}
      maxCount={1}
      accept=".png,.jpg,.jpeg,.tif,.tiff"
    >
      <Button icon={<UploadOutlined />}>{t("Click to Upload")}</Button>
    </Upload>
  );
};

export default VenueMapUpload;
