import "./styles.less";

import { useQueryClient } from "@tanstack/react-query";
import { useAddFavoriteForumMutation, useRemoveFavoriteForumMutation } from "api/mutations/userPreferences";
import { useForumsQuery, useForumsYearsQuery } from "api/queries/forums";
import { ForumResponseModel, PageResponseForumResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ForumsTable from "./ForumsTable";
import ForumYearSelect from "./ForumYearSelect";

export default function Home() {
  const queryClient = useQueryClient();

  const [years, setYears] = useState<number[]>();
  const [selectedYear, setSelectedYear] = useState<number | undefined>();
  const [forums, setForums] = useState<ForumResponseModel[] | undefined>();
  const [forumCount, setForumCount] = useState<number | undefined>();
  const [nameSearch, setNameSearch] = useState<string | undefined>();
  const [codeSearch, setCodeSearch] = useState<string | undefined>(undefined);
  const [countryCodeSearch, setCountryCodeSearch] = useState<string | undefined>();
  const [filterFavorites, setFilterFavorites] = useState(true);

  const { t } = useTranslation();

  const addFavoriteForumMutation = useAddFavoriteForumMutation();
  const removeFavoriteForumMutation = useRemoveFavoriteForumMutation();

  const forumsYearsRequest = useForumsYearsQuery(
    {},
    {
      onSuccess: ({ data }) => {
        setYears(data || []);
      },
      refetchOnWindowFocus: false,
    },
  );

  const forumsRequest = useModernQueryWithPaginationAndOrder(
    useForumsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseForumResponseModel }) => {
        setForums(data.items);
        setForumCount(data.total);
      },
      enabled: filterFavorites ? true : forumsYearsRequest.isFetched && !!years && !!years[0],
    },
    {
      year: !filterFavorites ? selectedYear : undefined,
      code: codeSearch,
      title: nameSearch,
      fav: filterFavorites ? "true" : undefined,
      countryCode: countryCodeSearch,
    },
    {},
  );

  const handleYearSelect = (year: number) => {
    setFilterFavorites(false);
    setSelectedYear(year);
  };

  // display proper loader
  if (forumsYearsRequest.isLoading || !years) {
    return null;
  }

  const handleFavFilter = () => {
    setSelectedYear(undefined);
    return setFilterFavorites(true);
  };
  const markFavorite = (forum: ForumResponseModel) => {
    if (forum.favorite && forum.id) {
      removeFavoriteForumMutation.mutate(
        { forumId: forum.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
          },
        },
      );
    } else {
      addFavoriteForumMutation.mutate(
        { forumId: forum.id as number },
        {
          onSuccess: () => {
            queryClient.invalidateQueries();
          },
        },
      );
    }

    setForums(
      forums?.map((f) => {
        if (f.id === forum.id) {
          f.favorite = !f.favorite;
        }
        return f;
      }),
    );
  };

  return (
    <div className="events-page">
      <div className="event-wrapper">
        <h1>{t("Pick your forum")}</h1>
        <ForumYearSelect
          years={years}
          selectedYear={selectedYear}
          isLoading={forumsRequest.response.isLoading}
          shouldFilterFavorites={filterFavorites}
          onSelect={handleYearSelect}
          onFavoriteSelect={handleFavFilter}
        />
        <ForumsTable
          count={forumCount}
          forums={forums}
          isLoading={
            forumsRequest.response.isLoading ||
            addFavoriteForumMutation.isLoading ||
            removeFavoriteForumMutation.isLoading
          }
          onFavoriteChange={markFavorite}
          onTableChange={forumsRequest.handleSort}
          pagination={forumsRequest.pagination}
          nameSearch={nameSearch}
          setNameSearch={setNameSearch}
          codeSearch={codeSearch}
          setCodeSearch={setCodeSearch}
          countryCodeSearch={countryCodeSearch}
          setCountryCodeSearch={setCountryCodeSearch}
        />
      </div>
    </div>
  );
}
