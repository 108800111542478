export const swap = <T>(array: Array<T>, from: number, to: number) => {
  const temp = [...array];

  [temp[from], temp[to]] = [temp[to], temp[from]];

  return temp;
};

export const replaceAt = <T>(array: Array<T>, index: number, replaceWith: T) => {
  const temp = [...array];

  temp[index] = replaceWith;

  return temp;
};

export const removeAt = <T>(array: Array<T>, index: number) => {
  const temp = [...array];

  temp.splice(index, 1);

  return temp;
};

export const insertAt = <T>(array: Array<T>, index: number, element: T) => {
  const temp = [...array];

  temp.splice(index, 0, element);

  return temp;
};

export const removeOccurrences = <T>(targetArray: Array<T>, occurrences: T | T[], keyProperty: keyof T) => {
  const occurrencesArray = Array.isArray(occurrences) ? occurrences : [occurrences];
  let result = targetArray;

  occurrencesArray.map((occurrence) => {
    result = result.filter((targetItem) => targetItem[keyProperty] !== occurrence[keyProperty]);
  });

  return result;
};
