import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Typography } from "components/styleguide";
import { SelectionSiteContentSelectionSiteContentPage, SelectionSiteItemType } from "generated/api";
import React, { ComponentProps, MouseEventHandler, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import selectionSiteItemTypeEnum from "utils/selectionSiteItemTypeUtils";

import SelectionSiteStatusContext from "../../../../context/selection-site-status";
import DependenciesList from "../DependenciesList";
import DependenciesRenderer from "../ItemLabel/components/DependenciesRenderer";

const ALLOWED_TYPES_TO_ADD: Array<SelectionSiteItemType> = [
  "FreeText",
  "Availability",
  "ConferenceRankV2",
  "ConferenceSelectionV2",
  "EventOptions",
  "Joint",
  "NegativeSelection",
  "PositiveSelectionV2",
  "ReasonForMeeting",
  "ReasonForMeetingV2",
  "ReasonForMeetingV3",
  "SubmittingSelections",
  "TabbedConferenceSelection",
];

interface PageLabelProps {
  title: string;
  pageId: number;
  pages: Array<SelectionSiteContentSelectionSiteContentPage>;
  isSelected?: boolean;
  hasErrors?: boolean;
  onRemoveConfirm?: () => void | Promise<void>;
  onAddItemClick?: (type: string) => void;
}

const PageLabel = ({
  title,
  pageId,
  pages,
  isSelected,
  hasErrors,
  onRemoveConfirm,
  onAddItemClick,
}: PageLabelProps) => {
  const { t } = useTranslation();

  const status = useContext(SelectionSiteStatusContext);
  const isDeletionDisabled = status === "Open";

  const [isMutating, setIsMutating] = useState(false);
  const items = useMemo(() => {
    return ALLOWED_TYPES_TO_ADD.map((value) => ({
      key: value,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: t(selectionSiteItemTypeEnum.MAP[value]),
    }));
  }, [t]);

  const handleRemoveButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const pageItems = pages.find((page) => page?.id === pageId)?.items ?? [];
    const itemsCount = pageItems?.length ?? 0;
    const hasItems = itemsCount !== 0;

    const defaultMessage = hasItems
      ? t("Do you want to proceed with deletion of this page, it has items?")
      : t("Do you want to proceed with deletion of this page?");

    Modal.confirm({
      title: "Confirmation",
      content: (
        <DependenciesList
          defaultMessage={<Typography.Text>{defaultMessage}</Typography.Text>}
          foundDependenciesMessage={(entries) => {
            return (
              <>
                <Typography.Text>{t("This page is associated with next items:")}</Typography.Text>
                <DependenciesRenderer items={entries} content={{ pages }} />
                <Typography.Text>{t("Do you want to proceed with its deletion?")}</Typography.Text>
              </>
            );
          }}
          itemIds={pageItems.map((item) => item?.id)}
          content={{ pages }}
        />
      ),
      closable: true,
      onOk: () => {
        const callback = onRemoveConfirm?.();

        if (callback instanceof Promise) {
          setIsMutating(true);
        }
      },
    });
  };

  const handleAddButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleMenuDropdownClick: ComponentProps<typeof Menu>["onClick"] = ({ domEvent, key }) => {
    domEvent.stopPropagation();
    domEvent.preventDefault();

    if (ALLOWED_TYPES_TO_ADD.includes(key as SelectionSiteItemType)) {
      onAddItemClick?.(key);
    }
  };

  return (
    <Menu.ItemLabel
      isSelected={isSelected}
      append={
        <Button
          type="text"
          icon={<DeleteOutlined />}
          size="small"
          loading={isMutating}
          disabled={isMutating || isDeletionDisabled}
          onClick={handleRemoveButtonClick}
        />
      }
    >
      <Typography.Text type={hasErrors ? "danger" : undefined}>{title}</Typography.Text>
      <Dropdown
        trigger={["click"]}
        disabled={isDeletionDisabled}
        overlay={<Menu items={items} onClick={handleMenuDropdownClick} />}
      >
        <Button disabled={isMutating} onClick={handleAddButtonClick} icon={<PlusOutlined />} size="small" />
      </Dropdown>
    </Menu.ItemLabel>
  );
};

export default PageLabel;
