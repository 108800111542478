import { AxiosError } from "axios";
import { Em2ExceptionResponseObject, Em2ExceptionTypes } from "generated/api";

type CapitalizeObjectKeys<T> = {
  [K in keyof T as Capitalize<K & string>]: CapitalizeObjectKeys<T[K]>;
};

export type Em2ExceptionResponse<TMeta> = Omit<Em2ExceptionResponseObject, "meta"> & {
  meta: CapitalizeObjectKeys<TMeta>;
};

export class Em2AxiosError {
  error: AxiosError;

  constructor(error: unknown) {
    this.error = error as AxiosError;
  }

  isOfType(exceptionType: Em2ExceptionTypes): boolean {
    const exceptionError = this.error.response?.data as Em2ExceptionResponse<unknown>;
    return exceptionError?.type === exceptionType.toString();
  }

  getMeta<TMeta>(): CapitalizeObjectKeys<TMeta> {
    return (this.error.response?.data as Em2ExceptionResponse<TMeta>).meta;
  }
}
