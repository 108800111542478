import { Space } from "components/styleguide";
import moment, { Moment } from "moment";
import { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import { CancellationsSortOptions } from "../constants";
import DatePickerOption from "../OptionTypes/DatePickerOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const CancellationsOptions = ({ allowDateClear = false }: { allowDateClear?: boolean }) => {
  const [orderBy, setOrderBy] = useState<string>(CancellationsSortOptions.Company);
  const [date, setDate] = useState<Moment>();

  return (
    <Space align="end">
      <SortOrderOption options={CancellationsSortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
      <DatePickerOption date={date} setDate={setDate} allowClear={allowDateClear} />

      <LinkToReport
        disabled={!date && !allowDateClear}
        parameters={{
          OrderBy: orderBy,
          Date: date && moment(date).format("YYYY-MM-DD"),
        }}
      />
    </Space>
  );
};

export default CancellationsOptions;
