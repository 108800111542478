import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { TableProps } from "antd";
import { useDeleteSeminarSessionMutation } from "api/mutations/forumSeminarSessions";
import { useSeminarsSessionsQuery } from "api/queries/forumSeminarSessions";
import KEYS from "api/queries/keys";
import { useForumVisualSpeakerDocumentsQuery } from "api/queries/visualSpeakerDocuments";
import DateRenderer from "components/DateRenderer";
import { Button, Col, Modal, Row, Table, Typography } from "components/styleguide";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ForumSeminarSessionWithTimeslotSession,
  ForumSeminarsWithSessionsModel,
  ForumSeminarWithTypeAndStreamAndSpeakers,
} from "../../../../generated/api";
import SeminarSessionForm from "../Edit";

import styles from "./styles.module.less";

const { confirm } = Modal;
interface SeminarSessionsProps {
  seminar: ForumSeminarWithTypeAndStreamAndSpeakers;
}

const SeminarSessions: FC<SeminarSessionsProps> = ({ seminar }) => {
  const forumId = useParams().id;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [sessions, setSessions] = useState<Array<ForumSeminarSessionWithTimeslotSession>>([]);
  const [activeSession, setActiveSession] = useState<ForumSeminarSessionWithTimeslotSession | undefined>(undefined);

  const documentsQuery = useForumVisualSpeakerDocumentsQuery(
    { forumId: Number(forumId), seminarId: seminar.id },
    { enabled: false },
  );

  const deleteSeminarSessionMutation = useDeleteSeminarSessionMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([KEYS.GET_SEMINAR_SESSIONS]);
      queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
    },
  });

  const showDrawer = () => setIsDrawerVisible(true);

  const hideDrawer = () => {
    setIsDrawerVisible(false);
    setActiveSession(undefined);
  };

  const { response: query, handleSort } = useModernQueryWithPaginationAndOrder(
    useSeminarsSessionsQuery,
    {
      onSuccess: ({ data }: { data: Array<ForumSeminarSessionWithTimeslotSession> }) => {
        setSessions(data);
      },
      enabled: seminar != null,
    },
    { forumId, forumSeminarId: seminar?.id },
  );

  const editSession = (session: ForumSeminarSessionWithTimeslotSession) => {
    setActiveSession(session);
    showDrawer();
  };

  const handleSessionDelete = async (sessionId: number) => {
    const documents = await documentsQuery.refetch();
    const isCurrentSeminar = (associatedSeminar: ForumSeminarsWithSessionsModel) =>
      associatedSeminar.seminarId === seminar.id;

    if (documents.status !== "success") {
      toast.error(t("Something went wrong"));
    }

    // get all associatedSeminars with id equal to current session seminar id
    const documentsLocations = (documents?.data?.data || [])
      .filter((document) => (document.associatedSeminars || []).some(isCurrentSeminar))
      .map((document) => (document.associatedSeminars || []).filter(isCurrentSeminar))
      .flat();

    // check if there is at least one document attached to session with id equal to one we want to delete
    const isDocumentAttachedToSession = documentsLocations.some((location) => {
      return !location.seminarSessions || location.seminarSessions.some((session) => session.sessionId === sessionId);
    });

    const relatedDocumentsDeletionWarning = isDocumentAttachedToSession
      ? " " + t("The related documents will also be deleted")
      : "";
    const popupMessage = t("Do you want to proceed with deletion of this session?") + relatedDocumentsDeletionWarning;

    confirm({
      title: t("confirmation"),
      content: <Typography.Text>{popupMessage}</Typography.Text>,
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: () => {
        return deleteSeminarSessionMutation.mutateAsync({
          forumId: Number(forumId),
          forumSeminarId: seminar?.id,
          forumSeminarSessionId: sessionId,
        });
      },
    });
  };

  const columns: TableProps<ForumSeminarSessionWithTimeslotSession>["columns"] = [
    {
      title: "Facility Name",
      dataIndex: "facilityName",
      sorter: true,
    },
    {
      title: "Number of Places",
      dataIndex: "places",
      sorter: true,
    },
    {
      title: "Meal Included",
      dataIndex: "includeMeal",
      sorter: true,
      render: (includeMeal: boolean) => (includeMeal ? "Yes" : "No"),
    },
    {
      title: "Speaker",
      dataIndex: "speaker",
      sorter: true,
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      sorter: true,
      render: (startTime: Date) => <DateRenderer date={startTime} showTime />,
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      sorter: true,
      render: (endTime: Date) => <DateRenderer date={endTime} showTime />,
    },
    {
      dataIndex: "id",
      render: (_, record) => (
        <div className={styles.actions}>
          <Button onClick={() => editSession(record)}>
            {t("Edit")}
          </Button>
          <DeleteFilled onClick={() => handleSessionDelete(record.id)} />
        </div>
      ),
    },
  ];

  return (
    <div className="table-wrapper">
      <SeminarSessionForm visible={isDrawerVisible} seminar={seminar} session={activeSession} onClose={hideDrawer} />
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Button onClick={showDrawer}>{t("Add New Session")}</Button>
        </Col>
        <Col span={24}>
          <Table
            id="sessionsTable"
            dataSource={sessions}
            columns={columns}
            loading={query.isLoading || documentsQuery.isFetching}
            pagination={false}
            onChange={handleSort}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
};

export default SeminarSessions;
