import { UserInfoModel } from "generated/api";

import { UserRoles } from "../types";

class UserRole {
  constructor(user: UserInfoModel) {
    this._user = user;

    this._fillMembershipSet();
  }

  _user: UserInfoModel;

  _membership: Set<string> = new Set();

  _fillMembershipSet() {
    this._membership = new Set();

    if (!this._user) {
      return;
    }

    this._user?.memberships?.forEach((entry) => {
      if (entry.forumId === null) {
        if (entry.role) {
          this._membership.add(entry.role);
        }
      }
    });
  }

  get roles() {
    return {
      SYSTEM_ADMIN: this.isSystemAdmin,
      FORUM_ADMIN: this.isForumAdmin,
    };
  }

  get isSystemAdmin() {
    return this._membership.has("Admin");
  }

  get isForumAdmin() {
    if (this.isSystemAdmin) {
      return true;
    }

    return this._membership.has("ForumAdmin");
  }

  is = (f: (roles: UserRoles) => boolean) => {
    return f(this.roles);
  };
}

export default UserRole;
