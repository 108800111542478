import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import ContextualDateRenderer from "../../../../../../components/ContextualDateRenderer";
import { Divider, Typography } from "../../../../../../components/styleguide";

import styles from "./styles.module.less";

interface ApprovalDataProps {
  approvalStatus: string | null;
  approvalStatusUpdatedBy: string | null;
  approvalStatusUpdatedAt: string | null;
  forumCode: string;
  documentDefinitionType: string;
  forumGroupName: string;
  createdBy: string;
  numberOfDocuments: number;
  createdAt: string | null;
  orderingType: string;
}

const ApprovalData: FC<ApprovalDataProps> = ({
  approvalStatus,
  approvalStatusUpdatedBy,
  approvalStatusUpdatedAt,
  forumCode,
  documentDefinitionType,
  forumGroupName,
  createdBy,
  numberOfDocuments,
  createdAt,
  orderingType,
}) => {
  const { t } = useTranslation();
  const { Title, Text } = Typography;

  const fields = [
    {
      title: "Approval status",
      content: approvalStatus ?? "-",
    },
    {
      title: "Approved by",
      content: approvalStatusUpdatedBy ?? "-",
    },
    {
      title: "Approved time",
      content: approvalStatusUpdatedAt ? <ContextualDateRenderer date={approvalStatusUpdatedAt} showTime /> : "-",
    },
    {
      title: "Forum",
      content: forumCode,
    },
    {
      title: "Type",
      content: documentDefinitionType,
    },
    {
      title: "Group",
      content: forumGroupName,
    },
    {
      title: "Queued by",
      content: createdBy,
    },
    {
      title: "Scheduled documents",
      content: numberOfDocuments,
    },
    {
      title: "Created",
      content: createdAt ? <ContextualDateRenderer date={createdAt} showTime /> : "-",
    },
    {
      title: "Ordered by",
      content: orderingType,
    },
  ];

  return (
    <div className={styles.approvalData}>
      {fields.map(({ title, content }) => (
        <div key={title}>
          <Title level={5}>{t(title)}</Title>
          <Text>{content}</Text>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default ApprovalData;
