/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumVisualSpeakerDocumentResponseModel } from '../apiModels';
// @ts-ignore
import { InvalidModelStateExceptionResponse } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { UnknownObjectExceptionResponse } from '../apiModels';
/**
 * ForumVisualSpeakerDocumentApi - axios parameter creator
 * @export
 */
export const ForumVisualSpeakerDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes visual speaker document
         * @param {number} forumId 
         * @param {number} documentId 
         * @param {string} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete: async (forumId: number, documentId: number, seminarId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete', 'forumId', forumId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete', 'documentId', documentId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete', 'seminarId', seminarId)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}/visual-speaker-document/{documentId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update  document
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {number} documentId 
         * @param {string} title 
         * @param {string} [description] 
         * @param {string} [publishedFromDate] 
         * @param {string} [publishedUntilDate] 
         * @param {Array<number>} [permissionGroupIds] 
         * @param {Array<number>} [seminarIds] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut: async (forumId: number, seminarId: number, documentId: number, title: string, description?: string, publishedFromDate?: string, publishedUntilDate?: string, permissionGroupIds?: Array<number>, seminarIds?: Array<number>, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut', 'seminarId', seminarId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut', 'documentId', documentId)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut', 'title', title)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}/visual-speaker-document/{documentId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (publishedFromDate !== undefined) { 
                localVarFormParams.append('PublishedFromDate', publishedFromDate as any);
            }
    
            if (publishedUntilDate !== undefined) { 
                localVarFormParams.append('PublishedUntilDate', publishedUntilDate as any);
            }
                if (permissionGroupIds) {
                permissionGroupIds.forEach((element) => {
                    localVarFormParams.append('PermissionGroupIds', element as any);
                })
            }

                if (seminarIds) {
                seminarIds.forEach((element) => {
                    localVarFormParams.append('SeminarIds', element as any);
                })
            }

    
            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get forum visual speaker documents
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet: async (forumId: number, seminarId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet', 'seminarId', seminarId)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}/visual-speaker-document`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload  document
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {string} title 
         * @param {string} [description] 
         * @param {string} [publishedFromDate] 
         * @param {string} [publishedUntilDate] 
         * @param {Array<number>} [permissionGroupIds] 
         * @param {Array<number>} [seminarIds] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost: async (forumId: number, seminarId: number, title: string, description?: string, publishedFromDate?: string, publishedUntilDate?: string, permissionGroupIds?: Array<number>, seminarIds?: Array<number>, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost', 'seminarId', seminarId)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost', 'title', title)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}/visual-speaker-document`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (title !== undefined) { 
                localVarFormParams.append('Title', title as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (publishedFromDate !== undefined) { 
                localVarFormParams.append('PublishedFromDate', publishedFromDate as any);
            }
    
            if (publishedUntilDate !== undefined) { 
                localVarFormParams.append('PublishedUntilDate', publishedUntilDate as any);
            }
                if (permissionGroupIds) {
                permissionGroupIds.forEach((element) => {
                    localVarFormParams.append('PermissionGroupIds', element as any);
                })
            }

                if (seminarIds) {
                seminarIds.forEach((element) => {
                    localVarFormParams.append('SeminarIds', element as any);
                })
            }

    
            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumVisualSpeakerDocumentApi - functional programming interface
 * @export
 */
export const ForumVisualSpeakerDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumVisualSpeakerDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes visual speaker document
         * @param {number} forumId 
         * @param {number} documentId 
         * @param {string} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete(forumId: number, documentId: number, seminarId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete(forumId, documentId, seminarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update  document
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {number} documentId 
         * @param {string} title 
         * @param {string} [description] 
         * @param {string} [publishedFromDate] 
         * @param {string} [publishedUntilDate] 
         * @param {Array<number>} [permissionGroupIds] 
         * @param {Array<number>} [seminarIds] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut(forumId: number, seminarId: number, documentId: number, title: string, description?: string, publishedFromDate?: string, publishedUntilDate?: string, permissionGroupIds?: Array<number>, seminarIds?: Array<number>, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumVisualSpeakerDocumentResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut(forumId, seminarId, documentId, title, description, publishedFromDate, publishedUntilDate, permissionGroupIds, seminarIds, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get forum visual speaker documents
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet(forumId: number, seminarId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumVisualSpeakerDocumentResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet(forumId, seminarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload  document
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {string} title 
         * @param {string} [description] 
         * @param {string} [publishedFromDate] 
         * @param {string} [publishedUntilDate] 
         * @param {Array<number>} [permissionGroupIds] 
         * @param {Array<number>} [seminarIds] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost(forumId: number, seminarId: number, title: string, description?: string, publishedFromDate?: string, publishedUntilDate?: string, permissionGroupIds?: Array<number>, seminarIds?: Array<number>, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumVisualSpeakerDocumentResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost(forumId, seminarId, title, description, publishedFromDate, publishedUntilDate, permissionGroupIds, seminarIds, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumVisualSpeakerDocumentApi - factory interface
 * @export
 */
export const ForumVisualSpeakerDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumVisualSpeakerDocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes visual speaker document
         * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete(requestParameters.forumId, requestParameters.documentId, requestParameters.seminarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update  document
         * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumVisualSpeakerDocumentResponseModel> {
            return localVarFp.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut(requestParameters.forumId, requestParameters.seminarId, requestParameters.documentId, requestParameters.title, requestParameters.description, requestParameters.publishedFromDate, requestParameters.publishedUntilDate, requestParameters.permissionGroupIds, requestParameters.seminarIds, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get forum visual speaker documents
         * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumVisualSpeakerDocumentResponseModel>> {
            return localVarFp.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet(requestParameters.forumId, requestParameters.seminarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload  document
         * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumVisualSpeakerDocumentResponseModel> {
            return localVarFp.forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost(requestParameters.forumId, requestParameters.seminarId, requestParameters.title, requestParameters.description, requestParameters.publishedFromDate, requestParameters.publishedUntilDate, requestParameters.permissionGroupIds, requestParameters.seminarIds, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete operation in ForumVisualSpeakerDocumentApi.
 * @export
 * @interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDeleteRequest
 */
export interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete
     */
    readonly documentId: number

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete
     */
    readonly seminarId: string
}

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut operation in ForumVisualSpeakerDocumentApi.
 * @export
 * @interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPutRequest
 */
export interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly seminarId: number

    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly documentId: number

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly title: string

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly publishedFromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly publishedUntilDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly permissionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly seminarIds?: Array<number>

    /**
     * 
     * @type {File}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut
     */
    readonly formFile?: File
}

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet operation in ForumVisualSpeakerDocumentApi.
 * @export
 * @interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGetRequest
 */
export interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet
     */
    readonly seminarId: number
}

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost operation in ForumVisualSpeakerDocumentApi.
 * @export
 * @interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPostRequest
 */
export interface ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly seminarId: number

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly title: string

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly description?: string

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly publishedFromDate?: string

    /**
     * 
     * @type {string}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly publishedUntilDate?: string

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly permissionGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly seminarIds?: Array<number>

    /**
     * 
     * @type {File}
     * @memberof ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost
     */
    readonly formFile?: File
}

/**
 * ForumVisualSpeakerDocumentApi - object-oriented interface
 * @export
 * @class ForumVisualSpeakerDocumentApi
 * @extends {BaseAPI}
 */
export class ForumVisualSpeakerDocumentApi extends BaseAPI {
    /**
     * 
     * @summary Deletes visual speaker document
     * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVisualSpeakerDocumentApi
     */
    public forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumVisualSpeakerDocumentApiFp(this.configuration).forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdDelete(requestParameters.forumId, requestParameters.documentId, requestParameters.seminarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update  document
     * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVisualSpeakerDocumentApi
     */
    public forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPutRequest, options?: AxiosRequestConfig) {
        return ForumVisualSpeakerDocumentApiFp(this.configuration).forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentDocumentIdPut(requestParameters.forumId, requestParameters.seminarId, requestParameters.documentId, requestParameters.title, requestParameters.description, requestParameters.publishedFromDate, requestParameters.publishedUntilDate, requestParameters.permissionGroupIds, requestParameters.seminarIds, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get forum visual speaker documents
     * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVisualSpeakerDocumentApi
     */
    public forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGetRequest, options?: AxiosRequestConfig) {
        return ForumVisualSpeakerDocumentApiFp(this.configuration).forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentGet(requestParameters.forumId, requestParameters.seminarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload  document
     * @param {ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVisualSpeakerDocumentApi
     */
    public forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost(requestParameters: ForumVisualSpeakerDocumentApiForumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPostRequest, options?: AxiosRequestConfig) {
        return ForumVisualSpeakerDocumentApiFp(this.configuration).forumsForumIdSeminarsSeminarIdVisualSpeakerDocumentPost(requestParameters.forumId, requestParameters.seminarId, requestParameters.title, requestParameters.description, requestParameters.publishedFromDate, requestParameters.publishedUntilDate, requestParameters.permissionGroupIds, requestParameters.seminarIds, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }
}

