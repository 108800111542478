import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useGetTimeslotQuery = getWrappedUseQuery(KEYS.GET_TIMESLOT, apiClient.TimeSlot.get);

export const useTimeslotsQuery = getWrappedUseQuery(KEYS.GET_TIMESLOTS, apiClient.TimeSlot.getAll);

export const useExportTimeSlotsQuery = getWrappedUseQuery(KEYS.TIMESLOTS_EXPORT, apiClient.TimeSlot.export);
