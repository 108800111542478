import { Col, Row, Typography } from "components/styleguide";
import { Button, Grid } from "components/styleguide/mobile";
import { Link } from "react-router-dom";

export interface LinkListProp {
  id: string;
  title: string;
  link: string;
  footerTextLeft?: string;
  footerTextMiddle?: string;
  footerTextRight?: string;
  onButtonClick?: () => void;
}

const { Text, Title } = Typography;

export default function MobileLinkList({ items }: { items: LinkListProp[] }) {
  return (
    <>
      {items.map((item) => (
        <Grid.Item key={item.id}>
          <Link style={{ color: "black" }} to={item.link}>
            <Button block={true} size="large" color="primary" onClick={item.onButtonClick}>
              <Row>
                <Col span={24}>
                  <Title style={{ color: "white" }} level={3}>
                    {item.title}
                  </Title>
                </Col>
              </Row>
              <Row gutter={16} justify={"start"}>
                <Col span={9}>
                  <Text style={{ color: "white" }}>{item.footerTextLeft}</Text>
                </Col>
                <Col span={15}>
                  <Text style={{ color: "white" }}>{item.footerTextMiddle}</Text>
                </Col>
              </Row>
            </Button>
          </Link>
        </Grid.Item>
      ))}
    </>
  );
}
