import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, ColProps, FormInstance, Modal, Row } from "antd";
import { AdminTypes } from "enums/common";
import { toNumber } from "lodash";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDeleteForumTableMutation } from "../../../../api/mutations/forumTables";
import { useDeleteTableMutation } from "../../../../api/mutations/tables";
import { Button, Divider, Drawer, Form, Input, InputNumber, Select, Tabs } from "../../../../components/styleguide";
import {
  FacilityCodeType,
  FacilityRequestModel,
  FacilityResponseModel,
  FacilityType,
  FloorModel,
} from "../../../../generated/api";
import VenueContext from "../../../../pages/venueContext";
import ComboBox from "../../../ComboBox";
import Tables from "./Tables";

import styles from "./styles.module.less";

const { TabPane } = Tabs;
const { confirm } = Modal;

type UseDeleteTableMutationOptions = Parameters<ReturnType<typeof useDeleteTableMutation>["mutate"]>[1];
type UseDeleteForumTableMutationOptions = Parameters<ReturnType<typeof useDeleteForumTableMutation>["mutate"]>[1];

interface EditFacilityProps {
  ui: AdminTypes;
  isLoading: boolean;
  facilityId: number;
  visible: boolean;
  form: FormInstance;
  onClose: () => undefined;
  onSubmit: (values: FacilityRequestModel, originalFacilityType: keyof typeof FacilityType | undefined) => undefined;
}

const EditFacility: FC<EditFacilityProps> = ({ ui, isLoading, facilityId, visible, form, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const buildingId = toNumber(useParams().buildingId);

  const venueActions = useContext(VenueContext);
  const { useFacilityQuery, deleteTableMutate, floors, onFloorAdd, onFloorDelete } = venueActions;

  const [facility, setFacility] = useState<FacilityResponseModel | null>(null);
  const [originalFacilityType, setOriginalFacilityType] = useState<keyof typeof FacilityType>();

  const [isComboboxOpen, setIsComboboxOpen] = useState(false);

  const title = facility?.code && facility.name ? `${facility.code} - ${facility.name}` : t("Edit Facility");

  const facilityQuery = useFacilityQuery(
    { buildingId, facilityId },
    {
      onSuccess: ({ data }) => {
        setFacility(data);
        form.setFieldsValue(data);
      },
      enabled: visible,
    },
  );

  const facilityTypes = useMemo(
    () =>
      (Object.keys(FacilityType) as Array<keyof typeof FacilityType>)
        .filter((key) => !["toDisplayName", "Bedroom"].includes(key))
        .map((key) => {
          return (
            <Select.Option value={FacilityType[key]} key={key}>
              {t(`facilityTypeNames.${FacilityType[key]}`)}
            </Select.Option>
          );
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const facilityTypeCodes = useMemo(
    () =>
      (Object.keys(FacilityCodeType) as Array<keyof typeof FacilityCodeType>).map((key) => {
        return (
          <Select.Option value={FacilityCodeType[key]} key={key}>
            {key}
          </Select.Option>
        );
      }),
    [],
  );

  const loadCode = () => {
    const type = form.getFieldValue("facilityType");
    form.setFieldValue("facilityCodeType", type);
  };

  const addFloor = (name: string) => {
    if (floors.find(({ name: floorName }) => floorName.trim() === name.trim())) {
      toast.error(t("This floor already exists"));

      return;
    }
    onFloorAdd(name);
  };

  const deleteFloor = (floor: FloorModel) => {
    setIsComboboxOpen(true);
    confirm({
      title: t("confirmation"),
      content: t("Do you want to delete this floor? There could be facilities assigned to this floor"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: async () => {
        setIsComboboxOpen(false);

        if (floor.name === form.getFieldValue("floor")) {
          form.setFieldValue("floor", null);
        }
        onFloorDelete(floor.id);
      },
    });
  };

  const handleSubmit = (values: FacilityRequestModel) => {
    onSubmit(values, originalFacilityType);
  };

  useEffect(() => {
    if (facility?.facilityType) {
      setOriginalFacilityType(facility?.facilityType);
    }
  }, [facility]);

  const injectedDeleteTableMutate = (
    { tableId }: { tableId: number },
    options: UseDeleteTableMutationOptions | UseDeleteForumTableMutationOptions,
  ) => {
    deleteTableMutate({ facilityId, tableId }, options);
  };

  return (
    <VenueContext.Provider value={{ ...venueActions, deleteTableMutate: injectedDeleteTableMutate }}>
      <Drawer
        title={title}
        placement="right"
        contentWrapperStyle={{ minWidth: "50%" }}
        open={visible}
        onClose={onClose}
        getContainer={false}
        destroyOnClose
      >
        <Tabs className="" hideNav={false}>
          <TabPane tab="Details" key="details">
            <Form
              id="newFacilityForm"
              className={styles.form}
              name="new-facility"
              labelCol={{ justify: "left", span: 24 } as ColProps}
              wrapperCol={{ justify: "right" } as ColProps}
              labelWrap
              layout="vertical"
              onFinish={handleSubmit}
              form={form}
            >
              <div className={styles.formLayout}>
                <div>
                  <Form.Item
                    label={t("Facility Code")}
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: t("Facility Code is required!"),
                      },
                      {
                        max: 5,
                        message: t("errors.max.facility_code", { count: 5 }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("facilityNameDescription")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("Facility Name is required!"),
                      },
                      {
                        max: 100,
                        message: t("errors.max.facility_description", { count: 100 }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label={t("facilityType")}
                    name="facilityType"
                    rules={[
                      {
                        required: true,
                        message: t("Facility Type is required!"),
                      },
                    ]}
                  >
                    <Select onChange={loadCode}>{facilityTypes}</Select>
                  </Form.Item>
                  <Form.Item
                    label={t("facilityTypeCode")}
                    name="facilityCodeType"
                    initialValue={facility?.facilityCodeType}
                    rules={[
                      {
                        required: true,
                        message: t("Facility Type Code is required!"),
                      },
                    ]}
                  >
                    <Select disabled>{facilityTypeCodes}</Select>
                  </Form.Item>
                  <Form.Item id="floor" label={t("Floor")} name="floor">
                    <ComboBox
                      stayOpen={isComboboxOpen}
                      options={floors}
                      onAdd={addFloor}
                      onDelete={deleteFloor}
                      addButtonText={t("Add Floor")}
                      intputPlaceholder={t("Floor name")}
                      placeholder={t("Choose Floor")}
                      fieldNames={{
                        key: "id",
                        label: "name",
                        value: "name",
                      }}
                      maxLength={20}
                    />
                  </Form.Item>
                  <Form.Item label={t("Places")} name="places" extra={t("Maximum of {{count}}", { count: 1000 })}>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={1}
                      max={1000}
                      // unlike the "code" input from the NewFacility component
                      // looks like here errors do not disappear when you type another value
                      onChange={() =>
                        form.setFields([
                          {
                            name: "places",
                            errors: [],
                          },
                        ])
                      }
                    />
                  </Form.Item>
                  <Form.Item label={t("numberOfTables")} name="tables">
                    <InputNumber style={{ width: "100%" }} disabled />
                  </Form.Item>
                </div>
                <div>
                  <Divider />
                  <Row justify="space-between">
                    <Col>
                      <Button onClick={onClose}>{t("Cancel")}</Button>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" loading={facilityQuery.isLoading || isLoading}>
                          {t("Save")}
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </TabPane>
          <TabPane tab="Tables" key="tables">
            <Tables ui={ui} facility={facility} />
          </TabPane>
        </Tabs>
      </Drawer>
    </VenueContext.Provider>
  );
};
export default EditFacility;
