import { Button, Col, Layout, Row } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import ParticipationCodesDrawer from "./components/ParticipationCodesDrawer";
import ParticipationCodesTable from "./components/ParticipationCodesTable";

import styles from "./styles.module.less";

const { Header, Content } = Layout;

const ParticipationCodes = () => {
  const { t } = useTranslation();

  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  const onClose = () => {
    setIsDrawerVisible(false);
  };

  return (
    <div className={styles.participationCodesPage}>
      <Layout>
        <Header className={styles.header}>
          <Row id="participationCodeControls" gutter={16}>
            <Col>
              <Button onClick={() => setIsDrawerVisible(true)}>{t("Add Code")}</Button>
            </Col>
          </Row>
        </Header>
        <Content>
          <UserGuide.TableTitle
            className={styles.container}
            articleId="16426453978269-participation-codes"
            title="Participation Codes"
          >
            <ParticipationCodesTable className="participation-codes-table" />
          </UserGuide.TableTitle>
          <ParticipationCodesDrawer visible={isDrawerVisible} onClose={onClose} />
        </Content>
      </Layout>
    </div>
  );
};

export default ParticipationCodes;
