import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Modal, Typography } from "antd";
import { useDeleteSelectionOptionMutation } from "api/mutations/forumAttendeePreferences";
import KEYS from "api/queries/keys";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface DeleteSelectionOptionButtonProps {
  selectionOptionId: number;
}

const DeleteSelectionOptionButton: FC<DeleteSelectionOptionButtonProps> = ({ selectionOptionId }) => {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const { mutate: deleteSelectionOptionMutate } = useDeleteSelectionOptionMutation();

  const deleteSelectionOption = () => {
    deleteSelectionOptionMutate(
      {
        forumId,
        attendeeId,
        selectionChoiceId: selectionOptionId,
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS]),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onDelete = () => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with deletion of this selection option?")}</Typography.Text>,
      closable: true,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: () => deleteSelectionOption(),
    });
  };

  return <DeleteFilled style={{ fontSize: "large" }} onClick={onDelete} />;
};

export default DeleteSelectionOptionButton;
