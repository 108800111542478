import { Col, Row, Typography } from "components/styleguide";
import { ForumAttendeesInformation } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import SummaryListItem from "./SummaryListItem";

import styles from "./styles.module.less";

interface StatisticsProps {
  summary: ForumAttendeesInformation;
}

const Statistics: FC<StatisticsProps> = ({ summary }) => {
  const { t } = useTranslation();
  const { Title } = Typography;
  const { confirmedAttendeeCount, delegatesInformation, speakersInformation, suppliersInformation, otherInformation } =
    summary;

  const summaryStats = [
    {
      title: "Suppliers",
      stats: suppliersInformation,
    },
    {
      title: "Delegates",
      stats: delegatesInformation,
    },
    {
      title: "Speakers",
      stats: speakersInformation,
    },
    {
      title: "Other attendees",
      stats: otherInformation,
    },
  ];

  return (
    <div className={styles.statsContainer}>
      <Row>
        <Col span={24}>
          <Title level={4}>{t("Confirmed Attendees:", { count: confirmedAttendeeCount ?? 0 })}</Title>
        </Col>
      </Row>
      <Row className={styles.statsList} justify="start" gutter={30}>
        {summaryStats.map((summaryItem) => (
          <Col key={summaryItem.title} lg={6} md={12} sm={24}>
            <SummaryListItem {...summaryItem} />
          </Col>
        ))}
        <Col span={4}></Col>
      </Row>
    </div>
  );
};

export default Statistics;
