import { useQueryClient } from "@tanstack/react-query";
import { FormInstance, FormRule } from "antd";

import getUniqueKeys from "../../../../../../../../api/helpers/getUniqueKeys";
import KEYS from "../../../../../../../../api/queries/keys";
import apiClient from "../../../../../../../../api-client";

type ReturnType = (form: FormInstance, field: string) => FormRule;
const useQuestionnaireVersionValidator = (forumId: number): ReturnType => {
  const queryClient = useQueryClient();

  const validator = (form: FormInstance, field: string) => async (rule: FormRule, questionnaireId: number) => {
    if (!questionnaireId) {
      return Promise.resolve();
    }

    const currentVersion = form.getFieldValue(field);
    const params = {
      forumId: forumId,
      questionnaireId: questionnaireId,
    };

    const query = await queryClient.fetchQuery({
      queryKey: [KEYS.GET_QUESTIONNAIRE, ...getUniqueKeys(params)],
      queryFn: () => apiClient.ForumQuestionnaires.get(params),
    });

    const lastVersion = query.data.contentVersion;

    if (currentVersion !== lastVersion) {
      return Promise.reject("Current version and last one are different");
    }

    return Promise.resolve();
  };

  return (form, field) => ({ validator: validator(form, field) });
};

export default useQuestionnaireVersionValidator;
