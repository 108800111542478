import { useExportForumCompanyPackages } from "api/queries/forumCompanies";
import DropdownButton from "components/DropdownButton";
import { FileExportType } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

const ExportSupplierPackageButton: FC = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [documentType, setDocumentType] = useState<FileExportType | null>(null);

  const { isFetching: isSupplierPackageExportLoading } = useExportForumCompanyPackages(
    {
      forumId,
      exportType: documentType as FileExportType,
    },
    {
      enabled: !!documentType,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
      onSettled: () => setDocumentType(null),
    },
  );

  const items = [
    {
      label: t("Excel"),
      key: "excel",
      onClick: () => setDocumentType(FileExportType.Xls),
    },
    {
      label: t("CSV"),
      key: "csv",
      onClick: () => setDocumentType(FileExportType.Csv),
    },
  ];

  return <DropdownButton title="Supplier Package Export" items={items} isLoading={isSupplierPackageExportLoading} />;
};

export default ExportSupplierPackageButton;
