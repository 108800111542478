import { Button } from "components/styleguide";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.less";

const NotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <h1>404</h1>
      <p>{t("Page Not Found")}</p>
      <p>{t("The page you are looking for doesn't exist or an other error occurred")}</p>
      <Button onClick={() => navigate("..")}>{t("Go to Home Page")}</Button>
    </div>
  );
};

export default NotFound;
