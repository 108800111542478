import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumDocumentCustomPageElementMutation } from "api/mutations/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Select, Typography } from "components/styleguide";
import TextEditor from "components/TextEditor";
import {
  CustomPageElementAlignments,
  CustomPageElementTypes,
  ForumDocumentCustomPageElementUpdateModel,
} from "generated/api";
import { toNumber } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { validateMergeTags } from "utils/validatorUtils";

import { CustomPageElement } from "../index";

const mergeTagsRegex = /\[.*?\]/g;

interface EditElementDrawerProps {
  isDrawerVisible: boolean;
  onClose: () => void;
  mergeTags?: string[];
  forumId: number;
  customPageId: number;
  pageName?: string;
  element?: CustomPageElement;
}

const EditElementDrawer: React.FC<EditElementDrawerProps> = ({
  onClose,
  isDrawerVisible,
  forumId,
  customPageId,
  pageName,
  mergeTags,
  element,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const mutation = useUpdateForumDocumentCustomPageElementMutation();

  const elementTypeOptions = Object.values(CustomPageElementTypes).map((type) => ({
    label: type,
    value: type,
  }));

  const alignmentOptions = Object.values(CustomPageElementAlignments).map((type) => ({
    label: type,
    value: type,
  }));

  const submit = (values: ForumDocumentCustomPageElementUpdateModel) => {
    mutation.mutate(
      {
        forumId,
        customPageId,
        elementId: toNumber(element?.id),
        forumDocumentCustomPageElementUpdateModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE_ID]);
          onClose();
        },
        onError: () => {
          toast.error(t("Error"));
        },
      },
    );
  };

  return (
    <Drawer
      title={t("Edit Element")}
      placement="right"
      onClose={onClose}
      open={isDrawerVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <FullHeightForm
        onFinish={submit}
        layout="vertical"
        actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
        actionsAppend={
          <Button htmlType="submit" type="primary" loading={mutation.isLoading}>
            {t("Update")}
          </Button>
        }
      >
        <Form.Item label={t("Page Name")}>
          <Typography.Text>{pageName}</Typography.Text>
        </Form.Item>

        <Form.Item label={t("Type")} name="type" initialValue={element?.type}>
          <Select style={{ width: "40%" }} options={elementTypeOptions}></Select>
        </Form.Item>

        <Form.Item label={t("Alignment")} name="alignment" initialValue={element?.alignment}>
          <Select style={{ width: "40%" }} options={alignmentOptions}></Select>
        </Form.Item>
        <Form.Item
          initialValue={element?.value}
          name="value"
          rules={[
            {
              validator: validateMergeTags(mergeTagsRegex, t, mergeTags),
            },
          ]}
        >
          <TextEditor mergeTags={mergeTags} showListFormats />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default EditElementDrawer;
