import { TableProps } from "antd";
import { Button } from "components/styleguide";
import Actions from "components/styleguide/components/Actions";
import Table from "components/styleguide/components/Table";
import { EventOptionsChoice } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { removeAt, replaceAt } from "utils/arrayUtils";

import EditChoice from "../EditChoice";

interface ListingTableProps {
  choices: Array<EventOptionsChoice>;
  onChange?: (value: Array<EventOptionsChoice>) => void;
}

const ListingTable: FC<ListingTableProps> = ({ choices, onChange }) => {
  const { t } = useTranslation();

  const handleChoiceChange = (index: number, choice: Partial<EventOptionsChoice>) => {
    const newChoice = { ...choices[index], ...choice };
    onChange?.(replaceAt(choices, index, newChoice));
  };

  const handleDelete = (index: number) => {
    onChange?.(removeAt(choices, index));
  };

  const columns: TableProps<EventOptionsChoice>["columns"] = [
    {
      dataIndex: "tardisLink",
      render: (_, choice, index) => (
        <EditChoice value={choice} onChange={(newChoice) => handleChoiceChange(index, newChoice)} />
      ),
      width: 50,
    },
    {
      dataIndex: "name",
      title: t("Choice Name"),
      width: "25%",
    },
    {
      dataIndex: "title",
      title: t("Choice Title Text"),
      width: "25%",
    },
    {
      dataIndex: "body",
      title: t("Choice Body Text"),
      width: "25%",
    },
    {
      dataIndex: "tardisLink",
      title: t("Choice Tardis Link"),
      width: "25%",
    },
    {
      dataIndex: "tardisLink",
      render: (_, __, index) => (
        <Actions horizontalAlign="right">
          <Button type="primary" onClick={() => handleDelete(index)}>
            {t("Delete")}
          </Button>
        </Actions>
      ),
      width: 100,
    },
  ];

  return <Table columns={columns} dataSource={choices} rowKey="tardisLink" pagination={false} />;
};

export default ListingTable;
