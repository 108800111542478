import { arrayMove } from "@dnd-kit/sortable";
import { useCreateTimeslotMutation } from "api/mutations/timeslots";
import { useGetForumByIdQuery } from "api/queries/forums";
import Button from "components/styleguide/components/Button";
import Divider from "components/styleguide/components/Divider";
import Form from "components/styleguide/components/Form";
import Input from "components/styleguide/components/Input";
import Select from "components/styleguide/components/Select";
import TimeType from "enums/TimeType";
import { toNumber } from "lodash";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

import ForumUtcOffsetContext from "../../../contexts/forumUtcOffset";
import SessionTable from "../EditTimeslot/SessionsTable";

const { Option } = Select;

const NewTimeSlot = () => {
  const { t } = useTranslation();

  const forumOffsetString = useContext(ForumUtcOffsetContext);

  const isEditing = (record) => record.order === editingKey;

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { id } = useOutletContext();

  const [formLayout] = useState("inline");
  const [forum, setForum] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [disableOrdering, setDisableOrdering] = useState(false);

  const { mutate: createTimeslot, isLoading: isCreateTimeslotLoading } = useCreateTimeslotMutation();

  const handleSubmit = (values) => {
    const request = values;
    request.sessions = sessions.map((session) => {
      return {
        ...session,
        startTime: moment(session.startTime).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: moment(session.endTime).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });

    createTimeslot(
      {
        forumId: id,
        timeSlotRequestModel: request,
      },
      {
        onSuccess: () => navigate("../"),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const handleSessionAdd = () => {
    const newSession = {
      id: 0,
      order: sessions.length + 1,
      startTime: forum.startDate,
    };

    setSessions([...sessions, newSession]);
    setEditingKey(newSession.order);
  };

  const handleSessionSave = async (record) => {
    try {
      const row = await form.validateFields();
      const values = {
        startTime: moment(row.duration[0]).format("YYYY-MM-DDTHH:mm:ss"),
        endTime: moment(row.duration[1]).format("YYYY-MM-DDTHH:mm:ss"),
      };

      const newData = [...sessions];
      const index = newData.findIndex((item) => record.order === item.order);
      const item = newData[index];
      newData.splice(index, 1, { ...item, ...values });
      setSessions(newData);
      setEditingKey("");
    } catch (e) {
      console.error(e);
    }
  };

  const handleSessionEdit = (record) => {
    form.setFieldsValue({
      order: "",
      startTime: "",
      endTime: "",
      ...record,
    });
    setEditingKey(record.order);
  };

  const handleSessionCancel = (record) => {
    if (!record.startTime && !record.endTime) {
      const newData = sessions.filter((item) => item.order !== record.order);
      setSessions(newData);
    }
    setEditingKey("");
  };

  const handleSessionDelete = (session) => {
    const newData = sessions.filter((item) => item.order !== session.order);
    setSessions(newData);
    setEditingKey("");
  };

  const timeTypes = Object.keys(TimeType)
    .filter((key) => key !== "toDisplayName")
    .map((key) => {
      return (
        <Option value={TimeType[key]} key={key}>
          {t(`timeTypes.${TimeType.toDisplayName(TimeType[key])}`)}
        </Option>
      );
    });

  const { isLoading } = useGetForumByIdQuery(
    { forumId: toNumber(id) },
    {
      onSuccess: ({ data }) => {
        setForum(data);
        if (sessions.length === 0) {
          const defaultSession = { id: 0, order: 1, startTime: data.startDate };
          setSessions([defaultSession]);
          handleSessionEdit(defaultSession);
        }
      },
      StaleTime: Infinity,
      CacheTime: Infinity,
    },
  );

  const handleSessionReorder = (event) => {
    const oldIndex = sessions.findIndex((item) => item.order === event.active.id);
    const newIndex = sessions.findIndex((item) => item.order === event.over.id);
    const updatedSessions = arrayMove(sessions, oldIndex, newIndex);

    updatedSessions.forEach((item, index) => {
      item.order = index + 1;
    });

    setSessions(updatedSessions);
  };

  return (
    <div>
      <Form onFinish={handleSubmit} id="new-timeslot-form" layout={formLayout}>
        <Form.Item
          label={t("description")}
          name="description"
          rules={[
            {
              required: true,
              message: t("errors.required", {
                prop: "$t(timeslotDescription)",
              }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("timeType")}
          name="timeSlotType"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(timeType)" }),
            },
          ]}
        >
          <Select style={{ width: 200 }}>{timeTypes}</Select>
        </Form.Item>
        <Form.Item>
          <Button
            htmlType="submit"
            type="primary"
            loading={isCreateTimeslotLoading}
            disabled={!!editingKey || isCreateTimeslotLoading}
          >
            {t("add")}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <div className="section">
        <SessionTable
          form={form}
          data={sessions}
          defaultTimezone={forumOffsetString}
          onAdd={handleSessionAdd}
          onEdit={handleSessionEdit}
          isEditing={isEditing}
          onSave={handleSessionSave}
          onDelete={handleSessionDelete}
          onCancel={handleSessionCancel}
          onDragEnd={handleSessionReorder}
          setDisableOrdering={setDisableOrdering}
          disableOrdering={disableOrdering}
          editingKey={editingKey}
          loading={isLoading}
          isSaveLoading={false}
        />
      </div>
    </div>
  );
};

export default NewTimeSlot;
