import { TableApi } from "./../../generated/api/apiPackage/table-api";
import { initAPIClient } from "./_base";

const api = initAPIClient(TableApi);

const Table = {
  get: api.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdGet,
  put: api.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdPut,
  delete: api.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdTablesTableIdDelete,
};

export default Table;
