import { Checkbox, Col, Row, Typography } from "components/styleguide";
import { List } from "components/styleguide/mobile";

import { useItemCard } from "../hooks";
import { ItemCardDesktopProps } from "../types";

import styles from "../styles.module.less";

export const ItemCardDesktop = ({
  key,
  title,
  subTitle1,
  subTitle2,
  checkedIn,
  onCheckboxChange,
}: ItemCardDesktopProps) => {
  const { getCardClassByAttendeeStatus } = useItemCard();
  return (
    <List.Item key={key} className={getCardClassByAttendeeStatus(checkedIn)}>
      <Row align={"middle"}>
        <Col span={1}>
          <Checkbox checked={!!checkedIn} onChange={onCheckboxChange} />
        </Col>
        <Col>
          <Row>
            <Col>
              <Typography.Title level={4} className={styles.text}>
                {title}
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text type="secondary" className={styles.text}>
                {subTitle1}
              </Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Text type="secondary" className={styles.text}>
                {subTitle2}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </List.Item>
  );
};
