/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumITContractItemRequestModel } from '../apiModels';
// @ts-ignore
import { ForumITContractItemResponseModel } from '../apiModels';
// @ts-ignore
import { ForumITContractResponseModel } from '../apiModels';
// @ts-ignore
import { ForumITContractStatusRequestModel } from '../apiModels';
// @ts-ignore
import { InvalidEntityStateExceptionResponse } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { UnsupportedFormatExceptionResponse } from '../apiModels';
/**
 * ForumITContractApi - axios parameter creator
 * @export
 */
export const ForumITContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exports Forum IT Contract items to file
         * @param {number} forumId 
         * @param {string} [format] Format of output file. Default: pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractExportGet: async (forumId: number, format?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItContractExportGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/it-contract/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Forum IT Contract approval information
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItContractGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/it-contract`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractItemsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItContractItemsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/it-contract/items`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} itemId 
         * @param {ForumITContractItemRequestModel} [forumITContractItemRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractItemsItemIdPut: async (forumId: number, itemId: number, forumITContractItemRequestModel?: ForumITContractItemRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItContractItemsItemIdPut', 'forumId', forumId)
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('forumsForumIdItContractItemsItemIdPut', 'itemId', itemId)
            const localVarPath = `/forums/{forumId}/it-contract/items/{itemId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumITContractItemRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Approve or reject Forum IT Contract
         * @param {number} forumId 
         * @param {ForumITContractStatusRequestModel} [forumITContractStatusRequestModel] Requested status should be Approved or Rejected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractPut: async (forumId: number, forumITContractStatusRequestModel?: ForumITContractStatusRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdItContractPut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/it-contract`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumITContractStatusRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumITContractApi - functional programming interface
 * @export
 */
export const ForumITContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumITContractApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exports Forum IT Contract items to file
         * @param {number} forumId 
         * @param {string} [format] Format of output file. Default: pdf
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItContractExportGet(forumId: number, format?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItContractExportGet(forumId, format, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Forum IT Contract approval information
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItContractGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumITContractResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItContractGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItContractItemsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumITContractItemResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItContractItemsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} itemId 
         * @param {ForumITContractItemRequestModel} [forumITContractItemRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItContractItemsItemIdPut(forumId: number, itemId: number, forumITContractItemRequestModel?: ForumITContractItemRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItContractItemsItemIdPut(forumId, itemId, forumITContractItemRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Approve or reject Forum IT Contract
         * @param {number} forumId 
         * @param {ForumITContractStatusRequestModel} [forumITContractStatusRequestModel] Requested status should be Approved or Rejected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdItContractPut(forumId: number, forumITContractStatusRequestModel?: ForumITContractStatusRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdItContractPut(forumId, forumITContractStatusRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumITContractApi - factory interface
 * @export
 */
export const ForumITContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumITContractApiFp(configuration)
    return {
        /**
         * 
         * @summary Exports Forum IT Contract items to file
         * @param {ForumITContractApiForumsForumIdItContractExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractExportGet(requestParameters: ForumITContractApiForumsForumIdItContractExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumsForumIdItContractExportGet(requestParameters.forumId, requestParameters.format, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Forum IT Contract approval information
         * @param {ForumITContractApiForumsForumIdItContractGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractGet(requestParameters: ForumITContractApiForumsForumIdItContractGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumITContractResponseModel> {
            return localVarFp.forumsForumIdItContractGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumITContractApiForumsForumIdItContractItemsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractItemsGet(requestParameters: ForumITContractApiForumsForumIdItContractItemsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumITContractItemResponseModel>> {
            return localVarFp.forumsForumIdItContractItemsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumITContractApiForumsForumIdItContractItemsItemIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractItemsItemIdPut(requestParameters: ForumITContractApiForumsForumIdItContractItemsItemIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItContractItemsItemIdPut(requestParameters.forumId, requestParameters.itemId, requestParameters.forumITContractItemRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Approve or reject Forum IT Contract
         * @param {ForumITContractApiForumsForumIdItContractPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdItContractPut(requestParameters: ForumITContractApiForumsForumIdItContractPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdItContractPut(requestParameters.forumId, requestParameters.forumITContractStatusRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdItContractExportGet operation in ForumITContractApi.
 * @export
 * @interface ForumITContractApiForumsForumIdItContractExportGetRequest
 */
export interface ForumITContractApiForumsForumIdItContractExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumITContractApiForumsForumIdItContractExportGet
     */
    readonly forumId: number

    /**
     * Format of output file. Default: pdf
     * @type {string}
     * @memberof ForumITContractApiForumsForumIdItContractExportGet
     */
    readonly format?: string
}

/**
 * Request parameters for forumsForumIdItContractGet operation in ForumITContractApi.
 * @export
 * @interface ForumITContractApiForumsForumIdItContractGetRequest
 */
export interface ForumITContractApiForumsForumIdItContractGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumITContractApiForumsForumIdItContractGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdItContractItemsGet operation in ForumITContractApi.
 * @export
 * @interface ForumITContractApiForumsForumIdItContractItemsGetRequest
 */
export interface ForumITContractApiForumsForumIdItContractItemsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumITContractApiForumsForumIdItContractItemsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdItContractItemsItemIdPut operation in ForumITContractApi.
 * @export
 * @interface ForumITContractApiForumsForumIdItContractItemsItemIdPutRequest
 */
export interface ForumITContractApiForumsForumIdItContractItemsItemIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumITContractApiForumsForumIdItContractItemsItemIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumITContractApiForumsForumIdItContractItemsItemIdPut
     */
    readonly itemId: number

    /**
     * 
     * @type {ForumITContractItemRequestModel}
     * @memberof ForumITContractApiForumsForumIdItContractItemsItemIdPut
     */
    readonly forumITContractItemRequestModel?: ForumITContractItemRequestModel
}

/**
 * Request parameters for forumsForumIdItContractPut operation in ForumITContractApi.
 * @export
 * @interface ForumITContractApiForumsForumIdItContractPutRequest
 */
export interface ForumITContractApiForumsForumIdItContractPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumITContractApiForumsForumIdItContractPut
     */
    readonly forumId: number

    /**
     * Requested status should be Approved or Rejected
     * @type {ForumITContractStatusRequestModel}
     * @memberof ForumITContractApiForumsForumIdItContractPut
     */
    readonly forumITContractStatusRequestModel?: ForumITContractStatusRequestModel
}

/**
 * ForumITContractApi - object-oriented interface
 * @export
 * @class ForumITContractApi
 * @extends {BaseAPI}
 */
export class ForumITContractApi extends BaseAPI {
    /**
     * 
     * @summary Exports Forum IT Contract items to file
     * @param {ForumITContractApiForumsForumIdItContractExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumITContractApi
     */
    public forumsForumIdItContractExportGet(requestParameters: ForumITContractApiForumsForumIdItContractExportGetRequest, options?: AxiosRequestConfig) {
        return ForumITContractApiFp(this.configuration).forumsForumIdItContractExportGet(requestParameters.forumId, requestParameters.format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Forum IT Contract approval information
     * @param {ForumITContractApiForumsForumIdItContractGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumITContractApi
     */
    public forumsForumIdItContractGet(requestParameters: ForumITContractApiForumsForumIdItContractGetRequest, options?: AxiosRequestConfig) {
        return ForumITContractApiFp(this.configuration).forumsForumIdItContractGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumITContractApiForumsForumIdItContractItemsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumITContractApi
     */
    public forumsForumIdItContractItemsGet(requestParameters: ForumITContractApiForumsForumIdItContractItemsGetRequest, options?: AxiosRequestConfig) {
        return ForumITContractApiFp(this.configuration).forumsForumIdItContractItemsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumITContractApiForumsForumIdItContractItemsItemIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumITContractApi
     */
    public forumsForumIdItContractItemsItemIdPut(requestParameters: ForumITContractApiForumsForumIdItContractItemsItemIdPutRequest, options?: AxiosRequestConfig) {
        return ForumITContractApiFp(this.configuration).forumsForumIdItContractItemsItemIdPut(requestParameters.forumId, requestParameters.itemId, requestParameters.forumITContractItemRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Approve or reject Forum IT Contract
     * @param {ForumITContractApiForumsForumIdItContractPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumITContractApi
     */
    public forumsForumIdItContractPut(requestParameters: ForumITContractApiForumsForumIdItContractPutRequest, options?: AxiosRequestConfig) {
        return ForumITContractApiFp(this.configuration).forumsForumIdItContractPut(requestParameters.forumId, requestParameters.forumITContractStatusRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

