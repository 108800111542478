import { ItineraryBookletsDocumentTestApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ItineraryBookletsDocumentTestApi);

const ItineraryBookletsDocumentTest = {
  get: api.forumsForumIdBookletDocumentTestGet,
};

export default ItineraryBookletsDocumentTest;
