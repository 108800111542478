import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useUpdateZoomMappingsTableMappingsMutation = getWrappedUseMutation(
  apiClient.ZoomMappings.tableMappings.put,
);

export const useUpdateZoomMappingsSessionMappingsMutation = getWrappedUseMutation(
  apiClient.ZoomMappings.sessionMappings.put,
);

export const useDeleteZoomMappingsSessionMappingsMutation = getWrappedUseMutation(
  apiClient.ZoomMappings.sessionMappings.delete,
);
