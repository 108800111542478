import apiClient from "api-client";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useOnSiteAvailableAttendeesQuery = getWrappedUseQuery(
  KEYS.GET_ON_SITE_AVAILABLE_ATTENDEES,
  apiClient.AttendeeOnSiteMeetings.availableAttendees.get,
);

export const useOnSiteAvailablePreferencesQuery = getWrappedUseQuery(
  KEYS.GET_ON_SITE_AVAILABLE_PREFERENCES,
  apiClient.AttendeeOnSiteMeetings.availablePreferences.get,
);
