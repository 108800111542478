import { ExclamationCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteAttendeeItinerary } from "api/mutations/attendees";
import { useAttendeeItineraryQuery, useExportItinerary } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Modal, Space, Table, Typography } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import TimeType from "enums/TimeType";
import ErrorTypes from "error-handling/errorTypes";
import {
  AttendeeItineraryItemResponseModel,
  AttendeeItineraryRemovalReasonType,
  AttendeeItinerarySpeakerDeleteOptions,
  TimeSlotType,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";
import { TableColumn } from "utils/tableColumnUtils";

import AttendeeItineraryDeleteOptions from "./AttendeeItineraryDeleteOptions";
import MeetingsMoveDrawer from "./MeetingsMoveDrawer";
import RemoveConfirmationPopup from "./RemoveConfirmationPopup";
import SeminarSessionsMoveDrawer from "./SeminarSessionsMoveDrawer";

import styles from "../styles.module.less";

const { info } = Modal;

const timeFormat = "HH:mm";

export default function ItineraryTab() {
  const { t } = useTranslation();
  const { id: forumId, attendeeId } = useParams();
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedItinerary, setSelectedItinerary] = useState<AttendeeItineraryItemResponseModel | null>(null);
  const [isSeminarSessionsMoveDrawerVisible, setIsSeminarSessionsMoveDrawerVisible] = useState<boolean>(false);
  const [isMeetingsMoveDrawerVisible, setIsMeetingsMoveDrawerVisible] = useState<boolean>(false);
  const [removalReason, setRemovalReason] = useState<string>();
  const [otherText, setOtherText] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | undefined>();

  const showDeleteModal = (id: number) => {
    setIdToDelete(id);
    setIsModalOpen(true);
  };

  const hideDeleteModal = () => {
    setIdToDelete(undefined);
    setRemovalReason(AttendeeItineraryRemovalReasonType.NoneSpecified);
    setOtherText(undefined);
    setIsModalOpen(false);
  };

  const showSeminarSessionsMoveDrawer = (itinerary: AttendeeItineraryItemResponseModel) => {
    setSelectedItinerary(itinerary);
    setIsSeminarSessionsMoveDrawerVisible(true);
  };

  const showMeetingsMoveDrawer = (itinerary: AttendeeItineraryItemResponseModel) => {
    setSelectedItinerary(itinerary);
    setIsMeetingsMoveDrawerVisible(true);
  };

  const hideSeminarSessionsMoveDrawer = () => {
    setSelectedItinerary(null);
    setIsSeminarSessionsMoveDrawerVisible(false);
  };

  const hideMeetingsMoveDrawer = () => {
    setSelectedItinerary(null);
    setIsMeetingsMoveDrawerVisible(false);
  };

  const {
    response: { isLoading, data: { data: { items: itineraries = [] } = {} } = {} },
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeeItineraryQuery,
    {
      onSuccess: () => {
        setErrorMessage("");
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: ({ response }: any) => {
        if (ErrorTypes.isOfType(response, ErrorTypes.ServiceCallException)) {
          setErrorMessage(
            t("Error while calling internal service {{service}}", {
              service: response.data?.meta?.ServiceName,
            }),
          );
        }
        if (ErrorTypes.isOfType(response, ErrorTypes.ObjectNotFound)) {
          setErrorMessage(
            t("{{entity}} not found", {
              entity: response.data?.meta?.ObjectType,
            }),
          );
        }
      },
    },
    {
      forumId,
      attendeeId,
    },
  );

  const deleteItinerary = useDeleteAttendeeItinerary();

  const exportItinerary = useExportItinerary({
    onSuccess: ({ data }: { data: string }) => {
      downloadFileByURL(data);
    },
    onError: (error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const eMessage = error?.response?.data?.Message || error.message;
      const genericError = t("An error occurred while exporting the item.");
      const message = `${genericError} ${eMessage}`;
      toast.error(message);
    },
  });

  const handleExportItinerary = () => {
    exportItinerary.mutate({ forumId: toNumber(forumId), attendeeId: toNumber(attendeeId) });
  };

  const handleDeleteItinerary = useCallback(
    async (itineraryId: number, deleteOption?: AttendeeItinerarySpeakerDeleteOptions) => {
      await deleteItinerary.mutateAsync(
        {
          forumId: toNumber(forumId),
          attendeeId: toNumber(attendeeId),
          itineraryId,
          attendeeItinerarySpeakerDeleteOptions: deleteOption,
          attendeeItineraryRemovalReasonType: removalReason as AttendeeItineraryRemovalReasonType,
          otherText,
        },
        { onSuccess: () => hideDeleteModal() },
      );
    },
    [attendeeId, deleteItinerary, forumId, removalReason, queryClient],
  );

  const onItineraryRemoveClick = (record: AttendeeItineraryItemResponseModel) => {
    if (record.timeSlotType === TimeSlotType.Conference && record.isSpeaker) {
      info({
        title: t("Please select what should be removed"),
        content: <AttendeeItineraryDeleteOptions record={record} onDelete={handleDeleteItinerary} />,
        icon: <InfoCircleOutlined />,
        okText: t("Cancel"),
        okType: "ghost",
        zIndex: 2001,
        maskStyle: { zIndex: 2000 },
        closable: true,
      });
    } else {
      showDeleteModal(record.id);
    }
  };

  const columns = useMemo(
    () => [
      new TableColumn(t("Date"), "date", {
        render: (date: string) => date && moment(date).format("D MMMM YYYY"),
      }),
      new TableColumn(t("Priority"), "priority"),
      new TableColumn(t("Timeslot"), "timeSlotName", {
        render: (_: string, { timeSlotName, startTime, endTime }: AttendeeItineraryItemResponseModel) => (
          <>
            <Typography.Title className={styles.title} level={5}>
              {timeSlotName || "-"}
            </Typography.Title>
            <Typography.Text>
              {moment(startTime).format(timeFormat)} - {moment(endTime).format(timeFormat)}
            </Typography.Text>
          </>
        ),
      }),
      new TableColumn(t("Type"), "timeSlotType", {
        width: "15%",
        render: (timeSlotType: TimeSlotType) => {
          return t(`timeTypes.${TimeType.toDisplayName(TimeType[timeSlotType])}`);
        },
      }),
      new TableColumn(t("Description"), "description"),
      new TableColumn(t("Facility"), "facility"),
      new TableColumn(t("Table"), "tableNumber"),
      new TableColumn(t("Ratio"), "ratio"),
      new TableColumn("", "id", {
        render: (_: number, record: AttendeeItineraryItemResponseModel) => (
          <TableRowActionButtons
            onEdit={() => {
              if (record.timeSlotType === TimeSlotType.Conference) {
                showSeminarSessionsMoveDrawer(record);
              } else {
                showMeetingsMoveDrawer(record);
              }
            }}
            onDelete={() => onItineraryRemoveClick(record)}
          />
        ),
      }),
    ],
    [t, onItineraryRemoveClick],
  );

  return (
    <div className={styles.itinerarySection}>
      <Modal
        title={
          <Space fullWidth={true}>
            <ExclamationCircleOutlined style={{ color: "#faad14", fontSize: "22px" }} />
            <span> {t("confirmation")}</span>
          </Space>
        }
        open={isModalOpen}
        confirmLoading={deleteItinerary.isLoading}
        onCancel={() => hideDeleteModal()}
        onOk={async () => {
          await handleDeleteItinerary(idToDelete as number);
          await queryClient.resetQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          await queryClient.resetQueries([KEYS.GET_ATTENDEE_REMOVED_ITINERARIES]);
        }}
      >
        <RemoveConfirmationPopup
          reason={removalReason}
          text={otherText}
          onSelect={setRemovalReason}
          onTextChange={setOtherText}
        />
      </Modal>
      {errorMessage && !isLoading && <Typography.Text className={styles.error}>{errorMessage}</Typography.Text>}
      {!errorMessage && !isLoading && (
        <div>
          <Button
            loading={exportItinerary.isLoading}
            disabled={exportItinerary.isLoading}
            onClick={() => handleExportItinerary()}
          >
            {t("Export")}
          </Button>
        </div>
      )}

      {selectedItinerary && (
        <>
          <SeminarSessionsMoveDrawer
            itineraryId={selectedItinerary.id}
            isSpeaker={!!selectedItinerary.isSpeaker}
            timeSlotSessionId={selectedItinerary.timeSlotSessionId}
            timeSlotType={selectedItinerary.timeSlotType}
            facilityId={selectedItinerary.forumFacilityId}
            seminarId={selectedItinerary.seminarId}
            isVisible={isSeminarSessionsMoveDrawerVisible}
            onClose={hideSeminarSessionsMoveDrawer}
          />
          <MeetingsMoveDrawer
            itineraryId={selectedItinerary.id}
            timeSlotType={selectedItinerary.timeSlotType}
            tableSupportsJoint={!!selectedItinerary.tableSupportsJoint}
            isVisible={isMeetingsMoveDrawerVisible}
            onClose={hideMeetingsMoveDrawer}
          />
        </>
      )}

      <Table
        id="itineraries-table"
        dataSource={itineraries}
        columns={columns}
        pagination={pagination}
        bordered={true}
        loading={isLoading}
        rowKey="id"
      />
    </div>
  );
}
