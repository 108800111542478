import { useQuery } from "@tanstack/react-query";
import api from "api";
import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

const getEmailTemplates = "get_email_templates";
const getEmailTemplateDetails = "get_email_template_details";
const getDefaultTemplates = "get_default_templates";

const emailQueryKeys = {
  getTemplates: () => [getEmailTemplates],
  getDefaultTemplates: () => [getDefaultTemplates],
  getEmailTemplateDetails: (templateId) => [getEmailTemplateDetails, templateId],
};

const useGetEmailTemplatesQuery = (options = {}) => {
  return useQuery(emailQueryKeys.getTemplates(), async () => await api.get("/email/templates"), options);
};
const useGetDefaultEmailTemplatesQuery = (options = {}) => {
  return useQuery(
    emailQueryKeys.getDefaultTemplates(),
    async () => await api.get("/email/templates", { params: { IsDefault: true } }),
    options,
  );
};

const useGetEmailTemplateDetails = (templateId, options = {}) => {
  return useQuery(
    emailQueryKeys.getEmailTemplateDetails(templateId),
    async () => await api.get(`/email/templates/${templateId}`),
    options,
  );
};

const useGetSentEmailsQuery = getWrappedUseQuery(KEYS.GET_SENT_EMAILS, apiClient.Email.byUserId.get);

export {
  useGetDefaultEmailTemplatesQuery,
  useGetEmailTemplateDetails,
  useGetEmailTemplatesQuery,
  useGetSentEmailsQuery,
};
