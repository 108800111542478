/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const SelectionSiteItemType = {
    Page: 'Page',
    FreeText: 'FreeText',
    Availability: 'Availability',
    ConferenceSelectionV2: 'ConferenceSelectionV2',
    ConferenceRankV2: 'ConferenceRankV2',
    EventOptions: 'EventOptions',
    Joint: 'Joint',
    NegativeSelection: 'NegativeSelection',
    NegativeSelectionV2: 'NegativeSelectionV2',
    PositiveSelectionV2: 'PositiveSelectionV2',
    ReasonForMeeting: 'ReasonForMeeting',
    ReasonForMeetingV2: 'ReasonForMeetingV2',
    ReasonForMeetingV3: 'ReasonForMeetingV3',
    SubmittingSelections: 'SubmittingSelections',
    TabbedConferenceSelection: 'TabbedConferenceSelection'
} as const;

export type SelectionSiteItemType = typeof SelectionSiteItemType[keyof typeof SelectionSiteItemType];



