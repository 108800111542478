import { ComponentProps, FC } from "react";

import { Input as AntdInput } from "../../../../../../../../components/styleguide";
import withOnBlur from "../../utils/withOnBlur";

type Parts = {
  TextArea: typeof AntdInput.TextArea;
  Group: typeof AntdInput.Group;
  Search: typeof AntdInput.Search;
  Password: typeof AntdInput.Password;
};

// We do use typecasting here, because it's hard to rewrite `withOnBlur` quickly to support generics
const Input: FC<ComponentProps<typeof AntdInput>> & Parts = withOnBlur(AntdInput) as typeof AntdInput;

// We do use typecasting here, because it's hard to rewrite `withOnBlur` quickly to support generics
Input.TextArea = withOnBlur(AntdInput.TextArea) as typeof AntdInput.TextArea;

Input.Group = AntdInput.Group;
Input.Search = AntdInput.Search;
Input.Password = AntdInput.Password;

export default Input;
