import apiClient from "api-client";

import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useGetZoomMappingsAccountsQuery = getWrappedUseQuery(
  KEYS.GET_ZOOM_MAPPINGS_ACCOUNTS,
  apiClient.ZoomMappings.accounts.get,
);

export const useGetZoomMappingsTableMappingsQuery = getWrappedUseQuery(
  KEYS.GET_ZOOM_MAPPINGS_TABLE_MAPPINGS,
  apiClient.ZoomMappings.tableMappings.get,
);

export const useGetZoomMappingsSessionMappingsQuery = getWrappedUseQuery(
  KEYS.GET_ZOOM_MAPPINGS_SESSION_MAPPINGS,
  apiClient.ZoomMappings.sessionMappings.get,
);

export const useGetZoomMappingsWebinarsQuery = getWrappedUseQuery(
  KEYS.GET_ZOOM_MAPPINGS_WEBINARS,
  apiClient.ZoomMappings.webinars.get,
);
