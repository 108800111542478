import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Modal, Typography } from "antd";
import { useDeleteEmailTemplateById } from "api/mutations/emailTemplates";
import { useEmailTemplatesQuery } from "api/queries/emailTemplates";
import KEYS from "api/queries/keys";
import { Button, Space, Table } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { EmailTemplateResponseBase } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import styles from "./styles.module.less";

export default function EmailTemplates() {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState<EmailTemplateResponseBase[]>([]);
  const deleteEmailTemplates = useDeleteEmailTemplateById();
  const queryClient = useQueryClient();

  const { response: query, handleSort } = useModernQueryWithPaginationAndOrder(
    useEmailTemplatesQuery,
    {
      onSuccess: ({ data }: { data: EmailTemplateResponseBase[] }) => {
        setTemplates(data);
      },
    },
    {},
  );

  const deleteTemplate = (id: number) => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with deletion of this email template?")}</Typography.Text>,
      closable: true,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: () => {
        deleteEmailTemplates.mutate(
          { templateId: id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_EMAIL_TEMPLATES]);
              toast.success(t("Template has been deleted successfully"));
            },
            onError: (response): void => {
              const error = response as { data: { message: string } };
              if (error.data.message) {
                toast.error(error?.data?.message);
              } else {
                toast.error(t("Something went wrong"));
              }
            },
          },
        );
      },
    });
  };

  const columns = [
    {
      title: t("name"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("subject"),
      dataIndex: "subject",
      sorter: true,
    },
    {
      title: "",
      dataIndex: "id",
      width: "10%",
      render: (id: number) => {
        return (
          <div className={styles.buttonWrapper}>
            <Button>
              <Link to={`edit/${id}`}>{t("edit")}</Link>
            </Button>
            <DeleteFilled className={styles.deleteIcon} onClick={() => deleteTemplate(id)} />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <UserGuide.Title title="Email Templates" articleId="16441858893853-email-templates" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button>
          <Link to={"new"}>{t("createEmailTemplate")}</Link>
        </Button>
        <Table columns={columns} loading={query.isLoading} dataSource={templates} onChange={handleSort} rowKey="id" />
      </Space>
    </div>
  );
}
