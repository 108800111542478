import { Card } from "components/styleguide";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import SelectionSiteItemType from "utils/selectionSiteItemTypeUtils";

import ItemEditRenderer from "../ItemEditRenderer";

const ItemRenderer = ({ itemId, item, onChange }) => {
  const { t } = useTranslation();
  const itemType = useMemo(() => new SelectionSiteItemType(item.type), [item.type]);

  return (
    <Card title={t(itemType.toString())} id={itemId}>
      <ItemEditRenderer item={item} onChange={onChange} />
    </Card>
  );
};

ItemRenderer.propTypes = {
  itemId: PropTypes.string.isRequired,
  item: SelectionSitesPropTypes.Item.ANY.isRequired,
  onChange: PropTypes.func,
};

ItemRenderer.defaultProps = {
  onChange: () => undefined,
};

export default ItemRenderer;
