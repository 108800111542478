import { ForumSocialLinksModel } from "generated/api";
import { TFunction } from "react-i18next";
import { isUrlValid } from "utils/anchorUtils";

const validateUrl = (url: string) => {
  return isUrlValid(url) ? Promise.resolve() : Promise.reject();
};

const getFormFields = ({
  t,
  socialLinks,
}: {
  t: TFunction<"translation", undefined>;
  socialLinks: ForumSocialLinksModel;
}) => [
  {
    title: t("Facebook"),
    dataIndex: "facebook",
    initialValue: socialLinks.facebook,
    rules: [
      {
        validator: validateUrl,
        message: t("Must be a valid web address"),
      },
    ],
  },
  {
    title: t("LinkedIn Page"),
    dataIndex: "linkedInPage",
    initialValue: socialLinks.linkedInPage,
  },
  {
    title: t("Twitter"),
    dataIndex: "twitter",
    initialValue: socialLinks.twitter,
  },
  {
    title: t("LinkedIn Group"),
    dataIndex: "linkedInGroup",
    initialValue: socialLinks.linkedInGroup,
  },
  {
    title: t("Instagram"),
    dataIndex: "instagram",
    initialValue: socialLinks.instagram,
  },
];

export { getFormFields };
