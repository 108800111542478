import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { validateBuildingCode } from "utils/validatorUtils";

const BuildingDrawer = ({
  hideBuildingDrawer,
  buildingDrawerVisible,
  submitBuilding,
  venue,
  isLoading,
  isEdit,
  building,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      className="new-building-drawer"
      title={isEdit ? t("Edit Building") : t("createNewBuilding")}
      placement="right"
      onClose={hideBuildingDrawer}
      open={buildingDrawerVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose
    >
      <FullHeightForm
        id="newBuildingForm"
        name="new-building"
        labelCol={{ justify: "left", span: 6 }}
        wrapperCol={{ justify: "right" }}
        labelWrap
        actionsPrepend={<Button onClick={hideBuildingDrawer}>{t("cancel")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isEdit ? t("save") : t("create")}
          </Button>
        }
        onFinish={submitBuilding}
      >
        <Form.Item
          label={t("buildingName")}
          name="name"
          initialValue={isEdit ? building?.name : undefined}
          rules={[
            {
              required: true,
              message: t("buildingNameRequired"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("buildingCode")}
          name="code"
          initialValue={isEdit ? building?.code : undefined}
          rules={[
            {
              required: true,
              message: t("buildingCodeRequired"),
            },
            {
              validator: validateBuildingCode,
              message: t("Building Code should be up to 5 characters!"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("address")} name="address" initialValue={venue.address}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("address2")} name="addressLine2" initialValue={venue.addressLine2}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("city")} name="city" initialValue={venue.city}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("county")} name="county" initialValue={venue.county}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("postcode")} name="postcode" initialValue={venue.postCode}>
          <Input disabled />
        </Form.Item>
        <Form.Item label={t("country")} name="countryCode" initialValue={venue.country}>
          <Input disabled />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

BuildingDrawer.propTypes = {
  hideBuildingDrawer: PropTypes.func,
  buildingDrawerVisible: PropTypes.bool,
  submitBuilding: PropTypes.func,
  venue: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    postCode: PropTypes.string,
    country: PropTypes.string,
    email: PropTypes.string,
    websiteAddress: PropTypes.string,
    phone: PropTypes.string,
    description: PropTypes.string,
    directions: PropTypes.string,
  }),
  createBuildingMutation: PropTypes.object,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  building: PropTypes.object,
};

export default BuildingDrawer;
