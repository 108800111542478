import { FormInstance } from "antd";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input, Select, TimePicker, Typography } from "components/styleguide";
import { DocumentDefinitionSectionOptionType, ForumDocumentDefinitionSectionResponseModel } from "generated/api";
import { isEmpty, isEqual } from "lodash";
import moment from "moment/moment";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useUpdateSectionAndSectionOptions } from "../../hooks/useUpdateSectionAndSectionOptions";
import TextEditorOption, { ReactQuillType } from "./TextEditorOption";

interface SectionOptionsProps {
  documentDefinitionSection?: ForumDocumentDefinitionSectionResponseModel;
  forumId: number;
  documentDefinitionSectionId: number;
  onClose: () => void;
  sectionForm: FormInstance;
  sectionOptionsForm: FormInstance;
  quills: ReactQuillType[];
  setQuills: Dispatch<SetStateAction<ReactQuillType[]>>;
}

const { Option } = Select;

const SectionOptions: React.FC<SectionOptionsProps> = ({
  documentDefinitionSection,
  forumId,
  documentDefinitionSectionId,
  onClose,
  sectionForm,
  sectionOptionsForm,
  quills,
  setQuills,
}) => {
  const { t } = useTranslation();
  const { options } = documentDefinitionSection || {};

  const { submit, isMutationLoading } = useUpdateSectionAndSectionOptions({
    forumId,
    documentDefinitionSectionId,
    onClose,
    quills,
  });

  const addQuill = (newQuill: ReactQuillType) => {
    setQuills((prev) => {
      const exists = prev.some((quill) => isEqual(quill, newQuill));
      return exists ? prev : [...prev, newQuill];
    });
  };

  const getOptions = () =>
    options?.map(({ name, value, id, optionType, valueOptions }) => {
      if (optionType === DocumentDefinitionSectionOptionType.Html) {
        return <TextEditorOption name={name} id={id} value={value} key={id} addQuill={addQuill} />;
      }

      if (optionType === DocumentDefinitionSectionOptionType.String) {
        return (
          <Form.Item key={id} name={id} label={t(name)} initialValue={value}>
            <Input maxLength={500} />
          </Form.Item>
        );
      }

      if (optionType === DocumentDefinitionSectionOptionType.Boolean) {
        return (
          <Form.Item key={id} name={id} label={t(name)} initialValue={value}>
            <Select>
              <Option value="True">{t("Yes")}</Option>
              <Option value="False">{t("No")}</Option>
            </Select>
          </Form.Item>
        );
      }

      if (optionType === DocumentDefinitionSectionOptionType.DropdownList) {
        return (
          <Form.Item key={id} name={id} label={t(name)} initialValue={value}>
            <Select>
              {valueOptions.map((valueOption) => (
                <Option key={valueOption} value={valueOption}>
                  {valueOption}
                </Option>
              ))}
            </Select>
          </Form.Item>
        );
      }

      if (optionType === DocumentDefinitionSectionOptionType.Time) {
        return (
          <Form.Item key={id} name={id} label={t(name)} initialValue={value ? moment(value, "HH:mm:ss") : null}>
            <TimePicker />
          </Form.Item>
        );
      }
    });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatTimeFields = (obj: any) => {
    Object.keys(obj).map((key) => {
      if (moment.isMoment(obj[key])) {
        obj[key] = obj[key].format("HH:mm:ss");
      }
    });

    return obj;
  };

  return (
    <FullHeightForm
      form={sectionOptionsForm}
      onFinish={(values) =>
        submit({ ...documentDefinitionSection, ...sectionForm.getFieldsValue() }, formatTimeFields(values))
      }
      layout="vertical"
      actionsPrepend={!isEmpty(options) && <Button onClick={onClose}>{t("Cancel")}</Button>}
      actionsAppend={
        !isEmpty(options) && (
          <Button htmlType="submit" type="primary" loading={isMutationLoading}>
            {t("Update")}
          </Button>
        )
      }
    >
      {isEmpty(options) && <Typography>{t("No options available for this section")}</Typography>}
      {getOptions()}
    </FullHeightForm>
  );
};

export default SectionOptions;
