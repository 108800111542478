import "./styles.less";

import UserGuide from "components/UserGuide";
import { ForumAttendeeResponseModel, UserInfoModel } from "generated/api";
import { toNumber } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useAttendeeQuery } from "../../api/queries/attendees";
import { Button, Collapse, Layout } from "../../components/styleguide";
import SendEmailDrawer from "../Attendees/SendEmailDrawer";
import AttendeeAttendanceDetails from "./components/AttendanceDetails";
import AttendeeAddressInfo from "./components/AttendeeAddressInfo";
import AttendeeBasicInfoPanel from "./components/AttendeeBasicInfo";
import AttendeeContactDetails from "./components/AttendeeContactDetails";
import AttendeeDescription from "./components/AttendeeDescription";
import AttendeeDietaryRequirements from "./components/AttendeeDietaryRequirements";
import AttendeeGroupMembershipsPanel from "./components/AttendeeGroupMemberships";
import AttendeeHistoryInformation from "./components/AttendeeHistoryInformation";
import HotelAndRoomAllocation from "./components/AttendeeHotelAndRoomAllocation";
import AttendeeItinerary from "./components/AttendeeItinerary";
import AttendeeMeetingLocationsPanel from "./components/AttendeeMeetingLocations";
import AttendeePartnerDetails from "./components/AttendeePartnerDetails";
import AttendeePreferencesPanel from "./components/AttendeePreferences";
import AttendeeQuestionnaireAnswers from "./components/AttendeeQuestionnaireAnswers";
import AttendeeSocialLinks from "./components/AttendeeSocialLinks";
import AttendeeSpeakerDetails from "./components/AttendeeSpeakerDetails";
import AttendeeAvailability from "./components/Availability";
import MoveToCompanyDrawer from "./components/MoveToCompanyDrawer";

const { Content } = Layout;

const collapseDefaultOpenKeys = [
  "details",
  "address",
  "description",
  "social",
  "attendance",
  "history",
  "availability",
  "meeting-locations",
  "group-memberships",
  "preferences",
  "answers",
  "partner",
  "speaker",
  "hotel-and-room-allocation",
  "itinerary",
  "dietary",
];

export default function Attendee({ userInfo }: { userInfo: UserInfoModel | undefined }) {
  const { id, attendeeId: attendId } = useParams();
  const forumId = toNumber(id);
  const attendeeId = toNumber(attendId);

  const { t } = useTranslation();

  const [attendee, setAttendee] = useState<ForumAttendeeResponseModel | null>(null);
  const [isEditingAddressInfo, setIsEditingAddressInfo] = useState(false);
  const [isEditingSocialLinks, setIsEditingSocialLinks] = useState(false);
  const [isEditingAttendance, setIsEditingAttendance] = useState(false);
  const [isEditingContactDetails, setIsEditingContactDetails] = useState(false);
  const [isSendEmailDrawerVisible, setIsSendEmailDrawerVisible] = useState(false);
  const [isMoveToCompanyDrawerVisible, setIsMoveToCompanyDrawerVisible] = useState(false);
  const [isEditingPartner, setIsEditingPartner] = useState(false);
  const [isEditingSpeakerDetails, setIsEditingSpeakerDetails] = useState(false);
  const [isEditingHotelAllocation, setIsEditingHotelAllocation] = useState(false);
  const [isDescriptionEditing, setIsDescriptionEditing] = useState(false);
  const [isEditingDietaryRequirements, setIsEditingDietaryRequirements] = useState(false);

  const getEditButton = useCallback(
    (setEditState: (value: boolean) => void) => {
      return (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setEditState(true);
          }}
        >
          {t("Edit")}
        </Button>
      );
    },
    [t],
  );

  const attendeeRequest = useAttendeeQuery(
    {
      forumId: toNumber(id),
      attendeeId: toNumber(attendeeId),
      userTimeZoneOffset: new Date().getTimezoneOffset(),
    },
    {
      onSuccess: ({ data }) => {
        setAttendee(data);
      },
    },
  );

  return (
    <Layout>
      <Content>
        <SendEmailDrawer
          forumId={toNumber(id)}
          onClose={() => {
            setIsSendEmailDrawerVisible(false);
          }}
          isOpen={isSendEmailDrawerVisible}
          attendees={attendee ? [attendee] : []}
          userInfo={userInfo}
          hideTargetGroup
        />
        {attendee && (
          <MoveToCompanyDrawer
            forumId={toNumber(id)}
            onClose={() => {
              setIsMoveToCompanyDrawerVisible(false);
            }}
            isOpen={isMoveToCompanyDrawerVisible}
            attendee={attendee}
          />
        )}
        <div className="attendee-page">
          <AttendeeBasicInfoPanel
            attendee={attendee}
            isLoading={attendeeRequest.isLoading}
            onEmailDrawerOpen={() => setIsSendEmailDrawerVisible(true)}
            onMoveToCompanyDrawerOpen={() => setIsMoveToCompanyDrawerVisible(true)}
          />
          <Collapse
            className={"collapse-parent contact-details"}
            bordered={false}
            defaultActiveKey={collapseDefaultOpenKeys}
            ghost
          >
            <UserGuide.Panel
              id="details"
              title="attendees.sideMenuItems.Contact Details"
              articleId="16422935956381-contact-details"
              key="details"
              extra={getEditButton(setIsEditingContactDetails)}
            >
              <AttendeeContactDetails isEditing={isEditingContactDetails} setIsEditing={setIsEditingContactDetails} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="description"
              title="attendees.sideMenuItems.Description"
              articleId="16423011864093-attendee-description"
              key="description"
              extra={getEditButton(setIsDescriptionEditing)}
            >
              <AttendeeDescription isEditing={isDescriptionEditing} setIsEditing={setIsDescriptionEditing} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="address"
              title="attendees.sideMenuItems.Address Information"
              articleId="16423045238429-address-information"
              key="address"
              extra={getEditButton(setIsEditingAddressInfo)}
            >
              <AttendeeAddressInfo isEditing={isEditingAddressInfo} setIsEditing={setIsEditingAddressInfo} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="social"
              title="Social Links"
              articleId="16423058850973-social-links"
              key="social"
              extra={getEditButton(setIsEditingSocialLinks)}
            >
              <AttendeeSocialLinks isEditing={isEditingSocialLinks} setIsEditing={setIsEditingSocialLinks} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="attendance"
              title="Attendance Details"
              articleId="16423070198685-attendance-details"
              key="attendance"
            >
              <AttendeeAttendanceDetails
                attendee={attendee}
                isEditing={isEditingAttendance}
                setIsEditing={setIsEditingAttendance}
                companyName={attendee?.company}
              />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="history"
              title="History Information"
              articleId="16423071797277-history-information"
              key="history"
            >
              <AttendeeHistoryInformation forumId={forumId} attendeeId={attendeeId} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="dietary"
              title="Dietary Requirements"
              articleId="16423118892957-dietary-requirements"
              key="dietary"
              extra={getEditButton(setIsEditingDietaryRequirements)}
            >
              <AttendeeDietaryRequirements
                isEditing={isEditingDietaryRequirements}
                setIsEditing={setIsEditingDietaryRequirements}
                isLoading={attendeeRequest.isLoading}
              />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="availability"
              title="Availability"
              articleId="16423125897757-availability"
              key="availability"
            >
              <AttendeeAvailability forumId={forumId} attendeeId={attendeeId} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="meeting-locations"
              title="Meeting Locations"
              articleId="16423152973341-meeting-locations"
              key="meeting-locations"
            >
              <AttendeeMeetingLocationsPanel forumId={forumId} attendeeId={attendeeId} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="group-memberships"
              title="Group Memberships"
              articleId="16423145450909-group-membership"
              key="group-memberships"
            >
              <AttendeeGroupMembershipsPanel forumId={forumId} attendeeId={attendeeId} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="preferences"
              title="Preferences"
              articleId="16423166773661-preferences"
              key="preferences"
            >
              <AttendeePreferencesPanel forumId={forumId} attendeeId={attendeeId} />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="answers"
              title="Questionnaire Answers"
              articleId="16423162640285-questionnaire-answers"
              key="answers"
            >
              <AttendeeQuestionnaireAnswers />
            </UserGuide.Panel>
            <UserGuide.Panel
              id="partner"
              title="Spouse/Partner"
              articleId="16423117586973-spouse-partner"
              key="partner"
              extra={getEditButton(setIsEditingPartner)}
            >
              <AttendeePartnerDetails
                forumId={forumId}
                attendeeId={attendeeId}
                isEditing={isEditingPartner}
                setIsEditing={setIsEditingPartner}
              />
            </UserGuide.Panel>
            {attendee?.isSpeaker && (
              <UserGuide.Panel
                id="speaker"
                title="Speaker Tracking/Details"
                articleId="16539905705757-speaker-tracking"
                key="speaker"
                extra={getEditButton(setIsEditingSpeakerDetails)}
              >
                <AttendeeSpeakerDetails isEditing={isEditingSpeakerDetails} setIsEditing={setIsEditingSpeakerDetails} />
              </UserGuide.Panel>
            )}
            <UserGuide.Panel
              id="hotel-and-room-allocation"
              title="Hotel and/or Room Allocation"
              articleId="16423075433373-hotel-and-room-allocation"
              key="hotel-and-room-allocation"
              extra={getEditButton(setIsEditingHotelAllocation)}
            >
              <HotelAndRoomAllocation isEditing={isEditingHotelAllocation} setIsEditing={setIsEditingHotelAllocation} />
            </UserGuide.Panel>
            <UserGuide.Panel id="itinerary" title="Itinerary" articleId="16423061165341-itinerary" key="itinerary">
              <AttendeeItinerary attendeeId={attendeeId} forumId={forumId} />
            </UserGuide.Panel>
          </Collapse>
        </div>
      </Content>
    </Layout>
  );
}
