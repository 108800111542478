import { Input as AntdInput } from "antd";
import { ChangeEvent, ComponentProps, FC, useRef, useState } from "react";

interface InputProps extends ComponentProps<typeof AntdInput> {
  lazy?: boolean;
}

const LazyInput: FC<InputProps> = ({ value, onBlur, onChange, ...props }) => {
  const lastChangeEvent = useRef<ChangeEvent<HTMLInputElement>>();
  const [v, setV] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setV(e.currentTarget.value);
    lastChangeEvent.current = e;
  };

  const handleBlur: InputProps["onBlur"] = (e) => {
    onBlur?.(e);

    if (lastChangeEvent.current) {
      onChange?.(lastChangeEvent.current);
      lastChangeEvent.current = undefined;
    }
  };

  return <AntdInput {...props} value={v} onBlur={handleBlur} onChange={handleChange} />;
};

interface TextAreaProps extends ComponentProps<typeof AntdInput.TextArea> {
  lazy?: boolean;
}

const LazyTextArea: FC<TextAreaProps> = ({ value, onBlur, onChange, ...props }) => {
  const lastChangeEvent = useRef<ChangeEvent<HTMLTextAreaElement>>();
  const [v, setV] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setV(e.currentTarget.value);
    lastChangeEvent.current = e;
  };

  const handleBlur: TextAreaProps["onBlur"] = (e) => {
    onBlur?.(e);

    if (lastChangeEvent.current) {
      onChange?.(lastChangeEvent.current);
      lastChangeEvent.current = undefined;
    }
  };

  return <AntdInput.TextArea {...props} value={v} onBlur={handleBlur} onChange={handleChange} />;
};

type Parts = {
  TextArea: typeof TextArea;
  Group: typeof AntdInput.Group;
  Search: typeof AntdInput.Search;
  Password: typeof AntdInput.Password;
};
const Input: FC<InputProps> & Parts = ({ lazy, ...props }) => {
  if (lazy) {
    return <LazyInput {...props} />;
  }

  return <AntdInput {...props} />;
};

const TextArea: FC<TextAreaProps> = ({ lazy, ...props }) => {
  if (lazy) {
    return <LazyTextArea {...props} />;
  }

  return <AntdInput.TextArea {...props} />;
};

Input.propTypes = AntdInput.propTypes;
Input.defaultProps = AntdInput.defaultProps;
Input.TextArea = TextArea;
Input.Group = AntdInput.Group;
Input.Search = AntdInput.Search;
Input.Password = AntdInput.Password;

export default Input;
