const ForumGroupColor = {
  DarkRed: 1,
  DarkBlue: 2,
  Green: 3,
  LightBlue: 4,
  Navy: 5,
  Orange: 6,
  Pink: 7,
  Purple: 8,
  Red: 9,

  toDisplayName(type) {
    switch (type) {
      case 1:
        return "Dark Red";
      case 2:
        return "Dark Blue";
      case 3:
        return "Green";
      case 4:
        return "Light Blue";
      case 5:
        return "Navy";
      case 6:
        return "Orange";
      case 7:
        return "Pink";
      case 8:
        return "Purple";
      case 9:
        return "Red";
      default:
        return "";
    }
  },
};

export default ForumGroupColor;
