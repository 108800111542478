import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { Select, Space, Typography } from "components/styleguide";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";

interface PeopleTypeSelectOptionProps {
  peopleTypes: string[];
  setPeopleTypes: Dispatch<SetStateAction<string[]>>;
}

const hasDelegatesOrExecutives = (arr: string[]): boolean =>
  arr.some((code: string) => ["AllDelegates", "AllExecutives"].includes(code));

const PeopleTypeSelectOption = ({ peopleTypes, setPeopleTypes }: PeopleTypeSelectOptionProps) => {
  const { t } = useTranslation();

  const [codesOptions, setCodesOptions] = useState<{ value: string; label: string }[]>([]);

  const { isLoading: isCodesLoading } = useParticipationCodesQuery(
    {
      ignorePagination: true,
    },
    {
      onSuccess: ({ data: { items = [] } = {} }) => {
        const codes = items.map(({ code }) => ({ value: code, label: code }));
        const additionalCodeOptions = [
          { value: "AllDelegates", label: t("All Delegates") },
          { value: "AllExecutives", label: t("All Executives") },
        ];

        setCodesOptions([...additionalCodeOptions, ...codes]);
      },
    },
  );

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("People Type")}</Typography.Text>
      <Select
        allowClear
        showSearch
        style={{ minWidth: "200px" }}
        mode={"multiple"}
        optionFilterProp={"label"}
        options={codesOptions}
        placeholder={t("Select People Type")}
        value={peopleTypes}
        onChange={(value) => {
          if (value.length && (hasDelegatesOrExecutives(value) || hasDelegatesOrExecutives(peopleTypes))) {
            setPeopleTypes([value[value.length - 1]]);
          } else {
            setPeopleTypes(value);
          }
        }}
        loading={isCodesLoading}
      />
    </Space>
  );
};

export default PeopleTypeSelectOption;
