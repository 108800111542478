import { ForumSeminarApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumSeminarApi);

const ForumSeminar = {
  get: api.forumsForumIdSeminarsGet,
};

export default ForumSeminar;
