/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompaniesPage } from '../apiModels';
// @ts-ignore
import { CompanyModel } from '../apiModels';
// @ts-ignore
import { CompanySocialLinksRequestModel } from '../apiModels';
/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesCompanyIdGet: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companiesCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesCompanyIdPut: async (companyId: number, companyModel?: CompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companiesCompanyIdPut', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CompanySocialLinksRequestModel} [companySocialLinksRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesCompanyIdSocialLinksPut: async (companyId: number, companySocialLinksRequestModel?: CompanySocialLinksRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companiesCompanyIdSocialLinksPut', 'companyId', companyId)
            const localVarPath = `/companies/{companyId}/social-links`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySocialLinksRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [country] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesGet: async (name?: string, country?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (country !== undefined) {
                localVarQueryParameter['Country'] = country;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesPost: async (companyModel?: CompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesCompanyIdGet(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesCompanyIdPut(companyId: number, companyModel?: CompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesCompanyIdPut(companyId, companyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} companyId 
         * @param {CompanySocialLinksRequestModel} [companySocialLinksRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesCompanyIdSocialLinksPut(companyId: number, companySocialLinksRequestModel?: CompanySocialLinksRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesCompanyIdSocialLinksPut(companyId, companySocialLinksRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [country] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesGet(name?: string, country?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesPage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesGet(name, country, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companiesPost(companyModel?: CompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companiesPost(companyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {CompanyApiCompaniesCompanyIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesCompanyIdGet(requestParameters: CompanyApiCompaniesCompanyIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyModel> {
            return localVarFp.companiesCompanyIdGet(requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyApiCompaniesCompanyIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesCompanyIdPut(requestParameters: CompanyApiCompaniesCompanyIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.companiesCompanyIdPut(requestParameters.companyId, requestParameters.companyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyApiCompaniesCompanyIdSocialLinksPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesCompanyIdSocialLinksPut(requestParameters: CompanyApiCompaniesCompanyIdSocialLinksPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.companiesCompanyIdSocialLinksPut(requestParameters.companyId, requestParameters.companySocialLinksRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyApiCompaniesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesGet(requestParameters: CompanyApiCompaniesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CompaniesPage> {
            return localVarFp.companiesGet(requestParameters.name, requestParameters.country, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyApiCompaniesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companiesPost(requestParameters: CompanyApiCompaniesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.companiesPost(requestParameters.companyModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for companiesCompanyIdGet operation in CompanyApi.
 * @export
 * @interface CompanyApiCompaniesCompanyIdGetRequest
 */
export interface CompanyApiCompaniesCompanyIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompaniesCompanyIdGet
     */
    readonly companyId: number
}

/**
 * Request parameters for companiesCompanyIdPut operation in CompanyApi.
 * @export
 * @interface CompanyApiCompaniesCompanyIdPutRequest
 */
export interface CompanyApiCompaniesCompanyIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompaniesCompanyIdPut
     */
    readonly companyId: number

    /**
     * 
     * @type {CompanyModel}
     * @memberof CompanyApiCompaniesCompanyIdPut
     */
    readonly companyModel?: CompanyModel
}

/**
 * Request parameters for companiesCompanyIdSocialLinksPut operation in CompanyApi.
 * @export
 * @interface CompanyApiCompaniesCompanyIdSocialLinksPutRequest
 */
export interface CompanyApiCompaniesCompanyIdSocialLinksPutRequest {
    /**
     * 
     * @type {number}
     * @memberof CompanyApiCompaniesCompanyIdSocialLinksPut
     */
    readonly companyId: number

    /**
     * 
     * @type {CompanySocialLinksRequestModel}
     * @memberof CompanyApiCompaniesCompanyIdSocialLinksPut
     */
    readonly companySocialLinksRequestModel?: CompanySocialLinksRequestModel
}

/**
 * Request parameters for companiesGet operation in CompanyApi.
 * @export
 * @interface CompanyApiCompaniesGetRequest
 */
export interface CompanyApiCompaniesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CompanyApiCompaniesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyApiCompaniesGet
     */
    readonly country?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof CompanyApiCompaniesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof CompanyApiCompaniesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof CompanyApiCompaniesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof CompanyApiCompaniesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for companiesPost operation in CompanyApi.
 * @export
 * @interface CompanyApiCompaniesPostRequest
 */
export interface CompanyApiCompaniesPostRequest {
    /**
     * 
     * @type {CompanyModel}
     * @memberof CompanyApiCompaniesPost
     */
    readonly companyModel?: CompanyModel
}

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CompanyApiCompaniesCompanyIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companiesCompanyIdGet(requestParameters: CompanyApiCompaniesCompanyIdGetRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companiesCompanyIdGet(requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompaniesCompanyIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companiesCompanyIdPut(requestParameters: CompanyApiCompaniesCompanyIdPutRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companiesCompanyIdPut(requestParameters.companyId, requestParameters.companyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompaniesCompanyIdSocialLinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companiesCompanyIdSocialLinksPut(requestParameters: CompanyApiCompaniesCompanyIdSocialLinksPutRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companiesCompanyIdSocialLinksPut(requestParameters.companyId, requestParameters.companySocialLinksRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companiesGet(requestParameters: CompanyApiCompaniesGetRequest = {}, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companiesGet(requestParameters.name, requestParameters.country, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyApiCompaniesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companiesPost(requestParameters: CompanyApiCompaniesPostRequest = {}, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companiesPost(requestParameters.companyModel, options).then((request) => request(this.axios, this.basePath));
    }
}

