/* eslint-disable simple-import-sort/imports */
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Theme, { SUPPORTED_THEMES } from "./components/styleguide/Theme";

import EventLayout from "layouts/EventLayout";
import BaseLayout from "./layouts/BaseLayout/BaseLayout";
import ForumAdminLayout from "./layouts/ForumAdminLayout";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Timeslots from "./pages/Timeslots";
import NewTimeSlot from "./pages/Timeslots/NewTimeslot";

import ToastContainer from "./components/styleguide/components/ToastContainer";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { useUserInfoQuery } from "api/queries/user";
import "./App.less";

import LocaleHandler from "./components/LocaleHandler";
import AllTimeslots from "pages/Timeslots/AllTimeslots";
import EditTimeslot from "pages/Timeslots/EditTimeslot";
import Attendees from "./pages/Attendees";
import Attendee from "./pages/Attendee";
import General from "pages/General";
import Forums from "pages/SystemAdmin/Forums";
import VenueDetailsPanel from "pages/SystemAdmin/Venues/VenueDetails";
import AllVenues from "pages/SystemAdmin/Venues/AllVenues";
import SystemVenueLayout from "layouts/SystemVenueLayout";
import ForumVenueLayout from "layouts/ForumVenueLayout";
import ForumVenue from "./pages/ForumVenue";
import SystemBuilding from "pages/SystemAdmin/Venues/VenueDetails/Building";
import ForumBuilding from "./pages/ForumVenue/ForumBuilding";
import ForumCompany from "pages/ForumCompany";
import ConferenceLayout from "./layouts/ConferenceLayout";
import Stream from "./pages/Conference/Stream";
import ConferenceLanguagesPage from "./pages/Conference/Languages";
import ParticipationCodes from "pages/SystemAdmin/ParticipationCodes";
import SystemAdminLayout from "layouts/SystemAdminLayout";
import QuestionnairesLayout from "./pages/Questionnaires/Layout";
import QuestionnairesListPage from "./pages/Questionnaires/List";
import NewQuestionnairePage from "./pages/Questionnaires/New";
import QuestionnaireEditPage from "./pages/Questionnaires/Edit";
import SelectionSitesLayout from "pages/SelectionSites/Layout";
import SelectionSitesPage from "pages/SelectionSites";
import NotFound from "pages/NotFound";
import SelectionSiteEditPage from "pages/SelectionSites/EditPage";
import ForumCompanyLayout from "pages/ForumCompany/Layout";
import QuestionnaireImportPage from "./pages/Questionnaires/Import";
import QuestionnaireImportQuestionsPage from "./pages/Questionnaires/ImportQuestions";
import ForumDetails from "pages/SystemAdmin/Forums/components/ForumDetails";
import ForumsList from "pages/SystemAdmin/Forums/components/ForumsList";
import EmailTemplatesLayout from "pages/EmailTemplates/Layout";
import EmailTemplates from "pages/EmailTemplates/List";
import EditEmailTemplate from "pages/EmailTemplates/Edit";
import Page403 from "./pages/403";
import RoleGuard from "./guards/Role";
import SeminarTypes from "pages/SeminarTypes/List";
import Seminars from "pages/Seminars/List";
import HotelAdmin from "pages/HotelAdmin";
import SpeakerVisuals from "./pages/SpeakerVisuals";
import SpeakerVisualsContent from "./pages/SpeakerVisuals/SpeakerVisualsContent";
import Essentials from "pages/Itinerary/Essentials";
import ItineraryLayout from "pages/Itinerary/Layout";
import ItineraryTestSection from "./pages/Itinerary/Test";
import ItineraryAdvancedSection from "pages/Itinerary/Advanced";
import ItineraryCreateSection from "pages/Itinerary/Create";
import EmailTracking from "pages/EmailTracking";
import Feedback from "pages/Feedback";
import BookletProfileSetup from "pages/Itinerary/BookletProfileSetup";
import GDPRRequests from "pages/SystemAdmin/GDPRRequests";
import Reporting from "pages/Reporting";
import ReportingOptions from "pages/Reporting/ReportingOptions";
import ReportViewer from "pages/Reporting/ReportViewer";
import ItContracts from "./pages/ItContracts";
import Groups from "./pages/Groups";
import OnSiteFunctions from "pages/OnSiteFunctions";
import Playbook from "pages/Playbook";
import PlaybookLayout from "pages/Playbook/Layout";
import PlaybookFacility from "pages/Playbook/Conferences/Facility";
import PlaybookConferences from "pages/Playbook/Conferences";
import PlaybookMeetings from "pages/Playbook/Meetings";
import PlaybookConferenceCheckIn from "pages/Playbook/Conferences/ConferenceCheckIn";
import PlaybookMeetingFacility from "pages/Playbook/Meetings/Facility";
import PlaybookMeetingCheckIn from "pages/Playbook/Meetings/CheckIn";
import MeetingLocations from "pages/MeetingLocations";
import ManageDescriptions from "pages/ManageDescriptions";
import ManageVirtualForum from "./pages/ManageVirtualForum";

function App() {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const userInfoRequest = useUserInfoQuery({});

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(loginRequest);
    }
  });

  if (inProgress === InteractionStatus.Startup) {
    return;
  }

  return (
    <Theme type={SUPPORTED_THEMES.main}>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/:lang" element={<LocaleHandler />}>
          <Route element={<BaseLayout userInfo={userInfoRequest} />}>
            <Route path="" element={<Home />} />
          </Route>

          <Route
            path="system-admin"
            element={
              <RoleGuard allowIf={({ SYSTEM_ADMIN }) => SYSTEM_ADMIN}>
                <SystemAdminLayout />
              </RoleGuard>
            }
          >
            <Route path="" element={<Navigate to={"forums"} replace />} />
            <Route path="forums" element={<Forums />}>
              <Route path="" element={<ForumsList />}></Route>
              <Route path=":forumId" element={<ForumDetails />}></Route>
            </Route>
            <Route path="participation-codes" element={<ParticipationCodes />} />
            <Route path="venues" element={<AllVenues />} />
            <Route element={<SystemVenueLayout />}>
              <Route path="venues/:venueId" element={<VenueDetailsPanel />} />
              <Route path="venues/:venueId/buildings/:buildingId" element={<SystemBuilding />} />
            </Route>
            <Route path="gdpr-requests" element={<GDPRRequests />} />
          </Route>
          <Route
            path="forum/:id"
            element={
              <RoleGuard allowIf={({ FORUM_ADMIN }) => FORUM_ADMIN}>
                <EventLayout userInfo={userInfoRequest} />
              </RoleGuard>
            }
          >
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="attendees" element={<Attendees userInfo={userInfoRequest?.data?.data} />} />
            <Route path="attendees/:attendeeId">
              <Route index element={<Attendee userInfo={userInfoRequest?.data?.data} />} />
            </Route>
            <Route path="meeting-locations">
              <Route index element={<MeetingLocations />} />
            </Route>
            <Route element={<ConferenceLayout />}>
              <Route path="conference" element={<Navigate to="stream" replace />} />
              <Route path={"conference/stream"} element={<Stream />} />
              <Route path={"conference/seminar-types"} element={<SeminarTypes />} />
              <Route path={"conference/seminars"} element={<Seminars />} />
              <Route path={"conference/speaker-visuals"} element={<SpeakerVisuals />} />
              <Route path={"conference/speaker-visuals/:seminarId"} element={<SpeakerVisualsContent />} />
              <Route path={"conference/languages"} element={<ConferenceLanguagesPage />} />
            </Route>
            <Route element={<ForumAdminLayout />}>
              <Route path="forum-admin" element={<Navigate to="general" replace />} />
              <Route path="forum-admin/general" element={<General />} />
              <Route path="forum-admin/groups" element={<Groups />} />
              <Route path="forum-admin/it-contract" element={<ItContracts />} />
              <Route path="forum-admin/timeslots" element={<Timeslots />}>
                <Route path="" element={<AllTimeslots />} />
                <Route path="new" element={<NewTimeSlot />} />
                <Route path=":timeslotId/edit" element={<EditTimeslot />} />
              </Route>
              <Route path="forum-admin/questionnaires" element={<QuestionnairesLayout />}>
                <Route path="" element={<QuestionnairesListPage />} />
                <Route path="new" element={<NewQuestionnairePage />} />
              </Route>
              <Route path="forum-admin/selection-sites" element={<SelectionSitesLayout />}>
                <Route path="" element={<SelectionSitesPage />} />
              </Route>
              <Route path="forum-admin/descriptions" element={<ManageDescriptions />} />
              <Route path="forum-admin/manage-virtual-forum" element={<ManageVirtualForum />} />
              <Route path="forum-admin/itinerary" element={<ItineraryLayout />}>
                <Route path="" element={<Essentials />} />
                <Route path="essentials" element={<Essentials />} />
                <Route path="test" element={<ItineraryTestSection />} />
                <Route path="advanced" element={<ItineraryAdvancedSection />} />
                <Route path="create">
                  <Route index element={<Navigate to="queue" replace />} />
                  <Route path="queue" element={<ItineraryCreateSection tab="queue" />} />
                  <Route path="completed" element={<ItineraryCreateSection tab="completed" />} />
                  <Route path="completed/:documentId" element={<ItineraryCreateSection tab="completed" />} />
                </Route>
                <Route path="booklet-profile-setup" element={<BookletProfileSetup />} />
              </Route>
              <Route path="forum-admin/feedback" element={<Feedback />} />
              <Route path="forum-admin/on-site-functions" element={<OnSiteFunctions />} />
              <Route path="forum-admin/email-templates" element={<EmailTemplatesLayout />}>
                <Route path="" element={<EmailTemplates />} />
                <Route path="new" element={<EditEmailTemplate />} />
                <Route path="edit/:templateId" element={<EditEmailTemplate />} />
              </Route>
              <Route path="forum-admin/hotel-admin" element={<HotelAdmin />} />
            </Route>

            <Route path="forum-admin/selection-sites/:selectionSiteId">
              <Route path="" element={<SelectionSiteEditPage />} />
            </Route>
            <Route path="forum-admin/questionnaires/:questionnaireId">
              <Route path="" element={<QuestionnaireEditPage />} />
              <Route path="import">
                <Route path="" element={<QuestionnaireImportPage />} />
                <Route path=":importForumId/:importQuestionnaireId" element={<QuestionnaireImportQuestionsPage />} />
              </Route>
            </Route>
            <Route path="reporting">
              <Route path="" element={<Reporting />} />

              <Route path={":category"} element={<Reporting />} />
              <Route path={":category/:subcategory"} element={<Reporting />} />
              <Route path={":category/:subcategory/:subcategory2"} element={<Reporting />} />
              <Route path={":category/:subcategory/:subcategory2/:subcategory3"} element={<Reporting />} />
              <Route
                path={":category/:subcategory/:subcategory2/:subcategory3/:subcategory4"}
                element={<Reporting />}
              />

              <Route path=":category">
                <Route path="options/:reportKey" element={<ReportingOptions />} />
                <Route path=":subcategory/options/:reportKey" element={<ReportingOptions />} />
                <Route path=":subcategory/:subcategory2/options/:reportKey" element={<ReportingOptions />} />
                <Route
                  path=":subcategory/:subcategory2/:subcategory3/options/:reportKey"
                  element={<ReportingOptions />}
                />
                <Route
                  path=":subcategory/:subcategory2/:subcategory3/:subcategory4/options/:reportKey"
                  element={<ReportingOptions />}
                />
              </Route>
              <Route path=":category">
                <Route path="options/:reportKey/report" element={<ReportViewer />} />
                <Route path=":subcategory/options/:reportKey/report" element={<ReportViewer />} />
                <Route path=":subcategory/:subcategory2/options/:reportKey/report" element={<ReportViewer />} />
                <Route
                  path=":subcategory/:subcategory2/:subcategory3/options/:reportKey/report"
                  element={<ReportViewer />}
                />
                <Route
                  path=":subcategory/:subcategory2/:subcategory3/:subcategory4/options/:reportKey/report"
                  element={<ReportViewer />}
                />
              </Route>
              <Route path=":category">
                <Route path="report/:reportKey" element={<ReportViewer />} />
                <Route path=":subcategory/report/:reportKey" element={<ReportViewer />} />
                <Route path=":subcategory/:subcategory2/report/:reportKey" element={<ReportViewer />} />
                <Route path=":subcategory/:subcategory2/:subcategory3/report/:reportKey" element={<ReportViewer />} />
                <Route
                  path=":subcategory/:subcategory2/:subcategory3/:subcategory4/report/:reportKey"
                  element={<ReportViewer />}
                />
              </Route>
            </Route>

            <Route path="email-tracking">
              <Route path="" element={<EmailTracking />} />
            </Route>
            <Route path="attendees" element={<Attendees userInfo={userInfoRequest?.data?.data} />} />
            <Route path="attendees/:attendeeId">
              <Route index element={<Attendee userInfo={userInfoRequest?.data?.data} />} />
            </Route>
            <Route path="attendees/company/:companyId" element={<ForumCompanyLayout />}>
              <Route index element={<ForumCompany />} />
            </Route>
            <Route element={<ForumVenueLayout />}>
              <Route path="venues/:venueId" element={<ForumVenue />} />
              <Route path="venues/:venueId/buildings/:buildingId" element={<ForumBuilding />} />
            </Route>
          </Route>
          <Route element={<PlaybookLayout />}>
            <Route path="forum/:id/playbook" element={<Playbook />} />
            <Route path="forum/:id/playbook/meetings" element={<PlaybookMeetings />} />
            <Route path="forum/:id/playbook/conferences" element={<PlaybookConferences />} />
            <Route
              path="forum/:id/playbook/conferences/facilities/:buildingId/:facilityId"
              element={<PlaybookFacility />}
            />
            <Route
              path="forum/:id/playbook/meetings/facilities/:buildingId/:facilityId"
              element={<PlaybookMeetingFacility />}
            />
            <Route
              path="forum/:id/playbook/conferences/facilities/:buildingId/:facilityId/sessions/:timeslotId/:sessionId"
              element={<PlaybookConferenceCheckIn />}
            />
            <Route
              path="forum/:id/playbook/meetings/facilities/:buildingId/:facilityId/sessions/:timeslotId/:sessionId"
              element={<PlaybookMeetingCheckIn />}
            />
          </Route>
          <Route path={"403"} element={<Page403 />} />
          <Route path={"*"} element={<NotFound />} />
        </Route>
        <Route path={"403"} element={<Page403 />} />
        <Route path={"*"} element={<Navigate to={"/en-GB/"} replace />} />
      </Routes>
    </Theme>
  );
}

export default App;
