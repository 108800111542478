import { useSeminarsAllSessionsQuery } from "api/queries/seminars";
import DateRenderer from "components/DateRenderer";
import { Button, Table } from "components/styleguide";
import { ForumSeminarSessionsModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, TableColumn } from "utils/tableColumnUtils";

import LinkToReport from "../../components/LinkToReport";

interface SessionsTableOptionProps {
  onSessionSelect?: (session: ForumSeminarSessionsModel) => void;
}

const SessionsTableOption = ({ onSessionSelect }: SessionsTableOptionProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [sessionCodeSearch, setSessionCodeSearch] = useState<string>();
  const [seminarCodeSearch, setSeminarCodeSearch] = useState<string>();
  const [facilityCodeSearch, setFacilityCodeSearch] = useState<string>();

  const { response: { isLoading, data: { data: { items: sessions = [] } = {} } = {} } = {}, pagination } =
    useModernQueryWithPaginationAndOrder(
      useSeminarsAllSessionsQuery,
      {},
      {
        forumId: toNumber(id),
        sessionCode: sessionCodeSearch,
        seminarCode: seminarCodeSearch,
        facilityCode: facilityCodeSearch,
      },
      { defaultPageSize: 10, pageSizeOptions: [10, 50, 100], showSizeChange: false },
    );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "sessionCode": {
        setSessionCodeSearch(selectedKeys[0]);
        break;
      }
      case "seminarCode": {
        setSeminarCodeSearch(selectedKeys[0]);
        break;
      }
      case "facilityCode": {
        setFacilityCodeSearch(selectedKeys[0]);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "sessionCode": {
        setSessionCodeSearch(undefined);
        break;
      }
      case "seminarCode": {
        setSeminarCodeSearch(undefined);
        break;
      }
      case "facilityCode": {
        setFacilityCodeSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = [
    new TableColumn(t("Session Code"), "sessionCode", {
      width: "15%",
      ...getColumnSearchProps("sessionCode", handleSearch, handleReset, "sessionCode", null),
    }),
    new TableColumn(t("Display Code"), "seminarCode", {
      ...getColumnSearchProps("seminarCode", handleSearch, handleReset, "seminarCode", null),
    }),
    new TableColumn(t("Facility Code"), "facilityCode", {
      ...getColumnSearchProps("facilityCode", handleSearch, handleReset, "facilityCode", null),
    }),
    new TableColumn(t("Start Date"), "startDate", {
      render: (start: Date) => <DateRenderer date={start} showTime />,
    }),
    new TableColumn(t("End Date"), "endDate", {
      render: (end: Date) => <DateRenderer date={end} showTime />,
    }),
    new TableColumn("", "id", {
      width: "10%",
      render: (sessionId: number, session: ForumSeminarSessionsModel) =>
        onSessionSelect ? (
          <Button onClick={() => onSessionSelect(session)}>{t("Select").toString()}</Button>
        ) : (
          <LinkToReport parameters={{ ForumSeminarSessionId: sessionId }} />
        ),
    }),
  ];

  return (
    <Table columns={columns} dataSource={sessions} loading={isLoading} rowKey="sessionCode" pagination={pagination} />
  );
};

export default SessionsTableOption;
