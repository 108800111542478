/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const CreateDocumentDefinitionTypes = {
    AttendeeSpeakerItineraryWithCover: 'AttendeeSpeakerItineraryWithCover',
    AttendeeSpeakerItineraryWithCoverSupplier: 'AttendeeSpeakerItineraryWithCoverSupplier',
    CpdCertificate: 'CPDCertificate',
    EnhancedCpd: 'EnhancedCPD',
    ItineraryCover2: 'ItineraryCover2',
    ItineraryWithCover: 'ItineraryWithCover',
    PiggyProvItinerary: 'PiggyProvItinerary',
    ProvItinerary: 'ProvItinerary',
    Questionnaire: 'Questionnaire',
    SelectionSite: 'SelectionSite',
    SpeakerPackWithCover: 'SpeakerPackWithCover',
    TestReason: 'TestReason',
    TransportVoucher: 'TransportVoucher'
} as const;

export type CreateDocumentDefinitionTypes = typeof CreateDocumentDefinitionTypes[keyof typeof CreateDocumentDefinitionTypes];



