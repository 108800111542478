import { isEqual, omit } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Tabs, Typography } from "../../../../../../../components/styleguide";
import ItemErrorsContext from "../../../../context/item-errors";
import { Input } from "../inputs";

const DefaultForm = ({ itemId, initialValues, options, dataNormalizer, onChange }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState([]);
  const [isValid, setIsValid] = useState(true);

  const itemErrorsContext = useContext(ItemErrorsContext);

  useEffect(() => {
    if (itemId === undefined) {
      console.warn("Item ID is not defined. Item errors will not be tracked.");
      return;
    }

    if (isValid) {
      itemErrorsContext.remove(itemId);
      return;
    }

    itemErrorsContext.add(itemId);
  }, [isValid, itemErrorsContext, itemId]);

  useEffect(() => {
    form
      .validateFields()
      .then(() => setIsValid(true))
      .catch((error) => {
        const fields = Array.isArray(error.errorFields) ? error.errorFields : [];
        setIsValid(fields.length === 0);
      });
  }, []);

  useEffect(() => {
    const formValues = form.getFieldsValue();
    const areEqual = isEqual(formValues, omit(initialValues, ["type"]));
    if (!areEqual) {
      form.setFieldsValue(initialValues);
    }
  }, [form, initialValues]);

  const handleFieldsChange = (changedFields, allFields) => {
    const newFields = dataNormalizer ? dataNormalizer(changedFields, allFields) : allFields;
    setFormValues(newFields);
  };

  const handleFormSubmit = async (values) => {
    setIsValid(true);
    const result = onChange(values);

    if (result instanceof Promise) {
      try {
        await result;
      } catch (error) {
        // Instead of calling reset method we use `setFieldsValue` to avoid
        // mounting and unmounting of Form.Item elements in the form
        // which cause our drawers to be closed or even whole form to be re-rendered.
        form.setFieldsValue(initialValues);
      }
    }
  };

  const optionsText = t("Options");
  const optionsTabHeader = isValid ? optionsText : <Typography.Text type={"danger"}>{optionsText}</Typography.Text>;

  return (
    <Form
      form={form}
      initialValues={initialValues}
      fields={formValues}
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFieldsChange={handleFieldsChange}
      onFinish={handleFormSubmit}
      onFinishFailed={() => setIsValid(false)}
    >
      <Tabs defaultActiveKey="details">
        <Tabs.TabPane key="details" tab={t("Details")} forceRender>
          <Form.Item label={t("Description")} name="description">
            <Input.TextArea />
          </Form.Item>
        </Tabs.TabPane>

        {options && (
          <Tabs.TabPane key="options" tab={optionsTabHeader} forceRender>
            {options(form)}
          </Tabs.TabPane>
        )}
      </Tabs>
    </Form>
  );
};

DefaultForm.propTypes = {
  itemId: PropTypes.number,
  initialValues: PropTypes.object,
  options: PropTypes.func,
  dataNormalizer: PropTypes.func,
  onChange: PropTypes.func,
};

DefaultForm.defaultProps = {
  itemId: undefined,
  initialValues: undefined,
  options: undefined,
  dataNormalizer: undefined,
  onChange: () => undefined,
};

export default DefaultForm;
