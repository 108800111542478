import { CloseCircleOutlined } from "@ant-design/icons";
import { BaseOptionType } from "antd/lib/select";
import { useForumGroupTasksAttendeesQuery } from "api/queries/attendees";
import { Button, Drawer, Space, Table } from "components/styleguide";
import {
  AttendeeTaskStatusModel,
  ForumGroupTaskModel,
  ForumGroupTaskStatus,
  PageResponseAttendeeTaskStatusModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { removeOccurrences } from "utils/arrayUtils";
import { getColumnSearchProps, getColumnSelectFilterProps } from "utils/tableColumnUtils";

import EmailTaskDrawer from "../EmailTaskDrawer";

import styles from "./styles.module.less";

const DataIndex = {
  Id: "id",
  FirstName: "firstName",
  LastName: "lastName",
  Company: "company",
  TaskStatus: "taskStatus",
};

type TaskStatusEnum = {
  [ForumGroupTaskStatus.NotStarted]: string;
  [ForumGroupTaskStatus.InProgress]: string;
  [ForumGroupTaskStatus.Completed]: string;
};

export default function AttendeeTaskStatusesDrawer({
  visible,
  forumId,
  groupId,
  task,
  onClose,
}: {
  visible: boolean;
  forumId: number;
  groupId: number;
  task: ForumGroupTaskModel | undefined;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  const taskStatusOptions: BaseOptionType[] = [
    { label: t("Draft"), value: ForumGroupTaskStatus.NotStarted },
    { label: t("Live"), value: ForumGroupTaskStatus.InProgress },
    { label: t("Closed"), value: ForumGroupTaskStatus.Completed },
  ];

  const TaskStatuses: TaskStatusEnum = {
    [ForumGroupTaskStatus.NotStarted]: t("Draft"),
    [ForumGroupTaskStatus.InProgress]: t("Live"),
    [ForumGroupTaskStatus.Completed]: t("Closed"),
  };

  const [attendees, setAttendees] = useState<AttendeeTaskStatusModel[]>();
  const [allAttendees, setAllAttendees] = useState<AttendeeTaskStatusModel[]>();
  const [selectedAttendees, setSelectedAttendees] = useState<AttendeeTaskStatusModel[]>([]);

  const [firstNameSearch, setFirstNameSearch] = useState<string | undefined>(undefined);
  const [lastNameSearch, setLastNameSearch] = useState<string | undefined>(undefined);
  const [companySearch, setCompanySearch] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<ForumGroupTaskStatus[] | undefined>(undefined);

  const [allFlag, setAllFlag] = useState(false);
  const [isEmailDrawerVisible, setIsEmailDrawerVisible] = useState(false);

  const showEmailDrawer = () => setIsEmailDrawerVisible(true);
  const hideEmailDrawer = () => setIsEmailDrawerVisible(false);

  const isFiltered = !!firstNameSearch || !!lastNameSearch || !!companySearch || !!statusFilter;

  const {
    response: { isLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useForumGroupTasksAttendeesQuery,
    {
      enabled: !!task?.id,
      onSuccess: ({ data }: { data: PageResponseAttendeeTaskStatusModel }) => {
        setAttendees(data.items);
      },
    },
    {
      forumId,
      groupId,
      taskId: task?.id,
      firstName: firstNameSearch,
      lastName: lastNameSearch,
      company: companySearch,
      statuses: statusFilter,
    },
  );

  const { isLoading: isLoadingAllAttendees } = useForumGroupTasksAttendeesQuery(
    {
      forumId: forumId,
      groupId: groupId,
      taskId: task?.id as number,
      firstName: firstNameSearch,
      lastName: lastNameSearch,
      company: companySearch,
      statuses: statusFilter,
    },
    {
      enabled: !!task?.id,
      onSuccess: ({ data }: { data: PageResponseAttendeeTaskStatusModel }) => {
        setAllAttendees(data.items);
      },
    },
  );

  const rowSelection = {
    onSelect: (selectedRow: AttendeeTaskStatusModel, isSelection: boolean) => {
      if (isSelection) {
        setSelectedAttendees([...selectedAttendees, selectedRow]);
        setAllFlag(false);
      } else {
        setSelectedAttendees(removeOccurrences(selectedAttendees, selectedRow, "id"));
        setAllFlag(false);
      }
    },
    onSelectAll: (isSelection: boolean, _: unknown, newSelected: AttendeeTaskStatusModel[]) => {
      if (isSelection) {
        setSelectedAttendees([...selectedAttendees, ...newSelected]);
        setAllFlag(false);
      } else {
        setSelectedAttendees(removeOccurrences(selectedAttendees, newSelected, "id"));
        setAllFlag(false);
      }
    },

    selectedRowKeys: selectedAttendees.map((attendee) => attendee.id),
  };

  const handleUnselectAllAttendees = () => {
    setSelectedAttendees([]);
    setAllFlag(false);
  };

  const handleSelectAll = () => {
    if (allFlag) {
      handleUnselectAllAttendees();
    } else {
      if (allAttendees) {
        setSelectedAttendees([...allAttendees]);
      }

      setAllFlag(true);
    }
  };

  const handleSearch = (
    selectedKeys: string[] | BaseOptionType[],
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case DataIndex.FirstName: {
        setFirstNameSearch(selectedKeys[0] as string);
        break;
      }
      case DataIndex.LastName: {
        setLastNameSearch(selectedKeys[0] as string);
        break;
      }
      case DataIndex.Company: {
        setCompanySearch(selectedKeys[0] as string);
        break;
      }
      case DataIndex.TaskStatus: {
        setStatusFilter((selectedKeys as BaseOptionType[]).map(({ value }) => value));
        break;
      }
    }
    confirm?.();
  };
  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case DataIndex.FirstName: {
        setFirstNameSearch(undefined);
        break;
      }
      case DataIndex.LastName: {
        setLastNameSearch(undefined);
        break;
      }
      case DataIndex.Company: {
        setCompanySearch(undefined);
        break;
      }
      case DataIndex.TaskStatus: {
        setStatusFilter(undefined);
        break;
      }
    }
    clearFilters?.();
    handleUnselectAllAttendees();
    confirm?.();
  };

  const columns = [
    {
      title: t("First Name"),
      dataIndex: DataIndex.FirstName,
      ...(getColumnSearchProps(
        DataIndex.FirstName,
        handleSearch,
        handleReset,
        DataIndex.FirstName,
      ) as Partial<AttendeeTaskStatusModel>),
    },
    {
      title: t("Last Name"),
      dataIndex: DataIndex.LastName,
      ...(getColumnSearchProps(
        DataIndex.LastName,
        handleSearch,
        handleReset,
        DataIndex.LastName,
      ) as Partial<AttendeeTaskStatusModel>),
    },
    {
      title: t("Company"),
      dataIndex: DataIndex.Company,
      ...(getColumnSearchProps(
        DataIndex.Company,
        handleSearch,
        handleReset,
        DataIndex.Company,
      ) as Partial<AttendeeTaskStatusModel>),
    },
    {
      title: t("Task Status"),
      dataIndex: "taskStatus",
      render: (taskStatus: string) => {
        return TaskStatuses[taskStatus as keyof TaskStatusEnum];
      },
      ...(getColumnSelectFilterProps(
        DataIndex.TaskStatus,
        handleSearch,
        handleReset,
        DataIndex.TaskStatus,
        taskStatusOptions,
      ) as Partial<AttendeeTaskStatusModel>),
    },
  ];

  return (
    <Drawer
      title={t("Attendee Task Statuses")}
      placement="right"
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "40%" }}
      destroyOnClose={true}
    >
      <EmailTaskDrawer
        forumId={toNumber(forumId)}
        attendees={selectedAttendees}
        visible={isEmailDrawerVisible}
        onClose={hideEmailDrawer}
        selectedAll={allFlag}
        isFiltered={isFiltered}
        taskCategory={task?.category}
      />
      <div className={styles.controls}>
        <Space>
          <Button
            onClick={showEmailDrawer}
            loading={isLoadingAllAttendees || isLoading}
            disabled={selectedAttendees.length === 0 || isLoadingAllAttendees || isLoading}
          >
            {t("Send Email")}
          </Button>
          <Button onClick={handleSelectAll} loading={isLoadingAllAttendees} disabled={isLoadingAllAttendees}>
            {selectedAttendees?.length && selectedAttendees?.length > 0 && allFlag
              ? t("Unselect all attendees")
              : t("Select all attendees")}
          </Button>
          {!!selectedAttendees?.length && (
            <div className={styles.selectedAttendees}>
              {selectedAttendees?.length} items selected{" "}
              <CloseCircleOutlined onClick={handleUnselectAllAttendees} className={styles.removeSelection} />
            </div>
          )}
        </Space>
      </div>
      <Table
        columns={columns}
        rowSelection={rowSelection}
        dataSource={attendees}
        loading={isLoading}
        pagination={pagination}
        onChange={handleSort}
        rowKey="id"
      />
    </Drawer>
  );
}
