import { useQueryClient } from "@tanstack/react-query";
import { useAddPersonsAsAttnedeesMutation } from "api/mutations/attendees";
import KEYS from "api/queries/keys";
import { useCompanyPersonsQuery } from "api/queries/persons";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Space, Table } from "components/styleguide";
import { PageResponsePersonWithParticipationModel, PersonWithParticipationModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import EditAttendeeForm from "../EditAttendeeForm";

const CompanyAttendees = ({
  visible,
  onClose,
  companyId,
}: {
  visible: boolean;
  onClose: () => void;
  companyId: number | null;
}) => {
  const { t } = useTranslation();
  const forumId = useParams().id;
  const queryClient = useQueryClient();

  const [attendees, setAttendees] = useState<PersonWithParticipationModel[] | undefined>();
  const [personIds, setPersonIds] = useState<number[]>([]);

  const [isEditFormVisible, setIsEditFormVisible] = useState(false);

  const showEditForm = () => {
    return setIsEditFormVisible(true);
  };

  const hideEditForm = () => {
    return setIsEditFormVisible(false);
  };
  const addAttendeesMutation = useAddPersonsAsAttnedeesMutation();

  const { response: attendeesQuery, pagination } = useModernQueryWithPaginationAndOrder(
    useCompanyPersonsQuery,
    {
      onSuccess: ({ data }: { data: PageResponsePersonWithParticipationModel }) => {
        setAttendees(data.items);
      },
      enabled: visible,
    },
    {
      forumId: forumId,
      companyId: companyId,
    },
    { defaultPageSize: 10, pageSizeOptions: [10], showSizeChange: false },
  );

  const addAttendees = () => {
    addAttendeesMutation.mutate(
      { forumId: toNumber(forumId), requestBody: personIds },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_COMPANY_PERSONS]);
          queryClient.resetQueries([KEYS.GET_ATTENDEES]);
          onClose();
        },
      },
    );
  };

  const columns = [
    {
      title: t("First Name"),
      dataIndex: "firstName",
    },
    {
      title: t("Last Name"),
      dataIndex: "lastName",
    },
    {
      title: t("Participation Code"),
      dataIndex: "peopleType",
    },
    {
      title: t("Participates in Forum"),
      dataIndex: "participation",
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setPersonIds(selectedRowKeys.map((k) => toNumber(k)));
    },

    getCheckboxProps: (record: PersonWithParticipationModel) => ({
      disabled: record.participation === "Yes",
    }),
  };

  return (
    <Drawer
      title={t("Company Attendees")}
      placement="right"
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose={true}
    >
      <EditAttendeeForm visible={isEditFormVisible} onClose={hideEditForm} companyId={companyId} />
      <div>
        <FullHeightForm onFinish={addAttendees} className="add-persons-form">
          <Space direction={"vertical"} size={"middle"}>
            <Space direction={"horizontal"}>
              <Button
                htmlType="submit"
                loading={addAttendeesMutation.isLoading}
                disabled={addAttendeesMutation.isLoading || (personIds != null && personIds.length == 0)}
              >
                {t("Add Attendee")}
              </Button>
              <Button onClick={showEditForm} loading={attendeesQuery.isLoading}>
                {t("Create Attendee")}
              </Button>
            </Space>
            <Table
              columns={columns}
              dataSource={attendees}
              pagination={pagination}
              rowSelection={rowSelection}
              rowKey="id"
              loading={attendeesQuery.isLoading}
            />
          </Space>
        </FullHeightForm>
      </div>
    </Drawer>
  );
};

CompanyAttendees.propTypes = { visible: PropTypes.bool, onClose: PropTypes.func, company: PropTypes.object };

export default CompanyAttendees;
