import { Item } from "pages/Reporting/helpers";
import { TFunction } from "react-i18next";

import { EventLayoutLocation } from "./types";

const getBreadcrumbsItems = ({
  t,
  location,
  attendeeName,
  companyName,
  venueName,
  buildingName,
  reportKey,
  category,
  subcategory,
  subcategory2,
  subcategory3,
  subcategory4,
  flatReports,
}: {
  t: TFunction<"translation", undefined>;
  location: EventLayoutLocation;
  attendeeName?: string;
  companyName?: string;
  venueName?: string;
  buildingName?: string;
  reportKey?: string;
  category?: string;
  subcategory?: string;
  subcategory2?: string;
  subcategory3?: string;
  subcategory4?: string;
  flatReports: Array<Item>;
}) => {
  const reportBreadcrumb = {
    path: "report",
    breadcrumb: null,
    children: [
      { path: ":reportKey", breadcrumb: t(flatReports.find((item) => reportKey === item.key)?.label as string) },
    ],
  };

  const reportOptionsBreadcrumb = {
    path: "options",
    breadcrumb: null,
    children: [
      {
        path: ":reportKey",
        breadcrumb: t("Options"),
        children: [
          {
            path: "report",
            breadcrumb: t(flatReports.find((item) => reportKey === item.key)?.label as string),
          },
        ],
      },
    ],
  };

  const breadcrumbs = [
    {
      path: "/:lang",
      breadcrumb: null,
      children: [
        {
          path: "forum/:id",
          breadcrumb: null,
          children: [
            {
              path: "dashboard",
              breadcrumb: t("breadcrumbs.dashboard"),
              children: [],
            },
            {
              path: "attendees",
              breadcrumb: t("breadcrumbs.attendees"),
              children: [
                {
                  path: ":attendeeId",
                  breadcrumb: attendeeName,
                },
                {
                  path: "company",
                  breadcrumb: null,
                  children: [
                    {
                      path: ":companyId",
                      breadcrumb: companyName,
                    },
                  ],
                },
              ],
            },
            {
              path: "venues",
              breadcrumb: null,
              children: [
                {
                  path: ":venueId",
                  breadcrumb: venueName ? `${t("Facilities")} (${venueName})` : t("loading"),
                  children: [
                    {
                      path: "buildings",
                      breadcrumb: null,
                      children: [
                        {
                          path: ":buildingId",
                          breadcrumb: buildingName ?? t("loading"),
                          state: {
                            ...location.state,
                            buildingName: buildingName,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "forum-admin",
              breadcrumb: t("breadcrumbs.forumAdmin"),
              children: [
                {
                  path: "general",
                  breadcrumb: null,
                },
                {
                  path: "groups",
                  breadcrumb: t("Groups"),
                },
                {
                  path: "it-contract",
                  breadcrumb: t("IT Contract"),
                },
                {
                  path: "timeslots",
                  breadcrumb: t("Timeslots"),
                  children: [
                    {
                      path: "new",
                      breadcrumb: t("New Timeslot"),
                    },
                    {
                      path: ":timeslotId",
                      breadcrumb: null,
                      children: [
                        {
                          path: "edit",
                          breadcrumb: t("Edit Timeslot"),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "questionnaires",
                  breadcrumb: t("breadcrumbs.questionnaires"),
                  children: [
                    {
                      path: "new",
                      breadcrumb: t("breadcrumbs.newQuestionnaire"),
                    },
                    {
                      path: ":questionnaireId",
                      breadcrumb: location.state?.questionnaireName || t("loading"),
                      state: {
                        ...location.state,
                        questionnaireName: location.state?.questionnaireName,
                        children: [
                          {
                            path: "import",
                            children: [
                              {
                                path: ":importForumId",
                                breadcrumb: null,
                                children: [
                                  {
                                    path: ":importQuestionnaireId",
                                    breadcrumb: location.state?.importQuestionnaireName || t("loading"),
                                    state: {
                                      ...location.state,
                                      importQuestionnaireName: location.state?.importQuestionnaireName,
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    },
                  ],
                },
                {
                  path: "selection-sites",
                  breadcrumb: t("breadcrumbs.selectionSites"),
                  children: [
                    {
                      path: ":selectionSiteId",
                      breadcrumb: location.state?.selectionSiteName || t("loading"),
                      state: {
                        ...location.state,
                        selectionSiteName: location.state?.selectionSiteName,
                      },
                    },
                  ],
                },
                {
                  path: "manage-virtual-forum",
                  breadcrumb: t("Manage Virtual Forum"),
                },
                {
                  path: "email-templates",
                  breadcrumb: "Email Templates",
                  children: [
                    {
                      path: "new",
                      breadcrumb: "New Email Template",
                    },
                    {
                      path: "edit",
                      breadcrumb: null,
                      children: [
                        {
                          path: ":templateId",
                          breadcrumb: "Edit Email Template",
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "itinerary",
                  breadcrumb: t("Itinerary"),
                  children: [
                    {
                      path: "essentials",
                      breadcrumb: t("Essentials"),
                    },
                    {
                      path: "test",
                      breadcrumb: t("Test"),
                    },
                    {
                      path: "advanced",
                      breadcrumb: t("Advanced"),
                    },
                    {
                      path: "create",
                      breadcrumb: t("Create"),
                      children: [
                        {
                          path: "queue",
                          breadcrumb: t("Queue"),
                        },
                        {
                          path: "completed",
                          breadcrumb: t("Completed"),
                        },
                        {
                          path: "completed/:documentId",
                          breadcrumb: null,
                        },
                      ],
                    },
                    {
                      path: "booklet-profile-setup",
                      breadcrumb: t("Booklet Profile Setup"),
                    },
                  ],
                },
              ],
            },
            {
              path: "reporting",
              breadcrumb: t("Reporting"),
              children: [
                reportBreadcrumb,
                {
                  path: ":category",
                  breadcrumb: t(flatReports.find((item) => category === item.key)?.label as string),
                  children: [
                    reportBreadcrumb,
                    reportOptionsBreadcrumb,
                    {
                      path: ":subcategory",
                      breadcrumb: t(flatReports.find((item) => subcategory === item.key)?.label as string),
                      children: [
                        reportBreadcrumb,
                        reportOptionsBreadcrumb,
                        {
                          path: ":subcategory2",
                          breadcrumb: t(flatReports.find((item) => subcategory2 === item.key)?.label as string),
                          children: [
                            reportBreadcrumb,
                            reportOptionsBreadcrumb,
                            {
                              path: ":subcategory3",
                              breadcrumb: t(flatReports.find((item) => subcategory3 === item.key)?.label as string),
                              children: [
                                reportBreadcrumb,
                                reportOptionsBreadcrumb,
                                {
                                  path: ":subcategory4",
                                  breadcrumb: t(flatReports.find((item) => subcategory4 === item.key)?.label as string),
                                  children: [reportBreadcrumb, reportOptionsBreadcrumb],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "conference",
              breadcrumb: t("breadcrumbs.conference"),
              children: [
                {
                  path: "stream",
                  breadcrumb: null,
                },
                {
                  path: "seminars",
                  breadcrumb: t("Seminars"),
                },
                {
                  path: "speaker-visuals",
                  breadcrumb: t("Speaker Visuals"),
                  children: [
                    {
                      path: ":seminarId",
                      breadcrumb: location.state?.seminarCode || t("loading"),
                      state: {
                        ...location.state,
                        seminarName: location.state?.seminarName,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  return breadcrumbs;
};

export { getBreadcrumbsItems };
