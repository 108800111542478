/* eslint-disable no-empty-pattern */
import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import React from "react";

import { useDeleteForumTableMutation } from "../api/mutations/forumTables";
import { useDeleteTableMutation } from "../api/mutations/tables";
import { useBedroomsQuery, useFacilityQuery } from "../api/queries/facilities";
import { useForumBedroomsQuery, useForumFacilityQuery } from "../api/queries/forumFacilities";
import { useForumTablesQuery } from "../api/queries/forumTables";
import { useTablesQuery } from "../api/queries/tables";
import {
  AttendeeModel,
  FacilityRequestModel,
  FacilityResponseModel,
  FileExportType,
  FloorModel,
  RoomGradeResponseModel,
} from "../generated/api";
import { useQueryWithPaginationAndOrder } from "../hooks";

type UseDeleteTableMutationOptions = Parameters<ReturnType<typeof useDeleteTableMutation>["mutate"]>[1];

type UseDeleteForumTableMutationOptions = Parameters<ReturnType<typeof useDeleteForumTableMutation>["mutate"]>[1];

const defaultPaginationResult = {
  response: [],
  pagination: {
    pageSize: 0,
    pageSizeOptions: [],
    showSizeChanger: false,
    onChange: () => {},
    onShowSizeChange: () => {},
  },
  handleSort: () => undefined,
};

const VenueContext = React.createContext<{
  // Facilities

  useFacilityQuery: (
    {
      forumOrVenueId,
      buildingId,
      facilityId,
    }: {
      forumOrVenueId?: number;
      buildingId?: number;
      facilityId: number;
    },
    options: Parameters<typeof useFacilityQuery>[1] | Parameters<typeof useForumFacilityQuery>[1],
  ) => ReturnType<typeof useFacilityQuery | typeof useForumFacilityQuery>;
  setCodeSearch: (value: string) => void;
  setNameSearch: (value: string) => void;
  setDescriptionSearch: (value: string) => void;
  onFacilityCreateSubmit: (value: FacilityRequestModel) => void;
  isFacilityCreateLoading: boolean;
  onFacilityEditSubmit: (value: FacilityRequestModel) => void;
  isFacilityEditLoading: boolean;
  onFacilityDeleteSubmit: (value: FacilityResponseModel) => void;

  // Floors

  floors: Array<FloorModel>;
  onFloorAdd: (floorName: string) => void;
  onFloorDelete: (floorId: number) => void;

  // Meeting Facilities

  meetingRoomsQuery: ReturnType<typeof useQueryWithPaginationAndOrder>;
  conferenceRoomsQuery: ReturnType<typeof useQueryWithPaginationAndOrder>;
  speedMeetingsQuery: ReturnType<typeof useQueryWithPaginationAndOrder>;
  restaurantsQuery: ReturnType<typeof useQueryWithPaginationAndOrder>;

  // Tables

  useTablesQuery: typeof useTablesQuery | typeof useForumTablesQuery;
  deleteTableMutate: (
    { buildingId, facilityId, tableId }: { buildingId?: number; facilityId?: number; tableId: number },
    options: UseDeleteTableMutationOptions | UseDeleteForumTableMutationOptions,
  ) => void;

  // Bedrooms

  bedroomsQuery: typeof useBedroomsQuery | typeof useForumBedroomsQuery;
  exportBedroomsQuery: UseQueryResult<AxiosResponse<string>>;
  setRoomGradeSearch: (value: string) => void;
  roomGrades: RoomGradeResponseModel[];
  onRoomGradeAdd: (name: string) => void;
  onRoomGradeDelete: (id: number) => void;
  attendees: Array<AttendeeModel>;
  setAttendeeSearch: (value: string) => void;
  setBillingNoteSearch: (value: string) => void;
  setExportBedroomsDocumentType: (documentType: FileExportType | null) => void;
  onBedroomTableChange?: () => void;
}>({
  useFacilityQuery: ({}, options) => useFacilityQuery({ venueId: 0, buildingId: 0, facilityId: 0 }, options),
  setCodeSearch: () => {},
  setNameSearch: () => {},
  setDescriptionSearch: () => {},
  onFacilityCreateSubmit: () => {},
  isFacilityCreateLoading: false,
  onFacilityEditSubmit: () => {},
  isFacilityEditLoading: false,
  onFacilityDeleteSubmit: () => {},
  floors: [],
  onFloorAdd: () => {},
  onFloorDelete: () => {},
  meetingRoomsQuery: defaultPaginationResult,
  conferenceRoomsQuery: defaultPaginationResult,
  speedMeetingsQuery: defaultPaginationResult,
  restaurantsQuery: defaultPaginationResult,
  useTablesQuery: useTablesQuery,
  deleteTableMutate: () => {},
  bedroomsQuery: useBedroomsQuery,
  exportBedroomsQuery: {} as UseQueryResult<AxiosResponse<string>>,
  setRoomGradeSearch: () => {},
  roomGrades: [],
  onRoomGradeAdd: () => {},
  onRoomGradeDelete: () => {},
  attendees: [],
  setAttendeeSearch: () => {},
  setBillingNoteSearch: () => {},
  setExportBedroomsDocumentType: () => {},
});

export default VenueContext;
