import { FormInstance } from "antd";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Spin } from "components/styleguide";
import { ForumDocumentDefinitionSectionResponseModel } from "generated/api";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useForumDocumentDefinitionWatermarksQuery } from "../../../../../../api/queries/forumDocumentDefinition";
import { useUpdateSectionAndSectionOptions } from "../../hooks/useUpdateSectionAndSectionOptions";
import { ReactQuillType } from "../SelectionOptions/TextEditorOption";
import UploadWatermarkDrawer from "./UploadWatermarkDrawer";
import Watermarks from "./Watermarks";

interface WatermarkProps {
  documentDefinitionSection?: ForumDocumentDefinitionSectionResponseModel;
  forumId: number;
  documentDefinitionSectionId: number;
  onClose: () => void;
  sectionForm: FormInstance;
  sectionOptionsForm: FormInstance;
  quills: ReactQuillType[];
}

const Watermark: React.FC<WatermarkProps> = ({
  documentDefinitionSection,
  forumId,
  documentDefinitionSectionId,
  onClose,
  sectionForm,
  sectionOptionsForm,
  quills,
}) => {
  const { t } = useTranslation();

  const [isUploadDrawerVisible, setIsUploadDrawerVisible] = useState<boolean>(false);
  const [selectedWatermarkId, setSelectedWatermarkId] = useState<number | null>(
    documentDefinitionSection?.watermarkId ?? null,
  );

  const { data: { data: watermarks = [] } = {}, isLoading } = useForumDocumentDefinitionWatermarksQuery({ forumId });

  const { submit, isMutationLoading } = useUpdateSectionAndSectionOptions({
    forumId,
    documentDefinitionSectionId,
    onClose,
    quills,
  });

  useEffect(() => {
    if (!selectedWatermarkId) {
      sectionForm.resetFields(["watermarkId"]);
    }
  }, [selectedWatermarkId]);

  const showUploadDrawer = () => setIsUploadDrawerVisible(true);

  const onChangeWatermark = (watermarkId: number) => setSelectedWatermarkId(watermarkId);
  const deselectWatermark = () => setSelectedWatermarkId(null);

  return (
    <>
      <UploadWatermarkDrawer
        forumId={forumId}
        isVisible={isUploadDrawerVisible}
        setIsUploadDrawerVisible={setIsUploadDrawerVisible}
      />
      <Button onClick={showUploadDrawer}>{t("Upload Watermark")}</Button>
      {isLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <FullHeightForm
          form={sectionForm}
          onFinish={(values) =>
            submit({ ...documentDefinitionSection, ...values }, sectionOptionsForm.getFieldsValue())
          }
          layout="vertical"
          actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
          actionsAppend={
            <Button htmlType="submit" type="primary" loading={isMutationLoading}>
              {t("Update")}
            </Button>
          }
        >
          <Form.Item initialValue={selectedWatermarkId} name="watermarkId">
            <Watermarks
              forumId={forumId}
              watermarks={watermarks}
              onChange={onChangeWatermark}
              deselect={deselectWatermark}
            />
          </Form.Item>
        </FullHeightForm>
      )}
    </>
  );
};

export default Watermark;
