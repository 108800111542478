import { ItineraryBookletsForumTimeTypeLabelApi } from "../../generated/api/apiPackage/itinerary-booklets-forum-time-type-label-api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ItineraryBookletsForumTimeTypeLabelApi);

const ItineraryBookletsForumTimeTypeLabel = {
  get: api.forumsForumIdItinerarybookletsTimeTypeLabelsGet,
  put: api.forumsForumIdItinerarybookletsTimeTypeLabelsTimeTypeLabelIdPut,
};

export default ItineraryBookletsForumTimeTypeLabel;
