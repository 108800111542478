import { Space } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import { AttendeeModel } from "generated/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import LinkToReport from "../components/LinkToReport";
import { DefaultSortOptions } from "../constants";
import AttendeesTableOption from "../OptionTypes/AttendeesTableOption";
import PeopleTypeSelectOption from "../OptionTypes/PeopleTypeSelectOption";
import SortOrderOption from "../OptionTypes/SortByOption";

interface IndividualCancellationsProps {
  showAttendeesTableOption?: boolean;
  showPeopleTypeOption?: boolean;
}

const FollowUpAddressesOptions = ({
  showAttendeesTableOption = false,
  showPeopleTypeOption = false,
}: IndividualCancellationsProps) => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Company);
  const [peopleTypes, setPeopleTypes] = useState<string[]>([]);

  const onAttendeeSelect = (attendee: AttendeeModel) => {
    navigate("report", {
      state: {
        parameters: {
          OrderBy: orderBy,
          ForumAttendeeId: attendee.attendId,
        },
      },
    });
  };

  return (
    <Space direction={"vertical"} size={"middle"} fullWidth>
      <Space align="end">
        <SortOrderOption options={DefaultSortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
        {showPeopleTypeOption && (
          <>
            <PeopleTypeSelectOption peopleTypes={peopleTypes} setPeopleTypes={setPeopleTypes} />
            <LinkToReport
              disabled={!peopleTypes.length}
              parameters={{ OrderBy: orderBy, ParticipationCode: peopleTypes }}
            />
          </>
        )}
      </Space>

      {showAttendeesTableOption && (
        <AttendeesTableOption
          onAttendeeSelect={onAttendeeSelect}
          additionalFilters={{ attendeeStatus: [AttendeeStatus.Attending, AttendeeStatus.AttendingReplacement] }}
        />
      )}
    </Space>
  );
};

export default FollowUpAddressesOptions;
