import collectReferences from "./collect-references";
import ContentItem from "./content-item";

const getDependentItems = (dependentItems: Set<ContentItem>, item: ContentItem) => {
  const pageId = item.pageId;
  const itemId = item.value.id;

  const result = new Set<ContentItem>();

  dependentItems.forEach((entry) => {
    const refs = collectReferences(entry);
    if (refs.pages.has(pageId) || refs.items.has(itemId)) {
      result.add(entry);
    }
  });

  return result;
};

export default getDependentItems;
