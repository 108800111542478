import { FacilityApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(FacilityApi);

const Facilities = {
  getAll: api.venuesVenueIdBuildingsBuildingIdFacilitiesGet,
  get: api.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdGet,
  post: api.venuesVenueIdBuildingsBuildingIdFacilitiesPost,
  put: api.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdPut,
  delete: api.venuesVenueIdBuildingsBuildingIdFacilitiesFacilityIdDelete,
};

export default Facilities;
