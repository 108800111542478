import { Drawer } from "antd";
import { Tabs } from "components/styleguide";
import { TimeSlotType } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { filterTabs } from "../../helpers";
import SeminarSessionsMoveLocation from "./SeminarSessionsMoveLocation";
import SeminarSessionsMoveTiming from "./SeminarSessionsMoveTiming";

interface SeminarSessionsMoveDrawerProps {
  itineraryId: number;
  isSpeaker: boolean;
  timeSlotType: TimeSlotType;
  timeSlotSessionId: number | null;
  facilityId: number | null;
  seminarId: number | null;
  isVisible: boolean;
  onClose: () => void;
}

const SeminarSessionsMoveDrawer: FC<SeminarSessionsMoveDrawerProps> = ({
  itineraryId,
  isSpeaker,
  timeSlotType,
  timeSlotSessionId,
  facilityId,
  seminarId,
  isVisible,
  onClose,
}) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("Timing"),
      key: "timing",
      children: facilityId && seminarId && (
        <SeminarSessionsMoveTiming {...{ itineraryId, facilityId, seminarId, isSpeaker, timeSlotType, onClose }} />
      ),
    },
    {
      label: t("Location"),
      key: "location",
      children: facilityId && timeSlotSessionId && (
        <SeminarSessionsMoveLocation {...{ itineraryId, timeSlotSessionId, facilityId, onClose }} />
      ),
    },
  ];

  const getTabs = () => {
    if (!isSpeaker) {
      return filterTabs(tabs, ["timing"]);
    }

    return tabs;
  };

  return (
    <Drawer
      title={t("Moving bookings")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Tabs items={getTabs()} />
    </Drawer>
  );
};

export default SeminarSessionsMoveDrawer;
