import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateForumAttendeeAvailibilityMutation,
  useDeleteForumAttendeeAvailibilityMutation,
  useUpdateForumAttendeeAvailibilityMutation,
} from "api/mutations/forumAttendeeAvailability/intex";
import { useForumAttendeeAvailibilitiesQuery } from "api/queries/forumAttendeeAvailability/intex";
import KEYS from "api/queries/keys";
import { Button, DatePicker, Form, Modal, Space, Table, Typography } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import { ForumAttendeeAvailabilityResponseModel } from "generated/api";
import { EventLayoutContext } from "layouts/EventLayout/types";
import moment, { Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { getDisabledDates } from "utils/dateUtils";

import AvailabilityCell from "./AvailabilityCell";

import styles from "./styles.module.less";

export const columnDataIndexes = {
  start: "unavailableFromDate",
  end: "unavailableToDate",
  duration: "duration",
  id: "id",
};

const { Text } = Typography;
const { RangePicker } = DatePicker;

const dateTimeFormat = "L HH:mm";

export default function AttendeeAvailability({ forumId, attendeeId }: { forumId: number; attendeeId: number }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [addAvailabilityForm] = Form.useForm();
  const { forum } = useOutletContext<EventLayoutContext>();

  const [editingId, setEditingId] = useState<number | null>();

  const createMutation = useCreateForumAttendeeAvailibilityMutation();
  const updateMutation = useUpdateForumAttendeeAvailibilityMutation();
  const deleteMutation = useDeleteForumAttendeeAvailibilityMutation();


  useEffect(() => {
    if (forum?.startDate && forum?.endDate) {
      addAvailabilityForm.setFieldValue("range", [moment(forum.startDate), moment(forum.endDate)]);
    }
  }, [forum?.startDate, forum?.endDate]);

  const { data: { data: availabilities = [] } = {}, isLoading: isLoadingAvailabilities } =
    useForumAttendeeAvailibilitiesQuery({
      forumId,
      attendeeId,
    });

  const handleEdit = (id: number | null) => {
    form.resetFields();
    setEditingId(id);
  };

  const handleUpdate = ({ range }: { range: [Moment | null, Moment | null] }) => {
    const forumAttendeeAvailabilityRequestModel = {
      unavailableFromDate: moment(range[0]).format("YYYY-MM-DDTHH:mm"),
      unavailableToDate: moment(range[1]).format("YYYY-MM-DDTHH:mm"),
    };

    updateMutation.mutate(
      {
        forumId,
        attendeeId,
        availabilityId: editingId as number,
        forumAttendeeAvailabilityRequestModel,
      },
      {
        onSuccess: () => {
          form.resetFields();
          setEditingId(null);
          queryClient.resetQueries([KEYS.GET_ATTENDEE_AVAILABILITIES]);
        },
        onError: () => {
          Modal.error({
            title: t("Error"),
            content: t("The attendee cannot be set as unavailable as he already has bookings for this period"),
            okText: t("Ok"),
          });
        },
      },
    );
  };

  const handleDelete = async (availabilityId: number) => {
    Modal.confirm({
      title: t("confirmation"),
      content: t("Do you want to proceed with deletion of the selected period?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: async () => {
        await deleteMutation.mutate(
          { forumId, attendeeId, availabilityId },
          {
            onSuccess: () => queryClient.resetQueries([KEYS.GET_ATTENDEE_AVAILABILITIES]),
          },
        );
      },
    });
  };

  const columns = [
    {
      title: t("Start"),
      dataIndex: columnDataIndexes.start,
    },
    {
      title: t("End"),
      dataIndex: columnDataIndexes.end,
    },
    {
      title: t("Duration"),
      dataIndex: columnDataIndexes.duration,
      render: (_: unknown, record: ForumAttendeeAvailabilityResponseModel) => {
        const start = moment(record.unavailableFromDate);
        const end = moment(record.unavailableToDate);

        const duration = moment.duration(end.diff(start));
        return (
          <span>
            {t("{days} days, {hours} hours, {minutes} minutes", {
              days: duration.days(),
              hours: duration.hours(),
              minutes: duration.minutes(),
            })}
          </span>
        );
      },
    },
    {
      dataIndex: columnDataIndexes.id,
      render: (id: number) => (
        <>
          {id === editingId ? (
            <div className={styles.buttonWrapper}>
              <Button type="primary" htmlType="submit">
                {t("Save")}
              </Button>
              <Button onClick={() => handleEdit(null)}>{t("Cancel")}</Button>
            </div>
          ) : (
            <TableRowActionButtons onEdit={() => handleEdit(id)} onDelete={() => handleDelete(id)} />
          )}
        </>
      ),
    },
  ];

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record: ForumAttendeeAvailabilityResponseModel) => {
        return {
          record: record,
          index: col.dataIndex,
          title: col.title,
          editing: editingId === record.id,
          dateTimeFormat,
          disabledDate: (value: Moment) => getDisabledDates(value, forum),
        };
      },
    };
  });

  const getFormattedDate = (date: Moment) => date.format("YYYY-MM-DDTHH:mm");

  const handleSubmit = ({ range }: { range: Array<Moment> }) => {
    const forumAttendeeAvailabilityRequestModel = {
      unavailableFromDate: getFormattedDate(range[0]),
      unavailableToDate: getFormattedDate(range[1]),
    };

    createMutation.mutate(
      { forumId, attendeeId, forumAttendeeAvailabilityRequestModel },
      {
        onSuccess: () => queryClient.resetQueries([KEYS.GET_ATTENDEE_AVAILABILITIES]),
        onError: () =>
          toast.error(t("The attendee cannot be set as unavailable as he already has bookings for this period")),
      },
    );
  };

  return (
    <>
      <Space fullWidth={true} direction="vertical">
        <Text type="secondary">
          {t("Specify the day(s) and times when this attendee is NOT available for meetings and conference sessions")}
        </Text>
        <Form form={addAvailabilityForm} layout="inline" onFinish={handleSubmit}>
          <Form.Item
            name="range"
            rules={[{ required: true, message: t("errors.required", { prop: "$t(Start and End Time)" }) }]}
            initialValue={[moment(forum?.startDate), moment(forum?.endDate)]}
          >
            <RangePicker
              showTime={true}
              format={dateTimeFormat}
              style={{ minWidth: "350px" }}
              disabledDate={(value: Moment) => getDisabledDates(value, forum)}
              defaultPickerValue={[moment(forum?.startDate), moment(forum?.endDate)]}
              minuteStep={5}
            />
          </Form.Item>
          <Button htmlType="submit" loading={createMutation.isLoading} disabled={createMutation.isLoading}>
            {t("Add")}
          </Button>
        </Form>
        <Form form={form} onFinish={handleUpdate}>
          <Table
            columns={mergedColumns}
            loading={isLoadingAvailabilities}
            dataSource={availabilities}
            pagination={false}
            components={{
              body: {
                cell: AvailabilityCell,
              },
            }}
          />
        </Form>
      </Space>
    </>
  );
}
