/* eslint-disable @typescript-eslint/no-explicit-any */
import { UploadOutlined } from "@ant-design/icons";
import { ColProps, FormInstance } from "antd";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Select, Upload } from "components/styleguide";
import { FacilityResponseModel, ForumFacilityTableModel, TableResponseModel } from "generated/api";
import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { handleFileFakeRequest } from "utils/documentUtils";
import { v4 as uuidv4 } from "uuid";

import { FileImportModes } from "../helpers";
import { FileImportSubmit } from "../types";

export default function ImportDrawer({
  title,
  visible,
  onSubmit,
  onClose,
  isLoading,
  form,
  existingEntities,
}: {
  title: string;
  visible: boolean;
  onSubmit: (values: FileImportSubmit) => void;
  onClose: () => void;
  isLoading: boolean;
  form: FormInstance;
  existingEntities: FacilityResponseModel[] | TableResponseModel[] | ForumFacilityTableModel[];
}) {
  const { t } = useTranslation();

  const importModeOptions = [
    {
      label: t("Overwrite all data"),
      value: "Recreate",
    },
    {
      label: t("Add new data to existing records"),
      value: "Upsert",
    },
  ];

  const submit = (values: FileImportSubmit) => {
    const importKey = uuidv4();

    onSubmit({ ...values, importKey } as FileImportSubmit);
  };

  return (
    <Drawer title={t(title)} open={visible} onClose={onClose} contentWrapperStyle={{ minWidth: "50%" }} destroyOnClose>
      <FullHeightForm
        form={form}
        onFinish={submit}
        actionsPrepend={<Button onClick={onClose}>{t("Close")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
            {t("Import")}
          </Button>
        }
      >
        <Form.Item
          name="formFile"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(File)" }),
            },
          ]}
        >
          <Upload.Dragger maxCount={1} accept=".xlsx" customRequest={handleFileFakeRequest}>
            <h1>
              <UploadOutlined />
            </h1>
            <p>{t("Drag file here or Browse")}</p>
          </Upload.Dragger>
        </Form.Item>
        {!isEmpty(existingEntities) ? (
          <Form.Item
            key="importMode"
            label={t("The new data will overwrite the existing one. Choose an option to continue:").toString()}
            name="importMode"
            initialValue={FileImportModes.Upsert}
            labelCol={{ justify: "left", span: "24" } as ColProps}
            rules={[
              {
                required: true,
                message: t("errors.required", { prop: "$t(Import Mode)" }),
              },
            ]}
          >
            <Select options={importModeOptions} />
          </Form.Item>
        ) : null}
      </FullHeightForm>
    </Drawer>
  );
}
