import { AttendeeStatus as ApiEnum } from "generated/api";

const AttendeeStatus = {
  Cancelled: ApiEnum.NUMBER_1,
  Replaced: ApiEnum.NUMBER_2,
  Attending: ApiEnum.NUMBER_3,
  AttendingReplacement: ApiEnum.NUMBER_4,
  CancelledWithFee: ApiEnum.NUMBER_5,

  toDisplayName(type: number) {
    switch (type) {
      case ApiEnum.NUMBER_1:
        return "Cancelled";
      case ApiEnum.NUMBER_2:
        return "Replaced";
      case ApiEnum.NUMBER_3:
        return "Attending";
      case ApiEnum.NUMBER_4:
        return "Attending, Replacement";
      case ApiEnum.NUMBER_5:
        return "Cancelled (with fee)";
      default:
        return "";
    }
  },
};

export default AttendeeStatus;
