/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumTeamMemberRequestModel } from '../apiModels';
// @ts-ignore
import { ForumTeamMemberResponseModel } from '../apiModels';
// @ts-ignore
import { UserResponseModel } from '../apiModels';
/**
 * ForumTeamMemberApi - axios parameter creator
 * @export
 */
export const ForumTeamMemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersGet: async (forumId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/team-members`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {ForumTeamMemberRequestModel} [forumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersPost: async (forumId: number, forumTeamMemberRequestModel?: ForumTeamMemberRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/team-members`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumTeamMemberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersTeamMemberIdDelete: async (forumId: number, teamMemberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersTeamMemberIdDelete', 'forumId', forumId)
            // verify required parameter 'teamMemberId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersTeamMemberIdDelete', 'teamMemberId', teamMemberId)
            const localVarPath = `/forum/{forumId}/team-members/{teamMemberId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"teamMemberId"}}`, encodeURIComponent(String(teamMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersTeamMemberIdGet: async (forumId: number, teamMemberId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersTeamMemberIdGet', 'forumId', forumId)
            // verify required parameter 'teamMemberId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersTeamMemberIdGet', 'teamMemberId', teamMemberId)
            const localVarPath = `/forum/{forumId}/team-members/{teamMemberId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"teamMemberId"}}`, encodeURIComponent(String(teamMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} teamMemberId 
         * @param {ForumTeamMemberRequestModel} [forumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersTeamMemberIdPut: async (forumId: number, teamMemberId: number, forumTeamMemberRequestModel?: ForumTeamMemberRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersTeamMemberIdPut', 'forumId', forumId)
            // verify required parameter 'teamMemberId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersTeamMemberIdPut', 'teamMemberId', teamMemberId)
            const localVarPath = `/forum/{forumId}/team-members/{teamMemberId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"teamMemberId"}}`, encodeURIComponent(String(teamMemberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumTeamMemberRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersUnassignedUsersGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdTeamMembersUnassignedUsersGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/team-members/unassigned-users`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumTeamMemberApi - functional programming interface
 * @export
 */
export const ForumTeamMemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumTeamMemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTeamMembersGet(forumId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumTeamMemberResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTeamMembersGet(forumId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {ForumTeamMemberRequestModel} [forumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTeamMembersPost(forumId: number, forumTeamMemberRequestModel?: ForumTeamMemberRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumTeamMemberResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTeamMembersPost(forumId, forumTeamMemberRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTeamMembersTeamMemberIdDelete(forumId: number, teamMemberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTeamMembersTeamMemberIdDelete(forumId, teamMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} teamMemberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTeamMembersTeamMemberIdGet(forumId: number, teamMemberId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumTeamMemberResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTeamMembersTeamMemberIdGet(forumId, teamMemberId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} teamMemberId 
         * @param {ForumTeamMemberRequestModel} [forumTeamMemberRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTeamMembersTeamMemberIdPut(forumId: number, teamMemberId: number, forumTeamMemberRequestModel?: ForumTeamMemberRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumTeamMemberResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTeamMembersTeamMemberIdPut(forumId, teamMemberId, forumTeamMemberRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdTeamMembersUnassignedUsersGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdTeamMembersUnassignedUsersGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumTeamMemberApi - factory interface
 * @export
 */
export const ForumTeamMemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumTeamMemberApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumTeamMemberApiForumForumIdTeamMembersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersGet(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumTeamMemberResponseModel>> {
            return localVarFp.forumForumIdTeamMembersGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumTeamMemberApiForumForumIdTeamMembersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersPost(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumTeamMemberResponseModel> {
            return localVarFp.forumForumIdTeamMembersPost(requestParameters.forumId, requestParameters.forumTeamMemberRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersTeamMemberIdDelete(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdTeamMembersTeamMemberIdDelete(requestParameters.forumId, requestParameters.teamMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersTeamMemberIdGet(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumTeamMemberResponseModel> {
            return localVarFp.forumForumIdTeamMembersTeamMemberIdGet(requestParameters.forumId, requestParameters.teamMemberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersTeamMemberIdPut(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumTeamMemberResponseModel> {
            return localVarFp.forumForumIdTeamMembersTeamMemberIdPut(requestParameters.forumId, requestParameters.teamMemberId, requestParameters.forumTeamMemberRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdTeamMembersUnassignedUsersGet(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<UserResponseModel>> {
            return localVarFp.forumForumIdTeamMembersUnassignedUsersGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdTeamMembersGet operation in ForumTeamMemberApi.
 * @export
 * @interface ForumTeamMemberApiForumForumIdTeamMembersGetRequest
 */
export interface ForumTeamMemberApiForumForumIdTeamMembersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdTeamMembersPost operation in ForumTeamMemberApi.
 * @export
 * @interface ForumTeamMemberApiForumForumIdTeamMembersPostRequest
 */
export interface ForumTeamMemberApiForumForumIdTeamMembersPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumTeamMemberRequestModel}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersPost
     */
    readonly forumTeamMemberRequestModel?: ForumTeamMemberRequestModel
}

/**
 * Request parameters for forumForumIdTeamMembersTeamMemberIdDelete operation in ForumTeamMemberApi.
 * @export
 * @interface ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDeleteRequest
 */
export interface ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDelete
     */
    readonly teamMemberId: number
}

/**
 * Request parameters for forumForumIdTeamMembersTeamMemberIdGet operation in ForumTeamMemberApi.
 * @export
 * @interface ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGetRequest
 */
export interface ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGet
     */
    readonly teamMemberId: number
}

/**
 * Request parameters for forumForumIdTeamMembersTeamMemberIdPut operation in ForumTeamMemberApi.
 * @export
 * @interface ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPutRequest
 */
export interface ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPut
     */
    readonly teamMemberId: number

    /**
     * 
     * @type {ForumTeamMemberRequestModel}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPut
     */
    readonly forumTeamMemberRequestModel?: ForumTeamMemberRequestModel
}

/**
 * Request parameters for forumForumIdTeamMembersUnassignedUsersGet operation in ForumTeamMemberApi.
 * @export
 * @interface ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGetRequest
 */
export interface ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGet
     */
    readonly forumId: number
}

/**
 * ForumTeamMemberApi - object-oriented interface
 * @export
 * @class ForumTeamMemberApi
 * @extends {BaseAPI}
 */
export class ForumTeamMemberApi extends BaseAPI {
    /**
     * 
     * @param {ForumTeamMemberApiForumForumIdTeamMembersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTeamMemberApi
     */
    public forumForumIdTeamMembersGet(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersGetRequest, options?: AxiosRequestConfig) {
        return ForumTeamMemberApiFp(this.configuration).forumForumIdTeamMembersGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumTeamMemberApiForumForumIdTeamMembersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTeamMemberApi
     */
    public forumForumIdTeamMembersPost(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersPostRequest, options?: AxiosRequestConfig) {
        return ForumTeamMemberApiFp(this.configuration).forumForumIdTeamMembersPost(requestParameters.forumId, requestParameters.forumTeamMemberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTeamMemberApi
     */
    public forumForumIdTeamMembersTeamMemberIdDelete(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumTeamMemberApiFp(this.configuration).forumForumIdTeamMembersTeamMemberIdDelete(requestParameters.forumId, requestParameters.teamMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTeamMemberApi
     */
    public forumForumIdTeamMembersTeamMemberIdGet(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdGetRequest, options?: AxiosRequestConfig) {
        return ForumTeamMemberApiFp(this.configuration).forumForumIdTeamMembersTeamMemberIdGet(requestParameters.forumId, requestParameters.teamMemberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTeamMemberApi
     */
    public forumForumIdTeamMembersTeamMemberIdPut(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersTeamMemberIdPutRequest, options?: AxiosRequestConfig) {
        return ForumTeamMemberApiFp(this.configuration).forumForumIdTeamMembersTeamMemberIdPut(requestParameters.forumId, requestParameters.teamMemberId, requestParameters.forumTeamMemberRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumTeamMemberApi
     */
    public forumForumIdTeamMembersUnassignedUsersGet(requestParameters: ForumTeamMemberApiForumForumIdTeamMembersUnassignedUsersGetRequest, options?: AxiosRequestConfig) {
        return ForumTeamMemberApiFp(this.configuration).forumForumIdTeamMembersUnassignedUsersGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }
}

