import { useQueryClient } from "@tanstack/react-query";
import { useUpdateBookletProfileSetupMutation } from "api/mutations/itineraryBookletsProfileSetup";
import { useGetBookletProfileSetup } from "api/queries/itineraryBookletsProfileSetup";
import KEYS from "api/queries/keys";
import { Button, Layout, Select, Space, Table } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { ProfileSetupType } from "generated/api";
import { BookletProfileSetupRequestModel } from "generated/api/apiModels/booklet-profile-setup-request-model";
import { BookletProfileSetupResponseModel } from "generated/api/apiModels/booklet-profile-setup-response-model";
import { toNumber } from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";

import EditBookletProfileDrawer from "./EditDrawer";

import styles from "./styles.module.less";

const BookletProfileSetup = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const forumId = toNumber(id);

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState<BookletProfileSetupResponseModel | undefined>();
  const [profileFilter, setProfileFilter] = useState<ProfileSetupType | undefined>(undefined);

  const { isLoading, data: { data: questions = [] } = {} } = useGetBookletProfileSetup({
    forumId,
    profileSetupType: profileFilter,
  });

  const { mutate, isLoading: isUpdateLoading } = useUpdateBookletProfileSetupMutation();

  const handleEdit = (question: BookletProfileSetupResponseModel) => {
    setIsEditDrawerOpen(true);
    setQuestionToEdit(question);
  };

  const handleClose = () => {
    if (isUpdateLoading) {
      return;
    }

    setIsEditDrawerOpen(false);
    setQuestionToEdit(undefined);
  };

  const handleSubmit = (values: BookletProfileSetupRequestModel) => {
    const bookletProfileSetupRequestModel = {
      ...values,
      selectionSiteItemId: questionToEdit?.selectionSiteItemId,
      questionId: questionToEdit?.questionId,
    };

    mutate(
      { forumId, bookletProfileSetupRequestModel },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_ITINERARY_BOOKLET_PROFILE_SETUP]);

          handleClose();
        },
        onError: () => {
          toast.error(t("Something went wrong"));
        },
      },
    );
  };

  const columns = useMemo(
    () => [
      buildColumn(t("Question Title"), "questionTitle"),
      buildColumn(t("Show in Profile"), "showInProfile", { render: (value: boolean) => (value ? t("Yes") : t("No")) }),
      buildColumn(t("Directory Style"), "directoryStyle", { render: (value: string) => t(value) }),
      buildColumn(t("Profile Title"), "profileTitle"),
      buildColumn("", "id", {
        align: "center",
        width: "10%",
        render: (_: number, question: BookletProfileSetupResponseModel) => (
          <Button onClick={() => handleEdit(question)}>{t("Edit")}</Button>
        ),
      }),
    ],
    [t],
  );

  return (
    <div>
      <UserGuide.Title title="Booklet Profile Setup" articleId="16441687725981-booklet-profile-setup" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Layout className={styles.bookletProfileSection}>
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <div className={styles.filtersBox}>
              <Select
                onSelect={(value) => setProfileFilter(value)}
                options={Object.values(ProfileSetupType).map((value: string) => ({ value, label: t(value) }))}
                placeholder={t("Select Profile")}
                value={profileFilter}
                allowClear
                onClear={() => setProfileFilter(undefined)}
              />
            </div>
            <Table
              id="bookletProfileSetupTable"
              data-testid="bookletProfileTable"
              dataSource={questions}
              columns={columns}
              rowKey="questionId"
              loading={isLoading}
              pagination={false}
            />
          </Space>
          <EditBookletProfileDrawer
            isOpen={isEditDrawerOpen}
            isUpdateLoading={isUpdateLoading}
            questionToEdit={questionToEdit}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        </Layout>
      </Space>
    </div>
  );
};

export default BookletProfileSetup;
