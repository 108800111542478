/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ReorderRequestModel } from '../apiModels';
// @ts-ignore
import { StreamRequestModel } from '../apiModels';
// @ts-ignore
import { StreamResponseModel } from '../apiModels';
/**
 * ForumStreamsApi - axios parameter creator
 * @export
 */
export const ForumStreamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all streams
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdStreamsGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/streams`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a Stream
         * @param {number} forumId 
         * @param {StreamRequestModel} [streamRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsPost: async (forumId: number, streamRequestModel?: StreamRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdStreamsPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/streams`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streamRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete existing stream
         * @param {number} forumId 
         * @param {number} streamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsStreamIdDelete: async (forumId: number, streamId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdStreamsStreamIdDelete', 'forumId', forumId)
            // verify required parameter 'streamId' is not null or undefined
            assertParamExists('forumForumIdStreamsStreamIdDelete', 'streamId', streamId)
            const localVarPath = `/forum/{forumId}/streams/{streamId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"streamId"}}`, encodeURIComponent(String(streamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a stream
         * @param {number} forumId 
         * @param {number} streamId 
         * @param {StreamRequestModel} [streamRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsStreamIdPut: async (forumId: number, streamId: number, streamRequestModel?: StreamRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdStreamsStreamIdPut', 'forumId', forumId)
            // verify required parameter 'streamId' is not null or undefined
            assertParamExists('forumForumIdStreamsStreamIdPut', 'streamId', streamId)
            const localVarPath = `/forum/{forumId}/streams/{streamId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"streamId"}}`, encodeURIComponent(String(streamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(streamRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder streams
         * @param {number} forumId 
         * @param {number} streamId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsStreamIdReorderPut: async (forumId: number, streamId: number, reorderRequestModel?: ReorderRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdStreamsStreamIdReorderPut', 'forumId', forumId)
            // verify required parameter 'streamId' is not null or undefined
            assertParamExists('forumForumIdStreamsStreamIdReorderPut', 'streamId', streamId)
            const localVarPath = `/forum/{forumId}/streams/{streamId}/reorder`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"streamId"}}`, encodeURIComponent(String(streamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumStreamsApi - functional programming interface
 * @export
 */
export const ForumStreamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumStreamsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all streams
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdStreamsGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StreamResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdStreamsGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a Stream
         * @param {number} forumId 
         * @param {StreamRequestModel} [streamRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdStreamsPost(forumId: number, streamRequestModel?: StreamRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdStreamsPost(forumId, streamRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete existing stream
         * @param {number} forumId 
         * @param {number} streamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdStreamsStreamIdDelete(forumId: number, streamId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdStreamsStreamIdDelete(forumId, streamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a stream
         * @param {number} forumId 
         * @param {number} streamId 
         * @param {StreamRequestModel} [streamRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdStreamsStreamIdPut(forumId: number, streamId: number, streamRequestModel?: StreamRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StreamResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdStreamsStreamIdPut(forumId, streamId, streamRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder streams
         * @param {number} forumId 
         * @param {number} streamId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdStreamsStreamIdReorderPut(forumId: number, streamId: number, reorderRequestModel?: ReorderRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdStreamsStreamIdReorderPut(forumId, streamId, reorderRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumStreamsApi - factory interface
 * @export
 */
export const ForumStreamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumStreamsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all streams
         * @param {ForumStreamsApiForumForumIdStreamsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsGet(requestParameters: ForumStreamsApiForumForumIdStreamsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<StreamResponseModel>> {
            return localVarFp.forumForumIdStreamsGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a Stream
         * @param {ForumStreamsApiForumForumIdStreamsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsPost(requestParameters: ForumStreamsApiForumForumIdStreamsPostRequest, options?: AxiosRequestConfig): AxiosPromise<StreamResponseModel> {
            return localVarFp.forumForumIdStreamsPost(requestParameters.forumId, requestParameters.streamRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete existing stream
         * @param {ForumStreamsApiForumForumIdStreamsStreamIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsStreamIdDelete(requestParameters: ForumStreamsApiForumForumIdStreamsStreamIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdStreamsStreamIdDelete(requestParameters.forumId, requestParameters.streamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a stream
         * @param {ForumStreamsApiForumForumIdStreamsStreamIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsStreamIdPut(requestParameters: ForumStreamsApiForumForumIdStreamsStreamIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<StreamResponseModel> {
            return localVarFp.forumForumIdStreamsStreamIdPut(requestParameters.forumId, requestParameters.streamId, requestParameters.streamRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder streams
         * @param {ForumStreamsApiForumForumIdStreamsStreamIdReorderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdStreamsStreamIdReorderPut(requestParameters: ForumStreamsApiForumForumIdStreamsStreamIdReorderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdStreamsStreamIdReorderPut(requestParameters.forumId, requestParameters.streamId, requestParameters.reorderRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdStreamsGet operation in ForumStreamsApi.
 * @export
 * @interface ForumStreamsApiForumForumIdStreamsGetRequest
 */
export interface ForumStreamsApiForumForumIdStreamsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumForumIdStreamsPost operation in ForumStreamsApi.
 * @export
 * @interface ForumStreamsApiForumForumIdStreamsPostRequest
 */
export interface ForumStreamsApiForumForumIdStreamsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {StreamRequestModel}
     * @memberof ForumStreamsApiForumForumIdStreamsPost
     */
    readonly streamRequestModel?: StreamRequestModel
}

/**
 * Request parameters for forumForumIdStreamsStreamIdDelete operation in ForumStreamsApi.
 * @export
 * @interface ForumStreamsApiForumForumIdStreamsStreamIdDeleteRequest
 */
export interface ForumStreamsApiForumForumIdStreamsStreamIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdDelete
     */
    readonly streamId: number
}

/**
 * Request parameters for forumForumIdStreamsStreamIdPut operation in ForumStreamsApi.
 * @export
 * @interface ForumStreamsApiForumForumIdStreamsStreamIdPutRequest
 */
export interface ForumStreamsApiForumForumIdStreamsStreamIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdPut
     */
    readonly streamId: number

    /**
     * 
     * @type {StreamRequestModel}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdPut
     */
    readonly streamRequestModel?: StreamRequestModel
}

/**
 * Request parameters for forumForumIdStreamsStreamIdReorderPut operation in ForumStreamsApi.
 * @export
 * @interface ForumStreamsApiForumForumIdStreamsStreamIdReorderPutRequest
 */
export interface ForumStreamsApiForumForumIdStreamsStreamIdReorderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdReorderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdReorderPut
     */
    readonly streamId: number

    /**
     * 
     * @type {ReorderRequestModel}
     * @memberof ForumStreamsApiForumForumIdStreamsStreamIdReorderPut
     */
    readonly reorderRequestModel?: ReorderRequestModel
}

/**
 * ForumStreamsApi - object-oriented interface
 * @export
 * @class ForumStreamsApi
 * @extends {BaseAPI}
 */
export class ForumStreamsApi extends BaseAPI {
    /**
     * 
     * @summary Get all streams
     * @param {ForumStreamsApiForumForumIdStreamsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumStreamsApi
     */
    public forumForumIdStreamsGet(requestParameters: ForumStreamsApiForumForumIdStreamsGetRequest, options?: AxiosRequestConfig) {
        return ForumStreamsApiFp(this.configuration).forumForumIdStreamsGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a Stream
     * @param {ForumStreamsApiForumForumIdStreamsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumStreamsApi
     */
    public forumForumIdStreamsPost(requestParameters: ForumStreamsApiForumForumIdStreamsPostRequest, options?: AxiosRequestConfig) {
        return ForumStreamsApiFp(this.configuration).forumForumIdStreamsPost(requestParameters.forumId, requestParameters.streamRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete existing stream
     * @param {ForumStreamsApiForumForumIdStreamsStreamIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumStreamsApi
     */
    public forumForumIdStreamsStreamIdDelete(requestParameters: ForumStreamsApiForumForumIdStreamsStreamIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumStreamsApiFp(this.configuration).forumForumIdStreamsStreamIdDelete(requestParameters.forumId, requestParameters.streamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a stream
     * @param {ForumStreamsApiForumForumIdStreamsStreamIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumStreamsApi
     */
    public forumForumIdStreamsStreamIdPut(requestParameters: ForumStreamsApiForumForumIdStreamsStreamIdPutRequest, options?: AxiosRequestConfig) {
        return ForumStreamsApiFp(this.configuration).forumForumIdStreamsStreamIdPut(requestParameters.forumId, requestParameters.streamId, requestParameters.streamRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder streams
     * @param {ForumStreamsApiForumForumIdStreamsStreamIdReorderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumStreamsApi
     */
    public forumForumIdStreamsStreamIdReorderPut(requestParameters: ForumStreamsApiForumForumIdStreamsStreamIdReorderPutRequest, options?: AxiosRequestConfig) {
        return ForumStreamsApiFp(this.configuration).forumForumIdStreamsStreamIdReorderPut(requestParameters.forumId, requestParameters.streamId, requestParameters.reorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

