import { useQueryClient } from "@tanstack/react-query";
import { UploadFile } from "antd";
import { useUpdateForumMutation, useUploadLogoMutation } from "api/mutations/forums";
import { useGetCountriesQuery } from "api/queries/countries";
import { useGetForumByIdQuery } from "api/queries/forums";
import { useGetForumVenueQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import { useTimezonesQuery } from "api/queries/timezone";
import { useGetVenuesQuery } from "api/queries/venues";
import VenueAssignmentType from "backend-models/venueAssignmentType";
import { Button, Col, Collapse, Divider, Form, Input, Layout, Row, Space, Spin } from "components/styleguide";
import {
  CountryModel,
  ForumRequestModel,
  ForumResponseModel,
  PageResponseVenueResponseModel,
  TimezoneModel,
  VenueDetailsModel,
  VenueResponseModel,
} from "generated/api";
import { toNumber } from "lodash";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getForumDisplayDateByDuration } from "utils/dateUtils";

import { getFormFields } from "./helpers";

import styles from "./styles.module.less";

const { Content } = Layout;
const { Panel } = Collapse;
const collapseDefaultOpenKeys = ["details"];

export default function ForumDetails() {
  const { t } = useTranslation();
  const { forumId } = useParams();
  const queryClient = useQueryClient();

  const [isEditing, setIsEditing] = useState(false);
  const [forum, setForum] = useState<ForumResponseModel>();
  const [countries, setCountries] = useState<CountryModel[]>([]);
  const [timezones, setTimezones] = useState<TimezoneModel[]>([]);
  const [venues, setVenues] = useState<VenueResponseModel[]>([]);
  const [venueFilter, setVenueFilter] = useState<string | null | undefined>(null);
  const [venueAssignmentType, setVenueAssignmentType] = useState(VenueAssignmentType.LIST[2]?.value);
  const [form] = Form.useForm();

  const updateMutation = useUpdateForumMutation({
    onSuccess: () => {
      queryClient.resetQueries([KEYS.GET_FORUM_BY_ID]);
      setIsEditing(false);
    },
  });

  const query = useGetForumByIdQuery(
    { forumId: toNumber(forumId) },
    {
      onSuccess: ({ data }: { data: ForumResponseModel }) => {
        setForum(data);
        form.setFieldsValue(data);
        form.setFieldValue("duration", [moment(data.startDate), moment(data.endDate)]);
        form.setFieldValue("timezoneId", data.timezoneId ?? "+00:00");
      },
    },
  );

  const countriesQuery = useGetCountriesQuery(
    {},
    {
      onSuccess: ({ data }: { data: CountryModel[] }) => {
        setVenueFilter(forum?.countryCode);
        setCountries(data);
      },
      enabled: !!forum,
    },
  );

  const timeZonesQuery = useTimezonesQuery({
    onSuccess: ({ data }: { data: TimezoneModel[] }) => setTimezones(data),
  });

  const venuesQuery = useGetVenuesQuery(
    { pageNumber: 1, pageSize: 1000, orderBy: "name", orderDir: "asc" },
    {
      onSuccess: ({ data }: { data: PageResponseVenueResponseModel }) => {
        setVenues(data.items ?? []);
      },
    },
  );

  const { data: { data: venue = {} } = {} } = useGetForumVenueQuery(
    { forumId: toNumber(forum?.id) },
    {
      onSuccess: ({ data }: { data: VenueDetailsModel }) => {
        setVenueAssignmentType(data?.assignType);
      },
      enabled: !!forum?.id,
    },
  );

  const edit = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsEditing(!isEditing);
  };

  const cancelEdit = () => {
    form.setFieldsValue(forum);
    setIsEditing(false);
  };

  const uploadLogoMutation = useUploadLogoMutation();

  const submit = ({
    duration,
    logo,
    ...values
  }: {
    duration: Array<Moment>;
    code: string;
    shortName: string;
    longName: string;
    venueId: number;
    countryCode: string;
    title: string;
    timezoneId: string;
    formattedName: string;
    displayDates: string;
    websiteAddress: string;
    logo: UploadFile[];
  }) => {
    const forumData: ForumRequestModel = {
      startDate: duration[0].format("YYYY-MM-DD"), // to use the date only, without the local time, which may send a wrong date
      endDate: duration[1].format("YYYY-MM-DD"),
      venueAssignmentType,
      ...values,
    };

    if (!logo) {
      updateMutation.mutate({
        id: toNumber(forumId),
        forumRequestModel: forumData,
      });
    } else {
      uploadLogoMutation.mutate(
        {
          formFile: logo?.[0].originFileObj,
        },
        {
          onSuccess: ({ data }) => {
            form.setFieldValue("logoFileBlobId", data);

            updateMutation.mutate({
              id: toNumber(forumId),
              forumRequestModel: { ...forumData, logoFileBlobId: data },
            });
          },
        },
      );
    }
  };

  const onDurationChange = (duration: Array<Moment>) => {
    form.setFieldValue("displayDates", getForumDisplayDateByDuration(duration));
  };

  const onCountryChange = (countryCode: string) => {
    if (!form.getFieldValue("isVirtual")) {
      setVenueFilter(countryCode);
      form.setFieldValue("venueId", null);
    }
  };

  const onVenueChange = () => {
    const defaultType = VenueAssignmentType.LIST[0].value;

    // manage radio buttons value when venue is changed
    if (form.getFieldValue("venueId") !== forum?.venueId) {
      setVenueAssignmentType(defaultType);
    } else {
      setVenueAssignmentType(venue.assignType);
    }
  };

  const isLoading =
    query.isLoading ||
    countriesQuery.isLoading ||
    venuesQuery.isLoading ||
    countriesQuery.isLoading ||
    timeZonesQuery.isLoading;

  const isMutationLoading = updateMutation.isLoading || uploadLogoMutation.isLoading;

  return (
    <Layout>
      <Content>
        <div className={styles.details}>
          <Collapse
            className={"collapse-parent forum-details"}
            bordered={false}
            defaultActiveKey={collapseDefaultOpenKeys}
            ghost
          >
            <Panel
              id="forum-details"
              className="collapse-panel"
              header={t("Details")}
              key="details"
              getContainer={false}
              extra={
                <span>
                  <Button onClick={(e) => edit(e)}>{t("Edit")}</Button>
                </span>
              }
            >
              {forum && !isLoading ? (
                <Form
                  form={form}
                  onFinish={submit}
                  id="contactInfoForm"
                  name="contact-info"
                  labelAlign="left"
                  labelCol={{ span: 13 }}
                  wrapperCol={{ span: 11 }}
                  labelWrap
                  colon={false}
                >
                  <Row gutter={[80, 10]}>
                    {getFormFields(
                      form,
                      forum,
                      countries,
                      timezones,
                      venues,
                      venueFilter,
                      t,
                      onCountryChange,
                      onVenueChange,
                      onDurationChange,
                    ).map((f) => (
                      <Col key={f.dataIndex} md={24} lg={12}>
                        {f.title ? (
                          <Form.Item
                            className={styles.formItem}
                            label={t(f.title)}
                            name={f.dataIndex}
                            rules={isEditing && f.rules ? f.rules : undefined}
                            hidden={f.hidden}
                          >
                            {isEditing ? (
                              f.component ?? <Input />
                            ) : (
                              <label className={styles.label}>
                                {f.displayValue ?? form.getFieldValue(f.dataIndex) ?? "-"}
                              </label>
                            )}
                          </Form.Item>
                        ) : (
                          <Space />
                        )}
                        {f.title && <Divider />}
                      </Col>
                    ))}
                  </Row>
                  {isEditing && (
                    <Row gutter={80}>
                      <Col span={1}>
                        <Button
                          loading={isMutationLoading}
                          disabled={isMutationLoading}
                          htmlType="submit"
                          type="primary"
                        >
                          {t("Save")}
                        </Button>
                      </Col>
                      <Col span={1}>
                        <Button onClick={cancelEdit} disabled={isMutationLoading}>
                          {t("Cancel")}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Form>
              ) : (
                <div className="loader">
                  <Spin />
                </div>
              )}
            </Panel>
          </Collapse>
        </div>
      </Content>
    </Layout>
  );
}
