import { SwipeActionRef } from "antd-mobile/es/components/swipe-action";
import { Col, Row, Typography } from "components/styleguide";
import { List, SwipeAction } from "components/styleguide/mobile";
import { useRef } from "react";

import { useItemCard } from "../hooks";
import { ItemCardMobileProps } from "../types";

import styles from "../styles.module.less";

export default function ItemCardMobile({
  key,
  title,
  subTitle1,
  subTitle2,
  checkedIn,
  leftActions,
  rightActions,
  onActionsReveal,
}: ItemCardMobileProps) {
  const ref = useRef<SwipeActionRef>(null);
  const { getCardClassByAttendeeStatus } = useItemCard();

  const handleActionsReveal = (side: "left" | "right") => {
    if (onActionsReveal) {
      onActionsReveal(side);
      ref.current?.close();
    }
  };

  return (
    <SwipeAction
      className={styles.listItem}
      ref={ref}
      key={key}
      leftActions={leftActions}
      rightActions={rightActions}
      onActionsReveal={handleActionsReveal}
    >
      <List.Item className={getCardClassByAttendeeStatus(checkedIn)}>
        <Row>
          <Col>
            <Typography.Title level={4} className={styles.text}>
              {title}
            </Typography.Title>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text type="secondary" className={styles.text}>
              {subTitle1}
            </Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Text type="secondary" className={styles.text}>
              {subTitle2}
            </Typography.Text>
          </Col>
        </Row>
      </List.Item>
    </SwipeAction>
  );
}
