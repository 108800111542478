import { ItineraryBookletApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ItineraryBookletApi);

const ItineraryBooklets = {
  get: api.forumsForumIdItineraryBookletSettingsGet,
  put: api.forumsForumIdItineraryBookletSettingsPut,
  copySettings: {
    put: api.forumsForumIdItineraryBookletExistingForumIdPut,
  },
  generateDocument: {
    get: api.forumsForumIdItineraryBookletGenerateDocumentGet,
  },
  documentGenerationRuns: {
    getAll: api.forumsForumIdItineraryBookletDocumentGenerationRunsGet,
    get: api.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdGet,
    put: api.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdPut,
    export: {
      get: api.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdDocumentsGet,
    },
  },
  queuedDocuments: {
    get: api.forumsForumIdItineraryBookletDocumentGenerationRunsGet,
    post: api.forumsForumIdItineraryBookletDocumentGenerationRunsPost,
  },
  cancelDocument: {
    post: api.forumsForumIdItineraryBookletDocumentGenerationRunsDocumentGenerationRunIdCancelPost,
  },
};

export default ItineraryBooklets;
