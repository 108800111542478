import { ColumnsType } from "antd/lib/table";
import { useAttendeePreferencesSelectionOptionsQuery } from "api/queries/attendees";
import { Button, Space } from "components/styleguide";
import Table from "components/styleguide/components/Table";
import {
  ForumAttendeeSelectionChoiceResponseModel,
  PageResponseForumAttendeeSelectionChoiceResponseModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TableColumn } from "utils/tableColumnUtils";

import AddSelectionOptionDrawer from "./AddSelectionOptionDrawer";
import DeleteSelectionOptionButton from "./DeleteSelectionOptionButton";
import EditSelectionOptionDrawer from "./EditSelectionOptionDrawer";

import styles from "./styles.module.less";

const AttendeePreferencesSelectionOptions: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const [selectionOptions, setSelectionOptions] = useState<
    Array<ForumAttendeeSelectionChoiceResponseModel> | undefined
  >();
  const [selectedSelectionOption, setSelectedSelectionOption] =
    useState<ForumAttendeeSelectionChoiceResponseModel | null>();
  const [isAddSelectionOptionDrawerVisible, setIsAddSelectionOptionDrawerVisible] = useState<boolean>(false);
  const [isEditSelectionOptionDrawerVisible, setIsEditSelectionOptionDrawerVisible] = useState<boolean>(false);

  const showAddSelectionOptionDrawer = () => setIsAddSelectionOptionDrawerVisible(true);

  const hideAddSelectionOptionDrawer = () => setIsAddSelectionOptionDrawerVisible(false);

  const showEditSelectionOptionDrawer = (selectionOption: ForumAttendeeSelectionChoiceResponseModel) => {
    setSelectedSelectionOption(selectionOption);
    setIsEditSelectionOptionDrawerVisible(true);
  };

  const hideEditSelectionOptionDrawer = () => {
    setIsEditSelectionOptionDrawerVisible(true);
    setSelectedSelectionOption(null);
  };

  const {
    response: { isLoading },
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeePreferencesSelectionOptionsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseForumAttendeeSelectionChoiceResponseModel }) => {
        setSelectionOptions(data.items);
      },
    },
    { forumId, attendeeId },
    { defaultPageSize: 10, pageSizeOptions: [10, 20] },
  );

  const columns: ColumnsType<ForumAttendeeSelectionChoiceResponseModel> = [
    new TableColumn(t("Choice Name"), "name"),
    new TableColumn(t("Choice Description"), "description"),
    new TableColumn(t("Choice Value"), "value"),
    new TableColumn("", "id", {
      width: "5%",
      render: (id: number | undefined, selectionOption: ForumAttendeeSelectionChoiceResponseModel) => {
        return (
          <div className={styles.buttonWrapper}>
            <Button onClick={() => showEditSelectionOptionDrawer(selectionOption)}>{t("edit")}</Button>
            {id && <DeleteSelectionOptionButton selectionOptionId={id} />}
          </div>
        );
      },
    }),
  ];

  const conditionalPagination = selectionOptions && selectionOptions?.length > 10 ? pagination : false;

  return (
    <>
      <Space direction="vertical" size="middle" fullWidth>
        <Button onClick={showAddSelectionOptionDrawer}>{t("Add")}</Button>
        <Table
          bordered
          id="attendee-preferences-selection-options-table"
          columns={columns}
          rowKey="id"
          loading={isLoading}
          pagination={conditionalPagination}
          dataSource={selectionOptions}
        />
      </Space>
      <AddSelectionOptionDrawer isVisible={isAddSelectionOptionDrawerVisible} onClose={hideAddSelectionOptionDrawer} />
      {selectedSelectionOption && (
        <EditSelectionOptionDrawer
          selectionOption={selectedSelectionOption}
          isVisible={isEditSelectionOptionDrawerVisible}
          onClose={hideEditSelectionOptionDrawer}
        />
      )}
    </>
  );
};

export default AttendeePreferencesSelectionOptions;
