import { useForumTablesQuery } from "api/queries/forumTables";
import { Select } from "components/styleguide";
import { AttendeeMeetingLocationTimeSlotTypeModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface AttendeeMeetingLocationsQuickSetupSelectTableCellProps {
  forumId: number;
  meetingLocation: AttendeeMeetingLocationTimeSlotTypeModel;
  setMeetingLocation: (index: number, record: AttendeeMeetingLocationTimeSlotTypeModel) => void;
  index: number;
  loading: boolean;
  key: number;
}

const AttendeeMeetingLocationsQuickSetupSelectTableCell: FC<AttendeeMeetingLocationsQuickSetupSelectTableCellProps> = ({
  forumId,
  meetingLocation,
  setMeetingLocation,
  index,
  loading,
}) => {
  const { t } = useTranslation();

  const forumTablesQuery = useForumTablesQuery(
    {
      forumId,
      forumBuildingId: meetingLocation.forumBuildingId ?? 0,
      forumFacilityId: meetingLocation.forumFacilityId ?? 0,
    },
    { enabled: !!meetingLocation.forumBuildingId && !!meetingLocation.forumFacilityId },
  );

  const forumTables = forumTablesQuery.data?.data ?? [];

  return (
    <Select
      style={{ width: "100%" }}
      disabled={!meetingLocation.forumBuildingId || !meetingLocation.forumFacilityId}
      options={[
        { value: null, label: t("None") },
        ...forumTables.map((table) => ({ value: table.id, label: table.tableNumber })),
      ]}
      defaultValue={meetingLocation.forumTableId}
      onChange={(value) => setMeetingLocation(index, { ...meetingLocation, forumTableId: value ?? null })}
      loading={loading}
    />
  );
};

export default AttendeeMeetingLocationsQuickSetupSelectTableCell;
