import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDeleteForumCompanyPrimaryLocationMutation } from "../../../api/mutations/forumCompanies";
import { useGetForumCompanyPrimaryLocationsQuery } from "../../../api/queries/forumCompanies";
import KEYS from "../../../api/queries/keys";
import { Button, Modal, Space } from "../../../components/styleguide";
import { ForumCompanyPrimaryLocationModel } from "../../../generated/api";
import ForumCompanyPrimaryLocationDrawer from "./ForumCompanyPrimaryLocationDrawer";
import ForumCompanyPrimaryLocationsTable from "./ForumCompanyPrimaryLocationsTable";

interface PrimaryLocationsPanelProps {
  forumId: number;
  forumCompanyId: number;
}

const PrimaryLocationsPanel: FC<PrimaryLocationsPanelProps> = ({ forumId, forumCompanyId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  // Get

  const getForumCompanyPrimaryLocationsQuery = useGetForumCompanyPrimaryLocationsQuery({
    forumId,
    forumCompanyId,
  });
  const forumCompanyPrimaryLocations = getForumCompanyPrimaryLocationsQuery.data?.data ?? [];

  // Create, Update

  const [selectedPrimaryLocation, setSelectedPrimaryLocation] = useState<ForumCompanyPrimaryLocationModel | null>(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  const onDrawerClose = () => {
    setSelectedPrimaryLocation(null);
    setIsDrawerVisible(false);
  };

  // Delete

  const deleteMutation = useDeleteForumCompanyPrimaryLocationMutation();

  const handleForumCompanyPrimaryLocationDelete = (primaryLocation: ForumCompanyPrimaryLocationModel) => {
    deleteMutation.mutate(
      { forumId, forumCompanyId, locationId: primaryLocation.id },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_FORUM_COMPANY_PRIMARY_LOCATIONS]);
        },
      },
    );
  };

  // Action handlers

  const onClickAdd = () => {
    setSelectedPrimaryLocation(null);
    setIsDrawerVisible(true);
  };

  const onClickEdit = (primaryLocation: ForumCompanyPrimaryLocationModel) => {
    setSelectedPrimaryLocation(primaryLocation);
    setIsDrawerVisible(true);
  };

  const onClickDelete = (primaryLocation: ForumCompanyPrimaryLocationModel) => {
    Modal.confirm({
      title: t("Delete primary location {{locationName}}", { locationName: primaryLocation.forumFacilityName }),
      content: t("Do you want to proceed with deletion of this primary location?"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("no"),
      okText: t("Yes"),
      onOk: async () => {
        handleForumCompanyPrimaryLocationDelete(primaryLocation);
      },
    });
  };

  return (
    <>
      <Space direction={"vertical"} size={"middle"} fullWidth>
        <Button onClick={onClickAdd}>{t("Add")}</Button>
        <ForumCompanyPrimaryLocationsTable
          forumCompanyPrimaryLocations={forumCompanyPrimaryLocations}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          isLoading={getForumCompanyPrimaryLocationsQuery.isLoading}
        />
      </Space>
      <ForumCompanyPrimaryLocationDrawer
        forumId={forumId}
        forumCompanyId={forumCompanyId}
        isVisible={isDrawerVisible}
        initialValues={selectedPrimaryLocation}
        onClose={onDrawerClose}
      />
    </>
  );
};

export default PrimaryLocationsPanel;
