import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Col, Row } from "antd";
import { useAddParticipationCodeMutation, useRemoveParticipationCodeMutation } from "api/mutations/groups";
import { useAvailableCodesQuery, useSelectedCodesQuery } from "api/queries/groups";
import KEYS from "api/queries/keys";
import { useTeamMembersQuery } from "api/queries/teamMembers";
import { Button, Divider, Drawer, Form, Input, Select, Spin, Table } from "components/styleguide";
import ForumGroupType from "enums/ForumGroupType";
import ErrorTypes from "error-handling/errorTypes";
import {
  Em2ExceptionResponseObject,
  ForumGroupResponseModel,
  PageResponseParticipationCodeModel,
  ParticipationCodeModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getColumnSearchProps } from "utils/tableColumnUtils";
import { validateMaxStringLength } from "utils/validatorUtils";

import styles from "./styles.module.less";

const { Option } = Select;

export default function EditGroupDrawer({
  visible,
  isLoading,
  onClose,
  onFinish,
  group,
}: {
  visible: boolean;
  group: ForumGroupResponseModel | null | undefined;
  isLoading: boolean;
  onClose: () => void;
  onFinish: ({ groupId, ...group }: { groupId: number }) => void;
}) {
  const { id: forumId } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [availableCodes, setAvailableCodes] = useState<ParticipationCodeModel[] | undefined>([]);
  const [selectedCodes, setSelectedCodes] = useState<ParticipationCodeModel[] | undefined>([]);
  const [availableCodeSearch, setAvailableCodeSearch] = useState<string | undefined>();
  const [availableDescrSearch, setAvailableDesrcSearch] = useState<string | undefined>();
  const [selectedCodeSearch, setSelectedCodeSearch] = useState<string | undefined>();
  const [selectedDescrSearch, setSelectedDescrSearch] = useState<string | undefined>();
  const [isLoadingTables, setIsLoadingTables] = useState(true);

  const addParticipationCodeMutation = useAddParticipationCodeMutation();
  const removeParticipationCodeMutation = useRemoveParticipationCodeMutation();

  const { pagination: availablePagination } = useModernQueryWithPaginationAndOrder(
    useAvailableCodesQuery,
    {
      onSuccess: ({ data }: { data: PageResponseParticipationCodeModel }) => {
        setAvailableCodes(data.items);
        setIsLoadingTables(false);
      },
      enabled: visible,
    },
    {
      forumId,
      groupId: group?.id,
      code: availableCodeSearch ?? undefined,
      description: availableDescrSearch ?? undefined,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 20] },
  );

  const { pagination: selectedPagination } = useModernQueryWithPaginationAndOrder(
    useSelectedCodesQuery,
    {
      onSuccess: ({ data }: { data: PageResponseParticipationCodeModel }) => {
        setSelectedCodes(data.items);
        setIsLoadingTables(false);
      },
      enabled: visible,
    },
    {
      forumId,
      groupId: group?.id,
      code: selectedCodeSearch ?? undefined,
      description: selectedDescrSearch ?? undefined,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 20] },
  );

  const addCode = (code: ParticipationCodeModel) => {
    if (addParticipationCodeMutation.isLoading) {
      return;
    }

    setIsLoadingTables(true);
    addParticipationCodeMutation.mutate(
      {
        forumId: toNumber(forumId),
        groupId: toNumber(group?.id),
        addDeleteParticipationCodeModel: [{ participationCode: code.code }],
      },
      {
        onSuccess: async () => {
          await queryClient.resetQueries([KEYS.GET_AVAILABLE_CODES]);
          await queryClient.resetQueries([KEYS.GET_SELECTED_CODES]);
        },
        onError: (err) => {
          const error = err as { response: { data: Em2ExceptionResponseObject } };
          if (ErrorTypes.isOfType(error.response, ErrorTypes.DuplicateItem)) {
            toast.error(t("This Code was already added"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const removeCode = async (code: ParticipationCodeModel) => {
    setIsLoadingTables(true);
    removeParticipationCodeMutation.mutate(
      {
        forumId: toNumber(forumId),
        groupId: toNumber(group?.id),
        addDeleteParticipationCodeModel: [{ participationCode: code.code }],
      },
      {
        onSuccess: async () => {
          await queryClient.resetQueries([KEYS.GET_AVAILABLE_CODES]);
          await queryClient.resetQueries([KEYS.GET_SELECTED_CODES]);
        },
      },
    );
  };

  const { data: { data: forumTeamMembers } = {}, isLoading: isTeamMembersLoading } = useTeamMembersQuery(
    { forumId: toNumber(forumId) },
    { enabled: !!forumId },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    if (searchSet === "available") {
      if (dataIndex === "code") {
        setAvailableCodeSearch(selectedKeys[0]);
      } else {
        setAvailableDesrcSearch(selectedKeys[0]);
      }
      queryClient.invalidateQueries([KEYS.GET_AVAILABLE_CODES]);
    } else {
      if (dataIndex === "code") {
        setSelectedCodeSearch(selectedKeys[0]);
      } else {
        setSelectedDescrSearch(selectedKeys[0]);
      }
    }
    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    if (searchSet === "available") {
      if (dataIndex === "code") {
        setAvailableCodeSearch(undefined);
      } else {
        setAvailableDesrcSearch(undefined);
      }
    } else {
      if (dataIndex === "code") {
        setSelectedCodeSearch(undefined);
      } else {
        setSelectedDescrSearch(undefined);
      }
    }
    clearFilters();
    confirm();
  };

  const resetAllSearch = () => {
    setAvailableCodeSearch(undefined);
    setAvailableDesrcSearch(undefined);
    setSelectedCodeSearch(undefined);
    setSelectedDescrSearch(undefined);
  };

  const availableCodeColumns = [
    {
      title: "People Type",
      dataIndex: "code",
      ...getColumnSearchProps("code", handleSearch, handleReset, "available", null, styles.filteredSearch),
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description", handleSearch, handleReset, "available", null, styles.filteredSearch),
    },
    {
      title: "",
      render: (_: unknown, record: ParticipationCodeModel) => {
        return (
          <span className={styles.codeIcon} title="Include participation code">
            <PlusCircleOutlined onClick={() => addCode(record)} />
          </span>
        );
      },
    },
  ];

  const selectedCodeColumns = [
    {
      title: "People Type",
      dataIndex: "code",
      ...getColumnSearchProps("code", handleSearch, handleReset, "selected", null, styles.filteredSearch),
    },
    {
      title: "Description",
      dataIndex: "description",
      ...getColumnSearchProps("description", handleSearch, handleReset, "selected", null, styles.filteredSearch),
    },
    {
      title: "",
      render: (_: unknown, record: ParticipationCodeModel) => {
        return (
          <span className={styles.codeIcon} title="Remove code">
            <MinusCircleOutlined onClick={() => removeCode(record)} />
          </span>
        );
      },
    },
  ];

  return (
    <Drawer
      className="group-drawer"
      title={"Participation Codes"}
      placement="right"
      onClose={() => {
        resetAllSearch();
        onClose();
      }}
      open={visible}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Form
        id="groupForm"
        name="group-form"
        className={styles.groupForm}
        labelCol={{ span: 4 }}
        onFinish={(values) => {
          resetAllSearch();
          onFinish({ ...values, groupId: group?.id });
        }}
      >
        <div className={styles.formLayout}>
          <div>
            <Form.Item
              label="Name"
              name="name"
              initialValue={group?.name}
              rules={[{ required: true, message: "Group name is required!" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              initialValue={group?.description}
              rules={[
                { required: true, message: "Group description is required!" },
                {
                  validator: validateMaxStringLength(200),
                  message: t("'Description' field has a maximum of 200 characters"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("Contact")}
              name="contactUserId"
              initialValue={group?.contactUserId}
              rules={[
                {
                  required: true,
                  message: t("This field is mandatory!"),
                },
              ]}
            >
              <Select allowClear>
                {isTeamMembersLoading ? (
                  <div>
                    <Spin />
                  </div>
                ) : (
                  forumTeamMembers?.map(({ name, userId }, index) => (
                    <Option value={userId} key={index}>
                      {name}
                    </Option>
                  ))
                )}
              </Select>
            </Form.Item>
            <Form.Item
              label="Group Type"
              name="groupType"
              initialValue={group?.groupType}
              rules={[{ required: true, message: "Group type is required!" }]}
            >
              <Select>
                <Option value={1} key={ForumGroupType.DisplayType}>
                  {t(`forumGroupTypes.${ForumGroupType.toDisplayName(ForumGroupType.DisplayType)}`)}
                </Option>
                <Option value={2} key={ForumGroupType.General}>
                  {t(`forumGroupTypes.${ForumGroupType.toDisplayName(ForumGroupType.General)}`)}
                </Option>
                <Option value={3} key={ForumGroupType.Selection}>
                  {t(`forumGroupTypes.${ForumGroupType.toDisplayName(ForumGroupType.Selection)}`)}
                </Option>
              </Select>
            </Form.Item>
            <Form.Item label="Primary" name="primary" valuePropName="checked" initialValue={group?.primary}>
              <Checkbox />
            </Form.Item>
          </div>
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <h4>
                  <strong>Available Codes</strong>
                </h4>
                <Table
                  bordered={true}
                  columns={availableCodeColumns}
                  dataSource={availableCodes}
                  loading={isLoadingTables}
                  pagination={availablePagination}
                  rowKey="code"
                ></Table>
              </Col>
              <Col span={12}>
                <h4>
                  <strong>Selected Codes</strong>
                </h4>
                <Table
                  columns={selectedCodeColumns}
                  dataSource={selectedCodes}
                  bordered={true}
                  loading={isLoadingTables}
                  pagination={selectedPagination}
                  rowKey="code"
                ></Table>
              </Col>
            </Row>
          </div>
          <div>
            <Divider />
            <Row>
              <Col span={3}>
                <Button
                  onClick={() => {
                    resetAllSearch();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={18} />
              <Col span={3}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {"Update"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Drawer>
  );
}

EditGroupDrawer.propTypes = {
  isLoading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onFinish: PropTypes.func,
  group: PropTypes.object,
};
