import { Space } from "components/styleguide";
import { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import { DefaultSortOptions } from "../constants";
import PeopleTypeSelectOption from "../OptionTypes/PeopleTypeSelectOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const AttendeeItineraryByPeopleTypeOptions = () => {
  const [orderBy, setOrderBy] = useState<string>(DefaultSortOptions.Company);
  const [peopleTypes, setPeopleTypes] = useState<string[]>([]);

  return (
    <Space align="end">
      <SortOrderOption orderBy={orderBy} setOrderBy={setOrderBy} options={DefaultSortOptions} />
      <PeopleTypeSelectOption peopleTypes={peopleTypes} setPeopleTypes={setPeopleTypes} />
      <LinkToReport
        disabled={!peopleTypes.length}
        parameters={{ OrderBy: orderBy, ParticipationCode: peopleTypes.join(",") }}
      />
    </Space>
  );
};

export default AttendeeItineraryByPeopleTypeOptions;
