import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useGetCountriesQuery = getWrappedUseQuery(KEYS.GET_COUNTRIES, apiClient.Countries.getAll);

export const useGetForumCountriesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_COUNTRIES,
  apiClient.Countries.forumCountries.get,
);
