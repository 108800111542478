import { useTranslation } from "react-i18next";

import { AvailableFiltersType } from "../../types";
import DateFilterForm from "./components/DateFilterForm";
import QuestionnaireFilterForm from "./components/Questionnaire";
import SelectionSitesFilterForm from "./components/SelectionSites";

interface FilterByTypeProps<FilterType extends AvailableFiltersType> {
  type: FilterType;
}

const FilterByType = <FilterType extends AvailableFiltersType>({ type }: FilterByTypeProps<FilterType>) => {
  const { t } = useTranslation();

  if (type === null || type === undefined) {
    return null;
  }

  switch (type) {
    case "questionnaire":
      return <QuestionnaireFilterForm />;
    case "selectionSites":
      return <SelectionSitesFilterForm />;
    case "supplierContractDate":
    case "delegateSpeakerBFR":
    case "cancellationDate":
      return <DateFilterForm />;
  }

  return <div>{t("Sorry this type isn't supported yet.")}</div>;
};

export default FilterByType;
