import { Space, Typography, YesNo } from "components/styleguide";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import LinkToReport from "../components/LinkToReport";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = {
  CompanyName: "Company",
  Surname: "Surname",
};

const SummaryOfPreferencesOptions = () => {
  const { t } = useTranslation();

  const [orderBy, setOrderBy] = useState<string>("CompanyName");
  const [isWithNegatives, setIsWithNegatives] = useState<boolean>(false);

  return (
    <Space align="end">
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />

      <Space direction={"vertical"}>
        <Typography.Text>{t("With Negatives")}</Typography.Text>
        <YesNo
          style={{ width: "200px" }}
          defaultValue={false}
          onSelect={(value: boolean) => setIsWithNegatives(value)}
        />
      </Space>

      <LinkToReport parameters={{ OrderBy: orderBy, WithNegatives: isWithNegatives }} />
    </Space>
  );
};

export default SummaryOfPreferencesOptions;
