import { ForumBuildingApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumBuildingApi);

const ForumBuilding = {
  get: api.forumsForumIdVenueBuildingsGet,
  delete: api.forumsForumIdVenueBuildingsForumBuildingIdDelete,
  export: {
    get: api.forumsForumIdVenueBuildingsFacilitiesExportGet,
  },
};

export default ForumBuilding;
