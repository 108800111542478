import { TableProps } from "antd";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useSelectionSitePredefinedEventChoicesQuery } from "../../../../../../../../../api/queries/selectionSites";
import { Button, Drawer, Space } from "../../../../../../../../../components/styleguide";
import Actions from "../../../../../../../../../components/styleguide/components/Actions";
import Table from "../../../../../../../../../components/styleguide/components/Table";
import { EventOptionsChoice } from "../../../../../../../../../generated/api";
import AddNewChoice from "../AddNewChoice";

interface AddChoiceProps {
  onAdd?: (choice: EventOptionsChoice) => void;
}

const AddChoice: FC<AddChoiceProps> = ({ onAdd }) => {
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(false);

  const query = useSelectionSitePredefinedEventChoicesQuery({});

  const choices = useMemo<Array<EventOptionsChoice>>(() => query.data?.data ?? [], [query.data?.data]);
  const choicesMap = useMemo(() => {
    const map = new Map<EventOptionsChoice["id"], EventOptionsChoice>();
    choices.forEach((choice) => map.set(choice.id, choice));
    return map;
  }, [choices]);

  const showDrawer = () => setIsVisible(true);
  const hideDrawer = () => setIsVisible(false);

  const handleAddClick = (id: EventOptionsChoice["id"]) => {
    const choice = choicesMap.get(id);
    if (choice) {
      onAdd?.(choice);
      hideDrawer();
    }
  };

  const handleAddNewChoice = (choice: EventOptionsChoice) => {
    onAdd?.(choice);
    hideDrawer();
  };

  const columns: TableProps<EventOptionsChoice>["columns"] = [
    {
      dataIndex: "name",
      title: t("Choice Name"),
      width: "25%",
    },
    {
      dataIndex: "title",
      title: t("Choice Title Text"),
      width: "25%",
    },
    {
      dataIndex: "body",
      title: t("Choice Body Text"),
      width: "25%",
    },
    {
      dataIndex: "tardisLink",
      title: t("Choice Tardis Link"),
      width: "25%",
    },
    {
      dataIndex: "id",
      render: (id) => (
        <Actions horizontalAlign="right">
          <Button type="primary" onClick={() => handleAddClick(id)}>
            {t("Add Choice")}
          </Button>
        </Actions>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Button onClick={showDrawer}>{t("Add choice")}</Button>
      <Drawer open={isVisible} placement="right" title={t("Choose an option to add")} width="100%" onClose={hideDrawer}>
        <Space direction="vertical">
          <AddNewChoice onSubmit={handleAddNewChoice} />
          <Table loading={query.isLoading} columns={columns} dataSource={choices} rowKey="id" pagination={false} />
        </Space>
      </Drawer>
    </>
  );
};

export default AddChoice;
