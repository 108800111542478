import { Select } from "components/styleguide";
import { TFunction } from "react-i18next";
import { buildTemplateSelectOptions, EMAIL_CATEGORIES_OPTIONS } from "utils/emailTemplates";

export default function getFormFields(onTemplateChange: (value: string) => Promise<void>, t: TFunction) {
  return [
    {
      title: "template",
      dataIndex: "template",

      component: (
        <Select onChange={onTemplateChange} placeholder={t("Select Template")} options={buildTemplateSelectOptions()} />
      ),
    },
    {
      title: "Category",
      dataIndex: "emailTemplateCategory",
      rules: [{ required: true, message: "Required" }],
      component: <Select placeholder={t("Select Category")} options={EMAIL_CATEGORIES_OPTIONS} />,
    },
    {
      title: "name",
      dataIndex: "name",
      placeholder: "Enter Name",
      rules: [{ required: true, message: "Required" }],
    },
    {
      title: "subject",
      dataIndex: "subject",
      placeholder: "Enter Subject title",
      rules: [{ required: true, message: "Required" }],
    },
  ];
}
