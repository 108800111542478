/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageResponseQuestionnaireModel } from '../apiModels';
/**
 * QuestionnairesApi - axios parameter creator
 * @export
 */
export const QuestionnairesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [forumCode] 
         * @param {string} [title] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnairesGet: async (forumCode?: string, title?: string, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questionnaires`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forumCode !== undefined) {
                localVarQueryParameter['ForumCode'] = forumCode;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnairesApi - functional programming interface
 * @export
 */
export const QuestionnairesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnairesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [forumCode] 
         * @param {string} [title] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionnairesGet(forumCode?: string, title?: string, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseQuestionnaireModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionnairesGet(forumCode, title, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnairesApi - factory interface
 * @export
 */
export const QuestionnairesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnairesApiFp(configuration)
    return {
        /**
         * 
         * @param {QuestionnairesApiQuestionnairesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnairesGet(requestParameters: QuestionnairesApiQuestionnairesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResponseQuestionnaireModel> {
            return localVarFp.questionnairesGet(requestParameters.forumCode, requestParameters.title, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for questionnairesGet operation in QuestionnairesApi.
 * @export
 * @interface QuestionnairesApiQuestionnairesGetRequest
 */
export interface QuestionnairesApiQuestionnairesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof QuestionnairesApiQuestionnairesGet
     */
    readonly forumCode?: string

    /**
     * 
     * @type {string}
     * @memberof QuestionnairesApiQuestionnairesGet
     */
    readonly title?: string

    /**
     * 
     * @type {string}
     * @memberof QuestionnairesApiQuestionnairesGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof QuestionnairesApiQuestionnairesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof QuestionnairesApiQuestionnairesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof QuestionnairesApiQuestionnairesGet
     */
    readonly orderDir?: string
}

/**
 * QuestionnairesApi - object-oriented interface
 * @export
 * @class QuestionnairesApi
 * @extends {BaseAPI}
 */
export class QuestionnairesApi extends BaseAPI {
    /**
     * 
     * @param {QuestionnairesApiQuestionnairesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnairesApi
     */
    public questionnairesGet(requestParameters: QuestionnairesApiQuestionnairesGetRequest = {}, options?: AxiosRequestConfig) {
        return QuestionnairesApiFp(this.configuration).questionnairesGet(requestParameters.forumCode, requestParameters.title, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }
}

