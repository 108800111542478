import { ConferenceRankV2Item } from "../../../../generated/api";
import ContentItem from "../content-item";
import References from "../references";

const collectForConferenceRankV2 = (refs: References, item: ContentItem<ConferenceRankV2Item>): void => {
  const itemId = item.value.rankedItemId;
  if (!itemId) {
    return;
  }

  refs.items.add(itemId);
};

export default collectForConferenceRankV2;
