/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IntegrationForumResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponsePersonWithParticipationModel } from '../apiModels';
// @ts-ignore
import { PersonModel } from '../apiModels';
/**
 * PersonsApi - axios parameter creator
 * @export
 */
export const PersonsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets list of people in a company with data about their participation in a forum
         * @param {number} forumId 
         * @param {number} companyId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsForumForumIdCompanyCompanyIdGet: async (forumId: number, companyId: number, firstName?: string, lastName?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('personsForumForumIdCompanyCompanyIdGet', 'forumId', forumId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('personsForumForumIdCompanyCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/persons/forum/{forumId}/company/{companyId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPersonIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('personsPersonIdGet', 'id', id)
            const localVarPath = `/persons/person/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} personId 
         * @param {PersonModel} [personModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPersonIdPut: async (personId: number, personModel?: PersonModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'personId' is not null or undefined
            assertParamExists('personsPersonIdPut', 'personId', personId)
            const localVarPath = `/persons/{personId}`
                .replace(`{${"personId"}}`, encodeURIComponent(String(personId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PersonModel} [personModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPost: async (personModel?: PersonModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/persons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPublicIdForumsGet: async (publicId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicId' is not null or undefined
            assertParamExists('personsPublicIdForumsGet', 'publicId', publicId)
            const localVarPath = `/persons/{publicId}/forums`
                .replace(`{${"publicId"}}`, encodeURIComponent(String(publicId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonsApi - functional programming interface
 * @export
 */
export const PersonsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets list of people in a company with data about their participation in a forum
         * @param {number} forumId 
         * @param {number} companyId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personsForumForumIdCompanyCompanyIdGet(forumId: number, companyId: number, firstName?: string, lastName?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponsePersonWithParticipationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personsForumForumIdCompanyCompanyIdGet(forumId, companyId, firstName, lastName, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personsPersonIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personsPersonIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} personId 
         * @param {PersonModel} [personModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personsPersonIdPut(personId: number, personModel?: PersonModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personsPersonIdPut(personId, personModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PersonModel} [personModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personsPost(personModel?: PersonModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personsPost(personModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} publicId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personsPublicIdForumsGet(publicId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntegrationForumResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personsPublicIdForumsGet(publicId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PersonsApi - factory interface
 * @export
 */
export const PersonsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonsApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets list of people in a company with data about their participation in a forum
         * @param {PersonsApiPersonsForumForumIdCompanyCompanyIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsForumForumIdCompanyCompanyIdGet(requestParameters: PersonsApiPersonsForumForumIdCompanyCompanyIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponsePersonWithParticipationModel> {
            return localVarFp.personsForumForumIdCompanyCompanyIdGet(requestParameters.forumId, requestParameters.companyId, requestParameters.firstName, requestParameters.lastName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PersonsApiPersonsPersonIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPersonIdGet(requestParameters: PersonsApiPersonsPersonIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<PersonModel> {
            return localVarFp.personsPersonIdGet(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PersonsApiPersonsPersonIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPersonIdPut(requestParameters: PersonsApiPersonsPersonIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.personsPersonIdPut(requestParameters.personId, requestParameters.personModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PersonsApiPersonsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPost(requestParameters: PersonsApiPersonsPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PersonModel> {
            return localVarFp.personsPost(requestParameters.personModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PersonsApiPersonsPublicIdForumsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personsPublicIdForumsGet(requestParameters: PersonsApiPersonsPublicIdForumsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<IntegrationForumResponseModel>> {
            return localVarFp.personsPublicIdForumsGet(requestParameters.publicId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for personsForumForumIdCompanyCompanyIdGet operation in PersonsApi.
 * @export
 * @interface PersonsApiPersonsForumForumIdCompanyCompanyIdGetRequest
 */
export interface PersonsApiPersonsForumForumIdCompanyCompanyIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly companyId: number

    /**
     * 
     * @type {string}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly lastName?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof PersonsApiPersonsForumForumIdCompanyCompanyIdGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for personsPersonIdGet operation in PersonsApi.
 * @export
 * @interface PersonsApiPersonsPersonIdGetRequest
 */
export interface PersonsApiPersonsPersonIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonsApiPersonsPersonIdGet
     */
    readonly id: number
}

/**
 * Request parameters for personsPersonIdPut operation in PersonsApi.
 * @export
 * @interface PersonsApiPersonsPersonIdPutRequest
 */
export interface PersonsApiPersonsPersonIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof PersonsApiPersonsPersonIdPut
     */
    readonly personId: number

    /**
     * 
     * @type {PersonModel}
     * @memberof PersonsApiPersonsPersonIdPut
     */
    readonly personModel?: PersonModel
}

/**
 * Request parameters for personsPost operation in PersonsApi.
 * @export
 * @interface PersonsApiPersonsPostRequest
 */
export interface PersonsApiPersonsPostRequest {
    /**
     * 
     * @type {PersonModel}
     * @memberof PersonsApiPersonsPost
     */
    readonly personModel?: PersonModel
}

/**
 * Request parameters for personsPublicIdForumsGet operation in PersonsApi.
 * @export
 * @interface PersonsApiPersonsPublicIdForumsGetRequest
 */
export interface PersonsApiPersonsPublicIdForumsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PersonsApiPersonsPublicIdForumsGet
     */
    readonly publicId: string
}

/**
 * PersonsApi - object-oriented interface
 * @export
 * @class PersonsApi
 * @extends {BaseAPI}
 */
export class PersonsApi extends BaseAPI {
    /**
     * 
     * @summary Gets list of people in a company with data about their participation in a forum
     * @param {PersonsApiPersonsForumForumIdCompanyCompanyIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personsForumForumIdCompanyCompanyIdGet(requestParameters: PersonsApiPersonsForumForumIdCompanyCompanyIdGetRequest, options?: AxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personsForumForumIdCompanyCompanyIdGet(requestParameters.forumId, requestParameters.companyId, requestParameters.firstName, requestParameters.lastName, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PersonsApiPersonsPersonIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personsPersonIdGet(requestParameters: PersonsApiPersonsPersonIdGetRequest, options?: AxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personsPersonIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PersonsApiPersonsPersonIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personsPersonIdPut(requestParameters: PersonsApiPersonsPersonIdPutRequest, options?: AxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personsPersonIdPut(requestParameters.personId, requestParameters.personModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PersonsApiPersonsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personsPost(requestParameters: PersonsApiPersonsPostRequest = {}, options?: AxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personsPost(requestParameters.personModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PersonsApiPersonsPublicIdForumsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonsApi
     */
    public personsPublicIdForumsGet(requestParameters: PersonsApiPersonsPublicIdForumsGetRequest, options?: AxiosRequestConfig) {
        return PersonsApiFp(this.configuration).personsPublicIdForumsGet(requestParameters.publicId, options).then((request) => request(this.axios, this.basePath));
    }
}

