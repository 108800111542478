import { Layout as AntLayout } from 'antd';
import React from "react";

const {Header: AntHeader, Sider: AntSider, Content: AntContent} = AntLayout;

const Layout = (props) => {

  return <AntLayout {...props} />
};

const Sider = (props) => {

  return (
    <AntSider {...props} />
  )
};

const Header = ({ children, className, ...rest }) => {

  return (
    <AntHeader className={`header ${className ? className : ""}`}  id={"header"} {...rest}>
      {children}
    </AntHeader>
  )
};

Layout.Header = Header;
Layout.Sider = Sider;
Layout.Content = AntContent;

export default Layout;