import { TableProps } from "antd";
import {
  Button,
  Form,
  Input as InputWithoutOnChange,
  Space,
  YesNo as YesNoWithoutOnChange,
} from "components/styleguide";
import Actions from "components/styleguide/components/Actions";
import Table from "components/styleguide/components/Table";
import { EventOptionsChoice, EventOptionsChoiceOption } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import nextTick from "utils/nextTickUtils";

import { Input as InputWithOnChange, YesNo as YesNoWithOnChange } from "../../../../../inputs";

import styles from "./styles.module.less";

interface EditOptionsTableProps {
  submitFormOnChangeEvent?: boolean;
  add?: (option: EventOptionsChoiceOption, index: number) => void;
  remove?: (index: number) => void;
}

const EditOptionsTable: FC<EditOptionsTableProps> = ({ submitFormOnChangeEvent, add, remove }) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance<EventOptionsChoice>();

  const options: Array<EventOptionsChoiceOption> | undefined = form.getFieldValue("options");

  const Input = submitFormOnChangeEvent ? InputWithOnChange : InputWithoutOnChange;
  const YesNo = submitFormOnChangeEvent ? YesNoWithOnChange : YesNoWithoutOnChange;

  const triggerFormValidation = async () => {
    // This is a hack to trigger form validation
    // and `nextTick` is needed to wait for the form to update
    await nextTick();
    await form.validateFields();
  };

  const handleAddButtonClick = async () => {
    add?.({ text: "", value: "", label: "", default: false }, options?.length ?? 0);
    await triggerFormValidation();
  };

  const handleDeleteButtonClick = async (index: number) => {
    remove?.(index);
    await triggerFormValidation();
  };

  const columns: TableProps<EventOptionsChoiceOption>["columns"] = [
    {
      dataIndex: "text",
      title: t("Choice Text"),
      render: (_, __, index) => (
        <Form.Item name={[index, "text"]} rules={[{ required: true, message: t("Choice text can't be empty") }]}>
          <Input />
        </Form.Item>
      ),
    },
    {
      dataIndex: "value",
      title: t("Choice Value"),
      render: (_, __, index) => (
        <Form.Item name={[index, "value"]} rules={[{ required: true, message: t("Choice value can't be empty") }]}>
          <Input />
        </Form.Item>
      ),
    },
    {
      dataIndex: "default",
      title: t("Default"),
      render: (_, __, index) => (
        <Form.Item name={[index, "default"]}>
          <YesNo />
        </Form.Item>
      ),
    },
    {
      render: (_, option, index) => (
        <Actions horizontalAlign="right">
          <Button type="primary" onClick={() => handleDeleteButtonClick(index)}>
            {t("Delete")}
          </Button>
        </Actions>
      ),
    },
  ];

  return (
    <Space direction="vertical" fullWidth>
      <Table columns={columns} dataSource={options} pagination={false} rowClassName={styles.row} />
      <Button type="primary" onClick={handleAddButtonClick}>
        {t("Add Choice")}
      </Button>
    </Space>
  );
};

export default EditOptionsTable;
