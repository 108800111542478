import { useGetItineraryBookletGenerateDocumentQuery } from "api/queries/itineraryBooklet";
import { useQuestionnairesSubmissionQuery } from "api/queries/questionnaires";
import { Button, Table } from "components/styleguide";
import {
  AttendeeQuestionnaireSubmissionStatusModel,
  DocumentDefinitionTypes,
  QuestionnaireSubmissionStatus,
} from "generated/api";
import { toNumber } from "lodash";
import { SubmissionStatusMap } from "pages/Attendees/Filters/components/FilterByType/components/Questionnaire";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { downloadFileByURL } from "utils/fileUtils";

import styles from "./styles.module.less";

export default function AttendeeQuestionnaireAnswers() {
  const { t } = useTranslation();
  const { id, attendeeId } = useParams();
  const forumId = toNumber(id);
  const [questionnaireId, setQuestionnaireId] = useState<number | null>();

  const { data: { data: submissions = [] } = {}, isLoading } = useQuestionnairesSubmissionQuery({
    forumId,
    attendeeId: toNumber(attendeeId),
    statuses: [
      QuestionnaireSubmissionStatus.NotStarted,
      QuestionnaireSubmissionStatus.InProgress,
      QuestionnaireSubmissionStatus.Completed,
    ],
  });

  const { isLoading: isLoadingExport } = useGetItineraryBookletGenerateDocumentQuery(
    {
      forumId,
      forumAttendeeId: toNumber(attendeeId),
      forumQuestionnaireId: toNumber(questionnaireId),
      documentDefinitionType: DocumentDefinitionTypes.Questionnaire,
    },
    {
      enabled: !!questionnaireId && !!attendeeId,
      onSuccess: ({ data }) => {
        downloadFileByURL(data);
        setQuestionnaireId(null);
      },
    },
  );

  const handleQuestionnaireExport = (forumQuestionnaireId: number) => {
    setQuestionnaireId(forumQuestionnaireId);
  };

  const columns = [
    {
      dataIndex: "forumQuestionnaireName",
      title: t("Name"),
    },
    {
      dataIndex: "status",
      title: t("Status"),
      render: (status: QuestionnaireSubmissionStatus) => {
        return t(SubmissionStatusMap[status]);
      },
    },
    {
      dataIndex: "forumQuestionnaireId",
      render: (forumQuestionnaireId: number, record: AttendeeQuestionnaireSubmissionStatusModel) => {
        const isLoadingButton = !!questionnaireId && record.forumQuestionnaireId === questionnaireId && isLoadingExport;
        return (
          <div className={styles.buttonWrapper}>
            <Button
              disabled={isLoadingButton}
              loading={isLoadingButton}
              onClick={() => handleQuestionnaireExport(forumQuestionnaireId)}
            >
              {t("Download")}
            </Button>
          </div>
        );
      },
    },
  ];

  return <Table columns={columns} dataSource={submissions} loading={isLoading} />;
}
