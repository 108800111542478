import Icon, { DeleteFilled } from "@ant-design/icons";
import { useGetForumByIdQuery } from "api/queries/forums";
import { DraggableTable } from "components/DraggableTable";
import { Button, Form, Space } from "components/styleguide";
import DateTimezoneRenderSwitcher from "contexts/dateTimezoneRenderSwitcher";
import ForumUtcOffsetContext from "contexts/forumUtcOffset";
import { toNumber } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addTimezoneIfNotExists, stringOffsetToNumber } from "utils/dateUtils";
import { dateTimeSorter, numericalSorter } from "utils/sorterUtils";
import * as yup from "yup";

import styles from '../styles.module.less'

import SessionCell from "../SessionCell";

const dateTimeFormat = "L HH:mm";

const normalizeData = (data, timezone) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((entry) => ({
    ...entry,
    startTime: addTimezoneIfNotExists(entry.startTime, timezone),
    endTime: addTimezoneIfNotExists(entry.endTime, timezone),
  }));
};

const SessionTable = ({
  editingKey,
  data,
  form,
  loading,
  isEditing,
  onAdd,
  onCancel,
  onSave,
  onEdit,
  onDelete,
  onDragEnd,
  isSaveLoading,
  defaultTimezone,
  setDisableOrdering,
  disableOrdering = false,
  rowKey = "order",
}) => {
  const { t } = useTranslation();
  const isEditingSession = !!editingKey;

  const dataSource = useMemo(() => normalizeData(data, defaultTimezone), [data, defaultTimezone]);
  const forumOffsetString = useContext(ForumUtcOffsetContext);
  const forumOffset = stringOffsetToNumber(forumOffsetString);

  const forumId = toNumber(useParams().id);

  const disabledDate = (value) => {
    return (
      moment(value).utc() < moment(new Date(forum.startDate)).utc() ||
      moment(value).utc() > moment(new Date(forum.endDate)).utc()
    );
  };

  let getSchema = (form) =>
    yup.object().shape({
      duration: yup
        .array()
        .nullable()
        .required(t("errors.required", { prop: "$t(Start and End Time)" }))
        .test("startTimeBeforeEndTime", t("errors.custom.sessionEndTimeBeforeStartTime"), (duration) => {
          const endTimeAsString = form.getFieldValue("duration")[1];
          if (!endTimeAsString) {
            return true;
          }
          return duration[0] < new Date(endTimeAsString);
        })
        .test("endTimeAfterStartTime", t("errors.custom.sessionStartTimeAfterEndTime"), (duration) => {
          const startTimeAsString = form.getFieldValue("duration")[0];
          if (!startTimeAsString) {
            return true;
          }
          return new Date(startTimeAsString) < duration[1];
        }),
    });
  const yupSync = {
    async validator({ field }, value) {
      await getSchema(form).validateSyncAt(field, { [field]: value });
    },
  };

  const [forum, setForum] = useState({});

  useGetForumByIdQuery(
    { forumId },
    {
      onSuccess: (response) => {
        setForum(response.data);
      },
    },
  );

  // hotfix placing of correct values in form item
  // in scenario when user clicks on edit button
  // then on cancel, but after selects another row to edit
  useEffect(() => {
    if (!isEditingSession) {
      form.resetFields();
    }
  }, [form, isEditingSession]);

  const onChange = (_, __, sorter) => {
    const { column } = sorter;

    if (!column) {
      setDisableOrdering(true);
    } else {
      setDisableOrdering(false);
    }
  };

  const columns = [
    {
      title: t("order"),
      dataIndex: "order",
      key: "order",
      sorter: (a, b) => numericalSorter(a.order, b.order),
    },
    {
      title: t("startTime"),
      dataIndex: "startTime",
      key: "startTime",
      sorter: (a, b) => dateTimeSorter(a.startTime, b.startTime),
    },
    {
      title: t("endTime"),
      dataIndex: "endTime",
      key: "endTime",
      sorter: (a, b) => dateTimeSorter(a.endTime, b.endTime),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className={styles.buttonWrapper}>
            <Button
              loading={isSaveLoading}
              disabled={isSaveLoading}
              onClick={() => onSave(record)}
              style={{
                marginRight: 8,
              }}
            >
              {t("save")}
            </Button>
            <Button onClick={() => onCancel(record)}>{t("cancel")}</Button>
          </div>
        ) : (
          <div className={styles.buttonWrapper}>
            <Button disabled={isEditingSession} onClick={() => onEdit(record)}>
              {t("edit")}
            </Button>
            <Icon
              className={styles.deleteIcon}
              key={record.order}
              component={DeleteFilled}
              alt={t("delete")}
              onClick={() => onDelete(record)}
            />
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          type: col.dataIndex === "startTime" || col.dataIndex === "endTime" ? "dateTime" : "text",
          index: col.dataIndex,
          title: col.title,
          editing: isEditing(record) ? 1 : 0,
          yupSync: yupSync,
          dateTimeFormat,
          disabledDate,
          forumOffset,
        };
      },
    };
  });

  return (
    <DateTimezoneRenderSwitcher.Provider>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button onClick={onAdd} disabled={isEditingSession}>
          {t("addSession")}
        </Button>
        {!isEditingSession && <DateTimezoneRenderSwitcher.Toggle />}
        <Form form={form} component={false}>
          <DraggableTable
            components={{
              body: {
                cell: SessionCell,
              },
            }}
            id="sessions-table"
            dataSource={dataSource}
            columns={mergedColumns}
            pagination={false}
            bordered={true}
            rowKey={rowKey}
            onDragEnd={onDragEnd}
            onChange={onChange}
            loading={loading}
            disableOrdering={disableOrdering}
          ></DraggableTable>
        </Form>
      </Space>
    </DateTimezoneRenderSwitcher.Provider>
  );
};

SessionTable.propTypes = {
  editingKey: PropTypes.any,
  data: PropTypes.array,
  form: PropTypes.any,
  loading: PropTypes.bool,
  isEditing: PropTypes.func,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDragEnd: PropTypes.func,
  isSaveLoading: PropTypes.bool,
  rowKey: PropTypes.string,
  setDisableOrdering: PropTypes.func,
  disableOrdering: PropTypes.bool,
  defaultTimezone: PropTypes.string,
};

SessionTable.defaultProps = {
  defaultTimezone: "+00:00",
  rowKey: "order",
};

export default SessionTable;
