import { useAccount, useMsal } from "@azure/msal-react";

import { useUserInfoQuery } from "../../api/queries/user";

const useLogout = () => {
  const userInfo = useUserInfoQuery({});
  const { instance } = useMsal();
  const accountInfo = useAccount({ localAccountId: userInfo?.data?.sub });

  return () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accountInfo?.homeAccountId),
      mainWindowRedirectUri: "/",
      postLogoutRedirectUri: "/",
    };
    if (accountInfo?.homeAccountId) {
      instance.logoutRedirect(logoutRequest);
    }
  };
};

export default useLogout;
