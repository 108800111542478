import LOCAL_STORAGE_EVENTS from "./LOCAL_STORAGE_EVENTS";
import LOCAL_STORAGE_KEYS from "./LOCAL_STORAGE_KEYS";

/**Updates the importKey in local storage in order to register an event for value change */
export const updateFileImportKey = (newKey: string | null) => {
  localStorage.setItem(LOCAL_STORAGE_KEYS.FILE_IMPORT, JSON.stringify(newKey));
  //The event name has to match the eventListeners defined in the subscribe function
  window.dispatchEvent(new Event(LOCAL_STORAGE_EVENTS.FILE_IMPORTING));
};

export const subscribeToFileImport = (callback: () => void) => {
  window.addEventListener(LOCAL_STORAGE_EVENTS.FILE_IMPORTING, callback);
  return () => {
    window.removeEventListener(LOCAL_STORAGE_EVENTS.FILE_IMPORTING, callback);
  };
};

//Read the current value from the browser API
export const getFileImportSnapshot = () => {
  //alert("localStorage changed")
  const item = localStorage.getItem(LOCAL_STORAGE_KEYS.FILE_IMPORT);
  if (!item) {
    return null;
  }

  // Add more checks here
  return JSON.parse(item);
};
