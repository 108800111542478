import { FacilityType as ApiFacilityType } from "generated/api";

const FacilityType = {
  MeetingRoom: 1,
  ConferenceRoom: 2,
  SpeedMeetings: 3,
  Restaurant: 4,
  Bedroom: 5,

  toApiFacilityType(type) {
    switch (type) {
      case 1:
        return ApiFacilityType.MeetingRoom;
      case 2:
        return ApiFacilityType.ConferenceRoom;
      case 3:
        return ApiFacilityType.SpeedMeetings;
      case 4:
        return ApiFacilityType.Restaurant;
      case 5:
        return ApiFacilityType.Bedroom;
    }
  },

  fromApiFacilityType(type) {
    switch (type) {
      case ApiFacilityType.MeetingRoom:
        return FacilityType.MeetingRoom;
      case ApiFacilityType.ConferenceRoom:
        return FacilityType.ConferenceRoom;
      case ApiFacilityType.SpeedMeetings:
        return FacilityType.SpeedMeetings;
      case ApiFacilityType.Restaurant:
        return FacilityType.Restaurant;
      case ApiFacilityType.Bedroom:
        return FacilityType.Bedroom;
    }
  },

  toDisplayName(type) {
    switch (type) {
      case 1:
        return "Meeting Room";
      case 2:
        return "Conference Room";
      case 3:
        return "Speed Meetings";
      case 4:
        return "Restaurant";
      case 5:
        return "Bedroom";
      default:
        return "";
    }
  },
};

export default FacilityType;
