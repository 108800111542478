import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons/lib/components/Icon";
import { useQueryClient } from "@tanstack/react-query";
import { Space } from "antd";
import { useDeleteSelectionSite, useGetSelectionSiteItemMutation } from "api/mutations/selectionSites";
import KEYS from "api/queries/keys";
import { Button, Modal, Table, Tag } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import SelectionSiteExportButton from "./SelectionSiteExportButton";

const { confirm, warning } = Modal;

const buildColumn = (title = "", key, options) => ({
  title,
  key,
  dataIndex: key,
  ...options,
});

const getTranslationsRenderer = (languages) =>
  languages?.map((language) => (
    <Tag key={language} style={{ borderRadius: "10px" }}>
      {language}
    </Tag>
  ));

const SelectionSitesTable = ({ className, items, isLoading, handleSort, forumId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const getSelectionsSite = useGetSelectionSiteItemMutation();
  const deleteSelection = useDeleteSelectionSite();
  const queryClient = useQueryClient();

  const showDeleteConfirm = (selectionSiteId) => {
    confirm({
      title: t("confirmation"),
      content: t("selectionSitesDeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        await deleteSelection.mutateAsync(
          { forumId, selectionSiteId },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_FORUM_GROUPS]);
              toast.success(t("selectionSiteDeleteSuccess"));
            },
            onError: ({ response }) => {
              if (ErrorTypes.isOfType(response, ErrorTypes.ReferencedObjectCannotBeDeleted)) {
                toast.error(t("The selection site cannot be deleted as it has attendee's selections"));
              }
            },
          },
        );
      },
    });
  };

  const showEditConfirm = (record) => {
    warning({
      title: t("warning"),
      content: t("selectionSitesEditWarning"),
      onOk: () =>
        navigate(`${record?.id}`, {
          state: { ...location?.state, selectionSiteName: record?.name },
        }),
    });
  };

  const showCannotDeleteModal = () => {
    confirm({
      title: t("warning"),
      content: t("selectionSitesCantDelete"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("ok"),
      okButtonProps: { style: { display: "none" } },
    });
  };

  const handleSelectionSiteDelete = (selectionSiteId) => {
    getSelectionsSite.mutate(
      {
        forumId,
        selectionSiteId,
      },
      {
        onSuccess: ({ data }) => {
          if (data.status === "Open") {
            showCannotDeleteModal();
          } else {
            showDeleteConfirm(selectionSiteId);
          }
        },
      },
    );
  };

  const handleEdit = (record) => {
    const selectionSiteId = record.id;
    getSelectionsSite.mutate(
      {
        forumId,
        selectionSiteId,
      },
      {
        onSuccess: ({ data }) => {
          if (data.status === "Open") {
            showEditConfirm(record);
          } else {
            navigate(`${record?.id}`, {
              state: { ...location?.state, selectionSiteName: record?.name },
            });
          }
        },
      },
    );
  };

  const getActionButtonsRenderer = useCallback(
    (record) => (
      <Space size="middle" style={{ width: "100%", justifyContent: "flex-end" }}>
        <SelectionSiteExportButton selectionSiteId={record.id} />
        <Button>{t("preview")}</Button>
        <Button onClick={() => handleEdit(record)}>{t("edit")}</Button>
        <Icon
          className="delete-icon"
          component={DeleteFilled}
          alt="delete"
          onClick={() => handleSelectionSiteDelete(record.id)}
        />
      </Space>
    ),
    [],
  );

  const columns = useMemo(
    () => [
      buildColumn(t("name"), "name", { width: "25%", sorter: true }),
      buildColumn(t("translations"), "languages", {
        render: getTranslationsRenderer,
      }),
      buildColumn(null, null, {
        width: "20%",
        render: (_, record) => getActionButtonsRenderer(record),
      }),
    ],
    [navigate],
  );

  return (
    <Table
      data-testid="selectionSitesTable"
      className={className}
      dataSource={items}
      columns={columns}
      bordered
      loading={isLoading}
      rowkey="id"
      onChange={handleSort}
    />
  );
};

SelectionSitesTable.propTypes = {
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  handleSort: PropTypes.func,
  forumId: PropTypes.number,
  setIsEdit: PropTypes.func,
  setSelectedSelectionSite: PropTypes.func,
  setIsDrawerVisible: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      forumId: PropTypes.number,
      name: PropTypes.string,
      translations: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

SelectionSitesTable.defaultProps = {
  className: undefined,
  isLoading: false,
  items: [],
};

export default SelectionSitesTable;
