/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { EmailTemplateCategory } from '../apiModels';
// @ts-ignore
import { EmailTemplateRequest } from '../apiModels';
// @ts-ignore
import { EmailTemplateResponse } from '../apiModels';
// @ts-ignore
import { EmailTemplateResponseBase } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseSentEmailResponseModel } from '../apiModels';
// @ts-ignore
import { ReferencedObjectCannotBeDeletedExceptionResponse } from '../apiModels';
// @ts-ignore
import { SendEmailRequestBase } from '../apiModels';
// @ts-ignore
import { SendEmailToMultipleUsersRequest } from '../apiModels';
/**
 * EmailApi - axios parameter creator
 * @export
 */
export const EmailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send email to attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {SendEmailRequestBase} [sendEmailRequestBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailForumForumIdAttendeeAttendeeIdPut: async (forumId: number, attendeeId: number, sendEmailRequestBase?: SendEmailRequestBase, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('emailForumForumIdAttendeeAttendeeIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('emailForumForumIdAttendeeAttendeeIdPut', 'attendeeId', attendeeId)
            const localVarPath = `/email/forum/{forumId}/attendee/{attendeeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailRequestBase, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send email to attendees
         * @param {number} forumId 
         * @param {SendEmailToMultipleUsersRequest} [sendEmailToMultipleUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailForumForumIdAttendeesPut: async (forumId: number, sendEmailToMultipleUsersRequest?: SendEmailToMultipleUsersRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('emailForumForumIdAttendeesPut', 'forumId', forumId)
            const localVarPath = `/email/forum/{forumId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendEmailToMultipleUsersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [sender] 
         * @param {string} [recipient] 
         * @param {string} [subject] 
         * @param {EmailTemplateCategory} [template] 
         * @param {boolean} [isSent] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailForumForumIdUserGet: async (forumId: number, sender?: string, recipient?: string, subject?: string, template?: EmailTemplateCategory, isSent?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('emailForumForumIdUserGet', 'forumId', forumId)
            const localVarPath = `/email/forum/{forumId}/user`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sender !== undefined) {
                localVarQueryParameter['Sender'] = sender;
            }

            if (recipient !== undefined) {
                localVarQueryParameter['Recipient'] = recipient;
            }

            if (subject !== undefined) {
                localVarQueryParameter['Subject'] = subject;
            }

            if (template !== undefined) {
                localVarQueryParameter['Template'] = template;
            }

            if (isSent !== undefined) {
                localVarQueryParameter['IsSent'] = isSent;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload image for template
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailImagePost: async (formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all templates
         * @param {boolean} [isDefault] 
         * @param {EmailTemplateCategory} [emailTemplateCategory] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesGet: async (isDefault?: boolean, emailTemplateCategory?: EmailTemplateCategory, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isDefault !== undefined) {
                localVarQueryParameter['IsDefault'] = isDefault;
            }

            if (emailTemplateCategory !== undefined) {
                localVarQueryParameter['EmailTemplateCategory'] = emailTemplateCategory;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create template
         * @param {EmailTemplateRequest} [emailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesPost: async (emailTemplateRequest?: EmailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete email template
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateIdDelete: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('emailTemplatesTemplateIdDelete', 'templateId', templateId)
            const localVarPath = `/email/templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get email template
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateIdGet: async (templateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('emailTemplatesTemplateIdGet', 'templateId', templateId)
            const localVarPath = `/email/templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update email template
         * @param {number} templateId 
         * @param {EmailTemplateRequest} [emailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateIdPut: async (templateId: number, emailTemplateRequest?: EmailTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('emailTemplatesTemplateIdPut', 'templateId', templateId)
            const localVarPath = `/email/templates/{templateId}`
                .replace(`{${"templateId"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(emailTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailApi - functional programming interface
 * @export
 */
export const EmailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EmailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send email to attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {SendEmailRequestBase} [sendEmailRequestBase] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailForumForumIdAttendeeAttendeeIdPut(forumId: number, attendeeId: number, sendEmailRequestBase?: SendEmailRequestBase, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailForumForumIdAttendeeAttendeeIdPut(forumId, attendeeId, sendEmailRequestBase, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send email to attendees
         * @param {number} forumId 
         * @param {SendEmailToMultipleUsersRequest} [sendEmailToMultipleUsersRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailForumForumIdAttendeesPut(forumId: number, sendEmailToMultipleUsersRequest?: SendEmailToMultipleUsersRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailForumForumIdAttendeesPut(forumId, sendEmailToMultipleUsersRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [sender] 
         * @param {string} [recipient] 
         * @param {string} [subject] 
         * @param {EmailTemplateCategory} [template] 
         * @param {boolean} [isSent] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailForumForumIdUserGet(forumId: number, sender?: string, recipient?: string, subject?: string, template?: EmailTemplateCategory, isSent?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseSentEmailResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailForumForumIdUserGet(forumId, sender, recipient, subject, template, isSent, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload image for template
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailImagePost(formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailImagePost(formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all templates
         * @param {boolean} [isDefault] 
         * @param {EmailTemplateCategory} [emailTemplateCategory] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesGet(isDefault?: boolean, emailTemplateCategory?: EmailTemplateCategory, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EmailTemplateResponseBase>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesGet(isDefault, emailTemplateCategory, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create template
         * @param {EmailTemplateRequest} [emailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesPost(emailTemplateRequest?: EmailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesPost(emailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete email template
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateIdDelete(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateIdDelete(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get email template
         * @param {number} templateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateIdGet(templateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateIdGet(templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update email template
         * @param {number} templateId 
         * @param {EmailTemplateRequest} [emailTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailTemplatesTemplateIdPut(templateId: number, emailTemplateRequest?: EmailTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EmailTemplateResponseBase>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailTemplatesTemplateIdPut(templateId, emailTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EmailApi - factory interface
 * @export
 */
export const EmailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EmailApiFp(configuration)
    return {
        /**
         * 
         * @summary Send email to attendee
         * @param {EmailApiEmailForumForumIdAttendeeAttendeeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailForumForumIdAttendeeAttendeeIdPut(requestParameters: EmailApiEmailForumForumIdAttendeeAttendeeIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.emailForumForumIdAttendeeAttendeeIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.sendEmailRequestBase, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send email to attendees
         * @param {EmailApiEmailForumForumIdAttendeesPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailForumForumIdAttendeesPut(requestParameters: EmailApiEmailForumForumIdAttendeesPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.emailForumForumIdAttendeesPut(requestParameters.forumId, requestParameters.sendEmailToMultipleUsersRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EmailApiEmailForumForumIdUserGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailForumForumIdUserGet(requestParameters: EmailApiEmailForumForumIdUserGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseSentEmailResponseModel> {
            return localVarFp.emailForumForumIdUserGet(requestParameters.forumId, requestParameters.sender, requestParameters.recipient, requestParameters.subject, requestParameters.template, requestParameters.isSent, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload image for template
         * @param {EmailApiEmailImagePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailImagePost(requestParameters: EmailApiEmailImagePostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.emailImagePost(requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all templates
         * @param {EmailApiEmailTemplatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesGet(requestParameters: EmailApiEmailTemplatesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<EmailTemplateResponseBase>> {
            return localVarFp.emailTemplatesGet(requestParameters.isDefault, requestParameters.emailTemplateCategory, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create template
         * @param {EmailApiEmailTemplatesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesPost(requestParameters: EmailApiEmailTemplatesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponseBase> {
            return localVarFp.emailTemplatesPost(requestParameters.emailTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete email template
         * @param {EmailApiEmailTemplatesTemplateIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateIdDelete(requestParameters: EmailApiEmailTemplatesTemplateIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.emailTemplatesTemplateIdDelete(requestParameters.templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get email template
         * @param {EmailApiEmailTemplatesTemplateIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateIdGet(requestParameters: EmailApiEmailTemplatesTemplateIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponse> {
            return localVarFp.emailTemplatesTemplateIdGet(requestParameters.templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update email template
         * @param {EmailApiEmailTemplatesTemplateIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailTemplatesTemplateIdPut(requestParameters: EmailApiEmailTemplatesTemplateIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<EmailTemplateResponseBase> {
            return localVarFp.emailTemplatesTemplateIdPut(requestParameters.templateId, requestParameters.emailTemplateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for emailForumForumIdAttendeeAttendeeIdPut operation in EmailApi.
 * @export
 * @interface EmailApiEmailForumForumIdAttendeeAttendeeIdPutRequest
 */
export interface EmailApiEmailForumForumIdAttendeeAttendeeIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailForumForumIdAttendeeAttendeeIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailForumForumIdAttendeeAttendeeIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {SendEmailRequestBase}
     * @memberof EmailApiEmailForumForumIdAttendeeAttendeeIdPut
     */
    readonly sendEmailRequestBase?: SendEmailRequestBase
}

/**
 * Request parameters for emailForumForumIdAttendeesPut operation in EmailApi.
 * @export
 * @interface EmailApiEmailForumForumIdAttendeesPutRequest
 */
export interface EmailApiEmailForumForumIdAttendeesPutRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailForumForumIdAttendeesPut
     */
    readonly forumId: number

    /**
     * 
     * @type {SendEmailToMultipleUsersRequest}
     * @memberof EmailApiEmailForumForumIdAttendeesPut
     */
    readonly sendEmailToMultipleUsersRequest?: SendEmailToMultipleUsersRequest
}

/**
 * Request parameters for emailForumForumIdUserGet operation in EmailApi.
 * @export
 * @interface EmailApiEmailForumForumIdUserGetRequest
 */
export interface EmailApiEmailForumForumIdUserGetRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly sender?: string

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly recipient?: string

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly subject?: string

    /**
     * 
     * @type {EmailTemplateCategory}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly template?: EmailTemplateCategory

    /**
     * 
     * @type {boolean}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly isSent?: boolean

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailForumForumIdUserGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for emailImagePost operation in EmailApi.
 * @export
 * @interface EmailApiEmailImagePostRequest
 */
export interface EmailApiEmailImagePostRequest {
    /**
     * 
     * @type {File}
     * @memberof EmailApiEmailImagePost
     */
    readonly formFile?: File
}

/**
 * Request parameters for emailTemplatesGet operation in EmailApi.
 * @export
 * @interface EmailApiEmailTemplatesGetRequest
 */
export interface EmailApiEmailTemplatesGetRequest {
    /**
     * 
     * @type {boolean}
     * @memberof EmailApiEmailTemplatesGet
     */
    readonly isDefault?: boolean

    /**
     * 
     * @type {EmailTemplateCategory}
     * @memberof EmailApiEmailTemplatesGet
     */
    readonly emailTemplateCategory?: EmailTemplateCategory

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailTemplatesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof EmailApiEmailTemplatesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for emailTemplatesPost operation in EmailApi.
 * @export
 * @interface EmailApiEmailTemplatesPostRequest
 */
export interface EmailApiEmailTemplatesPostRequest {
    /**
     * 
     * @type {EmailTemplateRequest}
     * @memberof EmailApiEmailTemplatesPost
     */
    readonly emailTemplateRequest?: EmailTemplateRequest
}

/**
 * Request parameters for emailTemplatesTemplateIdDelete operation in EmailApi.
 * @export
 * @interface EmailApiEmailTemplatesTemplateIdDeleteRequest
 */
export interface EmailApiEmailTemplatesTemplateIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailTemplatesTemplateIdDelete
     */
    readonly templateId: number
}

/**
 * Request parameters for emailTemplatesTemplateIdGet operation in EmailApi.
 * @export
 * @interface EmailApiEmailTemplatesTemplateIdGetRequest
 */
export interface EmailApiEmailTemplatesTemplateIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailTemplatesTemplateIdGet
     */
    readonly templateId: number
}

/**
 * Request parameters for emailTemplatesTemplateIdPut operation in EmailApi.
 * @export
 * @interface EmailApiEmailTemplatesTemplateIdPutRequest
 */
export interface EmailApiEmailTemplatesTemplateIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof EmailApiEmailTemplatesTemplateIdPut
     */
    readonly templateId: number

    /**
     * 
     * @type {EmailTemplateRequest}
     * @memberof EmailApiEmailTemplatesTemplateIdPut
     */
    readonly emailTemplateRequest?: EmailTemplateRequest
}

/**
 * EmailApi - object-oriented interface
 * @export
 * @class EmailApi
 * @extends {BaseAPI}
 */
export class EmailApi extends BaseAPI {
    /**
     * 
     * @summary Send email to attendee
     * @param {EmailApiEmailForumForumIdAttendeeAttendeeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailForumForumIdAttendeeAttendeeIdPut(requestParameters: EmailApiEmailForumForumIdAttendeeAttendeeIdPutRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailForumForumIdAttendeeAttendeeIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.sendEmailRequestBase, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send email to attendees
     * @param {EmailApiEmailForumForumIdAttendeesPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailForumForumIdAttendeesPut(requestParameters: EmailApiEmailForumForumIdAttendeesPutRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailForumForumIdAttendeesPut(requestParameters.forumId, requestParameters.sendEmailToMultipleUsersRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EmailApiEmailForumForumIdUserGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailForumForumIdUserGet(requestParameters: EmailApiEmailForumForumIdUserGetRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailForumForumIdUserGet(requestParameters.forumId, requestParameters.sender, requestParameters.recipient, requestParameters.subject, requestParameters.template, requestParameters.isSent, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload image for template
     * @param {EmailApiEmailImagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailImagePost(requestParameters: EmailApiEmailImagePostRequest = {}, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailImagePost(requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all templates
     * @param {EmailApiEmailTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailTemplatesGet(requestParameters: EmailApiEmailTemplatesGetRequest = {}, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailTemplatesGet(requestParameters.isDefault, requestParameters.emailTemplateCategory, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create template
     * @param {EmailApiEmailTemplatesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailTemplatesPost(requestParameters: EmailApiEmailTemplatesPostRequest = {}, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailTemplatesPost(requestParameters.emailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete email template
     * @param {EmailApiEmailTemplatesTemplateIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailTemplatesTemplateIdDelete(requestParameters: EmailApiEmailTemplatesTemplateIdDeleteRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailTemplatesTemplateIdDelete(requestParameters.templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get email template
     * @param {EmailApiEmailTemplatesTemplateIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailTemplatesTemplateIdGet(requestParameters: EmailApiEmailTemplatesTemplateIdGetRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailTemplatesTemplateIdGet(requestParameters.templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update email template
     * @param {EmailApiEmailTemplatesTemplateIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailApi
     */
    public emailTemplatesTemplateIdPut(requestParameters: EmailApiEmailTemplatesTemplateIdPutRequest, options?: AxiosRequestConfig) {
        return EmailApiFp(this.configuration).emailTemplatesTemplateIdPut(requestParameters.templateId, requestParameters.emailTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

