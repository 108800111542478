import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";

import apiClient from "../../../api-client";
import KEYS from "../keys";

const useGetTimeTypeLabelsQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_TIME_TYPE_LABELS,
  apiClient.ItineraryBookletsForumTimeTypeLabel.get,
);

export { useGetTimeTypeLabelsQuery };
