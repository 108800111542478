import { ReactComponent as Logo } from "assets/img/logo.svg";
import { ReactComponent as ShipLogo } from "assets/img/richmond.svg";
import { Col, Divider, Row, Typography } from "components/styleguide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

const Footer: FC = () => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <footer className={styles.footer}>
      <Divider />
      <Row gutter={20} className={styles.row} justify="space-between">
        <Col className={styles.col}>
          <Logo width={140} />
        </Col>
        <Col className={styles.col}>
          <Text>
            {t("London")} | {t("New York")} | {t("Milan")} | {t("Basel")}
          </Text>
        </Col>
        <Col className={styles.col}>
          <ShipLogo width={140} />
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
