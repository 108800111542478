/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileResponseModel } from '../apiModels';
// @ts-ignore
import { ForumGroupDocumentResponseModel } from '../apiModels';
/**
 * ForumGroupDocumentApi - axios parameter creator
 * @export
 */
export const ForumGroupDocumentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes document of forum group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsDocumentIdDelete: async (forumId: number, groupId: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsDocumentIdDelete', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsDocumentIdDelete', 'groupId', groupId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsDocumentIdDelete', 'documentId', documentId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/documents/{documentId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get read access URL (with SAS token) for downloading a forum group document from Storage Account
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsDocumentIdGet: async (forumId: number, groupId: number, documentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsDocumentIdGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsDocumentIdGet', 'groupId', groupId)
            // verify required parameter 'documentId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsDocumentIdGet', 'documentId', documentId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/documents/{documentId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"documentId"}}`, encodeURIComponent(String(documentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about forum group uploaded documents
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsGet: async (forumId: number, groupId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/documents`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload the formFile and the document details through **multipart/form-data**
         * @summary Upload a document to forum group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} documentName 
         * @param {string} [text] 
         * @param {string} [publishFrom] 
         * @param {string} [publishUntil] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsPost: async (forumId: number, groupId: number, documentName: string, text?: string, publishFrom?: string, publishUntil?: string, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsPost', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsPost', 'groupId', groupId)
            // verify required parameter 'documentName' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdDocumentsPost', 'documentName', documentName)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/documents`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (documentName !== undefined) { 
                localVarFormParams.append('DocumentName', documentName as any);
            }
    
            if (text !== undefined) { 
                localVarFormParams.append('Text', text as any);
            }
    
            if (publishFrom !== undefined) { 
                localVarFormParams.append('PublishFrom', publishFrom as any);
            }
    
            if (publishUntil !== undefined) { 
                localVarFormParams.append('PublishUntil', publishUntil as any);
            }
    
            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumGroupDocumentApi - functional programming interface
 * @export
 */
export const ForumGroupDocumentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumGroupDocumentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes document of forum group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdDocumentsDocumentIdDelete(forumId: number, groupId: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdDocumentsDocumentIdDelete(forumId, groupId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get read access URL (with SAS token) for downloading a forum group document from Storage Account
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} documentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdDocumentsDocumentIdGet(forumId: number, groupId: number, documentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdDocumentsDocumentIdGet(forumId, groupId, documentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about forum group uploaded documents
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdDocumentsGet(forumId: number, groupId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumGroupDocumentResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdDocumentsGet(forumId, groupId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload the formFile and the document details through **multipart/form-data**
         * @summary Upload a document to forum group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} documentName 
         * @param {string} [text] 
         * @param {string} [publishFrom] 
         * @param {string} [publishUntil] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdDocumentsPost(forumId: number, groupId: number, documentName: string, text?: string, publishFrom?: string, publishUntil?: string, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdDocumentsPost(forumId, groupId, documentName, text, publishFrom, publishUntil, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumGroupDocumentApi - factory interface
 * @export
 */
export const ForumGroupDocumentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumGroupDocumentApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes document of forum group
         * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsDocumentIdDelete(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdDocumentsDocumentIdDelete(requestParameters.forumId, requestParameters.groupId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get read access URL (with SAS token) for downloading a forum group document from Storage Account
         * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsDocumentIdGet(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<FileResponseModel> {
            return localVarFp.forumForumIdGroupsGroupIdDocumentsDocumentIdGet(requestParameters.forumId, requestParameters.groupId, requestParameters.documentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about forum group uploaded documents
         * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsGet(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumGroupDocumentResponseModel>> {
            return localVarFp.forumForumIdGroupsGroupIdDocumentsGet(requestParameters.forumId, requestParameters.groupId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload the formFile and the document details through **multipart/form-data**
         * @summary Upload a document to forum group
         * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdDocumentsPost(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdDocumentsPost(requestParameters.forumId, requestParameters.groupId, requestParameters.documentName, requestParameters.text, requestParameters.publishFrom, requestParameters.publishUntil, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdGroupsGroupIdDocumentsDocumentIdDelete operation in ForumGroupDocumentApi.
 * @export
 * @interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDeleteRequest
 */
export interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDelete
     */
    readonly groupId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDelete
     */
    readonly documentId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdDocumentsDocumentIdGet operation in ForumGroupDocumentApi.
 * @export
 * @interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGetRequest
 */
export interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGet
     */
    readonly groupId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGet
     */
    readonly documentId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdDocumentsGet operation in ForumGroupDocumentApi.
 * @export
 * @interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGetRequest
 */
export interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGet
     */
    readonly groupId: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdGroupsGroupIdDocumentsPost operation in ForumGroupDocumentApi.
 * @export
 * @interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPostRequest
 */
export interface ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly groupId: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly documentName: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly text?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly publishFrom?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly publishUntil?: string

    /**
     * 
     * @type {File}
     * @memberof ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPost
     */
    readonly formFile?: File
}

/**
 * ForumGroupDocumentApi - object-oriented interface
 * @export
 * @class ForumGroupDocumentApi
 * @extends {BaseAPI}
 */
export class ForumGroupDocumentApi extends BaseAPI {
    /**
     * 
     * @summary Deletes document of forum group
     * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupDocumentApi
     */
    public forumForumIdGroupsGroupIdDocumentsDocumentIdDelete(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumGroupDocumentApiFp(this.configuration).forumForumIdGroupsGroupIdDocumentsDocumentIdDelete(requestParameters.forumId, requestParameters.groupId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get read access URL (with SAS token) for downloading a forum group document from Storage Account
     * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupDocumentApi
     */
    public forumForumIdGroupsGroupIdDocumentsDocumentIdGet(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsDocumentIdGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupDocumentApiFp(this.configuration).forumForumIdGroupsGroupIdDocumentsDocumentIdGet(requestParameters.forumId, requestParameters.groupId, requestParameters.documentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about forum group uploaded documents
     * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupDocumentApi
     */
    public forumForumIdGroupsGroupIdDocumentsGet(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupDocumentApiFp(this.configuration).forumForumIdGroupsGroupIdDocumentsGet(requestParameters.forumId, requestParameters.groupId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload the formFile and the document details through **multipart/form-data**
     * @summary Upload a document to forum group
     * @param {ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupDocumentApi
     */
    public forumForumIdGroupsGroupIdDocumentsPost(requestParameters: ForumGroupDocumentApiForumForumIdGroupsGroupIdDocumentsPostRequest, options?: AxiosRequestConfig) {
        return ForumGroupDocumentApiFp(this.configuration).forumForumIdGroupsGroupIdDocumentsPost(requestParameters.forumId, requestParameters.groupId, requestParameters.documentName, requestParameters.text, requestParameters.publishFrom, requestParameters.publishUntil, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }
}

