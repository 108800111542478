import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useGetVenuesQuery = getWrappedUseQuery(KEYS.GET_VENUES, apiClient.Venues.get);

export const useGetVenueQuery = getWrappedUseQuery(KEYS.GET_VENUE, apiClient.Venues.getById);

export const useGetVenueFacilitiesQuery = getWrappedUseQuery(
  KEYS.GET_VENUE_FACILITIES,
  apiClient.Venues.facilities.get,
);

export const useVenueTablesQuery = getWrappedUseQuery(KEYS.GET_VENUE_TABLES, apiClient.Venues.tables.get);
