import { useQueryClient } from "@tanstack/react-query";
import { useCreateSeminarTypeMutation, useUpdateSeminarTypeMutation } from "api/mutations/seminarTypes";
import KEYS from "api/queries/keys";
import { Button, Drawer, Form, Input } from "components/styleguide";
import { FIXED_CODES } from "constants/seminarTypes";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import FullHeightForm from "../../../components/FullHeightForm";
import { getFormFields } from "./helpers";

const codeFormat = new RegExp("^[A-Z]$");

export default function AddSeminarType({ visible, onClose, seminarType, existingSeminarTypes, setSort }) {
  const { t } = useTranslation();
  const forumId = useParams().id;
  const createMutation = useCreateSeminarTypeMutation();
  const updateMutation = useUpdateSeminarTypeMutation();
  const queryClient = useQueryClient();

  const validateCodeFormat = async (_, value) => {
    return value.length === 0 || codeFormat.test(value) ? Promise.resolve() : Promise.reject();
  };

  const validateUniqueCode = async (_, value) => {
    const codes = existingSeminarTypes.map((s) => s.code);
    if (seminarType) {
      if (value.length === 0 || !codes.includes(value) || seminarType.code === value) {
        return Promise.resolve();
      }
      return Promise.reject();
    } else {
      if (value.length === 0 || !codes.includes(value)) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
  };

  const submit = (values) => {
    if (seminarType) {
      updateMutation.mutate(
        { forumId, seminarTypeId: seminarType.id, seminarType: values },
        {
          onSuccess: () => {
            setSort({ orderBy: "code", orderDir: "asc" });
            queryClient.invalidateQueries([KEYS.GET_SEMINAR_TYPES]);
            onClose();
          },
        },
      );
      onClose();
    } else {
      createMutation.mutate(
        { forumId, seminarType: values },
        {
          onSuccess: () => {
            setSort({ orderBy: "code", orderDir: "asc" });
            queryClient.invalidateQueries([KEYS.GET_SEMINAR_TYPES]);
            onClose();
          },
        },
      );
    }
  };

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      placement="right"
      title={seminarType ? t("Edit Seminar Type") : t("Add New Seminar Type")}
      size="large"
      destroyOnClose
    >
      <FullHeightForm
        layout="vertical"
        id="seminarTypeForm"
        name="seminar-type-form"
        onFinish={submit}
        labelWrap
        actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
        actionsAppend={
          <Button htmlType="submit" type="primary" loading={createMutation.isLoading || updateMutation.isLoading}>
            {t(seminarType ? "Update" : "Create")}
          </Button>
        }
      >
        {getFormFields({
          t,
          validateCodeFormat,
          validateUniqueCode,
        }).map((f) => (
          <Form.Item
            initialValue={seminarType ? seminarType[f.dataIndex] : null}
            label={t(f.title)}
            name={f.dataIndex}
            key={f.dataIndex}
            rules={f.rules ?? []}
          >
            {f.component ?? <Input disabled={f.dataIndex === "code" && FIXED_CODES.includes(seminarType?.code)} />}
          </Form.Item>
        ))}
      </FullHeightForm>
    </Drawer>
  );
}

AddSeminarType.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  seminarType: PropTypes.object,
  existingSeminarTypes: PropTypes.arrayOf(PropTypes.object),
  setSort: PropTypes.func,
};
