export const EMAIL_CATEGORIES = {
  general: "General",
  questionnaire: "Questionnaire",
  selectionSite: "SelectionSite",
};

export const EMAIL_CATEGORIES_OPTIONS = [
  { label: "General", value: EMAIL_CATEGORIES.general },
  { label: "Questionnaire", value: EMAIL_CATEGORIES.questionnaire },
  { label: "Selection Site", value: EMAIL_CATEGORIES.selectionSite },
];

const TEMPLATES = {
  [EMAIL_CATEGORIES.questionnaire]: [
    {
      label: "Open Profiling Questionnaire",
      value: "./utils/open-profiling-questionnaire.json",
    },
    {
      label: "Open Profiling Questionnaire - Delegates",
      value: "./utils/open-profiling-questionnaire-delegates.json",
    },
  ],
  [EMAIL_CATEGORIES.selectionSite]: [
    {
      label: "Open Selection Site - Admin Contacts",
      value: "./utils/open-selection-site-admin-contacts.json",
    },
    {
      label: "Open Selection Site - Delegates",
      value: "./utils/open-selection-site-delegates.json",
    },
    {
      label: "Open Selection Site - Executives",
      value: "./utils/open-selection-site-executives.json",
    },
    {
      label: "Open Selection Site - Piggy Backers",
      value: "./utils/open-selection-site-piggy-backers.json",
    },
  ],
  [EMAIL_CATEGORIES.general]: [
    {
      label: "Itinerary ready - All groups",
      value: "./utils/itinerary-ready-all-groups.json",
    },
  ],
};

export const getCategoryByTemplatePath = (path: string) => {
  for (const [category, templates] of Object.entries(TEMPLATES)) {
    if (templates.some((template) => template.value === path)) {
      return category;
    }
  }
  return null;
};

export const buildTemplateSelectOptions = () => {
  return Object.entries(TEMPLATES).map(([category, templates]) => ({
    label: category,
    options: templates.map(({ label, value }) => ({
      label,
      value,
      category,
    })),
  }));
};
