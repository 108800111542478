import { InfoCircleOutlined } from "@ant-design/icons";
import clsx from "clsx";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

export enum InfoSize {
  SMALL = "small",
  BIG = "big",
}

export enum InfoWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

interface InfoProps {
  articleId: string;
  className?: string;
  type?: InfoWeight;
  size?: InfoSize;
}

const Info: FC<InfoProps> = ({ articleId, className, type = InfoWeight.NORMAL, size = InfoSize.SMALL }) => {
  const { t } = useTranslation();

  const baseUrl = "https://richmondevents.zendesk.com/hc/en-us/articles";

  return (
    <a
      className={clsx(styles.link, styles[size], className)}
      href={`${baseUrl}/${articleId}`}
      target="_blank"
      rel="noreferrer"
      title={t("How do I use this section?")}
    >
      <InfoCircleOutlined className={clsx(styles.icon, styles[type], styles[size])} />
    </a>
  );
};

export default Info;
