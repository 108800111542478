import * as i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const isDevelopment = process.env.NODE_ENV === "development";

export const languages = {
  "en-GB": {
    name: "English UK",
    key: "en-GB",
  },
  "en-US": {
    name: "English US",
    key: "en-US",
  },
  fr: {
    name: "French",
    key: "fr",
  },
  it: {
    name: "Italian",
    key: "it",
  },
  de: {
    name: "German",
    key: "de",
  },
};

export const FALLBACK_LANGUAGE = languages["en-GB"].key;

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: isDevelopment,
    fallbackLng: FALLBACK_LANGUAGE,
    interpolation: {
      skipOnVariables: false,
    },
  });

export default i18n;
