import { Space, Typography, YesNo } from "components/styleguide";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface WithJointSelectOptionProps {
  isWithJoint: boolean;
  setIsWithJoint: Dispatch<SetStateAction<boolean>>;
}

const WithJointSelectOption = ({ isWithJoint, setIsWithJoint }: WithJointSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("With Joint")}</Typography.Text>
      <YesNo style={{ width: "200px" }} value={isWithJoint} onSelect={setIsWithJoint} />
    </Space>
  );
};

export default WithJointSelectOption;
