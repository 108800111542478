import { ForumAttendeeApi, ForumAttendeeItineraryApi, ForumAttendeePreferencesApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeApi);
const preferencesApi = initAPIClient(ForumAttendeePreferencesApi);
const itineraryApi = initAPIClient(ForumAttendeeItineraryApi);

const ForumAttendee = {
  get: api.forumForumIdAttendeesGet,
  getById: api.forumForumIdAttendeesAttendeeIdGet,
  patch: api.forumForumIdAttendeesAttendeeIdPatch,
  bfrDate: {
    put: api.forumForumIdAttendeesAttendeeIdBfrDatePut,
  },
  contactDetails: {
    get: api.forumForumIdAttendeesAttendeeIdContactDetailsGet,
    put: api.forumForumIdAttendeesAttendeeIdContactDetailsPut,
  },
  description: {
    get: api.forumForumIdAttendeesAttendeeIdDescriptionGet,
    getAll: api.forumForumIdAttendeesDescriptionsGet,
    put: api.forumForumIdAttendeesAttendeeIdDescriptionPut,
  },
  reContacts: {
    get: api.forumForumIdAttendeesAttendeeIdRecontactsGet,
  },
  addressInfo: {
    get: api.forumForumIdAttendeesAttendeeIdAddressInfoGet,
    put: api.forumForumIdAttendeesAttendeeIdAddressInfoPut,
  },
  replacement: {
    get: api.forumForumIdAttendeesAttendeeIdPotentialReplacementsGet,
    post: api.forumForumIdAttendeesAttendeeIdReplacementPost,
  },
  makeAttending: {
    put: api.forumForumIdAttendeesAttendeeIdMakeAttendingPut,
  },
  companyAddress: {
    get: api.forumForumIdAttendeesAttendeeIdCompanyAddressGet,
  },
  groups: {
    get: api.forumForumIdAttendeesAttendeeIdGroupsGet,
  },
  socialLinks: {
    get: api.forumForumIdAttendeesAttendeeIdSocialLinksGet,
    put: api.forumForumIdAttendeesAttendeeIdSocialLinksPut,
  },
  history: {
    get: api.forumForumIdAttendeesAttendeeIdHistoryGet,
  },
  partner: {
    get: api.forumForumIdAttendeesAttendeeIdPartnerGet,
    post: api.forumForumIdAttendeesAttendeeIdPartnerPost,
  },
  seminarPreference: {
    getById: preferencesApi.forumForumIdAttendeesPreferencesSeminarIdGet,
    get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSeminarsGet,
    post: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSeminarsPost,
    put: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdPut,
    delete: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSeminarsIdDelete,
  },
  meetingPreference: {
    get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesMeetingsGet,
    post: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesMeetingsPost,
    put: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdPut,
    delete: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesMeetingsIdDelete,
  },
  categoryPreference: {
    get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesCategoryGet,
    put: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesCategoryPut,
    available: {
      get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesAvailableCategoriesGet,
    },
  },
  blockedMeetingPreference: {
    get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsGet,
    put: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdPut,
    delete: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesBlockedMeetingsIdDelete,
  },
  fromPersons: {
    putMany: api.forumForumIdAttendeesFrompersonPut,
  },
  getExport: api.forumForumIdAttendeesExportGet,
  speakerTracking: {
    get: api.forumForumIdAttendeesAttendeeIdSpeakerTrackingGet,
    put: api.forumForumIdAttendeesAttendeeIdSpeakerTrackingPut,
  },
  hotelAndRoomAllocation: {
    get: api.forumForumIdAttendeesAttendeeIdHotelRoomAllocationGet,
    put: api.forumForumIdAttendeesAttendeeIdHotelRoomAllocationPut,
  },
  company: {
    put: api.forumForumIdAttendeesAttendeeIdCompanyPut,
  },
  selectionOptionsPreferences: {
    get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet,
  },
  selectionChoice: {
    get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesGet,
    post: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicePost,
    put: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdPut,
    delete: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesSelectionChoicesSelectionChoiceIdDelete,
    definition: {
      get: preferencesApi.forumForumIdAttendeesAttendeeIdPreferencesAvailableSelectionChoicesDefinitionsGet,
    },
  },
  itinerary: {
    get: itineraryApi.forumForumIdAttendeesAttendeeIdItineraryGet,
    delete: itineraryApi.forumForumIdAttendeesAttendeeIdItineraryItineraryIdDelete,
    jointCandidates: {
      get: itineraryApi.forumForumIdAttendeesAttendeeIdItineraryItineraryIdJointCandidatesGet,
    },
    move: {
      patch: itineraryApi.forumForumIdAttendeesAttendeeIdItineraryItineraryIdPatch,
      patchTest: itineraryApi.forumForumIdAttendeesAttendeeIdItineraryItineraryIdTestPatch,
    },
    export: {
      get: itineraryApi.forumForumIdAttendeesAttendeeIdItineraryExportGet,
    },
  },
  dietaryRequirements: {
    get: api.forumForumIdAttendeesAttendeeIdDietaryRequirementsGet,
    put: api.forumForumIdAttendeesAttendeeIdDietaryRequirementsPut,
  },
  byParticipationType: {
    get: api.forumForumIdAttendeesByParticipationTypeGet,
  },
  attendanceDetails: {
    get: api.forumForumIdAttendeesAttendeeIdAttendanceDetailsGet,
  },
  reasonsForMeeting: {
    get: api.forumForumIdAttendeesAttendeeIdReasonsForMeetingGet,
  },
  summary: {
    get: api.forumForumIdAttendeesSummaryGet,
  },
};

export default ForumAttendee;
