import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useUpdateForumAttendeeItineraryCheckInMutation } from "api/mutations/forumAttendeeItinerary";
import { useForumFacilityQuery } from "api/queries/forumFacilities";
import { useForumItineraryConferenceFacilityTimeslotSessionAttendeesQuery } from "api/queries/forumItinerary";
import { useTimeslotSessionQuery } from "api/queries/timeslotSession";
import { ItemCardDesktop } from "components/ItemCard/Desktop";
import ItemCardMobile from "components/ItemCard/Mobile";
import { Col, Row, Typography } from "components/styleguide";
import { Button, FloatingPanel, List, Space, SpinLoading } from "components/styleguide/mobile";
import { AttendeeItineraryModel } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment";
import { AttendeeSortOptions, getSortDirAndVal, isSortButtonActive } from "pages/Playbook/helpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { useCheckMobileScreen } from "utils/screenUtils";

import { AttendeeSortOptionsType, PlaybookOutletContext } from "../../types";

import styles from "./styles.module.less";

const DATETIME_FORMAT = "L LT";
const anchors = [72, 72 + 119, window.innerHeight * 0.8];

export default function PlaybookConferenceCheckIn() {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();
  const { id, buildingId, facilityId, timeslotId, sessionId } = useParams();
  const { setTitle, setTitle2 } = useOutletContext<PlaybookOutletContext>();

  const [attendees, setAttendees] = useState<AttendeeItineraryModel[]>();
  const [sortVal, setSortVal] = useState<string | undefined>(undefined);
  const [sortDir, setSortDir] = useState<string | undefined>(undefined);

  const mutation = useUpdateForumAttendeeItineraryCheckInMutation();

  const query = useForumItineraryConferenceFacilityTimeslotSessionAttendeesQuery(
    {
      forumId: toNumber(id),
      forumMeetingFacilityId: toNumber(facilityId),
      timeSlotSessionId: toNumber(sessionId),
      orderBy: sortVal,
      orderDir: sortDir,
    },
    {
      onSuccess: ({ data }: { data: AttendeeItineraryModel[] }) => {
        setAttendees(data);
      },
    },
  );

  const { data: { data: facility } = {} } = useForumFacilityQuery(
    {
      forumId: toNumber(id),
      forumBuildingId: toNumber(buildingId),
      facilityId: toNumber(facilityId),
    },
    { cacheTime: Infinity, staleTime: Infinity },
  );

  const { data: { data: session = {} } = {} } = useTimeslotSessionQuery(
    {
      forumId: toNumber(id),
      timeslotId: toNumber(timeslotId),
      sessionId: toNumber(sessionId),
    },
    { cacheTime: Infinity, staleTime: Infinity },
  );

  const mobileItems = attendees?.map((a) => (
    <ItemCardMobile
      key={a.id}
      title={`${a.firstName} ${a.surname} | ${a.companyName}`}
      subTitle1={a.forumTableNumber?.toString()}
      checkedIn={a.hasCheckedIn}
      leftActions={[
        {
          key: "present",
          text: t("Present"),
          color: "success",
        },
      ]}
      rightActions={[
        {
          key: "notPresent",
          text: t("Not Present"),
          color: "danger",
        },
      ]}
      onActionsReveal={(side: "left" | "right") => {
        mutation.mutate(
          {
            forumId: toNumber(id),
            attendeeId: a.forumAttendeeId as number,
            itineraryId: toNumber(a.id),
            attendeeItineraryCheckInRequest: { hasCheckedIn: side === "left" },
          },
          {
            onSuccess: () => {
              setAttendees(
                attendees?.map((item: AttendeeItineraryModel) =>
                  item.id === a.id ? { ...item, hasCheckedIn: side === "left" } : item,
                ),
              );
            },
          },
        );
      }}
    />
  ));

  const desktopItems = attendees?.map((a) => {
    return (
      <ItemCardDesktop
        key={a.id}
        title={`${a.firstName} ${a.surname} | ${a.companyName}`}
        subTitle1={a.forumTableNumber?.toString()}
        checkedIn={a.hasCheckedIn}
        onCheckboxChange={(e: CheckboxChangeEvent) => {
          mutation.mutate(
            {
              forumId: toNumber(id),
              attendeeId: a.forumAttendeeId as number,
              itineraryId: toNumber(a.id),
              attendeeItineraryCheckInRequest: { hasCheckedIn: e.target.checked },
            },
            {
              onSuccess: () => {
                setAttendees(
                  attendees.map((item) => (item.id === a.id ? { ...item, hasCheckedIn: e.target.checked } : item)),
                );
              },
            },
          );
        }}
      />
    );
  });

  const handleSortButtonClick = (key: string) => {
    const result = getSortDirAndVal(key, sortDir, sortVal);
    setSortDir(result?.sortDir);
    setSortVal(result?.sortVal);
  };

  useEffect(() => {
    if (facility?.name && session?.startTime) {
      setTitle(facility.name);
      setTitle2(moment(session.startTime).format(DATETIME_FORMAT));
    } else {
      setTitle(t("Loading..."));
      setTitle2(null);
    }
  }, [facility?.name, session?.startTime]);

  return (
    <>
      <List mode="default" className={styles.list}>
        <Space direction="vertical" block={true}>
          {!attendees || query.isLoading ? (
            <div className="loader">
              <SpinLoading style={{ "--size": "48px" }} />
            </div>
          ) : isMobile ? (
            mobileItems
          ) : (
            desktopItems
          )}
        </Space>
      </List>
      <FloatingPanel anchors={anchors}>
        <div style={{ paddingLeft: "20px" }}>
          <Typography.Title level={4}>{t("Sort List")}</Typography.Title>
          <Row gutter={16}>
            {Object.keys(AttendeeSortOptions).map((o) => (
              <Col key={o} style={{ margin: "0 0 20px 0" }}>
                <Button
                  onClick={() => handleSortButtonClick(o)}
                  fill={isSortButtonActive(o, sortVal, sortDir) ? "outline" : "solid"}
                  color={isSortButtonActive(o, sortVal, sortDir) ? "primary" : "default"}
                  key={o}
                >
                  {t(AttendeeSortOptions[o as keyof AttendeeSortOptionsType])}
                </Button>
              </Col>
            ))}
          </Row>
        </div>
      </FloatingPanel>
    </>
  );
}
