import { Button, Checkbox, Divider, Drawer, Form, Input, InputNumber, Select, Tabs } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import QuestionCategory from "utils/questionCategoryUtils";
import { prepareQuestionForUpdate } from "utils/questionnaireContentUtils";
import QuestionOptionType from "utils/questionOptionTypeUtils";
import QuestionType from "utils/questionTypeUtils";

import OptionsColumnFormItem from "./components/OptionsColumnFormItem";
import OptionsFormItem from "./components/OptionsFormItem";

import styles from "./styles.module.less";

const QuestionEditDrawer = ({ question, questionIndex, visible, onVisibleChange, onChange }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState(() => new QuestionType(question.type));

  const { t } = useTranslation();

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible]);

  const initialValues = {
    ...question,
    numberOfColumns: question.numberOfColumns ?? 1,
    layoutHorizontal: question.layoutHorizontal ?? false,
  };

  const close = () => onVisibleChange(false);

  const handleTypeChange = (newType) => {
    const type = new QuestionType(newType);
    setType(type);

    if (type.isFreeText) {
      form.setFieldValue("options", [
        {
          text: t("Please, enter the answer"),
          type: "LargePlainTextbox",
        },
      ]);
      form.setFieldValue("optionsColumns", []);
      return;
    }

    form.setFieldValue("options", []);
    form.setFieldValue("optionsColumns", []);
  };

  const handleFormSubmit = (values) => {
    onChange(prepareQuestionForUpdate({ ...question, ...values }));
  };

  return (
    <Drawer
      open={visible}
      placement="right"
      title={t("Question {{n}}", { n: questionIndex + 1 })}
      size="large"
      destroyOnClose
      onClose={close}
    >
      <Form
        form={form}
        className={styles.container}
        initialValues={initialValues}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleFormSubmit}
      >
        <Tabs defaultActiveKey="details">
          <Tabs.TabPane key="details" tab={t("Details")} forceRender>
            <Form.Item label={t("Type of the Question")} name="type" rules={[{ required: true }]}>
              <Select onChange={handleTypeChange}>
                {QuestionType.LIST.map(({ label, value }) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {type.isFreeText && (
              <Form.Item label={t("Question Category")} name="category" rules={[{ required: true }]}>
                <Select>
                  {Object.entries(QuestionCategory.MAP).map(([key, label]) => (
                    <Select.Option key={key} value={key}>
                      {t(label)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item label={t("Title of the question")} name="text" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label={t("Description of the question")} name="description">
              <Input.TextArea />
            </Form.Item>
            {(type.isFreeText || type.isStaticText) && (
              <Form.Item label={t("Character limit")} name="textCharacterLimit">
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            )}
            <Form.Item label={t("Number of columns")} name="numberOfColumns">
              <Select>
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
                <Select.Option value={4}>4</Select.Option>
                <Select.Option value={5}>5</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={t("Secondary text")} name="secondaryText">
              <Input.TextArea />
            </Form.Item>
            <Form.Item label={t("Show secondary text")} name="showSecondaryText" valuePropName="checked">
              <Checkbox />
            </Form.Item>
            <Form.Item label={t("Layout mode")} name="layoutHorizontal">
              <Select>
                <Select.Option value={false}>Vertical</Select.Option>
                <Select.Option value={true}>Horizontal</Select.Option>
              </Select>
            </Form.Item>
          </Tabs.TabPane>
          {type.hasOptions && (
            <Tabs.TabPane key="options" tab={t("Options")} forceRender>
              {type.hasOptionsColumns && <OptionsColumnFormItem name="optionsColumns" />}
              {type.hasOptions && (
                <OptionsFormItem
                  name="options"
                  getOptionsPlaceholder={(index) => {
                    if (type.isFreeText) {
                      return t("Label Name #{{n}}", { n: index + 1 });
                    }

                    return t("Enter item #{{n}}", { n: index + 1 });
                  }}
                />
              )}
            </Tabs.TabPane>
          )}
        </Tabs>
        <div style={{ marginTop: "auto" }} />
        <Divider />
        <div className={styles.footer}>
          <div className={styles.spacer} />
          <Button type="primary" htmlType="submit" style={{ marginLeft: "auto" }}>
            {t("Save")}
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

QuestionEditDrawer.propTypes = {
  questionIndex: PropTypes.number.isRequired,
  question: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(QuestionType.VALUES).isRequired,
    category: PropTypes.oneOf(QuestionCategory.VALUES).isRequired,
    description: PropTypes.string,
    numberOfColumns: PropTypes.number,
    secondaryText: PropTypes.string,
    showSecondaryText: PropTypes.bool,
    layoutHorizontal: PropTypes.bool,
    optionsColumns: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
        type: PropTypes.oneOf(QuestionOptionType.VALUES),
      }),
    ),
  }).isRequired,
  visible: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  onChange: PropTypes.func,
};

QuestionEditDrawer.defaultProps = {
  onVisibleChange: () => undefined,
  onChange: () => undefined,
};

export default QuestionEditDrawer;
