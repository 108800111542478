import { ForumITContractApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumITContractApi);

const ItContracts = {
  get: api.forumsForumIdItContractItemsGet,
  put: api.forumsForumIdItContractItemsItemIdPut,
  approval: {
    get: api.forumsForumIdItContractGet,
    put: api.forumsForumIdItContractPut,
  },
  export: {
    get: api.forumsForumIdItContractExportGet,
  },
};

export default ItContracts;
