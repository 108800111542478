import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useMoveAttendeeItineraryMutation } from "api/mutations/attendees";
import { useAttendeeItineraryJointCandidatesQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Select, Spin } from "components/styleguide";
import { JointCandidate } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface MeetingsMoveJointProps {
  itineraryId: number;
  tableSupportsJoint: boolean;
  onClose: () => void;
}

const MeetingsMoveJoint: FC<MeetingsMoveJointProps> = ({ itineraryId, tableSupportsJoint, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const forumId = toNumber(useParams().id);
  const attendeeId = toNumber(useParams().attendeeId);

  const { mutate: moveMutate, isLoading: isMoveLoading } = useMoveAttendeeItineraryMutation();

  const { data: { data: jointCandidates = [] as Array<JointCandidate> } = {}, isLoading: isJointCandidatesLoading } =
    useAttendeeItineraryJointCandidatesQuery(
      { forumId, attendeeId, itineraryId },
      {
        enabled: tableSupportsJoint,
        onError: () => toast.error(t("Something went wrong")),
      },
    );

  const jointCandidatesOptions = useMemo(() => {
    return jointCandidates.map(({ forumAttendeeId, firstName, lastName, jobTitle, company }) => ({
      value: forumAttendeeId,
      label: `${firstName} ${lastName}, ${company}, ${jobTitle}`,
    }));
  }, [jointCandidates]);

  const move = (selectedAttendeeId: number) => {
    moveMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: {
          attendeeToMakeJointWithId: selectedAttendeeId,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          onClose();
          toast.success(t("The meeting was converted to joint"));
        },
        onError: ({ response }) => {
          if (response.data.Type === "InsufficientCapacity") {
            toast.error("The table doesn't have enough seats");
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const onSubmit = ({ id }: { id: number }) => move(id);

  if (!tableSupportsJoint) {
    return <>{t("The table doesn't have enough seats")}</>;
  }

  if (isJointCandidatesLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (!jointCandidates.length) {
    return <>{t("There are no appropriate attendees to convert meeting to joint")}</>;
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        label={t("Choose an Attendee")}
        name="id"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: t("Attendee") }),
          },
        ]}
      >
        <Select
          style={{ width: "350px" }}
          options={jointCandidatesOptions}
          showSearch
          allowClear
          filterOption={(input, option) => (option?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={isMoveLoading}
        />
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={isMoveLoading} disabled={isMoveLoading}>
        {t("Move")}
      </Button>
    </Form>
  );
};

export default MeetingsMoveJoint;
