import { Space, Typography } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import QuestionOptionType from "utils/questionOptionTypeUtils";
import QuestionType from "utils/questionTypeUtils";

import MatrixOptionsRenderer from "./components/MatrixOptionsRenderer";
import OptionsRenderer from "./components/OptionsRenderer";

const QuestionPreviewRenderer = ({ question }) => {
  const isDescriptionShown = question.description;
  const isSecondaryTextShown = question.secondaryText && question.showSecondaryText;

  const type = useMemo(() => {
    return new QuestionType(question.type);
  }, [question.type]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Typography.Text>
        <strong>{question.text}</strong>
      </Typography.Text>
      {isDescriptionShown && <Typography.Text>{question.description}</Typography.Text>}
      {isSecondaryTextShown && <Typography.Text>{question.secondaryText}</Typography.Text>}

      {type.isFreeText && (
        <OptionsRenderer type="free-text" options={question.options} columns={question.numberOfColumns} />
      )}

      {type.isCheckbox && (
        <OptionsRenderer type="checkbox" options={question.options} columns={question.numberOfColumns} />
      )}
      {type.isRadioButton && (
        <OptionsRenderer type="radio" options={question.options} columns={question.numberOfColumns} />
      )}
      {type.isDropdownList && <OptionsRenderer type="dropdown" options={question.options} />}

      {type.isMatrixSingleChoice && (
        <MatrixOptionsRenderer type="radio" options={question.options} columns={question.optionsColumns} />
      )}
      {type.isMatrixMultiChoice && (
        <MatrixOptionsRenderer type="checkbox" options={question.options} columns={question.optionsColumns} />
      )}
    </Space>
  );
};

QuestionPreviewRenderer.propTypes = {
  question: PropTypes.shape({
    text: PropTypes.string.isRequired,
    type: PropTypes.oneOf(QuestionType.VALUES).isRequired,
    description: PropTypes.string,
    numberOfColumns: PropTypes.number,
    secondaryText: PropTypes.string,
    showSecondaryText: PropTypes.bool,
    layoutHorizontal: PropTypes.bool,
    optionsColumns: PropTypes.arrayOf(PropTypes.string),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        text: PropTypes.string,
        type: PropTypes.oneOf(QuestionOptionType.VALUES),
      }),
    ),
  }).isRequired,
};

QuestionPreviewRenderer.defaultProps = {};

export default QuestionPreviewRenderer;
