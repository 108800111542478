import { t } from "i18next";

import { MenuItem } from "./types";

export class Item {
  key: string;

  constructor(
    public value: string,
    public label: string,
    public children?: MenuItem[] | null,
    public hasOptions = false,
    public type?: "group",
  ) {
    this.key = value;
    this.label = t(label);
  }
}

export const ReportsValues = {
  IndividualMeetingPreferencesBy: "Operations-MeetingPreferences-PreCrunch-Individual_MeetingPreferencesBy",
  IndividualMeetingPreferencesFor: "Operations-MeetingPreferences-PreCrunch-Individual_MeetingPreferencesFor",
  PeopleTypeMeetingPreferencesBy: "Operations-MeetingPreferences-PreCrunch-PeopleType_MeetingPreferencesBy",
  PeopleTypeMeetingPreferencesFor: "Operations-MeetingPreferences-PreCrunch-PeopleType_MeetingPreferencesFor",
  MissingBFRDate: "CrunchChecks-MandatoryChecks-MissingBFRDate",
  MissingContractDate: "CrunchChecks-MandatoryChecks-MissingContractDate",
  BusinessHours: "CrunchChecks-MandatoryChecks-BusinessHours",
  MandatoryChecksPackageDetails: "CrunchChecks-MandatoryChecks-PackageDetails",
  MandatoryChecksRatiosAndTables: "CrunchChecks-MandatoryChecks-RatiosAndTables",
  GeneralBooking: "CrunchChecks-MandatoryChecks-GeneralBookings",
  PeopleTypeTotals: "CrunchChecks-MandatoryChecks-PeopleTypeTotals",
  JointNotReciprocated: "CrunchChecks-MandatoryChecks-JointNotReciprocated",
  SessionDetailsAllSessionPlaces: "Conference-Session-AllPlaces",
  SessionDetailsAllSessionTimes: "Conference-Session-AllTimes",
  DuplicateSupplierCompanyPreferencesForDelegates:
    "CrunchChecks-HelpfulChecks-DublicateSponsorsCoPreferencesForDelegates",
  DuplicateDelegatesPreferencesForSupplierCompanies:
    "CrunchChecks-HelpfulChecks-DublicateDelegatePreferencesForSupplierCos",
  NoPrioritySeminarPreferences: "CrunchChecks-MandatoryChecks-NoPrioritySemPref",
  JointWithThemselves: "CrunchChecks-MandatoryChecks-JointWithThemselves",
  SeminarCodesAndTitles: "Conference-SeminarCodesAndTitles",
  MissingPeopleType: "CrunchChecks-MandatoryChecks-MissingPeopleType",
  PreferencesForSeminar: "Conference-PreferencesForASeminar",
  NoPriorityMeetingPreferences: "CrunchChecks-MandatoryChecks-NoPriorityMeetPref",
  ByDelegatesForDelegates: "Operations-MeetingPreferences-PreCrunch-ByDelesForDeles",
  AllBelowRatioMissingMealtimes: "CrunchReports-Mealtimes-AllBelowRatioMissing",
  ByExecutivesForExecutives: "Operations-MeetingPreferences-PreCrunch-ByExecsForExecs",
  LoungesAndRestaurantsCompaniesOnly: "Operations-Package-CompaniesLoungesAndRestaurants",
  LoungesAndRestaurantsWithExecutiveNames: "Operations-Package-ExecsLoungesAndRestaurants",
  SummaryOfPreferences: "Conference-SummaryOfPreferences",
  SummaryOfPreferencesD2DE2E: "Operations-MeetingPreferences-PreCrunch-SummaryOfPrefD2DE2E",
  SummaryOfPreferencesE2DD2E: "Operations-MeetingPreferences-PreCrunch-SummaryOfPrefE2DD2E",
  PreferencesByAnIndividualAllPreferences: "Conference-PreferenceByAnIndividual-PreCrunch-PrecrunchAllPreferences",
  NoCategoryPreferences: "CrunchChecks-HelpfulChecks-NoCategoryPreferences",
  TablesUsedForSpeedMeetings: "Operations-Facilities-SpeedMeetingsTablesInUse",
  ListOfCancelledEventAttendees: "Operations-Cancellations-(Date)ListOfCancelledEventAttendees",
  OperationsPackageDetails: "Operations-Package-Packages-PackageDetails",
  NoMeetingPreferences: "CrunchChecks-HelpfulChecks-NoMeetingPreferences",
  SatisfiedAndUnsatisfiedTotalsE2DAndD2E: "Operations-MeetingPreferences-PostCrunch-PostCrunch_TotalsE2D&D2E",
  SatisfiedAndUnsatisfiedTotalsD2DAndE2E: "Operations-MeetingPreferences-PostCrunch-PostCrunch_TotalsD2D&E2E",
  PossibleMissedMutuals: "CrunchChecks-HelpfulChecks-PossMissedMutuals",
  PeopleTypeMeetingPreferencesByPostCrunch:
    "Operations-MeetingPreferences-PostCrunch-(PeopleType)PostCrunch_MeetingPreferencesBY",
  PeopleTypeMeetingPreferencesForPostCrunch:
    "Operations-MeetingPreferences-PostCrunch-(PeopleType)PostCrunch_MeetingPreferencesFOR",
  NoConferencePreferences: "CrunchChecks-HelpfulChecks-NoConferencePreferences",
  AttendeeItineraryByIndividual: "Operations-Itineraries-(Individual)Itinerary",
  NoMeetingsOrConferencePreferences: "CrunchChecks-HelpfulChecks-NoMeetingOrConferencePreferences",
  CheckRestaurantTables: "CrunchChecks-SharedCharterChecks-CheckRestaurantTables",
  MeetingsByFacilityTimeslotBookedMeetingsOnly:
    "CrunchReports-MeetingContactInformation-MeetingsByFacilityByTimeslotBookedMeetingsOnly",
  DietaryRequirements: "Operations-Restaurants-DietaryRequirements",
  SpeedMeetings: "Operations-Itineraries-Speedmeets",
  RatioMealtimeComparison: "CrunchChecks-HelpfulChecks-RatioMealtimeComparison",
  NegativePreferencesByExecutivesPositivePreferencesForExecutives:
    "CrunchChecks-HelpfulChecks-NegativePreferencesByExecutivesPositivePreferencesForExecutives",
  AttendeeItineraryByPeopleType: "Operations-Itineraries-(PeopleType)Itineraries",
  NegativePreferencesByDelegatesPositivePreferencesForDelegates:
    "CrunchChecks-HelpfulChecks-NegativePreferencesByDelegatesPositivePreferencesForDelegates",
  DelegateRatioComparison: "CrunchChecks-HelpfulChecks-DelegateRatioComparison",
  ConferenceSpeakerSessionBookings: "Conference-SpeakerBooking-SpeakerSessionBookings",
  SummaryOfAllReturnedPreferences: "CrunchChecks-HelpfulChecks-SummaryOfAllReturnedPrefs",
  ConferenceAllConferenceAndGeneralBookings: "Conference-SpeakerBooking-AllConferenceAndGeneralBookings",
  IndividualMeetingPreferencesByPostCrunch:
    "Operations-MeetingPreferences-PostCrunch-(Individual)PostCrunch_MeetingPreferencesBY",
  PreferencesByAnIndividualSatisfiedPreferences: "Conference-PreferenceByAnIndividual-PostCrunch-SatisfiedPreferences",
  ExtraPreferences: "Operations-MeetingPreferences-PreCrunch-Other-Preferences-ExtraPreferences",
  SummaryOfExtraPreferences: "Operations-MeetingPreferences-PreCrunch-Other-Preferences-ExtraPreferenceSummary",
  PreferencesByAnIndividualUnsatisfiedPreferences:
    "Conference-PreferenceByAnIndividual-PostCrunch-UnsatisfiedPreferences",
  PreferencesByAnIndividualPostCrunchAllPreferences: "Conference-PreferenceByAnIndividual-PostCrunch-AllPreferences",
  IndividualMeetingPreferencesForPostCrunch:
    "Operations-MeetingPreferences-PostCrunch-(Individual)PostCrunch_MeetingPreferencesFOR",
  VirtualMeetingsByFacilityTimeslotBookedMeetingsOnly: "CrunchReports-VirtualMeetings",
  IndividualMeetingScores: "Operations-Itineraries-IndividualMeetingScores",
  DuplicateDelegateCompanyPreferencesForSupplierCompanies:
    "CrunchChecks-HelpfulChecks-DublicateDelegateCoPreferencesForSponsorCos",
  AllTableAssignments: "Operations-Restaurants-AllTablesAllMeals",
  ExecutiveConferencePreferences: "CrunchChecks-HelpfulChecks-ExecutiveConferencePreferences",
  FillInTables: "Operations-Restaurants-FillInTables",
  ConferenceAttendeesSelectedSeminarCode: "Conference-Attendees-SelectedSeminarCode",
  MeetingsByFacilityTimeslot: "CrunchReports-MeetingContactInformation-MeetingsByFacilityByTimeslot",
  ConferenceAttendeesSelectedSeminarType: "Conference-Attendees-SelectedSeminarType",
  AttendeeAvailability: "CrunchReports-AttendeeAvailability",
  ConferenceAttendeesSelectedSession: "Conference-Attendees-SelectedSession",
  AllReplacements: "Operations-Replacements-AllReplacements",
  NumberOfSeatsBooked: "Operations-Restaurants-NumberOfSeatsBooked",
  FellowDinersForExecutives: "Operations-Restaurants-FellowDiners(ForExecutives)",
  FellowDinersForExecutivesByIndividual: "Operations-Restaurants-FellowDiners(ForExecutives)Individual",
  ExecutivesMeetingTotalsBusinessHours: "CrunchChecks-ExecutivesMeetingsTotals-BusinessHours",
  CompaniesWithMoreThanOneDelegate: "CrunchChecks-ITChecks-CosMoreThanOneDel",
  ConferenceAttendeesAllAssessmentFormsForSeminarType:
    "Conference-Attendees-AssessmentFormsForSeminarTypeForEachAttendee",
  AllAboveRatio: "CrunchReports-Mealtimes-AllAboveRatio",
  CancelledMeetingPreferences: "Operations-Cancellations-CancelledMeetingPreferences",
  ConferenceAttendeesAssessmentFormsForSeminarType: "Conference-Attendees-AssessmentFormsForSeminarType",
  AllCancelledScheduledMeetingsOnly: "Operations-Cancellations-CancelledSchedulesMeetingOnly",
  SessionDetailsFreeSessionPlaces: "Conference-Session-AllFreePlaces",
  SessionDetailsOverallocatedSessionPlaces: "Conference-Session-AllOverallocatedPlaces",
  SummaryOfCheckins: "PostEvent-SummaryOfCheckins",
  ExecutivesMeetingTotalsAllExecutiveMeetings: "CrunchReports-ExecutiveMeetingTotals-BusinessHoursAndMealtimes",
  NonAttendeesInSchedule: "CrunchChecks-ITChecks-NonAttendeesInSchedule",
  SummaryOfAllPreferences: "Operations-MeetingPreferences-PreCrunch-AllPreferences",
  SchedulesWithNegativeMeetingPreferences: "CrunchChecks-ITChecks-SchedulesWnegative_Web_MeetPrefs",
  GuestsObservers: "Operations-Attendees-GuestsObservers",
  AllEventAttendees: "Operations-Attendees-AllEventAttendees",
  ExecutivesRequestsScoresVsTimeType: "CrunchChecks-ITChecks-ExecutivesRequests(scores)vsTimeType",
  Press: "Operations-Attendees-Press",
  IndividualCancelledScheduledMeetingsOnly:
    "Operations-Cancellations-Individual-(Individual)CancelledScheduleMeetingOnly",
  DelegatesWithoutPreferencesForSomeSeminarTypes: "CrunchChecks-HelpfulChecks-DelegatesPreferencesForSomeSeminarTypes",
  IndividualCancelledMeetingPreferences:
    "Operations-Cancellations-Individual-(Individual)PostCrunchCancelledMeetPreferencesBY",
  OccupiedBusinessHoursExecutives: "CrunchReports-OccupiedBusinessHours-(OccupiedBusinesshours)Executives",
  OccupiedBusinessHoursDelegates: "CrunchReports-OccupiedBusinessHours-(OccupiedBusinesshours)Delegates",
  ListOfAllCancellations: "Operations-Cancellations-ListOfAllCancellations",
  ReParticipants: "Operations-Attendees-REParticipants",
  ConferenceAttendeesAssessmentFormsForSession: "Conference-Attendees-AssessmentFormsForSession",
  ConferenceAttendeesAssessmentFormsForFacility: "Conference-Attendees-AssessmentFormsForFacility",
  ExecutivesMealtimeMeetings: "CrunchReports-Mealtimes-(MealTimes)Executives",
  EventAttendeesDelegates: "Operations-Attendees-EventAttendeesDelegates",
  AssignedBedrooms: "Operations-Bedrooms-AssignedBedrooms",
  EventAttendeesSpeakers: "Operations-Attendees-EventAttendeesSpeakers",
  AllBedrooms: "Operations-Bedrooms-AllBedrooms",
  EventAttendeesExecutives: "Operations-Attendees-EventAttendeesExecutives",
  AssignedToSpecifiedPeopleTypes: "Operations-Bedrooms-Assigned-PeopleTypes",
  EmptyBedrooms: "Operations-Bedrooms-EmptyBedrooms",
  PeopleMissingBedrooms: "Operations-Bedrooms-Unassigned-PeopleMissingBedrooms",
  BedroomsGradeListing: "Operations-Bedrooms-BedroomsGradeListing",
  FollowUpAddressesIndividual: "Operations-FollowUpAddresses-FollowUpAddresses",
  FollowUpAddressesPeopleType: "Operations-FollowUpAddresses-PeopleTypeFollowUpAddresses",
  ConferenceAttendeesFellowAttendees: "Conference-Attendees-FellowAttendees",
  DuplicateBedrooms: "Operations-Bedrooms-DuplicateBedrooms",
  ContractorsOthers: "Operations-Attendees-ContractorsOthers",
  PeopleWithoutBedrooms: "Operations-Bedrooms-PeopleWithoutBedrooms",
  PlaceCards: "Operations-PlaceCards-PlaceCards",
  SharedBedrooms: "Operations-Bedrooms-SharedBedrooms",
};

export const ReportsLabels = {
  [ReportsValues.IndividualMeetingPreferencesBy]: "Individual Meeting Preferences",
  [ReportsValues.IndividualMeetingPreferencesFor]: "Individual Meeting Preferences",
  [ReportsValues.PeopleTypeMeetingPreferencesBy]: "People Type Meeting Preferences",
  [ReportsValues.PeopleTypeMeetingPreferencesFor]: "People Type Meeting Preferences",
  [ReportsValues.MissingBFRDate]: "Missing BFR Date",
  [ReportsValues.MissingContractDate]: "Missing Contract Date",
  [ReportsValues.BusinessHours]: "Business Hours",
  [ReportsValues.MandatoryChecksPackageDetails]: "Package Details",
  [ReportsValues.MandatoryChecksRatiosAndTables]: "Ratios and Tables",
  [ReportsValues.GeneralBooking]: "General Bookings",
  [ReportsValues.PeopleTypeTotals]: "People Type Totals",
  [ReportsValues.JointNotReciprocated]: "Joint - Not Reciprocated",
  [ReportsValues.SessionDetailsAllSessionPlaces]: "All Session Places",
  [ReportsValues.SessionDetailsAllSessionTimes]: "All Session Times",
  [ReportsValues.DuplicateSupplierCompanyPreferencesForDelegates]:
    "Duplicate Supplier Company Preferences for Delegates",
  [ReportsValues.DuplicateDelegatesPreferencesForSupplierCompanies]:
    "Duplicate Delegate Preferences for Supplier Companies",
  [ReportsValues.NoPrioritySeminarPreferences]: "No Priority Seminar Preferences",
  [ReportsValues.JointWithThemselves]: "Joint With Themselves",
  [ReportsValues.SeminarCodesAndTitles]: "Seminar Codes and Titles",
  [ReportsValues.MissingPeopleType]: "Missing People Type",
  [ReportsValues.PreferencesForSeminar]: "Preferences for a Seminar",
  [ReportsValues.NoPriorityMeetingPreferences]: "No Priority Meeting Preferences",
  [ReportsValues.ByDelegatesForDelegates]: "By Delegates For Delegates",
  [ReportsValues.AllBelowRatioMissingMealtimes]: "All Below Ratio / Missing Mealtimes",
  [ReportsValues.ByExecutivesForExecutives]: "By Executives For Executives",
  [ReportsValues.LoungesAndRestaurantsCompaniesOnly]: "Companies Only",
  [ReportsValues.LoungesAndRestaurantsWithExecutiveNames]: "With Executive Names",
  [ReportsValues.SummaryOfPreferences]: "Summary of Preferences",
  [ReportsValues.SummaryOfPreferencesD2DE2E]: "Summary of Preferences By / For D2D & E2E",
  [ReportsValues.SummaryOfPreferencesE2DD2E]: "Summary of Preferences By / For E2D & D2E",
  [ReportsValues.PreferencesByAnIndividualAllPreferences]: "All Preferences",
  [ReportsValues.NoCategoryPreferences]: "No Category Preferences",
  [ReportsValues.TablesUsedForSpeedMeetings]: "Tables Used For Speed Meetings",
  [ReportsValues.ListOfCancelledEventAttendees]: "List of Cancelled Event Attendees",
  [ReportsValues.OperationsPackageDetails]: "Package Details",
  [ReportsValues.NoMeetingPreferences]: "No Meeting Preferences",
  [ReportsValues.SatisfiedAndUnsatisfiedTotalsE2DAndD2E]: "Satisfied and Unsatisfied Totals E2D & D2E",
  [ReportsValues.SatisfiedAndUnsatisfiedTotalsD2DAndE2E]: "Satisfied and Unsatisfied Totals D2D & E2E",
  [ReportsValues.PossibleMissedMutuals]: "Possible Missed Mutuals",
  [ReportsValues.NoConferencePreferences]: "No Conference Preferences",
  [ReportsValues.AttendeeItineraryByIndividual]: "Individual",
  [ReportsValues.NoMeetingsOrConferencePreferences]: "No Meetings or Conference Preferences",
  [ReportsValues.CheckRestaurantTables]: "Check Restaurant Tables",
  [ReportsValues.MeetingsByFacilityTimeslotBookedMeetingsOnly]:
    "Meetings by Facility & Timeslot - Booked meetings only",
  [ReportsValues.DietaryRequirements]: "Dietary Requirements",
  [ReportsValues.SpeedMeetings]: "Speed Meetings",
  [ReportsValues.RatioMealtimeComparison]: "Ratio/Mealtime Comparison",
  [ReportsValues.NegativePreferencesByExecutivesPositivePreferencesForExecutives]:
    "Negative Preferences By Executives/Positive Preferences For Executives",
  [ReportsValues.AttendeeItineraryByPeopleType]: "People Type",
  [ReportsValues.NegativePreferencesByDelegatesPositivePreferencesForDelegates]:
    "Negative Preferences By Delegates/Positive Preferences For Delegates",
  [ReportsValues.DelegateRatioComparison]: "Delegate/Ratio Comparison",
  [ReportsValues.ConferenceAllConferenceAndGeneralBookings]: "All Conference and General Bookings",
  [ReportsValues.SummaryOfAllReturnedPreferences]: "Summary Of All Returned Preferences",
  [ReportsValues.ConferenceSpeakerSessionBookings]: "Excluding General Sessions and Free Time",
  [ReportsValues.PreferencesByAnIndividualSatisfiedPreferences]: "Satisfied Preferences",
  [ReportsValues.ExtraPreferences]: "Extra Preferences",
  [ReportsValues.SummaryOfExtraPreferences]: "Summary of Extra Preferences",
  [ReportsValues.PreferencesByAnIndividualUnsatisfiedPreferences]: "Unsatisfied Preferences",
  [ReportsValues.VirtualMeetingsByFacilityTimeslotBookedMeetingsOnly]:
    "Virtual Meetings by Facility & Timeslot - Booked meetings only",
  [ReportsValues.IndividualMeetingScores]: "Individual Meeting Scores",
  [ReportsValues.DuplicateDelegateCompanyPreferencesForSupplierCompanies]:
    "Duplicate Delegate Company Preferences For Supplier Companies",
  [ReportsValues.AllTableAssignments]: "All Tables",
  [ReportsValues.ExecutiveConferencePreferences]: "Executive Conference Preferences",
  [ReportsValues.FillInTables]: "Fill in Tables",
  [ReportsValues.ConferenceAttendeesSelectedSeminarCode]: "Selected Seminar Code",
  [ReportsValues.MeetingsByFacilityTimeslot]: "Meetings by Facility & Timeslot",
  [ReportsValues.ConferenceAttendeesSelectedSeminarType]: "Selected Seminar Type",
  [ReportsValues.AttendeeAvailability]: "Attendee Availability",
  [ReportsValues.ConferenceAttendeesSelectedSession]: "Selected Session",
  [ReportsValues.AllReplacements]: "All Replacements",
  [ReportsValues.NumberOfSeatsBooked]: "Number of Seats Booked",
  [ReportsValues.FellowDinersForExecutives]: "Fellow Diners for Executives",
  [ReportsValues.FellowDinersForExecutivesByIndividual]: "Fellow Diners for Executives by Individual",
  [ReportsValues.ExecutivesMeetingTotalsBusinessHours]: "Business Hours",
  [ReportsValues.CompaniesWithMoreThanOneDelegate]: "Companies With More Than One Delegate",
  [ReportsValues.ConferenceAttendeesAllAssessmentFormsForSeminarType]: "All Assessment Forms for Seminar Type",
  [ReportsValues.CancelledMeetingPreferences]: "Cancelled Meeting Preferences",
  [ReportsValues.AllAboveRatio]: "All Above Ratio",
  [ReportsValues.ConferenceAttendeesAssessmentFormsForSeminarType]: "Assessment Forms for Seminar Type",
  [ReportsValues.AllCancelledScheduledMeetingsOnly]: "Cancelled Scheduled Meetings Only",
  [ReportsValues.SessionDetailsFreeSessionPlaces]: "Free Session Places",
  [ReportsValues.SessionDetailsOverallocatedSessionPlaces]: "Overallocated Session Places",
  [ReportsValues.SummaryOfCheckins]: "Summary of Check-ins",
  [ReportsValues.ExecutivesMeetingTotalsAllExecutiveMeetings]: "All Executive Meetings",
  [ReportsValues.NonAttendeesInSchedule]: "Non Attendees In Schedule",
  [ReportsValues.SummaryOfAllPreferences]: "Summary of All Preferences",
  [ReportsValues.SchedulesWithNegativeMeetingPreferences]: "Schedules With Negative Meeting Preferences",
  [ReportsValues.GuestsObservers]: "Guests / Observers",
  [ReportsValues.AllEventAttendees]: "All Event Attendees",
  [ReportsValues.ExecutivesRequestsScoresVsTimeType]: "Executives Requests (Scores) vs Time Type",
  [ReportsValues.Press]: "Press",
  [ReportsValues.IndividualCancelledScheduledMeetingsOnly]: "Cancelled Scheduled Meetings Only",
  [ReportsValues.DelegatesWithoutPreferencesForSomeSeminarTypes]:
    "Delegates Without Preferences For Some Seminar Types",
  [ReportsValues.IndividualCancelledMeetingPreferences]: "Cancelled Meeting Preferences",
  [ReportsValues.OccupiedBusinessHoursExecutives]: "Executives",
  [ReportsValues.OccupiedBusinessHoursDelegates]: "Delegates",
  [ReportsValues.ListOfAllCancellations]: "List of All Cancellations",
  [ReportsValues.ReParticipants]: "RE Participants",
  [ReportsValues.ConferenceAttendeesAssessmentFormsForSession]: "Assessment Forms for Session",
  [ReportsValues.ConferenceAttendeesAssessmentFormsForFacility]: "Assessment Forms for Facility",
  [ReportsValues.ExecutivesMealtimeMeetings]: "Executives Mealtime Meetings",
  [ReportsValues.EventAttendeesDelegates]: "Delegates",
  [ReportsValues.AssignedBedrooms]: "Assigned Bedrooms",
  [ReportsValues.EventAttendeesSpeakers]: "Speakers",
  [ReportsValues.AllBedrooms]: "All Bedrooms",
  [ReportsValues.EventAttendeesExecutives]: "Executives",
  [ReportsValues.AssignedToSpecifiedPeopleTypes]: "Assigned to Specified People Types",
  [ReportsValues.EmptyBedrooms]: "Empty Bedrooms",
  [ReportsValues.PeopleMissingBedrooms]: "People Missing Bedrooms",
  [ReportsValues.BedroomsGradeListing]: "Bedrooms Grade Listing",
  [ReportsValues.FollowUpAddressesPeopleType]: "People Type",
  [ReportsValues.FollowUpAddressesIndividual]: "Individual",
  [ReportsValues.ConferenceAttendeesFellowAttendees]: "Fellow Attendees",
  [ReportsValues.DuplicateBedrooms]: "Duplicate Bedrooms",
  [ReportsValues.ContractorsOthers]: "Contractors / Others",
  [ReportsValues.PeopleWithoutBedrooms]: "People Without Bedrooms",
  [ReportsValues.PlaceCards]: "Place Cards",
  [ReportsValues.SharedBedrooms]: "Shared Bedrooms",
};

export const getReports = () => [
  new Item("operations", "Operations", [
    new Item("attendees", "Attendees", [
      new Item("eventAttendees", "Event Attendees", [
        new Item(ReportsValues.AllEventAttendees, ReportsLabels[ReportsValues.AllEventAttendees], null, true),
        new Item(
          ReportsValues.EventAttendeesDelegates,
          ReportsLabels[ReportsValues.EventAttendeesDelegates],
          null,
          true,
        ),
        new Item(ReportsValues.EventAttendeesSpeakers, ReportsLabels[ReportsValues.EventAttendeesSpeakers], null, true),
        new Item(
          ReportsValues.EventAttendeesExecutives,
          ReportsLabels[ReportsValues.EventAttendeesExecutives],
          null,
          true,
        ),
        new Item(ReportsValues.GuestsObservers, ReportsLabels[ReportsValues.GuestsObservers], null, true),
        new Item(ReportsValues.Press, ReportsLabels[ReportsValues.Press], null, true),
      ]),
      new Item("other", "Other", [
        new Item(ReportsValues.ReParticipants, ReportsLabels[ReportsValues.ReParticipants], null, true),
        new Item(ReportsValues.ContractorsOthers, ReportsLabels[ReportsValues.ContractorsOthers], null, true),
      ]),
    ]),
    new Item("bedrooms", "Bedrooms", [
      new Item("bedroomsAll", "All", [
        new Item(ReportsValues.AllBedrooms, ReportsLabels[ReportsValues.AllBedrooms], null, true),
        new Item(ReportsValues.BedroomsGradeListing, ReportsLabels[ReportsValues.BedroomsGradeListing], null, true),
      ]),
      new Item("bedroomsUnassigned", "Unassigned", [
        new Item(ReportsValues.EmptyBedrooms, ReportsLabels[ReportsValues.EmptyBedrooms], null, true),
        new Item(ReportsValues.PeopleMissingBedrooms, ReportsLabels[ReportsValues.PeopleMissingBedrooms], null, true),
        new Item(ReportsValues.PeopleWithoutBedrooms, ReportsLabels[ReportsValues.PeopleWithoutBedrooms], null, true),
      ]),
      new Item("bedroomsAssigned", "Assigned", [
        new Item(ReportsValues.AssignedBedrooms, ReportsLabels[ReportsValues.AssignedBedrooms], null, true),
        new Item(
          ReportsValues.AssignedToSpecifiedPeopleTypes,
          ReportsLabels[ReportsValues.AssignedToSpecifiedPeopleTypes],
          null,
          true,
        ),
        new Item(ReportsValues.SharedBedrooms, ReportsLabels[ReportsValues.SharedBedrooms], null, true),
      ]),
      new Item("bedroomsOther", "Other", [
        new Item(ReportsValues.DuplicateBedrooms, ReportsLabels[ReportsValues.DuplicateBedrooms], null, true),
      ]),
    ]),
    new Item("cancellations", "Cancellations", [
      new Item("all", "All", [
        new Item(
          ReportsValues.CancelledMeetingPreferences,
          ReportsLabels[ReportsValues.CancelledMeetingPreferences],
          null,
          true,
        ),
        new Item(
          ReportsValues.AllCancelledScheduledMeetingsOnly,
          ReportsLabels[ReportsValues.AllCancelledScheduledMeetingsOnly],
          null,
          true,
        ),
        new Item(ReportsValues.ListOfAllCancellations, ReportsLabels[ReportsValues.ListOfAllCancellations], null, true),
      ]),
      new Item("onAfterSpecifiedDate", "On / After Specified Date", [
        new Item(
          ReportsValues.ListOfCancelledEventAttendees,
          ReportsLabels[ReportsValues.ListOfCancelledEventAttendees],
          null,
          true,
        ),
      ]),
      new Item("individual", "Individual", [
        new Item(
          ReportsValues.IndividualCancelledScheduledMeetingsOnly,
          ReportsLabels[ReportsValues.IndividualCancelledScheduledMeetingsOnly],
          null,
          true,
        ),
        new Item(
          ReportsValues.IndividualCancelledMeetingPreferences,
          ReportsLabels[ReportsValues.IndividualCancelledMeetingPreferences],
          null,
          true,
        ),
      ]),
    ]),
    new Item("facilities", "Facilities", [
      new Item(ReportsValues.TablesUsedForSpeedMeetings, ReportsLabels[ReportsValues.TablesUsedForSpeedMeetings]),
    ]),
    new Item("followUpAddresses", "Follow Up Addresses", [
      new Item(
        ReportsValues.FollowUpAddressesPeopleType,
        ReportsLabels[ReportsValues.FollowUpAddressesPeopleType],
        null,
        true,
      ),
      new Item(
        ReportsValues.FollowUpAddressesIndividual,
        ReportsLabels[ReportsValues.FollowUpAddressesIndividual],
        null,
        true,
      ),
    ]),
    new Item("itineraries", "Itineraries", [
      new Item("standardItineraries", "Standard Itineraries", [
        new Item(
          ReportsValues.AttendeeItineraryByIndividual,
          ReportsLabels[ReportsValues.AttendeeItineraryByIndividual],
          null,
          true,
        ),
        new Item(
          ReportsValues.AttendeeItineraryByPeopleType,
          ReportsLabels[ReportsValues.AttendeeItineraryByPeopleType],
          null,
          true,
        ),
      ]),
      new Item("itinerariesOther", "Other", [
        new Item(
          ReportsValues.IndividualMeetingScores,
          ReportsLabels[ReportsValues.IndividualMeetingScores],
          null,
          true,
        ),
        new Item(ReportsValues.SpeedMeetings, ReportsLabels[ReportsValues.SpeedMeetings], null, true),
      ]),
    ]),
    new Item("meetingPreferences", "Meeting Preferences", [
      new Item("preCrunch", "Pre Crunch", [
        new Item("preCrunchBy", "By", [
          new Item(
            ReportsValues.IndividualMeetingPreferencesBy,
            ReportsLabels[ReportsValues.IndividualMeetingPreferencesBy],
            null,
            true,
          ),
          new Item(
            ReportsValues.PeopleTypeMeetingPreferencesBy,
            ReportsLabels[ReportsValues.PeopleTypeMeetingPreferencesBy],
            null,
            true,
          ),
        ]),
        new Item("preCrunchFor", "For", [
          new Item(
            ReportsValues.IndividualMeetingPreferencesFor,
            ReportsLabels[ReportsValues.IndividualMeetingPreferencesFor],
            null,
            true,
          ),
          new Item(
            ReportsValues.PeopleTypeMeetingPreferencesFor,
            ReportsLabels[ReportsValues.PeopleTypeMeetingPreferencesFor],
            null,
            true,
          ),
        ]),
        new Item("meetingPreferencesOther", "Other", [
          new Item(
            ReportsValues.ByDelegatesForDelegates,
            ReportsLabels[ReportsValues.ByDelegatesForDelegates],
            null,
            true,
          ),
          new Item(
            ReportsValues.ByExecutivesForExecutives,
            ReportsLabels[ReportsValues.ByExecutivesForExecutives],
            null,
            true,
          ),
          new Item(
            ReportsValues.SummaryOfPreferencesD2DE2E,
            ReportsLabels[ReportsValues.SummaryOfPreferencesD2DE2E],
            null,
            true,
          ),
          new Item(
            ReportsValues.SummaryOfPreferencesE2DD2E,
            ReportsLabels[ReportsValues.SummaryOfPreferencesE2DD2E],
            null,
            true,
          ),
          new Item("preferences", "Preferences", [
            new Item(ReportsValues.ExtraPreferences, ReportsLabels[ReportsValues.ExtraPreferences], null, true),
            new Item(ReportsValues.SummaryOfExtraPreferences, ReportsLabels[ReportsValues.SummaryOfExtraPreferences]),
            new Item(
              ReportsValues.SummaryOfAllPreferences,
              ReportsLabels[ReportsValues.SummaryOfAllPreferences],
              null,
              true,
            ),
          ]),
        ]),
      ]),
      new Item("postCrunch", "Post Crunch", [
        new Item("postCrunchBy", "By", [
          new Item(
            ReportsValues.IndividualMeetingPreferencesByPostCrunch,
            ReportsLabels[ReportsValues.IndividualMeetingPreferencesBy],
            null,
            true,
          ),
          new Item(
            ReportsValues.PeopleTypeMeetingPreferencesByPostCrunch,
            ReportsLabels[ReportsValues.PeopleTypeMeetingPreferencesBy],
            null,
            true,
          ),
        ]),
        new Item("postCrunchFor", "For", [
          new Item(
            ReportsValues.IndividualMeetingPreferencesForPostCrunch,
            ReportsLabels[ReportsValues.IndividualMeetingPreferencesFor],
            null,
            true,
          ),
          new Item(
            ReportsValues.PeopleTypeMeetingPreferencesForPostCrunch,
            ReportsLabels[ReportsValues.PeopleTypeMeetingPreferencesFor],
            null,
            true,
          ),
        ]),
        new Item("satisfiedAndUnsatisfiedTotals", "Satisfied & Unsatisfied Totals", [
          new Item(
            ReportsValues.SatisfiedAndUnsatisfiedTotalsE2DAndD2E,
            ReportsLabels[ReportsValues.SatisfiedAndUnsatisfiedTotalsE2DAndD2E],
            null,
            true,
          ),
          new Item(
            ReportsValues.SatisfiedAndUnsatisfiedTotalsD2DAndE2E,
            ReportsLabels[ReportsValues.SatisfiedAndUnsatisfiedTotalsD2DAndE2E],
            null,
            true,
          ),
        ]),
      ]),
    ]),
    new Item("packagesCategory", "Packages", [
      new Item("packages", "Packages", [
        new Item(
          ReportsValues.OperationsPackageDetails,
          ReportsLabels[ReportsValues.OperationsPackageDetails],
          null,
          true,
        ),
      ]),
      new Item("loungesAndRestaurants", "Lounges and Restaurants", [
        new Item(
          ReportsValues.LoungesAndRestaurantsCompaniesOnly,
          ReportsLabels[ReportsValues.LoungesAndRestaurantsCompaniesOnly],
          null,
          true,
        ),
        new Item(
          ReportsValues.LoungesAndRestaurantsWithExecutiveNames,
          ReportsLabels[ReportsValues.LoungesAndRestaurantsWithExecutiveNames],
          null,
          true,
        ),
      ]),
    ]),
    new Item("replacements", "Replacements", [
      new Item(ReportsValues.AllReplacements, ReportsLabels[ReportsValues.AllReplacements], null, true),
    ]),
    new Item(ReportsValues.PlaceCards, ReportsLabels[ReportsValues.PlaceCards], null, true),
    new Item("restaurants", "Restaurants", [
      new Item("tableAssignments", "Table Assignments", [
        new Item(ReportsValues.AllTableAssignments, ReportsLabels[ReportsValues.AllTableAssignments], null, true),
        new Item(ReportsValues.FillInTables, ReportsLabels[ReportsValues.FillInTables], null, true),
      ]),
      new Item("restaurantsOther", "Other", [
        new Item(ReportsValues.DietaryRequirements, ReportsLabels[ReportsValues.DietaryRequirements], null, true),
        new Item(ReportsValues.NumberOfSeatsBooked, ReportsLabels[ReportsValues.NumberOfSeatsBooked], null, true),
        new Item(
          ReportsValues.FellowDinersForExecutives,
          ReportsLabels[ReportsValues.FellowDinersForExecutives],
          null,
          true,
        ),
        new Item(
          ReportsValues.FellowDinersForExecutivesByIndividual,
          ReportsLabels[ReportsValues.FellowDinersForExecutivesByIndividual],
          null,
          true,
        ),
      ]),
    ]),
  ]),
  new Item("conference", "Conference", [
    new Item("conferenceAttendees", "Conference Attendees", [
      new Item("seminars", "Seminars", [
        new Item(
          ReportsValues.ConferenceAttendeesSelectedSeminarCode,
          ReportsLabels[ReportsValues.ConferenceAttendeesSelectedSeminarCode],
          null,
          true,
        ),
        new Item(
          ReportsValues.ConferenceAttendeesSelectedSeminarType,
          ReportsLabels[ReportsValues.ConferenceAttendeesSelectedSeminarType],
          null,
          true,
        ),
        new Item(
          ReportsValues.ConferenceAttendeesAssessmentFormsForSeminarType,
          ReportsLabels[ReportsValues.ConferenceAttendeesAssessmentFormsForSeminarType],
          null,
          true,
        ),
        new Item(
          ReportsValues.ConferenceAttendeesAllAssessmentFormsForSeminarType,
          ReportsLabels[ReportsValues.ConferenceAttendeesAllAssessmentFormsForSeminarType],
          null,
          true,
        ),
      ]),
      new Item("sessions", "Sessions", [
        new Item(
          ReportsValues.ConferenceAttendeesSelectedSession,
          ReportsLabels[ReportsValues.ConferenceAttendeesSelectedSession],
          null,
          true,
        ),
        new Item(
          ReportsValues.ConferenceAttendeesAssessmentFormsForSession,
          ReportsLabels[ReportsValues.ConferenceAttendeesAssessmentFormsForSession],
          null,
          true,
        ),
      ]),
      new Item("facility", "Facility", [
        new Item(
          ReportsValues.ConferenceAttendeesAssessmentFormsForFacility,
          ReportsLabels[ReportsValues.ConferenceAttendeesAssessmentFormsForFacility],
          null,
          true,
        ),
        new Item(
          ReportsValues.ConferenceAttendeesFellowAttendees,
          ReportsLabels[ReportsValues.ConferenceAttendeesFellowAttendees],
          null,
          true,
        ),
      ]),
    ]),
    new Item("sessionDetails", "Session Details", [
      new Item("sessionPlaces", "Session Places", [
        new Item(
          ReportsValues.SessionDetailsAllSessionPlaces,
          ReportsLabels[ReportsValues.SessionDetailsAllSessionPlaces],
          null,
          true,
        ),
        new Item(
          ReportsValues.SessionDetailsFreeSessionPlaces,
          ReportsLabels[ReportsValues.SessionDetailsFreeSessionPlaces],
          null,
          true,
        ),
        new Item(
          ReportsValues.SessionDetailsOverallocatedSessionPlaces,
          ReportsLabels[ReportsValues.SessionDetailsOverallocatedSessionPlaces],
          null,
          true,
        ),
      ]),
      new Item("times", "Times", [
        new Item(
          ReportsValues.SessionDetailsAllSessionTimes,
          ReportsLabels[ReportsValues.SessionDetailsAllSessionTimes],
          null,
          true,
        ),
      ]),
    ]),
    new Item("speakerBooking", "Speaker Bookings", [
      new Item(
        ReportsValues.ConferenceAllConferenceAndGeneralBookings,
        ReportsLabels[ReportsValues.ConferenceAllConferenceAndGeneralBookings],
        null,
        true,
      ),
      new Item(
        ReportsValues.ConferenceSpeakerSessionBookings,
        ReportsLabels[ReportsValues.ConferenceSpeakerSessionBookings],
        null,
        true,
      ),
    ]),
    new Item(ReportsValues.SeminarCodesAndTitles, ReportsLabels[ReportsValues.SeminarCodesAndTitles]),
    new Item("preferencesByAnIndividual", "Preferences By An Individual", [
      new Item("conferencePreCrunch", "Pre Crunch", [
        new Item(
          ReportsValues.PreferencesByAnIndividualAllPreferences,
          ReportsLabels[ReportsValues.PreferencesByAnIndividualAllPreferences],
          null,
          true,
        ),
      ]),
      new Item("conferencePostCrunch", "Post Crunch", [
        new Item(
          ReportsValues.PreferencesByAnIndividualPostCrunchAllPreferences,
          ReportsLabels[ReportsValues.PreferencesByAnIndividualAllPreferences],
          null,
          true,
        ),
        new Item(
          ReportsValues.PreferencesByAnIndividualSatisfiedPreferences,
          ReportsLabels[ReportsValues.PreferencesByAnIndividualSatisfiedPreferences],
          null,
          true,
        ),
        new Item(
          ReportsValues.PreferencesByAnIndividualUnsatisfiedPreferences,
          ReportsLabels[ReportsValues.PreferencesByAnIndividualUnsatisfiedPreferences],
          null,
          true,
        ),
      ]),
    ]),
    new Item(ReportsValues.PreferencesForSeminar, ReportsLabels[ReportsValues.PreferencesForSeminar], null, true),
    new Item(ReportsValues.SummaryOfPreferences, ReportsLabels[ReportsValues.SummaryOfPreferences]),
  ]),
  new Item("crunchChecks", "Crunch Checks", [
    new Item("mandatoryChecks", "Mandatory Checks", [
      new Item(ReportsValues.MissingBFRDate, ReportsLabels[ReportsValues.MissingBFRDate]),
      new Item(ReportsValues.MissingContractDate, ReportsLabels[ReportsValues.MissingContractDate]),
      new Item(ReportsValues.BusinessHours, ReportsLabels[ReportsValues.BusinessHours]),
      new Item(ReportsValues.MandatoryChecksPackageDetails, ReportsLabels[ReportsValues.MandatoryChecksPackageDetails]),
      new Item(
        ReportsValues.MandatoryChecksRatiosAndTables,
        ReportsLabels[ReportsValues.MandatoryChecksRatiosAndTables],
      ),
      new Item(ReportsValues.GeneralBooking, ReportsLabels[ReportsValues.GeneralBooking]),
      new Item(ReportsValues.PeopleTypeTotals, ReportsLabels[ReportsValues.PeopleTypeTotals]),
      new Item(ReportsValues.JointNotReciprocated, ReportsLabels[ReportsValues.JointNotReciprocated]),
      new Item(ReportsValues.NoPrioritySeminarPreferences, ReportsLabels[ReportsValues.NoPrioritySeminarPreferences]),
      new Item(ReportsValues.JointWithThemselves, ReportsLabels[ReportsValues.JointWithThemselves]),
      new Item(ReportsValues.MissingPeopleType, ReportsLabels[ReportsValues.MissingPeopleType]),
      new Item(ReportsValues.NoPriorityMeetingPreferences, ReportsLabels[ReportsValues.NoPriorityMeetingPreferences]),
    ]),
    new Item("helpfulChecks", "Helpful Checks", [
      new Item(ReportsValues.DelegateRatioComparison, ReportsLabels[ReportsValues.DelegateRatioComparison]),
      new Item(
        ReportsValues.DelegatesWithoutPreferencesForSomeSeminarTypes,
        ReportsLabels[ReportsValues.DelegatesWithoutPreferencesForSomeSeminarTypes],
      ),
      new Item(
        ReportsValues.DuplicateSupplierCompanyPreferencesForDelegates,
        ReportsLabels[ReportsValues.DuplicateSupplierCompanyPreferencesForDelegates],
      ),
      new Item(
        ReportsValues.DuplicateDelegateCompanyPreferencesForSupplierCompanies,
        ReportsLabels[ReportsValues.DuplicateDelegateCompanyPreferencesForSupplierCompanies],
      ),
      new Item(
        ReportsValues.DuplicateDelegatesPreferencesForSupplierCompanies,
        ReportsLabels[ReportsValues.DuplicateDelegatesPreferencesForSupplierCompanies],
      ),
      new Item(
        ReportsValues.ExecutiveConferencePreferences,
        ReportsLabels[ReportsValues.ExecutiveConferencePreferences],
      ),
      new Item(
        ReportsValues.NegativePreferencesByDelegatesPositivePreferencesForDelegates,
        ReportsLabels[ReportsValues.NegativePreferencesByDelegatesPositivePreferencesForDelegates],
        null,
        true,
      ),
      new Item(
        ReportsValues.NegativePreferencesByExecutivesPositivePreferencesForExecutives,
        ReportsLabels[ReportsValues.NegativePreferencesByExecutivesPositivePreferencesForExecutives],
        null,
        true,
      ),
      new Item(ReportsValues.NoMeetingPreferences, ReportsLabels[ReportsValues.NoMeetingPreferences]),
      new Item(
        ReportsValues.NoMeetingsOrConferencePreferences,
        ReportsLabels[ReportsValues.NoMeetingsOrConferencePreferences],
      ),
      new Item(ReportsValues.NoConferencePreferences, ReportsLabels[ReportsValues.NoConferencePreferences]),
      new Item(ReportsValues.NoCategoryPreferences, ReportsLabels[ReportsValues.NoCategoryPreferences]),
      new Item(ReportsValues.PossibleMissedMutuals, ReportsLabels[ReportsValues.PossibleMissedMutuals]),
      new Item(ReportsValues.RatioMealtimeComparison, ReportsLabels[ReportsValues.RatioMealtimeComparison]),
      new Item(
        ReportsValues.SummaryOfAllReturnedPreferences,
        ReportsLabels[ReportsValues.SummaryOfAllReturnedPreferences],
      ),
    ]),
    new Item("sharedCharterChecks", "Shared Charter Checks", [
      new Item(ReportsValues.CheckRestaurantTables, ReportsLabels[ReportsValues.CheckRestaurantTables], null, true),
    ]),
    new Item("itChecks", "IT Checks", [
      new Item(
        ReportsValues.CompaniesWithMoreThanOneDelegate,
        ReportsLabels[ReportsValues.CompaniesWithMoreThanOneDelegate],
      ),
      new Item(
        ReportsValues.ExecutivesRequestsScoresVsTimeType,
        ReportsLabels[ReportsValues.ExecutivesRequestsScoresVsTimeType],
      ),
      new Item(ReportsValues.NonAttendeesInSchedule, ReportsLabels[ReportsValues.NonAttendeesInSchedule]),
      new Item(
        ReportsValues.SchedulesWithNegativeMeetingPreferences,
        ReportsLabels[ReportsValues.SchedulesWithNegativeMeetingPreferences],
      ),
    ]),
  ]),
  new Item("crunchReports", "Crunch Reports", [
    new Item("executivesMeetingTotals", "Executives Meeting Totals", [
      new Item(
        ReportsValues.ExecutivesMeetingTotalsBusinessHours,
        ReportsLabels[ReportsValues.ExecutivesMeetingTotalsBusinessHours],
        null,
        true,
      ),
      new Item(
        ReportsValues.ExecutivesMeetingTotalsAllExecutiveMeetings,
        ReportsLabels[ReportsValues.ExecutivesMeetingTotalsAllExecutiveMeetings],
        null,
        true,
      ),
    ]),
    new Item("mealtimes", "Mealtimes", [
      new Item(ReportsValues.AllAboveRatio, ReportsLabels[ReportsValues.AllAboveRatio], null, true),
      new Item(
        ReportsValues.AllBelowRatioMissingMealtimes,
        ReportsLabels[ReportsValues.AllBelowRatioMissingMealtimes],
        null,
        true,
      ),
      new Item(
        ReportsValues.ExecutivesMealtimeMeetings,
        ReportsLabels[ReportsValues.ExecutivesMealtimeMeetings],
        null,
        true,
      ),
    ]),
    new Item("occupiedBusinessHours", "Occupied Business Hours", [
      new Item(
        ReportsValues.OccupiedBusinessHoursExecutives,
        ReportsLabels[ReportsValues.OccupiedBusinessHoursExecutives],
        null,
        true,
      ),
      new Item(
        ReportsValues.OccupiedBusinessHoursDelegates,
        ReportsLabels[ReportsValues.OccupiedBusinessHoursDelegates],
        null,
        true,
      ),
    ]),
    new Item("meetingContactInformation", "Meeting Contact Information", [
      new Item(
        ReportsValues.MeetingsByFacilityTimeslot,
        ReportsLabels[ReportsValues.MeetingsByFacilityTimeslot],
        null,
        true,
      ),
      new Item(
        ReportsValues.MeetingsByFacilityTimeslotBookedMeetingsOnly,
        ReportsLabels[ReportsValues.MeetingsByFacilityTimeslotBookedMeetingsOnly],
        null,
        true,
      ),
      new Item(
        ReportsValues.VirtualMeetingsByFacilityTimeslotBookedMeetingsOnly,
        ReportsLabels[ReportsValues.VirtualMeetingsByFacilityTimeslotBookedMeetingsOnly],
      ),
    ]),
    new Item("availability", "Availability", [
      new Item(ReportsValues.AttendeeAvailability, ReportsLabels[ReportsValues.AttendeeAvailability], null, true),
    ]),
  ]),
  new Item("postEvent", "Post Event", [
    new Item(ReportsValues.SummaryOfCheckins, ReportsLabels[ReportsValues.SummaryOfCheckins], null, true),
  ]),
];

// show all categories/subcategories/reports on one level
export const flattenReports = (arr: Item[] = getReports()): Item[] => {
  const result: Item[] = [];

  arr.forEach((item: Item) => {
    result.push(item);

    if (item.children) {
      result.push(...flattenReports(item.children as Item[]));
    }
  });

  return result;
};

// submenu keys of first level
export const rootSubmenuKeys = ["operations", "conference", "crunchChecks", "crunchReports", "postEvent"];
