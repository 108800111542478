import EnumBuilder from "utils/enumBuilderUtils";

const builder = new EnumBuilder();

const MemberRolesEnum = builder
  .add("CEO", "CEO")
  .add("Chairman", "Chairman")
  .add("ConferenceManager", "Conference Manager")
  .add("ConferenceOperationsManager", "Conference Operations Manager")
  .add("DelegateManager", "Delegate Manager")
  .add("ManagingDirector", "Managing Director")
  .add("OperationsManager", "Operations Manager")
  .add("ProjectManager", "Project Manager")
  .add("SalesManager", "Sales Manager")
  .build();

export default MemberRolesEnum;
