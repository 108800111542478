import { useEffect, useState } from "react";

export const useDebounce = (value: string | null, delay: number, minLength: number) => {
  const [debouncedValue, setDebouncedValue] = useState<string | null>(value);

  useEffect(() => {
    if (value && (value.length == 0 || value.length >= minLength)) {
      const timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(timeoutId);
      };
    } else {
      setDebouncedValue(null);
    }
  }, [value, delay, minLength]);

  return debouncedValue;
};
