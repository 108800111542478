/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompanyAddressRequestModel } from '../apiModels';
// @ts-ignore
import { CompanyDescriptionRequestModel } from '../apiModels';
// @ts-ignore
import { CompanyExhibitingStatusRequestModel } from '../apiModels';
// @ts-ignore
import { CompanyForumParticipationModel } from '../apiModels';
// @ts-ignore
import { CompanyModel } from '../apiModels';
// @ts-ignore
import { CompanyPackageModel } from '../apiModels';
// @ts-ignore
import { CompanySocialLinksRequestModel } from '../apiModels';
// @ts-ignore
import { CompanyType } from '../apiModels';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { ForumCompanyPrimaryLocationModel } from '../apiModels';
// @ts-ignore
import { ForumCompanyPrimaryLocationRequestModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeModel } from '../apiModels';
// @ts-ignore
import { PageResponseCompanyDescriptionResponseModel } from '../apiModels';
// @ts-ignore
import { PageResponseCompanyResponseModel } from '../apiModels';
/**
 * ForumCompaniesApi - axios parameter creator
 * @export
 */
export const ForumCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesCompanyIdPut: async (forumId: number, companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesCompanyIdPut', 'forumId', forumId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesCompanyIdPut', 'companyId', companyId)
            const localVarPath = `/forum/{forumId}/companies/{companyId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the names and descriptions of all companies within forum
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [name] 
         * @param {CompanyType} [companyType] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesDescriptionsGet: async (forumId: number, orderBy?: string, name?: string, companyType?: CompanyType, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesDescriptionsGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/companies/descriptions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (companyType !== undefined) {
                localVarQueryParameter['CompanyType'] = companyType;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesExportGet: async (forumId: number, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesExportGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/companies/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyAddressRequestModel} [companyAddressRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdAddressInformationPut: async (forumId: number, forumCompanyId: number, companyAddressRequestModel?: CompanyAddressRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdAddressInformationPut', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdAddressInformationPut', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/address-information`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyAddressRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Attendees of a Company participating in a Forum
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdAttendeesGet: async (forumId: number, forumCompanyId: number, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdAttendeesGet', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdAttendeesGet', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyDescriptionRequestModel} [companyDescriptionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdDescriptionPut: async (forumId: number, forumCompanyId: number, companyDescriptionRequestModel?: CompanyDescriptionRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdDescriptionPut', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdDescriptionPut', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/description`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyDescriptionRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyExhibitingStatusRequestModel} [companyExhibitingStatusRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdExhibitingStatusPut: async (forumId: number, forumCompanyId: number, companyExhibitingStatusRequestModel?: CompanyExhibitingStatusRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdExhibitingStatusPut', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdExhibitingStatusPut', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/exhibiting-status`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyExhibitingStatusRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdGet: async (forumId: number, forumCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdGet', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdGet', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {string} [forumCode] 
         * @param {string} [forumName] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdHistoryInformationGet: async (forumId: number, forumCompanyId: number, forumCode?: string, forumName?: string, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdHistoryInformationGet', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdHistoryInformationGet', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/history-information`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forumCode !== undefined) {
                localVarQueryParameter['ForumCode'] = forumCode;
            }

            if (forumName !== undefined) {
                localVarQueryParameter['ForumName'] = forumName;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Package Info
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPackageGet: async (forumId: number, forumCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPackageGet', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPackageGet', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/package`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Company Package Info
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyPackageModel} [companyPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPackagePut: async (forumId: number, forumCompanyId: number, companyPackageModel?: CompanyPackageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPackagePut', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPackagePut', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/package`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyPackageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPersonsGet: async (forumId: number, forumCompanyId: number, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPersonsGet', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPersonsGet', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/persons`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets Primary Locations of Forum Company
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet: async (forumId: number, forumCompanyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/primary-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes Primary location
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete: async (forumId: number, forumCompanyId: number, locationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete', 'forumCompanyId', forumCompanyId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete', 'locationId', locationId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/primary-locations/{locationId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates Primary location
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {number} locationId 
         * @param {ForumCompanyPrimaryLocationRequestModel} [forumCompanyPrimaryLocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut: async (forumId: number, forumCompanyId: number, locationId: number, forumCompanyPrimaryLocationRequestModel?: ForumCompanyPrimaryLocationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut', 'forumCompanyId', forumCompanyId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut', 'locationId', locationId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/primary-locations/{locationId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)))
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumCompanyPrimaryLocationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds Primary location
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {ForumCompanyPrimaryLocationRequestModel} [forumCompanyPrimaryLocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost: async (forumId: number, forumCompanyId: number, forumCompanyPrimaryLocationRequestModel?: ForumCompanyPrimaryLocationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/primary-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumCompanyPrimaryLocationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanySocialLinksRequestModel} [companySocialLinksRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdSocialLinksPut: async (forumId: number, forumCompanyId: number, companySocialLinksRequestModel?: CompanySocialLinksRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdSocialLinksPut', 'forumId', forumId)
            // verify required parameter 'forumCompanyId' is not null or undefined
            assertParamExists('forumForumIdCompaniesForumCompanyIdSocialLinksPut', 'forumCompanyId', forumCompanyId)
            const localVarPath = `/forum/{forumId}/companies/{forumCompanyId}/social-links`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumCompanyId"}}`, encodeURIComponent(String(forumCompanyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companySocialLinksRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [orderDir] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesGet: async (forumId: number, orderDir?: string, orderBy?: string, pageSize?: number, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/companies`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesPost: async (forumId: number, requestBody?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdCompaniesPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/companies`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumCompaniesApi - functional programming interface
 * @export
 */
export const ForumCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesCompanyIdPut(forumId: number, companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesCompanyIdPut(forumId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the names and descriptions of all companies within forum
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [name] 
         * @param {CompanyType} [companyType] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesDescriptionsGet(forumId: number, orderBy?: string, name?: string, companyType?: CompanyType, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseCompanyDescriptionResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesDescriptionsGet(forumId, orderBy, name, companyType, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesExportGet(forumId: number, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesExportGet(forumId, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyAddressRequestModel} [companyAddressRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdAddressInformationPut(forumId: number, forumCompanyId: number, companyAddressRequestModel?: CompanyAddressRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdAddressInformationPut(forumId, forumCompanyId, companyAddressRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets Attendees of a Company participating in a Forum
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdAttendeesGet(forumId: number, forumCompanyId: number, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdAttendeesGet(forumId, forumCompanyId, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyDescriptionRequestModel} [companyDescriptionRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdDescriptionPut(forumId: number, forumCompanyId: number, companyDescriptionRequestModel?: CompanyDescriptionRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdDescriptionPut(forumId, forumCompanyId, companyDescriptionRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyExhibitingStatusRequestModel} [companyExhibitingStatusRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdExhibitingStatusPut(forumId: number, forumCompanyId: number, companyExhibitingStatusRequestModel?: CompanyExhibitingStatusRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdExhibitingStatusPut(forumId, forumCompanyId, companyExhibitingStatusRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdGet(forumId: number, forumCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdGet(forumId, forumCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {string} [forumCode] 
         * @param {string} [forumName] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdHistoryInformationGet(forumId: number, forumCompanyId: number, forumCode?: string, forumName?: string, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyForumParticipationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdHistoryInformationGet(forumId, forumCompanyId, forumCode, forumName, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Company Package Info
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPackageGet(forumId: number, forumCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyPackageModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPackageGet(forumId, forumCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Company Package Info
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanyPackageModel} [companyPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPackagePut(forumId: number, forumCompanyId: number, companyPackageModel?: CompanyPackageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPackagePut(forumId, forumCompanyId, companyPackageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPersonsGet(forumId: number, forumCompanyId: number, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPersonsGet(forumId, forumCompanyId, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets Primary Locations of Forum Company
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet(forumId: number, forumCompanyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumCompanyPrimaryLocationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet(forumId, forumCompanyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes Primary location
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {number} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete(forumId: number, forumCompanyId: number, locationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete(forumId, forumCompanyId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates Primary location
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {number} locationId 
         * @param {ForumCompanyPrimaryLocationRequestModel} [forumCompanyPrimaryLocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut(forumId: number, forumCompanyId: number, locationId: number, forumCompanyPrimaryLocationRequestModel?: ForumCompanyPrimaryLocationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut(forumId, forumCompanyId, locationId, forumCompanyPrimaryLocationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds Primary location
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {ForumCompanyPrimaryLocationRequestModel} [forumCompanyPrimaryLocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost(forumId: number, forumCompanyId: number, forumCompanyPrimaryLocationRequestModel?: ForumCompanyPrimaryLocationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost(forumId, forumCompanyId, forumCompanyPrimaryLocationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} forumCompanyId 
         * @param {CompanySocialLinksRequestModel} [companySocialLinksRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesForumCompanyIdSocialLinksPut(forumId: number, forumCompanyId: number, companySocialLinksRequestModel?: CompanySocialLinksRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesForumCompanyIdSocialLinksPut(forumId, forumCompanyId, companySocialLinksRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {string} [orderDir] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesGet(forumId: number, orderDir?: string, orderBy?: string, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseCompanyResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesGet(forumId, orderDir, orderBy, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {Array<number>} [requestBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdCompaniesPost(forumId: number, requestBody?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdCompaniesPost(forumId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumCompaniesApi - factory interface
 * @export
 */
export const ForumCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesCompanyIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesCompanyIdPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesCompanyIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesCompanyIdPut(requestParameters.forumId, requestParameters.companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the names and descriptions of all companies within forum
         * @param {ForumCompaniesApiForumForumIdCompaniesDescriptionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesDescriptionsGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesDescriptionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseCompanyDescriptionResponseModel> {
            return localVarFp.forumForumIdCompaniesDescriptionsGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.name, requestParameters.companyType, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesExportGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdCompaniesExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdAddressInformationPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdAddressInformationPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyAddressRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Attendees of a Company participating in a Forum
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdAttendeesGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeModel> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdAttendeesGet(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdDescriptionPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdDescriptionPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyDescriptionRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdExhibitingStatusPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdExhibitingStatusPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyExhibitingStatusRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyModel> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdGet(requestParameters.forumId, requestParameters.forumCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdHistoryInformationGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CompanyForumParticipationModel>> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdHistoryInformationGet(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.forumCode, requestParameters.forumName, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Package Info
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPackageGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGetRequest, options?: AxiosRequestConfig): AxiosPromise<CompanyPackageModel> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPackageGet(requestParameters.forumId, requestParameters.forumCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Company Package Info
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPackagePut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPackagePut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyPackageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPersonsGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeModel> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPersonsGet(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets Primary Locations of Forum Company
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumCompanyPrimaryLocationModel>> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet(requestParameters.forumId, requestParameters.forumCompanyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes Primary location
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates Primary location
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.locationId, requestParameters.forumCompanyPrimaryLocationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds Primary location
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.forumCompanyPrimaryLocationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesForumCompanyIdSocialLinksPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesForumCompanyIdSocialLinksPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companySocialLinksRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseCompanyResponseModel> {
            return localVarFp.forumForumIdCompaniesGet(requestParameters.forumId, requestParameters.orderDir, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumCompaniesApiForumForumIdCompaniesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdCompaniesPost(requestParameters: ForumCompaniesApiForumForumIdCompaniesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdCompaniesPost(requestParameters.forumId, requestParameters.requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdCompaniesCompanyIdPut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesCompanyIdPutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesCompanyIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesCompanyIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesCompanyIdPut
     */
    readonly companyId: number
}

/**
 * Request parameters for forumForumIdCompaniesDescriptionsGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesDescriptionsGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesDescriptionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly name?: string

    /**
     * 
     * @type {CompanyType}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly companyType?: CompanyType

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesDescriptionsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdCompaniesExportGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesExportGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {FileExportType}
     * @memberof ForumCompaniesApiForumForumIdCompaniesExportGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdAddressInformationPut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPut
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {CompanyAddressRequestModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPut
     */
    readonly companyAddressRequestModel?: CompanyAddressRequestModel
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdAttendeesGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGet
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdDescriptionPut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPut
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {CompanyDescriptionRequestModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPut
     */
    readonly companyDescriptionRequestModel?: CompanyDescriptionRequestModel
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdExhibitingStatusPut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPut
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {CompanyExhibitingStatusRequestModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPut
     */
    readonly companyExhibitingStatusRequestModel?: CompanyExhibitingStatusRequestModel
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGet
     */
    readonly forumCompanyId: number
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdHistoryInformationGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGet
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGet
     */
    readonly forumCode?: string

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGet
     */
    readonly forumName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPackageGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGet
     */
    readonly forumCompanyId: number
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPackagePut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePut
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {CompanyPackageModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePut
     */
    readonly companyPackageModel?: CompanyPackageModel
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPersonsGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGet
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGet
     */
    readonly forumCompanyId: number
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDeleteRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete
     */
    readonly locationId: number
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut
     */
    readonly locationId: number

    /**
     * 
     * @type {ForumCompanyPrimaryLocationRequestModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut
     */
    readonly forumCompanyPrimaryLocationRequestModel?: ForumCompanyPrimaryLocationRequestModel
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPostRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPost
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {ForumCompanyPrimaryLocationRequestModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPost
     */
    readonly forumCompanyPrimaryLocationRequestModel?: ForumCompanyPrimaryLocationRequestModel
}

/**
 * Request parameters for forumForumIdCompaniesForumCompanyIdSocialLinksPut operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPutRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPut
     */
    readonly forumCompanyId: number

    /**
     * 
     * @type {CompanySocialLinksRequestModel}
     * @memberof ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPut
     */
    readonly companySocialLinksRequestModel?: CompanySocialLinksRequestModel
}

/**
 * Request parameters for forumForumIdCompaniesGet operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesGetRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {string}
     * @memberof ForumCompaniesApiForumForumIdCompaniesGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesGet
     */
    readonly pageNumber?: number
}

/**
 * Request parameters for forumForumIdCompaniesPost operation in ForumCompaniesApi.
 * @export
 * @interface ForumCompaniesApiForumForumIdCompaniesPostRequest
 */
export interface ForumCompaniesApiForumForumIdCompaniesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumCompaniesApiForumForumIdCompaniesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumCompaniesApiForumForumIdCompaniesPost
     */
    readonly requestBody?: Array<number>
}

/**
 * ForumCompaniesApi - object-oriented interface
 * @export
 * @class ForumCompaniesApi
 * @extends {BaseAPI}
 */
export class ForumCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesCompanyIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesCompanyIdPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesCompanyIdPutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesCompanyIdPut(requestParameters.forumId, requestParameters.companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the names and descriptions of all companies within forum
     * @param {ForumCompaniesApiForumForumIdCompaniesDescriptionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesDescriptionsGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesDescriptionsGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesDescriptionsGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.name, requestParameters.companyType, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesExportGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesExportGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdAddressInformationPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAddressInformationPutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdAddressInformationPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyAddressRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Attendees of a Company participating in a Forum
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdAttendeesGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdAttendeesGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdAttendeesGet(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdDescriptionPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdDescriptionPutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdDescriptionPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyDescriptionRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdExhibitingStatusPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdExhibitingStatusPutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdExhibitingStatusPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyExhibitingStatusRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdGet(requestParameters.forumId, requestParameters.forumCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdHistoryInformationGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdHistoryInformationGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdHistoryInformationGet(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.forumCode, requestParameters.forumName, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Package Info
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPackageGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackageGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPackageGet(requestParameters.forumId, requestParameters.forumCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Company Package Info
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPackagePut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPackagePutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPackagePut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companyPackageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPersonsGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPersonsGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPersonsGet(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets Primary Locations of Forum Company
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet(requestParameters.forumId, requestParameters.forumCompanyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes Primary location
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates Primary location
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.locationId, requestParameters.forumCompanyPrimaryLocationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds Primary location
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdPrimaryLocationsPostRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.forumCompanyPrimaryLocationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesForumCompanyIdSocialLinksPut(requestParameters: ForumCompaniesApiForumForumIdCompaniesForumCompanyIdSocialLinksPutRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesForumCompanyIdSocialLinksPut(requestParameters.forumId, requestParameters.forumCompanyId, requestParameters.companySocialLinksRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesGet(requestParameters: ForumCompaniesApiForumForumIdCompaniesGetRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesGet(requestParameters.forumId, requestParameters.orderDir, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumCompaniesApiForumForumIdCompaniesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumCompaniesApi
     */
    public forumForumIdCompaniesPost(requestParameters: ForumCompaniesApiForumForumIdCompaniesPostRequest, options?: AxiosRequestConfig) {
        return ForumCompaniesApiFp(this.configuration).forumForumIdCompaniesPost(requestParameters.forumId, requestParameters.requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}

