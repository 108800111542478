/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Divider, Row } from "antd";
import React from "react";

interface DetailBoxProps {
  title: string;
  data?: React.ReactNode;
  isLastPair?: boolean;
  render?: (arg: any) => any;
}

const DetailBox: React.FC<DetailBoxProps> = ({ title, data, render, isLastPair }) => (
  <Col span={12}>
    <Row gutter={32} align="middle" style={{ height: "100%" }}>
      <Col span={10}>
        <label>{title}</label>
      </Col>
      <Col span={10}>{(render ? render(data) : data) ?? "-"}</Col>
    </Row>
    {!isLastPair && <Divider />}
  </Col>
);

export default DetailBox;
