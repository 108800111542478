import { useQueryClient } from "@tanstack/react-query";
import { ColProps } from "antd";
import { useUpdateForumCompanySocialLinksMutation } from "api/mutations/forumCompanies";
import KEYS from "api/queries/keys";
import { Button, Col, Divider, Form, Input, Row } from "components/styleguide";
import { CompanyModel, CompanySocialLinksRequestModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

interface ForumCompanySocialLinksFormProps {
  forumId: number;
  forumCompanyId: number;
  initialValues: CompanyModel;
  setIsEditing: (value: boolean) => void;
}

const ForumCompanySocialLinksForm: FC<ForumCompanySocialLinksFormProps> = ({
  forumId,
  forumCompanyId,
  initialValues,
  setIsEditing,
}) => {
  const { t } = useTranslation();

  const urlYupTest = () =>
    yup
      .string()
      .nullable()
      // yup interfaces are weird: validateSync returns string | undefined
      // but .test() needs to return boolean | ValidationError
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .test((value: string | null | undefined): any => {
        if (!value) {
          return true;
        }
        try {
          return yup.string().url(t("errors.Must be a valid web address")).validateSync(value);
        } catch (_) {
          return yup.string().url(t("errors.Must be a valid web address")).validateSync(`https://${value}`) as string;
        }
      });

  const getSchema = () => {
    return yup.object().shape({
      facebook: urlYupTest(),
      twitter: urlYupTest(),
      linkedIn: urlYupTest(),
    });
  };

  const [form] = Form.useForm();
  const validation = Form.useYupValidator(form, getSchema);

  const queryClient = useQueryClient();
  const updateMutation = useUpdateForumCompanySocialLinksMutation();

  const submit = (values: CompanySocialLinksRequestModel) => {
    const initialSocialLinks: CompanySocialLinksRequestModel = ((model: CompanyModel) => ({
      twitter: model.twitter ?? null,
      facebook: model.facebook ?? null,
      linkedIn: model.linkedIn ?? null,
    }))(initialValues);

    const updatedCompany: CompanySocialLinksRequestModel = { ...initialSocialLinks, ...values };

    updateMutation.mutate(
      { forumId, forumCompanyId, companySocialLinksRequestModel: updatedCompany },
      {
        onSuccess: () => {
          setIsEditing(false);
          queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY]);
        },
      },
    );
  };

  return (
    <Form
      onFinish={submit}
      id="socialLinksForm"
      name="social-links"
      form={form}
      labelAlign="left"
      labelCol={{ justify: "left", span: 13 } as ColProps}
      wrapperCol={{ justify: "right", span: 11 } as ColProps}
      labelWrap
      colon={false}
    >
      <Row gutter={80}>
        <Col md={24} lg={12}>
          <Form.Item
            name="facebook"
            label={t("Facebook")}
            initialValue={initialValues.facebook}
            // useYupValidator props need to be updated
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rules={validation.rules as any}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="twitter"
            label={t("Twitter")}
            initialValue={initialValues.twitter}
            // useYupValidator props need to be updated
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rules={validation.rules as any}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Divider />
        </Col>

        <Col md={24} lg={12}>
          <Form.Item
            name="linkedIn"
            label={t("LinkedIn")}
            initialValue={initialValues.linkedIn}
            // useYupValidator props need to be updated
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rules={validation.rules as any}
          >
            <Input maxLength={100} />
          </Form.Item>
          <Divider />
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={1}>
          <Button loading={updateMutation.isLoading} htmlType="submit" type="primary">
            {t("Save")}
          </Button>
        </Col>
        <Col span={1}>
          <Button onClick={() => setIsEditing(false)}>{t("Cancel")}</Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ForumCompanySocialLinksForm;
