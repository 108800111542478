import { ForumGroupDocumentApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumGroupDocumentApi);

const ForumGroupDocuments = {
  getAll: api.forumForumIdGroupsGroupIdDocumentsGet,
  get: api.forumForumIdGroupsGroupIdDocumentsDocumentIdGet,
  post: api.forumForumIdGroupsGroupIdDocumentsPost,
  delete: api.forumForumIdGroupsGroupIdDocumentsDocumentIdDelete,
};

export default ForumGroupDocuments;
