import { Space } from "components/styleguide";
import { ForumSeminarTypeResponseModel } from "generated/api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import SeminarTypesTableOption from "../OptionTypes/SeminarTypesTableOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = { SeminarCode: "Seminar Code", Start: "Session Start Time" };

const ConferenceAssessmentFormsOptions = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<string>("SeminarCode");

  const onSeminarTypeSelect = (seminarType: ForumSeminarTypeResponseModel) => {
    navigate("report", {
      state: {
        parameters: {
          OrderBy: orderBy,
          ForumSeminarTypeId: seminarType.id,
        },
      },
    });
  };

  return (
    <Space direction={"vertical"} size={"large"} fullWidth>
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
      <SeminarTypesTableOption onSeminarTypeSelect={onSeminarTypeSelect} />
    </Space>
  );
};

export default ConferenceAssessmentFormsOptions;
