import styles from "./styles.module.less";

export const useItemCard = () => {
  const getCardClassByAttendeeStatus = (present: boolean | undefined | null) => {
    if (present !== undefined && present !== null) {
      return present ? styles.presentItem : styles.notPresentItem;
    } else return "";
  };

  return { getCardClassByAttendeeStatus };
};
