import { useForumFacilityQuery } from "api/queries/forumFacilities";
import { useForumItineraryMeetingFacilityTimeslotSessionsQuery } from "api/queries/forumItinerary";
import MobileLinkList, { LinkListProp } from "components/MobileLinkList";
import { Grid, SpinLoading } from "components/styleguide/mobile";
import { FacilityResponseModel, TimeSlotSessionListItemModel } from "generated/api";
import { toNumber } from "lodash-es";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";

import { PlaybookOutletContext } from "../../types";

export default function PlaybookMeetingFacility() {
  const { id, buildingId, facilityId } = useParams();
  const { t } = useTranslation();
  const { setTitle, setTitle2 } = useOutletContext<PlaybookOutletContext>();

  const { data: { data: facility = {} as FacilityResponseModel } = {}, isLoading: isLoadingFacility } =
    useForumFacilityQuery({
      forumId: toNumber(id),
      forumBuildingId: toNumber(buildingId),
      facilityId: toNumber(facilityId),
    });

  const { data: { data: sessions = [] } = {}, isLoading: isLoadingSessions } =
    useForumItineraryMeetingFacilityTimeslotSessionsQuery(
      {
        forumId: toNumber(id),
        forumMeetingFacilityId: toNumber(facilityId),
      },
      { cacheTime: Infinity, staleTime: Infinity },
    );

  useEffect(() => {
    if (isLoadingFacility || !facility?.name) {
      setTitle(t("Loading..."));
    } else {
      setTitle(facility.name);
    }
    setTitle2(null);
  }, [facility.name, isLoadingFacility]);

  if (!sessions || isLoadingSessions || isLoadingFacility) {
    return (
      <div className="loader">
        <SpinLoading style={{ "--size": "48px" }} />
      </div>
    );
  }

  return (
    <Grid columns={2} gap={8}>
      <MobileLinkList
        items={sessions?.map((s: TimeSlotSessionListItemModel) => {
          return {
            id: s.id?.toString(),
            title: moment(s.startTime).format("LT"),
            link: `sessions/${s.timeSlotId}/${s.id}`,
            footerTextLeft: moment(s.startTime).format("L"),
          } as LinkListProp;
        })}
      />
    </Grid>
  );
}
