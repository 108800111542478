import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useGetBillingNotesQuery } from "api/queries/billingNotes";
import ComboBox from "components/ComboBox";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Modal, Select, Tabs } from "components/styleguide";
import {
  AttendeeModel,
  FacilityRequestModel,
  FacilityResponseModel,
  FloorModel,
  RoomGradeResponseModel,
} from "generated/api";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import Grades from "../../Grades";

import styles from "./styles.module.less";

const { confirm } = Modal;
const { Option } = Select;

interface BedroomDrawerProps {
  isCreate: boolean;
  visible: boolean;
  isLoading: boolean;
  bedroom: FacilityResponseModel;
  onBedroomEditSubmit: (bedroomData: FacilityRequestModel) => void;
  onCancel: () => void;
  onBedroomCreate: (bedroomData: FacilityRequestModel) => void;
  onFloorAdd: (name: string) => void;
  onFloorDelete: (floorId: number) => void;
  floors: FloorModel[];
  roomGrades: RoomGradeResponseModel[];
  attendees: AttendeeModel[];
}

const getAttendeeIds = (attendees: AttendeeModel[]) => {
  return attendees?.map((attendee) => attendee.attendId);
};

const getAttendeesOptions = (attendees: AttendeeModel[]) => {
  return attendees?.map(({ attendId, firstName, lastName }) => ({
    value: attendId,
    label: `${firstName} ${lastName}`,
  }));
};

const BedroomDrawer = ({
  isCreate,
  onBedroomEditSubmit,
  visible,
  bedroom,
  onCancel,
  isLoading,
  onBedroomCreate,
  onFloorAdd,
  onFloorDelete,
  floors,
  roomGrades,
  attendees,
}: BedroomDrawerProps) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const forumId = toNumber(useParams().id);

  const { data: { data: billingNotes = [] } = {}, isLoading: isBillingNotesLoading } = useGetBillingNotesQuery(
    {
      forumId,
    },
    { enabled: !!forumId },
  );

  const handleSubmit = () => {
    const bedroomData = form.getFieldsValue();
    if (isCreate) {
      onBedroomCreate(bedroomData);
    } else {
      onBedroomEditSubmit(bedroomData);
    }
  };

  const handleClose = () => {
    onCancel();
    form.resetFields();
  };

  const addFloor = (name: string) => {
    onFloorAdd(name);
  };

  const deleteFloor = (floor: FloorModel) => {
    confirm({
      title: t("confirmation"),
      content: t("Do you want to delete this floor? There could be facilities assigned to this floor"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: async () => {
        if (floor.name === form.getFieldValue("floor")) {
          form.setFieldValue("floor", null);
        }
        onFloorDelete(floor.id);
      },
    });
  };

  useEffect(() => {
    if (bedroom !== null) {
      form.setFields([
        {
          name: "code",
          value: bedroom.code,
        },
        {
          name: "name",
          value: bedroom.name,
        },
        {
          name: "description",
          value: bedroom.description,
        },
        {
          name: "roomGrade",
          value: bedroom.roomGrade,
        },
        {
          name: "floor",
          value: bedroom.floor,
        },
        {
          name: "attendeeIds",
          value: getAttendeeIds(bedroom?.attendees ?? []),
        },
        {
          name: "billingNoteId",
          value: bedroom.billingNoteId,
        },
      ]);
    }
  }, [bedroom, form]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <Drawer
      title={isCreate ? t("New Bedroom") : t("Edit Bedroom")}
      placement="right"
      onClose={handleClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "40%" }}
      destroyOnClose
    >
      <Tabs className={styles.tabs} defaultActiveKey="details">
        <Tabs.TabPane tab={t("Details")} key="details">
          <FullHeightForm
            form={form}
            actionsPrepend={
              <Button onClick={handleClose} disabled={isLoading}>
                {t("Cancel")}
              </Button>
            }
            actionsAppend={
              <Button type="primary" disabled={isLoading} loading={isLoading} htmlType="submit">
                {isCreate ? t("Create") : t("Update")}
              </Button>
            }
            onFinish={handleSubmit}
          >
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              label={t("Facility Code")}
              name={"code"}
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: "$t(Facility Code)" }),
                },
                {
                  max: 5,
                  message: t("The character limit is {{count}}", { count: 5 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 24, offset: 0 }}
              label={t("Bedroom Name")}
              name={"name"}
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: "$t(Bedroom Name)" }),
                },
                {
                  max: 100,
                  message: t("The character limit is {{count}}", { count: 100 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item labelCol={{ span: 24, offset: 0 }} label={t("Room Grade")} name={"roomGrade"}>
              <Select>
                {roomGrades.map((r) => (
                  <Option key={r.id} value={r.name}>
                    {r.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item labelCol={{ span: 24, offset: 0 }} label={t("floor")} name="floor">
              <ComboBox
                options={floors}
                onAdd={addFloor}
                onDelete={deleteFloor}
                fieldNames={{
                  key: "id",
                  label: "name",
                  value: "name",
                }}
                maxLength={20}
              />
            </Form.Item>
            {forumId ? (
              <>
                <Form.Item labelCol={{ span: 24, offset: 0 }} label={t("Attendees")} name="attendeeIds">
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    optionFilterProp={"label"}
                    options={getAttendeesOptions(attendees)}
                  />
                </Form.Item>
                <Form.Item labelCol={{ span: 24, offset: 0 }} label={t("Billing Notes")} name="billingNoteId">
                  <Select
                    loading={isBillingNotesLoading}
                    options={billingNotes.map(({ id, type }) => ({ value: id, label: type }))}
                    optionFilterProp={"label"}
                    showSearch
                    allowClear
                  />
                </Form.Item>
              </>
            ) : null}
          </FullHeightForm>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Bedroom Grades")} key="grades">
          <Grades drawerVisible={visible} />
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

BedroomDrawer.propTypes = {
  isCreate: PropTypes.bool,
  onBedroomEditSubmit: PropTypes.func,
  visible: PropTypes.bool,
  bedroom: PropTypes.object,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  onBedroomCreate: PropTypes.func,
  onFloorAdd: PropTypes.func,
  onFloorDelete: PropTypes.func,
  floors: PropTypes.array,
  roomGrades: PropTypes.array,
  attendees: PropTypes.array,
};

export default BedroomDrawer;
