/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentDefinitionStandardSectionModel } from '../apiModels';
/**
 * DocumentDefinitionStandardSectionApi - axios parameter creator
 * @export
 */
export const DocumentDefinitionStandardSectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get system configured standard sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDefinitionStandardSectionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/document-definition-standard-sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentDefinitionStandardSectionApi - functional programming interface
 * @export
 */
export const DocumentDefinitionStandardSectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentDefinitionStandardSectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get system configured standard sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentDefinitionStandardSectionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDefinitionStandardSectionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentDefinitionStandardSectionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentDefinitionStandardSectionApi - factory interface
 * @export
 */
export const DocumentDefinitionStandardSectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentDefinitionStandardSectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get system configured standard sections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentDefinitionStandardSectionsGet(options?: AxiosRequestConfig): AxiosPromise<Array<DocumentDefinitionStandardSectionModel>> {
            return localVarFp.documentDefinitionStandardSectionsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentDefinitionStandardSectionApi - object-oriented interface
 * @export
 * @class DocumentDefinitionStandardSectionApi
 * @extends {BaseAPI}
 */
export class DocumentDefinitionStandardSectionApi extends BaseAPI {
    /**
     * 
     * @summary Get system configured standard sections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentDefinitionStandardSectionApi
     */
    public documentDefinitionStandardSectionsGet(options?: AxiosRequestConfig) {
        return DocumentDefinitionStandardSectionApiFp(this.configuration).documentDefinitionStandardSectionsGet(options).then((request) => request(this.axios, this.basePath));
    }
}

