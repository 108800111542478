import { Col, Row, Typography } from "components/styleguide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import Info, { InfoSize, InfoWeight } from "../Info";

import styles from "./styles.module.less";

interface TitleProps {
  articleId: string;
  title: string;
}

const Title: FC<TitleProps> = ({ articleId, title }) => {
  const { t } = useTranslation();

  return (
    <Row className={styles.container} align="middle" gutter={[10, 0]}>
      <Col>
        <Typography.Title className={styles.title} level={4}>
          {t(title)}
        </Typography.Title>
      </Col>
      <Col>
        <Info articleId={articleId} type={InfoWeight.BOLD} size={InfoSize.SMALL} />
      </Col>
    </Row>
  );
};

export default Title;
