import { Tabs } from "components/styleguide";
import { useTranslation } from "react-i18next";

import MeetingLocationsByTimeslot from "./Timeslot";
import MeetingLocationsByTimeType from "./TimeType";

import styles from "./styles.module.less";

export default function MeetingLocations() {
  const { t } = useTranslation();
  const tabs = [
    {
      key: "timeType",
      label: t("Time Type"),
      children: <MeetingLocationsByTimeType />,
    },
    {
      key: "timeslot",
      label: t("Timeslot"),
      children: <MeetingLocationsByTimeslot />,
    },
  ];

  return (
    <div id="meeting-locations-page">
      <div className={styles.page}>
        <Tabs items={tabs} />
      </div>
    </div>
  );
}
