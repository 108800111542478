/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileImportModes } from '../apiModels';
// @ts-ignore
import { FileImportStatus } from '../apiModels';
/**
 * FileImportApi - axios parameter creator
 * @export
 */
export const FileImportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportBedroomsPost: async (forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdImportBedroomsPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/import/bedrooms`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportFacilitiesPost: async (forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdImportFacilitiesPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/import/facilities`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportTablesPost: async (forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdImportTablesPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/import/tables`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportTimeslotsPost: async (forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdImportTimeslotsPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/import/timeslots`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestImportKeyGet: async (key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('requestImportKeyGet', 'key', key)
            const localVarPath = `/request-import/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdImportBedroomsPost: async (venueId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdImportBedroomsPost', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/import/bedrooms`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdImportFacilitiesPost: async (venueId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdImportFacilitiesPost', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/import/facilities`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdImportTablesPost: async (venueId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdImportTablesPost', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/import/tables`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
            if (importKey !== undefined) { 
                localVarFormParams.append('ImportKey', importKey as any);
            }
    
            if (importMode !== undefined) { 
                localVarFormParams.append('ImportMode', importMode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileImportApi - functional programming interface
 * @export
 */
export const FileImportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileImportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdImportBedroomsPost(forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdImportBedroomsPost(forumId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdImportFacilitiesPost(forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdImportFacilitiesPost(forumId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdImportTablesPost(forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdImportTablesPost(forumId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdImportTimeslotsPost(forumId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdImportTimeslotsPost(forumId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestImportKeyGet(key: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileImportStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestImportKeyGet(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdImportBedroomsPost(venueId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdImportBedroomsPost(venueId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdImportFacilitiesPost(venueId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdImportFacilitiesPost(venueId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {string} [importKey] 
         * @param {FileImportModes} [importMode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdImportTablesPost(venueId: number, formFile?: File, importKey?: string, importMode?: FileImportModes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdImportTablesPost(venueId, formFile, importKey, importMode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileImportApi - factory interface
 * @export
 */
export const FileImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileImportApiFp(configuration)
    return {
        /**
         * 
         * @param {FileImportApiForumsForumIdImportBedroomsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportBedroomsPost(requestParameters: FileImportApiForumsForumIdImportBedroomsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdImportBedroomsPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiForumsForumIdImportFacilitiesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportFacilitiesPost(requestParameters: FileImportApiForumsForumIdImportFacilitiesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdImportFacilitiesPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiForumsForumIdImportTablesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportTablesPost(requestParameters: FileImportApiForumsForumIdImportTablesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdImportTablesPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiForumsForumIdImportTimeslotsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdImportTimeslotsPost(requestParameters: FileImportApiForumsForumIdImportTimeslotsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdImportTimeslotsPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiRequestImportKeyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestImportKeyGet(requestParameters: FileImportApiRequestImportKeyGetRequest, options?: AxiosRequestConfig): AxiosPromise<FileImportStatus> {
            return localVarFp.requestImportKeyGet(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiVenuesVenueIdImportBedroomsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdImportBedroomsPost(requestParameters: FileImportApiVenuesVenueIdImportBedroomsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdImportBedroomsPost(requestParameters.venueId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiVenuesVenueIdImportFacilitiesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdImportFacilitiesPost(requestParameters: FileImportApiVenuesVenueIdImportFacilitiesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdImportFacilitiesPost(requestParameters.venueId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FileImportApiVenuesVenueIdImportTablesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdImportTablesPost(requestParameters: FileImportApiVenuesVenueIdImportTablesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdImportTablesPost(requestParameters.venueId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdImportBedroomsPost operation in FileImportApi.
 * @export
 * @interface FileImportApiForumsForumIdImportBedroomsPostRequest
 */
export interface FileImportApiForumsForumIdImportBedroomsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiForumsForumIdImportBedroomsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiForumsForumIdImportBedroomsPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiForumsForumIdImportBedroomsPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiForumsForumIdImportBedroomsPost
     */
    readonly importMode?: FileImportModes
}

/**
 * Request parameters for forumsForumIdImportFacilitiesPost operation in FileImportApi.
 * @export
 * @interface FileImportApiForumsForumIdImportFacilitiesPostRequest
 */
export interface FileImportApiForumsForumIdImportFacilitiesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiForumsForumIdImportFacilitiesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiForumsForumIdImportFacilitiesPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiForumsForumIdImportFacilitiesPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiForumsForumIdImportFacilitiesPost
     */
    readonly importMode?: FileImportModes
}

/**
 * Request parameters for forumsForumIdImportTablesPost operation in FileImportApi.
 * @export
 * @interface FileImportApiForumsForumIdImportTablesPostRequest
 */
export interface FileImportApiForumsForumIdImportTablesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiForumsForumIdImportTablesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiForumsForumIdImportTablesPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiForumsForumIdImportTablesPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiForumsForumIdImportTablesPost
     */
    readonly importMode?: FileImportModes
}

/**
 * Request parameters for forumsForumIdImportTimeslotsPost operation in FileImportApi.
 * @export
 * @interface FileImportApiForumsForumIdImportTimeslotsPostRequest
 */
export interface FileImportApiForumsForumIdImportTimeslotsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiForumsForumIdImportTimeslotsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiForumsForumIdImportTimeslotsPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiForumsForumIdImportTimeslotsPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiForumsForumIdImportTimeslotsPost
     */
    readonly importMode?: FileImportModes
}

/**
 * Request parameters for requestImportKeyGet operation in FileImportApi.
 * @export
 * @interface FileImportApiRequestImportKeyGetRequest
 */
export interface FileImportApiRequestImportKeyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof FileImportApiRequestImportKeyGet
     */
    readonly key: string
}

/**
 * Request parameters for venuesVenueIdImportBedroomsPost operation in FileImportApi.
 * @export
 * @interface FileImportApiVenuesVenueIdImportBedroomsPostRequest
 */
export interface FileImportApiVenuesVenueIdImportBedroomsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiVenuesVenueIdImportBedroomsPost
     */
    readonly venueId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiVenuesVenueIdImportBedroomsPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiVenuesVenueIdImportBedroomsPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiVenuesVenueIdImportBedroomsPost
     */
    readonly importMode?: FileImportModes
}

/**
 * Request parameters for venuesVenueIdImportFacilitiesPost operation in FileImportApi.
 * @export
 * @interface FileImportApiVenuesVenueIdImportFacilitiesPostRequest
 */
export interface FileImportApiVenuesVenueIdImportFacilitiesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiVenuesVenueIdImportFacilitiesPost
     */
    readonly venueId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiVenuesVenueIdImportFacilitiesPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiVenuesVenueIdImportFacilitiesPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiVenuesVenueIdImportFacilitiesPost
     */
    readonly importMode?: FileImportModes
}

/**
 * Request parameters for venuesVenueIdImportTablesPost operation in FileImportApi.
 * @export
 * @interface FileImportApiVenuesVenueIdImportTablesPostRequest
 */
export interface FileImportApiVenuesVenueIdImportTablesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof FileImportApiVenuesVenueIdImportTablesPost
     */
    readonly venueId: number

    /**
     * 
     * @type {File}
     * @memberof FileImportApiVenuesVenueIdImportTablesPost
     */
    readonly formFile?: File

    /**
     * 
     * @type {string}
     * @memberof FileImportApiVenuesVenueIdImportTablesPost
     */
    readonly importKey?: string

    /**
     * 
     * @type {FileImportModes}
     * @memberof FileImportApiVenuesVenueIdImportTablesPost
     */
    readonly importMode?: FileImportModes
}

/**
 * FileImportApi - object-oriented interface
 * @export
 * @class FileImportApi
 * @extends {BaseAPI}
 */
export class FileImportApi extends BaseAPI {
    /**
     * 
     * @param {FileImportApiForumsForumIdImportBedroomsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public forumsForumIdImportBedroomsPost(requestParameters: FileImportApiForumsForumIdImportBedroomsPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).forumsForumIdImportBedroomsPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiForumsForumIdImportFacilitiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public forumsForumIdImportFacilitiesPost(requestParameters: FileImportApiForumsForumIdImportFacilitiesPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).forumsForumIdImportFacilitiesPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiForumsForumIdImportTablesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public forumsForumIdImportTablesPost(requestParameters: FileImportApiForumsForumIdImportTablesPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).forumsForumIdImportTablesPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiForumsForumIdImportTimeslotsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public forumsForumIdImportTimeslotsPost(requestParameters: FileImportApiForumsForumIdImportTimeslotsPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).forumsForumIdImportTimeslotsPost(requestParameters.forumId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiRequestImportKeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public requestImportKeyGet(requestParameters: FileImportApiRequestImportKeyGetRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).requestImportKeyGet(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiVenuesVenueIdImportBedroomsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public venuesVenueIdImportBedroomsPost(requestParameters: FileImportApiVenuesVenueIdImportBedroomsPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).venuesVenueIdImportBedroomsPost(requestParameters.venueId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiVenuesVenueIdImportFacilitiesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public venuesVenueIdImportFacilitiesPost(requestParameters: FileImportApiVenuesVenueIdImportFacilitiesPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).venuesVenueIdImportFacilitiesPost(requestParameters.venueId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FileImportApiVenuesVenueIdImportTablesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileImportApi
     */
    public venuesVenueIdImportTablesPost(requestParameters: FileImportApiVenuesVenueIdImportTablesPostRequest, options?: AxiosRequestConfig) {
        return FileImportApiFp(this.configuration).venuesVenueIdImportTablesPost(requestParameters.venueId, requestParameters.formFile, requestParameters.importKey, requestParameters.importMode, options).then((request) => request(this.axios, this.basePath));
    }
}

