export const useForumTableSearch = (
  setNameSearch: (value: React.SetStateAction<string | undefined>) => void,
  setCodeSearch: (value: React.SetStateAction<string | undefined>) => void,
  setCountryCodeSearch: (value: React.SetStateAction<string | undefined>) => void,
) => {
  const searchFunc = (selectedKeys: string[], confirm: () => void, _: string, searchSet: string) => {
    switch (searchSet) {
      case "name": {
        setNameSearch(selectedKeys[0]);
        break;
      }
      case "code": {
        setCodeSearch(selectedKeys[0]);
        break;
      }
      case "countryCode": {
        setCountryCodeSearch(selectedKeys[0]);
        break;
      }
    }
    confirm();
  };

  const resetFunc = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "name": {
        setNameSearch(undefined);
        break;
      }
      case "code": {
        setCodeSearch(undefined);
        break;
      }
      case "countryCode": {
        setCountryCodeSearch(undefined);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  return { searchFunc, resetFunc };
};
