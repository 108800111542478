import { Space } from "antd";
import React, { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import { DefaultSortOptions } from "../constants";
import PeopleTypeSelectOption from "../OptionTypes/PeopleTypeSelectOption";
import SortOrderOption from "../OptionTypes/SortByOption";
import WithFullReasonSelectOption from "../OptionTypes/WithFullReasonSelectOption";
import WithJointSelectOption from "../OptionTypes/WithJointSelectOption";
import WithNegativesSelectOption from "../OptionTypes/WithNegativesSelectOption";
import WithReasonSelectOption from "../OptionTypes/WithReasonSelectOption";

const PeopleTypePreferencesOptions = () => {
  const [orderBy, setOrderBy] = useState(DefaultSortOptions.Surname);
  const [isWithJoint, setIsWithJoint] = useState(false);
  const [isWithReason, setIsWithReason] = useState(false);
  const [isWithFullReason, setIsWithFullReason] = useState(false);
  const [isWithNegatives, setIsWithNegatives] = useState(false);
  const [peopleTypes, setPeopleTypes] = useState<string[]>([]);

  return (
    <Space wrap align={"end"}>
      <SortOrderOption orderBy={orderBy} setOrderBy={setOrderBy} options={DefaultSortOptions} />
      <WithJointSelectOption isWithJoint={isWithJoint} setIsWithJoint={setIsWithJoint} />
      <WithNegativesSelectOption isWithNegatives={isWithNegatives} setIsWithNegatives={setIsWithNegatives} />
      <WithReasonSelectOption isWithReason={isWithReason} setIsWithReason={setIsWithReason} />
      <WithFullReasonSelectOption isWithFullReason={isWithFullReason} setIsWithFullReason={setIsWithFullReason} />

      <Space align={"end"}>
        <PeopleTypeSelectOption peopleTypes={peopleTypes} setPeopleTypes={setPeopleTypes} />
        <LinkToReport
          disabled={!peopleTypes.length}
          parameters={{
            ParticipationCode: peopleTypes.join(","),
            WithJoint: isWithJoint,
            WithReason: isWithReason,
            WithFullReason: isWithFullReason,
            OrderBy: orderBy,
            WithNegatives: isWithNegatives,
          }}
        />
      </Space>
    </Space>
  );
};

export default PeopleTypePreferencesOptions;
