import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useCreateGroupMutation = getWrappedUseMutation(apiClient.ForumGroups.post);

export const useUpdateGroupMutation = getWrappedUseMutation(apiClient.ForumGroups.put);

export const useAddParticipationCodeMutation = getWrappedUseMutation(apiClient.ForumGroups.codes.post);

export const useRemoveParticipationCodeMutation = getWrappedUseMutation(apiClient.ForumGroups.codes.delete);

export const useUpdateGroupFlagsMutation = getWrappedUseMutation(apiClient.ForumGroups.flags.put);
