import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Form } from "antd";
import { useMoveAttendeeItineraryMutation, useMoveTestAttendeeItineraryMutation } from "api/mutations/attendees";
import { useSeminarsSessionsQuery } from "api/queries/forumSeminarSessions";
import { useForumVenueTimeslotSessionsQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import DateRenderer from "components/DateRenderer";
import { Button, Modal, Select, Spin } from "components/styleguide";
import {
  AttendeeItineraryChangeModel,
  AttendeeItineraryValidationResult,
  AvailableTimeSlotSessionModel,
  ForumSeminarSessionWithTimeslotSession,
  TimeSlotSessionResponseModel,
  TimeSlotType,
} from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

interface Timeslot {
  id?: number;
  description?: string;
  sessions: Array<TimeSlotSessionResponseModel>;
}

interface SeminarSessionsMoveTimingProps {
  itineraryId: number;
  facilityId: number;
  seminarId: number;
  isSpeaker: boolean;
  timeSlotType: TimeSlotType;
  onClose: () => void;
}

const SeminarSessionsMoveTiming: FC<SeminarSessionsMoveTimingProps> = ({
  itineraryId,
  facilityId,
  seminarId,
  isSpeaker,
  timeSlotType,
  onClose,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const forumId = toNumber(useParams().id);
  const attendeeId = toNumber(useParams().attendeeId);

  const { mutate: moveMutate, isLoading: isMoveLoading } = useMoveAttendeeItineraryMutation();
  const { mutate: validateMutate, isLoading: isValidateLoading } = useMoveTestAttendeeItineraryMutation();

  const [timeslots, setTimeslots] = useState<Timeslot[]>([]);

  const getTimeslotsFromSessions = (
    sessions: Array<AvailableTimeSlotSessionModel | ForumSeminarSessionWithTimeslotSession>,
  ) => {
    const timeslotsArr: Array<Timeslot> = [];

    sessions.forEach(({ id, startTime, endTime, timeSlotId, timeSlotDescription }) => {
      const timeslot = timeslotsArr.find(({ description }) => description === timeSlotDescription);
      const timeslotSession = { id, startTime, endTime };

      if (!timeslot) {
        timeslotsArr.push({
          id: timeSlotId,
          description: timeSlotDescription,
          sessions: [timeslotSession],
        });
      } else {
        timeslot.sessions.push(timeslotSession);
      }
    });

    return timeslotsArr;
  };

  const { isLoading: isAvailableTimeslotSessionsLoading } = useForumVenueTimeslotSessionsQuery(
    {
      forumId,
      facilityId,
      timeSlotType,
    },
    {
      onError: () => toast.error(t("Something went wrong")),
      onSuccess: ({ data: sessions }) => {
        if (isSpeaker) {
          setTimeslots(getTimeslotsFromSessions(sessions));
        }
      },
      enabled: isSpeaker,
    },
  );

  const { isLoading: isSeminarTimeslotSessionsLoading } = useSeminarsSessionsQuery(
    {
      forumId,
      forumSeminarId: seminarId,
    },
    {
      onError: () => toast.error(t("Something went wrong")),
      onSuccess: ({ data: sessions }) => {
        if (!isSpeaker) {
          setTimeslots(getTimeslotsFromSessions(sessions));
        }
      },
      enabled: !isSpeaker,
    },
  );

  const timeslotSessionsOptions = useMemo(() => {
    return timeslots.map(({ description, sessions }) => ({
      label: description,
      options: sessions.map(({ id, startTime, endTime }) => ({
        value: id,
        label: (
          <>
            <DateRenderer date={startTime as string} showTime />
            {" - "}
            <DateRenderer date={endTime as string} showTime />
          </>
        ),
      })),
    }));
  }, [timeslots]);

  const getTimeslotId = (timeslotSessionId: number) => {
    return timeslots.find(({ sessions }) => {
      return sessions.find(({ id }) => id === timeslotSessionId);
    })?.id;
  };

  const getAttendeeSpeakerItineraryChangeModel = (timeslotSessionId: number, numberOfAttendeesAvailable: number) => ({
    timeSlotId: getTimeslotId(timeslotSessionId),
    timeSlotSessionId: timeslotSessionId,
    selectedAttendeesCount: numberOfAttendeesAvailable,
  });

  const getAttendeeNotSpeakerItineraryChangeModel = (timeslotSessionId: number) => ({
    forumSeminarSessionId: timeslotSessionId,
  });

  const getAttendeeItineraryChangeModel = (
    timeslotSessionId: number,
    numberOfAttendeesAvailable?: number,
  ): AttendeeItineraryChangeModel => {
    return isSpeaker
      ? getAttendeeSpeakerItineraryChangeModel(timeslotSessionId, toNumber(numberOfAttendeesAvailable))
      : getAttendeeNotSpeakerItineraryChangeModel(timeslotSessionId);
  };

  const move = (timeslotSessionId: number, numberOfAttendeesAvailable?: number) => {
    moveMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: getAttendeeItineraryChangeModel(timeslotSessionId, numberOfAttendeesAvailable),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_ITINERARY]);
          onClose();
          toast.success(
            t(isSpeaker ? "The session was moved to a new time" : "The attendee was moved to a new session"),
          );
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const showAttendeesAvailableMoveModal = (
    timeslotSessionId: number,
    numberOfAttendeesAvailable: number,
    numberOfAttendeesUnavailable: number,
  ) => {
    Modal.confirm({
      title: t("Move"),
      content: t(
        "{{numberOfAttendeesAvailable}} attendees available for this time, {{numberOfAttendeesUnavailable}} attendees are unavailable. Do you still want to move the session?",
        { numberOfAttendeesAvailable, numberOfAttendeesUnavailable },
      ),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("No"),
      okText: t("Yes"),
      onOk: () => move(timeslotSessionId, numberOfAttendeesAvailable),
    });
  };

  const getNotAvailableAttendeesErrorMessage = (notAvailableAttendees: Array<string>) => {
    if (notAvailableAttendees.length > 1) {
      return t("{{attendees}} are not available for a new session", {
        attendees: notAvailableAttendees.join(", "),
      });
    }

    return t("{{attendee}} is not available for a new session", { attendee: notAvailableAttendees[0] });
  };

  const moveSpeaker = (
    timeslotSessionId: number,
    numberOfAttendeesAvailable: number,
    numberOfTotalAttendees: number,
  ) => {
    const numberOfAttendeesUnavailable = numberOfTotalAttendees - numberOfAttendeesAvailable;

    if (!numberOfAttendeesUnavailable) {
      move(timeslotSessionId, numberOfAttendeesAvailable);
    } else {
      showAttendeesAvailableMoveModal(timeslotSessionId, numberOfAttendeesAvailable, numberOfAttendeesUnavailable);
    }
  };

  const onValidateSuccess = (timeslotSessionId: number, data: AttendeeItineraryValidationResult) => {
    if (data.isValid) {
      if (isSpeaker) {
        moveSpeaker(timeslotSessionId, toNumber(data.availableAttendees), toNumber(data.totalAttendees));
      } else {
        move(timeslotSessionId);
      }
    } else {
      if (data?.notAvailableAttendees && data?.notAvailableAttendees.length) {
        toast.error(getNotAvailableAttendeesErrorMessage(data.notAvailableAttendees));
      } else {
        toast.error(t(data?.message ?? "Something went wrong"));
      }
    }
  };

  const validate = (timeslotSessionId: number) => {
    validateMutate(
      {
        forumId,
        attendeeId,
        itineraryId,
        attendeeItineraryChangeModel: getAttendeeItineraryChangeModel(timeslotSessionId),
      },
      {
        onSuccess: ({ data }: { data: AttendeeItineraryValidationResult }) =>
          onValidateSuccess(timeslotSessionId, data),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onSubmit = ({ id }: { id: number }) => validate(id);

  if ((isSpeaker && isAvailableTimeslotSessionsLoading) || (!isSpeaker && isSeminarTimeslotSessionsLoading)) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  if (!timeslots.length) {
    return <>{t("There are no available timeslot sessions at this time")}</>;
  }

  return (
    <Form form={form} onFinish={onSubmit}>
      <Form.Item
        label={t(isSpeaker ? "Choose a Conference Timeslot session" : "Choose a Conference session")}
        name="id"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: t("Conference Timeslot session") }),
          },
        ]}
      >
        <Select
          style={{ width: "350px" }}
          options={timeslotSessionsOptions}
          disabled={isValidateLoading || isMoveLoading}
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        loading={isValidateLoading || isMoveLoading}
        disabled={isValidateLoading || isMoveLoading}
      >
        {t("Move")}
      </Button>
    </Form>
  );
};

export default SeminarSessionsMoveTiming;
