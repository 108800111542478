/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumGroupTaskCreateModel } from '../apiModels';
// @ts-ignore
import { ForumGroupTaskEditModel } from '../apiModels';
// @ts-ignore
import { ForumGroupTaskModel } from '../apiModels';
// @ts-ignore
import { ForumGroupTaskStatus } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeTaskStatusModel } from '../apiModels';
/**
 * ForumGroupTasksApi - axios parameter creator
 * @export
 */
export const ForumGroupTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export forum group tasks
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksAttendeesExportGet: async (forumId: number, groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksAttendeesExportGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksAttendeesExportGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/tasks/attendees/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of tasks of forum group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksGet: async (forumId: number, groupId: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/tasks`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create task for group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {ForumGroupTaskCreateModel} [forumGroupTaskCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksPost: async (forumId: number, groupId: number, forumGroupTaskCreateModel?: ForumGroupTaskCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksPost', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksPost', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/tasks`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumGroupTaskCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} taskId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [company] 
         * @param {Array<ForumGroupTaskStatus>} [statuses] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet: async (forumId: number, groupId: number, taskId: number, firstName?: string, lastName?: string, company?: string, statuses?: Array<ForumGroupTaskStatus>, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet', 'groupId', groupId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet', 'taskId', taskId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/tasks/{taskId}/attendees`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (firstName !== undefined) {
                localVarQueryParameter['FirstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['LastName'] = lastName;
            }

            if (company !== undefined) {
                localVarQueryParameter['Company'] = company;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a forum group task
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} taskId 
         * @param {ForumGroupTaskEditModel} [forumGroupTaskEditModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksTaskIdPut: async (forumId: number, groupId: number, taskId: number, forumGroupTaskEditModel?: ForumGroupTaskEditModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksTaskIdPut', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksTaskIdPut', 'groupId', groupId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdTasksTaskIdPut', 'taskId', taskId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/tasks/{taskId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumGroupTaskEditModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumGroupTasksApi - functional programming interface
 * @export
 */
export const ForumGroupTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumGroupTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export forum group tasks
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdTasksAttendeesExportGet(forumId: number, groupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdTasksAttendeesExportGet(forumId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of tasks of forum group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdTasksGet(forumId: number, groupId: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumGroupTaskModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdTasksGet(forumId, groupId, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create task for group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {ForumGroupTaskCreateModel} [forumGroupTaskCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdTasksPost(forumId: number, groupId: number, forumGroupTaskCreateModel?: ForumGroupTaskCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumGroupTaskModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdTasksPost(forumId, groupId, forumGroupTaskCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} taskId 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [company] 
         * @param {Array<ForumGroupTaskStatus>} [statuses] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet(forumId: number, groupId: number, taskId: number, firstName?: string, lastName?: string, company?: string, statuses?: Array<ForumGroupTaskStatus>, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeTaskStatusModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet(forumId, groupId, taskId, firstName, lastName, company, statuses, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Edit a forum group task
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} taskId 
         * @param {ForumGroupTaskEditModel} [forumGroupTaskEditModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdTasksTaskIdPut(forumId: number, groupId: number, taskId: number, forumGroupTaskEditModel?: ForumGroupTaskEditModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdTasksTaskIdPut(forumId, groupId, taskId, forumGroupTaskEditModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumGroupTasksApi - factory interface
 * @export
 */
export const ForumGroupTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumGroupTasksApiFp(configuration)
    return {
        /**
         * 
         * @summary Export forum group tasks
         * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksAttendeesExportGet(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdGroupsGroupIdTasksAttendeesExportGet(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of tasks of forum group
         * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksGet(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumGroupTaskModel>> {
            return localVarFp.forumForumIdGroupsGroupIdTasksGet(requestParameters.forumId, requestParameters.groupId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create task for group
         * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksPost(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumGroupTaskModel> {
            return localVarFp.forumForumIdGroupsGroupIdTasksPost(requestParameters.forumId, requestParameters.groupId, requestParameters.forumGroupTaskCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeTaskStatusModel> {
            return localVarFp.forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.taskId, requestParameters.firstName, requestParameters.lastName, requestParameters.company, requestParameters.statuses, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a forum group task
         * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdTasksTaskIdPut(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdTasksTaskIdPut(requestParameters.forumId, requestParameters.groupId, requestParameters.taskId, requestParameters.forumGroupTaskEditModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdGroupsGroupIdTasksAttendeesExportGet operation in ForumGroupTasksApi.
 * @export
 * @interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGetRequest
 */
export interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGet
     */
    readonly groupId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdTasksGet operation in ForumGroupTasksApi.
 * @export
 * @interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGetRequest
 */
export interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGet
     */
    readonly groupId: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdGroupsGroupIdTasksPost operation in ForumGroupTasksApi.
 * @export
 * @interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPostRequest
 */
export interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPost
     */
    readonly groupId: number

    /**
     * 
     * @type {ForumGroupTaskCreateModel}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPost
     */
    readonly forumGroupTaskCreateModel?: ForumGroupTaskCreateModel
}

/**
 * Request parameters for forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet operation in ForumGroupTasksApi.
 * @export
 * @interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGetRequest
 */
export interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly groupId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly taskId: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly company?: string

    /**
     * 
     * @type {Array<ForumGroupTaskStatus>}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly statuses?: Array<ForumGroupTaskStatus>

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdGroupsGroupIdTasksTaskIdPut operation in ForumGroupTasksApi.
 * @export
 * @interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPutRequest
 */
export interface ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPut
     */
    readonly groupId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPut
     */
    readonly taskId: number

    /**
     * 
     * @type {ForumGroupTaskEditModel}
     * @memberof ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPut
     */
    readonly forumGroupTaskEditModel?: ForumGroupTaskEditModel
}

/**
 * ForumGroupTasksApi - object-oriented interface
 * @export
 * @class ForumGroupTasksApi
 * @extends {BaseAPI}
 */
export class ForumGroupTasksApi extends BaseAPI {
    /**
     * 
     * @summary Export forum group tasks
     * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupTasksApi
     */
    public forumForumIdGroupsGroupIdTasksAttendeesExportGet(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksAttendeesExportGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupTasksApiFp(this.configuration).forumForumIdGroupsGroupIdTasksAttendeesExportGet(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of tasks of forum group
     * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupTasksApi
     */
    public forumForumIdGroupsGroupIdTasksGet(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupTasksApiFp(this.configuration).forumForumIdGroupsGroupIdTasksGet(requestParameters.forumId, requestParameters.groupId, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create task for group
     * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupTasksApi
     */
    public forumForumIdGroupsGroupIdTasksPost(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksPostRequest, options?: AxiosRequestConfig) {
        return ForumGroupTasksApiFp(this.configuration).forumForumIdGroupsGroupIdTasksPost(requestParameters.forumId, requestParameters.groupId, requestParameters.forumGroupTaskCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupTasksApi
     */
    public forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdAttendeesGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupTasksApiFp(this.configuration).forumForumIdGroupsGroupIdTasksTaskIdAttendeesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.taskId, requestParameters.firstName, requestParameters.lastName, requestParameters.company, requestParameters.statuses, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a forum group task
     * @param {ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupTasksApi
     */
    public forumForumIdGroupsGroupIdTasksTaskIdPut(requestParameters: ForumGroupTasksApiForumForumIdGroupsGroupIdTasksTaskIdPutRequest, options?: AxiosRequestConfig) {
        return ForumGroupTasksApiFp(this.configuration).forumForumIdGroupsGroupIdTasksTaskIdPut(requestParameters.forumId, requestParameters.groupId, requestParameters.taskId, requestParameters.forumGroupTaskEditModel, options).then((request) => request(this.axios, this.basePath));
    }
}

