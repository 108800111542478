import { useQueryClient } from "@tanstack/react-query";
import { useUpdateSeminarSessionMutation } from "api/mutations/forumSeminarSessions";
import { useSeminarsSessionsQuery } from "api/queries/forumSeminarSessions";
import KEYS from "api/queries/keys";
import { Switch } from "components/styleguide";
import Table from "components/styleguide/components/Table";
import { ForumSeminarSessionWithTimeslotSession } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";

interface SeminarDetailsTabProps {
  seminar: ForumSeminarSessionWithTimeslotSession;
}

const dateFormat = "DD/MM/YYYY HH:mm";

const SeminarDetailsTab = ({ seminar }: SeminarDetailsTabProps) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const updateMutation = useUpdateSeminarSessionMutation();

  const { data: { data: sessions = [] } = {}, isLoading } = useSeminarsSessionsQuery(
    {
      forumId,
      forumSeminarId: seminar?.id,
    },
    { enabled: !!seminar?.id },
  );

  const updateSeminarSession = (session: ForumSeminarSessionWithTimeslotSession) => {
    if (!updateMutation.isLoading) {
      updateMutation.mutateAsync(
        {
          forumId,
          forumSeminarId: seminar?.id,
          forumSeminarSessionId: session.id,
          forumSeminarSessionRequestModel: session,
        },
        {
          onError: () => {
            toast.error(t("Something went wrong"));
          },
          onSettled: () => {
            queryClient.invalidateQueries([KEYS.GET_SEMINAR_SESSIONS]);
          },
        },
      );
    }
  };

  const columns = [
    buildColumn(t("Session Start and End Time"), "startTime", {
      render: (_: string, { startTime, endTime }: ForumSeminarSessionWithTimeslotSession) =>
        `${moment(startTime).format(dateFormat)} - ${moment(endTime).format(dateFormat)}`,
    }),
    buildColumn(t("Speaker"), "speaker"),
    buildColumn(t("Display on Customer Portal"), "isDisplayedOnCustomerPortal", {
      ellipsis: true,
      render: (value: boolean, record: ForumSeminarSessionWithTimeslotSession) => (
        <Switch
          checked={value}
          onChange={() => updateSeminarSession({ ...record, isDisplayedOnCustomerPortal: !value })}
        />
      ),
    }),
  ];

  return (
    <Table
      id="seminarSessionsTable"
      className="seminarSessionsTable"
      dataSource={sessions}
      columns={columns}
      bordered
      loading={isLoading || updateMutation.isLoading}
      rowKey="id"
    />
  );
};

export default SeminarDetailsTab;
