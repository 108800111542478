import { Rule } from "antd/lib/form";
import ContextualDateRenderer from "components/ContextualDateRenderer";
import { DatePicker, Form } from "components/styleguide";
import { TimeSlotSessionResponseModel } from "generated/api";
import moment, { Moment } from "moment";
import React from "react";
import { fakeTimeOffset } from "utils/dateUtils";

const { RangePicker } = DatePicker;

export default function SessionCell({
  dateTimeFormat,
  disabledDate,
  forumOffset,
  editing,
  yupSync,
  index,
  title,
  type,
  record,
  children,
  ...restProps
}: {
  dateTimeFormat: string;
  disabledDate: (date: Moment) => boolean;
  forumOffset: number;
  editing: boolean;
  yupSync: Rule;
  index: string;
  title: string;
  type: string;
  record: TimeSlotSessionResponseModel;
  children: React.ReactNode[];
}) {
  const isStartTime = index === "startTime";
  const isEndTime = index === "endTime";
  if (editing) {
    if (isStartTime) {
      return (
        <td {...restProps} colSpan={2}>
          <Form.Item
            name="duration"
            rules={[yupSync]}
            initialValue={[
              record?.startTime ? moment(fakeTimeOffset(record?.startTime, forumOffset)) : null,
              record?.endTime ? moment(fakeTimeOffset(record?.endTime, forumOffset)) : null,
            ]}
          >
            <RangePicker
              style={{ width: "100%" }}
              showTime={true}
              format={dateTimeFormat}
              disabledDate={disabledDate}
              minuteStep={5}
            />
          </Form.Item>
        </td>
      );
    } else if (isEndTime) {
      return <></>;
    }
  }

  return (
    <td {...restProps}>
      {(isStartTime || isEndTime) && children ? (
        <ContextualDateRenderer date={children[1] as string} showTime></ContextualDateRenderer>
      ) : (
        children
      )}
    </td>
  );
}
