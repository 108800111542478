import { v4 as uuid } from "uuid";

import {
  QuestionnaireContentPage,
  QuestionnaireContentQuestion,
  QuestionOption,
  QuestionQuestionType,
} from "../generated/api";
import { insertAt, removeAt, replaceAt, swap } from "./arrayUtils";
import { getNumberInRange } from "./numberUtils";
import QuestionType from "./questionTypeUtils";

type Page = QuestionnaireContentPage;
type Pages = Array<Page>;
type Question = QuestionnaireContentQuestion;
type Questions = Array<Question>;

const PREFIX = "questionnaire";

const NAV_PREFIX = `${PREFIX}_nav_`;
const PAGE_PREFIX = `${PREFIX}_page_`;
const QUESTION_PREFIX = `${PREFIX}_question_`;

export const generateNavId = (id: string) => {
  return `${NAV_PREFIX}${id}`;
};

export const getIdFromNavId = (navId: string) => {
  return navId.slice(NAV_PREFIX.length);
};

export const generatePageId = (pageIndex: number) => {
  return `${PAGE_PREFIX}${pageIndex}`;
};

export const isPageId = (id: string) => {
  return id.startsWith(PAGE_PREFIX);
};

export const getPageIndexFromId = (id: string) => {
  if (!isPageId(id)) {
    return null;
  }

  const indexString = id.slice(PAGE_PREFIX.length);
  return parseInt(indexString);
};

export const generateQuestionId = (pageIndex: number, questionIndex: number) => {
  return `${QUESTION_PREFIX}${pageIndex}_${questionIndex}`;
};

export const isQuestionId = (id: string) => {
  return id.startsWith(QUESTION_PREFIX);
};

export const getQuestionIndexFromId = (id: string): [number, number] | null => {
  if (!isQuestionId(id)) {
    return null;
  }

  const pageAndQuestionIndexes = id.slice(QUESTION_PREFIX.length);
  const [page, question] = pageAndQuestionIndexes.split("_").map((str) => parseInt(str));
  return [page, question];
};

export const appendPage = (pages: Pages): Pages => {
  return [...pages, { questions: [] }];
};

export const appendQuestion = (pages: Pages, pageIndex: number, optionText: string): Pages => {
  const questions = pages[pageIndex].questions ?? [];

  const newQuestions = [
    ...questions,
    {
      text: `Question ${questions.length + 1}`,
      type: "FreeText",
      options: [{ text: optionText, type: "LargePlainTextbox" }],
    } as QuestionnaireContentQuestion,
  ];

  return replaceAt(pages, pageIndex, { questions: newQuestions });
};

export const getPrevPageIndex = (pages: Pages, currentIndex: number) => {
  return getNumberInRange(currentIndex - 1, 0, pages.length - 1);
};

export const getNextPageIndex = (pages: Pages, currentIndex: number) => {
  return getNumberInRange(currentIndex + 1, 0, pages.length - 1);
};

export const getPrevQuestionIndex = (
  pages: Pages,
  currentPageIndex: number,
  currentQuestionIndex: number,
): [number, number] => {
  const questions = pages[currentPageIndex]?.questions ?? [];
  const count = questions.length;

  // Something strange happens when we're trying to move question
  // outside page which have no questions
  if (count === 0) {
    return [currentPageIndex, currentQuestionIndex];
  }

  // In that case we have to move question to prev page
  if (currentQuestionIndex === 0) {
    const prevPageIndex = getPrevPageIndex(pages, currentPageIndex);

    // Do nothing when there is no prev page to move
    if (prevPageIndex === currentPageIndex) {
      return [currentPageIndex, currentQuestionIndex];
    }

    const prevQuestions = pages[prevPageIndex]?.questions ?? [];
    const newIndex = prevQuestions.length;

    return [prevPageIndex, newIndex];
  }

  const prevQuestionIndex = getNumberInRange(currentQuestionIndex - 1, 0, count - 1);
  return [currentPageIndex, prevQuestionIndex];
};

export const getNextQuestionIndex = (
  pages: Pages,
  currentPageIndex: number,
  currentQuestionIndex: number,
): [number, number] => {
  const questions = pages[currentPageIndex]?.questions ?? [];
  const count = questions.length;

  // Something strange happens when we're trying to move question
  // outside page which have no questions
  if (count === 0) {
    return [currentPageIndex, currentQuestionIndex];
  }

  // In case when the question is at the end of the list, or it is just 1 on the list
  // We have to move it to the next page
  if (currentQuestionIndex >= count - 1) {
    const nextPageIndex = getNextPageIndex(pages, currentPageIndex);

    // Do nothing when there is no next page index to move
    if (nextPageIndex === currentPageIndex) {
      return [currentPageIndex, currentQuestionIndex];
    }

    return [nextPageIndex, 0];
  }

  const nextQuestionIndex = getNumberInRange(currentQuestionIndex + 1, 0, count - 1);
  return [currentPageIndex, nextQuestionIndex];
};

export const movePage = (pages: Pages, from: number, to: number): Pages => {
  const minIndex = 0;
  const maxIndex = pages.length - 1;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _to = getNumberInRange(to, minIndex, maxIndex);

  if (from === _to) {
    return pages;
  }

  return swap(pages, from, _to);
};

export const moveQuestion = (
  pages: Pages,
  [fromPageIndex, fromQuestionIndex]: [number, number],
  [toPageIndex, toQuestionIndex]: [number, number],
): Pages => {
  const questions = pages[fromPageIndex]?.questions ?? [];

  if (fromPageIndex === toPageIndex) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _from = getNumberInRange(fromQuestionIndex, 0, questions.length - 1);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _to = getNumberInRange(toQuestionIndex, 0, questions.length - 1);

    const newQuestions = swap(questions, _from, _to);
    return replaceAt(pages, fromPageIndex, { questions: newQuestions });
  }

  const nextQuestions = pages[toPageIndex]?.questions ?? [];

  const questionToMove = questions[fromQuestionIndex];

  const newFromQuestions = removeAt(questions, fromQuestionIndex);
  const newToQuestions = insertAt(nextQuestions, toQuestionIndex, questionToMove);

  const afterRemoving = replaceAt(pages, fromPageIndex, { questions: newFromQuestions });
  // noinspection UnnecessaryLocalVariableJS
  const afterAppending = replaceAt(afterRemoving, toPageIndex, { questions: newToQuestions });

  return afterAppending;
};

export const removePage = (pages: Pages, pageIndex: number): Pages => {
  return removeAt(pages, pageIndex);
};

export const removeQuestion = (pages: Pages, pageIndex: number, questionIndex: number): Pages => {
  const questions = pages[pageIndex]?.questions ?? [];
  const newQuestions = removeAt(questions, questionIndex);
  return replaceAt(pages, pageIndex, { questions: newQuestions });
};

export const collectQuestionsByIndexes = (pages: Pages, questionIndexes: Array<[number, number]>) => {
  return (
    questionIndexes
      // group questions into pages
      .reduce<Array<Array<number>>>((accumulator, [pageIndex, questionIndex]) => {
        if (!Array.isArray(accumulator[pageIndex])) {
          accumulator[pageIndex] = [];
        }

        accumulator[pageIndex].push(questionIndex);
        return accumulator;
      }, [])
      // sort questions' indexes on the page
      .map((indexes) => {
        // array could be filled with empty values in previous step,
        // when for example just questions for 3rd page was selected
        if (!Array.isArray(indexes)) {
          return;
        }

        return [...indexes].sort((a, b) => (a > b ? 0 : -1));
      })
      // replace questions' indexes with actual questions from pages
      // and change the structure from `Array<number>` to `{ questions: Array<Question> }`
      .map((indexes, pageIndex) => {
        const questions = indexes?.map((index) => pages[pageIndex].questions?.[index]);
        return { questions };
      })
      // remove empty values from array
      .filter(Boolean)
  );
};

type MakeIdNullable<T extends { id: unknown }> = Omit<T, "id"> & { id?: T["id"] | undefined };
type QuestionWithoutId = MakeIdNullable<Question> | { options?: Array<MakeIdNullable<QuestionOption>> };
export const removeQuestionsIds = (pages: Pages): Array<{ questions: Array<QuestionWithoutId> | undefined }> => {
  return pages.map((page) => {
    return {
      ...page,
      questions: page.questions?.map<QuestionWithoutId>((question) => ({
        ...question,
        id: undefined,
        options: question.options?.map((option) => ({
          ...option,
          id: undefined,
        })),
      })),
    };
  });
};

export const fillQuestionsWithIds = (pages: Pages): Pages => {
  return pages.map((page) => {
    const questions = page.questions?.map((question) => {
      return {
        ...question,
        id: question.id ?? uuid(),
        options: question.options?.map((option) => ({
          ...option,
          id: option.id ?? uuid(),
        })),
      };
    });

    return { questions };
  });
};

export const prepareQuestionForUpdate = (question: Question): Question => {
  const type = new QuestionType(question.type);

  const result = { ...question };

  if (!type.hasOptionsColumns) {
    delete result.optionsColumns;
  }

  if (!type.hasOptions) {
    delete result.options;
  }

  return result;
};

export const getQuestionsFromPages = (pages: Pages): Questions => {
  return pages.flatMap((page) => page.questions ?? []);
};

export const getQuestionById = (pages: Pages, id: string): Question | undefined => {
  return getQuestionsFromPages(pages).find((question) => question.id === id);
};

export const getQuestionOptionsById = (pages: Pages, id: string): Array<QuestionOption> => {
  const question = getQuestionById(pages, id);
  return question?.options ?? [];
};

export const filterQuestionsByType = (questions: Questions, types: Array<QuestionQuestionType>): Questions => {
  return questions.filter((question) => types.includes(question.type));
};
