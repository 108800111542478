import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useUpdateForumAttendeeSessionFeedbackMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeSessionFeedback.put,
);

export const useDeleteForumAttendeeSessionFeedbackMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeSessionFeedback.delete,
);
