import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useGetForumCompaniesQuery = getWrappedUseQuery(KEYS.GET_FORUM_COMPANIES, apiClient.ForumCompanies.getAll);

export const useGetForumCompanyQuery = getWrappedUseQuery(KEYS.GET_FORUM_COMPANY, apiClient.ForumCompanies.get);

export const useGetForumCompanyPackageQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_COMPANY_PACKAGE,
  apiClient.ForumCompanies.package.get,
);

export const useGetForumCompanyPrimaryLocationsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_COMPANY_PRIMARY_LOCATIONS,
  apiClient.ForumCompanies.primaryLocations.get,
);

export const useGetForumCompanyHistoryInformationQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_COMPANY_HISTORY_INFORMATION,
  apiClient.ForumCompanies.historyInformation.get,
);

export const useExportForumCompanyPackages = getWrappedUseQuery(
  KEYS.GET_FORUM_COMPANY_PACKAGE_EXPORT,
  apiClient.ForumCompanies.export.get,
);

export const useGetForumCompanyAttendeesQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_COMPANY_ATTENDEES,
  apiClient.ForumCompanies.attendees.get,
);

export const useForumCompaniesDescriptionsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_COMPANIES_DESCRIPTIONS,
  apiClient.ForumCompanies.description.getAll,
);
