import { useGetCompaniesQuery } from "api/queries/companies";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Space, Table } from "components/styleguide";
import { CompanyResponseModel } from "generated/api";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import { useModernQueryWithPaginationAndOrder } from "../../../hooks";
import CompanyAttendees from "../CompanyAttendees";

import styles from "./styles.module.less";

const ChooseCompanyDrawer = ({
  onClose,
  visible,
  submitDisabled,
  onSubmit,
  loadingOnSubmit,
  onAddNewClick,
}: {
  onClose: () => void;
  visible: boolean;
  submitDisabled: boolean;
  onSubmit: () => void;
  loadingOnSubmit: boolean;
  onAddNewClick: () => void;
}) => {
  const { t } = useTranslation();
  const [isCompanyAttendeesVisible, setIsCompanyAttendeesVisible] = useState(false);
  const [companyId, setCompanyId] = useState<number | null>(null);
  const [countrySearch, setCountrySearch] = useState<string | undefined>();
  const [nameSearch, setNameSearch] = useState<string | undefined>();

  const showCompanyAttendees = (id: number) => {
    setCompanyId(id);
    return setIsCompanyAttendeesVisible(true);
  };

  const hideCompanyAttendees = () => {
    setCompanyId(null);
    return setIsCompanyAttendeesVisible(false);
  };

  const {
    response: { isLoading: isLoadingCompanies, data: { data: { items: companies = [] } = {} } = {} },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetCompaniesQuery,
    {},
    { name: nameSearch, country: countrySearch },
    { defaultPageSize: 10, pageSizeOptions: [10, 20] },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    switch (dataIndex) {
      case "country": {
        setCountrySearch(selectedKeys[0]);
        break;
      }
      case "name": {
        setNameSearch(selectedKeys[0]);
        break;
      }
    }
    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, _: unknown, confirm: () => void) => {
    switch (dataIndex) {
      case "country": {
        setCountrySearch(undefined);
        break;
      }
      case "name": {
        setNameSearch(undefined);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  const columns = [
    {
      title: nameSearch ? <span className={styles.filteredTitle}>{nameSearch}</span> : t("companyDrawer.name"),
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (name: string, record: CompanyResponseModel) => (
        <Link
          className={styles.link}
          to={"#"}
          onClick={() => {
            if (record.id) {
              showCompanyAttendees(record.id);
            }
          }}
        >
          {name}
        </Link>
      ),
      ...getColumnSearchProps("name", handleSearch, handleReset, "name"),
    },
    {
      title: countrySearch ? <span className={styles.filteredTitle}>{countrySearch}</span> : t("Country"),
      dataIndex: "country",
      key: "country",
      sorter: true,
      ...getColumnSearchProps("country", handleSearch, handleReset, "country"),
    },
    {
      title: t("Company Address"),
      dataIndex: "address",
      key: "address",
      sorter: true,
    },
  ];

  return (
    <>
      <Drawer
        title={t("Choose Company")}
        placement="right"
        onClose={onClose}
        open={visible}
        contentWrapperStyle={{ minWidth: "50%" }}
        getContainer={false}
      >
        <CompanyAttendees visible={isCompanyAttendeesVisible} onClose={hideCompanyAttendees} companyId={companyId} />
        <FullHeightForm
          actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
          actionsAppend={
            <Button
              disabled={submitDisabled}
              onClick={onSubmit}
              type="primary"
              loading={loadingOnSubmit || isLoadingCompanies}
            >
              {t("companyDrawer.submit")}
            </Button>
          }
        >
          <Space direction={"vertical"} size={"middle"} fullWidth>
            <Button onClick={onAddNewClick}>{t("companyDrawer.addNewCompany")}</Button>
            <Table
              id="companies-table"
              className="companies-table"
              loading={isLoadingCompanies}
              dataSource={companies}
              columns={columns}
              pagination={pagination}
              bordered={true}
              rowKey="id"
              onChange={handleSort}
            />
          </Space>
        </FullHeightForm>
      </Drawer>
    </>
  );
};

export default ChooseCompanyDrawer;
