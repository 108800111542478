/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AvailableTimeSlotSessionModel } from '../apiModels';
// @ts-ignore
import { FacilityResponseModel } from '../apiModels';
// @ts-ignore
import { FacilityType } from '../apiModels';
// @ts-ignore
import { ForumFacilityTableModel } from '../apiModels';
// @ts-ignore
import { TableType } from '../apiModels';
// @ts-ignore
import { TimeSlotType } from '../apiModels';
// @ts-ignore
import { VenueDetailsModel } from '../apiModels';
// @ts-ignore
import { VenueRequestModel } from '../apiModels';
/**
 * ForumVenueApi - axios parameter creator
 * @export
 */
export const ForumVenueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * A timeslot session is available if there are no ForumSeminarSessions happening in the forum facility at that time
         * @summary Get available timeslot sessions for a forum facility
         * @param {number} forumId 
         * @param {number} facilityId 
         * @param {TimeSlotType} [timeSlotType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet: async (forumId: number, facilityId: number, timeSlotType?: TimeSlotType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet', 'forumId', forumId)
            // verify required parameter 'facilityId' is not null or undefined
            assertParamExists('forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet', 'facilityId', facilityId)
            const localVarPath = `/forums/{forumId}/venue/facilities/{facilityId}/available-timeslot-sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"facilityId"}}`, encodeURIComponent(String(facilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (timeSlotType !== undefined) {
                localVarQueryParameter['TimeSlotType'] = timeSlotType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the facilities of a forum\'s venue
         * @param {number} forumId 
         * @param {Array<FacilityType>} [types] 
         * @param {number} [availableForTimeslotSessionId] 
         * @param {boolean} [includeEmpty] 
         * @param {number} [minCapacityFacilityId] 
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueFacilitiesGet: async (forumId: number, types?: Array<FacilityType>, availableForTimeslotSessionId?: number, includeEmpty?: boolean, minCapacityFacilityId?: number, name?: string, code?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueFacilitiesGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/facilities`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (types) {
                localVarQueryParameter['Types'] = types;
            }

            if (availableForTimeslotSessionId !== undefined) {
                localVarQueryParameter['AvailableForTimeslotSessionId'] = availableForTimeslotSessionId;
            }

            if (includeEmpty !== undefined) {
                localVarQueryParameter['IncludeEmpty'] = includeEmpty;
            }

            if (minCapacityFacilityId !== undefined) {
                localVarQueryParameter['MinCapacityFacilityId'] = minCapacityFacilityId;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details about the venue where the forum takes place
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete forum venue map
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueMapDelete: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueMapDelete', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/map`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get forum venue map access URL
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueMapGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueMapGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/map`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload forum venue map
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueMapPut: async (forumId: number, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueMapPut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/map`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update details about the venue where the forum takes place
         * @param {number} forumId 
         * @param {VenueRequestModel} [venueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenuePut: async (forumId: number, venueRequestModel?: VenueRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenuePut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get tables by filter
         * @param {number} forumId 
         * @param {Array<FacilityType>} [facilityTypes] 
         * @param {Array<TableType>} [tableTypes] 
         * @param {string} [availableFrom] 
         * @param {string} [availableTo] 
         * @param {number} [availableForMeeting] ForumAttendeeItineraryItemIds
         * @param {boolean} [allAttendeeMeetings] All attendee meeting from AvailableForMeeting of given time type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueTablesGet: async (forumId: number, facilityTypes?: Array<FacilityType>, tableTypes?: Array<TableType>, availableFrom?: string, availableTo?: string, availableForMeeting?: number, allAttendeeMeetings?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueTablesGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/venue/tables`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (facilityTypes) {
                localVarQueryParameter['FacilityTypes'] = facilityTypes;
            }

            if (tableTypes) {
                localVarQueryParameter['TableTypes'] = tableTypes;
            }

            if (availableFrom !== undefined) {
                localVarQueryParameter['AvailableFrom'] = (availableFrom as any instanceof Date) ?
                    (availableFrom as any).toISOString() :
                    availableFrom;
            }

            if (availableTo !== undefined) {
                localVarQueryParameter['AvailableTo'] = (availableTo as any instanceof Date) ?
                    (availableTo as any).toISOString() :
                    availableTo;
            }

            if (availableForMeeting !== undefined) {
                localVarQueryParameter['AvailableForMeeting'] = availableForMeeting;
            }

            if (allAttendeeMeetings !== undefined) {
                localVarQueryParameter['AllAttendeeMeetings'] = allAttendeeMeetings;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumVenueApi - functional programming interface
 * @export
 */
export const ForumVenueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumVenueApiAxiosParamCreator(configuration)
    return {
        /**
         * A timeslot session is available if there are no ForumSeminarSessions happening in the forum facility at that time
         * @summary Get available timeslot sessions for a forum facility
         * @param {number} forumId 
         * @param {number} facilityId 
         * @param {TimeSlotType} [timeSlotType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet(forumId: number, facilityId: number, timeSlotType?: TimeSlotType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableTimeSlotSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet(forumId, facilityId, timeSlotType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the facilities of a forum\'s venue
         * @param {number} forumId 
         * @param {Array<FacilityType>} [types] 
         * @param {number} [availableForTimeslotSessionId] 
         * @param {boolean} [includeEmpty] 
         * @param {number} [minCapacityFacilityId] 
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueFacilitiesGet(forumId: number, types?: Array<FacilityType>, availableForTimeslotSessionId?: number, includeEmpty?: boolean, minCapacityFacilityId?: number, name?: string, code?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueFacilitiesGet(forumId, types, availableForTimeslotSessionId, includeEmpty, minCapacityFacilityId, name, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details about the venue where the forum takes place
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueDetailsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete forum venue map
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueMapDelete(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueMapDelete(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get forum venue map access URL
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueMapGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueMapGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload forum venue map
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueMapPut(forumId: number, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueMapPut(forumId, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update details about the venue where the forum takes place
         * @param {number} forumId 
         * @param {VenueRequestModel} [venueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenuePut(forumId: number, venueRequestModel?: VenueRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenuePut(forumId, venueRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get tables by filter
         * @param {number} forumId 
         * @param {Array<FacilityType>} [facilityTypes] 
         * @param {Array<TableType>} [tableTypes] 
         * @param {string} [availableFrom] 
         * @param {string} [availableTo] 
         * @param {number} [availableForMeeting] ForumAttendeeItineraryItemIds
         * @param {boolean} [allAttendeeMeetings] All attendee meeting from AvailableForMeeting of given time type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueTablesGet(forumId: number, facilityTypes?: Array<FacilityType>, tableTypes?: Array<TableType>, availableFrom?: string, availableTo?: string, availableForMeeting?: number, allAttendeeMeetings?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumFacilityTableModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueTablesGet(forumId, facilityTypes, tableTypes, availableFrom, availableTo, availableForMeeting, allAttendeeMeetings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumVenueApi - factory interface
 * @export
 */
export const ForumVenueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumVenueApiFp(configuration)
    return {
        /**
         * A timeslot session is available if there are no ForumSeminarSessions happening in the forum facility at that time
         * @summary Get available timeslot sessions for a forum facility
         * @param {ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet(requestParameters: ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AvailableTimeSlotSessionModel>> {
            return localVarFp.forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet(requestParameters.forumId, requestParameters.facilityId, requestParameters.timeSlotType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the facilities of a forum\'s venue
         * @param {ForumVenueApiForumsForumIdVenueFacilitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueFacilitiesGet(requestParameters: ForumVenueApiForumsForumIdVenueFacilitiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FacilityResponseModel>> {
            return localVarFp.forumsForumIdVenueFacilitiesGet(requestParameters.forumId, requestParameters.types, requestParameters.availableForTimeslotSessionId, requestParameters.includeEmpty, requestParameters.minCapacityFacilityId, requestParameters.name, requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details about the venue where the forum takes place
         * @param {ForumVenueApiForumsForumIdVenueGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueGet(requestParameters: ForumVenueApiForumsForumIdVenueGetRequest, options?: AxiosRequestConfig): AxiosPromise<VenueDetailsModel> {
            return localVarFp.forumsForumIdVenueGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete forum venue map
         * @param {ForumVenueApiForumsForumIdVenueMapDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueMapDelete(requestParameters: ForumVenueApiForumsForumIdVenueMapDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueMapDelete(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get forum venue map access URL
         * @param {ForumVenueApiForumsForumIdVenueMapGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueMapGet(requestParameters: ForumVenueApiForumsForumIdVenueMapGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueMapGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload forum venue map
         * @param {ForumVenueApiForumsForumIdVenueMapPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueMapPut(requestParameters: ForumVenueApiForumsForumIdVenueMapPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueMapPut(requestParameters.forumId, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update details about the venue where the forum takes place
         * @param {ForumVenueApiForumsForumIdVenuePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenuePut(requestParameters: ForumVenueApiForumsForumIdVenuePutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenuePut(requestParameters.forumId, requestParameters.venueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get tables by filter
         * @param {ForumVenueApiForumsForumIdVenueTablesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueTablesGet(requestParameters: ForumVenueApiForumsForumIdVenueTablesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumFacilityTableModel>> {
            return localVarFp.forumsForumIdVenueTablesGet(requestParameters.forumId, requestParameters.facilityTypes, requestParameters.tableTypes, requestParameters.availableFrom, requestParameters.availableTo, requestParameters.availableForMeeting, requestParameters.allAttendeeMeetings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGetRequest
 */
export interface ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet
     */
    readonly facilityId: number

    /**
     * 
     * @type {TimeSlotType}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet
     */
    readonly timeSlotType?: TimeSlotType
}

/**
 * Request parameters for forumsForumIdVenueFacilitiesGet operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueFacilitiesGetRequest
 */
export interface ForumVenueApiForumsForumIdVenueFacilitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {Array<FacilityType>}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly types?: Array<FacilityType>

    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly availableForTimeslotSessionId?: number

    /**
     * 
     * @type {boolean}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly includeEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly minCapacityFacilityId?: number

    /**
     * 
     * @type {string}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ForumVenueApiForumsForumIdVenueFacilitiesGet
     */
    readonly code?: string
}

/**
 * Request parameters for forumsForumIdVenueGet operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueGetRequest
 */
export interface ForumVenueApiForumsForumIdVenueGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdVenueMapDelete operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueMapDeleteRequest
 */
export interface ForumVenueApiForumsForumIdVenueMapDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueMapDelete
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdVenueMapGet operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueMapGetRequest
 */
export interface ForumVenueApiForumsForumIdVenueMapGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueMapGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdVenueMapPut operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueMapPutRequest
 */
export interface ForumVenueApiForumsForumIdVenueMapPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueMapPut
     */
    readonly forumId: number

    /**
     * 
     * @type {File}
     * @memberof ForumVenueApiForumsForumIdVenueMapPut
     */
    readonly formFile?: File
}

/**
 * Request parameters for forumsForumIdVenuePut operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenuePutRequest
 */
export interface ForumVenueApiForumsForumIdVenuePutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenuePut
     */
    readonly forumId: number

    /**
     * 
     * @type {VenueRequestModel}
     * @memberof ForumVenueApiForumsForumIdVenuePut
     */
    readonly venueRequestModel?: VenueRequestModel
}

/**
 * Request parameters for forumsForumIdVenueTablesGet operation in ForumVenueApi.
 * @export
 * @interface ForumVenueApiForumsForumIdVenueTablesGetRequest
 */
export interface ForumVenueApiForumsForumIdVenueTablesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {Array<FacilityType>}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly facilityTypes?: Array<FacilityType>

    /**
     * 
     * @type {Array<TableType>}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly tableTypes?: Array<TableType>

    /**
     * 
     * @type {string}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly availableFrom?: string

    /**
     * 
     * @type {string}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly availableTo?: string

    /**
     * ForumAttendeeItineraryItemIds
     * @type {number}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly availableForMeeting?: number

    /**
     * All attendee meeting from AvailableForMeeting of given time type
     * @type {boolean}
     * @memberof ForumVenueApiForumsForumIdVenueTablesGet
     */
    readonly allAttendeeMeetings?: boolean
}

/**
 * ForumVenueApi - object-oriented interface
 * @export
 * @class ForumVenueApi
 * @extends {BaseAPI}
 */
export class ForumVenueApi extends BaseAPI {
    /**
     * A timeslot session is available if there are no ForumSeminarSessions happening in the forum facility at that time
     * @summary Get available timeslot sessions for a forum facility
     * @param {ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet(requestParameters: ForumVenueApiForumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueFacilitiesFacilityIdAvailableTimeslotSessionsGet(requestParameters.forumId, requestParameters.facilityId, requestParameters.timeSlotType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the facilities of a forum\'s venue
     * @param {ForumVenueApiForumsForumIdVenueFacilitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueFacilitiesGet(requestParameters: ForumVenueApiForumsForumIdVenueFacilitiesGetRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueFacilitiesGet(requestParameters.forumId, requestParameters.types, requestParameters.availableForTimeslotSessionId, requestParameters.includeEmpty, requestParameters.minCapacityFacilityId, requestParameters.name, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details about the venue where the forum takes place
     * @param {ForumVenueApiForumsForumIdVenueGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueGet(requestParameters: ForumVenueApiForumsForumIdVenueGetRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete forum venue map
     * @param {ForumVenueApiForumsForumIdVenueMapDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueMapDelete(requestParameters: ForumVenueApiForumsForumIdVenueMapDeleteRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueMapDelete(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get forum venue map access URL
     * @param {ForumVenueApiForumsForumIdVenueMapGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueMapGet(requestParameters: ForumVenueApiForumsForumIdVenueMapGetRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueMapGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload forum venue map
     * @param {ForumVenueApiForumsForumIdVenueMapPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueMapPut(requestParameters: ForumVenueApiForumsForumIdVenueMapPutRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueMapPut(requestParameters.forumId, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update details about the venue where the forum takes place
     * @param {ForumVenueApiForumsForumIdVenuePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenuePut(requestParameters: ForumVenueApiForumsForumIdVenuePutRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenuePut(requestParameters.forumId, requestParameters.venueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get tables by filter
     * @param {ForumVenueApiForumsForumIdVenueTablesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumVenueApi
     */
    public forumsForumIdVenueTablesGet(requestParameters: ForumVenueApiForumsForumIdVenueTablesGetRequest, options?: AxiosRequestConfig) {
        return ForumVenueApiFp(this.configuration).forumsForumIdVenueTablesGet(requestParameters.forumId, requestParameters.facilityTypes, requestParameters.tableTypes, requestParameters.availableFrom, requestParameters.availableTo, requestParameters.availableForMeeting, requestParameters.allAttendeeMeetings, options).then((request) => request(this.axios, this.basePath));
    }
}

