import { useQueryClient } from "@tanstack/react-query";
import { ColumnType } from "antd/lib/table";
import { Sort } from "hooks/useCustomHandleSort";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { useItineraryBookletDocumentGenerationRunMutation } from "../../../../../api/mutations/itineraryBooklets";
import {
  useGetItineraryBookletDocumentGenerationRunExportQuery,
  useGetItineraryBookletDocumentGenerationRunQuery,
} from "../../../../../api/queries/itineraryBooklet";
import KEYS from "../../../../../api/queries/keys";
import ContextualDateRenderer from "../../../../../components/ContextualDateRenderer";
import { Button, Col, Divider, Drawer, Row, Spin, Table } from "../../../../../components/styleguide";
import {
  DocumentGenerationRunApprovalStatus,
  DocumentGenerationRunResultModel,
  ItineraryBookletGeneratedDocumentModel,
} from "../../../../../generated/api";
import { downloadFileByURL } from "../../../../../utils/fileUtils";
import ApprovalData from "./ApprovalData";

import styles from "./styles.module.less";

interface DocumentResultsDrawerProps {
  forumId: number;
  documentId: number | null;
  isVisible: boolean;
  setSort: (sort: Sort) => void;
  onClose: () => void;
}

const DocumentResults: FC<DocumentResultsDrawerProps> = ({ forumId, documentId, isVisible, setSort, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Array<number>>([]);

  const {
    isLoading: isDocumentRunLoading,
    data: { data: completedDocumentRun = {} as DocumentGenerationRunResultModel } = {},
    refetch: refetchDocumentGenerationRun,
  } = useGetItineraryBookletDocumentGenerationRunQuery(
    {
      forumId,
      documentGenerationRunId: toNumber(documentId),
    },
    {
      enabled: !!(forumId && documentId),
    },
  );

  const { refetch: downloadDocuments } = useGetItineraryBookletDocumentGenerationRunExportQuery(
    { forumId: forumId, documentGenerationRunId: toNumber(documentId), documentIds: selectedDocumentIds },
    {
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
      enabled: false,
      retry: false,
    },
  );

  const { mutate: updateApprovalStatus, isLoading: isUpdateApprovalStatusLoading } =
    useItineraryBookletDocumentGenerationRunMutation();

  const updateStatus = (status: DocumentGenerationRunApprovalStatus) => {
    updateApprovalStatus(
      {
        forumId,
        documentGenerationRunId: toNumber(documentId),
        documentGenerationRunUpdateStatusModel: { approvalStatus: status },
      },
      {
        onSuccess: () => {
          refetchDocumentGenerationRun();
          setSort({ orderBy: "createdAt", orderDir: "desc" });
          queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUNS]);
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const columns: Array<ColumnType<ItineraryBookletGeneratedDocumentModel>> = [
    {
      title: t("Forum Code"),
      render: () => completedDocumentRun.forumCode,
    },
    {
      title: t("Document"),
      render: () => t(`queueDocumentRunDocuments.${completedDocumentRun.documentDefinitionType}`),
    },
    {
      title: t("Attendee"),
      dataIndex: "attendeeFullName",
    },
    {
      title: t("Company"),
      dataIndex: "attendeeCompanyName",
    },
    {
      title: t("Total"),
      dataIndex: "numberOfPages",
    },
    {
      title: t("Created"),
      dataIndex: "generatedAt",
      render: (_) => (
        <p className="session-time">
          <ContextualDateRenderer date={_} showTime />
        </p>
      ),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys: Array<React.Key>) => {
      setSelectedDocumentIds(selectedRowKeys.map((k) => toNumber(k)));
    },
  };

  return (
    <Drawer
      className={styles.documentResults}
      visible={isVisible}
      onClose={onClose}
      title={t("Document results")}
      placement="right"
      width="100%"
      destroyOnClose
    >
      {isDocumentRunLoading || isUpdateApprovalStatusLoading ? (
        <div className="loader">
          <Spin />
        </div>
      ) : (
        <Row gutter={24}>
          <Col flex="auto">
            <Table
              bordered={true}
              dataSource={completedDocumentRun.documents}
              columns={columns}
              pagination={false}
              rowSelection={rowSelection}
              rowKey="id"
            />
          </Col>
          <Col flex="300px">
            <Row gutter={[10, 10]}>
              <Col flex="auto">
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  disabled={!selectedDocumentIds.length}
                  onClick={() => downloadDocuments()}
                >
                  {t("Download Selected Documents")}
                </Button>
              </Col>
              <Col flex="auto">
                <Row gutter={10}>
                  <Col flex="auto">
                    <Button
                      type="primary"
                      style={{ width: "100%" }}
                      onClick={() => updateStatus(DocumentGenerationRunApprovalStatus.Approved)}
                      disabled={
                        isUpdateApprovalStatusLoading ||
                        completedDocumentRun.approvalStatus === DocumentGenerationRunApprovalStatus.Approved
                      }
                    >
                      {t("Approve")}
                    </Button>
                  </Col>
                  <Col flex="auto">
                    <Button
                      style={{ width: "100%" }}
                      onClick={() => updateStatus(DocumentGenerationRunApprovalStatus.Rejected)}
                      disabled={
                        isUpdateApprovalStatusLoading ||
                        completedDocumentRun.approvalStatus === DocumentGenerationRunApprovalStatus.Rejected
                      }
                    >
                      {t("Reject")}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Divider />
                  <ApprovalData {...completedDocumentRun} />
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

export default DocumentResults;
