import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import KEYS from "api/queries/keys";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useCreateSeminarMutation, useUpdateSeminarMutation } from "../../../api/mutations/seminars";
import { useForumStreamsQuery } from "../../../api/queries/forumStreams";
import { useLanguagesQuery } from "../../../api/queries/languages";
import { useSeminarTypesQuery } from "../../../api/queries/seminarTypes";
import FullHeightForm from "../../../components/FullHeightForm";
import { Button, Drawer, Form, Input, Modal, Tabs } from "../../../components/styleguide";
import { FIXED_CODES } from "../../../constants/seminarTypes";
import {
  ForumSeminarRequestModel,
  ForumSeminarTypeResponseModel,
  ForumSeminarWithTypeAndStreamAndSpeakers,
  StreamResponseModel,
} from "../../../generated/api";
import SeminarSessions from "../SeminarSessions/List";
import { getFormFields } from "./helpers";
import SpeakerDetails from "./SpeakerDetails";

const codeFormat = new RegExp("^[A-Z0-9a-z]*$");
const { TabPane } = Tabs;

const { confirm } = Modal;

const seminarEditTabs = {
  seminarDetails: "seminarDetails",
  speakerDetails: "speakerDetails",
};

interface SeminarFormProps {
  visible: boolean;
  onClose: () => void;
  seminar: ForumSeminarWithTypeAndStreamAndSpeakers;
  seminars: Array<ForumSeminarWithTypeAndStreamAndSpeakers>;
}

const SeminarForm: FC<SeminarFormProps> = ({ visible, onClose, seminar, seminars }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [seminarTypes, setSeminarTypes] = useState<ForumSeminarTypeResponseModel[]>([]);
  const [streams, setStreams] = useState<Array<StreamResponseModel>>([]);

  const createMutation = useCreateSeminarMutation();
  const updateMutation = useUpdateSeminarMutation();

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const streamsQuery = useForumStreamsQuery(
    { forumId },
    {
      onSuccess: ({ data }: { data: Array<StreamResponseModel> }) => {
        setStreams(data);
      },
      enabled: visible,
    },
  );

  const seminarTypesQuery = useModernQueryWithPaginationAndOrder(
    useSeminarTypesQuery,
    {
      onSuccess: ({ data }: { data: ForumSeminarTypeResponseModel[] }) => {
        setSeminarTypes(data);
      },
      enabled: visible,
    },
    { forumId },
  );

  const { data: { data: languages = [] } = {} } = useLanguagesQuery();

  const validateCodeFormat = async (_: unknown, value: string) => {
    return value.length <= 0 || codeFormat.test(value) ? Promise.resolve() : Promise.reject();
  };

  const validateUniqueCode = async (_: unknown, value: string) => {
    const codes = seminars.map((s) => s.code);
    if (seminars.length > 0) {
      if (seminar || value.length === 0 || !codes.includes(value)) {
        return Promise.resolve();
      }
      return Promise.reject();
    } else {
      if (value.length === 0 || !codes.includes(value)) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
  };

  const submit = (values: ForumSeminarRequestModel) => {
    if (seminar) {
      updateMutation.mutate(
        { forumId, seminarId: seminar.id, forumSeminarRequestModel: values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
            onClose();
          },
        },
      );
    } else {
      createMutation.mutate(
        { forumId, forumSeminarRequestModel: values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
            onClose();
          },
        },
      );
    }
  };

  const onSeminarTypeSelect = (id: number) => {
    if (!seminar || seminar.forumSeminarTypeId === id) return;

    const specialTypesIds = seminarTypes
      .filter(({ id: forumSeminarTypeId, code }) => {
        if (code && ["F", "G", "Z"].includes(code)) {
          return forumSeminarTypeId;
        }
      })
      .map(({ id: forumSeminarTypeId }) => forumSeminarTypeId);

    if (specialTypesIds.includes(id)) {
      confirm({
        title: t("confirmation"),
        content: t(
          "Are you sure you want to change the seminar code to F/G/Z? The conferences preferences of all attendees, which are associated to this seminar, will be deleted",
        ),
        icon: <ExclamationCircleOutlined />,
        okText: t("yes"),
        okType: "danger",
        cancelText: t("no"),
        zIndex: 2001,
        maskStyle: { zIndex: 2000 },
        onOk: async () => {
          form.setFieldValue("forumSeminarTypeId", id);
        },
        onCancel: async () => {
          form.setFieldValue("forumSeminarTypeId", seminar.forumSeminarTypeId);
        },
      });
    }
  };

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      placement="right"
      title={seminar ? t("Edit Seminar") + " - " + seminar.title : t("Add New Seminar")}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <Tabs hideNav={!seminar} defaultActiveKey={"seminar"} className="">
        <TabPane tab={t("Seminar Details")} key="seminar">
          <FullHeightForm
            layout="vertical"
            id="seminarForm"
            name="seminar-form"
            onFinish={submit}
            labelWrap
            actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
            actionsAppend={
              <Button
                htmlType="submit"
                type="primary"
                loading={createMutation.isLoading || updateMutation.isLoading || seminarTypesQuery.response.isLoading || streamsQuery.isLoading}
              >
                {t(seminar ? "Update" : "Create")}
              </Button>
            }
          >
            {getFormFields({
              streams,
              seminarTypes,
              t,
              validateCodeFormat,
              validateUniqueCode,
              languages,
              onSeminarTypeSelect,
              seminar,
            }).map((f) => (
              <Form.Item
                initialValue={seminar ? f.initialValue ?? seminar[f.dataIndex as keyof typeof seminar] : undefined}
                label={t(f.title)}
                name={f.dataIndex}
                key={f.dataIndex}
                rules={f.rules ?? []}
              >
                {f.component ?? (
                  <Input disabled={f.dataIndex === "code" && seminar && FIXED_CODES.includes(seminar.code)} />
                )}
              </Form.Item>
            ))}
          </FullHeightForm>
        </TabPane>
        {seminar && (
          <>
            <TabPane tab={t("Session Details")} key="sessions">
              <SeminarSessions seminar={seminar} />
            </TabPane>
            <TabPane tab={"Speaker Details"} key={seminarEditTabs.speakerDetails}>
              <SpeakerDetails forumId={forumId.toString()} seminarId={seminar.id} />
            </TabPane>
          </>
        )}
      </Tabs>
    </Drawer>
  );
};

export default SeminarForm;
