import { useQueryClient } from "@tanstack/react-query";
import { useCreateForumRoomGradeMutation } from "api/mutations/forumRoomGrades";
import { useCreateRoomGradeMutation } from "api/mutations/roomGrade";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import { RoomGradeRequestModel } from "generated/api";
import { toNumber } from "lodash-es";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import GradeForm from "../GradeForm";

interface AddGradeProps {
  isVisible: boolean;
  onClose: () => void;
}

export default function AddGrade({ isVisible, onClose }: AddGradeProps) {
  const { t } = useTranslation();
  const { id: forumId, venueId, buildingId } = useParams();
  const queryClient = useQueryClient();

  const { isLoading: isCreateForumRoomGradeLoading, mutate: createForumRoomGradeMutate } =
    useCreateForumRoomGradeMutation();
  const { isLoading: isCreateSystemAdminRoomGradeLoading, mutate: createSystemAdminRoomGradeMutate } =
    useCreateRoomGradeMutation();

  const isCreateRoomGradeLoading = forumId ? isCreateForumRoomGradeLoading : isCreateSystemAdminRoomGradeLoading;

  const createForumRoomGrade = (roomGradeRequestModel: RoomGradeRequestModel) => {
    createForumRoomGradeMutate(
      { forumId: toNumber(forumId), forumBuildingId: toNumber(buildingId), roomGradeRequestModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_ROOM_GRADES]);
          onClose();
        },
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
            toast.error(t("A room grade with this name already exists"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const createSystemAdminRoomGrade = (roomGradeRequestModel: RoomGradeRequestModel) => {
    createSystemAdminRoomGradeMutate(
      { venueId: toNumber(venueId), buildingId: toNumber(buildingId), roomGradeRequestModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ROOM_GRADES]);
          onClose();
        },
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
            toast.error(t("A room grade with this name already exists"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  const onSubmit = (roomGradeRequestModel: RoomGradeRequestModel) => {
    if (forumId) {
      createForumRoomGrade(roomGradeRequestModel);
    } else {
      createSystemAdminRoomGrade(roomGradeRequestModel);
    }
  };

  return (
    <Drawer title={t("Add Grade")} open={isVisible} onClose={onClose} placement="right" size="large" destroyOnClose>
      <GradeForm {...{ onSubmit, onClose }} isSubmitting={isCreateRoomGradeLoading} />
    </Drawer>
  );
}
