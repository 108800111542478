import { useQueryClient } from "@tanstack/react-query";
import { getForumBuildingKey } from "api/queries/buildings";
import { useGetFileImportQuery } from "api/queries/fileImport";
import KEYS from "api/queries/keys";
import ExportTemplates from "components/ExportTemplates";
import FileImport from "components/FileImport";
import { Button, Layout, Space, Typography } from "components/styleguide";
import BuildingDrawer from "components/VenueDetails/BuildingDrawer";
import ForumBuildingDrawer from "components/VenueDetails/ForumBuildingDrawer";
import { FileImportStatuses } from "generated/api";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";
import { getFileImportSnapshot, subscribeToFileImport, updateFileImportKey } from "utils/localStorage/fileImport";

import ExportFacilitiesButton from "./ExportFacilitiesButton";

import styles from "./styles.module.less";

const { Content, Header } = Layout;
const { Text } = Typography;

const importLoadingToastId = "importToastLoading";
const resultToastId = "resultToastId";

const VenueLayout = ({
  venue,
  buildingsData,
  createBuildingMutation,
  updateBuildingMutation,
  isBuildingsLoading,
  isForumBuilding,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { venueId, buildingId, id: forumId } = useParams();
  const [isBuildingDrawerVisible, setIsBuildingDrawerVisible] = useState(false);
  const [isBuildingEdit, setIsBuildingEdit] = useState(false);
  const [buildingEditId, setBuildingEditId] = useState(undefined);

  const importKey = useSyncExternalStore(subscribeToFileImport, getFileImportSnapshot, () => undefined);

  useEffect(() => {
    if (importKey) {
      toast.loading(t("Importing File"), {
        toastId: importLoadingToastId,
      });
    }
  }, [importKey, t]);

  const handleErrorDowload = (errorsLink) => {
    updateFileImportKey(null);
    return downloadFileByURL(errorsLink);
  };

  useGetFileImportQuery(
    { key: importKey },
    {
      refetchInterval: 2000,
      enabled: !!importKey,
      onSuccess: ({ data }) => {
        if (data.status === FileImportStatuses.Completed) {
          toast.dismiss(importLoadingToastId);
          toast.success(
            <Space direction="vertical">
              <Text>{t("Import successful")}</Text>
            </Space>,
            { toastId: resultToastId },
          );
          updateFileImportKey(null);
        }

        if (data.status === FileImportStatuses.Failed) {
          toast.dismiss(importLoadingToastId);
          updateFileImportKey(null);
          toast.error(
            <Space direction="vertical">
              <Text>{t("Unable to upload the file, please, download the errors report")}</Text>
              {data.errorsReportFileLink && (
                <Button onClick={() => handleErrorDowload(data.errorsReportFileLink)}>{t("Export Errors")}</Button>
              )}
            </Space>,
            { toastId: resultToastId, autoClose: false },
          );
        }
      },
      onError: () => {
        toast.dismiss(importLoadingToastId);
        toast.error(t("Something went wrong"));
      },
    },
  );

  const hideBuildingDrawer = () => {
    if (createBuildingMutation.isLoading) return;

    return setIsBuildingDrawerVisible(false);
  };

  const submitBuilding = (values) => {
    const building = {
      name: values.name,
      code: values.code,
      floors: values.floors,
    };

    if (isBuildingEdit) {
      updateBuildingMutation.mutate(
        {
          venueId,
          building,
          buildingId,
        },
        {
          onSuccess: async () => {
            await queryClient.invalidateQueries([KEYS.GET_BUILDING]);
            await queryClient.invalidateQueries([KEYS.GET_BUILDINGS]);
            hideBuildingDrawer(true);
          },
        },
      );
    } else {
      createBuildingMutation.mutateAsync(
        {
          venueId,
          building,
        },
        {
          onSuccess: async ({ data }) => {
            await queryClient.invalidateQueries([KEYS.GET_BUILDINGS]);
            await queryClient.invalidateQueries(KEYS.GET_FORUM_BUILDINGS);
            await queryClient.invalidateQueries([KEYS.GET_FORUM_BUILDINGS]);
            navigate(`../venues/${venueId}/buildings/${data.id}`);
            hideBuildingDrawer(true);
          },
        },
      );
    }
  };

  const submitForumBuilding = (building) => {
    if (isBuildingEdit) {
      updateBuildingMutation.mutate(
        {
          forumId,
          building,
          buildingId,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([getForumBuildingKey]);
            queryClient.invalidateQueries([KEYS.GET_FORUM_BUILDINGS]);
            hideBuildingDrawer(true);
          },
        },
      );
    } else {
      createBuildingMutation.mutate(
        {
          forumId,
          building,
        },
        {
          onSuccess: ({ data }) => {
            queryClient.invalidateQueries(KEYS.GET_FORUM_BUILDINGS);
            navigate(`../venues/${venueId}/buildings/${data.id}`, {
              state: {
                ...location.state,
                venueName: venue?.name,
                buildingName: data?.name,
              },
            });
            hideBuildingDrawer(true);
          },
        },
      );
    }
  };

  const addNewBuilding = () => {
    setIsBuildingEdit(false);
    setIsBuildingDrawerVisible(true);
  };

  const editBuilding = (id) => {
    setBuildingEditId(id);
    setIsBuildingEdit(true);
    setIsBuildingDrawerVisible(true);
  };

  const buildingToEdit =
    !isBuildingsLoading &&
    isBuildingEdit &&
    buildingsData &&
    buildingsData.filter(({ id }) => id === Number(buildingEditId))[0];

  const showExportFacilitiesBtn = !!forumId && !buildingId;
  const showImportFileBtn = forumId ? !!forumId && !buildingId : !!venueId && !buildingId;

  return (
    <Layout>
      <Content>
        <Header id="venueDetailsHeader" className={styles.header}>
          <Button onClick={addNewBuilding}>{t("Add Building")}</Button>
          {showExportFacilitiesBtn && <ExportFacilitiesButton />}
          {showImportFileBtn && <FileImport venueId={toNumber(venueId)} forumId={forumId} />}
          {!buildingId && <ExportTemplates />}
        </Header>
        <Content id="venueDetailsPage" className={styles.page}>
          {isForumBuilding ? (
            <ForumBuildingDrawer
              building={buildingToEdit}
              isEdit={isBuildingEdit}
              hideBuildingDrawer={hideBuildingDrawer}
              buildingDrawerVisible={isBuildingDrawerVisible}
              submitBuilding={submitForumBuilding}
              venue={venue}
              isLoading={createBuildingMutation?.isLoading || updateBuildingMutation?.isLoading}
            />
          ) : (
            <BuildingDrawer
              building={buildingToEdit}
              isEdit={isBuildingEdit}
              hideBuildingDrawer={hideBuildingDrawer}
              buildingDrawerVisible={isBuildingDrawerVisible}
              submitBuilding={submitBuilding}
              venue={venue}
              isLoading={createBuildingMutation?.isLoading || updateBuildingMutation?.isLoading}
            />
          )}

          <Outlet context={{ venueId, editBuilding }} />
        </Content>
      </Content>
    </Layout>
  );
};

export default VenueLayout;

VenueLayout.propTypes = {
  venue: PropTypes.object,
  buildingsData: PropTypes.any,
  createBuildingMutation: PropTypes.object,
  updateBuildingMutation: PropTypes.object,
  isBuildingsLoading: PropTypes.bool,
  isForumBuilding: PropTypes.bool,
};
