import { Form } from "components/styleguide";
import { ReasonForMeetingItem, ReasonForMeetingV2Item, ReasonForMeetingV3Item } from "generated/api";
import { useTranslation } from "react-i18next";

import DefaultForm from "../../../DefaultForm";
import { SelectionSiteItemSelect, Setup, YesNo } from "../../../inputs";
import ReasonForMeetingSetup from "../ReasonForMeetingSetup";

type ReasonForMeetingItemType = ReasonForMeetingItem | ReasonForMeetingV2Item | ReasonForMeetingV3Item;
type ReasonForMeetingProps<T extends ReasonForMeetingItemType> = {
  forumId: number;
  selectionSiteId: number;
  item: T;
  onChange: (item: T) => void;
};

const ReasonForMeetingComponent = <T extends ReasonForMeetingItemType>({
  forumId,
  selectionSiteId,
  item,
  onChange,
}: ReasonForMeetingProps<T>) => {
  const { t } = useTranslation();
  const handleChange = (values: Partial<ReasonForMeetingV2Item> & { TO_REMOVE: unknown }) => {
    const newValues = {
      ...item,
      ...values,
    };

    // It's a temp fix for the Ant Form to render the content inside the Form.Item
    // when no `name` is passed to it.
    delete newValues.TO_REMOVE;

    return onChange(newValues);
  };

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      onChange={handleChange}
      options={() => (
        <>
          <Form.Item name="associatedSelectionStep" label={t("Associated selection step")}>
            <SelectionSiteItemSelect
              forumId={forumId}
              selectionSiteId={selectionSiteId}
              types={["PositiveSelectionV2"]}
            />
          </Form.Item>

          <Form.Item name="showReasonForMeetingDropdown" label={t("Show reason for meeting dropdown")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="showReasonForMeetingFreeText" label={t("Show reason for meeting free text")}>
            <YesNo />
          </Form.Item>

          <Form.Item name="reasonsForMeeting" label={t("Set up this reasons for meeting on this step")}>
            <Setup
              formTitle={t("Set up this reasons for meeting on this step")}
              form={{
                element: ReasonForMeetingSetup,
              }}
            />
          </Form.Item>

          <Form.Item name="displayLocalisedLabels" label={t("Display localised labels")}>
            <YesNo />
          </Form.Item>
        </>
      )}
    />
  );
};

export default ReasonForMeetingComponent;
