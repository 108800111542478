import getUniqueKeys from "api/helpers/getUniqueKeys";
import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

const keys = {
  companyPersons: (params) => [KEYS.GET_COMPANY_PERSONS, ...getUniqueKeys(params)],
};

const useCompanyPersonsQuery = getWrappedUseQuery(KEYS.GET_COMPANY_PERSONS, apiClient.Persons.getByCompanyId);

export { keys, useCompanyPersonsQuery };
