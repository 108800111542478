import { Tabs } from "components/styleguide";
import { useTranslation } from "react-i18next";

import ItineraryTab from "./IntineraryTab";
import RemovedBookingsTab from "./RemovedBookingsTab";

import styles from "./styles.module.less";

interface AttendeeItineraryProps {
  forumId: number;
  attendeeId: number;
}

const AttendeeItinerary = ({ forumId, attendeeId }: AttendeeItineraryProps) => {
  const { t } = useTranslation();

  const tabs = [
    {
      label: t("Itinerary"),
      key: "itineraryTab",
      children: <ItineraryTab />,
    },
    {
      label: t("Removed Bookings"),
      key: "removedBookingsTab",
      children: <RemovedBookingsTab forumId={forumId} attendeeId={attendeeId} />,
    },
  ];

  return (
    <div className={styles.itinerarySection}>
      <Tabs items={tabs} />
    </div>
  );
};

export default AttendeeItinerary;
