import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Select } from "components/styleguide";
import TextEditor from "components/TextEditor";
import {
  CustomPageElementAlignments,
  CustomPageElementTypes,
  ForumDocumentCustomPageElementResponseModel,
  ForumDocumentCustomPageElementUpdateModel,
} from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { validateMergeTags } from "utils/validatorUtils";

const mergeTagsRegex = /\[.*?\]/g;

interface EditElementDrawerProps {
  open: boolean;

  mergeTags?: string[];
  forumId: number;
  isLoading: boolean;
  element?: ForumDocumentCustomPageElementResponseModel;
  onClose: () => void;
  onSubmit: (values: ForumDocumentCustomPageElementUpdateModel) => void;
}

const EditElementDrawer: FC<EditElementDrawerProps> = ({ open, isLoading, mergeTags, element, onSubmit, onClose }) => {
  const { t } = useTranslation();

  const elementTypeOptions = Object.values(CustomPageElementTypes).map((type) => ({
    label: type,
    value: type,
  }));

  const alignmentOptions = Object.values(CustomPageElementAlignments).map((type) => ({
    label: type,
    value: type,
  }));

  return (
    <Drawer
      title={t("Edit Element")}
      placement="right"
      onClose={onClose}
      open={open}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <FullHeightForm
        onFinish={onSubmit}
        layout="vertical"
        actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
        actionsAppend={
          <Button htmlType="submit" type="primary" loading={isLoading} disabled={isLoading}>
            {t("Update")}
          </Button>
        }
      >
        <Form.Item label={t("Type")} name="type" initialValue={element?.type}>
          <Select style={{ width: "40%" }} options={elementTypeOptions}></Select>
        </Form.Item>

        <Form.Item label={t("Alignment")} name="alignment" initialValue={element?.alignment}>
          <Select style={{ width: "40%" }} options={alignmentOptions}></Select>
        </Form.Item>
        <Form.Item
          initialValue={element?.value}
          name="value"
          rules={[
            {
              validator: validateMergeTags(mergeTagsRegex, t, mergeTags),
            },
          ]}
        >
          <TextEditor mergeTags={mergeTags} showListFormats />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default EditElementDrawer;
