/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { RoomGradeRequestModel } from '../apiModels';
// @ts-ignore
import { RoomGradeResponseModel } from '../apiModels';
/**
 * RoomGradeApi - axios parameter creator
 * @export
 */
export const RoomGradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets grades for specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesGet: async (venueId: number, buildingId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGet', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGet', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/roomgrades`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a grade for specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} gradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete: async (venueId: number, buildingId: number, gradeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete', 'buildingId', buildingId)
            // verify required parameter 'gradeId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete', 'gradeId', gradeId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/roomgrades/{gradeId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"gradeId"}}`, encodeURIComponent(String(gradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a grade for specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} gradeId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut: async (venueId: number, buildingId: number, gradeId: number, roomGradeRequestModel?: RoomGradeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut', 'buildingId', buildingId)
            // verify required parameter 'gradeId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut', 'gradeId', gradeId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/roomgrades/{gradeId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)))
                .replace(`{${"gradeId"}}`, encodeURIComponent(String(gradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomGradeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a grade in the specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesPost: async (venueId: number, buildingId: number, roomGradeRequestModel?: RoomGradeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesPost', 'venueId', venueId)
            // verify required parameter 'buildingId' is not null or undefined
            assertParamExists('venuesVenueIdBuildingsBuildingIdRoomgradesPost', 'buildingId', buildingId)
            const localVarPath = `/venues/{venueId}/buildings/{buildingId}/roomgrades`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"buildingId"}}`, encodeURIComponent(String(buildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomGradeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomGradeApi - functional programming interface
 * @export
 */
export const RoomGradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoomGradeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets grades for specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdRoomgradesGet(venueId: number, buildingId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomGradeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdRoomgradesGet(venueId, buildingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a grade for specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} gradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete(venueId: number, buildingId: number, gradeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete(venueId, buildingId, gradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a grade for specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {number} gradeId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut(venueId: number, buildingId: number, gradeId: number, roomGradeRequestModel?: RoomGradeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut(venueId, buildingId, gradeId, roomGradeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a grade in the specified building
         * @param {number} venueId 
         * @param {number} buildingId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdBuildingsBuildingIdRoomgradesPost(venueId: number, buildingId: number, roomGradeRequestModel?: RoomGradeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdBuildingsBuildingIdRoomgradesPost(venueId, buildingId, roomGradeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoomGradeApi - factory interface
 * @export
 */
export const RoomGradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoomGradeApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets grades for specified building
         * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesGet(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RoomGradeResponseModel>> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdRoomgradesGet(requestParameters.venueId, requestParameters.buildingId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a grade for specified building
         * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete(requestParameters.venueId, requestParameters.buildingId, requestParameters.gradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a grade for specified building
         * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.gradeId, requestParameters.roomGradeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a grade in the specified building
         * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdBuildingsBuildingIdRoomgradesPost(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdBuildingsBuildingIdRoomgradesPost(requestParameters.venueId, requestParameters.buildingId, requestParameters.roomGradeRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdRoomgradesGet operation in RoomGradeApi.
 * @export
 * @interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGetRequest
 */
export interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGet
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGet
     */
    readonly buildingId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete operation in RoomGradeApi.
 * @export
 * @interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDeleteRequest
 */
export interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete
     */
    readonly gradeId: number
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut operation in RoomGradeApi.
 * @export
 * @interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPutRequest
 */
export interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut
     */
    readonly buildingId: number

    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut
     */
    readonly gradeId: number

    /**
     * 
     * @type {RoomGradeRequestModel}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut
     */
    readonly roomGradeRequestModel?: RoomGradeRequestModel
}

/**
 * Request parameters for venuesVenueIdBuildingsBuildingIdRoomgradesPost operation in RoomGradeApi.
 * @export
 * @interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPostRequest
 */
export interface RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPost
     */
    readonly venueId: number

    /**
     * 
     * @type {number}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPost
     */
    readonly buildingId: number

    /**
     * 
     * @type {RoomGradeRequestModel}
     * @memberof RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPost
     */
    readonly roomGradeRequestModel?: RoomGradeRequestModel
}

/**
 * RoomGradeApi - object-oriented interface
 * @export
 * @class RoomGradeApi
 * @extends {BaseAPI}
 */
export class RoomGradeApi extends BaseAPI {
    /**
     * 
     * @summary Gets grades for specified building
     * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomGradeApi
     */
    public venuesVenueIdBuildingsBuildingIdRoomgradesGet(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGetRequest, options?: AxiosRequestConfig) {
        return RoomGradeApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdRoomgradesGet(requestParameters.venueId, requestParameters.buildingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a grade for specified building
     * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomGradeApi
     */
    public venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDeleteRequest, options?: AxiosRequestConfig) {
        return RoomGradeApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdDelete(requestParameters.venueId, requestParameters.buildingId, requestParameters.gradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a grade for specified building
     * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomGradeApi
     */
    public venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPutRequest, options?: AxiosRequestConfig) {
        return RoomGradeApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdRoomgradesGradeIdPut(requestParameters.venueId, requestParameters.buildingId, requestParameters.gradeId, requestParameters.roomGradeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a grade in the specified building
     * @param {RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomGradeApi
     */
    public venuesVenueIdBuildingsBuildingIdRoomgradesPost(requestParameters: RoomGradeApiVenuesVenueIdBuildingsBuildingIdRoomgradesPostRequest, options?: AxiosRequestConfig) {
        return RoomGradeApiFp(this.configuration).venuesVenueIdBuildingsBuildingIdRoomgradesPost(requestParameters.venueId, requestParameters.buildingId, requestParameters.roomGradeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

