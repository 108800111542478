import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumCompanyExhibitingStatusMutation } from "api/mutations/forumCompanies";
import KEYS from "api/queries/keys";
import EditableField from "components/EditableField";
import { YesNo } from "components/styleguide";
import { CompanyExhibitingStatusRequestModel } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface ExhibitingStatusProps {
  id: number;
  status: boolean;
  forumId: number;
}

const ExhibitingStatus: FC<ExhibitingStatusProps> = ({ id, status, forumId }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutate: updateExhibitingStatusMutate, isLoading: isExhibitingStatusUpdating } =
    useUpdateForumCompanyExhibitingStatusMutation();

  const updateExhibitingStatus = (
    { exhibitingStatus }: CompanyExhibitingStatusRequestModel,
    onCloseEditing: () => void,
  ) => {
    if (exhibitingStatus === status) {
      onCloseEditing();
      return;
    }

    updateExhibitingStatusMutate(
      {
        forumId,
        forumCompanyId: id,
        companyExhibitingStatusRequestModel: { exhibitingStatus },
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_FORUM_COMPANY]),
        onError: () => toast.error(t("Something went wrong")),
        onSettled: onCloseEditing,
      },
    );
  };

  return (
    <EditableField
      name="exhibitingStatus"
      title="exhibitingStatus"
      initialValue={status}
      displayValue={status ? t("Yes") : t("No")}
      isUpdating={isExhibitingStatusUpdating}
      onUpdate={updateExhibitingStatus}
    >
      <YesNo />
    </EditableField>
  );
};

export default ExhibitingStatus;
