import { Select as AntdSelect, SelectProps } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import React from "react";

const Select = <OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: SelectProps<any, OptionType> & {
    children?: React.ReactNode;
  },
) => {
  return <AntdSelect {...props} />;
};

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;

export default Select;
