import { useQueryClient } from "@tanstack/react-query";
import { Button, Col, Divider, Form, Row, Select, Typography } from "antd";
import { useUpdateSelectionSiteStatus } from "api/mutations/selectionSites";
import SelectionSiteStatus from "backend-models/selectionSiteStatus";
import FullHeightForm from "components/FullHeightForm";
import moment from "moment";
import SelectionSiteStatusContext from "pages/SelectionSites/EditPage/context/selection-site-status";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const { Text } = Typography;

const formatType = "DD/MM/YYYY";

const SelectionSiteInfo = ({ selectionSite }) => {
  const status = useContext(SelectionSiteStatusContext);
  const isEditDisabled = status === "Open";
  const { id: forumId } = useOutletContext();
  const params = useParams();
  const selectionSiteId = parseInt(params.selectionSiteId);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [selectedStatus, setSelectedStatus] = useState(selectionSite?.status);

  const { mutate, isLoading: isMutationLoading } = useUpdateSelectionSiteStatus();

  useEffect(() => {
    form.resetFields();
    setSelectedStatus(selectionSite?.status);
  }, [selectionSite?.status]);

  const onSubmit = ({ ...selectionSiteStatusRequestModel }) => {
    mutate(
      {
        forumId,
        selectionSiteId,
        selectionSiteStatusRequestModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(selectionSite);
        },
        onError: (error) => {
          const errorMessage = error?.response?.data?.Message || error.message;
          const genericError = t("An error occurred while updating the item.");
          const message = `${genericError} ${errorMessage}`;
          toast.error(message);
        },
      },
    );
  };

  return (
    <div>
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteName">Name</label>
        </Col>
        <Col span={12}>
          <Text id="selectionSiteName">{selectionSite?.name || "-"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteDescription">Description</label>
        </Col>
        <Col span={12}>
          <Text id="selectionSiteDescription">{selectionSite?.description || "-"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteA">Available from</label>
        </Col>
        <Col span={12}>
          <Text id="selectionSiteAvailableFrom">{moment(selectionSite?.availableFrom).format(formatType) || "-"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteAvailableTo">Available to</label>
        </Col>
        <Col span={12}>
          <Text id="selectionSiteAvailableTo">{moment(selectionSite?.availableTo).format(formatType) || "-"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteGroup">Group</label>
        </Col>
        <Col span={12}>
          <Text id="selectionSiteGroup">{selectionSite?.group?.name || "-"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteColour">Colour Scheme</label>
        </Col>
        <Col span={12}>
          <Text id="selectionSiteColour">{selectionSite?.colorScheme || "-"}</Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={6}>
        <Col span={12}>
          <label htmlFor="selectionSiteStatus">Selection status</label>
        </Col>

        <Col span={12}>
          {isEditDisabled ? (
            <FullHeightForm
              form={form}
              divider={false}
              actionsAppend={
                <Button
                  disabled={isMutationLoading || selectedStatus === SelectionSiteStatus.VALUES[0]}
                  loading={isMutationLoading}
                  type="primary"
                  htmlType="submit"
                >
                  {t("Save")}
                </Button>
              }
              onFinish={onSubmit}
            >
              <Form.Item
                name="status"
                initialValue={selectionSite?.status || SelectionSiteStatus.VALUES[1]}
                rules={[
                  {
                    required: true,
                    message: t("Status is required!"),
                  },
                ]}
              >
                <Select onChange={(status) => setSelectedStatus(status)}>
                  {SelectionSiteStatus.LIST.map(({ value, label }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </FullHeightForm>
          ) : (
            <Text id="selectionSiteStatus">{selectionSite?.status || "-"}</Text>
          )}
        </Col>
      </Row>
    </div>
  );
};

SelectionSiteInfo.propTypes = {
  selectionSite: PropTypes.shape({
    availableFrom: PropTypes.string,
    availableTo: PropTypes.string,
    colorScheme: PropTypes.string,
    description: PropTypes.string,
    forumId: PropTypes.number,
    group: PropTypes.shape({
      name: PropTypes.string,
    }),
    id: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.oneOf(SelectionSiteStatus.LIST),
  }).isRequired,
};

SelectionSiteInfo.defaultProps = {
  selectionSite: null,
};

export default SelectionSiteInfo;
