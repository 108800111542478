import { BaseOptionType } from "antd/lib/select";
import { Tag } from "components/styleguide";

import styles from "../styles.module.less";

const MembershipGroupsTags = ({
  groupSearch,
  groups,
  handleSearch,
  handleReset,
  disabled = false,
}: {
  groupSearch: string | undefined;
  groups: string[];
  handleSearch: (selectedKeys: string[] | BaseOptionType[], confirm: () => void, _: string, searchSet: string) => void;
  handleReset: (clearFilters: () => void, _: unknown, searchSet: string, confirm: () => void) => void;
  disabled: boolean;
}) => {
  const handleTagClick = (group: string) => {
    if (groupSearch === group) {
      handleReset(
        () => {},
        null,
        "groupMembershipTag",
        () => {},
      );
    } else {
      handleSearch([group], () => {}, "groupMembershipTag", "groupMembershipTag");
    }
  };

  return (
    <>
      {groups?.map((group: string, index: number) => (
        <Tag
          className={!disabled ? styles.tag : styles.tagDisabled}
          key={index}
          color={groupSearch === group ? "green" : "default"}
          onClick={() => (!disabled ? handleTagClick(group) : false)}
        >
          {group}
        </Tag>
      ))}
    </>
  );
};

export default MembershipGroupsTags;
