import { SelectionSiteContent } from "generated/api";
import { ReactElement, useMemo } from "react";
import getSelectionSiteDependentItems from "utils/getSelectionSiteDependentItems";
import Content from "utils/getSelectionSiteDependentItems/utils/content";
import ContentItem from "utils/getSelectionSiteDependentItems/utils/content-item";

interface DependenciesProps {
  defaultMessage: ReactElement;
  foundDependenciesMessage: (items: Set<ContentItem>) => ReactElement | Array<ReactElement>;
  itemIds: Array<number | undefined>;
  content: SelectionSiteContent;
}

const DependenciesList = ({ defaultMessage, itemIds, content, foundDependenciesMessage }: DependenciesProps) => {
  const contentInstance = useMemo(() => new Content(content), [content]);

  const dependencies = useMemo(() => {
    const result = new Set<ContentItem>();

    getSelectionSiteDependentItems(content, itemIds).forEach((id) => {
      const item = contentInstance.getItemById(id);
      if (!item) {
        return;
      }

      result.add(item);
    });

    return result;
  }, [content, contentInstance, itemIds]);

  if (dependencies.size === 0) {
    return defaultMessage;
  }

  return <>{foundDependenciesMessage(dependencies)}</>;
};

export default DependenciesList;
