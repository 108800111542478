/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientReportSource } from '../apiModels';
// @ts-ignore
import { Parameter } from '../apiModels';
// @ts-ignore
import { ReportsControllerReportClientResponse } from '../apiModels';
/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} clientID 
         * @param {ClientReportSource} [clientReportSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsClientsClientIDParametersPost: async (clientID: string, clientReportSource?: ClientReportSource, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientID' is not null or undefined
            assertParamExists('reportsClientsClientIDParametersPost', 'clientID', clientID)
            const localVarPath = `/reports/clients/{clientID}/parameters`
                .replace(`{${"clientID"}}`, encodeURIComponent(String(clientID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientReportSource, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsClientsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReportIdParametersGet: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('reportsReportIdParametersGet', 'reportId', reportId)
            const localVarPath = `/reports/{reportId}/parameters`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} clientID 
         * @param {ClientReportSource} [clientReportSource] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsClientsClientIDParametersPost(clientID: string, clientReportSource?: ClientReportSource, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parameter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsClientsClientIDParametersPost(clientID, clientReportSource, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsClientsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportsControllerReportClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsClientsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reportsReportIdParametersGet(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parameter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reportsReportIdParametersGet(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @param {ReportsApiReportsClientsClientIDParametersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsClientsClientIDParametersPost(requestParameters: ReportsApiReportsClientsClientIDParametersPostRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Parameter>> {
            return localVarFp.reportsClientsClientIDParametersPost(requestParameters.clientID, requestParameters.clientReportSource, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsClientsPost(options?: AxiosRequestConfig): AxiosPromise<ReportsControllerReportClientResponse> {
            return localVarFp.reportsClientsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReportsApiReportsReportIdParametersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reportsReportIdParametersGet(requestParameters: ReportsApiReportsReportIdParametersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Parameter>> {
            return localVarFp.reportsReportIdParametersGet(requestParameters.reportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for reportsClientsClientIDParametersPost operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsClientsClientIDParametersPostRequest
 */
export interface ReportsApiReportsClientsClientIDParametersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsClientsClientIDParametersPost
     */
    readonly clientID: string

    /**
     * 
     * @type {ClientReportSource}
     * @memberof ReportsApiReportsClientsClientIDParametersPost
     */
    readonly clientReportSource?: ClientReportSource
}

/**
 * Request parameters for reportsReportIdParametersGet operation in ReportsApi.
 * @export
 * @interface ReportsApiReportsReportIdParametersGetRequest
 */
export interface ReportsApiReportsReportIdParametersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiReportsReportIdParametersGet
     */
    readonly reportId: string
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @param {ReportsApiReportsClientsClientIDParametersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsClientsClientIDParametersPost(requestParameters: ReportsApiReportsClientsClientIDParametersPostRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsClientsClientIDParametersPost(requestParameters.clientID, requestParameters.clientReportSource, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsClientsPost(options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsClientsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReportsApiReportsReportIdParametersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public reportsReportIdParametersGet(requestParameters: ReportsApiReportsReportIdParametersGetRequest, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).reportsReportIdParametersGet(requestParameters.reportId, options).then((request) => request(this.axios, this.basePath));
    }
}

