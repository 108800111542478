import { DatePicker, Form } from "components/styleguide";
import { ForumResponseModel } from "generated/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { getDisabledDates } from "utils/dateUtils";

const { RangePicker } = DatePicker;

const DateFilterForm = () => {
  const { t } = useTranslation();

  const { forum } = useOutletContext<{ forum: ForumResponseModel }>();

  return (
    <Form.Item
      name="dateRange"
      label={t("Dates")}
      rules={[{ required: true, message: t("errors.required", { prop: "$t(Date Range)" }) }]}
      initialValue={[moment(forum?.startDate), moment(forum?.endDate)]}
    >
      <RangePicker
        style={{ width: "100%" }}
        disabledDate={(value) => getDisabledDates(value, forum)}
        defaultPickerValue={[moment(forum.startDate), moment(forum.endDate)]}
      />
    </Form.Item>
  );
};

export default DateFilterForm;
