import { FormItemProps } from "antd";
import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "../../../../../../../../components/styleguide";
import DependentField from "../DependentField";

type DependentFormItemProps = FormItemProps & {
  children: ReactElement;
  dependency: {
    name: string;
    label: string;
  };
};
const DependentFormItem: FC<DependentFormItemProps> = ({ children, dependencies, dependency, ...props }) => {
  const { t } = useTranslation();

  // rewrite it with useMemo
  const newDependencies = useMemo(() => {
    if (dependencies) {
      return [...dependencies, dependency.name];
    }

    return [dependency.name];
  }, [dependencies, dependency.name]);

  return (
    <Form.Item dependencies={newDependencies} {...props}>
      <DependentField
        dependency={dependency.name}
        message={t("This setting depends on {{field}}", { field: dependency.label })}
      >
        {children}
      </DependentField>
    </Form.Item>
  );
};

export default DependentFormItem;
