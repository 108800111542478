import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import UserGuide from "components/UserGuide";
import { isEmpty, toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useDeleteForumBuildingMutation, useDeleteVenueBuildingMutation } from "../../../api/mutations/buildings";
import { useFacilitiesQuery as useVenueFacilitiesQuery } from "../../../api/queries/facilities";
import { useForumFacilitiesQuery } from "../../../api/queries/forumFacilities";
import KEYS from "../../../api/queries/keys";
import { Button, Col, Collapse, Divider, Form, Modal, Row } from "../../../components/styleguide";
import { AdminTypes } from "../../../enums/common";
import ErrorTypes from "../../../error-handling/errorTypes";
import VenueContext from "../../../pages/venueContext";
import { BuildingLabels } from "../constants";
import Bedrooms from "./Bedrooms";
import EditFacility from "./EditFacility";
import Facilities from "./Facilities";
import NewFacility from "./NewFacility";

import styles from "./styles.module.less";

const { Panel } = Collapse;

export default function Building({ ui, building }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { buildingId, id } = useParams();
  const forumBuildingId = toNumber(buildingId);
  const forumId = toNumber(id);
  const { venueId, editBuilding } = useOutletContext();

  const venueActions = useContext(VenueContext);

  const { data: { data: forumFacilities = [] } = {} } = useForumFacilitiesQuery(
    {
      forumId,
      forumBuildingId,
    },
    { enabled: !!forumId },
  );
  const { data: { data: venueFacilities = [] } = {} } = useVenueFacilitiesQuery(
    {
      venueId,
      buildingId: toNumber(buildingId),
    },
    { enabled: !!venueId && !!buildingId && !forumId },
  );

  const deleteForumBuildingMutation = useDeleteForumBuildingMutation();
  const deleteVenueBuildingMutation = useDeleteVenueBuildingMutation();

  const {
    onFacilityCreateSubmit,
    isFacilityCreateLoading,
    onFacilityEditSubmit,
    isFacilityEditLoading,
    onFacilityDeleteSubmit,
    meetingRoomsQuery,
    conferenceRoomsQuery,
    speedMeetingsQuery,
    restaurantsQuery,
    bedroomsQuery,
    deleteTableMutate,
    onBedroomTableChange,
  } = venueActions;

  const [isNewFacilityVisible, setIsNewFacilityVisible] = useState(false);
  const [isEditFacilityVisible, setIsEditFacilityVisible] = useState(false);
  const [selectedFacilityId, setSelectedFacilityId] = useState(0);

  const [newFacilityForm] = Form.useForm();
  const [editFacilityForm] = Form.useForm();

  const showNewFacility = (resetFields = true) => {
    if (resetFields) {
      newFacilityForm.resetFields();
    }
    return setIsNewFacilityVisible(true);
  };
  const showEditFacility = useCallback(
    (facility) => {
      setSelectedFacilityId(facility.id);
      editFacilityForm.resetFields();
      setIsEditFacilityVisible(true);
    },
    [editFacilityForm],
  );

  const hideNewFacility = () => setIsNewFacilityVisible(false);
  const hideEditFacility = () => {
    setIsEditFacilityVisible(false);
    setSelectedFacilityId(0);
  };

  const handleFacilityCreateError = ({ response }) => {
    if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
      newFacilityForm.setFields([
        {
          name: "code",
          errors: [t("facilityCodeNotUnique")],
        },
      ]);
    }
  };

  const handleFacilityEditError = ({ response }) => {
    if (ErrorTypes.isOfType(response, ErrorTypes.ValueOutOfRange)) {
      editFacilityForm.setFields([
        {
          name: "places",
          errors: [
            t("The number of places should be equal to or more than {{sessionMaxPlaces}}", {
              sessionMaxPlaces: response.data.meta.RangeStartValue,
            }),
          ],
        },
      ]);
    }
  };

  const submitFacility = async (values) => {
    await onFacilityCreateSubmit(values)
      .then(hideNewFacility, handleFacilityCreateError)
      .then(() => {
        queryClient.resetQueries([KEYS.GET_FORUM_FACILITIES]);
        queryClient.resetQueries([KEYS.GET_FACILITIES]);
      });
  };

  const submitFacilityEdit = (values, originalFacilityType) => {
    onFacilityEditSubmit(values, selectedFacilityId, originalFacilityType).then(
      hideEditFacility,
      handleFacilityEditError,
    );
  };

  const handleBuildingEdit = (e) => {
    e.stopPropagation();
    editBuilding(forumBuildingId);
  };

  const handleBuildingDelete = (e) => {
    e.stopPropagation();

    if (!isEmpty(forumFacilities)) {
      toast.error(
        t("The building cannot be deleted as it has assigned facilities. Please delete the facilities first."),
      );
    } else {
      const handleDeleteError = ({ response }) => {
        if (response.status === 409) {
          toast.error(
            t("The building cannot be deleted as it has assigned facilities. Please delete the facilities first."),
          );
        } else {
          toast.error(t("Something went wrong"));
        }
      };

      Modal.confirm({
        title: t("confirmation"),
        content: !isEmpty(venueFacilities)
          ? t("The building has assigned facilities. Do you want to proceed with its deletion?")
          : t("Do you want to proceed with deletion of this building?"),
        icon: <ExclamationCircleOutlined />,
        okText: t("yes"),
        okType: "danger",
        confirmLoading: deleteForumBuildingMutation.isLoading || deleteVenueBuildingMutation.isLoading,
        cancelText: t("no"),
        onOk: async () => {
          if (forumId) {
            await deleteForumBuildingMutation.mutateAsync(
              { forumId, forumBuildingId },
              {
                onSuccess: () => {
                  navigate(`../venues/${venueId}`);
                  queryClient.invalidateQueries([KEYS.GET_FORUM_BUILDINGS]);
                  toast.success(t("Building deleted successfully"));
                },
                onError: handleDeleteError,
              },
            );
          } else {
            await deleteVenueBuildingMutation.mutateAsync(
              { venueId, buildingId: toNumber(buildingId) },
              {
                onSuccess: async () => {
                  navigate(`../venues/${venueId}`);
                  await queryClient.invalidateQueries([KEYS.GET_BUILDINGS]);
                  toast.success(t("Building deleted successfully"));
                },
                onError: handleDeleteError,
              },
            );
          }
        },
      });
    }
  };

  const boxes = BuildingLabels.map((label) => (
    <Col span={12} key={label.dataindex}>
      <Row gutter={32}>
        <Col span={12}>
          <label style={{ wordBreak: "break-word" }}>{t(`buildingLabels.${label.title}`)}</label>
        </Col>
        <Col span={12} style={{ wordBreak: "break-all" }}>
          {building[label.dataindex] ?? "-"}
        </Col>
      </Row>
      <Divider />
    </Col>
  ));

  const onForumMeetingFacilityDelete = (facility) => {
    const hasTablesAssigned = facility.facilityTables && facility.facilityTables.length > 0;

    Modal.confirm({
      title: t("Delete facility {{facilityName}}", { facilityName: facility.name }),
      content: hasTablesAssigned
        ? t("The facility has assigned tables, do you want to proceed with its deletion?")
        : t("Do you want to proceed with deletion of this facility?"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("no"),
      okText: t("Yes"),
      onOk: async () => {
        await onFacilityDeleteSubmit(facility);
      },
    });
  };

  const FACILITIES = [
    {
      name: "meetingRooms",
      key: "meeting-rooms",
      header: t("Meeting Rooms"),
      query: meetingRoomsQuery,
      props: {
        onDelete: onForumMeetingFacilityDelete,
      },
    },
    {
      name: "conferenceRooms",
      key: "conference-rooms",
      header: t("Conference Rooms"),
      query: conferenceRoomsQuery,
      props: {
        onDelete: onForumMeetingFacilityDelete,
      },
    },
    {
      name: t("Restaurants"),
      query: restaurantsQuery,
      key: "restaurants",
      props: {
        onDelete: onForumMeetingFacilityDelete,
      },
    },
    {
      name: "speedMeetings",
      header: t("Speed Meetings"),
      key: "speed-meetings",
      query: speedMeetingsQuery,
      props: {
        onDelete: onForumMeetingFacilityDelete,
      },
    },
    {
      name: "Bedrooms",
      header: t("Bedrooms"),
      query: bedroomsQuery,
      key: "bedrooms",
      onChange: onBedroomTableChange ?? bedroomsQuery.handleSort,
      Component: Bedrooms,
      props: {
        venueId,
        buildingId: forumBuildingId,
        isLoading: isFacilityEditLoading || isFacilityCreateLoading,
        onDelete: onForumMeetingFacilityDelete,
      },
    },
  ];

  const isFacilitiesLoading =
    meetingRoomsQuery.response.isLoading ||
    conferenceRoomsQuery.response.isLoading ||
    speedMeetingsQuery.response.isLoading ||
    restaurantsQuery.response.isLoading;

  const injectedDeleteTableMutate = ({ facilityId, tableId }, options) => {
    deleteTableMutate({ buildingId: forumBuildingId, facilityId, tableId }, options);
  };

  return (
    <VenueContext.Provider
      value={{
        ...venueActions,
        deleteTableMutate: injectedDeleteTableMutate,
      }}
    >
      <div className={styles.container}>
        <NewFacility
          visible={isNewFacilityVisible}
          onClose={hideNewFacility}
          onSubmit={submitFacility}
          form={newFacilityForm}
          isLoading={isFacilityCreateLoading || isFacilitiesLoading}
        />
        <EditFacility
          ui={ui}
          isLoading={isFacilityEditLoading || isFacilitiesLoading}
          facilityId={selectedFacilityId}
          visible={isEditFacilityVisible}
          form={editFacilityForm}
          onClose={hideEditFacility}
          onSubmit={submitFacilityEdit}
        />
        <Collapse
          className="collapse-parent"
          bordered={false}
          defaultActiveKey={[
            "details",
            "meeting-rooms",
            "conference-rooms",
            "restaurants",
            "speed-meetings",
            "bedrooms",
          ]}
        >
          <UserGuide.Panel
            id="details"
            title="Details"
            articleId={
              ui === AdminTypes.ForumAdmin
                ? "16423937374365-buildings-and-facilities"
                : "16426508054301-buildings-and-facilities"
            }
            key="details"
            extra={[
              <Button key="editBtn" onClick={handleBuildingEdit} style={{ marginRight: 8 }}>
                {t("Edit")}
              </Button>,
              <Button key="deleteBtn" onClick={handleBuildingDelete}>
                {t("Delete")}
              </Button>,
            ]}
          >
            <Row gutter={32}>{boxes}</Row>
          </UserGuide.Panel>
          <div className={styles.buttonContainer}>
            <Divider />
            <Button onClick={showNewFacility}>{t("addFacility")}</Button>
            <Divider />
          </div>

          {FACILITIES.map((facility) => {
            const Component = facility.Component ?? Facilities;

            return (
              <Panel
                id={facility.id || facility.key || facility.name}
                className="collapse-panel"
                header={t(facility.header ?? facility.name)}
                key={facility.key || facility.name}
              >
                <Component
                  data={facility.query.response?.data?.data}
                  onSort={facility.onChange ?? facility.query.handleSort}
                  onEdit={showEditFacility}
                  loading={facility.query.response.isLoading}
                  {...(facility.props || {})}
                />
              </Panel>
            );
          })}
        </Collapse>
      </div>
    </VenueContext.Provider>
  );
}

Building.propTypes = {
  ui: PropTypes.oneOf(AdminTypes),
  building: PropTypes.object,
};
