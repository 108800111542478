import { Button, Col } from "components/styleguide";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

const menuItems = [
  {
    title: "Key Dates",
    link: "forum-admin/it-contract",
  },
  {
    title: "Groups & Tasks",
    link: "forum-admin/groups",
  },
  {
    title: "Questionnaires",
    link: "forum-admin/questionnaires",
  },
  {
    title: "Selection Sites",
    link: "forum-admin/selection-sites",
  },
  {
    title: "Reports",
    link: "reporting",
  },
];

export default function DashboardMenu() {
  const { t } = useTranslation();

  return (
    <>
      {menuItems.map(({ title, link }) => (
        <Col key={title} lg={4} md={8} sm={12}>
          <Button className={styles.button} href={link} rel="noreferrer" type="primary">
            {t(title)}
          </Button>
        </Col>
      ))}
    </>
  );
}
