import { DeleteFilled } from "@ant-design/icons";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteSeminarMutation, useReorderSeminarsMutation } from "api/mutations/seminars";
import KEYS from "api/queries/keys";
import { useSeminarsQuery } from "api/queries/seminars";
import apiClient from "api-client";
import { DraggableTable } from "components/DraggableTable";
import { Button, Col, Modal, Row, Tag, Typography } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { useQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { stringSorter } from "utils/sorterUtils";

import SeminarForm from "../Edit";

import styles from "./styles.module.less";

const { confirm } = Modal;

const SpeakerTag = ({ speaker }) => (
  <Tag className={styles.tag} color={"default"}>
    {speaker.firstName + " " + speaker.surname}
  </Tag>
);

SpeakerTag.propTypes = {
  speaker: PropTypes.object,
};

export default function Seminars() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const forumId = toNumber(useParams().id);

  const { mutate: reorderSeminarsMutate } = useReorderSeminarsMutation();

  const [seminars, setSeminars] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const [activeSeminar, setActiveSeminar] = useState(null);
  const [seminarIdToDelete, setSeminarIdToDelete] = useState(null);

  const showDrawer = () => setIsDrawerVisible(true);

  const deleteSeminarMutation = useDeleteSeminarMutation();

  const { response: query } = useQueryWithPaginationAndOrder(
    useSeminarsQuery,
    {
      onSuccess: ({ data }) => {
        setSeminars(data);
      },
    },
    { forumId },
  );

  const hideDrawer = () => {
    setActiveSeminar(null);
    setIsDrawerVisible(false);
  };

  const editSeminar = useCallback((seminar) => {
    setActiveSeminar(seminar);
    showDrawer(true);
  }, []);

  const handleDelete = useCallback(
    async (seminar) => {
      setSeminarIdToDelete(seminar.id);

      const requestParams = {
        forumId,
        seminarId: seminar.id,
      };
      const { data: seminarPreferences } = await apiClient.ForumAttendees.seminarPreference.getById(requestParams);
      const { data: speakerDocuments } = await apiClient.ForumVisualSpeakerDocument.get(requestParams);

      const preferenceMessage = seminarPreferences.length
        ? t("This seminar was selected as an attendee's preferences. ")
        : "";
      const speakerDocumentMessage = speakerDocuments.length
        ? t("The seminar will be deleted with associated documents. ")
        : "";
      const popupMessage =
        preferenceMessage + speakerDocumentMessage + t("Do you want to proceed with deletion of this seminar?");

      confirm({
        title: t("confirmation"),
        content: <Typography.Text>{popupMessage}</Typography.Text>,
        closable: true,
        okText: t("yes"),
        okType: "danger",
        cancelText: t("no"),
        onOk: () => {
          return deleteSeminarMutation.mutateAsync(
            { forumId, forumSeminarId: seminar.id },
            {
              onSettled: () => {
                setSeminarIdToDelete(null);
              },
              onSuccess: () => {
                queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
                toast.success(t("Seminar has been deleted successfully"));
              },
              onError: ({ response }) => {
                if (response.status === 409) {
                  toast.error(
                    t(
                      "The seminar has assigned sessions. To proceed with deletion, please, delete the sessions firstly",
                    ),
                  );
                } else if (response?.data?.Message) {
                  toast.error(response?.data?.message);
                } else {
                  toast.error(t("Something went wrong"));
                }
              },
            },
          );
        },
        onCancel: () => setSeminarIdToDelete(null),
      });
    },
    [forumId, t, queryClient, deleteSeminarMutation],
  );

  const getSeminarIndexById = (id) => seminars.findIndex((item) => item.id === id);

  const reorderSeminars = (seminarId, order) => {
    reorderSeminarsMutate(
      {
        forumId,
        seminarId,
        reorderRequestModel: {
          order,
        },
      },
      {
        onSuccess: () => queryClient.invalidateQueries([KEYS.GET_SEMINARS]),
      },
    );
  };

  const onDragEnd = ({ active, over }) => {
    const oldIndex = getSeminarIndexById(active.id);
    const newIndex = getSeminarIndexById(over?.id);
    const updatedSeminars = arrayMove(seminars, oldIndex, newIndex);

    reorderSeminars(updatedSeminars[newIndex].id, newIndex + 1);
  };

  const columns = useMemo(
    () => [
      {
        title: t("Display Code"),
        dataIndex: "code",
        sorter: (a, b) => stringSorter(a.code, b.code),
      },
      {
        title: t("Title"),
        dataIndex: "title",
        sorter: (a, b) => stringSorter(a.title, b.title),
      },
      {
        title: t("Stream Name"),
        dataIndex: "forumStreamName",
        sorter: (a, b) => stringSorter(a.forumStreamName, b.forumStreamName),
      },
      {
        title: t("Seminar Type Name"),
        dataIndex: "forumSeminarTypeName",
        sorter: (a, b) => stringSorter(a.forumSeminarTypeName, b.forumSeminarTypeName),
      },
      {
        title: t("Assigned Speakers"),
        dataIndex: "assignedSpeakers",
        render: (speakers) => {
          if (!speakers.length) {
            return null;
          }
          return speakers.map((speaker) => <SpeakerTag key={speaker.id} speaker={speaker} />);
        },
      },
      {
        title: t("Assigned Sessions"),
        dataIndex: "sessionsCount",
        render: (count) => <span>{t("Sessions", { count })}</span>,
      },
      {
        title: t("Languages"),
        dataIndex: "languages",
        width: "10%",
        render: (languages) => languages.map(({ languageId, languageName }) =>
          <Typography.Text key={languageId} style={{ display: "block" }}>{languageName}</Typography.Text>),
      },
      { title: t("Short Description"), dataIndex: "shortDescription" },
      {
        dataIndex: "id",
        render: (_, record) => (
          <div className={styles.actions}>
            <Button onClick={() => editSeminar(record)}>{t("Edit")}</Button>
            <DeleteFilled className={styles.deleteIcon} onClick={() => handleDelete(record)} />
          </div>
        ),
      },
    ],
    [editSeminar, t, handleDelete],
  );

  return (
    <div className="table-wrapper">
      <SeminarForm seminars={seminars} visible={isDrawerVisible} onClose={hideDrawer} seminar={activeSeminar} />
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserGuide.Title title="Seminars" articleId="16426047015325-seminars" />
        </Col>
        <Col span={24}>
          <Button onClick={showDrawer}>{t("Add New Seminar")}</Button>
        </Col>
        <Col span={24}>
          <DraggableTable
            id="seminarsTable"
            dataSource={seminars}
            columns={columns}
            loading={query.isLoading || !!seminarIdToDelete}
            pagination={false}
            rowKey="id"
            onDragEnd={onDragEnd}
          />
        </Col>
      </Row>
    </div>
  );
}
