import { DeleteOutlined } from "@ant-design/icons";
import React, { MouseEventHandler, useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Menu, Modal, Typography } from "../../../../../../../components/styleguide";
import { SelectionSiteContentSelectionSiteContentPage } from "../../../../../../../generated/api";
import SelectionSiteStatusContext from "../../../../context/selection-site-status";
import DependenciesList from "../DependenciesList";
import DependenciesRenderer from "./components/DependenciesRenderer";

interface ItemLabelProps {
  title: string;
  itemId: number;
  pages: Array<SelectionSiteContentSelectionSiteContentPage>;
  isSelected?: boolean;
  hasErrors?: boolean;
  onRemoveConfirm?: () => void;
  onClick?: () => void;
}

const ItemLabel = ({ title, itemId, pages, isSelected, hasErrors, onRemoveConfirm, onClick }: ItemLabelProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const status = useContext(SelectionSiteStatusContext);
  const isDeletionDisabled = status === "Open";

  const handleRemoveConfirm = async () => {
    setIsLoading(true);
    try {
      await onRemoveConfirm?.();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    Modal.confirm({
      title: "Confirmation",
      content: (
        <DependenciesList
          defaultMessage={t("Do you want to proceed with deletion of {{itemType}} item type?", { itemType: title })}
          foundDependenciesMessage={(items) => {
            return (
              <>
                <Typography.Text>
                  {t("This {{itemType}} item type is associated with:", { itemType: title })}
                </Typography.Text>
                <DependenciesRenderer items={items} content={{ pages }} />
                <Typography.Text>{t("Do you want to proceed with its deletion?")}</Typography.Text>
              </>
            );
          }}
          itemIds={[itemId]}
          content={{ pages }}
        />
      ),
      closable: true,
      onOk: () => {
        handleRemoveConfirm();
      },
    });
  };

  return (
    <Menu.ItemLabel
      isSelected={isSelected}
      append={
        <Button
          loading={isLoading}
          disabled={isLoading || isDeletionDisabled}
          onClick={handleRemoveButtonClick}
          type="text"
          icon={<DeleteOutlined />}
          size="small"
        />
      }
      onClick={onClick}
    >
      <Typography.Text type={hasErrors ? "danger" : undefined}>{title}</Typography.Text>
    </Menu.ItemLabel>
  );
};

export default ItemLabel;
