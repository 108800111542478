import { useQueryClient } from "@tanstack/react-query";
import KEYS from "api/queries/keys";
import { toNumber } from "lodash";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useCreateSeminarSessionMutation,
  useUpdateSeminarSessionMutation,
} from "../../../../api/mutations/forumSeminarSessions";
import { useForumVenueFacilitiesQuery, useForumVenueTimeslotSessionsQuery } from "../../../../api/queries/forumVenues";
import { useAssignedSpeakersQuery } from "../../../../api/queries/seminars";
import FullHeightForm from "../../../../components/FullHeightForm";
import { Button, Drawer, Form, Input } from "../../../../components/styleguide";
import { FIXED_CODES } from "../../../../constants/seminarTypes";
import ErrorTypes from "../../../../error-handling/errorTypes";
import {
  AvailableTimeSlotSessionModel,
  FacilityResponseModel,
  FacilityType as ApiFacilityType,
  ForumSeminarSessionRequestModel,
  ForumSeminarSessionWithTimeslotSession,
  ForumSeminarWithTypeAndStreamAndSpeakers,
  SpeakerForumAttendee,
} from "../../../../generated/api";
import { getFormFields } from "./helpers";

interface SeminarSessionFormProps {
  visible: boolean;
  onClose: () => void;
  seminar: ForumSeminarWithTypeAndStreamAndSpeakers;
  session?: ForumSeminarSessionWithTimeslotSession;
}

const SeminarSessionForm: FC<SeminarSessionFormProps> = ({ visible = false, onClose, seminar, session }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  const [conferenceRooms, setConferenceRooms] = useState<FacilityResponseModel[]>([]);
  const [timeslotSessions, setTimeslotSessions] = useState<Array<AvailableTimeSlotSessionModel>>([]);
  const [facilityId, setFacilityId] = useState<number | undefined>(undefined);
  const [facility, setFacility] = useState<FacilityResponseModel | null>(null);

  const createMutation = useCreateSeminarSessionMutation();
  const updateMutation = useUpdateSeminarSessionMutation();

  useEffect(() => {
    if (facilityId) {
      setFacility(conferenceRooms.filter((c) => c.id === facilityId)[0]);
    }
    // IDK why only `facilityId` is needed here, in case on any issues
    // ask Boyko about it
  }, [facilityId, conferenceRooms]);

  useEffect(() => {
    if (visible) {
      setFacilityId(session?.forumMeetingFacilityId);
      form.resetFields(["includeMeal"]);
      return;
    }

    setFacilityId(undefined);
    setFacility(null);
    setTimeslotSessions([]);
    form.resetFields();
  }, [visible, session, form]);

  useForumVenueFacilitiesQuery(
    { forumId, types: [ApiFacilityType.ConferenceRoom] },
    {
      onSuccess: ({ data }: { data: FacilityResponseModel[] }) => {
        setConferenceRooms(data);

        // Not sure what this was meant to do, an array does not have the property `facilityId`
        // if (data.facilityId) {
        //   setFacilityId(data.facilityId);
        // }
      },
      enabled: visible,
    },
  );

  const onFacilityChange = (value: number) => {
    setFacilityId(value);
    form.setFieldValue("timeSlotSessionId", null);
  };

  useForumVenueTimeslotSessionsQuery(
    { forumId, facilityId: toNumber(facilityId), timeSlotType: "Conference" },
    {
      onSuccess: ({ data }: { data: Array<AvailableTimeSlotSessionModel> }) => {
        setTimeslotSessions(data);
      },
      enabled: visible && !!facilityId,
    },
  );

  const { data: { data: seminarAssignedSpeakers = [] } = {} } = useAssignedSpeakersQuery({
    forumId,
    forumSeminarId: seminar.id,
  }) as { data: { data: SpeakerForumAttendee[] } };

  const submit = (values: ForumSeminarSessionRequestModel) => {
    if (session) {
      updateMutation.mutate(
        {
          forumId,
          forumSeminarId: seminar.id,
          forumSeminarSessionId: session.id,
          forumSeminarSessionRequestModel: values,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_SEMINAR_SESSIONS]);
            onClose();
          },
          onError: ({ response }) => {
            onClose();
            if (ErrorTypes.isOfType(response, ErrorTypes.ObjectModificationNotAllowed)) {
              toast.error(t("The session cannot be edited, it has assigned attendees"));
            } else {
              toast.error(t("Something went wrong"));
            }
          },
        },
      );
    } else {
      createMutation.mutate(
        { forumId, forumSeminarId: seminar.id, forumSeminarSessionRequestModel: values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_SEMINAR_SESSIONS]);
            queryClient.invalidateQueries([KEYS.GET_SEMINARS]);
            onClose();
          },
        },
      );
    }
  };

  const isMutationLoading = createMutation.isLoading || updateMutation.isLoading;

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      placement="right"
      title={session ? t("Edit Session") : t("Add New Session")}
      size="large"
      destroyOnClose
    >
      <FullHeightForm
        className=""
        layout="vertical"
        id="sessionForm"
        name="session-form"
        onFinish={submit}
        labelWrap
        form={form}
        actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
        actionsAppend={
          <Button htmlType="submit" type="primary" loading={isMutationLoading}>
            {t(session ? "Update" : "Create")}
          </Button>
        }
      >
        {getFormFields({
          t,
          conferenceRooms,
          facility,
          timeslotSessions,
          seminarSpeakers: seminarAssignedSpeakers,
          onFacilityChange,
          session,
        }).map((f) => (
          <Form.Item
            initialValue={session ? session[f.dataIndex as keyof typeof session] : f.initialValue}
            label={t(f.title)}
            name={f.dataIndex}
            key={f.dataIndex}
            rules={f.rules ?? []}
            extra={f.extra}
          >
            {f.component ?? <Input disabled={f.dataIndex === "code" && FIXED_CODES.includes(seminar?.code)} />}
          </Form.Item>
        ))}
      </FullHeightForm>
    </Drawer>
  );
};

export default SeminarSessionForm;
