import { useQueryClient } from "@tanstack/react-query";
import { BaseOptionType } from "antd/lib/select";
import { useUpdateForumAttendeeSessionMeetingLocationsMutation } from "api/mutations/forumAttendeeSessionMeetingLocation";
import { useForumAttendeesSessionMeetingLocationsQuery } from "api/queries/forumAttendeeSessionMeetingLocations";
import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import { useGetForumGroupsQuery } from "api/queries/groups";
import KEYS from "api/queries/keys";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { useTimeslotsQuery } from "api/queries/timeslots";
import { useAllTimeslotsSessionsQuery } from "api/queries/timeslotSession";
import { Select, Space } from "components/styleguide";
import Info from "components/UserGuide/Info";
import AttendeeStatus from "enums/AttendeeStatus";
import TimeSlotTypes from "enums/TimeSlotType";
import {
  ForumGroupResponseModel,
  MeetingLocationResponseModel,
  PageResponseMeetingLocationResponseModel,
  TimeSlotResponseModel,
  TimeSlotType,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import MeetingLocationsExportButton from "../MeetingLocationsExportButton";
import MeetingLocationsTable from "../Table";

import styles from "../styles.module.less";

export default function MeetingLocationsByTimeslot() {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [meetingLocations, setMeetingLocations] = useState<MeetingLocationResponseModel[]>([]);
  const [attendeeNameSearch, setAttendeeNameSearch] = useState<string>();
  const [peopleTypeFilter, setPeopleTypeFilter] = useState<string[]>();
  const [facilityFilter, setFacilityFilter] = useState<number[]>();
  const [companyNameSearch, setCompanyNameSearch] = useState<string>();
  const [timeslotFilter, setTimeslotFilter] = useState<number | undefined | null>(null);
  const [timeslotFilterOptions, setTimeslotFilterOptions] = useState<BaseOptionType[]>();
  const [groupFilter, setGroupFilter] = useState<number[] | null | undefined>(null);
  const [peopleTypeFilterOptions, setPeopleTypeFilterOptions] = useState<BaseOptionType[]>([]);
  const [facilityFilterOptions, setFacilityFilterOptions] = useState<BaseOptionType[]>([]);
  const [groupFilterOptions, setGroupFilterOptions] = useState<BaseOptionType[]>([]);

  const updateMutation = useUpdateForumAttendeeSessionMeetingLocationsMutation();

  const {
    response: query,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useForumAttendeesSessionMeetingLocationsQuery,
    {
      enabled: groupFilter !== null || timeslotFilter !== null,
      onSuccess: ({ data }: { data: PageResponseMeetingLocationResponseModel }) => {
        setMeetingLocations(data.items ?? []);
      },
    },
    {
      forumId,
      forumAttendeeName: attendeeNameSearch,
      forumAttendeeParticipationCodes: peopleTypeFilter,
      forumCompanyName: companyNameSearch,
      timeSlotId: timeslotFilter,
      forumGroupIds: groupFilter,
      forumFacilityIds: facilityFilter,
      attendeeStatuses: [AttendeeStatus.Attending, AttendeeStatus.AttendingReplacement],
    },
  );

  const { isLoading: isLoadingForumGroups } = useGetForumGroupsQuery(
    {
      forumId,
    },
    {
      onSuccess: ({ data = [] }: { data: ForumGroupResponseModel[] }) => {
        const codes = data.map(({ id, name }) => ({ value: id, label: name }));
        const executivesId = data.find((g) => g.name === "Executives")?.id;

        setGroupFilterOptions(codes);

        if (groupFilter === null && executivesId) {
          setGroupFilter([executivesId]);
        }
      },
    },
  );

  const { isLoading: isTimeslotsLoading } = useTimeslotsQuery(
    {
      forumId,
    },
    {
      onSuccess: ({ data = [] }: { data: TimeSlotResponseModel[] }) => {
        const timeslots = data
          .filter(({ timeSlotType }) => timeSlotType !== TimeSlotType.Conference)
          .map(({ id, description }) => ({ value: id, label: description }));
        const breakfastId = data.find((g) => g.timeSlotType === TimeSlotTypes.Breakfast)?.id;

        setTimeslotFilterOptions(timeslots);

        if (timeslotFilter === null) {
          setTimeslotFilter(breakfastId);
        }
      },
    },
  );

  const { data: { data: sessions = [] } = {}, isLoading: isLoadingSessions } = useAllTimeslotsSessionsQuery({
    forumId,
  });

  const { isLoading: isCodesLoading } = useParticipationCodesQuery(
    {
      ignorePagination: true,
    },
    {
      onSuccess: ({ data: { items = [] } = {} }) => {
        const codes = items.map(({ code }) => ({ value: code, label: code }));
        setPeopleTypeFilterOptions(codes);
      },
    },
  );

  const { data: { data: facilities = [] } = {}, isLoading: isFacilitiesLoading } = useForumVenueFacilitiesQuery(
    {
      forumId,
    },
    {
      onSuccess: ({ data }) => {
        const facilitiesOptions = data?.map(({ id, name }) => ({ value: id, label: name }));
        setFacilityFilterOptions(facilitiesOptions);
      },
    },
  );

  const handleTimeslotChange = (value: number) => {
    setTimeslotFilter(value);
  };

  const handleGroupChange = (value: number[]) => {
    setGroupFilter(value);
  };

  const handleMeetingLocationUpdate = (attendeeId: number, model: MeetingLocationResponseModel) => {
    updateMutation.mutate(
      {
        forumId,
        attendeeId,
        timeSlotId: model.timeSlotId as number,
        attendeeSessionMeetingLocationRequestModel: {
          timeSlotSessionId: model.timeSlotSessionId,
          facilityId: model.forumFacilityId,
          tableId: model.forumTableId,
          isLocked: model.isLocked,
          ratio: model.ratio,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEES_SESSIONS_MEETING_LOCATIONS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_SESSION_MEETING_LOCATIONS]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS_BY_ATTENDEE_ID]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_MEETING_LOCATIONS]);
        },
      },
    );
  };

  const handleFacilityChange = (facilityId: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.forumFacilityId = facilityId;
    meetingLocation.forumTableId = null;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleTableChange = (tableId: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.forumTableId = tableId;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleRatioChange = (ratio: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.ratio = ratio;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleSessionChange = (timeSlotSessionId: number, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.timeSlotSessionId = timeSlotSessionId;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const handleLockedClicked = (isLocked: boolean, attendeeId: number) => {
    const meetingLocation = meetingLocations.filter((m) => m.forumAttendeeId === attendeeId)[0];
    meetingLocation.isLocked = isLocked;

    handleMeetingLocationUpdate(attendeeId, meetingLocation);
  };

  const isTableLoading =
    isLoadingSessions ||
    isTimeslotsLoading ||
    isCodesLoading ||
    isFacilitiesLoading ||
    query.isLoading ||
    updateMutation.isLoading;

  return (
    <div id="meeting-locations-time-type-tab">
      <Space direction="vertical" fullWidth={true}>
        <div className={styles.header}>
          <Space fullWidth={true} direction="horizontal">
            <Select
              placeholder={t("Filter by Timeslot")}
              className={styles.filter}
              options={timeslotFilterOptions}
              value={timeslotFilter}
              onChange={handleTimeslotChange}
            />
            <Select
              placeholder={t("Filter by Group")}
              className={styles.filter}
              options={groupFilterOptions}
              value={groupFilter}
              onChange={handleGroupChange}
              allowClear
              optionFilterProp="label"
              showSearch={true}
              loading={isLoadingForumGroups}
              disabled={isLoadingForumGroups}
              mode="multiple"
            />
            <MeetingLocationsExportButton
              timeslotId={timeslotFilter ?? undefined}
              groupIds={groupFilter ?? undefined}
              peopleTypes={peopleTypeFilter}
              facilities={facilityFilter}
            />
          </Space>
          <Info articleId="16423895049373-meeting-locations" />
        </div>
        <MeetingLocationsTable
          meetingLocations={meetingLocations}
          facilities={facilities}
          sessions={sessions}
          isLoading={isTableLoading}
          peopleTypeFilterOptions={peopleTypeFilterOptions}
          facilityFilterOptions={facilityFilterOptions}
          pagination={pagination}
          setAttendeeNameSearch={setAttendeeNameSearch}
          setPeopleTypeFilter={setPeopleTypeFilter}
          setFacilityFilter={setFacilityFilter}
          setCompanyNameSearch={setCompanyNameSearch}
          onFacilityChange={handleFacilityChange}
          onTableChange={handleTableChange}
          onRatioChange={handleRatioChange}
          onSessionChange={handleSessionChange}
          onLockedClicked={handleLockedClicked}
          onSort={handleSort}
        />
      </Space>
    </div>
  );
}
