import {
  DocumentCustomPdfApi,
  DocumentDefinitionStandardSectionApi,
  ForumDocumentCustomPageApi,
  ForumDocumentDefinitionSectionApi,
  ForumDocumentDefinitionWatermarksApi,
} from "generated/api";

import { initAPIClient } from "./_base";

const forumDocumentDefinitionSectionApi = initAPIClient(ForumDocumentDefinitionSectionApi);
const documentCustomPdfApi = initAPIClient(DocumentCustomPdfApi);
const forumDocumentCustomPageApi = initAPIClient(ForumDocumentCustomPageApi);
const forumDocumentDefinitionWatermarkApi = initAPIClient(ForumDocumentDefinitionWatermarksApi);
const documentDefinitionStandardSectionApi = initAPIClient(DocumentDefinitionStandardSectionApi);

const ForumDocument = {
  documentDefinitionSection: {
    getAll: forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsGet,
    get: forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdGet,
    post: forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsPost,
    delete: forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdDelete,
    put: forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdPut,
    optionsPut:
      forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdOptionsPut,
    reorderPut:
      forumDocumentDefinitionSectionApi.forumsForumIdDocumentDefinitionSectionsDocumentDefinitionSectionIdReorderPut,
  },
  documentDefinitionWatermark: {
    getAll: forumDocumentDefinitionWatermarkApi.forumsForumIdDocumentDefinitionWatermarksGet,
    post: forumDocumentDefinitionWatermarkApi.forumsForumIdDocumentDefinitionWatermarksPost,
    delete: forumDocumentDefinitionWatermarkApi.forumsForumIdDocumentDefinitionWatermarksDelete,
  },
  documentStandardSections: {
    get: documentDefinitionStandardSectionApi.documentDefinitionStandardSectionsGet,
  },
  documentCustomPdf: {
    get: documentCustomPdfApi.documentCustomPdfsGet,
    post: documentCustomPdfApi.documentCustomPdfsPost,
  },
  forumDocumentCustomPage: {
    get: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesGet,
    post: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesPost,
    elements: {
      get: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesCustomPageIdGet,
      post: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesCustomPageIdElementsPost,
      put: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut,
      delete: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete,
      reorder: forumDocumentCustomPageApi.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut,
    },
    mergeTags: {
      get: forumDocumentCustomPageApi.documentCustomPagesAvailableMergeTagsGet,
    },
  },
};

export default ForumDocument;
