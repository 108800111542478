import { Button, Select } from "components/styleguide";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { DocumentDefinitionTypes } from "../../../../generated/api";
import AddNewSectionDrawer from "./AddNewSectionDrawer";
import DocumentDefinitionsTable from "./DocumentDefinitionsTable";

interface DocumentDefinitionsProps {
  onEditDefinitionSection: (documentDefinitionSectionId: number) => void;
  setActiveDocumentDefinitionType: (activeDocumentDefinitionId: DocumentDefinitionTypes | null) => void;
}

const DocumentDefinitions: React.FC<DocumentDefinitionsProps> = ({
  onEditDefinitionSection,
  setActiveDocumentDefinitionType,
}) => {
  const { t } = useTranslation();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const getSelectedDocumentDefinition = (): DocumentDefinitionTypes | null => {
    const savedDocumentDefinition = sessionStorage.getItem("documentDefinitionType");
    return savedDocumentDefinition ? JSON.parse(savedDocumentDefinition) : null;
  };

  const [selectedDocumentDefinitionType, setSelectedDocumentDefinitionType] = useState<DocumentDefinitionTypes | null>(
    getSelectedDocumentDefinition(),
  );

  useEffect(() => {
    sessionStorage.setItem("documentDefinitionType", JSON.stringify(selectedDocumentDefinitionType));
    setActiveDocumentDefinitionType(selectedDocumentDefinitionType);
  }, [selectedDocumentDefinitionType]);

  const handleSelectChange = (value: DocumentDefinitionTypes) => {
    setSelectedDocumentDefinitionType(value);
  };

  const documentDefinitionsOptions = () => {
    const mappedDefinitions = Object.keys(DocumentDefinitionTypes)
      .map((documentDefinition) => documentDefinition as keyof typeof DocumentDefinitionTypes)
      .sort((a, b) => t(`documentDefinitionTypes.${a}`).localeCompare(t(`documentDefinitionTypes.${b}`)))
      .map((documentDefinition) => ({
        label: t(`documentDefinitionTypes.${documentDefinition}`),
        value: DocumentDefinitionTypes[documentDefinition],
      }));
    return [{ value: null, label: t("Select a document") }, ...mappedDefinitions];
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  return (
    <>
      <Select
        defaultValue={selectedDocumentDefinitionType}
        style={{ width: 350, marginRight: 12 }}
        onChange={handleSelectChange}
        options={documentDefinitionsOptions()}
      />
      {selectedDocumentDefinitionType && (
        <Button onClick={() => setIsDrawerVisible(true)}>{t("Add New Section")}</Button>
      )}
      <DocumentDefinitionsTable
        documentDefinitionType={selectedDocumentDefinitionType}
        onEdit={onEditDefinitionSection}
      />
      {selectedDocumentDefinitionType && (
        <AddNewSectionDrawer
          selectedDocumentDefinitionType={selectedDocumentDefinitionType}
          onClose={onCloseDrawer}
          isVisible={isDrawerVisible}
        />
      )}
    </>
  );
};

export default DocumentDefinitions;
