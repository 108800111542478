import { useAttendeeHistoryInformationQuery } from "api/queries/attendees";
import Table from "components/styleguide/components/Table";
import AttendeeStatus from "enums/AttendeeStatus";
import { AttendeeHistoryModel, AttendeeStatus as ApiAttendeeStatus } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps } from "utils/tableColumnUtils";

interface AttendeeHistoryInformationProps {
  forumId: number;
  attendeeId: number;
}

const AttendeeHistoryInformation = ({ forumId, attendeeId }: AttendeeHistoryInformationProps) => {
  const { t } = useTranslation();

  const [nameSearch, setNameSearch] = useState<string | undefined>();
  const [codeSearch, setCodeSearch] = useState<string | undefined>();

  const {
    response: { isLoading, data: { data: { items: historyInformation = [] } = {} } = {} },
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeeHistoryInformationQuery,
    {},
    {
      forumId: forumId,
      attendeeId: attendeeId,
      ForumName: nameSearch,
      ForumCode: codeSearch,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 20, 50] },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, _: string, searchSet: string) => {
    switch (searchSet) {
      case "forumName": {
        setNameSearch(selectedKeys[0]);
        break;
      }
      case "forumCode": {
        setCodeSearch(selectedKeys[0]);
        break;
      }
    }

    confirm();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "forumName": {
        setNameSearch(undefined);
        break;
      }
      case "forumCode": {
        setCodeSearch(undefined);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  const columns = [
    {
      title: t("eventCode"),
      dataIndex: "forumCode",
      key: "forumCode",
      sorter: true,
      ...getColumnSearchProps("forumCode", handleSearch, handleReset, "forumCode"),
    },
    {
      title: t("eventName"),
      dataIndex: "forumName",
      key: "forumName",
      sorter: true,
      ...getColumnSearchProps("forumName", handleSearch, handleReset, "forumName"),
    },
    {
      title: t("Year"),
      dataIndex: "year",
      key: "year",
      sorter: true,
    },
    {
      title: t("attendStatus"),
      dataIndex: "attendStatus",
      key: "attendStatus",
      sorter: true,
      render: (attendStatus: ApiAttendeeStatus) => t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendStatus)}`),
    },
    {
      title: t("peopleType"),
      dataIndex: "peopleType",
      key: "peopleType",
      sorter: true,
    },
    {
      title: t("company"),
      dataIndex: "company",
      key: "company",
      sorter: true,
    },
    {
      title: t("Positive selections for"),
      dataIndex: "positiveSelectionsFor",
      key: "positiveSelectionsFor",
    },
    {
      title: t("Negative selections for"),
      dataIndex: "negativeSelectionsFor",
      key: "negativeSelectionsFor",
    },
    {
      title: t("Positive selections by"),
      dataIndex: "positiveSelectionsBy",
      key: "positiveSelectionsBy",
    },
    {
      title: t("Negative selections by"),
      dataIndex: "negativeSelectionsBy",
      key: "negativeSelectionsBy",
    },
  ];

  return (
    <Table
      id="history-information-table"
      dataSource={historyInformation}
      columns={columns}
      pagination={pagination}
      bordered={true}
      loading={isLoading}
      rowKey={(row: AttendeeHistoryModel) => `${row.forumCode}_${row.company}_${row.createdAt}`}
      onChange={handleSort}
    />
  );
};

export default AttendeeHistoryInformation;
