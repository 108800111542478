/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FacilityResponseModel } from '../apiModels';
// @ts-ignore
import { FacilityType } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseVenueResponseModel } from '../apiModels';
// @ts-ignore
import { TableResponseModel } from '../apiModels';
// @ts-ignore
import { VenueRequestModel } from '../apiModels';
// @ts-ignore
import { VenueResponseModel } from '../apiModels';
/**
 * VenueApi - axios parameter creator
 * @export
 */
export const VenueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all venues
         * @param {string} [countryCode] Search by Country code
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesGet: async (countryCode?: string, code?: string, name?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (countryCode !== undefined) {
                localVarQueryParameter['CountryCode'] = countryCode;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates venue
         * @param {VenueRequestModel} [venueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesPost: async (venueRequestModel?: VenueRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets venue facilities of specified types
         * @param {number} venueId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FacilityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdFacilitiesGet: async (venueId: number, orderBy?: string, orderDir?: string, type?: FacilityType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdFacilitiesGet', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/facilities`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets venue details
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdGet: async (venueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdGet', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete venue map
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdMapDelete: async (venueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdMapDelete', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/map`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get venue map access URL
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdMapGet: async (venueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdMapGet', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/map`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload venue map
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdMapPut: async (venueId: number, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdMapPut', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/map`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates the venue details
         * @param {number} venueId 
         * @param {VenueRequestModel} [venueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdPut: async (venueId: number, venueRequestModel?: VenueRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdPut', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets all tables by venue
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdTablesGet: async (venueId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('venuesVenueIdTablesGet', 'venueId', venueId)
            const localVarPath = `/venues/{venueId}/tables`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenueApi - functional programming interface
 * @export
 */
export const VenueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VenueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all venues
         * @param {string} [countryCode] Search by Country code
         * @param {string} [code] 
         * @param {string} [name] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesGet(countryCode?: string, code?: string, name?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseVenueResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesGet(countryCode, code, name, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates venue
         * @param {VenueRequestModel} [venueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesPost(venueRequestModel?: VenueRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesPost(venueRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets venue facilities of specified types
         * @param {number} venueId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {FacilityType} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdFacilitiesGet(venueId: number, orderBy?: string, orderDir?: string, type?: FacilityType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FacilityResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdFacilitiesGet(venueId, orderBy, orderDir, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets venue details
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdGet(venueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete venue map
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdMapDelete(venueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdMapDelete(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get venue map access URL
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdMapGet(venueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdMapGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload venue map
         * @param {number} venueId 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdMapPut(venueId: number, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdMapPut(venueId, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates the venue details
         * @param {number} venueId 
         * @param {VenueRequestModel} [venueRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdPut(venueId: number, venueRequestModel?: VenueRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdPut(venueId, venueRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets all tables by venue
         * @param {number} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async venuesVenueIdTablesGet(venueId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TableResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.venuesVenueIdTablesGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VenueApi - factory interface
 * @export
 */
export const VenueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VenueApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all venues
         * @param {VenueApiVenuesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesGet(requestParameters: VenueApiVenuesGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResponseVenueResponseModel> {
            return localVarFp.venuesGet(requestParameters.countryCode, requestParameters.code, requestParameters.name, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates venue
         * @param {VenueApiVenuesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesPost(requestParameters: VenueApiVenuesPostRequest = {}, options?: AxiosRequestConfig): AxiosPromise<VenueResponseModel> {
            return localVarFp.venuesPost(requestParameters.venueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets venue facilities of specified types
         * @param {VenueApiVenuesVenueIdFacilitiesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdFacilitiesGet(requestParameters: VenueApiVenuesVenueIdFacilitiesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<FacilityResponseModel>> {
            return localVarFp.venuesVenueIdFacilitiesGet(requestParameters.venueId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets venue details
         * @param {VenueApiVenuesVenueIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdGet(requestParameters: VenueApiVenuesVenueIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdGet(requestParameters.venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete venue map
         * @param {VenueApiVenuesVenueIdMapDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdMapDelete(requestParameters: VenueApiVenuesVenueIdMapDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdMapDelete(requestParameters.venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get venue map access URL
         * @param {VenueApiVenuesVenueIdMapGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdMapGet(requestParameters: VenueApiVenuesVenueIdMapGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdMapGet(requestParameters.venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload venue map
         * @param {VenueApiVenuesVenueIdMapPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdMapPut(requestParameters: VenueApiVenuesVenueIdMapPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdMapPut(requestParameters.venueId, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates the venue details
         * @param {VenueApiVenuesVenueIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdPut(requestParameters: VenueApiVenuesVenueIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.venuesVenueIdPut(requestParameters.venueId, requestParameters.venueRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets all tables by venue
         * @param {VenueApiVenuesVenueIdTablesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        venuesVenueIdTablesGet(requestParameters: VenueApiVenuesVenueIdTablesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<TableResponseModel>> {
            return localVarFp.venuesVenueIdTablesGet(requestParameters.venueId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for venuesGet operation in VenueApi.
 * @export
 * @interface VenueApiVenuesGetRequest
 */
export interface VenueApiVenuesGetRequest {
    /**
     * Search by Country code
     * @type {string}
     * @memberof VenueApiVenuesGet
     */
    readonly countryCode?: string

    /**
     * 
     * @type {string}
     * @memberof VenueApiVenuesGet
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof VenueApiVenuesGet
     */
    readonly name?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof VenueApiVenuesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof VenueApiVenuesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof VenueApiVenuesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof VenueApiVenuesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for venuesPost operation in VenueApi.
 * @export
 * @interface VenueApiVenuesPostRequest
 */
export interface VenueApiVenuesPostRequest {
    /**
     * 
     * @type {VenueRequestModel}
     * @memberof VenueApiVenuesPost
     */
    readonly venueRequestModel?: VenueRequestModel
}

/**
 * Request parameters for venuesVenueIdFacilitiesGet operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdFacilitiesGetRequest
 */
export interface VenueApiVenuesVenueIdFacilitiesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdFacilitiesGet
     */
    readonly venueId: number

    /**
     * 
     * @type {string}
     * @memberof VenueApiVenuesVenueIdFacilitiesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof VenueApiVenuesVenueIdFacilitiesGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {FacilityType}
     * @memberof VenueApiVenuesVenueIdFacilitiesGet
     */
    readonly type?: FacilityType
}

/**
 * Request parameters for venuesVenueIdGet operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdGetRequest
 */
export interface VenueApiVenuesVenueIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdGet
     */
    readonly venueId: number
}

/**
 * Request parameters for venuesVenueIdMapDelete operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdMapDeleteRequest
 */
export interface VenueApiVenuesVenueIdMapDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdMapDelete
     */
    readonly venueId: number
}

/**
 * Request parameters for venuesVenueIdMapGet operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdMapGetRequest
 */
export interface VenueApiVenuesVenueIdMapGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdMapGet
     */
    readonly venueId: number
}

/**
 * Request parameters for venuesVenueIdMapPut operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdMapPutRequest
 */
export interface VenueApiVenuesVenueIdMapPutRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdMapPut
     */
    readonly venueId: number

    /**
     * 
     * @type {File}
     * @memberof VenueApiVenuesVenueIdMapPut
     */
    readonly formFile?: File
}

/**
 * Request parameters for venuesVenueIdPut operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdPutRequest
 */
export interface VenueApiVenuesVenueIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdPut
     */
    readonly venueId: number

    /**
     * 
     * @type {VenueRequestModel}
     * @memberof VenueApiVenuesVenueIdPut
     */
    readonly venueRequestModel?: VenueRequestModel
}

/**
 * Request parameters for venuesVenueIdTablesGet operation in VenueApi.
 * @export
 * @interface VenueApiVenuesVenueIdTablesGetRequest
 */
export interface VenueApiVenuesVenueIdTablesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof VenueApiVenuesVenueIdTablesGet
     */
    readonly venueId: number
}

/**
 * VenueApi - object-oriented interface
 * @export
 * @class VenueApi
 * @extends {BaseAPI}
 */
export class VenueApi extends BaseAPI {
    /**
     * 
     * @summary Get all venues
     * @param {VenueApiVenuesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesGet(requestParameters: VenueApiVenuesGetRequest = {}, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesGet(requestParameters.countryCode, requestParameters.code, requestParameters.name, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates venue
     * @param {VenueApiVenuesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesPost(requestParameters: VenueApiVenuesPostRequest = {}, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesPost(requestParameters.venueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets venue facilities of specified types
     * @param {VenueApiVenuesVenueIdFacilitiesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdFacilitiesGet(requestParameters: VenueApiVenuesVenueIdFacilitiesGetRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdFacilitiesGet(requestParameters.venueId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets venue details
     * @param {VenueApiVenuesVenueIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdGet(requestParameters: VenueApiVenuesVenueIdGetRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdGet(requestParameters.venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete venue map
     * @param {VenueApiVenuesVenueIdMapDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdMapDelete(requestParameters: VenueApiVenuesVenueIdMapDeleteRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdMapDelete(requestParameters.venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get venue map access URL
     * @param {VenueApiVenuesVenueIdMapGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdMapGet(requestParameters: VenueApiVenuesVenueIdMapGetRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdMapGet(requestParameters.venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload venue map
     * @param {VenueApiVenuesVenueIdMapPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdMapPut(requestParameters: VenueApiVenuesVenueIdMapPutRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdMapPut(requestParameters.venueId, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates the venue details
     * @param {VenueApiVenuesVenueIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdPut(requestParameters: VenueApiVenuesVenueIdPutRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdPut(requestParameters.venueId, requestParameters.venueRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets all tables by venue
     * @param {VenueApiVenuesVenueIdTablesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public venuesVenueIdTablesGet(requestParameters: VenueApiVenuesVenueIdTablesGetRequest, options?: AxiosRequestConfig) {
        return VenueApiFp(this.configuration).venuesVenueIdTablesGet(requestParameters.venueId, options).then((request) => request(this.axios, this.basePath));
    }
}

