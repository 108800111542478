import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { Space, Switch, Typography } from "../../components/styleguide";

type DateTimeZoneRenderSwitcherContext = {
  showInUserTimezone: boolean;
  setShowInUserTimezone: React.Dispatch<React.SetStateAction<boolean>>;
};

const Context = React.createContext<DateTimeZoneRenderSwitcherContext>({
  showInUserTimezone: true,
  setShowInUserTimezone: () => false,
});

const Provider = ({ children }: { children: JSX.Element }) => {
  const [showInUserTimezone, setShowInUserTimezone] = useState(false);

  return (
    <Context.Provider
      value={{
        showInUserTimezone,
        setShowInUserTimezone,
      }}
    >
      {children}
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: undefined,
};

const Toggle = () => {
  const { t } = useTranslation();
  const context = useContext(Context);

  return (
    <Space>
      <Switch checked={context.showInUserTimezone} onChange={context.setShowInUserTimezone} />
      <Typography.Text>{t("Show time in my timezone")}</Typography.Text>
    </Space>
  );
};

const DateTimezoneRenderSwitcher = {
  Context,
  Provider,
  Toggle,
};

export default DateTimezoneRenderSwitcher;
