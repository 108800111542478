import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

const useCreateRoomGradeMutation = getWrappedUseMutation(apiClient.RoomGrade.post);

const useUpdateRoomGradeMutation = getWrappedUseMutation(apiClient.RoomGrade.put);

const useDeleteRoomGradeMutation = getWrappedUseMutation(apiClient.RoomGrade.delete);

export { useCreateRoomGradeMutation, useDeleteRoomGradeMutation, useUpdateRoomGradeMutation };
