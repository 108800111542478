import { Rule } from "antd/lib/form";
import { DatePicker, Form } from "components/styleguide";
import { ForumAttendeeAvailabilityResponseModel } from "generated/api";
import moment, { Moment } from "moment";
import React from "react";

import { columnDataIndexes } from ".";

const { RangePicker } = DatePicker;

export default function AvailabilityCell({
  dateTimeFormat,
  disabledDate,
  editing,
  index,
  title,
  record,
  children,
  ...restProps
}: {
  dateTimeFormat: string;
  disabledDate: (date: Moment) => boolean;
  forumOffset: number;
  editing: boolean;
  yupSync: Rule;
  index: string;
  title: string;
  type: string;
  record: ForumAttendeeAvailabilityResponseModel;
  children: React.ReactNode[];
}) {
  const isStartTime = index === columnDataIndexes.start;
  const isEndTime = index === columnDataIndexes.end;

  if (editing) {
    if (isStartTime) {
      return (
        <td {...restProps} colSpan={2}>
          <Form.Item
            name="range"
            initialValue={[
              record?.unavailableFromDate ? moment(record?.unavailableFromDate) : null,
              record?.unavailableToDate ? moment(record.unavailableToDate) : null,
            ]}
          >
            <RangePicker
              style={{ width: "100%" }}
              showTime={true}
              format={dateTimeFormat}
              disabledDate={disabledDate}
              minuteStep={5}
            />
          </Form.Item>
        </td>
      );
    } else if (isEndTime) {
      return <></>;
    }
  }

  return (
    <td {...restProps}>
      {(isStartTime || isEndTime) && children ? <span>{moment(children[1] as string).format("L LT")}</span> : children}
    </td>
  );
}
