import {
  SelectionSiteContentSelectionSiteContentPage,
  SelectionSiteContentSelectionSiteContentPageItemsInner,
} from "../../../generated/api";

type Page = SelectionSiteContentSelectionSiteContentPage;
type Item = SelectionSiteContentSelectionSiteContentPageItemsInner;

class References {
  public readonly pages: Set<Page["id"]> = new Set();

  public readonly items: Set<Item["id"]> = new Set();
}

export default References;
