import { useQueryClient } from "@tanstack/react-query";
import {
  useDeleteForumVenueMapMutation,
  useUpdateForumVenueMutation,
  useUploadForumVenueMapMutation,
} from "api/mutations/venues";
import { useGetForumVenueQuery } from "api/queries/forumVenues";
import KEYS from "api/queries/keys";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import VenueDetails from "../../components/VenueDetails";

const ForumVenue = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const updateForumVenueMutation = useUpdateForumVenueMutation();
  const uploadVenueMapMutation = useUploadForumVenueMapMutation();
  const deleteVenueMapMutation = useDeleteForumVenueMapMutation();

  const [isVenueDetailsEditing, setIsVenueDetailsEditing] = useState(false);

  const { data: { data: venue = {} } = {} } = useGetForumVenueQuery(
    { forumId },
    {
      onSuccess: ({ data }) => {
        if (!location.state || !location.state.venueName) {
          navigate(".", {
            replace: true,
            state: { ...location.state, venueName: data.name },
          });
        }
      },
    },
  );

  const uploadVenueMap = (forumId, mapFile, onSuccess) => {
    uploadVenueMapMutation.mutate(
      {
        forumId,
        formFile: mapFile,
      },
      {
        onSuccess,
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const deleteVenueMap = (forumId, onSuccess) => {
    deleteVenueMapMutation.mutate(
      {
        forumId,
      },
      {
        onSuccess,
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const onUpdateVenueSuccess = () => {
    queryClient.invalidateQueries([KEYS.GET_FORUM_VENUE]);
    setIsVenueDetailsEditing(false);
    toast.success(t("Venue successfully updated"));
  };

  const handleVenueEditSubmit = (venue) => {
    const { map, oldMap } = venue;

    updateForumVenueMutation.mutate(
      { forumId, venueRequestModel: venue },
      {
        onSuccess: () => {
          if (Array.isArray(map) && map.length) {
            uploadVenueMap(forumId, map[0]?.originFileObj, onUpdateVenueSuccess);
          } else if (!map && oldMap) {
            deleteVenueMap(forumId, onUpdateVenueSuccess);
          } else {
            onUpdateVenueSuccess();
          }
        },
      },
    );
  };

  return (
    <VenueDetails
      venue={venue}
      isForumVenue={true}
      onVenueEditSubmit={handleVenueEditSubmit}
      isUpdateLoading={updateForumVenueMutation.isLoading}
      isVenueDetailsEditing={isVenueDetailsEditing}
      setIsVenueDetailsEditing={setIsVenueDetailsEditing}
    />
  );
};

export default ForumVenue;
