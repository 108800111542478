import React, { ComponentProps, ComponentType, useState } from "react";

import { Form } from "../../../../../../../components/styleguide";

interface WithOnBlurProps<Value> {
  value?: Value;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line react/display-name
const withOnBlur =
  <Component extends ComponentType, Props extends ComponentProps<Component>, Value>(
    WrappedComponent: ComponentType<Props>,
  ) =>
  // eslint-disable-next-line react/display-name
  ({ value, onBlur, ...props }: Props & WithOnBlurProps<Value>) => {
    const [prevValue, setPrevValue] = useState(value);
    const form = Form.useFormInstance();

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const v = event.target.value;
      if (prevValue === v) {
        return;
      }
      // again, it's hard to fix this error so we just use typecasting here
      setPrevValue(v as Value);

      onBlur?.(event);
      form.submit();
    };

    // It's hard to fix these errors, so we just ignore them.
    // The thing is that it understands types correctly from generics and it is
    // much usable not then before
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <WrappedComponent value={value} onBlur={handleBlur} {...props} />;
  };

export default withOnBlur;
