import PropTypes from "prop-types";
import React, { useContext } from "react";

import DateTimezoneRenderSwitcher from "../../contexts/dateTimezoneRenderSwitcher";
import ForumUtcOffsetContext from "../../contexts/forumUtcOffset";
import DateRenderer from "../DateRenderer";

const ContextualDateRenderer = (props) => {
  const utcOffset = useContext(ForumUtcOffsetContext);
  const dateTimezoneSwitcher = useContext(DateTimezoneRenderSwitcher.Context);

  const offset = dateTimezoneSwitcher.showInUserTimezone ? undefined : utcOffset;

  return <DateRenderer utcOffset={offset} {...props} />;
};

ContextualDateRenderer.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hideDate: PropTypes.bool,
  showTime: PropTypes.bool,
};

ContextualDateRenderer.defaultProps = {
  hideDate: false,
  showTime: false,
};

export default ContextualDateRenderer;
