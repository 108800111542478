import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";

import apiClient from "../../../api-client";

export const useCreateTeamMember = getWrappedUseMutation(apiClient.ItineraryBookletsForumTeamMembers.post);

export const useUpdateTeamMember = getWrappedUseMutation(apiClient.ItineraryBookletsForumTeamMembers.put);

export const useDeleteTeamMember = getWrappedUseMutation(apiClient.ItineraryBookletsForumTeamMembers.delete);

export const useReorderTeamMembersMutation = getWrappedUseMutation(
  apiClient.ItineraryBookletsForumTeamMembers.reorder.put,
);
