import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useUpdateAttendeeRemovedItineraryMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeItinerary.removedItineraries.put,
);

export const useUpdateForumAttendeeItineraryCheckInMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeItinerary.checkIn.put,
);
