import { CountryApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(CountryApi);

const Countries = {
  getAll: api.countriesGet,
  post: api.countriesPost,
  forumCountries: {
    get: api.forumsCountriesGet,
  },
};

export default Countries;
