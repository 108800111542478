/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DuplicateItemExceptionResponse } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { RoomGradeRequestModel } from '../apiModels';
// @ts-ignore
import { RoomGradeResponseModel } from '../apiModels';
/**
 * ForumRoomGradeApi - axios parameter creator
 * @export
 */
export const ForumRoomGradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a room grade from a forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumGradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete: async (forumId: number, forumBuildingId: number, forumGradeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumGradeId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete', 'forumGradeId', forumGradeId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/roomgrades/{forumGradeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumGradeId"}}`, encodeURIComponent(String(forumGradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a forum room grade
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumGradeId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut: async (forumId: number, forumBuildingId: number, forumGradeId: number, roomGradeRequestModel?: RoomGradeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut', 'forumBuildingId', forumBuildingId)
            // verify required parameter 'forumGradeId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut', 'forumGradeId', forumGradeId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/roomgrades/{forumGradeId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)))
                .replace(`{${"forumGradeId"}}`, encodeURIComponent(String(forumGradeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomGradeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets room grades by a forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {string} [name] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {boolean} [onlyInUseInBedrooms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet: async (forumId: number, forumBuildingId: number, name?: string, orderBy?: string, orderDir?: string, onlyInUseInBedrooms?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/roomgrades`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (onlyInUseInBedrooms !== undefined) {
                localVarQueryParameter['onlyInUseInBedrooms'] = onlyInUseInBedrooms;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a room grade for specified forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost: async (forumId: number, forumBuildingId: number, roomGradeRequestModel?: RoomGradeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost', 'forumId', forumId)
            // verify required parameter 'forumBuildingId' is not null or undefined
            assertParamExists('forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost', 'forumBuildingId', forumBuildingId)
            const localVarPath = `/forums/{forumId}/venue/buildings/{forumBuildingId}/roomgrades`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumBuildingId"}}`, encodeURIComponent(String(forumBuildingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roomGradeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumRoomGradeApi - functional programming interface
 * @export
 */
export const ForumRoomGradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumRoomGradeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a room grade from a forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumGradeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete(forumId: number, forumBuildingId: number, forumGradeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete(forumId, forumBuildingId, forumGradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a forum room grade
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {number} forumGradeId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut(forumId: number, forumBuildingId: number, forumGradeId: number, roomGradeRequestModel?: RoomGradeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut(forumId, forumBuildingId, forumGradeId, roomGradeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets room grades by a forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {string} [name] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {boolean} [onlyInUseInBedrooms] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet(forumId: number, forumBuildingId: number, name?: string, orderBy?: string, orderDir?: string, onlyInUseInBedrooms?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoomGradeResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet(forumId, forumBuildingId, name, orderBy, orderDir, onlyInUseInBedrooms, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a room grade for specified forum building
         * @param {number} forumId 
         * @param {number} forumBuildingId 
         * @param {RoomGradeRequestModel} [roomGradeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost(forumId: number, forumBuildingId: number, roomGradeRequestModel?: RoomGradeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost(forumId, forumBuildingId, roomGradeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumRoomGradeApi - factory interface
 * @export
 */
export const ForumRoomGradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumRoomGradeApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a room grade from a forum building
         * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumGradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a forum room grade
         * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumGradeId, requestParameters.roomGradeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets room grades by a forum building
         * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<RoomGradeResponseModel>> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.name, requestParameters.orderBy, requestParameters.orderDir, requestParameters.onlyInUseInBedrooms, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a room grade for specified forum building
         * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.roomGradeRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete operation in ForumRoomGradeApi.
 * @export
 * @interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDeleteRequest
 */
export interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete
     */
    readonly forumGradeId: number
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut operation in ForumRoomGradeApi.
 * @export
 * @interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPutRequest
 */
export interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut
     */
    readonly forumGradeId: number

    /**
     * 
     * @type {RoomGradeRequestModel}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut
     */
    readonly roomGradeRequestModel?: RoomGradeRequestModel
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet operation in ForumRoomGradeApi.
 * @export
 * @interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGetRequest
 */
export interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {string}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {boolean}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet
     */
    readonly onlyInUseInBedrooms?: boolean
}

/**
 * Request parameters for forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost operation in ForumRoomGradeApi.
 * @export
 * @interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPostRequest
 */
export interface ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost
     */
    readonly forumBuildingId: number

    /**
     * 
     * @type {RoomGradeRequestModel}
     * @memberof ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost
     */
    readonly roomGradeRequestModel?: RoomGradeRequestModel
}

/**
 * ForumRoomGradeApi - object-oriented interface
 * @export
 * @class ForumRoomGradeApi
 * @extends {BaseAPI}
 */
export class ForumRoomGradeApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a room grade from a forum building
     * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumRoomGradeApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumRoomGradeApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdDelete(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumGradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a forum room grade
     * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumRoomGradeApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPutRequest, options?: AxiosRequestConfig) {
        return ForumRoomGradeApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdRoomgradesForumGradeIdPut(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.forumGradeId, requestParameters.roomGradeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets room grades by a forum building
     * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumRoomGradeApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesGetRequest, options?: AxiosRequestConfig) {
        return ForumRoomGradeApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdRoomgradesGet(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.name, requestParameters.orderBy, requestParameters.orderDir, requestParameters.onlyInUseInBedrooms, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a room grade for specified forum building
     * @param {ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumRoomGradeApi
     */
    public forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost(requestParameters: ForumRoomGradeApiForumsForumIdVenueBuildingsForumBuildingIdRoomgradesPostRequest, options?: AxiosRequestConfig) {
        return ForumRoomGradeApiFp(this.configuration).forumsForumIdVenueBuildingsForumBuildingIdRoomgradesPost(requestParameters.forumId, requestParameters.forumBuildingId, requestParameters.roomGradeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

