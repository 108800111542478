import { useQueryClient } from "@tanstack/react-query";
import { useUpdateSelectionOptionMutation } from "api/mutations/forumAttendeePreferences";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { AttendeeChoice, ForumAttendeeSelectionChoiceResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import SelectionOptionForm from "../SelectionOptionForm";

interface EditSelectionOptionDrawerProps {
  selectionOption: ForumAttendeeSelectionChoiceResponseModel;
  isVisible: boolean;
  onClose: () => void;
}

const EditSelectionOptionDrawer: FC<EditSelectionOptionDrawerProps> = ({ selectionOption, isVisible, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const { isLoading: isSelectionOptionUpdating, mutate: updateSelectionOption } = useUpdateSelectionOptionMutation();

  const onSubmit = (values: AttendeeChoice) => {
    updateSelectionOption(
      {
        forumId,
        attendeeId,
        selectionChoiceId: toNumber(selectionOption?.id),
        attendeeChoice: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer
      title={t("Edit Selection Option")}
      open={isVisible}
      size="large"
      placement="right"
      destroyOnClose
      onClose={onClose}
    >
      <SelectionOptionForm
        initialValue={{ choiceId: selectionOption.choiceId, optionId: selectionOption.choiceOptionId }}
        isLoading={isSelectionOptionUpdating}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default EditSelectionOptionDrawer;
