import { LeftOutlined } from "@ant-design/icons";
import { useAddParticipationCodeMutation, useCreateGroupMutation, useUpdateGroupMutation } from "api/mutations/groups";
import { useGetForumGroupsQuery } from "api/queries/groups";
import { AxiosResponse } from "axios";
import UserGuide from "components/UserGuide";
import ForumGroupColor from "enums/ForumGroupColor";
import ForumGroupTypes from "enums/ForumGroupType";
import ErrorTypes from "error-handling/errorTypes";
import {
  Em2ExceptionResponseObject,
  ForumGroupRequestModel,
  ForumGroupResponseModel,
  ParticipationCodeModel,
} from "generated/api";
import { toNumber } from "lodash-es";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Button, Form, Layout, Space, Table, Tabs, Typography } from "../../components/styleguide";
import { useModernQueryWithPaginationAndOrder } from "../../hooks";
import AddGroupDrawer from "./AddGroupDrawer";
import GroupDocuments from "./Documents";
import EditGroupDrawer from "./EditGroupDrawer";
import GroupFlagsLabels from "./GroupFlagsLabels";
import ManageGroups from "./ManageGroups";
import Tasks from "./Tasks";

import styles from "./styles.module.less";

const mainTabsKeys = {
  main: "main",
  selectedGroup: "selected-group",
};

const { TabPane } = Tabs;
const { Content } = Layout;

const Groups = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { Title } = Typography;
  const [labelsForm] = Form.useForm();

  const [isAddGroupDrawerVisible, setIsAddGroupDrawerVisible] = useState(false);
  const [isEditGroupDrawerVisible, setIsEditGroupDrawerVisible] = useState(false);
  const [forumGroups, setForumGroups] = useState<ForumGroupResponseModel[] | undefined>([]);
  const [selectedGroup, setSelectedGroup] = useState<ForumGroupResponseModel | null | undefined>(null);
  const [mainTabActiveKey, setMainTabActiveKey] = useState(mainTabsKeys.main);
  const [managedGroup, setManagedGroup] = useState<ForumGroupResponseModel | null | undefined>(null);

  const createGroupMutation = useCreateGroupMutation();
  const updateGroupMutation = useUpdateGroupMutation();
  const addCodesMutation = useAddParticipationCodeMutation();

  const getForumGroupsQuery = useModernQueryWithPaginationAndOrder(
    useGetForumGroupsQuery,
    {
      onSuccess: ({ data }: { data: ForumGroupResponseModel[] }) => {
        setForumGroups(data);
      },
    },
    { forumId: id },
  );

  const handleCreateGroup = (values: {
    group: ForumGroupRequestModel;
    selectedCodes: ParticipationCodeModel[] | undefined;
  }) => {
    setIsAddGroupDrawerVisible(false);
    return createGroupMutation.mutateAsync(
      {
        forumId: toNumber(id),
        forumGroupRequestModel: values.group,
      },
      {
        onSuccess: async ({ data }) => {
          await getForumGroupsQuery.response.refetch();
          await addCodesMutation.mutateAsync(
            {
              forumId: toNumber(id),
              groupId: toNumber(data.id),
              addDeleteParticipationCodeModel: values?.selectedCodes?.map((c) => ({
                participationCode: c.code,
              })),
            },
            {
              onSuccess: () => setIsAddGroupDrawerVisible(false),
              onError: (err) => {
                const error = err as { response: { data: Em2ExceptionResponseObject } };
                setIsAddGroupDrawerVisible(true);
                if (ErrorTypes.isOfType(error.response, ErrorTypes.DuplicateItem)) {
                  toast.error(t("This Code was already added"));
                } else {
                  toast.error(t("Something went wrong"));
                }
              },
            },
          );
        },
        onError: () => setIsAddGroupDrawerVisible(true),
      },
    ) as Promise<AxiosResponse<ForumGroupRequestModel>>;
  };

  const handleUpdateGroup = ({ groupId, ...group }: { groupId: number }) => {
    setIsEditGroupDrawerVisible(false);
    updateGroupMutation.mutateAsync(
      {
        forumId: toNumber(id),
        groupId,
        forumGroupRequestModel: group,
      },
      {
        onSuccess: async () => {
          await getForumGroupsQuery.response.refetch();
          setIsEditGroupDrawerVisible(false);
        },
        onError: () => setIsEditGroupDrawerVisible(true),
      },
    );
  };

  const onDrawerClose = () => {
    if (createGroupMutation.isLoading || updateGroupMutation.isLoading) {
      return;
    }

    setIsAddGroupDrawerVisible(false);
    setIsEditGroupDrawerVisible(false);
    setSelectedGroup(null);
  };

  const editGroup = (group: ForumGroupResponseModel) => {
    setSelectedGroup(group);
    setIsEditGroupDrawerVisible(true);
  };

  const columns = useMemo(
    // Not sure if unused variable should actually do something
    // eslint-disable-next-line no-unused-vars
    () => [
      {
        title: t("groupName"),
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: t("Description"),
        dataIndex: "description",
        key: "description",
        sorter: true,
      },
      {
        title: t("Contact"),
        dataIndex: "contactUser",
        key: "contactUser",
        sorter: true,
      },
      {
        title: t("Type"),
        dataIndex: "groupType",
        key: "groupType",
        sorter: true,
        render: (_: unknown, record: ForumGroupResponseModel) => ForumGroupTypes.toDisplayName(record?.groupType),
      },
      {
        title: t("primary"),
        dataIndex: "primary",
        sorter: true,
        render: (_: unknown, record: ForumGroupResponseModel) => {
          return <div>{record.primary ? "True" : "False"}</div>;
        },
      },
      {
        title: "",
        width: "5%",
        render: (_: unknown, record: ForumGroupResponseModel) => {
          return <Button onClick={() => editGroup(record)}>{t("Edit")}</Button>;
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleGroupManage = (group: ForumGroupResponseModel) => {
    labelsForm.setFieldsValue({
      color: group.color ? ForumGroupColor[group.color] : null,
      imageText: group.imageText,
      longText: group.longText,
    });
    setManagedGroup(group);
    setMainTabActiveKey(mainTabsKeys.selectedGroup);
  };

  const handleManageGroupEnd = () => {
    setMainTabActiveKey(mainTabsKeys.main);
    setManagedGroup(null);
  };

  return (
    <div className={styles.groupsPage}>
      <UserGuide.Title title="Groups" articleId="16439185846045-groups" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Content className={styles.groupsContent}>
          <Tabs hideNav activeKey={mainTabActiveKey}>
            <TabPane tab="1" key={mainTabsKeys.main}>
              <Tabs defaultActiveKey="1">
                <TabPane tab={t("Groups")} key="groups">
                  <AddGroupDrawer
                    visible={isAddGroupDrawerVisible}
                    isLoading={createGroupMutation.isLoading || getForumGroupsQuery.response?.isLoading}
                    onClose={onDrawerClose}
                    onFinish={handleCreateGroup}
                  />

                  <EditGroupDrawer
                    visible={isEditGroupDrawerVisible}
                    isLoading={createGroupMutation.isLoading || getForumGroupsQuery.response?.isLoading}
                    onClose={onDrawerClose}
                    onFinish={handleUpdateGroup}
                    group={selectedGroup}
                  />
                  <div className={styles.sectionButton}>
                    <Button onClick={() => setIsAddGroupDrawerVisible(true)}>{t("addGroup")}</Button>
                  </div>
                  <Table
                    id="groupsTable"
                    dataSource={forumGroups}
                    columns={columns}
                    bordered={true}
                    rowKey={"id"}
                    pagination={getForumGroupsQuery.pagination}
                    onChange={getForumGroupsQuery.handleSort}
                    loading={getForumGroupsQuery.response.isLoading}
                  />
                </TabPane>
                <TabPane tab={t("Manage Groups")} key="manage-groups">
                  <ManageGroups onGroupManage={handleGroupManage} />
                </TabPane>
              </Tabs>
            </TabPane>
            {managedGroup && (
              <TabPane tab="2" key={mainTabsKeys.selectedGroup}>
                <Title level={5}>
                  <LeftOutlined onClick={handleManageGroupEnd} style={{ fontSize: "14px", marginRight: "5px" }} />
                  {t("Manage Group - {{groupName}}", { groupName: managedGroup?.name })}
                </Title>
                <Tabs defaultActiveKey="tasks">
                  <TabPane key="tasks" tab={t("Tasks")}>
                    <Tasks group={managedGroup} forumId={toNumber(id)} />
                  </TabPane>
                  <TabPane key="documents" tab={t("Documents")}>
                    <GroupDocuments forumId={Number.parseInt(id as string)} groupId={managedGroup?.id} />
                  </TabPane>
                  {!managedGroup?.primary && (
                    <TabPane key="labels" tab={`${t("Group Flags/Labels")}`}>
                      <GroupFlagsLabels form={labelsForm} group={managedGroup} onSubmit={handleManageGroupEnd} />
                    </TabPane>
                  )}
                </Tabs>
              </TabPane>
            )}
          </Tabs>
        </Content>
      </Space>
    </div>
  );
};

export default Groups;
