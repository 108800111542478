import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";
import apiClient from "api-client";

import KEYS from "../keys";

export const useTeamMembersQuery = getWrappedUseQuery(KEYS.GET_FORUM_TEAM_MEMEBERS, apiClient.ForumTeamMember.getAll);
export const useUnassignedTeamMembersQuery = getWrappedUseQuery(
  KEYS.GET_UNASSIGNED_FORUM_TEAM_MEMBER_BY_ID,
  apiClient.ForumTeamMember.unassignedUsers.get,
);

export const useTeamMemberQuery = getWrappedUseQuery(KEYS.GET_FORUM_TEAM_MEMEBER_BY_ID, apiClient.ForumTeamMember.get);
