import { FormInstance } from "antd";
import { Rule } from "antd/lib/form";
import { DatePicker, Input, Radio, Select } from "components/styleguide";
import { CountryModel, ForumResponseModel, Office, TimezoneModel, VenueResponseModel } from "generated/api";
import moment from "moment";
import { ReactElement } from "react";
import { TFunction } from "react-i18next";
import { validateFileSize } from "utils/validatorUtils";

import ForumLogoDragger from "../ForumLogoDragger";

import styles from "./styles.module.less";

const { Option } = Select;
const { RangePicker } = DatePicker;

const eventCodeFormat = new RegExp("^[A-Z]{3}[0-9]{2}$");
const validateCodeFormat = async (_: Rule, value: string) => {
  return value.length === 0 || eventCodeFormat.test(value) ? Promise.resolve() : Promise.reject();
};

interface FormField {
  title?: string;
  dataIndex: string;
  component?: ReactElement;
  displayValue?: string | boolean | ReactElement | null;
  rules?: Rule[];
  hidden?: boolean;
}

const getFormFields = (
  form: FormInstance,
  forum: ForumResponseModel,
  countries: CountryModel[] | null,
  timezones: TimezoneModel[] | null,
  venues: VenueResponseModel[],
  venueFilter: string | null | undefined,
  t: TFunction,
  onCountryChange: (countryCode: string) => void,
  onVenueChange: (e: unknown) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDurationChange: (duration: any) => void,
): FormField[] => [
  {
    title: "Event Logo",
    dataIndex: "logo",
    displayValue: forum?.logoReadUrl ? (
      <img className={styles.logo} alt={t("Event Logo")} src={forum.logoReadUrl} />
    ) : (
      "-"
    ),
    component: <ForumLogoDragger existingForum={forum} form={form} />,
    rules: [
      {
        validator: validateFileSize(100 * 1024),
        message: t("File is bigger than {{size}}", { size: "100KB" }),
      },
    ],
  },
  {
    title: "Forum Code",
    dataIndex: "code",
    displayValue: forum.code || "-",
    component: <Input defaultValue={forum.code} />,
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(eventCode)" }),
      },
      {
        validator: validateCodeFormat,
        message: t("The Event Code should consist of 3 capital letters followed by 2 digits"),
      },
    ],
  },
  {
    title: "Short Name",
    dataIndex: "shortName",
    displayValue: forum.shortName,
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(shortName)" }),
      },
    ],
  },
  {
    title: "Name",
    displayValue: forum.title,
    dataIndex: "title",
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(name)" }),
      },
    ],
  },
  { title: "Long Name", dataIndex: "longName", displayValue: forum.longName },
  { title: "Formatted Name", dataIndex: "formattedName", displayValue: forum.formattedName },
  {
    title: "Duration",
    dataIndex: "duration",
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(duration)" }),
      },
    ],
    displayValue: `${moment(forum.startDate).format("L")} - ${moment(forum.endDate).format("L")}`,
    component: <RangePicker style={{ width: "100%" }} onChange={onDurationChange} />,
  },
  { title: "Display Dates", dataIndex: "displayDates", displayValue: forum.displayDates },
  { title: "Website Address", dataIndex: "websiteAddress", displayValue: forum.websiteAddress },
  {
    title: "Virtual",
    dataIndex: "isVirtual",
    displayValue: forum.isVirtual ? t("Yes") : t("No"),
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(Virtual)" }),
      },
    ],
    component: (
      <Radio.Group disabled>
        <Radio value={true}>{t("Yes")}</Radio>
        <Radio value={false}>{t("No")}</Radio>
      </Radio.Group>
    ),
  },
  {
    title: "Venue",
    dataIndex: "venueId",
    displayValue: forum.forumVenueName,
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(Venue)" }),
      },
    ],
    component: (
      <Select
        onChange={onVenueChange}
        notFoundContent={venueFilter && t("forum.errors.noVenueForCountry")}
        showSearch={true}
        optionFilterProp="children"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLocaleLowerCase())}
        disabled={form.getFieldValue("isVirtual")}
      >
        {venues
          ?.filter((venue) => venue?.countryCode === venueFilter)
          .map((v) => (
            <Option key={v?.id} value={v?.id}>
              {v?.name}
            </Option>
          ))}
      </Select>
    ),
  },
  {
    title: "Country",
    dataIndex: "countryCode",
    displayValue: forum.country,
    rules: [
      {
        required: !form.getFieldValue("isVirtual"),
        message: t("errors.required", { prop: "$t(country)" }),
      },
    ],
    component: (
      <Select
        showSearch
        placeholder={t("placeholders.select", { prop: "$t(country)" })}
        onChange={onCountryChange}
        filterOption={(input, option) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {countries?.map((c) => (
          <Option key={c.isoCode} value={c.isoCode}>
            {c.name}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    title: "Office",
    dataIndex: "office",
    displayValue: forum.office && t(Office[forum.office]),
    component: (
      <Select
        placeholder={t("Select Office")}
        options={Object.entries(Office).map(([value, label]) => ({ value, label: t(label) }))}
      />
    ),
  },
  {
    title: "Time Zone",
    dataIndex: "timezoneId",
    displayValue: forum.timezoneId,
    rules: [
      {
        required: true,
        message: t("errors.required", { prop: "$t(Time Zone)" }),
      },
    ],
    component: (
      <Select>
        {timezones?.map((z) => (
          <Option key={z.id} value={z.id}>
            {z.description}
          </Option>
        ))}
      </Select>
    ),
  },
  {
    dataIndex: "logoFileBlobId",
    hidden: true,
  },
];

export { getFormFields };
