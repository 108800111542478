import "../styles.less";

import { Avatar, Spin } from "components/styleguide";
import { CompanyModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import CompanyName from "./CompanyName";
import ExhibitingStatus from "./ExhibitingStatus";

interface ForumCompanyDetailsProps {
  forumCompany: CompanyModel;
  isLoading: boolean;
  onChangeCompanyName: (newCompanyName: string) => void;
}

const ForumCompanyDetails: FC<ForumCompanyDetailsProps> = ({ forumCompany, isLoading, onChangeCompanyName }) => {
  const { t } = useTranslation();

  return (
    <div className="company">
      <div className="basic-info">
        {!isLoading && !!forumCompany ? (
          <div>
            <div className="header">
              <Avatar size={45} className="avatar-primary-outlined">
                {forumCompany.name?.[0]}
              </Avatar>
              <CompanyName style={{ marginLeft: "30px" }} forumCompany={forumCompany} onChange={onChangeCompanyName} />
            </div>
            <div className="content">
              <div className="info-column">
                <span className="title">{t("Address")}:</span>
                {forumCompany.addressLine1 || "-"}
                <br />
                <span className="title">{t("City")}:</span>
                {forumCompany.city || "-"}
                <br />
                <span className="title">{t("County")}:</span>
                {forumCompany.county || "-"}
                <br />
                <span className="title">{t("Country")}:</span>
                {forumCompany.country || "-"}
              </div>
              <div className="info-column">
                <span className="title">{t("Postcode")}:</span>
                {forumCompany.postcode || "-"}
                <br />
                <span className="title">{t("Grade")}:</span>
                {forumCompany.grade || "-"}
                <br />
                <span className="title">{t("Phone")}:</span>
                {forumCompany.phone || "-"}
              </div>
              <div className="info-column">
                <span className="title">{t("Website")}:</span>
                {(
                  <a href={forumCompany.website ?? undefined} target="_blank" rel="noreferrer">
                    {forumCompany.website || "-"}
                  </a>
                ) || "-"}
                <br />
                <span className="title">{t("Email")}:</span>
                {forumCompany.email || "-"}
                <br />
                <ExhibitingStatus
                  id={forumCompany.id}
                  status={!!forumCompany.exhibitingStatus}
                  forumId={toNumber(forumCompany.forumId)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="loader">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForumCompanyDetails;
