import { SelectionSiteContent, SelectionSiteContentSelectionSiteContentPageItemsInner } from "../../generated/api";
import getForConferenceRankV2 from "./get-for-conference-rank-v2";
import getForConferenceSelectionV2 from "./get-for-conference-selection-v2";
import getForJoint from "./get-for-joint";
import getForPositiveSelectionV2 from "./get-for-positive-selection-v2";
import getForReasonsForMeeting from "./get-for-reasons-for-meeting";
import getForTabbedConferenceSelection from "./get-for-tabbed-conference-selection";
import Content from "./utils/content";
import ContentItem from "./utils/content-item";

type ContentType = SelectionSiteContent;
type ItemType = SelectionSiteContentSelectionSiteContentPageItemsInner;
type Id = ItemType["id"];

const getDependentItems = (content: Content, item: ContentItem): Array<Id> => {
  if (item.is("PositiveSelectionV2")) {
    return getForPositiveSelectionV2(content, item);
  }

  if (item.is("ReasonForMeeting") || item.is("ReasonForMeetingV2") || item.is("ReasonForMeetingV3")) {
    return getForReasonsForMeeting(content, item);
  }

  if (item.is("ConferenceSelectionV2")) {
    return getForConferenceSelectionV2(content, item);
  }

  if (item.is("TabbedConferenceSelection")) {
    return getForTabbedConferenceSelection(content, item);
  }

  if (item.is("ConferenceRankV2")) {
    return getForConferenceRankV2(content, item);
  }

  if (item.is("Joint")) {
    return getForJoint(content, item);
  }

  return [];
};

const getSelectionSiteDependentItems = (content: ContentType, ids: Array<Id>): Set<Id> => {
  const result = new Set<Id>();

  const pages = content.pages ?? [];
  const idsSet = new Set(ids);

  if (pages.length === 0 || idsSet.size === 0) {
    return result;
  }

  const contentInstance = new Content(content);
  contentInstance.items.forEach((item) => {
    if (!idsSet.has(item.value.id)) {
      return;
    }

    const dependentItems = getDependentItems(contentInstance, item);
    dependentItems.forEach((id) => {
      result.add(id);
    });
  });

  return result;
};

export default getSelectionSiteDependentItems;
