// Useful when you need to perform an action
// pragmatically (w/o using `useEffect` or `useLayoutEffect`)
// for example, after React updates its state
const nextTick = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
};

export default nextTick;
