import { Image } from "antd";
import { Col, Divider, Row, Space } from "components/styleguide";
import { VenueDetailsModel } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import getFields from "../helpers";

import styles from "../styles.module.less";

interface VenueDetailsProps {
  venue: VenueDetailsModel;
}

const VenueDetails: FC<VenueDetailsProps> = ({ venue }) => {
  const { t } = useTranslation();

  const getVenueValue = (index: string, type?: string) => {
    const value = venue[index as keyof VenueDetailsModel];

    if (!value) {
      return "-";
    }

    if (type === "image") {
      return <Image src={value.toString()} width={200} />;
    }

    return value;
  };

  return (
    <Row gutter={24}>
      {getFields().map(({ title, dataIndex, type, displayValue }) => (
        <Col className={styles.col} span={12} key={dataIndex}>
          {title ? (
            <>
              <Row className={styles.item} gutter={32} align="middle">
                <Col span={12}>
                  <label>{t(`venueDetailsLabels.${title}`)}</label>
                </Col>
                <Col style={{ wordBreak: "break-all" }} span={12}>
                  {getVenueValue(displayValue ?? dataIndex, type)}
                </Col>
              </Row>
              <Divider />
            </>
          ) : (
            <Space />
          )}
        </Col>
      ))}
    </Row>
  );
};

export default VenueDetails;
