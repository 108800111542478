import { Button, Grid } from "components/styleguide/mobile";
import { useTranslation } from "react-i18next";
import { Link, useOutletContext } from "react-router-dom";

import { PlaybookOutletContext } from "./types";

export default function Playbook() {
  const { t } = useTranslation();
  const { setTitle } = useOutletContext<PlaybookOutletContext>();

  setTitle(t("Playbook"));

  return (
    <Grid columns={2} gap={10}>
      <Grid.Item>
        <Link style={{ color: "black" }} to={"conferences"}>
          <Button block={true} size="large" color="primary">
            {t("Conference")}
          </Button>
        </Link>
      </Grid.Item>
      <Grid.Item>
        <Link style={{ color: "black" }} to={"meetings"}>
          <Button block={true} size="large" color="primary">
            {t("Meetings")}
          </Button>
        </Link>
      </Grid.Item>
    </Grid>
  );
}
