import { AxiosRequestConfig } from "axios";

import apiClient from "../../../api-client";
import { FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, FacilityType } from "../../../generated/api";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useFacilityQuery = getWrappedUseQuery(KEYS.GET_FACILITY, apiClient.Facilities.get);

export const useFacilitiesQuery = getWrappedUseQuery(KEYS.GET_FACILITIES, apiClient.Facilities.getAll);

export const useMeetingRoomsQuery = getWrappedUseQuery(
  KEYS.GET_MEETING_ROOMS,
  (
    params: Omit<FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, "type">,
    options: AxiosRequestConfig = {},
  ) => apiClient.Facilities.getAll({ ...params, type: FacilityType.MeetingRoom }, options),
);

export const useConferenceRoomsQuery = getWrappedUseQuery(
  KEYS.GET_CONFERENCE_ROOMS,
  (
    params: Omit<FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, "type">,
    options: AxiosRequestConfig = {},
  ) => apiClient.Facilities.getAll({ ...params, type: FacilityType.ConferenceRoom }, options),
);

export const useSpeedMeetingsQuery = getWrappedUseQuery(
  KEYS.GET_SPEED_MEETINGS,
  (
    params: Omit<FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, "type">,
    options: AxiosRequestConfig = {},
  ) => apiClient.Facilities.getAll({ ...params, type: FacilityType.SpeedMeetings }, options),
);

export const useRestaurantsQuery = getWrappedUseQuery(
  KEYS.GET_RESTAURANTS,
  (
    params: Omit<FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, "type">,
    options: AxiosRequestConfig = {},
  ) => apiClient.Facilities.getAll({ ...params, type: FacilityType.Restaurant }, options),
);

export const useBedroomsQuery = getWrappedUseQuery(
  KEYS.GET_BEDROOMS,
  (
    params: Omit<FacilityApiVenuesVenueIdBuildingsBuildingIdFacilitiesGetRequest, "type">,
    options: AxiosRequestConfig = {},
  ) => apiClient.Facilities.getAll({ ...params, type: FacilityType.Bedroom }, options),
);
