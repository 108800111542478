import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useGetItineraryBookletGenerateDocumentQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_GENERATE_DOCUMENT,
  apiClient.ItineraryBooklets.generateDocument.get,
);

export const useGetItineraryBookletQueuedDocumentsQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_QUEUED_DOCUMENTS,
  apiClient.ItineraryBooklets.queuedDocuments.get,
);

export const useGetItineraryBookletDocumentGenerationRunsQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUNS,
  apiClient.ItineraryBooklets.documentGenerationRuns.getAll,
);

export const useGetItineraryBookletDocumentGenerationRunQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUN,
  apiClient.ItineraryBooklets.documentGenerationRuns.get,
);

export const useGetItineraryBookletDocumentGenerationRunExportQuery = getWrappedUseQuery(
  KEYS.GET_ITINERARY_BOOKLET_DOCUMENT_GENERATION_RUN_EXPORT,
  apiClient.ItineraryBooklets.documentGenerationRuns.export.get,
);
