import { cloneElement, FC, ReactElement } from "react";

import { Form, Typography } from "../../../../../../../../components/styleguide";

interface DependentFieldProps {
  children: ReactElement;
  dependency: string;
  message: string;
  value?: unknown;
  onChange?: (value: unknown) => void;
}

const DependentField: FC<DependentFieldProps> = ({ children, dependency, message, value, onChange }) => {
  const form = Form.useFormInstance();
  const dependencyValue = form.getFieldValue(dependency);

  if (dependencyValue === null || dependencyValue === undefined) {
    return <Typography.Text>{message}</Typography.Text>;
  }

  return cloneElement(children, { value, onChange });
};

export default DependentField;
