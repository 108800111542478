import { useQueryClient } from "@tanstack/react-query";
import { BaseOptionType } from "antd/lib/select";
import { useUpdateCompanyDescriptionMutation } from "api/mutations/companies";
import { useForumCompaniesDescriptionsQuery } from "api/queries/forumCompanies";
import KEYS from "api/queries/keys";
import { Button, Form, Select, Space, Table } from "components/styleguide";
import {
  AttendeeManageDescriptionModel,
  CompanyDescriptionRequestModel,
  CompanyType,
  PageResponseCompanyDescriptionResponseModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import EditDescriptionDrawer from "../EditDrawer";

import styles from "./styles.module.less";
import editorStyles from "components/TextEditor/plainTextStyles.module.less";

const DataIndexes = {
  CompanyName: "name",
  Description: "description",
};

export default function ManageCompanyDescriptions() {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const [descriptions, setDescriptions] = useState<CompanyDescriptionRequestModel[]>();
  const [companyNameSearch, setCompanyNameSearch] = useState<string | undefined>(undefined);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<AttendeeManageDescriptionModel | null>();
  const [companyTypeFilter, setCompanyTypeFilter] = useState<CompanyType | undefined>();

  const companyTypes = useMemo(() => {
    return Object.keys(CompanyType).map((k) => ({
      value: k,
      label: t(k),
    }));
  }, []);

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    response: query,
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useForumCompaniesDescriptionsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseCompanyDescriptionResponseModel }) => {
        setDescriptions(data.items);
      },
    },
    {
      forumId,
      name: companyNameSearch,
      companyType: companyTypeFilter,
    },
  );

  const updateMutation = useUpdateCompanyDescriptionMutation();

  const handleSearch = (
    selectedKeys: Array<string> | Array<BaseOptionType>,
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case DataIndexes.CompanyName: {
        setCompanyNameSearch(selectedKeys[0] as string);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case DataIndexes.CompanyName: {
        setCompanyNameSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const handleEditClick = (record: AttendeeManageDescriptionModel) => {
    setActiveItem(record);
    form.setFieldValue("description", record.description);
    setDrawerOpen(true);
  };

  const handleEditSubmit = (values: CompanyDescriptionRequestModel) => {
    updateMutation.mutate(
      {
        forumId,
        forumCompanyId: activeItem?.id as number,
        companyDescriptionRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_FORUM_COMPANIES_DESCRIPTIONS]);

          setDrawerOpen(false);
          form.resetFields();
        },
      },
    );
  };

  const handleCompanyTypeFilterChange = (value: CompanyType) => setCompanyTypeFilter(value);

  const columns = [
    {
      title: t("Company Name"),
      dataIndex: DataIndexes.CompanyName,
      sorter: true,
      ...getColumnSearchProps(DataIndexes.CompanyName, handleSearch, handleReset, DataIndexes.CompanyName),
    },
    {
      title: t("Company Description"),
      dataIndex: DataIndexes.Description,
      render: (description: string) => {
        return (
          <span
            className={editorStyles.editorText}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        );
      },
    },
    {
      dataIndex: "id",
      width: "5%",
      render: (_: unknown, record: CompanyDescriptionRequestModel) => (
        <div className={styles.buttonWrapper}>
          <Button onClick={() => handleEditClick(record)}>{t("Edit")}</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <EditDescriptionDrawer
        title={t("Edit Company Description")}
        editorPlaceholder={t("Please, enter Company description")}
        form={form}
        open={drawerOpen}
        isLoading={updateMutation.isLoading}
        onClose={handleDrawerClose}
        onSubmit={handleEditSubmit}
      />
      <Space direction="vertical" fullWidth={true}>
        <div className={styles.header}>
          <Space fullWidth={true} direction="horizontal">
            <Select
              placeholder={t("Filter Companies by")}
              className={styles.filter}
              options={companyTypes}
              value={companyTypeFilter}
              onChange={handleCompanyTypeFilterChange}
              allowClear
            ></Select>
          </Space>
        </div>
        <Table
          columns={columns}
          rowKey="id"
          loading={query.isLoading}
          dataSource={descriptions}
          onChange={handleSort}
          pagination={pagination}
        />
      </Space>
    </>
  );
}
