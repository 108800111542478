import Icon, { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { Typography } from "components/styleguide";
import { ITContractApprovalStatus } from "enums/ITContractApprovalStatus";
import { t } from "i18next";
import React from "react";

import styles from "./styles.module.less";

const ItContractApprovalStatus = ({ status }: { status: string }) =>
  status === ITContractApprovalStatus.Approved ? (
    <div className={styles.status}>
      <Icon className={clsx([styles.icon, styles.greenFill])} component={() => <CheckCircleOutlined />} />
      <Typography.Text>{t("Approved") as string}</Typography.Text>
    </div>
  ) : status === ITContractApprovalStatus.Rejected ? (
    <div className={styles.status}>
      <Icon className={clsx([styles.icon, styles.redFill])} component={() => <CloseCircleOutlined />} />
      <Typography.Text>{t("Rejected") as string}</Typography.Text>
    </div>
  ) : null;

export default ItContractApprovalStatus;
