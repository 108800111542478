import PropTypes from "prop-types";
import React from "react";

import { Select } from "../../../inputs";

const PrefilledListOrder = ({ value, onChange }) => {
  return (
    <Select value={value} onChange={onChange}>
      <Select.Option value="All">All</Select.Option>
      <Select.Option value="All, Matched">All, Matched</Select.Option>
      <Select.Option value="All, Mutual">All, Mutual</Select.Option>
      <Select.Option value="All, Mutual, Matched">All, Mutual, Matched</Select.Option>
      <Select.Option value="All, Matched, Mutual">All, Matched, Mutual</Select.Option>
      <Select.Option value="Mutual">Mutual</Select.Option>
      <Select.Option value="Mutual, All">Mutual, All</Select.Option>
      <Select.Option value="Mutual, Matched">Mutual, Matched</Select.Option>
      <Select.Option value="Mutual, All, Matched">Mutual, All, Matched</Select.Option>
      <Select.Option value="Mutual, Matched, All">Mutual, Matched, All</Select.Option>
    </Select>
  );
};

PrefilledListOrder.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

PrefilledListOrder.defaultProps = {
  value: undefined,
  onChange: () => undefined,
};

export default PrefilledListOrder;
