import { Button } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import {
  AttendeeModel,
  AttendeeStatus as ApiAttendeeStatus,
  ForumAttendeeApiForumForumIdAttendeesGetRequest,
} from "generated/api";
import { t } from "i18next";
import { ValueOf } from "types";
import {
  getColumnSearchProps,
  getColumnSelectFilterProps,
  ResetFunction,
  SearchFunctionType,
  TableColumn,
} from "utils/tableColumnUtils";

import LinkToReport from "../../components/LinkToReport";

type KeyOfAttendeeStatus = keyof typeof AttendeeStatus;

export const getAttendeeStatusOptions = (reportSpecificAttendeeStatuses: ValueOf<typeof AttendeeStatus>[]) => {
  const attendeeStatuses = Object.keys(AttendeeStatus).filter((key) => key !== "toDisplayName");

  const specificAttendeeStatuses =
    reportSpecificAttendeeStatuses &&
    attendeeStatuses.filter((value) =>
      reportSpecificAttendeeStatuses.includes(AttendeeStatus[value as KeyOfAttendeeStatus]),
    );

  return (specificAttendeeStatuses ?? attendeeStatuses).map((key) => ({
    value: AttendeeStatus[key as KeyOfAttendeeStatus],
    label: t(`attendeeStatus.${AttendeeStatus.toDisplayName(AttendeeStatus[key as KeyOfAttendeeStatus] as number)}`),
  }));
};

export const getColumns = ({
  handleSearch,
  handleReset,
  peopleTypesOptions,
  onAttendeeSelect,
  additionalColumns = [],
  additionalFilters = {},
}: {
  handleSearch: SearchFunctionType;
  handleReset: ResetFunction;
  peopleTypesOptions: { value: string; label: string }[];
  onAttendeeSelect?: (attendee: AttendeeModel) => void;
  additionalColumns?: TableColumn[];
  additionalFilters?: Partial<
    Record<
      keyof ForumAttendeeApiForumForumIdAttendeesGetRequest,
      ForumAttendeeApiForumForumIdAttendeesGetRequest[keyof ForumAttendeeApiForumForumIdAttendeesGetRequest]
    >
  >;
}) => {
  const selectButtonColumn = new TableColumn("", "attendId", {
    width: "10%",
    render: (id: number, attendee: AttendeeModel) =>
      onAttendeeSelect ? (
        <Button onClick={() => onAttendeeSelect(attendee)}>{t("Select").toString()}</Button>
      ) : (
        <LinkToReport parameters={{ ForumAttendeeId: id }} />
      ),
  });

  return [
    new TableColumn(t("People Code"), "personId", { sorter: true }),
    new TableColumn(t("Name"), "fullName", {
      sorter: true,
      ...getColumnSearchProps("fullName", handleSearch, handleReset, "fullName", null),
    }),
    new TableColumn(t("Company"), "forumCompany", {
      sorter: true,
      ...getColumnSearchProps("forumCompany", handleSearch, handleReset, "forumCompany", null),
    }),
    new TableColumn(t("peopleType"), "peopleType", {
      sorter: true,
      ...getColumnSelectFilterProps("peopleType", handleSearch, handleReset, "peopleType", peopleTypesOptions, null),
    }),
    new TableColumn(t("attendStatus"), "attendStatus", {
      render: (attendStatus: ApiAttendeeStatus) => t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendStatus)}`),
      sorter: true,
      ...getColumnSelectFilterProps(
        "attendStatus",
        handleSearch,
        handleReset,
        "attendStatus",
        getAttendeeStatusOptions(additionalFilters.attendeeStatus as ApiAttendeeStatus[]),
        null,
        false,
      ),
    }),
    additionalColumns.length ? [...additionalColumns, selectButtonColumn] : selectButtonColumn,
  ].flat();
};
