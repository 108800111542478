import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateForumTableMutation,
  useCreateTableMutation,
  useUpdateForumTableMutation,
  useUpdateTableMutation,
} from "api/mutations/tables";
import { tablesKey } from "api/queries/tables";
import { Button, Col, Drawer, Form, Input, Row, Select } from "components/styleguide";
import { AdminTypes } from "enums/common";
import TableType from "enums/TableType";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.less";

const { Option } = Select;

export default function EditTable({ ui, visible, form, onClose, facility, tablesLoading, activeTable }) {
  const { t } = useTranslation();
  const params = useParams();
  const [venueId, buildingId, forumId] = [params.venueId, params.buildingId, params.id].map((id) => parseInt(id));
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const isSysAdminUi = ui === AdminTypes.SystemAdmin;

  const createSystemAdminTableMutation = useCreateTableMutation();
  const updateSystemAdminTableMutation = useUpdateTableMutation();
  const createForumAdminTableMutation = useCreateForumTableMutation();
  const updateForumAdminTableMutation = useUpdateForumTableMutation();

  const tableTypes = useMemo(
    () =>
      Object.keys(TableType)
        .filter((key) => key !== "toDisplayName")
        .map((key) => {
          return (
            <Option value={TableType[key]} key={key}>
              {TableType.toDisplayName(TableType[key])}
            </Option>
          );
        }),
    [],
  );

  const numberOfSeats = useMemo(() => {
    const options = [];
    for (let i = 1; i <= 1000; i++) {
      options.push(
        <Option value={i} key={i}>
          {i}
        </Option>,
      );
    }
    return options;
  }, []);

  const onFinish = (values) => {
    form.setFieldValue("tableNumber", values.tableNumber.trim());
    form.validateFields().then(() => {
      setIsLoading(true);
      const { ...filteredValues } = values;
      const onSuccess = async () => {
        setIsLoading(false);
        form.resetFields();
        onClose();
        await queryClient.invalidateQueries(tablesKey);
      };
      const facilityId = +facility?.id;
      const tableId = +activeTable?.id;

      if (isSysAdminUi) {
        if (activeTable) {
          return updateSystemAdminTableMutation.mutate(
            { venueId, buildingId, facilityId, tableId, tableRequestModel: values },
            {
              onSuccess,
            },
          );
        } else {
          return createSystemAdminTableMutation.mutate(
            { venueId, buildingId, facilityId, table: filteredValues },
            {
              onSuccess,
            },
          );
        }
      } else {
        if (activeTable) {
          return updateForumAdminTableMutation.mutate(
            {
              forumId,
              forumBuildingId: buildingId,
              forumFacilityId: facilityId,
              forumTableId: tableId,
              forumTableRequestModel: values,
            },
            { onSuccess },
          );
        } else {
          return createForumAdminTableMutation.mutate(
            { forumId, forumBuildingId: buildingId, forumFacilityId: facilityId, forumTableRequestModel: values },
            { onSuccess },
          );
        }
      }
    });
  };

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={activeTable ? t("Edit Table") : t("Add Table")}
      placement="right"
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
      getContainer={document.getElementById("venueDetailsPage")}
    >
      <Form
        id="addTableForm"
        className={styles.form}
        labelWrap
        layout="vertical"
        name="new-facility"
        labelCol={{ justify: "left", span: 24 }}
        wrapperCol={{ justify: "right" }}
        onFinish={onFinish}
        form={form}
      >
        <div className={styles.formLayout}>
          <div>
            <Form.Item
              label={t("Table Number")}
              name="tableNumber"
              initialValue={activeTable?.tableNumber}
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: t("Table Number") }),
                },
                {
                  max: 5,
                  message: t("errors.max.table_number", { count: 5 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item initialValue={facility.tables} label={t("Number of Tables")} name="numberOfTables">
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={t("Number of Seats")}
              name="numberOfSeats"
              initialValue={activeTable?.numberOfSeats}
              rules={[
                {
                  required: !isSysAdminUi,
                  message: t("errors.required", { prop: t("Number of Seats") }),
                },
              ]}
            >
              <Select showSearch allowClear>
                {numberOfSeats}
              </Select>
            </Form.Item>
            <Form.Item
              label={t("Table Type")}
              name="tableType"
              initialValue={activeTable?.tableType}
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: t("Table Type") }),
                },
              ]}
            >
              <Select>{tableTypes}</Select>
            </Form.Item>
          </div>
          <div>
            <Row justify="space-between">
              <Col>
                <Button onClick={onClose}>{t("Cancel")}</Button>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={tablesLoading || isLoading}>
                    {activeTable ? t("Update") : t("Save")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Drawer>
  );
}

EditTable.propTypes = {
  ui: PropTypes.oneOf(AdminTypes),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  facility: PropTypes.object,
  tablesLoading: PropTypes.bool,
  activeTable: PropTypes.object,
  form: PropTypes.any,
};
