import { Space } from "antd";
import { Select, Typography } from "components/styleguide";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface SortOrderOptionProps {
  options: Record<string, string>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<string>>;
}

const SortOrderOption = ({ options, orderBy, setOrderBy }: SortOrderOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Typography.Text>{t("Sort Order")}</Typography.Text>
      <Select
        style={{ minWidth: "200px" }}
        options={Object.entries(options).map(([value, label]) => ({ value, label: t(label) }))}
        value={orderBy}
        onSelect={(value: string) => setOrderBy(value)}
      />
    </Space>
  );
};

export default SortOrderOption;
