import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { BaseOptionType } from "antd/lib/select";
import { useCopySetupMutation } from "api/mutations/itineraryBookletsForumCopySetup";
import { useGetForumCountriesQuery } from "api/queries/countries";
import { useForumsQuery, useForumsYearsQuery } from "api/queries/forums";
import KEYS from "api/queries/keys";
import { Button, Modal, Select, Space, Table, Typography } from "components/styleguide";
import { CountryModel, ForumResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getColumnSearchProps, TableColumn } from "utils/tableColumnUtils";

import styles from "./styles.module.less";

const currentYear = new Date().getFullYear();

const CopySetup = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const forumId = toNumber(id);

  const [eventCodeSearch, setEventCodeSearch] = useState<string | undefined>();
  const [eventNameSearch, setEventNameSearch] = useState<string | undefined>();
  const [yearFilter, setYearFilter] = useState<number | undefined>(currentYear);
  const [countryFilter, setCountryFilter] = useState<string | undefined>("UK");

  const { mutateAsync, isLoading: isCopyMutationLoading } = useCopySetupMutation();

  const {
    handleSort,
    pagination,
    response: { isLoading, data: { data: { items: forums = [] } = {} } = {} } = {},
  } = useModernQueryWithPaginationAndOrder(
    useForumsQuery,
    {},
    { year: yearFilter, countryCode: countryFilter, code: eventCodeSearch, shortName: eventNameSearch },
    { defaultPageSize: 10, pageSizeOptions: [10, 50, 100] },
  );

  const { isLoading: isCountriesLoading, data: { data: countries = [] } = {} } = useGetForumCountriesQuery({});
  const { isLoading: isYearsLoading, data: { data: years = [] } = {} } = useForumsYearsQuery({});

  const handleForumSelect = (existingForumId: number, existingForumName: string | null | undefined) => {
    Modal.confirm({
      title: t("confirmation"),
      content: t("CopyDocumentSetupConfirmationMessage", {
        forumName: existingForumName ?? t("the selected forum"),
      }),
      icon: <ExclamationCircleOutlined />,
      okText: t("Copy"),
      okType: "danger",
      cancelText: t("Cancel"),
      onOk: async () => {
        await mutateAsync(
          { forumId, existingForumId },
          {
            onSuccess: () => {
              toast.success(t("Setup successfully copied"));
              queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_FORUM_WELCOME_LETTER]);
              queryClient.invalidateQueries([KEYS.GET_ITINERARY_BOOKLET_TEAM_MEMBERS]);
              queryClient.invalidateQueries([KEYS.GET_LANGUAGE_LABELS_TIME_FORMATS_ESSENTIALS]);
            },
          },
        );
      },
    });
  };

  const handleSearch = (
    selectedKeys: string[] | BaseOptionType[],
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case "code": {
        setEventCodeSearch(selectedKeys[0] as string);
        break;
      }
      case "shortName": {
        setEventNameSearch(selectedKeys[0] as string);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "code": {
        setEventCodeSearch(undefined);
        break;
      }
      case "shortName": {
        setEventNameSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = [
    new TableColumn(t("Location"), "countryCode"),
    new TableColumn(t("Event Code"), "code", {
      sorter: true,
      ...getColumnSearchProps("code", handleSearch, handleReset, "code"),
    }),
    new TableColumn(t("Event Name"), "shortName", {
      sorter: true,
      ...getColumnSearchProps("shortName", handleSearch, handleReset, "shortName"),
    }),
    new TableColumn("", "id", {
      align: "center",
      width: "10%",
      render: (existingForumId: number, forum: ForumResponseModel) => (
        <Button
          disabled={existingForumId === forumId}
          onClick={() => handleForumSelect(existingForumId, forum.shortName)}
        >
          {t("Select")}
        </Button>
      ),
    }),
  ];

  const handleYearsFilterSelect = (value: number) => {
    setYearFilter(value);
  };

  const handleLocationFilterSelect = (value: string) => {
    setCountryFilter(value);
  };

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Typography.Title level={5} style={{ margin: 0 }}>
        {t("Select the document setup to copy:")}
      </Typography.Title>
      <div className={styles.filtersBox}>
        <Select
          onSelect={(value) => handleYearsFilterSelect(value)}
          options={years.map((value: number) => ({ value, label: value }))}
          loading={isYearsLoading}
          placeholder={t("Select Year")}
          value={yearFilter}
          allowClear
          onClear={() => setYearFilter(undefined)}
        />
        <Select
          onSelect={(value) => handleLocationFilterSelect(value)}
          options={countries.map(({ isoCode, name }: CountryModel) => ({
            value: isoCode,
            label: name,
          }))}
          loading={isCountriesLoading}
          placeholder={t("Select Location")}
          value={countryFilter}
          allowClear
          onClear={() => setCountryFilter(undefined)}
        />
      </div>
      <Table
        id="copyForumSetupTable"
        data-testid="copySetupTable"
        dataSource={forums}
        columns={columns}
        pagination={pagination}
        bordered={true}
        rowKey="id"
        loading={isLoading || isCopyMutationLoading}
        onChange={handleSort}
      />
    </Space>
  );
};

export default CopySetup;
