import { UserPreferencesApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(UserPreferencesApi);

const UsersPreferences = {
  get: api.favoriteForumsGet,
  put: api.favoriteForumsForumIdPut,
  delete: api.favoriteForumsForumIdDelete,
};

export default UsersPreferences;
