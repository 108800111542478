import { TabsProps } from "antd";
import { Layout, Space, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { toNumber } from "lodash-es";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { DocumentDefinitionTypes } from "../../../generated/api";
import DocumentDefinitions from "./DocumentDefinitions";
import EditDocumentDefinitionDrawer from "./DocumentDefinitions/EditDocumentDefinitionDrawer";
import TimeTypeLabels from "./TimeTypeLabels";

import styles from "./styles.module.less";

export default function Advanced() {
  const [isEditDocumentDefinitionDrawerVisible, setIsEditDocumentDefinitionDrawerVisible] = useState<boolean>(false);
  const [activeDocumentDefinitionSectionId, setActiveDocumentDefinitionSectionId] = useState<number | null>(null);
  const [activeDocumentDefinitionId, setActiveDocumentDefinitionType] = useState<DocumentDefinitionTypes | null>(null);

  const { t } = useTranslation();
  const { id: forumId } = useParams();

  const onEditDefinitionSection = (documentDefinitionSectionId: number) => {
    setActiveDocumentDefinitionSectionId(documentDefinitionSectionId);
    setIsEditDocumentDefinitionDrawerVisible(true);
  };

  const onCloseEditDocumentDefinitionSectionDrawer = () => {
    setActiveDocumentDefinitionSectionId(null);
    setIsEditDocumentDefinitionDrawerVisible(false);
  };

  const tabs: TabsProps["items"] = [
    {
      key: "documentDefinitions",
      label: t("Document Definitions"),
      children: <DocumentDefinitions {...{ onEditDefinitionSection, setActiveDocumentDefinitionType }} />,
    },
    {
      key: "timeTypeLabels",
      label: t("Time Type Labels"),
      children: <TimeTypeLabels />,
    },
  ];

  return (
    <div>
      <UserGuide.Title title="Advanced" articleId="16441757547165-advanced" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Layout className={styles.advancedSection}>
          <Tabs items={tabs} />
        </Layout>
      </Space>
      {activeDocumentDefinitionId && activeDocumentDefinitionSectionId && (
        <EditDocumentDefinitionDrawer
          forumId={toNumber(forumId)}
          documentDefinitionSectionId={activeDocumentDefinitionSectionId}
          isVisible={isEditDocumentDefinitionDrawerVisible}
          onClose={onCloseEditDocumentDefinitionSectionDrawer}
        />
      )}
    </div>
  );
}
