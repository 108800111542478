import { Layout, Space, Tabs } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import MappingAccountsToLocations from "./MappingAccountsToLocations";
import MappingWebinarsToSessions from "./MappingWebinarsToSessions";

import styles from "./styles.module.less";

const { Content } = Layout;

const ManageVirtualForum: FC = () => {
  const { t } = useTranslation();

  const items = [
    {
      label: t("Mapping Accounts to Locations"),
      key: "accounts",
      children: <MappingAccountsToLocations />,
    },
    {
      label: t("Mapping Webinars to Sessions"),
      key: "webinars",
      children: <MappingWebinarsToSessions />,
    },
  ];

  return (
    <div className={styles.container}>
      <UserGuide.Title title="Manage Virtual Forum" articleId="16441637320093-manage-virtual-forum" />
      <Space direction="vertical" size="middle" className={styles.space}>
        <Content className={styles.content}>
          <Tabs items={items} />
        </Content>
      </Space>
    </div>
  );
};

export default ManageVirtualForum;
