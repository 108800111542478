import { useSeminarsQuery } from "api/queries/seminars";
import { Button, Table } from "components/styleguide";
import { ForumSeminarResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, TableColumn } from "utils/tableColumnUtils";

import LinkToReport from "../../components/LinkToReport";

interface SeminarsTableOptionProps {
  onSeminarSelect?: (seminar: ForumSeminarResponseModel) => void;
}

const SeminarsTableOption = ({ onSeminarSelect }: SeminarsTableOptionProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [codeSearch, setCodeSearch] = useState<string>();
  const [titleSearch, setTitleSearch] = useState<string>();

  const {
    response: { isLoading, data: { data: seminars = [] } = {} } = {},
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useSeminarsQuery,
    {},
    {
      forumId: toNumber(id),
      code: codeSearch,
      title: titleSearch,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 50, 100], showSizeChange: false },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "code": {
        setCodeSearch(selectedKeys[0] as string);
        break;
      }
      case "title": {
        setTitleSearch(selectedKeys[0] as string);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "code": {
        setCodeSearch(undefined);
        break;
      }
      case "title": {
        setTitleSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = [
    new TableColumn(t("Code"), "code", {
      width: "15%",
      ...getColumnSearchProps("code", handleSearch, handleReset, "code", null),
    }),
    new TableColumn(t("Name"), "title", {
      ...getColumnSearchProps("title", handleSearch, handleReset, "title", null),
    }),
    new TableColumn("", "id", {
      width: "10%",
      render: (seminarId: number, seminar: ForumSeminarResponseModel) =>
        onSeminarSelect ? (
          <Button onClick={() => onSeminarSelect(seminar)}>{t("Select").toString()}</Button>
        ) : (
          <LinkToReport parameters={{ ForumSeminarId: seminarId }} />
        ),
    }),
  ];
  return (
    <Table
      columns={columns}
      dataSource={seminars}
      loading={isLoading}
      rowKey="id"
      pagination={pagination}
      onChange={handleSort}
    />
  );
};

export default SeminarsTableOption;
