import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateItContractApprovalMutation, useUpdateItContractItemMutation } from "api/mutations/itContracts";
import { useGetItContractExport, useGetItContractItemsQuery, useGetItContractQuery } from "api/queries/itContracts";
import KEYS from "api/queries/keys";
import { Button, Layout, Modal, Space, Table } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { ITContractApprovalStatus } from "enums/ITContractApprovalStatus";
import { ForumITContractItemRequestModel, ForumITContractItemResponseModel } from "generated/api";
import { EventLayoutContext } from "layouts/EventLayout/types";
import { toNumber } from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { Role } from "utils/userRole";

import { downloadFileByURL } from "../../utils/fileUtils";
import EditDrawer from "./EditDrawer";
import { getApprovalColumns, getContractColumns } from "./helpers";

import styles from "./styles.module.less";

const { confirm } = Modal;
const { Content } = Layout;

const ItContracts = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { forum } = useOutletContext<EventLayoutContext>();

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [contractToEdit, setContractToEdit] = useState<ForumITContractItemResponseModel>({});

  const editContract = useCallback((record: ForumITContractItemResponseModel) => {
    setContractToEdit(record);
    setIsEditDrawerOpen(true);
  }, []);

  const updateItContractItemMutation = useUpdateItContractItemMutation();
  const updateItContractApprovalMutation = useUpdateItContractApprovalMutation();

  const { isLoading: isContractItemsLoading, data: { data: itContracts = [] } = {} } = useGetItContractItemsQuery({
    forumId: toNumber(id),
  });

  const { isLoading: isContractApprovalLoading, data: { data: itContractApproval = {} } = {} } = useGetItContractQuery({
    forumId: toNumber(id),
  });

  const itContractsExport = useGetItContractExport(
    { forumId: parseInt(id ? id : "-1") },
    {
      onSuccess: ({ data }) => {
        downloadFileByURL(data);
      },
      enabled: false,
    },
  );

  const columns = getContractColumns(editContract, itContractApproval);
  const approvalColumns = getApprovalColumns();

  const exportItContracts = () => {
    itContractsExport.refetch();
  };

  const onClose = useCallback(() => {
    if (updateItContractItemMutation.isLoading) {
      return;
    }

    setIsEditDrawerOpen(false);
  }, [updateItContractItemMutation.isLoading]);

  const onFinish = useCallback(
    ({ dueDate, ...values }: ForumITContractItemRequestModel) => {
      const forumITContractItemRequestModel = {
        dueDate: dueDate && moment(dueDate).format("YYYY-MM-DD"),
        ...values,
      };

      updateItContractItemMutation.mutate(
        {
          forumId: toNumber(id),
          itemId: contractToEdit.id as number,
          forumITContractItemRequestModel,
        },
        {
          onSuccess: () => {
            onClose();
            queryClient.resetQueries([KEYS.GET_FORUM_IT_CONTRACT_ITEMS]);
          },
        },
      );
    },
    [contractToEdit.id, id, updateItContractItemMutation, queryClient, onClose],
  );

  const showConfirmation = () => {
    confirm({
      title: t("confirmation"),
      content: t("Do you want to proceed with {{approvalType}} of this IT Contract?", {
        approvalType: itContractApproval.status === ITContractApprovalStatus.Approved ? "rejection" : "approval",
      }),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: () => {
        const newStatus =
          itContractApproval.status === ITContractApprovalStatus.Approved
            ? ITContractApprovalStatus.Rejected
            : ITContractApprovalStatus.Approved;

        updateItContractApprovalMutation.mutate(
          { forumId: toNumber(id), forumITContractStatusRequestModel: { status: newStatus } },
          {
            onSuccess: () => {
              queryClient.resetQueries([KEYS.GET_FORUM_IT_CONTRACT_APPROVAL]);
            },
          },
        );
      },
    });
  };

  return (
    <div className={styles.itContractPage}>
      <UserGuide.Title title="IT Contract" articleId="16426940988317-it-contract" />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Content className={styles.itContractContent}>
          <div className={styles.buttons}>
            <Button onClick={exportItContracts}>{t("exportPDF")}</Button>
            <Role renderIf={({ SYSTEM_ADMIN }) => SYSTEM_ADMIN}>
              <Button onClick={showConfirmation}>
                {itContractApproval.status === ITContractApprovalStatus.Approved ? t("Reject") : t("Approve")}
              </Button>
            </Role>
          </div>
          {itContractApproval.status !== ITContractApprovalStatus.Undetermined && (
            <Table
              id="contractApprovalTable"
              dataSource={[itContractApproval]}
              columns={approvalColumns}
              bordered={true}
              rowKey={"id"}
              pagination={false}
              loading={isContractApprovalLoading || updateItContractApprovalMutation.isLoading}
            />
          )}
          <Table
            id="contractTable"
            dataSource={itContracts}
            columns={columns}
            bordered={true}
            rowKey={"id"}
            pagination={false}
            loading={isContractItemsLoading}
          />
          <EditDrawer
            isOpen={isEditDrawerOpen}
            isUpdateLoading={updateItContractItemMutation.isLoading}
            contractToEdit={contractToEdit}
            onClose={onClose}
            onFinish={onFinish}
            forum={forum}
          />
        </Content>
      </Space>
    </div>
  );
};

export default ItContracts;
