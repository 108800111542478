import moment from "moment/moment";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import * as yup from "yup";

import QuestionnaireStatus from "../../../../backend-models/questionnaireStatus";
import QuestionnaireType from "../../../../backend-models/questionnaireType";
import { Button, DatePicker, Form, Input, Select, Space, Typography } from "../../../../components/styleguide";
import useSet from "../../../../hooks/useSet";
import GroupSelect from "./GroupSelect";

const getSchema = (form) => {
  return yup.object().shape({
    name: yup.string().required("Questionnaire name is required!"),
    availableFrom: yup.date().nullable().default(undefined).required(`"Available from" is required!`),
    availableTo: yup
      .date()
      .nullable()
      .default(undefined)
      .required(`"Available to" is required!`)
      .when("availableFrom", (_, schema) => {
        if (form.getFieldValue("availableFrom")) {
          return schema.min(
            moment(form.getFieldValue("availableFrom")).toDate(),
            `"Available to" should be greater than "Available from"`,
          );
        }

        return schema;
      }),
    type: yup.string().required("Questionnaire type is required!"),
    forumGroupIds: yup
      .array()
      .of(yup.number())
      .min(1, "Questionnaire group is required!")
      .required("Questionnaire group is required!"),
    status: yup.string().required("Questionnaire status is required!"),
  });
};

const QuestionnaireForm = ({
  forumId,
  initialValues,
  isLoading,
  labelCol,
  error,
  hideFields,
  showCancel,
  onFinish,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const validation = Form.useYupValidator(form, getSchema);
  const hiddenFields = useSet(hideFields);

  useEffect(() => {
    hiddenFields.set(hideFields);
  }, [hideFields]);

  const handleAvailableFromChange = () => {
    // noinspection JSIgnoredPromiseFromCall
    form.validateFields(["availableTo"]);
  };

  return (
    <Form form={form} data-testid="questionnaireForm" labelCol={labelCol} onFinish={onFinish}>
      {!hiddenFields.has("name") && (
        <Form.Item
          label="Questionnaire name"
          name="name"
          rules={validation.rules}
          required={true}
          initialValue={initialValues.name}
        >
          <Input />
        </Form.Item>
      )}
      {!hiddenFields.has("availableFrom") && (
        <Form.Item
          label="Available from"
          name="availableFrom"
          rules={validation.rules}
          required={true}
          initialValue={initialValues.availableFrom}
        >
          <DatePicker removeMargins onChange={handleAvailableFromChange} />
        </Form.Item>
      )}
      {!hiddenFields.has("availableTo") && (
        <Form.Item
          label="Available to"
          name="availableTo"
          rules={validation.rules}
          required={true}
          initialValue={initialValues.availableTo}
        >
          <DatePicker removeMargins />
        </Form.Item>
      )}
      {!hiddenFields.has("type") && (
        <Form.Item
          label="Questionnaire type"
          name="type"
          rules={validation.rules}
          required={true}
          initialValue={initialValues.type}
        >
          <Select>
            {QuestionnaireType.LIST.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {!hiddenFields.has("forumGroupIds") && (
        <Form.Item
          label="Questionnaire group"
          name="forumGroupIds"
          rules={validation.rules}
          required={true}
          initialValue={initialValues?.forumGroups?.map(({ id }) => id)}
        >
          <GroupSelect forumId={forumId} mode="multiple" />
        </Form.Item>
      )}
      {!hiddenFields.has("status") && (
        <Form.Item
          label="Questionnaire status"
          name="status"
          rules={validation.rules}
          required={true}
          initialValue={initialValues.status}
        >
          <Select>
            {QuestionnaireStatus.LIST.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {error && <Typography.Text type="danger">{error}</Typography.Text>}
      <Space>
        <Button htmlType="submit" type="primary" disabled={isLoading} loading={isLoading}>
          Save
        </Button>
        {showCancel && (
          <Button type="secondary" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </Space>
    </Form>
  );
};

QuestionnaireForm.propTypes = {
  forumId: PropTypes.number.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    availableFrom: PropTypes.string,
    availableTo: PropTypes.string,
    type: PropTypes.oneOf(QuestionnaireType.LIST),
    status: PropTypes.oneOf(QuestionnaireStatus.LIST),
    forumGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  hideFields: PropTypes.arrayOf(
    PropTypes.oneOf(["name", "availableFrom", "availableTo", "type", "forumGroupIds", "status"]),
  ),
  labelCol: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  showCancel: PropTypes.bool,
  onFinish: PropTypes.func,
  onCancel: PropTypes.func,
};

QuestionnaireForm.defaultProps = {
  initialValues: {},
  hideFields: [],
  labelCol: undefined,
  error: undefined,
  isLoading: false,
  showCancel: false,
  onFinish: () => undefined,
  onCancel: () => undefined,
};

export default QuestionnaireForm;
