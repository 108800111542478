import { useQueryClient } from "@tanstack/react-query";
import { ColProps } from "antd";
import { useUpdateAttendeeHotelAndRoomAllocation } from "api/mutations/attendees";
import { useGetHotelAndRoomAllocationQuery } from "api/queries/attendees";
import { useGetBillingNotesQuery } from "api/queries/billingNotes";
import { useForumBuildingsQuery } from "api/queries/forumBuilding";
import { useForumBedroomsQuery } from "api/queries/forumFacilities";
import { useForumRoomGradesQuery } from "api/queries/forumRoomGrades";
import KEYS from "api/queries/keys";
import { Button, Col, Divider, Form, Input, Row, Spin } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import {
  BuildingResponseModel,
  FacilityResponseModel,
  ForumAttendeeHotelRoomAllocationRequestModel,
  ForumAttendeeHotelRoomAllocationResponseModel,
  RoomGradeResponseModel,
} from "generated/api";
import { EventLayoutContext } from "layouts/EventLayout/types";
import { toNumber } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import useGetFormFields from "./helpers";

import styles from "./styles.module.less";

interface HotelAndRoomAllocationProps {
  isEditing: boolean;
  setIsEditing: (_: boolean) => void;
}

const HotelAndRoomAllocation = ({ isEditing, setIsEditing }: HotelAndRoomAllocationProps) => {
  const params = useParams();
  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { forum } = useOutletContext<EventLayoutContext>();

  const { mutate: updateHotelRoomAllocation, isLoading: isHotelRoomAllocationUpdating } =
    useUpdateAttendeeHotelAndRoomAllocation();

  const [buildingId, setBuildingId] = useState<number | undefined>();
  const [roomGradeId, setRoomGradeId] = useState<number | undefined>();
  const [bedroomId, setBedroomId] = useState<number | undefined>();

  const setInitialValues = (data: ForumAttendeeHotelRoomAllocationResponseModel) => {
    setBuildingId(data.forumBuildingId ?? undefined);
    setRoomGradeId(data.roomGradeId ?? undefined);
    setBedroomId(data.bedroomId ?? undefined);
  };

  const {
    data: { data: hotelAndRoomAllocation = {} as ForumAttendeeHotelRoomAllocationResponseModel } = {},
    isLoading: isHotelAndRoomAllocationLoading,
  } = useGetHotelAndRoomAllocationQuery(
    { forumId, attendeeId },
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => setInitialValues(data),
    },
  );

  const { data: { data: buildings = [] as BuildingResponseModel[] } = {} } = useForumBuildingsQuery({ forumId });

  const { data: { data: roomGrades = [] as RoomGradeResponseModel[] } = {}, refetch: refetchRoomGrades } =
    useForumRoomGradesQuery(
      {
        forumId,
        forumBuildingId: toNumber(buildingId),
        onlyInUseInBedrooms: true,
      },
      {
        enabled: !!buildingId,
      },
    );

  const { data: { data: bedrooms = [] as FacilityResponseModel[] } = {}, refetch: refetchBedrooms } =
    useForumBedroomsQuery(
      {
        forumId,
        forumBuildingId: toNumber(buildingId),
        roomGrade: roomGrades.find(({ id }) => id === roomGradeId)?.name,
      },
      {
        enabled: !!buildingId && !!roomGradeId,
      },
    );

  const { data: { data: billingNotes = [] } = {}, isLoading: isBillingNotesLoading } = useGetBillingNotesQuery({
    forumId,
  });

  const onChangeBuilding = (newBuildingId: number) => {
    setBuildingId(newBuildingId);
    setRoomGradeId(undefined);
    setBedroomId(undefined);
  };

  const onChangeRoomGrade = (newRoomGradeId: number) => {
    setRoomGradeId(newRoomGradeId);
    setBedroomId(undefined);
  };

  const onChangeBedroom = (newBedroomId: number, { code }: { code: string }) => {
    form.setFieldValue("facilityCode", code);

    setBedroomId(newBedroomId);

    const bedroomRoomGrade = bedrooms.find(({ id }) => id === newBedroomId)?.roomGrade;
    const bedroomRoomGradeId = roomGrades.find(({ name }) => name === bedroomRoomGrade)?.id;
    setRoomGradeId(bedroomRoomGradeId);
  };

  // when the form item values are not updated after updating speaker tracking dates
  useEffect(() => {
    if (hotelAndRoomAllocation?.checkInDate && hotelAndRoomAllocation?.checkOutDate) {
      form.setFieldsValue({
        checkInDate: moment.utc(hotelAndRoomAllocation.checkInDate),
        checkOutDate: moment.utc(hotelAndRoomAllocation.checkOutDate),
      });
    }
  }, [hotelAndRoomAllocation?.checkInDate, hotelAndRoomAllocation?.checkOutDate, form]);

  useEffect(() => {
    if (buildingId) {
      refetchRoomGrades();
      refetchBedrooms();
    }

    form.setFieldValue("forumBuildingId", buildingId);
  }, [buildingId]);

  useEffect(() => {
    if (buildingId) {
      refetchBedrooms();
    }

    form.setFieldValue("roomGradeId", roomGradeId);
  }, [roomGradeId]);

  useEffect(() => {
    form.setFieldValue("bedroomId", bedroomId);

    if (!bedroomId) {
      form.setFieldValue("billingNoteId", undefined);
      form.setFieldValue("facilityCode", undefined);
    }
  }, [bedroomId]);

  const [formFields] = useGetFormFields({
    hotelAndRoomAllocation,
    billingNotes,
    roomGrades,
    bedrooms,
    roomGradeId,
    buildingId,
    bedroomId,
    onChangeRoomGrade,
    onChangeBedroom,
    onChangeBuilding,
    buildings,
    forum,
  });

  const cancelEdit = () => {
    setInitialValues(hotelAndRoomAllocation);
    form.resetFields();
    setIsEditing(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = ({ checkInDate, checkOutDate, ...values }: any) => {
    const forumAttendeeHotelRoomAllocationRequestModel = {
      checkInDate: moment(checkInDate).format("YYYY-MM-DD"),
      checkOutDate: moment(checkOutDate).format("YYYY-MM-DD"),
      forumBedroomId: values.bedroomId,
      billingNoteId: values.billingNoteId,
      hotelNote: values.hotelNote,
    } as ForumAttendeeHotelRoomAllocationRequestModel;

    updateHotelRoomAllocation(
      {
        forumId,
        attendeeId,
        forumAttendeeHotelRoomAllocationRequestModel,
      },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.ATTENDEE_SPEAKER_TRACKING_QUERY]);
          queryClient.resetQueries([KEYS.GET_ATTENDEE_HOTEL_AND_ROOM_ALLOCATION]);

          setIsEditing(false);
        },
        onError: ({ response }) => {
          if (ErrorTypes.isOfType(response, ErrorTypes.InvalidTimePeriod)) {
            toast.error(t("Check Out Date should be greater than Check In Date"));
          } else {
            toast.error(t("Something went wrong"));
          }
        },
      },
    );
  };

  if (isHotelAndRoomAllocationLoading || isBillingNotesLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div>
      <Form
        onFinish={submit}
        id="hotelAndRoomAllocationForm"
        name="hotelAndRoomAllocation"
        className={styles.hotelAndRoomAllocation}
        form={form}
        labelAlign="left"
        labelCol={{ justify: "left", span: 13 } as ColProps}
        wrapperCol={{ justify: "right", span: 11 } as ColProps}
        labelWrap
        colon={false}
      >
        <Row gutter={80}>
          {formFields.map((f) => (
            <Col key={f.dataIndex} className={styles.formItemCol} md={24} lg={12}>
              <Form.Item
                initialValue={
                  f.initialValue ?? hotelAndRoomAllocation[f.dataIndex as keyof typeof hotelAndRoomAllocation]
                }
                label={t(f.title)}
                name={f.dataIndex}
                rules={isEditing ? f.rules : undefined}
                className={styles.formItem}
              >
                {isEditing && !f.isReadonly ? (
                  f.component ?? <Input disabled />
                ) : (
                  <label>
                    {f.label ?? hotelAndRoomAllocation[f.dataIndex as keyof typeof hotelAndRoomAllocation] ?? "-"}
                  </label>
                )}
              </Form.Item>
              <Divider />
            </Col>
          ))}
        </Row>
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={isHotelRoomAllocationUpdating} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default HotelAndRoomAllocation;
