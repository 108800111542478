import { ItineraryBookletsProfileSetupApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ItineraryBookletsProfileSetupApi);

const ItineraryBookletsProfileSetup = {
  get: api.forumsForumIdBookletProfileSetupGet,
  put: api.forumsForumIdBookletProfileSetupPut,
};

export default ItineraryBookletsProfileSetup;
