import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { Select } from "../../../inputs";

// filters array of strings for strings that isn't NaN
// and then converts them to numbers, remove duplicates
// and after that sorts them in ascending order
const normalize = (array) => {
  if (!Array.isArray(array) || array.length === 0) {
    return [];
  }

  // remove duplicates
  const unique = [...new Set(array)];

  // remove NaN
  const filtered = unique.filter((value) => !Number.isNaN(Number(value)));

  // convert to numbers
  const numbers = filtered.map((value) => Number(value));

  // sort in ascending order
  return numbers.sort((a, b) => a - b);
};

const PageSizesAvailableForTheSelectionGrid = ({ value, onChange }) => {
  // To not have bugs when `10` stored in the options and `"10"` received
  // when user types it because of `tags` mode we need to
  // convert all values to strings.
  //
  // The conversion back to numbers will be done in the `normalize` function
  // which is called when form is submitted, so normalized (converted to numbers) values
  // will be sent to backend.
  const stringsFromArrayOfNumbers = useMemo(() => {
    if (!Array.isArray(value) || value.length === 0) {
      return [];
    }

    return value.map((value) => value.toString());
  }, [value]);

  return (
    <Select mode="tags" value={stringsFromArrayOfNumbers} onChange={onChange}>
      <Select.Option value="10">10</Select.Option>
      <Select.Option value="20">20</Select.Option>
      <Select.Option value="50">50</Select.Option>
    </Select>
  );
};

PageSizesAvailableForTheSelectionGrid.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

PageSizesAvailableForTheSelectionGrid.defaultProps = {
  value: undefined,
  onChange: () => undefined,
};

PageSizesAvailableForTheSelectionGrid.normalize = normalize;

export default PageSizesAvailableForTheSelectionGrid;
