import { ForumCompaniesApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumCompaniesApi);

const ForumCompanies = {
  getAll: api.forumForumIdCompaniesGet,
  post: api.forumForumIdCompaniesPost,
  get: api.forumForumIdCompaniesForumCompanyIdGet,
  exhibitingStatus: {
    put: api.forumForumIdCompaniesForumCompanyIdExhibitingStatusPut,
  },
  addressInformation: {
    put: api.forumForumIdCompaniesForumCompanyIdAddressInformationPut,
  },
  socialLinks: {
    put: api.forumForumIdCompaniesForumCompanyIdSocialLinksPut,
  },
  persons: {
    get: api.forumForumIdCompaniesForumCompanyIdPersonsGet,
  },
  attendees: {
    get: api.forumForumIdCompaniesForumCompanyIdAttendeesGet,
  },
  primaryLocations: {
    get: api.forumForumIdCompaniesForumCompanyIdPrimaryLocationsGet,
    post: api.forumForumIdCompaniesForumCompanyIdPrimaryLocationsPost,
    put: api.forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdPut,
    delete: api.forumForumIdCompaniesForumCompanyIdPrimaryLocationsLocationIdDelete,
  },
  package: {
    get: api.forumForumIdCompaniesForumCompanyIdPackageGet,
    put: api.forumForumIdCompaniesForumCompanyIdPackagePut,
  },
  historyInformation: {
    get: api.forumForumIdCompaniesForumCompanyIdHistoryInformationGet,
  },
  export: {
    get: api.forumForumIdCompaniesExportGet,
  },
  description: {
    put: api.forumForumIdCompaniesForumCompanyIdDescriptionPut,
    getAll: api.forumForumIdCompaniesDescriptionsGet,
  },
};

export default ForumCompanies;
