import { Select } from "components/styleguide";
import BriefingCallStatus from "enums/BriefingCallStatus";
import { SpeakerTrackingResponseModel } from "generated/api";
import { languages } from "i18n";
import React from "react";

const { Option } = Select;

interface FormFieldsProp {
  dataIndex: string;
  title?: string;
  initialValue?: boolean | string;
  render?: boolean;
  component?: React.ReactNode;
  large?: boolean;
  hasDivider?: boolean;
}

const getFormFields = (t: (s: string) => string, speakerTracking: SpeakerTrackingResponseModel): FormFieldsProp[] => {
  return [
    {
      dataIndex: "prefferedLanguage",
      title: "Preferred Language",
      initialValue: speakerTracking.prefferedLanguage,
      component: (
        <Select>
          {Object.values(languages).map((language) => (
            <Option value={language.name} key={language.name}>
              {language.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      dataIndex: "speakerGuide",
      title: "Sent Speaker Guide",
      initialValue: speakerTracking.speakerGuide,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "briefingCallStatus",
      title: "Briefing Call Status",
      initialValue: speakerTracking.briefingCallStatus,
      component: (
        <Select>
          {Object.keys(BriefingCallStatus)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .filter((key: any) => !isNaN(Number(BriefingCallStatus[key])))
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((s: any) => (
              <Option key={s} value={BriefingCallStatus[s]}>
                {s}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      dataIndex: "reveivedBiography",
      title: "Received Biography",
      initialValue: speakerTracking.reveivedBiography,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "recievedPhoto",
      title: "Received Photo",
      initialValue: speakerTracking.recievedPhoto,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "sentCopyrightForm",
      title: "Sent Copyright Form",
      initialValue: speakerTracking.sentCopyrightForm,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "recievedCopyrightForm",
      title: "Received Copyright Form",
      initialValue: speakerTracking.recievedCopyrightForm,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "sentPresentationTemplate",
      title: "Sent Presentation Template",
      initialValue: speakerTracking.sentPresentationTemplate,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "recievedTechnicalRequirments",
      title: "Received Technical Requirements",
      initialValue: speakerTracking.recievedTechnicalRequirments,
      hasDivider: false,
      component: (
        <Select>
          <Option value={true}>{t("Yes")}</Option>
          <Option value={false}>{t("No")}</Option>
        </Select>
      ),
    },
    {
      dataIndex: "",
    },
  ];
};

export { getFormFields };
