import { useForumTablesQuery } from "api/queries/forumTables";
import { Select } from "components/styleguide";
import { AttendeeSessionMeetingLocationModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";

type TableSelectType = string | number | null;
interface AttendeeMeetingLocationsAdvancedSetupSelectTableCellProps {
  forumId: number;
  meetingLocation: AttendeeSessionMeetingLocationModel;
  setMeetingLocation: (index: number, record: AttendeeSessionMeetingLocationModel) => void;
  index: number;
  loading: boolean;
  key: number;
}

const TableSelect = ({
  forumId,
  meetingLocation,
  setMeetingLocation,
  index,
  loading,
}: AttendeeMeetingLocationsAdvancedSetupSelectTableCellProps) => {
  const { t } = useTranslation();

  const forumTablesQuery = useForumTablesQuery(
    {
      forumId,
      forumBuildingId: meetingLocation.facility?.buildingId ?? 0,
      forumFacilityId: meetingLocation.facility?.id ?? 0,
    },
    { enabled: !!meetingLocation.facility?.buildingId && !!meetingLocation.facility?.id },
  );

  const forumTables = forumTablesQuery.data?.data ?? [];

  const handleTableSelectChange = (value: TableSelectType) => {
    setMeetingLocation(index, {
      ...meetingLocation,
      table: value ? { ...meetingLocation.table, id: Number(value) } : null,
    });
  };
  return (
    <Select
      style={{ width: "100%" }}
      disabled={!meetingLocation.facility?.buildingId || !meetingLocation.facility?.id || meetingLocation.isLocked}
      options={[
        { value: null, label: t("None") },
        ...forumTables.map((table) => ({ value: table.id, label: table.tableNumber })),
      ]}
      value={meetingLocation.table?.tableNumber || null}
      onChange={handleTableSelectChange}
      loading={loading}
    />
  );
};

export default TableSelect;
