import { useForumVisualSpeakerDocumentsQuery } from "api/queries/visualSpeakerDocuments";
import { Button, Table } from "components/styleguide";
import { ForumVisualSpeakerDocumentResponseModel } from "generated/api";
import moment from "moment";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import AddDocumentDrawer from "./AddDocumentDrawer";
import DeleteDocumentButton from "./DeleteDocumentButton";
import EditDocumentDrawer from "./EditDocumentDrawer";

import styles from "./styles.module.less";

const SpeakerVisualsDocuments: FC = () => {
  const { t } = useTranslation();
  const { id: forumId, seminarId } = useParams();

  const [selectedDocument, setSelectedDocument] = useState<ForumVisualSpeakerDocumentResponseModel | null>();
  const [isUploadDocumentDrawerVisible, setIsUploadDocumentDrawerVisible] = useState<boolean>(false);
  const [isEditDocumentDrawerVisible, setIsEditDocumentDrawerVisible] = useState<boolean>(false);

  const showUploadDocumentDrawer = () => setIsUploadDocumentDrawerVisible(true);

  const hideUploadDocumentDrawer = () => setIsUploadDocumentDrawerVisible(false);

  const showEditDocumentDrawer = (document: ForumVisualSpeakerDocumentResponseModel) => {
    setSelectedDocument(document);
    setIsEditDocumentDrawerVisible(true);
  };

  const hideEditDocumentDrawer = () => {
    setIsEditDocumentDrawerVisible(true);
    setSelectedDocument(null);
  };

  const visualSpeakerDocumentsQuery = useForumVisualSpeakerDocumentsQuery({
    forumId: Number(forumId),
    seminarId: Number(seminarId),
  });

  const renderDate = useCallback(
    (date: string) => {
      return <div className={styles.date_cell}>{date !== null ? moment(date).format(t("dateFormat")) : "-"}</div>;
    },
    [t],
  );

  const columns = useMemo(
    () => [
      {
        title: t("Title"),
        dataIndex: "title",
      },
      {
        title: t("Description"),
        dataIndex: "description",
      },
      {
        title: t("Publish from"),
        dataIndex: "publishedFromDate",
        render: renderDate,
      },
      {
        title: t("Publish until"),
        dataIndex: "publishedUntilDate",
        render: renderDate,
      },
      {
        dataIndex: "id",
        render: (id: number | undefined, document: ForumVisualSpeakerDocumentResponseModel) => {
          return (
            <div className={styles.buttonWrapper}>
              <Button onClick={() => showEditDocumentDrawer(document)}>{t("edit")}</Button>
              {id && <DeleteDocumentButton documentId={id} seminarId={String(seminarId)} />}
            </div>
          );
        },
      },
    ],
    [t, renderDate],
  );

  return (
    <>
      <AddDocumentDrawer isVisible={isUploadDocumentDrawerVisible} onClose={hideUploadDocumentDrawer} />
      {selectedDocument && (
        <EditDocumentDrawer
          document={selectedDocument}
          isVisible={isEditDocumentDrawerVisible}
          onClose={hideEditDocumentDrawer}
        />
      )}
      <Button onClick={() => showUploadDocumentDrawer()}>{t("Upload document")}</Button>
      <Table
        className={styles.documentsTable}
        id="seminarsTable"
        dataSource={visualSpeakerDocumentsQuery?.data?.data || []}
        columns={columns}
        loading={visualSpeakerDocumentsQuery.isLoading}
        pagination={false}
        rowKey="id"
      />
    </>
  );
};

export default SpeakerVisualsDocuments;
