import Icon, { StarFilled } from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { Button, Select } from "components/styleguide";
import { useTranslation } from "react-i18next";
import { useCheckMobileScreen } from "utils/screenUtils";

import styles from "./styles.module.less";

const { Option } = Select;

export default function ForumYearSelect({
  years,
  selectedYear,
  isLoading,
  shouldFilterFavorites,
  onSelect,
  onFavoriteSelect,
}: {
  years: number[];
  selectedYear?: number;
  isLoading: boolean;
  shouldFilterFavorites: boolean;
  onSelect: (year: number) => void;
  onFavoriteSelect: () => void;
}) {
  const { t } = useTranslation();
  const isMobile = useCheckMobileScreen();

  const maxYearDisplayIndex = isMobile ? 7 : 10;

  const displayedYears = years.slice(0, maxYearDisplayIndex);
  const hiddenYears = years.slice(maxYearDisplayIndex, years.length);

  return (
    <div className={styles.list}>
      <Button
        type="primary"
        onClick={onFavoriteSelect}
        className={`primary-button ${shouldFilterFavorites ? "active" : ""}`}
      >
        <Icon component={StarFilled as React.ForwardRefExoticComponent<CustomIconComponentProps>} />
        {t("event.favourites")}
      </Button>
      {displayedYears.map((year) => (
        <Button
          loading={selectedYear === year && isLoading}
          key={year}
          className={`${styles.listButton} ${year === selectedYear ? "active" : ""}`}
          onClick={() => onSelect(year)}
        >
          {year}
        </Button>
      ))}
      {!!hiddenYears.length && (
        <Select
          className={"event-button"}
          showSearch
          placeholder={t("event.more")}
          optionFilterProp="children"
          onChange={onSelect}
        >
          {hiddenYears.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
}
