import { useQueryClient } from "@tanstack/react-query";
import { useUpdateStreamMutation } from "api/mutations/streams";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { StreamRequestModel, StreamResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import StreamForm from "../StreamForm";

interface EditStreamDrawerProps {
  stream: StreamResponseModel;
  isVisible: boolean;
  onClose: () => void;
}

const EditStreamDrawer: FC<EditStreamDrawerProps> = ({ stream, isVisible, onClose }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { isLoading: isUpdateStreamLoading, mutate: updateStream } = useUpdateStreamMutation();

  const onSubmit = (values: StreamRequestModel) => {
    updateStream(
      {
        forumId,
        streamId: toNumber(stream.id),
        streamRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_STREAMS]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer title={t("Edit Stream")} open={isVisible} size="large" placement="right" destroyOnClose onClose={onClose}>
      <StreamForm
        initialValues={stream}
        submitButtonTitle={"Save"}
        isLoading={isUpdateStreamLoading}
        onSubmit={onSubmit}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default EditStreamDrawer;
