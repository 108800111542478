/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DuplicateItemExceptionResponse } from '../apiModels';
// @ts-ignore
import { ForumSeminarRequestModel } from '../apiModels';
// @ts-ignore
import { ForumSeminarResponseModel } from '../apiModels';
// @ts-ignore
import { ForumSeminarWithTypeAndStreamAndSpeakers } from '../apiModels';
// @ts-ignore
import { ForumSeminarsWithSessionsModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseForumSeminarSessionsModel } from '../apiModels';
// @ts-ignore
import { ReorderRequestModel } from '../apiModels';
// @ts-ignore
import { SpeakerForumAttendee } from '../apiModels';
/**
 * ForumSeminarApi - axios parameter creator
 * @export
 */
export const ForumSeminarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {string} [sessionCode] 
         * @param {string} [seminarCode] 
         * @param {string} [facilityCode] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsAllSessionsGet: async (forumId: number, sessionCode?: string, seminarCode?: string, facilityCode?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsAllSessionsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/seminars/all-sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sessionCode !== undefined) {
                localVarQueryParameter['SessionCode'] = sessionCode;
            }

            if (seminarCode !== undefined) {
                localVarQueryParameter['SeminarCode'] = seminarCode;
            }

            if (facilityCode !== undefined) {
                localVarQueryParameter['FacilityCode'] = facilityCode;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seminar by id
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdGet: async (forumId: number, forumSeminarId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdGet', 'forumId', forumId)
            // verify required parameter 'forumSeminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdGet', 'forumSeminarId', forumSeminarId)
            const localVarPath = `/forums/{forumId}/seminars/{forumSeminarId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarId"}}`, encodeURIComponent(String(forumSeminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get possible speakers - attendees with speaker participation codes and AttendeeStatus = Attend
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet: async (forumId: number, forumSeminarId: number, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet', 'forumId', forumId)
            // verify required parameter 'forumSeminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet', 'forumSeminarId', forumSeminarId)
            const localVarPath = `/forums/{forumId}/seminars/{forumSeminarId}/possible-speakers`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"forumSeminarId"}}`, encodeURIComponent(String(forumSeminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the seminars within a forum
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {string} [code] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsGet: async (forumId: number, orderBy?: string, orderDir?: string, code?: string, title?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/seminars`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (title !== undefined) {
                localVarQueryParameter['Title'] = title;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a seminar within a forum
         * @param {number} forumId 
         * @param {ForumSeminarRequestModel} [forumSeminarRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsPost: async (forumId: number, forumSeminarRequestModel?: ForumSeminarRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/seminars`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a ForumSeminar with all its associated SeminarSpeakers, AttendeeConferencePreferences and IForumVisualSpeakerDocuments
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdDelete: async (forumId: number, seminarId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdDelete', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdDelete', 'seminarId', seminarId)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update seminar
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {ForumSeminarRequestModel} [forumSeminarRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdPut: async (forumId: number, seminarId: number, forumSeminarRequestModel?: ForumSeminarRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdPut', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdPut', 'seminarId', seminarId)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumSeminarRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder seminars
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdReorderPut: async (forumId: number, seminarId: number, reorderRequestModel?: ReorderRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdReorderPut', 'forumId', forumId)
            // verify required parameter 'seminarId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSeminarIdReorderPut', 'seminarId', seminarId)
            const localVarPath = `/forums/{forumId}/seminars/{seminarId}/reorder`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"seminarId"}}`, encodeURIComponent(String(seminarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get seminars with sessions
         * @param {number} forumId 
         * @param {boolean} [excludeVirtualAndNotMappedSessions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSessionsGet: async (forumId: number, excludeVirtualAndNotMappedSessions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdSeminarsSessionsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/seminars/sessions`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludeVirtualAndNotMappedSessions !== undefined) {
                localVarQueryParameter['ExcludeVirtualAndNotMappedSessions'] = excludeVirtualAndNotMappedSessions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumSeminarApi - functional programming interface
 * @export
 */
export const ForumSeminarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumSeminarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {string} [sessionCode] 
         * @param {string} [seminarCode] 
         * @param {string} [facilityCode] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsAllSessionsGet(forumId: number, sessionCode?: string, seminarCode?: string, facilityCode?: string, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseForumSeminarSessionsModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsAllSessionsGet(forumId, sessionCode, seminarCode, facilityCode, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seminar by id
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsForumSeminarIdGet(forumId: number, forumSeminarId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumSeminarResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsForumSeminarIdGet(forumId, forumSeminarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get possible speakers - attendees with speaker participation codes and AttendeeStatus = Attend
         * @param {number} forumId 
         * @param {number} forumSeminarId 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet(forumId: number, forumSeminarId: number, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpeakerForumAttendee>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet(forumId, forumSeminarId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the seminars within a forum
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {string} [code] 
         * @param {string} [title] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsGet(forumId: number, orderBy?: string, orderDir?: string, code?: string, title?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumSeminarWithTypeAndStreamAndSpeakers>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsGet(forumId, orderBy, orderDir, code, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a seminar within a forum
         * @param {number} forumId 
         * @param {ForumSeminarRequestModel} [forumSeminarRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsPost(forumId: number, forumSeminarRequestModel?: ForumSeminarRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumSeminarResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsPost(forumId, forumSeminarRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a ForumSeminar with all its associated SeminarSpeakers, AttendeeConferencePreferences and IForumVisualSpeakerDocuments
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdDelete(forumId: number, seminarId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdDelete(forumId, seminarId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update seminar
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {ForumSeminarRequestModel} [forumSeminarRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdPut(forumId: number, seminarId: number, forumSeminarRequestModel?: ForumSeminarRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdPut(forumId, seminarId, forumSeminarRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder seminars
         * @param {number} forumId 
         * @param {number} seminarId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSeminarIdReorderPut(forumId: number, seminarId: number, reorderRequestModel?: ReorderRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSeminarIdReorderPut(forumId, seminarId, reorderRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get seminars with sessions
         * @param {number} forumId 
         * @param {boolean} [excludeVirtualAndNotMappedSessions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdSeminarsSessionsGet(forumId: number, excludeVirtualAndNotMappedSessions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumSeminarsWithSessionsModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdSeminarsSessionsGet(forumId, excludeVirtualAndNotMappedSessions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumSeminarApi - factory interface
 * @export
 */
export const ForumSeminarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumSeminarApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumSeminarApiForumsForumIdSeminarsAllSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsAllSessionsGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsAllSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseForumSeminarSessionsModel> {
            return localVarFp.forumsForumIdSeminarsAllSessionsGet(requestParameters.forumId, requestParameters.sessionCode, requestParameters.seminarCode, requestParameters.facilityCode, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seminar by id
         * @param {ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumSeminarResponseModel> {
            return localVarFp.forumsForumIdSeminarsForumSeminarIdGet(requestParameters.forumId, requestParameters.forumSeminarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get possible speakers - attendees with speaker participation codes and AttendeeStatus = Attend
         * @param {ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<SpeakerForumAttendee>> {
            return localVarFp.forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the seminars within a forum
         * @param {ForumSeminarApiForumsForumIdSeminarsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumSeminarWithTypeAndStreamAndSpeakers>> {
            return localVarFp.forumsForumIdSeminarsGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.code, requestParameters.title, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a seminar within a forum
         * @param {ForumSeminarApiForumsForumIdSeminarsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsPost(requestParameters: ForumSeminarApiForumsForumIdSeminarsPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumSeminarResponseModel> {
            return localVarFp.forumsForumIdSeminarsPost(requestParameters.forumId, requestParameters.forumSeminarRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a ForumSeminar with all its associated SeminarSpeakers, AttendeeConferencePreferences and IForumVisualSpeakerDocuments
         * @param {ForumSeminarApiForumsForumIdSeminarsSeminarIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdDelete(requestParameters: ForumSeminarApiForumsForumIdSeminarsSeminarIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarsSeminarIdDelete(requestParameters.forumId, requestParameters.seminarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update seminar
         * @param {ForumSeminarApiForumsForumIdSeminarsSeminarIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdPut(requestParameters: ForumSeminarApiForumsForumIdSeminarsSeminarIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarsSeminarIdPut(requestParameters.forumId, requestParameters.seminarId, requestParameters.forumSeminarRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder seminars
         * @param {ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSeminarIdReorderPut(requestParameters: ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdSeminarsSeminarIdReorderPut(requestParameters.forumId, requestParameters.seminarId, requestParameters.reorderRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get seminars with sessions
         * @param {ForumSeminarApiForumsForumIdSeminarsSessionsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdSeminarsSessionsGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsSessionsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumSeminarsWithSessionsModel>> {
            return localVarFp.forumsForumIdSeminarsSessionsGet(requestParameters.forumId, requestParameters.excludeVirtualAndNotMappedSessions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdSeminarsAllSessionsGet operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsAllSessionsGetRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsAllSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly sessionCode?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly seminarCode?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly facilityCode?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsAllSessionsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsForumIdSeminarsForumSeminarIdGet operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGetRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGet
     */
    readonly forumSeminarId: number
}

/**
 * Request parameters for forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGetRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet
     */
    readonly forumSeminarId: number

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet
     */
    readonly name?: string
}

/**
 * Request parameters for forumsForumIdSeminarsGet operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsGetRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsGet
     */
    readonly orderDir?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsGet
     */
    readonly code?: string

    /**
     * 
     * @type {string}
     * @memberof ForumSeminarApiForumsForumIdSeminarsGet
     */
    readonly title?: string
}

/**
 * Request parameters for forumsForumIdSeminarsPost operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsPostRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumSeminarRequestModel}
     * @memberof ForumSeminarApiForumsForumIdSeminarsPost
     */
    readonly forumSeminarRequestModel?: ForumSeminarRequestModel
}

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdDelete operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsSeminarIdDeleteRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsSeminarIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdDelete
     */
    readonly seminarId: number
}

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdPut operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsSeminarIdPutRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsSeminarIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdPut
     */
    readonly seminarId: number

    /**
     * 
     * @type {ForumSeminarRequestModel}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdPut
     */
    readonly forumSeminarRequestModel?: ForumSeminarRequestModel
}

/**
 * Request parameters for forumsForumIdSeminarsSeminarIdReorderPut operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPutRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPut
     */
    readonly seminarId: number

    /**
     * 
     * @type {ReorderRequestModel}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPut
     */
    readonly reorderRequestModel?: ReorderRequestModel
}

/**
 * Request parameters for forumsForumIdSeminarsSessionsGet operation in ForumSeminarApi.
 * @export
 * @interface ForumSeminarApiForumsForumIdSeminarsSessionsGetRequest
 */
export interface ForumSeminarApiForumsForumIdSeminarsSessionsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSessionsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {boolean}
     * @memberof ForumSeminarApiForumsForumIdSeminarsSessionsGet
     */
    readonly excludeVirtualAndNotMappedSessions?: boolean
}

/**
 * ForumSeminarApi - object-oriented interface
 * @export
 * @class ForumSeminarApi
 * @extends {BaseAPI}
 */
export class ForumSeminarApi extends BaseAPI {
    /**
     * 
     * @param {ForumSeminarApiForumsForumIdSeminarsAllSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsAllSessionsGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsAllSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsAllSessionsGet(requestParameters.forumId, requestParameters.sessionCode, requestParameters.seminarCode, requestParameters.facilityCode, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seminar by id
     * @param {ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsForumSeminarIdGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsForumSeminarIdGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsForumSeminarIdGet(requestParameters.forumId, requestParameters.forumSeminarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get possible speakers - attendees with speaker participation codes and AttendeeStatus = Attend
     * @param {ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsForumSeminarIdPossibleSpeakersGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsForumSeminarIdPossibleSpeakersGet(requestParameters.forumId, requestParameters.forumSeminarId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the seminars within a forum
     * @param {ForumSeminarApiForumsForumIdSeminarsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.code, requestParameters.title, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a seminar within a forum
     * @param {ForumSeminarApiForumsForumIdSeminarsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsPost(requestParameters: ForumSeminarApiForumsForumIdSeminarsPostRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsPost(requestParameters.forumId, requestParameters.forumSeminarRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a ForumSeminar with all its associated SeminarSpeakers, AttendeeConferencePreferences and IForumVisualSpeakerDocuments
     * @param {ForumSeminarApiForumsForumIdSeminarsSeminarIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsSeminarIdDelete(requestParameters: ForumSeminarApiForumsForumIdSeminarsSeminarIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsSeminarIdDelete(requestParameters.forumId, requestParameters.seminarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update seminar
     * @param {ForumSeminarApiForumsForumIdSeminarsSeminarIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsSeminarIdPut(requestParameters: ForumSeminarApiForumsForumIdSeminarsSeminarIdPutRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsSeminarIdPut(requestParameters.forumId, requestParameters.seminarId, requestParameters.forumSeminarRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder seminars
     * @param {ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsSeminarIdReorderPut(requestParameters: ForumSeminarApiForumsForumIdSeminarsSeminarIdReorderPutRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsSeminarIdReorderPut(requestParameters.forumId, requestParameters.seminarId, requestParameters.reorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get seminars with sessions
     * @param {ForumSeminarApiForumsForumIdSeminarsSessionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumSeminarApi
     */
    public forumsForumIdSeminarsSessionsGet(requestParameters: ForumSeminarApiForumsForumIdSeminarsSessionsGetRequest, options?: AxiosRequestConfig) {
        return ForumSeminarApiFp(this.configuration).forumsForumIdSeminarsSessionsGet(requestParameters.forumId, requestParameters.excludeVirtualAndNotMappedSessions, options).then((request) => request(this.axios, this.basePath));
    }
}

