import { ForumGroupApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumGroupApi);

const ForumGroup = {
  getAll: api.forumForumIdGroupsGet,
  post: api.forumForumIdGroupsPost,
  get: api.forumForumIdGroupsGroupIdGet,
  put: api.forumForumIdGroupsGroupIdPut,
  flags: {
    put: api.forumForumIdGroupsGroupIdFlagsPut,
  },
  availableCodes: {
    getAll: api.forumForumIdGroupsGroupIdAvilableCodesGet,
  },
  selectedCodes: {
    getAll: api.forumForumIdGroupsGroupIdSelectedCodesGet,
  },
  codes: {
    post: api.forumForumIdGroupsGroupIdCodePost,
    delete: api.forumForumIdGroupsGroupIdCodeDelete,
  },
  contactPerson: {
    put: api.forumForumIdGroupsGroupIdContactPersonUserIdPut,
    delete: api.forumForumIdGroupsGroupIdContactPersonDelete,
  },
};

export default ForumGroup;
