import { Col, Divider, Row } from "components/styleguide";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { urlToAnchor } from "utils/anchorUtils";

const ForumCompanySocialLinks = ({ company }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={24}>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Facebook")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {company["facebook"] ? urlToAnchor(company["facebook"]) : "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("Twitter")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {company["twitter"] ? urlToAnchor(company["twitter"]) : "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
          <Row gutter={24}>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              <label>{t("LinkedIn")}</label>
            </Col>
            <Col className="gutter-row" span={12} sm={24} md={24} lg={12}>
              {company["linkedIn"] ? urlToAnchor(company["linkedIn"]) : "-"}
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
    </>
  );
};

ForumCompanySocialLinks.propTypes = {
  company: PropTypes.any,
};

export default ForumCompanySocialLinks;
