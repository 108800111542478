import { Button } from "antd";
import { Collapse } from "components/styleguide";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { replaceAt } from "utils/arrayUtils";
import { generatePageId } from "utils/questionnaireContentUtils";
import QuestionOptionType from "utils/questionOptionTypeUtils";
import QuestionType from "utils/questionTypeUtils";

import QuestionsRenderer from "../QuestionsRenderer";
import DetailsPanelContent from "./components/DetailsPanelContent";

import styles from "./styles.module.less";

const QuestionnaireContentRenderer = ({ forumId, questionnaireId, isEditable, pages, onChange }) => {
  const [isReadonlyDetails, setIsReadonlyDetails] = useState(true);

  const { t } = useTranslation();

  const defaultOpenedItems = useMemo(() => {
    const pageIds = pages.map((_, index) => generatePageId(index));
    return ["details", ...pageIds];
  }, [pages]);

  const toggleReadonlyDetails = () => setIsReadonlyDetails((b) => !b);

  const handleQuestionnaireEdit = (e) => {
    e.stopPropagation();
    toggleReadonlyDetails();
  };
  const handleSuccessUpdate = () => {
    setIsReadonlyDetails(true);
  };

  const handleQuestionsChange = (pageIndex, newQuestions) => {
    onChange(replaceAt(pages, pageIndex, { questions: newQuestions }));
  };

  return (
    <div data-testid="questionnaire-content" className={styles.container}>
      <Collapse bordered={false} defaultActiveKey={defaultOpenedItems} ghost>
        <Collapse.Panel
          id="details"
          header="Details"
          key="details"
          extra={<Button onClick={handleQuestionnaireEdit}>{t("Edit")}</Button>}
        >
          <DetailsPanelContent
            isReadonly={isReadonlyDetails}
            forumId={forumId}
            questionnaireId={questionnaireId}
            onCancel={() => setIsReadonlyDetails(true)}
            onSuccessUpdate={handleSuccessUpdate}
          />
        </Collapse.Panel>

        {pages.map((page, pageIndex) => {
          const pageId = generatePageId(pageIndex);
          const header = `${t("page")} ${pageIndex + 1}`;

          return (
            <Collapse.Panel id={pageId} header={header} key={pageId}>
              <QuestionsRenderer
                pageIndex={pageIndex}
                questions={page.questions}
                isEditable={isEditable}
                onChange={(newQuestions) => handleQuestionsChange(pageIndex, newQuestions)}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

QuestionnaireContentRenderer.propTypes = {
  forumId: PropTypes.number.isRequired,
  questionnaireId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          type: PropTypes.oneOf(QuestionType.VALUES).isRequired,
          description: PropTypes.string,
          numberOfColumns: PropTypes.number,
          secondaryText: PropTypes.string,
          showSecondaryText: PropTypes.bool,
          layoutHorizontal: PropTypes.bool,
          optionsColumns: PropTypes.arrayOf(PropTypes.string),
          options: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              text: PropTypes.string,
              type: PropTypes.oneOf(QuestionOptionType.VALUES),
            }),
          ),
        }),
      ),
    }),
  ),
  onChange: PropTypes.func,
};

QuestionnaireContentRenderer.defaultProps = {
  isEditable: true,
  pages: [],
  onChange: () => undefined,
};

export default QuestionnaireContentRenderer;
