import { ForumFeedbackQuestionsApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumFeedbackQuestionsApi);

const ForumFeedbackQuestions = {
  get: api.feedbackQuestionsGet,
};

export default ForumFeedbackQuestions;
