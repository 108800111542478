import { useQueryClient } from "@tanstack/react-query";
import { useAttendeeSpeakerTrackingQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Col, Form, Row, Tabs } from "components/styleguide";
import BriefingCallStatus from "enums/BriefingCallStatus";
import { SpeakerTrackingResponseModel } from "generated/api";
import { toNumber } from "lodash-es";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useUpdateAttendeeSpeakerTrackingMutation } from "../../../../api/mutations/attendees";
import Attendance from "./Attendance";
import Details from "./Details";
import FeesAndExpenses from "./FeesAndExpenses";
import OtherContacts from "./OtherContacts";
import SessionDetails from "./SessionDetails";

interface AttendeeSpeakerDetailProps {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
}

const AttendeeSpeakerDetails = ({ isEditing, setIsEditing }: AttendeeSpeakerDetailProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: forumId, attendeeId } = useParams();
  const queryClient = useQueryClient();

  const [speakerTracking, setSpeakerTracking] = useState<SpeakerTrackingResponseModel | null>(null);

  const close = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const speakerTrackingQuery = useAttendeeSpeakerTrackingQuery(
    {
      forumId: toNumber(forumId),
      attendeeId: toNumber(attendeeId),
    },
    {
      onSuccess: ({ data }) => {
        setSpeakerTracking(data);
      },
    },
  );

  const updateMutation = useUpdateAttendeeSpeakerTrackingMutation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submit = (values: any) => {
    //Map Objects to Arrays
    const mappedMeals = values.availabilityForMeals
      ? Object.keys(values.availabilityForMeals).map((key) => {
        return {
          id: toNumber(key),
          isAvailable: values.availabilityForMeals[key].isAvailable,
        };
      })
      : null;

    const mappedSessions = values.sessionsDetails
      ? Object.keys(values.sessionsDetails).map((key) => ({
        id: toNumber(key),
        seminar: values.sessionsDetails[key].seminar,
        startTime: values.sessionsDetails[key].startTime,
        endTime: values.sessionsDetails[key].endTime,
        synopsisConfrimed: values.sessionsDetails[key].synopsisConfrimed,
        presentationReceived: values.sessionsDetails[key].presentationReceived,
      }))
      : null;

    const checkInDate = values.accommodationRequired?.[0].format();
    const checkOutDate = values.accommodationRequired?.[1].format();

    const model = {
      ...values,
      availabilityForMeals: mappedMeals,
      sessionsDetails: mappedSessions,
      checkInDate,
      checkOutDate,
    };

    updateMutation.mutate(
      {
        forumId: toNumber(forumId),
        attendeeId: toNumber(attendeeId),
        speakerTrackingRequestModel: model,
      },
      {
        onSuccess: () => {
          setSpeakerTracking({ ...model, briefingCallStatus: BriefingCallStatus[values.briefingCallStatus] });

          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_HOTEL_AND_ROOM_ALLOCATION]);
          queryClient.invalidateQueries([KEYS.ATTENDEE_SPEAKER_TRACKING_QUERY]);

          setIsEditing(false);
        },
      },
    );
  };

  const isLoading = updateMutation.isLoading || speakerTrackingQuery.isLoading;

  return (
    <Form
      form={form}
      id="speakerDetailsForm"
      name="speaker-details"
      labelAlign="left"
      labelCol={{ span: 13 }}
      labelWrap
      colon={false}
      onFinish={submit}
    >
      <Tabs defaultActiveKey="details" className="speaker-details-tabs" hideNav={false}>
        <Tabs.TabPane key="details" tab={t("Details")} forceRender>
          <Details isEditing={isEditing} speakerTracking={speakerTracking} isLoading={speakerTrackingQuery.isLoading} />
        </Tabs.TabPane>
        <Tabs.TabPane key="attendance" tab={t("Attendance")} forceRender>
          <Attendance
            isEditing={isEditing}
            speakerTracking={speakerTracking}
            isLoading={speakerTrackingQuery.isLoading}
            isLoadingTable={isLoading}
            form={form}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="feesAndExpenses" tab={t("Fees and Expenses")} forceRender>
          <FeesAndExpenses
            isEditing={isEditing}
            speakerTracking={speakerTracking}
            isLoading={speakerTrackingQuery.isLoading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="otherContacts" tab={t("Other Contacts")} forceRender>
          <OtherContacts
            isEditing={isEditing}
            speakerTracking={speakerTracking}
            isLoading={speakerTrackingQuery.isLoading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane key="sessionDetails" tab={t("Session Details")} forceRender>
          <SessionDetails
            isEditing={isEditing}
            speakerTracking={speakerTracking}
            isLoading={speakerTrackingQuery.isLoading}
            isLoadingTable={isLoading}
          />
        </Tabs.TabPane>
      </Tabs>
      {isEditing && (
        <Row gutter={80}>
          <Col span={1}>
            <Button htmlType="submit" type="primary" loading={isLoading} disabled={isLoading}>
              {t("Save")}
            </Button>
          </Col>
          <Col span={1}>
            <Button onClick={close} loading={isLoading} disabled={isLoading}>
              {t("Cancel")}
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default AttendeeSpeakerDetails;
