import { FormRule } from "antd";
import ParticipationCodeTypes from "backend-models/participationCodeTypes";
import FullHeightForm from "components/FullHeightForm";
import { Button, Form, Input, Select } from "components/styleguide";
import YesNo from "components/YesNoSelect";
import { ParticipationCodeModel } from "generated/api";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface FormComponentProps {
  isLoading: boolean;
  onCancel: () => void;
  onSubmit: (participationCode: ParticipationCodeModel) => void;
}

const eventCodeFormat = new RegExp("^[A-Z0-9]{2}");
const validateCodeFormat = async (_: FormRule, value: string) => {
  return value.length === 0 || eventCodeFormat.test(value) ? Promise.resolve() : Promise.reject();
};

const FormComponent = ({ isLoading, onCancel, onSubmit }: FormComponentProps) => {
  const { t } = useTranslation();

  const YesNoFormItems = useMemo(
    () => [
      {
        label: "Meal Meetings",
        dataIndex: "mealMeet",
      },
      {
        label: "Business Meetings",
        dataIndex: "busMeet",
      },
      {
        label: "Include in SelToDel",
        dataIndex: "selToDel",
      },
      {
        label: "Include in SelToExec",
        dataIndex: "includingSelToExec",
      },
      {
        label: "Include in AttRep",
        dataIndex: "includingAttRep",
      },
      {
        label: "Include in Crunch",
        dataIndex: "includingCrunch",
      },
    ],
    [],
  );

  return (
    <FullHeightForm
      id="participationCodeForm"
      className="participationCodeForm"
      name="participationCodeForm"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      onFinish={onSubmit}
      actionsPrepend={<Button onClick={isLoading ? undefined : onCancel}>{t("Cancel")}</Button>}
      actionsAppend={
        <Button type="primary" disabled={isLoading} loading={isLoading} htmlType="submit">
          {t("create")}
        </Button>
      }
    >
      <Form.Item
        label={t("Code")}
        name="code"
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(Code)" }),
          },
          {
            validator: validateCodeFormat,
            message: t("The code should be alphanumeric with capital letters"),
          },
        ]}
      >
        <Input maxLength={2} placeholder={t("placeholders.add", { prop: "$t(Code)" })} />
      </Form.Item>
      <Form.Item
        label={t("Description")}
        name="description"
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(description)" }),
          },
          {
            max: 40,
            message: t("errors.max.participation_description", { count: 40 }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(description)" })} />
      </Form.Item>
      <Form.Item label={t("Configuration")} name="type">
        <Select>
          {Object.entries(ParticipationCodeTypes.MAP).map(([key, label]) => (
            <Select.Option key={key} value={key}>
              {t(label as string)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {YesNoFormItems.map(({ label, dataIndex }) => (
        <Form.Item key={dataIndex} label={t(label)} name={dataIndex}>
          <YesNo defaultValue={false} />
        </Form.Item>
      ))}
    </FullHeightForm>
  );
};

export default FormComponent;
