import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "../../../../../../../../../components/styleguide";
import { SelectionSiteContent } from "../../../../../../../../../generated/api";
import ContentItem from "../../../../../../../../../utils/getSelectionSiteDependentItems/utils/content-item";
import SelectionSiteItemType from "../../../../../../../../../utils/selectionSiteItemTypeUtils";

interface DependenciesRendererProps {
  items: Set<ContentItem>;
  content: SelectionSiteContent;
}

const groupByPage = (items: Set<ContentItem>): Map<number | undefined, Array<ContentItem>> => {
  const map = new Map<number | undefined, Array<ContentItem>>();

  items.forEach((item) => {
    if (!map.has(item.pageId)) {
      map.set(item.pageId, []);
    }

    map.set(item.pageId, [...(map.get(item.pageId) ?? []), item]);
  });

  return map;
};

const DependenciesRenderer = ({ items, content }: DependenciesRendererProps) => {
  const { t } = useTranslation();

  const groups = useMemo(() => groupByPage(items), [items]);

  const pageIndexes = useMemo(() => {
    const result = new Map<number | undefined, number>();

    content.pages?.forEach((page, index) => {
      result.set(page.id, index);
    });

    return result;
  }, [content.pages]);

  return (
    <>
      {[...groups].map(([pageId, contentItems]) => {
        const pageIndex = pageIndexes.get(pageId);
        return (
          <div key={pageId}>
            <Typography.Text strong>
              {t("Page")} {pageIndex !== undefined ? pageIndex + 1 : t("N/A")}
            </Typography.Text>
            <ul>
              {contentItems.map((contentItem) => {
                const itemType = new SelectionSiteItemType(contentItem.value.type);
                const itemTypeTranslation = t(itemType.toString() ?? "");

                return <li key={contentItem.value.id}>{itemTypeTranslation}</li>;
              })}
            </ul>
          </div>
        );
      })}
    </>
  );
};

export default DependenciesRenderer;
