import { useGetExportTaskStatusesQuery } from "api/queries/groups";
import { Button } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

interface TaskStatusesExportButtonProps {
  groupId: number;
}

const TaskStatusesExportButton: FC<TaskStatusesExportButtonProps> = ({ groupId }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const { refetch: exportTaskStatuses, isFetching: isTaskStatusesExportLoading } = useGetExportTaskStatusesQuery(
    {
      forumId,
      groupId,
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
    },
  );

  return (
    <Button
      onClick={() => exportTaskStatuses()}
      loading={isTaskStatusesExportLoading}
      disabled={isTaskStatusesExportLoading}
    >
      {t("Export")}
    </Button>
  );
};

export default TaskStatusesExportButton;
