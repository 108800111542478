import { useQueryClient } from "@tanstack/react-query";
import { ColumnsType } from "antd/lib/table";
import {
  useCreateForumDocumentCustomPageMutation,
  useCreateForumDocumentDefinitionSectionMutation,
} from "api/mutations/forumDocumentDefinition";
import { useForumDocumentDefinitionCustomPageQuery } from "api/queries/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import { Button, Input, Space, Table } from "components/styleguide";
import {
  DocumentDefinitionCustomPageSectionType,
  DocumentDefinitionSectionTypes,
  ForumDocumentCustomPageListItemModel,
  ForumDocumentCustomPageRequestModel,
  ForumDocumentDefinitionSectionCreateModel,
} from "generated/api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import { TabsTableProps } from "../index";
import CustomPageElementsDrawer from "./CustomPageElementsDrawer";

const CustomPageTable: React.FC<TabsTableProps> = ({ forumId, documentDefinitionType }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [customPage, setCustomPage] = useState<ForumDocumentCustomPageListItemModel | undefined>();
  const [customPages, setCustomPages] = useState<ForumDocumentCustomPageListItemModel[]>();
  const [pageFieldInput, setPageFieldInput] = useState<string | undefined>();
  const [nameSearch, setNameSearch] = useState<string | undefined>();

  const forumDocumentCreateDefinitionSectionMutation = useCreateForumDocumentDefinitionSectionMutation();
  const forumDocumentCreateCustomPageMutation = useCreateForumDocumentCustomPageMutation();

  const { isLoading } = useForumDocumentDefinitionCustomPageQuery(
    { forumId, name: nameSearch },
    {
      onSuccess: ({ data }) => {
        setCustomPages(data);
      },
    },
  );

  const handleAddCustomPage = () => {
    const forumDocumentCustomPageRequestModel = { name: pageFieldInput } as ForumDocumentCustomPageRequestModel;
    forumDocumentCreateCustomPageMutation.mutate(
      {
        forumId,
        forumDocumentCustomPageRequestModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_CUSTOM_PAGE]);
          toast.success(t("Custom Page created successfully"));
          setPageFieldInput(undefined);
        },
      },
    );
  };

  const onInsertPageSubmit = ({ id }: ForumDocumentCustomPageListItemModel, withBreak: boolean) => {
    const forumDocumentDefinitionSectionCreateModel: ForumDocumentDefinitionSectionCreateModel = {
      documentDefinitionType,
      sectionType: DocumentDefinitionSectionTypes.CustomPage,
      forumDocumentCustomPageId: id,
      customPageSectionType: withBreak
        ? DocumentDefinitionCustomPageSectionType.CustomPageWithBreak
        : DocumentDefinitionCustomPageSectionType.CustomPage,
    };

    forumDocumentCreateDefinitionSectionMutation.mutate(
      { forumId, forumDocumentDefinitionSectionCreateModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS]);
        },
      },
    );
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
    setCustomPage(undefined);
  };

  const handleSearch = (selectedKeys: string[], confirm: () => void, _: string, searchSet: string) => {
    if (searchSet === "name") {
      setNameSearch(selectedKeys[0]);
    }
    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    if (searchSet === "name") {
      setNameSearch(undefined);
    }
    clearFilters?.();
    confirm?.();
  };

  const columns: ColumnsType<ForumDocumentCustomPageListItemModel> = [
    buildColumn(t("Page Name"), "name", {
      ...getColumnSearchProps("name", handleSearch, handleReset, "name"),
    }),
    buildColumn("", "id", {
      width: "20%",
      render: (id: number, record: ForumDocumentCustomPageListItemModel) => (
        <Button onClick={() => onInsertPageSubmit(record, false)}>{t("Insert page")}</Button>
      ),
    }),
    buildColumn("", "id", {
      width: "20%",
      render: (id: number, record: ForumDocumentCustomPageListItemModel) => (
        <Button onClick={() => onInsertPageSubmit(record, true)}>{t("Insert page with break")}</Button>
      ),
    }),
    buildColumn("", "id", {
      width: "12%",
      render: (id: number, record: ForumDocumentCustomPageListItemModel) => (
        <Button
          onClick={() => {
            setIsDrawerVisible(true);
            setCustomPage(record);
          }}
        >
          {t("Edit")}
        </Button>
      ),
    }),
  ];

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Space direction="horizontal">
        <Input
          allowClear
          placeholder={t("Add page")}
          bordered
          value={pageFieldInput}
          onChange={({ target: { value } }) => setPageFieldInput(value)}
        />
        <Button onClick={handleAddCustomPage} type="primary">
          {t("Add page")}
        </Button>
      </Space>
      <Table bordered data-testid="custom-page-table" dataSource={customPages} columns={columns} loading={isLoading} />
      {customPage && (
        <CustomPageElementsDrawer
          customPage={customPage}
          forumId={forumId}
          onClose={onCloseDrawer}
          isDrawerVisible={isDrawerVisible}
        />
      )}
    </Space>
  );
};

export default CustomPageTable;
