import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface MailLinkProps {
  to: string;
  subject?: string;
  body?: string;
  children?: JSX.Element | string;
}

const MailLink: FC<MailLinkProps> = ({ to, subject, body, children }) => {
  const { t } = useTranslation();

  const getParamsString = () => {
    if (!subject && !body) {
      return null;
    }

    const subjectParam = subject && `subject=${encodeURIComponent(t(subject))}`;
    const bodyParam = body && `body=${encodeURIComponent(t(body))}`;

    if (subject && body) {
      return `${subjectParam}&${bodyParam}`;
    }

    return `${subjectParam ?? bodyParam}`;
  };

  const getMailString = () => {
    const params = getParamsString();
    const mailTo = `mailto:${to}`;

    if (params) {
      return `${mailTo}?${params}`;
    }

    return mailTo;
  };

  return <a href={getMailString()}>{children}</a>;
};

export default MailLink;
