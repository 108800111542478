import { useQueryClient } from "@tanstack/react-query";
import { useCreateSeminarPreferenceMutation, useUpdateSeminarPreferenceMutation } from "api/mutations/attendees";
import KEYS from "api/queries/keys";
import { useSeminarsQuery } from "api/queries/seminars";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, InputNumber, Select, Spin } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import {
  ForumSeminarWithTypeAndStreamAndSpeakers,
  SeminarPreferenceRequestModel,
  SeminarPreferenceResponseModel,
} from "generated/api";
import { toNumber } from "lodash-es";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface AddOrEditConferenceDrawerProps {
  forumId: number;
  attendeeId: number;
  activeSeminar: SeminarPreferenceResponseModel | null;
  isVisible: boolean;
  onClose: () => void;
}
const excludedCodes = ["F", "G", "Z"];

const AddOrEditConferenceDrawer = ({
  forumId,
  attendeeId,
  activeSeminar,
  isVisible,
  onClose,
}: AddOrEditConferenceDrawerProps) => {
  const { t } = useTranslation();
  const [seminars, setSeminars] = useState<ForumSeminarWithTypeAndStreamAndSpeakers[]>([]);
  const queryClient = useQueryClient();

  const createMutation = useCreateSeminarPreferenceMutation();
  const updateMutation = useUpdateSeminarPreferenceMutation();

  const seminarsQuery = useSeminarsQuery(
    { forumId: toNumber(forumId), orderBy: "code", orderDir: "asc" },
    {
      onSuccess: ({ data }: { data: ForumSeminarWithTypeAndStreamAndSpeakers[] }) => {
        const filteredSeminars = data.filter((s) => s.forumSeminarCode && !excludedCodes.includes(s.forumSeminarCode));
        setSeminars(filteredSeminars);
      },
    },
  );

  const submit = (values: SeminarPreferenceRequestModel) => {
    if (activeSeminar) {
      updateMutation.mutate(
        { forumId, attendeeId, id: activeSeminar.id, seminarPreferenceRequestModel: values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_CONFERENCES]);
            onClose();
          },
          onError: ({ response }) => {
            if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
              const { Priority, SeminarId } = response.data.meta.ItemFields;

              if (Priority) {
                toast.error(t("Priority must be unique for this Conference"));
              } else if (SeminarId) {
                toast.error(t("Seminar must be unique for this Conference"));
              } else {
                toast.error(t("Duplicate field found for this Conference"));
              }
            }
          },
        },
      );
    } else {
      createMutation.mutate(
        { forumId, attendeeId, seminarPreferenceRequestModel: values },
        {
          onSuccess: () => {
            queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_CONFERENCES]);
            onClose();
          },
          onError: ({ response }) => {
            if (ErrorTypes.isOfType(response, ErrorTypes.DuplicateItem)) {
              const { Priority, SeminarId } = response.data.meta.ItemFields;

              if (Priority) {
                toast.error(t("Priority must be unique for this Conference"));
              } else if (SeminarId) {
                toast.error(t("Seminar must be unique for this Conference"));
              } else {
                toast.error(t("Duplicate field found for this Conference"));
              }
            }
          },
        },
      );
    }
  };

  return (
    <Drawer
      title={activeSeminar ? t("Edit Conference Preference") : t("Add Conference Preference")}
      placement="right"
      onClose={onClose}
      open={isVisible}
      contentWrapperStyle={{ minWidth: "50%" }}
      getContainer={false}
      destroyOnClose={true}
    >
      {!seminarsQuery.isLoading ? (
        <FullHeightForm
          onFinish={submit}
          layout="vertical"
          className={"add-conference-preference-form"}
          actionsPrepend={<Button onClick={onClose}>{t("cancel")}</Button>}
          actionsAppend={
            <Button htmlType="submit" type="primary" loading={createMutation.isLoading || updateMutation.isLoading}>
              {activeSeminar ? t("Update") : t("Add Preference")}
            </Button>
          }
        >
          <Form.Item
            label={t("Priority")}
            name="priority"
            initialValue={activeSeminar?.priority}
            wrapperCol={{ style: { width: "100%" } }}
            extra={t("Priority must be unique and between 1 and 1000")}
            rules={[{ required: true, message: t("errors.required", { prop: "$t(Priority)" }) }]}
          >
            <InputNumber min={1} max={1000} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={t("Seminar Code")}
            initialValue={activeSeminar?.seminarId}
            name="seminarId"
            rules={[{ required: true, message: t("errors.required", { prop: "$t(Seminar Code)" }) }]}
          >
            <Select options={seminars.map((s) => ({ label: s.code, value: s.id }))} />
          </Form.Item>
          <Form.Item
            label={t("Seminar Title")}
            initialValue={activeSeminar?.seminarId}
            name="seminarId"
            rules={[{ required: true, message: t("errors.required", { prop: "$t(Seminar Title)" }) }]}
          >
            <Select options={seminars.map((s) => ({ label: s.title, value: s.id }))} />
          </Form.Item>
          {activeSeminar && (
            <Form.Item label={t("Satisfied")} initialValue={activeSeminar?.satisfied} name="satisfied">
              <Select>
                <Select.Option value={true}>{t("Yes")}</Select.Option>
                <Select.Option value={false}>{t("No")}</Select.Option>
              </Select>
            </Form.Item>
          )}
        </FullHeightForm>
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )}
    </Drawer>
  );
};

export default AddOrEditConferenceDrawer;
