import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { Layout } from "components/styleguide";
import { msalInstance } from "index";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import styles from "./styles.module.less";

const { Content } = Layout;

const ReportViewer = () => {
  const { reportKey, id: forumId } = useParams();

  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const [token, setToken] = useState("");
  const viewerRef = useRef();

  const parameters = state?.parameters;

  const onReady = () => {
    const buttonsToHide = [
      "telerik_ReportViewer_historyBack",
      "telerik_ReportViewer_historyForward",
      "telerik_ReportViewer_stopRendering",
      "telerik_ReportViewer_toggleDocumentMap",
      "telerik_ReportViewer_toggleParametersArea",
      "telerik_ReportViewer_toggleSendEmailDialog",
    ];

    buttonsToHide.forEach((button) => {
      window.jQuery(`[data-command="${button}"]`).parent().hide();
    });
  };

  useEffect(() => {
    if (pathname.includes("/options") && !state) {
      navigate(`../options/${reportKey}`);
    }

    // need to inject Authorization token into Telerik's requests
    const account = msalInstance.getActiveAccount();

    msalInstance
      .acquireTokenSilent({ account })
      .then((data) => setToken(data.idToken))
      .catch(() => setToken(""));
  }, [pathname, state, navigate, reportKey]);

  useEffect(() => {
    if (token) {
      // the library uses jquery
      window.jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR) {
        jqXHR.setRequestHeader("Authorization", `Bearer ${token}`);
      });

      window.jQuery.ajaxSetup({
        headers: {
          Authorization: `Bearer ${token}`,
        },
        xhrFields: { withCredentials: true },
      });
    }
  }, [token]);

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        {token && (
          <TelerikReportViewer
            ready={onReady}
            ref={viewerRef}
            serviceUrl={`${process.env.REACT_APP_API_URL}/reports/`}
            reportSource={{
              report: reportKey,
              parameters: {
                ForumId: forumId,
                TimeZoneOffsetMinutes: new Date().getTimezoneOffset(),
                ...parameters,
              },
            }}
            viewerContainerStyle={{
              position: "absolute",
              top: "0px",
              bottom: "0px",
              overflow: "hidden",
              clear: "both",
              width: "100%",
            }}
            viewMode="PRINT_PREVIEW"
            printMode="FORCE_PDF_FILE"
            scaleMode="FIT_PAGE"
            parametersAreaVisible={false}
            documentMapVisible={false}
          />
        )}
      </Content>
    </Layout>
  );
};

export default ReportViewer;
