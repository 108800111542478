import {
  QuestionaireSearchIndex,
  QuestionaireSearchIndexQuestionSearchIndex,
  QuestionnaireContentQuestion,
  QuestionQuestionType,
} from "generated/api";
import { filterQuestionsByType } from "utils/questionnaireContentUtils";

import { ListingTableProps } from "../components/ListingTable";

type Questions = Array<QuestionnaireContentQuestion>;

const SUPPORTED_TYPES: Array<QuestionQuestionType> = ["FreeText", "Checkbox", "RadioButton", "DropdownList"];

const mapQuestionsWithMappings = (
  questions: Questions,
  mappings: QuestionaireSearchIndex,
): ListingTableProps["value"] => {
  const map = new Map<
    QuestionaireSearchIndexQuestionSearchIndex["questionId"],
    QuestionaireSearchIndexQuestionSearchIndex
  >();

  mappings.questions?.forEach((mapping) => {
    map.set(mapping.questionId, mapping);
  });

  const availableQuestions = filterQuestionsByType(questions, SUPPORTED_TYPES);
  return availableQuestions.map((question) => {
    const mapping = map.get(question.id);

    if (!mapping) {
      return {
        questionId: question.id,
        advancedSearchTitle: question.text,
        freeText: false,
        advancedSearch: false,
      };
    }

    return mapping;
  });
};

export default mapQuestionsWithMappings;
