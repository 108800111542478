import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space, Table } from "../../../../../../../../../../../components/styleguide";

import styles from "./styles.module.less";

const arrayToMap = (options) => {
  const map = new Map();
  options.forEach((option) => {
    map.set(option.value, option.label);
  });
  return map;
};

const ListingTable = ({ options, mappings, modifyColumns, onDelete }) => {
  const { t } = useTranslation();

  const fromMap = useMemo(() => arrayToMap(options.from), [options.from]);
  const toMap = useMemo(() => arrayToMap(options.to), [options.to]);

  const data = useMemo(
    () =>
      mappings.map((mapping) => ({
        id: `${mapping.from} ${mapping.to}`,
        ...mapping,
      })),
    [mappings],
  );

  const handleDeleteClick = useCallback(
    (index) => {
      onDelete?.(index);
    },
    [onDelete],
  );

  const columns = useMemo(() => {
    const cols = [
      {
        dataIndex: "from",
        width: "50%",
        render: (from) => fromMap.get(from),
      },
      {
        dataIndex: "to",
        key: "to",
        width: "50%",
        render: (to) => toMap.get(to),
      },
      {
        key: "actions",
        width: 50,
        render: (_, __, index) => {
          return (
            <Space className={styles.actions}>
              <Button type="primary" onClick={() => handleDeleteClick(index)}>
                {t("Delete")}
              </Button>
            </Space>
          );
        },
      },
    ];

    if (modifyColumns) {
      return modifyColumns(cols);
    }

    return cols;
  }, [fromMap, toMap, modifyColumns, t, handleDeleteClick]);

  return <Table columns={columns} dataSource={data} rowKey="id" pagination={false} />;
};

ListingTable.propTypes = {
  options: PropTypes.shape({
    from: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
    to: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ),
  }),
  mappings: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }),
  ),
  modifyColumns: PropTypes.func,
  onDelete: PropTypes.func,
};

ListingTable.defaultProps = {
  options: {
    from: [],
    to: [],
  },
  mappings: [],
  modifyColumns: undefined,
  onDelete: () => undefined,
};

export default ListingTable;
