import { useForumVenueFacilitiesQuery } from "api/queries/forumVenues";
import { Select, Space, Typography } from "components/styleguide";
import TableType from "enums/TableType";
import { FacilityResponseModel, FacilityType, TableResponseModel } from "generated/api";
import { toNumber } from "lodash";
import { ReportsValues } from "pages/Reporting/helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import LinkToReport from "../components/LinkToReport";
import { AllMealtimes, DefaultSortOptions } from "../constants";
import MealtimeOption from "../OptionTypes/MealtimeOption";
import SortOrderOption from "../OptionTypes/SortByOption";

const { Text } = Typography;

interface RestaurantsOptionsProps {
  showSorting?: boolean;
  showTables?: boolean;
}
const SortOptions = {
  ...DefaultSortOptions,
  Table: "Table",
};

const RestaurantsOptions = ({ showSorting, showTables }: RestaurantsOptionsProps) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const { reportKey } = useParams();

  const [orderBy, setOrderBy] = useState<string>(SortOptions.Surname);
  const [restaurantId, setRestaurantId] = useState<number>();
  const [session, setSession] = useState<number | typeof AllMealtimes>();
  const [tableId, setTableId] = useState<number>();

  const { isLoading: isRestaurantsLoading, data: { data: restaurants = [] } = {} } = useForumVenueFacilitiesQuery({
    forumId,
    types: [FacilityType.Restaurant],
  });

  const baseParameters = {
    RestaurantId: restaurantId,
    TimeSlotSessionId: session,
    TableId: tableId,
    OrderBy: orderBy,
  };

  const parameters = showSorting ? baseParameters : { ...baseParameters, OrderBy: undefined };

  return (
    <Space align={"end"} wrap>
      {showSorting && (
        <SortOrderOption
          options={reportKey === ReportsValues.AllTableAssignments ? DefaultSortOptions : SortOptions}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
      )}

      <Space direction={"vertical"}>
        <Text>{t("Restaurant")}</Text>
        <Select
          style={{ minWidth: "200px" }}
          placeholder={t("Select restaurant")}
          options={restaurants.map(({ id, name }: FacilityResponseModel) => ({ value: id, label: name }))}
          value={restaurantId}
          onChange={(value) => {
            if (value !== restaurantId) {
              setTableId(undefined);
            }
            setRestaurantId(value);
          }}
          loading={isRestaurantsLoading}
          allowClear
          showSearch
          optionFilterProp={"label"}
        />
      </Space>

      <MealtimeOption session={session} setSession={setSession} />

      {showTables && (
        <Space direction={"vertical"}>
          <Text>{t("Table")}</Text>
          <Select
            style={{ minWidth: "200px" }}
            placeholder={t("Select table")}
            options={restaurants
              ?.find(({ id }) => id === restaurantId)
              ?.facilityTables?.map(({ id, tableNumber, tableType }: TableResponseModel) => ({
                value: id,
                label: `${tableNumber} - ${TableType.toDisplayName(tableType)}`,
              }))}
            value={tableId}
            onChange={(value) => setTableId(value)}
            loading={isRestaurantsLoading}
            allowClear
            showSearch
            optionFilterProp={"label"}
            notFoundContent={t("No tables found for selected restaurant")}
          />
        </Space>
      )}

      <LinkToReport parameters={parameters} />
    </Space>
  );
};

export default RestaurantsOptions;
