import { useSeminarByIdQuery } from "api/queries/seminars";
import { Tabs } from "components/styleguide";
import { ForumSeminarSessionWithTimeslotSession } from "generated/api";
import SeminarDetailsTab from "pages/SpeakerVisuals/SpeakerVisualsContent/SeminarDetails";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import SpeakerVisualsDocuments from "./SpeakerVisualsDocuments";

import styles from "./styles.module.less";

type LocationState = { seminarCode: string };

const { TabPane } = Tabs;

const TABS = {
  seminarDetails: {
    key: "seminarDetails",
    label: "Seminar Details",
  },
  documents: {
    key: "documents",
    label: "Documents",
  },
};

const SpeakerVisualsContent: FC = () => {
  const { id: forumId, seminarId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: { data: seminar = {} } = {} } = useSeminarByIdQuery(
    {
      forumId: Number(forumId),
      forumSeminarId: Number(seminarId),
    },
    {
      onSuccess: ({ data }) => {
        if (!location.state || !(location.state as LocationState).seminarCode) {
          navigate(".", {
            replace: true,
            state: { seminarCode: data.code },
          });
        }
      },
    },
  );

  return (
    <div className={styles.container}>
      <Tabs className="" defaultActiveKey={TABS.seminarDetails.key} hideNav={false}>
        <TabPane tab={t(TABS.seminarDetails.label)} key={TABS.seminarDetails.key}>
          <SeminarDetailsTab seminar={seminar as ForumSeminarSessionWithTimeslotSession} />
        </TabPane>
        <TabPane tab={t(TABS.documents.label)} key={TABS.documents.key}>
          <SpeakerVisualsDocuments />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default SpeakerVisualsContent;
