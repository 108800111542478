import { Space as AntSpace, SpaceProps as AntSpaceProps } from "antd";
import clsx from "clsx";
import React, { FC } from "react";

import styles from "./styles.module.less";

interface SpaceProps extends AntSpaceProps {
  fullWidth?: boolean;
}

const Space: FC<SpaceProps> = ({ fullWidth, ...props }) => {
  const classes = clsx({
    [styles.fullWidth]: fullWidth,
  });

  return <AntSpace className={classes} {...props} />;
};

export default Space;
