/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Test section
 * @export
 * @enum {string}
 */

export const TestDocumentDefinitionTypes = {
    AllProfiles: 'AllProfiles',
    AllSisterEventAttendeesExpanded: 'AllSisterEventAttendeesExpanded',
    AttendeeList: 'AttendeeList',
    ProfileList: 'ProfileList',
    ProfileListNoProfiles: 'ProfileListNoProfiles',
    QuestDefinition: 'QuestDefinition',
    TestConferenceProfile: 'TestConferenceProfile',
    TestProfile: 'TestProfile',
    TestReason: 'TestReason',
    AllSisterEventsList: 'AllSisterEventsList'
} as const;

export type TestDocumentDefinitionTypes = typeof TestDocumentDefinitionTypes[keyof typeof TestDocumentDefinitionTypes];



