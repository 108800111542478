import { Layout } from "components/styleguide";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";

import styles from "./styles.module.less";

export default function EmailTemplatesLayout() {
  const { id }: { id: number } = useOutletContext();

  return (
    <div className={styles.container}>
      <Layout.Content>
        <Outlet context={{ id }} />
      </Layout.Content>
    </div>
  );
}
