import EllipsisText from "components/EllipsisText";
import { Button, Typography } from "components/styleguide";
import { ForumAttendeeMeetingFeedbackModel, ForumAttendeeSessionFeedbackModel } from "generated/api";
import { t } from "i18next";
import moment from "moment";
import { getColumnDateRangeSearchProps, getColumnSelectFilterProps, TableColumn } from "utils/tableColumnUtils";

import DeleteMeetingFeedbackButton from "./Delete/DeleteMeetingFeedbackButton";
import DeleteSessionFeedbackButton from "./Delete/DeleteSessionFeedbackButton";
import { ConferenceRatingsType, FollowUpsType, HandleResetType, HandleSearchType, RatingsType } from "./types";

import styles from "./styles.module.less";

const { Text } = Typography;

export const DataIndexes = {
  Id: "id",
  StartTime: "startTime",
  Attendee: "id",
  FollowUp: "followUpRequest",
  Rating: "supplierMeetingRating",
  CorrectPerson: "correctPerson",
};

export const ConferenceSessionDataIndexes = {
  SessionStartDate: "sessionStartDate",
  SessionEndDate: "sessionEndDate",
  SeminarName: "seminarName",
  ContentQuality: "contentQuality",
  ContentMatch: "contentMatch",
  SessionMeetYourNeeds: "sessionMeetYourNeeds",
  FeedbackOnSpeakers: "feedbackOnSpeakers",
  Feedback: "comment",
};

export const FollowUps: FollowUpsType = {
  AsSoonAsPossible: "As Soon As Possible",
  Within3Months: "Within 3 Months",
  Within6Months: "Within 6 Months",
  Within1Year: "Within 1 Year",
  NoFollowUp: "No Follow-up",
};

export const Ratings: RatingsType = {
  Good: "Good",
  Fair: "Fair",
  Poor: "Poor",
  NoShow: "No show",
};

export const ConferenceRatings: ConferenceRatingsType = {
  Excellent: "Excellent",
  Good: "Good",
  Fair: "Fair",
  Poor: "Poor",
  VeryPoor: "Very poor",
};

const ConferenceRatingsSelectOptions = Object.entries(ConferenceRatings).map(([value, label]) => ({
  value,
  label: t(label),
}));

export const getMeetingColumns = ({
  forumId,
  attendeeId,
  handleSearch,
  handleReset,
  onEditFeedback,
}: {
  forumId: number;
  attendeeId: number;
  handleSearch: HandleSearchType;
  handleReset: HandleResetType;
  onEditFeedback: (feedback: ForumAttendeeMeetingFeedbackModel) => void;
}) => [
  new TableColumn(t("Start Time"), DataIndexes.StartTime, {
    sorter: true,
    render: (startTime: string) => <Text>{moment.utc(startTime).utcOffset(moment().utcOffset()).format("L LT")}</Text>,
    ...(getColumnDateRangeSearchProps(
      DataIndexes.StartTime,
      handleSearch,
      handleReset,
      DataIndexes.StartTime,
    ) as Partial<ForumAttendeeMeetingFeedbackModel>),
  }),
  new TableColumn(t("Attendee"), DataIndexes.Id, {
    sorter: true,
    render: (_: number, record: ForumAttendeeMeetingFeedbackModel) => {
      return (
        <div>
          <Text>
            {record.attendeeFirstName} {record.attendeeLastName}
          </Text>
          <br />
          <Text type="secondary">{record.attendeeJobTitle}</Text>
        </div>
      );
    },
  }),
  new TableColumn(t("Follow-up Requested"), DataIndexes.FollowUp, {
    render: (followUp: string) => <Text>{t(FollowUps[followUp as keyof FollowUpsType]).toString()}</Text>,
  }),
  new TableColumn(t("Supplier Meeting Rating"), DataIndexes.Rating, {
    render: (rating: string) => <Text>{t(Ratings[rating as keyof RatingsType]).toString()}</Text>,
  }),
  new TableColumn(t("Correct Person"), DataIndexes.CorrectPerson, {
    render: (correctPerson: string) => t(correctPerson),
  }),
  new TableColumn("", "id", {
    render: (feedbackId: number, record: ForumAttendeeMeetingFeedbackModel) => (
      <div className={styles.buttonWrapper}>
        <Button onClick={() => onEditFeedback(record)}>{t("Edit") as string}</Button>
        <DeleteMeetingFeedbackButton {...{ forumId, attendeeId, feedbackId }} />
      </div>
    ),
  }),
];

export const getConferenceSessionColumns = ({
  forumId,
  attendeeId,
  handleSearch,
  handleReset,
  onEditFeedback,
}: {
  forumId: number;
  attendeeId: number;
  handleSearch: HandleSearchType;
  handleReset: HandleResetType;
  onEditFeedback: (feedback: ForumAttendeeSessionFeedbackModel) => void;
}) => [
  new TableColumn(t("Date and Time"), ConferenceSessionDataIndexes.SessionStartDate, {
    sorter: true,
    width: "20%",
    render: (_: string, record: ForumAttendeeSessionFeedbackModel) => (
      <Text>
        {moment.utc(record.sessionStartDate).format("L LT")}
        {" - "}
        {moment.utc(record.sessionEndDate).format("L LT")}
      </Text>
    ),
    ...(getColumnDateRangeSearchProps(
      ConferenceSessionDataIndexes.SessionStartDate,
      handleSearch,
      handleReset,
      ConferenceSessionDataIndexes.SessionStartDate,
    ) as Partial<ForumAttendeeMeetingFeedbackModel>),
  }),
  new TableColumn(t("Name"), ConferenceSessionDataIndexes.SeminarName, { width: "15%", sorter: true }),
  new TableColumn(t("Please rate the content quality of this session"), ConferenceSessionDataIndexes.ContentQuality, {
    render: (rating: string) => <Text>{t(ConferenceRatings[rating as keyof ConferenceRatingsType]).toString()}</Text>,
    ...getColumnSelectFilterProps(
      ConferenceSessionDataIndexes.ContentQuality,
      handleSearch,
      handleReset,
      ConferenceSessionDataIndexes.ContentQuality,
      ConferenceRatingsSelectOptions,
    ),
  }),
  new TableColumn(
    t("How well did the content match the programme description"),
    ConferenceSessionDataIndexes.ContentMatch,
    {
      render: (rating: string) => <Text>{t(ConferenceRatings[rating as keyof ConferenceRatingsType]).toString()}</Text>,
      ...getColumnSelectFilterProps(
        ConferenceSessionDataIndexes.ContentMatch,
        handleSearch,
        handleReset,
        ConferenceSessionDataIndexes.ContentMatch,
        ConferenceRatingsSelectOptions,
      ),
    },
  ),
  new TableColumn(t("How well did the session meet your needs"), ConferenceSessionDataIndexes.SessionMeetYourNeeds, {
    render: (rating: string) => <Text>{t(ConferenceRatings[rating as keyof ConferenceRatingsType]).toString()}</Text>,
    ...getColumnSelectFilterProps(
      ConferenceSessionDataIndexes.SessionMeetYourNeeds,
      handleSearch,
      handleReset,
      ConferenceSessionDataIndexes.SessionMeetYourNeeds,
      ConferenceRatingsSelectOptions,
    ),
  }),
  new TableColumn(t("Please provide feedback on the speakers"), ConferenceSessionDataIndexes.FeedbackOnSpeakers, {
    render: (rating: string) => <Text>{t(ConferenceRatings[rating as keyof ConferenceRatingsType]).toString()}</Text>,
    ...getColumnSelectFilterProps(
      ConferenceSessionDataIndexes.FeedbackOnSpeakers,
      handleSearch,
      handleReset,
      ConferenceSessionDataIndexes.FeedbackOnSpeakers,
      ConferenceRatingsSelectOptions,
    ),
  }),
  new TableColumn(t("Feedback"), ConferenceSessionDataIndexes.Feedback, {
    render: (feedback: string | null) => (feedback ? <EllipsisText text={feedback} maxLength={100} /> : feedback),
  }),
  new TableColumn("", "id", {
    render: (feedbackId: number, record: ForumAttendeeSessionFeedbackModel) => (
      <div className={styles.buttonWrapper}>
        <Button onClick={() => onEditFeedback(record)}>{t("Edit") as string}</Button>
        <DeleteSessionFeedbackButton {...{ forumId, attendeeId, feedbackId }} />
      </div>
    ),
  }),
];
