import { Space } from "antd";
import { Layout, Typography } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ReportsValues } from "../helpers";
import AttendeeItineraryByPeopleTypeOptions from "./AttendeeItineraryByPeopleTypeOptions";
import BedroomsOptions, { BedroomsSortOptions } from "./BedroomsOptions";
import CancellationsOptions from "./CancellationsOptions";
import ConferenceAssessmentFormsOptions from "./ConferenceAssessmentFormsOptions";
import ConferenceAttendeesSeminarsOptions from "./ConferenceAttendeesSeminarsOptions";
import ConferenceAttendeesSessionOptions from "./ConferenceAttendeesSessionOptions";
import { DefaultSortOptions } from "./constants";
import EventAttendeesOptions from "./EventAttendeesOptions";
import FollowUpAddressesOptions from "./FollowUpAddressesOptions";
import IndividualCancellationsOptions from "./IndividualCancellationsOptions";
import IndividualMeetingPreferencesPostCrunchOptions from "./IndividualMeetingPreferencesPostCrunchOptions";
import IndividualMeetingPreferencesPreCrunchOptions from "./IndividualMeetingPreferencesPreCrunchOptions";
import LoungesAndRestaurantsOptions from "./LoungesAndRestaurantsOptions";
import AttendeesTableOption from "./OptionTypes/AttendeesTableOption";
import BaseSortOptions from "./OptionTypes/BaseSortOptions";
import FacilitiesTableOption from "./OptionTypes/FacilitiesTableOption";
import SeminarsTableOption from "./OptionTypes/SeminarsTableOption";
import SessionsTableOption from "./OptionTypes/SessionsTableOption";
import SisterEventsOption from "./OptionTypes/SisterEventsOption";
import PeopleTypePreferencesOptions from "./PeopleTypePreferencesOptions";
import PeopleTypePreferencesPostCrunchOptions from "./PeopleTypePreferencesPostCrunchOptions";
import PlaceCardsOptions from "./PlaceCardsOptions";
import PreCrunchSummaryOfAllPreferencesOptions from "./PreCrunchSummaryOfAllPreferencesOptions";
import PreferencesByAnIndividualOptions from "./PreferencesByAnIndividualOptions";
import RestaurantsOptions from "./RestaurantOptions";
import SatisfiedAndUnsatisfiedTotalsOptions from "./SatisfiedAndUnsatisfiedTotalsOptions";
import SessionDetailsOptions from "./SessionDetailsOptions";
import SpeakerBookingsOptions from "./SpeakerBookingsOptions";
import SummaryOfPreferencesOptions from "./SummaryOfPreferencesOptions";

import styles from "./styles.module.less";

const { Content } = Layout;
const { Title } = Typography;

const ReportingOptions = () => {
  const { t } = useTranslation();
  const { reportKey } = useParams();

  const getReportOptions = () => {
    switch (reportKey) {
      case ReportsValues.IndividualMeetingPreferencesBy:
      case ReportsValues.IndividualMeetingPreferencesFor: {
        return <IndividualMeetingPreferencesPreCrunchOptions />;
      }
      case ReportsValues.IndividualMeetingPreferencesByPostCrunch:
      case ReportsValues.IndividualMeetingPreferencesForPostCrunch: {
        return <IndividualMeetingPreferencesPostCrunchOptions />;
      }
      case ReportsValues.PeopleTypeMeetingPreferencesFor:
      case ReportsValues.PeopleTypeMeetingPreferencesBy: {
        return <PeopleTypePreferencesOptions />;
      }
      case ReportsValues.PeopleTypeMeetingPreferencesByPostCrunch:
      case ReportsValues.PeopleTypeMeetingPreferencesForPostCrunch: {
        return <PeopleTypePreferencesPostCrunchOptions />;
      }
      case ReportsValues.PreferencesForSeminar: {
        return <SeminarsTableOption />;
      }
      case ReportsValues.LoungesAndRestaurantsCompaniesOnly:
      case ReportsValues.LoungesAndRestaurantsWithExecutiveNames:
      case ReportsValues.OperationsPackageDetails: {
        return <LoungesAndRestaurantsOptions />;
      }
      case ReportsValues.SessionDetailsAllSessionPlaces:
      case ReportsValues.SessionDetailsAllSessionTimes:
      case ReportsValues.SessionDetailsFreeSessionPlaces:
      case ReportsValues.SessionDetailsOverallocatedSessionPlaces: {
        return <SessionDetailsOptions />;
      }
      case ReportsValues.SummaryOfPreferencesD2DE2E:
      case ReportsValues.SummaryOfPreferencesE2DD2E: {
        return <SummaryOfPreferencesOptions />;
      }
      case ReportsValues.PreferencesByAnIndividualAllPreferences:
      case ReportsValues.PreferencesByAnIndividualSatisfiedPreferences:
      case ReportsValues.PreferencesByAnIndividualUnsatisfiedPreferences:
      case ReportsValues.PreferencesByAnIndividualPostCrunchAllPreferences: {
        return <PreferencesByAnIndividualOptions />;
      }
      case ReportsValues.IndividualCancelledScheduledMeetingsOnly: {
        return <IndividualCancellationsOptions showDate />;
      }
      case ReportsValues.IndividualCancelledMeetingPreferences: {
        return <IndividualCancellationsOptions sortOptions={DefaultSortOptions} />;
      }
      case ReportsValues.ListOfCancelledEventAttendees: {
        return <CancellationsOptions />;
      }
      case ReportsValues.ListOfAllCancellations:
      case ReportsValues.CancelledMeetingPreferences:
      case ReportsValues.AllCancelledScheduledMeetingsOnly: {
        return <CancellationsOptions allowDateClear />;
      }
      case ReportsValues.SatisfiedAndUnsatisfiedTotalsE2DAndD2E:
      case ReportsValues.SatisfiedAndUnsatisfiedTotalsD2DAndE2E: {
        return <SatisfiedAndUnsatisfiedTotalsOptions />;
      }
      case ReportsValues.AttendeeItineraryByIndividual: {
        return (
          <AttendeesTableOption
            additionalFilters={{ attendeeStatus: [AttendeeStatus.Attending, AttendeeStatus.AttendingReplacement] }}
          />
        );
      }
      case ReportsValues.AttendeeAvailability:
      case ReportsValues.SpeedMeetings: {
        return <BaseSortOptions />;
      }
      case ReportsValues.DietaryRequirements:
      case ReportsValues.AllTableAssignments:
      case ReportsValues.FillInTables:
      case ReportsValues.FellowDinersForExecutives: {
        return <RestaurantsOptions showSorting showTables />;
      }
      case ReportsValues.FellowDinersForExecutivesByIndividual: {
        return <AttendeesTableOption additionalFilters={{ group: "Executives" }} />;
      }
      case ReportsValues.NumberOfSeatsBooked: {
        return <RestaurantsOptions />;
      }
      case ReportsValues.MeetingsByFacilityTimeslotBookedMeetingsOnly:
      case ReportsValues.ByDelegatesForDelegates:
      case ReportsValues.ByExecutivesForExecutives:
      case ReportsValues.NegativePreferencesByDelegatesPositivePreferencesForDelegates:
      case ReportsValues.ExtraPreferences:
      case ReportsValues.SummaryOfCheckins:
      case ReportsValues.NegativePreferencesByExecutivesPositivePreferencesForExecutives:
      case ReportsValues.MeetingsByFacilityTimeslot:
      case ReportsValues.OccupiedBusinessHoursExecutives:
      case ReportsValues.OccupiedBusinessHoursDelegates: {
        return <BaseSortOptions defaultOption={DefaultSortOptions.Company} />;
      }
      case ReportsValues.AttendeeItineraryByPeopleType: {
        return <AttendeeItineraryByPeopleTypeOptions />;
      }
      case ReportsValues.ConferenceSpeakerSessionBookings:
      case ReportsValues.ConferenceAllConferenceAndGeneralBookings: {
        return <SpeakerBookingsOptions />;
      }
      case ReportsValues.AllReplacements: {
        return (
          <BaseSortOptions
            options={{
              Company: "Company",
              ["Surname-YR"]: "Surname - YR",
              ["Surname-R"]: "Surname - R",
              ["Date-Ascending"]: "Date - Ascending",
              ["Date-Descending"]: "Date - Descending",
            }}
            defaultOption={"Company"}
          />
        );
      }
      case ReportsValues.IndividualMeetingScores: {
        return <AttendeesTableOption />;
      }
      case ReportsValues.ConferenceAttendeesSelectedSeminarCode:
      case ReportsValues.ConferenceAttendeesSelectedSeminarType: {
        return <ConferenceAttendeesSeminarsOptions />;
      }
      case ReportsValues.ConferenceAttendeesSelectedSession:
      case ReportsValues.ConferenceAttendeesFellowAttendees: {
        return <ConferenceAttendeesSessionOptions />;
      }
      case ReportsValues.AllAboveRatio:
      case ReportsValues.AllBelowRatioMissingMealtimes:
      case ReportsValues.ExecutivesMeetingTotalsBusinessHours:
      case ReportsValues.ExecutivesMeetingTotalsAllExecutiveMeetings:
      case ReportsValues.ExecutivesMealtimeMeetings: {
        return (
          <BaseSortOptions
            options={{ ...DefaultSortOptions, Total: "Total" }}
            defaultOption={DefaultSortOptions.Company}
          />
        );
      }
      case ReportsValues.ConferenceAttendeesAllAssessmentFormsForSeminarType:
      case ReportsValues.ConferenceAttendeesAssessmentFormsForSeminarType: {
        return <ConferenceAssessmentFormsOptions />;
      }
      case ReportsValues.SummaryOfAllPreferences: {
        return <PreCrunchSummaryOfAllPreferencesOptions />;
      }
      case ReportsValues.AllEventAttendees:
      case ReportsValues.GuestsObservers:
      case ReportsValues.ReParticipants:
      case ReportsValues.EventAttendeesDelegates:
      case ReportsValues.EventAttendeesSpeakers:
      case ReportsValues.Press:
      case ReportsValues.EventAttendeesExecutives:
      case ReportsValues.ContractorsOthers: {
        return <EventAttendeesOptions />;
      }
      case ReportsValues.ConferenceAttendeesAssessmentFormsForSession: {
        return <SessionsTableOption />;
      }
      case ReportsValues.ConferenceAttendeesAssessmentFormsForFacility: {
        return <FacilitiesTableOption />;
      }
      case ReportsValues.AssignedBedrooms:
      case ReportsValues.AllBedrooms:
      case ReportsValues.DuplicateBedrooms: {
        return <BedroomsOptions />;
      }
      case ReportsValues.AssignedToSpecifiedPeopleTypes: {
        return <BedroomsOptions showPeopleTypes />;
      }
      case ReportsValues.EmptyBedrooms: {
        return <BedroomsOptions options={BedroomsSortOptions} />;
      }
      case ReportsValues.PeopleMissingBedrooms: {
        return (
          <BedroomsOptions
            options={{ PeopleType: "People Type", ...DefaultSortOptions }}
            defaultOption={"PeopleType"}
          />
        );
      }
      case ReportsValues.BedroomsGradeListing: {
        return <BedroomsOptions options={{ Total: "Total", Grade: "Grade" }} defaultOption={"Total"} />;
      }
      case ReportsValues.FollowUpAddressesPeopleType: {
        return <FollowUpAddressesOptions showPeopleTypeOption />;
      }
      case ReportsValues.FollowUpAddressesIndividual: {
        return <FollowUpAddressesOptions showAttendeesTableOption />;
      }
      case ReportsValues.PeopleWithoutBedrooms: {
        return <BedroomsOptions options={DefaultSortOptions} defaultOption={DefaultSortOptions.Surname} />;
      }
      case ReportsValues.PlaceCards: {
        return <PlaceCardsOptions />;
      }
      case ReportsValues.SharedBedrooms: {
        return <BedroomsOptions options={{ Bedroom: "Bedroom", ...DefaultSortOptions }} />;
      }
      case ReportsValues.CheckRestaurantTables: {
        return <SisterEventsOption />;
      }
    }
  };

  return (
    <Layout className={styles.layout}>
      <Content className={styles.content}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Title level={5}>{t("Reporting options")}</Title>

          {getReportOptions()}
        </Space>
      </Content>
    </Layout>
  );
};

export default ReportingOptions;
