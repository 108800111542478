import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Button, Form } from "components/styleguide";
import React, { FC } from "react";

import styles from "./styles.module.less";

interface EditFieldProps {
  name: string;
  initialValue: boolean;
  isUpdating: boolean;
  onUpdate: (values: any) => void;
  onClose: () => void;
  children: JSX.Element;
}

const EditField: FC<EditFieldProps> = ({ name, initialValue, isUpdating, onUpdate, onClose, children }) => {
  return (
    <Form className={styles.form} layout="inline" onFinish={onUpdate}>
      <Form.Item className={styles.formItem} name={name} initialValue={initialValue}>
        {children}
      </Form.Item>
      <Button
        className={styles.button}
        shape="circle"
        size="middle"
        icon={<CloseCircleOutlined className={styles.icon} />}
        onClick={onClose}
      />
      <Button
        className={styles.button}
        htmlType="submit"
        shape="circle"
        size="middle"
        icon={<CheckCircleOutlined className={styles.icon} />}
        loading={isUpdating}
        disabled={isUpdating}
      />
    </Form>
  );
};

export default EditField;
