/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumResponseModel } from '../apiModels';
/**
 * UserPreferencesApi - axios parameter creator
 * @export
 */
export const UserPreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Unmarks forum as favorite for current user
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteForumsForumIdDelete: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('favoriteForumsForumIdDelete', 'forumId', forumId)
            const localVarPath = `/favorite-forums/{forumId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Makes forum favorite for current user
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteForumsForumIdPut: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('favoriteForumsForumIdPut', 'forumId', forumId)
            const localVarPath = `/favorite-forums/{forumId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Endpoint doesn\'t accept pagination options nor search function
         * @summary Returns the current user\'s favorite forums in a list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteForumsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/favorite-forums`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPreferencesApi - functional programming interface
 * @export
 */
export const UserPreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Unmarks forum as favorite for current user
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteForumsForumIdDelete(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteForumsForumIdDelete(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Makes forum favorite for current user
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteForumsForumIdPut(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteForumsForumIdPut(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Endpoint doesn\'t accept pagination options nor search function
         * @summary Returns the current user\'s favorite forums in a list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async favoriteForumsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.favoriteForumsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserPreferencesApi - factory interface
 * @export
 */
export const UserPreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPreferencesApiFp(configuration)
    return {
        /**
         * 
         * @summary Unmarks forum as favorite for current user
         * @param {UserPreferencesApiFavoriteForumsForumIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteForumsForumIdDelete(requestParameters: UserPreferencesApiFavoriteForumsForumIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.favoriteForumsForumIdDelete(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Makes forum favorite for current user
         * @param {UserPreferencesApiFavoriteForumsForumIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteForumsForumIdPut(requestParameters: UserPreferencesApiFavoriteForumsForumIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.favoriteForumsForumIdPut(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * Endpoint doesn\'t accept pagination options nor search function
         * @summary Returns the current user\'s favorite forums in a list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        favoriteForumsGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.favoriteForumsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for favoriteForumsForumIdDelete operation in UserPreferencesApi.
 * @export
 * @interface UserPreferencesApiFavoriteForumsForumIdDeleteRequest
 */
export interface UserPreferencesApiFavoriteForumsForumIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof UserPreferencesApiFavoriteForumsForumIdDelete
     */
    readonly forumId: number
}

/**
 * Request parameters for favoriteForumsForumIdPut operation in UserPreferencesApi.
 * @export
 * @interface UserPreferencesApiFavoriteForumsForumIdPutRequest
 */
export interface UserPreferencesApiFavoriteForumsForumIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof UserPreferencesApiFavoriteForumsForumIdPut
     */
    readonly forumId: number
}

/**
 * UserPreferencesApi - object-oriented interface
 * @export
 * @class UserPreferencesApi
 * @extends {BaseAPI}
 */
export class UserPreferencesApi extends BaseAPI {
    /**
     * 
     * @summary Unmarks forum as favorite for current user
     * @param {UserPreferencesApiFavoriteForumsForumIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferencesApi
     */
    public favoriteForumsForumIdDelete(requestParameters: UserPreferencesApiFavoriteForumsForumIdDeleteRequest, options?: AxiosRequestConfig) {
        return UserPreferencesApiFp(this.configuration).favoriteForumsForumIdDelete(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Makes forum favorite for current user
     * @param {UserPreferencesApiFavoriteForumsForumIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferencesApi
     */
    public favoriteForumsForumIdPut(requestParameters: UserPreferencesApiFavoriteForumsForumIdPutRequest, options?: AxiosRequestConfig) {
        return UserPreferencesApiFp(this.configuration).favoriteForumsForumIdPut(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Endpoint doesn\'t accept pagination options nor search function
     * @summary Returns the current user\'s favorite forums in a list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPreferencesApi
     */
    public favoriteForumsGet(options?: AxiosRequestConfig) {
        return UserPreferencesApiFp(this.configuration).favoriteForumsGet(options).then((request) => request(this.axios, this.basePath));
    }
}

