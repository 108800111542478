import { QuestionnairesApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(QuestionnairesApi);

const Questionnaires = {
  getAll: api.questionnairesGet,
};

export default Questionnaires;
