import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteSeminarPreferenceMutation, useUpdateSeminarPreferenceMutation } from "api/mutations/attendees";
import { useAttendeePreferencesConferencesQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Modal, Table, Typography } from "components/styleguide";
import { PageResponseSeminarPreferenceResponseModel, SeminarPreferenceResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { numericalSorter, stringSorter } from "utils/sorterUtils";

import PriorityInputBox, { PreferenceResponseModels } from "../common/PriorityInputBox";
import { handleDuplicatePriorityError, validatePriorityRange } from "../common/utils";

import styles from "./styles.module.less";

interface AttendeePreferencesConferenceTableProps {
  forumId: number;
  attendeeId: number;
  onEdit: (seminar: SeminarPreferenceResponseModel) => void;
}

const AttendeePreferencesConferenceTable: FC<AttendeePreferencesConferenceTableProps> = ({
  forumId,
  attendeeId,
  onEdit,
}) => {
  const { t } = useTranslation();

  const [conferences, setConferences] = useState<PageResponseSeminarPreferenceResponseModel | null>(null);

  const {
    response: { isLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeePreferencesConferencesQuery,
    {
      onSuccess: ({ data }: { data: PageResponseSeminarPreferenceResponseModel }) => {
        setConferences(data);
      },
    },
    {
      forumId: forumId,
      attendeeId: attendeeId,
    },
  );

  const queryClient = useQueryClient();
  const updateMutation = useUpdateSeminarPreferenceMutation();
  const deleteMutation = useDeleteSeminarPreferenceMutation();

  // this state looks like this (where 3 is the seminarId of the table row):
  // {
  //   3: [
  //     "Must be between 1 and 1000",
  //     "This priority is already set for a seminar",
  //   ],
  // }
  const [preferenceErrors, setPreferenceErrors] = useState<{ [seminarId: number]: string[] }>({});

  const updatePriority = useCallback(
    (id: number, oldValue: number, newValue: number, conference?: PreferenceResponseModels) => {
      // add error to display if value is not in range
      const outOfRange = validatePriorityRange(preferenceErrors, setPreferenceErrors, id, newValue);
      if (outOfRange) {
        return;
      }

      // skip mutation if value didn't change
      if (oldValue === newValue) {
        return;
      }
      if (conference) {
        updateMutation.mutate(
          { forumId, attendeeId, id, seminarPreferenceRequestModel: { ...conference, priority: newValue } },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_CONFERENCES]);
            },
            onError: ({ response }) => {
              // add error to display when DuplicateItemException is returned
              handleDuplicatePriorityError(preferenceErrors, setPreferenceErrors, id, response);
            },
          },
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [],
  );

  const deleteSeminarPreference = (id: number) => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Typography.Text>{t("Do you want to proceed with deletion of this entry?")}</Typography.Text>,
      closable: true,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: () => {
        deleteMutation.mutate(
          { forumId, attendeeId, id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_CONFERENCES]);
              toast.success(t("Preference has been deleted successfully"));
            },
            onError: ({ response }) => {
              if (response?.data?.Message) {
                toast.error(response?.data?.message);
              } else {
                toast.error(t("Something went wrong"));
              }
            },
          },
        );
      },
    });
  };

  const columns = [
    {
      title: t("Priority"),
      dataIndex: "priority",
      key: "preference",
      render: (priority: number, record: PreferenceResponseModels) => (
        <PriorityInputBox
          priority={priority}
          preferenceErrors={preferenceErrors}
          setPreferenceErrors={setPreferenceErrors}
          rowId={record.id}
          updatePriority={updatePriority}
          record={record}
        />
      ),
      sorter: (a: SeminarPreferenceResponseModel, b: SeminarPreferenceResponseModel) =>
        numericalSorter(a.priority, b.priority),
    },
    {
      title: t("Seminar"),
      dataIndex: "seminarCode",
      key: "seminar",
      sorter: (a: SeminarPreferenceResponseModel, b: SeminarPreferenceResponseModel) =>
        stringSorter(a.seminarCode, b.seminarCode),
    },
    {
      title: t("Conference Title"),
      dataIndex: "seminarTitle",
      key: "conferenceTitle",
      sorter: (a: SeminarPreferenceResponseModel, b: SeminarPreferenceResponseModel) =>
        stringSorter(a.seminarTitle, b.seminarTitle),
    },
    {
      title: t("Satisfied"),
      dataIndex: "satisfied",
      key: "satisfied",
      render: (a: boolean) => (a ? t("True") : t("False")),
      sorter: (a: SeminarPreferenceResponseModel, b: SeminarPreferenceResponseModel) =>
        numericalSorter(toNumber(!!a.satisfied), toNumber(!!b.satisfied)),
    },
    {
      dataIndex: "id",
      render: (_: number, record: SeminarPreferenceResponseModel) => (
        <div className={styles.optionWrapper}>
          <Button onClick={() => onEdit(record)}>{t("Edit")}</Button>
          <span className={styles.iconWrapper}>
            <DeleteFilled className="delete-icon" onClick={() => deleteSeminarPreference(record.id)} />
          </span>
        </div>
      ),
    },
  ];

  return (
    <Table
      id="attendee-group-memberships-table"
      dataSource={conferences?.items ?? []}
      columns={columns}
      pagination={pagination}
      bordered={true}
      loading={isLoading || updateMutation.isLoading}
      rowKey="seminarId"
      onChange={handleSort}
    />
  );
};

export default AttendeePreferencesConferenceTable;
