import { useMutation } from "@tanstack/react-query";
import api from "api";

import apiClient from "../../../api-client";
import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

const useCreateVenueMutation = getWrappedUseMutation(apiClient.Venues.post);

const useUpdateVenueMutation = getWrappedUseMutation(apiClient.Venues.put);

const useUploadVenueMapMutation = getWrappedUseMutation(apiClient.Venues.map.put);

const useDeleteVenueMapMutation = getWrappedUseMutation(apiClient.Venues.map.delete);

const useUpdateForumVenueMutation = getWrappedUseMutation(apiClient.ForumVenues.put);

const useUploadForumVenueMapMutation = getWrappedUseMutation(apiClient.ForumVenues.map.put);

const useDeleteForumVenueMapMutation = getWrappedUseMutation(apiClient.ForumVenues.map.delete);

const useBulkImportBedroomsMutation = (options = {}) =>
  useMutation(({ venueId, formData }) => api.post(`/venues/${venueId}/import/facilities`, formData), options);

export {
  useBulkImportBedroomsMutation,
  useCreateVenueMutation,
  useDeleteForumVenueMapMutation,
  useDeleteVenueMapMutation,
  useUpdateForumVenueMutation,
  useUpdateVenueMutation,
  useUploadForumVenueMapMutation,
  useUploadVenueMapMutation,
};
