/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentCustomPdfModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * DocumentCustomPdfApi - axios parameter creator
 * @export
 */
export const DocumentCustomPdfApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get custom PDFs used for Document creation
         * @param {string} [fileName] 
         * @param {string} [createdBy] 
         * @param {string} [filterDate] 
         * @param {string} [filterToDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCustomPdfsGet: async (fileName?: string, createdBy?: string, filterDate?: string, filterToDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/document-custom-pdfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileName !== undefined) {
                localVarQueryParameter['FileName'] = fileName;
            }

            if (createdBy !== undefined) {
                localVarQueryParameter['CreatedBy'] = createdBy;
            }

            if (filterDate !== undefined) {
                localVarQueryParameter['FilterDate'] = (filterDate as any instanceof Date) ?
                    (filterDate as any).toISOString() :
                    filterDate;
            }

            if (filterToDate !== undefined) {
                localVarQueryParameter['FilterToDate'] = (filterToDate as any instanceof Date) ?
                    (filterToDate as any).toISOString() :
                    filterToDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new custom PDF
         * @param {string} fileName 
         * @param {string} [description] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCustomPdfsPost: async (fileName: string, description?: string, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('documentCustomPdfsPost', 'fileName', fileName)
            const localVarPath = `/document-custom-pdfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentCustomPdfApi - functional programming interface
 * @export
 */
export const DocumentCustomPdfApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentCustomPdfApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get custom PDFs used for Document creation
         * @param {string} [fileName] 
         * @param {string} [createdBy] 
         * @param {string} [filterDate] 
         * @param {string} [filterToDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCustomPdfsGet(fileName?: string, createdBy?: string, filterDate?: string, filterToDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentCustomPdfModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCustomPdfsGet(fileName, createdBy, filterDate, filterToDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new custom PDF
         * @param {string} fileName 
         * @param {string} [description] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCustomPdfsPost(fileName: string, description?: string, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCustomPdfsPost(fileName, description, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentCustomPdfApi - factory interface
 * @export
 */
export const DocumentCustomPdfApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentCustomPdfApiFp(configuration)
    return {
        /**
         * 
         * @summary Get custom PDFs used for Document creation
         * @param {DocumentCustomPdfApiDocumentCustomPdfsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCustomPdfsGet(requestParameters: DocumentCustomPdfApiDocumentCustomPdfsGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<DocumentCustomPdfModel>> {
            return localVarFp.documentCustomPdfsGet(requestParameters.fileName, requestParameters.createdBy, requestParameters.filterDate, requestParameters.filterToDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new custom PDF
         * @param {DocumentCustomPdfApiDocumentCustomPdfsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCustomPdfsPost(requestParameters: DocumentCustomPdfApiDocumentCustomPdfsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.documentCustomPdfsPost(requestParameters.fileName, requestParameters.description, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for documentCustomPdfsGet operation in DocumentCustomPdfApi.
 * @export
 * @interface DocumentCustomPdfApiDocumentCustomPdfsGetRequest
 */
export interface DocumentCustomPdfApiDocumentCustomPdfsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsGet
     */
    readonly fileName?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsGet
     */
    readonly createdBy?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsGet
     */
    readonly filterDate?: string

    /**
     * 
     * @type {string}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsGet
     */
    readonly filterToDate?: string
}

/**
 * Request parameters for documentCustomPdfsPost operation in DocumentCustomPdfApi.
 * @export
 * @interface DocumentCustomPdfApiDocumentCustomPdfsPostRequest
 */
export interface DocumentCustomPdfApiDocumentCustomPdfsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsPost
     */
    readonly fileName: string

    /**
     * 
     * @type {string}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsPost
     */
    readonly description?: string

    /**
     * 
     * @type {File}
     * @memberof DocumentCustomPdfApiDocumentCustomPdfsPost
     */
    readonly formFile?: File
}

/**
 * DocumentCustomPdfApi - object-oriented interface
 * @export
 * @class DocumentCustomPdfApi
 * @extends {BaseAPI}
 */
export class DocumentCustomPdfApi extends BaseAPI {
    /**
     * 
     * @summary Get custom PDFs used for Document creation
     * @param {DocumentCustomPdfApiDocumentCustomPdfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCustomPdfApi
     */
    public documentCustomPdfsGet(requestParameters: DocumentCustomPdfApiDocumentCustomPdfsGetRequest = {}, options?: AxiosRequestConfig) {
        return DocumentCustomPdfApiFp(this.configuration).documentCustomPdfsGet(requestParameters.fileName, requestParameters.createdBy, requestParameters.filterDate, requestParameters.filterToDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new custom PDF
     * @param {DocumentCustomPdfApiDocumentCustomPdfsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentCustomPdfApi
     */
    public documentCustomPdfsPost(requestParameters: DocumentCustomPdfApiDocumentCustomPdfsPostRequest, options?: AxiosRequestConfig) {
        return DocumentCustomPdfApiFp(this.configuration).documentCustomPdfsPost(requestParameters.fileName, requestParameters.description, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }
}

