import { useSeminarsQuery } from "api/queries/seminars";
import { TreeSelect } from "components/styleguide";
import { ForumSeminarWithTypeAndStreamAndSpeakers } from "generated/api";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

interface LocationDropdownProps {
  forumId: number;
  value?: number[];
  onChange?: (value: number[]) => void;
}

type Seminar = Omit<ForumSeminarWithTypeAndStreamAndSpeakers, "forumSeminarTypeName">;

type LocationTreeDataType = {
  [key: string]: Seminar[];
};

const LocationDropdown: React.FC<LocationDropdownProps> = ({ forumId, value, onChange }) => {
  const { t } = useTranslation();
  const { data: { data: seminars = [] } = {}, isLoading: isSeminarsLoading } = useSeminarsQuery({ forumId });

  const [seminarIds, setSeminarIds] = useState<number[]>(value || []);

  const groupedDataMap: LocationTreeDataType = {};

  seminars.forEach((seminar) => {
    const { forumSeminarTypeName, ...rest } = seminar;
    if (!groupedDataMap[forumSeminarTypeName]) {
      groupedDataMap[forumSeminarTypeName] = [];
    }
    groupedDataMap[forumSeminarTypeName].push(rest);
  });

  const mapChildren = (children: Seminar[]) => {
    return children.map(({ id, title, code }) => ({
      key: id,
      value: id,
      title: `${code} - ${title}`,
    }));
  };

  const treeData = Object.entries(groupedDataMap).map(([groupName, children]) => ({
    key: groupName,
    value: groupName,
    title: groupName,
    children: mapChildren(children),
  }));

  const handleSelectChange = (ids: number[]) => {
    setSeminarIds(ids);
    onChange?.(ids);
  };

  return (
    <TreeSelect
      treeCheckable
      treeDefaultExpandAll
      showSearch
      allowClear
      placeholder={t("Please, select the seminars to which you want to attach the document")}
      treeData={treeData}
      value={seminarIds}
      onChange={handleSelectChange}
      loading={isSeminarsLoading}
    />
  );
};

export default LocationDropdown;
