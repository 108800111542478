import { BillingNoteApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(BillingNoteApi);

const BillingNotes = {
  get: api.forumsForumIdBillingNotesGet,
  post: api.forumsForumIdBillingNotesPost,
  delete: api.forumsForumIdBillingNotesBillingNoteIdDelete,
};

export default BillingNotes;
