import moment from "moment";
import { FC, useMemo } from "react";

interface DateRendererProps {
  date: object | string;
  hideDate?: boolean;
  showTime?: boolean;
  utcOffset?: number | string;
}
const DateRenderer: FC<DateRendererProps> = ({
  date,
  hideDate = false,
  showTime = false,
  utcOffset,
}: DateRendererProps) => {
  const format = useMemo(() => {
    const d = hideDate ? "" : "L";
    const t = showTime ? "LT" : "";

    return `${d} ${t}`.trim();
  }, [showTime, hideDate]);

  const momentDate = utcOffset ? moment(date).utcOffset(utcOffset) : moment(date);
  return <>{momentDate.format(format)}</>;
};

export default DateRenderer;
