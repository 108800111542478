import { Col, Input, Row, Select, Space } from "components/styleguide";
import { AttendeeItineraryRemovalReasonType } from "generated/api";
import { useTranslation } from "react-i18next";

import { RemovalReasons, RemovalReasonsType } from "../../helpers";

export default function RemoveConfirmationPopup({
  reason,
  text,
  onSelect,
  onTextChange,
}: {
  reason?: string;
  text?: string;
  onSelect: (value: string) => void;
  onTextChange: (value: string) => void;
}) {
  const { t } = useTranslation();

  const isOtherReason = reason === AttendeeItineraryRemovalReasonType.Other;

  const handleSelect = (value: string) => {
    onSelect(value);
  };

  const handleTextChange = (value: string) => {
    onTextChange(value);
  };

  return (
    <div id="popup">
      <Space fullWidth={true} direction="vertical">
        <Row>
          <Col>{t("Do you want to remove this booking?")}</Col>
        </Row>
        <Row>
          <Col>{`${t("Reason")}:`}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <Select
              style={{ width: "100%" }}
              defaultValue={AttendeeItineraryRemovalReasonType.NoneSpecified}
              value={reason}
              onSelect={handleSelect}
              getPopupContainer={() => document.getElementById("popup") as HTMLElement}
            >
              {Object.keys(RemovalReasons).map((r) => (
                <Select.Option value={r} key={r}>
                  {t(RemovalReasons[r as keyof RemovalReasonsType])}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {isOtherReason && (
          <Row>
            <Col span={24}>
              <Input
                value={text}
                onChange={(e) => handleTextChange(e.target.value)}
                style={{ width: "100%" }}
                placeholder={t("Type your reason here")}
              />
            </Col>
          </Row>
        )}
      </Space>
    </div>
  );
}
