/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const Office = {
    NewYork: 'NewYork',
    Richmond: 'Richmond',
    Italy: 'Italy',
    Basel: 'Basel'
} as const;

export type Office = typeof Office[keyof typeof Office];



