import { useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { useMoveAttendeeToCompanyMutation } from "../../../../api/mutations/attendees";
import { useGetForumCompaniesQuery } from "../../../../api/queries/forumCompanies";
import KEYS from "../../../../api/queries/keys";
import FullHeightForm from "../../../../components/FullHeightForm";
import { Button, Drawer, Form, Input, Select } from "../../../../components/styleguide";
import { AttendeeMoveToCompanyRequestModel, ForumAttendeeResponseModel } from "../../../../generated/api";

const { Option } = Select;

interface MoveToCompanyDrawerProps {
  forumId: number;
  attendee: ForumAttendeeResponseModel;
  isOpen: boolean;
  onClose: () => void;
}

const MoveToCompanyDrawer: FC<MoveToCompanyDrawerProps> = ({ forumId, attendee, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const handleClose = () => {
    form.setFieldValue("forumCompanyId", null);
    onClose();
  };

  const forumCompaniesQuery = useGetForumCompaniesQuery({
    forumId,
    pageNumber: 1,
    pageSize: 150,
  });

  const moveAttendeeToCompanyMutation = useMoveAttendeeToCompanyMutation({
    onSuccess: () => {
      handleClose();
    },
  });

  const handleSubmit = (values: AttendeeMoveToCompanyRequestModel) => {
    moveAttendeeToCompanyMutation.mutate(
      {
        forumId,
        attendeeId: attendee.attendeeId,
        attendeeMoveToCompanyRequestModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE]);
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_HISTORY_INFORMATION]);
          handleClose();
        },
      },
    );
  };

  return (
    <Drawer title={t("Move to another company")} placement="right" onClose={handleClose} open={isOpen} destroyOnClose>
      <FullHeightForm
        form={form}
        layout="vertical"
        actionsPrepend={
          <Button onClick={handleClose} disabled={moveAttendeeToCompanyMutation.isLoading}>
            {t("cancel")}
          </Button>
        }
        actionsAppend={
          <Button
            type="primary"
            disabled={moveAttendeeToCompanyMutation.isLoading}
            loading={moveAttendeeToCompanyMutation.isLoading}
            htmlType="submit"
          >
            {t("Save")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("Company")}
          name="forumCompanyId"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Company)" }),
            },
          ]}
        >
          <Select
            showSearch
            placeholder={t("placeholders.select", { prop: "$t(Company)" })}
            loading={forumCompaniesQuery.isLoading}
            optionFilterProp={"label"}
          >
            {(forumCompaniesQuery?.data?.data?.items ?? [])
              .filter((c) => c.id !== attendee.forumCompanyId)
              .map((company) => (
                <Option value={company.id} label={company.name} key={company.id}>
                  {company.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item label={t("Job Title")} name="jobTitle" initialValue={attendee.jobTitle}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Email")}
          name="email"
          initialValue={attendee.email}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Email)" }),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label={t("Phone")} name="phone" initialValue={attendee.phone}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Mobile Phone")} name="mobilePhone" initialValue={attendee.mobilePhone}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Business Phone")} name="businessPhone" initialValue={attendee.businessPhone}>
          <Input />
        </Form.Item>
        <Form.Item label={t("Address")} name="address" initialValue={attendee.address}>
          <Input />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default MoveToCompanyDrawer;
