import { ForumAttendeeMeetingFeedbackApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeMeetingFeedbackApi);

const ForumAttendeeMeetingFeedback = {
  get: api.forumForumIdAttendeesAttendeeIdMeetingFeedbackGet,
  put: api.forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdPut,
  delete: api.forumForumIdAttendeesAttendeeIdMeetingFeedbackFeedbackIdDelete,
};

export default ForumAttendeeMeetingFeedback;
