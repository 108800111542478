import { useGetForumCompanyAttendeesQuery } from "api/queries/forumCompanies";
import { Table } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import { AttendeeModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { buildColumn } from "utils/columnUtils";

import styles from "./styles.module.less";

interface ForumCompanyAttendeesTableProps {
  forumId: number;
  forumCompanyId: number;
}

const ForumCompanyAttendeesTable: FC<ForumCompanyAttendeesTableProps> = ({ forumId, forumCompanyId }) => {
  const { t } = useTranslation();

  const {
    response: { isLoading, data: { data: { items: attendees = [] } = {} } = {} } = {},
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useGetForumCompanyAttendeesQuery,
    {},
    { forumId, forumCompanyId, OrderBy: "fullName" },
    { defaultPageSize: 10, pageSizeOptions: [10, 20, 50] },
  );

  const columns = useMemo(
    () => [
      buildColumn("Name", "fullName", {
        width: "40%",
        sorter: true,
        render: (fullName: string, record: AttendeeModel) => {
          return (
            <Link className={styles.link} to={`../../attendees/${record?.attendId}`}>
              <p>{fullName}</p>
            </Link>
          );
        },
      }),
      buildColumn("People Type", "peopleType", {
        width: "20%",
        sorter: true,
      }),
      buildColumn("Job Title", "jobTitle", { width: "20%" }),
      buildColumn("Attend Status", "attendStatus", {
        sorter: true,
        render: (attendStatus: number) => t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendStatus)}`),
      }),
    ],
    [t],
  );

  return (
    <Table
      rowKey="id"
      data-testid="peopleTable"
      dataSource={attendees}
      columns={columns}
      bordered
      pagination={pagination}
      loading={isLoading}
      onChange={handleSort}
    />
  );
};

export default ForumCompanyAttendeesTable;
