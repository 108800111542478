import { QuestionnaireContentQuestion, QuestionnaireMatching } from "../../../../../../../../../../generated/api";

type Question = QuestionnaireContentQuestion;

const mapQuestionsWithMappings = (
  qbsQuestions: Array<Question>,
  qmsQuestions: Array<Question>,
  mappings: QuestionnaireMatching,
): QuestionnaireMatching => {
  const questions = mappings.questions ?? [];
  if (questions.length === 0) {
    return mappings;
  }

  const qbsSet = new Set<Question["id"] | undefined>();
  const qmsSet = new Set<Question["id"] | undefined>();

  qbsQuestions.forEach((question) => qbsSet.add(question.id));
  qmsQuestions.forEach((question) => qmsSet.add(question.id));

  return {
    ...mappings,
    questions: questions.filter((question) => {
      return qbsSet.has(question.beingSelected) && qmsSet.has(question.makingSelections);
    }),
  };
};

export default mapQuestionsWithMappings;
