const TimeType = {
  AfternoonBusinessHours: 1,
  AllMeetAllOutsideBH: 2,
  Breakfast: 3,
  Dinner: 4,
  Lunch: 5,
  MorningBusinessHours: 6,
  Conference: 7,

  toDisplayName(timeType: number) {
    switch (timeType) {
      case 1:
        return "Afternoon Business Hours";
      case 2:
        return "All Meet All - Outside BH";
      case 3:
        return "Breakfast";
      case 4:
        return "Dinner";
      case 5:
        return "Lunch";
      case 6:
        return "Morning Business Hours";
      case 7:
        return "Conference";
      default:
        return "";
    }
  },
};

export default TimeType;
