/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ItineraryBookletsForumWelcomeLetterRequestModel } from '../apiModels';
// @ts-ignore
import { ItineraryBookletsForumWelcomeLetterResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * ItineraryBookletsForumWelcomeLetterApi - axios parameter creator
 * @export
 */
export const ItineraryBookletsForumWelcomeLetterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Welcome Letter by forum
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdWelcomeLetterGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdWelcomeLetterGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/welcome-letter`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload image for Welcome Letter by forum
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdWelcomeLetterImagePost: async (forumId: number, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdWelcomeLetterImagePost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/welcome-letter/image`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Welcome Letter by forum
         * @param {number} forumId 
         * @param {ItineraryBookletsForumWelcomeLetterRequestModel} [itineraryBookletsForumWelcomeLetterRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdWelcomeLetterPut: async (forumId: number, itineraryBookletsForumWelcomeLetterRequestModel?: ItineraryBookletsForumWelcomeLetterRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdWelcomeLetterPut', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/welcome-letter`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itineraryBookletsForumWelcomeLetterRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItineraryBookletsForumWelcomeLetterApi - functional programming interface
 * @export
 */
export const ItineraryBookletsForumWelcomeLetterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItineraryBookletsForumWelcomeLetterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Welcome Letter by forum
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdWelcomeLetterGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItineraryBookletsForumWelcomeLetterResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdWelcomeLetterGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload image for Welcome Letter by forum
         * @param {number} forumId 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdWelcomeLetterImagePost(forumId: number, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdWelcomeLetterImagePost(forumId, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Welcome Letter by forum
         * @param {number} forumId 
         * @param {ItineraryBookletsForumWelcomeLetterRequestModel} [itineraryBookletsForumWelcomeLetterRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdWelcomeLetterPut(forumId: number, itineraryBookletsForumWelcomeLetterRequestModel?: ItineraryBookletsForumWelcomeLetterRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdWelcomeLetterPut(forumId, itineraryBookletsForumWelcomeLetterRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItineraryBookletsForumWelcomeLetterApi - factory interface
 * @export
 */
export const ItineraryBookletsForumWelcomeLetterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItineraryBookletsForumWelcomeLetterApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Welcome Letter by forum
         * @param {ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdWelcomeLetterGet(requestParameters: ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGetRequest, options?: AxiosRequestConfig): AxiosPromise<ItineraryBookletsForumWelcomeLetterResponseModel> {
            return localVarFp.forumForumIdWelcomeLetterGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload image for Welcome Letter by forum
         * @param {ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdWelcomeLetterImagePost(requestParameters: ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePostRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdWelcomeLetterImagePost(requestParameters.forumId, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Welcome Letter by forum
         * @param {ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdWelcomeLetterPut(requestParameters: ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdWelcomeLetterPut(requestParameters.forumId, requestParameters.itineraryBookletsForumWelcomeLetterRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdWelcomeLetterGet operation in ItineraryBookletsForumWelcomeLetterApi.
 * @export
 * @interface ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGetRequest
 */
export interface ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumForumIdWelcomeLetterImagePost operation in ItineraryBookletsForumWelcomeLetterApi.
 * @export
 * @interface ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePostRequest
 */
export interface ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePostRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePost
     */
    readonly forumId: number

    /**
     * 
     * @type {File}
     * @memberof ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePost
     */
    readonly formFile?: File
}

/**
 * Request parameters for forumForumIdWelcomeLetterPut operation in ItineraryBookletsForumWelcomeLetterApi.
 * @export
 * @interface ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPutRequest
 */
export interface ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPut
     */
    readonly forumId: number

    /**
     * 
     * @type {ItineraryBookletsForumWelcomeLetterRequestModel}
     * @memberof ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPut
     */
    readonly itineraryBookletsForumWelcomeLetterRequestModel?: ItineraryBookletsForumWelcomeLetterRequestModel
}

/**
 * ItineraryBookletsForumWelcomeLetterApi - object-oriented interface
 * @export
 * @class ItineraryBookletsForumWelcomeLetterApi
 * @extends {BaseAPI}
 */
export class ItineraryBookletsForumWelcomeLetterApi extends BaseAPI {
    /**
     * 
     * @summary Get Welcome Letter by forum
     * @param {ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumWelcomeLetterApi
     */
    public forumForumIdWelcomeLetterGet(requestParameters: ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumWelcomeLetterApiFp(this.configuration).forumForumIdWelcomeLetterGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload image for Welcome Letter by forum
     * @param {ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumWelcomeLetterApi
     */
    public forumForumIdWelcomeLetterImagePost(requestParameters: ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterImagePostRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumWelcomeLetterApiFp(this.configuration).forumForumIdWelcomeLetterImagePost(requestParameters.forumId, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Welcome Letter by forum
     * @param {ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsForumWelcomeLetterApi
     */
    public forumForumIdWelcomeLetterPut(requestParameters: ItineraryBookletsForumWelcomeLetterApiForumForumIdWelcomeLetterPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsForumWelcomeLetterApiFp(this.configuration).forumForumIdWelcomeLetterPut(requestParameters.forumId, requestParameters.itineraryBookletsForumWelcomeLetterRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

