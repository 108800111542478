import { Select, Space, Typography } from "components/styleguide";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

interface EventsSelectOptionProps {
  eventType: EventTypes;
  setEventType: Dispatch<SetStateAction<EventTypes>>;
}

export enum EventTypes {
  ThisEvent = "ThisEvent",
  SisterEvents = "SisterEvents",
}

const EventsSelectOption = ({ eventType, setEventType }: EventsSelectOptionProps) => {
  const { t } = useTranslation();

  return (
    <Space direction={"vertical"}>
      <Text>{t("Events")}</Text>
      <Select
        style={{ minWidth: "200px" }}
        placeholder={t("Select event")}
        options={[
          {
            value: EventTypes.ThisEvent,
            label: t("This Event"),
          },
          {
            value: EventTypes.SisterEvents,
            label: t("Sister Events"),
          },
        ]}
        value={eventType}
        onChange={(value) => setEventType(value)}
      />
    </Space>
  );
};

export default EventsSelectOption;
