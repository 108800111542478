import PropTypes from "prop-types";
import Base from "./base";

const option = PropTypes.shape({
  beingSelected: PropTypes.string.isRequired,
  makingSelections: PropTypes.string.isRequired,
});

const question = PropTypes.shape({
  beingSelected: PropTypes.string.isRequired,
  makingSelections: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(option),
});
question.option = option;

const questionnaireMatching = PropTypes.shape({
  questions: PropTypes.arrayOf(question),
});
questionnaireMatching.question = question;

const PositiveSelectionV2 = PropTypes.shape({
  ...Base,
  groupSelectingFrom: PropTypes.number,
  questionnaireOfGroupBeingSelected: PropTypes.number,
  questionnaireOfGroupMakingSelections: PropTypes.number,
  showReasonForMeeting: PropTypes.bool,
  showFreeTextSearch: PropTypes.bool,
  showAdvancedSearch: PropTypes.bool,
  prefilteredListOrder: PropTypes.string,
  showPersonLocationOnSite: PropTypes.bool,
  showCompanyLocationOnSite: PropTypes.bool,
  colourForPositiveSelections: PropTypes.string,
  colourForNegativeSelections: PropTypes.string,
  includeCompanyProfileInSearch: PropTypes.bool,
  includePersonalProfileInSearch: PropTypes.bool,
  setTheMinimumOfSelectionsRequired: PropTypes.number,
  showContactDetailsOnTheProfile: PropTypes.bool,
  showAddressInformationOnTheProfileDisplay: PropTypes.bool,
  showCompanyOverviewOn: PropTypes.bool,
  showPersonalBiographyInProfile: PropTypes.bool,
  showAdditionalInformation: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  displayLocalisedLabels: PropTypes.bool,
  showTheMutualColumnInTheSelectionGrid: PropTypes.bool,
  useTheLegacyBookletProfile: PropTypes.bool,
  allowPagingOnTheSelectionGrid: PropTypes.bool,
  defaultSelectedPageSize: PropTypes.number,
  pageSizesAvailableForTheSelectionGrid: PropTypes.arrayOf(PropTypes.number),
  filterRequestsToMeetToTopOfResults: PropTypes.bool,
  questionnaireMatching: questionnaireMatching,
});

PositiveSelectionV2.questionnaireMatching = questionnaireMatching;

export default PositiveSelectionV2;
