import { BuildingApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(BuildingApi);

const Building = {
  get: api.venuesVenueIdBuildingsGet,
  getById: api.venuesVenueIdBuildingsBuildingIdGet,
  delete: api.venuesVenueIdBuildingsBuildingIdDelete,
};

export default Building;
