/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentDefinitionWatermarkResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * ForumDocumentDefinitionWatermarksApi - axios parameter creator
 * @export
 */
export const ForumDocumentDefinitionWatermarksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete watermark
         * @param {number} forumId 
         * @param {number} [watermarkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionWatermarksDelete: async (forumId: number, watermarkId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionWatermarksDelete', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-definition-watermarks`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (watermarkId !== undefined) {
                localVarQueryParameter['watermarkId'] = watermarkId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all watermarks
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionWatermarksGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionWatermarksGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-definition-watermarks`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload watermark
         * @param {number} forumId 
         * @param {string} [description] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionWatermarksPost: async (forumId: number, description?: string, formFile?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentDefinitionWatermarksPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-definition-watermarks`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (description !== undefined) { 
                localVarFormParams.append('Description', description as any);
            }
    
            if (formFile !== undefined) { 
                localVarFormParams.append('FormFile', formFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumDocumentDefinitionWatermarksApi - functional programming interface
 * @export
 */
export const ForumDocumentDefinitionWatermarksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumDocumentDefinitionWatermarksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete watermark
         * @param {number} forumId 
         * @param {number} [watermarkId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionWatermarksDelete(forumId: number, watermarkId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionWatermarksDelete(forumId, watermarkId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all watermarks
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionWatermarksGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocumentDefinitionWatermarkResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionWatermarksGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload watermark
         * @param {number} forumId 
         * @param {string} [description] 
         * @param {File} [formFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentDefinitionWatermarksPost(forumId: number, description?: string, formFile?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentDefinitionWatermarksPost(forumId, description, formFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumDocumentDefinitionWatermarksApi - factory interface
 * @export
 */
export const ForumDocumentDefinitionWatermarksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumDocumentDefinitionWatermarksApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete watermark
         * @param {ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionWatermarksDelete(requestParameters: ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionWatermarksDelete(requestParameters.forumId, requestParameters.watermarkId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all watermarks
         * @param {ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionWatermarksGet(requestParameters: ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<DocumentDefinitionWatermarkResponseModel>> {
            return localVarFp.forumsForumIdDocumentDefinitionWatermarksGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload watermark
         * @param {ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentDefinitionWatermarksPost(requestParameters: ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentDefinitionWatermarksPost(requestParameters.forumId, requestParameters.description, requestParameters.formFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdDocumentDefinitionWatermarksDelete operation in ForumDocumentDefinitionWatermarksApi.
 * @export
 * @interface ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDeleteRequest
 */
export interface ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDelete
     */
    readonly watermarkId?: number
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionWatermarksGet operation in ForumDocumentDefinitionWatermarksApi.
 * @export
 * @interface ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGetRequest
 */
export interface ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdDocumentDefinitionWatermarksPost operation in ForumDocumentDefinitionWatermarksApi.
 * @export
 * @interface ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPostRequest
 */
export interface ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPost
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPost
     */
    readonly description?: string

    /**
     * 
     * @type {File}
     * @memberof ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPost
     */
    readonly formFile?: File
}

/**
 * ForumDocumentDefinitionWatermarksApi - object-oriented interface
 * @export
 * @class ForumDocumentDefinitionWatermarksApi
 * @extends {BaseAPI}
 */
export class ForumDocumentDefinitionWatermarksApi extends BaseAPI {
    /**
     * 
     * @summary Delete watermark
     * @param {ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionWatermarksApi
     */
    public forumsForumIdDocumentDefinitionWatermarksDelete(requestParameters: ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksDeleteRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionWatermarksApiFp(this.configuration).forumsForumIdDocumentDefinitionWatermarksDelete(requestParameters.forumId, requestParameters.watermarkId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all watermarks
     * @param {ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionWatermarksApi
     */
    public forumsForumIdDocumentDefinitionWatermarksGet(requestParameters: ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksGetRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionWatermarksApiFp(this.configuration).forumsForumIdDocumentDefinitionWatermarksGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload watermark
     * @param {ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentDefinitionWatermarksApi
     */
    public forumsForumIdDocumentDefinitionWatermarksPost(requestParameters: ForumDocumentDefinitionWatermarksApiForumsForumIdDocumentDefinitionWatermarksPostRequest, options?: AxiosRequestConfig) {
        return ForumDocumentDefinitionWatermarksApiFp(this.configuration).forumsForumIdDocumentDefinitionWatermarksPost(requestParameters.forumId, requestParameters.description, requestParameters.formFile, options).then((request) => request(this.axios, this.basePath));
    }
}

