import EnumBuilder from "./enumBuilderUtils";

const builder = new EnumBuilder();
const EnumClass = builder
  .add("FreeText", "Free Text")
  .add("StaticText", "Static Text")
  .add("Checkbox", "Checkbox")
  .add("RadioButton", "Radio Button")
  .add("DropdownList", "Dropdown List")
  .add("MatrixSingleChoice", "Matrix Single Choice")
  .add("MatrixMultiChoice", "Matrix Multi Choice")
  .build();

class QuestionType extends EnumClass {
  get isFreeText() {
    return this.value === "FreeText";
  }

  get isStaticText() {
    return this.value === "StaticText";
  }

  get isCheckbox() {
    return this.value === "Checkbox";
  }

  get isRadioButton() {
    return this.value === "RadioButton";
  }

  get isDropdownList() {
    return this.value === "DropdownList";
  }

  get isMatrixSingleChoice() {
    return this.value === "MatrixSingleChoice";
  }

  get isMatrixMultiChoice() {
    return this.value === "MatrixMultiChoice";
  }

  get hasOptionsColumns() {
    return this.isMatrixSingleChoice || this.isMatrixMultiChoice;
  }

  get hasOptions() {
    return (
      this.isFreeText ||
      this.isCheckbox ||
      this.isRadioButton ||
      this.isDropdownList ||
      this.isMatrixSingleChoice ||
      this.isMatrixMultiChoice
    );
  }
}

export default QuestionType;
