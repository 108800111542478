import { TimeSlotSessionApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(TimeSlotSessionApi);

const TimeSlotSession = {
  getAll: api.forumsForumIdTimeslotsTimeslotIdSessionsGet,
  get: api.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdGet,
  post: api.forumsForumIdTimeslotsTimeslotIdSessionsPost,
  put: api.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdPut,
  delete: api.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdDelete,
  sessions: {
    getAll: api.forumsForumIdTimeslotsSessionsGet,
  },
  reoder: {
    put: api.forumsForumIdTimeslotsTimeslotIdSessionsSessionIdReorderPut,
  },
};

export default TimeSlotSession;
