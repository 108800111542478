import apiClient from "api-client";

import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";

export const useUpdateForumAttendeeMeetingFeedbackMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeMeetingFeedback.put,
);

export const useDeleteForumAttendeeMeetingFeedbackMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeMeetingFeedback.delete,
);
