import { useQuestionnaireExportQuery } from "api/queries/questionnaires";
import DropdownButton from "components/DropdownButton";
import { Select } from "components/styleguide";
import { FileExportWithPdf, ForumGroupResponseModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

interface QuestionnaireAnswersExportButtonProps {
  questionnaireId: number;
  groups: Array<ForumGroupResponseModel>;
}

type QuestionnaireFileExportType = Omit<FileExportWithPdf, "PDF">;

const QuestionnaireAnswersExportButton: FC<QuestionnaireAnswersExportButtonProps> = ({ questionnaireId, groups }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [documentType, setDocumentType] = useState<QuestionnaireFileExportType | null>(null);

  const items = [
    {
      label: t("Excel"),
      key: "excel",
      onClick: () => setDocumentType(FileExportWithPdf.Xls),
    },
    {
      label: t("CSV"),
      key: "csv",
      onClick: () => setDocumentType(FileExportWithPdf.Csv),
    },
  ];

  const getGroupsOptions = () => {
    return groups.map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  };

  const { isFetching: isQuestionnaireAnswersExportLoading } = useQuestionnaireExportQuery(
    {
      forumId,
      questionnaireId,
      forumGroupIds: selectedGroupIds,
      exportType: documentType as FileExportWithPdf,
    },
    {
      enabled: !!documentType,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: ({ response }: any) => {
        const type = response.data?.type;
        const message = response.data?.meta?.Message;

        if (type === "DomainSpecificBadRequestException" && message) {
          toast.error(message);
          return;
        }

        toast.error(t("Something went wrong"));
      },
      onSettled: () => setDocumentType(null),
    },
  );

  return (
    <>
      <Select
        style={{ flex: 1, width: "200px" }}
        placeholder={t("Select Group")}
        mode="multiple"
        allowClear
        showSearch
        optionFilterProp={"label"}
        options={getGroupsOptions()}
        value={selectedGroupIds}
        onChange={setSelectedGroupIds}
      />
      <DropdownButton
        title={t("Export")}
        items={items}
        isLoading={isQuestionnaireAnswersExportLoading}
        disabled={!selectedGroupIds.length}
      />
    </>
  );
};

export default QuestionnaireAnswersExportButton;
