import { useSelectionSiteContentQuery } from "api/queries/selectionSites";
import { SelectionSiteItemType } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { filterItemsByType, getItemsFromPages, itemTypeToString } from "utils/selectionSiteContentUtils";

import { AsyncSelect } from "../index";

interface SelectionSiteItemSelectProps {
  types: Array<SelectionSiteItemType>;
  forumId: number;
  selectionSiteId: number;
  value?: number;
  onChange?: (value: number) => void;
}

type Options = Array<{ value: number; label: string }>;

const SelectionSiteItemSelect: FC<SelectionSiteItemSelectProps> = ({
  value,
  forumId,
  selectionSiteId,
  types,
  onChange,
}) => {
  const { t } = useTranslation();
  const query = useSelectionSiteContentQuery({ forumId, selectionSiteId });

  const optionsMapper = (response: NonNullable<typeof query.data>["data"]) => {
    const pages = response.pages ?? [];
    const itemPageMap = new Map<number, number>();

    pages.forEach((page, index) => {
      page.items?.forEach((item) => {
        if (!item.id) {
          return;
        }

        itemPageMap.set(item.id, index + 1);
      });
    });

    const items = getItemsFromPages(pages);
    return filterItemsByType(items, types).reduce<Options>((accumulator, item) => {
      if (!item.type || !item.id) {
        return accumulator;
      }

      const page = itemPageMap.get(item.id);
      const type = types.length > 1 ? t(itemTypeToString(item.type)) : "";

      const option = {
        value: item.id,
        label: t("Page {{page}}; Step {{type}};", { page, type }),
      };
      return [...accumulator, option];
    }, []);
  };

  return <AsyncSelect query={query} optionsMapper={optionsMapper} value={value} onChange={onChange} />;
};

export default SelectionSiteItemSelect;
