import { createContext } from "react";

type ContextType = {
  errors: Set<number>;
  add: (id: number) => void;
  remove: (id: number) => void;
  has: (id: number) => boolean;
};

const ItemErrorsContext = createContext<ContextType>({
  errors: new Set(),
  add: () => {},
  remove: () => {},
  has: () => false,
});

export default ItemErrorsContext;
