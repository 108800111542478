import { Space, Typography } from "components/styleguide";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React from "react";
import { useTranslation } from "react-i18next";
import { generateItemId } from "utils/selectionSiteContentUtils";

import ItemRenderer from "../ItemRenderer";

const ItemsRenderer = ({ pageId, items, onChange }) => {
  const { t } = useTranslation();

  if (!items || items.length === 0) {
    return <Typography.Text>{t("Sorry, there is no items to display")}</Typography.Text>;
  }

  return (
    <Space direction="vertical" size="large" fullWidth>
      {items.map((item) => {
        const id = generateItemId(pageId, item.id);

        return (
          <ItemRenderer key={item.id} itemId={id} item={item} onChange={(newItem) => onChange(item.id, newItem)} />
        );
      })}
    </Space>
  );
};

ItemsRenderer.propTypes = {
  pageId: PropTypes.number.isRequired,
  items: SelectionSitesPropTypes.Items,
  onChange: PropTypes.func,
};

ItemsRenderer.defaultProps = {
  items: [],
  onChange: () => undefined,
};

export default ItemsRenderer;
