import { FormInstance } from "antd/es/form/Form";
import { Rule } from "antd/lib/form";
import { Button, Col, Form, Input, Row } from "components/styleguide";
import { ForumContactInfoModel } from "generated/api";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

export default function ContactInfo({
  data,
  loading,
  form,
  onSubmit,
  onReset,
}: {
  data: ForumContactInfoModel | undefined;
  loading: boolean;
  form: FormInstance;
  onSubmit: (values: ForumContactInfoModel) => void;
  onReset: () => void;
}) {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    contactName: yup
      .string()
      .nullable()
      .max(50, t("errors.max", { count: 50 })),
    contactPhone: yup
      .string()
      .nullable()
      .max(50, t("errors.max", { count: 50 })),
    contactEmail: yup
      .string()
      .nullable()
      .email(t("errors.emailValid"))
      .max(50, t("errors.max", { count: 50 })),
  });

  const yupSync = {
    async validator({ field }: { field: string }, value: unknown) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  } as unknown as Rule;

  return (
    <Form onFinish={onSubmit} id="contactInfoForm" name="contact-info" form={form}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item rules={[yupSync]} label={t("contactName")} name="contactName">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[yupSync]} label={t("contactPhone")} name="contactPhone" initialValue={data?.contactPhone}>
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item rules={[yupSync]} label={t("contactEmail")} name="contactEmail">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={80}>
        <Col span={1}>
          <Button htmlType="submit" type="primary" loading={loading}>
            {t("save")}
          </Button>
        </Col>
        <Col span={1}>
          <Button onClick={onReset}>{t("reset")}</Button>
        </Col>
      </Row>
    </Form>
  );
}
