import moment, { Moment } from "moment";
import { getForumDisplayDateByStartAndEnd } from "utils/dateUtils";

type ForumDateCallback = (date: Moment) => Moment;

type ForumDatesCallback = (startDate: Moment, endDate: Moment) => Array<Moment> | string | null;

type ForumDateFunc = (date: string, callback?: ForumDateCallback) => Moment | null;

type ForumDatesFunc = (
  startDate: string,
  endDate: string,
  getForumDateFunc: ForumDateFunc,
  callback: ForumDatesCallback,
) => Array<Moment> | string | null;

const getForumDate: ForumDateFunc = (date, callback) => {
  const parsedDate = moment(date);

  if (!parsedDate.isValid()) {
    return null;
  }

  return callback ? callback(parsedDate) : parsedDate;
};

const getForumDateNextYear = (date: string) => {
  return getForumDate(date, (parsedDate) => parsedDate.add(1, "years"));
};

const getForumDates: ForumDatesFunc = (startDate, endDate, getForumDateFunc, callback) => {
  const startForumDate = getForumDateFunc(startDate);
  const endForumDate = getForumDateFunc(endDate);

  if (!startForumDate || !endForumDate) {
    return null;
  }

  return callback(startForumDate, endForumDate);
};

const getForumDatesArray: ForumDatesCallback = (startDate, endDate) => [startDate, endDate];

export const getForumDuration = (startDate: string, endDate: string) => {
  return getForumDates(startDate, endDate, getForumDate, getForumDatesArray);
};

export const getRecurForumDuration = (startDate: string, endDate: string) => {
  return getForumDates(startDate, endDate, getForumDateNextYear, getForumDatesArray);
};

export const getForumDisplayDates = (startDate: string, endDate: string) => {
  return getForumDates(startDate, endDate, getForumDate, getForumDisplayDateByStartAndEnd);
};

export const getRecurForumDisplayDates = (startDate: string, endDate: string) => {
  return getForumDates(startDate, endDate, getForumDateNextYear, getForumDisplayDateByStartAndEnd);
};
