import { Space } from "components/styleguide";
import { AttendeeModel } from "generated/api";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DefaultSortOptions } from "../constants";
import AttendeesTableOption from "../OptionTypes/AttendeesTableOption";
import SortOrderOption from "../OptionTypes/SortByOption";
import WithFullReasonSelectOption from "../OptionTypes/WithFullReasonSelectOption";
import WithReasonSelectOption from "../OptionTypes/WithReasonSelectOption";

const SortOptions = { ...DefaultSortOptions, Priority: "Priority" };

const IndividualMeetingPreferencesPostCrunchOptions = () => {
  const navigate = useNavigate();

  const [isWithReason, setIsWithReason] = useState<boolean>(false);
  const [isWithFullReason, setIsWithFullReason] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string>(SortOptions.Company);

  const onAttendeeSelect = useCallback(
    (attendee: AttendeeModel) => {
      navigate("report", {
        state: {
          parameters: {
            ForumAttendeeId: attendee.attendId,
            WithReason: isWithReason,
            WithFullReason: isWithFullReason,
            OrderBy: orderBy,
          },
        },
      });
    },
    [isWithReason, isWithFullReason, navigate, orderBy],
  );

  return (
    <Space fullWidth direction={"vertical"} size={"large"}>
      <Space>
        <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
        <WithReasonSelectOption isWithReason={isWithReason} setIsWithReason={setIsWithReason} />
        <WithFullReasonSelectOption isWithFullReason={isWithFullReason} setIsWithFullReason={setIsWithFullReason} />
      </Space>
      <AttendeesTableOption onAttendeeSelect={onAttendeeSelect} />
    </Space>
  );
};

export default IndividualMeetingPreferencesPostCrunchOptions;
