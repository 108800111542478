import { UploadOutlined } from "@ant-design/icons";
import { useGetForumGroupsQuery } from "api/queries/groups";
import { Button, DatePicker, Divider, Form, Input, TreeSelect, Upload } from "components/styleguide";
import { ForumVisualSpeakerDocumentResponseModel } from "generated/api";
import { EventLayoutContext } from "layouts/EventLayout/types";
import { toNumber } from "lodash";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext, useParams } from "react-router-dom";
import { handleFileFakeRequest, MAX_FILE_SIZE, normFile } from "utils/documentUtils";
import { validateFileSize } from "utils/validatorUtils";

import { DocumentUpdateModel, DocumentUploadModel } from "../types";
import LocationDropdown from "./LocationDropdown";

import styles from "./styles.module.less";

const { Dragger } = Upload;

interface DocumentFormProps {
  initialValue?: ForumVisualSpeakerDocumentResponseModel;
  isLoading: boolean;
  onSubmit: <T extends DocumentUploadModel & DocumentUpdateModel>(values: T) => void;
  onClose: () => void;
}

const DocumentForm: FC<DocumentFormProps> = ({ initialValue, isLoading, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm();
  const { forum } = useOutletContext<EventLayoutContext>();
  const forumId = toNumber(params.id);
  const seminarId = toNumber(params.seminarId);

  const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

  const { data: { data: groups = [] } = {} } = useGetForumGroupsQuery({ forumId });

  const getDefaultDateRange = () => [moment(initialValue?.publishedFromDate), moment(initialValue?.publishedUntilDate)];

  const getDefaultSeminarIds = () => initialValue?.associatedSeminars?.map((seminar) => seminar?.seminarId);

  const getDefaultPermissionIds = () => initialValue?.permissionGroups?.map(({ forumGroupId }) => forumGroupId);

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={onSubmit}>
      <Form.Item
        name="file"
        label={t("File")}
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          { required: !initialValue, message: t("Please select a file") },
          {
            validator: validateFileSize(MAX_FILE_SIZE),
            message: t("File is bigger than {{size}}", { size: "100MB" }),
          },
        ]}
      >
        <Dragger
          accept=".doc,.docx,.ppt,.pptx,.xlsx,application/pdf"
          customRequest={handleFileFakeRequest}
          maxCount={1}
          onChange={({ fileList }) => setIsFileSelected(!!fileList.length)}
        >
          <p>
            <UploadOutlined />
            &nbsp; {t("Drag file here or Browse")}
          </p>
        </Dragger>
      </Form.Item>
      {initialValue && !isFileSelected && (
        <p>
          <span className={styles.title}>{t("Current file:")} </span>
          {initialValue?.title}
        </p>
      )}
      <Form.Item name="description" label={t("Description")} initialValue={initialValue?.description ?? undefined}>
        <Input maxLength={500} placeholder={t("Please, enter file description")} />
      </Form.Item>
      <Form.Item
        name="location"
        label={t("Location")}
        initialValue={initialValue ? getDefaultSeminarIds() : [seminarId]}
        rules={[
          {
            required: true,
            message: t("Please, select the seminars to which you want to attach the document"),
          },
        ]}
      >
        <LocationDropdown {...{ forumId }} />
      </Form.Item>
      <Form.Item
        name="publish"
        label={t("Publish From - Until")}
        initialValue={
          initialValue?.publishedFromDate && initialValue?.publishedUntilDate
            ? getDefaultDateRange()
            : [moment(forum?.startDate), moment(forum?.endDate)]
        }
      >
        <DatePicker.RangePicker />
      </Form.Item>
      <Divider />
      <h2>{t("Permissions")}</h2>
      <Form.Item name="permissions" label={t("Add Permission")} initialValue={getDefaultPermissionIds()}>
        <TreeSelect
          treeData={groups}
          treeCheckable
          fieldNames={{ label: "name", value: "id" }}
          treeDefaultExpandAll
          showSearch
          placeholder={t("Select Group")}
        />
      </Form.Item>
      <Divider />
      <div className={styles.actions}>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <div />
        <Button loading={isLoading} disabled={isLoading} type="primary" htmlType="submit">
          {t("Save")}
        </Button>
      </div>
    </Form>
  );
};

export default DocumentForm;
