import { UploadOutlined } from "@ant-design/icons";
import { FormInstance } from "antd";
import { FileImportModes } from "components/FileImport/helpers";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Upload } from "components/styleguide";
import { useTranslation } from "react-i18next";
import { handleFileFakeRequest } from "utils/documentUtils";
import { v4 as uuidv4 } from "uuid";

import { FileImportSubmit } from "../types";

export default function ImportDrawer({
  title,
  visible,
  onSubmit,
  onClose,
  isLoading,
  form,
}: {
  title: string;
  visible: boolean;
  onSubmit: (values: FileImportSubmit) => void;
  onClose: () => void;
  isLoading: boolean;
  form: FormInstance;
}) {
  const { t } = useTranslation();

  const submit = (values: FileImportSubmit) => {
    const importKey = uuidv4();

    onSubmit({ ...values, importMode: FileImportModes.Recreate, importKey } as FileImportSubmit);
  };

  return (
    <Drawer title={t(title)} open={visible} onClose={onClose} contentWrapperStyle={{ minWidth: "50%" }} destroyOnClose>
      <FullHeightForm
        form={form}
        onFinish={submit}
        actionsPrepend={<Button onClick={onClose}>{t("Close")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
            {t("Import")}
          </Button>
        }
      >
        <Form.Item
          name="formFile"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(File)" }),
            },
          ]}
        >
          <Upload.Dragger maxCount={1} accept=".xlsx" customRequest={handleFileFakeRequest}>
            <h1>
              <UploadOutlined />
            </h1>
            <p>{t("Drag file here or Browse")}</p>
          </Upload.Dragger>
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
}
