import api from "api";
import { useQuery } from "@tanstack/react-query";

const getBuildingFloorsQueryKey = "get_floors";
const getForumBuildingFloorsQueryKey = "get_forum_floors";

const floorsQueryKeys = {
  getBuildingFloors: (venueId, buildingId) => [
    getBuildingFloorsQueryKey,
    venueId,
    buildingId,
  ],
  getForumBuildingFloors: (forumId, buildingId) => [
    getForumBuildingFloorsQueryKey,
    forumId,
    buildingId,
  ],
};

const useBuildingFloorsQuery = ({ venueId, buildingId }, options = {}) => {
  return useQuery(
    floorsQueryKeys.getBuildingFloors(venueId, buildingId),
    () => api.get(`venues/${venueId}/buildings/${buildingId}/floors`),
    options
  );
};

const useForumBuildingFloorsQuery = ({ forumId, buildingId }, options = {}) =>
  useQuery(
    floorsQueryKeys.getForumBuildingFloors(forumId, buildingId),
    () => api.get(`/forums/${forumId}/venue/buildings/${buildingId}/floors`),
    options
  );

export {
  floorsQueryKeys,
  useBuildingFloorsQuery,
  useForumBuildingFloorsQuery,
  getBuildingFloorsQueryKey,
  getForumBuildingFloorsQueryKey,
};
