import Icon, { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteTimeslotMutation } from "api/mutations/timeslots";
import { useGetFileImportQuery } from "api/queries/fileImport";
import KEYS from "api/queries/keys";
import { useTimeslotsQuery } from "api/queries/timeslots";
import ContextualDateRenderer from "components/ContextualDateRenderer";
import { Button, Modal, Space, Table, Typography } from "components/styleguide";
import DateTimezoneRenderSwitcher from "contexts/dateTimezoneRenderSwitcher";
import ForumUtcOffsetContext from "contexts/forumUtcOffset";
import TimeType from "enums/TimeType";
import { FileImportStatuses, TimeSlotResponseModel, TimeSlotSessionResponseModel, TimeSlotType } from "generated/api";
import { EventLayoutContext } from "layouts/EventLayout/types";
import React, { useContext, useEffect, useState, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { addTimezoneIfNotExists } from "utils/dateUtils";
import { downloadFileByURL } from "utils/fileUtils";
import { getFileImportSnapshot, subscribeToFileImport, updateFileImportKey } from "utils/localStorage/fileImport";
import { dateTimeSorter, stringSorter } from "utils/sorterUtils";

import ExportTimeslotsButton from "./ExportTimeslotsButton";
import TimeslotImport from "./FileImport";

import styles from "./styles.module.less";

const { confirm } = Modal;
const { Text } = Typography;

const importLoadingToastId = "importTimeslotToast";
const resultToastId = "resultToastId";

const normalizeData = (data: TimeSlotResponseModel[], timezone: string) => {
  if (!Array.isArray(data)) {
    return [];
  }

  return data.map((entry) => ({
    ...entry,
    sessions: entry.sessions?.map(
      (session) =>
        ({
          ...session,
          startTime: addTimezoneIfNotExists(session.startTime as string, timezone),
          endTime: addTimezoneIfNotExists(session.endTime as string, timezone),
        } as TimeSlotSessionResponseModel),
    ),
  }));
};

const TimeSlotsTable = () => {
  const { t } = useTranslation();
  const { id } = useOutletContext<EventLayoutContext>();
  const navigate = useNavigate();
  const forumUtcOffset = useContext(ForumUtcOffsetContext);
  const queryClient = useQueryClient();
  const importKey = useSyncExternalStore(subscribeToFileImport, getFileImportSnapshot, () => undefined);

  const [timeslots, setTimeSlots] = useState<TimeSlotResponseModel[]>([]);

  const deleteTimeslotMutation = useDeleteTimeslotMutation();

  useEffect(() => {
    if (importKey) {
      toast.loading(t("Importing File"), {
        toastId: importLoadingToastId,
      });
    }
  }, [importKey, t]);

  const handleErrorDowload = (errorsLink: string) => {
    updateFileImportKey(null);
    return downloadFileByURL(errorsLink);
  };

  useGetFileImportQuery(
    { key: importKey },
    {
      refetchInterval: 2000,
      enabled: !!importKey,
      onSuccess: ({ data }) => {
        if (data.status === FileImportStatuses.Completed) {
          toast.dismiss(importLoadingToastId);
          toast.success(
            <Space direction="vertical">
              <Text>{t("Import successful")}</Text>
            </Space>,
            { toastId: resultToastId },
          );
          updateFileImportKey(null);
          queryClient.resetQueries([KEYS.GET_TIMESLOTS]);
        }

        if (data.status === FileImportStatuses.Failed) {
          updateFileImportKey(null);
          toast.dismiss(importLoadingToastId);
          toast.error(
            <Space direction="vertical">
              <Text>{t("Unable to upload the file, please, download the errors report")}</Text>
              {data.errorsReportFileLink && (
                <Button onClick={() => handleErrorDowload(data.errorsReportFileLink as string)}>
                  {t("Export Errors")}
                </Button>
              )}
            </Space>,
            { toastId: resultToastId, autoClose: false },
          );
        }
      },
      onError: () => {
        toast.dismiss(importLoadingToastId);
        toast.error(t("Something went wrong"));
      },
    },
  );

  const timeslotsQuery = useTimeslotsQuery(
    { forumId: id },
    {
      onSuccess: ({ data }) => {
        setTimeSlots(data);
      },
    },
  );

  const showCantDeleteModal = () => {
    confirm({
      title: t("warning"),
      content: t("timeslotDeleteWarning"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("ok"),
      okButtonProps: { style: { display: "none" } },
    });
  };

  const showDeleteConfirm = (timeslotId: number) => {
    confirm({
      title: t("confirmation"),
      content: t("timeslotDeleteConfirm"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      onOk: async () => {
        await deleteTimeslotMutation.mutateAsync(
          { forumId: id, timeSlotId: timeslotId },
          {
            onSuccess: () => {
              const newTimeslots = timeslots.filter((slot) => slot.id !== timeslotId);
              setTimeSlots(newTimeslots);
              toast.success(t("timeslotDeleteSuccess"));
            },
          },
        );
      },
    });
  };

  const handleTimeslotDelete = (timeslot: TimeSlotResponseModel) => {
    if (timeslot?.sessions?.length && timeslot.sessions.length > 0) {
      showCantDeleteModal();
    } else {
      showDeleteConfirm(timeslot.id as number);
    }
  };

  const columns = [
    {
      title: t("timeType"),
      dataIndex: "timeSlotType",
      key: "id",
      sorter: (a: TimeSlotResponseModel, b: TimeSlotResponseModel) =>
        stringSorter(
          t(`timeTypes.${TimeType.toDisplayName(TimeType[a.timeSlotType as TimeSlotType])}`),
          t(`timeTypes.${TimeType.toDisplayName(TimeType[b.timeSlotType as TimeSlotType])}`),
        ),
      render: (timeSlotType: TimeSlotType) => {
        return <p>{t(`timeTypes.${TimeType.toDisplayName(TimeType[timeSlotType])}`)}</p>;
      },
    },
    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      sorter: (a: TimeSlotResponseModel, b: TimeSlotResponseModel) =>
        stringSorter(a.description as string, b.description as string),
    },
    {
      title: t("Sessions"),
      dataIndex: "sessions",
      key: "sessions",
      sorter: (a: TimeSlotResponseModel, b: TimeSlotResponseModel) => {
        if (a.sessions && b.sessions && a.sessions[0] && b.sessions[0]) {
          return dateTimeSorter(a.sessions[0].startTime as string, b.sessions[0].startTime as string);
        } else if (a.sessions) {
          return 1;
        } else if (b.sessions) {
          return -1;
        } else {
          return 0;
        }
      },
      render: (_: unknown, record: TimeSlotResponseModel) => {
        if (record.sessions) {
          return (
            <>
              {record.sessions
                .sort((a, b) => (a.order as number) - (b.order as number))
                .map((session) => (
                  <p key={session.id} className={styles.sessionTime}>
                    <ContextualDateRenderer date={session.startTime} showTime />
                    {" - "}
                    <ContextualDateRenderer date={session.endTime} showTime />
                  </p>
                ))}
            </>
          );
        }
        return <div>{t("noSessions")}</div>;
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (_: unknown, record: TimeSlotResponseModel) => {
        return (
          <div className={styles.buttonWrapper}>
            <Button>
              <Link to={`${record.id}/edit`}>{t("edit")}</Link>
            </Button>
            <Icon
              className={styles.deleteIcon}
              key={record.id}
              component={DeleteFilled as React.ForwardRefExoticComponent<CustomIconComponentProps>}
              alt="delete"
              onClick={() => handleTimeslotDelete(record)}
            />
          </div>
        );
      },
    },
  ];

  const addTimeslotClick = () => {
    navigate("new");
  };

  return (
    <DateTimezoneRenderSwitcher.Provider>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Space direction="horizontal" style={{ width: "100%" }}>
          <Button onClick={addTimeslotClick}>{t("Add Timeslot Type")}</Button>
          <ExportTimeslotsButton />
          <TimeslotImport forumId={id} />
        </Space>
        <DateTimezoneRenderSwitcher.Toggle />
        <Table
          className={styles.table}
          dataSource={normalizeData(timeslots || [], forumUtcOffset)}
          columns={columns}
          loading={timeslotsQuery.isLoading}
          pagination={false}
          bordered={true}
          rowKey="id"
        />
      </Space>
    </DateTimezoneRenderSwitcher.Provider>
  );
};

export default TimeSlotsTable;
