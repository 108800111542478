import { ForumsApi } from "../../generated/api";
import { initAPIClient } from "./_base";

const api = initAPIClient(ForumsApi);

const Forums = {
  getAll: api.forumsGet,
  get: api.forumsForumIdGet,
  put: api.forumsIdPut,
  post: api.forumsPost,
  postFromExisting: api.forumsExistingForumIdPost,
  socialLinks: {
    get: api.forumsForumIdSocialLinksGet,
    put: api.forumsForumIdSocialLinksPut,
  },
  logo: {
    get: api.forumsForumIdLogoAccessUrlGet,
    post: api.forumsLogoPost,
  },
  years: {
    get: api.forumsYearsGet,
  },
  contact: {
    get: api.forumsForumIdContactGet,
    put: api.forumsForumIdContactPut,
  },
  sisterEvents: {
    get: api.forumsForumIdSisterForumsGet,
  },
};

export default Forums;
