import { Form } from "components/styleguide";
import { EventOptionsItem } from "generated/api";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import DefaultForm from "../DefaultForm";
import { Setup, YesNo } from "../inputs";
import Choices from "./components/Choices";

interface EventOptionsProps {
  item?: EventOptionsItem;
  onChange?: (item: EventOptionsItem) => void;
}

const EventOptions: FC<EventOptionsProps> = ({ item, onChange }) => {
  const { t } = useTranslation();

  const handleChange = (values: Partial<EventOptionsItem>) => {
    const newValues = { ...item, ...values };

    return onChange?.(newValues);
  };

  return (
    <DefaultForm
      itemId={item?.id}
      initialValues={item}
      onChange={handleChange}
      options={() => (
        <>
          <Form.Item name="displayLocalisedLabels" label={t("Display localized labels")}>
            <YesNo />
          </Form.Item>
          <Form.Item name="choises" label={t("Set up the options on this step")}>
            <Setup formTitle={t("Set up the options on this step")} form={{ element: Choices }} />
          </Form.Item>
        </>
      )}
    />
  );
};

export default EventOptions;
