import api from "api";
import {useQuery} from "@tanstack/react-query";

const timezonesKey = "timezones";

const timezonesKeys = {
  getTimezonesKey: () => [timezonesKey],
};

const useTimezonesQuery = (options = {}) => {
  return useQuery(
    timezonesKeys.getTimezonesKey(),
    async () => api.get(`timezones`),
    options
  );
};

export {useTimezonesQuery};
