import { FormInstance } from "antd";
import { Col, DatePicker, Divider, Form, Row, Select, Space, Spin, Table, Typography } from "components/styleguide";
import { AvailabilityForMealResponseModel, SpeakerTrackingResponseModel } from "generated/api";
import { EventLayoutContext } from "layouts/EventLayout/types";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

interface AttendanceProps {
  isEditing: boolean;
  speakerTracking: SpeakerTrackingResponseModel | null;
  isLoading?: boolean;
  isLoadingTable: boolean;
  form: FormInstance;
}

const Attendance = ({ isEditing, speakerTracking, isLoading, isLoadingTable, form }: AttendanceProps) => {
  const { t } = useTranslation();
  const { forum } = useOutletContext<EventLayoutContext>();

  // when the form item values are not updated after updating hotel allocations dates
  useEffect(() => {
    if (speakerTracking?.checkInDate && speakerTracking?.checkOutDate) {
      form.setFieldValue("accommodationRequired", [
        moment.utc(speakerTracking.checkInDate),
        moment.utc(speakerTracking.checkOutDate),
      ]);
    }
  }, [speakerTracking?.checkInDate, speakerTracking?.checkOutDate, form]);

  if (!speakerTracking || isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  const columns = [
    { title: t("Timeslot"), dataIndex: "timeslot" },
    {
      title: t("Is Available"),
      dataIndex: "isAvailable",
      render: (_: boolean, record: AvailabilityForMealResponseModel) => (
        <Form.Item initialValue={record.isAvailable} name={[record.id.toString(), "isAvailable"]}>
          {isEditing ? (
            <Select>
              <Option value={true}>{t("Yes")}</Option>
              <Option value={false}>{t("No")}</Option>
            </Select>
          ) : (
            <label>{record.isAvailable !== null ? (record.isAvailable ? t("Yes") : t("No")) : "-"}</label>
          )}
        </Form.Item>
      ),
    },
  ];

  return (
    <>
      <Row gutter={80}>
        <Col span={24}>
          <Form.Item
            name={"inPersonOrVirtual"}
            initialValue={speakerTracking.inPersonOrVirtual}
            label={t("In Person/Virtual")}
          >
            {isEditing ? (
              <Select>
                <Option value={true}>{t("In-Person")}</Option>
                <Option value={false}>{t("Virtual")}</Option>
              </Select>
            ) : (
              <label>{speakerTracking.inPersonOrVirtual ? t("In-Person") : t("Virtual")}</label>
            )}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name={"accommodationRequired"}
            initialValue={[moment.utc(speakerTracking.checkInDate ?? forum.startDate), moment.utc(speakerTracking.checkOutDate ?? forum.endDate)]}
            label={t("Accommodation Required")}
          >
            {isEditing ? (
              <DatePicker.RangePicker
                showTime={true}
                style={{ width: "100%" }}
                minuteStep={5}
                format={t("dateFormat")} />
            ) : (
              <label>
                {speakerTracking.checkInDate && speakerTracking.checkOutDate
                  ? `${moment.utc(speakerTracking.checkInDate).format(t("dateFormat"))} - ${moment
                    .utc(speakerTracking.checkOutDate)
                    .format(t("dateFormat"))}`
                  : "-"}
              </label>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row justify="center">
          <Col span={24}>
            <Typography.Title level={5}>{t("Availability for Meals")}</Typography.Title>
          </Col>
        </Row>

        <Form.List name="availabilityForMeals">
          {() => (
            <Table
              columns={columns}
              dataSource={speakerTracking.availabilityForMeals}
              loading={isLoadingTable}
              rowKey="id"
              pagination={false}
            />
          )}
        </Form.List>
        <Divider />
      </Space>
    </>
  );
};

export default Attendance;
