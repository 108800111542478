import { SelectProps } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { t } from "i18next";

import Select from "../Select";

const YesNo = <ValueType, OptionsType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: SelectProps<ValueType, OptionsType>,
) => {
  return (
    <Select {...props}>
      <Select.Option value={true}>{t("Yes").toString()}</Select.Option>
      <Select.Option value={false}>{t("No").toString()}</Select.Option>
    </Select>
  );
};

export default YesNo;
