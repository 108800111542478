import { Space } from "antd";
import { ReportsValues } from "pages/Reporting/helpers";
import { useState } from "react";
import { useParams } from "react-router-dom";

import LinkToReport from "../components/LinkToReport";
import SortOrderOption from "../OptionTypes/SortByOption";

const PackagesOptions = {
  Company: "Company",
  LoungeAndCompany: "Lounge and Company",
  LoungeAndTable: "Lounge and Table",
  RestaurantAndCompany: "Restaurant, Company",
  RestaurantAndTable: "Restaurant, Table",
};

const PackagesOptionsWithSurname = {
  ...PackagesOptions,
  Surname: "Surname",
};

const LoungesAndRestaurantsOptions = () => {
  const { reportKey } = useParams();

  const [orderBy, setOrderBy] = useState<string>("Company");

  let sortOptions: Record<string, string> = {};

  switch (reportKey) {
    case ReportsValues.LoungesAndRestaurantsWithExecutiveNames: {
      sortOptions = PackagesOptionsWithSurname;
      break;
    }
    default: {
      sortOptions = PackagesOptions;
    }
  }

  return (
    <Space align={"end"}>
      <SortOrderOption options={sortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
      <LinkToReport parameters={{ OrderBy: orderBy }} />
    </Space>
  );
};

export default LoungesAndRestaurantsOptions;
