/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GdprRequestStatusModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseGdprRequestModel } from '../apiModels';
/**
 * GdprRequestApi - axios parameter creator
 * @export
 */
export const GdprRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} gdprRequestId 
         * @param {GdprRequestStatusModel} [gdprRequestStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gdprGdprRequestIdPut: async (gdprRequestId: number, gdprRequestStatusModel?: GdprRequestStatusModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gdprRequestId' is not null or undefined
            assertParamExists('gdprGdprRequestIdPut', 'gdprRequestId', gdprRequestId)
            const localVarPath = `/gdpr/{gdprRequestId}`
                .replace(`{${"gdprRequestId"}}`, encodeURIComponent(String(gdprRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gdprRequestStatusModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gdprGet: async (pageSize?: number, orderBy?: string, orderDir?: string, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gdpr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GdprRequestApi - functional programming interface
 * @export
 */
export const GdprRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GdprRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} gdprRequestId 
         * @param {GdprRequestStatusModel} [gdprRequestStatusModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gdprGdprRequestIdPut(gdprRequestId: number, gdprRequestStatusModel?: GdprRequestStatusModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gdprGdprRequestIdPut(gdprRequestId, gdprRequestStatusModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageSize] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gdprGet(pageSize?: number, orderBy?: string, orderDir?: string, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseGdprRequestModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gdprGet(pageSize, orderBy, orderDir, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GdprRequestApi - factory interface
 * @export
 */
export const GdprRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GdprRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {GdprRequestApiGdprGdprRequestIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gdprGdprRequestIdPut(requestParameters: GdprRequestApiGdprGdprRequestIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.gdprGdprRequestIdPut(requestParameters.gdprRequestId, requestParameters.gdprRequestStatusModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GdprRequestApiGdprGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gdprGet(requestParameters: GdprRequestApiGdprGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PageResponseGdprRequestModel> {
            return localVarFp.gdprGet(requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for gdprGdprRequestIdPut operation in GdprRequestApi.
 * @export
 * @interface GdprRequestApiGdprGdprRequestIdPutRequest
 */
export interface GdprRequestApiGdprGdprRequestIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof GdprRequestApiGdprGdprRequestIdPut
     */
    readonly gdprRequestId: number

    /**
     * 
     * @type {GdprRequestStatusModel}
     * @memberof GdprRequestApiGdprGdprRequestIdPut
     */
    readonly gdprRequestStatusModel?: GdprRequestStatusModel
}

/**
 * Request parameters for gdprGet operation in GdprRequestApi.
 * @export
 * @interface GdprRequestApiGdprGetRequest
 */
export interface GdprRequestApiGdprGetRequest {
    /**
     * 
     * @type {number}
     * @memberof GdprRequestApiGdprGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof GdprRequestApiGdprGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof GdprRequestApiGdprGet
     */
    readonly orderDir?: string

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof GdprRequestApiGdprGet
     */
    readonly pageNumber?: number
}

/**
 * GdprRequestApi - object-oriented interface
 * @export
 * @class GdprRequestApi
 * @extends {BaseAPI}
 */
export class GdprRequestApi extends BaseAPI {
    /**
     * 
     * @param {GdprRequestApiGdprGdprRequestIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GdprRequestApi
     */
    public gdprGdprRequestIdPut(requestParameters: GdprRequestApiGdprGdprRequestIdPutRequest, options?: AxiosRequestConfig) {
        return GdprRequestApiFp(this.configuration).gdprGdprRequestIdPut(requestParameters.gdprRequestId, requestParameters.gdprRequestStatusModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GdprRequestApiGdprGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GdprRequestApi
     */
    public gdprGet(requestParameters: GdprRequestApiGdprGetRequest = {}, options?: AxiosRequestConfig) {
        return GdprRequestApiFp(this.configuration).gdprGet(requestParameters.pageSize, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageNumber, options).then((request) => request(this.axios, this.basePath));
    }
}

