const getUniqueKeys = (params: Record<string, unknown>): Array<string> => {
  // params object is converted to two-dimensional array of key-value pairs
  const paramsArray = Object.entries(params);

  // then sorted by key in alphabetical order
  // to make sure that { id: 2, page: 1 } will generate the same key as { page: 1, id: 2 }
  const sortedParamsArray = paramsArray.sort((a, b) => a[0].localeCompare(b[0]));

  // filter out undefined values
  const filteredParamsArray = sortedParamsArray.filter(([, value]) => value !== undefined);

  // then join them into a string
  const array = filteredParamsArray.map(([key, value]) => `${key}=${JSON.stringify(value)}`);

  return array;
};

export default getUniqueKeys;
