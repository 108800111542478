import { TableProps } from "antd";
import { Table } from "components/styleguide";
import TableRowActionButtons from "components/TableRowActionButtons";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FacilityResponseModel } from "../../../../generated/api";

import styles from "./styles.module.less";

interface FacilitiesProps {
  data: Array<FacilityResponseModel>;
  onSort: TableProps<FacilityResponseModel>["onChange"];
  loading: boolean;
  onEdit: (value: FacilityResponseModel) => void;
  onDelete: (value: FacilityResponseModel) => void;
}

const Facilities: FC<FacilitiesProps> = ({ data, onSort, loading, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        title: t("facilityCode"),
        dataIndex: "code",
        sorter: true,
      },
      {
        title: t("facilityName"),
        dataIndex: "name",
        sorter: true,
      },
      {
        title: t("tables"),
        dataIndex: "tables",
        sorter: true,
      },
      {
        title: t("floor"),
        dataIndex: "floor",
        sorter: true,
      },
      {
        title: "",
        render: (_: unknown, record: FacilityResponseModel) => {
          return <TableRowActionButtons onEdit={() => onEdit(record)} onDelete={() => onDelete(record)} />;
        },
      },
    ],
    [onEdit, onDelete, t],
  );

  return (
    <div className={styles.container}>
      <Table columns={columns} dataSource={data} onChange={onSort} loading={loading} pagination={false} rowKey="id" />
    </div>
  );
};

export default Facilities;
