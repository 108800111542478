import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateFacilityMutation,
  useDeleteFacilityMutation,
  useUpdateFacilityMutation,
} from "api/mutations/facilities";
import { useCreateFloorMutation, useDeleteFloorMutation } from "api/mutations/floors";
import { useCreateRoomGradeMutation, useDeleteRoomGradeMutation } from "api/mutations/roomGrade";
import { useDeleteTableMutation } from "api/mutations/tables";
import { useGetBuildingQuery } from "api/queries/buildings";
import {
  useBedroomsQuery,
  useConferenceRoomsQuery,
  useFacilityQuery,
  useMeetingRoomsQuery,
  useRestaurantsQuery,
  useSpeedMeetingsQuery,
} from "api/queries/facilities";
import { getBuildingFloorsQueryKey, useBuildingFloorsQuery } from "api/queries/floors";
import KEYS from "api/queries/keys";
import { useRoomGradesQuery } from "api/queries/roomGrade";
import { useTablesQuery } from "api/queries/tables";
import { useGetVenueQuery } from "api/queries/venues";
import Building from "components/VenueDetails/Building";
import { AdminTypes } from "enums/common";
import FacilityType from "enums/FacilityType";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import VenueContext from "pages/venueContext";
import React, { useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const facilityTypesQueryKeys = {
  [FacilityType.MeetingRoom]: KEYS.GET_MEETING_ROOMS,
  [FacilityType.ConferenceRoom]: KEYS.GET_CONFERENCE_ROOMS,
  [FacilityType.SpeedMeetings]: KEYS.GET_SPEED_MEETINGS,
  [FacilityType.Restaurant]: KEYS.GET_RESTAURANTS,
  [FacilityType.Bedroom]: KEYS.GET_BEDROOMS,
};

const SystemBuilding = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { venueId } = useOutletContext();
  const { buildingId } = useParams();

  const [building, setBuilding] = useState({});
  const [codeSearch, setCodeSearch] = useState(null);
  const [nameSearch, setNameSearch] = useState(null);
  const [descriptionSearch, setDescriptionSearch] = useState(null);
  const [roomGradeSearch, setRoomGradeSearch] = useState(null);

  const createNewFacilityMutation = useCreateFacilityMutation();
  const updateFacilityMutation = useUpdateFacilityMutation();
  const createFloorMutation = useCreateFloorMutation();
  const deleteFloorMutation = useDeleteFloorMutation();
  const createRoomGradeMutation = useCreateRoomGradeMutation();
  const deleteRoomGradeMutation = useDeleteRoomGradeMutation();
  const deleteFacilityMutation = useDeleteFacilityMutation();

  const { data: { data: floors = [] } = {} } = useBuildingFloorsQuery({
    venueId,
    buildingId,
  });
  const { data: { data: roomGrades = [] } = {} } = useRoomGradesQuery({
    venueId,
    buildingId,
  });

  const { data: { data: venue = {} } = {} } = useGetVenueQuery({ venueId });

  useGetBuildingQuery(
    { venueId, buildingId },
    {
      onSuccess: ({ data }) => {
        setBuilding(data);
        if (!location.state || !location.state.venueName || !location.state.buildingName) {
          const hash = location.hash ?? location.pathname.split("#")[1];

          navigate(`${location.pathname}${hash}`, {
            replace: true,
            hash: hash,
            state: {
              ...location.state,
              venueName: venue.name,
              buildingName: data.name,
            },
          });
        }
      },
    },
  );

  const handleFacilityCreateSubmit = (facility) => {
    return createNewFacilityMutation.mutateAsync(
      {
        venueId,
        buildingId,
        facility,
      },
      {
        onSuccess: async () => {
          if (facilityTypesQueryKeys[facility.facilityType]) {
            await queryClient.invalidateQueries([facilityTypesQueryKeys[facility.facilityType]]);
          }
        },
      },
    );
  };

  const handleFacilityEditSubmit = (facility, facilityId, originalFacilityType) => {
    return updateFacilityMutation.mutateAsync(
      { venueId, buildingId, facility, facilityId },
      {
        onSuccess: async () => {
          const facilityTypeQueryKey =
            facilityTypesQueryKeys[FacilityType[facility.facilityType]] ||
            facilityTypesQueryKeys[facility.facilityType];
          const originalFacilityTypeQueryKey =
            facilityTypesQueryKeys[FacilityType[originalFacilityType]] || facilityTypesQueryKeys[originalFacilityType];

          if (facilityTypeQueryKey) {
            await queryClient.invalidateQueries([facilityTypeQueryKey]);
            await queryClient.invalidateQueries([originalFacilityTypeQueryKey]);
          }
        },
      },
    );
  };

  const handleFloorAdd = (name) => {
    createFloorMutation.mutate(
      { venueId, buildingId, floor: { name } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([getBuildingFloorsQueryKey]);
        },
      },
    );
  };

  const handleFloorDelete = (floorId) => {
    deleteFloorMutation.mutate(
      { venueId, buildingId, floorId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([getBuildingFloorsQueryKey]);
          queryClient.invalidateQueries([KEYS.GET_MEETING_ROOMS]);
          queryClient.invalidateQueries([KEYS.GET_CONFERENCE_ROOMS]);
          queryClient.invalidateQueries([KEYS.GET_SPEED_MEETINGS]);
          queryClient.invalidateQueries([KEYS.GET_RESTAURANTS]);
          queryClient.invalidateQueries([KEYS.GET_BEDROOMS]);
        },
      },
    );
  };

  const handleRoomGradeAdd = (name) => {
    createRoomGradeMutation.mutate(
      { venueId, buildingId, roomGradeRequestModel: { name } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(KEYS.GET_ROOM_GRADES);
        },
      },
    );
  };

  const handleRoomGradeDelete = (roomGradeId) => {
    deleteRoomGradeMutation.mutate(
      { venueId, buildingId, roomGradeId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ROOM_GRADES, KEYS.GET_BEDROOMS]);
        },
      },
    );
  };

  const useInjectedFacilityQuery = ({ buildingId, facilityId }, options) => {
    return useFacilityQuery({ venueId, buildingId, facilityId }, options);
  };

  const meetingRoomsQuery = useModernQueryWithPaginationAndOrder(useMeetingRoomsQuery, {}, { venueId, buildingId });

  const conferenceRoomsQuery = useModernQueryWithPaginationAndOrder(
    useConferenceRoomsQuery,
    {},
    { venueId, buildingId },
  );

  const speedMeetingsQuery = useModernQueryWithPaginationAndOrder(useSpeedMeetingsQuery, {}, { venueId, buildingId });

  const restaurantsQuery = useModernQueryWithPaginationAndOrder(useRestaurantsQuery, {}, { venueId, buildingId });

  const useInjectedTablesQuery = ({ facilityId, type }, options) => {
    return useTablesQuery({ venueId, buildingId, facilityId, type }, options);
  };

  const deleteTableMutation = useDeleteTableMutation();

  const injectedDeleteTableMutate = ({ buildingId, facilityId, tableId }, options) => {
    deleteTableMutation.mutate({ venueId, buildingId, facilityId, tableId }, options);
  };

  const bedroomsQuery = useModernQueryWithPaginationAndOrder(
    useBedroomsQuery,
    {},
    {
      venueId,
      buildingId,
      FacilityCode: codeSearch,
      Name: nameSearch,
      Description: descriptionSearch,
      RoomGrade: roomGradeSearch,
    },
  );

  const handleFacilityDelete = async (facility) => {
    return await deleteFacilityMutation.mutateAsync(
      { venueId, buildingId, facilityId: facility.id },
      {
        onSuccess: () => {
          const facilityTypeQueryKey =
            facilityTypesQueryKeys[FacilityType[facility.facilityType]] ||
            facilityTypesQueryKeys[facility.facilityType];

          if (facilityTypeQueryKey) {
            queryClient.resetQueries([facilityTypeQueryKey]);
          }

          queryClient.resetQueries([KEYS.GET_FORUM_FACILITIES]);
          queryClient.resetQueries([KEYS.GET_FACILITIES]);
        },
      },
    );
  };

  const venueActions = {
    useFacilityQuery: useInjectedFacilityQuery,
    setCodeSearch: setCodeSearch,
    setNameSearch: setNameSearch,
    setDescriptionSearch: setDescriptionSearch,
    onFacilityCreateSubmit: handleFacilityCreateSubmit,
    isFacilityCreateLoading: createNewFacilityMutation.isLoading,
    onFacilityEditSubmit: handleFacilityEditSubmit,
    isFacilityEditLoading: updateFacilityMutation.isLoading,
    onFacilityDeleteSubmit: handleFacilityDelete,
    floors: floors,
    onFloorAdd: handleFloorAdd,
    onFloorDelete: handleFloorDelete,
    meetingRoomsQuery: meetingRoomsQuery,
    conferenceRoomsQuery: conferenceRoomsQuery,
    speedMeetingsQuery: speedMeetingsQuery,
    restaurantsQuery: restaurantsQuery,
    useTablesQuery: useInjectedTablesQuery,
    deleteTableMutate: injectedDeleteTableMutate,
    bedroomsQuery: bedroomsQuery,
    roomGrades: roomGrades,
    onRoomGradeAdd: handleRoomGradeAdd,
    onRoomGradeDelete: handleRoomGradeDelete,
    setRoomGradeSearch: setRoomGradeSearch,
    attendees: undefined,
    setAttendeeSearch: undefined,
    setBillingNoteSearch: undefined,
  };

  return (
    <VenueContext.Provider value={venueActions}>
      <Building ui={AdminTypes.SystemAdmin} building={building} />
    </VenueContext.Provider>
  );
};

export default SystemBuilding;
