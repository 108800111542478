import React from "react";
import {Breadcrumb as AntBreadcrumb} from "antd";
import {Link} from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const Breadcrumb = (props) => {
  const breadcrumbs = useBreadcrumbs(props.breadcrumbs, {
    excludePaths: props.excludePaths,
  });

  const breadcrumbItems = breadcrumbs.map(({match, breadcrumb}) => (
    <AntBreadcrumb.Item key={match.pathname}>
      <Link to={match.pathname} state={match.route?.state}>
        {breadcrumb}
      </Link>
    </AntBreadcrumb.Item>
  ));

  return (
    <AntBreadcrumb separator={props.separator ?? "/"}>
      {breadcrumbItems}
    </AntBreadcrumb>
  );
};

export default Breadcrumb;
