import { useSeminarTypesQuery } from "api/queries/seminarTypes";
import { Button, Table } from "components/styleguide";
import { ForumSeminarTypeResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getColumnSearchProps, TableColumn } from "utils/tableColumnUtils";

import LinkToReport from "../../components/LinkToReport";

interface SeminarTypesTableOptionProps {
  onSeminarTypeSelect?: (seminarType: ForumSeminarTypeResponseModel) => void;
}

const SeminarTypesTableOption = ({ onSeminarTypeSelect }: SeminarTypesTableOptionProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [codeSearch, setCodeSearch] = useState<string>();
  const [typeSearch, setTypeSearch] = useState<string>();

  const {
    response: { isLoading, data: { data: seminars = [] } = {} } = {},
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useSeminarTypesQuery,
    {},
    {
      forumId: toNumber(id),
      code: codeSearch,
      type: typeSearch,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 50, 100], showSizeChange: false },
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSearch = (selectedKeys: any, confirm: () => void, dataIndex: string, searchSet: string) => {
    switch (searchSet) {
      case "code": {
        setCodeSearch(selectedKeys[0] as string);
        break;
      }
      case "type": {
        setTypeSearch(selectedKeys[0] as string);
        break;
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters: () => void, dataIndex: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "code": {
        setCodeSearch(undefined);
        break;
      }
      case "type": {
        setTypeSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = [
    new TableColumn(t("Seminar Type"), "code", {
      width: "15%",
      sorter: true,
      ...getColumnSearchProps("code", handleSearch, handleReset, "code", null),
    }),
    new TableColumn(t("Name"), "type", {
      sorter: true,
      ...getColumnSearchProps("type", handleSearch, handleReset, "type", null),
    }),
    new TableColumn("", "id", {
      width: "10%",
      render: (seminarTypeId: number, seminarType: ForumSeminarTypeResponseModel) =>
        onSeminarTypeSelect ? (
          <Button onClick={() => onSeminarTypeSelect(seminarType)}>{t("Select").toString()}</Button>
        ) : (
          <LinkToReport parameters={{ ForumSeminarTypeId: seminarTypeId }} />
        ),
    }),
  ];
  return (
    <Table
      columns={columns}
      dataSource={seminars}
      loading={isLoading}
      rowKey="id"
      pagination={pagination}
      onChange={handleSort}
    />
  );
};

export default SeminarTypesTableOption;
