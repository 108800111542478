import { useImportBedroomsMutation } from "api/mutations/fileImport";
import VenueLayout from "layouts/VenueLayout";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { useCreateBuildingMutation, useUpdateBuildingMutation } from "../../api/mutations/buildings";
import { useGetBuildingsQuery } from "../../api/queries/buildings";
import { useGetVenueQuery } from "../../api/queries/venues";

const SystemVenueLayout = () => {
  const { venueId } = useParams();
  const [buildingsData, setBuildingsData] = useState(null);

  const { data: { data: venue = {} } = {} } = useGetVenueQuery({ venueId: toNumber(venueId) });

  const createBuildingMutation = useCreateBuildingMutation();
  const updateBuildingMutation = useUpdateBuildingMutation();
  const importBedroomsMutation = useImportBedroomsMutation();

  const getBuildingsQuery = useGetBuildingsQuery(
    { venueId: toNumber(venueId) },
    {
      onSuccess: ({ data }) => {
        setBuildingsData(data);
      },
    },
  );

  return (
    <VenueLayout
      venue={venue}
      buildingsData={buildingsData}
      createBuildingMutation={createBuildingMutation}
      updateBuildingMutation={updateBuildingMutation}
      importBedroomsMutation={importBedroomsMutation}
      isBuildingsLoading={getBuildingsQuery.isLoading}
    />
  );
};

export default SystemVenueLayout;
