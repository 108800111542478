import { TimeSlotType as ApiType } from "generated/api";

export type TimeslotType = {
  [ApiType.AfternoonBusinessHours]: "Afternoon Business Hours";
  [ApiType.AllMeetAllOutsideBh]: "All Meet All - Outside BH";
  [ApiType.Breakfast]: "Breakfast";
  [ApiType.Dinner]: "Dinner";
  [ApiType.Lunch]: "Lunch";
  [ApiType.MorningBusinessHours]: "Morning Business Hours";
  [ApiType.Conference]: "Conference";
};

const TimeSlotTypes: TimeslotType = {
  [ApiType.AfternoonBusinessHours]: "Afternoon Business Hours",
  [ApiType.AllMeetAllOutsideBh]: "All Meet All - Outside BH",
  [ApiType.Breakfast]: "Breakfast",
  [ApiType.Dinner]: "Dinner",
  [ApiType.Lunch]: "Lunch",
  [ApiType.MorningBusinessHours]: "Morning Business Hours",
  [ApiType.Conference]: "Conference",
} as const;

export default TimeSlotTypes;
