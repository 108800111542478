import { useQueryClient } from "@tanstack/react-query";
import { useCreateForumDocumentDefinitionSectionMutation } from "api/mutations/forumDocumentDefinition";
import { useDocumentDefinitionStandardSectionsQuery } from "api/queries/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import { Button, Space, Table } from "components/styleguide";
import { DocumentDefinitionSectionTypes, ForumDocumentDefinitionSectionCreateModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";
import { buildColumn } from "utils/columnUtils";

import { TabsTableProps } from "../index";

const StandardSectionsTypesTable: React.FC<TabsTableProps> = ({ forumId, documentDefinitionType }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: { data: documentStandardSections = [] } = {} } = useDocumentDefinitionStandardSectionsQuery({});

  const createForumDocumentDefinitionSectionMutation = useCreateForumDocumentDefinitionSectionMutation();

  const onInsertPageSubmit = ({ id }: { id: number }) => {
    const forumDocumentDefinitionSectionCreateModel: ForumDocumentDefinitionSectionCreateModel = {
      documentDefinitionType,
      sectionType: DocumentDefinitionSectionTypes.StandardSection,
      documentDefinitionStandardSectionId: id,
    };

    createForumDocumentDefinitionSectionMutation.mutate(
      { forumId, forumDocumentDefinitionSectionCreateModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS]);
        },
      },
    );
  };

  const columns = [
    buildColumn(t("Section Name"), "name", {
      render: (name: string) => t(`documentDefinitionStandardSections.${name}`),
    }),
    buildColumn(t("Section Description"), "description", {
      render: (description: string) => t(description),
    }),
    buildColumn("", "id", {
      width: "20%",
      render: (id: number, record: { id: number }) => (
        <Button onClick={() => onInsertPageSubmit(record)}>{t("Select")}</Button>
      ),
    }),
  ];

  return (
    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
      <Table
        bordered
        data-testid="standard-sections-types-Table"
        dataSource={documentStandardSections}
        columns={columns}
      />
    </Space>
  );
};

export default StandardSectionsTypesTable;
