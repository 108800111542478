import { ForumAttendeeSessionFeedbackApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeSessionFeedbackApi);

const ForumAttendeeSessionFeedback = {
  get: api.forumForumIdAttendeesAttendeeIdSessionFeedbackGet,
  put: api.forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdPut,
  delete: api.forumForumIdAttendeesAttendeeIdSessionFeedbackFeedbackIdDelete,
};

export default ForumAttendeeSessionFeedback;
