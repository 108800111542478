import React, { useMemo, useState } from "react";

import { useSystemQuestionnaires } from "../../../api/queries/questionnaires";
import { Typography } from "../../../components/styleguide";
import { useModernQueryWithPaginationAndOrder } from "../../../hooks";
import SystemQuestionnairesTable from "./SystemQuestionnariesTable";

import styles from "./styles.module.less";

const QuestionnaireImportPage = () => {
  const [nameSearch, setNameSearch] = useState(null);
  const [codeSearch, setCodeSearch] = useState(null);

  const {
    response: query,
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useSystemQuestionnaires,
    {},
    {
      title: nameSearch || undefined,
      forumCode: codeSearch || undefined,
    },
    { defaultPageSize: 10, pageSizeOptions: [10, 20, 50] },
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    switch (dataIndex) {
      case "title": {
        setNameSearch(selectedKeys[0]);
        break;
      }
      case "forumCode": {
        setCodeSearch(selectedKeys[0]);
        break;
      }
    }
    confirm();
  };

  const handleReset = (clearFilters, dataIndex, _, confirm) => {
    switch (dataIndex) {
      case "title": {
        setNameSearch(null);
        break;
      }
      case "forumCode": {
        setCodeSearch(null);
        break;
      }
    }

    clearFilters();
    confirm();
  };

  const items = useMemo(() => {
    return query.data?.data?.items ?? [];
  }, [query.data]);

  return (
    <div className={styles.container}>
      <Typography.Title level={4}>A library of questions and sections for re-use across projects</Typography.Title>
      <SystemQuestionnairesTable
        items={items}
        loading={query.isLoading}
        pagination={pagination}
        onChange={handleSort}
        handleReset={handleReset}
        handleSearch={handleSearch}
      />
    </div>
  );
};

export default QuestionnaireImportPage;
