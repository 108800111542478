import { useQuery } from "@tanstack/react-query";

import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import api from "../../index";
import KEYS from "../keys";

const GET_POSSIBLE_SPEAKERS_QUERY = "get_possible_speakers_query";
const GET_ASSIGNED_SPEAKERS_QUERY = "get_assigned_speakers_query";

const keys = {
  getPossibleSpeakers: (forumId, forumSeminarId) => [GET_POSSIBLE_SPEAKERS_QUERY, forumId, forumSeminarId],
  getAssignedSpeakers: (forumId, forumSeminarId) => [GET_ASSIGNED_SPEAKERS_QUERY, forumId, forumSeminarId],
};

const useSeminarsQuery = getWrappedUseQuery(KEYS.GET_SEMINARS, apiClient.ForumSeminars.getAll);

const usePossibleSpeakersQuery = ({ forumId, forumSeminarId }, options = {}) =>
  useQuery(keys.getPossibleSpeakers(forumId, forumSeminarId), () =>
    api.get(`/forums/${forumId}/seminars/${forumSeminarId}/possible-speakers`),
  );

const useAssignedSpeakersQuery = ({ forumId, forumSeminarId }, options = {}) =>
  useQuery(
    keys.getAssignedSpeakers(forumId, forumSeminarId),
    () => api.get(`/forums/${forumId}/seminars/${forumSeminarId}/assigned-speakers`),
    options,
  );

const useSeminarByIdQuery = getWrappedUseQuery(KEYS.GET_FORUM_SEMINAR_BY_ID, apiClient.ForumSeminars.details.get);
const useSeminarsWithSessionsQuery = getWrappedUseQuery(
  KEYS.GET_FORUM_SEMINARS_WITH_SESSIONS,
  apiClient.ForumSeminars.withSessions.get,
);

const useSeminarsAllSessionsQuery = getWrappedUseQuery(KEYS.GET_ALL_SESSIONS, apiClient.ForumSeminars.sessions.getAll);

export {
  GET_ASSIGNED_SPEAKERS_QUERY,
  GET_POSSIBLE_SPEAKERS_QUERY,
  useAssignedSpeakersQuery,
  usePossibleSpeakersQuery,
  useSeminarByIdQuery,
  useSeminarsAllSessionsQuery,
  useSeminarsQuery,
  useSeminarsWithSessionsQuery,
};
