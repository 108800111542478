import { useQueryClient } from "@tanstack/react-query";
import { useBookAttendeeToMeetingMutation } from "api/mutations/attendeeOnSiteMeetings";
import {
  useOnSiteAvailableAttendeesQuery,
  useOnSiteAvailablePreferencesQuery,
} from "api/queries/attendeeOnSiteMeetings";
import KEYS from "api/queries/keys";
import { useAllTimeslotsSessionsQuery } from "api/queries/timeslotSession";
import { Button, Select, Space, Table, Typography } from "components/styleguide";
import OnSiteErrorTypes from "error-handling/onSiteErrorTypes";
import { TimeSlotSessionResponseModel, TimeSlotType } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

import { getAvailableAttendeesColumns, getAvailablePreferencesColumns } from "./helpers";

import styles from "./styles.module.less";

const timeFormat = "HH:mm A";

const FindMeetings = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const forumId = toNumber(useParams().id);

  const [timeSlotSessionId, setTimeSlotSessionId] = useState<number | undefined>();
  const [executiveAttendeeId, setExecutiveAttendeeId] = useState<number | undefined>();
  const [attendeeToMeetWithId, setAttendeeToMeetWithId] = useState<number | undefined>();

  const { isLoading: isSessionsLoading, data: { data: sessions = [] } = {} } = useAllTimeslotsSessionsQuery({
    forumId,
    timeSlotTypes: Object.keys(TimeSlotType).filter((key) => key !== TimeSlotType.Conference) as TimeSlotType[],
  });

  const {
    isLoading: isAvailableAttendeesLoading,
    fetchStatus: attendeesFetchStatus,
    data: { data: availableAttendees = [] } = {},
  } = useOnSiteAvailableAttendeesQuery(
    { forumId, timeSlotSessionId: timeSlotSessionId as number },
    { enabled: !!timeSlotSessionId },
  );

  const {
    isLoading: isAvailablePreferencesLoading,
    fetchStatus: preferencesFetchStatus,
    data: { data: availablePreferences = [] } = {},
  } = useOnSiteAvailablePreferencesQuery(
    { forumId, timeSlotSessionId: timeSlotSessionId as number, executiveAttendeeId: executiveAttendeeId as number },
    { enabled: !!executiveAttendeeId },
  );

  const { mutate, isLoading: isBookLoading } = useBookAttendeeToMeetingMutation();

  const bookAttendeeToMeeting = () => {
    if (timeSlotSessionId && executiveAttendeeId && attendeeToMeetWithId) {
      mutate(
        {
          forumId,
          timeSlotSessionId,
          executiveAttendeeId,
          meetingAttendeeRequestModel: { attendeeToMeetWithId },
        },
        {
          onSuccess: ({ data }) => {
            if (data?.exportedMeetingsLink) {
              downloadFileByURL(data.exportedMeetingsLink);
              setExecutiveAttendeeId(undefined);
            }
          },
          onError: ({ response }: any) => {
            const errorType = response.data?.type;

            switch (errorType) {
              case OnSiteErrorTypes.InsufficientCapacity: {
                return toast.error("The table doesn't have enough seats");
              }
              case OnSiteErrorTypes.InvalidEntityState: {
                return toast.error(
                  t("Selected attendees should have the Facility and Table specified in Meeting Locations"),
                );
              }
              case OnSiteErrorTypes.MeetingAttendeeUnavailable: {
                const attendeeType =
                  response.data.meta.ObjectType === "AttendeeToMeetWith" ? "attendee to meet with" : "host attendee";

                return toast.error(t("Please make sure the {{attendeeType}} is available", { attendeeType }));
              }
              default:
                return toast.error("Something went wrong");
            }
          },
          onSettled: () => {
            queryClient.resetQueries([KEYS.GET_ON_SITE_AVAILABLE_ATTENDEES]);
            queryClient.resetQueries([KEYS.GET_ON_SITE_AVAILABLE_PREFERENCES]);
          },
        },
      );
    }
  };

  const onSessionSelect = (value: number) => {
    setTimeSlotSessionId(value);
    setExecutiveAttendeeId(undefined);
    setAttendeeToMeetWithId(undefined);
  };

  const availableAttendeesColumns = useMemo(
    () => getAvailableAttendeesColumns(executiveAttendeeId, setExecutiveAttendeeId, setAttendeeToMeetWithId),
    [executiveAttendeeId],
  );

  const availablePreferencesColumns = useMemo(
    () => getAvailablePreferencesColumns(attendeeToMeetWithId, setAttendeeToMeetWithId),
    [attendeeToMeetWithId],
  );

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      <div className={styles.filtersBox}>
        <div className={styles.filters}>
          <Select options={[{ value: "Executives", label: t("Executives") }]} value={"Executives"} />

          <Typography.Text>{t("under ratio for")}</Typography.Text>

          <Select
            style={{ minWidth: "400px" }}
            onSelect={onSessionSelect}
            options={sessions.map(({ id, timeSlotName, startTime, endTime }: TimeSlotSessionResponseModel) => ({
              value: id,
              label: `${timeSlotName} - ${moment(startTime).format(t("dateFormatWithTime"))} - ${moment(endTime).format(
                timeFormat,
              )}`,
            }))}
            loading={isSessionsLoading}
            value={timeSlotSessionId}
            allowClear
            onClear={() => setTimeSlotSessionId(undefined)}
          />
        </div>

        <Button disabled={!attendeeToMeetWithId} onClick={bookAttendeeToMeeting} loading={isBookLoading}>
          {t("Book")}
        </Button>
      </div>

      <div className={styles.tables}>
        <div className={styles.tableSection}>
          <Typography.Title level={5}>{t("Available Attendees")}</Typography.Title>
          <Table
            id="availableAttendeesTable"
            data-testid="availableAttendeesTable"
            dataSource={availableAttendees}
            columns={availableAttendeesColumns}
            pagination={false}
            bordered={true}
            rowKey="id"
            loading={isAvailableAttendeesLoading && attendeesFetchStatus !== "idle"}
          />
        </div>
        <div className={styles.tableSection}>
          <Typography.Title level={5}>{t("Available Preferences")}</Typography.Title>
          <Table
            id="availablePreferencesTable"
            data-testid="availablePreferencesTable"
            dataSource={availablePreferences}
            columns={availablePreferencesColumns}
            pagination={false}
            bordered={true}
            rowKey="id"
            loading={isAvailablePreferencesLoading && preferencesFetchStatus !== "idle"}
          />
        </div>
      </div>
    </Space>
  );
};

export default FindMeetings;
