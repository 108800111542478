import { VenueResponseModel } from "generated/api";
import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Select } from "../../../../../../../../../components/styleguide";

interface VenuesSelectProps {
  isLoading: boolean;
  venues: VenueResponseModel[];
}

const VenuesSelect = ({ isLoading, venues, ...props }: VenuesSelectProps) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();

  const countryCode = form.getFieldValue("countryCode");
  const isVirtual = form.getFieldValue("isVirtual");

  return (
    <Select
      notFoundContent={countryCode && t("forum.errors.noVenueForCountry")}
      disabled={!countryCode || isVirtual}
      placeholder={t("placeholders.select", { prop: "$t(venue)" })}
      loading={isLoading}
      {...props}
    >
      {(venues ?? [])
        .filter((venue) => {
          return venue.countryCode === countryCode;
        })
        .map((venue) => (
          <Select.Option key={venue.id} value={venue.id}>
            {venue.name}
          </Select.Option>
        ))}
    </Select>
  );
};

export default VenuesSelect;
