import { useMutation } from "@tanstack/react-query";

import getWrappedUseMutation from "../../../api/helpers/getWrappedUseMutation";
import apiClient from "../../../api-client";
import api from "../../index";

const useCreateTableMutation = (options = {}) => {
  return useMutation(
    ({ venueId, buildingId, facilityId, table }) =>
      api.post(`/venues/${venueId}/buildings/${buildingId}/facilities/${facilityId}/tables`, table),
    options,
  );
};

const useUpdateTableMutation = getWrappedUseMutation(apiClient.Table.put);

const useDeleteTableMutation = getWrappedUseMutation(apiClient.Table.delete);

const useCreateForumTableMutation = getWrappedUseMutation(apiClient.ForumTables.post);

const useUpdateForumTableMutation = getWrappedUseMutation(apiClient.ForumTables.put);

export {
  useCreateForumTableMutation,
  useCreateTableMutation,
  useDeleteTableMutation,
  useUpdateForumTableMutation,
  useUpdateTableMutation,
};
