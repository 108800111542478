import { FC, useMemo } from "react";

import { useGetForumGroupsQuery } from "../../../../../../../../../api/queries/groups";
import { Spin } from "../../../../../../../../../components/styleguide";
import { PositiveSelectionV2Item } from "../../../../../../../../../generated/api";
import withOnChange from "../../../utils/withOnChange";
import Add from "./components/Add";
import ListingTable from "./components/ListingTable";

type Value = PositiveSelectionV2Item["attendeeImageDisplayForumGroups"];
type Entry = NonNullable<Value>[number];
type ReturnType = Array<Pick<Entry, "id">>;

interface AttendeeImageDisplayForumGroupsProps {
  forumId: number;
  value?: Value;
  onChange?: (value: ReturnType | null) => void;
}

const AttendeeImageDisplayForumGroups: FC<AttendeeImageDisplayForumGroupsProps> = ({ forumId, value, onChange }) => {
  const query = useGetForumGroupsQuery({ forumId, primary: false });

  const usedGroupIds = useMemo(() => {
    return (value ?? []).reduce<Array<number>>((accumulator, entry) => {
      if (!entry.id) {
        return accumulator;
      }

      return accumulator.concat(entry.id);
    }, []);
  }, [value]);

  const handleChange = (newValue: Value) => {
    if (!newValue) {
      onChange?.(null);
      return;
    }

    const justIds = newValue.reduce<ReturnType>((accumulator, entry) => {
      if (!entry.id) {
        return accumulator;
      }

      return accumulator.concat({ id: entry.id });
    }, []);

    onChange?.(justIds);
  };

  const handleAdd = (groupId: number) => {
    const newValue = [...(value ?? []), { id: groupId }];
    handleChange(newValue);
  };

  if (query.isLoading) {
    return <Spin />;
  }

  return (
    <>
      <Add groups={query.data?.data ?? []} exclude={usedGroupIds} onAdd={handleAdd} />
      <ListingTable groups={query.data?.data ?? []} value={value ?? []} onChange={handleChange} />
    </>
  );
};

export default withOnChange(AttendeeImageDisplayForumGroups);
