import { DeleteFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteZoomMappingsSessionMappingsMutation } from "api/mutations/zoomMappings";
import KEYS from "api/queries/keys";
import { Modal, Typography } from "components/styleguide";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const { Text } = Typography;

interface DeleteMappingButtonProps {
  sessionId: number;
}

const DeleteMappingButton: FC<DeleteMappingButtonProps> = ({ sessionId }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { mutate: deleteMeetingFeedbackMutate } = useDeleteZoomMappingsSessionMappingsMutation();

  const onDelete = () => {
    deleteMeetingFeedbackMutate(
      { forumId, forumSeminarSessionId: sessionId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ZOOM_MAPPINGS_SESSION_MAPPINGS]);
          queryClient.invalidateQueries([KEYS.GET_FORUM_SEMINARS_WITH_SESSIONS]);
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const showDeletionConfirmation = () => {
    Modal.confirm({
      title: t("confirmation"),
      content: <Text>{t("Do you want to proceed with deletion of this mapping?")}</Text>,
      closable: true,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk: onDelete,
    });
  };

  return <DeleteFilled className="delete-icon" onClick={showDeletionConfirmation} />;
};

export default DeleteMappingButton;
