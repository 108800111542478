import { trimEnd } from "lodash";

import api from "../../api";
import { Configuration } from "../../generated/api";
import { BaseAPI } from "../../generated/api/base";

const BASE_PATH = trimEnd(process.env.REACT_APP_API_URL, "/");

type BaseApiParams = ConstructorParameters<typeof BaseAPI>;
const BASE_CONSTRUCTOR_PARAMS: BaseApiParams = [new Configuration({ basePath: BASE_PATH }), BASE_PATH, api];

export const initAPIClient = <T extends BaseAPI>(Client: new (...args: BaseApiParams) => T): T => {
  const client = new Client(...BASE_CONSTRUCTOR_PARAMS);

  // We do need typecasting here, because typescript thinks
  // that result of Object.keys() is string[], but it's actually Array<keyof T>
  //
  // Client is a class, and we need to bind the methods to the instance to not lose `this`.
  const methods = Object.getOwnPropertyNames(Client.prototype) as Array<keyof T>;
  methods.forEach((key) => {
    if (typeof client[key] === "function") {
      // TS works quite weird here. So we need to typecast to Function `client[key]`
      // to use `.bind()` method.
      // eslint-disable-next-line @typescript-eslint/ban-types
      client[key] = (client[key] as Function).bind(client);
    }
  });

  return client;
};
