class EnumBuilder {
  constructor() {
    this._map = new Map();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _map: Map<any, any>;

  get entries() {
    return Array.from(this._map);
  }

  get list() {
    return this.entries.map(([value, label]) => ({ value, label }));
  }

  get map() {
    return this.entries.reduce((accumulator, [value, label]) => {
      return {
        ...accumulator,
        [value]: label,
      };
    }, {});
  }

  get values() {
    return Array.from(this._map.keys());
  }

  add(value: string | number, label: string): EnumBuilder {
    this._map.set(value, label);
    return this;
  }

  build() {
    const internalMap = this._map;
    const map = this.map;
    const list = this.list;
    const values = this.values;

    return class {
      value: string | undefined;

      static MAP = map;

      static LIST = list;

      static VALUES = values;

      constructor(value: string | undefined) {
        this.value = value;
      }

      toString(): string | undefined {
        return internalMap.get(this.value);
      }
    };
  }
}

export default EnumBuilder;
