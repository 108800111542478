/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeSessionMeetingLocationRequestModel } from '../apiModels';
// @ts-ignore
import { AttendeeStatus } from '../apiModels';
// @ts-ignore
import { Em2ExceptionResponseObject } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseAttendeeSessionMeetingLocationModel } from '../apiModels';
// @ts-ignore
import { PageResponseMeetingLocationResponseModel } from '../apiModels';
/**
 * ForumAttendeeSessionMeetingLocationApi - axios parameter creator
 * @export
 */
export const ForumAttendeeSessionMeetingLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets Session meeting Locations configured for an Attendee   Based on Default meeting locations for an Attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet: async (forumId: number, attendeeId: number, orderBy?: string, orderDir?: string, pageSize?: number, pageNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet', 'attendeeId', attendeeId)
            const localVarPath = `/forums/{forumId}/attendees/{attendeeId}/session-meeting-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} timeSlotId 
         * @param {AttendeeSessionMeetingLocationRequestModel} [attendeeSessionMeetingLocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut: async (forumId: number, attendeeId: number, timeSlotId: number, attendeeSessionMeetingLocationRequestModel?: AttendeeSessionMeetingLocationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'timeSlotId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut', 'timeSlotId', timeSlotId)
            const localVarPath = `/forums/{forumId}/attendees/{attendeeId}/session-meeting-locations/{timeSlotId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"timeSlotId"}}`, encodeURIComponent(String(timeSlotId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeSessionMeetingLocationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets session locations for attendees, filtered by group and time slot
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {number} [timeSlotId] 
         * @param {Array<number>} [forumGroupIds] Optional; provide null value in order to get the Attendees from all ForumGroups
         * @param {Array<number>} [forumFacilityIds] 
         * @param {string} [forumAttendeeName] 
         * @param {Array<string>} [forumAttendeeParticipationCodes] 
         * @param {string} [forumCompanyName] 
         * @param {Array<AttendeeStatus>} [attendeeStatuses] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesSessionMeetingLocationsGet: async (forumId: number, orderBy?: string, timeSlotId?: number, forumGroupIds?: Array<number>, forumFacilityIds?: Array<number>, forumAttendeeName?: string, forumAttendeeParticipationCodes?: Array<string>, forumCompanyName?: string, attendeeStatuses?: Array<AttendeeStatus>, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdAttendeesSessionMeetingLocationsGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/attendees/session-meeting-locations`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (timeSlotId !== undefined) {
                localVarQueryParameter['TimeSlotId'] = timeSlotId;
            }

            if (forumGroupIds) {
                localVarQueryParameter['ForumGroupIds'] = forumGroupIds;
            }

            if (forumFacilityIds) {
                localVarQueryParameter['ForumFacilityIds'] = forumFacilityIds;
            }

            if (forumAttendeeName !== undefined) {
                localVarQueryParameter['ForumAttendeeName'] = forumAttendeeName;
            }

            if (forumAttendeeParticipationCodes) {
                localVarQueryParameter['ForumAttendeeParticipationCodes'] = forumAttendeeParticipationCodes;
            }

            if (forumCompanyName !== undefined) {
                localVarQueryParameter['ForumCompanyName'] = forumCompanyName;
            }

            if (attendeeStatuses) {
                localVarQueryParameter['AttendeeStatuses'] = attendeeStatuses;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeSessionMeetingLocationApi - functional programming interface
 * @export
 */
export const ForumAttendeeSessionMeetingLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeSessionMeetingLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets Session meeting Locations configured for an Attendee   Based on Default meeting locations for an Attendee
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet(forumId: number, attendeeId: number, orderBy?: string, orderDir?: string, pageSize?: number, pageNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseAttendeeSessionMeetingLocationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet(forumId, attendeeId, orderBy, orderDir, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} timeSlotId 
         * @param {AttendeeSessionMeetingLocationRequestModel} [attendeeSessionMeetingLocationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut(forumId: number, attendeeId: number, timeSlotId: number, attendeeSessionMeetingLocationRequestModel?: AttendeeSessionMeetingLocationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut(forumId, attendeeId, timeSlotId, attendeeSessionMeetingLocationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets session locations for attendees, filtered by group and time slot
         * @param {number} forumId 
         * @param {string} [orderBy] 
         * @param {number} [timeSlotId] 
         * @param {Array<number>} [forumGroupIds] Optional; provide null value in order to get the Attendees from all ForumGroups
         * @param {Array<number>} [forumFacilityIds] 
         * @param {string} [forumAttendeeName] 
         * @param {Array<string>} [forumAttendeeParticipationCodes] 
         * @param {string} [forumCompanyName] 
         * @param {Array<AttendeeStatus>} [attendeeStatuses] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdAttendeesSessionMeetingLocationsGet(forumId: number, orderBy?: string, timeSlotId?: number, forumGroupIds?: Array<number>, forumFacilityIds?: Array<number>, forumAttendeeName?: string, forumAttendeeParticipationCodes?: Array<string>, forumCompanyName?: string, attendeeStatuses?: Array<AttendeeStatus>, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseMeetingLocationResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdAttendeesSessionMeetingLocationsGet(forumId, orderBy, timeSlotId, forumGroupIds, forumFacilityIds, forumAttendeeName, forumAttendeeParticipationCodes, forumCompanyName, attendeeStatuses, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeSessionMeetingLocationApi - factory interface
 * @export
 */
export const ForumAttendeeSessionMeetingLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeSessionMeetingLocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets Session meeting Locations configured for an Attendee   Based on Default meeting locations for an Attendee
         * @param {ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet(requestParameters: ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseAttendeeSessionMeetingLocationModel> {
            return localVarFp.forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut(requestParameters: ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.timeSlotId, requestParameters.attendeeSessionMeetingLocationRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets session locations for attendees, filtered by group and time slot
         * @param {ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdAttendeesSessionMeetingLocationsGet(requestParameters: ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseMeetingLocationResponseModel> {
            return localVarFp.forumsForumIdAttendeesSessionMeetingLocationsGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.timeSlotId, requestParameters.forumGroupIds, requestParameters.forumFacilityIds, requestParameters.forumAttendeeName, requestParameters.forumAttendeeParticipationCodes, requestParameters.forumCompanyName, requestParameters.attendeeStatuses, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet operation in ForumAttendeeSessionMeetingLocationApi.
 * @export
 * @interface ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGetRequest
 */
export interface ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet
     */
    readonly orderDir?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet
     */
    readonly pageNumber?: number
}

/**
 * Request parameters for forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut operation in ForumAttendeeSessionMeetingLocationApi.
 * @export
 * @interface ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPutRequest
 */
export interface ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut
     */
    readonly timeSlotId: number

    /**
     * 
     * @type {AttendeeSessionMeetingLocationRequestModel}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut
     */
    readonly attendeeSessionMeetingLocationRequestModel?: AttendeeSessionMeetingLocationRequestModel
}

/**
 * Request parameters for forumsForumIdAttendeesSessionMeetingLocationsGet operation in ForumAttendeeSessionMeetingLocationApi.
 * @export
 * @interface ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGetRequest
 */
export interface ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly timeSlotId?: number

    /**
     * Optional; provide null value in order to get the Attendees from all ForumGroups
     * @type {Array<number>}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly forumFacilityIds?: Array<number>

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly forumAttendeeName?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly forumAttendeeParticipationCodes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly forumCompanyName?: string

    /**
     * 
     * @type {Array<AttendeeStatus>}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly attendeeStatuses?: Array<AttendeeStatus>

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGet
     */
    readonly orderDir?: string
}

/**
 * ForumAttendeeSessionMeetingLocationApi - object-oriented interface
 * @export
 * @class ForumAttendeeSessionMeetingLocationApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeSessionMeetingLocationApi extends BaseAPI {
    /**
     * 
     * @summary Gets Session meeting Locations configured for an Attendee   Based on Default meeting locations for an Attendee
     * @param {ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeSessionMeetingLocationApi
     */
    public forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet(requestParameters: ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeSessionMeetingLocationApiFp(this.configuration).forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.orderBy, requestParameters.orderDir, requestParameters.pageSize, requestParameters.pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeSessionMeetingLocationApi
     */
    public forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut(requestParameters: ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeSessionMeetingLocationApiFp(this.configuration).forumsForumIdAttendeesAttendeeIdSessionMeetingLocationsTimeSlotIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.timeSlotId, requestParameters.attendeeSessionMeetingLocationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets session locations for attendees, filtered by group and time slot
     * @param {ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeSessionMeetingLocationApi
     */
    public forumsForumIdAttendeesSessionMeetingLocationsGet(requestParameters: ForumAttendeeSessionMeetingLocationApiForumsForumIdAttendeesSessionMeetingLocationsGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeSessionMeetingLocationApiFp(this.configuration).forumsForumIdAttendeesSessionMeetingLocationsGet(requestParameters.forumId, requestParameters.orderBy, requestParameters.timeSlotId, requestParameters.forumGroupIds, requestParameters.forumFacilityIds, requestParameters.forumAttendeeName, requestParameters.forumAttendeeParticipationCodes, requestParameters.forumCompanyName, requestParameters.attendeeStatuses, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }
}

