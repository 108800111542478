import { FC } from "react";

import ForumCompanyAttendeesTable from "./ForumCompanyAttendeesTable";

interface ForumCompanyAttendeesProps {
  forumId: number;
  forumCompanyId: number;
}

const ForumCompanyAttendees: FC<ForumCompanyAttendeesProps> = ({ forumId, forumCompanyId }) => {
  return <ForumCompanyAttendeesTable forumId={forumId} forumCompanyId={forumCompanyId} />;
};

export default ForumCompanyAttendees;
