import { ForumItineraryApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumItineraryApi);

const ForumItinerary = {
  forumItineraries: {
    getAll: api.forumForumIdItineraryGet,
  },
  meetings: {
    export: api.forumForumIdItineraryMeetingsExportGet,
    facilities: {
      get: api.forumForumIdItineraryMeetingsFacilitiesGet,
    },
    timeslotSessions: {
      get: api.forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsGet,
    },
    attendees: {
      get: api.forumForumIdItineraryMeetingsFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet,
    },
  },
  conferences: {
    facilities: {
      get: api.forumForumIdItineraryConferencesFacilitiesGet,
    },
    timeslotSessions: {
      get: api.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet,
    },
    attendees: {
      get: api.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet,
    },
  },
  timeslotSessions: {
    get: api.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsGet,
  },
  attendees: {
    get: api.forumForumIdItineraryConferencesFacilitiesForumMeetingFacilityIdTimeslotSessionsTimeSlotSessionIdAttendeesGet,
  },
};

export default ForumItinerary;
