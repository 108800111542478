/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookletProfileSetupRequestModel } from '../apiModels';
// @ts-ignore
import { BookletProfileSetupResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ProfileSetupType } from '../apiModels';
/**
 * ItineraryBookletsProfileSetupApi - axios parameter creator
 * @export
 */
export const ItineraryBookletsProfileSetupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {ProfileSetupType} [profileSetupType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBookletProfileSetupGet: async (forumId: number, profileSetupType?: ProfileSetupType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdBookletProfileSetupGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/booklet-profile-setup`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (profileSetupType !== undefined) {
                localVarQueryParameter['profileSetupType'] = profileSetupType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {BookletProfileSetupRequestModel} [bookletProfileSetupRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBookletProfileSetupPut: async (forumId: number, bookletProfileSetupRequestModel?: BookletProfileSetupRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdBookletProfileSetupPut', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/booklet-profile-setup`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookletProfileSetupRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItineraryBookletsProfileSetupApi - functional programming interface
 * @export
 */
export const ItineraryBookletsProfileSetupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItineraryBookletsProfileSetupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {ProfileSetupType} [profileSetupType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdBookletProfileSetupGet(forumId: number, profileSetupType?: ProfileSetupType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookletProfileSetupResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdBookletProfileSetupGet(forumId, profileSetupType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {BookletProfileSetupRequestModel} [bookletProfileSetupRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdBookletProfileSetupPut(forumId: number, bookletProfileSetupRequestModel?: BookletProfileSetupRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdBookletProfileSetupPut(forumId, bookletProfileSetupRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItineraryBookletsProfileSetupApi - factory interface
 * @export
 */
export const ItineraryBookletsProfileSetupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItineraryBookletsProfileSetupApiFp(configuration)
    return {
        /**
         * 
         * @param {ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBookletProfileSetupGet(requestParameters: ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BookletProfileSetupResponseModel>> {
            return localVarFp.forumsForumIdBookletProfileSetupGet(requestParameters.forumId, requestParameters.profileSetupType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBookletProfileSetupPut(requestParameters: ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdBookletProfileSetupPut(requestParameters.forumId, requestParameters.bookletProfileSetupRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdBookletProfileSetupGet operation in ItineraryBookletsProfileSetupApi.
 * @export
 * @interface ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGetRequest
 */
export interface ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGet
     */
    readonly forumId: number

    /**
     * 
     * @type {ProfileSetupType}
     * @memberof ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGet
     */
    readonly profileSetupType?: ProfileSetupType
}

/**
 * Request parameters for forumsForumIdBookletProfileSetupPut operation in ItineraryBookletsProfileSetupApi.
 * @export
 * @interface ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPutRequest
 */
export interface ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPut
     */
    readonly forumId: number

    /**
     * 
     * @type {BookletProfileSetupRequestModel}
     * @memberof ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPut
     */
    readonly bookletProfileSetupRequestModel?: BookletProfileSetupRequestModel
}

/**
 * ItineraryBookletsProfileSetupApi - object-oriented interface
 * @export
 * @class ItineraryBookletsProfileSetupApi
 * @extends {BaseAPI}
 */
export class ItineraryBookletsProfileSetupApi extends BaseAPI {
    /**
     * 
     * @param {ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsProfileSetupApi
     */
    public forumsForumIdBookletProfileSetupGet(requestParameters: ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupGetRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsProfileSetupApiFp(this.configuration).forumsForumIdBookletProfileSetupGet(requestParameters.forumId, requestParameters.profileSetupType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItineraryBookletsProfileSetupApi
     */
    public forumsForumIdBookletProfileSetupPut(requestParameters: ItineraryBookletsProfileSetupApiForumsForumIdBookletProfileSetupPutRequest, options?: AxiosRequestConfig) {
        return ItineraryBookletsProfileSetupApiFp(this.configuration).forumsForumIdBookletProfileSetupPut(requestParameters.forumId, requestParameters.bookletProfileSetupRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

