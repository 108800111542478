import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import TableRowActionButtons from "components/TableRowActionButtons";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { tablesKey } from "../../../../../api/queries/tables";
import { Button, Form, Layout, Modal, Table } from "../../../../../components/styleguide";
import { AdminTypes } from "../../../../../enums/common";
import TableType from "../../../../../enums/TableType";
import VenueContext from "../../../../../pages/venueContext";
import EditTable from "./EditTable";

import styles from "./styles.module.less";

const { Header, Content } = Layout;
const { confirm } = Modal;

export default function Tables({ ui, facility }) {
  const { t } = useTranslation();
  const [isAddTableVisible, setIsTableVisible] = useState(false);
  const [tables, setTables] = useState([]);
  const [tableTypeFilter, setTableTypeFilter] = useState();
  const [activeTable, setActiveTable] = useState(null);
  const [form] = Form.useForm();

  const showAddTable = () => {
    form.resetFields();
    setIsTableVisible(true);
  };
  const hideAddTable = () => {
    setActiveTable(null);
    setIsTableVisible(false);
  };

  const queryClient = useQueryClient();

  const venueActions = useContext(VenueContext);

  const { useTablesQuery, deleteTableMutate } = venueActions;

  const tablesQuery = useTablesQuery(
    { facilityId: facility?.id, type: tableTypeFilter },
    {
      onSuccess: ({ data }) => {
        setTables(data);
      },
      enabled: !!facility?.id,
    },
  );

  const deleteTable = (tableId) => {
    confirm({
      title: t("confirmation"),
      content: t("Are you sure you want to delete this table?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      confirmLoading: deleteTableMutate.isLoading,
      cancelText: t("no"),
      onOk: async () => {
        await deleteTableMutate(
          { tableId },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(tablesKey);
            },
          },
        );
      },
    });
  };

  const editTable = (table) => {
    form.resetFields();
    setActiveTable(table);
    showAddTable();
  };

  const columns = [
    {
      title: t("Table Number"),
      dataIndex: "tableNumber",
    },
    {
      title: t("Table Type"),
      dataIndex: "tableType",
      render: (tableType) => TableType.toDisplayName(tableType),
      filters: Object.values(TableType).map((value) => ({
        text: TableType.toDisplayName(value),
        value,
      })),
      filterMultiple: false,
      onFilter: (value) => {
        setTableTypeFilter(value);
        return true;
      },
    },
    {
      title: t("Number of Seats"),
      dataIndex: "numberOfSeats",
    },
    {
      title: "",
      dataIndex: "id",
      render: (id, record) => (
        <TableRowActionButtons onEdit={() => editTable(record)} onDelete={() => deleteTable(id)} />
      ),
    },
  ];

  const handleChange = (_, filters) => {
    setTableTypeFilter(filters[0]);
  };

  return (
    <div>
      <Layout>
        <Header className={styles.header}>
          <Button onClick={showAddTable}>{t("Add Table")}</Button>
        </Header>
        <Content id="tablesContent">
          <EditTable
            ui={ui}
            visible={isAddTableVisible}
            onClose={hideAddTable}
            facility={facility}
            activeTable={activeTable}
            tablesLoading={tablesQuery.isLoading}
            form={form}
          />
          <Table
            loading={tablesQuery.isLoading || deleteTableMutate.isLoading}
            columns={columns}
            onChange={handleChange}
            dataSource={tables}
            rowKey="id"
          />
        </Content>
      </Layout>
    </div>
  );
}

Tables.propTypes = {
  ui: PropTypes.oneOf(AdminTypes),
  facility: PropTypes.any,
  useTablesQuery: PropTypes.func,
  deleteTableMutate: PropTypes.func,
};
