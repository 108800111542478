import { DownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { ColProps, Divider, MenuProps } from "antd";
import {
  useAttendeeReplaceOrCancelMutation,
  useMakeAttendeeAttendingMutation,
  useUpdateAttendeeMutation,
} from "api/mutations/attendees";
import { useAttendeeAttendaceDetailsQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import { Button, Col, Dropdown, Form, Modal, Row, Space, Spin } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import { AttendeeAttendanceDetailsModel, ForumAttendeeResponseModel } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Replace from "./Replace";

import styles from "./styles.module.less";

interface AttendeeAttendanceDetailsProps {
  attendee: ForumAttendeeResponseModel | null;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  companyName: string | undefined | null;
}

const AttendeeAttendanceDetails: FC<AttendeeAttendanceDetailsProps> = ({
  attendee,
  isEditing,
  companyName,
  setIsEditing,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: forumId, attendeeId } = useParams();
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const [attendanceDetails, setAttendanceDetails] = useState<AttendeeAttendanceDetailsModel>();
  const [isReplaceVisible, setIsReplaceVisible] = useState(false);

  const updateAttendeeMutation = useUpdateAttendeeMutation();
  const replaceOrCancelMutation = useAttendeeReplaceOrCancelMutation();
  const makeAttendingMutation = useMakeAttendeeAttendingMutation();

  const { isLoading } = useAttendeeAttendaceDetailsQuery(
    {
      forumId: toNumber(forumId),
      attendeeId: toNumber(attendeeId),
    },
    {
      enabled: !!companyName && inView,
      onSuccess: ({ data }) => {
        setAttendanceDetails(data);
      },
    },
  );

  const isAttending = attendanceDetails?.attendStatus === AttendeeStatus.Attending;
  const isCancelled =
    attendanceDetails?.attendStatus === AttendeeStatus.Cancelled ||
    attendanceDetails?.attendStatus === AttendeeStatus.CancelledWithFee;
  const isReplaced = attendanceDetails?.attendStatus === AttendeeStatus.Replaced;
  const isReplacement = attendanceDetails?.attendStatus === AttendeeStatus.AttendingReplacement;

  const showReplace = () => {
    setIsReplaceVisible(true);
  };

  const hideReplace = () => {
    setIsReplaceVisible(false);
  };

  const submit = (values: AttendeeAttendanceDetailsProps) => {
    const updatedModel = { ...attendanceDetails, ...values };
    // eslint-disable-next-line
    updateAttendeeMutation.mutate({ forumId, attendeeId: attendanceDetails?.id, attendee: updatedModel } as any, {
      onSuccess: () => {
        setAttendanceDetails(updatedModel);
        setIsEditing(false);
      },
    });
  };

  const cancelEdit = () => {
    form.resetFields();
    setIsEditing(false);
  };

  const cancelAttendee = (key: string) => {
    Modal.confirm({
      title: t("confirmation"),
      content: t("Do you want to cancel this attendee?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: () => {
        const attendeeStatus = key === "1" ? AttendeeStatus.Cancelled : AttendeeStatus.CancelledWithFee;
        const updatedAttendee = {
          attendeeStatus,
          ...attendanceDetails,
        };
        replaceOrCancelMutation.mutate(
          {
            forumId: toNumber(forumId),
            attendeeId: toNumber(attendeeId),
            attendeeActionModel: { replacementAttendeeId: 0, attendeeStatus: attendeeStatus },
          },
          {
            onSuccess: () => {
              setAttendanceDetails(updatedAttendee);

              [
                KEYS.GET_ATTENDEE_ATTENDANCE_DETAILS,
                KEYS.GET_ATTENDEE_CONTACT_DETAILS,
                KEYS.GET_ATTENDEE_HISTORY_INFORMATION,
                KEYS.GET_ATTENDEE_ITINERARY,
                KEYS.GET_ATTENDEE_HOTEL_AND_ROOM_ALLOCATION,
                KEYS.GET_ATTENDEE_PREFERENCES_CONFERENCES,
                KEYS.GET_ATTENDEE_PREFERENCES_MEETINGS,
                KEYS.GET_ATTENDEE_PREFERENCES_BLOCKED_MEETINGS,
                KEYS.GET_ATTENDEE_PREFERENCES_CATEGORIES,
                KEYS.GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS,
                KEYS.GET_QUESTIONNAIRES_SUBMISSION,
                KEYS.GET_ATTENDEE,
              ].forEach((query) => queryClient.resetQueries([query]));
            },
          },
        );
      },
    });
  };

  const handleChangeStatusClick = async () => {
    Modal.confirm({
      title: t("confirmation"),
      content: t("Do you want to make this person attending?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: async () => {
        await makeAttendingMutation.mutateAsync(
          { forumId: toNumber(forumId), attendeeId: toNumber(attendeeId) },
          {
            onSuccess: () => {
              const updatedData = { attendStatus: AttendeeStatus.Attending, ...attendanceDetails };
              setAttendanceDetails(updatedData);
              queryClient.resetQueries([KEYS.GET_ATTENDEE_ATTENDANCE_DETAILS]);
              queryClient.resetQueries([KEYS.GET_ATTENDEE_CONTACT_DETAILS]);
              queryClient.resetQueries([KEYS.GET_ATTENDEE_HISTORY_INFORMATION]);
              queryClient.resetQueries([KEYS.GET_ATTENDEE]);
            },
            onError: () => toast.error(t("Something went wrong")),
          },
        );
      },
    });
  };

  const handleButtonClick = () => {
    cancelAttendee(AttendeeStatus.Cancelled.toString());
  };

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    cancelAttendee(e.key);
  };

  const items: MenuProps["items"] = [
    {
      label: t("attendeeStatus.Cancel with fee"),
      key: AttendeeStatus.CancelledWithFee,
      danger: true,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  if (isLoading || !attendanceDetails) {
    return (
      <div className="loader" ref={ref}>
        <Spin />
      </div>
    );
  }

  return (
    <div ref={ref}>
      {attendee && (
        <Replace
          attendee={attendee}
          visible={isReplaceVisible}
          onClose={hideReplace}
          attendanceDetails={attendanceDetails}
        />
      )}
      <Form
        onFinish={submit}
        id="contactInfoForm"
        name="contact-info"
        labelAlign="left"
        labelCol={{ justify: "left", span: 13 } as ColProps}
        wrapperCol={{ justify: "right", span: 11 } as ColProps}
        labelWrap
        form={form}
        colon={false}
        className={styles.form}
      >
        <Row gutter={80}>
          <Col span={12}>
            <Form.Item
              initialValue={attendanceDetails.attendStatus}
              label={t("Current Attend Status")}
              name={"attendStatus"}
              className={styles.formItem}
            >
              <label>
                {t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendanceDetails.attendStatus as number)}`)}
              </label>
            </Form.Item>
            <Divider />
          </Col>
          {isCancelled && (
            <Col span={12}>
              <Form.Item
                initialValue={attendanceDetails.cancelDate}
                label={t("Cancel Date")}
                name={"cancelDate"}
                className={styles.formItem}
              >
                <label>{moment(attendanceDetails.cancelDate).format("L")}</label>
              </Form.Item>
              <Divider />
            </Col>
          )}
          {isCancelled && attendanceDetails.cancelledBy && (
            <Col span={12}>
              <Form.Item
                initialValue={attendanceDetails.cancelledBy}
                label={t("Cancelled By")}
                name={"cancelledBy"}
                className={styles.formItem}
              >
                <label>{attendanceDetails.cancelledBy}</label>
              </Form.Item>
              <Divider />
            </Col>
          )}
          {isReplaced && (
            <>
              <Col span={12}>
                <Form.Item label={t("Replaced By")} name={"replaced"} className={styles.formItem}>
                  <label>{`${attendanceDetails?.replacementAttendee?.firstName} ${attendanceDetails?.replacementAttendee?.lastName}`}</label>
                </Form.Item>
                <Divider />
              </Col>
              <Col span={12}>
                <Form.Item label={t("Replaced On")} name={"replacedDate"} className={styles.formItem}>
                  <label>{moment(attendanceDetails.replacementTime).format("L")}</label>
                </Form.Item>
                <Divider />
              </Col>
            </>
          )}
          {(isReplaced || isCancelled) && (
            <Col span={24}>
              <Button className={styles.button} onClick={handleChangeStatusClick}>
                {t("Change Status")}
              </Button>
            </Col>
          )}
          {isReplacement && attendanceDetails?.replacedAttendee && (
            <Col span={12}>
              <Form.Item label={t("Replacement for")} name={"replacementFor"} className={styles.formItem}>
                <label>{`${attendanceDetails.replacedAttendee.firstName} ${attendanceDetails.replacedAttendee.lastName}`}</label>
              </Form.Item>
              <Divider />
            </Col>
          )}
        </Row>
        {(isAttending || isReplacement) && (
          <Row gutter={80}>
            <Col>
              <Space className={styles.buttonContainer} direction="horizontal">
                <Button onClick={showReplace}>{t("Replace")}</Button>
                <Dropdown.Button
                  onClick={handleButtonClick}
                  danger
                  menu={menuProps}
                  icon={<DownOutlined />}
                  loading={replaceOrCancelMutation.isLoading}
                  disabled={replaceOrCancelMutation.isLoading}
                >
                  {t("Cancel")}
                </Dropdown.Button>
              </Space>
            </Col>
          </Row>
        )}
        {isEditing && (
          <Row gutter={80}>
            <Col span={1}>
              <Button loading={isLoading || updateAttendeeMutation.isLoading} htmlType="submit" type="primary">
                {t("Save")}
              </Button>
            </Col>
            <Col span={1}>
              <Button onClick={cancelEdit}>{t("Cancel")}</Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default AttendeeAttendanceDetails;
