import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { ColumnType } from "antd/es/table";
import { FilterDropdownProps } from "antd/es/table/interface";
import { BaseOptionType } from "antd/lib/select";
import { Key } from "antd/lib/table/interface";
import ColumnSearchActionButtons from "components/ColumnSearchActionButtons";
import { DatePicker, Input, Select } from "components/styleguide";
import { t } from "i18next";
import moment from "moment";

const { Search } = Input;
const { RangePicker } = DatePicker;

type ColumnSearchProps<T> = {
	filterDropdown: ColumnType<T>["filterDropdown"];
	filterIcon: ColumnType<T>["filterIcon"];
	onFilter: ColumnType<T>["onFilter"];
};
export type FilterFunction<T> = (value?: string | number | boolean, record?: T, dataIndex?: string) => boolean;

export class TableColumn {
	public dataIndex: string;

	constructor(public title: string, public key: string, options?: Record<string, unknown>) {
		this.dataIndex = key;

		if (options) {
			Object.assign(this, options);
		}
	}
}

export type SearchFunctionType = (
	selectedKeys: string[],
	confirm: FilterDropdownProps["confirm"],
	dataIndex: string,
	searchSet: string,
) => void;

export type ResetFunction = (
	clearFilters: () => void | undefined,
	dataIndex: string,
	searchSet: string,
	confirm: FilterDropdownProps["confirm"],
) => void;

const getColumnSearchProps = function <T>(
	dataIndex: string,
	handleSearch: SearchFunctionType,
	handleReset: ResetFunction,
	searchSet: string,
	globalSearchValue?: string | null,
	searchClassName = "",
	filter: FilterFunction<T> = () => true,
): ColumnSearchProps<T> {
	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
			return (
				<div
					style={{
						padding: 8,
					}}
				>
					<Search
						placeholder={t("Search")}
						value={selectedKeys[0]}
						onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
						onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex, searchSet)}
						style={{
							marginBottom: 8,
							display: "block",
						}}
						autoFocus
					/>
					<ColumnSearchActionButtons
						handleSearch={handleSearch}
						selectedKeys={selectedKeys as string[]}
						dataIndex={dataIndex}
						searchSet={searchSet}
						handleReset={handleReset}
						clearFilters={clearFilters}
						confirm={confirm}
						triggerReset={globalSearchValue}
					/>
				</div>
			);
		},
		filterIcon: (filtered) => (
			<SearchOutlined className={filtered ? searchClassName : ""} style={{ fontSize: "14px" }} />
		),
		onFilter: (value, record) => filter(value, record, dataIndex),
	};
};

const getColumnDateRangeSearchProps = function <T>(
	dataIndex: string,
	handleSearch: SearchFunctionType,
	handleReset: ResetFunction,
	searchSet: string,
	globalSearchValue?: string | null,
	searchClassName = "",
	filter: FilterFunction<T> = () => true,
): ColumnSearchProps<T> {
	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div
				style={{
					padding: 8,
				}}
			>
				<RangePicker
					showTime={true}
					minuteStep={5}
					format={"L HH:mm"}
					value={selectedKeys[0] ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : null}
					onChange={(dates) =>
						setSelectedKeys(dates ? [moment(dates[0]).toISOString(true), moment(dates[1]).toISOString(true)] : [])
					}
					style={{
						marginBottom: 8,
						display: "flex",
						width: "350px",
					}}
					onKeyDown={(e) =>
						e.key === "Enter" ? handleSearch(selectedKeys as string[], confirm, dataIndex, searchSet) : false
					}
					autoFocus
				/>
				<ColumnSearchActionButtons
					handleSearch={handleSearch}
					selectedKeys={selectedKeys as string[]}
					dataIndex={dataIndex}
					searchSet={searchSet}
					handleReset={handleReset}
					clearFilters={clearFilters}
					confirm={confirm}
					triggerReset={globalSearchValue}
				/>
			</div>
		),
		filterIcon: (filtered) => (
			<FilterOutlined className={filtered ? searchClassName : ""} style={{ fontSize: "14px" }} />
		),
		onFilter: (value, record) => filter(value, record, dataIndex),
	};
};

const getColumnSelectFilterProps = function <T>(
	dataIndex: string,
	handleSearch: SearchFunctionType,
	handleReset: ResetFunction,
	searchSet: string,
	options: BaseOptionType[] = [],
	globalSearchValue?: string | null,
	isMultipleMode = true,
	searchClassName = "",
	filter: FilterFunction<T> = () => true,
): ColumnSearchProps<T> {
	return {
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
			<div
				style={{
					padding: 8,
				}}
			>
				<div>
					<Select
						style={{ width: "100%", marginBottom: 8, maxWidth: "400px" }}
						showArrow
						showSearch
						optionFilterProp="label"
						mode={isMultipleMode ? "multiple" : undefined}
						options={options}
						value={selectedKeys}
						placeholder={t("Select").toString()}
						onChange={(_, strings) => setSelectedKeys(strings ? ((isMultipleMode ? strings : [strings]) as Key[]) : [])}
						autoFocus
					/>
				</div>
				<ColumnSearchActionButtons
					handleSearch={handleSearch}
					selectedKeys={selectedKeys as string[]}
					dataIndex={dataIndex}
					searchSet={searchSet}
					handleReset={handleReset}
					clearFilters={clearFilters}
					confirm={confirm}
					triggerReset={globalSearchValue}
				/>
			</div>
		),
		filterIcon: (filtered) => (
			<FilterOutlined className={filtered ? searchClassName : ""} style={{ fontSize: "14px" }} />
		),
		onFilter: (value, record) => filter(value, record, dataIndex),
	};
};
export { getColumnDateRangeSearchProps, getColumnSearchProps, getColumnSelectFilterProps };
