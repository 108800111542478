import { BuildingResponseModel } from "generated/api";
import { TFunction } from "react-i18next";
import { Link } from "react-router-dom";
import { generateAnchorLink } from "utils/anchorUtils";

export const getBuildingMenuItems = (
  t: TFunction<"translation", undefined>,
  location: Location,
  venueId: string,
  buildingId?: string,
) => {
  const isInBuilding =
    location.pathname.split("/")[5] === "buildings" && location.pathname.split("/")[6] === buildingId?.toString();
  const baseLink = `venues/${venueId}/buildings/${buildingId}`;

  const items = [
    {
      id: `${buildingId}_#details`,
      label: t("Details"),
      anchorLink: "#details",
    },
    {
      id: `${buildingId}_#meeting-rooms`,
      label: t("Meeting Rooms"),
      anchorLink: "#meeting-rooms",
    },
    {
      id: `${buildingId}_#conference-rooms`,
      label: t("Conference Rooms"),
      anchorLink: "#conference-rooms",
    },
    {
      id: `${buildingId}_#restaurants`,
      label: t("Restaurants"),
      anchorLink: "#restaurants",
    },
    {
      id: `${buildingId}_#speed-meetings`,
      label: t("Speed Meetings"),
      anchorLink: "#speed-meetings",
    },
    {
      id: `${buildingId}_#bedrooms`,
      label: t("Bedrooms"),
      anchorLink: "#bedrooms",
    },
  ];

  return items.map((i) => {
    return generateAnchorLink({
      id: i.id,
      label: i.label,
      anchorLink: i.anchorLink,
      link: baseLink,
      shouldBeAnchor: isInBuilding,
    });
  });
};

export const getVenueMenuItems = (
  t: TFunction<"translation", undefined>,
  buildings: BuildingResponseModel[],
  venueId: string,
) => {
  return buildings?.map((building) => {
    return {
      label: <Link to={`venues/${venueId}/buildings/${building.id}`}>{building.name}</Link>,
      key: building.id,
      id: building.id,
      children: getBuildingMenuItems(t, location, venueId, building.id?.toString()),
    };
  });
};

export const getDefaultSelectedKeys = (location: Location) => {
  const mainItem = location.pathname.split("/")[3];

  if (mainItem === "venues") {
    return ["venues"];
  }

  return [mainItem];
};

export const getDefaultOpenKeys = (location: Location) => {
  const mainItem = location.pathname.split("/")[3];
  const subItem = location.pathname.split("/")[4];

  //These are put here so that the menu doesn't overflow to a point in doesn't render it's children
  if (mainItem === "venues" && subItem) {
    return ["venues"];
  }

  return [];
};
