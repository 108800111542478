import { ForumAttendeeApiForumForumIdAttendeesGetRequest } from "../../../generated/api";
import { AttendeeAdvancedFilterTypes } from "./../../../generated/api/apiModels/attendee-advanced-filter-types";
import { AvailableFilters, DateFilter } from "./../Filters/types";

const getDateFilter = (advancedFilterType: AttendeeAdvancedFilterTypes, filters: DateFilter) => {
  return {
    advancedFilterType,
    startDate: filters.dateRange ? filters.dateRange[0].format("YYYY-MM-DD") : undefined,
    endDate: filters.dateRange ? filters.dateRange[1].format("YYYY-MM-DD") : undefined,
  };
};

const applyAttendeesFilters = (
  filters: AvailableFilters | undefined,
): Omit<ForumAttendeeApiForumForumIdAttendeesGetRequest, "forumId"> => {
  if (!filters) {
    return {};
  }

  switch (filters.type) {
    case "questionnaire":
      return {
        advancedFilterType: AttendeeAdvancedFilterTypes.QuestionnaireStatus,
        questionnaireId: filters.id,
        statuses: filters.statuses,
      };
    case "supplierContractDate":
      return getDateFilter(AttendeeAdvancedFilterTypes.SupplierContractDate, filters);
    case "delegateSpeakerBFR":
      return getDateFilter(AttendeeAdvancedFilterTypes.SpeakerBfr, filters);
    case "cancellationDate":
      return getDateFilter(AttendeeAdvancedFilterTypes.CancellationDate, filters);
    case "selectionSites":
      return {
        advancedFilterType: AttendeeAdvancedFilterTypes.SelectionSiteStatus,
        selectionSiteId: filters.id,
        status: filters.statuses,
      };
  }

  return {};
};

export default applyAttendeesFilters;
