import { ForumQuestionnaireResponseModel, QuestionnaireSubmissionStatus } from "generated/api";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { useQuestionnairesQuery } from "../../../../../../../api/queries/questionnaires";
import { AsyncSelect, Form, Select } from "../../../../../../../components/styleguide";

export const SubmissionStatusMap: Record<QuestionnaireSubmissionStatus, string> = {
  [QuestionnaireSubmissionStatus.NotStarted]: "Not started",
  [QuestionnaireSubmissionStatus.InProgress]: "In progress",
  [QuestionnaireSubmissionStatus.Completed]: "Completed",
};

const QuestionnaireFilterForm = () => {
  const { t } = useTranslation();
  const params = useParams();
  const forumId = parseInt(params.id ?? "");

  const questionnairesQuery = useQuestionnairesQuery({ forumId });

  const optionsMapper = (response: NonNullable<typeof questionnairesQuery.data>["data"]) => {
    return (
      response.items?.map((questionnaire: ForumQuestionnaireResponseModel) => ({
        value: questionnaire.id,
        label: questionnaire.name,
      })) ?? []
    );
  };

  return (
    <>
      <Form.Item name="id" label={t("Questionnaire")} rules={[{ required: true }]}>
        <AsyncSelect
          query={questionnairesQuery}
          optionsMapper={optionsMapper}
          placeholder={t("Select questionnaire")}
        />
      </Form.Item>
      <Form.Item name="statuses" label={t("Status")} rules={[{ required: true }]}>
        <Select mode="multiple" placeholder={t("Select status")}>
          {Object.entries(SubmissionStatusMap).map(([value, label]) => (
            <Select.Option key={value} value={value}>
              {t(label)}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};

export default QuestionnaireFilterForm;
