import { DownOutlined } from "@ant-design/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dropdown } from "../styleguide";

interface DropdownButtonMenuItem {
  label: string;
  key: string;
  onClick: () => void;
}

interface DropdownButtonProps {
  title: string;
  items: Array<DropdownButtonMenuItem>;
  isLoading?: boolean;
  disabled?: boolean;
}

const DropdownButton: FC<DropdownButtonProps> = ({ title, items, isLoading, disabled }) => {
  const { t } = useTranslation();

  return (
    <Dropdown menu={{ items }} disabled={disabled}>
      <Button loading={isLoading}>
        {t(title)}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default DropdownButton;
