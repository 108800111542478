import { Space } from "antd";
import { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = { SeminarCode: "Seminar Code", Facility: "Facility", Start: "Session Start Time" };

const SessionDetailsOptions = () => {
  const [orderBy, setOrderBy] = useState<string>("SeminarCode");

  return (
    <Space align={"end"}>
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />
      <LinkToReport parameters={{ OrderBy: orderBy }} />
    </Space>
  );
};

export default SessionDetailsOptions;
