import "./styles.less";

import { useForumsQuery } from "api/queries/forums";
import { Button, Col, DatePicker, Row, Table } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { InfoSize } from "components/UserGuide/Info";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getColumnSearchProps } from "utils/tableColumnUtils";

import ForumDrawer from "../ForumDrawer";

const SEARCHABLE_COLUMN_TITLES = {
  code: "code",
  title: "title",
  year: "year",
};

const ForumsList = () => {
  const { t } = useTranslation();

  const [forums, setForums] = useState([]);
  const [codeSearch, setCodeSearch] = useState(undefined);
  const [titleSearch, setTitleSearch] = useState(undefined);
  const [yearFilter, setYearFilter] = useState(undefined);
  const [forumToEdit, setForumToEdit] = useState(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const showDrawer = (forum) => {
    setForumToEdit(forum);
    setIsEditDrawerOpen(true);
  };

  const hideDrawer = () => {
    setForumToEdit(null);
    setIsEditDrawerOpen(true);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex, searchSet) => {
    if (searchSet === SEARCHABLE_COLUMN_TITLES.code) {
      setCodeSearch(selectedKeys[0]);
    }
    if (searchSet === SEARCHABLE_COLUMN_TITLES.title) {
      setTitleSearch(selectedKeys[0]);
    }
    if (searchSet === SEARCHABLE_COLUMN_TITLES.year) {
      if (selectedKeys === null) {
        handleReset(null, null, SEARCHABLE_COLUMN_TITLES.year, null);
      } else {
        setYearFilter(moment(selectedKeys).year());
      }
    }

    confirm?.();
  };

  const handleReset = (clearFilters, dataIndex, searchSet, confirm) => {
    if (searchSet === SEARCHABLE_COLUMN_TITLES.code) {
      setCodeSearch(undefined);
    }
    if (searchSet === SEARCHABLE_COLUMN_TITLES.title) {
      setTitleSearch(undefined);
    }
    if (searchSet === SEARCHABLE_COLUMN_TITLES.year) {
      setYearFilter(undefined);
    }

    clearFilters?.();
    confirm?.();
  };

  const columnTitle = (value) => <span className="filtered-title">{value}</span>;

  const columns = [
    {
      title: codeSearch ? columnTitle(codeSearch) : t("Code"),
      dataIndex: "code",
      sorter: true,
      render: (code) => {
        return <div>{code}</div>;
      },
      ...getColumnSearchProps("code", handleSearch, handleReset, "code"),
    },
    {
      title: titleSearch ? columnTitle(titleSearch) : t("name"),
      dataIndex: "title",
      sorter: true,
      render: (name, record) => (
        // we have to use `String(record.id)` instead of `record.id` because of `Link` component
        // expects string as `to` prop but `record.id` is number.
        // Otherwise, navigation will not work.
        <Link className="details-link" to={String(record.id)} state={{ forumName: name }}>
          {name}
        </Link>
      ),
      ...getColumnSearchProps("title", handleSearch, handleReset, "title"),
    },
    {
      title: t("startDate"),
      dataIndex: "startDate",
      sorter: true,
      render: (date) => {
        return <div>{moment(date).format("L")}</div>;
      },
    },
    {
      title: t("endDate"),
      dataIndex: "endDate",
      sorter: true,
      render: (date) => {
        return <div>{moment(date).format("L")}</div>;
      },
    },
    {
      title: t("createdAt"),
      dataIndex: "createdAt",
      sorter: true,
      render: (date) => {
        return <div>{moment(date).format("L LT")}</div>;
      },
    },
    {
      title: t("updatedAt"),
      dataIndex: "updatedAt",
      sorter: true,
      render: (date) => {
        return <div>{moment(date).format("L LT")}</div>;
      },
    },
    {
      dataIndex: "id",
      key: "id",
      responsive: ["sm"],
      render: (_, record) => {
        return (
          <div>
            <Button onClick={() => showDrawer(record)}>{t("Edit")}</Button>
          </div>
        );
      },
    },
  ];

  const {
    response: { isLoading } = {},
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useForumsQuery,
    {
      onSuccess: ({ data }) => {
        setForums(data.items);
      },
    },
    { orderBy: "updatedAt", orderDir: "desc", code: codeSearch, title: titleSearch, year: yearFilter },
  );

  return (
    <div className="forums-list-component">
      <Row className="controls" justify="space-between" align="middle">
        <Col>
          <DatePicker
            onChange={(year) => handleSearch(year, null, null, SEARCHABLE_COLUMN_TITLES.year)}
            picker="year"
            format="YYYY"
          />
        </Col>
        <Col>
          <UserGuide.Info articleId="16426451611549-forums" size={InfoSize.BIG} />
        </Col>
      </Row>
      <Table
        pagination={pagination}
        loading={isLoading}
        columns={columns}
        dataSource={forums}
        id="forumsListTable"
        rowKey="id"
        onChange={handleSort}
      />
      {forumToEdit && (
        <ForumDrawer
          key={JSON.stringify(forumToEdit)}
          existingForum={forumToEdit}
          visible={isEditDrawerOpen}
          onClose={hideDrawer}
          setIsDrawerOpen={setIsEditDrawerOpen}
          isEdit
        />
      )}
    </div>
  );
};

export default ForumsList;
