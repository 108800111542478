import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Input, Radio, Select } from "../../../../../../../components/styleguide";
import { useTranslation } from "react-i18next";
import AutoGrid from "../../../../../../../components/styleguide/components/AutoGrid";

const OptionsRenderer = ({ type, options, columns }) => {
  const { t } = useTranslation();

  if (options.length === 0) {
    return t("There is no options to display");
  }

  switch (type) {
    case "free-text":
      return <AutoGrid columns={columns} gap={8}>
        {options.map(({ id, text }) => <div key={id}><Input.TextArea disabled placeholder={text} /></div>)}
      </AutoGrid>;
    case "checkbox":
      return <AutoGrid columns={columns} gap={8}>
        {options.map(({ id, text }) => <div key={id}><Checkbox disabled>{text}</Checkbox></div>)}
      </AutoGrid>;
    case "radio":
      return <Radio.Group style={{ width: "100%" }} disabled>
        <AutoGrid columns={columns} gap={8}>
          {options.map(({ id, text }) => <div key={id}><Radio value={id}>{text}</Radio></div>)}
        </AutoGrid>
      </Radio.Group>;
    case "dropdown":
      return <Select placeholder={t("Please, select option")} disabled>
        {options.map(({ id, text }) => <Select.Option key={id}>{text}</Select.Option>)}
      </Select>;
    default:
      return t("Can't show options. Type is unsupported.");
  }
};

OptionsRenderer.propTypes = {
  type: PropTypes.oneOf([
    "free-text",
    "checkbox",
    "radio",
    "dropdown",
  ]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })),
  columns: PropTypes.number,
};

OptionsRenderer.defaultProps = {
  options: [],
  columns: 1,
};

export default OptionsRenderer;
