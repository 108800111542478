import { useQueryClient } from "@tanstack/react-query";
import { useUpdateForumMutation } from "api/mutations/forums";
import { useGetForumByIdQuery } from "api/queries/forums";
import KEYS from "api/queries/keys";
import { Col, Row, Select, Spin } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { ForumRequestModel } from "generated/api";
import { languages } from "i18n";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ConferenceLanguagesPage: FC = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const { data: { data: forum } = {}, isLoading } = useGetForumByIdQuery({ forumId });

  const { mutate: updateForum, isLoading: isUpdateLoading } = useUpdateForumMutation();

  const updateForumLanguage = (language: string) => {
    if (forum) {
      updateForum(
        {
          id: forumId,
          forumRequestModel: { ...forum, language } as ForumRequestModel,
        },
        {
          onSuccess: () => queryClient.invalidateQueries([KEYS.GET_FORUM_BY_ID]),
          onError: () => toast.error(t("Something went wrong")),
        },
      );
    }
  };

  if (isLoading) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <div className="table-wrapper">
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <UserGuide.Title title="Languages" articleId="16426049863453-languages" />
        </Col>
        <Col span={24}>
          <Select
            value={forum?.language ?? languages["en-GB"].key}
            style={{ width: "200px" }}
            onChange={updateForumLanguage}
            loading={isUpdateLoading}
          >
            {Object.values(languages).map(({ key, name }) => (
              <Select.Option value={key} key={key}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
};

export default ConferenceLanguagesPage;
