import { FeedbackDefaultAnswersEnum, FeedBackQuestionType, ForumFeedBackQuestionModel } from "generated/api";
import { t } from "i18next";

import { ConferenceRatings, FollowUps, Ratings } from "../helpers";
import { ConferenceRatingsType, FollowUpsType, RatingsType } from "../types";
import { MeetingFormValues, SessionFormValues } from "./types";

const getQuestionByType = (questions: Array<ForumFeedBackQuestionModel>, questionType: FeedBackQuestionType) => {
  return questions.find(({ feedBackQuestionType }) => feedBackQuestionType === questionType);
};

const getQuestionItems = (question: ForumFeedBackQuestionModel) => {
  return question?.feedbackQuestionItems?.map(({ itemValue }) => itemValue);
};

const getQuestionItemTranslation = (questionType: FeedBackQuestionType, questionItem: FeedbackDefaultAnswersEnum) => {
  switch (questionType) {
    case FeedBackQuestionType.FollowUp:
      return t(FollowUps[questionItem as keyof FollowUpsType]);
    case FeedBackQuestionType.Supplier:
      return t(Ratings[questionItem as keyof RatingsType]);
    case FeedBackQuestionType.ContentQuality:
    case FeedBackQuestionType.ContentMatch:
    case FeedBackQuestionType.SessionMeetYourNeeds:
    case FeedBackQuestionType.FeedbackOnSpeakers:
      return t(ConferenceRatings[questionItem as keyof ConferenceRatingsType]);
    default:
      return t(questionItem);
  }
};

export const getQuestionItemsOptions = (
  questions: Array<ForumFeedBackQuestionModel>,
  questionType: FeedBackQuestionType,
) => {
  const question = getQuestionByType(questions, questionType);

  if (!question) {
    return;
  }

  return getQuestionItems(question)?.map((questionItem) => ({
    value: questionItem,
    label: questionItem && getQuestionItemTranslation(questionType, questionItem),
  }));
};

export const getQuestionsFromValues = (values: MeetingFormValues | SessionFormValues) => {
  return Object.keys(values)
    .filter((key) => key in FeedBackQuestionType)
    .map((key) => {
      return {
        feedBackQuestionType: key as FeedBackQuestionType,
        value: values[key as keyof typeof values] as FeedbackDefaultAnswersEnum,
      };
    });
};
