import { TimeSlotApi } from "generated/api/apiPackage/time-slot-api";

import { initAPIClient } from "./_base";

const api = initAPIClient(TimeSlotApi);

const TimeSlot = {
  get: api.forumForumIdTimeslotTimeSlotIdGet,
  getAll: api.forumForumIdTimeslotGet,
  export: api.forumForumIdTimeslotExportGet,
  post: api.forumForumIdTimeslotPost,
  put: api.forumForumIdTimeslotTimeSlotIdPut,
  delete: api.forumForumIdTimeslotTimeSlotIdDelete,
};

export default TimeSlot;
