import { useQueryClient } from "@tanstack/react-query";
import {
  keys,
  useQuestionnaireContentQuery,
  useQuestionnaireMatchesQuery,
  useQuestionnaireQuery,
} from "api/queries/questionnaires";
import { Form, Space, Spin } from "components/styleguide";
import PropTypes from "prop-types";
import SelectionSitesPropTypes from "propTypes/selectionSites";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { removeAt, replaceAt } from "utils/arrayUtils";
import { getQuestionsFromPages } from "utils/questionnaireContentUtils";

import withOnChange from "../../../utils/withOnChange";
import Mapper from "./components/Mapper";
import OptionsMatchingButton from "./components/OptionsMatchingButton";
import ListingTable from "./components/Table";
import mapQuestionsWithMappings from "./utils/mapQuestionsWithMappings";

const SUPPORTED_TYPES = ["Checkbox", "RadioButton", "DropdownList"];

const getAvailableQuestions = (pages) => {
  const allQuestions = getQuestionsFromPages(pages ?? []);
  return allQuestions.filter((q) => SUPPORTED_TYPES.includes(q.type));
};

const convertQuestionsToOptions = (questions) => {
  return questions.map((question) => ({
    value: question.id,
    label: question.text,
  }));
};

const QuestionnaireMatching = ({ forumId, value, onChange }) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    questionnaireOfGroupBeingSelected: qbs,
    questionnaireOfGroupMakingSelections: qms,
    questionnaireOfGroupBeingSelectedVersion: qbsContentVersion,
  } = form.getFieldsValue([
    "questionnaireOfGroupBeingSelected",
    "questionnaireOfGroupMakingSelections",
    "questionnaireOfGroupBeingSelectedVersion",
  ]);

  const qbsInfoQuery = useQuestionnaireQuery({ forumId, questionnaireId: qbs });
  const qmsInfoQuery = useQuestionnaireQuery({ forumId, questionnaireId: qms });

  const qbsQuery = useQuestionnaireContentQuery({ forumId, questionnaireId: qbs });
  const qmsQuery = useQuestionnaireContentQuery({ forumId, questionnaireId: qms });

  const createMatchesQuery = useQuestionnaireMatchesQuery({
    forumId,
    sourceQuestionnaireId: qbs,
    targetQuestionnaireId: qms,
  });

  const isLoading = qbsInfoQuery.isLoading || qmsInfoQuery.isLoading || qbsQuery.isLoading || qmsQuery.isLoading;

  const qbsResponse = qbsQuery.data?.data;
  const qmsResponse = qmsQuery.data?.data;

  const qbsTitle = qbsInfoQuery.data?.data?.name;
  const qmsTitle = qmsInfoQuery.data?.data?.name;

  const qbsQuestions = useMemo(() => getAvailableQuestions(qbsResponse?.pages), [qbsResponse]);
  const qmsQuestions = useMemo(() => getAvailableQuestions(qmsResponse?.pages), [qmsResponse]);

  const qbsOptions = useMemo(() => convertQuestionsToOptions(qbsQuestions), [qbsQuestions]);
  const qmsOptions = useMemo(() => convertQuestionsToOptions(qmsQuestions), [qmsQuestions]);

  const handleAddMatching = (from, to) => {
    const questions = value?.questions ?? [];
    const newQuestions = [...questions, { makingSelections: from, beingSelected: to, options: [] }];

    return onChange({ questions: newQuestions });
  };

  const handleMatchesCreate = () => {
    const questions = value?.questions ?? [];
    const autoMatches = createMatchesQuery?.data?.data?.questions || [];

    const filteredAutoMatches = autoMatches
      .filter(
        ({ beingSelected, makingSelections }) =>
          // making sure that pair exist
          beingSelected !== null &&
          makingSelections !== null &&
          // making sure that this question haven't been matched yet
          questions.findIndex(
            (question) => question.beingSelected === beingSelected && question.makingSelections === makingSelections,
          ) === -1 &&
          // making sure that questions types allowed for match
          qmsQuestions.find(({ id }) => id === makingSelections) &&
          qbsQuestions.find(({ id }) => id === beingSelected),
      )
      .map((question) => {
        return { ...question, options: [] };
      });

    if (filteredAutoMatches.length) {
      const newQuestions = [...questions, ...filteredAutoMatches];

      return onChange({ questions: newQuestions });
    }
  };

  const handleDeleteMatching = (index) => {
    const questions = value?.questions ?? [];
    const newQuestions = removeAt(questions, index);

    return onChange({ questions: newQuestions });
  };

  const handleOptionsChange = useCallback(
    (index, options) => {
      const matching = value?.questions?.[index];
      const newMatching = {
        ...matching,
        options: options.map(({ from, to }) => ({ makingSelections: from, beingSelected: to })),
      };

      const newQuestions = replaceAt(value?.questions ?? [], index, newMatching);
      return onChange({ questions: newQuestions });
    },
    [onChange, value?.questions],
  );

  const optionsMatchingColumn = useMemo(() => {
    return {
      key: "options",
      width: 20,
      render: (_, { from, to, options }, index) => {
        const mappedOptions = (options ?? []).map(({ makingSelections, beingSelected }) => ({
          from: makingSelections,
          to: beingSelected,
        }));

        return (
          <OptionsMatchingButton
            forumId={forumId}
            from={{
              questionnaire: qms,
              question: from,
            }}
            to={{
              questionnaire: qbs,
              question: to,
            }}
            options={mappedOptions}
            onOptionsChange={(newOptions) => handleOptionsChange(index, newOptions)}
          />
        );
      },
    };
  }, [forumId, qbs, qms, handleOptionsChange]);

  const handleModifyColumns = useCallback((columns) => [optionsMatchingColumn, ...columns], [optionsMatchingColumn]);

  const mappings = useMemo(() => {
    const v = value?.questions;

    const isVersionDiffersFromLatest = qbsContentVersion !== qbsInfoQuery.data?.data.contentVersion;

    const questions = isVersionDiffersFromLatest || !v ? undefined : v;
    const mapped = mapQuestionsWithMappings(qbsQuestions, qmsQuestions, { questions: questions });

    return mapped.questions?.map(({ beingSelected, makingSelections, options }) => ({
      from: makingSelections,
      to: beingSelected,
      options,
    }));
  }, [qbsContentVersion, value?.questions, qbsInfoQuery.data?.data.contentVersion, qbsQuestions, qmsQuestions]);

  useEffect(() => {
    queryClient.invalidateQueries(
      keys.getAutoCreatedMatches({
        forumId,
        sourceQuestionnaireId: qbs,
        targetQuestionnaireId: qms,
      }),
    );
  }, [forumId, qbs, qms, queryClient, value?.questions]);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Space direction="vertical" size="middle" fullWidth>
      <Mapper
        first={{
          title: qmsTitle,
          options: qmsOptions,
          placeholder: t("Select question from the list"),
          errorMessage: t("Please select a question"),
        }}
        second={{
          title: qbsTitle,
          options: qbsOptions,
          placeholder: t("Select question from the list"),
          errorMessage: t("Please select a question"),
        }}
        onSubmit={handleAddMatching}
        isAutoMatchesLoading={createMatchesQuery.isLoading}
        onMatchesAutoCreate={handleMatchesCreate}
      />
      <ListingTable
        options={{
          from: qmsOptions,
          to: qbsOptions,
        }}
        mappings={mappings}
        modifyColumns={handleModifyColumns}
        onDelete={handleDeleteMatching}
      />
    </Space>
  );
};

QuestionnaireMatching.propTypes = {
  forumId: PropTypes.number.isRequired,
  value: SelectionSitesPropTypes.Item.PositiveSelectionV2.questionnaireMatching,
  onChange: PropTypes.func,
};

QuestionnaireMatching.defaultProps = {
  value: undefined,
  onChange: () => undefined,
};

export default withOnChange(QuestionnaireMatching);
