import { useQueryClient } from "@tanstack/react-query";
import { useCreateSelectionOptionMutation } from "api/mutations/forumAttendeePreferences";
import KEYS from "api/queries/keys";
import { Drawer } from "components/styleguide";
import { AttendeeChoice } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import SelectionOptionForm from "../SelectionOptionForm";

interface AddSelectionOptionDrawerProps {
  isVisible: boolean;
  onClose: () => void;
}

const AddSelectionOptionDrawer: FC<AddSelectionOptionDrawerProps> = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const params = useParams();
  const queryClient = useQueryClient();

  const forumId = toNumber(params.id);
  const attendeeId = toNumber(params.attendeeId);

  const { isLoading: isSelectionOptionAdding, mutate: addSelectionOption } = useCreateSelectionOptionMutation();

  const onSubmit = (values: AttendeeChoice) => {
    addSelectionOption(
      {
        forumId,
        attendeeId,
        attendeeChoice: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ATTENDEE_PREFERENCES_SELECTION_OPTIONS]);
          onClose();
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  return (
    <Drawer
      title={t("Add Selection Option")}
      open={isVisible}
      size="large"
      placement="right"
      destroyOnClose
      onClose={onClose}
    >
      <SelectionOptionForm isLoading={isSelectionOptionAdding} onSubmit={onSubmit} onClose={onClose} />
    </Drawer>
  );
};

export default AddSelectionOptionDrawer;
