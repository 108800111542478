import { useMutation } from "@tanstack/react-query";
import api from "api";

const useCreateFloorMutation = (options = {}) => {
  const mutation = useMutation(
    ({ venueId, buildingId, floor }) =>
      api.post(`/venues/${venueId}/buildings/${buildingId}/floors`, floor),
    options
  );
  return mutation;
};

const useDeleteFloorMutation = (options = {}) => {
  return useMutation(async ({ venueId, buildingId, floorId }) => {
    await api.delete(
      `/venues/${venueId}/buildings/${buildingId}/floors/${floorId}`
    ),
      options;
  });
};

const useCreateForumFloorMutation = (options = {}) => {
  return useMutation(
    ({ forumId, buildingId, floor }) =>
      api.post(`/forums/${forumId}/venue/buildings/${buildingId}/floors`, floor),
    options
  );
};

const useDeleteForumFloorMutation = (options = {}) => {
  return useMutation(({ forumId, buildingId, floorId }) => {
    api.delete(
      `/forums/${forumId}/venue/buildings/${buildingId}/floors/${floorId}`
    ),
      options;
  });
};

export {
  useCreateFloorMutation,
  useDeleteFloorMutation,
  useCreateForumFloorMutation,
  useDeleteForumFloorMutation,
};
