import EnumBuilder from "utils/enumBuilderUtils";

const builder = new EnumBuilder();

const ParticipationCodeTypes = builder
  .add("Delegate", "Delegate")
  .add("Supplier", "Supplier")
  .add("DelegateAndSpeaker", "Delegate and Speaker")
  .add("SupplierAndSpeaker", "Supplier and Speaker")
  .build();

export default ParticipationCodeTypes;
