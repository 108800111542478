import { InputNumber, Select } from "components/styleguide";
import React from "react";

import DateRenderer from "../../../../components/DateRenderer";

const { Option } = Select;

const createTimeslotFromSession = ({ timeSlotId, timeSlotDescription, timeSlotSessionId, startTime, endTime }) => ({
	id: timeSlotId,
	description: timeSlotDescription,
	sessions: [
		{
			id: timeSlotSessionId,
			startTime,
			endTime,
		},
	],
});

const createTimeslots = (facilityId, session, timeslotSessions) => {
	const timeslotsArr = [];

	if (session && facilityId === session?.forumMeetingFacilityId) {
		timeslotsArr.push(createTimeslotFromSession(session));
	}

	timeslotSessions.forEach(({ id, startTime, endTime, order, timeSlotId, timeSlotDescription }) => {
		const timeslot = timeslotsArr.find(({ description }) => description === timeSlotDescription);
		const timeslotSession = { id, startTime, endTime, order };

		if (!timeslot) {
			timeslotsArr.push({
				id: timeSlotId,
				description: timeSlotDescription,
				sessions: [timeslotSession],
			});
		} else {
			timeslot.sessions.push(timeslotSession);
		}
	});

	return timeslotsArr;
};

const getTimeslotSessionsOptions = (facilityId, session, timeslotSessions) => {
	return createTimeslots(facilityId, session, timeslotSessions).map(({ description, sessions }) => ({
		label: description,
		options: sessions.map(({ id, startTime, endTime }) => ({
			value: id,
			label: (
				<>
					<DateRenderer date={startTime} showTime />
					{" - "}
					<DateRenderer date={endTime} showTime />
				</>
			),
		})),
	}));
};

const getFormFields = ({
	t,
	conferenceRooms,
	facility,
	timeslotSessions,
	seminarSpeakers,
	onFacilityChange,
	session,
}) => [
		{
			title: t("Choose a Facility"),
			dataIndex: "forumMeetingFacilityId",
			component: (
				<Select disabled={session?.hasAssignedAttendees} onChange={onFacilityChange}>
					{conferenceRooms.map((c) => (
						<Option key={c.id} value={c.id}>
							{c.name}
						</Option>
					))}
				</Select>
			),
			rules: [{ required: true, message: t("Please select a facility") }],
		},
		{
			title: t("Number of places"),
			dataIndex: "places",
			component: <InputNumber disabled={session?.hasAssignedAttendees} min={0} max={facility?.places || 1000} style={{ width: "100%" }} />,
			extra: t("Maximum of {{count}}", { count: facility?.places || 1000 }),
		},
		{
			title: t("Include Meal"),
			dataIndex: "includeMeal",
			component: (
				<Select disabled={session?.hasAssignedAttendees}>
					<Option value={true}>{t("Yes")}</Option>
					<Option value={false}>{t("No")}</Option>
				</Select>
			),
			initialValue: false,
			rules: [{ required: true, message: t("Please select a meal option") }],
		},
		{
			title: t("Conference timeslot"),
			dataIndex: "timeSlotSessionId",
			component: (
				<Select
					disabled={session?.hasAssignedAttendees}
					notFoundContent={
						facility
							? t(
								"No timeslots are available for the chosen facility. Please select a different facility or create new timeslots.",
							)
							: t("Please choose a facility first.")
					}
					options={getTimeslotSessionsOptions(facility?.id, session, timeslotSessions)}
				/>
			),
			rules: [{ required: true, message: t("Please select a timeslot") }],
		},
		{
			title: t("Speaker"),
			dataIndex: "speakerId",
			component: (
				<Select>
					{seminarSpeakers.map((c) => (
						<Option key={c.id} value={c.id}>
							{c.firstName + " " + c.surname}
						</Option>
					))}
				</Select>
			),
		},
	];

export { getFormFields };
