import { FormProps } from "antd";
import clsx from "clsx";
import React, { ReactNode } from "react";

import { Divider, Form } from "../styleguide";

import styles from "./styles.module.less";

export interface FullHeightFormProps<Values> extends FormProps<Values> {
  actionsPrepend?: ReactNode;
  actionsAppend?: ReactNode;
  footerClassName?: string;
  divider?: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const FullHeightForm = <Values,>({
  className,
  children,
  actionsAppend,
  actionsPrepend,
  footerClassName,
  divider = true,
  ...props
}: FullHeightFormProps<Values>) => {
  const formClasses = clsx(styles.form, className);
  const footerClasses = clsx(styles.footer, footerClassName);

  return (
    <Form className={formClasses} {...props}>
      <>
        {children}

        {(actionsPrepend || actionsAppend) && (
          <>
            <div style={{ marginTop: "auto" }} />
            {divider && <Divider />}
            <div>
              <div className={footerClasses}>
                {actionsPrepend}
                <div className={styles.spacer} />
                {actionsAppend}
              </div>
            </div>
          </>
        )}
      </>
    </Form>
  );
};

export default FullHeightForm;
