import getWrappedUseMutation from "api/helpers/getWrappedUseMutation";
import apiClient from "api-client";

export const useCreateForumAttendeeAvailibilityMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeAvailability.post,
);

export const useUpdateForumAttendeeAvailibilityMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeAvailability.put,
);

export const useDeleteForumAttendeeAvailibilityMutation = getWrappedUseMutation(
  apiClient.ForumAttendeeAvailability.delete,
);
