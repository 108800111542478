import { UploadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Drawer, Form, Input, Upload } from "components/styleguide";
import { ForumResponseModel } from "generated/api";
import moment, { Moment } from "moment";
import { useTranslation } from "react-i18next";
import { getDisabledDates } from "utils/dateUtils";
import { handleFileFakeRequest, MAX_FILE_SIZE, normFile } from "utils/documentUtils";
import { validateFileSize } from "utils/validatorUtils";

import styles from "./styles.module.less";

const DocumentUpload = ({
  className,
  visible,
  isLoading,
  onVisibleChange,
  onSuccess,
  forum,
}: {
  className: string;
  visible: boolean;
  isLoading: boolean;
  onVisibleChange: (value: boolean) => void;
  onSuccess: (values: { file: File; title: string; text: string; publishFrom: Moment; publishUntil: Moment }) => void;
  forum: ForumResponseModel;
}) => {
  const { t } = useTranslation();

  const showDrawer = () => {
    onVisibleChange(true);
  };

  const hideDrawer = () => {
    if (isLoading) {
      return;
    }

    onVisibleChange(false);
  };

  const handleFormSubmit = (values: {
    files: { originFileObj: File }[];
    title: string;
    text: string;
    publish: Moment[];
  }) => {
    onSuccess({
      file: values.files[0]?.originFileObj,
      title: values.title,
      text: values.text,
      publishFrom: values.publish[0],
      publishUntil: values.publish[1],
    });
  };

  return (
    <>
      <Button className={className} onClick={showDrawer}>
        {t("Upload document")}
      </Button>
      <Drawer
        open={visible}
        placement="right"
        title={t("Upload document")}
        size={"large"}
        destroyOnClose
        onClose={hideDrawer}
      >
        <Form
          className={styles.container}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={handleFormSubmit}
        >
          <Form.Item
            name="files"
            label={t("File")}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              { required: true, message: t("Please select a file") },
              {
                validator: validateFileSize(MAX_FILE_SIZE),
                message: t("File is bigger than {{size}}", { size: "100MB" }),
              },
            ]}
          >
            <Upload customRequest={handleFileFakeRequest} maxCount={1}>
              <Button icon={<UploadOutlined />}>{t("Click to Upload")}</Button>
            </Upload>
          </Form.Item>

          <Form.Item name="title" label={t("Title")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item name="text" label={t("Text")} rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item
            name="publish"
            label={t("Publish From - Until")}
            rules={[{ required: true }]}
            initialValue={[moment(forum?.startDate), moment(forum?.endDate)]}
          >
            <DatePicker.RangePicker
              disabledDate={(value: Moment) => getDisabledDates(value, forum)}
              defaultPickerValue={[moment(forum?.startDate), moment(forum?.endDate)]}
            />
          </Form.Item>

          <div style={{ marginTop: "auto" }} />
          <Divider />
          <div className={styles.footer}>
            <Button disabled={isLoading} onClick={hideDrawer}>
              {t("Cancel")}
            </Button>
            <div className={styles.spacer} />
            <Button type="primary" htmlType="submit" style={{ marginLeft: "auto" }} loading={isLoading}>
              {t("Save")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default DocumentUpload;
