import { TableProps } from "antd";
import { Avatar, Button, Space, Table } from "components/styleguide";
import { ForumGroupResponseModel, PositiveSelectionV2Item } from "generated/api";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { removeAt } from "utils/arrayUtils";
import { getMapBy } from "utils/mapUtils";

import styles from "./styles.module.less";

type Entry = NonNullable<PositiveSelectionV2Item["attendeeImageDisplayForumGroups"]>[number];

type Group = Pick<ForumGroupResponseModel, "id" | "name" | "color" | "imageText" | "longText">;

interface ListingTableProps {
  groups: Array<Group>;
  value: Array<Entry>;
  onChange: (value: Array<Entry>) => void;
}

const ListingTable: FC<ListingTableProps> = ({ groups, value, onChange }) => {
  const { t } = useTranslation();

  const groupMap = useMemo(() => getMapBy(groups, "id"), [groups]);

  const handleDelete = (index: number) => {
    onChange(removeAt(value, index));
  };

  const columns: TableProps<Entry>["columns"] = [
    {
      dataIndex: "id",
      title: t("Group Name"),
      render: (id) => groupMap.get(id)?.name,
      width: 300,
    },
    {
      dataIndex: "color",
      title: t("Colour"),
      render: (color, row) => {
        const group = row.id ? groupMap.get(row.id) : undefined;
        const c = group?.color ?? color;
        if (!c) {
          return null;
        }

        return (
          <Space>
            <Avatar size={20} style={{ backgroundColor: c }} />
            {c}
          </Space>
        );
      },
      width: 300,
    },
    {
      dataIndex: "imageText",
      title: t("Image text"),
      render: (imageText, row) => {
        const group = row.id ? groupMap.get(row.id) : undefined;
        return group?.imageText ?? imageText;
      },
      width: 300,
    },
    {
      dataIndex: "longText",
      title: t("Long text"),
      render: (longText, row) => {
        const group = row.id ? groupMap.get(row.id) : undefined;
        return group?.longText ?? longText;
      },
    },
    {
      dataIndex: "id",
      render: (_, __, index) => (
        <div className={styles.actions}>
          <Button type="primary" onClick={() => handleDelete(index)}>
            {t("Delete")}
          </Button>
        </div>
      ),
      width: 100,
    },
  ];

  return <Table columns={columns} dataSource={value} rowKey="id" pagination={false} />;
};

export default ListingTable;
