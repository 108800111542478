import { TableProps } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { SortOrder } from "antd/lib/table/interface";
import { useCallback, useState } from "react";

// For changing the sorting order after an action
// For instance: To display some column in alphabetical order after editing an entity

export interface Sort {
  orderBy: string | undefined;
  orderDir: "asc" | "desc" | undefined;
}

const useCustomHandleSort = (orderBy: string, orderDir: "asc" | "desc") => {
  const [sort, setSort] = useState<Sort>({ orderBy, orderDir });

  const getShortOrderDir = (order: SortOrder | undefined) => {
    if (order === "ascend") {
      return "asc";
    } else if (order === "descend") {
      return "desc";
    }
  };

  const getLongOrderDir = (order: "asc" | "desc" | undefined) => {
    if (order === "asc") {
      return "ascend" as SortOrder;
    } else if (order === "desc") {
      return "descend" as SortOrder;
    } else {
      return null;
    }
  };

  const handleSort: TableProps<any>["onChange"] = (_, __, sorter) => {
    sorter = sorter as SorterResult<any>;

    setSort({
      orderBy: sorter.column?.dataIndex?.toString(),
      orderDir: getShortOrderDir(sorter.order),
    });
  };

  // Call this inside the table columns and pass the sorted dataIndex to display the sorter icon state correctly
  const getSortOrder = useCallback(
    (dataIndex: string): { sortOrder: SortOrder } => {
      return { sortOrder: sort.orderBy === dataIndex ? getLongOrderDir(sort.orderDir) : null };
    },
    [sort],
  );

  return { handleSort, getSortOrder, sort, setSort };
};

export default useCustomHandleSort;
