/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AttendeeQuestionnaireSubmissionStatusModel } from '../apiModels';
// @ts-ignore
import { FileExportType } from '../apiModels';
// @ts-ignore
import { FileExportWithPdf } from '../apiModels';
// @ts-ignore
import { ForumQuestionnaireRequestModel } from '../apiModels';
// @ts-ignore
import { ForumQuestionnaireResponseModel } from '../apiModels';
// @ts-ignore
import { ForumQuestionnaireUpdateModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { PageResponseForumQuestionnaireResponseModel } from '../apiModels';
// @ts-ignore
import { QuestionnaireContent } from '../apiModels';
// @ts-ignore
import { QuestionnaireMatchedContent } from '../apiModels';
// @ts-ignore
import { QuestionnaireSubmissionStatus } from '../apiModels';
/**
 * ForumQuestionnaireApi - axios parameter creator
 * @export
 */
export const ForumQuestionnaireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesExportGet: async (forumId: number, exportType?: FileExportType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesExportGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/questionnaires/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search Questionnaires on forum by filter
         * @param {number} forumId 
         * @param {number} [groupId] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesGet: async (forumId: number, groupId?: number, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/questionnaires`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (groupId !== undefined) {
                localVarQueryParameter['GroupId'] = groupId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create questionnaire
         * @param {number} forumId 
         * @param {ForumQuestionnaireRequestModel} [forumQuestionnaireRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesPost: async (forumId: number, forumQuestionnaireRequestModel?: ForumQuestionnaireRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/questionnaires`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumQuestionnaireRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Questionnaire content (questions)
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdContentGet: async (forumId: number, questionnaireId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdContentGet', 'forumId', forumId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdContentGet', 'questionnaireId', questionnaireId)
            const localVarPath = `/forum/{forumId}/questionnaires/{questionnaireId}/content`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Questionnaire content (questions)
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {QuestionnaireContent} [questionnaireContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdContentPut: async (forumId: number, questionnaireId: number, questionnaireContent?: QuestionnaireContent, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdContentPut', 'forumId', forumId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdContentPut', 'questionnaireId', questionnaireId)
            const localVarPath = `/forum/{forumId}/questionnaires/{questionnaireId}/content`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionnaireContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {Array<number>} [forumGroupIds] 
         * @param {FileExportWithPdf} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdExportGet: async (forumId: number, questionnaireId: number, forumGroupIds?: Array<number>, exportType?: FileExportWithPdf, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdExportGet', 'forumId', forumId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdExportGet', 'questionnaireId', questionnaireId)
            const localVarPath = `/forum/{forumId}/questionnaires/{questionnaireId}/export`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (forumGroupIds) {
                localVarQueryParameter['forumGroupIds'] = forumGroupIds;
            }

            if (exportType !== undefined) {
                localVarQueryParameter['exportType'] = exportType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Questionnaire details
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdGet: async (forumId: number, questionnaireId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdGet', 'forumId', forumId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdGet', 'questionnaireId', questionnaireId)
            const localVarPath = `/forum/{forumId}/questionnaires/{questionnaireId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Questionnaire details
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {ForumQuestionnaireUpdateModel} [forumQuestionnaireUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdPut: async (forumId: number, questionnaireId: number, forumQuestionnaireUpdateModel?: ForumQuestionnaireUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdPut', 'forumId', forumId)
            // verify required parameter 'questionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesQuestionnaireIdPut', 'questionnaireId', questionnaireId)
            const localVarPath = `/forum/{forumId}/questionnaires/{questionnaireId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"questionnaireId"}}`, encodeURIComponent(String(questionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumQuestionnaireUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates matching of questions and options for source and target Questionnaires
         * @param {number} forumId 
         * @param {number} sourceQuestionnaireId 
         * @param {number} targetQuestionnaireId 
         * @param {Array<string>} [questionMap] Array of QuestionId paris: [from,to,from,to,from,to].   Specifies questions for option mapping.  Other questions are mapped by question Text.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet: async (forumId: number, sourceQuestionnaireId: number, targetQuestionnaireId: number, questionMap?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet', 'forumId', forumId)
            // verify required parameter 'sourceQuestionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet', 'sourceQuestionnaireId', sourceQuestionnaireId)
            // verify required parameter 'targetQuestionnaireId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet', 'targetQuestionnaireId', targetQuestionnaireId)
            const localVarPath = `/forum/{forumId}/questionnaires/{sourceQuestionnaireId}/match/{targetQuestionnaireId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"sourceQuestionnaireId"}}`, encodeURIComponent(String(sourceQuestionnaireId)))
                .replace(`{${"targetQuestionnaireId"}}`, encodeURIComponent(String(targetQuestionnaireId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (questionMap) {
                localVarQueryParameter['questionMap'] = questionMap;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} [attendeeId] 
         * @param {number} [forumGroupId] 
         * @param {Array<QuestionnaireSubmissionStatus>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesSubmissionGet: async (forumId: number, attendeeId?: number, forumGroupId?: number, statuses?: Array<QuestionnaireSubmissionStatus>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdQuestionnairesSubmissionGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/questionnaires/submission`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (attendeeId !== undefined) {
                localVarQueryParameter['AttendeeId'] = attendeeId;
            }

            if (forumGroupId !== undefined) {
                localVarQueryParameter['ForumGroupId'] = forumGroupId;
            }

            if (statuses) {
                localVarQueryParameter['Statuses'] = statuses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumQuestionnaireApi - functional programming interface
 * @export
 */
export const ForumQuestionnaireApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumQuestionnaireApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} forumId 
         * @param {FileExportType} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesExportGet(forumId: number, exportType?: FileExportType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesExportGet(forumId, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search Questionnaires on forum by filter
         * @param {number} forumId 
         * @param {number} [groupId] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesGet(forumId: number, groupId?: number, pageSize?: number, pageNumber?: number, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageResponseForumQuestionnaireResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesGet(forumId, groupId, pageSize, pageNumber, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create questionnaire
         * @param {number} forumId 
         * @param {ForumQuestionnaireRequestModel} [forumQuestionnaireRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesPost(forumId: number, forumQuestionnaireRequestModel?: ForumQuestionnaireRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumQuestionnaireResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesPost(forumId, forumQuestionnaireRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Questionnaire content (questions)
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesQuestionnaireIdContentGet(forumId: number, questionnaireId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesQuestionnaireIdContentGet(forumId, questionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Questionnaire content (questions)
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {QuestionnaireContent} [questionnaireContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesQuestionnaireIdContentPut(forumId: number, questionnaireId: number, questionnaireContent?: QuestionnaireContent, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesQuestionnaireIdContentPut(forumId, questionnaireId, questionnaireContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {Array<number>} [forumGroupIds] 
         * @param {FileExportWithPdf} [exportType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesQuestionnaireIdExportGet(forumId: number, questionnaireId: number, forumGroupIds?: Array<number>, exportType?: FileExportWithPdf, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesQuestionnaireIdExportGet(forumId, questionnaireId, forumGroupIds, exportType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Questionnaire details
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesQuestionnaireIdGet(forumId: number, questionnaireId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumQuestionnaireResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesQuestionnaireIdGet(forumId, questionnaireId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Questionnaire details
         * @param {number} forumId 
         * @param {number} questionnaireId 
         * @param {ForumQuestionnaireUpdateModel} [forumQuestionnaireUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesQuestionnaireIdPut(forumId: number, questionnaireId: number, forumQuestionnaireUpdateModel?: ForumQuestionnaireUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumQuestionnaireResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesQuestionnaireIdPut(forumId, questionnaireId, forumQuestionnaireUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates matching of questions and options for source and target Questionnaires
         * @param {number} forumId 
         * @param {number} sourceQuestionnaireId 
         * @param {number} targetQuestionnaireId 
         * @param {Array<string>} [questionMap] Array of QuestionId paris: [from,to,from,to,from,to].   Specifies questions for option mapping.  Other questions are mapped by question Text.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet(forumId: number, sourceQuestionnaireId: number, targetQuestionnaireId: number, questionMap?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuestionnaireMatchedContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet(forumId, sourceQuestionnaireId, targetQuestionnaireId, questionMap, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} [attendeeId] 
         * @param {number} [forumGroupId] 
         * @param {Array<QuestionnaireSubmissionStatus>} [statuses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdQuestionnairesSubmissionGet(forumId: number, attendeeId?: number, forumGroupId?: number, statuses?: Array<QuestionnaireSubmissionStatus>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AttendeeQuestionnaireSubmissionStatusModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdQuestionnairesSubmissionGet(forumId, attendeeId, forumGroupId, statuses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumQuestionnaireApi - factory interface
 * @export
 */
export const ForumQuestionnaireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumQuestionnaireApiFp(configuration)
    return {
        /**
         * 
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesExportGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdQuestionnairesExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search Questionnaires on forum by filter
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesGetRequest, options?: AxiosRequestConfig): AxiosPromise<PageResponseForumQuestionnaireResponseModel> {
            return localVarFp.forumForumIdQuestionnairesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create questionnaire
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesPost(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumQuestionnaireResponseModel> {
            return localVarFp.forumForumIdQuestionnairesPost(requestParameters.forumId, requestParameters.forumQuestionnaireRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Questionnaire content (questions)
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdContentGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGetRequest, options?: AxiosRequestConfig): AxiosPromise<QuestionnaireContent> {
            return localVarFp.forumForumIdQuestionnairesQuestionnaireIdContentGet(requestParameters.forumId, requestParameters.questionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Questionnaire content (questions)
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdContentPut(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdQuestionnairesQuestionnaireIdContentPut(requestParameters.forumId, requestParameters.questionnaireId, requestParameters.questionnaireContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdExportGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGetRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.forumForumIdQuestionnairesQuestionnaireIdExportGet(requestParameters.forumId, requestParameters.questionnaireId, requestParameters.forumGroupIds, requestParameters.exportType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Questionnaire details
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumQuestionnaireResponseModel> {
            return localVarFp.forumForumIdQuestionnairesQuestionnaireIdGet(requestParameters.forumId, requestParameters.questionnaireId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Questionnaire details
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesQuestionnaireIdPut(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<ForumQuestionnaireResponseModel> {
            return localVarFp.forumForumIdQuestionnairesQuestionnaireIdPut(requestParameters.forumId, requestParameters.questionnaireId, requestParameters.forumQuestionnaireUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates matching of questions and options for source and target Questionnaires
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<QuestionnaireMatchedContent> {
            return localVarFp.forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet(requestParameters.forumId, requestParameters.sourceQuestionnaireId, requestParameters.targetQuestionnaireId, requestParameters.questionMap, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdQuestionnairesSubmissionGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<AttendeeQuestionnaireSubmissionStatusModel>> {
            return localVarFp.forumForumIdQuestionnairesSubmissionGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumGroupId, requestParameters.statuses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdQuestionnairesExportGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesExportGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {FileExportType}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesExportGet
     */
    readonly exportType?: FileExportType
}

/**
 * Request parameters for forumForumIdQuestionnairesGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesGet
     */
    readonly groupId?: number

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdQuestionnairesPost operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesPostRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumQuestionnaireRequestModel}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesPost
     */
    readonly forumQuestionnaireRequestModel?: ForumQuestionnaireRequestModel
}

/**
 * Request parameters for forumForumIdQuestionnairesQuestionnaireIdContentGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGet
     */
    readonly questionnaireId: number
}

/**
 * Request parameters for forumForumIdQuestionnairesQuestionnaireIdContentPut operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPutRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPut
     */
    readonly questionnaireId: number

    /**
     * 
     * @type {QuestionnaireContent}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPut
     */
    readonly questionnaireContent?: QuestionnaireContent
}

/**
 * Request parameters for forumForumIdQuestionnairesQuestionnaireIdExportGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGet
     */
    readonly questionnaireId: number

    /**
     * 
     * @type {Array<number>}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGet
     */
    readonly forumGroupIds?: Array<number>

    /**
     * 
     * @type {FileExportWithPdf}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGet
     */
    readonly exportType?: FileExportWithPdf
}

/**
 * Request parameters for forumForumIdQuestionnairesQuestionnaireIdGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGet
     */
    readonly questionnaireId: number
}

/**
 * Request parameters for forumForumIdQuestionnairesQuestionnaireIdPut operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPutRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPut
     */
    readonly questionnaireId: number

    /**
     * 
     * @type {ForumQuestionnaireUpdateModel}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPut
     */
    readonly forumQuestionnaireUpdateModel?: ForumQuestionnaireUpdateModel
}

/**
 * Request parameters for forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet
     */
    readonly sourceQuestionnaireId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet
     */
    readonly targetQuestionnaireId: number

    /**
     * Array of QuestionId paris: [from,to,from,to,from,to].   Specifies questions for option mapping.  Other questions are mapped by question Text.
     * @type {Array<string>}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet
     */
    readonly questionMap?: Array<string>
}

/**
 * Request parameters for forumForumIdQuestionnairesSubmissionGet operation in ForumQuestionnaireApi.
 * @export
 * @interface ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGetRequest
 */
export interface ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGet
     */
    readonly attendeeId?: number

    /**
     * 
     * @type {number}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGet
     */
    readonly forumGroupId?: number

    /**
     * 
     * @type {Array<QuestionnaireSubmissionStatus>}
     * @memberof ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGet
     */
    readonly statuses?: Array<QuestionnaireSubmissionStatus>
}

/**
 * ForumQuestionnaireApi - object-oriented interface
 * @export
 * @class ForumQuestionnaireApi
 * @extends {BaseAPI}
 */
export class ForumQuestionnaireApi extends BaseAPI {
    /**
     * 
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesExportGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesExportGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesExportGet(requestParameters.forumId, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search Questionnaires on forum by filter
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create questionnaire
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesPost(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesPostRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesPost(requestParameters.forumId, requestParameters.forumQuestionnaireRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Questionnaire content (questions)
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesQuestionnaireIdContentGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesQuestionnaireIdContentGet(requestParameters.forumId, requestParameters.questionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Questionnaire content (questions)
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesQuestionnaireIdContentPut(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdContentPutRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesQuestionnaireIdContentPut(requestParameters.forumId, requestParameters.questionnaireId, requestParameters.questionnaireContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesQuestionnaireIdExportGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdExportGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesQuestionnaireIdExportGet(requestParameters.forumId, requestParameters.questionnaireId, requestParameters.forumGroupIds, requestParameters.exportType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Questionnaire details
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesQuestionnaireIdGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesQuestionnaireIdGet(requestParameters.forumId, requestParameters.questionnaireId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Questionnaire details
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesQuestionnaireIdPut(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesQuestionnaireIdPutRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesQuestionnaireIdPut(requestParameters.forumId, requestParameters.questionnaireId, requestParameters.forumQuestionnaireUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates matching of questions and options for source and target Questionnaires
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesSourceQuestionnaireIdMatchTargetQuestionnaireIdGet(requestParameters.forumId, requestParameters.sourceQuestionnaireId, requestParameters.targetQuestionnaireId, requestParameters.questionMap, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumQuestionnaireApi
     */
    public forumForumIdQuestionnairesSubmissionGet(requestParameters: ForumQuestionnaireApiForumForumIdQuestionnairesSubmissionGetRequest, options?: AxiosRequestConfig) {
        return ForumQuestionnaireApiFp(this.configuration).forumForumIdQuestionnairesSubmissionGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumGroupId, requestParameters.statuses, options).then((request) => request(this.axios, this.basePath));
    }
}

