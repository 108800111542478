import { AttendeeSortOptionsType } from "./types";

export const SORT_DIRS = {
  ASC: "asc",
  DESC: "desc",
};

export const SORT_VALS = {
  SURNAME: "surname",
  COMPANY: "companyName",
  TABLE: "forumTableNumber",
};

export const AttendeeSortOptions: AttendeeSortOptionsType = {
  sortByNameAsc: "Surname (A-Z)",
  sortByNameDesc: "Surname (Z-A)",
  sortByCompanyAsc: "Company (A-Z)",
  sortByCompanyDesc: "Company(Z-A)",
  sortByTableAsc: "Table (A-Z)",
  sortByTableDesc: "Table (Z-A)",
};

export const AttendeeSortOptionsMap = {
  [AttendeeSortOptions.sortByNameAsc]: { name: "sortByNameAsc", sortVal: SORT_VALS.SURNAME, sortDir: SORT_DIRS.ASC },
  [AttendeeSortOptions.sortByNameDesc]: { name: "sortByNameDesc", sortVal: SORT_VALS.SURNAME, sortDir: SORT_DIRS.DESC },
  [AttendeeSortOptions.sortByCompanyAsc]: {
    name: "sortByCompanyAsc",
    sortVal: SORT_VALS.COMPANY,
    sortDir: SORT_DIRS.ASC,
  },
  [AttendeeSortOptions.sortByCompanyDesc]: {
    name: "sortByCompanyDesc",
    sortVal: SORT_VALS.COMPANY,
    sortDir: SORT_DIRS.DESC,
  },
  [AttendeeSortOptions.sortByTableAsc]: { name: "sortByTableAsc", sortVal: SORT_VALS.TABLE, sortDir: SORT_DIRS.ASC },
  [AttendeeSortOptions.sortByTableDesc]: { name: "sortByTableDesc", sortVal: SORT_VALS.TABLE, sortDir: SORT_DIRS.DESC },
};

export const isSortButtonActive = (buttonKey: string, sortVal?: string, sortDir?: string) => {
  const key = Object.keys(AttendeeSortOptionsMap).filter((k) => AttendeeSortOptionsMap[k].name === buttonKey);

  const option = AttendeeSortOptionsMap[key as never];

  return option?.sortVal == sortVal && option?.sortDir == sortDir;
};

export const getSortDirAndVal = (buttonKey: string, sortDir?: string, sortVal?: string) => {
  const key = Object.keys(AttendeeSortOptionsMap).filter((k) => AttendeeSortOptionsMap[k].name === buttonKey);

  const option = AttendeeSortOptionsMap[key as never];

  return option?.sortVal === sortVal && option?.sortDir === sortDir
    ? { sortVal: undefined, sortDir: undefined }
    : { sortVal: option.sortVal, sortDir: option.sortDir };
};
