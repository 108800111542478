import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Col, ColProps, FormInstance, Row } from "antd";
import { FC, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import ComboBox from "../../../../components/ComboBox";
import { Button, Divider, Drawer, Form, Input, InputNumber, Modal, Select } from "../../../../components/styleguide";
import FacilityType from "../../../../enums/FacilityType";
import FacilityTypeCode from "../../../../enums/FacilityTypeCode";
import { FacilityRequestModel, FloorModel } from "../../../../generated/api";
import VenueContext from "../../../../pages/venueContext";

import styles from "./styles.module.less";

const { confirm } = Modal;

interface NewFacilityProps {
  isLoading: boolean;
  form: FormInstance<FacilityRequestModel>;
  visible: boolean;
  onClose: () => void;
  onSubmit: (values: FacilityRequestModel) => void;
}

type MeetingFacilityTypeEnum = keyof Omit<
  typeof FacilityType,
  "toDisplayName" | "toApiFacilityType" | "fromApiFacilityType"
>;

type FacilityTypeCodeEnum = keyof Omit<typeof FacilityTypeCode, "toDisplayName">;

const NewFacility: FC<NewFacilityProps> = ({ isLoading, form, visible, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const MeetingFacilityTypeEnumKeys = useMemo(
    () =>
      Object.keys(FacilityType).filter(
        (key) => !["toDisplayName", "fromApiFacilityType", "toApiFacilityType", "Bedroom"].includes(key),
      ) as Array<MeetingFacilityTypeEnum>,
    [],
  );

  const facilityTypes = useMemo(
    () =>
      MeetingFacilityTypeEnumKeys.map((key) => {
        return (
          <Select.Option value={FacilityType[key]} key={key}>
            {t(`facilityTypeNames.${FacilityType.toDisplayName(FacilityType[key])}`)}
          </Select.Option>
        );
      }),
    [t, MeetingFacilityTypeEnumKeys],
  );

  const FacilityTypeCodeEnumKeys = useMemo(
    () => Object.keys(FacilityTypeCode).filter((key) => key != "toDisplayName") as Array<FacilityTypeCodeEnum>,
    [],
  );

  const facilityTypeCodes = useMemo(
    () =>
      FacilityTypeCodeEnumKeys.map((key) => {
        return (
          <Select.Option value={FacilityTypeCode[key]} key={key}>
            {key}
          </Select.Option>
        );
      }),
    [FacilityTypeCodeEnumKeys],
  );

  const venueActions = useContext(VenueContext);

  const { floors, onFloorAdd, onFloorDelete } = venueActions;

  const addFloor = (name: string) => {
    if (floors.find(({ name: floorName }) => floorName.trim() === name.trim())) {
      toast.error(t("This floor already exists"));
      return;
    }
    onFloorAdd(name);
  };

  const deleteFloor = (floor: FloorModel) => {
    confirm({
      title: t("confirmation"),
      content: t("Do you want to delete this floor? There could be facilities assigned to this floor"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: async () => {
        if (floor.name === form.getFieldValue("floor")) {
          form.setFieldValue("floor", null);
        }
        onFloorDelete(floor.id);
      },
    });
  };

  const loadCode = () => {
    const type = form.getFieldValue("facilityType");
    form.setFieldValue("facilityCodeType", type);
  };

  return (
    <Drawer
      title={t("newFacility")}
      placement="right"
      contentWrapperStyle={{ minWidth: "50%" }}
      open={visible}
      onClose={onClose}
      getContainer={false}
      destroyOnClose
    >
      <Form
        id="newFacilityForm"
        className={styles.form}
        name="new-facility"
        labelCol={{ justify: "left", span: 24 } as ColProps}
        wrapperCol={{ justify: "right" } as ColProps}
        labelWrap
        layout="vertical"
        onFinish={onSubmit}
        form={form}
      >
        <div className={styles.formLayout}>
          <div>
            <Form.Item
              label={t("facilityCode")}
              name="code"
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: "$t(facilityCode)" }),
                },
                {
                  max: 5,
                  message: t("errors.max.facility_code", { count: 5 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("facilityNameDescription")}
              name="name"
              rules={[
                {
                  required: true,
                  message: t("errors.required", {
                    prop: "$t(facilityNameDescription)",
                  }),
                },
                {
                  max: 100,
                  message: t("errors.max.facility_description", { count: 100 }),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("facilityType")}
              name="facilityType"
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: "$t(facilityType)" }),
                },
              ]}
            >
              <Select onChange={loadCode}>{facilityTypes}</Select>
            </Form.Item>
            <Form.Item
              label={t("facilityTypeCode")}
              name="facilityCodeType"
              rules={[
                {
                  required: true,
                  message: t("errors.required", { prop: "$t(facilityTypeCode)" }),
                },
              ]}
            >
              <Select disabled>{facilityTypeCodes}</Select>
            </Form.Item>
            <Form.Item label={t("floor")} name="floor">
              <ComboBox
                options={floors}
                onAdd={addFloor}
                onDelete={deleteFloor}
                fieldNames={{
                  key: "id",
                  label: "name",
                  value: "name",
                }}
                maxLength={20}
              />
            </Form.Item>
            <Form.Item label={t("places")} name="places" extra={t("errors.maxAmount", { count: 1000 })}>
              <InputNumber style={{ width: "100%" }} min={1} max={1000} />
            </Form.Item>
            <Form.Item label={t("numberOfTables")} name="tables">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </div>
          <div>
            <Divider />
            <Row justify="space-between">
              <Col>
                <Button onClick={onClose}>{t("cancel")}</Button>
              </Col>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {t("addFacility")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Drawer>
  );
};

export default NewFacility;
