import { Layout } from "components/styleguide";
import UserGuide from "components/UserGuide";
import React from "react";

import GDPRRequestsTable from "./GDPRRequestsTable";

import styles from "./styles.module.less";

const { Content } = Layout;

const GDPRRequests = () => {
  return (
    <div className={styles.gdprRequestsPage}>
      <Layout>
        <Content>
          <UserGuide.TableTitle
            className={styles.container}
            articleId="16426515727389-gdpr-requests"
            title="GDPR requests"
          >
            <GDPRRequestsTable />
          </UserGuide.TableTitle>
        </Content>
      </Layout>
    </div>
  );
};

export default GDPRRequests;
