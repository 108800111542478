
import { Content } from "antd/lib/layout/layout";
import UserGuide from "components/UserGuide";
import React from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import styles from './styles.module.less';

const TimeSlots = () => {
  const { id } = useOutletContext();


  return (
    <div className={styles.timeslotsPage}>
      <UserGuide.Title title="Timeslots" articleId="16426966424605-timeslots" />

      <Content className={styles.timeslotsContent} >
        <Outlet context={{ id }} />
      </Content>
    </div>
  );
};

export default TimeSlots;
