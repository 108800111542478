import { TFunction } from "react-i18next";

const getFormFields = (t: TFunction) => [
  {
    title: t("Facebook"),
    dataIndex: "facebook",
  },
  {
    title: t("LinkedIn"),
    dataIndex: "linkedIn",
  },
  {
    title: t("Twitter"),
    dataIndex: "twitter",
  },
];

export { getFormFields };
