import { useQueryClient } from "@tanstack/react-query";
import { useDocumentDefinitionCustomPdfQuery } from "api/queries/forumDocumentDefinition";
import { Button, Form, Space, Table } from "components/styleguide";
import {
  DocumentCustomPdfModel,
  DocumentDefinitionSectionTypes,
  ForumDocumentDefinitionSectionCreateModel,
} from "generated/api";
import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getColumnDateRangeSearchProps, getColumnSearchProps } from "utils/tableColumnUtils";

import { useCreateForumDocumentDefinitionSectionMutation } from "../../../../../../api/mutations/forumDocumentDefinition";
import KEYS from "../../../../../../api/queries/keys";
import { TabsTableProps } from "../index";
import UploadPDFDrawer from "../UploadPDFDrawer";

const CustomPDFTable: React.FC<TabsTableProps> = ({ forumId, documentDefinitionType }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [customPDFs, setCustomPDFs] = useState<DocumentCustomPdfModel[]>();
  const [isUploadDrawerVisible, setIsUploadDrawerVisible] = useState(false);
  const [fileNameSearch, setFileNameSearch] = useState<string | undefined>(undefined);
  const [createdBySearch, setCreatedBySearch] = useState<string | undefined>(undefined);
  const [filterDateSearch, setFilterDateSearch] = useState<string | undefined>(undefined);
  const [filterToDateSearch, setFilterToDateSearch] = useState<string | undefined>(undefined);

  const forumDocumentDefinitionSectionMutation = useCreateForumDocumentDefinitionSectionMutation();

  const { isLoading } = useDocumentDefinitionCustomPdfQuery(
    {
      fileName: fileNameSearch,
      createdBy: createdBySearch,
      filterDate: filterDateSearch,
      filterToDate: filterToDateSearch,
    },
    {
      onSuccess: ({ data }) => {
        setCustomPDFs(data);
      },
    },
  );

  const showUploadDrawer = () => setIsUploadDrawerVisible(true);
  const hideUploadDrawer = () => {
    form.resetFields();
    setIsUploadDrawerVisible(false);
  };

  const handleInsertPdf = (record: DocumentCustomPdfModel) => {
    const forumDocumentDefinitionSectionCreateModel: ForumDocumentDefinitionSectionCreateModel = {
      documentDefinitionType,
      sectionType: DocumentDefinitionSectionTypes.CustomPdf,
      documentCustomPdfId: record.id,
    };

    forumDocumentDefinitionSectionMutation.mutate(
      { forumId, forumDocumentDefinitionSectionCreateModel },
      {
        onSuccess: () => {
          queryClient.resetQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS]);
        },
      },
    );
  };

  const handleSearch = (selectedKeys: string[], confirm: () => void, _: string, searchSet: string) => {
    switch (searchSet) {
      case "fileName": {
        setFileNameSearch(selectedKeys[0]);
        break;
      }
      case "createdAt": {
        setFilterDateSearch(selectedKeys[0]);
        setFilterToDateSearch(selectedKeys[1]);
        break;
      }
      case "createdBy": {
        setCreatedBySearch(selectedKeys[0]);
        break;
      }
    }

    confirm?.();
  };
  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "fileName": {
        setFileNameSearch(undefined);
        break;
      }
      case "createdAt": {
        setFilterDateSearch(undefined);
        setFilterToDateSearch(undefined);
        break;
      }
      case "createdBy": {
        setCreatedBySearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const columns = [
    {
      key: "fileName",
      dataIndex: "fileName",
      title: t("File Name"),
      ...(getColumnSearchProps("fileName", handleSearch, handleReset, "fileName") as Partial<DocumentCustomPdfModel>),
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: t("Date/Time"),
      render: (createdAt: string) => {
        return <span>{moment.utc(createdAt).utcOffset(moment().utcOffset()).format("L LT")}</span>;
      },
      ...(getColumnDateRangeSearchProps(
        "createdAt",
        handleSearch,
        handleReset,
        "createdAt",
      ) as Partial<DocumentCustomPdfModel>),
    },
    {
      key: "createdBy",
      dataIndex: "createdBy",
      title: t("Created by"),
      ...(getColumnSearchProps("createdBy", handleSearch, handleReset, "createdBy") as Partial<DocumentCustomPdfModel>),
    },
    {
      key: "id",
      dataIndex: "id",
      render: (_: number, record: DocumentCustomPdfModel) => (
        <Button onClick={() => handleInsertPdf(record)}>{t("Insert PDF")}</Button>
      ),
    },
  ];

  return (
    <>
      <UploadPDFDrawer form={form} visible={isUploadDrawerVisible} onClose={hideUploadDrawer} />
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button onClick={showUploadDrawer}>{t("Upload Custom PDF")}</Button>
        <Table
          bordered
          data-testid="custom-pdf-table"
          dataSource={customPDFs}
          columns={columns}
          loading={isLoading}
          rowKey="id"
        />
      </Space>
    </>
  );
};

export default CustomPDFTable;
