import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Drawer } from "../../../../../../../../../components/styleguide";
import { EventOptionsChoice } from "../../../../../../../../../generated/api";
import ChoiceSettingsForm from "../ChoiceSettingsForm";

interface AddNewChoiceProps {
  onSubmit?: (value: EventOptionsChoice) => void;
}

const AddNewChoice = ({ onSubmit }: AddNewChoiceProps) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const showDrawer = () => setIsVisible(true);
  const hideDrawer = () => setIsVisible(false);

  const handleOnSubmit = (value: EventOptionsChoice) => {
    onSubmit?.(value);
    hideDrawer();
  };

  return (
    <>
      <Button onClick={showDrawer}>{t("Add New Choice")}</Button>
      <Drawer
        open={isVisible}
        placement="right"
        title={t("Choice Details")}
        width="100%"
        destroyOnClose
        onClose={hideDrawer}
      >
        <ChoiceSettingsForm
          onFinish={handleOnSubmit}
          actionsPrepend={<Button onClick={hideDrawer}>{t("Cancel")}</Button>}
          actionsAppend={
            <Button type={"primary"} htmlType="submit">
              {t("Save")}
            </Button>
          }
        />
      </Drawer>
    </>
  );
};

export default AddNewChoice;
