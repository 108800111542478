import React from "react";
import { Button, Form, Input } from "../../../../../../../components/styleguide";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const OptionsColumnFormItem = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Form.List
      name={name}
      rules={[
        {
          validator: async (_, names) => {
            if (!names || names.length < 1) {
              return Promise.reject(new Error(t("At least 1 question must be provided")));
            }
          },
        },
      ]}
    >
      {(fields, { add, remove }, { errors }) => (
        <>
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
            >
              {t("Add new question")}
            </Button>
          </Form.Item>
          <Form.ErrorList errors={errors} />

          {fields.map((field, index) => (
            <Form.Item
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[{ required: true, message: t(`Question's text can't be empty`) }]}
                noStyle
              >
                <Input
                  placeholder={t("Enter question #{{n}}", { n: index + 1 })}
                  addonAfter={fields.length > 1 && (
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  )}
                />
              </Form.Item>
            </Form.Item>
          ))}
        </>
      )}
    </Form.List>
  );
};

OptionsColumnFormItem.propTypes = {
  name: PropTypes.string.isRequired,
};

export default OptionsColumnFormItem;
