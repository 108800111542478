import { SettingOutlined } from "@ant-design/icons";
import React, { FC, useState } from "react";

import { Button, Drawer } from "../../../../../../../../../../../components/styleguide";
import {
  QuestionnaireProfileDetailsOptionProfileDetails,
  QuestionOption,
} from "../../../../../../../../../../../generated/api";
import OptionsTable from "../OptionsTable";

interface OptionsButtonProps {
  title: string;
  options: Array<QuestionOption>;
  value: Array<QuestionnaireProfileDetailsOptionProfileDetails>;
  onChange?: (options: Array<QuestionnaireProfileDetailsOptionProfileDetails>) => void;
}

const OptionsButton: FC<OptionsButtonProps> = ({ value, title, options, onChange }) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const show = () => setIsDrawerVisible(true);
  const close = () => setIsDrawerVisible(false);

  return (
    <>
      <Button type="text" icon={<SettingOutlined />} onClick={show} />
      <Drawer open={isDrawerVisible} placement="right" title={title} size="large" destroyOnClose onClose={close}>
        <OptionsTable options={options} value={value} onChange={onChange} />
      </Drawer>
    </>
  );
};

export default OptionsButton;
