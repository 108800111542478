/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumDocumentCustomPageElementCreateModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentCustomPageElementUpdateModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentCustomPageListItemModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentCustomPageRequestModel } from '../apiModels';
// @ts-ignore
import { ForumDocumentCustomPageResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ReorderRequestModel } from '../apiModels';
/**
 * ForumDocumentCustomPageApi - axios parameter creator
 * @export
 */
export const ForumDocumentCustomPageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCustomPagesAvailableMergeTagsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/document-custom-pages/available-merge-tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an element of a custom page
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete: async (forumId: number, customPageId: number, elementId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete', 'forumId', forumId)
            // verify required parameter 'customPageId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete', 'customPageId', customPageId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete', 'elementId', elementId)
            const localVarPath = `/forums/{forumId}/document-custom-pages/{customPageId}/elements/{elementId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"customPageId"}}`, encodeURIComponent(String(customPageId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reorder the list of elements by moving one of them to a specific position
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {number} elementId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut: async (forumId: number, customPageId: number, elementId: number, reorderRequestModel?: ReorderRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut', 'forumId', forumId)
            // verify required parameter 'customPageId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut', 'customPageId', customPageId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut', 'elementId', elementId)
            const localVarPath = `/forums/{forumId}/document-custom-pages/{customPageId}/elements/{elementId}/order`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"customPageId"}}`, encodeURIComponent(String(customPageId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an element of a custom page
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {number} elementId 
         * @param {ForumDocumentCustomPageElementUpdateModel} [forumDocumentCustomPageElementUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut: async (forumId: number, customPageId: number, elementId: number, forumDocumentCustomPageElementUpdateModel?: ForumDocumentCustomPageElementUpdateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut', 'forumId', forumId)
            // verify required parameter 'customPageId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut', 'customPageId', customPageId)
            // verify required parameter 'elementId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut', 'elementId', elementId)
            const localVarPath = `/forums/{forumId}/document-custom-pages/{customPageId}/elements/{elementId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"customPageId"}}`, encodeURIComponent(String(customPageId)))
                .replace(`{${"elementId"}}`, encodeURIComponent(String(elementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentCustomPageElementUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Request body properties are not required, if not provided they will be populated with the default values
         * @summary Create a new element for a custom page
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {ForumDocumentCustomPageElementCreateModel} [forumDocumentCustomPageElementCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsPost: async (forumId: number, customPageId: number, forumDocumentCustomPageElementCreateModel?: ForumDocumentCustomPageElementCreateModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsPost', 'forumId', forumId)
            // verify required parameter 'customPageId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdElementsPost', 'customPageId', customPageId)
            const localVarPath = `/forums/{forumId}/document-custom-pages/{customPageId}/elements`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"customPageId"}}`, encodeURIComponent(String(customPageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentCustomPageElementCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a custom page configured for a forum by id
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdGet: async (forumId: number, customPageId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdGet', 'forumId', forumId)
            // verify required parameter 'customPageId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesCustomPageIdGet', 'customPageId', customPageId)
            const localVarPath = `/forums/{forumId}/document-custom-pages/{customPageId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"customPageId"}}`, encodeURIComponent(String(customPageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get custom pages configured for a forum
         * @param {number} forumId 
         * @param {string} [name] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesGet: async (forumId: number, name?: string, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-custom-pages`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new custom page and configures default HTMLText element \"Change this text\"
         * @param {number} forumId 
         * @param {ForumDocumentCustomPageRequestModel} [forumDocumentCustomPageRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesPost: async (forumId: number, forumDocumentCustomPageRequestModel?: ForumDocumentCustomPageRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdDocumentCustomPagesPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/document-custom-pages`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumDocumentCustomPageRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumDocumentCustomPageApi - functional programming interface
 * @export
 */
export const ForumDocumentCustomPageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumDocumentCustomPageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentCustomPagesAvailableMergeTagsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentCustomPagesAvailableMergeTagsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an element of a custom page
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {number} elementId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete(forumId: number, customPageId: number, elementId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete(forumId, customPageId, elementId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reorder the list of elements by moving one of them to a specific position
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {number} elementId 
         * @param {ReorderRequestModel} [reorderRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut(forumId: number, customPageId: number, elementId: number, reorderRequestModel?: ReorderRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut(forumId, customPageId, elementId, reorderRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an element of a custom page
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {number} elementId 
         * @param {ForumDocumentCustomPageElementUpdateModel} [forumDocumentCustomPageElementUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut(forumId: number, customPageId: number, elementId: number, forumDocumentCustomPageElementUpdateModel?: ForumDocumentCustomPageElementUpdateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut(forumId, customPageId, elementId, forumDocumentCustomPageElementUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Request body properties are not required, if not provided they will be populated with the default values
         * @summary Create a new element for a custom page
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {ForumDocumentCustomPageElementCreateModel} [forumDocumentCustomPageElementCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesCustomPageIdElementsPost(forumId: number, customPageId: number, forumDocumentCustomPageElementCreateModel?: ForumDocumentCustomPageElementCreateModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesCustomPageIdElementsPost(forumId, customPageId, forumDocumentCustomPageElementCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a custom page configured for a forum by id
         * @param {number} forumId 
         * @param {number} customPageId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesCustomPageIdGet(forumId: number, customPageId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumDocumentCustomPageResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesCustomPageIdGet(forumId, customPageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get custom pages configured for a forum
         * @param {number} forumId 
         * @param {string} [name] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesGet(forumId: number, name?: string, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumDocumentCustomPageListItemModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesGet(forumId, name, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new custom page and configures default HTMLText element \"Change this text\"
         * @param {number} forumId 
         * @param {ForumDocumentCustomPageRequestModel} [forumDocumentCustomPageRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdDocumentCustomPagesPost(forumId: number, forumDocumentCustomPageRequestModel?: ForumDocumentCustomPageRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumDocumentCustomPageResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdDocumentCustomPagesPost(forumId, forumDocumentCustomPageRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumDocumentCustomPageApi - factory interface
 * @export
 */
export const ForumDocumentCustomPageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumDocumentCustomPageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentCustomPagesAvailableMergeTagsGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.documentCustomPagesAvailableMergeTagsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an element of a custom page
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete(requestParameters.forumId, requestParameters.customPageId, requestParameters.elementId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reorder the list of elements by moving one of them to a specific position
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut(requestParameters.forumId, requestParameters.customPageId, requestParameters.elementId, requestParameters.reorderRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an element of a custom page
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut(requestParameters.forumId, requestParameters.customPageId, requestParameters.elementId, requestParameters.forumDocumentCustomPageElementUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Request body properties are not required, if not provided they will be populated with the default values
         * @summary Create a new element for a custom page
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdElementsPost(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdDocumentCustomPagesCustomPageIdElementsPost(requestParameters.forumId, requestParameters.customPageId, requestParameters.forumDocumentCustomPageElementCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a custom page configured for a forum by id
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesCustomPageIdGet(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumDocumentCustomPageResponseModel> {
            return localVarFp.forumsForumIdDocumentCustomPagesCustomPageIdGet(requestParameters.forumId, requestParameters.customPageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get custom pages configured for a forum
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesGet(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumDocumentCustomPageListItemModel>> {
            return localVarFp.forumsForumIdDocumentCustomPagesGet(requestParameters.forumId, requestParameters.name, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new custom page and configures default HTMLText element \"Change this text\"
         * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdDocumentCustomPagesPost(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumDocumentCustomPageResponseModel> {
            return localVarFp.forumsForumIdDocumentCustomPagesPost(requestParameters.forumId, requestParameters.forumDocumentCustomPageRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDeleteRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete
     */
    readonly customPageId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete
     */
    readonly elementId: number
}

/**
 * Request parameters for forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPutRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut
     */
    readonly customPageId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut
     */
    readonly elementId: number

    /**
     * 
     * @type {ReorderRequestModel}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut
     */
    readonly reorderRequestModel?: ReorderRequestModel
}

/**
 * Request parameters for forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPutRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut
     */
    readonly customPageId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut
     */
    readonly elementId: number

    /**
     * 
     * @type {ForumDocumentCustomPageElementUpdateModel}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut
     */
    readonly forumDocumentCustomPageElementUpdateModel?: ForumDocumentCustomPageElementUpdateModel
}

/**
 * Request parameters for forumsForumIdDocumentCustomPagesCustomPageIdElementsPost operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPostRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPost
     */
    readonly customPageId: number

    /**
     * 
     * @type {ForumDocumentCustomPageElementCreateModel}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPost
     */
    readonly forumDocumentCustomPageElementCreateModel?: ForumDocumentCustomPageElementCreateModel
}

/**
 * Request parameters for forumsForumIdDocumentCustomPagesCustomPageIdGet operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGetRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGet
     */
    readonly customPageId: number
}

/**
 * Request parameters for forumsForumIdDocumentCustomPagesGet operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGetRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {string}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumsForumIdDocumentCustomPagesPost operation in ForumDocumentCustomPageApi.
 * @export
 * @interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPostRequest
 */
export interface ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumDocumentCustomPageRequestModel}
     * @memberof ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPost
     */
    readonly forumDocumentCustomPageRequestModel?: ForumDocumentCustomPageRequestModel
}

/**
 * ForumDocumentCustomPageApi - object-oriented interface
 * @export
 * @class ForumDocumentCustomPageApi
 * @extends {BaseAPI}
 */
export class ForumDocumentCustomPageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public documentCustomPagesAvailableMergeTagsGet(options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).documentCustomPagesAvailableMergeTagsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an element of a custom page
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdDelete(requestParameters.forumId, requestParameters.customPageId, requestParameters.elementId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reorder the list of elements by moving one of them to a specific position
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdOrderPut(requestParameters.forumId, requestParameters.customPageId, requestParameters.elementId, requestParameters.reorderRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an element of a custom page
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPutRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesCustomPageIdElementsElementIdPut(requestParameters.forumId, requestParameters.customPageId, requestParameters.elementId, requestParameters.forumDocumentCustomPageElementUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Request body properties are not required, if not provided they will be populated with the default values
     * @summary Create a new element for a custom page
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesCustomPageIdElementsPost(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdElementsPostRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesCustomPageIdElementsPost(requestParameters.forumId, requestParameters.customPageId, requestParameters.forumDocumentCustomPageElementCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a custom page configured for a forum by id
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesCustomPageIdGet(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesCustomPageIdGetRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesCustomPageIdGet(requestParameters.forumId, requestParameters.customPageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get custom pages configured for a forum
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesGet(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesGetRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesGet(requestParameters.forumId, requestParameters.name, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new custom page and configures default HTMLText element \"Change this text\"
     * @param {ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumDocumentCustomPageApi
     */
    public forumsForumIdDocumentCustomPagesPost(requestParameters: ForumDocumentCustomPageApiForumsForumIdDocumentCustomPagesPostRequest, options?: AxiosRequestConfig) {
        return ForumDocumentCustomPageApiFp(this.configuration).forumsForumIdDocumentCustomPagesPost(requestParameters.forumId, requestParameters.forumDocumentCustomPageRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

