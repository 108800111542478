import { ReasonForMeetingItem } from "generated/api";
import { FC } from "react";

import ReasonForMeetingComponent from "./components/ReasonForMeetingComponent";

interface ReasonForMeetingProps {
  forumId: number;
  selectionSiteId: number;
  item: ReasonForMeetingItem;
  onChange: (item: ReasonForMeetingItem) => void;
}

const ReasonForMeeting: FC<ReasonForMeetingProps> = ({ forumId, selectionSiteId, item, onChange }) => {
  return (
    <ReasonForMeetingComponent<ReasonForMeetingItem>
      forumId={forumId}
      selectionSiteId={selectionSiteId}
      item={item}
      onChange={onChange}
    />
  );
};

export default ReasonForMeeting;
