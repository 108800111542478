import { LockFilled, UnlockOutlined } from "@ant-design/icons";
import { BaseOptionType } from "antd/lib/select";
import { TablePaginationConfig } from "antd/lib/table";
import { Select, Table } from "components/styleguide";
import {
  FacilityResponseModel,
  MeetingLocationResponseModel,
  TableModel,
  TimeSlotSessionResponseModel,
} from "generated/api";
import moment from "moment";
import { FC, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getColumnSearchProps, getColumnSelectFilterProps } from "utils/tableColumnUtils";

import styles from "./styles.module.less";

interface MeetingLocationsTableProps {
  isLoading: boolean;
  meetingLocations: MeetingLocationResponseModel[];
  facilities: FacilityResponseModel[];
  sessions?: TimeSlotSessionResponseModel[];
  peopleTypeFilterOptions: BaseOptionType[];
  facilityFilterOptions: BaseOptionType[];
  pagination: false | TablePaginationConfig | undefined;
  setAttendeeNameSearch: (value: SetStateAction<string | undefined>) => void;
  setPeopleTypeFilter: (value: SetStateAction<string[] | undefined>) => void;
  setFacilityFilter: (value: SetStateAction<number[] | undefined>) => void;
  setCompanyNameSearch: (value: SetStateAction<string | undefined>) => void;
  onFacilityChange: (facilityId: number, attendeeId: number) => void;
  onTableChange: (tableId: number, attendeeId: number) => void;
  onRatioChange: (ratio: number, attendeeId: number) => void;
  onLockedClicked: (isLocked: boolean, attendeeId: number) => void;
  onSessionChange?: (timeSlotSessionId: number, attendeeId: number) => void;
  onSort: (_: unknown, __: unknown, sort: unknown) => void;
}

const columnDataIndexes = {
  attendeeName: "forumAttendeeName",
  jobTitle: "forumAttendeeJobTitle",
  participationCode: "forumAttendeeParticipationCode",
  companyName: "forumCompanyName",
  facility: "forumFacilityId",
  table: "forumTableId",
  ratio: "ratio",
  attendeeId: "forumAttendeeId",
  timeslotSessionId: "timeSlotSessionId",
  isLocked: "isLocked",
};

const MeetingLocationsTable: FC<MeetingLocationsTableProps> = ({
  isLoading,
  meetingLocations,
  facilities,
  sessions,
  peopleTypeFilterOptions,
  facilityFilterOptions,
  pagination,
  setAttendeeNameSearch,
  setPeopleTypeFilter,
  setFacilityFilter,
  setCompanyNameSearch,
  onFacilityChange,
  onTableChange,
  onRatioChange,
  onSessionChange,
  onLockedClicked,
  onSort,
}) => {
  const { t } = useTranslation();

  const handleSearch = useCallback(
    (selectedKeys: string[] | BaseOptionType[], confirm: () => void, _: string, searchSet: string) => {
      switch (searchSet) {
        case columnDataIndexes.attendeeName: {
          setAttendeeNameSearch(selectedKeys[0] as string);
          break;
        }
        case columnDataIndexes.participationCode: {
          setPeopleTypeFilter((selectedKeys as BaseOptionType[]).map((o) => o.value));
          break;
        }
        case columnDataIndexes.companyName: {
          setCompanyNameSearch(selectedKeys[0] as string);
          break;
        }
        case columnDataIndexes.facility: {
          setFacilityFilter((selectedKeys as BaseOptionType[]).map((o) => o.value));
          break;
        }
      }

      confirm?.();
    },
    [setAttendeeNameSearch, setPeopleTypeFilter, setCompanyNameSearch, setFacilityFilter],
  );
  const handleReset = useCallback(
    (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
      switch (searchSet) {
        case columnDataIndexes.attendeeName: {
          setAttendeeNameSearch(undefined);
          break;
        }
        case columnDataIndexes.participationCode: {
          setPeopleTypeFilter(undefined);
          break;
        }
        case columnDataIndexes.companyName: {
          setCompanyNameSearch(undefined);
          break;
        }
        case columnDataIndexes.facility: {
          setFacilityFilter(undefined);
          break;
        }
      }

      clearFilters?.();
      confirm?.();
    },
    [setAttendeeNameSearch, setPeopleTypeFilter, setCompanyNameSearch, setFacilityFilter],
  );

  const columns = useMemo(
    () =>
      [
        {
          title: t("Attendee Name"),
          dataIndex: columnDataIndexes.attendeeName,
          sorter: true,
          ...(getColumnSearchProps(
            columnDataIndexes.attendeeName,
            handleSearch,
            handleReset,
            columnDataIndexes.attendeeName,
          ) as Partial<MeetingLocationResponseModel>),
        },
        {
          title: t("Job Title"),
          dataIndex: columnDataIndexes.jobTitle,
          sorter: true,
        },
        {
          title: t("People Type"),
          dataIndex: columnDataIndexes.participationCode,
          sorter: true,
          ...(getColumnSelectFilterProps(
            columnDataIndexes.participationCode,
            handleSearch,
            handleReset,
            columnDataIndexes.participationCode,
            peopleTypeFilterOptions,
          ) as Partial<MeetingLocationResponseModel>),
        },
        {
          title: t("Company Name"),
          dataIndex: columnDataIndexes.companyName,
          sorter: true,
          ...(getColumnSearchProps(
            columnDataIndexes.companyName,
            handleSearch,
            handleReset,
            columnDataIndexes.companyName,
          ) as Partial<MeetingLocationResponseModel>),
        },
        {
          title: t("Facility"),
          dataIndex: columnDataIndexes.facility,
          render: (facilityId: number, record: MeetingLocationResponseModel) => {
            return (
              <Select
                disabled={record.isLocked ?? false}
                style={{ width: "100%" }}
                options={[
                  { value: null, label: t("None") },
                  ...(facilities?.map((facility: FacilityResponseModel) => ({
                    value: facility.id,
                    label: facility.name,
                    forumBuildingId: facility.buildingId,
                  })) ?? []),
                ]}
                value={facilityId}
                onChange={(value) => onFacilityChange(value, record.forumAttendeeId)}
                showSearch
                optionFilterProp="label"
              />
            );
          },
          ...getColumnSelectFilterProps(
            columnDataIndexes.facility,
            handleSearch,
            handleReset,
            columnDataIndexes.facility,
            facilityFilterOptions,
          ),
        },
        {
          title: t("Table"),
          dataIndex: columnDataIndexes.table,
          render: (tableId: number, record: MeetingLocationResponseModel) => {
            return (
              <Select
                disabled={record.isLocked ?? false}
                style={{ width: "100%" }}
                options={[
                  { value: null, label: t("None") },
                  ...(record.selectedFacilityTables?.map((facility: TableModel) => ({
                    value: facility.id,
                    label: facility.tableNumber,
                  })) ?? []),
                ]}
                value={tableId}
                onChange={(value) => onTableChange(value, record.forumAttendeeId)}
              />
            );
          },
        },
        {
          title: t("Ratio"),
          dataIndex: columnDataIndexes.ratio,
          render: (radio: number, record: MeetingLocationResponseModel) => {
            return (
              <Select
                disabled={record.isLocked ?? false}
                style={{ width: "100%" }}
                options={[
                  { value: null, label: t("None") },
                  ...[...Array(13).keys()].map((value: number) => ({
                    value,
                    label: value,
                  })),
                ]}
                value={radio}
                onChange={(value) => onRatioChange(value, record.forumAttendeeId)}
              />
            );
          },
        },
        {
          title: t("Session"),
          dataIndex: columnDataIndexes.timeslotSessionId,
          render: (timeslotSessionId: number, record: MeetingLocationResponseModel) => {
            return (
              <Select
                disabled={record.isLocked ?? false}
                style={{ width: "100%" }}
                options={[
                  ...(sessions
                    ?.filter((session) => session.timeSlotId === record.timeSlotId)
                    .map((session: TimeSlotSessionResponseModel) => ({
                      value: session.id,
                      label: `${moment(session.startTime).format("L LT")} - ${moment(session.endTime).format("L LT")}`,
                    })) ?? []),
                ]}
                value={timeslotSessionId}
                onChange={(value) => (onSessionChange ? onSessionChange(value, record.forumAttendeeId) : false)}
              />
            );
          },
        },
        {
          title: t("Locked"),
          dataIndex: columnDataIndexes.isLocked,
          render: (isLocked: boolean, record: MeetingLocationResponseModel) => {
            return (
              <span className={styles.locked}>
                {isLocked ? (
                  <LockFilled
                    style={{ fontSize: "large" }}
                    onClick={() => onLockedClicked(!isLocked, record.forumAttendeeId)}
                  />
                ) : (
                  <UnlockOutlined
                    style={{ fontSize: "large" }}
                    onClick={() => onLockedClicked(!isLocked, record.forumAttendeeId)}
                  />
                )}
              </span>
            );
          },
        },
      ].filter((col) =>
        sessions
          ? col
          : col.dataIndex !== columnDataIndexes.timeslotSessionId && col.dataIndex !== columnDataIndexes.isLocked,
      ),
    [
      facilities,
      handleReset,
      handleSearch,
      onFacilityChange,
      peopleTypeFilterOptions,
      facilityFilterOptions,
      t,
      sessions,
    ],
  );

  return (
    <Table
      columns={columns}
      loading={isLoading}
      dataSource={meetingLocations}
      pagination={pagination}
      onChange={onSort}
      rowKey="id"
    />
  );
};

export default MeetingLocationsTable;
