/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePicker, Input, Select } from "components/styleguide";
import {
  BillingNoteResponseModel,
  BuildingResponseModel,
  FacilityResponseModel,
  ForumAttendeeHotelRoomAllocationResponseModel,
  ForumResponseModel,
  RoomGradeResponseModel,
} from "generated/api";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";

const { Option } = Select;
const { TextArea } = Input;

const dateFormat = "L";

interface FormFieldsProps {
  billingNotes: BillingNoteResponseModel[];
  hotelAndRoomAllocation: ForumAttendeeHotelRoomAllocationResponseModel;
  roomGrades: RoomGradeResponseModel[];
  bedrooms: FacilityResponseModel[];
  buildings: BuildingResponseModel[] | undefined;
  roomGradeId: number | undefined;
  buildingId: number | undefined;
  bedroomId: number | undefined;
  forum: ForumResponseModel;
  onChangeRoomGrade: (value: number) => void;
  onChangeBuilding: (value: number) => void;
  onChangeBedroom: (value: number, { code }: any) => void;
}

const handleSearch = (inputValue: string, option: any) => {
  return option.children.toLowerCase().includes(inputValue.toLowerCase());
};

const getFormattedDate = (date: string | null) => (date ? moment(date).format(dateFormat) : "-");

const useGetFormFields = ({
  billingNotes,
  hotelAndRoomAllocation,
  roomGrades,
  bedrooms,
  roomGradeId,
  buildingId,
  bedroomId,
  onChangeRoomGrade,
  onChangeBedroom,
  onChangeBuilding,
  buildings,
  forum = {},
}: FormFieldsProps) => {
  const idBedroomsEmpty = useMemo(() => !isEmpty(bedrooms), [isEmpty(bedrooms)]);

  const formFields = [
    { title: "Hotel", dataIndex: "hotel", isReadonly: true },
    { title: "Address", dataIndex: "address", isReadonly: true },
    { title: "City", dataIndex: "city", isReadonly: true },
    { title: "County", dataIndex: "county", isReadonly: true },
    { title: "Postcode", dataIndex: "postcode", isReadonly: true },
    { title: "Country", dataIndex: "country", isReadonly: true },
    {
      title: "Check in",
      dataIndex: "checkInDate",
      initialValue: hotelAndRoomAllocation?.checkInDate
        ? moment(hotelAndRoomAllocation.checkInDate)
        : moment(forum?.startDate),
      label: getFormattedDate(hotelAndRoomAllocation.checkInDate),
      component: <DatePicker style={{ width: "100%" }} allowClear={false} format={dateFormat} />,
    },
    {
      title: "Check out",
      dataIndex: "checkOutDate",
      initialValue: hotelAndRoomAllocation?.checkOutDate
        ? moment(hotelAndRoomAllocation.checkOutDate)
        : moment(forum?.endDate),
      label: getFormattedDate(hotelAndRoomAllocation.checkOutDate),
      component: <DatePicker style={{ width: "100%" }} allowClear={false} format={dateFormat} />,
    },
    {
      title: "Building",
      dataIndex: "forumBuildingId",
      label: buildings?.find(({ id }) => id === hotelAndRoomAllocation.forumBuildingId)?.name,
      initialValue: buildingId,
      component: (
        <Select disabled={isEmpty(buildings)} onChange={onChangeBuilding}>
          {buildings?.map(({ id, name }) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Room Grade",
      dataIndex: "roomGradeId",
      label: hotelAndRoomAllocation.roomGrade,
      initialValue: roomGradeId,
      component: (
        <Select
          showSearch
          disabled={isEmpty(roomGrades)}
          onChange={onChangeRoomGrade}
          allowClear={true}
          optionFilterProp={"label"}
          options={roomGrades.map(({ id, name }) => ({ value: id, label: name }))}
        />
      ),
    },
    {
      title: "Bedroom Name",
      dataIndex: "bedroomId",
      label: hotelAndRoomAllocation?.bedroomName,
      initialValue: bedroomId,
      rules: [{ required: idBedroomsEmpty, message: "" }],
      component: (
        <Select showSearch disabled={isEmpty(bedrooms)} onChange={onChangeBedroom} filterOption={handleSearch}>
          {bedrooms.map(({ name, id, code }) => (
            <Option key={id} value={id} code={code}>
              {name}
            </Option>
          ))}
        </Select>
      ),
    },
    { title: "Facility Code", dataIndex: "facilityCode" },
    {
      title: "Hotel Billing",
      dataIndex: "billingNoteId",
      label: billingNotes.find(({ id }) => id === hotelAndRoomAllocation.billingNoteId)?.type || "-",
      component: (
        <Select disabled={!bedroomId}>
          {billingNotes.map(({ id, type }) => (
            <Option key={id} value={id}>
              {type}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Hotel Note",
      dataIndex: "hotelNote",
      component: <TextArea rows={3} cols={6} style={{ width: "100%" }} />,
    },
  ];

  return [formFields];
};

export default useGetFormFields;
