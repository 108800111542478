import { Button, Divider, Form, Input, Select } from "components/styleguide";
import { StreamRequestModel, StreamResponseModel } from "generated/api";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

interface StreamFormProps {
  initialValues?: StreamResponseModel;
  submitButtonTitle: string;
  isLoading: boolean;
  onSubmit: (values: StreamRequestModel) => void;
  onClose: () => void;
}

const StreamForm: FC<StreamFormProps> = ({ initialValues, submitButtonTitle, isLoading, onSubmit, onClose }) => {
  const { t } = useTranslation();

  return (
    <Form className={styles.form} initialValues={initialValues} onFinish={onSubmit}>
      <Form.Item
        label={t("Type")}
        name="type"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(type)" }),
          },
        ]}
      >
        <Input placeholder={t("placeholders.add", { prop: "$t(type)" })} />
      </Form.Item>
      <Form.Item
        label={t("description")}
        name="description"
        labelCol={{ span: 24, offset: 0 }}
        rules={[
          {
            required: true,
            message: t("errors.required", { prop: "$t(description)" }),
          },
        ]}
      >
        <Input.TextArea placeholder={t("placeholders.add", { prop: "$t(description)" })} />
      </Form.Item>
      <Form.Item label={t("stream.selectAll")} name="streamSelectionType" labelCol={{ span: 24, offset: 0 }}>
        <Select allowClear>
          <Select.Option value={1}>{t("stream.selectAllOptions.no")}</Select.Option>
          <Select.Option value={2}>{t("stream.selectAllOptions.yes")}</Select.Option>
        </Select>
      </Form.Item>
      <div style={{ marginTop: "auto" }} />
      <Divider />
      <div>
        <div className={styles.footer}>
          <Button onClick={onClose}>{t("stream.cancel")}</Button>
          <div className={styles.spacer} />
          <Button type="primary" disabled={isLoading} loading={isLoading} htmlType="submit">
            {t(submitButtonTitle)}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default StreamForm;
