import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "../../../../../../../components/styleguide";
import SelectionSitesPropTypes from "../../../../../../../propTypes/selectionSites";
import DefaultForm from "../DefaultForm";
import { YesNo } from "../inputs";

const FreeText = ({ item, onChange }) => {
  const { t } = useTranslation();

  return (
    <DefaultForm
      itemId={item.id}
      initialValues={item}
      onChange={onChange}
      options={() => (
        <Form.Item label={t("Display localised labels")} name="displayLocalisedLabels">
          <YesNo />
        </Form.Item>
      )}
    />
  );
};

FreeText.propTypes = {
  item: SelectionSitesPropTypes.Item.FreeText.isRequired,
  onChange: PropTypes.func,
};

FreeText.defaultProps = {
  onChange: () => undefined,
};

export default FreeText;
