import { DownOutlined } from "@ant-design/icons";
import { useGetForumFeedbackExportQuery } from "api/queries/forumFeedback";
import { Button, Dropdown } from "components/styleguide";
import { ForumFeedBackGroupType, ItineraryItemType } from "generated/api";
import { toNumber } from "lodash";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

interface ExportFeedbackProps {
  group: ForumFeedBackGroupType;
}

const ExportFeedback: FC<ExportFeedbackProps> = ({ group }) => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);

  const [type, setType] = useState<ItineraryItemType | null>(null);

  const { isFetching: isForumFeedbackExportLoading } = useGetForumFeedbackExportQuery(
    {
      forumId,
      group,
      type: type as ItineraryItemType,
    },
    {
      enabled: !!type,
      refetchOnWindowFocus: false,
      onSuccess: ({ data: link }) => downloadFileByURL(link),
      onError: () => toast.error(t("Something went wrong")),
      onSettled: () => setType(null),
    },
  );

  const menu = {
    items: [
      {
        label: t("Meeting Feedback"),
        key: "meeting",
        onClick: () => setType(ItineraryItemType.Meetings),
      },
      {
        label: t("Conference Feedback"),
        key: "conference",
        onClick: () => setType(ItineraryItemType.Conferences),
      },
    ],
  };

  return (
    <Dropdown {...{ menu }}>
      <Button loading={isForumFeedbackExportLoading}>
        {t("Export Feedback")}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default ExportFeedback;
