import { TableProps } from "antd";
import { Checkbox, Input, Table } from "components/styleguide";
import { QuestionaireSearchIndexQuestionSearchIndex, QuestionnaireContentQuestion } from "generated/api";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { replaceAt } from "utils/arrayUtils";

export interface ListingTableProps {
  questions: Array<QuestionnaireContentQuestion>;
  value?: Array<QuestionaireSearchIndexQuestionSearchIndex>;
  onChange?: (value: Array<QuestionaireSearchIndexQuestionSearchIndex>) => void;
}

const ListingTable: FC<ListingTableProps> = ({ questions, value, onChange }) => {
  const { t } = useTranslation();

  const questionsMap = useMemo(() => {
    return questions.reduce((map, question) => {
      map.set(question.id, question);
      return map;
    }, new Map<QuestionnaireContentQuestion["id"], QuestionnaireContentQuestion>());
  }, [questions]);

  const updateByIndex = (index: number, patch: Partial<QuestionaireSearchIndexQuestionSearchIndex>) => {
    if (!value || !onChange) {
      return;
    }

    const newValue = {
      ...value[index],
      ...patch,
    };

    const newArray = replaceAt(value, index, newValue);
    onChange(newArray);
  };

  const columns: TableProps<QuestionaireSearchIndexQuestionSearchIndex>["columns"] = [
    {
      dataIndex: "questionId",
      title: t("Question"),
      render: (questionId) => questionsMap.get(questionId)?.text,
      width: "25%",
    },
    {
      dataIndex: "advancedSearchTitle",
      title: t("Advanced search title"),
      render: (advancedSearchTitle, record, index) => {
        return (
          <Input
            value={advancedSearchTitle}
            lazy
            onChange={(e) => updateByIndex(index, { advancedSearchTitle: e.target.value })}
          />
        );
      },
    },
    {
      dataIndex: "freeText",
      title: t("Free Text"),
      render: (freeText, record, index) => {
        return <Checkbox checked={freeText} onChange={(e) => updateByIndex(index, { freeText: e.target.checked })} />;
      },
      width: 150,
    },
    {
      dataIndex: "advancedSearch",
      title: t("Advanced Search"),
      render: (advancedSearch, record, index) => {
        return (
          <Checkbox
            checked={advancedSearch}
            onChange={(e) => updateByIndex(index, { advancedSearch: e.target.checked })}
          />
        );
      },
      width: 150,
    },
  ];

  return <Table columns={columns} dataSource={value} rowKey="questionId" pagination={false} />;
};

export default ListingTable;
