import { useQueryClient } from "@tanstack/react-query";
import { useAddDocumentToQueueMutation } from "api/mutations/itineraryBooklets";
import { useAttendeesQuery } from "api/queries/attendees";
import { useGetForumGroupsQuery } from "api/queries/groups";
import { useGetItineraryBookletGenerateDocumentQuery } from "api/queries/itineraryBooklet";
import KEYS from "api/queries/keys";
import { useUserInfoQuery } from "api/queries/user";
import { Button, Col, Divider, Form, Typography } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import ErrorTypes from "error-handling/errorTypes";
import { Em2AxiosError } from "error-handling/errorUtil";
import {
  AttendeeAdvancedFilterTypes,
  DocumentDefinitionTypes,
  DocumentGenerationRunRequestModel,
  Em2ExceptionTypes,
  ForumGroupResponseModel,
  ObjectNotFoundExceptionResponseMeta,
  QuestionnaireSubmissionStatus,
} from "generated/api";
import { toNumber } from "lodash-es";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";

import { useQuestionnairesSubmissionQuery } from "../../../../api/queries/questionnaires";
import { AttendeeTypes, getDocumentGenerationFormItems } from "./helpers";
import QueuedDocumentsTable from "./QueuedDocumentsTable";
import { DocumentGenerationFormItem } from "./types";

const { Title } = Typography;

const QueueDocumentRun = () => {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const [selectedDocumentDefinitionType, setSelectedDocumentDefinitionType] = useState<DocumentDefinitionTypes>();
  const [selectedAttendeesType, setSelectedAttendeesType] = useState<AttendeeTypes>(AttendeeTypes.Group);
  const [selectedAttendeeId, setSelectedAttendeeId] = useState<number>();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState<number>();
  const [groups, setGroups] = useState<ForumGroupResponseModel[]>([]);

  const [quill, setQuill] = useState<React.RefObject<ReactQuill> | null>(null);

  const handleQuillAvailable = (q: React.RefObject<ReactQuill>) => {
    setQuill(q);
  };

  const getAttendeesGroupsParams = (forumGroups: Array<string>) => {
    return {
      advancedFilterType: AttendeeAdvancedFilterTypes.ForumGroups,
      groups: forumGroups,
    };
  };

  const getAttendeeSpeakerItineraryWithCoverParams = () => {
    if (selectedDocumentDefinitionType === DocumentDefinitionTypes.AttendeeSpeakerItineraryWithCover) {
      return getAttendeesGroupsParams(["Speakers", "SpeakersWebsteps"]);
    }
  };

  const setDocumentSpecificGroups = (data: ForumGroupResponseModel[], specificGroups: string[]) => {
    return setGroups(data.filter(({ name }) => specificGroups.includes(name)));
  };

  const { data: { data: user = {} } = {} } = useUserInfoQuery({}, { retry: false });

  const { isLoading: isAttendeesLoading, data: { data: { items: attendees = [] } = {} } = {} } = useAttendeesQuery({
    forumId,
    onlySpeakers: selectedDocumentDefinitionType === DocumentDefinitionTypes.SpeakerPackWithCover,
    attendeeStatus: [AttendeeStatus.Attending, AttendeeStatus.AttendingReplacement],
    forumGroupIds: [DocumentDefinitionTypes.PiggyProvItinerary, DocumentDefinitionTypes.ItineraryCover2].some(
      (value) => value === selectedDocumentDefinitionType,
    )
      ? groups?.map(({ id }) => id)
      : undefined,
    ...getAttendeeSpeakerItineraryWithCoverParams(),
  });

  const { isLoading: isGroupsLoading } = useGetForumGroupsQuery(
    { forumId },
    {
      onSuccess: ({ data }) => {
        switch (selectedDocumentDefinitionType) {
          case DocumentDefinitionTypes.ItineraryCover2: {
            return setDocumentSpecificGroups(data, ["Piggy Backers", "Executives"]);
          }
          case DocumentDefinitionTypes.AttendeeSpeakerItineraryWithCover: {
            return setDocumentSpecificGroups(data, ["Speakers", "SpeakersWebsteps"]);
          }
          case DocumentDefinitionTypes.PiggyProvItinerary: {
            return setDocumentSpecificGroups(data, ["Piggy Backers"]);
          }
          default:
            setGroups(data);
        }
      },
    },
  );

  const { isFetching: isLoadingGenerateDocument, refetch: downloadGeneratedDocument } =
    useGetItineraryBookletGenerateDocumentQuery(
      {
        forumId,
        documentDefinitionType: selectedDocumentDefinitionType!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        forumAttendeeId: selectedAttendeeId!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        forumQuestionnaireId: selectedQuestionnaireId,
      },
      {
        enabled: false,
        retry: false,
        onSuccess: ({ data }) => downloadFileByURL(data),
        onError: (error) => {
          const exception = new Em2AxiosError(error);

          if (exception.isOfType(Em2ExceptionTypes.ObjectNotFound)) {
            const meta = exception.getMeta<Required<ObjectNotFoundExceptionResponseMeta>>();
            if (
              meta.ObjectType === "ForumAttendee" &&
              "ForumId" in meta.ObjectFields &&
              "ForumGroupName" in meta.ObjectFields
            ) {
              return toast.error(
                t("Document can't be generated for this PiggyBacker because his company does not have any Executives"),
              );
            }
          }

          toast.error(t("Something went wrong"));
        },
      },
    );

  const { mutate: addToQueueMutate, isLoading: isAddToQueueLoading } = useAddDocumentToQueueMutation();

  const refetchGroups = () => {
    queryClient.resetQueries([KEYS.GET_FORUM_GROUPS]);
  };

  const handleAddToQueue = (values: DocumentGenerationRunRequestModel) => {
    if (values.forumGroupId) {
      addToQueueMutate(
        {
          forumId,
          documentGenerationRunRequestModel: {
            ...values,
            notificationText: quill?.current?.unprivilegedEditor?.getHTML(),
          },
        },
        {
          onSuccess: () => {
            toast.success("Successfully added to queue");
            queryClient.resetQueries([KEYS.GET_ITINERARY_BOOKLET_QUEUED_DOCUMENTS]);
          },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onError: ({ response }: any) => {
            if (ErrorTypes.isOfType(response, ErrorTypes.InvalidEntityState)) {
              return toast.error(t("There are no attendees in the selected group"));
            }

            if (ErrorTypes.isOfType(response, ErrorTypes.ObjectNotFound)) {
              const meta = response.data?.meta;

              if (meta.ObjectType === "ForumAttendee" && "ForumGroupName" in meta.ObjectFields) {
                return toast.error(
                  t("Document can't be generated because the Piggy Backers group does not have any Executives"),
                );
              }
            }

            toast.error(t("Something went wrong"));
          },
        },
      );
    }
  };

  const onSubmit = (values: DocumentGenerationRunRequestModel) => {
    if (selectedGroupId) {
      handleAddToQueue(values);
    }
    if (selectedAttendeeId) {
      downloadGeneratedDocument();
    }
  };

  const { isLoading: isSubmissionLoading, data: { data: completedQuestionnaires = [] } = {} } =
    useQuestionnairesSubmissionQuery(
      {
        forumId,
        forumGroupId: selectedGroupId,
        attendeeId: selectedAttendeeId,
        statuses: [QuestionnaireSubmissionStatus.InProgress, QuestionnaireSubmissionStatus.Completed],
      },
      {
        enabled: !!selectedAttendeeId || !!selectedGroupId,
      },
    );

  const getQuestionnairesNamesOptions = () =>
    completedQuestionnaires.map((questionnaire) => ({
      label: questionnaire.forumQuestionnaireName,
      value: questionnaire.forumQuestionnaireId,
    }));

  const isSubmitButtonDisabled = () => {
    if (selectedDocumentDefinitionType === DocumentDefinitionTypes.Questionnaire) {
      return !(selectedDocumentDefinitionType && selectedQuestionnaireId && (selectedGroupId || selectedAttendeeId));
    }
    return !(selectedDocumentDefinitionType && (selectedGroupId || selectedAttendeeId));
  };

  const formItems = getDocumentGenerationFormItems({
    selectedAttendeesType,
    setSelectedAttendeesType,
    selectedDocumentDefinitionType,
    setSelectedDocumentDefinitionType,
    setSelectedGroupId,
    setSelectedAttendeeId,
    form,
    attendees,
    isAttendeesLoading,
    groups,
    isGroupsLoading,
    handleQuillAvailable,
    currUserEmail: user.email,
    getQuestionnairesNamesOptions,
    isSubmissionLoading,
    setSelectedQuestionnaireId,
    refetchGroups,
  });

  return (
    <div>
      <Title style={{ marginBottom: "20px" }} level={5}>
        {t("Select your document")}
      </Title>

      <Form id="documentRunForm" name="generatePdf" labelWrap layout="vertical" onFinish={onSubmit} form={form}>
        <Col md={24} lg={10}>
          {formItems.flat().map((item: DocumentGenerationFormItem | false) => {
            if (item) {
              const { component, name } = item;

              return (
                <Form.Item key={name} {...item}>
                  {component}
                </Form.Item>
              );
            }
          })}
        </Col>
      </Form>

      <Col md={24} lg={24} style={{ marginTop: "20px" }}>
        <Button
          type="primary"
          disabled={isSubmitButtonDisabled()}
          onClick={form.submit}
          loading={isAddToQueueLoading || isLoadingGenerateDocument}
        >
          {selectedAttendeesType === AttendeeTypes.Group ? t("Add to queue") : t("Download")}
        </Button>
      </Col>

      <Divider />

      {selectedAttendeesType === AttendeeTypes.Group && <QueuedDocumentsTable forumId={forumId} />}
    </div>
  );
};

export default QueueDocumentRun;
