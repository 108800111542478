import React from "react";
import { Avatar, Col, Divider, Popover, Row, Spin } from "../styleguide";
import { LoadingOutlined, LogoutOutlined } from "@ant-design/icons";

import styles from "./styles.module.less";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useLogout from "../../hooks/useLogout";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const UserMenuAvatar = ({ userInfo }) => {
  const { t } = useTranslation();
  const logout = useLogout();

  if (
    userInfo.isLoading ||
    !userInfo.isFetched ||
    !userInfo.data ||
    !userInfo.data.data
  ) {
    return <Spin indicator={antIcon} />;
  }

  const { firstName = "", lastName = "", email } = userInfo.data.data;

  const name =
    !firstName && !lastName ? "N/A" : `${firstName + " " + lastName}`;

  return (
    <Popover
      overlayClassName={styles.popover}
      placement="bottomRight"
      content={
        <div>
          <Row gutter={24} className={styles.header}>
            <Col>
              <Avatar size={50} className={styles.headerAvatar}>
                {firstName?.[0] || lastName?.[0] || "N"}
              </Avatar>
            </Col>
            <Col className={styles.info}>
              <Row>
                <Col>{name}</Col>
              </Row>
              <Row>
                <Col>{email}</Col>
              </Row>
            </Col>
            <Divider />
          </Row>
          <Row className={styles.links}>
            <Col>
              <Link to="#" className={styles.logout} onClick={logout}>
                <LogoutOutlined />
                {t("Logout")}
              </Link>
            </Col>
          </Row>
        </div>
      }
      trigger="click"
    >
      <Avatar className={styles.avatar}>{firstName?.[0] || lastName?.[0] || "N"}</Avatar>
    </Popover>
  );
};

export default UserMenuAvatar;
