const FacilityTypeCode = {
  M: 1,
  C: 2,
  A: 3,
  R: 4,

  toDisplayName(type: number) {
    switch (type) {
      case 1:
        return "M";
      case 2:
        return "C";
      case 3:
        return "A";
      case 4:
        return "R";
      default:
        return "";
    }
  },
};

export default FacilityTypeCode;
