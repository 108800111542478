import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Checkbox, Radio, Typography } from "../../../../../../../components/styleguide";
import { useTranslation } from "react-i18next";

const MatrixOptionsRenderer = ({ type, columns, options }) => {
  const { t } = useTranslation();

  const columnsCount = columns.length;

  const optionPlaceholder = useMemo(() => {
    switch (type) {
      case "checkbox":
        return <Checkbox disabled />;
      case "radio":
        return <Radio disabled />;
      default:
        return t("Unsupported type");
    }
  }, [type, t]);

  return <table style={{ width: "100%" }}>
    <thead>
    <tr>
      <td />

      {columns.map((text, index) => (
        <td key={index}>
          <Typography.Text>{text}</Typography.Text>
        </td>
      ))}
    </tr>
    </thead>

    {options.map(({ id, text }) => (
      <tr key={id}>
        <td>
          <Typography.Text>{text}</Typography.Text>
        </td>

        {(new Array(columnsCount)).fill(undefined).map((_, index) => (
          <td key={index}>{optionPlaceholder}</td>
        ))}
      </tr>
    ))}
  </table>;
};

MatrixOptionsRenderer.propTypes = {
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  })),
};

MatrixOptionsRenderer.defaultProps = {
  columns: [],
  options: [],
};

export default MatrixOptionsRenderer;
