import createDependentCollector from "./utils/create-dependent-collector";

const getForPositiveSelectionV2 = createDependentCollector([
  "ReasonForMeeting",
  "ReasonForMeetingV2",
  "ReasonForMeetingV3",
  "Joint",
]);

export default getForPositiveSelectionV2;
