import getWrappedUseQuery from "api/helpers/getWrappedUseQuery";

import apiClient from "../../../api-client";
import KEYS from "../keys";

const useGetLanguageLabelsTimeFormatsQuery = getWrappedUseQuery(
  KEYS.GET_LANGUAGE_LABELS_TIME_FORMATS_ESSENTIALS,
  apiClient.ItineraryBooklets.get,
);

export { useGetLanguageLabelsTimeFormatsQuery };
