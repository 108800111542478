import { useExportBookedMeetingsQuery, useForumItinerariesQuery } from "api/queries/forumItinerary";
import { Button, Space, Table, Typography } from "components/styleguide";
import { AttendeeItineraryItemResponseModel, TimeSlotType } from "generated/api";
import { toNumber } from "lodash";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadFileByURL } from "utils/fileUtils";
import { TableColumn } from "utils/tableColumnUtils";

import { getFormattedDate } from "./helpers";

const BookedMeetings = () => {
  const { t } = useTranslation();

  const forumId = toNumber(useParams().id);

  const [selectedBookingsIds, setSelectedBookingsIds] = useState<number[]>([]);

  const { isLoading: isBookedMeetingsLoading, data: { data: bookedMeetings = [] } = {} } = useForumItinerariesQuery({
    forumId,
    manualSched: true,
    timeSlotTypes: (Object.keys(TimeSlotType) as TimeSlotType[]).filter((x) => x !== TimeSlotType.Conference),
  });

  const {
    isLoading: isDocumentLoading,
    fetchStatus,
    refetch: downloadDocument,
  } = useExportBookedMeetingsQuery(
    {
      forumId,
      itineraryItemIds: selectedBookingsIds,
    },
    {
      enabled: false,
      retry: false,
      onSuccess: ({ data }) => downloadFileByURL(data),
      onError: () => toast.error(t("Something went wrong")),
    },
  );

  const bookedMeetingsColumns = useMemo(
    () => [
      new TableColumn(t("Created"), "createdAt", { render: (date: string) => getFormattedDate(date) }),
      new TableColumn(t("Start Time"), "startTime", { render: (date: string) => getFormattedDate(date) }),
      new TableColumn(t("End Time"), "endTime", { render: (date: string) => getFormattedDate(date) }),
      new TableColumn(t("Attendee {{number}}", { number: 1 }), "attendeeId", {
        render: (_: string, record: AttendeeItineraryItemResponseModel) =>
          `${record.attendeeFirstName ?? ""} ${record.attendeeSurname ?? ""}`,
      }),
      new TableColumn(t("Attendee {{number}}", { number: 2 }), "meetAttendeeId", {
        render: (_: string, record: AttendeeItineraryItemResponseModel) =>
          `${record.meetAttendeeFirstName ?? ""} ${record.meetAttendeeSurname ?? ""}`,
      }),
      new TableColumn(t("Facility"), "facility"),
      new TableColumn(t("Table"), "tableNumber"),
    ],
    [],
  );

  const rowSelection = {
    onChange: (selectedRowKeys: Array<React.Key>) => {
      setSelectedBookingsIds(selectedRowKeys.map((key) => toNumber(key)));
    },
  };

  const isDownloading = isDocumentLoading && fetchStatus !== "idle";

  return (
    <Space direction={"vertical"} size={"middle"} fullWidth>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Title level={5}>{t("Booked Meetings")}</Typography.Title>
        <Button
          type="primary"
          loading={isDownloading}
          disabled={!selectedBookingsIds.length || isDownloading}
          onClick={() => downloadDocument()}
        >
          {t("Print selected")}
        </Button>
      </div>
      <Table
        id="bookedMeetingsTable"
        data-testid="bookedMeetingsTable"
        dataSource={bookedMeetings}
        columns={bookedMeetingsColumns}
        pagination={false}
        bordered={true}
        rowKey="id"
        rowSelection={rowSelection}
        loading={isBookedMeetingsLoading}
      />
    </Space>
  );
};

export default BookedMeetings;
