import { useQueryClient } from "@tanstack/react-query";
import {
  useUpdateForumDocumentDefinitionSectionMutation,
  useUpdateForumDocumentDefinitionSectionOptionsMutation,
} from "api/mutations/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import ErrorTypes from "error-handling/errorTypes";
import {
  ForumDocumentDefinitionSectionUpdateModel,
  ForumDocumentDefinitionStandardSectionOptionValueRequestModel,
} from "generated/api";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ReactQuillType } from "../EditDocumentDefinitionDrawer/SelectionOptions/TextEditorOption";
import { getChangedValues } from "../helpers";
import { ChangedValuesKeys } from "../types";

interface HookProps {
  forumId: number;
  documentDefinitionSectionId: number;
  onClose: () => void;
  quills: ReactQuillType[];
}

interface HookResponse {
  submit: (
    sectionValues: ForumDocumentDefinitionSectionUpdateModel,
    sectionOptionsValues: ForumDocumentDefinitionStandardSectionOptionValueRequestModel,
  ) => void;
  isMutationLoading: boolean;
}

/**
 * Hook that calls both endpoints (update definition section AND update definition section options).
 * Used for saving the data from all the tabs at the same time.
 */
export const useUpdateSectionAndSectionOptions = ({
  forumId,
  documentDefinitionSectionId,
  onClose,
  quills,
}: HookProps): HookResponse => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const updateSectionMutation = useUpdateForumDocumentDefinitionSectionMutation();
  const updateSectionOptionsMutation = useUpdateForumDocumentDefinitionSectionOptionsMutation();

  const isMutationLoading = updateSectionMutation.isLoading || updateSectionOptionsMutation.isLoading;

  const submit = useCallback(
    (
      sectionValues: ForumDocumentDefinitionSectionUpdateModel,
      sectionOptionsValues: ForumDocumentDefinitionStandardSectionOptionValueRequestModel,
    ) => {
      const textEditorRequestModel = Object.values(quills).map(({ component, id }) => {
        return {
          id,
          value: component?.unprivilegedEditor?.getHTML(),
        };
      });

      const inputRequestModel = getChangedValues(sectionOptionsValues as ChangedValuesKeys);

      const forumDocumentDefinitionStandardSectionOptionValueRequestModel = isEmpty(quills)
        ? inputRequestModel
        : [...textEditorRequestModel, ...inputRequestModel];

      updateSectionMutation.mutateAsync(
        {
          forumId,
          documentDefinitionSectionId,
          forumDocumentDefinitionSectionUpdateModel: sectionValues,
        },
        {
          onSuccess: () => {
            if (!isEmpty(forumDocumentDefinitionStandardSectionOptionValueRequestModel)) {
              updateSectionOptionsMutation.mutateAsync(
                {
                  forumId,
                  documentDefinitionSectionId,
                  forumDocumentDefinitionStandardSectionOptionValueRequestModel,
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS]);
                    queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTION]);
                    toast.success(t("Document Definition Section successfully updated"));
                    onClose();
                  },
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onError: ({ response }: any) => {
                    if (response.status === 400) {
                      toast.error(t("Editor's length should not exceed 3000 characters"));
                    } else if (response.status === 409 && ErrorTypes.isOfType(response, ErrorTypes.ValueOutOfRange)) {
                      const { PropertyName, RangeStartValue, RangeEndValue } = response.data.meta;

                      toast.error(
                        t("{{PropertyName}} should have values between {{RangeStartValue}} and {{RangeEndValue}}", {
                          PropertyName,
                          RangeStartValue,
                          RangeEndValue,
                        }),
                      );
                    } else {
                      toast.error(t("Something went wrong"));
                    }
                  },
                },
              );
            } else {
              queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTIONS]);
              queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTION]);
              toast.success(t("Document Definition Section successfully updated"));
              onClose();
            }
          },
          onError: () => {
            toast.error(t("Something went wrong"));
          },
        },
      );
    },
    [
      documentDefinitionSectionId,
      quills,
      forumId,
      onClose,
      queryClient,
      t,
      updateSectionMutation,
      updateSectionOptionsMutation,
    ],
  );

  return { submit, isMutationLoading };
};
