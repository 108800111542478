import { useMutation } from "@tanstack/react-query";
import api from "api";
import getWrappedUseMutation from "../../helpers/getWrappedUseMutation";
import apiClient from "../../../api-client";

const useCreateBuildingMutation = (options = {}) => {
  const mutation = useMutation(
    async ({ venueId, building }) => api.post(`/venues/${venueId}/buildings`, building),
    options,
  );
  return mutation;
};

const useUpdateBuildingMutation = (options = {}) => {
  return useMutation(
    async ({ venueId, buildingId, building }) => api.put(`/venues/${venueId}/buildings/${buildingId}`, building),
    options,
  );
};

const useUpdateForumBuildingMutation = (options = {}) => {
  return useMutation(
    ({ forumId, buildingId, building }) => api.put(`forums/${forumId}/venue/buildings/${buildingId}`, building),
    options,
  );
};

const useCreateForumBuildingMutation = (options = {}) => {
  return useMutation(({ forumId, building }) => api.post(`forums/${forumId}/venue/buildings`, building), options);
};

const useDeleteForumBuildingMutation = getWrappedUseMutation(apiClient.ForumBuilding.delete);

const useDeleteVenueBuildingMutation = getWrappedUseMutation(apiClient.Building.delete);

export {
  useCreateBuildingMutation,
  useCreateForumBuildingMutation,
  useUpdateBuildingMutation,
  useUpdateForumBuildingMutation,
  useDeleteForumBuildingMutation,
  useDeleteVenueBuildingMutation,
};
