import { DeleteOutlined } from "@ant-design/icons";
import Tooltip from "antd/es/tooltip";
import ConditionalWrapper from "components/ConditionalWrapper";
import PropTypes from "prop-types";
import React, { createRef, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Menu, Modal, Typography } from "../../../../../../../components/styleguide";

import styles from "./styles.module.less";

const QuestionLabel = ({ title, isDeleteDisabled, isSelected, onRemoveConfirm }) => {
  const { t } = useTranslation();

  const ref = createRef();

  const [isOverflow, setIsOverflow] = useState(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;
      setIsOverflow(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [ref]);

  const handleRemoveButtonClick = (e) => {
    if (isDeleteDisabled) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    Modal.confirm({
      title: "Confirmation",
      content: <Typography.Text>{t("Do you want to proceed with deletion of this question?")}</Typography.Text>,
      closable: true,
      onOk: () => onRemoveConfirm(),
    });
  };

  return (
    <Menu.ItemLabel
      isSelected={isSelected}
      append={
        <Button
          onClick={handleRemoveButtonClick}
          type="text"
          icon={<DeleteOutlined />}
          size="small"
          className={styles.questionDeleteButton}
          disabled={isDeleteDisabled}
        />
      }
    >
      <ConditionalWrapper condition={isOverflow} wrapper={(children) => <Tooltip title={title}>{children}</Tooltip>}>
        <span className={styles.questionLabel} ref={ref}>
          {title}
        </span>
      </ConditionalWrapper>
    </Menu.ItemLabel>
  );
};

QuestionLabel.propTypes = {
  title: PropTypes.string.isRequired,
  isDeleteDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  onRemoveConfirm: PropTypes.func,
};

QuestionLabel.defaultProps = {
  isDeleteDisabled: false,
  isSelected: false,
  onRemoveConfirm: () => undefined,
};

export default QuestionLabel;
