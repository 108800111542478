import type { ColumnType } from "antd/es/table";
import { Button, Form, Input, Space, Table } from "components/styleguide";
import { ReasonForMeeting } from "generated/api";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { removeAt } from "utils/arrayUtils";

import withOnChange from "../../../utils/withOnChange";

import styles from "../../../PositiveSelectionV2/components/QuestionnaireMatching/components/Mapper/styles.module.less";

interface ReasonForMeetingSetupProps {
  value: Array<ReasonForMeeting>;
  onChange: (reasonsForMeetings: Array<ReasonForMeeting>) => void;
}

const ReasonForMeetingSetup: FC<ReasonForMeetingSetupProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleFormSubmit = (values: ReasonForMeeting) => {
    onChange([...value, values]);
    form.resetFields();
  };

  const handleDeleteClick = useCallback(
    (index: number) => {
      const newValues = removeAt(value ?? [], index);
      return onChange(newValues);
    },
    [onChange, value],
  );

  const codeFormat = new RegExp("^[A-Z]$");

  const validateCodeFormat = async (_: object, code: string) => {
    return codeFormat.test(code) ? Promise.resolve() : Promise.reject();
  };

  const validateUniqueCode = async (_: object, code: string) => {
    const codes = value.map((reasonForMeeting: ReasonForMeeting) => reasonForMeeting.code);

    return codes.includes(code) ? Promise.reject() : Promise.resolve();
  };

  const columns: ColumnType<ReasonForMeeting>[] = useMemo(() => {
    return [
      {
        title: t("Reason Description"),
        dataIndex: "reason",
        width: "50%",
      },
      {
        title: t("Reason Code"),
        dataIndex: "code",
        width: "50%",
      },
      {
        key: "actions",
        width: 50,
        render: (_, __, index) => {
          return (
            <Space className={styles.actions} fullWidth={false}>
              <Button type="primary" onClick={() => handleDeleteClick(index)}>
                {t("Delete")}
              </Button>
            </Space>
          );
        },
      },
    ];
  }, [handleDeleteClick, t]);

  return (
    <Space direction="vertical" size="middle" fullWidth>
      <Form className={styles.table} form={form} onFinish={handleFormSubmit}>
        <table className={styles.table}>
          <thead>
            <tr>
              <td style={{ width: "50%" }}>{t("Reason Description")}</td>
              <td style={{ width: "50%" }}>{t("Reason Code")}</td>
              <td style={{ width: 100 }} />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form.Item
                  name={"reason"}
                  rules={[
                    {
                      required: true,
                      message: t("errors.required", { prop: "$t(Reason Description)" }),
                    },
                  ]}
                >
                  <Input maxLength={200} />
                </Form.Item>
              </td>
              <td>
                <Form.Item
                  name={"code"}
                  rules={[
                    {
                      required: true,
                      message: t("errors.required", { prop: "$t(Reason Code)" }),
                    },
                    {
                      validator: validateCodeFormat,
                      message: t("Code can only be a single capital letter"),
                    },
                    {
                      validator: validateUniqueCode,
                      message: t("This Code already exists"),
                    },
                  ]}
                >
                  <Input maxLength={1} />
                </Form.Item>
              </td>
              <td>
                <div className={styles.actions}>
                  <Button block type="primary" htmlType="submit">
                    {t("Add")}
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </Form>
      <Table columns={columns} dataSource={value} rowKey="code" pagination={false} />
    </Space>
  );
};

ReasonForMeetingSetup.defaultProps = {
  value: undefined,
  onChange: () => undefined,
};

export default withOnChange(ReasonForMeetingSetup);
