import { useGetForumGroupsQuery } from "api/queries/groups";
import { Button, Table } from "components/styleguide";
import ForumGroupType from "enums/ForumGroupType";
import { ForumGroupResponseModel } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import styles from "./styles.module.less";

export default function ManageGroups({ onGroupManage }: { onGroupManage: (group: ForumGroupResponseModel) => void }) {
  const { t } = useTranslation();

  const forumId = useParams().id;
  const [groups, setGroups] = useState<ForumGroupResponseModel[]>([]);
  const { response: getForumGroupsQuery, handleSort } = useModernQueryWithPaginationAndOrder(
    useGetForumGroupsQuery,
    {
      onSuccess: ({ data }: { data: ForumGroupResponseModel[] }) => {
        setGroups(data);
      },
    },
    { forumId: toNumber(forumId) },
  );

  const columns = useMemo(
    () => [
      { title: t("groupName"), dataIndex: "name", sorter: true },
      {
        title: t("Group Type"),
        dataIndex: "groupType",
        sorter: true,
        render: (type: number) => ForumGroupType.toDisplayName(type),
      },
      {
        width: "5%",
        render: (row: ForumGroupResponseModel) => {
          return (
            <div className={styles.buttonWrapper}>
              <Button onClick={() => onGroupManage(row)}>{t("Select Group")}</Button>
            </div>
          );
        },
      },
    ],
    [groups],
  );

  return (
    <div>
      <Table
        dataSource={groups}
        columns={columns}
        rowKey={"id"}
        loading={getForumGroupsQuery.isLoading}
        onChange={handleSort}
      />
    </div>
  );
}

ManageGroups.propTypes = {
  onGroupManage: PropTypes.func,
};
