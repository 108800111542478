import { useQueryClient } from "@tanstack/react-query";
import { Checkbox } from "antd";
import { useUpdateGroupTaskMutation } from "api/mutations/tasks";
import { getGroupTasksQueryKey, useGroupTasksQuery } from "api/queries/groups";
import { Button, Space, Table, Tag } from "components/styleguide";
import ErrorTypes from "error-handling/errorTypes";
import {
  Em2ExceptionResponseObject,
  ForumGroupResponseModel,
  ForumGroupTaskModel,
  ForumGroupTaskStatus,
  TaskType,
} from "generated/api";
import { toNumber } from "lodash-es";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { dateTimeSorter, stringSorter } from "utils/sorterUtils";

import AttendeeTaskStatusesDrawer from "./AttendeeTaskStatusesDrawer";
import EditTaskDrawer from "./EditTaskDrawer";
import TaskStatusesExportButton from "./TaskStatusesExportButton";

import styles from "./styles.module.less";

const Tasks = ({ group, forumId }: { group: ForumGroupResponseModel; forumId: number }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [taskForEdit, setTaskForEdit] = useState<ForumGroupTaskModel | null>(null);
  const [selectedTask, setSelectedTask] = useState<ForumGroupTaskModel | undefined>();
  const [isAttendeeStatusDrawerVisible, setIsAttendeeStatusDrawerVisible] = useState(false);

  const tasksRequest = useGroupTasksQuery(
    {
      groupId: group?.id,
      forumId,
    },
    {
      enabled: Boolean(group?.id),
    },
  );

  const showAttendeeStatusDrawer = (task: ForumGroupTaskModel) => {
    setSelectedTask(task);
    return setIsAttendeeStatusDrawerVisible(true);
  };
  const hideAttendeeStatusDrawer = () => {
    setSelectedTask(undefined);
    setIsAttendeeStatusDrawerVisible(false);
  };

  const updateTaskMutation = useUpdateGroupTaskMutation({
    onSuccess: () => {
      setTaskForEdit(null);
      queryClient.invalidateQueries({ queryKey: [getGroupTasksQueryKey] });
      toast.success(t("Task successfully updated"));
    },
    onError: (err) => {
      const error = err as { response: { data: Em2ExceptionResponseObject } };

      if (ErrorTypes.isOfType(error.response, ErrorTypes.InvalidTimePeriod)) {
        toast.error(t("The Due Date of a Questionnaire Task shouldn't  be before the Questionnaire start date"));
      } else {
        setTaskForEdit(null);
        toast.error(t("Something went wrong"));
      }
    },
  });

  const handleTaskEdit = (task: ForumGroupTaskModel) => {
    setTaskForEdit(task);
  };

  const handleTaskEditCancel = () => {
    setTaskForEdit(null);
  };

  const handleTaskEditSubmit = (taskInfo: ForumGroupTaskModel) => {
    updateTaskMutation.mutate({
      groupId: toNumber(group.id),
      forumId: toNumber(forumId),
      taskId: toNumber(taskForEdit?.id),
      forumGroupTaskEditModel: {
        ...taskInfo,
        dueDate: moment.isMoment(taskInfo.dueDate)
          ? taskInfo.dueDate.utcOffset(0, true).format()
          : moment(taskInfo.dueDate).utcOffset(0, true).format(),
      },
    });
  };

  const TaskStatuses = {
    [ForumGroupTaskStatus.NotStarted]: t("Draft"),
    [ForumGroupTaskStatus.InProgress]: t("Live"),
    [ForumGroupTaskStatus.Completed]: t("Closed"),
  };

  const columns = [
    {
      title: t("Task Name"),
      dataIndex: "name",
      sorter: (a: ForumGroupTaskModel, b: ForumGroupTaskModel) => stringSorter(a.name, b.name),
    },
    {
      title: t("Task Display Name"),
      dataIndex: "displayName",
      sorter: (a: ForumGroupTaskModel, b: ForumGroupTaskModel) => stringSorter(a.displayName, b.displayName),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      render: (status: ForumGroupTaskStatus, record: ForumGroupTaskModel) => {
        if (record.type === TaskType.Task) {
          return <Tag className={styles.rounded}>{TaskStatuses[status]}</Tag>;
        }
        return null;
      },
      sorter: (a: ForumGroupTaskModel, b: ForumGroupTaskModel) =>
        stringSorter(TaskStatuses[a.status], TaskStatuses[b.status]),
    },
    {
      title: t("Due Date"),
      dataIndex: "dueDate",
      render: (date: string) => {
        return moment(date).format("L");
      },
      sorter: (a: ForumGroupTaskModel, b: ForumGroupTaskModel) => dateTimeSorter(a.dueDate, b.dueDate),
    },
    {
      title: t("Active"),
      dataIndex: "isActive",
      render: (bool: boolean) => {
        return <Checkbox checked={bool} />;
      },
    },
    {
      title: t("Link"),
      dataIndex: "link",
      render: (bool: boolean) => {
        return <Checkbox checked={bool} />;
      },
    },
    {
      render: (task: ForumGroupTaskModel) => (
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button onClick={() => showAttendeeStatusDrawer(task)}>{t("Email")}</Button>
          <Button onClick={() => handleTaskEdit(task)}>{t("edit")}</Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <EditTaskDrawer
        task={taskForEdit}
        onEditCancel={handleTaskEditCancel}
        onEditSubmit={handleTaskEditSubmit}
        isLoading={updateTaskMutation.isLoading}
      />
      <AttendeeTaskStatusesDrawer
        visible={isAttendeeStatusDrawerVisible}
        onClose={hideAttendeeStatusDrawer}
        forumId={forumId}
        groupId={group?.id}
        task={selectedTask}
      />
      <Space direction="vertical" size="middle" fullWidth>
        <TaskStatusesExportButton groupId={group.id} />
        <Table
          columns={columns}
          pagination={false}
          rowKey={"id"}
          loading={tasksRequest.isLoading}
          dataSource={tasksRequest?.data?.data}
        />
      </Space>
    </>
  );
};

Tasks.propTypes = {
  forumId: PropTypes.number,
  group: PropTypes.object,
};

export default Tasks;
