import { useQueryClient } from "@tanstack/react-query";
import {
  useCreateEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
  useUploadImageMutation,
} from "api/mutations/emailTemplates";
import { useEmailByIdQuery } from "api/queries/emailTemplates";
import KEYS from "api/queries/keys";
import { Button, Col, Form, Input, Row } from "components/styleguide";
import { EmailTemplateRequest, EmailTemplateResponse } from "generated/api";
import { toNumber } from "lodash";
import { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { getCategoryByTemplatePath } from "../../../utils/emailTemplates";
import getFormFields from "./helpers";
import * as blank from "./utils/blank.json";

import styles from "./styles.module.less";

const EDITOR_OPTIONS = {
  mergeTags: {
    forumName: {
      name: "Forum Name",
      value: "#[ForumName]#",
    },
    firstName: {
      name: "First Name",
      value: "#[FirstName]#",
    },
    forumDisplayDates: {
      name: "Forum Display Dates",
      value: "#[ForumDisplayDates]#",
    },
    questionnaireTitle: {
      name: "Questionnaire Title",
      value: "#[QuestionnaireTitle]#",
    },
    questionnaireCloseDate: {
      name: "Questionnaire Close Date",
      value: "#[QuestionnaireCloseDate]#",
    },
    reContactName: {
      name: "RE Contact Name",
      value: "#[REContactName]#",
    },
    reContactJobTitle: {
      name: "RE Contact Job Title",
      value: "#[REContactJobTitle]#",
    },
    reContactPhone: {
      name: "RE Contact Phone",
      value: "#[REContactPhone]#",
    },
    personEmail: {
      name: "Person Email",
      value: "#[PersonEmail]#",
    },
    siteCloseDate: {
      name: "Site Close Date",
      value: "#[SiteCloseDate]#",
    },
    fromDate: {
      name: "From Date",
      value: "#[FromDate]#",
    },
    toDate: {
      name: "To Date",
      value: "#[ToDate]#",
    },
  },
};

export default function EditEmailTemplate() {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const emailEditorRef = useRef<InstanceType<typeof EmailEditor> | null>(null);
  const navigate = useNavigate();
  const templateId = useParams().templateId;
  const [template, setTemplate] = useState<EmailTemplateResponse | null>(null);
  const queryClient = useQueryClient();

  const createMutation = useCreateEmailTemplateMutation();
  const updateMutation = useUpdateEmailTemplateMutation();
  const uploadImageMutation = useUploadImageMutation();

  const emailQuery = useEmailByIdQuery(
    { templateId: toNumber(templateId) },
    {
      onSuccess: ({ data }) => {
        form.setFieldValue("name", data.name);
        form.setFieldValue("subject", data.subject);
        form.setFieldValue("emailTemplateCategory", data.emailTemplateCategory);
        emailEditorRef?.current?.loadDesign(JSON.parse(data?.body?.jsonBody as string));
        setTemplate(data);
      },
      enabled: !!templateId,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const onTemplateChange = async (value: string) => {
    form.setFieldValue("emailTemplateCategory", getCategoryByTemplatePath(value));
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const json = require(`${value}`);
    emailEditorRef?.current?.loadDesign(json);
  };

  const onReady = () => {
    emailEditorRef?.current?.registerCallback("image", (file, done) => {
      const formData = new FormData();
      formData.append("FormFile", file.attachments[0]);

      uploadImageMutation.mutate(
        { formFile: formData.get("FormFile") as File | undefined },
        {
          onSuccess: ({ data }) => {
            done({ url: data, progress: 100 });
          },
        },
      );
    });

    if (!templateId) {
      const templatePath = form.getFieldValue("template");
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const json = templatePath ? require(`${templatePath}`) : null;

      emailEditorRef?.current?.loadDesign(json ?? blank);
    } else {
      queryClient.invalidateQueries([KEYS.GET_EMAIL_TEMPLATE_BY_ID]);
    }
  };

  const submit = ({
    name,
    subject,
    emailTemplateCategory,
  }: {
    name: string;
    subject: string;
    emailTemplateCategory: string;
  }) => {
    emailEditorRef?.current?.exportHtml(async (data) => {
      const { design, html } = data;
      const emailTemplateRequest = {
        name: name,
        subject: subject,
        body: {
          jsonBody: JSON.stringify(design),
          htmlBody: `${html}`,
        },
        emailTemplateCategory: emailTemplateCategory,
      } as EmailTemplateRequest;

      if (templateId) {
        updateMutation.mutate(
          { templateId: toNumber(templateId), emailTemplateRequest },
          {
            onSuccess: () => {
              navigate("..");
            },
          },
        );
      } else {
        createMutation.mutate(
          { emailTemplateRequest },
          {
            onSuccess: () => {
              navigate("..");
            },
          },
        );
      }
    });
  };

  const validateBody = async () => {
    return new Promise((resolve, reject) => {
      emailEditorRef?.current?.exportHtml(async (data) => {
        if (data.design.body.rows.length > 0) {
          resolve(data);
        } else {
          reject();
        }
      });
    });
  };

  const handleContextMenu = (event: Event) => {
    event.preventDefault();
  };

  useEffect(() => {
    document.addEventListener("contextmenu", handleContextMenu);
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <div>
      <Form
        id="emailTemplatesForm"
        name="emailTemplates"
        labelAlign="left"
        layout="vertical"
        form={form}
        onFinish={submit}
        className={styles.form}
      >
        <div className="form-layout">
          <div>
            {getFormFields(onTemplateChange, t).map((field) => (
              <Form.Item
                key={field.dataIndex}
                label={t(field.title)}
                name={field.dataIndex}
                initialValue={template?.name}
                rules={field.rules ?? []}
              >
                {field.component ?? <Input placeholder={t(field.placeholder) ?? ""} />}
              </Form.Item>
            ))}
            <Form.Item
              label={t("body")}
              key="body"
              name="body"
              rules={[
                {
                  required: true,
                  message: "Required",
                  validator: () => validateBody(),
                },
              ]}
            >
              <div className={styles.editor}>
                <EmailEditor
                  ref={emailEditorRef}
                  onReady={onReady}
                  style={{ minWidth: "200px", overflow: "auto" }}
                  options={EDITOR_OPTIONS as never}
                />
              </div>
            </Form.Item>
          </div>
          <div>
            <Row gutter={80}>
              <Col span={1}>
                <Button
                  loading={
                    createMutation.isLoading || updateMutation.isLoading || (!!templateId && emailQuery.isLoading)
                  }
                  htmlType="submit"
                  type="primary"
                >
                  {t("save")}
                </Button>
              </Col>
              <Col span={1}>
                <Button>
                  <Link to={".."}>{t("Back")}</Link>
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </div>
  );
}
