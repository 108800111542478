import { Space } from "components/styleguide";
import { useState } from "react";

import LinkToReport from "../components/LinkToReport";
import SortOrderOption from "../OptionTypes/SortByOption";

const SortOptions = { CompanyName: "Company", Surname: "Surname" };

const SatisfiedAndUnsatisfiedTotalsOptions = () => {
  const [orderBy, setOrderBy] = useState<string>("CompanyName");

  return (
    <Space align={"end"}>
      <SortOrderOption options={SortOptions} orderBy={orderBy} setOrderBy={setOrderBy} />

      <LinkToReport parameters={{ OrderBy: orderBy }} />
    </Space>
  );
};

export default SatisfiedAndUnsatisfiedTotalsOptions;
