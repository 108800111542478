import EnumBuilder from "utils/enumBuilderUtils";

const builder = new EnumBuilder();

const VenueAssignmentType = builder
  .add("IncludeBuildingsAndFacilities", "Assign venue with buildings and facilities")
  .add("ExcludeBuildingsAndFacilities", "Assign venue without buildings and facilities")
  .add("WithBuildingsOnly", "Assign venue with buildings only")
  .build();

export default VenueAssignmentType;
