import { Button, Drawer, Form, Input, Select, YesNo } from "components/styleguide";
import { PositiveSelectionV2ItemProfileStyle } from "generated/api";
import { BookletProfileSetupRequestModel } from "generated/api/apiModels/booklet-profile-setup-request-model";
import { BookletProfileSetupResponseModel } from "generated/api/apiModels/booklet-profile-setup-response-model";
import React from "react";
import { useTranslation } from "react-i18next";

import FullHeightForm from "../../../../components/FullHeightForm";

interface EditBookletProfileDrawerProps {
  isOpen: boolean;
  isUpdateLoading: boolean;
  handleClose: () => void;
  handleSubmit: (values: BookletProfileSetupRequestModel) => void;
  questionToEdit: BookletProfileSetupResponseModel | undefined;
}

const EditBookletProfileDrawer = ({
  isOpen,
  isUpdateLoading,
  handleClose,
  handleSubmit,
  questionToEdit,
}: EditBookletProfileDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <Drawer
      className="booklet-profile-edit-drawer"
      title={t("Edit Booklet Profile")}
      placement="right"
      onClose={handleClose}
      open={isOpen}
      contentWrapperStyle={{ minWidth: "50%" }}
      destroyOnClose
    >
      <FullHeightForm
        preserve={false}
        id="bookletProfileForm"
        name="booklet-profile-form"
        labelCol={{ span: 4 }}
        form={form}
        actionsPrepend={<Button onClick={handleClose}>{t("cancel")}</Button>}
        actionsAppend={
          <Button type="primary" htmlType="submit" loading={isUpdateLoading}>
            {t("Update")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("Profile Title")}
          name="profileTitle"
          initialValue={questionToEdit?.profileTitle}
          rules={[{ max: 100 }]}
          labelCol={{ span: 24, offset: 0 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Show in Profile")}
          name="showInProfile"
          initialValue={questionToEdit?.showInProfile}
          labelCol={{ span: 24, offset: 0 }}
        >
          <YesNo />
        </Form.Item>
        <Form.Item
          label={t("Directory Style")}
          name="directoryStyle"
          initialValue={questionToEdit?.directoryStyle}
          labelCol={{ span: 24, offset: 0 }}
        >
          <Select
            options={Object.values(PositiveSelectionV2ItemProfileStyle).map((value) => ({ value, label: t(value) }))}
          />
        </Form.Item>
      </FullHeightForm>
    </Drawer>
  );
};

export default EditBookletProfileDrawer;
