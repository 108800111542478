import { Typography } from "antd";
import { Col, Row } from "components/styleguide";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./styles.module.less";

export interface DescriptionProps {
  children: JSX.Element;
}

const Description: FC<DescriptionProps> = ({ children }) => {
  const { t } = useTranslation();
  const { Title } = Typography;

  return (
    <div className={styles.descriptionSection}>
      <Row>
        <Col>
          <Title level={5}>{t("How to use this report")}</Title>
        </Col>
      </Row>
      <Row>
        <Col className={styles.description}>{children}</Col>
      </Row>
    </div>
  );
};

export default Description;
