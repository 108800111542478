import { Space } from "antd";
import { Input, Radio, Select } from "components/styleguide";
import TextEditor from "components/TextEditor";
import {
  AttendeeModel,
  CreateDocumentDefinitionTypes,
  DocumentDefinitionTypes,
  DocumentGenerationOrderingTypes,
  ForumGroupResponseModel,
} from "generated/api";
import { t } from "i18next";

import { GetDocumentGenerationFormItemsProps } from "./types";

export enum AttendeeTypes {
  Group = "Group",
  Individual = "Individual",
}

const getAttendeesOptions = (attendees: AttendeeModel[]) => {
  return attendees.map(({ attendId, fullName }) => ({
    label: fullName,
    value: attendId,
  }));
};

const getDocumentDefinitionTypesOptions = () => {
  return Object.keys(DocumentDefinitionTypes)
    .filter((x) => x in CreateDocumentDefinitionTypes)
    .map((documentDefinition) => documentDefinition as keyof typeof DocumentDefinitionTypes)
    .sort((d1, d2) =>
      t(`queueDocumentRunDocuments.${d1 as string}`).localeCompare(t(`queueDocumentRunDocuments.${d2 as string}`)),
    )
    .map((documentDefinition) => ({
      label: t(`queueDocumentRunDocuments.${documentDefinition as string}`),
      value: DocumentDefinitionTypes[documentDefinition],
    }));
};

export const getDocumentGenerationFormItems = ({
  selectedAttendeesType,
  setSelectedAttendeesType,
  selectedDocumentDefinitionType,
  setSelectedDocumentDefinitionType,
  setSelectedGroupId,
  setSelectedAttendeeId,
  form,
  attendees,
  isAttendeesLoading,
  groups,
  isGroupsLoading,
  handleQuillAvailable,
  currUserEmail,
  getQuestionnairesNamesOptions,
  isSubmissionLoading,
  setSelectedQuestionnaireId,
  refetchGroups,
}: GetDocumentGenerationFormItemsProps) => [
  {
    title: "Select your document",
    label: "Document",
    name: "documentDefinitionType",
    component: (
      <Select
        showSearch
        allowClear
        style={{ width: "100%" }}
        value={selectedDocumentDefinitionType}
        onChange={(value) => {
          setSelectedDocumentDefinitionType(value);
          refetchGroups();
        }}
        options={getDocumentDefinitionTypesOptions()}
        placeholder={t("Select a document").toString()}
        optionFilterProp={"label"}
      />
    ),
  },
  {
    title: "Select attendees",
    label: "Attendees",
    name: "attendees",
    component: (
      <Radio.Group
        defaultValue={AttendeeTypes.Group}
        value={selectedAttendeesType}
        onChange={({ target: { value } }) => {
          setSelectedAttendeesType(value);

          setSelectedGroupId(undefined);
          setSelectedAttendeeId(undefined);
          setSelectedQuestionnaireId(undefined);

          form.resetFields(["forumAttendeeId", "forumGroupId", "forumQuestionnaireId"]);
        }}
      >
        <Space>
          <Radio value={AttendeeTypes.Group}>{t("Group").toString()}</Radio>
          <Radio value={AttendeeTypes.Individual}>{t("Individual").toString()}</Radio>
        </Space>
      </Radio.Group>
    ),
  },
  selectedAttendeesType === AttendeeTypes.Individual && [
    {
      label: "Attendee",
      name: "forumAttendeeId",
      title: "Select attendee",
      component: (
        <Select
          style={{ width: "100%" }}
          options={getAttendeesOptions(attendees)}
          onChange={(value) => {
            setSelectedAttendeeId(value);
            setSelectedQuestionnaireId(undefined);

            form.resetFields(["forumQuestionnaireId"]);
          }}
          placeholder={t("Select an attendee").toString()}
          showSearch
          allowClear
          optionFilterProp={"label"}
          loading={isAttendeesLoading}
        />
      ),
    },
  ],
  selectedAttendeesType === AttendeeTypes.Group && [
    {
      label: "Group",
      name: "forumGroupId",
      component: (
        <Select
          style={{ width: "100%" }}
          options={groups.map(({ id, name }: ForumGroupResponseModel) => ({
            label: name,
            value: id,
          }))}
          onChange={setSelectedGroupId}
          placeholder={t("Select a group").toString()}
          showSearch
          optionFilterProp={"label"}
          loading={isGroupsLoading}
        />
      ),
    },
    {
      label: "Notify",
      name: "notifyEmail",
      initialValue: currUserEmail,
      component: <Input placeholder={t("Add notification email")} />,
      rules: [{ required: true, message: t("errors.required", { prop: t("Email") }) }],
    },
    {
      label: "Notification Text",
      name: "notificationText",
      initialValue:
        "<p>Hello,</p><p><br/></p><p>The #[DocumentName]# was generated for the #[GroupName]# for the #[ForumCode]#. The time of creation is #[Date]#. To view the details, please, access this <a href='#[URLToDocumentsPage]#' rel='noopener noreferrer' target='_blank'>link</a>.</p>",
      component: <TextEditor onQuillAvailable={handleQuillAvailable} />,
    },
    {
      label: "Order",
      name: "orderingType",
      initialValue: DocumentGenerationOrderingTypes.Surname,
      component: (
        <Select
          style={{ width: "100%" }}
          options={Object.entries(DocumentGenerationOrderingTypes).map(([value, label]) => ({
            label: t(label),
            value,
          }))}
          placeholder={t("Select order type").toString()}
        />
      ),
    },
  ],
  selectedDocumentDefinitionType === DocumentDefinitionTypes.Questionnaire && {
    title: "Completed Questionnaires",
    label: "Questionnaire",
    name: "forumQuestionnaireId",
    component: (
      <Select
        allowClear
        showSearch
        style={{ width: "100%" }}
        options={getQuestionnairesNamesOptions()}
        onChange={setSelectedQuestionnaireId}
        placeholder={t("Select questionnaire").toString()}
        loading={isSubmissionLoading}
      />
    ),
  },
];
