import { Button, Col, Layout, Row } from "components/styleguide";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";

import { useGetForumByIdQuery } from "../../../api/queries/forums";
import ForumDrawer from "./components/ForumDrawer";

const { Header, Content } = Layout;

const Forums = () => {
  const { t } = useTranslation();
  const { forumId } = useParams();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [forum, setForum] = useState(null);

  const showDrawer = () => {
    setIsDrawerOpen(true);
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const forumQuery = useGetForumByIdQuery(
    { forumId: toNumber(forumId) },
    {
      onSuccess: ({ data }) => {
        return setForum(data);
      },
      enabled: Boolean(forumId),
    },
  );

  return (
    <div id="forumsPage">
      <Layout>
        <Header>
          <Row id="forumsControls" gutter={16}>
            <Col>
              <Button onClick={showDrawer} loading={forumId && forumQuery.isLoading}>
                {forumId ? t("recurForum") : t("createNewForum")}
              </Button>
              <ForumDrawer
                visible={isDrawerOpen}
                onClose={onClose}
                setIsDrawerOpen={setIsDrawerOpen}
                existingForum={forumId && !forumQuery.isLoading && forum}
              />
            </Col>
          </Row>
        </Header>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </div>
  );
};

export default Forums;
