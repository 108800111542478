import React, { FC, useEffect, useState } from "react";

import { Select } from "../../../../../../components/styleguide";
import { ForumResponseModel } from "../../../../../../generated/api";

import styles from "../../styles.module.less";

interface ISisterEvents {
  initValue: Array<number> | null;
  forums: Array<ForumResponseModel>;
  onSubmit: (selectedForums: Array<number> | null) => void;
}

const SisterEventsSelect: FC<ISisterEvents> = ({ initValue, forums, onSubmit }) => {
  const [selectedForums, setSelectedForums] = useState<Array<number> | null>(initValue);

  useEffect(() => {
    if (selectedForums !== initValue) {
      setSelectedForums(initValue);
    }
  }, [initValue]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onSubmit(selectedForums);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [selectedForums]);

  return (
    <Select
      className={styles.select}
      mode="multiple"
      optionLabelProp={"label"}
      value={selectedForums}
      onChange={setSelectedForums}
      onBlur={() => onSubmit(selectedForums)}
    >
      {forums.map(({ id, code, title }) => (
        <Select.Option key={id} value={id} label={code}>
          {`${code}, ${title}`}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SisterEventsSelect;
