import { Avatar, Col, Row, Spin } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import { ForumAttendeeResponseModel } from "generated/api";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import BFRDatePicker from "./BFRDatePicker";
import OptionsButton from "./OptionsButton";

import styles from "./styles.module.less";

interface AttendeeBasicInfoPanelProps {
  attendee: ForumAttendeeResponseModel | null;
  isLoading: boolean;
  onEmailDrawerOpen: () => void;
  onMoveToCompanyDrawerOpen: () => void;
}

const AttendeeBasicInfoPanel: FC<AttendeeBasicInfoPanelProps> = ({
  attendee,
  isLoading,
  onEmailDrawerOpen,
  onMoveToCompanyDrawerOpen,
}) => {
  const { t } = useTranslation();

  if (isLoading || !attendee) {
    return (
      <div className={styles.container}>
        <div className="loader">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Row>
        <Col span={1}>
          <Avatar size={45} className="avatar-primary-outlined">
            {`${attendee.firstName[0]}${attendee.lastName[0]}`}
          </Avatar>
        </Col>
        <Col className={styles.contentContainer} span={23}>
          <Row justify="space-between" align="middle">
            <Col className={styles.attendeeName} span={8}>{`${attendee.firstName} ${attendee.lastName}`}</Col>
            <Col span={8} className={styles.optionsButtonContainer}>
              <OptionsButton onSendEmail={onEmailDrawerOpen} onMoveToCompany={onMoveToCompanyDrawerOpen} />
            </Col>
          </Row>
          <Row className={styles.infoContainer}>
            <Col span={8}>
              <Row className={styles.infoRow}>
                <Col>
                  {attendee.jobTitle && (
                    <span>
                      {attendee.jobTitle}
                      {attendee.jobTitle && attendee.company && ", "}
                    </span>
                  )}
                  <Link
                    to={`../../attendees/company/${attendee.forumCompanyId}`}
                    state={{ companyName: attendee.company }}
                  >
                    {attendee.company}
                  </Link>
                </Col>
                <Col>
                  <span className={styles.title}>{t("attendees.basicInfoLabels.Attend Status")}:</span>
                  {attendee.attendStatus
                    ? t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendee.attendStatus)}`)
                    : "-"}
                </Col>
                {attendee.attendeeStatusUpdatedBy && (
                  <Col>
                    <span className={styles.title}>{t("attendees.basicInfoLabels.Attend Status updated by")}:</span>
                    {attendee.attendeeStatusUpdatedBy}
                  </Col>
                )}
                <Col>
                  <span className={styles.title}>{t("Attend Status updated on")}:</span>
                  {attendee.attendStatusUpdatedOn ? moment(attendee.attendStatusUpdatedOn).format("L LT") : "-"}
                </Col>
                <Col className={styles.bfrDateContainer}>
                  <span className={styles.title}>{t("attendees.basicInfoLabels.BFR date")}:</span>
                  <BFRDatePicker value={attendee.bfrDate} />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row className={styles.infoRow}>
                <Col>
                  <span className={styles.title}>{t("attendees.basicInfoLabels.People C")}:</span>
                  {attendee.personId || "-"}
                  <br />
                  {attendee.participationCode}
                  {attendee.participationDescription && ` - ${attendee.participationDescription}`}
                </Col>
                <Col>
                  <span className={styles.title}>{t("attendees.basicInfoLabels.Email")}:</span>
                  {attendee.email || "-"}
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row className={styles.infoRow}>
                <Col>
                  <span className={styles.title}>{t("attendees.basicInfoLabels.Company C")}:</span>
                  {attendee.companyId || "-"}
                </Col>
                <Col>
                  <span className={styles.title}>{t("attendees.basicInfoLabels.Last Updated")}:</span>
                  {attendee.updated ? moment(attendee.updated).format("L") : "-"}
                </Col>
                {attendee.updatedBy?.firstName && attendee.updatedBy?.lastName && (
                  <Col>
                    <span className={styles.title}>{t("attendees.basicInfoLabels.updatedBy")}:</span>
                    {`${attendee.updatedBy.firstName} ${attendee.updatedBy.lastName}`}
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AttendeeBasicInfoPanel;
