import { useQueryClient } from "@tanstack/react-query";
import { useUpdateZoomMappingsTableMappingsMutation } from "api/mutations/zoomMappings";
import KEYS from "api/queries/keys";
import { useGetZoomMappingsAccountsQuery, useGetZoomMappingsTableMappingsQuery } from "api/queries/zoomMappings";
import { Select, Table } from "components/styleguide";
import { ForumTableZoomAccountModel } from "generated/api";
import { toNumber } from "lodash";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const MappingAccountsToLocations: FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const forumId = toNumber(useParams().id);

  const { mutate: updateMapping, isLoading: isMappingUpdating } = useUpdateZoomMappingsTableMappingsMutation();

  const { data: { data: mappings = [] } = {}, isLoading: isMappingsLoading } = useGetZoomMappingsTableMappingsQuery({
    forumId,
  });

  const { data: { data: accounts = [] } = {}, isLoading: isAccountsLoading } = useGetZoomMappingsAccountsQuery({
    forumId,
    notMapped: true,
  });

  const getAccountsOptions = () => {
    return accounts.map(({ id, zoomUser }) => ({
      value: id,
      label: zoomUser,
    }));
  };

  const getCurrentAccountOption = (zoomAccountId: string | null, zoomUser: string | null) => {
    if (!zoomAccountId || !zoomUser) {
      return null;
    }

    return {
      value: zoomAccountId,
      label: zoomUser,
    };
  };

  const onAccountChange = (forumTableId: number, zoomAccountId: string | null) => {
    updateMapping(
      {
        forumId,
        forumTableId,
        forumTableZoomAccountRequest: { zoomAccountId },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_ZOOM_MAPPINGS_TABLE_MAPPINGS]);
          queryClient.invalidateQueries([KEYS.GET_ZOOM_MAPPINGS_ACCOUNTS]);
        },
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  const columns = [
    {
      title: t("Facility"),
      dataIndex: "facilityCode",
      width: "35%",
      render: (_: unknown, { facilityCode, facilityName }: ForumTableZoomAccountModel) => {
        return `${facilityCode} - ${facilityName}`;
      },
    },
    {
      title: t("Table Number"),
      width: "15%",
      dataIndex: "tableNumber",
    },
    {
      title: t("Account"),
      dataIndex: "zoomAccountId",
      render: (zoomAccountId: string | null, { forumTableId, zoomUser }: ForumTableZoomAccountModel) => {
        const currentAccountOption = getCurrentAccountOption(zoomAccountId, zoomUser);
        const accountsOptions = getAccountsOptions();

        const options = currentAccountOption ? [currentAccountOption, ...accountsOptions] : accountsOptions;

        return (
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder={t("Please select an account")}
            options={options}
            value={zoomAccountId}
            onChange={(value) => onAccountChange(forumTableId, value)}
            loading={isAccountsLoading}
            disabled={isMappingUpdating}
          />
        );
      },
    },
  ];

  return <Table bordered columns={columns} dataSource={mappings} loading={isMappingsLoading || isMappingUpdating} />;
};

export default MappingAccountsToLocations;
