/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddDeleteParticipationCodeModel } from '../apiModels';
// @ts-ignore
import { ForumGroupFlagsRequestModel } from '../apiModels';
// @ts-ignore
import { ForumGroupRequestModel } from '../apiModels';
// @ts-ignore
import { ForumGroupResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
// @ts-ignore
import { ParticipationCodeModel } from '../apiModels';
// @ts-ignore
import { ParticipationCodeType } from '../apiModels';
/**
 * ForumGroupApi - axios parameter creator
 * @export
 */
export const ForumGroupApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets a list of groups for forum
         * @param {number} forumId 
         * @param {boolean} [primary] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGet: async (forumId: number, primary?: boolean, orderBy?: string, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGet', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/groups`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (primary !== undefined) {
                localVarQueryParameter['Primary'] = primary;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of available participation codes
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [code] If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
         * @param {string} [description] If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
         * @param {ParticipationCodeType} [type] 
         * @param {boolean} [ignorePagination] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdAvilableCodesGet: async (forumId: number, groupId: number, code?: string, description?: string, type?: ParticipationCodeType, ignorePagination?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdAvilableCodesGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdAvilableCodesGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/avilable-codes`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (ignorePagination !== undefined) {
                localVarQueryParameter['IgnorePagination'] = ignorePagination;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes a list of participation codes codes from a group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {Array<AddDeleteParticipationCodeModel>} [addDeleteParticipationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdCodeDelete: async (forumId: number, groupId: number, addDeleteParticipationCodeModel?: Array<AddDeleteParticipationCodeModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdCodeDelete', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdCodeDelete', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/code`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDeleteParticipationCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds a list of participation code to a group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {Array<AddDeleteParticipationCodeModel>} [addDeleteParticipationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdCodePost: async (forumId: number, groupId: number, addDeleteParticipationCodeModel?: Array<AddDeleteParticipationCodeModel>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdCodePost', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdCodePost', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/code`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDeleteParticipationCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset contact person
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdContactPersonDelete: async (forumId: number, groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdContactPersonDelete', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdContactPersonDelete', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/contact-person`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Assign contact person
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdContactPersonUserIdPut: async (forumId: number, groupId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdContactPersonUserIdPut', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdContactPersonUserIdPut', 'groupId', groupId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdContactPersonUserIdPut', 'userId', userId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/contact-person/{userId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates flags of a non-primary group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {ForumGroupFlagsRequestModel} [forumGroupFlagsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdFlagsPut: async (forumId: number, groupId: number, forumGroupFlagsRequestModel?: ForumGroupFlagsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdFlagsPut', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdFlagsPut', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/flags`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumGroupFlagsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a group by id for forum
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdGet: async (forumId: number, groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdParticipationCodesGet: async (forumId: number, groupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdParticipationCodesGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdParticipationCodesGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/participation-codes`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates a group for forum
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {ForumGroupRequestModel} [forumGroupRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdPut: async (forumId: number, groupId: number, forumGroupRequestModel?: ForumGroupRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdPut', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdPut', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumGroupRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the list of selected participation codes
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [code] If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
         * @param {string} [description] If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
         * @param {ParticipationCodeType} [type] 
         * @param {boolean} [ignorePagination] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdSelectedCodesGet: async (forumId: number, groupId: number, code?: string, description?: string, type?: ParticipationCodeType, ignorePagination?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdSelectedCodesGet', 'forumId', forumId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('forumForumIdGroupsGroupIdSelectedCodesGet', 'groupId', groupId)
            const localVarPath = `/forum/{forumId}/groups/{groupId}/selected-codes`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['Code'] = code;
            }

            if (description !== undefined) {
                localVarQueryParameter['Description'] = description;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }

            if (ignorePagination !== undefined) {
                localVarQueryParameter['IgnorePagination'] = ignorePagination;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (orderDir !== undefined) {
                localVarQueryParameter['OrderDir'] = orderDir;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a group for forum
         * @param {number} forumId 
         * @param {ForumGroupRequestModel} [forumGroupRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsPost: async (forumId: number, forumGroupRequestModel?: ForumGroupRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdGroupsPost', 'forumId', forumId)
            const localVarPath = `/forum/{forumId}/groups`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumGroupRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumGroupApi - functional programming interface
 * @export
 */
export const ForumGroupApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumGroupApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets a list of groups for forum
         * @param {number} forumId 
         * @param {boolean} [primary] 
         * @param {string} [orderBy] 
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGet(forumId: number, primary?: boolean, orderBy?: string, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumGroupResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGet(forumId, primary, orderBy, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the list of available participation codes
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [code] If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
         * @param {string} [description] If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
         * @param {ParticipationCodeType} [type] 
         * @param {boolean} [ignorePagination] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdAvilableCodesGet(forumId: number, groupId: number, code?: string, description?: string, type?: ParticipationCodeType, ignorePagination?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdAvilableCodesGet(forumId, groupId, code, description, type, ignorePagination, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes a list of participation codes codes from a group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {Array<AddDeleteParticipationCodeModel>} [addDeleteParticipationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdCodeDelete(forumId: number, groupId: number, addDeleteParticipationCodeModel?: Array<AddDeleteParticipationCodeModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdCodeDelete(forumId, groupId, addDeleteParticipationCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds a list of participation code to a group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {Array<AddDeleteParticipationCodeModel>} [addDeleteParticipationCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdCodePost(forumId: number, groupId: number, addDeleteParticipationCodeModel?: Array<AddDeleteParticipationCodeModel>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdCodePost(forumId, groupId, addDeleteParticipationCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset contact person
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdContactPersonDelete(forumId: number, groupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdContactPersonDelete(forumId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Assign contact person
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdContactPersonUserIdPut(forumId: number, groupId: number, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdContactPersonUserIdPut(forumId, groupId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates flags of a non-primary group
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {ForumGroupFlagsRequestModel} [forumGroupFlagsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdFlagsPut(forumId: number, groupId: number, forumGroupFlagsRequestModel?: ForumGroupFlagsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdFlagsPut(forumId, groupId, forumGroupFlagsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a group by id for forum
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdGet(forumId: number, groupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdGet(forumId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdParticipationCodesGet(forumId: number, groupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParticipationCodeModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdParticipationCodesGet(forumId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates a group for forum
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {ForumGroupRequestModel} [forumGroupRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdPut(forumId: number, groupId: number, forumGroupRequestModel?: ForumGroupRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdPut(forumId, groupId, forumGroupRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the list of selected participation codes
         * @param {number} forumId 
         * @param {number} groupId 
         * @param {string} [code] If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
         * @param {string} [description] If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
         * @param {ParticipationCodeType} [type] 
         * @param {boolean} [ignorePagination] 
         * @param {string} [orderBy] 
         * @param {number} [pageSize] Number of items per page
         * @param {number} [pageNumber] Page number starting from 1
         * @param {string} [orderDir] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsGroupIdSelectedCodesGet(forumId: number, groupId: number, code?: string, description?: string, type?: ParticipationCodeType, ignorePagination?: boolean, orderBy?: string, pageSize?: number, pageNumber?: number, orderDir?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsGroupIdSelectedCodesGet(forumId, groupId, code, description, type, ignorePagination, orderBy, pageSize, pageNumber, orderDir, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a group for forum
         * @param {number} forumId 
         * @param {ForumGroupRequestModel} [forumGroupRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdGroupsPost(forumId: number, forumGroupRequestModel?: ForumGroupRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumGroupResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdGroupsPost(forumId, forumGroupRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumGroupApi - factory interface
 * @export
 */
export const ForumGroupApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumGroupApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets a list of groups for forum
         * @param {ForumGroupApiForumForumIdGroupsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGet(requestParameters: ForumGroupApiForumForumIdGroupsGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumGroupResponseModel>> {
            return localVarFp.forumForumIdGroupsGet(requestParameters.forumId, requestParameters.primary, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of available participation codes
         * @param {ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdAvilableCodesGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdAvilableCodesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.code, requestParameters.description, requestParameters.type, requestParameters.ignorePagination, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes a list of participation codes codes from a group
         * @param {ForumGroupApiForumForumIdGroupsGroupIdCodeDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdCodeDelete(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdCodeDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdCodeDelete(requestParameters.forumId, requestParameters.groupId, requestParameters.addDeleteParticipationCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds a list of participation code to a group
         * @param {ForumGroupApiForumForumIdGroupsGroupIdCodePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdCodePost(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdCodePostRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdCodePost(requestParameters.forumId, requestParameters.groupId, requestParameters.addDeleteParticipationCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset contact person
         * @param {ForumGroupApiForumForumIdGroupsGroupIdContactPersonDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdContactPersonDelete(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdContactPersonDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdContactPersonDelete(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Assign contact person
         * @param {ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdContactPersonUserIdPut(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdContactPersonUserIdPut(requestParameters.forumId, requestParameters.groupId, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates flags of a non-primary group
         * @param {ForumGroupApiForumForumIdGroupsGroupIdFlagsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdFlagsPut(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdFlagsPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdFlagsPut(requestParameters.forumId, requestParameters.groupId, requestParameters.forumGroupFlagsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a group by id for forum
         * @param {ForumGroupApiForumForumIdGroupsGroupIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdGet(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdParticipationCodesGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ParticipationCodeModel>> {
            return localVarFp.forumForumIdGroupsGroupIdParticipationCodesGet(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates a group for forum
         * @param {ForumGroupApiForumForumIdGroupsGroupIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdPut(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdPut(requestParameters.forumId, requestParameters.groupId, requestParameters.forumGroupRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the list of selected participation codes
         * @param {ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsGroupIdSelectedCodesGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGetRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumForumIdGroupsGroupIdSelectedCodesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.code, requestParameters.description, requestParameters.type, requestParameters.ignorePagination, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a group for forum
         * @param {ForumGroupApiForumForumIdGroupsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdGroupsPost(requestParameters: ForumGroupApiForumForumIdGroupsPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumGroupResponseModel> {
            return localVarFp.forumForumIdGroupsPost(requestParameters.forumId, requestParameters.forumGroupRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdGroupsGet operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGetRequest
 */
export interface ForumGroupApiForumForumIdGroupsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGet
     */
    readonly forumId: number

    /**
     * 
     * @type {boolean}
     * @memberof ForumGroupApiForumForumIdGroupsGet
     */
    readonly primary?: boolean

    /**
     * 
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdGroupsGroupIdAvilableCodesGet operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGetRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly groupId: number

    /**
     * If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly code?: string

    /**
     * If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly description?: string

    /**
     * 
     * @type {ParticipationCodeType}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly type?: ParticipationCodeType

    /**
     * 
     * @type {boolean}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly ignorePagination?: boolean

    /**
     * 
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdGroupsGroupIdCodeDelete operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdCodeDeleteRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdCodeDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdCodeDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdCodeDelete
     */
    readonly groupId: number

    /**
     * 
     * @type {Array<AddDeleteParticipationCodeModel>}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdCodeDelete
     */
    readonly addDeleteParticipationCodeModel?: Array<AddDeleteParticipationCodeModel>
}

/**
 * Request parameters for forumForumIdGroupsGroupIdCodePost operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdCodePostRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdCodePostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdCodePost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdCodePost
     */
    readonly groupId: number

    /**
     * 
     * @type {Array<AddDeleteParticipationCodeModel>}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdCodePost
     */
    readonly addDeleteParticipationCodeModel?: Array<AddDeleteParticipationCodeModel>
}

/**
 * Request parameters for forumForumIdGroupsGroupIdContactPersonDelete operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdContactPersonDeleteRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdContactPersonDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdContactPersonDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdContactPersonDelete
     */
    readonly groupId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdContactPersonUserIdPut operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPutRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPut
     */
    readonly groupId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPut
     */
    readonly userId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdFlagsPut operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdFlagsPutRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdFlagsPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdFlagsPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdFlagsPut
     */
    readonly groupId: number

    /**
     * 
     * @type {ForumGroupFlagsRequestModel}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdFlagsPut
     */
    readonly forumGroupFlagsRequestModel?: ForumGroupFlagsRequestModel
}

/**
 * Request parameters for forumForumIdGroupsGroupIdGet operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdGetRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdGet
     */
    readonly groupId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdParticipationCodesGet operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGetRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGet
     */
    readonly groupId: number
}

/**
 * Request parameters for forumForumIdGroupsGroupIdPut operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdPutRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdPut
     */
    readonly groupId: number

    /**
     * 
     * @type {ForumGroupRequestModel}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdPut
     */
    readonly forumGroupRequestModel?: ForumGroupRequestModel
}

/**
 * Request parameters for forumForumIdGroupsGroupIdSelectedCodesGet operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGetRequest
 */
export interface ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly groupId: number

    /**
     * If Code is specified - only available codes with that code will be returned  Otherwise all available codes will be returned
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly code?: string

    /**
     * If Description is specified - only available codes with that description will be returned  Otherwise all available codes will be returned
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly description?: string

    /**
     * 
     * @type {ParticipationCodeType}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly type?: ParticipationCodeType

    /**
     * 
     * @type {boolean}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly ignorePagination?: boolean

    /**
     * 
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly orderBy?: string

    /**
     * Number of items per page
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly pageSize?: number

    /**
     * Page number starting from 1
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {string}
     * @memberof ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGet
     */
    readonly orderDir?: string
}

/**
 * Request parameters for forumForumIdGroupsPost operation in ForumGroupApi.
 * @export
 * @interface ForumGroupApiForumForumIdGroupsPostRequest
 */
export interface ForumGroupApiForumForumIdGroupsPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumGroupApiForumForumIdGroupsPost
     */
    readonly forumId: number

    /**
     * 
     * @type {ForumGroupRequestModel}
     * @memberof ForumGroupApiForumForumIdGroupsPost
     */
    readonly forumGroupRequestModel?: ForumGroupRequestModel
}

/**
 * ForumGroupApi - object-oriented interface
 * @export
 * @class ForumGroupApi
 * @extends {BaseAPI}
 */
export class ForumGroupApi extends BaseAPI {
    /**
     * 
     * @summary Gets a list of groups for forum
     * @param {ForumGroupApiForumForumIdGroupsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGet(requestParameters: ForumGroupApiForumForumIdGroupsGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGet(requestParameters.forumId, requestParameters.primary, requestParameters.orderBy, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of available participation codes
     * @param {ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdAvilableCodesGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdAvilableCodesGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdAvilableCodesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.code, requestParameters.description, requestParameters.type, requestParameters.ignorePagination, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes a list of participation codes codes from a group
     * @param {ForumGroupApiForumForumIdGroupsGroupIdCodeDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdCodeDelete(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdCodeDeleteRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdCodeDelete(requestParameters.forumId, requestParameters.groupId, requestParameters.addDeleteParticipationCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds a list of participation code to a group
     * @param {ForumGroupApiForumForumIdGroupsGroupIdCodePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdCodePost(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdCodePostRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdCodePost(requestParameters.forumId, requestParameters.groupId, requestParameters.addDeleteParticipationCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset contact person
     * @param {ForumGroupApiForumForumIdGroupsGroupIdContactPersonDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdContactPersonDelete(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdContactPersonDeleteRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdContactPersonDelete(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Assign contact person
     * @param {ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdContactPersonUserIdPut(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdContactPersonUserIdPutRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdContactPersonUserIdPut(requestParameters.forumId, requestParameters.groupId, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates flags of a non-primary group
     * @param {ForumGroupApiForumForumIdGroupsGroupIdFlagsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdFlagsPut(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdFlagsPutRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdFlagsPut(requestParameters.forumId, requestParameters.groupId, requestParameters.forumGroupFlagsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a group by id for forum
     * @param {ForumGroupApiForumForumIdGroupsGroupIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdGet(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdParticipationCodesGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdParticipationCodesGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdParticipationCodesGet(requestParameters.forumId, requestParameters.groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates a group for forum
     * @param {ForumGroupApiForumForumIdGroupsGroupIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdPut(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdPutRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdPut(requestParameters.forumId, requestParameters.groupId, requestParameters.forumGroupRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the list of selected participation codes
     * @param {ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsGroupIdSelectedCodesGet(requestParameters: ForumGroupApiForumForumIdGroupsGroupIdSelectedCodesGetRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsGroupIdSelectedCodesGet(requestParameters.forumId, requestParameters.groupId, requestParameters.code, requestParameters.description, requestParameters.type, requestParameters.ignorePagination, requestParameters.orderBy, requestParameters.pageSize, requestParameters.pageNumber, requestParameters.orderDir, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a group for forum
     * @param {ForumGroupApiForumForumIdGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumGroupApi
     */
    public forumForumIdGroupsPost(requestParameters: ForumGroupApiForumForumIdGroupsPostRequest, options?: AxiosRequestConfig) {
        return ForumGroupApiFp(this.configuration).forumForumIdGroupsPost(requestParameters.forumId, requestParameters.forumGroupRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

