import apiClient from "../../../api-client";
import getUniqueKeys from "../../helpers/getUniqueKeys";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const keys = {
  roomGrades: (params) => [KEYS.GET_ROOM_GRADES, ...getUniqueKeys(params)],
};

export const useRoomGradesQuery = getWrappedUseQuery(KEYS.GET_ROOM_GRADES, apiClient.RoomGrade.get);
