import { FormInstance } from "antd";
import { TFunction } from "react-i18next";

const VENUE_CODE_REGEX = "^.{5}$";

export const validateBuildingCode = (_: unknown, value: string) => {
  return value.length >= 0 && value.length <= 5 ? Promise.resolve() : Promise.reject();
};

export const validateFileSize = (maxSize: number) => async (_: unknown, value: unknown) => {
  const file = Array.isArray(value) ? value[0] : value;

  if (!file) {
    return false;
  }

  const size = file.size;

  if (size < maxSize) {
    return true;
  }

  return Promise.reject("File size is bigger than expected");
};

export const validateFileType = (extensions: string[]) => async (_: unknown, value: unknown) => {
  const file = Array.isArray(value) ? value[0] : value;

  if (!file) {
    return false;
  }

  const availableFileExtensions = Array.isArray(extensions) ? extensions : [extensions];
  const currentFileExtension = file.name.split(".").reverse()[0];

  return availableFileExtensions.includes(currentFileExtension) ? Promise.resolve() : Promise.reject();
};

export const validateMergeTags =
  (mergeTagsRegex: RegExp, t: TFunction<"translation", undefined>, mergeTags?: string[]) =>
  async (_: unknown, value: string) => {
    const matches = value.match(mergeTagsRegex);
    const invalidMatches = matches?.filter((m: string) => !mergeTags?.includes(m));

    if (matches && invalidMatches && invalidMatches.length > 0) {
      const invalidMatchesString = invalidMatches.join(", ");

      return Promise.reject(t("You have invalid merge tags: ") + invalidMatchesString);
    } else {
      return Promise.resolve();
    }
  };

export const validateVenueCode = () => async (_: unknown, value: string) => {
  return value.length == 0 || value.match(VENUE_CODE_REGEX) ? Promise.resolve() : Promise.reject();
};

export const validateMaxStringLength = (maxLength: number) => async (_: unknown, value: string) => {
  return value.length <= maxLength ? Promise.resolve() : Promise.reject();
};

export const validateIsNotEqual = (form: FormInstance, field: string) => async (_: unknown, value: unknown) => {
  if (value === undefined || value === null) {
    return Promise.resolve();
  }

  const otherValue = form.getFieldValue(field);

  if (value !== otherValue) {
    return true;
  }

  return Promise.reject(`Field cannot be equal to ${field}`);
};
