import React from "react";
import { Collapse as AntCollapse } from "antd";
import clsx from "clsx";

import styles from "./styles.module.less";

const Collapse = ({ className, ...props }) => {
  const classes = clsx(styles.collapse, className);

  return <AntCollapse className={classes} bordered={false} {...props} />;
};

const Panel = ({ className, ...props }) => {
  const classes = clsx(styles.panel, className);

  return <AntCollapse.Panel className={classes} {...props} />;
};

Collapse.Panel = Panel;

export default Collapse;
