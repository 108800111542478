import { Space } from "antd";
import { AttendeeModel } from "generated/api";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DefaultSortOptions } from "../constants";
import AttendeesTableOption from "../OptionTypes/AttendeesTableOption";
import SortOrderOption from "../OptionTypes/SortByOption";
import WithFullReasonSelectOption from "../OptionTypes/WithFullReasonSelectOption";
import WithJointSelectOption from "../OptionTypes/WithJointSelectOption";
import WithNegativesSelectOption from "../OptionTypes/WithNegativesSelectOption";
import WithReasonSelectOption from "../OptionTypes/WithReasonSelectOption";

export const SortOptions = { ...DefaultSortOptions, Priority: "Priority" };

const IndividualMeetingPreferencesPreCrunchByOptions = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<string>(SortOptions.Surname);
  const [isWithJoint, setIsWithJoint] = useState<boolean>(false);
  const [isWithReason, setIsWithReason] = useState<boolean>(false);
  const [isWithFullReason, setIsWithFullReason] = useState<boolean>(false);
  const [isWithNegatives, setIsWithNegatives] = useState<boolean>(false);

  const onAttendeeSelect = useCallback(
    (attendee: AttendeeModel) => {
      navigate("report", {
        state: {
          parameters: {
            OrderBy: orderBy,
            WithJoint: isWithJoint,
            WithNegatives: isWithNegatives,
            WithReason: isWithReason,
            WithFullReason: isWithFullReason,
            ForumAttendeeId: attendee.attendId,
          },
        },
      });
    },
    [orderBy, isWithJoint, isWithNegatives, isWithReason, isWithFullReason, navigate],
  );

  return (
    <Space direction="vertical" size={"middle"} style={{ width: "100%" }}>
      <Space wrap>
        <SortOrderOption orderBy={orderBy} setOrderBy={setOrderBy} options={SortOptions} />
        <WithJointSelectOption isWithJoint={isWithJoint} setIsWithJoint={setIsWithJoint} />
        <WithNegativesSelectOption isWithNegatives={isWithNegatives} setIsWithNegatives={setIsWithNegatives} />
        <WithReasonSelectOption isWithReason={isWithReason} setIsWithReason={setIsWithReason} />
        <WithFullReasonSelectOption isWithFullReason={isWithFullReason} setIsWithFullReason={setIsWithFullReason} />
      </Space>

      <AttendeesTableOption onAttendeeSelect={onAttendeeSelect} />
    </Space>
  );
};

export default IndividualMeetingPreferencesPreCrunchByOptions;
