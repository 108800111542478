import { useSendEmailToAttendeesMutation } from "api/mutations/email";
import {
  useGetDefaultEmailTemplatesQuery,
  useGetEmailTemplateDetails,
  useGetEmailTemplatesQuery,
} from "api/queries/emails";
import { useUserInfoQuery } from "api/queries/user";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Form, Input, Select } from "components/styleguide";
import DOMPurify from "dompurify";
import { AttendeeTaskStatusModel, EmailTemplateResponse, ForumGroupTaskStatus, TaskCategory } from "generated/api";
import { uniq, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { EMAIL_CATEGORIES, EMAIL_CATEGORIES_OPTIONS } from "utils/emailTemplates";

import styles from "./styles.module.less";

type ToField = {
  id: number;
  label: string;
  firstName: string;
  lastName: string;
  company: string;
  taskStatus: ForumGroupTaskStatus;
  value: number;
  groupMembership: string[];
};

type GroupMembership = {
  id: number;
  label: string;
  firstName: string;
  lastName: string;
  company: string;
  taskStatus: ForumGroupTaskStatus;
  value: string;
};

const getEmailCategory = (taskValue: TaskCategory | undefined) => {
  switch (taskValue) {
    case TaskCategory.Questionnaire: {
      return EMAIL_CATEGORIES.questionnaire;
    }
    case TaskCategory.SelectionSite: {
      return EMAIL_CATEGORIES.selectionSite;
    }
    default: {
      return EMAIL_CATEGORIES.general;
    }
  }
};

const { Option } = Select;

export default function EmailTaskDrawer({
  visible,
  forumId,
  attendees,
  selectedAll,
  isFiltered,
  taskCategory,
  onClose,
}: {
  visible: boolean;
  forumId: number;
  attendees: AttendeeTaskStatusModel[];
  selectedAll: boolean;
  isFiltered: boolean;
  taskCategory: TaskCategory | undefined;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [toFieldValues, setToFieldValues] = useState<ToField[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState(getEmailCategory(taskCategory));
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateResponse | undefined>();

  const sendEmailToAttendeesMutation = useSendEmailToAttendeesMutation();

  const { data: { data: userInfo = {} } = {} } = useUserInfoQuery({});

  const { isLoading: isLoadingEmailTemplates, data: { data: emailTemplates = [] } = {} } = useGetEmailTemplatesQuery();
  const { isLoading: isLoadingDefaultEmailTemplates, data: { data: defaultEmailTemplates = [] } = {} } =
    useGetDefaultEmailTemplatesQuery();

  useGetEmailTemplateDetails(selectedTemplateId, {
    enabled: Boolean(selectedTemplateId),
    onSuccess: ({ data }: { data: EmailTemplateResponse }) => {
      setEmailTemplate(data);
    },
  });

  const handleClose = () => {
    onClose();
    form.resetFields(["subject", "template", "emailTemplateCategory, groupMembership"]);
    setEmailTemplate(undefined);
  };

  const handleSubmit = () => {
    const { subject, template } = form.getFieldsValue();

    const to = selectedAll
      ? attendees.map(({ id }) => id)
      : form.getFieldValue("to").map((element: ToField) => element.value);

    sendEmailToAttendeesMutation.mutate(
      {
        forumId,
        sendEmailToMultipleUsersRequest: { subject, attendeeIds: to, templateId: template },
      },
      {
        onSuccess: () => {
          toast.success(t("Emails successfully sent"));
          handleClose();
        },
      },
    );
  };

  useEffect(() => {
    if (visible) {
      setSelectedCategory(getEmailCategory(taskCategory));
      form.resetFields(["emailTemplateCategory"]);
    }
  }, [visible]);

  useEffect(() => {
    if (userInfo !== null && attendees.length) {
      const to = attendees
        .filter((attendee) => !!attendee.email)
        .map(
          (attendee) =>
            ({
              value: attendee.id,
              label: attendee.email,
              ...attendee,
            } as ToField),
        );

      setToFieldValues(to);

      const fields = [
        {
          name: "to",
          value: selectedAll ? (isFiltered ? t("All Filtered Attendees") : t("All Attendees")) : to,
        },
        {
          name: "from",
          value: [{ value: userInfo.email, label: userInfo.email }],
        },
        { name: "groupMembership", value: attendees[0].groupMembership },
      ];
      form.setFields(fields);
    }
  }, [attendees, userInfo, visible, form]);

  useEffect(() => {
    if (emailTemplate) {
      form.setFieldValue("subject", emailTemplate.subject);
    }
  }, [emailTemplate, form]);

  function createEmailPreview() {
    if (emailTemplate && emailTemplate.body) {
      return { __html: DOMPurify.sanitize(emailTemplate.body.htmlBody) };
    }
  }

  const filteredTemplatesSelectOptions = useMemo(() => {
    if (!(emailTemplates && defaultEmailTemplates)) return;

    return [...defaultEmailTemplates, ...emailTemplates]
      .filter((template) => template.emailTemplateCategory === selectedCategory)
      .map((template) => (
        <Option value={template.id} label={template.name} key={template.id}>
          {template.name}
        </Option>
      ));
  }, [defaultEmailTemplates, emailTemplates, selectedCategory]);

  const handleToDeselect = () => {
    const to = form.getFieldValue("to");
    const groups = form.getFieldValue("groupMembership");
    const groupNames = uniq(groups?.flatMap((group: GroupMembership) => group.value));
    const mappedToFields = attendees?.filter((attendee) => {
      return to.some((toField: ToField) => attendee.id === toField.value);
    });

    const filteredGroups = groups
      .filter(
        (group: GroupMembership) =>
          groupNames.includes(group.value) &&
          mappedToFields.some((participant: AttendeeTaskStatusModel) =>
            participant.groupMembership.includes(group.value),
          ),
      )
      .flatMap((attendee: AttendeeTaskStatusModel) =>
        attendee.groupMembership.map((groupMember: string) => ({
          value: groupMember,
          label: groupMember,
          ...attendee,
        })),
      );
    const uniqGroups = uniqBy(filteredGroups, "value");

    form.setFields([{ name: "groupMembership", value: uniqGroups }]);
  };

  return (
    <Drawer
      title={t("Email Attendees")}
      placement="right"
      onClose={onClose}
      open={visible}
      contentWrapperStyle={{ minWidth: "40%" }}
      destroyOnClose
      className={styles.sendEmailDrawer}
    >
      <FullHeightForm
        form={form}
        actionsPrepend={
          <Button onClick={handleClose} disabled={sendEmailToAttendeesMutation.isLoading}>
            {t("cancel")}
          </Button>
        }
        actionsAppend={
          <Button
            type="primary"
            disabled={sendEmailToAttendeesMutation.isLoading}
            loading={sendEmailToAttendeesMutation.isLoading}
            htmlType="submit"
          >
            {t("Send")}
          </Button>
        }
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t("Category")}
          name="emailTemplateCategory"
          labelCol={{ span: 24, offset: 0 }}
          initialValue={selectedCategory}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Category)" }),
            },
          ]}
        >
          <Select
            onChange={(category) => {
              setSelectedCategory(category);
            }}
            placeholder={t("placeholders.select", { prop: "$t(Category)" })}
            options={EMAIL_CATEGORIES_OPTIONS}
          />
        </Form.Item>
        <Form.Item
          label={t("Template")}
          name="template"
          labelCol={{ span: 24, offset: 0 }}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Template)" }),
            },
          ]}
        >
          <Select
            onChange={(id) => setSelectedTemplateId(id)}
            showSearch
            placeholder={t("placeholders.select", { prop: "$t(Template)" })}
            loading={isLoadingEmailTemplates || isLoadingDefaultEmailTemplates}
            optionFilterProp={"label"}
          >
            {filteredTemplatesSelectOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label={t("To")}
          name="to"
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Recipient)" }),
            },
          ]}
          getValueFromEvent={(values) => {
            setToFieldValues(values); // This is just to make useEffect track this value and update remove icon
            return values;
          }}
          labelCol={{ span: 24, offset: 0 }}
        >
          <Select
            labelInValue
            mode="multiple"
            onDeselect={handleToDeselect}
            notFoundContent={null}
            showSearch={false}
            removeIcon={toFieldValues.length <= 1 || selectedAll ? null : undefined}
          />
        </Form.Item>
        <Form.Item
          label={t("Target Group")}
          name="groupMembership"
          labelCol={{ span: 24, offset: 0 }}
          initialValue={""}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item label={t("From")} name="from" labelCol={{ span: 24, offset: 0 }}>
          <Select mode="multiple" notFoundContent={null} showSearch={false} removeIcon={null} />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 24, offset: 0 }}
          label={t("Subject")}
          name={"subject"}
          rules={[
            {
              required: true,
              message: t("errors.required", { prop: "$t(Subject)" }),
            },
          ]}
        >
          <Input placeholder={t("placeholders.add", { prop: "$t(Subject)" })} />
        </Form.Item>
        <div className={styles.emailPreview} dangerouslySetInnerHTML={createEmailPreview()} />
      </FullHeightForm>
    </Drawer>
  );
}
