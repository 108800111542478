import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useAttendeeReplaceMutation } from "api/mutations/attendees";
import { useGetAttendeePotentialReplacementsQuery } from "api/queries/attendees";
import KEYS from "api/queries/keys";
import FullHeightForm from "components/FullHeightForm";
import { Button, Drawer, Modal, Table } from "components/styleguide";
import AttendeeStatus from "enums/AttendeeStatus";
import {
  AttendeeAttendanceDetailsModel,
  AttendeePotentialReplacementResponseModel,
  ForumAttendeeResponseModel,
  PageResponseAttendeePotentialReplacementResponseModel,
} from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import { toNumber } from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getColumnSearchProps } from "utils/tableColumnUtils";

export default function Replace({
  attendee,
  attendanceDetails,
  visible,
  onClose,
}: {
  attendee: ForumAttendeeResponseModel;
  attendanceDetails: AttendeeAttendanceDetailsModel;
  visible: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const forumId = toNumber(useParams().id);
  const queryClient = useQueryClient();

  const [replacementAttendeeId, setReplacementAttendeeId] = useState<number | null>(null);
  const [firstNameSearch, setFirstNameSearch] = useState<string | undefined>(undefined);
  const [lastNameSearch, setLastNameSearch] = useState<string | undefined>(undefined);
  const [people, setPeople] = useState<AttendeePotentialReplacementResponseModel[] | undefined>();

  const { mutate: replaceAttendeeMutate, isLoading: isAttendeeReplacing } = useAttendeeReplaceMutation();

  const {
    response: query,
    handleSort,
    pagination,
  } = useModernQueryWithPaginationAndOrder(
    useGetAttendeePotentialReplacementsQuery,
    {
      onSuccess: ({ data }: { data: PageResponseAttendeePotentialReplacementResponseModel }) => {
        setPeople(data.items);
      },
    },
    {
      forumId,
      attendeeId: attendanceDetails.id,
      firstName: firstNameSearch,
      lastName: lastNameSearch,
    },
    { defaultPageSize: 10, pageSizeOptions: [10], showSizeChange: false },
  );

  const handleSearch = (selectedKeys: string[], confirm: () => void, _: string, searchSet: string) => {
    switch (searchSet) {
      case "firstName": {
        setFirstNameSearch(selectedKeys[0]);
        break;
      }
      case "lastName": {
        setLastNameSearch(selectedKeys[0]);
        break;
      }
    }

    confirm?.();
  };
  const handleReset = (clearFilters: () => void, _: string, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "firstName": {
        setFirstNameSearch(undefined);
        break;
      }
      case "lastName": {
        setLastNameSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    confirm?.();
  };

  const replaceAttendee = () => {
    if (replacementAttendeeId) {
      replaceAttendeeMutate(
        {
          forumId,
          attendeeId: toNumber(attendanceDetails.id),
          attendeeReplacementRequestModel: { replacementPersonId: replacementAttendeeId },
        },
        {
          onSuccess: () => {
            queryClient.resetQueries([KEYS.GET_ATTENDEE_ATTENDANCE_DETAILS]);
            queryClient.resetQueries([KEYS.GET_ATTENDEE_CONTACT_DETAILS]);
            queryClient.resetQueries([KEYS.GET_ATTENDEE_HISTORY_INFORMATION]);
            queryClient.resetQueries([KEYS.GET_ATTENDEE]);
          },
          onError: () => toast.error(t("Something went wrong")),
          onSettled: onClose,
        },
      );
    }
  };

  const getAttendeeById = (attendeeId: number) => people?.find((person) => person.personId === attendeeId);

  const getAttendeeFullName = (forumAttendee: ForumAttendeeResponseModel | AttendeePotentialReplacementResponseModel) =>
    `${forumAttendee.firstName} ${forumAttendee.lastName}`;

  const getAttendeeFullNameById = (attendeeId: number) => {
    const forumAttendee = getAttendeeById(attendeeId);

    return forumAttendee && getAttendeeFullName(forumAttendee);
  };

  const сonfirmReplacementAttendee = (currentAttendeeFullName: string, replacementAttendeeFullName: string) => {
    Modal.confirm({
      title: t("confirmation"),
      content: t("Attendee {{X}} will be replaced with Attendee {{Y}}. Do you want to proceed?", {
        X: currentAttendeeFullName,
        Y: replacementAttendeeFullName,
      }),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("No"),
      okText: t("Yes"),
      onOk: () => replaceAttendee(),
    });
  };

  const onReplaceAttendee = () => {
    if (replacementAttendeeId) {
      const currentAttendeeFullName = getAttendeeFullName(attendee);
      const replacementAttendeeFullName = getAttendeeFullNameById(replacementAttendeeId);

      if (currentAttendeeFullName && replacementAttendeeFullName) {
        сonfirmReplacementAttendee(currentAttendeeFullName, replacementAttendeeFullName);
      }
    }
  };

  const columns = [
    {
      title: t("First Name"),
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
      ...(getColumnSearchProps(
        "firstName",
        handleSearch,
        handleReset,
        "firstName",
      ) as Partial<AttendeePotentialReplacementResponseModel>),
    },
    {
      title: t("Last Name"),
      dataIndex: "lastName",
      key: "lastName",
      sorter: true,
      ...(getColumnSearchProps(
        "lastName",
        handleSearch,
        handleReset,
        "lastName",
      ) as Partial<AttendeePotentialReplacementResponseModel>),
    },
    { title: t("Job Title"), dataIndex: "jobTitle", sorter: true },
    { title: t("Participation Code"), dataIndex: "participationCode", sorter: true },
  ];

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: AttendeePotentialReplacementResponseModel[]) => {
      setReplacementAttendeeId(selectedRows.length > 0 && selectedRows[0]?.personId ? selectedRows[0].personId : null);
    },
    getCheckboxProps: (record: AttendeePotentialReplacementResponseModel) => ({
      disabled:
        record.attendeeStatus === AttendeeStatus.AttendingReplacement || record.personId == attendanceDetails.id,
    }),
  };

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={t("Replace Attendee")}
      contentWrapperStyle={{ minWidth: "40%" }}
      destroyOnClose={true}
    >
      <FullHeightForm
        onFinish={onReplaceAttendee}
        className={"replace-attendee-form"}
        actionsPrepend={<Button onClick={onClose}>{t("Cancel")}</Button>}
        actionsAppend={
          <Button
            type="primary"
            htmlType="submit"
            disabled={replacementAttendeeId === null || isAttendeeReplacing}
            loading={isAttendeeReplacing}
          >
            {t("Save")}
          </Button>
        }
      >
        <Table
          columns={columns}
          dataSource={people}
          loading={query.isLoading}
          rowSelection={{ type: "radio", ...rowSelection }}
          rowKey="personId"
          pagination={pagination}
          onChange={handleSort}
        />
      </FullHeightForm>
    </Drawer>
  );
}
