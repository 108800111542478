import React from "react";
import PropTypes from "prop-types";
import QuestionnaireType from "../../../../backend-models/questionnaireType";
import QuestionnaireStatusClass from "../../../../backend-models/questionnaireStatus";
import { Table } from "../../../../components/styleguide";

const GenericQuestionnairesTable = ({
  className,
  items,
  loading,
  columns,
  ...props
}) => {
  return (
    <Table
      data-testid="questionnairesTable"
      className={className}
      dataSource={items}
      columns={columns}
      bordered
      loading={loading}
      {...props}
    />
  );
};

GenericQuestionnairesTable.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      forumId: PropTypes.number,
      eventCode: PropTypes.string,
      name: PropTypes.string,
      width: PropTypes.string,
      type: PropTypes.oneOf(QuestionnaireType.VALUES),
      status: PropTypes.oneOf(QuestionnaireStatusClass.VALUES),
    }),
  ),
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    dataIndex: PropTypes.string,
    sorter: PropTypes.bool,
    render: PropTypes.node,
  })),
  actions: PropTypes.node,
};

GenericQuestionnairesTable.defaultProps = {
  className: undefined,
  loading: false,
  items: [],
  appendColumns: [],
  actions: undefined,
};

export default GenericQuestionnairesTable;
