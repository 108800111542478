import { Collapse, Row } from "antd";
import { useUpdateForumContactInfoMutation } from "api/mutations/forums";
import { useForumContactInfoQuery, useGetForumByIdQuery, useGetForumSocialLinksQuery } from "api/queries/forums";
import DetailBox from "components/DetailBox";
import { Button, Form, Spin } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { ForumContactInfoModel, ForumResponseModel, ForumSocialLinksModel } from "generated/api";
import { chunk, toNumber } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { activeKeys, ForumDetailLabels } from "./constants";
import ContactInfo from "./ContactInfo";
import SocialLinks from "./SocialLinks";
import TeamMembers from "./TeamMembers";

import styles from "./styles.module.less";

const { Panel } = Collapse;

const General = () => {
  const location = useLocation();
  const { id } = useParams();
  const { t } = useTranslation();

  const [contactInfo, setContactInfo] = useState<ForumContactInfoModel | undefined>();
  const [socialLinks, setSocialLinks] = useState<ForumSocialLinksModel | undefined>();
  const [isEditingSocialLinks, setIsEditingSocialLinks] = useState(false);

  const [socialLinksForm] = Form.useForm();
  const [contactInfoForm] = Form.useForm();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        element?.scrollIntoView();
      }, 1000);
    }
  }, [location.hash]);

  const { data: { data: details = {} } = {}, isLoading: isForumLoading } = useGetForumByIdQuery(
    { forumId: toNumber(id) },
    {
      refetchOnWindowFocus: false,
    },
  );

  const updateContactInfoMutation = useUpdateForumContactInfoMutation();

  const forumContactInfoQuery = useForumContactInfoQuery(
    { forumId: toNumber(id) },
    {
      onSuccess: ({ data }) => {
        setContactInfo(data);
        contactInfoForm.setFieldsValue(data);
      },
      refetchOnWindowFocus: false,
    },
  );

  const socialLinksQuery = useGetForumSocialLinksQuery(
    { forumId: toNumber(id) },
    {
      onSuccess: ({ data }) => {
        setSocialLinks(data);
      },
    },
  );

  const updateContactInfo = (values: ForumContactInfoModel) => {
    updateContactInfoMutation.mutate(
      { forumId: toNumber(id), forumContactInfoModel: values },
      {
        onSuccess: ({ data }) => {
          setContactInfo(data);
        },
      },
    );
  };

  const resetContactInfo = () => {
    contactInfoForm.setFieldsValue(contactInfo);
  };

  const editSocialLinks = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsEditingSocialLinks(true);
  };

  const forumDetailLabelsPaired = useMemo(() => chunk(ForumDetailLabels, 2), []);

  const detailBoxes = forumDetailLabelsPaired.map((pair, idx) => {
    const isLastPair = idx === forumDetailLabelsPaired.length - 1;
    const leftElement = pair[0];
    const rightElement = pair[1];
    const leftElementData = details ? details[leftElement.dataindex as keyof ForumResponseModel] : null;
    const rightElementData = details
      ? rightElement && details[rightElement.dataindex as keyof ForumResponseModel]
      : null;

    return (
      <Row key={leftElement.dataindex} className={styles.detailsBox} gutter={80}>
        <DetailBox data={leftElementData} {...leftElement} isLastPair={isLastPair} />
        {rightElement && <DetailBox data={rightElementData} {...rightElement} isLastPair={isLastPair} />}
      </Row>
    );
  });

  return (
    <div id="generalPage" className={styles.generalPage}>
      <Collapse className={styles.collapseParent} bordered={false} defaultActiveKey={activeKeys} ghost>
        <UserGuide.Panel
          id="details"
          className={styles.collapsePanel}
          title="Details"
          articleId="16426881774877-details"
          key="details"
        >
          <div className={styles.details}>
            {isForumLoading ? (
              <span className={"loader"}>
                <Spin />
              </span>
            ) : (
              detailBoxes
            )}
          </div>
        </UserGuide.Panel>
        <UserGuide.Panel
          id="contact"
          className={styles.collapsePanel}
          title="contactInformation"
          articleId="16426917045533-contact-information"
          key="contact"
        >
          <ContactInfo
            data={contactInfo}
            onSubmit={updateContactInfo}
            onReset={resetContactInfo}
            form={contactInfoForm}
            loading={updateContactInfoMutation.isLoading || forumContactInfoQuery.isLoading}
          />
        </UserGuide.Panel>
        <UserGuide.Panel
          id="social-links"
          className={styles.collapsePanel}
          title="Social Links"
          articleId="16426928874269-social-links"
          key="social-links"
          extra={<Button onClick={editSocialLinks}>{t("Edit")}</Button>}
        >
          <SocialLinks
            isLoading={socialLinksQuery.isLoading}
            socialLinks={socialLinks}
            isEditing={isEditingSocialLinks}
            form={socialLinksForm}
            setSocialLinks={setSocialLinks}
            setIsEditing={setIsEditingSocialLinks}
          />
        </UserGuide.Panel>
        <UserGuide.Panel
          id="team-members-roles"
          title="Team Members And Roles"
          articleId="16426943946141-team-members-and-roles"
          key="team-members-roles"
          className={styles.collapsePanel}
        >
          <TeamMembers />
        </UserGuide.Panel>
      </Collapse>
    </div>
  );
};

export default General;
