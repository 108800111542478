import { Space } from "components/styleguide";
import React, { useState } from "react";

import LinkToReport from "../../components/LinkToReport";
import SisterEventsSelectOption from "../SisterEventsSelectOption";

// option with sister events select and link to report
const SisterEventsOption = () => {
  const [sisterEvents, setSisterEvents] = useState<number[]>([]);

  return (
    <Space align="end">
      <SisterEventsSelectOption sisterEvents={sisterEvents} setSisterEvents={setSisterEvents} />
      <LinkToReport parameters={{ SisterEventIds: sisterEvents.join(",") }} />
    </Space>
  );
};

export default SisterEventsOption;
