import { ForumAttendeeAvailabilityApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeAvailabilityApi);

const ForumAttendeeAvailability = {
  getAll: api.forumForumIdAttendeesAttendeeIdAvailabilityGet,
  post: api.forumForumIdAttendeesAttendeeIdAvailabilityPost,
  get: api.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet,
  put: api.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut,
  delete: api.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete,
};

export default ForumAttendeeAvailability;
