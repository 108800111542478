import { Select } from "components/styleguide";
import TimeSlotTypes from "enums/TimeSlotType";
import { AttendeeMeetingLocationModel, FacilityResponseModel } from "generated/api";
import { AttendeeMeetingLocationTimeSlotTypeModel } from "generated/api/apiModels/attendee-meeting-location-time-slot-type-model";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import AttendeeMeetingLocationsQuickSetupSelectTableCell from "./SelectTableCell";

interface AttendeeMeetingLocationsQuickSetupRowProps {
  forumId: number;
  meetingLocation: AttendeeMeetingLocationTimeSlotTypeModel;
  setMeetingLocation: (index: number, record: AttendeeMeetingLocationModel) => void;
  forumFacilities: FacilityResponseModel[];
  index: number;
  loading: boolean;
}

const AttendeeMeetingLocationsQuickSetupRow: FC<AttendeeMeetingLocationsQuickSetupRowProps> = ({
  forumId,
  meetingLocation,
  setMeetingLocation,
  forumFacilities,
  index,
  loading,
}) => {
  const { t } = useTranslation();

  const [key, setKey] = useState(0);

  return (
    <tr>
      <td style={{ width: "40%" }}>{t(`timeTypes.${TimeSlotTypes[meetingLocation.timeSlotType]}`)}</td>
      <td style={{ width: "35%" }}>
        <Select
          style={{ width: "100%" }}
          options={[
            { value: null, label: t("None") },
            ...forumFacilities.map((facility) => ({
              value: facility.id,
              label: facility.name,
              forumBuildingId: facility.buildingId,
            })),
          ]}
          defaultValue={meetingLocation.forumFacilityId}
          onChange={(value, option) => {
            const { forumBuildingId } = option as { forumBuildingId: number };
            setMeetingLocation(index, {
              ...meetingLocation,
              forumBuildingId,
              forumFacilityId: value,
              forumTableId: null,
            });
            setKey(key + 1);
          }}
          loading={loading}
        />
      </td>
      <td style={{ width: "15%" }}>
        <AttendeeMeetingLocationsQuickSetupSelectTableCell
          forumId={forumId}
          meetingLocation={meetingLocation}
          setMeetingLocation={setMeetingLocation}
          index={index}
          loading={loading}
          key={key}
        />
      </td>
      <td style={{ width: "10%" }}>
        <Select
          style={{ width: "100%" }}
          defaultValue={meetingLocation.ratio}
          onChange={(value) => setMeetingLocation(index, { ...meetingLocation, ratio: value })}
          loading={loading}
        >
          {[...Array(13).keys()].map((ratio) => (
            <Select.Option key={ratio} value={ratio}>
              {ratio}
            </Select.Option>
          ))}
        </Select>
      </td>
    </tr>
  );
};

export default AttendeeMeetingLocationsQuickSetupRow;
