import { Select, YesNo } from "components/styleguide";
import { ForumResponseModel, TranslationLanguage } from "generated/api";
import React from "react";

import SisterEventsSelect from "./components/SisterEventsSelect";
import { ItinerarySettingValue } from "./types";

import styles from "./styles.module.less";

export const getSections = (
  onSubmit: (data: ItinerarySettingValue, dataIndex: string) => void,
  languages: Array<TranslationLanguage>,
  forums: Array<ForumResponseModel>,
) => [
  {
    title: "Decks, Floors and Tables",
    formItems: [
      {
        label: "Decks / Floor Label",
        dataIndex: "floorLabel",
      },
      {
        label: "Floor Label Position",
        dataIndex: "floorLabelPosition",
        render: (data: ItinerarySettingValue) => (
          <Select
            className={styles.select}
            value={data}
            onSelect={(value) => onSubmit(value, "floorLabelPosition")}
            options={[
              { value: "First", label: "First" },
              { value: "Last", label: "Last" },
              { value: null, label: "None" },
            ]}
          />
        ),
      },
      { label: "Table Label", dataIndex: "tableLabel" },
      {
        label: "Show Lounge Tables",
        dataIndex: "showLoungeTables",
        render: (data: ItinerarySettingValue) => (
          <YesNo className={styles.select} value={data} onSelect={(value) => onSubmit(value, "showLoungeTables")} />
        ),
      },
      { label: "Speed meet Stand Label", dataIndex: "speedMeet" },
      { label: "Date Join Label", dataIndex: "dateJoinLabel" },
    ],
  },
  {
    title: "Date and Time Format",
    formItems: [
      {
        label: "Time Format",
        dataIndex: "timeFormat",
      },
      {
        label: "Long Date Format",
        dataIndex: "longDateFormat",
      },
      {
        label: "Long Date Format Year",
        dataIndex: "longDateFormatYear",
      },
    ],
  },
  {
    title: "Page Size",
    formItems: [
      {
        label: "Page Size",
        dataIndex: "pageSize",
        render: (data: ItinerarySettingValue) => (
          <Select
            className={styles.select}
            value={data}
            onSelect={(value) => onSubmit(value, "pageSize")}
            options={[{ value: "A4", label: "A4" }]}
          />
        ),
      },
    ],
  },
  {
    title: "Venue Map",
    formItems: [
      {
        label: "Show Venue Map",
        dataIndex: "showVenueMapField",
        render: (data: ItinerarySettingValue) => (
          <YesNo className={styles.select} value={data} onSelect={(value) => onSubmit(value, "showVenueMapField")} />
        ),
      },
      {
        label: "Venue Map Heading",
        dataIndex: "venueMapHeadingField",
      },
    ],
  },
  {
    title: "Language",
    formItems: [
      {
        label: "Language",
        dataIndex: "languageId",
        render: (data: ItinerarySettingValue) => (
          <Select
            className={styles.select}
            value={data}
            onSelect={(value) => onSubmit(value, "languageId")}
            options={languages.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        ),
      },
    ],
  },
  {
    title: "Footer",
    formItems: [
      {
        label: "Show Social media in Footer",
        dataIndex: "showSocialMediaInFooter",
        render: (data: ItinerarySettingValue) => (
          <YesNo
            className={styles.select}
            value={data}
            onSelect={(value) => onSubmit(value, "showSocialMediaInFooter")}
          />
        ),
      },
      {
        label: "Social Media Details",
        dataIndex: "socialMediaDetails",
      },
      {
        label: "Social Media Details Location",
        dataIndex: "socialMediaDetailsLocation",
        render: (data: ItinerarySettingValue) => (
          <Select
            className={styles.select}
            value={data}
            onSelect={(value) => onSubmit(value, "socialMediaDetailsLocation")}
            options={[
              { value: "Outside edges", label: "Outside Edges" },
              { value: "Inside edges", label: "Inside Edges" },
              { value: "Left aligned", label: "Left Aligned" },
              { value: "Right aligned", label: "Right Aligned" },
            ]}
          />
        ),
      },
    ],
  },
  {
    title: "Sister Events",
    description:
      "Define the sister (co-located) events. This option determines the attendee list when the add sister event sections are used in a document.",
    formItems: [
      {
        label: "Sister Events",
        dataIndex: "sisterForumsIds",
        render: (data: ItinerarySettingValue) => (
          <SisterEventsSelect
            initValue={data}
            forums={forums}
            onSubmit={(selectedForums) => onSubmit(selectedForums, "sisterForumsIds")}
          />
        ),
      },
    ],
  },
];
