import { EditTwoTone } from "@ant-design/icons";
import React, { CSSProperties, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Col, Row } from "../styleguide";
import EditField from "./EditField";

import styles from "./styles.module.less";

interface EditableFieldProps {
  name: string;
  initialValue: any;
  isUpdating: boolean;
  onUpdate: (values: any, onCloseEditing: () => void) => void;
  children: JSX.Element;
  title?: string;
  displayValue?: string;
  style?: CSSProperties;
  titleStyle?: CSSProperties;
  valueStyle?: CSSProperties;
}

const EditableField: FC<EditableFieldProps> = ({
  name,
  initialValue,
  isUpdating,
  onUpdate,
  children,
  title,
  displayValue,
  style,
  titleStyle,
  valueStyle,
}) => {
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onOpenEditing = () => setIsEditing(true);

  const onCloseEditing = () => setIsEditing(false);

  const onFinishEditing = (values: any) => onUpdate(values, onCloseEditing);

  return (
    <Row className={styles.container} align="middle" {...{ style }}>
      {title && (
        <Col className={styles.title} style={titleStyle}>
          {t(title)}:
        </Col>
      )}
      <Col className={styles.value} style={valueStyle}>
        {isEditing ? (
          <EditField {...{ name, initialValue, isUpdating }} onUpdate={onFinishEditing} onClose={onCloseEditing}>
            {children}
          </EditField>
        ) : (
          <>
            {displayValue ?? initialValue}
            <EditTwoTone className={styles.editIcon} onClick={onOpenEditing} />
          </>
        )}
      </Col>
    </Row>
  );
};

export default EditableField;
