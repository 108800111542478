/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForumAttendeeAvailabilityRequestModel } from '../apiModels';
// @ts-ignore
import { ForumAttendeeAvailabilityResponseModel } from '../apiModels';
// @ts-ignore
import { ObjectNotFoundExceptionResponse } from '../apiModels';
/**
 * ForumAttendeeAvailabilityApi - axios parameter creator
 * @export
 */
export const ForumAttendeeAvailabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete attendee availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} availabilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete: async (forumId: number, attendeeId: number, availabilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete', 'attendeeId', attendeeId)
            // verify required parameter 'availabilityId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete', 'availabilityId', availabilityId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/availability/{availabilityId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"availabilityId"}}`, encodeURIComponent(String(availabilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attendee`s availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} availabilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet: async (forumId: number, attendeeId: number, availabilityId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet', 'attendeeId', attendeeId)
            // verify required parameter 'availabilityId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet', 'availabilityId', availabilityId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/availability/{availabilityId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"availabilityId"}}`, encodeURIComponent(String(availabilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update attendee availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} availabilityId 
         * @param {ForumAttendeeAvailabilityRequestModel} [forumAttendeeAvailabilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut: async (forumId: number, attendeeId: number, availabilityId: number, forumAttendeeAvailabilityRequestModel?: ForumAttendeeAvailabilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut', 'attendeeId', attendeeId)
            // verify required parameter 'availabilityId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut', 'availabilityId', availabilityId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/availability/{availabilityId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)))
                .replace(`{${"availabilityId"}}`, encodeURIComponent(String(availabilityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumAttendeeAvailabilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get attendee`s all availability records
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityGet: async (forumId: number, attendeeId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityGet', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityGet', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/availability`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates attendee availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {ForumAttendeeAvailabilityRequestModel} [forumAttendeeAvailabilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityPost: async (forumId: number, attendeeId: number, forumAttendeeAvailabilityRequestModel?: ForumAttendeeAvailabilityRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityPost', 'forumId', forumId)
            // verify required parameter 'attendeeId' is not null or undefined
            assertParamExists('forumForumIdAttendeesAttendeeIdAvailabilityPost', 'attendeeId', attendeeId)
            const localVarPath = `/forum/{forumId}/attendees/{attendeeId}/availability`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"attendeeId"}}`, encodeURIComponent(String(attendeeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forumAttendeeAvailabilityRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForumAttendeeAvailabilityApi - functional programming interface
 * @export
 */
export const ForumAttendeeAvailabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForumAttendeeAvailabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete attendee availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} availabilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete(forumId: number, attendeeId: number, availabilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete(forumId, attendeeId, availabilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get attendee`s availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} availabilityId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet(forumId: number, attendeeId: number, availabilityId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeeAvailabilityResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet(forumId, attendeeId, availabilityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update attendee availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {number} availabilityId 
         * @param {ForumAttendeeAvailabilityRequestModel} [forumAttendeeAvailabilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut(forumId: number, attendeeId: number, availabilityId: number, forumAttendeeAvailabilityRequestModel?: ForumAttendeeAvailabilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut(forumId, attendeeId, availabilityId, forumAttendeeAvailabilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get attendee`s all availability records
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAvailabilityGet(forumId: number, attendeeId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ForumAttendeeAvailabilityResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAvailabilityGet(forumId, attendeeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates attendee availability record
         * @param {number} forumId 
         * @param {number} attendeeId 
         * @param {ForumAttendeeAvailabilityRequestModel} [forumAttendeeAvailabilityRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumForumIdAttendeesAttendeeIdAvailabilityPost(forumId: number, attendeeId: number, forumAttendeeAvailabilityRequestModel?: ForumAttendeeAvailabilityRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForumAttendeeAvailabilityResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumForumIdAttendeesAttendeeIdAvailabilityPost(forumId, attendeeId, forumAttendeeAvailabilityRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForumAttendeeAvailabilityApi - factory interface
 * @export
 */
export const ForumAttendeeAvailabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForumAttendeeAvailabilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete attendee availability record
         * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.availabilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attendee`s availability record
         * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGetRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeeAvailabilityResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.availabilityId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update attendee availability record
         * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPutRequest, options?: AxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.availabilityId, requestParameters.forumAttendeeAvailabilityRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get attendee`s all availability records
         * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityGet(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<ForumAttendeeAvailabilityResponseModel>> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAvailabilityGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates attendee availability record
         * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumForumIdAttendeesAttendeeIdAvailabilityPost(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPostRequest, options?: AxiosRequestConfig): AxiosPromise<ForumAttendeeAvailabilityResponseModel> {
            return localVarFp.forumForumIdAttendeesAttendeeIdAvailabilityPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumAttendeeAvailabilityRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete operation in ForumAttendeeAvailabilityApi.
 * @export
 * @interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDeleteRequest
 */
export interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete
     */
    readonly availabilityId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet operation in ForumAttendeeAvailabilityApi.
 * @export
 * @interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGetRequest
 */
export interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet
     */
    readonly availabilityId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut operation in ForumAttendeeAvailabilityApi.
 * @export
 * @interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPutRequest
 */
export interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPutRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut
     */
    readonly attendeeId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut
     */
    readonly availabilityId: number

    /**
     * 
     * @type {ForumAttendeeAvailabilityRequestModel}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut
     */
    readonly forumAttendeeAvailabilityRequestModel?: ForumAttendeeAvailabilityRequestModel
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAvailabilityGet operation in ForumAttendeeAvailabilityApi.
 * @export
 * @interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGetRequest
 */
export interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGet
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGet
     */
    readonly attendeeId: number
}

/**
 * Request parameters for forumForumIdAttendeesAttendeeIdAvailabilityPost operation in ForumAttendeeAvailabilityApi.
 * @export
 * @interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPostRequest
 */
export interface ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPostRequest {
    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPost
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPost
     */
    readonly attendeeId: number

    /**
     * 
     * @type {ForumAttendeeAvailabilityRequestModel}
     * @memberof ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPost
     */
    readonly forumAttendeeAvailabilityRequestModel?: ForumAttendeeAvailabilityRequestModel
}

/**
 * ForumAttendeeAvailabilityApi - object-oriented interface
 * @export
 * @class ForumAttendeeAvailabilityApi
 * @extends {BaseAPI}
 */
export class ForumAttendeeAvailabilityApi extends BaseAPI {
    /**
     * 
     * @summary Delete attendee availability record
     * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeAvailabilityApi
     */
    public forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDeleteRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeAvailabilityApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdDelete(requestParameters.forumId, requestParameters.attendeeId, requestParameters.availabilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attendee`s availability record
     * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeAvailabilityApi
     */
    public forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeAvailabilityApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdGet(requestParameters.forumId, requestParameters.attendeeId, requestParameters.availabilityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update attendee availability record
     * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeAvailabilityApi
     */
    public forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPutRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeAvailabilityApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAvailabilityAvailabilityIdPut(requestParameters.forumId, requestParameters.attendeeId, requestParameters.availabilityId, requestParameters.forumAttendeeAvailabilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get attendee`s all availability records
     * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeAvailabilityApi
     */
    public forumForumIdAttendeesAttendeeIdAvailabilityGet(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityGetRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeAvailabilityApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAvailabilityGet(requestParameters.forumId, requestParameters.attendeeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates attendee availability record
     * @param {ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForumAttendeeAvailabilityApi
     */
    public forumForumIdAttendeesAttendeeIdAvailabilityPost(requestParameters: ForumAttendeeAvailabilityApiForumForumIdAttendeesAttendeeIdAvailabilityPostRequest, options?: AxiosRequestConfig) {
        return ForumAttendeeAvailabilityApiFp(this.configuration).forumForumIdAttendeesAttendeeIdAvailabilityPost(requestParameters.forumId, requestParameters.attendeeId, requestParameters.forumAttendeeAvailabilityRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

