import { CloseCircleOutlined, EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { BaseOptionType } from "antd/lib/select";
import { TableProps } from "antd/lib/table";
import { SorterResult } from "antd/lib/table/interface";
import { useCreateCompanyMutation, useCreateForumCompaniesMutation } from "api/mutations/companies";
import { useAttendeesQuery } from "api/queries/attendees";
import { useGetCountriesQuery } from "api/queries/countries";
import KEYS from "api/queries/keys";
import { useParticipationCodesQuery } from "api/queries/participationCodes";
import { Button, Form, Input, Popover, Table, Tag } from "components/styleguide";
import UserGuide from "components/UserGuide";
import { InfoSize } from "components/UserGuide/Info";
import AttendeeStatus from "enums/AttendeeStatus";
import {
  AttendeeModel,
  AttendeeStatus as ApiAttendeeStatus,
  CompanyModel,
  CountryModel,
  PageResponseAttendeeModel,
  UserInfoModel,
} from "generated/api";
import { useDebounce } from "hooks/useDebounce";
import { EventLayoutContext } from "layouts/EventLayout/types";
import { toNumber } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useOutletContext } from "react-router-dom";
import { removeOccurrences } from "utils/arrayUtils";
import { EMAIL_CATEGORIES } from "utils/emailTemplates";

import { useModernQueryWithPaginationAndOrder } from "../../hooks";
import { getColumnSearchProps, getColumnSelectFilterProps } from "../../utils/tableColumnUtils";
import ChooseCompanyDrawer from "./ChooseCompanyDrawer";
import MembershipGroupsTags from "./components/MembershipGroupsTags";
import ExportAttendeesButton from "./ExportAttendeesButton";
import ExportSupplierPackageButton from "./ExportSupplierPackageButton";
import Filters from "./Filters";
import { AvailableFilters } from "./Filters/types";
import NewCompanyDrawer from "./NewCompanyDrawer";
import SendEmailDrawer from "./SendEmailDrawer";
import applyAttendeesFilters from "./utils/apply-attendees-filters";

import styles from "./styles.module.less";

const SEARCHABLE_COLUMN_TITLES = {
  firstName: "First Name",
  lastName: "Last Name",
  company: "Company",
  jobTitle: "Job Title",
  email: "Email",
  groupMembership: "Group Memberships",
};

const Attendees = ({ userInfo }: { userInfo: UserInfoModel | undefined }) => {
  const { id } = useOutletContext<EventLayoutContext>();
  const location = useLocation();
  const [addCompanyForm] = Form.useForm();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [appliedFilters, setAppliedFilters] = useState<AvailableFilters | undefined>();

  const [attendees, setAttendees] = useState<AttendeeModel[] | undefined>([]);
  const [countries, setCountries] = useState<CountryModel[] | undefined>([]);
  const [peopleTypesOptions, setPeopleTypesOptions] = useState<BaseOptionType[] | undefined>([]);
  const [selectedAttendees, setSelectedAttendees] = useState<AttendeeModel[] | undefined>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [companyDrawerVisible, setCompanyDrawerVisible] = useState(false);
  const [addCompanyDrawerVisible, setAddCompanyDrawerVisible] = useState(false);
  const [isSendEmailDrawerVisible, setIsSendEmailDrawerVisible] = useState(false);
  const [allFlag, setAllFlag] = useState(false);

  const [firstNameSearch, setFirstNameSearch] = useState<string | undefined>();
  const [lastNameSearch, setLastNameSearch] = useState<string | undefined>();
  const [companySearch, setCompanySearch] = useState<string | undefined>();
  const [jobTitleSearch, setJobTitleSearch] = useState<string | undefined>();
  const [emailSearch, setEmailSearch] = useState<string | undefined>();
  const [attendStatusSearch, setAttendStatusSearch] = useState<ApiAttendeeStatus[] | undefined>();
  const [groupMembershipSearch, setGroupMembershipSearch] = useState<string | undefined>();
  const [groupMembershipTagSearch, setGroupMembershipTagSearch] = useState<string | undefined>();
  const [peopleTypesSearch, setPeopleTypesSearch] = useState<string[] | undefined>();

  const createForumCompaniesMutation = useCreateForumCompaniesMutation();
  const createCompanyMutation = useCreateCompanyMutation();

  const hasSelected = selectedCompanies.length > 0;
  const [exportOrderBy, setExportOrderBy] = useState<string | undefined>();
  const [exportOrderDir, setExportOrderDir] = useState<string | undefined>();

  const [globalSearchValue, setGlobalSearchValue] = useState<string | null>(null);
  const [globalDebouncedSearchValue, setGlobalDebouncedSearchValue] = useState("");

  const debouncedSearchTerm = useDebounce(globalSearchValue, 300, 2);

  const isFiltered = !!(
    firstNameSearch ||
    lastNameSearch ||
    companySearch ||
    jobTitleSearch ||
    emailSearch ||
    attendStatusSearch ||
    groupMembershipSearch ||
    groupMembershipTagSearch ||
    peopleTypesSearch ||
    globalDebouncedSearchValue ||
    appliedFilters
  );

  const {
    response: { isLoading },
    pagination,
    handleSort,
  } = useModernQueryWithPaginationAndOrder(
    useAttendeesQuery,
    {
      onSuccess: ({ data }: { data: PageResponseAttendeeModel }) => {
        setAttendees(data.items);
      },
    },
    {
      forumId: id,
      firstName: firstNameSearch,
      lastName: lastNameSearch,
      company: companySearch,
      jobTitle: jobTitleSearch,
      email: emailSearch,
      attendeeStatus: attendStatusSearch,
      group: groupMembershipSearch ?? groupMembershipTagSearch,
      peopleTypes: peopleTypesSearch,
      globalSearch: globalDebouncedSearchValue,
      ...applyAttendeesFilters(appliedFilters),
    },
  );

  const { isLoading: isAllAttendeesLoading, data: { data: { items: allAttendees = [] } = {} } = {} } =
    useAttendeesQuery({
      forumId: id,
      firstName: firstNameSearch,
      lastName: lastNameSearch,
      company: companySearch,
      jobTitle: jobTitleSearch,
      email: emailSearch,
      attendeeStatus: attendStatusSearch,
      group: groupMembershipSearch ?? groupMembershipTagSearch,
      peopleTypes: peopleTypesSearch,
      globalSearch: globalDebouncedSearchValue,
      pageSize: undefined,
      ...applyAttendeesFilters(appliedFilters),
    });

  const handleGlobalSearch = (value: string | null) => {
    setGlobalSearchValue(value);
  };

  useEffect(() => {
    setGlobalDebouncedSearchValue(globalSearchValue ?? "");
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (selectedAttendees?.length === allAttendees.length && allAttendees.length > 0) {
      setAllFlag(true);
    }
  }, [selectedAttendees, allAttendees]);

  useGetCountriesQuery(
    {},
    {
      onSuccess: ({ data }: { data: CountryModel[] }) => {
        setCountries(data);
      },
    },
  );

  const { isLoading: isCodesLoading } = useParticipationCodesQuery(
    {
      ignorePagination: true,
    },
    {
      onSuccess: ({ data: { items = [] } = {} }) => {
        const codes = items.map(({ code }) => ({ value: code, label: code }));
        setPeopleTypesOptions(codes);
      },
    },
  );

  const showCompanyDrawer = () => {
    queryClient.invalidateQueries([KEYS.GET_COMPANIES]);
    setCompanyDrawerVisible(true);
  };

  const showAddCompanyDrawer = async () => {
    addCompanyForm.resetFields();
    setAddCompanyDrawerVisible(true);
  };

  const hideCompanyDrawer = () => {
    setCompanyDrawerVisible(false);
  };

  const hideAddCompanyDrawer = () => {
    setAddCompanyDrawerVisible(false);
  };

  const companiesRowSelection = {
    selectedRowKeys: selectedCompanies,
    onChange: (newSelectedCompanyKeys: number[]) => {
      setSelectedCompanies(newSelectedCompanyKeys);
      companiesRowSelection.selectedRowKeys = selectedCompanies;
    },
  };

  const handleAddForumCompanyClick = () =>
    createForumCompaniesMutation.mutate(
      { forumId: id, requestBody: selectedCompanies },
      {
        onSuccess: async () => {
          setSelectedCompanies([]);
          companiesRowSelection.onChange([]);
          hideCompanyDrawer();
        },
      },
    );

  const handleCreateCompany = (companyModel: CompanyModel) =>
    createCompanyMutation.mutate(
      { companyModel },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_COMPANIES]);
          hideAddCompanyDrawer();
        },
      },
    );

  const handleUnselectAllAttendees = () => {
    setSelectedAttendees([]);
    setAllFlag(false);
  };

  const handleSearch = (
    selectedKeys: string[] | BaseOptionType[],
    confirm: () => void,
    _: string,
    searchSet: string,
  ) => {
    switch (searchSet) {
      case "firstName": {
        setFirstNameSearch(selectedKeys[0] as string);
        break;
      }
      case "lastName": {
        setLastNameSearch(selectedKeys[0] as string);
        break;
      }
      case "forumCompany": {
        setCompanySearch(selectedKeys[0] as string);
        break;
      }
      case "jobTitle": {
        setJobTitleSearch(selectedKeys[0] as string);
        break;
      }
      case "email": {
        setEmailSearch(selectedKeys[0] as string);
        break;
      }
      case "attendStatus": {
        const option = selectedKeys[0] as BaseOptionType;
        setAttendStatusSearch(option.value);
        break;
      }
      case "groupMembership": {
        setGroupMembershipSearch(selectedKeys[0] as string);
        setGroupMembershipTagSearch(undefined);
        break;
      }
      case "groupMembershipTag": {
        setGroupMembershipTagSearch(selectedKeys[0] as string);
        setGroupMembershipSearch(undefined);
        break;
      }
      case "peopleType": {
        setPeopleTypesSearch((selectedKeys as BaseOptionType[]).map(({ value }) => value));
        break;
      }
    }

    handleUnselectAllAttendees();
    confirm?.();
  };

  const handleReset = (clearFilters: () => void, _: unknown, searchSet: string, confirm: () => void) => {
    switch (searchSet) {
      case "firstName": {
        setFirstNameSearch(undefined);
        break;
      }
      case "lastName": {
        setLastNameSearch(undefined);
        break;
      }
      case "forumCompany": {
        setCompanySearch(undefined);
        break;
      }
      case "jobTitle": {
        setJobTitleSearch(undefined);
        break;
      }
      case "email": {
        setEmailSearch(undefined);
        break;
      }
      case "attendStatus": {
        setAttendStatusSearch(undefined);
        break;
      }
      case "groupMembership": {
        setGroupMembershipSearch(undefined);
        break;
      }
      case "groupMembershipTag": {
        setGroupMembershipTagSearch(undefined);
        break;
      }
      case "peopleType": {
        setPeopleTypesSearch(undefined);
        break;
      }
    }

    clearFilters?.();
    handleUnselectAllAttendees();
    confirm?.();
  };

  const getAttendeeStatusOptions = () => {
    return Object.keys(AttendeeStatus)
      .filter((key) => key !== "toDisplayName")
      .map((key) => ({
        value: AttendeeStatus[key as keyof typeof AttendeeStatus],
        label: t(
          `attendeeStatus.${AttendeeStatus.toDisplayName(
            toNumber(AttendeeStatus[key as keyof typeof AttendeeStatus]),
          )}`,
        ),
      }));
  };

  const attendeeColumns = [
    {
      title: firstNameSearch ? (
        <span className={styles.filteredTitle}>{firstNameSearch}</span>
      ) : (
        SEARCHABLE_COLUMN_TITLES.firstName
      ),
      dataIndex: "firstName",
      key: "firstName",
      sorter: true,
      render: (data: string, record: AttendeeModel) => {
        return (
          <Link
            className={styles.link}
            to={`${location.pathname}/${record.attendId}`}
            state={{ attendeeName: record.firstName + " " + record.lastName }}
          >
            {data}
          </Link>
        );
      },
      ...getColumnSearchProps("firstName", handleSearch, handleReset, "firstName", globalSearchValue),
    },
    {
      title: lastNameSearch ? (
        <span className={styles.filteredTitle}>{lastNameSearch}</span>
      ) : (
        SEARCHABLE_COLUMN_TITLES.lastName
      ),
      dataIndex: "lastName",
      key: "lastName",
      sorter: true,
      render: (data: string, record: AttendeeModel) => {
        return (
          <Link
            to={`${location.pathname}/${record.attendId}`}
            state={{ attendeeName: record.firstName + " " + record.lastName }}
            className={styles.link}
          >
            {data}
          </Link>
        );
      },
      ...getColumnSearchProps("lastName", handleSearch, handleReset, "lastName", globalSearchValue),
    },
    {
      title: companySearch ? (
        <span className={styles.filteredTitle}>{companySearch}</span>
      ) : (
        SEARCHABLE_COLUMN_TITLES.company
      ),
      dataIndex: "forumCompany",
      key: "forumCompany",
      sorter: true,
      ...getColumnSearchProps("forumCompany", handleSearch, handleReset, "forumCompany", globalSearchValue),
      render: (data: string, record: AttendeeModel) => {
        return (
          <Link className={styles.link} to={`../attendees/company/${record?.forumCompanyId}`}>
            {data}
          </Link>
        );
      },
    },
    {
      title: jobTitleSearch ? (
        <span className={styles.filteredTitle}>{jobTitleSearch}</span>
      ) : (
        t(SEARCHABLE_COLUMN_TITLES.jobTitle)
      ),
      dataIndex: "jobTitle",
      key: "jobTitle",
      sorter: true,
      ...getColumnSearchProps("jobTitle", handleSearch, handleReset, "jobTitle", globalSearchValue),
    },
    {
      title: emailSearch ? <span className={styles.filteredTitle}>{emailSearch}</span> : SEARCHABLE_COLUMN_TITLES.email,
      dataIndex: "email",
      key: "email",
      sorter: true,
      ...getColumnSearchProps("email", handleSearch, handleReset, "email", globalSearchValue),
    },
    {
      title: t("attendStatus"),
      dataIndex: "attendStatus",
      key: "attendStatus",
      render: (attendStatus: number) => t(`attendeeStatus.${AttendeeStatus.toDisplayName(attendStatus)}`),
      sorter: true,
      ...getColumnSelectFilterProps(
        "attendStatus",
        handleSearch,
        handleReset,
        "attendStatus",
        getAttendeeStatusOptions(),
        globalSearchValue,
        false,
      ),
    },
    {
      title: t("peopleType"),
      dataIndex: "peopleType",
      key: "peopleType",
      sorter: true,
      ...getColumnSelectFilterProps(
        "peopleType",
        handleSearch,
        handleReset,
        "peopleType",
        peopleTypesOptions,
        globalSearchValue,
      ),
    },
    {
      title: groupMembershipSearch ? (
        <span className={styles.filteredTitle}>{groupMembershipSearch}</span>
      ) : (
        t(SEARCHABLE_COLUMN_TITLES.groupMembership)
      ),
      dataIndex: "groupMembership",
      key: "groupMembership",
      render: (groups: string[]) => {
        if (groups?.length <= 1) {
          return (
            <MembershipGroupsTags
              groups={groups}
              handleReset={handleReset}
              handleSearch={handleSearch}
              groupSearch={groupMembershipTagSearch}
              disabled={!!groupMembershipSearch}
            />
          );
        } else {
          const [firstGroup, ...rest] = groups;

          return (
            <>
              <MembershipGroupsTags
                groups={[firstGroup]}
                handleReset={handleReset}
                handleSearch={handleSearch}
                groupSearch={groupMembershipTagSearch}
                disabled={!!groupMembershipSearch}
              />
              <Popover
                placement="bottomRight"
                content={() => (
                  <MembershipGroupsTags
                    groups={rest}
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    groupSearch={groupMembershipTagSearch}
                    disabled={!!groupMembershipSearch}
                  />
                )}
              >
                <Tag className={styles.tag} color="default">
                  <EllipsisOutlined />
                </Tag>
              </Popover>
            </>
          );
        }
      },
      ...getColumnSearchProps("groupMembership", handleSearch, handleReset, "groupMembership", globalSearchValue),
    },
  ];

  const rowSelection = {
    onSelect: (selectedRow: AttendeeModel, isSelection: boolean) => {
      if (isSelection) {
        setSelectedAttendees([...(selectedAttendees as AttendeeModel[]), selectedRow]);
        setAllFlag(false);
      } else {
        setSelectedAttendees(removeOccurrences(selectedAttendees as AttendeeModel[], selectedRow, "attendId"));
        setAllFlag(false);
      }
    },
    onSelectAll: (isSelection: boolean, _: unknown, newSelected: AttendeeModel[]) => {
      if (isSelection) {
        setSelectedAttendees([...(selectedAttendees as AttendeeModel[]), ...newSelected]);
        setAllFlag(false);
      } else {
        setSelectedAttendees(removeOccurrences(selectedAttendees as AttendeeModel[], newSelected, "attendId"));
        setAllFlag(false);
      }
    },
    selectedRowKeys: selectedAttendees?.map((attendee) => attendee.attendId),
  };

  const onChange: TableProps<AttendeeModel>["onChange"] = (_, __, sorter) => {
    handleSort(_, __, sorter);
    const sortOptions = sorter as SorterResult<AttendeeModel>;
    setExportOrderBy((sortOptions.columnKey as string) ?? undefined);
    setExportOrderDir(sortOptions.order === "ascend" ? "asc" : sortOptions.order === "descend" ? "desc" : undefined);
  };

  const emailCategory = useMemo(() => {
    if (appliedFilters?.type === "selectionSites") {
      return EMAIL_CATEGORIES.selectionSite;
    } else if (appliedFilters?.type === "questionnaire") {
      return EMAIL_CATEGORIES.questionnaire;
    } else {
      return EMAIL_CATEGORIES.general;
    }
  }, [appliedFilters?.type]);

  const handleSelectAll = () => {
    if (allFlag) {
      handleUnselectAllAttendees();
    } else {
      setSelectedAttendees([...allAttendees]);
      setAllFlag(true);
    }
  };

  return (
    <div id="attendees-page">
      <div className={styles.header}>
        <Filters value={appliedFilters} onChange={setAppliedFilters} />
        <Input
          allowClear
          onChange={(e) => handleGlobalSearch(e.target.value)}
          placeholder={t("Search by attendee, company or email")}
          prefix={<SearchOutlined />}
          className={styles.globalSearch}
        />
        <Button onClick={showCompanyDrawer}>{t("Add Attendees")}</Button>
        <ExportAttendeesButton
          firstName={firstNameSearch}
          lastName={lastNameSearch}
          company={companySearch}
          jobTitle={jobTitleSearch}
          email={emailSearch}
          attendeeStatus={attendStatusSearch}
          group={groupMembershipSearch ?? groupMembershipTagSearch}
          peopleTypes={peopleTypesSearch}
          orderBy={exportOrderBy}
          orderDir={exportOrderDir}
        />
        <ExportSupplierPackageButton />
        {!!selectedAttendees?.length && (
          <Button onClick={() => setIsSendEmailDrawerVisible(true)}>{t("Send Email")}</Button>
        )}
      </div>
      <div className={styles.page}>
        <div className={styles.controls}>
          <Button onClick={handleSelectAll} loading={isAllAttendeesLoading} disabled={isAllAttendeesLoading}>
            {selectedAttendees && selectedAttendees.length > 0 && allFlag
              ? t("Unselect all attendees")
              : t("Select all attendees")}
          </Button>
          {!!selectedAttendees?.length && (
            <div className={styles.selectedAttendees}>
              <span>{t("{{count}} items selected", { count: selectedAttendees.length })}</span>
              <CloseCircleOutlined onClick={handleUnselectAllAttendees} className={styles.removeSelection} />
            </div>
          )}
          <ChooseCompanyDrawer
            visible={companyDrawerVisible}
            submitDisabled={!hasSelected}
            loadingOnSubmit={createForumCompaniesMutation.isLoading}
            onClose={hideCompanyDrawer}
            onAddNewClick={showAddCompanyDrawer}
            onSubmit={handleAddForumCompanyClick}
          />
          <NewCompanyDrawer
            countries={countries}
            form={addCompanyForm}
            visible={addCompanyDrawerVisible}
            isLoading={createCompanyMutation.isLoading}
            onClose={hideAddCompanyDrawer}
            onFinish={handleCreateCompany}
          />
          <SendEmailDrawer
            forumId={id}
            emailCategory={emailCategory}
            onClose={() => setIsSendEmailDrawerVisible(false)}
            isOpen={isSendEmailDrawerVisible}
            attendees={selectedAttendees}
            userInfo={userInfo}
            selectedAll={allFlag}
            isFiltered={isFiltered}
          />
          <UserGuide.Info className={styles.info} articleId="16421077708445-attendees" size={InfoSize.BIG} />
        </div>
        <Table
          id="attendeesTable"
          className="attendees-table"
          rowKey="attendId"
          rowSelection={rowSelection}
          bordered={true}
          loading={isLoading || isCodesLoading}
          dataSource={attendees}
          columns={attendeeColumns}
          pagination={pagination}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

Attendees.propTypes = {
  userInfo: PropTypes.any,
};

export default Attendees;
