import { ForumAttendeeMeetingsApi } from "generated/api";

import { initAPIClient } from "./_base";

const api = initAPIClient(ForumAttendeeMeetingsApi);

const AttendeeOnSiteMeetings = {
  post: api.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAttendeesPost,
  availableAttendees: {
    get: api.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioGet,
  },
  availablePreferences: {
    get: api.forumForumIdAttendeeMeetingsTimeSlotSessionIdExecutivesUnderRatioExecutiveAttendeeIdAvailableAttendeesGet,
  },
};

export default AttendeeOnSiteMeetings;
