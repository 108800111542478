const ForumGroupType = {
  DisplayType: 1,
  General: 2,
  Selection: 3,

  toDisplayName(type) {
    switch (type) {
      case 1:
        return "DisplayType";
      case 2:
        return "General";
      case 3:
        return "Selection";
      default:
        return "";
    }
  },
};

export default ForumGroupType;
