import { useAllTimeslotsSessionsQuery } from "api/queries/timeslotSession";
import { Select, Space, Typography } from "components/styleguide";
import { TimeSlotSessionResponseModel, TimeSlotType } from "generated/api";
import { toNumber } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { AllMealtimes } from "../../constants";

const { Text } = Typography;

interface MealtimeOptionProps {
  session?: number | typeof AllMealtimes;
  setSession: Dispatch<SetStateAction<number | typeof AllMealtimes | undefined>>;
  showAllMealtimesOption?: boolean;
}

const MealtimeOption = ({ session, setSession, showAllMealtimesOption = false }: MealtimeOptionProps) => {
  const { t } = useTranslation();

  const forumId = toNumber(useParams().id);

  const [mealtimeOptions, setMealtimeOptions] = useState<{ value: string | number; label: string }[]>([]);

  const { isLoading: isSessionsLoading } = useAllTimeslotsSessionsQuery(
    {
      forumId,
      timeSlotTypes: [TimeSlotType.Breakfast, TimeSlotType.Lunch, TimeSlotType.Dinner],
    },
    {
      onSuccess: ({ data }) => {
        const allMealtimesOption = { value: AllMealtimes, label: t("All Mealtimes") };
        const baseMealtimesOptions = data
          .sort((a, b) => a.startTime?.localeCompare(b.startTime as string) as number)
          .map(({ id, startTime, timeSlotType }: TimeSlotSessionResponseModel) => ({
            value: id as number,
            label: `${moment(startTime).format(t("dateFormatWithTime"))} - ${timeSlotType}`,
          }));

        setMealtimeOptions(
          showAllMealtimesOption ? [allMealtimesOption, ...baseMealtimesOptions] : baseMealtimesOptions,
        );
      },
    },
  );

  return (
    <Space direction={"vertical"}>
      <Text>{t("Mealtime")}</Text>
      <Select
        style={{ minWidth: "200px" }}
        dropdownStyle={{ minWidth: "250px" }}
        placeholder={t("Select mealtime")}
        options={mealtimeOptions}
        value={session}
        onChange={setSession}
        loading={isSessionsLoading}
        allowClear
        showSearch
        optionFilterProp={"label"}
      />
    </Space>
  );
};

export default MealtimeOption;
