import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// The same <Link> component, the only difference that it
// merges the state in the link instead of overwriting it
const StatefulLink = ({ state, ...props }) => {
  const location = useLocation();
  const locationState = location.state;

  const newState = useMemo(() => {
    if (typeof state === "function") {
      return state(locationState);
    }

    return {
      ...locationState,
      ...state,
    };
  }, [state, locationState]);

  return <Link state={newState} {...props} />;
};

StatefulLink.propTypes = {
  state: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default StatefulLink;
