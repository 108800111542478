import CustomSet from "../../../../../hooks/useSet/CustomSet";

class OpenKeysSet extends CustomSet {
  replace(from, to) {
    if (from === to) {
      return;
    }

    const isFromOpened = this.has(from);
    const isToOpened = this.has(to);

    if (isFromOpened && !isToOpened) {
      this.delete(from);
      this.add(to);
      return;
    }

    if (!isFromOpened && isToOpened) {
      this.delete(to);
      this.add(from);
      return;
    }

    // In cases when both of them was opened
    // or both of them wasn't - do nothing
    // noinspection UnnecessaryReturnStatementJS
    return;
  }
}

export default OpenKeysSet;
