import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useQueryClient } from "@tanstack/react-query";
import { Button, FormInstance, Modal, Space } from "antd";
import { ColumnsType } from "antd/lib/table";
import {
  useForumDocumentDefinitionElementCreateMutation,
  useForumDocumentDefinitionElementDeleteMutation,
  useForumDocumentDefinitionElementReorderMutation,
  useForumDocumentDefinitionElementUpdateMutation,
} from "api/mutations/forumDocumentDefinition";
import { useForumDocumentCustomPageMergeTagsQuery } from "api/queries/forumDocumentDefinition";
import KEYS from "api/queries/keys";
import { DraggableTable } from "components/DraggableTable";
import {
  CustomPageElementAlignments,
  CustomPageElementTypes,
  ForumDocumentCustomPageElementResponseModel,
  ForumDocumentCustomPageElementUpdateModel,
  ForumDocumentDefinitionSectionResponseModel,
} from "generated/api";
import { toNumber } from "lodash";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TableColumn } from "utils/tableColumnUtils";

import { ReactQuillType } from "../SelectionOptions/TextEditorOption";
import EditElementDrawer from "./EditElementDrawer";

import editorStyles from "components/TextEditor/plainTextStyles.module.less";

interface CustomElementsProps {
  documentDefinitionSection?: ForumDocumentDefinitionSectionResponseModel;
  forumId: number;
  documentDefinitionSectionId: number;
  onClose: () => void;
  sectionForm: FormInstance;
  sectionOptionsForm: FormInstance;
  quills: ReactQuillType[];
  setQuills: Dispatch<SetStateAction<ReactQuillType[]>>;
  setSection: Dispatch<SetStateAction<ForumDocumentDefinitionSectionResponseModel | undefined>>;
}

export default function CustomElements({
  documentDefinitionSection,
  forumId,
  documentDefinitionSectionId,
  setSection,
}: CustomElementsProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isEditElementDrawerVisible, setIsEditElementDrawerVisible] = useState(false);
  const [element, setElement] = useState<ForumDocumentCustomPageElementResponseModel | undefined>();
  const [mergeTags, setMergeTags] = useState<string[] | undefined>();

  const mergeTagsQuery = useForumDocumentCustomPageMergeTagsQuery(
    {},
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      onSuccess: ({ data }: { data: string[] }) => {
        setMergeTags(data.map((tag) => `[${tag}]`));
      },
    },
  );

  const createMutation = useForumDocumentDefinitionElementCreateMutation();
  const deleteMutation = useForumDocumentDefinitionElementDeleteMutation();
  const updateMutation = useForumDocumentDefinitionElementUpdateMutation();
  const reorderMutation = useForumDocumentDefinitionElementReorderMutation();

  const handleAddElement = () => {
    const forumDocumentCustomPageElementCreateModel = {
      type: CustomPageElementTypes.HtmlText,
      value: "Change this element",
      alignment: CustomPageElementAlignments.Left,
    };

    createMutation.mutate(
      {
        forumId,
        documentDefinitionSectionId,
        forumDocumentCustomPageElementCreateModel,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTION]);
        },
      },
    );
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id && over) {
      const current = documentDefinitionSection?.elements?.find((i) => i.order === active.id);

      setSection((previous) => {
        if (!previous) return;
        const activeIndex = previous.elements.findIndex((i) => i.order === active.id);
        const overIndex = previous.elements.findIndex((i) => i.order === over?.id);
        const newElements = arrayMove(previous.elements, activeIndex, overIndex);
        return { ...previous, elements: newElements };
      });

      reorderMutation.mutate({
        forumId,
        documentDefinitionSectionId,
        elementId: current?.id as number,
        reorderRequestModel: {
          order: over.id as number,
        },
      });
    }
  };

  const handleDelete = (record: ForumDocumentCustomPageElementResponseModel) => {
    Modal.confirm({
      title: t("Delete custom page element"),
      content: t("Do you want to proceed with deletion of this element?"),
      icon: <ExclamationCircleOutlined />,
      cancelText: t("no"),
      okText: t("Yes"),
      onOk: async () => {
        deleteMutation.mutate(
          {
            forumId,
            documentDefinitionSectionId: documentDefinitionSectionId,
            elementId: record.id,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTION]);
            },
          },
        );
      },
    });
  };

  const handleEdit = (record: ForumDocumentCustomPageElementResponseModel) => {
    setIsEditElementDrawerVisible(true);
    setElement(record);
  };

  const handleEditSubmit = (values: ForumDocumentCustomPageElementUpdateModel) => {
    updateMutation.mutate(
      {
        forumId,
        documentDefinitionSectionId,
        elementId: toNumber(element?.id),
        forumDocumentCustomPageElementUpdateModel: values,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([KEYS.GET_FORUM_DOCUMENT_DEFINITION_SECTION]);
          setIsEditElementDrawerVisible(false);
        },
        onError: () => {
          toast.error(t("Error"));
        },
      },
    );
  };

  const customElementsColumns: ColumnsType<ForumDocumentDefinitionSectionResponseModel> = [
    new TableColumn(t("Element"), "type", { width: "14%" }),
    new TableColumn(t("Description"), "value", {
      render: (description: string) => {
        return (
          <span
            className={editorStyles.editorText}
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        );
      },
    }),
    new TableColumn("", "id", {
      align: "center",
      width: "12%",
      render: (id: number, record: ForumDocumentCustomPageElementResponseModel) => (
        <Button
          loading={mergeTagsQuery.isLoading}
          disabled={mergeTagsQuery.isLoading}
          onClick={() => handleEdit(record)}
        >
          {t("Edit")}
        </Button>
      ),
    }),
    new TableColumn("", "id", {
      width: "8%",
      render: (_: unknown, record: ForumDocumentCustomPageElementResponseModel) => {
        return <DeleteFilled className="delete-icon" onClick={() => handleDelete(record)} />;
      },
    }),
  ];

  return (
    <>
      {element && (
        <EditElementDrawer
          forumId={forumId}
          element={element}
          open={isEditElementDrawerVisible}
          mergeTags={mergeTags}
          isLoading={updateMutation.isLoading}
          onClose={() => setIsEditElementDrawerVisible(false)}
          onSubmit={handleEditSubmit}
        />
      )}
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <Button onClick={handleAddElement} type="primary">
          {t("Add New Element")}
        </Button>
        <DraggableTable
          dataSource={documentDefinitionSection?.elements}
          columns={customElementsColumns}
          id="custom-elements-table"
          data-testId="custom-elements-table"
          bordered
          pagination={false}
          rowKey={"order"}
          onDragEnd={handleDragEnd}
          disableOrdering={false}
          loading={
            reorderMutation.isLoading ||
            updateMutation.isLoading ||
            createMutation.isLoading ||
            deleteMutation.isLoading
          }
        />
      </Space>
    </>
  );
}
