/* tslint:disable */
/* eslint-disable */
/**
 * EM2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingNoteRequestModel } from '../apiModels';
// @ts-ignore
import { BillingNoteResponseModel } from '../apiModels';
/**
 * BillingNoteApi - axios parameter creator
 * @export
 */
export const BillingNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Deletes a billing note from a forum building
         * @param {number} forumId 
         * @param {number} billingNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBillingNotesBillingNoteIdDelete: async (forumId: number, billingNoteId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdBillingNotesBillingNoteIdDelete', 'forumId', forumId)
            // verify required parameter 'billingNoteId' is not null or undefined
            assertParamExists('forumsForumIdBillingNotesBillingNoteIdDelete', 'billingNoteId', billingNoteId)
            const localVarPath = `/forums/{forumId}/billing-notes/{billingNoteId}`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)))
                .replace(`{${"billingNoteId"}}`, encodeURIComponent(String(billingNoteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets list of billing notes from a forum
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBillingNotesGet: async (forumId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdBillingNotesGet', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/billing-notes`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates a billing note to a forum building
         * @param {number} forumId 
         * @param {BillingNoteRequestModel} [billingNoteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBillingNotesPost: async (forumId: number, billingNoteRequestModel?: BillingNoteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forumId' is not null or undefined
            assertParamExists('forumsForumIdBillingNotesPost', 'forumId', forumId)
            const localVarPath = `/forums/{forumId}/billing-notes`
                .replace(`{${"forumId"}}`, encodeURIComponent(String(forumId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingNoteRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingNoteApi - functional programming interface
 * @export
 */
export const BillingNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Deletes a billing note from a forum building
         * @param {number} forumId 
         * @param {number} billingNoteId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdBillingNotesBillingNoteIdDelete(forumId: number, billingNoteId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdBillingNotesBillingNoteIdDelete(forumId, billingNoteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets list of billing notes from a forum
         * @param {number} forumId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdBillingNotesGet(forumId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingNoteResponseModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdBillingNotesGet(forumId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates a billing note to a forum building
         * @param {number} forumId 
         * @param {BillingNoteRequestModel} [billingNoteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forumsForumIdBillingNotesPost(forumId: number, billingNoteRequestModel?: BillingNoteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingNoteResponseModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forumsForumIdBillingNotesPost(forumId, billingNoteRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingNoteApi - factory interface
 * @export
 */
export const BillingNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingNoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Deletes a billing note from a forum building
         * @param {BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBillingNotesBillingNoteIdDelete(requestParameters: BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDeleteRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.forumsForumIdBillingNotesBillingNoteIdDelete(requestParameters.forumId, requestParameters.billingNoteId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets list of billing notes from a forum
         * @param {BillingNoteApiForumsForumIdBillingNotesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBillingNotesGet(requestParameters: BillingNoteApiForumsForumIdBillingNotesGetRequest, options?: AxiosRequestConfig): AxiosPromise<Array<BillingNoteResponseModel>> {
            return localVarFp.forumsForumIdBillingNotesGet(requestParameters.forumId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates a billing note to a forum building
         * @param {BillingNoteApiForumsForumIdBillingNotesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forumsForumIdBillingNotesPost(requestParameters: BillingNoteApiForumsForumIdBillingNotesPostRequest, options?: AxiosRequestConfig): AxiosPromise<BillingNoteResponseModel> {
            return localVarFp.forumsForumIdBillingNotesPost(requestParameters.forumId, requestParameters.billingNoteRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forumsForumIdBillingNotesBillingNoteIdDelete operation in BillingNoteApi.
 * @export
 * @interface BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDeleteRequest
 */
export interface BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDelete
     */
    readonly forumId: number

    /**
     * 
     * @type {number}
     * @memberof BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDelete
     */
    readonly billingNoteId: number
}

/**
 * Request parameters for forumsForumIdBillingNotesGet operation in BillingNoteApi.
 * @export
 * @interface BillingNoteApiForumsForumIdBillingNotesGetRequest
 */
export interface BillingNoteApiForumsForumIdBillingNotesGetRequest {
    /**
     * 
     * @type {number}
     * @memberof BillingNoteApiForumsForumIdBillingNotesGet
     */
    readonly forumId: number
}

/**
 * Request parameters for forumsForumIdBillingNotesPost operation in BillingNoteApi.
 * @export
 * @interface BillingNoteApiForumsForumIdBillingNotesPostRequest
 */
export interface BillingNoteApiForumsForumIdBillingNotesPostRequest {
    /**
     * 
     * @type {number}
     * @memberof BillingNoteApiForumsForumIdBillingNotesPost
     */
    readonly forumId: number

    /**
     * 
     * @type {BillingNoteRequestModel}
     * @memberof BillingNoteApiForumsForumIdBillingNotesPost
     */
    readonly billingNoteRequestModel?: BillingNoteRequestModel
}

/**
 * BillingNoteApi - object-oriented interface
 * @export
 * @class BillingNoteApi
 * @extends {BaseAPI}
 */
export class BillingNoteApi extends BaseAPI {
    /**
     * 
     * @summary Deletes a billing note from a forum building
     * @param {BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingNoteApi
     */
    public forumsForumIdBillingNotesBillingNoteIdDelete(requestParameters: BillingNoteApiForumsForumIdBillingNotesBillingNoteIdDeleteRequest, options?: AxiosRequestConfig) {
        return BillingNoteApiFp(this.configuration).forumsForumIdBillingNotesBillingNoteIdDelete(requestParameters.forumId, requestParameters.billingNoteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets list of billing notes from a forum
     * @param {BillingNoteApiForumsForumIdBillingNotesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingNoteApi
     */
    public forumsForumIdBillingNotesGet(requestParameters: BillingNoteApiForumsForumIdBillingNotesGetRequest, options?: AxiosRequestConfig) {
        return BillingNoteApiFp(this.configuration).forumsForumIdBillingNotesGet(requestParameters.forumId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates a billing note to a forum building
     * @param {BillingNoteApiForumsForumIdBillingNotesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingNoteApi
     */
    public forumsForumIdBillingNotesPost(requestParameters: BillingNoteApiForumsForumIdBillingNotesPostRequest, options?: AxiosRequestConfig) {
        return BillingNoteApiFp(this.configuration).forumsForumIdBillingNotesPost(requestParameters.forumId, requestParameters.billingNoteRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}

