export default function MergeTags({ mergeTags }: { mergeTags?: string[] }) {
  return (
    <select className="ql-insertCustomTags">
      {mergeTags?.map((tag) => (
        <option key={tag} value={tag.toString()}>
          {tag}
        </option>
      ))}
    </select>
  );
}
