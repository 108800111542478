import { ReasonForMeetingItem } from "../../../../generated/api";
import ContentItem from "../content-item";
import References from "../references";

const collectForReasonForMeeting = (refs: References, item: ContentItem<ReasonForMeetingItem>): void => {
  const itemId = item.value.associatedSelectionStep;
  if (!itemId) {
    return;
  }

  refs.items.add(itemId);
};

export default collectForReasonForMeeting;
