import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useCancelDocumentGenerationMutation } from "api/mutations/itineraryBooklets";
import { useGetItineraryBookletQueuedDocumentsQuery } from "api/queries/itineraryBooklet";
import KEYS from "api/queries/keys";
import { Button, Modal, Space, Table, Typography } from "components/styleguide";
import { DocumentGenerationRunStatus } from "generated/api";
import { useModernQueryWithPaginationAndOrder } from "hooks";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { buildColumn } from "utils/columnUtils";

import styles from "./styles.module.less";

interface QueuedDocumentsTableProps {
  forumId: number;
}

const { Title } = Typography;
const { confirm } = Modal;

const QueuedDocumentsTable = ({ forumId }: QueuedDocumentsTableProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    pagination: inProgressTablePagination,
    response: {
      isLoading: isInProgressDocumentsLoading,
      data: { data: { items: inProgressDocuments = [] } = {} } = {},
    } = {},
  } = useModernQueryWithPaginationAndOrder(
    useGetItineraryBookletQueuedDocumentsQuery,
    {},
    { forumId, status: DocumentGenerationRunStatus.InProgress },
  );

  const {
    pagination: queuedTablePagination,
    response: { isLoading: isQueuedDocumentsLoading, data: { data: { items: queuedDocuments = [] } = {} } = {} } = {},
  } = useModernQueryWithPaginationAndOrder(
    useGetItineraryBookletQueuedDocumentsQuery,
    {},
    { forumId, status: DocumentGenerationRunStatus.Queued },
  );

  const { mutate: cancelGenerationMutate, isLoading: isMutationLoading } = useCancelDocumentGenerationMutation();

  const onDelete = (id: number) => {
    confirm({
      title: t("confirmation"),
      content: t("Do you want to cancel the generation of this document?"),
      icon: <ExclamationCircleOutlined />,
      okText: t("yes"),
      okType: "danger",
      cancelText: t("no"),
      zIndex: 2001,
      maskStyle: { zIndex: 2000 },
      onOk: async () => {
        cancelGenerationMutate(
          { forumId, documentGenerationRunId: id },
          {
            onSuccess: () => {
              queryClient.resetQueries([KEYS.GET_ITINERARY_BOOKLET_QUEUED_DOCUMENTS]);
            },
            onError: () => {
              toast.error(t("Something went wrong"));
            },
          },
        );
      },
    });
  };

  const columns = [
    buildColumn("Forum", "forumCode"),
    buildColumn("Group", "forumGroupName"),
    buildColumn("Document", "documentDefinition", {
      render: (document: string) => t(`queueDocumentRunDocuments.${document}`),
    }),
    buildColumn("Total", "numberOfDocuments"),
    buildColumn("Queued By", "createdBy"),
    buildColumn("", "id", {
      render: (id: number) => <Button onClick={() => onDelete(id)}>{t("Remove")}</Button>,
      width: "10%",
    }),
  ];

  return (
    <div className={styles.documentRunsTableSection}>
      <Space direction="vertical">
        <Title level={5}>{t("In Progress")}</Title>
        <Table
          bordered={true}
          columns={columns}
          dataSource={inProgressDocuments}
          loading={isInProgressDocumentsLoading || isMutationLoading}
          rowKey="id"
          pagination={inProgressTablePagination}
        />
      </Space>
      <Space direction="vertical">
        <Title level={5}>{t("Queued")}</Title>
        <Table
          bordered={true}
          columns={columns}
          dataSource={queuedDocuments}
          loading={isQueuedDocumentsLoading || isMutationLoading}
          rowKey="id"
          pagination={queuedTablePagination}
        />
      </Space>
    </div>
  );
};

export default QueuedDocumentsTable;
