import {
  useUpdateWelcomeLetterMutation,
  useUploadWelcomeLetterImageMutation,
} from "api/mutations/itineraryBookletsForumWelcomeLetter";
import { useGetWelcomeLetterQuery } from "api/queries/itineraryBookletsForumWelcomeLetter";
import { Button, Col, Form, Row, Select, Spin } from "components/styleguide";
import TextEditor from "components/TextEditor";
import {
  ItineraryBookletsForumWelcomeLetterRequestModel,
  ItineraryBookletsForumWelcomeLetterResponseModel,
  WelcomeLetterType,
} from "generated/api";
import { toNumber } from "lodash";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { validateMergeTags } from "utils/validatorUtils";

import styles from "./styles.module.less";

const { Option } = Select;

const mergeTagsRegex = /#\[.*?\]#/g;

export default function WelcomeLetter() {
  const { t } = useTranslation();
  const { id: forumId } = useParams();
  const [form] = Form.useForm();
  const editorRef = useRef<ReactQuill>(null);
  const [letter, setLetter] = useState<ItineraryBookletsForumWelcomeLetterResponseModel>();

  const uploadImageMutation = useUploadWelcomeLetterImageMutation();
  const updateMutation = useUpdateWelcomeLetterMutation();

  const welcomeLetterQuery = useGetWelcomeLetterQuery(
    { forumId: toNumber(forumId) },
    {
      onSuccess: ({ data }) => {
        setLetter(data);
      },
    },
  );

  const imageHandler = () => {
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      if (input.files) {
        const file: File = input.files[0];
        uploadImageMutation.mutate(
          { forumId: toNumber(forumId), formFile: file },
          {
            onSuccess: async ({ data }) => {
              const range = editorRef.current?.getEditorSelection();
              editorRef.current?.getEditor().insertEmbed(range?.index ?? 0, "image", data);
            },
          },
        );
      }
    };
  };

  const submit = (values: { type: WelcomeLetterType; body: string }) => {
    const model = {
      type: values.type,
      body: {
        jsonBody: "placeholder",
        htmlBody: values.body,
      },
    } as ItineraryBookletsForumWelcomeLetterRequestModel;

    updateMutation.mutate(
      { forumId: toNumber(forumId), itineraryBookletsForumWelcomeLetterRequestModel: model },
      {
        onSuccess: () => toast.success(t("Welcome Letter updated successfully")),
        onError: () => toast.error(t("Something went wrong")),
      },
    );
  };

  if (welcomeLetterQuery.isLoading || !letter) {
    return (
      <div className="loader">
        <Spin />
      </div>
    );
  }

  return (
    <Form
      id="emailTemplatesForm"
      name="emailTemplates"
      labelAlign="left"
      layout="vertical"
      form={form}
      onFinish={submit}
      className={styles.form}
    >
      <div className="form-layout">
        <div>
          <Form.Item
            label={t("Type")}
            name="type"
            initialValue={letter?.type}
            rules={[
              {
                required: true,
                message: "Required",
              },
            ]}
          >
            <Select>
              <Option value="html">{t("Html")}</Option>
              <Option value="image">{t("Image")}</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={t("Letter")}
            key="body"
            name="body"
            initialValue={letter?.body?.htmlBody}
            rules={[
              {
                required: true,
                message: t("errors.required", { prop: "$t(Letter)" }),
                validator: () => {
                  const contents = editorRef.current?.getEditor().getContents();
                  const firstRow = contents?.ops ? contents?.ops[0] : null;

                  if (!contents || (contents.length() === 1 && firstRow?.insert == "\n")) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: validateMergeTags(mergeTagsRegex, t, letter?.replacers),
              },
            ]}
          >
            <TextEditor
              editorRef={editorRef}
              imageHandler={imageHandler}
              mergeTags={letter?.replacers}
              showListFormats
            />
          </Form.Item>
        </div>
        <div>
          <Row gutter={80}>
            <Col span={1}>
              <Button
                htmlType="submit"
                type="primary"
                loading={updateMutation.isLoading || welcomeLetterQuery.isLoading}
              >
                {t("Save")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Form>
  );
}
