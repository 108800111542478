import { AxiosRequestConfig } from "axios";
import { GdprRequestApiGdprGetRequest } from "generated/api";

import apiClient from "../../../api-client";
import getWrappedUseQuery from "../../helpers/getWrappedUseQuery";
import KEYS from "../keys";

export const useGdprRequestsQuery = getWrappedUseQuery(
  KEYS.GET_GDPR_REQUESTS,
  (params: GdprRequestApiGdprGetRequest, options: AxiosRequestConfig = {}) =>
    apiClient.GdprRequests.getAll({ ...params, orderBy: "createdAt", orderDir: "desc" }, options),
);
